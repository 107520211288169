import { gql } from '@apollo/client';

import { descriptionRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/descriptionRetailBulkAdjustmentAttributes';
import { disclaimerRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/disclaimerRetailBulkAdjustmentAttributes';
import { overlayRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/overlayRetailBulkAdjustmentAttributes';
import { priceRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/priceRetailBulkAdjustmentAttributes';
import { promotionRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/promotionRetailBulkAdjustmentAttributes';
import { retailBulkAdjustmentList } from 'store/api/graph/fragments/retailBulkAdjustmentList';
import { retailItemFilters } from 'store/api/graph/fragments/retailItemFilters';

import { rooftopList } from './rooftopList';

export const retailBulkAdjustmentDetail = gql`
  fragment RetailBulkAdjustmentDetailFragment on RetailBulkAdjustment {
    ...RetailBulkAdjustmentListFragment

    filter {
      ...RetailItemFiltersFragment
    }

    rooftopName: rooftop {
      ...RooftopListFragment
    }

    priceAttributes: attributes {
      ... on PriceRetailBulkAdjustmentAttributes {
        ...PriceRetailBulkAdjustmentAttributesFragment
      }
    }

    overlayAttributes: attributes {
      ... on OverlayRetailBulkAdjustmentAttributes {
        ...OverlayRetailBulkAdjustmentAttributesFragment
      }
    }

    descriptionAttributes: attributes {
      ... on DescriptionRetailBulkAdjustmentAttributes {
        ...DescriptionRetailBulkAdjustmentAttributesFragment
      }
    }

    promotionAttributes: attributes {
      ... on PromotionRetailBulkAdjustmentAttributes {
        ...PromotionRetailBulkAdjustmentAttributesFragment
      }
    }

    disclaimerAttributes: attributes {
      ... on DisclaimerRetailBulkAdjustmentAttributes {
        ...DisclaimerRetailBulkAdjustmentAttributesFragment
      }
    }

    order
  }
  ${rooftopList}
  ${overlayRetailBulkAdjustmentAttributes}
  ${priceRetailBulkAdjustmentAttributes}
  ${descriptionRetailBulkAdjustmentAttributes}
  ${promotionRetailBulkAdjustmentAttributes}
  ${disclaimerRetailBulkAdjustmentAttributes}
  ${retailBulkAdjustmentList}
  ${retailItemFilters}
`;
