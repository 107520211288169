import styled, { css } from 'styled-components/macro';

import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import { BLUE_500, LINE_HEIGHT_2 } from 'styles/tokens';
import { FONT_SIZE_12, FONT_SIZE_13, FONT_WEIGHT_SEMI_BOLD, LINE_HEIGHT_DEFAULT } from 'styles/typography';

import SecondaryText from './SecondaryText';
import Text from './Text';

/**
 * @deprecated Do not directly import `LabelCSS`, extend `Label` component instead
 */
export const LabelCSS = css`
  color: ${BODY_TEXT};
  font-size: ${FONT_SIZE_13};
  line-height: ${LINE_HEIGHT_2};
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  text-align: left;
`;

const Label = styled(Text)`
  ${LabelCSS}
`;

export const PrimaryLabel = styled(Label)`
  color: ${BLUE_500};
`;

export const SecondaryLabel = styled(SecondaryText)`
  color: ${BODY_TEXT_TERTIARY};
  font-size: ${FONT_SIZE_12};
  line-height: ${LINE_HEIGHT_DEFAULT};
  white-space: normal;
`;

export const TertiaryLabel = styled(Label)`
  color: ${BODY_TEXT};
`;

export default Label;
