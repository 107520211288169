import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import IntegrationWebsiteListItem from 'components/ui/lists/IntegrationWebsiteListItem';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  CppRooftopIntegrationAttributesInputParameter,
  LeadDistributionRooftopIntegrationAttributesInputParameter,
  RooftopIntegrationModifyParameter,
} from 'store/api/graph/interfaces/types';

import { RooftopIntegrationDetailsFields } from './interfaces';

const DetailFields: StepFields = {
  [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopIntegration.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
    label: 'type',
    placeholder: 'type',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
    label: 'integration_one',
    placeholder: 'integration_one',
    queryAlias: RooftopIntegration.INTEGRATION_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID]: {
    label: 'third_party_rooftop_id',
    queryAlias: RooftopIntegration.THIRD_PARTY_ROOFTOP_ID,
    placeholder: 'third_party_rooftop_id',
    hasSeparator: true,
    clear: { field: RooftopIntegrationModifyParameter._thirdPartyRooftopId },
  },
  [RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE]: {
    label: 'website_page_type',
    placeholder: 'website_page_type',
    queryVar: RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    queryAlias: [RooftopIntegration.WEBSITE_PAGE_TYPE, RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE],
    clear: {
      field: CppRooftopIntegrationAttributesInputParameter._websitePageType,
      target: RooftopIntegrationAttributesPointer.CPP,
    },
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.CPP_WEBSITE]: {
    label: 'website_one',
    placeholder: 'website_one',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    overrideSubStepRenderElement: IntegrationWebsiteListItem,
    queryAlias: [RooftopIntegration.CPP_WEBSITE, RooftopIntegrationDetailsFields.CPP_WEBSITE],
    clear: {
      field: CppRooftopIntegrationAttributesInputParameter._websiteId,
      target: RooftopIntegrationAttributesPointer.CPP,
    },
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.WEBSITE_ROUTES]: {
    label: 'website_route_other',
    placeholder: 'website_route_other',
    queryVar: RooftopIntegrationDetailsFields.WEBSITE_ROUTES,
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    queryAlias: [RooftopIntegration.WEBSITE_ROUTES, RooftopIntegrationDetailsFields.WEBSITE_ROUTES],
    tooltipContents: 'website_route_other_tooltip',
    clear: {
      field: CppRooftopIntegrationAttributesInputParameter._websiteRouteIds,
      target: RooftopIntegrationAttributesPointer.CPP,
    },
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE]: {
    label: 'website_one',
    placeholder: 'website_one',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    overrideSubStepRenderElement: IntegrationWebsiteListItem,
    queryAlias: [
      RooftopIntegration.LEAD_DISTRIBUTION_WEBSITE,
      RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE,
    ],
    clear: {
      field: LeadDistributionRooftopIntegrationAttributesInputParameter._websiteId,
      target: RooftopIntegrationAttributesPointer.LEAD_DISTRIBUTION,
    },
  },
  [RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID]: {
    label: 'third_party_id',
    queryAlias: RooftopIntegration.LEAD_DISTRIBUTION_THIRD_PARTY_ID,
    placeholder: 'third_party_id',
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.ACTIVE]: {
    label: 'active',
    queryAlias: RooftopIntegration.ACTIVE,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._active },
  },
  [RooftopIntegrationDetailsFields.PRIMARY]: {
    label: 'primary',
    queryAlias: RooftopIntegration.PRIMARY,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._primary },
  },
  [RooftopIntegrationDetailsFields.IMPORT_PHOTOS]: {
    label: 'import_photos',
    queryAlias: RooftopIntegration.IMPORT_PHOTOS,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._importPhotos },
  },
  [RooftopIntegrationDetailsFields.RUN_ONCE]: {
    label: 'run_once',
    queryAlias: RooftopIntegration.RUN_ONCE,
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'run_once_tooltip',
    hasSeparator: true,
    clear: { field: RooftopIntegrationModifyParameter._runOnce },
  },
  [RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL]: {
    label: 'privacy_policy_url',
    placeholder: 'privacy_policy_url',
    queryAlias: RooftopIntegration.PRIVACY_POLICY_URL,
    clear: { field: RooftopIntegrationModifyParameter._privacyPolicyUrl },
  },
};

export default DetailFields;
