export default {
  /**
   * Need to manually handle merging all non-normalized objects.
   * e.g. Nested array types.
   *
   * Combine nested objects that do not have IDs, but are known (by you, the application developer)
   * to represent the same logical object, assuming the parent object is the same.
   */
  NonNormalizedMergedTypes: [
    'VehicleAttributes',
    'RetailPricing',
    'TradeInAppraisals',
    'Location',
    'TradeInGuaranteedValue',
    'MonetaryAmount',
    'LeaseRetailPricing',
    'CashRetailPricing',
    'FinanceRetailPricing',
    'InventoryItemVideo',
    'ShowroomConfiguration',
    'LeadActivityAttributes',
  ],
  /**
   * Disable normalization for the following types.
   * e.g. FacetFilters.
   *
   * Objects that are not normalized are instead embedded within their parent object in the cache.
   * Some types have an ID but are not globally unique, so disable data normalization until BE makes
   * it unique per section, fixes stale data.
   */
  NonNormalizedUnMergedTypes: ['Facet', 'Filter'],
  /**
   * Singletons.
   * e.g. All metadata.
   *
   * If the cache will only ever contain one object and that object has no identifying fields,
   * you can provide an empty array for its keyFields to enable data normalization.
   */
  SingletonTypes: [
    'Metadata',
    'MutationMetadata',
    'InventoryItemMetadata',
    'VehicleAttributesMetadata',
    'MotorcycleAttributesMetadata',
    'RetailItemMetadata',
    'RetailItemPricingMetadata',
    'TradeInItemMetadata',
    'InventoryItemPhotoMetadata',
    'LeadMetadata',
    'LocationMetadata',
    'LeadActivityMetadata',
    'PageMetadata',
    'RetailBulkAdjustmentMetadata',
    'PriceAttributesMetadata',
    'RetailPriceAdjustmentMetadata',
    'RooftopMetadata',
    'RooftopDepartmentMetadata',
    'RooftopIntegrationMetadata',
    'FinanceRooftopPricingMetadata',
    'CashRooftopPricingMetadata',
    'LeaseRooftopPricingMetadata',
    'TagMetadata',
    'TaskMetadata',
    'UserMetadata',
  ],
};
