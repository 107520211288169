import WebFont from 'webfontloader';

import { BOLD_FVD } from 'styles/typography';

/**
 * Cross-browser preloading of supported font variations upon boot.
 * Used to prevent FOUT (Flash of Unstyled Text) issues.
 *
 * More info: https://en.wikipedia.org/wiki/Flash_of_unstyled_content
 *
 * @param fvdList List of FVDs to preload. Defaults to a list of mandatory FVDs.
 */
export const fontBootSequence = (fvdList: Record<string, unknown>[] = [BOLD_FVD]) => {
  const allFvds = fvdList.reduce((allFvds, currFvd) => Object.assign(allFvds, currFvd), {});
  const preloadedFontVariations = Object.keys(allFvds).join(',');
  WebFont.load({
    custom: {
      families: [`Adelle Sans:${preloadedFontVariations}`],
    },
  });

  return Promise.resolve();
};
