import { useMemo, useRef, useState } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import { SaveFilterViewDialog } from 'components/ui/dialogs/SaveFilterViewDialog';
import type FilterGroup from 'components/ui/filters/interfaces/filterGroup';
import SavedFilterViewList from 'components/ui/filters/SavedFilterViewList';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { ListSectionTitle } from 'components/ui/lists/CommonList';
import { Clickable } from 'components/ui/shared/Button';
import type { ScrollableRef } from 'components/ui/shared/interfaces/Scrollable';
import DefaultListSettings from 'containers/DefaultListSettings';
import { SavedFilterViewSettings } from 'containers/filters/SavedFilterViewSettings';
import {
  deleteSavedFilterView,
  isFilterViewSavable,
  isSaveFilterViewAvailableForRoute,
} from 'containers/filters/savedFilterViewUtils';
import { useSavedFilterQuery } from 'containers/filters/useSavedFilterQuery';
import { ClientFacetFilterId } from 'enums/clientFacetFilter';
import { ElementTestId } from 'enums/testing';
import { useSearch } from 'hooks/contexts/useSearch';
import { useMountEffect } from 'hooks/useMountEffect';
import { useRouter } from 'hooks/useRouter';
import { withTooltip } from 'hooks/withTooltip';
import type { Column } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { formatNumber } from 'utils/formatUtils';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';
import { userIdStorage } from 'utils/storage/auth';
import { storageManager, StorageType } from 'utils/storageUtils';

import CircularArrowIcon from '../icons/CircularArrowIcon';
import TrashCanIcon from '../icons/TrashCanIcon';
import { PrimaryArrowPosition, SecondaryArrowPosition, TooltipStyle } from '../shared/Tooltip';
import TooltipButton from '../shared/TooltipButton';

import { FiltersContainer, FilterSectionHeader, SelectedFacetFilterContainer } from './common';
import { SelectedFacetFilter } from './FacetFilter';
import FilterSections from './FilterSections';
import { DefaultFilters } from './interfaces/filters';
import { SortSelectedFacetFilterContainer } from './SortFilter';
import ViewToggleFilter from './ViewToggleFilter';

/**
 * Custom client side filters to be rendered in `Filters`
 * that are not part of the server provided filters.
 */
export type CustomFilterType = {
  /** Unique id of this filer section. */
  id: string;
  /** Order of apperance of this filter section in relation to all filters. */
  order: number;
  /** Whether or not this filter is enabled. */
  enabled?: boolean;
  /** Component to be rendered in the `Filters`. */
  renderElement: any;
};

/**
 * Input params for custom filter sections render methods
 */
export type CustomFilterInputType = {
  /** Search params of the filters in item connections. */
  connectionSearchParams: any;
  /** List of filters provided by the server. */
  serverFilters: Array<FilterGroup>;
};

const FilterSectionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const FilterSectionHeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SavedFilterNameLabel = styled(Label)`
  margin-top: 10px;
`;

const SavedFilterSectionHeader = styled(FilterSectionHeader)<{ hasActiveSavedFilter: boolean }>`
  background: ${({ hasActiveSavedFilter }) => (hasActiveSavedFilter ? BLUE_500 : NEUTRAL_0)};
  padding: 0;
`;

const SavedFilterClickable = styled(Clickable)`
  padding: 15px 17px;
`;

const ResetButtonTooltipIcon = withTooltip(() => <CircularArrowIcon />);

const ClearButtonTooltipIcon = withTooltip(() => <TrashCanIcon />);

interface SavedFilterHeaderProps {
  /** The name of the active saved filter (optional). */
  activeSavedFilterName?: string;
  /** Identifier for testing purposes */
  activeSavedFilterTestId: string;
  /** Function to handle click events */
  onClick: () => void;
}

const SavedFilterHeader = ({ onClick, activeSavedFilterName, activeSavedFilterTestId }: SavedFilterHeaderProps) => (
  <SavedFilterSectionHeader hasActiveSavedFilter={!!activeSavedFilterName}>
    <SavedFilterClickable onClick={onClick}>
      <ListSectionTitle>
        <Label
          css={css`
            color: ${activeSavedFilterName ? NEUTRAL_0 : BODY_TEXT};
          `}
        >
          {translate.t('saved_filters_other')}
        </Label>
        <FilterSectionHeaderActions
          css={css`
            color: ${activeSavedFilterName ? NEUTRAL_0 : BODY_TEXT};
          `}
        >
          <ChevronRightIcon />
        </FilterSectionHeaderActions>
      </ListSectionTitle>
      {activeSavedFilterName && (
        <SavedFilterNameLabel
          data-testid={activeSavedFilterTestId}
          css={css`
            color: ${activeSavedFilterName ? NEUTRAL_0 : BODY_TEXT};
          `}
        >
          {activeSavedFilterName}
        </SavedFilterNameLabel>
      )}
    </SavedFilterClickable>
  </SavedFilterSectionHeader>
);

interface Props {
  customFilterSections?: (customFilterinput: CustomFilterInputType) => CustomFilterType[];
  searchParams: any;
  filters: Array<FilterGroup>;
  columns: Array<Column>;
  totalEdges: number;
  isPristine: boolean;
  overrideSection: (filter: FilterGroup) => void;
  disabledFilters: DefaultFilters[];
}

const Filters = ({
  filters,
  columns,
  totalEdges,
  isPristine,
  overrideSection,
  disabledFilters,
  searchParams,
  customFilterSections,
}: Props) => {
  const [selectedFacetFilterId, setSelectedFacetFilterId] = useState<string>();
  const [saveFilterViewDialogOpen, setSavedFilterViewDialogOpen] = useState(false);
  const [deletePendingFilterViewId, setDeletePendingFilterViewId] = useState<string>();
  const router = useRouter();
  const {
    clearSearchParams,
    clearKeywordSearchParams,
    setLocalSearchParams,
    activeSavedFilterView,
    updateActiveSavedFilterView,
  } = useSearch();
  const filterSectionsRef = useRef<ScrollableRef>(null);
  const { t } = translate;

  useMountEffect(() => clearKeywordSearchParams());

  const currentRouteSavedFilterViewSettings = useMemo(
    () => SavedFilterViewSettings[router.sectionPath],
    [router.sectionPath]
  );

  const { globalSearchParams, localSearchParams } = useSearch();
  const { data: savedConnectionFilters } = useSavedFilterQuery(currentRouteSavedFilterViewSettings);

  const isSaveFilterButtonShown = useMemo(
    () =>
      !isPristine &&
      isFilterViewSavable({
        routerPath: router.sectionPath,
        savedFilterViews: savedConnectionFilters,
        currentSearchParams: localSearchParams,
        globalSearchParams,
      }) &&
      isNil(activeSavedFilterView),
    [
      isPristine,
      router.sectionPath,
      savedConnectionFilters,
      localSearchParams,
      activeSavedFilterView,
      globalSearchParams,
    ]
  );

  const selectedFacetFilterSelectionList = useMemo(() => {
    switch (selectedFacetFilterId) {
      case null:
      case undefined:
      case '': {
        break;
      }

      case ClientFacetFilterId.SORT_BY: {
        return (
          <SortSelectedFacetFilterContainer setSelectedFacetFilterId={setSelectedFacetFilterId} columns={columns} />
        );
      }

      case ClientFacetFilterId.SAVED_FILTER: {
        return (
          <SelectedFacetFilterContainer>
            <SavedFilterViewList
              onClose={() => setSelectedFacetFilterId(undefined)}
              onSelect={filter => {
                if (filter) {
                  updateActiveSavedFilterView(filter);
                } else {
                  updateActiveSavedFilterView(undefined);
                }

                setSelectedFacetFilterId(undefined);
              }}
              onDelete={id => {
                setDeletePendingFilterViewId(id);
              }}
              selectedSaveFilterViewId={activeSavedFilterView?.id}
              savedFilters={savedConnectionFilters || []}
            />
          </SelectedFacetFilterContainer>
        );
      }

      default: {
        const selectedFilter = filters.find(filter => filter.id === selectedFacetFilterId);
        if (!selectedFilter) {
          return;
        }

        return (
          <SelectedFacetFilterContainer>
            <SelectedFacetFilter
              filter={selectedFilter}
              onClose={() => {
                clearKeywordSearchParams();
                setSelectedFacetFilterId(undefined);
              }}
            />
          </SelectedFacetFilterContainer>
        );
      }
    }
  }, [
    selectedFacetFilterId,
    columns,
    activeSavedFilterView?.id,
    savedConnectionFilters,
    filters,
    updateActiveSavedFilterView,
    clearKeywordSearchParams,
  ]);

  return (
    <FiltersContainer data-testid={ElementTestId.FACET_FILTER_CONTAINER}>
      <FilterSectionsContainer>
        {!disabledFilters.includes(DefaultFilters.RESULTS) && (
          <FilterSectionHeader>
            <ListSectionTitle>
              <Label>{t('x_results', [formatNumber(totalEdges)], { count: totalEdges })}</Label>
              <FilterSectionHeaderActions>
                {!isPristine && (
                  <Clickable
                    data-testid={ElementTestId.FACET_FILTER_CLEAR_BUTTON}
                    css={'margin-right: 10px'}
                    onClick={() => {
                      // Clear all filters
                      clearSearchParams();
                      if (filterSectionsRef.current) {
                        filterSectionsRef.current.updateScroll(null, 0);
                      }
                    }}
                  >
                    <TooltipButton
                      icon={ClearButtonTooltipIcon}
                      tooltip={{
                        arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
                        margin: { x: 0, y: 8 },
                        styleVariant: TooltipStyle.NORMAL,
                      }}
                    >
                      {t('facet_filters_clear_button_tooltip')}
                    </TooltipButton>
                  </Clickable>
                )}
                <Clickable
                  onClick={() => {
                    // Reset filters to default
                    storageManager.clearStorage({
                      ofType: StorageType.LOCAL,
                      forUserId: impersonationManager.getImpersonatedUserId() || userIdStorage.get(),
                      sectionPath: router.sectionPath,
                    });
                    setLocalSearchParams(DefaultListSettings[router.sectionPath]?.filter);
                    updateActiveSavedFilterView(undefined);
                    if (filterSectionsRef.current) {
                      filterSectionsRef.current.updateScroll(null, 0);
                    }
                  }}
                >
                  <TooltipButton
                    icon={ResetButtonTooltipIcon}
                    tooltip={{
                      arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
                      margin: { x: 0, y: 8 },
                      styleVariant: TooltipStyle.NORMAL,
                    }}
                  >
                    {t('facet_filters_reset_button_tooltip')}
                  </TooltipButton>
                </Clickable>
              </FilterSectionHeaderActions>
            </ListSectionTitle>
          </FilterSectionHeader>
        )}
        {!disabledFilters.includes(DefaultFilters.LAYOUT_TOGGLE) && (
          <FilterSectionHeader css="height: 50px;">
            <ViewToggleFilter />
          </FilterSectionHeader>
        )}
        {isSaveFilterViewAvailableForRoute(router.sectionPath) && (
          <SavedFilterHeader
            activeSavedFilterTestId={`${ElementTestId.FACET_FILTER_SELECTED_SAVED_FILTER}-${activeSavedFilterView?.id}`}
            onClick={() => setSelectedFacetFilterId(ClientFacetFilterId.SAVED_FILTER)}
            activeSavedFilterName={activeSavedFilterView?.name}
          />
        )}
        <FilterSections
          customFilterSections={customFilterSections}
          searchParams={searchParams}
          filters={filters}
          columns={columns}
          disabledFilters={disabledFilters}
          setSelectedFacetFilterId={setSelectedFacetFilterId}
          overrideSection={overrideSection}
          containerRef={filterSectionsRef}
          onSaveFilterButtonClick={isSaveFilterButtonShown ? () => setSavedFilterViewDialogOpen(true) : undefined}
        />
      </FilterSectionsContainer>

      {selectedFacetFilterSelectionList}

      <SaveFilterViewDialog
        routePath={router.sectionPath}
        searchParams={searchParams}
        isOpen={saveFilterViewDialogOpen}
        onSaved={results => {
          updateActiveSavedFilterView(results);
          setSavedFilterViewDialogOpen(false);
        }}
        onClose={() => setSavedFilterViewDialogOpen(false)}
      />

      <PromptDialog
        isConfirmDestructive
        isOpen={!!deletePendingFilterViewId}
        onConfirm={async () => {
          if (!deletePendingFilterViewId) {
            return;
          }
          if (deletePendingFilterViewId === activeSavedFilterView?.id) {
            updateActiveSavedFilterView(undefined);
          }

          return deleteSavedFilterView(deletePendingFilterViewId);
        }}
        onCancel={() => setDeletePendingFilterViewId(undefined)}
        onComplete={() => setDeletePendingFilterViewId(undefined)}
        title={t('hard_delete_title')}
        message={t('hard_delete_message')}
      />
    </FiltersContainer>
  );
};

export default Filters;
