export enum DateTimeFormat {
  DEFAULT_TIME_ZONE = 'UTC',
  YEAR_MONTH_DAY_DASH_FORMAT = 'yyyy-LL-dd',
  YEAR_MONTH_DAY_SLASH_FORMAT = 'yyyy/LL/dd',
  YEAR_MONTH_DAY_UNDERSCORE_FORMAT = 'yyyy_LL_dd',
  DATE_TIME_FORMAT = `LLLL dd yyyy, h:mm a`,
  DATE_YEAR_FORMAT = 'LLL dd, yyyy',
  DAY_DATE_YEAR_FORMAT = 'EEE, LLL dd, yyyy',
  HOUR_MINUTE_SEPARATOR = ':',
  HOUR_FORMAT = 'hh',
  MINUTE_FORMAT = 'mm',
  MERIDIEM_FORMAT = 'a',
  TIME_STAMP_FORMAT = 'hh:mm a',
  TIME_TYPE_FORMAT = 'yyyy-LL-dd hh:mm a',
  TIME_FORMAT = 'h:mm a',
  HOUR_24_TIME_FORMAT = 'HH:mm:ss',
}
