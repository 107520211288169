import { cloneDeep } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import { RetailFilterBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { getRetailItemConnectionFilterFromFields } from 'utils/retailFilterUtils';

import { rooftopIntegrationInventoryExportModify } from '../InventoryExportCreateModifyQueries';

class FiltersStep extends RetailFilterStep {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    if (!props.tier.isCreating) {
      /*
       * Due to the filter for Rooftop Integrations of type INVENTORY_EXPORT being nested in an attribute object
       * we need to bring it to the top level to work with the shared RetailFilterStep.
       */
      props.tier.data = {
        ...cloneDeep(props.tier.data),
        filter: props.tier.data?.inventoryExportAttributes?.filter,
      };
    }

    super(props, context);

    for (const field of this.fields) {
      const queryVar = field.queryVar as RetailFilterBuilderFields;
      if ([RetailFilterBuilderFields.ROOFTOP_ID, RetailFilterBuilderFields.FILTER_STATUS].includes(queryVar)) {
        setDisplayTypes(
          [
            { type: StepFieldDisplayType.HIDDEN, active: true },
            { type: StepFieldDisplayType.OMITTED, active: true },
          ],
          field
        );
      } else if (RetailFilterBuilderFields.FILTER_TAG_ID === queryVar) {
        field.subStepAddConfig = undefined;
      } else if (RetailFilterBuilderFields.FLAGS === queryVar) {
        field.options = (field?.options as StepField[])?.filter(
          option => option.queryVar !== RetailFilterBuilderFields.FILTER_SHOW_WEB
        );
      }
    }
  }

  async save(): Promise<boolean> {
    const filter = getRetailItemConnectionFilterFromFields(this.fields);
    /*
     * RooftopIntegrationModify does not allow a rooftopId to be saved in the filter.
     * But it does need to be set in order to fetch MMST metadata, so we override it on save.
     */
    filter.rooftopId = undefined;

    const variableOverrides = {
      filter: null,
      inventoryExportAttributes: {
        filter,
      },
    };
    return super.save(rooftopIntegrationInventoryExportModify, variableOverrides);
  }
}

export default FiltersStep;
