import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';
import { Permission } from './permission';

export const role = gql`
  fragment RoleFragment on Role {
    id
    archived

    name {
      ...MultilingualStringFragment
    }
    permissions {
      ...PermissionFragment
    }
  }
  ${MultilingualString}
  ${Permission}
`;
