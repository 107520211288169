import { memo, type SVGProps } from 'react';

const DealerTrackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12.769 15.379 15.48 5h-2.868c-.848 3.056-2.511 9.362-2.653 9.906-.142.544-.493.66-.651.65H4.755c.326-1.113 1.006-3.46 1.124-3.932a.728.728 0 0 1 .74-.562h3.193l.739-2.81H6.618c-2.413 0-3.489 2.13-3.726 3.194L1 18.395h7.6c2.862 0 3.972-2.01 4.169-3.016ZM13.094 18.336 16.76 5h2.908c-.73 2.73-2.248 8.745-2.909 10.793-.59 1.833-2.67 2.563-3.666 2.543ZM20.043 8.282H23l-.798 2.78h-2.987l.828-2.78Z"
    />
  </svg>
);
export default memo(DealerTrackIcon);
