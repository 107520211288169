import { useCallback, useRef } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import { ImageSize, ImageType } from 'enums/imageType';
import type { ConversationItemAttachment } from 'store/api/graph/interfaces/types';
import { ConversationItemAttachmentType } from 'store/api/graph/interfaces/types';
import { NEUTRAL_700 } from 'styles/tokens';
import { formatFileSize } from 'utils/formatUtils';

import DocumentIcon from '../icons/DocumentIcon';
import DownloadIcon from '../icons/DownloadIcon';
import { Clickable } from '../shared/Button';

import type { ListItemProps } from './interfaces/ListItemProps';

const SVGImageCSS = css`
  height: 40px;

  > * {
    background: none;
  }
`;

const AttachmentImage = styled(Image)<{ isImg: boolean }>`
  height: 36px;
  width: 36px;
  ${({ isImg }) => !isImg && SVGImageCSS};
  flex-shrink: 0;
`;

const Container = styled(Clickable)<{ isLocalFile: boolean }>`
  width: 100%;

  ${({ isLocalFile }) =>
    isLocalFile &&
    css`
      cursor: auto;
    `};
  a {
    display: inline-flex;
    position: relative;
    top: 1px;
  }
`;

export type AttachmentListItemType = ConversationItemAttachment;

interface Props extends AttachmentListItemType, ListItemProps {}

const AttachmentListItem = ({ listItemType, type, typeName, url, thumbnailUrl, name, extension = '', size }: Props) => {
  const isLocalFile = !typeName;
  const downloadRef = useRef<HTMLAnchorElement>(null);

  return (
    <Container
      isLocalFile={isLocalFile}
      onClick={useCallback(() => !isLocalFile && downloadRef.current?.click(), [isLocalFile])}
    >
      <ListItem listItemType={listItemType}>
        <AttachmentImage
          type={ImageType.ICON}
          src={type === ConversationItemAttachmentType.IMAGE && !isLocalFile ? thumbnailUrl || '' : undefined}
          isImg={type === ConversationItemAttachmentType.IMAGE}
          fallbackSrc={<DocumentIcon width={36} height={36} />}
          size={ImageSize.THUMBNAIL}
        />
        <ListItemDetails>
          <TextRow css="grid-template-columns: 1fr auto">
            <PrimaryText css="text-align: left;">{name}</PrimaryText>
            {!isLocalFile && (
              <a
                onClick={e => e.stopPropagation()}
                href={url}
                download={name || ''}
                ref={downloadRef}
                rel="noopener noreferrer"
                target="_blank"
              >
                <DownloadIcon color={NEUTRAL_700} />
              </a>
            )}
          </TextRow>
          <TextRow>
            <SecondaryText>{`${formatFileSize(size)} ${extension || ''}`}</SecondaryText>
          </TextRow>
        </ListItemDetails>
      </ListItem>
    </Container>
  );
};

export default AttachmentListItem;
