import { StepLegendNav, StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import { conversationItemCreate } from 'components/sections/createModify/conversations/ConversationCreateModifyQuery';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  leadActivityCreate,
  leadActivityModify,
} from 'components/sections/createModify/leadActivities/LeadActivityCreateModifyQuery';
import LeadActivityQueryMethods from 'components/sections/createModify/leadActivities/LeadActivityQueryMethods';
import LeadActivityNoteStep, {
  LeadActivityNoteFields,
} from 'components/sections/createModify/leadActivities/leadActivityReply/LeadActivityNoteStep';
import LeadActivityReplyStep, {
  LeadActivityReplyFields,
} from 'components/sections/createModify/leadActivities/leadActivityReply/LeadActivityReplyStep';
import { LeadActivityBuilderStep } from 'components/sections/createModify/leadActivities/steps/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import DetailsFields, { DetailLinkedRetailFields } from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

const LeadActivityReplyRefetchQueries = [
  'LeadActivityConnectionQuery',
  'LeadActivityCondensedListQuery',
  'LeadActivityDetailsContainerQuery',
];

const defaultLeadActivityCreate: BuilderEntry = {
  refetchQueries: LeadActivityQueryMethods.refetchQueries,
  getMetaData: LeadActivityQueryMethods.getMetaData,
  legend: [StepLegendNav],
  resourceType: ResourceType.LEADS,
  requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }],
  mutations: {
    create: leadActivityCreate,
    modify: leadActivityModify,
  },
  steps: [
    {
      id: LeadActivityBuilderStep.DETAILS,
      step: DetailsStep,
      stepName: 'details',
      isEnabled: true,
      fields: DetailsFields,
    },
  ],
};

const LeadActivityBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.LEAD_ACTIVITY_CREATE]: {
    ...defaultLeadActivityCreate,
  },
  [BuilderType.LEAD_ACTIVITY_CREATE_LINKED_RETAIL_ITEMS]: {
    ...defaultLeadActivityCreate,
    steps: [
      {
        id: LeadActivityBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailLinkedRetailFields,
      },
    ],
  },
  [BuilderType.LEAD_ACTIVITY_CREATE_REPLY]: {
    steps: [
      {
        id: LeadActivityBuilderStep.REPLY,
        step: LeadActivityReplyStep,
        stepName: 'send_a_reply',
        isEnabled: true,
        fields: LeadActivityReplyFields,
        tooltip: {
          title: 'send_a_reply',
          content: 'send_a_reply',
        },
      },
    ],
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.LEADS,
    refetchQueries: LeadActivityReplyRefetchQueries,
    legend: [StepLegendTooltip],
    mutations: {
      create: conversationItemCreate,
    },
  },
  [BuilderType.LEAD_ACTIVITY_NOTE]: {
    steps: [
      {
        id: LeadActivityBuilderStep.NOTE,
        step: LeadActivityNoteStep,
        stepName: 'send_a_note',
        isEnabled: true,
        fields: LeadActivityNoteFields,
        tooltip: {
          title: 'send_a_note',
          content: 'send_a_note_message_for_leads',
        },
      },
    ],
    /*
     * Normally, ADVANCED permissions are needed to create/modify entities, but users with BASIC permission
     * are able to edit lead activities assigned to them
     */
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }],
    refetchQueries: LeadActivityReplyRefetchQueries,
    resourceType: ResourceType.LEADS,
    mutations: {
      modify: leadActivityModify,
    },
  },
  [BuilderType.LEAD_ACTIVITY_MODIFY]: {
    getData: LeadActivityQueryMethods.getData,
    getMetaData: LeadActivityQueryMethods.getMetaData,
    refetchQueries: LeadActivityQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }],
    resourceType: ResourceType.LEADS,
    steps: [
      {
        id: LeadActivityBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      modify: leadActivityModify,
    },
  },
};

export default LeadActivityBuilders;
