export enum RooftopDetailsBuilderFields {
  LOGO = 'logo',
  GROUP_ID = 'groupId',
  ACCOUNT_REP_ID = 'accountRepId',
  EMAIL_SUBDOMAIN = 'emailSubdomain',
  CARFAX_ID = 'carfaxId',
  NAME = 'name',
  LOCALE = 'locale',
  WEBSITE = 'website',
  WEBSITE_PROVIDER = 'websiteProvider',
  PHONE_NUMBER = 'phoneNumber',
  FAX_NUMBER = 'faxNumber',
  EMAIL_BILLING = 'emailBilling',
  EMAIL_GENERAL = 'emailGeneral',
  LOCATION = 'location',
  DEALERTRACK_ID = 'dealertrackId',
  DEALERTRACK_CONFIRM_ID = 'dealertrackConfirmId',
  WARRANTY_TYPES = 'warrantyTypes',
  PACKAGE_ID = 'packageId',
}

export enum LocationInputFields {
  ADDRESS = 'address',
  CITY = 'city',
  REGION_CODE = 'regionCode',
  COUNTRY_CODE = 'countryCode',
  ZIP_CODE = 'zipCode',
}

export enum RooftopDescriptionBuilderFields {
  DESCRIPTION = 'description',
}

export enum RooftopPackageBuilderFields {
  APPOINTMENT_ENABLED = 'appointmentEnabled',
  LEAD_CONVERSATIONS = 'leadConversations',
  LEAD_ENABLED = 'leadEnabled',
  LEAD_SMS_MAILBOX_LIMIT = 'leadSmsMailboxLimit',
  LEAD_FORWARDING = 'leadForwarding',
  PACKAGE = 'packageId',
  RETAIL_BUILD_AND_PRICE = 'retailBuildAndPrice',
  RETAIL_ENABLED = 'retailEnabled',
  RETAIL_ITEM_LIMIT = 'retailItemLimit',
  RETAIL_PAYMENT_OPTION = 'retailPaymentOptions',
  RETAIL_PREMIUM_EXPORT_INTEGRATION = 'retailPremiumExportIntegrations',
  RETAIL_SHOWROOM = 'retailShowroom',
  // Fake field used in combination with `RETAIL_ITEM_LIMIT`
  RETAIL_UNLIMITED_INVENTORY = 'retailUnlimitedInventory',
  TASK_ENABLED = 'taskEnabled',
  TRADE_IN_ENABLED = 'tradeInEnabled',
  GUARANTEED_OFFER_PACKAGE = 'guaranteedOfferPackage',
  OEM = 'bundle.addOns.oem',
}

export enum RooftopBuilderStep {
  DETAILS = 'ROOFTOP_DETAILS',
  PACKAGE = 'ROOFTOP_PACKAGE',
  DESCRIPTION = 'ROOFTOP_DESCRIPTION',
}
