import { gql } from '@apollo/client';

export const resource = gql`
  fragment ResourceFragment on ResourceDescription {
    resource
    resourceName
    levels {
      level
      levelName
      description
    }
    tier
  }
`;
