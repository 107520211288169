import { memo } from 'react';

import styled from 'styled-components/macro';

import ArrowTopToBottomIcon from './ArrowTopToBottomIcon';

const ArrowLeftToRightIcon = styled(ArrowTopToBottomIcon)`
  transform: rotate(90deg);
`;

export default memo(ArrowLeftToRightIcon);
