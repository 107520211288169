import { FieldDataType } from 'enums/fieldDataType';
import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

export enum RooftopPricingSegment {
  NAME = 'name',
  DESCRIPTION = 'description',
  ORDER = 'order',
  INCENTIVES = 'incentives',
  CONFIGURATION = 'configuration',
  TARGETS = 'targets',
  CONFIGURATION_ENABLED = 'configuration.enabled',
  CONFIGURATION_STARTING_PRICE_FIELD_NAME = 'configuration.startingPriceFieldName',
  CONFIGURATION_STARTING_PRICE_FIELD = 'configuration.startingPriceField',
  CONFIGURATION_INCLUDE_TAX = 'configuration.includeTax',
  CONFIGURATION_INCLUDE_AFTER_TAX_REBATES = 'configuration.includeAfterTaxRebates',
  CONFIGURATION_DISCLAIMER = 'configuration.disclaimerTemplate',
  CONFIGURATION_INCLUDE_FREIGHT = 'configuration.includeFreight',
  CONFIGURATION_PRICING_SOURCE = 'configuration.pricingSource',
  CONFIGURATION_PRICING_SOURCE_NAME = 'configuration.pricingSourceName',
  CONFIGURATION_MIN_DISPLAY_PRICE = 'configuration.minDisplayPrice',
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD_NAME = 'configuration.autoPriceAdjustmentConfiguration.targetPriceFieldName',
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD = 'configuration.autoPriceAdjustmentConfiguration.targetPriceField',
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL = 'configuration.autoPriceAdjustmentConfiguration.label',
  CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE = 'configuration.autoPriceAdjustmentConfiguration.disclaimerTemplate',
}

export enum RooftopFinancePricingSegment {
  CONFIGURATION_AVAILABLE_TERMS = 'configuration.availableTerms',
  CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES = 'configuration.availablePaymentFrequencies',
  CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES_NAME = 'configuration.availablePaymentFrequenciesNames',
  CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY = 'configuration.defaultPaymentFrequency',
  CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY_NAME = 'configuration.defaultPaymentFrequencyName',
  CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES = 'configuration.availableMileageAllowances',
  CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE = 'configuration.defaultMileageAllowance',
  CONFIGURATION_DEFAULT_TERM = 'configuration.defaultTerm',
  CONFIGURATION_INTEREST_RATES = 'configuration.interestRates',
  CONFIGURATION_INTEREST_RATE_TYPE_NAME = 'configuration.interestRateTypeName',
  CONFIGURATION_INTEREST_RATE_TYPE = 'configuration.interestRateType',
  CONFIGURATION_DEFAULT_TERM_TYPE_NAME = 'configuration.defaultTermTypeName',
  CONFIGURATION_DEFAULT_TERM_TYPE = 'configuration.defaultTermType',
}

export enum RooftopLeasePricingSegment {
  CONFIGURATION_AVAILABLE_TERMS = 'configuration.availableTerms',
  CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES = 'configuration.availablePaymentFrequencies',
  CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES_NAME = 'configuration.availablePaymentFrequenciesNames',
  CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES = 'configuration.availableMileageAllowances',
  CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY = 'configuration.defaultPaymentFrequency',
  CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY_NAME = 'configuration.defaultPaymentFrequencyName',
  CONFIGURATION_DEFAULT_TERM = 'configuration.defaultTerm',
  CONFIGURATION_INTEREST_RATES = 'configuration.interestRates',
  CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE = 'configuration.defaultMileageAllowance',
  CONFIGURATION_RESIDUAL_RATES = 'configuration.residualRates',
  CONFIGURATION_DEFAULT_TERM_TYPE = 'configuration.defaultTermType',
  CONFIGURATION_DEFAULT_TERM_TYPE_NAME = 'configuration.defaultTermTypeName',
  CONFIGURATION_INTEREST_RATE_TYPE = 'configuration.interestRateType',
  CONFIGURATION_INTEREST_RATE_TYPE_NAME = 'configuration.interestRateTypeName',
  CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE = 'configuration.defaultMileageAllowanceType',
  CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE_NAME = 'configuration.defaultMileageAllowanceTypeName',
}

const RooftopPricingSegmentConfig: TableSettingsType = {
  [RooftopPricingSegment.NAME]: { label: 'segment_configuration_name', type: FieldDataType.DEFAULT },
  [RooftopPricingSegment.DESCRIPTION]: { label: 'description', type: FieldDataType.DEFAULT },
  [RooftopPricingSegment.ORDER]: { label: 'order', type: FieldDataType.DEFAULT, tooltip: 'order_tooltip' },
  [RooftopPricingSegment.CONFIGURATION_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    type: FieldDataType.MONETARY,
    tooltip: 'minimum_display_price_tooltip',
  },
  [RooftopPricingSegment.CONFIGURATION_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX]: { label: 'include_tax', type: FieldDataType.BOOLEAN },
  [RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
    label: 'include_after_tax_rebates',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopPricingSegment.CONFIGURATION_DISCLAIMER]: {
    label: 'disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'pricing_segment_disclaimer_tooltip',
  },
  [RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD_NAME]: {
    label: 'starting_cash_price',
    type: FieldDataType.DEFAULT,
  },
  [RooftopPricingSegment.CONFIGURATION_INCLUDE_FREIGHT]: {
    label: 'include_freight',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE_NAME]: {
    label: 'incentives_and_rates_source',
    type: FieldDataType.DEFAULT,
  },
  [RooftopPricingSegment.TARGETS]: {
    label: 'target_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD_NAME]: {
    label: 'adjustment_target_price',
    type: FieldDataType.DEFAULT,
  },
  [RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]: {
    label: 'adjustment_label',
    type: FieldDataType.DEFAULT,
  },
  [RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE]: {
    label: 'adjustment_disclaimer',
    type: FieldDataType.DEFAULT,
  },
};

const RooftopFinancePricingSegmentConfig: TableSettingsType = {
  ...RooftopPricingSegmentConfig,
  [RooftopFinancePricingSegment.CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES]: {
    label: 'available_payment_period_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY]: {
    label: 'default_payment_period',
    type: FieldDataType.ENUM,
  },
  [RooftopFinancePricingSegment.CONFIGURATION_AVAILABLE_TERMS]: {
    label: 'available_term_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM]: { label: 'default_term', type: FieldDataType.ENUM },
  [RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD_NAME]: {
    label: 'starting_finance_price',
    type: FieldDataType.DEFAULT,
  },
  [RooftopFinancePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE_NAME]: {
    label: 'default_term',
    type: FieldDataType.ENUM,
  },
  [RooftopFinancePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE_NAME]: {
    label: 'interest_rate_type',
    type: FieldDataType.ENUM,
  },
  [RooftopPricingSegment.TARGETS]: {
    label: 'target_other',
    type: FieldDataType.BADGE_LIST,
  },
};

const RooftopLeasePricingSegmentConfig: TableSettingsType = {
  ...RooftopPricingSegmentConfig,
  [RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES]: {
    label: 'available_payment_period_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY]: {
    label: 'default_payment_period',
    type: FieldDataType.ENUM,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_TERMS]: {
    label: 'available_term_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES]: {
    label: 'available_mileage_allowance_other',
    type: FieldDataType.CUSTOM,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM]: { label: 'default_term', type: FieldDataType.ENUM },
  [RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD_NAME]: {
    label: 'starting_lease_price',
    type: FieldDataType.DEFAULT,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE]: {
    label: 'default_mileage_allowance',
    type: FieldDataType.FORMATTED_AMOUNT,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE_NAME]: {
    label: 'default_mileage_allowance',
    type: FieldDataType.ENUM,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE_NAME]: {
    label: 'interest_rate_type',
    type: FieldDataType.ENUM,
  },
  [RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE_NAME]: {
    label: 'default_term',
    type: FieldDataType.ENUM,
  },
  [RooftopPricingSegment.TARGETS]: {
    label: 'target_other',
    type: FieldDataType.BADGE_LIST,
  },
};

export const RooftopPricingSegmentSettings = translateFields(RooftopPricingSegmentConfig);
export const RooftopFinancePricingSegmentSettings = translateFields(RooftopFinancePricingSegmentConfig);
export const RooftopLeasePricingSegmentSettings = translateFields(RooftopLeasePricingSegmentConfig);
