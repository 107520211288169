import { DefaultPrivacyLevel } from '@datadog/browser-rum';

import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { MaskInputSettings } from 'components/ui/forms/shared/MaskedInput';
import { Employment, Lead } from 'enums/columns/lead';
import { StepFieldType } from 'enums/stepFieldType';
import { EmploymentInputParameter } from 'store/api/graph/interfaces/types';

import { LeadEmploymentInformationBuilderFields } from './interfaces';

const LeadEmploymentInformationFields: StepFields = {
  [LeadEmploymentInformationBuilderFields.TYPE]: {
    label: 'type',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.TYPE}`,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.employment.type',
    clear: { field: EmploymentInputParameter._type, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.STATUS]: {
    label: 'status',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.STATUS}`,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.employment.status',
    clear: { field: EmploymentInputParameter._status, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.EMPLOYER]: {
    label: 'employer',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.EMPLOYER}`,
    clear: { field: EmploymentInputParameter._employer, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.OCCUPATION]: {
    label: 'occupation',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.OCCUPATION}`,
    clear: { field: EmploymentInputParameter._occupation, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.SIN]: {
    label: 'sin',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.SIN}`,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    groupType: StepFieldType.MASKED_INPUT,
    settings: {
      disableInputWhileMasked: true,
    } as MaskInputSettings,
  },
  [LeadEmploymentInformationBuilderFields.DURATION_YEARS]: {
    label: 'duration_years',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.DURATION_YEARS}`,
    groupType: StepFieldType.NUMBER,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [LeadEmploymentInformationBuilderFields.DURATION_MONTHS]: {
    label: 'duration_months',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.DURATION_MONTHS}`,
    groupType: StepFieldType.NUMBER,
  },
  [LeadEmploymentInformationBuilderFields.PHONE_NUMBER]: {
    label: 'work_phone',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.WORK_PHONE}`,
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: EmploymentInputParameter._phoneNumber, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.MONTHLY_INCOME]: {
    label: 'monthly_income',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.MONTHLY_INCOME}`,
    groupType: StepFieldType.CURRENCY,
    clear: { field: EmploymentInputParameter._monthlyIncome, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.OTHER_INCOME]: {
    label: 'other_income',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.OTHER_INCOME}`,
    groupType: StepFieldType.CURRENCY,
    clear: { field: EmploymentInputParameter._otherIncome, target: Lead.EMPLOYMENT },
  },
  [LeadEmploymentInformationBuilderFields.OTHER_INCOME_SOURCE]: {
    label: 'other_income_source',
    queryAlias: `${Lead.EMPLOYMENT}.${Employment.OTHER_INCOME_SOURCE}`,
    clear: { field: EmploymentInputParameter._otherIncomeSource, target: Lead.EMPLOYMENT },
  },
};

export default LeadEmploymentInformationFields;
