import styled, { css } from 'styled-components/macro';

import { BODY_TEXT } from 'styles/color';
import { FONT_SIZE_14, FONT_WEIGHT_NORMAL, LETTER_SPACING_DENSE, LINE_HEIGHT_CONDENSED_ULTRA } from 'styles/typography';

const ellipsisCss = css`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextCSS = css`
  color: ${BODY_TEXT};
  font-size: ${FONT_SIZE_14};
  font-weight: ${FONT_WEIGHT_NORMAL};
  letter-spacing: ${LETTER_SPACING_DENSE};
  line-height: ${LINE_HEIGHT_CONDENSED_ULTRA};
  position: relative;
  ${ellipsisCss}

  &:empty {
    display: none;
  }
`;

// TODO: When element values in a Text component are truncated, we'll need a tooltip to show the full value on hover
const Text = styled.div`
  ${TextCSS}
`;

export default Text;
