import { memo, type SVGProps } from 'react';

const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M5.072 22.203c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V8.695a2.353 2.353 0 0 1-.728-.787 2.13 2.13 0 0 1-.272-1.076v-2.76c0-.63.222-1.167.665-1.61.443-.444.98-.665 1.61-.665h15.856c.63 0 1.166.222 1.61.665.444.443.665.98.665 1.61v2.76c0 .4-.09.758-.272 1.076-.181.317-.424.58-.728.787v11.233c0 .63-.221 1.166-.665 1.61-.444.444-.98.665-1.61.665H5.072Zm-1-15.37h15.862V4.071H4.072v2.76Zm5.904 7.31h4.054c.303 0 .559-.103.766-.311.208-.208.312-.463.312-.766 0-.304-.104-.559-.312-.767a1.041 1.041 0 0 0-.766-.311H9.976c-.303 0-.559.104-.766.312a1.041 1.041 0 0 0-.312.766c0 .303.104.558.312.766.207.208.463.311.766.311Z"
    />
  </svg>
);
export default memo(ArchiveIcon);
