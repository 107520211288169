import { gql } from '@apollo/client';

import { rooftopIntegrationDetails } from 'store/api/graph/fragments/rooftopIntegrationDetails';

export const rooftopIntegrationOemAccountExportCreate = gql`
  mutation RooftopIntegrationOemAccountExportCreate(
    $rooftopId: ID
    $integrationId: ID
    $oemAccountExportAttributes: OemAccountExportRooftopIntegrationAttributesInput
  ) {
    data: rooftopIntegrationCreate(
      rooftopId: $rooftopId
      integrationId: $integrationId
      oemAccountExportAttributes: $oemAccountExportAttributes
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;

export const rooftopIntegrationOemAccountExportModify = gql`
  mutation RooftopIntegrationOemAccountExportModify(
    $id: ID!
    $active: Boolean
    $oemAccountExportAttributes: OemAccountExportRooftopIntegrationAttributesInput
    $_clear: [RooftopIntegrationModifyParameter!]
  ) {
    data: rooftopIntegrationModify(
      id: $id
      active: $active
      oemAccountExportAttributes: $oemAccountExportAttributes
      _clear: $_clear
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;
