import {
  retailBulkAdjustmentCreate,
  retailBulkAdjustmentModify,
} from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentCreateModifyQuery';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';

class RetailBulkAdjustmentFiltersStep extends RetailFilterStep {
  async save(): Promise<boolean> {
    const {
      tier: { isCreating },
    } = this.props;

    return super.save(isCreating ? retailBulkAdjustmentCreate : retailBulkAdjustmentModify);
  }
}

export default RetailBulkAdjustmentFiltersStep;
