import { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import type Facet from 'components/ui/filters/interfaces/facet';
import type FilterGroup from 'components/ui/filters/interfaces/filterGroup';
import Checkbox from 'components/ui/forms/shared/Checkbox';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import ArrowLeftIcon from 'components/ui/icons/ArrowLeftIcon';
import Badge, { BadgeColors, BadgeSizes } from 'components/ui/shared/Badge';
import { Clickable, CTAButton } from 'components/ui/shared/Button';
import Scrollable from 'components/ui/shared/Scrollable';
import { useSearch } from 'hooks/contexts/useSearch';
import { FacetSearchMode } from 'store/api/graph/interfaces/types';
import { DIVIDER } from 'styles/color';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_800 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import { FacetContainer, FilterSearchSection, getNewFacetFilterValue, SelectedFilterSectionHeader } from './common';

const FacetCheckboxText = styled.div`
  display: flex;

  /* Checkbox */
  > :first-child {
    margin-right: 17px;
  }

  /* Label */
  ${Text} {
    max-width: 205px;
  }
`;

interface FacetListItemProps {
  facet: any;
  filter: any;
  /**
   * Custom behaviour when list item is clicked
   * e.g. Autoclose list selection for single select facetFilters
   */
  onClick?: () => void;
}

const FacetListItem = ({ facet, filter, onClick }: FacetListItemProps) => {
  const { getSearchParamAsArray, updateSearchParam } = useSearch();
  const selectedFacetIds = getSearchParamAsArray(filter.id);
  const selected = selectedFacetIds.includes(facet.id);
  return (
    <FacetContainer>
      <FacetCheckboxText>
        <Checkbox
          checked={selected}
          round={!filter.multiSelect}
          onChange={() => {
            updateSearchParam(
              filter.id,
              getNewFacetFilterValue({
                selectedFacetIds,
                multiSelect: filter.multiSelect,
                facetId: facet.id,
                selected: !selected,
              })
            );
            onClick?.();
          }}
        />
        <Text>{facet.name}</Text>
      </FacetCheckboxText>
      <Badge
        title={`${facet.name}: ${facet.count}`}
        size={BadgeSizes.LARGE}
        color={selected ? BadgeColors.POSITIVE : BadgeColors.DEFAULT}
      >
        {facet.count}
      </Badge>
    </FacetContainer>
  );
};

const FacetParent = styled.div`
  height: ${LIST_ITEM_HEIGHT};
  background-color: ${NEUTRAL_050};
  display: flex;
  align-items: center;
  padding-left: 18px;
  border-bottom: 1px solid ${DIVIDER};
`;

interface FacetFilterProps {
  filter: FilterGroup;
  facetSearchQuery: string;
  /** Handler to close facetFilter selection list i.e. go back */
  onClose: () => void;
}

const FacetFilter = ({ filter, facetSearchQuery, onClose }: FacetFilterProps) => {
  const listItemOnClickAutoCloseHandler = filter.multiSelect ? undefined : onClose;

  const facetsByParent = useMemo(
    () =>
      Object.entries(
        filter.facets
          // We only want the facets that match the current search query, since match uses regex we sanitize the input
          .filter(facet => facet.name.toLowerCase().includes(facetSearchQuery.toLowerCase()))
          // Only select facets that have a parent
          .filter(facet => filter.hasParent && facet.parent)
          // Group facets by their common parent
          .reduce((groups, facet) => {
            groups[facet.parent!] = (groups[facet.parent!] || []).concat(facet);
            return groups;
          }, {}) as { [key: string]: Facet[] }
      )
        // Create facet elements, group these elements by their parent and display the parent name in a header label
        .map(([parent, facets]) => (
          <div key={parent}>
            <FacetParent>
              <Label>{parent}</Label>
            </FacetParent>
            {facets.map(facet => (
              <FacetListItem key={facet.id} facet={facet} filter={filter} onClick={listItemOnClickAutoCloseHandler} />
            ))}
          </div>
        )),
    [facetSearchQuery, filter, listItemOnClickAutoCloseHandler]
  );

  const facetsWithNoParent = useMemo(
    () =>
      filter.facets
        // We only want the facets that match the current search query, sanitize the input for regex
        .filter(facet => facet.name.toLowerCase().includes(facetSearchQuery.toLowerCase()))
        // Only take facets that have no parent, or if the entire FilterGroup is configured to have no parents
        .filter(facet => (filter.hasParent && !facet.parent) || !filter.hasParent)
        // Create facet elements ignoring the need to have any header label
        .map((facet: Facet) => (
          <FacetListItem key={facet.id} facet={facet} filter={filter} onClick={listItemOnClickAutoCloseHandler} />
        )),
    [facetSearchQuery, filter, listItemOnClickAutoCloseHandler]
  );

  return (
    <>
      {facetsWithNoParent}
      {facetsByParent}
    </>
  );
};

export default FacetFilter;

const SelectedFacetFilterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DoneButton = styled(CTAButton)`
  background: ${NEUTRAL_800};
`;

const DoneButtonContainer = styled.div`
  background: ${NEUTRAL_0};
  padding: 15px;
`;

interface Props {
  filter: FilterGroup;
  onClose: () => void;
}

export const SelectedFacetFilter = ({ filter, onClose }: Props) => {
  const [facetSearchQuery, setFacetSearchQuery] = useState('');
  const { updateSearchParam, searchParams } = useSearch();

  const onSearch = useCallback(
    query => {
      // Filtering based off of the server
      if (filter.searchMode === FacetSearchMode.SERVER) {
        updateSearchParam('searchFilter', { id: filter.id, keyword: query });
      } else {
        // Default to client-side filtering
        setFacetSearchQuery(query);
      }
    },
    [filter.id, filter.searchMode, updateSearchParam]
  );

  return (
    <SelectedFacetFilterContainer>
      <SelectedFilterSectionHeader>
        <Clickable onClick={onClose}>
          <ArrowLeftIcon />
        </Clickable>
        <Label>{filter.name}</Label>
      </SelectedFilterSectionHeader>
      <FilterSearchSection>
        <SearchInput
          autoFocus
          placeholder={translate.t('search_x', [filter.name])}
          defaultValue={searchParams.searchFilter?.keyword}
          onChange={onSearch}
        />
      </FilterSearchSection>
      <Scrollable>
        <FacetFilter filter={filter} facetSearchQuery={facetSearchQuery} onClose={onClose} />
      </Scrollable>
      {filter.multiSelect && (
        <DoneButtonContainer>
          <DoneButton onClick={onClose}>{translate.t('done')}</DoneButton>
        </DoneButtonContainer>
      )}
    </SelectedFacetFilterContainer>
  );
};
