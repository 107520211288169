import { useCallback, useEffect, useRef } from 'react';

import { debounce } from 'lodash-es';

import { DEFAULT_SEARCH_DEBOUNCE } from 'utils/timeUtils';

/**
 * Keyword search hook
 * - debounces keyword search callback - based on timeout interval (defaults to 250)
 */
const useDebounceKeywordSearch = (
  text: string,
  onChange: (value: string) => void,
  timeout = DEFAULT_SEARCH_DEBOUNCE
) => {
  const onChangeDebounce = useCallback(keyword => onChange(keyword.trim()), [onChange]);
  const debouncedKeywords = useRef(debounce(onChangeDebounce, timeout));
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    return debouncedKeywords.current(text);
  }, [text]);
};

export default useDebounceKeywordSearch;
