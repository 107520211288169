import Badge, { BadgeSizes } from 'components/ui/shared/Badge';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export type RouteFilterGroup = {
  /**
   *  A label corresponding to a filters field to display as a prefix
   *  with the filter value (e.g Rooftop: Beaverton Buicks)
   */
  label: string;
  /**  A list of filter values corresponding to a filters field  */
  filterValues: string[];
};

interface Props {
  filters: RouteFilterGroup[];
}

export const WebsiteRouteFilterBadges = ({ filters }: Props) =>
  filters.map(filter =>
    filter.filterValues.map(stringValue => (
      <Badge title={`${t(filter.label)}: ${stringValue}`} size={BadgeSizes.LARGE} key={stringValue}>
        {`${t(filter.label)}: ${stringValue}`}
      </Badge>
    ))
  );
