type OnLoaded = (map: any) => void;

const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS}&callback=mapsApiLoaded&libraries=marker`;

let apiLoading = false;
let apiLoaded = false;
const onLoadedFns: OnLoaded[] = [];

export const isApiLoaded = () => apiLoaded;

/**
 * Load Google Maps API and get notified when the api is loaded.
 *
 * @param {function} fn callback function
 */
export const onMapsApiLoaded = (fn: OnLoaded) => {
  if (apiLoaded) {
    fn(window['google'].maps);
  } else {
    onLoadedFns.push(fn);

    if (!apiLoading) {
      apiLoading = true;

      ((d, s) => {
        window['mapsApiLoaded'] = () => {
          for (const _fn of onLoadedFns) {
            _fn(window['google'].maps);
          }
          onLoadedFns.splice(0);
          apiLoaded = true;
        };
        // eslint-disable-next-line unicorn/prefer-query-selector
        const fjs = d.getElementsByTagName(s)[0];
        const js = d.createElement(s);
        js['src'] = GOOGLE_MAPS_API_URL;
        fjs['parentNode']?.insertBefore(js, fjs);
      })(document, 'script');
    }
  }
};
