import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableSubType, TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { Lead } from 'enums/columns/lead';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import { useQuery } from 'hooks/useQuery';
import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { creditApplication } from 'store/api/graph/fragments/creditApplication';
import { filters } from 'store/api/graph/fragments/filters';
import { leadActivityList } from 'store/api/graph/fragments/leadActivityList';
import { leadAndLeadActivityList } from 'store/api/graph/fragments/leadAndLeadActivityList';
import { leadDetail } from 'store/api/graph/fragments/leadDetail';
import { listPhoto } from 'store/api/graph/fragments/listPhoto';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { selectStringOption } from 'store/api/graph/fragments/selectOption';
import { tag } from 'store/api/graph/fragments/tag';
import { ymmt } from 'store/api/graph/fragments/YMMT';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const leadDetailsQuery = gql`
  query LeadDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: lead(id: $id) {
      ...LeadDetailFragment

      leadActivities {
        ...LeadActivityListFragment
        note
      }

      retailItemsOfInterest {
        id
        archived
        ...YMMTFragment
        leadsCount
        leadActivitiesCount
        testDrivesCount: leadActivitiesCount(type: TEST_DRIVE)
        rooftop {
          id
        }
        status
        primaryPhoto {
          ...ListPhotoFragment
        }
      }

      tradeInItems {
        id
        archived
        vin
        primaryPhoto {
          ...ListPhotoFragment
        }
        ...YMMTFragment
        appraisals {
          averageAppraisalAmount {
            formattedAmountRounded
          }
        }

        motorcycleAttributes: attributes {
          ... on MotorcycleAttributes {
            color
            cylinders
            fuelType
            displacement {
              formattedAmount
            }
          }
        }

        vehicleAttributes: attributes {
          ... on VehicleAttributes {
            exteriorColor
            transmission
            cylinders
            fuelType
            mileage {
              formattedAmount
            }
            displacement {
              formattedAmount
            }
          }
        }
      }
    }

    metadata {
      ...ColorMetadataFragment
    }
  }
  ${ymmt}
  ${colorMetadata}
  ${leadDetail}
  ${leadActivityList}
  ${listPhoto}
`;

export const leadCondensedListQuery = gql`
  query LeadCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: LeadConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: leadConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...LeadAndLeadActivityListFragment
        }
      }
    }
    metadata {
      mutation {
        item: lead {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${pageInfo}
  ${leadAndLeadActivityList}
  ${selectStringOption}
`;

export const leadCreditApplicationsQuery = gql`
  query LeadCreditApplicationsQuery($leadId: ID!) {
    connection: lead(id: $leadId) {
      creditApplications {
        ...CreditApplicationFragment
      }
    }
  }
  ${creditApplication}
`;

export const leadSINDecodeQuery = gql`
  query LeadSINDecodeQuery($leadId: ID!) {
    lead(id: $leadId) {
      sin(decrypt: true)
    }
  }
`;

export const leadContainerQuery = gql`
  query LeadConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: LeadConnectionFilterInput
    $d_rooftopNameOn: Boolean!
    $d_groupNameOn: Boolean!
    $d_emailOn: Boolean!
    $d_paymentOptionsNamesOn: Boolean!
    $d_assignedToNameOn: Boolean!
    $d_retailItemsOfInterestCountOn: Boolean!
    $d_tagNameOn: Boolean!
    $d_tradeInItemsCountOn: Boolean!
    $d_appointmentsCountOn: Boolean!
    $d_subscribedEmailOn: Boolean!
    $d_subscribedPromotionsOn: Boolean!
    $d_createdByNameOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: leadConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...LeadAndLeadActivityListFragment
          groupName: rooftop @include(if: $d_groupNameOn) {
            id
            group {
              id
              name {
                value
              }
            }
          }
          rooftopName: rooftop @include(if: $d_rooftopNameOn) {
            id
            name {
              value
            }
          }
          email @include(if: $d_emailOn)
          paymentOptionsNames @include(if: $d_paymentOptionsNamesOn)
          assignedToName: assignedTo @include(if: $d_assignedToNameOn) {
            id
            firstName
            lastName
          }
          tagName: tags @include(if: $d_tagNameOn) {
            ...TagFragment
          }
          retailItemsOfInterestCount @include(if: $d_retailItemsOfInterestCountOn)
          tradeInItemsCount @include(if: $d_tradeInItemsCountOn)
          appointmentsCount @include(if: $d_appointmentsCountOn)
          subscribedEmail @include(if: $d_subscribedEmailOn)
          subscribedPromotions @include(if: $d_subscribedPromotionsOn)
          createdBy @include(if: $d_createdByNameOn) {
            id
            firstName
            lastName
          }
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
        }
      }
    }
    metadata {
      mutation {
        item: lead {
          status {
            ...SelectStringOptionFragment
          }
          employment {
            status {
              ...SelectStringOptionFragment
            }
            type {
              ...SelectStringOptionFragment
            }
          }
          homeStatus {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${tag}
  ${selectStringOption}
  ${leadAndLeadActivityList}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  Lead.LAST_ACTIVE,
  Lead.FIRST_NAME,
  Lead.LAST_NAME,
  Lead.STATUS,
  Lead.CONVERSATIONS_COUNT,
  Lead.PHONE_NUMBER,
  Lead.WALK_IN_COUNT,
  Lead.TEST_DRIVE_COUNT,
] as string[];

export const useLeadConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const { isWhiteLabelScoped, canAccessMultipleRooftops } = useUser();
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(
    TableType.LEADS,
    isWhiteLabelScoped || canAccessMultipleRooftops ? TableSubType.ALL : TableSubType.LIMITED
  );

  const formattedTableColumns = gqlFormatTableColumnFields(leadContainerQuery, tableConfigurationNext, staticColumns);

  return useQuery(leadContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};
