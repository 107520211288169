import { useCallback } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import ExternalLinkIcon from 'components/ui/icons/ExternalLinkIcon';
import QuestionMarkIcon from 'components/ui/icons/QuestionMarkIcon';
import MenuButton, { MenuButtonType, MenuItem, MenuPosition } from 'components/ui/menus/MenuButton';
import { ContactEmails } from 'enums/contactEmails';
import { ResourceCentreUrls } from 'enums/resourceCenterUrls';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT_TERTIARY } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_700 } from 'styles/tokens';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { isFrCA, translate } from 'utils/intlUtils';

import { MultiNavLinkSubItemCSS, MultiNavLinkSubItemsContainerCSS, NavActionItem } from '../shared/CommonNavItems';

const { t } = translate;

const FeedbackNavActionSubItem = styled.div`
  ${MultiNavLinkSubItemCSS}
`;

const FeedbackNavActionSubContainer = styled.div`
  ${MultiNavLinkSubItemsContainerCSS}
`;

const FeedbackNavAction = styled(NavActionItem)<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    background: ${isOpen ? BLUE_500 : NEUTRAL_700};
  `}

  &:hover ${FeedbackNavActionSubContainer} {
    display: block;
  }
`;

export const getContactEmail = () => (isFrCA() ? ContactEmails.FRENCH_SUPPORT : ContactEmails.ENGLISH_SUPPORT);

const email = getContactEmail();

export const getKnowledgeBaseURL = () =>
  isFrCA() ? ResourceCentreUrls.FRENCH_KNOWLEDGE_BASE : ResourceCentreUrls.ENGLISH_KNOWLEDGE_BASE;

const KNOWLEDGE_BASE_URL = getKnowledgeBaseURL();

interface FeedbackMenuNavItemProps {
  title?: string;
}

const ExternalLinkMenuItemIcon = styled(ExternalLinkIcon)`
  margin-left: 12px;
`;

const FeedbackMenuNavItem = ({ title }: FeedbackMenuNavItemProps) => {
  const flags = useFlags();

  const customIcon = useCallback(
    (isOpen: boolean) => (
      <FeedbackNavAction isOpen={isOpen} data-testid={ElementTestId.FEEDBACK_MENU_BUTTON}>
        {!isOpen && (
          <FeedbackNavActionSubContainer>
            <FeedbackNavActionSubItem>
              <Label>{title}</Label>
            </FeedbackNavActionSubItem>
          </FeedbackNavActionSubContainer>
        )}
        <QuestionMarkIcon color={isOpen ? NEUTRAL_0 : BODY_TEXT_TERTIARY} />
      </FeedbackNavAction>
    ),
    [title]
  );

  return (
    <MenuButton type={MenuButtonType.CUSTOM} position={MenuPosition.BOTTOM_LEFT} customIcon={customIcon}>
      <MenuItem label={t('contact_us')} onClick={() => (window.location.href = `mailto:${email}`)} icon={null} />
      <MenuItem
        label={t('give_feedback')}
        onClick={() => (window.location.href = `mailto:${ContactEmails.FEEDBACK}`)}
        icon={null}
      />
      {flags[LDFeatureFlags.resourceCentreEnabled] && (
        <MenuItem
          label={t('knowledge_base')}
          onClick={() => window.open(KNOWLEDGE_BASE_URL, '_blank')}
          icon={<ExternalLinkMenuItemIcon />}
        />
      )}
    </MenuButton>
  );
};

export default FeedbackMenuNavItem;
