import { gql } from '@apollo/client';

import { mileage } from 'store/api/graph/fragments/mileage';

export const motorcycleAttributes = gql`
  fragment MotorcycleAttributesFragment on MotorcycleAttributes {
    mileage {
      ...MileageFragment
    }
    category
    categoryName
    color
    colorName
    displacement {
      amount
      formattedAmount
    }
    cylinders
    fuelType
    fuelTypeName
  }

  ${mileage}
`;
