import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const selectStringOption = gql`
  fragment SelectStringOptionFragment on SelectStringOption {
    id: value
    name
    data
  }
`;

export const selectIntOption = gql`
  fragment SelectIntOptionFragment on SelectIntOption {
    id: value
    name
    data
  }
`;

export const selectMultilingualStringOption = gql`
  fragment SelectMultilingualStringOptionFragment on SelectMultilingualStringOption {
    id: name
    name
    data
    value {
      ...MultilingualStringFragment
    }
  }
  ${MultilingualString}
`;
