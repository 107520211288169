import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { rooftop } from 'store/api/graph/fragments/rooftop';

export const leadForwardDetail = gql`
  fragment LeadForwardDetailFragment on LeadForward {
    id
    rooftopName: rooftop {
      ...RooftopFragment
    }
    adf
    email
    sourceNames: sources {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${rooftop}
  ${MultilingualString}
`;
