import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { LocationInputFields } from 'components/sections/createModify/rooftops/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import LocationListItem from 'components/ui/lists/LocationListItem';
import { BuilderType } from 'enums/builderType';
import { Website } from 'enums/columns/website';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { WebsiteModifyParameter } from 'store/api/graph/interfaces/types';

import { WebsiteDetailsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [WebsiteDetailsBuilderFields.GROUP_ID]: {
    label: 'group',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
    subStepAddConfig: SubStepAddConfigs[BuilderType.GROUP_CREATE],
  },
  [WebsiteDetailsBuilderFields.PRIMARY_ROOFTOP_ID]: {
    label: 'primary_rooftop',
    queryAlias: [Website.PRIMARY_ROOFTOP_NAME],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
    clear: { field: WebsiteModifyParameter._primaryRooftopId },
  },
  [WebsiteDetailsBuilderFields.STATUS]: {
    label: 'status',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.website.status',
    hasSeparator: true,
    clear: { field: WebsiteModifyParameter._status },
  },
  [WebsiteDetailsBuilderFields.NAME]: {
    label: 'name',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: WebsiteModifyParameter._name },
  },
  [WebsiteDetailsBuilderFields.URL]: {
    label: 'url_one',
    clear: { field: WebsiteModifyParameter._url },
  },
  [WebsiteDetailsBuilderFields.GA4_PROPERTY_ID]: {
    label: 'ga4_property_id',
    groupType: StepFieldType.NUMBER,
    clear: { field: WebsiteModifyParameter._ga4PropertyId },
    settings: {
      maxLength: 9,
    },
  },
  [WebsiteDetailsBuilderFields.GOOGLE_ADS_ID]: {
    label: 'google_ads_id',
    hasSeparator: true,
    clear: { field: WebsiteModifyParameter._googleAdsId },
  },
  [WebsiteDetailsBuilderFields.LOCALES]: {
    label: 'supported_locales',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    required: true,
    options: 'mutation.website.locale',
  },
  [WebsiteDetailsBuilderFields.PRIMARY_LOCALE]: {
    label: 'primary_locale',
    groupType: StepFieldType.DROPDOWN,
    hasSeparator: true,
    subStep: [SubStepType.ASYNC],
    required: true,
    clear: { field: WebsiteModifyParameter._primaryLocale },
  },
  [WebsiteDetailsBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    groupType: StepFieldType.PHONE_NUMBER,
    hasSeparator: true,
    clear: { field: WebsiteModifyParameter._phoneNumber },
  },
  [WebsiteDetailsBuilderFields.LOCATION]: {
    label: 'address',
    queryAlias: [
      LocationInputFields.CITY,
      LocationInputFields.REGION_CODE,
      LocationInputFields.COUNTRY_CODE,
      LocationInputFields.ADDRESS,
      LocationInputFields.ZIP_CODE,
    ],
    groupType: StepFieldType.RENDER_OBJECT,
    subStep: [SubStepType.CUSTOM],
    renderElement: LocationListItem,
    hasSeparator: true,
    clear: { field: WebsiteModifyParameter._location },
  },
};

export default DetailFields;
