import { gql } from '@apollo/client';

import { leadDetail } from 'store/api/graph/fragments/leadDetail';

export const leadCreate = gql`
  mutation LeadCreateMutation(
    $rooftopId: ID
    $address: LocationInput
    $assignedToId: ID
    $avatarImage: Upload
    $birthdate: Date
    $company: String
    $contactMethod: LeadContactMethod
    $contactTime: LeadContactTime
    $cosigner: Boolean
    $currentAddressMonths: Int
    $declaredBankruptcy: Boolean
    $driversLicenseNumber: String
    $email: String
    $employment: EmploymentInput
    $firstName: String
    $gender: Gender
    $homeStatus: HomeStatus
    $note: String
    $lastName: String
    $locale: String
    $maritalStatus: MaritalStatus
    $paymentOptions: [PaymentOption!]
    $phoneNumber: String
    $previousAddress: LocationInput
    $previousAddressMonths: Int
    $previousEmployment: EmploymentInput
    $salutation: Salutation
    $sin: String
    $subscribedEmail: Boolean
    $subscribedPromotions: Boolean
    $tagIds: [ID!]
    $_submit: Boolean! = true
  ) {
    data: leadCreate(
      rooftopId: $rooftopId
      address: $address
      assignedToId: $assignedToId
      avatarImage: $avatarImage
      birthdate: $birthdate
      company: $company
      contactMethod: $contactMethod
      contactTime: $contactTime
      cosigner: $cosigner
      currentAddressMonths: $currentAddressMonths
      declaredBankruptcy: $declaredBankruptcy
      driversLicenseNumber: $driversLicenseNumber
      email: $email
      employment: $employment
      firstName: $firstName
      gender: $gender
      homeStatus: $homeStatus
      note: $note
      lastName: $lastName
      locale: $locale
      maritalStatus: $maritalStatus
      paymentOptions: $paymentOptions
      phoneNumber: $phoneNumber
      previousAddress: $previousAddress
      previousAddressMonths: $previousAddressMonths
      previousEmployment: $previousEmployment
      salutation: $salutation
      sin: $sin
      subscribedEmail: $subscribedEmail
      subscribedPromotions: $subscribedPromotions
      tagIds: $tagIds
      _submit: $_submit
    ) {
      ...LeadDetailFragment
    }
  }
  ${leadDetail}
`;

export const leadModify = gql`
  mutation LeadModifyMutation(
    $id: ID!
    $address: LocationInput
    $archived: Boolean
    $assignedToId: ID
    $avatarImage: Upload
    $birthdate: Date
    $company: String
    $contactMethod: LeadContactMethod
    $contactTime: LeadContactTime
    $cosigner: Boolean
    $currentAddressMonths: Int
    $declaredBankruptcy: Boolean
    $driversLicenseNumber: String
    $email: String
    $employment: EmploymentInput
    $firstName: String
    $gender: Gender
    $homeStatus: HomeStatus
    $note: String
    $lastName: String
    $locale: String
    $maritalStatus: MaritalStatus
    $paymentOptions: [PaymentOption!]
    $phoneNumber: String
    $previousAddress: LocationInput
    $previousAddressMonths: Int
    $previousEmployment: EmploymentInput
    $salutation: Salutation
    $sin: String
    $spam: Boolean
    $status: LeadStatus
    $subscribedEmail: Boolean
    $subscribedPromotions: Boolean
    $tagIds: [ID!]
    $_clear: [LeadModifyParameter!]
  ) {
    data: leadModify(
      id: $id
      address: $address
      archived: $archived
      assignedToId: $assignedToId
      avatarImage: $avatarImage
      birthdate: $birthdate
      company: $company
      contactMethod: $contactMethod
      contactTime: $contactTime
      cosigner: $cosigner
      currentAddressMonths: $currentAddressMonths
      declaredBankruptcy: $declaredBankruptcy
      driversLicenseNumber: $driversLicenseNumber
      email: $email
      employment: $employment
      firstName: $firstName
      gender: $gender
      homeStatus: $homeStatus
      note: $note
      lastName: $lastName
      locale: $locale
      maritalStatus: $maritalStatus
      paymentOptions: $paymentOptions
      phoneNumber: $phoneNumber
      previousAddress: $previousAddress
      previousAddressMonths: $previousAddressMonths
      previousEmployment: $previousEmployment
      salutation: $salutation
      sin: $sin
      spam: $spam
      status: $status
      subscribedEmail: $subscribedEmail
      subscribedPromotions: $subscribedPromotions
      tagIds: $tagIds
      _clear: $_clear
    ) {
      ...LeadDetailFragment
    }
  }
  ${leadDetail}
`;
