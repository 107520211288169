import parseUserAgent from 'ua-parser-js';

import { stripNonAscii } from './stringUtils';

/**
 * Unique identifier for the current build
 */
export const getReleaseIdentifier = () =>
  stripNonAscii(`${process.env.REACT_APP_BUILD_BRANCH}_${process.env.REACT_APP_BUILD_COMMIT_SHORT}`).toLowerCase();

export const parsedUserAgent = parseUserAgent(window.navigator.userAgent);

/**
 * Is the application running within Electron?
 *
 */
export const isDesktopApp = !!/Electron/.test(window.navigator.userAgent);

/**
 * Device and environment headers passed in each request
 * Note: Must be a getter so that initialization is deferred until runtime.
 */
export const getDeviceInfoHeaders = () =>
  ({
    'Device-Type': isDesktopApp ? 'desktop' : 'web',
    'Device-Name': stripNonAscii(`${parsedUserAgent.browser.name} (${parsedUserAgent.browser.major})`),
    'App-Version': getReleaseIdentifier(),
  }) as Readonly<{
    'Device-Type': string;
    'Device-Name': string;
    'App-Version': string;
  }>;

/**
 * Simple regex to determine whether or not the user is on a recognized mobile device
 */
export const isMobileDevice = (): boolean =>
  /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator?.userAgent);

/**
 * Simple regex to determine whether or not the user is on an IE browser
 */
export const isIE = (): boolean => !!/msie\s|trident\//i.test(window.navigator?.userAgent);
