import { gql } from '@apollo/client';

import { leadList } from 'store/api/graph/fragments/leadList';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';
import { rooftopList } from 'store/api/graph/fragments/rooftopList';
import { tradeInItemList } from 'store/api/graph/fragments/tradeInItemList';

import { appointment } from './appointment';
import { avatar } from './avatar';
import { tag } from './tag';
import { userList } from './userList';

export const appointmentDetail = gql`
  fragment AppointmentDetailFragment on Appointment {
    ## Details tab
    ...AppointmentFragment
    tagName: tags {
      ...TagFragment
    }
    rooftopName: rooftop {
      ...RooftopListFragment
    }
    assignedToName: assignedTo {
      ...UserListFragment
    }
    createdByName: createdBy {
      ...UserListFragment
    }
    created
    updated
    location

    ## Linked tab
    leadName: lead {
      ...LeadListFragment
    }

    inventoryItemName: inventoryItem {
      ...RetailItemListFragment
      ...TradeInItemListFragment
    }
  }
  ${tag}
  ${userList}
  ${appointment}
  ${leadList}
  ${avatar}
  ${tradeInItemList}
  ${retailItemList}
  ${rooftopList}
`;
