import { memo, type SVGProps } from 'react';

const TrashCanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M6.934 21.203c-.63 0-1.166-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V6.066a1.1 1.1 0 0 1-.808-.33 1.1 1.1 0 0 1-.33-.808c0-.319.11-.588.33-.808.22-.22.49-.33.808-.33h4.203c0-.318.11-.588.33-.808.22-.219.489-.329.808-.329h3.988c.32 0 .589.11.808.33.22.22.33.489.33.808h4.215c.319 0 .588.11.808.329.22.22.33.49.33.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33v12.862c0 .63-.222 1.166-.666 1.61-.443.444-.98.665-1.61.665H6.936ZM17.066 6.066H6.934v12.862h10.132V6.066ZM9.964 16.994c.3 0 .552-.103.758-.308a1.03 1.03 0 0 0 .308-.758V9.06c0-.3-.103-.552-.308-.758a1.03 1.03 0 0 0-.758-.308c-.3 0-.553.103-.76.308a1.024 1.024 0 0 0-.312.758v6.868c0 .3.104.552.312.758.207.205.46.308.76.308Zm4.078 0c.3 0 .552-.103.757-.308a1.03 1.03 0 0 0 .309-.758V9.06c0-.3-.103-.552-.309-.758a1.03 1.03 0 0 0-.757-.308c-.3 0-.553.103-.76.308a1.024 1.024 0 0 0-.312.758v6.868c0 .3.104.552.311.758.208.205.462.308.76.308Z"
    />
  </svg>
);

export default memo(TrashCanIcon);
