import { cloneDeep, pickBy } from 'lodash-es';
import qs from 'qs';

import type { SortDirection } from 'store/api/graph/interfaces/types';

/**
 * Url params that need to always be strings.
 * These are always top-level of an `xItemConnection`.
 * Our parser automatically casts Int to String, however there are exceptions.
 */
const STRING_URL_PARAMS = new Set([
  // Graphql relay spec needs these to be strings
  'before',
  'after',
  // Need to always be string even if a user enters a number to search
  'keyword',
]);

/**
 * These are specific url params that are used to deeplink into certain components. Most url params are used for
 * searching/filtering purposes, the exception being these.
 */
enum DeeplinkUrlParam {
  NESTED_VIEW = 'nestedView',
  COLUMNS = 'columns',
}

// Specific url param used to configure which table columns should be enabled
export const TABLE_COLUMNS_URL_PARAM = 'columns';

/**
 * Given a url string, this util will parse the url string and return an object where
 * the key corresponds to a given DeeplinkUrlParam, and the value is the value found in the url string. Ex.
 * ?test[id]=1 will return { test: { id: '1' } }.
 *
 * @param urlParamString - The url search string (window.location.search)
 */
export const getUrlDeeplinkParams = (urlParamString: string): Record<DeeplinkUrlParam, any> => {
  const availableParams = Object.values(DeeplinkUrlParam);
  const params = qs.parse(urlParamString, { ignoreQueryPrefix: true, comma: true });
  return pickBy(params, (value, key) => availableParams.includes(key as DeeplinkUrlParam)) as Record<
    DeeplinkUrlParam,
    string
  >;
};

/**
 * Given a url string, this util will parse the url and if the column param is found, will return an array of column
 * values.
 * @example /retail/table?columns=rooftop,status,type => ['rooftop', 'status', 'type']
 */
export const getTableColumnParams = (urlParamString: string): string[] => {
  const parsed = qs.parse(urlParamString, { ignoreQueryPrefix: true, comma: true });
  return (parsed?.columns as string[]) || [];
};

/**
 * Given a url string, this util will parse the url string and return an object where the key/value pair is to be
 * used in searching/filtering. Ex '?year=2012&make=test' will return { year: 2012, make: 'test' }, which can be
 * used to filter items by those conditions. Any url params that are used for deeplinking
 * purposes (see DeeplinkUrlParams) will be ignored as those are not meant to be included in any searching/filtering
 *
 * @param location - the url search string (comes from the router)
 */
export const getUrlSearchParams = location => {
  const parsed = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
    comma: true,
    /**
     * Custom decoder to parse primitives.
     * Qs default parser returns only strings.
     *
     * @source https://github.com/ljharb/qs/issues/91#issuecomment-437926409
     * @example Price filter range keys e.g. `gte` and `lte` should be `Int` not `String`.
     */
    decoder(str, decoder, charset) {
      const strWithoutPlus = str.replaceAll('+', ' ');
      if (charset === 'iso-8859-1') {
        // Unescape never throws, no try...catch needed:
        return strWithoutPlus.replaceAll(/%[\da-f]{2}/gi, unescape);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return Number.parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      };
      if (str in keywords) {
        return keywords[str];
      }

      // Utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch {
        return strWithoutPlus;
      }
    },
  });

  const searchParams = pickBy(
    parsed,
    (value, key) =>
      !Object.values(DeeplinkUrlParam).includes(key as DeeplinkUrlParam) && key !== TABLE_COLUMNS_URL_PARAM
  );

  const formattedSearchParams: Record<string, any> = cloneDeep(searchParams);

  // Cast any outlier params
  for (const key of Object.keys(searchParams)) {
    if (STRING_URL_PARAMS.has(key)) {
      formattedSearchParams[key] = String(searchParams[key]);
    }
  }

  return formattedSearchParams;
};

export const stringifyParams = params => qs.stringify(params, { encode: true, strictNullHandling: true });

export const areParamsEqual = (searchParams, location) =>
  stringifyParams(searchParams) === stringifyParams(getUrlSearchParams(location));

/**
 * Dynamically prepend 'https://' to a given URL string if the url does not already have 'https' or 'http'.
 * @param url - the url to prepend the protocol to
 * @param protocol - the protocol type to add, can be https or http (https is the default)
 */
export const includeUrlProtocol = (url: string | null | undefined, protocol: 'http' | 'https' = 'https') => {
  if (!url) {
    return null;
  }

  return !url.toLowerCase().includes('http://') && !url.toLowerCase().includes('https://')
    ? `${protocol}://${url}`
    : url;
};

/**
 * Util for generating a URL path for given set of list configurations (filters, sort order, keyword search, etc.)
 *
 * @param basePath: The base path for the url (ex /user)
 * @param filters: Filters are a generic object with key/value pairing. Ex { name: 'test' } will be name=test in the URL
 * @param sort: The sort order of the list.
 * @param keyword: Any active keyword searches present in the list
 */
export const generateUrlPathFromListFilters = (
  basePath: string,
  filters: Record<string, unknown>,
  sort?: SortDirection,
  keyword?: string
): string => {
  const params = stringifyParams({ ...filters, sort, keyword });
  return `${basePath}?${params}`;
};

/**
 * Opens a new tab with the open() method, maintains the Session Context
 *
 * @example If logged in as-user context, will not lose as-user context if open on a new tab.
 *
 * TODO: https://einc.atlassian.net/browse/ED-6743
 *
 */
export const openNewTab = (
  /* The desired url to be redirected to */
  url: string
) => window.open(url, '_blank');

/**
 * Util for joining a set of params to be used in a url
 */
export const getMetricDeeplinkUrl = (
  /* List of all the params */
  columns: string[],
  /* The separator to join all the params together with */
  separator?: string
): string => {
  const results = columns.join(separator);
  return results;
};

/**
 * Extracts the first section from a given pathname.
 *
 * This function takes a string representing a URL pathname and returns the first section of the pathname.
 * Sections are defined as strings separated by forward slashes ("/").
 *
 * @param {string} pathname - The URL pathname to extract the section from.
 * @returns {string} The first section of the URL pathname, prepended with a forward slash ("/").
 *
 * @example
 * // Returns "/"
 * getSectionPath("/");
 *
 * @example
 * // Returns "/retail"
 * getSectionPath("/retail");
 *
 * @example
 * // Returns "/retail"
 * getSectionPath("/retail/zAb918azA_g");
 *
 * @example
 * // Returns "/retail"
 * getSectionPath("/retail/table");
 *
 * @example
 * // Returns "/retail"
 * getSectionPath("/retail/table/zAb918azA_g");
 */
export const getSectionPath = (pathname: string): string => {
  const section = pathname.split('/').find(Boolean) || '';

  return `/${section}`;
};
