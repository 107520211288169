import { useContext } from 'react';

import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

export function useGlobalDialog() {
  const context = useContext(GlobalDialogContext);
  if (context === undefined) {
    throw new Error('useGlobalDialog must be used within a GlobalDialogProvider');
  }
  return context;
}
