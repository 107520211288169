import type { GlobalFilterOption } from 'components/ui/filters/interfaces/globalFilter';
import { GlobalAdminFilterProperties } from 'components/ui/filters/interfaces/globalFilter';
import { rooftopCondensedListQuery } from 'containers/rooftops/RooftopsContainerQuery';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';

/**
 * Given the user's selection of a new list option, update the rooftop/group selections
 */
export const updateGlobalFilterListSelection = ({
  currentSelectedOptions,
  newSelectedOption,
}: {
  /** The currently selected options (includes both rooftops and group) */
  currentSelectedOptions: GlobalFilterOption[];
  /** The new option that the user is attempting to select */
  newSelectedOption: GlobalFilterOption;
}): GlobalFilterOption[] => {
  const selectedItemsCopy = [...currentSelectedOptions];
  const itemIndex = selectedItemsCopy.findIndex(item => item.id === newSelectedOption.id);
  if (itemIndex > -1) {
    /**
     * See if the newly selected rooftop is already a part of the current list of selected rooftops, then
     * we need to deselect the rooftop from the selection
     */
    selectedItemsCopy.splice(itemIndex, 1);
    return selectedItemsCopy;
  } else {
    /**
     * Filter out any existing rooftop selections that are not a part of the newly selected rooftop's group
     */
    const currentRooftopsSelectedInSameGroup = selectedItemsCopy.filter(
      selectedItem => selectedItem.groupName.id === newSelectedOption.groupName.id
    );
    return [newSelectedOption, ...currentRooftopsSelectedInSameGroup];
  }
};

/**
 * Get a list of rooftops, can pass a keyword search filter or group id filter. This is used to get the list of
 * rooftops in the rooftop selection list
 */
export const getRooftops = async ({
  keyword,
  groupId,
}: {
  /** The keyword to search for rooftops by */
  keyword?: string;
  /** The group id to filter rooftops by */
  groupId?: string;
}): Promise<GlobalFilterOption[]> => {
  try {
    const response = await client.query({
      query: rooftopCondensedListQuery,
      variables: { first: keyword ? 50 : 10, keyword, filter: { groupId } },
    });
    return response.data.connection.edges.map(({ node }) => ({
      id: node.id,
      name: node.name.value,
      groupName: node.groupName,
      type: GlobalAdminFilterProperties.ROOFTOPS,
    }));
  } catch (error) {
    logApiError(error);
    return [];
  }
};

/**
 * Get the initial list of rooftop options to show in the rooftop selection list
 */
export const getInitialListOfRooftops = async (savedGroupId?: string): Promise<GlobalFilterOption[]> => {
  let savedGroupRooftops: GlobalFilterOption[] = [];

  if (savedGroupId) {
    savedGroupRooftops = await getRooftops({ groupId: savedGroupId });
  }

  const initialRooftops = await getRooftops({});

  return [...savedGroupRooftops, ...initialRooftops];
};
