import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { subscribeWithMetadata } from 'components/sections/createModify/shared/utils';
import { rooftopCashPricingSegmentMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { rooftopRetailPricingCashDetailQuery } from 'containers/rooftops/pricingSegments/RooftopRetailPricingContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import type {
  RooftopCashPricingSegmentMetaQuery,
  RooftopCashPricingSegmentMetaQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const RooftopCashSegmentQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const rooftopId = seededData?.rooftopName.id || defaultData.rooftopName.id;

    const rooftopCashPricingSegmentObservable = client.watchQuery<
      RooftopCashPricingSegmentMetaQuery,
      RooftopCashPricingSegmentMetaQueryVariables
    >({
      query: rooftopCashPricingSegmentMetaQuery,
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      variables: getRetailFiltersMetaQueryVariables(rooftopId, defaultData.filter),
    });

    return subscribeWithMetadata<RooftopCashPricingSegmentMetaQuery, RooftopCashPricingSegmentMetaQueryVariables>(
      rooftopCashPricingSegmentObservable,
      onMetaUpdate
    );
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopRetailPricingCashDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },
  refetchQueries: [
    'RooftopRetailCashPricingDetailQuery',
    'RooftopRetailPricingContainerQuery',
    'RooftopDetailsContainerQuery',
    'RooftopRetailPricingCountQuery',
  ],
};

export default RooftopCashSegmentQueryMethods;
