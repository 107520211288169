import { storageManager } from './storageUtils';

/*
 * Logging (e.g. datadog) is enabled by default (refer to the .env configs)
 * To disable locally please add the following key/value into your local storage:
 *
 * e.g. Run in browser console: localStorage.setItem('EDealer.loggingEnabled', false)
 */
export const loggingStorage = storageManager.createOrFetchStorage<boolean>('loggingEnabled');

/**
 * Checks if logging is enabled, by evaluating whether the env variable was overridden.
 */
export const isLoggingEnabled = () => loggingStorage.get() ?? process.env.REACT_APP_FEATURE_LOGGING_ENABLED === 'true';

/**
 * Checks if logging is enabled on localhost.
 */
export const isLoggingEnabledLocally = () =>
  isLoggingEnabled() && window?.location?.origin?.startsWith('http://localhost');

/**
 * Disables logging and reloads the page.
 */
export const disableLogging = () => {
  loggingStorage.set(false);
  window.location.reload();
};
