import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import DisclaimerPreview from 'components/sections/createModify/shoppingTools/DisclaimerPreview';
import {
  ShoppingToolsDetailsBuilderFields,
  ShoppingToolsTypes,
} from 'components/sections/createModify/shoppingTools/steps/interfaces';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import { RooftopShoppingTools } from 'enums/columns/rooftopShoppingTools';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

const getNestedAttribute = field => field.split('.')[1];

const DetailsFields: StepFields = {
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM]: {
    label: 'showroom',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
    queryAlias: [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG],
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE]: {
    label: 'build_and_price',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
    queryAlias: [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG],
    hasSeparator: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES]: {
    label: 'make_one',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_MAKES,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_MAKES),
    ],
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
    options: 'makes',
    required: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS]: {
    label: 'model_and_year',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_YEAR_MODELS),
    ],
    groupType: StepFieldType.CUSTOM,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
    forceRequired: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_TRIMS]: {
    label: 'include_commercial_vehicle_other',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES),
    ],
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENT]: {
    label: 'include_bulk_adjustment_in_price',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS),
    ],
    groupType: StepFieldType.SWITCH,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES]: {
    label: 'include_manufacturer_incentives_in_price',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES),
    ],
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS]: {
    label: 'show_finance_payment_other',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS),
    ],
    groupType: StepFieldType.SWITCH,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS]: {
    label: 'show_lease_payment_other',
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS),
    ],
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE]: {
    label: 'showroom_disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE),
    ],
    tooltipContents: 'showroom_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.LARGE,
    } as MultilingualToggleInputSettings,
    previewContents: props => <DisclaimerPreview {...props} type={ShoppingToolsTypes.SHOWROOM} />,
    required: true,
  },
  [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE]: {
    label: 'build_and_price_disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    queryAlias: [
      RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE,
      getNestedAttribute(RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE),
    ],
    tooltipContents: 'build_and_price_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.LARGE,
    } as MultilingualToggleInputSettings,
    previewContents: props => <DisclaimerPreview {...props} type={ShoppingToolsTypes.BUILD_AND_PRICE} />,
    required: true,
  },
};

export default DetailsFields;
