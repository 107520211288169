import { gql } from '@apollo/client';

import { rooftopDetail } from 'store/api/graph/fragments/rooftopDetail';
import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';

/**
 * This mutation is used in the rooftop create builder.
 */
export const rooftopCreate = gql`
  mutation RooftopCreateMutation(
    $groupId: ID
    $accountRepId: ID
    $carfaxId: ID
    $dealertrackId: ID
    $dealertrackConfirmId: ID
    $description: String
    $emailBilling: String
    $emailGeneral: String
    $emailSubdomain: String
    $faxNumber: String
    $locale: String
    $location: LocationInput
    $logo: Upload
    $name: MultilingualStringInput
    $phoneNumber: String
    $shoppingToolsConfiguration: ShoppingToolsConfigurationInput
    $website: String
    $websiteProvider: WebsiteProvider
    # Feature bundle related fields
    $bundle: FeatureBundleInput
    # Validate first config
    $_submit: Boolean! = true
  ) {
    data: rooftopCreate(
      groupId: $groupId
      accountRepId: $accountRepId
      carfaxId: $carfaxId
      dealertrackId: $dealertrackId
      dealertrackConfirmId: $dealertrackConfirmId
      description: $description
      emailBilling: $emailBilling
      emailGeneral: $emailGeneral
      emailSubdomain: $emailSubdomain
      faxNumber: $faxNumber
      locale: $locale
      location: $location
      logo: $logo
      name: $name
      phoneNumber: $phoneNumber
      shoppingToolsConfiguration: $shoppingToolsConfiguration
      website: $website
      websiteProvider: $websiteProvider

      # Feature bundle related fields
      bundle: $bundle
      # Validate first config
      _submit: $_submit
    ) {
      ...RooftopDetailFragment
      bundle {
        ...RooftopFeatureBundle
      }
    }
  }
  ${rooftopDetail}
  ${rooftopFeatureBundle}
`;

export const rooftopModify = gql`
  mutation RooftopModifyMutation(
    $id: ID!
    $active: Boolean
    $accountRepId: ID
    $carfaxId: ID
    $dealertrackId: ID
    $dealertrackConfirmId: ID
    $description: String
    $emailBilling: String
    $emailGeneral: String
    $faxNumber: String
    $locale: String
    $location: LocationInput
    $logo: Upload
    $name: MultilingualStringInput
    $phoneNumber: String
    $shoppingToolsConfiguration: ShoppingToolsConfigurationInput
    $website: String
    $websiteProvider: WebsiteProvider
    $warrantyTypes: [WarrantyType!]
    $bundle: FeatureBundleInput
    $_clear: [RooftopModifyParameter!]
  ) {
    data: rooftopModify(
      id: $id
      active: $active
      accountRepId: $accountRepId
      carfaxId: $carfaxId
      dealertrackId: $dealertrackId
      dealertrackConfirmId: $dealertrackConfirmId
      description: $description
      emailBilling: $emailBilling
      emailGeneral: $emailGeneral
      faxNumber: $faxNumber
      locale: $locale
      location: $location
      logo: $logo
      name: $name
      phoneNumber: $phoneNumber
      shoppingToolsConfiguration: $shoppingToolsConfiguration
      website: $website
      websiteProvider: $websiteProvider
      warrantyTypes: $warrantyTypes
      # Feature bundle related fields
      bundle: $bundle
      _clear: $_clear
    ) {
      ...RooftopDetailFragment
      bundle {
        ...RooftopFeatureBundle
      }
    }
  }
  ${rooftopDetail}
  ${rooftopFeatureBundle}
`;
