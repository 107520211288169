import { isEmpty } from 'lodash-es';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';

import { parsedUserAgent } from 'utils/deviceInfoUtils';

/**
 * A utility function meant to assist with creating different variations of the same component.
 * This is very useful when you want to define a component that has a specific interface and functionality
 * but has different looks depending on the variant that is required.
 * A good example of this is the Button component that can come in a "primary" or "secondary" variant.
 * This utility assumes you are using styled-components as it is a styled-component helper
 *
 * Example:
 * const Button = styled.button`
 *   ${variants('type', {
 *     primary: `color: red`,
 *     secondary: `color: green`
 *   })}
 * `;
 *
 * Then use it
 * <Button type="primary">This should be red</Button>
 */
export const variants =
  <VariantEnum extends string | number>(
    propName: string,
    variants: { [key in VariantEnum]: FlattenSimpleInterpolation | null },
    defaultVariantName?: VariantEnum
  ) =>
  props => {
    const propVariant = props[propName];
    const variantName = isEmpty(propVariant) ? defaultVariantName : propVariant;

    return variantName === undefined ? null : variants[variantName];
  };

/**
 * Similar to the above util, this function conditionally applies CSS to all browsers that are not Safari under version
 * 14. This is to fix an issue with TranslateScrollable component scrollbars failing to render on versions of Safari
 * under 14, due to the CSS property will-change: transform being applied to the scrollbar container. However, on other
 * browsers, this CSS property greatly improves scrolling performance so we'd still like to include it.
 */
export const nonSafari13BrowserStyles =
  (css: TemplateStringsArray, ...rest: any[]) =>
  (): string | TemplateStringsArray => {
    if (!(parsedUserAgent.browser.name === 'Safari' && Number(parsedUserAgent.browser.version) < 14)) {
      // Combine template strings
      const flatString = css.map((item, index) => item.concat(rest?.[index] || '')).join('');
      return flatString;
    }

    return '';
  };

export const hexToRGBA = (colourHex: string, alpha?: string): string => {
  const parseHex = colourHex.replace('#', '');
  const r = Number.parseInt(parseHex.length === 3 ? parseHex.slice(0, 1).repeat(2) : parseHex.slice(0, 2), 16);
  const g = Number.parseInt(parseHex.length === 3 ? parseHex.slice(1, 2).repeat(2) : parseHex.slice(2, 4), 16);
  const b = Number.parseInt(parseHex.length === 3 ? parseHex.slice(2, 3).repeat(2) : parseHex.slice(4, 6), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

/**
 * Converts a value from rem to pixels (px) based on the current root font size.
 * @param {string} rem - The value in rem (e.g., "0.5625rem") to be converted to pixels.
 * @returns {number} The converted value in pixels.
 */
export const remToPx = (rem: string): number => {
  // Remove `rem` from string
  const numericValue = Number.parseFloat(rem || '0');

  // Get the base font size (in pixels)
  const baseFontSize = Number.parseFloat(getComputedStyle(document.documentElement).fontSize || '16px');

  // Calculate pixels
  const px = numericValue * baseFontSize;

  return px;
};
