import 'whatwg-fetch';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Router from 'components/core/routing/Router';
import { ANONYMOUS_USER_KEY } from 'utils/featureFlagUtils';

export default withLDProvider({
  clientSideID: process.env['REACT_APP_LAUNCH_DARKLY_CLIENT_ID'] || '',
  user: {
    key: ANONYMOUS_USER_KEY,
    anonymous: true,
  },
})(Router);

/**
 * Force repaint when app is visible to get rid of
 * weird artifacts on screen e.g. black scrollbars.
 *
 * Repaint source:
 * https://stackoverflow.com/questions/3485365/how-can-i-force-webkit-to-redraw-repaint-to-propagate-style-changes
 */
document.addEventListener('visibilitychange', () => {
  const documentRoot = document.querySelector<HTMLDivElement>('#root');
  if (!document.hidden || !documentRoot) {
    return;
  }

  documentRoot.style.display = 'none';

  const offsetHeight = documentRoot.offsetHeight; // Reference variable to take effect
  documentRoot.style.display = '';
});
