import type { MultilingualInsertOption } from 'components/ui/editor/components/buttons/MultilingualInsertMenu';
import ContractIcon from 'components/ui/icons/ContractIcon';
import type {
  MultilingualString,
  OemProgram,
  RegionCode,
  SelectMultilingualStringOption,
} from 'store/api/graph/interfaces/types';

interface SelectMultilingualStringOptionData {
  /**
   * The OEM program that this disclaimer template is associated with
   */
  oem?: OemProgram;
  /**
   * The region code that this disclaimer template is associated with
   */
  regionCode?: string;
  /**
   * The region codes that this disclaimer template is associated with
   */
  regionCodes?: string[];
}

interface NormalizedSelectMultilingualStringOption extends MultilingualInsertOption {
  /**
   * The data field is parsed into an object for easier access
   */
  data: SelectMultilingualStringOptionData;
}

/**
 * Normalizes the disclaimer templates by parsing the data field into an object.
 *
 * @param templates - The disclaimer templates to normalize
 * @returns The normalized disclaimer templates
 *
 */
export const normalizeAndFilterDisclaimerTemplates = (
  templates: SelectMultilingualStringOption[],
  oemProgram?: OemProgram
): NormalizedSelectMultilingualStringOption[] =>
  templates
    .map<NormalizedSelectMultilingualStringOption>(({ data, ...rest }) => {
      let parsedData = {};

      try {
        parsedData = data ? JSON.parse(data) : {};
      } catch {
        parsedData = {};
      }

      return {
        ...rest,
        data: parsedData,
        icon: ContractIcon,
      };
    })
    .filter(template => {
      if (!template.data.oem) {
        return true;
      }

      return template.data.oem === oemProgram;
    });

/**
 * Given a list of disclaimer templates, determine which disclaimer template to use based on the provided RegionCode.
 */
export const getDefaultDisclaimerTemplate = (
  /** List of disclaimer templates */
  templates: NormalizedSelectMultilingualStringOption[],
  /** The provided region code */
  regionCode: RegionCode,
  /** The OEM program */
  oemProgram: OemProgram | undefined
): MultilingualString | undefined => {
  /**
   * Check to see if there is a template for oem program
   */
  if (oemProgram) {
    const foundTemplate = templates.find(template => template.data.oem === oemProgram);

    if (foundTemplate) {
      return foundTemplate.value;
    }
  }

  /**
   * Return template based on region code
   */
  const foundTemplate = templates.find(template => template.data.regionCodes?.includes(regionCode));

  return foundTemplate ? foundTemplate.value : templates[0].value;
};

/**
 * Replaces tags in the format [key] with corresponding values from the provided object.
 *
 * @param {string} template - The template string containing tags in the format [key].
 * @param {Record<string, string>} values - An object containing key-value pairs where the key matches the tag name.
 * @returns {string} - The template string with tags replaced by corresponding values from the object.
 *
 * @example
 * const template = "Hello, [name]! Welcome to [place].";
 * const values = { name: "John", place: "Wonderland" };
 * const result = replaceDisclaimerTemplateTags(template, values);
 * console.log(result); // "Hello, John! Welcome to Wonderland."
 */
export const replaceDislaimerTemplateTags = (template: string, values: Record<string, string>) =>
  template.replaceAll(/\[(\w+)]/g, (match, key) => values[key] || match);
