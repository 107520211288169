import { gql } from '@apollo/client';

import { appointmentList } from 'store/api/graph/fragments/appointmentList';
import { leadList } from 'store/api/graph/fragments/leadList';
import { taskList } from 'store/api/graph/fragments/taskList';
import { whiteLabel } from 'store/api/graph/fragments/whiteLabel';

import { group } from './group';
import { locale } from './locale';
import { Permission } from './permission';
import { role } from './role';
import { rooftopList } from './rooftopList';
import { userList } from './userList';

export const userDetail = gql`
  fragment UserDetailFragment on User {
    ## Minimum required fields
    ...UserListFragment
    editableByMe
    phoneNumber
    cellNumber
    email
    title
    canLoginWithPassword
    canLoginWithSso

    rooftops {
      ...RooftopListFragment
    }
    rooftopName: rooftops {
      ...RooftopListFragment
    }
    groupName: group {
      ...GroupFragment
    }
    roleName: role {
      ...RoleFragment
    }

    ## Details tab
    username
    active
    subscribedEmail
    locale {
      ...LocaleFragment
    }
    whiteLabelName: whiteLabel {
      ...WhiteLabelFragment
    }
    created
    updated
    lastActive

    ## Linked tab
    scope
    scopeName
    leadsCount
    walkInCount: leadActivitiesCount(type: WALK_IN)
    testDriveCount: leadActivitiesCount(type: TEST_DRIVE)
    phoneCallCount: leadActivitiesCount(type: PHONE_CALL)
    conversationsCount
    createdByName: createdBy {
      ...UserListFragment
    }
    tasks {
      ...TaskListFragment
    }
    appointments {
      ...AppointmentListFragment
    }
    leads {
      ...LeadListFragment
    }
    permissions {
      ...PermissionFragment
    }
    role {
      ...RoleFragment
    }
  }
  ${userList}
  ${locale}
  ${whiteLabel}
  ${taskList}
  ${appointmentList}
  ${leadList}
  ${role}
  ${Permission}
  ${group}
  ${rooftopList}
`;
