import type { TextNavLinkExternalProps } from 'components/core/navigation/shared/TextNavLinkExternal';
import { TextNavLinkExternal } from 'components/core/navigation/shared/TextNavLinkExternal';
import PrimaryText from 'components/core/typography/PrimaryText';
import { PLACEHOLDER } from 'utils/formatUtils';

/**
 * A component for rendering an ItemGridCell with a displayType of EXTERNAL_LINK.
 * Renders a TextNavLinkExternal component, or placholder as a fallback when `to` prop is not provided.
 */
const ItemGridCellContentExternalLink = ({
  children,
  to,
  ...props
}: Omit<TextNavLinkExternalProps, 'to'> & { to?: TextNavLinkExternalProps['to'] }) =>
  to ? (
    <TextNavLinkExternal to={to} {...props}>
      {children}
    </TextNavLinkExternal>
  ) : (
    <PrimaryText>{PLACEHOLDER}</PrimaryText>
  );

export default ItemGridCellContentExternalLink;
