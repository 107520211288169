import type { KeyboardEventHandler } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import DOMPurify from 'dompurify';
import { merge } from 'lodash-es';
import styled from 'styled-components/macro';

import BubbleMenu from 'components/ui/editor/components/shared/BubbleMenu';
import InputText from 'components/ui/forms/shared/InputText';
import CheckIcon from 'components/ui/icons/CheckIcon';
import IconButton, { IconButtonStyle } from 'components/ui/shared/IconButton';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { BORDER_DEFAULT } from 'styles/color';
import { NEUTRAL_800 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';
import { includeUrlProtocol } from 'utils/urlUtils';

const { t } = translate;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

const Input = styled(InputText)`
  & > input {
    border-color: ${BORDER_DEFAULT};
    margin-top: 0;
    min-height: 36px;
    width: 244px;
  }
`;

/**
 * A dialog that allows the user to set a link for the selected text.
 */
const EditLinkDialog = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { closeEditLinkDialog, editor, isLinkDialogOpen } = useRichTextEditor();

  /**
   * Focus the input when the link dialog is open
   */
  useEffect(() => {
    if (isLinkDialogOpen) {
      merge(inputRef.current, {
        value: editor?.getAttributes('link').href || '',
      });

      inputRef.current?.focus();
    }
  }, [editor, isLinkDialogOpen, inputRef]);

  /**
   * Handles the setting of the link in the editor
   */
  const handleSetUrlLink = useCallback(() => {
    const href = includeUrlProtocol(inputRef.current?.value, 'http');

    if (href) {
      const sanitizedHref = DOMPurify.sanitize(href, { USE_PROFILES: { html: true } });
      editor?.chain().focus().extendMarkRange('link').setLink({ href: sanitizedHref }).run();
    }

    closeEditLinkDialog();
  }, [closeEditLinkDialog, editor, inputRef]);

  /**
   * Sets URL on enter key press
   */
  const handleInputEnter = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (event.key === 'Enter') {
        handleSetUrlLink();
      }
    },
    [handleSetUrlLink]
  );

  /**
   * When text selection, if the link dialog is currently open and we switch to
   * a new selection, close the link dialog
   */
  const handleShouldShowOnTextSelection = useCallback(() => {
    if (isLinkDialogOpen) {
      closeEditLinkDialog();
      return false;
    }

    return true;
  }, [closeEditLinkDialog, isLinkDialogOpen]);

  return (
    <BubbleMenu
      data-testid={ElementTestId.EDITOR_DIALOG_SET_LINK}
      isOpen={isLinkDialogOpen}
      placement="bottom-start"
      shouldShowOnTextSelection={handleShouldShowOnTextSelection}
    >
      <LinkContainer>
        <Input ref={inputRef} placeholder={t('enter_link')} onKeyPress={handleInputEnter} />
        <IconButton styleVariant={IconButtonStyle.BASIC} onClick={handleSetUrlLink}>
          <CheckIcon color={NEUTRAL_800} />
        </IconButton>
      </LinkContainer>
    </BubbleMenu>
  );
};

export default EditLinkDialog;
