import { gql } from '@apollo/client';

export const listPhoto = gql`
  fragment ListPhotoFragment on InventoryItemPhoto {
    id
    thumb: url(width: 70, height: 70)
    listPhoto: url(width: 120, height: 120)
    large: url(width: 300, height: 215)
  }
`;
