import styled from 'styled-components/macro';

import { BODY_TEXT_SECONDARY } from 'styles/color';
import { FONT_WEIGHT_NORMAL, LINE_HEIGHT_DEFAULT } from 'styles/typography';

import Text from './Text';

const SecondaryText = styled(Text)`
  color: ${BODY_TEXT_SECONDARY};
  font-weight: ${FONT_WEIGHT_NORMAL};
`;

export const SecondaryMultiLineText = styled(SecondaryText)`
  line-height: ${LINE_HEIGHT_DEFAULT};
  overflow: visible;
  white-space: pre-line;
  text-align: left;
`;

export default SecondaryText;
