import { useCallback, useState } from 'react';

import { css } from 'styled-components/macro';
import invariant from 'tiny-invariant';

import { retailItemBulkModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { useRouter } from 'hooks/useRouter';
import ApiRequest, { getDefaultHeaders } from 'store/api/ApiRequest';
import { RetailItemStatus } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { downloadAttachment, getGraphURL } from 'utils/apiUtils';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import { translate } from 'utils/intlUtils';

import { TableType } from './tableSettings/TableSettings';

const { t, tPlural } = translate;

const { YEAR_MONTH_DAY_UNDERSCORE_FORMAT } = DateTimeFormat;

export enum ExportType {
  CSV = 'CSV',
  JSON = 'JSON',
}

interface Props {
  disabled?: boolean;
  /** Whether or not an export request is happening */
  onProcessing: (isProcessing: boolean) => void;
  /** `/graphql-export` specific parameters */
  query: string;
  variables: any;
  ids: string[];
  columnIds: string[];
  tableType: TableType;
  isAllSelected: boolean;
  /** Callback used to inform parent that the selected items have changed*/
  onSelectionChange: (ids: string[]) => void;
  /** Whether user has permissions to modify entity */
  isEditAllowed: boolean;
}

const TableBulkActionMenuItems = ({
  isEditAllowed,
  disabled,
  onProcessing,
  query,
  variables,
  ids,
  columnIds,
  tableType,
  isAllSelected,
  onSelectionChange,
}: Props) => {
  const router = useRouter();

  // State
  const [isArchiveSelectedDialogOpen, setIsArchiveSelectedDialogOpen] = useState<boolean>(false);
  const [isMarkForSaleDialogOpen, setIsMarkForSaleDialogOpen] = useState<boolean>(false);
  const [isMarkAsSoldDialogOpen, setIsMarkAsSoldDialogOpen] = useState<boolean>(false);
  const [isShowOnWebDialogOpen, setIsShowOnWebDialogOpen] = useState<boolean>(false);
  const [isHidewOnWebDialogOpen, setIsHideOnWebDialogOpen] = useState<boolean>(false);
  const [isMarkAsFeaturedDialogOpen, setIsMarkAsFeaturedDialogOpen] = useState<boolean>(false);
  const [isUnMarkAsFeaturedDialogOpen, setIsUnMarkAsFeaturedDialogOpen] = useState<boolean>(false);

  const [, setError] = useState();
  const [successLabel, setSuccessLabel] = useState<string>();
  const isSupportedConnection = !![
    /*
     * Connections that support exporting, only used here at the moment, use array of constants if used elsewhere
     * https://edealer-wiki.netlify.com/documentation/api.html#graphql-export-post
     */
    'appointmentConnection',
    'leadConnection',
    'leadActivityConnection',
    'retailItemConnection',
    'taskConnection',
    'tradeInItemConnection',
    'userConnection',
    'rooftopConnection',
    'conversationConnection',
  ].some(connection => query.includes(connection));

  // Make the export request
  const onExport = useCallback(
    (type: ExportType) => {
      const fileType = type === ExportType.JSON ? 'application/json' : 'text/csv';

      /*
       * TODO: Possibly Move away from this when upgrading to Apollo 3.0
       * Cleaning out client-side directives
       */
      const cleanedQuery = query
        .split('\n')
        .filter(line => !line.includes('@client'))
        .join('\n');

      // Export logic
      onProcessing(true);
      ApiRequest({
        url: `${getGraphURL()}/graphql-export`,
        data: {
          columnIds,
          query: cleanedQuery,
          variables: {
            ...variables,
            /**
             * Workaround for BE issue were paging shouldn't matter but is still being
             * accounted for.
             *
             * This fixes a 400 issue when trying to export on last page of table view.
             */
            after: '0',
          },
          ids: isAllSelected ? undefined : ids,
        },
        headers: {
          ...getDefaultHeaders(),
          Accept: fileType,
        },
      })
        .then(response => {
          const filenamePrefix = (router.sectionPath || '').replaceAll(/\W/g, '');
          const filename = `${filenamePrefix}Export_${getFormattedDateTimeString(
            getDateTime(),
            YEAR_MONTH_DAY_UNDERSCORE_FORMAT
          )}.${type === ExportType.JSON ? 'json' : 'csv'}`;

          invariant(!(response instanceof Error));

          downloadAttachment(
            type === ExportType.JSON ? JSON.stringify(response.data) : response.data,
            filename,
            fileType
          );
        })
        .catch(error => {
          // Triggering react ErrorBoundary
          setError(() => {
            throw new Error(error);
          });
        })
        .finally(() => {
          onProcessing(false);
        });
    },
    [query, onProcessing, columnIds, variables, isAllSelected, ids, router.sectionPath]
  );

  const onArchive = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          archived: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_archive', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onMarkForSale = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          status: RetailItemStatus.FOR_SALE,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_for_sale', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onMarkAsSold = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          status: RetailItemStatus.SOLD,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_sold', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onShowOnWeb = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          showWeb: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_show_web', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onHideOnWeb = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          showWeb: false,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_hide_web', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onMarkAsFeatured = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          featured: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_mark_featured', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const onUnMarkAsFeatured = useCallback(async () => {
    await client
      .mutate({
        mutation: retailItemBulkModify,
        variables: {
          ids,
          featured: false,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['RetailItemConnectionQuery'],
      })
      .then(result => {
        const numItems = ids.length === result.data.data ? result.data.data : `${result.data.data}/${ids.length}`;
        const successMessage = t('bulk_action_x_retail_item_success_remove_featured', [numItems]);
        setSuccessLabel(successMessage);
        onSelectionChange([]);
      });
  }, [ids, onSelectionChange]);

  const sharedMenuItemsConfig: MenuItemConfig[] = [
    {
      label: t('export_json'),
      onClick: () => onExport(ExportType.JSON),
      disabled: !isSupportedConnection,
    },
    {
      label: t('export_csv'),
      onClick: () => onExport(ExportType.CSV),
      disabled: !isSupportedConnection,
    },
  ];

  const retailMenuItemsConfig: MenuItemConfig[] =
    isEditAllowed && tableType === TableType.RETAIL_ITEMS && !isAllSelected
      ? [
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_for_sale', ids.length, [ids.length]),
            onClick: () => setIsMarkForSaleDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_sold', ids.length, [ids.length]),
            onClick: () => setIsMarkAsSoldDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_show_web', ids.length, [ids.length]),
            onClick: () => setIsShowOnWebDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_hide_web', ids.length, [ids.length]),
            onClick: () => setIsHideOnWebDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_mark_featured', ids.length, [ids.length]),
            onClick: () => setIsMarkAsFeaturedDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_remove_featured', ids.length, [ids.length]),
            onClick: () => setIsUnMarkAsFeaturedDialogOpen(true),
          },
          {
            label: tPlural('bulk_action_x_retail_item_menuitem_archive', ids.length, [ids.length]),
            onClick: () => setIsArchiveSelectedDialogOpen(true),
            titleCss: css`
              color: ${RED_500};
            `,
          },
        ]
      : [];

  const menuItemsConfig = [...sharedMenuItemsConfig, ...retailMenuItemsConfig];

  return (
    <>
      <MenuItems items={menuItemsConfig} />
      <PromptDialog
        isFixedToTop
        isOpen={isArchiveSelectedDialogOpen}
        onCancel={() => {
          setIsArchiveSelectedDialogOpen(false);
        }}
        onConfirm={onArchive}
        title={t('bulk_action_retail_item_title_archive')}
        message={tPlural('bulk_action_x_retail_item_confirm_archive', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isMarkForSaleDialogOpen}
        onCancel={() => {
          setIsMarkForSaleDialogOpen(false);
        }}
        onConfirm={onMarkForSale}
        title={t('bulk_action_retail_item_title_for_sale')}
        message={tPlural('bulk_action_x_retail_item_confirm_for_sale', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isMarkAsSoldDialogOpen}
        onCancel={() => {
          setIsMarkAsSoldDialogOpen(false);
        }}
        onConfirm={onMarkAsSold}
        title={t('bulk_action_retail_item_title_sold')}
        message={tPlural('bulk_action_x_retail_item_confirm_sold', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isShowOnWebDialogOpen}
        onCancel={() => {
          setIsShowOnWebDialogOpen(false);
        }}
        onConfirm={onShowOnWeb}
        title={t('bulk_action_retail_item_title_show_web')}
        message={tPlural('bulk_action_x_retail_item_confirm_show_web', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isHidewOnWebDialogOpen}
        onCancel={() => {
          setIsHideOnWebDialogOpen(false);
        }}
        onConfirm={onHideOnWeb}
        title={t('bulk_action_retail_item_title_hide_web')}
        message={tPlural('bulk_action_x_retail_item_confirm_hide_web', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isMarkAsFeaturedDialogOpen}
        onCancel={() => {
          setIsMarkAsFeaturedDialogOpen(false);
        }}
        onConfirm={onMarkAsFeatured}
        title={t('bulk_action_retail_item_title_mark_featured')}
        message={tPlural('bulk_action_x_retail_item_confirm_mark_featured', ids.length, [ids.length])}
        successMessage={successLabel}
      />
      <PromptDialog
        isFixedToTop
        isOpen={isUnMarkAsFeaturedDialogOpen}
        onCancel={() => {
          setIsUnMarkAsFeaturedDialogOpen(false);
        }}
        onConfirm={onUnMarkAsFeatured}
        title={t('bulk_action_retail_item_title_remove_featured')}
        message={tPlural('bulk_action_x_retail_item_confirm_remove_featured', ids.length, [ids.length])}
        successMessage={successLabel}
      />
    </>
  );
};

export default TableBulkActionMenuItems;
