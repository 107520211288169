import { gql } from '@apollo/client';

import { rooftopList } from 'store/api/graph/fragments/rooftopList';
import { shoppingToolsConfiguration } from 'store/api/graph/fragments/shoppingToolsConfiguration';

import { locale } from './locale';
import { MultilingualString } from './multilingualString';
import { userList } from './userList';
import { whiteLabel } from './whiteLabel';

export const rooftopDetail = gql`
  fragment RooftopDetailFragment on Rooftop {
    ...RooftopListFragment
    phoneNumber
    website
    websiteProvider
    websiteProviderName
    emailBilling
    emailGeneral
    faxNumber
    created
    updated
    description
    carfaxId
    dealertrackId
    dealertrackConfirmId
    logoUrl

    # Available warranties and selected warranties for this rooftop
    availableWarrantyTypes
    availableWarrantyTypesNames
    warrantyAvailable
    warrantyTypes
    warrantyTypesNames

    name {
      ...MultilingualStringFragment
    }
    whiteLabelName: group {
      id
      whiteLabel {
        ...WhiteLabelFragment
      }
    }
    locale {
      ...LocaleFragment
    }
    zipCode: location {
      value: zipCode
    }
    region: location {
      value: region
    }
    country: location {
      value: country
    }
    countryCode: location {
      value: countryCode
    }
    placeId: location {
      value: placeId
    }
    timeZone: location {
      value: timeZone
    }
    latitude: location {
      value: latitude
    }
    longitude: location {
      value: longitude
    }
    accountRepName: accountRep {
      ...UserListFragment
    }

    createdByName: createdBy {
      ...UserListFragment
    }

    shoppingToolsConfiguration {
      ...ShoppingToolsConfigurationFragment
    }

    ## For Configurations
    contactsCount
    tagsCount
    integrationsCount
    emailSubdomain
  }
  ${rooftopList}
  ${MultilingualString}
  ${locale}
  ${shoppingToolsConfiguration}
  ${whiteLabel}
  ${userList}
`;
