import { useCallback, useMemo } from 'react';

import type { DayPickerSingleProps } from 'react-day-picker';
import styled from 'styled-components/macro';

import type { DateListPickerSettings } from 'components/ui/forms/shared/DateListPicker';
import DateListPicker from 'components/ui/forms/shared/DateListPicker';
import DatePicker from 'components/ui/forms/shared/DatePicker';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';

import { ListPickerContainer } from './ListPickerContainer';
import { Container } from './ListSelectionContainer';

const { YEAR_MONTH_DAY_DASH_FORMAT } = DateTimeFormat;

export type ListSelectionDatePickerSettings = {
  /**
   * Matrix calendar config for the `ReactDayPicker` component.
   * Accepts either a config, or set to true for default behaviour.
   */
  matrixViewConfig?: DayPickerSingleProps | boolean;
  /**
   * List view calendar config for our custom calendar component.
   * Accepts either a config, or set to true for default behaviour.
   */
  listViewConfig?: DateListPickerSettings | boolean;
};

type ListSelectionDatePickerProps = {
  onSelect?: (item: string) => void;
  selectedValue?: string;
  settings?: ListSelectionDatePickerSettings;
};

const DatePickerContainer = styled.div`
  padding: 15px 17px;
  overflow: hidden;
`;

const ListSelectionDatePicker = ({
  onSelect = () => {},
  selectedValue,
  settings = {},
}: ListSelectionDatePickerProps) => {
  const isMatrixDatePicker = settings?.matrixViewConfig === true || !settings?.listViewConfig;
  const onChange = useCallback(
    isoDateString => {
      onSelect(isoDateString && getFormattedDateTimeString(isoDateString, YEAR_MONTH_DAY_DASH_FORMAT));
    },
    [onSelect]
  );
  const formattedDateTimeString = useMemo(
    () => selectedValue && getFormattedDateTimeString(getDateTime(selectedValue, YEAR_MONTH_DAY_DASH_FORMAT)),
    [selectedValue]
  );

  return (
    <Container>
      <ListPickerContainer>
        {isMatrixDatePicker ? (
          <DatePickerContainer>
            <DatePicker
              onChange={onChange}
              selectedDate={formattedDateTimeString}
              settings={settings?.matrixViewConfig as DayPickerSingleProps}
            />
          </DatePickerContainer>
        ) : (
          <DateListPicker
            onChange={onChange}
            selectedDate={formattedDateTimeString}
            settings={settings?.listViewConfig as DateListPickerSettings}
          />
        )}
      </ListPickerContainer>
    </Container>
  );
};

export default ListSelectionDatePicker;
