import { isNil } from 'lodash-es';
import invariant from 'tiny-invariant';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { IntegrationTypeOptions } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import {
  RooftopIntegrationBuilderStep,
  RooftopIntegrationDetailsFields,
} from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { isIntegrationFieldEnabled } from 'components/sections/rooftops/integrations/utils';
import {
  getIntegrationOptions,
  getIntegrationType,
  getRooftopOptions,
  getWebsitePageType,
} from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import type {
  Integration,
  IntegrationWebsiteFragment,
  RooftopIntegrationCreateMutationVariables,
  RooftopIntegrationsDetailsQuery,
} from 'store/api/graph/interfaces/types';
import { EntityType, IntegrationType, WebsitePageType } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { filterByKeyword } from 'utils/metadataUtils';
import { isEnumValue } from 'utils/typeUtils';

import { websiteAsyncConfiguration } from '../utils';

type RooftopIntegrationData =
  | RooftopIntegrationsDetailsQuery['item']
  | Partial<RooftopIntegrationsDetailsQuery['item']>;

const { t } = translate;

/**
 * Given an integration type, determine the default value to use for the import photos flag.
 * @param integrationType - the IntegrationType
 */
const getDefaultImportPhotosFlagValue = (integrationType: IntegrationType | undefined): boolean | null => {
  if (
    integrationType &&
    [
      IntegrationType.INVENTORY_EXPORT,
      IntegrationType.CPP,
      IntegrationType.OEM_ACCOUNT_EXPORT,
      IntegrationType.LEAD_DISTRIBUTION,
    ].includes(integrationType)
  ) {
    return null;
  } else {
    return integrationType === IntegrationType.PHOTO_IMPORT;
  }
};

/**
 * Given an integration type, determine the default value to use for the primary flag. All integration types
 * except EXPORT and CPP will use false, EXPORT and CPP will use null
 * @param integrationType - the IntegrationType
 */
const getDefaultPrimaryFlagValue = (integrationType: IntegrationType | undefined): boolean | null =>
  integrationType &&
  [
    IntegrationType.INVENTORY_EXPORT,
    IntegrationType.CPP,
    IntegrationType.OEM_ACCOUNT_EXPORT,
    IntegrationType.LEAD_DISTRIBUTION,
  ].includes(integrationType)
    ? null
    : false;

const getActiveFieldSelectedValue = (
  isCreating: boolean,
  isInventoryIntegrationType: boolean,
  data: RooftopIntegrationData
) => {
  if (isCreating) {
    if (isInventoryIntegrationType) {
      return false;
    }

    // For most integration types we allow the backend to determine whether to activate during creation
    return null;
  }

  return data?.active;
};

const getCppAttributes = (data: RooftopIntegrationData) => {
  try {
    const attributes = data?.cppAttributes;
    invariant(attributes?.__typename === 'CppRooftopIntegrationAttributes');
    return attributes;
  } catch {
    return null;
  }
};

const getLeadDistributionAttributes = (data: RooftopIntegrationData) => {
  try {
    const attributes = data?.leadDistributionAttributes;
    invariant(attributes?.__typename === 'LeadDistributionRooftopIntegrationAttributes');
    return attributes;
  } catch {
    return null;
  }
};

class RooftopIntegration extends StepComponentCore<
  RooftopIntegrationData,
  any,
  RooftopIntegrationCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<RooftopIntegrationData, any, RooftopIntegrationCreateMutationVariables>,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: { activeStep, data, seededData, isCreating },
    } = props;
    const cppAttributes = getCppAttributes(data);
    const leadDistributionAttributes = getLeadDistributionAttributes(data);

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops, featureFlags },
      },
    } = context;

    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);
    const integrationType: IntegrationType | undefined = data?.integrationType?.id;
    const isInventoryIntegrationType =
      !!integrationType &&
      [IntegrationType.INVENTORY_EXPORT, IntegrationType.INVENTORY_IMPORT].includes(integrationType);

    const websitePageType: SelectOption | null = cppAttributes
      ? {
          id: cppAttributes.websitePageType,
          name: cppAttributes.websitePageTypeName,
        }
      : null;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !!rooftop },
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
      },
      [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
        selectedValue: data?.integrationType,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: true },
        ]),
      },
      [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
        selectedValue: data?.integrationName,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !!isCreating && !data?.integrationType },
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
        hasSeparator:
          featureFlags.oemAccountExportIntegrationEnabled && integrationType === IntegrationType.OEM_ACCOUNT_EXPORT,
      },
      [RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active:
              !!isCreating ||
              !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.RUN_ONCE]: {
        selectedValue: data?.runOnce || false,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.RUN_ONCE, integrationType),
        }),
        hasSeparator: integrationType === IntegrationType.INVENTORY_EXPORT,
      },
      [RooftopIntegrationDetailsFields.IMPORT_PHOTOS]: {
        selectedValue: data?.importPhotos || getDefaultImportPhotosFlagValue(integrationType),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.IMPORT_PHOTOS, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE]: {
        selectedValue: websitePageType,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE, integrationType),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.CPP_WEBSITE]: {
        selectedValue: cppAttributes?.website,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active:
              !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.CPP_WEBSITE, integrationType) ||
              websitePageType?.id === WebsitePageType.VDP,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.CPP_WEBSITE, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.WEBSITE_ROUTES]: {
        selectedValue: cppAttributes?.websiteRoutes?.map(({ name, ...rest }) => ({
          ...rest,
          name: name.value,
        })),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active:
              !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.WEBSITE_ROUTES, integrationType) ||
              websitePageType?.id === WebsitePageType.VDP,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.WEBSITE_ROUTES, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.ACTIVE]: {
        selectedValue: getActiveFieldSelectedValue(!!isCreating, isInventoryIntegrationType, data),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.OMITTED,
            active: !!isCreating && !isInventoryIntegrationType,
          },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!isCreating && !isInventoryIntegrationType,
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.PRIMARY]: {
        selectedValue: data?.primary ?? getDefaultPrimaryFlagValue(integrationType),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.PRIMARY, integrationType),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL]: {
        selectedValue: data?.privacyPolicyUrl || null,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL, integrationType),
        }),
      },
      [RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE]: {
        selectedValue: leadDistributionAttributes?.website,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isIntegrationFieldEnabled(
              RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE,
              integrationType
            ),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isIntegrationFieldEnabled(
              RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE,
              integrationType
            ),
          },
        ]),
      },
      [RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isIntegrationFieldEnabled(
              RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID,
              integrationType
            ),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isIntegrationFieldEnabled(
              RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID,
              integrationType
            ),
          },
        ]),
      },
    });

    this.fields = defineFieldValues(this.fields, data);

    this.asyncConfigurations = {
      [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
      [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
        request: async (keyword: string) => {
          let integrationTypes = await getIntegrationType();
          integrationTypes = integrationTypes.filter(integrationType =>
            integrationType.id === IntegrationType.OEM_ACCOUNT_EXPORT
              ? featureFlags.oemAccountExportIntegrationEnabled
              : true
          );

          return filterByKeyword(keyword, integrationTypes);
        },
      },
      [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
        request: () => {
          const typeField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_TYPE, this.fields);
          return getIntegrationOptions(typeField.selectedValue.id);
        },
        disableKeywordRefetch: true,
      },
      [RooftopIntegrationDetailsFields.CPP_WEBSITE]: websiteAsyncConfiguration(rooftop.group.id),
      [RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE]: websiteAsyncConfiguration(rooftop.group.id),
      [RooftopIntegrationDetailsFields.WEBSITE_ROUTES]: {
        request: (keyword: string) => {
          const websiteField = getStepField<IntegrationWebsiteFragment>(
            RooftopIntegrationDetailsFields.CPP_WEBSITE,
            this.fields
          );
          const routes =
            websiteField.selectedValue?.routes.map(({ name, ...rest }) => ({
              ...rest,
              name: name.value,
            })) || [];

          return filterByKeyword(keyword, routes);
        },
      },
      [RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE]: {
        request: async (keyword: string) => {
          const websitePageType = await getWebsitePageType();
          return filterByKeyword(keyword, websitePageType);
        },
      },
    };
  }

  /**
   * Helper method to toggle a field's active status (ie whether it's hidden or not). If a field is hidden, its
   * selected value will be cleared. If a field is not hidden, its selected value will be initialized with the provided
   * initialValue (if one is given).
   * @param stepField - The step field to toggle
   * @param isEnabled - The hidden status of this field (true = enabled, false = hidden)
   * @param options - Options object has a property for initialValue. If a field is enabled, and this value is provided,
   * the field's initial value will be this. If a field is not enabled, can also provide a disabledValue to use, if
   * none is provided null will be used.
   */
  toggleActiveField(stepField: StepField, isEnabled: boolean, options?: { initialValue?: any; disabledValue?: any }) {
    setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isEnabled }, stepField);
    if (!isEnabled) {
      stepField.selectedValue = options?.disabledValue ?? null;
    } else if (stepField.selectedValue === null && !isNil(options?.initialValue)) {
      stepField.selectedValue = options.initialValue;
    }
  }

  /*
   * Dynamically returns the appropriate BuilderType given the IntegrationType selection.
   */
  getDynamicBuilderType(value: Integration | IntegrationTypeOptions): BuilderType | undefined {
    const featureFlags = this.context?.subContexts?.userContext?.featureFlags;
    const integrationTypeField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_TYPE, this.fields);
    const selectionChanged = integrationTypeField?.selectedValue?.id !== value.id;

    if (selectionChanged) {
      switch (value.id) {
        case IntegrationType.OEM_ACCOUNT_EXPORT: {
          return featureFlags?.oemAccountExportIntegrationEnabled
            ? BuilderType.ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_CREATE
            : undefined;
        }

        case IntegrationType.INVENTORY_EXPORT: {
          return BuilderType.ROOFTOP_INTEGRATION_INVENTORY_EXPORT_CREATE;
        }

        case IntegrationType.PHONE_CALL_TRACKING: {
          return BuilderType.ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_CREATE;
        }

        default: {
          return BuilderType.ROOFTOP_INTEGRATION_CREATE;
        }
      }
    }

    return undefined;
  }

  toggleBuilderIfNeeded(value: Integration | IntegrationTypeOptions): boolean {
    const {
      tier: { seededData },
    } = this.props;

    const dynamicBuilderType = this.getDynamicBuilderType(value);
    if (dynamicBuilderType) {
      const { toggleTier } = this.getContext();
      const data = isEnumValue(IntegrationType, value.id) ? { integrationType: { ...value, id: value.id } } : undefined;

      // Toggle new builder tier for appropriate integration type
      void Promise.resolve(toggleTier(CreateModifyTiers.TIER_0, undefined)).then(() =>
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: dynamicBuilderType,
          entityType: EntityType.ROOFTOP_INTEGRATION,
          seededData,
          data,
          activeField: RooftopIntegrationDetailsFields.INTEGRATION_ID,
          title: t('create_integration'),
          isCreating: true,
        })
      );
      return true;
    } else {
      return false;
    }
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: Integration | IntegrationTypeOptions) {
    const integrationTypeField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_TYPE, this.fields);
    const integrationField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_ID, this.fields);
    const importPhotosField = getStepField(RooftopIntegrationDetailsFields.IMPORT_PHOTOS, this.fields);
    const primaryField = getStepField(RooftopIntegrationDetailsFields.PRIMARY, this.fields);
    const privacyPolicyField = getStepField(RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL, this.fields);
    const runOnceField = getStepField(RooftopIntegrationDetailsFields.RUN_ONCE, this.fields);
    const thirdPartyField = getStepField(RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID, this.fields);
    const cppWebsiteField = getStepField(RooftopIntegrationDetailsFields.CPP_WEBSITE, this.fields);
    const websiteRouteField = getStepField(RooftopIntegrationDetailsFields.WEBSITE_ROUTES, this.fields);
    const websitePageTypeField = getStepField(RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE, this.fields);
    const leadDistributionWebsiteField = getStepField(
      RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE,
      this.fields
    );
    const leadDistributionThirdPartyIdField = getStepField(
      RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID,
      this.fields
    );
    const activeField = getStepField(RooftopIntegrationDetailsFields.ACTIVE, this.fields);

    if (stepField.queryVar === RooftopIntegrationDetailsFields.INTEGRATION_ID) {
      const integrationType: IntegrationType | undefined = integrationTypeField.selectedValue?.id;
      // Primary field should only be shown for IMPORT integrations
      this.toggleActiveField(
        primaryField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.PRIMARY, integrationType),
        { initialValue: false }
      );
      primaryField.selectedValue = getDefaultPrimaryFlagValue(integrationType);

      // Import photos field should only be shown for IMPORT and PHOTO_IMPORT integrations
      this.toggleActiveField(
        importPhotosField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.IMPORT_PHOTOS, integrationType),
        /*
         * If the integration is an export, then the disabled importPhotos value must be null, for all other
         * integration types, it should be set to false
         */
        { initialValue: false, disabledValue: getDefaultImportPhotosFlagValue(integrationType) }
      );

      // If PHOTO_IMPORT is selected, then the import photos field should be switched on and disabled
      importPhotosField.selectedValue = getDefaultImportPhotosFlagValue(integrationType);
      setDisplayTypes(
        { type: StepFieldDisplayType.DISABLED, active: integrationType === IntegrationType.PHOTO_IMPORT },
        importPhotosField
      );

      // Privacy policy field should only be shown for EXPORT integrations
      this.toggleActiveField(
        privacyPolicyField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL, integrationType)
      );

      // Run once field should be shown for all integrations except CHAT_PROVIDER
      this.toggleActiveField(
        runOnceField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.RUN_ONCE, integrationType),
        { initialValue: false }
      );

      // If integration is EXPORT, then show a separator between the run once toggle and privacy policy url field
      runOnceField.hasSeparator = integrationType === IntegrationType.INVENTORY_EXPORT;

      // Website Page Type Field should be shown for CPP Integration
      this.toggleActiveField(
        websitePageTypeField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE, integrationType),
        { initialValue: false }
      );

      // If integration is OEM Account Export, then show a separator between the integration and website fields
      integrationField.hasSeparator = integrationType === IntegrationType.OEM_ACCOUNT_EXPORT;

      // Website Id Field should be shown for Lead Distribution Integration
      this.toggleActiveField(
        leadDistributionWebsiteField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE, integrationType)
      );
      // Third Party Id Field should be shown for Lead Distribution Integration
      this.toggleActiveField(
        leadDistributionThirdPartyIdField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID, integrationType)
      );

      /*
       * Third Party Id Field should be shown for all integrations except
       * OEM_ACCOUNT_EXPORT, INVENTORY_EXPORT, LEAD_DISTRIBUTION
       */
      this.toggleActiveField(
        thirdPartyField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID, integrationType)
      );

      this.forceUpdate();
    }

    if (stepField.queryVar === RooftopIntegrationDetailsFields.INTEGRATION_TYPE) {
      if (this.toggleBuilderIfNeeded(value)) {
        return;
      }

      const integrationIdField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_ID, this.fields);
      const hasFieldUnselected = stepField.selectedValue?.id === value.id;
      const hasFieldChanged = stepField.selectedValue?.id !== value.id;

      integrationIdField.selectedValue = undefined;
      integrationIdField.options = [];

      if (hasFieldUnselected || hasFieldChanged) {
        const isInventoryImportIntegrationType = value.id === IntegrationType.INVENTORY_IMPORT;

        // Hide all fields (reset)
        for (const field of [
          importPhotosField,
          primaryField,
          privacyPolicyField,
          runOnceField,
          thirdPartyField,
          cppWebsiteField,
          websiteRouteField,
          websitePageTypeField,
          leadDistributionWebsiteField,
          leadDistributionThirdPartyIdField,
        ]) {
          setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, field);
        }

        // Hide integration field only if the field is unselected
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: hasFieldUnselected }, integrationIdField);

        for (const field of [cppWebsiteField, websiteRouteField, websitePageTypeField]) {
          setDisplayTypes({ type: StepFieldDisplayType.OMITTED, active: value.id !== IntegrationType.CPP }, field);
        }

        for (const field of [leadDistributionWebsiteField, leadDistributionThirdPartyIdField]) {
          setDisplayTypes(
            { type: StepFieldDisplayType.OMITTED, active: value.id !== IntegrationType.LEAD_DISTRIBUTION },
            field
          );
        }

        if (isInventoryImportIntegrationType) {
          activeField.selectedValue = false;
        }

        setDisplayTypes(
          [
            { type: StepFieldDisplayType.DISABLED, active: !isInventoryImportIntegrationType },
            { type: StepFieldDisplayType.OMITTED, active: !isInventoryImportIntegrationType },
          ],
          activeField
        );
      }
    }

    if (stepField.queryVar === RooftopIntegrationDetailsFields.CPP_WEBSITE) {
      const hasFieldUnselected = stepField.selectedValue?.id === value.id;
      const hasFieldChanged = stepField.selectedValue?.id !== value.id;

      if (hasFieldUnselected || hasFieldChanged) {
        websiteRouteField.selectedValue = [];
      }
    }

    if (stepField.queryVar === RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE) {
      // CPP Website Field should be shown for CPP Integration and VLP page type
      this.toggleActiveField(
        cppWebsiteField,
        isIntegrationFieldEnabled(RooftopIntegrationDetailsFields.CPP_WEBSITE, integrationTypeField.selectedValue.id) &&
          value.id === WebsitePageType.VLP
      );

      // Website Route Field should be shown for CPP Integration and VLP page type
      this.toggleActiveField(
        websiteRouteField,
        isIntegrationFieldEnabled(
          RooftopIntegrationDetailsFields.WEBSITE_ROUTES,
          integrationTypeField.selectedValue.id
        ) && value.id === WebsitePageType.VLP
      );
    }

    super.onFieldSelection(stepField, value);
  }

  async save(variableOverrides: Partial<any> | undefined = undefined) {
    const {
      tier: { steps },
    } = this.props;

    const integrationTypeField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_TYPE, this.fields);
    const websiteRouteField = getStepField(RooftopIntegrationDetailsFields.WEBSITE_ROUTES, this.fields);

    const overrides = {
      ...(integrationTypeField.selectedValue?.id === IntegrationType.CPP &&
        (!websiteRouteField.selectedValue || websiteRouteField.selectedValue?.length === 0) && {
          cppAttributes: {
            websiteRouteIds: null,
          },
        }),
    };

    if (steps && steps.length > 1) {
      for (const step of steps) {
        if (RooftopIntegrationBuilderStep.DETAILS !== step.id) {
          step.isEnabled = true;
        }
      }
    }

    return super.save(undefined, variableOverrides || overrides);
  }
}

export default RooftopIntegration;
