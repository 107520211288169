import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum RooftopLeadForwarding {
  ROOFTOP_NAME = 'rooftopName',
  SOURCE_NAMES = 'sourceNames',
  EMAIL = 'email',
  ADF = 'adf',
}

const RooftopLeadForwardingConfig: TableSettingsType = {
  [RooftopLeadForwarding.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [RooftopLeadForwarding.SOURCE_NAMES]: { label: 'source', type: FieldDataType.BADGE_LIST },
  [RooftopLeadForwarding.ADF]: { label: 'adf', type: FieldDataType.BOOLEAN, tooltip: 'auto_lead_data_format_tooltip' },
  [RooftopLeadForwarding.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
};

export const RooftopLeadForwardingSettings = translateFields(RooftopLeadForwardingConfig);
