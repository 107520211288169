import { gql } from '@apollo/client';

import { lender } from './lender';
import { MultilingualString } from './multilingualString';

export const creditApplication = gql`
  fragment CreditApplicationFragment on CreditApplication {
    id
    lead {
      id
      rooftop {
        id
        name {
          ...MultilingualStringFragment
        }
        location {
          regionCode
        }
      }
      displayName
    }
    lender {
      ...LenderFragment
    }
    productCode
    productCodeName
    received
    sent
    success
  }
  ${lender}
  ${MultilingualString}
`;
