import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { GuaranteedTradeBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { defineFieldValues, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

class GuaranteedTradeValueStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { seededData, activeStep },
    } = props;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [GuaranteedTradeBuilderFields.AMOUNT]: {
        selectedValue: seededData?.guaranteedValue?.amount?.amount,
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, seededData?.guaranteedValue);
  }

  async save() {
    const {
      tier: { isCreating, seededData, data },
    } = this.props;

    const variableOverrides = {};
    if (isCreating) {
      variableOverrides['tradeInItemId'] = seededData?.tradeInItemId;
    } else {
      variableOverrides['id'] = seededData?.id || data?.id;
    }

    return super.save(undefined, variableOverrides);
  }
}

export default GuaranteedTradeValueStep;
