import { gql } from '@apollo/client';

import { mileage } from 'store/api/graph/fragments/mileage';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { retailPricingConfiguration } from 'store/api/graph/fragments/retailPricingConfiguration';

import { monetaryAmount } from './monetaryAmount';

export const retailPricingLeaseConfiguration = gql`
  fragment RetailPricingLeaseConfigurationFragment on LeaseRetailPricingConfiguration {
    ...RetailPricingConfigurationFragment
    additionalFixedAmounts {
      amount {
        ...MonetaryAmountFragment
      }
      term
    }
    availablePaymentFrequencies
    availablePaymentFrequenciesNames
    defaultPaymentFrequency
    defaultPaymentFrequencyName
    availableTerms
    defaultTerm
    defaultTermType
    defaultTermTypeName
    interestRateType
    interestRateTypeName
    defaultMileageAllowanceType
    defaultMileageAllowanceTypeName
    residualRateType
    residualRateTypeName
    interestRates {
      interestRate
      term
    }
    residualRates {
      mileageAllowance {
        ...MileageFragment
      }
      residualRate
      term
    }
    defaultMileageAllowance {
      ...MileageFragment
    }
    availableMileageAllowances {
      ...MileageFragment
    }
    autoPriceAdjustmentConfiguration {
      targetPriceFieldName
      targetPriceField
      label {
        ...MultilingualStringFragment
      }
      disclaimerTemplate {
        ...MultilingualStringFragment
      }
    }
  }
  ${mileage}
  ${MultilingualString}
  ${retailPricingConfiguration}
  ${monetaryAmount}
`;
