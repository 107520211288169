import { gql } from '@apollo/client';

export const carfaxReport = gql`
  fragment CarfaxReportFragment on CarfaxReport {
    id
    badges
    badgesNames
    badgeUrls
    logoUrl
    url
    showWeb
  }
`;
