import DynamicImport from 'components/core/DynamicImport';
import { TabType } from 'enums/tabType';
import type { TaskResponseType } from 'store/api/graph/responses/responseTypes';

export const taskDetailsTabs = [
  { tabId: TabType.DETAILS, component: DynamicImport(() => import('components/sections/tasks/TaskDetailsTab')) },
  { tabId: TabType.LINKED, component: DynamicImport(() => import('components/sections/tasks/TaskLinkedTab')) },
];

export const getTaskDetailsTitle = (itemData: TaskResponseType) => itemData?.title;
