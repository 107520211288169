import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
  getTagOptions,
} from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

import { TaskDetailsBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, seededData, isCreating, activeStep, entityType },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);
    const leadId = seededData?.leadId || data.leadId;
    const inventoryItemId = seededData?.inventoryItemId || data.inventoryItemId;

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // TODO: [#1224] ensure these fields are properly modifying
      rooftopId: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !isCreating || !!inventoryItemId || !!leadId,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isCreating,
          },
        ]),
      },
      [TaskDetailsBuilderFields.TAG_IDS]: {
        selectedValue: data.tagName || [],
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !rooftop?.id && isWhiteLabelScoped,
        }),
      },
      [TaskDetailsBuilderFields.STATUS]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !!isCreating,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !!isCreating,
          },
        ]),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [TaskDetailsBuilderFields.ROOFTOP_ID]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId: seededData?.userGroupId,
                features: { task: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.TASK,
                    featureFlagOn: rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      [TaskDetailsBuilderFields.TAG_IDS]: {
        request: () =>
          getTagOptions({
            rooftopId: [getStepField(TaskDetailsBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id],
            entityType: entityType!,
          }),
        disableKeywordRefetch: true,
      },
    };
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === TaskDetailsBuilderFields.ROOFTOP_ID) {
      removeDisplayType(getStepField(TaskDetailsBuilderFields.TAG_IDS, this.fields), StepFieldDisplayType.DISABLED);
    }
    super.onFieldSelection(stepField, value);
  }

  async save() {
    const {
      tier: { steps, isCreating, data, seededData },
    } = this.props;

    const leadId = seededData?.leadId || data.leadId;
    const inventoryItemId = seededData?.inventoryItemId || data.inventoryItemId;
    const assignedToId = seededData?.assignedToId || data.assignedToId;

    const success = await super.save({
      ...(isCreating && {
        inventoryItemId,
        leadId,
        assignedToId,
      }),
    });

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }
}

export default DetailsStep;
