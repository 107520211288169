import { gql } from '@apollo/client';

import { retailPricingLeaseConfiguration } from 'store/api/graph/fragments/retailPricingLeaseConfiguration';
import { rooftopRetailPricing } from 'store/api/graph/fragments/rooftopRetailPricing';

export const rooftopRetailLeasePricingCreate = gql`
  mutation RooftopRetailLeasePricingCreate(
    $rooftopId: ID
    $leaseConfiguration: LeaseRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_submit: Boolean! = true
  ) {
    data: rooftopRetailPricingCreate(
      rooftopId: $rooftopId
      leaseConfiguration: $leaseConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _submit: $_submit
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingLeaseConfigurationFragment
      }
    }
  }

  ${retailPricingLeaseConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailLeasePricingModify = gql`
  mutation RooftopRetailLeasePricingModify(
    $id: ID!
    $leaseConfiguration: LeaseRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_clear: [RooftopRetailPricingModifyParameter!]
  ) {
    data: rooftopRetailPricingModify(
      id: $id
      leaseConfiguration: $leaseConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _clear: $_clear
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingLeaseConfigurationFragment
      }
    }
  }

  ${retailPricingLeaseConfiguration}
  ${rooftopRetailPricing}
`;
