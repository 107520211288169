import type { WebsiteDetailsContainerQuery, WebsiteSortOption } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';

export enum RetailFilterBuilderFields {
  ROOFTOP_ID = 'rooftopId',
  FILTER_TYPE = 'filter.type',
  FILTER_CONDITION = 'filter.condition',
  FILTER_TAG_ID = 'filter.tagId',
  FLAGS = 'flags',
  FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE = 'filter.vehicleAttributes.fuelType',
  FILTER_VEHICLE_ATTRIBUTES_MILEAGE = 'filter.vehicleAttributes.mileage',
  FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE = 'filter.vehicleAttributes.bodyType',
  FILTER_MOTORCYCLE_ATTRIBUTES_MILEAGE = 'filter.motorcycleAttributes.mileage',
  FILTER_YEAR_RANGE = 'filter.yearRange',
  FILTER_MAKE_ID = 'filter.makeId',
  FILTER_DAYS_IN_STOCK = 'filter.daysInStock',
  FILTER_MAKE_ID_EXCLUDED = 'filter.makeIdExcluded',
  FILTER_MODEL_ID = 'filter.modelId',
  FILTER_SUBMODEL_ID = 'filter.subModelId',
  FILTER_STATUS = 'filter.status',
  // Filter Flags
  FILTER_MAPPED = 'filter.mapped',
  FILTER_SHOW_WEB = 'filter.showWeb',
  FILTER_AS_IS = 'filter.asIs',
  FILTER_CERTIFIED = 'filter.certified',
  FILTER_DEMO = 'filter.demo',
  FILTER_FEATURED = 'filter.featured',
  FILTER_ON_ONDER = 'filter.onOrder',
  FILTER_IN_TRANSIT = 'filter.inTransit',
  FILTER_COMPLETE = 'filter.complete',
  FILTER_HAS_PRICE = 'filter.hasPrice',
  FILTER_HAS_MILEAGE = 'filter.hasMileage',
}

export enum InitialItemSortingBuilderFields {
  USE_CUSTOM_SORT_RULE = '_useCustomSortRule',
  INITIAL_ITEM_SORTING = 'initialItemSorting',
  ADD_SORTING_RULE = '_addSortingRule',
}

export const CUSTOM_ORDER_QUERY_VAR = 'customOrder';
export const COLUMN_ID_QUERY_VAR = 'columnId';

export type SortOption = {
  id: string;
  name: WebsiteSortOption['name'];
  data: Omit<WebsiteSortOption, 'customOrderOptions' | 'name'> & {
    customOrderOptions?: SelectOption[] | null;
  };
};

export type WebsiteDetails = WebsiteDetailsContainerQuery['item'];
