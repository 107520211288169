import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Rooftop, RooftopPackageFeature, RooftopSettings } from 'enums/columns/rooftop';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { STATIC_CONFIGS } from './staticTableConfig';

// TODO: [#1123] determine default sort order
export const rooftopsDefaultSortOrder = [];

export const Rooftops = Object.freeze<TableCellData[]>([
  ...STATIC_CONFIGS,
  getCellDataByItemColumnType(Rooftop.WHITE_LABEL_NAME, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.GROUP_NAME, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.NAME, RooftopSettings, { width: 320 }),
  getCellDataByItemColumnType(Rooftop.ACCOUNT_REP_NAME, RooftopSettings, { width: 200 }),
  getCellDataByItemColumnType(Rooftop.ADDRESS, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.CITY, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.ZIP_CODE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.REGION, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.REGION_CODE, RooftopSettings),
  getCellDataByItemColumnType(Rooftop.COUNTRY, RooftopSettings),
  getCellDataByItemColumnType(Rooftop.COUNTRY_CODE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.PLACE_ID, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.LOCALE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.TIME_ZONE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.IS_OPEN, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.ACTIVE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.WEBSITE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.PHONE_NUMBER, RooftopSettings),
  getCellDataByItemColumnType(Rooftop.FAX_NUMBER, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.EMAIL_BILLING, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.EMAIL_GENERAL, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.DESCRIPTION, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.CREATED, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.UPDATED, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(Rooftop.PACKAGE, RooftopSettings, { enabled: false }),
  getCellDataByItemColumnType(RooftopPackageFeature.OEM_NAME, RooftopSettings, { enabled: false }),
]);
