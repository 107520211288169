import type { ForwardRefExoticComponent, InputHTMLAttributes, ReactNode, TextareaHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components/macro';

import { LabelCSS } from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import { DISABLED_INPUT_OPACITY } from 'styles/forms';
import { BLUE_500, NEUTRAL_0, NEUTRAL_050, NEUTRAL_300, RED_050, RED_500, SPACE_200 } from 'styles/tokens';
import {
  FONT_SIZE_14,
  FONT_WEIGHT_BOLDER,
  FONT_WEIGHT_NORMAL,
  LETTER_SPACING_DENSE,
  LINE_HEIGHT_CONDENSED_ULTRA,
} from 'styles/typography';

type InputType = 'text' | 'hidden' | 'password' | 'number' | 'tel';

/*
 * =====================================================================
 * Elements ------------------------------------------------------------
 */

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputPrefix = styled(Text)<{ isInteractive?: boolean }>`
  font-weight: ${FONT_WEIGHT_BOLDER};
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-7px);
  user-select: none;
  pointer-events: ${props => (props.isInteractive ? 'auto' : 'none')};
  cursor: ${props => (props.isInteractive ? 'pointer' : 'auto')};
`;

export const InputSuffix = styled(Text)<{ isInteractive?: boolean; colour?: string }>`
  font-weight: ${FONT_WEIGHT_BOLDER};
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-7px);
  user-select: none;
  pointer-events: ${props => (props.isInteractive ? 'auto' : 'none')};
  cursor: ${props => (props.isInteractive ? 'pointer' : 'auto')};
  ${props =>
    props.colour &&
    css`
      color: ${props.colour};
    `}
`;

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  autoFocus?: boolean;
  theme?: string;
  active?: boolean;
  invalid?: boolean;
  ref?: any;
  value?: any;
  disabled?: boolean;
  type?: InputType;
  label?: string | ReactNode;
  field?: {
    name?: string; // Used as the element id and for attribute of the label
  };
  name?: string; // Used as the element id and for attribute of the label
  isLabel?: boolean;
};

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  active?: boolean;
  field?: {
    name?: string; // Used as the element id and for attribute of the label
  };
  name?: string; // Used as the element id and for attribute of the label
  invalid?: boolean;
  label?: string | ReactNode;
  theme?: string;
  type?: InputType;
};
export const InputCSS = css`
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  color: ${BODY_TEXT};
  font-size: ${FONT_SIZE_14};
  font-weight: ${FONT_WEIGHT_NORMAL};
  line-height: ${LINE_HEIGHT_CONDENSED_ULTRA};
  letter-spacing: ${LETTER_SPACING_DENSE};

  :disabled {
    opacity: ${DISABLED_INPUT_OPACITY};
    & + ${InputPrefix}, & + ${InputSuffix} {
      opacity: ${DISABLED_INPUT_OPACITY};
    }
  }

  /* stylelint-disable -- Hide arrows/spinners from number input */
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */
  ::placeholder {
    color: ${BODY_TEXT_TERTIARY};
  }

  :placeholder-shown {
    text-overflow: ellipsis;
  }
`;

export const Input = styled.input<Props>`
  ${InputCSS}
  ${props => props.theme !== 'none' && baseTheme} /** Apply base theme if the 'theme' prop is not set to 'none' */
  ${props => props.invalid && invalidInput} /** Highlight red if invalid */
  ${props => props.active && activeInput} /** If active highlight text, InputPrefix */
  /* Hide IE11 putting by default a close icon to clear inputs, and a reveal eye icon to show password inputs */
  ::-ms-clear {
    display: none;
  }

  ::-ms-reveal {
    display: none;
  }
  ${props =>
    props.isLabel &&
    css`
      margin: 12px 0 20px;
    `};
`;

export const TextArea = styled.textarea<TextAreaProps>`
  ${InputCSS}
  ${props => props.theme !== 'none' && baseTheme} /** Apply base theme if the 'theme' prop is not set to 'none' */
  ${props => props.invalid && invalidInput} /** Highlight red if invalid */
  ${props => props.active && activeInput} /** If active highlight text, InputPrefix */
  color: ${BODY_TEXT};
  height: 100%;
  padding: 12px 18px;
  resize: none;
  ${props =>
    props.label &&
    css`
      margin: 12px 0 20px;
    `};
`;

/*
 * =====================================================================
 * Shared styles -------------------------------------------------------
 */

export const baseTheme = css`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: ${NEUTRAL_050};
  border-radius: 8px;
  border: 1px solid ${NEUTRAL_300};
  margin-top: ${SPACE_200};
  padding: 8px 16px;
`;

export const activeInput = css`
  color: ${BLUE_500};
  background: ${NEUTRAL_0};
  border: 1px solid ${BLUE_500};

  & + ${InputPrefix}, & + ${InputSuffix} {
    color: ${BLUE_500};
  }
`;

export const invalidInput = css`
  color: ${RED_500};
  background: ${RED_050};
  border: 1px solid ${RED_500};
  & + ${InputPrefix}, & + ${InputSuffix} {
    color: ${RED_500};
  }
`;

const LabelStyle = styled.label<Props>`
  ${LabelCSS}
  ${props =>
    props.invalid &&
    css`
      color: ${RED_500};
    `}
`;

interface InputTextAreaProps extends TextAreaProps, Pick<Props, 'ref'> {}

export const InputTextArea = forwardRef(
  ({ field = { name: '' }, label = '', invalid = false, ...props }: InputTextAreaProps, ref) => {
    const { name } = field;

    return (
      <>
        {label && (
          <LabelStyle htmlFor={name} invalid={invalid}>
            {label}
          </LabelStyle>
        )}
        <TextArea ref={ref} id={name} {...field} invalid={invalid} label={label} {...props} className={undefined} />
      </>
    );
  }
);

/**
 * Component to capture user text input.
 * TODO: Enable controlled component functionality (See EBlock).
 */

const InputText: ForwardRefExoticComponent<Pick<Props, any>> = forwardRef(
  (
    {
      'data-testid': dataTestId,
      field = { name: undefined },
      label = '',
      type = 'text',
      invalid = false,
      name,
      placeholder,
      ...props
    },
    ref
  ) => (
    <InputContainer {...props} onChange={undefined}>
      {/* If label is used */}
      {label && type !== 'hidden' && (
        <LabelStyle htmlFor={field?.name || name} invalid={invalid}>
          {label}
        </LabelStyle>
      )}
      {/* TODO: [#1900] Refactor once we have updated designs for validation messaging */}
      <Input
        data-testid={dataTestId}
        id={field?.name || name}
        {...field}
        type={type}
        invalid={invalid}
        isLabel={!!label}
        placeholder={placeholder}
        {...props}
        className={undefined}
        ref={ref}
      />
    </InputContainer>
  )
);

export default InputText;
