import { gql } from '@apollo/client';

import { rooftopDepartment } from 'store/api/graph/fragments/rooftopDepartment';

export const rooftopDepartmentCreate = gql`
  mutation RooftopDepartmentCreate(
    $rooftopId: ID!
    $email: String
    $hours: [OpenHoursInput!]
    $phoneNumber: String
    $type: RooftopDepartmentType
    $location: LocationInput
  ) {
    data: rooftopDepartmentCreate(
      rooftopId: $rooftopId
      email: $email
      hours: $hours
      phoneNumber: $phoneNumber
      type: $type
      location: $location
    ) {
      ...RooftopDepartmentFragment
    }
  }
  ${rooftopDepartment}
`;

export const rooftopDepartmentModify = gql`
  mutation RooftopDepartmentModify(
    $id: ID!
    $email: String
    $hours: [OpenHoursInput!]
    $phoneNumber: String
    $type: RooftopDepartmentType
    $location: LocationInput
    $_clear: [RooftopDepartmentModifyParameter!]
  ) {
    data: rooftopDepartmentModify(
      id: $id
      email: $email
      hours: $hours
      phoneNumber: $phoneNumber
      type: $type
      location: $location
      _clear: $_clear
    ) {
      ...RooftopDepartmentFragment
    }
  }
  ${rooftopDepartment}
`;

export const rooftopDepartmentDelete = gql`
  mutation RooftopDepartmentDelete($id: ID!) {
    rooftopDepartmentDelete(id: $id)
  }
`;
