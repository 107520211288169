import { useMemo } from 'react';

import { gql } from '@apollo/client';

import { useUser } from 'hooks/contexts/useUser';
import { useQuery } from 'hooks/useQuery';
import type { CondensedListSubscriptionConfig } from 'hooks/useSubscription';
import { useCondensedListSubscription } from 'hooks/useSubscription';
import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { leadActivityDetail } from 'store/api/graph/fragments/leadActivityDetail';
import { leadActivityList } from 'store/api/graph/fragments/leadActivityList';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { userName } from 'store/api/graph/fragments/userName';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';

export const leadActivityDetailsQuery = gql`
  query LeadActivityDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: leadActivity(id: $id) {
      id
      ...LeadActivityDetailFragment
    }
    metadata {
      ...ColorMetadataFragment
    }
  }
  ${leadActivityDetail}
  ${colorMetadata}
`;

export const leadActivityCondensedListQuery = gql`
  query LeadActivityCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: LeadActivityConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: leadActivityConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...LeadActivityListFragment
        }
      }
    }
  }
  ${leadActivityList}
  ${pageInfo}
`;

export const leadActivityContainerQuery = gql`
  query LeadActivityConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: LeadActivityConnectionFilterInput
    $d_groupNameOn: Boolean!
    $d_rooftopNameOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: leadActivityConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...LeadActivityListFragment
          groupName: rooftop @include(if: $d_groupNameOn) {
            id
            group {
              id
              name {
                ...MultilingualStringFragment
              }
            }
          }
          rooftopName: rooftop @include(if: $d_rooftopNameOn) {
            id
            name {
              ...MultilingualStringFragment
            }
          }
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${MultilingualString}
  ${userName}
  ${leadActivityList}
`;

const leadActivityListSubscriptionQuery = gql`
  subscription LeadActivityListSubscriptionQuery {
    item: onLeadActivityConnectionUpdated {
      id
      previousId
      updatedItem: leadActivity {
        ...LeadActivityListFragment
      }
    }
  }
  ${leadActivityList}
`;

export const leadActivityListSubscriptionConfig: CondensedListSubscriptionConfig = {
  subscriptionQuery: leadActivityListSubscriptionQuery,
  ignoredFilters: ['archived', 'status', 'leadSpam'],
  sortBy: { target: 'updated', dir: 'desc' },
};

/** List of dynamic/optional fields used in the LeadActivityConnectionQuery */
const LeadActivityContainerQueryDynamicFields = ['groupName', 'rooftopName'];

export const useLeadActivityConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const formattedConnectionParams = parseConnectionParams(variables);
  const response = useQuery(leadActivityContainerQuery, {
    variables: formattedConnectionParams,
    options,
  });
  const {
    refetch,
    data: { connection },
    isLoaded,
  } = useMemo(() => response, [response]);
  const { isWhiteLabelScoped } = useUser();

  /**
   * LeadActivity route does not have a table view, so all dynamic columns can be disabled when configuring the
   * condensed list subscription with the LeadActivity container query (leadActivityContainerQuery)
   */
  for (const field of LeadActivityContainerQueryDynamicFields) {
    formattedConnectionParams[`d_${field}On`] = false;
  }

  useCondensedListSubscription(
    leadActivityListSubscriptionConfig,
    formattedConnectionParams,
    leadActivityContainerQuery,
    connection?.edges.map(edge => edge.node),
    refetch,
    !isWhiteLabelScoped && isLoaded
  );

  return response;
};
