import styled, { css } from 'styled-components/macro';

import { variants } from 'utils/styledUtils';

// Custom grid behaviour, based off of css grid
export enum FlexGridType {
  AUTO_FIT = 'AUTO_FIT',
  AUTO_FILL = 'AUTO_FILL',
}

export interface FlexGridProps {
  /** Simulates `grid-gap` */
  itemGap: number;
  /** Simulates the min arg of `minmax` */
  itemWidth: number;
  /** Simulates the total auto generated grid columns in a grid */
  numColumns: number;
  /** Type of css grid `repeat()` behaviour */
  type: FlexGridType;
}

/**
 * Simulates a css grid using flexbox.
 * Usually used in conjunction with the `useFlexGrid` custom hook.
 */
export const FlexGrid = styled.div<FlexGridProps>`
  /** Grid container */
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;

  /** Grid items */
  > * {
    height: auto;

    /** Add right margin to all items, except end of a row */
    &:not(:nth-child(${({ numColumns }) => numColumns}n)) {
      margin-right: ${({ itemGap }) => `${itemGap}px`};
    }

    /** Add top margin to all items, except first row */
    &:nth-child(n + ${({ numColumns }) => numColumns + 1}) {
      margin-top: ${({ itemGap }) => `${itemGap}px`};
    }

    ${({ itemWidth }) =>
      variants<FlexGridType>('type', {
        [FlexGridType.AUTO_FILL]: css`
          flex: 1;

          &:last-of-type {
            margin-right: 0;
          }
        `,
        [FlexGridType.AUTO_FIT]: css`
          width: ${itemWidth}px;
        `,
      })}
  }

  :empty {
    display: none;
  }
`;
