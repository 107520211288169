import { get, isEmpty } from 'lodash-es';

import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { StepFieldType } from 'enums/stepFieldType';
import type {
  LeadDetailsContainerQuery,
  LeadMetaQuery,
  LeadModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { defineFieldValues, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import {
  calculateTotalDuration,
  DURATION_MONTHS_CUSTOM_ERROR,
  DURATION_YEARS_CUSTOM_ERROR,
  durationAsMonthsRemainder,
  durationAsYearsTruncated,
  validateDurationFields,
} from 'utils/leadUtils';

import { LeadPreviousEmploymentInformationBuilderFields } from './interfaces';

const { t } = translate;

type LeadDetails = LeadDetailsContainerQuery['item'];

class LeadPreviousEmploymentInformationStep extends StepComponentCore<
  LeadDetails,
  LeadMetaQuery,
  LeadModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<LeadDetails, LeadMetaQuery, LeadModifyMutationVariables>,
    _context: CreateModifyContextInterface<LeadDetails, LeadMetaQuery>
  ) {
    super(props);
    const {
      tier: { data, activeStep, metadata },
    } = props;

    const durationMonths = data.previousEmployment?.durationMonths;

    // Convert the duration fields to the correct format
    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [LeadPreviousEmploymentInformationBuilderFields.DURATION_MONTHS]: {
        selectedValue: durationAsMonthsRemainder(durationMonths),
        customError: DURATION_MONTHS_CUSTOM_ERROR(t),
      },
      [LeadPreviousEmploymentInformationBuilderFields.DURATION_YEARS]: {
        selectedValue: durationAsYearsTruncated(durationMonths),
        customError: DURATION_YEARS_CUSTOM_ERROR(t),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data, metadata);
  }

  /**
   * Skip this step if nothing was changed, and the data was initially blank;
   * things should only be validated if at least one value was changed.
   * Used to prevent client-side validation, and server-side sending of data.
   */
  shouldSkip() {
    const {
      tier: { data },
    } = this.props;

    return (
      (!data || this.fields.every(field => isEmpty(get(data, field.queryVar)))) &&
      this.fields.every(field =>
        isEmpty(field.groupType === StepFieldType.DROPDOWN ? field.selectedValue?.id : field.selectedValue)
      )
    );
  }

  validateFields() {
    // Skip validation if the step should be skipped based on requirements in `shouldSkip()`
    if (this.shouldSkip()) {
      return true;
    }

    const durationErrors = validateDurationFields(
      this.fields,
      LeadPreviousEmploymentInformationBuilderFields.DURATION_MONTHS,
      LeadPreviousEmploymentInformationBuilderFields.DURATION_YEARS
    );

    if (durationErrors) {
      this.setTier({ errors: durationErrors });
      return false;
    }

    return super.validateFields();
  }

  /** Enable other steps for edit/navigation */
  enableSteps() {
    const {
      tier: { steps },
    } = this.props;

    for (const step of steps!) {
      step.isEnabled = true;
    }
  }

  async save() {
    // Replace durationMonths with the total computed value
    const durationMonths = calculateTotalDuration(
      this.fields,
      LeadPreviousEmploymentInformationBuilderFields.DURATION_MONTHS,
      LeadPreviousEmploymentInformationBuilderFields.DURATION_YEARS
    );

    const success = await super.save(undefined, {
      previousEmployment: {
        durationMonths,
      },
    });

    if (!success) {
      return false;
    }

    this.enableSteps();
    return true;
  }
}

export default LeadPreviousEmploymentInformationStep;
