import { css } from 'styled-components/macro';

import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import LeadListItem from 'components/ui/lists/LeadListItem';
import { WarrantyListItem } from 'components/ui/lists/WarrantyListItem';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

import { TradeCaptureBuilderFields } from './interfaces';

const CaptureFields: StepFields = {
  [TradeCaptureBuilderFields.ROOFTOP]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    containerStyles: css`
      padding-bottom: 8px;
    `,
    hasSeparator: true,
  },
  [TradeCaptureBuilderFields.CAPTURE_METHOD]: {
    label: 'capture_method',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    overrideSubStepRenderElement: WarrantyListItem,
    subStep: [SubStepType.DEFAULT],
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
    settings: {
      hideSearchHeader: true,
    } as ListSelectionSettings,
    forceRequired: true,
  },
  [TradeCaptureBuilderFields.LEAD]: {
    label: 'lead_one',
    queryAlias: 'leadId',
    groupType: StepFieldType.RENDER_OBJECT,
    renderElement: LeadListItem,
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.LEAD_CREATE],
    required: true,
  },
  [TradeCaptureBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    groupType: StepFieldType.PHONE_NUMBER,
    displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
    required: true,
  },
  [TradeCaptureBuilderFields.EMAIL_ADDRESS]: {
    label: 'email_one',
    displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
    required: true,
  },
  [TradeCaptureBuilderFields.COMMUNICATION_PREFERENCE]: {
    label: 'communication_preference',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT],
    displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
    required: false,
  },
};

export default CaptureFields;
