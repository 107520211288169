import type { MultilingualString } from 'store/api/graph/interfaces/types';

export enum ShoppingToolsDetailsBuilderFields {
  SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM = 'enableShowroom',
  SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE = 'enableBuildAndPrice',
  SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_TRIMS = 'shoppingToolsConfiguration.includeCommercialTrims',
  SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENT = 'shoppingToolsConfiguration.includeBulkAdjustments',
  SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES = 'shoppingToolsConfiguration.includeIncentives',
  SHOPPING_TOOLS_CONFIG_MAKES = 'shoppingToolsConfiguration.makeIds',
  SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS = 'shoppingToolsConfiguration.showFinancePayments',
  SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS = 'shoppingToolsConfiguration.showLeasePayments',
  SHOPPING_TOOLS_CONFIG_YEAR_MODELS = 'shoppingToolsConfiguration.yearModels',
  SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE = 'shoppingToolsConfiguration.showroomConfiguration.disclaimerTemplate',
  SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE = 'shoppingToolsConfiguration.buildAndPriceConfiguration.disclaimerTemplate',
}

export enum ShoppingToolsTypes {
  SHOWROOM = 'showroom',
  BUILD_AND_PRICE = 'buildAndPrice',
}

/** This is the interface for the list of model options categorized by year */
export interface YearModelOption {
  /* The unique id for this option */
  id: string;
  /* The year of this model */
  year: number;
  /* The id of this model */
  modelId: string;
  /* The id of the make for this model */
  makeId: string;
  /* The category label of this model (list is categorized by a year label) */
  category: string;
  /* The display name for this model */
  name: MultilingualString;
}
