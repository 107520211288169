import type { HTMLAttributes, ReactNode } from 'react';
import { useCallback, useEffect } from 'react';

import type { ComputePositionConfig, FlipOptions } from '@floating-ui/react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import { getMarkRange, posToDOMRect } from '@tiptap/core';
import type { Editor, EditorEvents } from '@tiptap/core';
import styled from 'styled-components/macro';

import { BaseDialogContainer, CONTENT_PADDING } from 'components/ui/editor/constants/styles';
import { getSelectionRange } from 'components/ui/editor/helpers/selection';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';

const DialogContainer = styled.div`
  ${BaseDialogContainer};
  padding: 8px;
`;

interface Props
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ComputePositionConfig, 'placement'>,
    Pick<FlipOptions, 'fallbackPlacements'> {
  /**
   * The children of the dialog
   */
  children: ReactNode;
  /**
   * Whether the dialog is open
   */
  isOpen: boolean;
  /**
   * A function that determines whether the menu should be shown or not.
   * If this function returns `false`, the menu will be hidden, otherwise it will be shown.
   */
  shouldShowOnTextSelection?: (editor: Editor) => boolean;
}

const BubbleMenu = ({
  children,
  fallbackPlacements,
  isOpen,
  shouldShowOnTextSelection = () => true,
  placement,
  ...props
}: Props) => {
  const { contentRef, editor } = useRichTextEditor();
  const { x, y, refs, strategy } = useFloating({
    middleware: [
      offset(4),
      flip({
        padding: CONTENT_PADDING,
        boundary: contentRef?.current || undefined,
        fallbackPlacements,
      }),
      shift({ boundary: contentRef?.current || undefined, padding: CONTENT_PADDING }),
    ],
    placement,
    strategy: 'absolute',
    whileElementsMounted: autoUpdate,
  });

  /**
   * Set the text selection reference
   */
  const setTextSelectionReference = useCallback(
    ({ editor }: Pick<EditorEvents['selectionUpdate'], 'editor'>) => {
      if (!shouldShowOnTextSelection(editor)) {
        return;
      }

      refs.setReference({
        getBoundingClientRect() {
          const { from, to } = getSelectionRange(editor.state.selection);

          const range = getMarkRange(editor.view.state.doc.resolve(from), editor.view.state.schema.marks.link);
          if (range) {
            return posToDOMRect(editor.view, range.from, range.to);
          }

          return posToDOMRect(editor.view, from, to);
        },
      });
    },
    [shouldShowOnTextSelection, refs]
  );

  /**
   * Update the reference element when the editor state changes
   */
  useEffect(() => {
    editor?.on('selectionUpdate', setTextSelectionReference);

    return () => {
      editor?.off('selectionUpdate', setTextSelectionReference);
    };
  }, [editor, setTextSelectionReference]);

  if (!isOpen) {
    return null;
  }

  return (
    <DialogContainer {...props} ref={refs.setFloating} style={{ left: x, position: strategy, top: y }}>
      {children}
    </DialogContainer>
  );
};

export type BubbleMenuProps = Props;
export default BubbleMenu;
