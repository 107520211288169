import { gql } from '@apollo/client';

import { shoppingToolsConfiguration } from 'store/api/graph/fragments/shoppingToolsConfiguration';

export const shoppingToolsModify = gql`
  mutation ShoppingToolsModifyMutation(
    $id: ID!
    $shoppingToolsConfiguration: ShoppingToolsConfigurationInput
    $_clear: [RooftopModifyParameter!]
  ) {
    data: rooftopModify(id: $id, shoppingToolsConfiguration: $shoppingToolsConfiguration, _clear: $_clear) {
      shoppingToolsConfiguration {
        ...ShoppingToolsConfigurationFragment
      }
    }
  }
  ${shoppingToolsConfiguration}
`;
