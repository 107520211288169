import type stepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getLenderOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type {
  CreditApplicationCreateMutationVariables,
  LeadDetailsContainerQuery,
  Lender,
} from 'store/api/graph/interfaces/types';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import { CreditApplicationBuilderFields } from './interfaces';
/*
 * Funding options are hardcoded because only one option exists at this time.
 * The BE does not take in a funding parameter.
 */
const DEALERTRACK_FUNDING_OPTION_ID = 'DEALERTRACK';

class DetailsStep extends StepComponentCore<
  LeadDetailsContainerQuery['item']['creditApplications'],
  { metadata: undefined },
  CreditApplicationCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<
      LeadDetailsContainerQuery['item']['creditApplications'],
      { metadata: undefined },
      CreditApplicationCreateMutationVariables
    >,
    _context: CreateModifyContextInterface
  ) {
    super(props);

    const {
      tier: { data, activeStep, seededData },
    } = props;

    const lead: LeadResponseType | undefined = seededData?.leadName;

    /*
     * The builder will not work without a lead being seeded,
     * which would be a development error, so throw one before proceeding if necessary
     */
    if (!lead) {
      throw new Error('The `leadName` property is necessary in seededData.');
    }
    const fundingByOptions: StepFieldOptions[] = [
      { id: DEALERTRACK_FUNDING_OPTION_ID, name: translate.t('dealertrack') },
    ];

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [CreditApplicationBuilderFields.FUNDING_BY]: {
        options: fundingByOptions,
        selectedValue: DEALERTRACK_FUNDING_OPTION_ID,
      },
    });

    this.fields = defineFieldValues(this.fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [CreditApplicationBuilderFields.LENDER_ID]: {
        request: () => getLenderOptions(lead.rooftopName.regionCode.value),
        disableKeywordRefetch: true,
      },
      [CreditApplicationBuilderFields.PRODUCT_CODE]: {
        request: keyword => this.getProductOptions(keyword),
      },
    };
  }

  /** When the Lender field is changed, clear the selected Product */
  onFieldSelection(
    stepField: stepField,
    value: Lender | undefined,
    persistSeededValues?: boolean,
    advance?: boolean
  ): void {
    if (stepField.queryVar === CreditApplicationBuilderFields.LENDER_ID) {
      const selectedLender: Lender | undefined = getStepField<Lender>(
        CreditApplicationBuilderFields.LENDER_ID,
        this.fields
      ).selectedValue;
      const productField = getStepField(CreditApplicationBuilderFields.PRODUCT_CODE, this.fields);
      productField.selectedValue = undefined;
      if (value?.id === selectedLender?.id) {
        productField.displayType = setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: true });
        super.onFieldSelection(stepField, value, persistSeededValues, false);
      } else {
        productField.displayType = undefined;
        super.onFieldSelection(stepField, value, persistSeededValues, true);
      }
    } else {
      super.onFieldSelection(stepField, value, persistSeededValues, advance);
    }
  }

  /** Generate product options based on the selected Lender's data  */
  private async getProductOptions(keyword: string): Promise<StepFieldOptions[]> {
    const selectedLender = getStepField(CreditApplicationBuilderFields.LENDER_ID, this.fields).selectedValue;
    if (selectedLender) {
      const lenderData: Lender = selectedLender.data;
      const options = lenderData.productCodes.map((code, i) => ({
        id: code,
        name: lenderData.productCodesNames[i],
      }));
      return keyword ? options.filter(o => o.name.includes(keyword)) : options;
    }
    return [];
  }

  async save() {
    const {
      tier: { seededData },
    } = this.props;

    const lead: LeadResponseType | undefined = seededData?.leadName;

    return super.save({ leadId: lead?.id });
  }
}

export default DetailsStep;
