export enum AppointmentDetailsBuilderFields {
  ROOFTOP_ID = 'rooftopId',
  TITLE = 'title',
  DATE = 'date',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  REMINDER = 'reminder',
  LOCATION = 'location',
  NOTE = 'note',
  TAG_IDS = 'tagIds',
}
