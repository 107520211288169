import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { mailboxDetailsQuery } from 'containers/mailbox/MailboxContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const MailboxQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const response = await client.query({
      query: mailboxDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['MailboxCondensedListQuery', 'RooftopDetailsContainerQuery', 'MailboxDetailsContainerQuery'],
};

export default MailboxQueryMethods;
