import { useCallback, useRef } from 'react';

import { useMountEffect } from 'hooks/useMountEffect';
/**
 * Invokes a callback one time after component mount but only after a condition has been met
 * Useful for waiting on a DOM node to be available before invoking a callback
 *
 * @param callback The callback to invoke
 * @param conditionFunction Invokes callback when this function returns true
 */
export const useConditionalMountEffect = (callback: () => Promise<void> | void, conditionFunction: () => boolean) => {
  const frameRef = useRef<number>();
  const checkCondition = useCallback(() => {
    frameRef.current = requestAnimationFrame(checkCondition);

    if (conditionFunction()) {
      cancelAnimationFrame(frameRef.current);
      void callback();
    }
  }, [callback, conditionFunction]);

  return useMountEffect(() => {
    frameRef.current = requestAnimationFrame(checkCondition);

    return () => cancelAnimationFrame(frameRef.current as number);
  });
};
