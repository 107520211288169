import { forwardRef } from 'react';

import styled from 'styled-components/macro';

import DraggableIcon from 'components/ui/icons/DraggableIcon';
import { Clickable } from 'components/ui/shared/Button';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT } from 'styles/color';

const DragIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DragClickable = styled(Clickable)`
  padding: 12px;
  cursor: grab;
  height: 100%;
`;

const DraggableHandle = forwardRef<HTMLButtonElement>((_, ref) => (
  <DragIconContainer>
    <DragClickable data-testid={ElementTestId.LIST_SELECTION_DRAG_ICON} ref={ref}>
      <DraggableIcon color={BODY_TEXT} />
    </DragClickable>
  </DragIconContainer>
));

export default DraggableHandle;
