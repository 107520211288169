import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { CreditApplicationCreate } from './CreditApplicationCreateModifyQuery';
import CreditApplicationQueryMethods from './CreditApplicationQueryMethods';
import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

export enum CreditApplicationBuilderStep {
  DETAILS = 'LENDER_DETAILS',
}

const CreditApplicationBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.CREDIT_APPLICATION_CREATE]: {
    refetchQueries: CreditApplicationQueryMethods.refetchQueries,
    getMetaData: CreditApplicationQueryMethods.getMetaData,
    legend: [StepLegendNav],
    resourceType: ResourceType.LEADS,
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }],
    mutations: {
      create: CreditApplicationCreate,
    },
    steps: [
      {
        id: CreditApplicationBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'lender_details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
  },
};

export default CreditApplicationBuilders;
