import { gql } from '@apollo/client';

import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const calculateCashPaymentQuery = gql`
  query CalculateCashPayment(
    $disclaimerTemplate: MultilingualStringInput!
    $lienAmount: Int! = 0
    $purchasePrice: Int!
    $taxRate: BigDecimal
    $tradeInPrice: Int! = 0
    $freight: Int! = 0
    $ending: Date
  ) {
    calculateCashPayment(
      disclaimerTemplate: $disclaimerTemplate
      lienAmount: $lienAmount
      purchasePrice: $purchasePrice
      taxRate: $taxRate
      tradeInPrice: $tradeInPrice
      freight: $freight
      ending: $ending
    ) {
      disclaimer {
        ...MultilingualStringFragment
      }
      totalObligation {
        ...MonetaryAmountFragment
      }
      taxAmount {
        ...MonetaryAmountFragment
      }
    }
  }
  ${monetaryAmount}
  ${MultilingualString}
`;
