import {
  rooftopRetailLeasePricingCreate,
  rooftopRetailLeasePricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/leasePricing/RooftopLeaseSegmentCreateModifyQuery';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';

class FiltersStep extends RetailFilterStep {
  async save(): Promise<boolean> {
    const {
      tier: { isCreating },
    } = this.props;

    return super.save(isCreating ? rooftopRetailLeasePricingCreate : rooftopRetailLeasePricingModify);
  }
}

export default FiltersStep;
