import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { WebsiteResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';
import { fullGroupOrAboveScopes } from 'utils/permissionUtils';
import { openNewTab } from 'utils/urlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: WebsiteResponseType;
}

const WebsiteMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const {
    user: { scope },
    hasPermissions,
  } = useUser();
  const { id } = item || {};
  const { toggleTier } = useCreateModify();

  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.WEBSITE_MODIFY,
    entityType: EntityType.WEBSITE,
    title: t('modify_x', [t('website_one')]),
    isCreating: false,
    itemId: id,
    seededData: {
      website: item,
    },
  });

  const onCreateRouteClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.WEBSITE_ROUTE_CREATE,
    itemId: id,
    seededData: {
      website: item,
    },
    title: t('route_other'),
    isCreating: true,
    entityType: EntityType.WEBSITE,
  });

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled:
        !hasPermissions(builderConfig[BuilderType.WEBSITE_MODIFY].requiredPermissions) ||
        !fullGroupOrAboveScopes.includes(scope),
    },
    {
      label: t('create_x', [t('route_one')]),
      onClick: onCreateRouteClicked,
      disabled:
        !hasPermissions(builderConfig[BuilderType.WEBSITE_MODIFY].requiredPermissions) ||
        !fullGroupOrAboveScopes.includes(scope),
    },
    {
      label: t('view_website'),
      onClick: () => openNewTab(item.url),
      disabled: false,
    },
  ];

  return <MenuItems items={menuItemsConfig} />;
};

export default WebsiteMenuItems;
