import { gql } from '@apollo/client';

import { tradeInAppraisalExternal } from 'store/api/graph/fragments/tradeInAppraisalExternal';
import { tradeInAppraisals } from 'store/api/graph/fragments/tradeInAppraisals';
import { tradeInGuaranteedTradeValue } from 'store/api/graph/fragments/tradeInGuaranteedTradeValue';

export const tradeInGuaranteedValueCreate = gql`
  mutation TradeInGuaranteedValueCreateMutation($tradeInItemId: ID, $amount: Int, $comments: String) {
    data: tradeInGuaranteedValueCreate(tradeInItemId: $tradeInItemId, amount: $amount, comments: $comments) {
      id
      guaranteedValue {
        ...TradeInGuaranteedTradeValueFragment
      }
      appraisals {
        ...TradeInAppraisalsFragment
      }
      pendingAppraisals {
        ...TradeInAppraisalExternalFragment
      }
    }
  }
  ${tradeInGuaranteedTradeValue}
  ${tradeInAppraisals}
  ${tradeInAppraisalExternal}
`;

export const tradeInGuaranteedValueModify = gql`
  mutation TradeInGuaranteedValueModifyMutation(
    $id: ID!
    $amount: Int
    $comments: String
    $_clear: [TradeInGuaranteedValueModifyParameter!]
  ) {
    data: tradeInGuaranteedValueModify(tradeInItemId: $id, amount: $amount, comments: $comments, _clear: $_clear) {
      id
      guaranteedValue {
        ...TradeInGuaranteedTradeValueFragment
      }
      appraisals {
        ...TradeInAppraisalsFragment
      }
      pendingAppraisals {
        ...TradeInAppraisalExternalFragment
      }
    }
  }
  ${tradeInGuaranteedTradeValue}
  ${tradeInAppraisals}
  ${tradeInAppraisalExternal}
`;
