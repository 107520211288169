import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { GREEN_500, RED_500 } from 'styles/tokens';
export const PurchasePriceAmount = styled(PrimaryText)<{ purchasePriceBelowMin: boolean; defaultColor: string }>`
  color: ${props => (props.purchasePriceBelowMin ? RED_500 : props.defaultColor)};
`;

interface RetailItemPurchasePriceDisplayProps {
  /** The formatted purchase price to display */
  purchasePriceFormatted: string;
  /** Whether or not the purchase price is below the minimum display price */
  purchasePriceBelowMin: boolean;
  /** Default color of price display if purchase price is not below minimum display price */
  defaultColor?: string;
}

const RetailItemPurchasePriceDisplay = ({
  purchasePriceFormatted,
  purchasePriceBelowMin,
  defaultColor = GREEN_500,
}: RetailItemPurchasePriceDisplayProps) => (
  <PurchasePriceAmount purchasePriceBelowMin={purchasePriceBelowMin} defaultColor={defaultColor}>
    {purchasePriceFormatted}
  </PurchasePriceAmount>
);
export default RetailItemPurchasePriceDisplay;
