import { memo, type SVGProps } from 'react';

const ArrowTopToBottomIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fill="currentColor"
      d="M10.863 16.852v-9.71l-.787.786a1.054 1.054 0 0 1-.783.323c-.31 0-.58-.114-.808-.342a1.085 1.085 0 0 1-.323-.798c0-.318.108-.584.323-.799l2.713-2.714c.112-.112.237-.193.373-.245.137-.052.28-.077.429-.077.15 0 .292.025.429.077.136.052.26.133.373.245l2.72 2.72c.21.211.316.473.316.786 0 .313-.108.583-.323.811a1.099 1.099 0 0 1-.805.336c-.313 0-.581-.112-.805-.336l-.768-.767v9.704l.793-.786c.211-.211.472-.317.783-.317.31 0 .58.114.808.342.211.21.317.476.317.796 0 .319-.106.584-.317.795l-2.72 2.72a1.053 1.053 0 0 1-.372.245 1.2 1.2 0 0 1-.429.078 1.2 1.2 0 0 1-.429-.078 1.054 1.054 0 0 1-.373-.245l-2.713-2.714a1.112 1.112 0 0 1-.33-.786 1.065 1.065 0 0 1 .33-.811c.211-.215.475-.322.792-.32.318.002.584.109.799.32l.787.761Z"
    />
  </svg>
);
export default memo(ArrowTopToBottomIcon);
