import { gql } from '@apollo/client';

import { integration } from 'store/api/graph/fragments/integration';
import { retailBulkAdjustment } from 'store/api/graph/fragments/retailBulkAdjustment';
import { retailPriceAdjustment } from 'store/api/graph/fragments/retailPriceAdjustment';
import { retailPricing } from 'store/api/graph/fragments/retailPricing';
import { retailPricingConfiguration } from 'store/api/graph/fragments/retailPricingConfiguration';
import { retailPricingFinanceConfiguration } from 'store/api/graph/fragments/retailPricingFinanceConfiguration';
import { retailPricingLeaseConfiguration } from 'store/api/graph/fragments/retailPricingLeaseConfiguration';

import { inventoryItemDetail } from './inventoryItemDetail';
import { leadList } from './leadList';
import { MultilingualString } from './multilingualString';
import { tag } from './tag';
import { trimPhotoFragment } from './trimPhotoFragment';

export const retailItemDetail = gql`
  fragment RetailItemDetailFragment on RetailItem {
    ...InventoryItemDetailFragment
    lockedFields
    listingUrl
    status
    statusName
    stockNumber
    asIs
    showWeb
    demo
    certified
    featured
    onOrder
    inTransit
    highlights {
      ...MultilingualStringFragment
    }
    warranty {
      description
      quebecCategory
      quebecCategoryName
      quebecCategoryOverride
      quebecCategoryOverrideName
      showWeb
    }
    windowStickerUrl

    ## TODO: Add in when modifiable
    ## mapped

    ## TODO: Add in when modifiable
    ## complete
    completePercent

    rooftop {
      id
      name {
        ...MultilingualStringFragment
      }
      group {
        id
      }
      warrantyAvailable
    }

    ## Pricing tab
    price1 {
      amount
      formattedAmountRounded
    }
    cost {
      amount
      formattedAmountRounded
    }
    price2 {
      amount
      formattedAmountRounded
    }
    priceBulkAdjustments: bulkAdjustments(type: PRICE) {
      ...RetailBulkAdjustmentFragment
    }
    overlayBulkAdjustments: bulkAdjustments(type: OVERLAY) {
      ...RetailBulkAdjustmentFragment
    }
    descriptionBulkAdjustments: bulkAdjustments(type: DESCRIPTION) {
      ...RetailBulkAdjustmentFragment
    }
    promotionBulkAdjustments: bulkAdjustments(type: PROMOTION) {
      ...RetailBulkAdjustmentFragment
    }
    disclaimerBulkAdjustments: bulkAdjustments(type: DISCLAIMER) {
      ...RetailBulkAdjustmentFragment
    }
    bulkAdjustments {
      ...RetailBulkAdjustmentFragment
    }
    priceAdjustments {
      ...RetailPriceAdjustmentFragment
    }

    description(adjusted: false) {
      ...MultilingualStringFragment
    }
    descriptionAdjusted: description(adjusted: true) {
      ...MultilingualStringFragment
    }
    tagName: tags {
      ...TagFragment
    }
    msrp {
      amount
      formattedAmountRounded
    }
    integrations {
      created
      rooftopIntegration {
        id
        integrationType: integration {
          id
          type
          value: typeName
        }
        integration {
          ...IntegrationFragment
        }
      }
      updated
    }
    purchaseActivity: leadActivities(type: PURCHASE) {
      id
      lead {
        ...LeadListFragment
      }
      attributesName: attributes {
        ... on PurchaseLeadActivityAttributes {
          purchaseDate
        }
      }
    }

    # For caching purposes, these fields need to be at the bottom of the fragment. The field 'percentageAsAmount' in
    # RetailItemPriceFragment will always return null when querying for all priceAdjustments, and we don't want
    # the value of 'percentageAsAmount' in these fields to be overwritten in the cache with a null value.
    # Builder: Shows list of price adjustments with null percentageAsAmount (because there is no starting price)
    # Details: Shows list with percentageAsAmount populated (because starting price is defined in this pricing type context)
    financeRetailPricing {
      ...RetailPricingFragment
      configurationOverride {
        ...RetailPricingConfigurationFragment
      }
      configuration {
        ...RetailPricingFinanceConfigurationFragment
      }
    }
    leaseRetailPricing {
      ...RetailPricingFragment
      configurationOverride {
        ...RetailPricingConfigurationFragment
      }
      configuration {
        ...RetailPricingLeaseConfigurationFragment
      }
      residualPrice {
        ...MonetaryAmountFragment
      }
      freight {
        ...MonetaryAmountFragment
      }
    }
    cashRetailPricing {
      ...RetailPricingFragment
      configurationOverride {
        ...RetailPricingConfigurationFragment
      }
      configuration {
        ...RetailPricingConfigurationFragment
      }
    }

    ## MEDIA TAB
    trim {
      interiorPhotos: photos(photoType: INTERIOR) {
        ...TrimPhotoFragment
      }
      exteriorPhotos: photos(photoType: EXTERIOR) {
        ...TrimPhotoFragment
      }
    }
  }
  ${MultilingualString}
  ${integration}
  ${inventoryItemDetail}
  ${tag}
  ${retailBulkAdjustment}
  ${retailPriceAdjustment}
  ${retailPricingConfiguration}
  ${retailPricingFinanceConfiguration}
  ${retailPricingLeaseConfiguration}
  ${retailPricing}
  ${leadList}
  ${trimPhotoFragment}
`;
