import { gql } from '@apollo/client';

export const inventoryItemVideo = gql`
  fragment InventoryItemVideoFragment on InventoryItemVideo {
    id
    shotCode
    url
    embeddedUrl
    thumbnailUrl
    source
    sourceName
  }
`;
