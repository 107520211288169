import type { StatusType } from '@datadog/browser-logs';

/**
 * Global file for all client side types.
 *
 * Similar to the BE API `types.ts`.
 *
 * The use of this file:
 *  - Prevents circular dependencies.
 *  - Consolidates all types into one file making it easy to find.
 *  etc.
 */

/**
 * 3rd Party Libs
 */

/**
 * Options to configure logging for our `LoggingService`.
 *
 * Currently our service is Datadog and types are from
 * @datadog/browser-logs/cjs/domain/logger.d.ts
 */
export interface LoggerOptions {
  /** Log message */
  message: string;
  /** Any additional context */
  messageContext?: object;
  /** Type of log message */
  status?: StatusType;
  /** Error object used for more context and categorization in DD */
  error?: Error;
}

/**
 * Used to set a privacy level to mask html elements.
 *
 * More info: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#privacy-options
 */
export const DD_PRIVACY_HTML_ATTRIBUTE = 'data-dd-privacy';
