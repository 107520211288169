import { gql } from '@apollo/client';

export const userName = gql`
  fragment UserNameFragment on User {
    id
    firstName
    lastName
    displayName
    active
  }
`;
