import { gql } from '@apollo/client';

import { selectStringOption } from './selectOption';

export const integration = gql`
  fragment IntegrationFragment on Integration {
    id
    name
    provider
    iconUrl
    type
    typeName
  }
`;

export const integrationTypeFragment = gql`
  fragment IntegrationTypeFragment on IntegrationMetadata {
    type {
      id: value
      name
    }
  }
`;

export const integrationOemAccountExportWebsiteBrandFragment = gql`
  fragment IntegrationOemAccountExportWebsiteBrandFragment on OemAccountExportAttributesMetadata {
    websiteBrand {
      ...SelectStringOptionFragment
    }
  }
  ${selectStringOption}
`;

export const integrationInventoryExportWebsiteBrandFragment = gql`
  fragment IntegrationInventoryExportWebsiteBrandFragment on InventoryExportAttributesMetadata {
    websiteBrand {
      ...SelectStringOptionFragment
    }
  }
  ${selectStringOption}
`;
