import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const descriptionRetailBulkAdjustmentAttributes = gql`
  fragment DescriptionRetailBulkAdjustmentAttributesFragment on DescriptionRetailBulkAdjustmentAttributes {
    text {
      ...MultilingualStringFragment
    }

    placement
    placementName
  }

  ${MultilingualString}
`;
