import { useMemo } from 'react';

import { gql } from '@apollo/client';
import type { DocumentNode } from 'graphql';

import { ApolloFetchPolicy } from 'enums/apollo';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { leadForwardDetail } from 'store/api/graph/fragments/leadForwardDetail';
import type { LeadForward } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

export const rooftopLeadForwardingDetailsQuery = gql`
  query RooftopLeadForwardingDetailsContainerQuery($id: ID!) {
    item: leadForward(id: $id) {
      ...LeadForwardDetailFragment
    }
  }
  ${leadForwardDetail}
`;

export const rooftopLeadForwardingCondensedListQuery = gql`
  query RooftopLeadForwardingCondensedListQuery($id: ID!) {
    rooftop(id: $id) {
      id
      leadForwards {
        ...LeadForwardDetailFragment
      }
    }
  }
  ${leadForwardDetail}
`;

/*
 * When viewing lead forward items in a nested list view, the ListSelection component will search for items
 * based on their 'name' or 'label' field. There is a ticket [#3123] to add more robust searching
 * support to ListSelection, as a workaround for now we'll assign the lead forward email as the 'name' attribute,
 * so users can search for lead forwards by email.
 */
const transformLeadForward = (leadForward: LeadForward) => ({
  ...leadForward,
  name: leadForward?.email,
});

export const useRooftopLeadForwardListQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const adaptedConfig = useMemo(
    () => ({
      ...config,
      options: { fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY },
      // Transforming the query to something usable by the container shell
      queryAdapter: ({ rooftop: { leadForwards } }) => ({
        connection: {
          edges: leadForwards?.map(lead => transformLeadForward(lead)),
        },
      }),
    }),
    [config]
  );

  return useQuery(query, adaptedConfig);
};
