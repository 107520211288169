import type { ElementType } from 'react';
import { useCallback, useMemo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import InputText from 'components/ui/forms/shared/InputText';
import { ElementTestId, rangeFieldTestId } from 'enums/testing';
import type { DateTimeRangeInput, IntRangeInput } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';
import { capitalizeFirstChar } from 'utils/stringUtils';

const { t } = translate;

export interface RangeInputSettings {
  input: ElementType;
  inputWidth?: string;
}

export interface RangeInputProps {
  settings: RangeInputSettings;
  onChange?: (val: Record<'currentTarget', { value: IntRangeInput | DateTimeRangeInput | null }>) => void;
  selectedValue?: IntRangeInput | DateTimeRangeInput;
  // ID used for referencing this input in tests
  testId?: string;
}

const RangeInputsContainer = styled.div<{ inputWidth: string }>`
  display: flex;

  /* TODO: [#2431] Use CSS constants: https://github.com/carmigo/edealer-web-v2/issues/2431 */
  margin: 12px 0 12px 0;

  > {
    /* Min input container */
    :nth-child(1) {
      ${({ inputWidth }) => css`
        width: ${inputWidth};
      `};
      margin-right: auto;

      > * {
        margin: 0;
      }
    }

    /* Max input container */
    :nth-child(2) {
      ${({ inputWidth }) => css`
        width: ${inputWidth};
      `};
      > * {
        margin: 0;
      }
    }
  }
`;

/**
 * Range input to capture a range of values with a specified input type
 */
const RangeInput = (props: RangeInputProps) => {
  const {
    settings: { input: Input, inputWidth, ...otherSettings } = {
      input: InputText,
      inputWidth: undefined,
    },
    onChange,
    selectedValue,
    testId,
  } = useMemo(() => props, [props]);
  // Holds the string values for the input fields
  const [[minRangeInput, maxRangeInput], setRangeInputValues] = useState<any[]>([
    selectedValue?.gte,
    selectedValue?.lte,
  ]);

  // When user types in the input fields, update the string values for the corresponding min or max input field
  const onInputChange = useCallback(
    (e, rangeBound) => {
      const inputValue = e.currentTarget.value;
      const newRange = rangeBound === 'min' ? [inputValue, maxRangeInput] : [minRangeInput, inputValue];

      setRangeInputValues(newRange);

      const value =
        newRange[0] || newRange[1]
          ? {
              gte: newRange[0] || undefined,
              lte: newRange[1] || undefined,
            }
          : null;

      onChange?.({ currentTarget: { value } });
    },
    [setRangeInputValues, onChange, minRangeInput, maxRangeInput]
  );

  return (
    <div>
      {Input && (
        /*
         * If no width is specified, we want the inputs to take up 50% of available space,
         * minus padding between both inputs
         */
        <RangeInputsContainer inputWidth={inputWidth || `calc(50% - 10px/2)`}>
          <Input
            data-testid={testId ? rangeFieldTestId(testId, ElementTestId.RANGE_INPUT_MIN) : undefined}
            defaultValue={selectedValue?.gte}
            onChange={e => onInputChange(e, 'min')}
            placeholder={capitalizeFirstChar(t('from'))}
            settings={otherSettings}
          />
          <Input
            data-testid={testId ? rangeFieldTestId(testId, ElementTestId.RANGE_INPUT_MAX) : undefined}
            defaultValue={selectedValue?.lte}
            onChange={e => onInputChange(e, 'max')}
            placeholder={capitalizeFirstChar(t('to'))}
            settings={otherSettings}
          />
        </RangeInputsContainer>
      )}
    </div>
  );
};

export default RangeInput;
