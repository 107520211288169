import { gql } from '@apollo/client';

import { motorcycleAttributes } from 'store/api/graph/fragments/motorcycleAttributes';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { vehicleAttributes } from 'store/api/graph/fragments/vehicleAttributes';

export const vinDecodeQuery = gql`
  query VinDecodeQuery($vin: String!, $trimId: ID) {
    vinDecode(vin: $vin, trimId: $trimId) {
      vinMatch {
        vehicleAttributes: attributes {
          ...VehicleAttributesFragment
        }
        motorcycleAttributes: attributes {
          ...MotorcycleAttributesFragment
        }
        destination {
          amount
          formattedAmountRounded
        }
        year
        makeName: make {
          id
          name {
            ...MultilingualStringFragment
          }
        }
        modelName: model {
          id
          name {
            ...MultilingualStringFragment
          }
        }
        subModelName: subModel {
          id
          name {
            ...MultilingualStringFragment
          }
        }
        trimName: trim {
          id
          name {
            ...MultilingualStringFragment
          }
          source
        }
        type
        msrp {
          amount
        }
      }
      matchingTrims {
        id
        name {
          ...MultilingualStringFragment
        }
        source
      }
    }
  }
  ${MultilingualString}
  ${vehicleAttributes}
  ${motorcycleAttributes}
`;
