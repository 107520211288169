import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum RooftopDepartment {
  TYPE = 'type',
  TYPE_NAME = 'typeName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  LOCATION = 'location',
  HOURS = 'hours',
}

const RooftopDepartmentConfig: TableSettingsType = {
  [RooftopDepartment.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
  [RooftopDepartment.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [RooftopDepartment.TYPE_NAME]: { label: 'department', type: FieldDataType.DEFAULT },
  [RooftopDepartment.LOCATION]: { label: 'location', type: FieldDataType.LOCATION },
};

export const RooftopDepartmentSettings = translateFields(RooftopDepartmentConfig);
