import { useCallback, useEffect, useRef } from 'react';

interface Props {
  /* The height of the OAuth dialog window */
  height?: number;
  /* The width of the OAuth dialog window */
  width?: number;
  /* The title of the OAuth dialog window */
  title?: string;
  /* The url of the OAuth dialog window */
  url?: string;
  /* The callback function once oauth provider returns a authorization code */
  onCodeRetrieved: (code, windowRef) => void;
}

const useOAuthDialog = ({ height = 600, width = 700, onCodeRetrieved, title = '', url }: Props) => {
  /* A `interval` reference; Used to clear the timeout during cleanup. */
  const intervalRef = useRef<number | undefined>(undefined);
  /* A `window` reference; Used to read the current state of the popup. */
  const windowRef = useRef<Window | null>(null);

  const clearTimer = useCallback(() => {
    window?.clearInterval(intervalRef.current);
  }, [intervalRef]);

  useEffect(
    () => () => {
      windowRef.current = null;
      clearTimer();
    },
    [clearTimer]
  );

  const onInterval = useCallback(() => {
    try {
      const currentUrl = windowRef?.current?.location?.href;
      const params = currentUrl ? new URL(currentUrl)?.searchParams : undefined;
      const code = params?.get?.('code');

      if (!code) {
        return;
      }

      clearTimer();
      onCodeRetrieved(code, windowRef);
      windowRef?.current?.close?.();
    } catch {
      // Catch error
    } finally {
      if (!windowRef || windowRef?.current?.closed) {
        clearTimer();
        windowRef.current = null;
      }
    }
  }, [clearTimer, onCodeRetrieved]);

  const onTriggerClick = useCallback(() => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    windowRef.current = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    intervalRef.current = window.setInterval(onInterval, 500);
  }, [height, onInterval, title, url, width]);

  return onTriggerClick;
};

export default useOAuthDialog;
