import type { Range } from '@tiptap/core';
import type { Selection } from '@tiptap/pm/state';

/**
 * Extracts the selection range from the editor's state selection.
 *
 * @param {Selection} selection - The selection object from the editor's state.
 * @returns {Range} An object containing the start and end positions of the selection.
 */
export const getSelectionRange = (selection: Selection | undefined): Range => {
  if (!selection) {
    return { from: 0, to: 0 };
  }

  const { ranges } = selection;
  const from = Math.min(...ranges.map(range => range.$from.pos));
  const to = Math.max(...ranges.map(range => range.$to.pos));

  return { from, to };
};
