import { type MouseEventHandler, useCallback } from 'react';

import { useMeasure } from 'react-use';
import styled from 'styled-components/macro';

import ColorPalette from 'components/ui/editor/components/buttons/ColorPalette';
import FormatSize from 'components/ui/editor/components/buttons/FormatSize';
import GenerateDescription from 'components/ui/editor/components/buttons/GenerateDescription';
import LanguageToggle from 'components/ui/editor/components/buttons/LanguageToggle';
import EditorIconButton from 'components/ui/editor/components/shared/EditorIconButton';
import IconDropdown from 'components/ui/editor/components/shared/IconDropdown';
import MenuBarContainer, { MenuBarLeft, MenuBarRight } from 'components/ui/editor/containers/MenuBarContainer';
import BoldIcon from 'components/ui/icons/BoldIcon';
import ItalicIcon from 'components/ui/icons/ItalicIcon';
import LinkIcon from 'components/ui/icons/LinkIcon';
import ListBulletedIcon from 'components/ui/icons/ListBulletedIcon';
import ListNumberedIcon from 'components/ui/icons/ListNumberedIcon';
import MoreIcon from 'components/ui/icons/MoreIcon';
import UnderlineIcon from 'components/ui/icons/UnderlineIcon';
import { VerticalDivider } from 'components/ui/shared/VerticalDivider';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { breakpoints } from 'styles/breakpoints';
import { LETTER_SPACING_DENSE } from 'styles/typography';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const MoreMenuItem = styled.span`
  letter-spacing: ${LETTER_SPACING_DENSE};
`;

const RichTextMenuBar = () => {
  const { clientType, editor, isTextGenerationButtonVisible, openEditLinkDialog } = useRichTextEditor();
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const isMobileClientType = clientType === RichTextClientType.MOBILE;

  /**
   * Handle the click event for the bold button.
   */
  const handleBoldIconButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().toggleBold().run(),
    [editor]
  );

  /**
   * Handle the click event for the italic button.
   */
  const handleItalicIconButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().toggleItalic().run(),
    [editor]
  );

  /**
   * Handle the click event for the underline button.
   */
  const handleUnderlineIconButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().toggleUnderline().run(),
    [editor]
  );

  /**
   * Handle the click event for the bullet list button.
   */
  const handleBulletListIconButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().toggleBulletList().run(),
    [editor]
  );

  /**
   * Handle the click event for the ordered list button.
   */
  const handleOrderedListIconButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().toggleOrderedList().run(),
    [editor]
  );

  const handleLinkButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    /**
     * If current selection is an active link don't do anything as the bubble
     * menu will pop up and handle the link
     */
    if (editor?.isActive('link') || editor?.state.selection.from === editor?.state.selection.to) {
      return;
    }

    openEditLinkDialog();
  }, [editor, openEditLinkDialog]);

  return (
    <MenuBarContainer ref={ref}>
      <MenuBarLeft>
        <FormatSize />
        <VerticalDivider />
        <ColorPalette />
        <EditorIconButton
          data-testid={ElementTestId.EDITOR_BUTTON_BOLD}
          icon={BoldIcon}
          onClick={handleBoldIconButtonClick}
          forceActive={editor?.isActive('bold')}
          tooltip={isMobileClientType ? undefined : { children: t('bold') }}
        />
        <EditorIconButton
          data-testid={ElementTestId.EDITOR_BUTTON_ITALIC}
          icon={ItalicIcon}
          onClick={handleItalicIconButtonClick}
          forceActive={editor?.isActive('italic')}
          tooltip={isMobileClientType ? undefined : { children: t('italic') }}
        />
        {width >= breakpoints.mobile && (
          <>
            <EditorIconButton
              data-testid={ElementTestId.EDITOR_BUTTON_UNDERLINE}
              icon={UnderlineIcon}
              onClick={handleUnderlineIconButtonClick}
              forceActive={editor?.isActive('underline')}
              tooltip={isMobileClientType ? undefined : { children: t('underline') }}
            />
            <EditorIconButton
              data-testid={ElementTestId.EDITOR_BUTTON_BULLET_LIST}
              icon={ListBulletedIcon}
              onClick={handleBulletListIconButtonClick}
              forceActive={editor?.isActive('bulletList')}
              tooltip={isMobileClientType ? undefined : { children: t('bullet_list') }}
            />
            <EditorIconButton
              data-testid={ElementTestId.EDITOR_BUTTON_NUMBERED_LIST}
              icon={ListNumberedIcon}
              onClick={handleOrderedListIconButtonClick}
              forceActive={editor?.isActive('orderedList')}
              tooltip={isMobileClientType ? undefined : { children: t('numbered_list') }}
            />
            <EditorIconButton
              data-testid={ElementTestId.EDITOR_BUTTON_LINK}
              icon={LinkIcon}
              forceActive={editor?.isActive('link')}
              onClick={handleLinkButtonClick}
              tooltip={isMobileClientType ? undefined : { children: t('link') }}
            />
          </>
        )}
        {width < breakpoints.mobile && (
          <IconDropdown
            data-testid={ElementTestId.EDITOR_BUTTON_MORE_MENU}
            icon={MoreIcon}
            items={[
              {
                label: t('underline'),
                labelRender: label => <MoreMenuItem>{label}</MoreMenuItem>,
                onClick: handleUnderlineIconButtonClick,
                isActive: editor?.isActive('underline'),
              },
              {
                label: t('bullet_list'),
                labelRender: label => <MoreMenuItem>{label}</MoreMenuItem>,
                onClick: handleBulletListIconButtonClick,
                isActive: editor?.isActive('bulletList'),
              },
              {
                label: t('numbered_list'),
                labelRender: label => <MoreMenuItem>{label}</MoreMenuItem>,
                onClick: handleOrderedListIconButtonClick,
                isActive: editor?.isActive('orderedList'),
              },
              {
                label: t('link'),
                labelRender: label => <MoreMenuItem>{label}</MoreMenuItem>,
                onClick: handleLinkButtonClick,
                isActive: editor?.isActive('link'),
              },
            ]}
          />
        )}
      </MenuBarLeft>
      <MenuBarRight>
        {isTextGenerationButtonVisible && (
          <>
            <GenerateDescription />
            <VerticalDivider />
          </>
        )}
        <LanguageToggle />
      </MenuBarRight>
    </MenuBarContainer>
  );
};

export default RichTextMenuBar;
