export enum SecondaryViewType {
  DETAILS = 'DETAILS',
  LIST = 'LISTS',
}

/** Available width sizes that a secondary nested view can have. */
export enum SecondaryViewWidth {
  /** The default size for secondary views, is about one column wide and used by the major of secondary views  */
  DEFAULT = '315px',
  /** An expanded size for secondary views, is about two columns wide */
  EXPANDED = '630px',
}

export enum NestedViewConfigType {
  SecondaryView = 'SecondaryView',
  DetailsView = 'DetailsView',
  ListView = 'ListView',
}
