import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { TextCSS } from 'components/core/typography/Text';
import { ButtonCSS, ClickableButtonCSS } from 'components/ui/shared/Button';
import { BORDER_DEFAULT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { LETTER_SPACING_DEFAULT } from 'styles/typography';

export const ButtonLikeLink = styled.a`
  ${ClickableButtonCSS}
  ${ButtonCSS}
  padding: 10px 13px 0;

  &:hover,
  &:focus {
    border: 1px solid ${BORDER_DEFAULT};
  }
`;

export const WrapLink = styled(NavLink)`
  text-decoration: none;
  display: block;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export const TextNavLink = styled(NavLink)`
  ${TextCSS}
  color: ${BLUE_500};
  text-transform: none;
  letter-spacing: ${LETTER_SPACING_DEFAULT};
`;
