import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Task, TaskSettings } from 'enums/columns/task';
import { FieldDataType } from 'enums/fieldDataType';
import { ItemsColumn } from 'enums/itemsColumn';
import { BODY_TEXT } from 'styles/color';
import { FONT_WEIGHT_BOLD } from 'styles/typography';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { PhotoHeaders, SelectHeaders } from './staticTableConfig';

export const tasksDefaultSortOrder = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  Task.GROUP_NAME,
  Task.STATUS,
  Task.TITLE,
  Task.NOTE,
  Task.DUE_DATE,
  Task.TAGS,
  Task.ROOFTOP_NAME,
  Task.ASSIGNED_TO_NAME,
  Task.LEAD_NAME,
  Task.INVENTORY_ITEM_NAME,
  Task.ARCHIVED,
  Task.CREATED_BY_NAME,
  Task.CREATED,
  Task.UPDATED,
];

const styles = {
  [Task.TITLE]: `
    > div { 
      color: ${BODY_TEXT}; 
      font-weight: ${FONT_WEIGHT_BOLD}; 
    }
  `,
};

const TASKS_BASE = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),
  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: FieldDataType.CUSTOM,
  }),

  // ...Rest
  getCellDataByItemColumnType(Task.GROUP_NAME, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.STATUS, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.LEAD_NAME, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.INVENTORY_ITEM_NAME, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.ARCHIVED, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.CREATED_BY_NAME, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.CREATED, TaskSettings, { enabled: false }),
  getCellDataByItemColumnType(Task.UPDATED, TaskSettings, { enabled: false }),
]);

export const TASKS = Object.freeze<TableCellData[]>([
  // Defaults
  ...TASKS_BASE,

  // ...Rest
  getCellDataByItemColumnType(Task.TITLE, TaskSettings, { width: '30%', styles: styles[Task.TITLE] }),
  getCellDataByItemColumnType(Task.NOTE, TaskSettings, { width: '30%' }),
  getCellDataByItemColumnType(Task.DUE_DATE, TaskSettings, { width: '10%' }),
  getCellDataByItemColumnType(Task.TAGS, TaskSettings, { width: '10%' }),
  getCellDataByItemColumnType(Task.ROOFTOP_NAME, TaskSettings, { width: '10%' }),
  getCellDataByItemColumnType(Task.ASSIGNED_TO_NAME, TaskSettings, { width: '10%' }),
]);

export const TASKS_LIMITED = Object.freeze<TableCellData[]>([
  // Defaults
  ...TASKS_BASE,

  // ...Rest
  getCellDataByItemColumnType(Task.TITLE, TaskSettings, { width: '40%', styles: styles[Task.TITLE] }),
  getCellDataByItemColumnType(Task.NOTE, TaskSettings, { width: '40%' }),
  getCellDataByItemColumnType(Task.DUE_DATE, TaskSettings, { width: '10%' }),
  getCellDataByItemColumnType(Task.TAGS, TaskSettings, { width: '10%' }),
  getCellDataByItemColumnType(Task.ASSIGNED_TO_NAME, TaskSettings, { enabled: false }),
]);
