import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import { MileageUnit } from 'store/api/graph/interfaces/types';
import { getStepField, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { formatMileage } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

export const seedAvailableMileageStep = prefill => ({ availableMileageAllowance: prefill || null });

class AvailableMileageAllowanceStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { activeStep, seededData },
    } = props;

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields.AVAILABLE_MILEAGE_ALLOWANCES]: {
        selectedValue: Number.isNaN(seededData.availableMileageAllowance)
          ? null
          : { amount: seededData.availableMileageAllowance, unit: MileageUnit.KILOMETERS },
      },
    });
  }

  async save(): Promise<boolean> {
    const {
      tier: { onStepSave, activeStep },
    } = this.props;

    const newMileage = getStepField(
      RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields.AVAILABLE_MILEAGE_ALLOWANCES,
      this.fields
    ).selectedValue;

    if (!newMileage?.amount) {
      this.setTier({
        errors: [
          {
            name: `mileage_allowance`,
            message: translate.t('please_enter_an_amount'),
            extensions: {
              fields: [RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields.AVAILABLE_MILEAGE_ALLOWANCES],
            },
          },
        ],
      });

      return false;
    }

    // This save method doesn't actually call any mutation, so we'll need to manually clear the discard prompt
    this.setOnClosePrompt(undefined);

    void onStepSave?.(activeStep!, {
      id: newMileage.amount,
      name: formatMileage(newMileage.amount, newMileage.unit),
      data: newMileage.unit,
    });
    return true;
  }
}

export default AvailableMileageAllowanceStep;
