import styled from 'styled-components/macro';

import { TextNavLinkExternal } from 'components/core/navigation/shared/TextNavLinkExternal';
import MultiLineText from 'components/core/typography/MultiLineText';
import RichText from 'components/core/typography/RichText';
import SecondaryText from 'components/core/typography/SecondaryText';
import Text from 'components/core/typography/Text';
import ActionableTitle from 'components/ui/details/ActionableTitle';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import { Section } from 'components/ui/layouts/CardLayout';
import { GridSectionTitle } from 'components/ui/layouts/Grid';
import { PanelBody, PanelRow, PanelValue } from 'components/ui/panels/Panel';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import { useUser } from 'hooks/contexts/useUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import type { TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { DIVIDER } from 'styles/color';
import { GREEN_600, NEUTRAL_0, NEUTRAL_700 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { formatAmountRounded, formatFullName } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { getRelativeTime } from 'utils/timeUtils';

const GuaranteedTradeValuePanel = styled(PanelBody)<{ isExpanded?: boolean }>`
  background-color: ${GREEN_600};
  ${Text} {
    color: ${NEUTRAL_0};
  }

  border-radius: 8px 8px ${({ isExpanded }) => (isExpanded ? '0 0' : '8px')};
  flex: inherit;
`;

const GuaranteedTradeValueComments = styled(Section)`
  border-left: 1px solid ${DIVIDER};
  border-right: 1px solid ${DIVIDER};
  border-radius: 0 0 8px 8px;
`;

const MessageGridSectionTitle = styled(GridSectionTitle)`
  display: flex;
  justify-content: space-between;
`;

/**
 * TODO: Replace formatting of time with field from api when it becomes available
 */
const GuaranteedTradeValueSection = ({
  rooftop,
  value,
  leadActivityPartial,
  onEdit,
}: {
  /** The rooftop associated with the trade in item */
  rooftop: TradeInItemResponseType['rooftop'];
  /** The guaranteedValue field for this trade in item */
  value: TradeInItemResponseType['guaranteedValue'];
  /** The id of the conversation created for this trade in item */
  leadActivityPartial?: TradeInItemResponseType['leadActivityPartial'];
  /** A callback supplied to the actionable title for the section */
  onEdit?: () => void;
}) => {
  const { t } = translate;

  const { hasPermissions, user } = useUser();
  const { flags } = useFeatureFlags();
  const conversation = leadActivityPartial?.conversation;
  const conversationId = conversation?.id;

  const canViewConversation =
    isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.CONVERSATION,
      featureFlagOn: flags.rooftopPackageEnabled,
    }) &&
    (hasPermissions([{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }]) ||
      (hasPermissions([{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }]) &&
        conversation?.leadName?.assignedToName?.id === user.id));

  const showViewConversationLink = !!conversationId && canViewConversation;

  if (!value) {
    return (
      <Section>
        <ActionableTitle label={t('send_trade_value')} onEdit={onEdit} icon={<CirclePlusIcon color={NEUTRAL_700} />} />
      </Section>
    );
  }

  const { displayName, updated, amount, comments } = value;

  return (
    <Section css="> *:last-of-type { margin-bottom: 0; }">
      <ActionableTitle label={t('sent_trade_value')} onEdit={onEdit} />
      <GuaranteedTradeValuePanel isExpanded={!!comments}>
        <PanelRow>
          <PanelValue css="flex: 1;">{formatAmountRounded(amount)}</PanelValue>
          <SecondaryText css="align-self: flex-start;">{getRelativeTime(updated)}</SecondaryText>
        </PanelRow>
        <PanelRow>
          <SecondaryText>{formatFullName({ displayName })}</SecondaryText>
        </PanelRow>
      </GuaranteedTradeValuePanel>
      {(!!comments || showViewConversationLink) && (
        <GuaranteedTradeValueComments>
          <MessageGridSectionTitle>
            {t('message_sent_to_lead')}
            {!!showViewConversationLink && (
              <TextNavLinkExternal to={`${RoutePath.CONVERSATIONS}/${conversationId}`}>
                {t('view_conversation')}
              </TextNavLinkExternal>
            )}
          </MessageGridSectionTitle>
          <MultiLineText>
            <RichText>{comments}</RichText>
          </MultiLineText>
        </GuaranteedTradeValueComments>
      )}
    </Section>
  );
};

export default GuaranteedTradeValueSection;
