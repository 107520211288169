export enum ImageType {
  /** Regular photo image with a a no-image icon */
  PHOTO = 'Photo', // TODO: use a no-image icon when available
  /** Regular photo image with a vehicle icon fallback */
  INVENTORY_ITEM = 'Vehicle',
  /** Immediately falls back to user initials with RGB hex background */
  USER = 'User',
  /** User photo with user silhouette fallback */
  AVATAR = 'Avatar',
  /** Immediately falls back to a provided ReactNode icon provided */
  ICON = 'Icon',
  /** Hero images are used in the HeroSection component, the images are set as a background of a container div */
  HERO = 'Hero',
}

export enum ImageSize {
  DEFAULT = 'Default',
  AVATAR = 'Avatar',
  THUMBNAIL = 'Thumbnail',
  LIST_ITEM = 'List item',
  FILL = 'Fill',
  HERO = 'Hero',
}
