import { gql } from '@apollo/client';

import { leadList } from 'store/api/graph/fragments/leadList';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';
import { rooftopList } from 'store/api/graph/fragments/rooftopList';
import { tradeInItemList } from 'store/api/graph/fragments/tradeInItemList';

import { MultilingualString } from './multilingualString';
import { tag } from './tag';
import { userList } from './userList';

export const taskDetail = gql`
  fragment TaskDetailFragment on Task {
    ## Details tab
    id
    archived
    title
    status
    statusName
    dateDue
    dateCompleted
    note
    tagName: tags {
      ...TagFragment
    }
    createdByName: createdBy {
      ...UserListFragment
    }
    created
    updated

    ## Linked tab
    leadName: lead {
      ...LeadListFragment
    }

    assignedToName: assignedTo {
      ...UserListFragment
    }

    inventoryItemName: inventoryItem {
      ...RetailItemListFragment
      ...TradeInItemListFragment
    }

    rooftopName: rooftop {
      ...RooftopListFragment
    }
  }
  ${tag}
  ${userList}
  ${leadList}
  ${rooftopList}
  ${tradeInItemList}
  ${retailItemList}
  ${MultilingualString}
`;
