import { useMemo } from 'react';

import type { DocumentNode } from 'graphql';
import { cloneDeep, set } from 'lodash-es';

import { rooftopRetailPricingCountQuery } from 'containers/rooftops/pricingSegments/RooftopRetailPricingContainerQuery';
import { TabType } from 'enums/tabType';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type {
  RooftopRetailPricingCountQuery,
  RooftopRetailPricingCountQueryVariables,
} from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

export const useRetailPricingListQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const pricingListResponse = useQuery(query, config);
  const countResponse = useQuery<RooftopRetailPricingCountQuery, RooftopRetailPricingCountQueryVariables>(
    rooftopRetailPricingCountQuery,
    { variables: { keyword: config.variables.keyword, rooftopId: config.variables.filter.rooftopId } }
  );

  return useMemo(() => {
    const combinedResponse = {
      ...cloneDeep(pricingListResponse),
      // Set `isLoading` based on parallel fetches
      isLoading: pricingListResponse.isLoading || countResponse.isLoading,
      isLoaded: pricingListResponse.isLoaded && countResponse.isLoaded,
    };

    if (!countResponse.isLoading && countResponse.isLoaded) {
      for (const key of [TabType.PRICING_CASH, TabType.PRICING_FINANCE, TabType.PRICING_LEASE]) {
        set(combinedResponse, `data.${key}Count`, countResponse.data[key]?.pageInfo?.totalEdges || 0);
      }
    }

    return combinedResponse;
  }, [countResponse, pricingListResponse]);
};
