import { useCallback, useState } from 'react';

import { leadForwardDelete } from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingCreateModifyQuery';
import RooftopLeadForwardingQueryMethods from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingQueryMethods';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

interface Props extends MenuItemProps {
  item: RooftopResponseType;
}

const RooftopLeadForwardingMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { t } = translate;
  const { hasPermissions } = useUser();
  const { back: backToListView } = useNestedView();
  const { toggleTier } = useCreateModify();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_LEAD_FORWARD_MODIFY,
        title: t('modify_x', [t('forwarding_rule')]),
        isCreating: false,
        itemId: item?.id,
      }),
    [t, item, toggleTier]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.ROOFTOP_LEAD_FORWARD_MODIFY].requiredPermissions),
    },
    {
      label: t('delete'),
      onClick: () => setDeleteDialogOpen(true),
      disabled: !hasPermissions(builderConfig[BuilderType.ROOFTOP_LEAD_FORWARD_MODIFY].requiredPermissions),
    },
  ];

  return (
    <>
      <MenuItems items={menuItemsConfig} />
      <PromptDialog
        isConfirmDestructive
        isOpen={isDeleteDialogOpen}
        onConfirm={useCallback(
          async () =>
            client.mutate({
              mutation: leadForwardDelete,
              variables: { id: item.id },
              refetchQueries: RooftopLeadForwardingQueryMethods.refetchQueries,
            }),
          [item]
        )}
        onCancel={() => setDeleteDialogOpen(false)}
        onComplete={useCallback(
          (success: boolean) => {
            if (success) {
              backToListView();
            }
          },
          [backToListView]
        )}
        title={t('delete_lead_forwarding')}
        message={t('delete_lead_forwarding_message')}
        confirmText={t('delete')}
        cancelText={t('cancel')}
      />
    </>
  );
};

export default RooftopLeadForwardingMenuItems;
