import type { DocumentNode } from 'graphql';

import { type QueryConfig, useQuery } from 'hooks/useQuery';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';

import { gqlFilterFields } from './gqlUtils';

// Custom proxy for useQuery, used by nested views to ensure errors are properly caught
export const useNestedViewQueryProxy = (
  /** The query that will be run */
  query: DocumentNode,
  /** Variables for this query */
  variables = {},
  /** Override the default useQuery hook with a custom hook */
  useQueryOverride = useQuery,
  /** Pre-seeded default data */
  itemData?: any
): CustomQueryResult =>
  useQueryOverride(
    query,
    {
      variables: gqlFilterFields(query, variables, false),
      ignore: !query,
    },
    itemData
  );

export const useGraphQuery = (
  query: DocumentNode,
  variables = {},
  options?: QueryConfig['options']
): CustomQueryResult => {
  const formattedVariablesNext = parseConnectionParams(variables);

  return useQuery(query, {
    variables: formattedVariablesNext,
    options,
  });
};

export const save = async (queryMutation: any = {}, variables?: any, refetchQueries?: string[]) => {
  try {
    return await client.mutate({
      mutation: queryMutation,
      variables,
      awaitRefetchQueries: !!refetchQueries?.length,
      refetchQueries,
    });
  } catch (error) {
    logApiError(error);
    throw error;
  }
};
