import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const websiteList = gql`
  fragment WebsiteListFragment on Website {
    ## Minimum required fields for table/listItem
    id
    name {
      ...MultilingualStringFragment
    }
    status
    statusName
    url
  }

  ${MultilingualString}
`;
