import { gql } from '@apollo/client';

import { contactList } from './contactList';

export const tradeInAppraisalExternal = gql`
  fragment TradeInAppraisalExternalFragment on TradeInAppraisalExternal {
    id
    updated
    contact {
      ...ContactListFragment
    }
  }
  ${contactList}
`;
