import { memo, type SVGProps } from 'react';

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M6.072 22.203c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V4.072c0-.63.222-1.167.665-1.61.444-.444.98-.665 1.61-.665h7.073a2.244 2.244 0 0 1 1.598.658l4.801 4.803a2.244 2.244 0 0 1 .659 1.598v11.072c0 .63-.221 1.166-.665 1.61-.444.444-.98.665-1.61.665H6.072Zm6.856-14.269V4.072H6.072v15.856h11.856V9.072h-3.862a1.1 1.1 0 0 1-.809-.33 1.1 1.1 0 0 1-.329-.808Z"
    />
  </svg>
);
export default memo(DocumentIcon);
