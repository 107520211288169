import { UserScope } from 'store/api/graph/interfaces/types';

/*
 * If a users scope is GLOBAL, or WHITE_LABEL, then it has a whitelabel scope, if however scope is
 * FULL or PARTIAL group, then the user does not have a whitelabel scope.
 */
export const whiteLabelScopes = [UserScope.GLOBAL, UserScope.WHITE_LABEL];
export const nonWhiteLabelScopes = [UserScope.FULL_GROUP, UserScope.PARTIAL_GROUP];
export const fullGroupOrAboveScopes = [UserScope.FULL_GROUP, ...whiteLabelScopes];

/**
 * Determines if scope is White label or higher.
 * e.g. Such users with this scope can grant all permissions.
 */
export const hasWhiteLabelScopedAccess = (scope: UserScope) => whiteLabelScopes.includes(scope);

/** The order of scopes, from least access to most. */
const scopeOrder: UserScope[] = [
  UserScope.PARTIAL_GROUP,
  UserScope.FULL_GROUP,
  UserScope.WHITE_LABEL,
  UserScope.GLOBAL,
];

/**
 * Determines if the current user has at least the given minimum scope.
 * @param userScope The scope of the current user
 * @param minimumScope The minimum scope that they must have
 */
export const hasMinimumScope = (userScope: UserScope, minimumScope: UserScope) => {
  const minimumScopeIndex = scopeOrder.indexOf(minimumScope);
  return scopeOrder.indexOf(userScope) >= minimumScopeIndex;
};

type AuthorizedCallbackParams<TBoolean extends boolean, TArgs extends any[]> = {
  /** Callback to return if authorized */
  cb: (...args: TArgs) => void;
  /** Whether or not user is authorized */
  isAuth: TBoolean;
};

/**
 * Helper method to return an authorized callback.
 */
export function authorizedCallback<TBoolean extends boolean, TArgs extends any[]>(
  params: AuthorizedCallbackParams<TBoolean, TArgs>
): TBoolean extends true ? (...args: TArgs) => void : undefined;
export function authorizedCallback<TBoolean extends boolean, TArgs extends any[], TFArgs extends any[]>(
  params: AuthorizedCallbackParams<TBoolean, TArgs> & {
    /** Fallback to return when user is not authorized */
    fallback: (...args: TFArgs) => void;
  }
): TBoolean extends true ? (...args: TArgs) => void : (...args: TFArgs) => void;
export function authorizedCallback(params) {
  return params.isAuth ? params.cb : params.fallback || undefined;
}
