import type { ReactNode, UIEvent } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import Button from 'components/ui/shared/Button';
import { CARD_WIDTH } from 'styles/layouts';
import { SPACE_12, SPACE_16, SPACE_32 } from 'styles/spacing';
import { BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { FONT_SIZE_24 } from 'styles/typography';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${NEUTRAL_0};
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 180px;
  min-width: ${CARD_WIDTH};
`;

const Icon = styled.div`
  margin-bottom: ${SPACE_12};
`;

const Title = styled(PrimaryText)`
  font-size: ${FONT_SIZE_24};
  margin-bottom: 12px;
  white-space: normal;
  text-align: center;
`;

const ActionButton = styled(Button)`
  color: ${NEUTRAL_0};
  background: ${BLUE_500};
  padding: ${SPACE_16} ${SPACE_32};

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PlaceholderText = styled(Text)`
  margin-bottom: ${SPACE_32};
  text-align: center;
  white-space: normal;
  width: calc(100% - ${SPACE_32});
`;

interface PlaceholderProps {
  /** The icon of the placeholder */
  icon?: ReactNode;
  /** The title label in the placeholder */
  title: string;
  /** The sub-title below the title in the placeholder */
  subtitle: string;
  /** The button text for the buttons in the placeholder. Multiple texts can be passed to render multiple buttons */
  buttonText?: string | string[];
  /** Callback for when a button is clicked. An index number is passed to determine which button was clicked */
  onClick?: (e: UIEvent<HTMLElement>, n: number) => void;
  /** Custom class name */
  className?: string;
  /** Testing id */
  testId?: string;
}

const Placeholder = ({ icon, title, subtitle, buttonText, onClick, className, testId }: PlaceholderProps) => {
  const renderButtons = () => {
    if (!onClick) {
      return null;
    }

    return [buttonText].flat().map((text, i) => (
      <ActionButton key={text} onClick={e => onClick(e, i)}>
        {text}
      </ActionButton>
    ));
  };

  return (
    <Container className={className} data-testid={testId}>
      {icon && <Icon>{icon}</Icon>}
      <Title>{title}</Title>
      <PlaceholderText>{subtitle}</PlaceholderText>
      {renderButtons()}
    </Container>
  );
};

export const BackgroundlessPlaceholder = styled(Placeholder)`
  background: none;
`;

export default Placeholder;
