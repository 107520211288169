import { gql } from '@apollo/client';

import { initialItemSorting } from './initialItemSorting';
import { MultilingualString } from './multilingualString';
import { userList } from './userList';
import { websiteLeadRoute } from './websiteLeadRoute';
import { websiteList } from './websiteList';
import { websiteRoute } from './websiteRoute';

export const websiteDetail = gql`
  fragment WebsiteDetailFragment on Website {
    ...WebsiteListFragment
    ## Details tab
    groupName: group {
      id
      name {
        ...MultilingualStringFragment
      }
      rooftops {
        id
        name {
          ...MultilingualStringFragment
        }
      }
    }
    primaryRooftop {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    address: location {
      value: address
    }
    city: location {
      value: city
    }
    regionCode: location {
      value: regionCode
    }
    zipCode: location {
      value: zipCode
    }
    region: location {
      value: region
    }
    country: location {
      value: country
    }
    countryCode: location {
      value: countryCode
    }
    placeId: location {
      value: placeId
    }
    timeZone: location {
      value: timeZone
    }
    latitude: location {
      value: latitude
    }
    longitude: location {
      value: longitude
    }
    phoneNumber
    ga4PropertyId
    googleAdsId
    locales {
      id: languageTag
      name: displayName
    }
    primaryLocale {
      id: languageTag
      name: displayName
    }
    created
    updated
    defaultInitialItemSorting {
      ...InitialItemSortingFragment
    }

    ## Routes Tab
    routes {
      ...WebsiteRouteFragment
    }

    ## Lead Routing Tab
    leadRoutes {
      ...WebsiteLeadRouteFragment
    }

    ## Linked Tab
    createdByName: createdBy {
      ...UserListFragment
    }
  }
  ${MultilingualString}
  ${websiteList}
  ${userList}
  ${initialItemSorting}
  ${websiteLeadRoute}
  ${websiteRoute}
`;
