import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { contactDetailsQuery } from 'containers/contacts/ContactsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const ContactQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const metaResponse = await client.query({
      query: contactDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
    return metaResponse.data.item;
  },

  refetchQueries: [
    'RooftopConnectionQuery',
    'RooftopDetailsContainerQuery',
    'ContactDetailsContainerQuery',
    'RooftopContactsCondensedListQuery',
    'RooftopContactsDetailsQuery',
  ],
};

export default ContactQueryMethods;
