import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { defineFieldValues, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;
class URLStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, data },
    } = props;

    if (activeStep) {
      activeStep.tooltip = {
        title: t('rooftop_integration_url_step_tooltip_title'),
        content: data?.oemAccountExportAttributes?.website?.name,
        canCopyContent: true,
      };
    }

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields);

    this.fields = defineFieldValues(this.fields, data);
  }
}

export default URLStep;
