import type { BuilderType, DemoBuilderType } from 'enums/builderType';
import type { TabType } from 'enums/tabType';

import type { ExtendedEntityType } from './extendedEntityType';

/**
 * List of testing commands exposed to the window object. This allows Cypress tests to execute certain functions
 * without needing to import the component/function directly.
 */
export enum TestingCommands {
  CYPRESS = 'Cypress',
  BUILDER_OPEN = 'builderOpen',
}

/**
 * Elements can be targeted in tests by including a data-testid attribute. This enum contains all possible data-testid
 * attribute values.
 */

export enum ElementTestId {
  VIN_ACTION_BUTTON_REFRESH = 'vin-action-button-refresh',
  CARFAX_ACTION_BUTTON_REFRESH = 'carfax-action-button-refresh',

  BUILDER_CONTAINER = 'builder-container',
  BUILDER_HOUR_PICKER = 'builder-hour-picker',
  BUILDER_LIST_SELECTION = 'builder-list-selection',
  BUILDER_LIST_SELECTION_DONE_BUTTON = 'builder-list-selection-done-button',
  BUILDER_LIST_SELECTION_CATEGORY_PREFIX = 'builder-list-selection-category',
  BUILDER_LIST_SELECTION_SEARCH_INPUT = 'builder-list-selection-search-input',
  BUILDER_MERIDIEN_PICKER = 'builder-meridien-picker',
  BUILDER_MINUTE_PICKER = 'builder-minute-picker',
  BUILDER_SAVE_AND_CONTINUE_BUTTON = 'builder-save-and-continue-button',
  BUILDER_SAVE_BUTTON = 'builder-save-button',
  BUILDER_CLOSE_BUTTON = 'builder-close-button',
  BUILDER_COPY_TRADE_IN_BUTTON = 'builder-copy-trade-in-button',
  BUILDER_STEP_FIELD_INPUT_PREFIX = 'builder-step-field-input-',
  BUILDER_SUB_STEP_CONTAINER = 'builder-sub-step-container',
  BUILDER_ERROR_CONTAINER = 'builder-error-container',
  BUILDER_ERROR_CONTAINER_OKAY_BUTTON = 'builder-error-container-okay-button',
  BUILDER_ALERT_CONTINUE_BUTTON = 'builder-alert-continue-button',
  BUILDER_STEP_LEGEND_CONTAINER = 'builder-step-legend-container',
  BUILDER_VIDEO_FIELD_CONTAINER = 'builder-video-field-container',
  BUILDER_ITEM_TYPE_SELECTOR_PREFIX = 'builder-item-type-selector-',
  BUILDER_RETURN_STEP = 'builder-return-step',
  BUILDER_STOCK_PHOTO_FIELD_CONTAINER = 'builder-stock-photo-field-container',
  BUILDER_EXPAND_BUTTON = 'builder-expand-button',
  BUILDER_COLLAPSE_BUTTON = 'builder-expand-button',
  BUILDER_PREVIEW_CONTAINER = 'builder-preview-container',
  BUILDER_OVERLAY_PREFIX = 'builder-overlay-',
  BUILDER_CONTAINER_PREFIX = 'builder-container-',
  BUILDER_CONTAINER_DISABLED_OVERLAY_PREFIX = 'builder-container-disabled-overlay',
  // TODO: Use prefix to target specific tier in case of staggered builders
  BUILDER_SAVE_BUTTON_PREFIX = 'builder-save-button-',
  BUILDER_SAVE_AND_CONTINUE_BUTTON_PREFIX = 'builder-save-and-continue-button-',

  DASHBOARD_UPCOMING_LEAD_ACTIVITIES = 'dashboard-upcoming-lead-activities',
  DASHBOARD_UPCOMING_TASKS = 'dashboard-upcoming-tasks',
  DASHBOARD_UPCOMING_APPOINTMENTS = 'dashboard-upcoming-appointments',
  DASHBOARD_LEAD_METRICS = 'dashboard-lead-metrics',
  DASHBOARD_LEAD_DISTRIBUTION = 'dashboard-lead-distribution',
  DASHBOARD_INVENTORY_HEALTH_METRICS = 'dashboard-inventory-health-metrics',

  DIALOG_BACKGROUND = 'dialog-background',

  DETAILS_VIEW_TAB_CONTAINER = 'details-view-tab-container',
  DETAILS_VIEW_EDIT_ICON = 'details-view-edit-icon',
  DETAILS_VIEW_LOCK_ICON = 'details-view-lock-icon',
  DETAILS_VIEW_GRID_SECTION = 'details-view-grid-section',

  FACET_FILTER_CLEAR_BUTTON = 'facet-filter-clear-button',
  FACET_FILTER_CONTAINER = 'facet-filter-container',
  FACET_FILTER_LAYOUT_VIEW_BUTTON = 'facet-filter-layout-view-button',
  FACET_FILTER_SAVED_FILTER_LIST_ITEM = 'facet-filter-saved-filter-list-item',
  FACET_FILTER_SELECTED_SAVED_FILTER = 'facet-filter-selected-saved-filter',
  FACET_FILTER_TOGGLE_BUTTON = 'facet-filter-toggle-button',
  FACET_GLOBAL_FILTER_TOGGLE_BUTTON = 'facet-global-filter-toggle-button',

  FEATURE_BUNDLE_TAGS = 'feature-bundle-tags',
  FEATURE_BUNDLE_APPOINTMENTS_SECTION = 'feature-bundle-appointments-section',
  FEATURE_BUNDLE_LEAD_SECTION = 'feature-bundle-lead-section',
  FEATURE_BUNDLE_PACKAGE_SECTION = 'feature-bundle-package-section',
  FEATURE_BUNDLE_RETAIL_INVENTORY = 'feature-bundle-retail-inventory',
  FEATURE_BUNDLE_TASKS_SECTION = 'feature-bundle-tasks-section',
  FEATURE_BUNDLE_TRADE_IN_INVENTORY = 'feature-bundle-trade-in-inventory',

  FEEDBACK_MENU_BUTTON = 'feedback-menu-button',

  ITEMS_CONTAINER = 'items-container',
  ITEMS_CONTAINER_FILTERS = 'items-container-filters',
  ITEMS_DETAILS_VIEW_CONTAINER = 'items-details-view-container',
  ITEMS_DETAILS_VIEW_BACK_BUTTON = 'items-details-view-back-button',
  ITEMS_DETAILS_LINKED_SECTION = 'items-details-linked-section',

  TABLE_CONTAINER = 'table-container',
  TABLE_CONTAINER_HEADER = 'table-container-header',
  TABLE_CONTENT = 'table-content',

  LINKED_SECTION_ITEM_PREFIX = 'linked-section-item-',
  LINKED_SECTION_ITEM_PANEL_PREFIX = 'linked-section-item-panel-',
  LINKED_LIST_VIEWER_BUTTON = 'linked-list-viewer-button',

  LIST_SELECTION_ADD_BUTTON = 'list-selection-add-button',
  LIST_SELECTION_DRAG_ICON = 'list-selection-drag-icon',
  LIST_SELECTION_ITEM_PREFIX = 'list-selection-item-',
  LIST_SELECTION_EMPTY_LIST_PLACEHOLDER = 'list-selection-empty-list-placeholder',

  LOCATION_DONE_BUTTON = 'location-done-button',
  LOCATION_RESULTS_CONTAINER = 'location-results-container',
  LOCATION_SEARCH_RESULTS_CONTAINER = 'location-search-results-container',
  LOCATION_SEARCH_INPUT = 'location-search-input',
  LOCATION_TOGGLE_MANUAL_INPUT = 'location-toggle-manual-input',

  SPINNING_LOADER = 'spinning-loader',

  LOGIN_USERNAME_FIELD = 'input-username',
  INPUT_PASSWORD = 'input-password',
  INPUT_RICH_TEXT = 'input-rich-text',
  INPUT_RICH_TEXT_GENERATE_TEXT_LOADING = 'input-rich-text-generate-text-loading',
  INPUT_FLAGS = 'input-flags',

  PROMPT_DIALOG_CONTAINER = 'prompt-dialog-container',
  PROMPT_DIALOG_CONFIRM = 'prompt-dialog-confirm',
  PROMPT_DIALOG_CANCEL = 'prompt-dialog-cancel',

  RETAIL_PRICING_CONFIGURATION_DETAILS_SECTION = 'retail-pricing-configuration-details-section',

  STRUCTURED_DIALOG_CONTAINER = 'structured-dialog-container',
  STRUCTURED_DIALOG_CLOSE_BUTTON = 'structured-dialog-close-button',

  LOCK_POPOVER_CONTENT_CONTAINER = 'lock-popover-content-container',
  LOCK_BUTTON_TOGGLE = 'lock-button-toggle',
  POPOVER_CONFIRM_BUTTON = 'popover-confirm-button',

  VIDEO_INPUT_PLACEHOLDER = 'video-input-placeholder',
  VIDEO_UPLOAD_BUTTON = 'video-upload-button',

  NESTED_VIEW_LIST_CONTAINER = 'nested-view-list-container',
  NESTED_VIEW_DETAILS_CONTAINER = 'nested-view-details-container',
  NESTED_VIEW_CONTENT = 'nested-view-content',
  NESTED_VIEW_CREATE_BUTTON = 'nested-view-create-button',
  NESTED_VIEW_BACK_BUTTON = 'nested-view-back-button',
  NESTED_VIEW_SEARCH_INPUT = 'nested-view-search-input',
  NESTED_VIEW_MEATBALL_MENU_BUTTON_CONTAINER = 'nested-view-meatball-menu-button-container',
  NESTED_VIEW_CLICKABLE_HEADER = 'nested-view-clickable-header',

  NO_RESULTS_PLACEHOLDER = 'no-results-placeholder',

  SUBMIT_TO_LENDER_LINKED_SECTION_HEADER = 'submit-to-lender-linked-section-header',

  USER_MENU_GLOBAL_NAV_BUTTON = 'user-menu-global-nav-button',
  LOGIN_AS_MODAL = 'login-as-modal',
  SEARCH_INPUT = 'search-input',
  FILTER_DIALOG_RESULTS_CONTAINER = 'filter-dialog-results-container',

  MASKED_INPUT_BUTTON = 'masked-input-button',

  MEATBALL_MENU_BUTTON_CONTAINER = 'meatball-menu-button-container',
  MENU_BULK_ACTIONS_CONTAINER = 'menu-bulk-actions-container',
  MENU_ITEMS_CONTAINER = 'menu-items-container',

  LOGIN_AS_USER_MEATBALL_MENU_ITEM = 'login-as-user-meatball-menu-item',
  LOGIN_AS_USER_MENU_ITEM = 'login-as-user-menu-item',
  LOGOUT_OF_USER_MENU_ITEM = 'logout-of-user-menu-item',
  MODIFY_MENU_ITEM = 'modify-menu-item',
  DELETE_MENU_ITEM = 'delete-menu-item',

  LOGGED_IN_AS_BANNER = 'logged-in-as-banner',
  LOGGED_IN_AS_BANNER_LOGOUT_LINK = 'logged-in-as-banner-logout-link',

  FILTER_PANEL_SEARCH_INPUT = 'filter-panel-search-input',
  FILTER_PANEL_SEARCH_RESULTS_CONTAINER = 'filter-panel-search-results-container',

  FILTER_PANEL_CREATE_BULK_ADJUSTMENT_BUTTON = 'filter-panel-create-bulk-adjustment-button',

  GLOBAL_NAV_ACTION_BUTTON = 'global-nav-action-button',
  GLOBAL_NAV_MENU_LINK_APPOINTMENT = 'global-nav-menu-link-appointment',
  GLOBAL_NAV_MENU_LINK_CONVERSATION = 'global-nav-menu-link-conversation',
  GLOBAL_NAV_MENU_LINK_LEAD = 'global-nav-menu-link-lead',
  GLOBAL_NAV_MENU_LINK_LEAD_ACTIVITY = 'global-nav-menu-link-lead-activity',
  GLOBAL_NAV_MENU_LINK_TASK = 'global-nav-menu-link-task',
  GLOBAL_NAV_MENU_LINK_TRADE_IN = 'global-nav-menu-link-trade-in',
  GLOBAL_NAV_MENU_LINK_RETAIL_ITEM = 'global-nav-menu-link-retail-item',

  NAV_ENTITY_CREATE_RETAIL_ITEM = 'nav-entity-create-retail-item',
  NAV_ENTITY_CREATE_TRADE_IN = 'nav-entity-create-trade-in',
  NAV_ENTITY_LOG_LEAD_ACTIVITY = 'nav-entity-log-lead-activity',
  NAV_ENTITY_CREATE_TASK = 'nav-entity-create-task',
  NAV_ENTITY_CREATE_APPOINTMENT = 'nav-entity-create-appointment',
  NAV_ENTITY_CREATE_ROOFTOP = 'nav-entity-create-rooftop',
  NAV_ENTITY_CREATE_USER = 'nav-entity-create-user',
  NAV_ENTITY_CREATE_WEBSITE = 'nav-entity-create-website',

  SLIDER_MAX_KNOB = 'slider-max-knob',
  SLIDER_MIN_KNOB = 'slider-min-knob',

  RANGE_INPUT_MIN = 'range-input-min',
  RANGE_INPUT_MAX = 'range-input-max',

  TOGGLE_ON = 'toggle-on',
  TOGGLE_OFF = 'toggle-off',

  TOGGLE_SELECT_INPUT = 'toggle-select-input',

  PAGINATION_PREVIOUS = 'pagination-previous',
  PAGINATION_NEXT = 'pagination-next',

  CONDENSED_LIST_SCROLL = 'condensed-list-scroll',

  OVERLAY_IMAGE_PREVIEW_4_BY_3 = 'overlay-image-preview-4-by-3',
  OVERLAY_IMAGE_PREVIEW_1_BY_1 = 'overlay-image-preview-1-by-1',
  OVERLAY_IMAGE_EXPAND_ICON_4_BY_3 = 'overlay-image-expand-icon-4-by-3',
  OVERLAY_IMAGE_EXPAND_ICON_1_BY_1 = 'overlay-image-expand-icon-1-by-1',

  MOBILE_RICH_TEXT_EDITOR = 'rich-text-editor',

  LEAD_ACTIVITY_REPLY = 'lead-activity-reply',

  LEAD_SIN_DETAILS_VIEW = 'lead-sin-details-view',
  LEAD_SIN_DETAILS_UNMASK_BUTTON = 'lead-sin-details-unmask-button',

  WEBSITE_CREATE_ROUTE_BUTTON = 'websites-create-route-button',
  WEBSITE_ROUTES_CONTAINER = 'websites-routes-section',
  WEBSITE_ROUTES_ADD_FILTER = 'websites-routes-add-filter',
  COLLAPSIBLE_ROUTE_SECTION = 'collapsible-route-section',
  DELETE_ICON = 'delete-icon',
  EDIT_ICON = 'edit-icon',
  LOCK_ICON = 'lock-icon',
  UNLOCK_ICON = 'unlock-icon',
  ICON_BUTTON = 'icon-button',
  ICON_TRUE = 'icon-true',
  ICON_FALSE = 'icon-false',
  ICON_REMOVE = 'icon-remove',
  ICON_DROPDOWN = 'icon-dropdown',
  ICON_INDETERMINATE = 'icon-indeterminate',
  CHEVRON_DOWN_ICON = 'chevron-down-icon',

  COLLAPSE_LINKED_SECTION_BUTTON = 'collapsible-button',
  LINKED_OPEN_ICON = 'linked-open-icon',
  LINKED_CLOSE_ICON = 'linked-close-icon',

  LIST_ITEM_CLICKABLE = 'list-item-clickable',

  VEHICLES_AFFECTED_FOOTER = 'vehicles-affected-footer',

  EDITOR_CONTENT = 'editor-content',
  EDITOR_BUTTON_BOLD = 'editor-button-bold',
  EDITOR_BUTTON_ITALIC = 'editor-button-italic',
  EDITOR_BUTTON_UNDERLINE = 'editor-button-underline',
  EDITOR_BUTTON_BULLET_LIST = 'editor-button-bullet-list',
  EDITOR_BUTTON_NUMBERED_LIST = 'editor-button-numbered-list',
  EDITOR_BUTTON_LANGUAGE_TOGGLE = 'editor-button-language-toggle',
  EDITOR_BUTTON_FORMAT_SIZE = 'editor-button-format-size',
  EDITOR_BUTTON_COLOR_PALETTE = 'editor-button-color-palette',
  EDITOR_BUTTON_GENERATE_DESCRIPTION = 'editor-button-generate-description',
  EDITOR_BUTTON_MORE_MENU = 'editor-button-more-menu',
  EDITOR_BUTTON_LINK = 'editor-button-link',
  EDITOR_BUTTON_EDIT_LINK = 'editor-button-edit-link',
  EDITOR_BUTTON_EXTERNAL_LINK = 'editor-button-external-link',
  EDITOR_BUTTON_UNLINK = 'editor-button-unlink',
  EDITOR_DIALOG_SET_LINK = 'editor-dialog-set-link',
  EDITOR_ICON_DROP_DOWN_ITEM_PREFIX = 'editor-icon-drop-down-item-',

  MULTILINGUAL_TEXT_INPUT_BUTTON_INSERT = 'multilingual-text-input-button-insert',
  SELECT_ALL_TOGGLE = 'select-all-toggle',
}

export function builderFieldTestId(field: string) {
  // "." cannot be used in a data-testid, replace all with "-"
  return field ? `${ElementTestId.BUILDER_STEP_FIELD_INPUT_PREFIX}${field.replaceAll('.', '-')}` : field;
}

export function listSelectionItemTestId(id: string | number) {
  return `${ElementTestId.LIST_SELECTION_ITEM_PREFIX}${id.toString().replaceAll('.', '-')}`;
}

export function linkedSectionItemTestId(id: ExtendedEntityType) {
  return `${ElementTestId.LINKED_SECTION_ITEM_PREFIX}${id}`;
}

export function builderItemTypeSelectorTestId(id: string) {
  return `${ElementTestId.BUILDER_ITEM_TYPE_SELECTOR_PREFIX}${id}`;
}

export function builderListSelectionCategoryTestId(id: string) {
  return `${ElementTestId.BUILDER_LIST_SELECTION_CATEGORY_PREFIX}${id}`;
}

export function gridCellItemEditIconTestId(label: string) {
  return `${ElementTestId.DETAILS_VIEW_EDIT_ICON}-${label.toString().replaceAll('.', '-')}`;
}

export function gridSectionTestId(label: string) {
  return `${ElementTestId.DETAILS_VIEW_GRID_SECTION}-${label.toString().replaceAll('.', '-')}`;
}

export function rangeFieldTestId(
  field: string,
  rangeId: ElementTestId.RANGE_INPUT_MAX | ElementTestId.RANGE_INPUT_MIN
) {
  return `${field}-${rangeId}`;
}

export function toggleFieldTestId(field: string, toggleId: ElementTestId.TOGGLE_ON | ElementTestId.TOGGLE_OFF) {
  return `${field}-${toggleId}`;
}

export function detailsViewTabsTestId(entity: ExtendedEntityType, tabId: TabType) {
  return `${ElementTestId.DETAILS_VIEW_TAB_CONTAINER}-${entity}-${tabId}`;
}

export function toggleSelectOptionTestId(id: string) {
  return `${ElementTestId.TOGGLE_SELECT_INPUT}-${id}`;
}

export function maskedInputButtonTestId(id: string) {
  return `${ElementTestId.MASKED_INPUT_BUTTON}-${id}`;
}

export function collapsibleRouteSectionTestId(id: string) {
  return `${ElementTestId.COLLAPSIBLE_ROUTE_SECTION}-${id}`;
}

/**
 * Generate test id for a builder step legend item
 *
 * @param builder
 * @param stepId
 */
export function builderStepLegendTestId(builder: BuilderType | DemoBuilderType, stepId: string) {
  return `${ElementTestId.BUILDER_STEP_LEGEND_CONTAINER}-${builder}-${stepId}`;
}

export enum TestingMakeIds {
  AUDI = 'FCJMnQUyR8KmXylRFpXE1A',
  BMW = '-NzMIed_TBCmxZ2XKC5uUA',
  LAND_ROVER = 'IlaASTcJTyGzVV864oEoiw',
  CHEVROLET = 'YG8DfvQ8QoSYOuYm6iJ_Vw',
}

// The enum key name for TestingModelIds should include the make as a prefix (make_model)
export enum TestingModelIds {
  AUDI_A3 = 'wUIq5NrNSqWQVkYeH8Q4EA',
  BMW_M3 = 'v3aZfpsKS5qarVyfo1KLxw',
  LAND_ROVER_RANGE_ROVER = 'Rax1W7G9T_-y0pSC_QDuEA',
  LAND_ROVER_RANGE_ROVER_SPORT = 'PDJb1yIATEaN0Fc4P9r3kg',
  CHEVROLET_SILVERADO_1500 = 'HFqgc4YrQUuIF_QOOhiBfg',
}

// The enum key name for TestingSubModelIds should include the make and model as a prefix (make_model_submodel)
export enum TestingSubModelIds {
  AUDI_A3_18T_KOMFORT = 'frrpL3utTOe1yBzhVjsJJQ',
  BMW_M3_COMPETITION_M = 'Yf0snzgqRBuPUyfCoy9lAQ',
  LAND_ROVER_RANGE_ROVER_ES = 'IfFlRz2GQ4GnynTGoxUcZw',
  LAND_ROVER_RANGE_ROVER_COUNTRY = '3vHziltqQ2-uGqGqq8zeHQ',
  CHEVROLET_SILVERADO_1500_LT_TRAIL_BOSS = 'ln61ar9FQwWRxI6ayX9nLA',
}

// The enum key name for TestingTrimIds should include the make, model, and sub-model as prefix (make_mod_submod_trim)
export enum TestingTrimIds {
  LAND_ROVER_RANGE_ROVER_HSE_4DR = '3rShJBIiQ_ucDVBoXHcAkw',
  CHEVROLET_SILVERADO_1500_SHORT_BOX_4_WHEEL_DRIVE = 'RFuJ4anhTTmDX5cZBTyr_w',
  CHEVROLET_SILVERADO_1500_STANDARD_BOX_4_WHEEL_DRIVE = 'peZ_yKmdTxOxt9Kdfz1Jbg',
}

export enum TestingTagIds {
  MANAGER_SPECIAL = 'yoFV_OSvRgKBLjIZw7HdDw',
  NEW_ARRIVAL = 'rqw6zzL8T9ayR8fH8bva0w',
}

export enum TestingLeadSourceIds {
  CAR_GURUS = 'Ph0Y8O7QSya_-TqZOwDP4g',
  OTHER = '23Kbz5wHSqqE-FwDsSY1FQ',
}

export enum TestingSINValues {
  VALID_SIN_1 = '287-212-799',
  VALID_SIN_2 = '046-454-286',
}

export enum TestingWhitelabelIds {
  EDEALER = '9dzH8ID1SJuBb1kmvO5UNw',
}

export const TestingAvailableTermOptions = [24, 36, 48, 60, 72, 84, 96];
