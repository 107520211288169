import { chunk, get, isEmpty, isNil, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { setChromeRatesTooltip } from 'components/sections/createModify/rooftopPricingSegments/utils';
import { getPricingSourceOptions } from 'components/sections/shared/ItemMetaHelpers';
import { RooftopFinancePricingSegment, RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { StepFieldType } from 'enums/stepFieldType';
import type {
  InterestRateEntry,
  InterestRateEntryInput,
  RooftopFinancePricingSegmentMetaQuery,
  RooftopRetailFinancePricingDetailQuery,
  SelectStringOptionFragment,
} from 'store/api/graph/interfaces/types';
import {
  DefaultTermType,
  FinanceRetailPricingConfigurationInputParameter,
  InterestRateType,
  PricingSource,
} from 'store/api/graph/interfaces/types';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { getStepField, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import {
  getInterestRateQueryAlias,
  getInterestRateQueryVar,
  getNestedConfigurationField,
} from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

import { RooftopFinanceSegmentRatesBuilderFields } from './interfaces';

const { t } = translate;

class RatesStep extends StepComponentCore {
  constructor(props: StepComponentProps, context) {
    super(props);
    const {
      tier: { data, activeStep, formData, metadata, seededData },
    } = props;

    const {
      subContexts: { featureFlags },
    } = context;

    // The list of available term options come from metadata
    const {
      rooftopRetailPricing: {
        financeConfiguration: {
          availableTerms: availableTermOptions,
          interestRateType: interestRateTypeOptions,
          defaultTermType: defaultTermTypeOptions,
          pricingSource: pricingSourceOptions,
        },
      },
    } = metadata.mutation;

    /*
     * If data is null or empty object, then use the formData from the previous step
     * TODO: this will be replaced when implementing the modify functionality
     */
    const configurationData =
      Object.keys(data || {}).length > 0
        ? get(data, RooftopPricingSegment.CONFIGURATION)
        : formData?.financeConfiguration;

    // Term options are Ints, but the Badge rendering expects a string
    const defaultTermOptions = availableTermOptions.filter(option =>
      configurationData?.availableTerms?.includes(option.id)
    );

    const defaultTerm = availableTermOptions.find(option => option.id === configurationData?.defaultTerm);

    const rooftop = data?.rooftopName || seededData?.rooftopName;
    const pricingSourceOptionsFiltered = getPricingSourceOptions(
      rooftop?.bundle?.features?.oem,
      pricingSourceOptions,
      featureFlags?.[LDFeatureFlags.oemPricingSourcesEnabled]
    );

    const hasPricingSource = configurationData?.pricingSource !== undefined;
    const isCustomSource = configurationData?.pricingSource === PricingSource.CUSTOM;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE]: {
        selectedValue: pricingSourceOptions.find(
          option => option.id === get(data, RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE)
        ),
        options: pricingSourceOptionsFiltered,
        hasSeparator: !hasPricingSource || isCustomSource,
      },
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES, false),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !hasPricingSource || isCustomSource,
          },
        ]),
      },
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS]: {
        selectedValue: availableTermOptions.filter(option => configurationData?.availableTerms?.includes(option.id)),
        options: availableTermOptions,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isCustomSource,
        }),
      },
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM]: {
        selectedValue: defaultTerm,
        options: defaultTermOptions,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !defaultTerm },
          { type: StepFieldDisplayType.HIDDEN, active: !isCustomSource },
        ]),
      },
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE]: {
        selectedValue: interestRateTypeOptions.find(option => option.id === configurationData?.interestRateType),
        // Hide interest rate type if pricing source is custom
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isNil(configurationData?.pricingSource) || isCustomSource,
        }),
        options: interestRateTypeOptions.filter(option => option.id !== InterestRateType.CUSTOM_RATES),
      },
      [RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE]: {
        selectedValue: defaultTermTypeOptions.find(option => option.id === configurationData?.defaultTermType),
        // Hide default term type if pricing source is custom
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isNil(configurationData?.pricingSource) || isCustomSource,
        }),
        options: defaultTermTypeOptions.filter(option => option.id !== DefaultTermType.CUSTOM),
      },
    });

    this.generateRateFields();
    setChromeRatesTooltip(
      getStepField(RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE, this.fields)
        .selectedValue?.id,
      activeStep
    );
  }

  generateRateFields() {
    const {
      tier: { isCreating, formData, data },
    } = this.props;

    // If the pricing source is not CUSTOM, then these rate fields will be hidden
    const isPricingSourceCustom =
      getStepField(RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE, this.fields)
        .selectedValue?.id === PricingSource.CUSTOM;

    // The dynamic rate fields will appear after the default term field, we need to get the index of that field
    const defaultTermFieldIndex = this.fields.findIndex(
      field => field.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM
    );

    // Get the available terms the user has selected, or if modifying, get the saved num of terms
    const availableTerms = getStepField(
      RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS,
      this.fields
    ).selectedValue;

    const interestRates = isCreating
      ? formData?.financeConfiguration?.interestRates
      : (get(data, RooftopFinancePricingSegment.CONFIGURATION_INTEREST_RATES) as InterestRateEntry[]);

    // If there are no terms, then there are no rate fields to generate
    if (!availableTerms?.length) {
      this.fields = this.fields.slice(0, defaultTermFieldIndex + 1);
      this.forceUpdate();
      return;
    }

    /*
     * All the fields in this step are dynamically generated by iterating through each available term. Each term
     * will have a header, followed by a field for the interest rate, and then a field to capture the residual rate
     * for each mileage allowance
     */
    const rateFields = availableTerms
      ?.map((term, termIndex) => {
        const existingField = getStepField(getInterestRateQueryVar(term.id), this.fields);
        return [
          // This is the header for this term
          {
            queryVar: term.id.toString(),
            label: `${term.id} ${t('month_term')}`,
            queryAlias: [getNestedConfigurationField(RooftopFinancePricingSegment.CONFIGURATION_INTEREST_RATES)],
            groupType: StepFieldType.PLAIN_TEXT,
            displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isPricingSourceCustom }),
          },
          // This is the input for the interest rate
          {
            // Each field requires a queryVar as a unique ID, there is nothing special about this value
            queryVar: getInterestRateQueryVar(term.id),
            queryAlias: [getInterestRateQueryAlias(termIndex)],
            label: t('interest_rate'),
            // Field is OMITTED, as the save() method in this class will dynamically generate the payload data
            displayType: setDisplayTypes([
              { type: StepFieldDisplayType.OMITTED, active: true },
              { type: StepFieldDisplayType.HIDDEN, active: !isPricingSourceCustom },
            ]),
            groupType: StepFieldType.PERCENTAGE,
            // If modifying, find the existing saved interest rate corresponding to the current term
            selectedValue: isEmpty(existingField)
              ? interestRates?.find(interestRate => Number(interestRate.term) === Number(term.id))?.interestRate
              : existingField.selectedValue,
            hasSeparator: true,
          },
        ] as StepField[];
      })
      .flat();

    // Append these new dynamic interest rate fields after the default term field
    this.fields = [...this.fields.slice(0, defaultTermFieldIndex + 1), ...rateFields];
    this.forceUpdate();
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE) {
      const prevInterestRateTypeFieldValue = stepField.selectedValue?.id;
      const pricingSource = getStepField(
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE,
        this.fields
      );
      // Prevent unsetting interest rate type when pricing source is chrome
      if (pricingSource.selectedValue?.id === PricingSource.CHROME && prevInterestRateTypeFieldValue === value?.id) {
        return;
      }
    }

    super.onFieldSelection(stepField, value);

    /**
     * Whenever the list of available terms change, we must update the list of options that the user
     * can select for the default terms.
     */
    if (stepField.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS) {
      const defaultTermField = getStepField(
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM,
        this.fields
      );
      defaultTermField.options = value;

      // Enable the default term field if there are options available
      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !value?.length }, defaultTermField);

      // If the default term is no longer in the available terms, then clear the field
      if (!value.some(item => item.id === defaultTermField.selectedValue?.id)) {
        defaultTermField.selectedValue = null;
      }

      // Whenever the available terms selection changes, we need to re-generate the rate fields
      this.generateRateFields();
    }

    if (stepField.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE) {
      this.onPricingSourceChanged(value.id as PricingSource);
    }
  }

  onPricingSourceChanged(value: PricingSource) {
    setChromeRatesTooltip(value, this.props.tier.activeStep);

    const pricingSourceField = getStepField<
      SelectStringOptionFragment,
      RooftopRetailFinancePricingDetailQuery['item'],
      RooftopFinancePricingSegmentMetaQuery['metadata']
    >(RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE, this.fields);

    const hasPricingSource = pricingSourceField?.selectedValue?.id !== undefined;
    const isCustomSource = pricingSourceField?.selectedValue?.id === PricingSource.CUSTOM;

    for (const field of this.fields) {
      if (
        [
          RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE,
          RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE,
        ].includes(field.queryVar as RooftopFinanceSegmentRatesBuilderFields)
      ) {
        // If the pricing source is a custom one, then hide the incentive related fields
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: isCustomSource }, field);
      } else if (field.queryVar !== RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE) {
        // If the pricing is not a custom one, then hide the dynamically generated term and interest rate fields
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isCustomSource }, field);
      }

      if (field.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE) {
        field.hasSeparator = !hasPricingSource || isCustomSource;
      }

      if (field.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES) {
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !hasPricingSource || isCustomSource }, field);
      }
    }

    const interestRateTypeField = getStepField(
      RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE,
      this.fields
    );
    // When pricing source is custom hide the interest rate type field
    if (value === PricingSource.CUSTOM) {
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, interestRateTypeField);
      interestRateTypeField.required = false;
    }

    /*
     * When modifying, if the original pricing source was CUSTOM, then the default term type and interest rate type
     * fields would have a CUSTOM value, however this value is hidden from the user, so these fields will be cleared
     * allowing the user to select a new option.
     */
    if (value === PricingSource.CHROME) {
      const defaultTermField = getStepField(
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE,
        this.fields
      );

      if (defaultTermField.selectedValue?.id === DefaultTermType.CUSTOM) {
        defaultTermField.selectedValue = null;
      }

      interestRateTypeField.required = true;

      if (interestRateTypeField.selectedValue?.id) {
        interestRateTypeField.selectedValue = null;
      }
    }

    this.forceUpdate();
  }

  showValidationError(fields: string[]) {
    this.setTier({
      errors: [
        {
          message: translate.t('please_enter_an_amount'),
          extensions: {
            fields,
          },
        },
      ],
    });
  }

  /**
   * Get the interest rate data from the dynamically generated fields. If a field was left empty, null will be returned.
   */
  getCustomInterestRateFieldData(): InterestRateEntryInput[] | null {
    // Get the available terms that the user has selected
    const availableTerms = getStepField(
      RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS,
      this.fields
    ).selectedValue;

    // This will hold the interest rates per term
    const interestRates: InterestRateEntryInput[] = [];

    /*
     * Each term will have a header field for displaying the term number, an input field for the interest rate.
     * We will split the fields up by this grouping, which means each group will have 2 fields
     * (interest rate, and term header)
     */

    // Each group of fields for entering an interest rate consist of a header and single input, so the size is 2.
    const ratesFieldGroupSize = 2;

    // The dynamic rate fields appear after the default term field, get the index of this field for splicing purposes
    const defaultTermFieldIndex = this.fields.findIndex(
      field => field.queryVar === RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM
    );

    // Interest rate fields cannot be left blank, keep track of any invalid fields
    const invalidFields: string[] = [];

    const fieldGroups = chunk(this.fields.slice(defaultTermFieldIndex + 1), ratesFieldGroupSize);

    for (const [termIndex, fieldGroup] of fieldGroups.entries()) {
      // The nth term for this interest rate corresponds to the nth term selected by the user
      const term = availableTerms[termIndex]?.id;
      // This field group consists of a header and input for the interest rate, therefore the rate field index is 1
      const interestRateFieldIndex = 1;

      if (!fieldGroup[interestRateFieldIndex].selectedValue) {
        invalidFields.push(fieldGroup[interestRateFieldIndex].queryVar);
      }

      // Include the value of the interest rate input field along with the term value in the interestRates array
      interestRates.push({
        interestRate: fieldGroup[interestRateFieldIndex].selectedValue,
        term,
      } as InterestRateEntryInput);
    }

    if (invalidFields.length > 0) {
      this.showValidationError(invalidFields);
      return null;
    } else {
      return interestRates;
    }
  }

  async save() {
    const variableOverrides = {};

    const pricingSource = getStepField(
      RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_PRICING_SOURCE,
      this.fields
    ).selectedValue?.id;

    const defaultTermTypeField = getStepField(
      RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE,
      this.fields
    );

    if (!defaultTermTypeField.selectedValue?.id || pricingSource === PricingSource.CUSTOM) {
      set(
        variableOverrides,
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE,
        DefaultTermType.CUSTOM
      );
    }

    if (pricingSource === PricingSource.CUSTOM) {
      /**
       * Set `include after-tax rebates` to null if pricing source is custom
       */
      set(
        variableOverrides,
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
        null
      );
      set(variableOverrides, RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_CLEAR, [
        FinanceRetailPricingConfigurationInputParameter._includeAfterTaxRebates,
      ]);

      const interestRates = this.getCustomInterestRateFieldData();

      set(
        variableOverrides,
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATE_TYPE,
        InterestRateType.CUSTOM_RATES
      );

      // There was an error getting interest rates, user probably forgot to fill out a field
      if (!interestRates) {
        return false;
      }

      set(
        variableOverrides,
        RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATES,
        interestRates
      );
    } else {
      /*
       * When saving a non-custom one, we need to clear any of the custom values entered by the user for
       * for terms and interest rates
       */
      set(variableOverrides, RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_TERMS, null);
      set(variableOverrides, RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_DEFAULT_TERM, null);
      set(variableOverrides, RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_INTEREST_RATES, null);
      set(variableOverrides, RooftopFinanceSegmentRatesBuilderFields.FINANCE_CONFIGURATION_CLEAR, [
        FinanceRetailPricingConfigurationInputParameter._defaultTerm,
        FinanceRetailPricingConfigurationInputParameter._interestRates,
        FinanceRetailPricingConfigurationInputParameter._availableTerms,
      ]);
    }

    return super.save({}, variableOverrides);
  }
}

export default RatesStep;
