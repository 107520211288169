import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import WebsiteRouteSortingFields from '../shared/steps/InitialItemSortingFields';

import { WebsiteRouteBuildersStepId } from './interfaces';
import WebsiteRouteDetailsFields from './steps/DetailsFields';
import WebsiteRouteDetailsStep from './steps/DetailsStep';
import WebsiteRouteSortingStep from './steps/WebsiteRoutesSortingStep';
import { websiteRouteCreate, websiteRouteModify } from './WebsiteRouteCreateModifyQuery';
import WebsiteRouteQueryMethods from './WebsiteRouteQueryMethods';

const WebsiteRouteBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.WEBSITE_ROUTE_CREATE]: {
    getMetaData: WebsiteRouteQueryMethods.getMetaData,
    resourceType: ResourceType.WEBSITES,
    refetchQueries: WebsiteRouteQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.WEBSITES, level: AccessLevel.FULL }],
    steps: [
      {
        id: 'WEBSITE_ROUTE_DETAILS',
        step: WebsiteRouteDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: WebsiteRouteDetailsFields,
      },
      {
        id: 'INITIAL_SORT_RULE',
        step: WebsiteRouteSortingStep,
        stepName: 'initial_sort_rule',
        fields: WebsiteRouteSortingFields,
      },
    ],
    mutations: {
      create: websiteRouteCreate,
      validate: websiteRouteCreate,
    },
  },
  [BuilderType.WEBSITE_ROUTE_MODIFY]: {
    getMetaData: WebsiteRouteQueryMethods.getMetaData,
    getData: WebsiteRouteQueryMethods.getData,
    resourceType: ResourceType.WEBSITES,
    refetchQueries: WebsiteRouteQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.WEBSITES, level: AccessLevel.FULL }],
    steps: [
      {
        id: WebsiteRouteBuildersStepId.WEBSITE_ROUTE_DETAILS,
        step: WebsiteRouteDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: WebsiteRouteDetailsFields,
      },
      {
        id: WebsiteRouteBuildersStepId.INITIAL_SORT_RULE,
        step: WebsiteRouteSortingStep,
        stepName: 'initial_sort_rule',
        isEnabled: true,
        fields: WebsiteRouteSortingFields,
      },
    ],
    mutations: {
      modify: websiteRouteModify,
    },
  },
};

export default WebsiteRouteBuilders;
