import { gql } from '@apollo/client';

import { featureSet } from 'store/api/graph/fragments/featureSet';

export const rooftopFeatureBundle = gql`
  fragment RooftopFeatureBundle on FeatureBundle {
    package {
      id
      name
      features {
        ...FeatureSetFragment
      }
    }
    addOns {
      ...FeatureSetFragment
    }
    features {
      ...FeatureSetFragment
    }
  }
  ${featureSet}
`;
