import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import {
  MSRP_PRICING_AND_MILEAGE_FIELD,
  RetailItemPricingInfoBuilderFields,
} from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/interfaces';
import { WarrantyListItem } from 'components/ui/lists/WarrantyListItem';
import type { ButtonSettings } from 'components/ui/shared/Button';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  MotorcycleAttributesInputParameter,
  RetailItemModifyParameter,
  VehicleAttributesInputParameter,
  WarrantyInputParameter,
} from 'store/api/graph/interfaces/types';
import { RED_500 } from 'styles/tokens';

import { RetailItemDetailsBuilderFields } from './interfaces';

const PricingAndMileageFields: StepFields = {
  [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [`${InventoryItemAttributesPointer.VEHICLE}.mileage`]: {
    label: 'mileage',
    queryAlias: 'mileage',
    groupType: StepFieldType.MILEAGE,
    clear: { field: VehicleAttributesInputParameter._mileage, target: InventoryItemAttributesPointer.VEHICLE },
  },
  [`${InventoryItemAttributesPointer.MOTORCYCLE}.mileage`]: {
    label: 'mileage',
    queryAlias: 'mileage',
    groupType: StepFieldType.MILEAGE,
    clear: { field: MotorcycleAttributesInputParameter._mileage, target: InventoryItemAttributesPointer.MOTORCYCLE },
  },
  [DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE]: {
    label: 'warranty',
    queryAlias: [InventoryItem.WARRANTY_QUEBEC_CATEGORY_NAME],
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    overrideSubStepRenderElement: WarrantyListItem,
    subStep: [SubStepType.DEFAULT],
    clear: { field: WarrantyInputParameter._quebecCategoryOverride, target: InventoryItem.WARRANTY },
    settings: {
      hideSearchHeader: true,
    } as ListSelectionSettings,
    hasSeparator: true,
  },
  [DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB]: {
    label: 'show_warranty',
    queryAlias: ['showWeb', InventoryItem.WARRANTY_SHOW_WEB],
    groupType: StepFieldType.SWITCH,
    clear: { field: WarrantyInputParameter._showWeb, target: InventoryItem.WARRANTY },
    hasSeparator: true,
  },
  [RetailItemPricingInfoBuilderFields.COST]: {
    label: 'cost',
    groupType: StepFieldType.CURRENCY,
    clear: { field: RetailItemModifyParameter._cost },
  },
  [RetailItemPricingInfoBuilderFields.MSRP]: {
    label: 'msrp',
    queryAlias: [MSRP_PRICING_AND_MILEAGE_FIELD],
    groupType: StepFieldType.CURRENCY,
    clear: { field: RetailItemModifyParameter._msrp },
  },
  [RetailItemPricingInfoBuilderFields.PRICE_1]: {
    label: 'price_1',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'price_1_and_2_tooltip',
    clear: { field: RetailItemModifyParameter._price1 },
  },
  [RetailItemPricingInfoBuilderFields.PRICE_2]: {
    label: 'price_2',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'price_1_and_2_tooltip',
    clear: { field: RetailItemModifyParameter._price2 },
    hasSeparator: true,
  },
  [RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED]: {
    label: 'cash_pricing_enabled',
    queryAlias: [InventoryItem.CASH_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED]: {
    label: 'finance_pricing_enabled',
    queryAlias: [InventoryItem.FINANCE_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED]: {
    label: 'lease_pricing_enabled',
    queryAlias: [InventoryItem.LEASE_RETAIL_PRICING],
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPricingInfoBuilderFields.REVERT_CONFIGURATION]: {
    groupType: StepFieldType.FIELD_BUTTON,
    settings: {
      buttonLabel: 'revert_to_segment_values',
      colour: RED_500,
    } as ButtonSettings,
  },
};

export default PricingAndMileageFields;
