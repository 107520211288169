import { memo } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { BORDER_RADIUS_150, NEUTRAL_0, SPACE_400 } from 'styles/tokens';
import { FONT_SIZE_11 } from 'styles/typography';

const Header = styled(Label)`
  margin-bottom: 15px;
`;

const TagsText = styled(Text)`
  white-space: break-spaces;
`;

const PreviewBox = styled.div`
  background: ${NEUTRAL_0};
  border-radius: ${BORDER_RADIUS_150};
  padding: ${SPACE_400};
  margin: ${SPACE_400} 0;
  min-height: 120px;
  word-wrap: break-word;
`;

const PreviewText = styled(Text)`
  white-space: pre-wrap;
`;

const NoteText = styled(Text)`
  font-size: ${FONT_SIZE_11};
  font-style: italic;
  white-space: break-spaces;
`;

interface Props {
  /** The text to display as a note below the preview. */
  noteText: string;
  /** The text to display in the preview box. */
  previewText: string;
  /** The title to display above the preview text. */
  previewTitle: string;
  /** The text to display as a list of tags. */
  tags: string;
  /** The title to display above the tags. */
  title: string;
}

const DisclaimerPreview = ({ noteText, previewText, previewTitle, title, tags }: Props) => (
  <>
    <Header>{title}</Header>
    <TagsText>{tags}</TagsText>
    <PreviewBox>
      <Header>{previewTitle}</Header>
      <PreviewText>{previewText}</PreviewText>
    </PreviewBox>
    <NoteText>{noteText}</NoteText>
  </>
);

export default memo(DisclaimerPreview);
