import { groupCreate } from 'components/sections/createModify/groups/GroupCreateModifyQueries';
import DetailsFields from 'components/sections/createModify/groups/steps/DetailsFields';
import DetailsStep from 'components/sections/createModify/groups/steps/DetailsStep';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

// Group builder is gated at the substep level
const GroupsBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.GROUP_CREATE]: {
    steps: [{ id: 'GROUP_CREATE', step: DetailsStep, stepName: 'create_group', fields: DetailsFields }],
    legend: null,
    mutations: {
      create: groupCreate,
    },
    requiredPermissions: [{ resource: ResourceType.GROUPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.GROUPS,
  },
};

export default GroupsBuilders;
