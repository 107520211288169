import { memo } from 'react';

import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopTag, RooftopTagSettings } from 'enums/columns/rooftopTag';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { NAME, TYPE } = RooftopTag;

const { BOLD } = ItemGridRowDisplayType;

/**
 * Note: This grid is always used in a nested view, so will always see one field per row.
 */
const gridConfig: ItemGridRowProps[] = [
  {
    fields: [NAME],
    displayType: [BOLD],
    canEdit: true,
  },
  {
    fields: [TYPE],
    displayType: [BOLD],
    canEdit: false,
  },
];

const TagDetailsTab = memo<ItemTabProps>(({ item: tag, onEdit }) =>
  tag ? (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          key={formatItemGridRowSectionKey(config.fields)}
          item={tag}
          settings={RooftopTagSettings}
          onEdit={authorizedCallback({
            cb: (field?: string) => onEdit(field, translate.t('tag')),
            isAuth: !!onEdit,
          })}
          config={config}
        />
      ))}
    </>
  ) : null
);

export default TagDetailsTab;
