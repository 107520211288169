import { createContext } from 'react';

import type { SecondaryViewType, SecondaryViewWidth } from 'containers/nestedView/interfaces';
import type { SecondaryDetailViewProps, SecondaryListTabViewProps } from 'containers/nestedView/NestedViewSettings';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import type { PaginationProps } from 'enums/pagination';
import type { TabType } from 'enums/tabType';
import type { SetViewParameters } from 'providers/NestedViewProvider';

export interface SecondaryViewItem {
  /** Title of the section being viewed */
  title: string;
  /** The type of view this item is */
  type: SecondaryViewType;
  /** A method used to transform the data from the API (may be unnecessary) */
  queryAdapter?: (data: unknown) => void;
  /** The queryVars required for this view to show its info (used mainly for condensedlist) */
  queryVars?: Record<string, unknown>;
  /** The entity being viewed */
  entityType: ExtendedEntityType;
  /** The width of this Secondary View (Default is SecondaryViewWidth.DEFAULT) */
  width: SecondaryViewWidth;
  /** The configuration for this view */
  config: SecondaryDetailViewProps | SecondaryListTabViewProps;
  /** Any seeded data being passed into the view */
  seededData?: any;
}

export interface NestedPaginationState extends PaginationProps {
  tabId: TabType;
}

/**
 * The context class used and referenced by for nested view rendering
 */
export const NestedViewContext = createContext<
  | {
      viewItems: SecondaryViewItem[];
      setView: (args: SetViewParameters) => void;
      /**
       * Update current view - allow passing in of seeddata
       */
      updateCurrentView: <T = any>(data?: T) => void;
      back: () => void;
      close: () => void;
      isDetailsShown: boolean;
      // Global state
      activeDetailsTab?: TabType;
      setActiveDetailsTab: (tab?: TabType) => void;
      activeListTab?: TabType;
      setActiveListTab: (tab?: TabType) => void;
      lastOpenedItem?: string;
      setLastOpenedItem: (position?: any) => void;
      pagination?: NestedPaginationState[];
      setPagination: (pagination?: NestedPaginationState[]) => void;
      keyword?: string;
      setKeyword: (keyword?: string) => void;
    }
  | undefined
>(undefined);
