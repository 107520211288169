import DynamicImport from 'components/core/DynamicImport';
import { TabType } from 'enums/tabType';
import type { AppointmentResponseType } from 'store/api/graph/responses/responseTypes';

export const appointmentDetailsTabs = [
  {
    tabId: TabType.DETAILS,
    component: DynamicImport(() => import('components/sections/appointments/AppointmentDetailsTab')),
  },
  {
    tabId: TabType.LINKED,
    component: DynamicImport(() => import('components/sections/appointments/AppointmentLinkedTab')),
  },
];

export const getAppointmentDetailsTitle = (item: AppointmentResponseType) => item?.title;
