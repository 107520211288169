import { createContext } from 'react';

import type { FeatureFlagSet } from 'hooks/useFeatureFlags';
import type { UserType } from 'store/api/graph/responses/responseTypes';
import type { RequiredPermissions } from 'types/Permissions';

export type Notifications = Pick<UserType, 'hasIncomingLeadActivities' | 'hasUnreadConversations'>;

export type ImpersonatingUser = Pick<UserType, 'id' | 'displayName'>;

export interface UserContextInterface {
  user: UserType;
  userNotifications: Notifications;
  setUser: (user: UserType) => void;
  setUserNotifications: (userNotifications: Notifications) => void;
  updateUserNotifications: () => Promise<boolean>;
  /*
   * TODO: Support default AccessLevel.READ when declaring these arrays,
   * potentially support ResourtType[] as well as Permission[]
   */
  hasPermissions: (permissions: RequiredPermissions) => boolean;
  /** Users with white label scope or higher. e.g. Such users can grant all permissions */
  isWhiteLabelScoped: boolean;
  canAccessMultipleRooftops: boolean;
  lastLogin?: number;
  setLastLogin: (lastLogin?: number) => void;
  /** Impersonate as a certain user */
  impersonateAsUser: (user: ImpersonatingUser | undefined) => void;
  /** Any errors that occured when attempting to impersonate */
  impersonationError?: string;
  /** Clears errors that occured when attempting to impersonate */
  clearImpersonationError: () => void;
  /** Feature flag from launchdarkly */
  featureFlags: FeatureFlagSet;
}

/**
 * The User context class used and referenced by everything in the app
 */
export const UserContext = createContext<UserContextInterface | undefined>(undefined);
