import { useCallback } from 'react';

import styled from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import LockButton from 'components/ui/shared/LockButton';
import Toggle, { ToggleTitle } from 'components/ui/shared/Toggles';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { listSelectionItemTestId } from 'enums/testing';

const TogglesHeader = styled(ToggleTitle)`
  width: 100%;
  padding: 20px 14px;
`;

const ToggleItem = styled(Toggle)`
  padding: 0 15px;
  margin-bottom: 10px;
  width: 100%;
  text-transform: capitalize;
`;

type ListSelectionTogglesProps = {
  /** The filtered list of available options */
  filteredOptions?: StepField[];
  /** Callback for when an option is selected */
  onSelect?: (item: SubStepOption | StepField[]) => void;
  /** Parent className used to prefix className for each toggle */
  baseClassName: string;
  /** A callback for handling field locking confirmation */
  onConfirmToggleFieldLock?: (optionQueryVar?: string) => void;
};

const ListSelectionToggles = ({
  filteredOptions = [],
  onSelect = () => {},
  baseClassName,
  onConfirmToggleFieldLock,
}: ListSelectionTogglesProps) => {
  const onFieldGroupChange = useCallback(
    (queryVar, value) => {
      filteredOptions.find(field => field.queryVar === queryVar)!.selectedValue = value;
      onSelect(filteredOptions);
    },
    [filteredOptions, onSelect]
  );

  const onConfirm = useCallback(queryVar => onConfirmToggleFieldLock?.(queryVar), [onConfirmToggleFieldLock]);

  return (
    <>
      <TogglesHeader />
      {filteredOptions.map(field => {
        const childrenBeforeLabel =
          (field.groupSubTypes?.includes?.(StepFieldSubType.LOCKABLE) && (
            <LockButton
              onConfirm={() => onConfirm(field.queryVar)}
              isLocked={field.isLocked}
              testId={listSelectionItemTestId(field.queryVar)}
            />
          )) ||
          undefined;

        return (
          <ToggleItem
            testId={listSelectionItemTestId(field.queryVar)}
            key={field.queryVar}
            label={field.label!}
            value={field.selectedValue}
            isBinary={!field.clear && !field.settings?.isClearable}
            onChange={onFieldGroupChange.bind(null, field.queryVar)}
            className={`${baseClassName}-${field.id}`}
            childrenBeforeLabel={childrenBeforeLabel}
          />
        );
      })}
    </>
  );
};

export default ListSelectionToggles;
