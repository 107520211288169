/* All of the available route paths */

export enum RoutePath {
  APPOINTMENTS = '/appointments',
  WEBSITES = '/websites',
  CONVERSATIONS = '/conversations',
  DASHBOARD = '/dashboard',
  FORGOT_PASSWORD = '/forgot-password',
  HOME = '/',
  LEAD_ACTIVITIES = '/incoming',
  LEADS = '/leads',
  LOGIN = '/login',
  MOBILE_RICH_TEXT_EDITOR = '/mobile-rich-text-editor',
  REQUEST_INVITE = '/request-invite',
  RESET_PASSWORD = '/reset-password',
  RETAIL_ITEMS = '/retail',
  ROOFTOPS = '/rooftops',
  TASKS = '/tasks',
  TRADE_IN_ITEMS = '/trade-ins',
  MY_ACCOUNT = '/my-account',
  USERS = '/users',
}

export enum SubRoute {
  TABLE = '/table',
}

export enum ExternalSites {
  V4_INVENTORY = '/inventory',
}
