import { gql } from '@apollo/client';

export const taskConnectionFilterFragment = gql`
  fragment TaskConnectionFilterFragment on TaskConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    archived
    assigned
    assignedToId
    assignedToMe
    createdById
    leadId
    due
    inventoryItemId
    status
    tagId
  }
`;
