import { useMemo } from 'react';

import { gql } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ApolloFetchPolicy } from 'enums/apollo';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { tag } from 'store/api/graph/fragments/tag';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parsePageInfo } from 'utils/apiUtils';
import type { FilterTagParams } from 'utils/filterUtils';
import { filterTagItems } from 'utils/filterUtils';

import { rooftopDetailsQuery } from '../RooftopsContainerQuery';

export const rooftopTagsCondensedListQuery = gql`
  query RooftopTagsCondensedListContainerQuery($rooftopId: ID!) {
    tags(rooftopId: [$rooftopId]) {
      ...TagFragment
    }
  }
  ${tag}
`;

export const tagDetailQuery = gql`
  query TagDetailQuery($id: ID!) {
    item: tag(id: $id) {
      ...TagFragment
    }
  }
  ${tag}
`;

export const useRooftopTagsCondensedListQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const flags = useFlags();

  const adaptedConfig = useMemo(
    () => ({
      ...config,
      variables: {
        ...config.variables,
        id: config.variables?.rooftopId,
      },
      options: { fetchPolicy: ApolloFetchPolicy.CACHE_FIRST },
      // Transforming the query to something usable by the container shell
      queryAdapter: ({ tags: edges, item: rooftop }) => {
        const filterTags: FilterTagParams = {
          response: edges,
          rooftop,
          flags,
        };

        const filteredTags = filterTagItems(filterTags);

        return {
          connection: {
            edges: filteredTags,
            pageInfo: parsePageInfo(),
          },
        };
      },
    }),
    [config, flags]
  );
  return useQuery(rooftopDetailsQuery, adaptedConfig);
};
