export enum UserDetailBuilderFields {
  SCOPE = 'scope',
  WHITE_LABEL_ID = 'whiteLabelId',
  GROUP_ID = 'groupId',
  ROOFTOP_IDS_LIMITED_TO = 'rooftopIdsLimitedTo',
  AVATAR_IMAGE = 'avatarImage',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TITLE = 'title',
  PHONE_NUMBER = 'phoneNumber',
  CELL_NUMBER = 'cellNumber',
  EMAIL = 'email',
  SUBSCRIBED_EMAIL = 'subscribedEmail',
  LOCALE = 'locale',
}

export enum UserPermissionBuilderFields {
  ROLE = 'role',
}
