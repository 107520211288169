import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { Clickable } from 'components/ui/shared/Button';
import {
  BORDER_RADIUS_150,
  BORDER_RADIUS_200,
  HEIGHT_700,
  NEUTRAL_0,
  NEUTRAL_700,
  NEUTRAL_800,
  SPACE_1000,
  SPACE_150,
  SPACE_200,
  SPACE_300,
} from 'styles/tokens';
import { Z_INDEX_3 } from 'styles/z-index';

export const MultiNavLinkSubItemCSS = css`
  background-color: ${NEUTRAL_800};
  height: ${HEIGHT_700};
  padding: ${SPACE_150} ${SPACE_200} ${SPACE_300};
  border-radius: ${BORDER_RADIUS_150};

  ${Label} {
    color: ${NEUTRAL_0};
  }

  &.active {
    pointer-events: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${NEUTRAL_700};
  }
`;

export const MultiNavLinkSubItemsContainerCSS = css`
  top: 50%;
  left: ${SPACE_1000};
  display: none;
  position: absolute;
  transform: translateY(-50%);
  z-index: ${Z_INDEX_3};
`;

export const navItemStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const navItemSizeStyles = css`
  padding: ${SPACE_200};
  border-radius: ${BORDER_RADIUS_200};
`;

/**
 * Used for creating actionable nav bar items
 * e.g. Clicking to open a dialog
 */
export const NavActionItem = styled(Clickable)`
  ${navItemStyles}
  ${navItemSizeStyles}
`;
