import { useMemo } from 'react';

import type { DocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';

import { rooftopDepartmentsMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { ApolloFetchPolicy } from 'enums/apollo';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { rooftopDepartment } from 'store/api/graph/fragments/rooftopDepartment';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parsePageInfo } from 'utils/apiUtils';

export const rooftopDepartmentDetailsQuery = gql`
  query RooftopDepartmentDetailQuery($id: ID!) {
    item: rooftopDepartment(id: $id) {
      ...RooftopDepartmentFragment
    }
  }

  ${rooftopDepartment}
`;

export const rooftopDepartmentsQuery = gql`
  query RooftopDepartmentsQuery($id: ID!) {
    rooftop(id: $id) {
      departments {
        ...RooftopDepartmentFragment
      }
    }
  }

  ${rooftopDepartment}
`;

export const useRooftopDepartmentDetailsQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const departmentMetadata = useQuery(rooftopDepartmentsMetaQuery);

  const adaptedConfig = useMemo(
    () => ({
      ...config,
      ignore: !departmentMetadata.isLoaded,
      queryAdapter: data => ({
        ...data,
        metadata: departmentMetadata.data,
      }),
    }),
    [config, departmentMetadata.isLoaded, departmentMetadata.data]
  );

  return useQuery(query, adaptedConfig);
};

export const useRooftopDepartmentsQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const adaptedConfig = useMemo(
    () => ({
      ...config,
      options: { fetchPolicy: ApolloFetchPolicy.CACHE_FIRST },
      // Transform the departments into a connection query response
      queryAdapter: data => {
        if (!data?.rooftop?.departments) {
          return {};
        }

        return {
          connection: {
            edges: data.rooftop.departments.map(node => ({ node })),
            pageInfo: parsePageInfo(),
          },
        };
      },
    }),
    [config]
  );

  return useQuery(query, adaptedConfig);
};
