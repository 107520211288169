import type { ReactNode } from 'react';

import LoggingService from 'components/core/logging/LoggingService';
import WebsiteDetailsTab from 'components/sections/websites/WebsiteDetailsTab';
import WebsiteLeadRoutingTab from 'components/sections/websites/WebsiteLeadRoutingTab';
import WebsiteLinkedTab from 'components/sections/websites/WebsiteLinkedTab';
import WebsiteMenuItems from 'components/sections/websites/WebsiteMenuItems';
import WebsiteRoutesTab from 'components/sections/websites/WebsiteRoutesTab';
import WebsiteListItem from 'components/ui/lists/WebsiteListItem';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import DefaultListSettings from 'containers/DefaultListSettings';
import { BuilderType } from 'enums/builderType';
import { RoutePath } from 'enums/routePath';
import { TabType } from 'enums/tabType';
import { withSearch } from 'hooks/contexts/withSearch';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { WebsiteResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

import ItemsContainer from '../ItemsContainer';

import { useWebsiteConnectionQuery, websiteDetailQuery } from './WebsitesContainerQuery';

export const websiteDetailTabs = [
  { tabId: TabType.DETAILS, component: WebsiteDetailsTab },
  { tabId: TabType.ROUTES, component: WebsiteRoutesTab },
  { tabId: TabType.LEAD_ROUTING, component: WebsiteLeadRoutingTab },
  { tabId: TabType.LINKED, component: WebsiteLinkedTab },
];

export const getWebsiteDetailsName = (itemData: WebsiteResponseType) => itemData?.name?.value;

const WebsitesContainer = () => (
  <ItemsContainer
    // General Container/Shared Props
    useConnectionQuery={useWebsiteConnectionQuery}
    basePath={RoutePath.WEBSITES}
    title={translate.t('website_other')}
    editBuilder={BuilderType.WEBSITE_MODIFY}
    menuItems={WebsiteMenuItems}
    // Table Specific Props
    tableType={TableType.WEBSITES}
    entityType={EntityType.WEBSITE}
    getCustomCellTemplate={getCustomCellTemplate}
    // Details Specific Props
    condensedListItem={WebsiteListItem}
    detailsQuery={websiteDetailQuery}
    detailsTabs={websiteDetailTabs}
    detailsTitleRenderMethod={getWebsiteDetailsName}
  />
);

const getCustomCellTemplate = (headerData: TableCellData, itemData: any): ReactNode => {
  switch (headerData.columnId) {
    default: {
      LoggingService.debug({ message: `Unsupported custom render: ${headerData.columnId}` });
      return null;
    }
  }
};

export default withSearch(WebsitesContainer, {
  initialParams: DefaultListSettings[RoutePath.WEBSITES]?.filter,
});
