import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { ListSection, ListSectionTitle } from 'components/ui/lists/CommonList';
import { Buttons, Clickable, FillWithClickable, RemovableButton } from 'components/ui/shared/Button';
import type { RoutePath } from 'enums/routePath';
import { useSearch } from 'hooks/contexts/useSearch';
import { useRouter } from 'hooks/useRouter';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { CARD_WIDTH } from 'styles/layouts';
import { NEUTRAL_0 } from 'styles/tokens';
import { Z_INDEX_1, Z_INDEX_2 } from 'styles/z-index';
import { isFilterPanelEnabledGivenActiveGlobalFilters, isGlobalFiltersAvailableForRoute } from 'utils/filterUtils';

import ChevronRightIcon from '../icons/ChevronRightIcon';

export const FiltersContainer = styled.div`
  position: relative;
  background: ${NEUTRAL_0};
  overflow: auto;
  max-height: 100%;
  width: ${CARD_WIDTH};
`;

export const FilterSectionHeader = styled(ListSection)`
  color: ${BODY_TEXT};
  background: white;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  > * {
    flex: 1;
    top: 0;
  }
`;

export const FacetFilterSectionTitle = styled(ListSectionTitle)`
  pointer-events: none;

  svg {
    fill: ${BODY_TEXT};
  }
`;

export const ClickableWorkaroundWrapper = styled.div`
  z-index: ${Z_INDEX_1};
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;

  button {
    pointer-events: all;
  }
`;

export const FacetFilterSectionContainer = styled(ListSection)<{ hasSelectedFacets: boolean }>`
  ${FillWithClickable} {
    margin-bottom: 0;

    ${({ hasSelectedFacets }) =>
      hasSelectedFacets &&
      css`
        max-height: 40px;
      `}; /** Max height set to padding derived height of filter */
  }
`;

export const getNewFacetFilterValue = ({ multiSelect, selectedFacetIds, facetId, selected }) => {
  if (multiSelect) {
    const ids = selectedFacetIds.filter(id => id !== facetId);
    if (selected) {
      ids.push(facetId);
    }
    return ids.length === 0 ? undefined : ids;
  }
  return selected ? facetId : undefined;
};

export const SelectedFacets = ({ filter, ...props }) => {
  const { getSearchParamAsArray, updateSearchParam } = useSearch();
  const selectedFacetIds = getSearchParamAsArray(filter.id);
  const children = filter.facets
    .filter(facet => selectedFacetIds.includes(facet.id))
    .map(facet => (
      <RemovableButton
        key={facet.id}
        onRemove={event => {
          event.stopPropagation();
          updateSearchParam(
            filter.id,
            getNewFacetFilterValue({
              selectedFacetIds,
              multiSelect: filter.multiSelect,
              facetId: facet.id,
              selected: false,
            })
          );
        }}
      >
        {facet.name}
      </RemovableButton>
    ));
  if (children.length === 0) {
    return null;
  }
  return <Buttons {...props}>{children}</Buttons>;
};

export const FacetFilterSection = ({ children, filter, onClick, ...props }) => {
  const { getSearchParam, globalSearchParams } = useSearch();
  const router = useRouter();
  const filterHasFacets = filter.facets && filter.facets.length > 0;
  const hasSelectedFacets = Boolean(getSearchParam(filter.id)) && filterHasFacets;

  return (
    <FacetFilterSectionContainer
      disabled={
        !filterHasFacets ||
        (isGlobalFiltersAvailableForRoute(router.sectionPath as RoutePath) &&
          !isFilterPanelEnabledGivenActiveGlobalFilters({
            globalAdminFilters: globalSearchParams,
            searchParamName: filter.id,
          }))
      }
      hasSelectedFacets={hasSelectedFacets}
      {...props}
    >
      <FillWithClickable onClick={onClick} />
      <FacetFilterSectionTitle>
        {children}
        <ChevronRightIcon />
      </FacetFilterSectionTitle>
      {hasSelectedFacets && (
        <ClickableWorkaroundWrapper>
          <SelectedFacets filter={filter} />
        </ClickableWorkaroundWrapper>
      )}
    </FacetFilterSectionContainer>
  );
};

export const SelectedFilterSectionHeader = styled(FilterSectionHeader)`
  ${Clickable} {
    position: absolute;
    display: flex;
    z-index: ${Z_INDEX_1};
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
  }
  ${Label} {
    text-align: center;
    padding-left: 27px;
  }
`;

export const SelectedFacetFilterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX_2};
  background: ${NEUTRAL_0};
`;

export const FilterSearchSection = styled.div`
  padding: 10px 14px;
  border-bottom: 1px solid ${DIVIDER};
`;

export const FacetContainer = styled.label`
  display: flex;
  justify-content: space-between;
  padding: 14px 17px;
  align-items: center;
  border-bottom: 1px solid ${DIVIDER};
  cursor: pointer;
`;
