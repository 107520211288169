import { gql } from '@apollo/client';

import { leadName } from 'store/api/graph/fragments/leadName';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { userName } from 'store/api/graph/fragments/userName';

import { avatar } from './avatar';

export const leadList = gql`
  fragment LeadListFragment on Lead {
    avatar {
      ...AvatarFragment
    }
    ...LeadNameFragment
    status
    statusName
    rgbHex
    phoneNumber
    lastActive
    walkInCount: leadActivitiesCount(type: WALK_IN)
    testDriveCount: leadActivitiesCount(type: TEST_DRIVE)
    phoneCallCount: leadActivitiesCount(type: PHONE_CALL)
    conversationsCount
    summary
    archived
    subscribedEmail
    assignedToName: assignedTo {
      ...UserNameFragment
    }
    rooftopName: rooftop {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${userName}
  ${avatar}
  ${leadName}
  ${MultilingualString}
`;
