import { Fragment, useCallback } from 'react';

import { get } from 'lodash-es';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { ElementTestId, listSelectionItemTestId } from 'enums/testing';

import type { ListSelectionSortableGroupListProps } from './interfaces';
import ListSelectionCheckbox from './ListSelectionCheckbox';
import { GroupHeading } from './ListSelectionGroupHeader';
import { OptionText } from './ListSelectionOptions';
import { ListSelectionEmptyPlaceHolder } from './ListSelectionPlaceholder';
import ListSelectionSortableDragPreview from './ListSelectionSortableDragPreview';
import { ListItemContainer } from './ListSelectionSortableListItemContainer';
import SortableListItemContainer from './SortableListItemContainer';

const UnorderedListContainer = styled.ul<{ maxHeight: string }>`
  list-style: none;
  padding: 0;
  margin: 0;
  height: ${({ maxHeight }) => maxHeight};
  overflow: auto;
`;

const RENDER_ELEMENT_HEIGHT = 51; // It would be better to derive this dynamically by ref measurement

const placeholderCss = css`
  height: auto;
  padding: 24px 16px;
`;

const ListSelectionSortableGroupList = ({
  group,
  onListItemDrag,
  onSelect,
  baseClassName,
  isMultiSelect,
  itemIsActive,
  styleVariant,
  onDone,
}: ListSelectionSortableGroupListProps) => {
  const onClick = useCallback(item => onSelect?.(item), [onSelect]);

  return group.map(({ items, label, isSortable, placeholder, scrollLimit, hoverCss }) => (
    <Fragment key={label}>
      <GroupHeading key={label} styleVariant={styleVariant}>
        <Label>{label}</Label>
      </GroupHeading>
      {items.length > 0 ? (
        <DndProvider backend={HTML5Backend}>
          <>
            {isSortable && <ListSelectionSortableDragPreview items={items} />}
            <UnorderedListContainer
              maxHeight={
                scrollLimit && items.length > scrollLimit ? `${RENDER_ELEMENT_HEIGHT * scrollLimit}px` : 'auto'
              }
              data-testid={`${ElementTestId.BUILDER_LIST_SELECTION}-${label}`}
            >
              {items.map((item, index) => (
                <SortableListItemContainer
                  key={item.id}
                  index={index}
                  onListItemDrag={onListItemDrag}
                  item={item}
                  isSortable={isSortable}
                  onDone={onDone}
                  hoverCss={hoverCss}
                >
                  <ListItemContainer
                    isCheckbox={true}
                    key={item.id}
                    onClick={!item.isReadOnly && !item.disabled ? () => onClick(item) : undefined}
                    className={`${baseClassName}-${item.id}`}
                    isActive={itemIsActive(item.id as string)}
                    isReadOnly={item?.disabled || item.isReadOnly}
                    data-testid={listSelectionItemTestId(item.id as string)}
                  >
                    <ListSelectionCheckbox
                      css={
                        item.isReadOnly
                          ? css`
                              visibility: hidden;
                            `
                          : ''
                      }
                      disabled={item.disabled}
                      round={!isMultiSelect}
                      checked={itemIsActive(item.id as string)}
                    />
                    <OptionText>{get(item.name, 'value', item.name as string)}</OptionText>
                  </ListItemContainer>
                </SortableListItemContainer>
              ))}
            </UnorderedListContainer>
          </>
        </DndProvider>
      ) : (
        !!placeholder && (
          <ListSelectionEmptyPlaceHolder
            title={placeholder.title}
            subtitle={placeholder.subtitle}
            containerCss={placeholderCss}
          />
        )
      )}
    </Fragment>
  ));
};

export default ListSelectionSortableGroupList;
