import { StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import MediaStep, {
  defaultFields as PhotosStepFields,
} from 'components/sections/createModify/inventoryItems/steps/photosStep/MediaStep';
import CaptureVinFields from 'components/sections/createModify/inventoryItems/tradeInItem/steps/CaptureVinFields';
import CaptureVinStep from 'components/sections/createModify/inventoryItems/tradeInItem/steps/CaptureVinStep';
import DetailsTradeInFields from 'components/sections/createModify/inventoryItems/tradeInItem/steps/DetailsTradeInFields';
import LeadNotesFields from 'components/sections/createModify/inventoryItems/tradeInItem/steps/LeadNotesFields';
import LeadNotesStep from 'components/sections/createModify/inventoryItems/tradeInItem/steps/LeadNotesStep';
import { mediaStepTooltipConfig } from 'components/sections/createModify/shared/steps/mediaStepTooltipConfig';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import CaptureFields from './steps/CaptureFields';
import CaptureStep from './steps/CaptureStep';
import DetailsStep from './steps/DetailsStep';
import GuaranteedTradeValueFields from './steps/GuaranteedTradeValueFields';
import { tradeInGuaranteedValueCreate, tradeInGuaranteedValueModify } from './steps/GuaranteedTradeValueQuery';
import GuaranteedTradeValueStep from './steps/GuaranteedTradeValueStep';
import InviteAppraisersFields from './steps/InviteAppraisersFields';
import { tradeInAppraisalExternalSendInvite } from './steps/InviteAppraisersQuery';
import InviteAppraisersStep from './steps/InviteAppraisersStep';
import MileageAndConditionFields from './steps/MileageAndConditionFields';
import MileageAndConditionStep from './steps/MileageAndConditionStep';
import SubmitAppraisalFields from './steps/SubmitAppraisalFields';
import { tradeInAppraisalInternalCreate, tradeInAppraisalInternalModify } from './steps/SubmitAppraisalQuery';
import SubmitAppraisalStep from './steps/SubmitAppraisalStep';
import { tradeInItemCreate, tradeInItemModify } from './TradeInItemCreateModifyQuery';
import TradeInItemQueryMethods from './TradeInItemQueryMethods';

const TradeInItemBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.TRADE_IN_ITEMS_CREATE]: {
    getMetaData: TradeInItemQueryMethods.getMetaData,
    refetchQueries: TradeInItemQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.TRADE_IN_ITEMS,
    steps: [
      {
        id: 'CAPTURE',
        step: CaptureStep,
        stepName: 'capture',
        isEnabled: true,
        fields: CaptureFields,
      },
      {
        id: 'VIN',
        step: CaptureVinStep,
        stepName: 'vin',
        isEnabled: false,
        fields: CaptureVinFields,
      },
      {
        id: 'DETAILS',
        step: DetailsStep,
        stepName: 'details',
        isEnabled: false,
        fields: DetailsTradeInFields,
      },
      {
        id: 'MILEAGE_AND_CONDITION',
        step: MileageAndConditionStep,
        stepName: 'mileage_and_condition',
        isEnabled: false,
        fields: MileageAndConditionFields,
      },
      {
        id: 'LEAD_NOTES',
        step: LeadNotesStep,
        stepName: 'lead_notes',
        isEnabled: false,
        fields: LeadNotesFields,
      },
      {
        id: 'MEDIA',
        step: MediaStep,
        stepName: 'media',
        isEnabled: false,
        isExpandable: true,
        tooltip: mediaStepTooltipConfig,
      },
    ],
    mutations: {
      create: tradeInItemCreate,
      modify: tradeInItemModify,
    },
  },
  [BuilderType.TRADE_IN_ITEMS_MODIFY]: {
    getMetaData: TradeInItemQueryMethods.getMetaData,
    getData: TradeInItemQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.TRADE_IN_ITEMS,
    steps: [
      { id: 'DETAILS', step: DetailsStep, stepName: 'details', isEnabled: true, fields: DetailsTradeInFields },
      {
        id: 'MILEAGE_AND_CONDITION',
        step: MileageAndConditionStep,
        stepName: 'mileage_and_condition',
        isEnabled: true,
        fields: MileageAndConditionFields,
      },
      {
        id: 'LEAD_NOTES',
        step: LeadNotesStep,
        stepName: 'notes_from_lead',
        isEnabled: true,
        fields: LeadNotesFields,
      },
      {
        id: 'MEDIA',
        step: MediaStep,
        stepName: 'media',
        isEnabled: true,
        isExpandable: true,
        fields: PhotosStepFields,
        tooltip: mediaStepTooltipConfig,
      },
    ],
    mutations: {
      modify: tradeInItemModify,
    },
  },
  [BuilderType.TRADE_IN_ITEMS_GUARANTEED_TRADE]: {
    steps: [
      {
        id: 'TRADE_VALUE',
        step: GuaranteedTradeValueStep,
        stepName: 'trade_value',
        isEnabled: true,
        fields: GuaranteedTradeValueFields,
        tooltip: {
          title: 'trade_value',
          content: 'enter_trade_value_message',
        },
      },
    ],
    legend: [StepLegendTooltip],
    requiredPermissions: [{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.ADVANCED }],
    refetchQueries: ['TradeInItemDetailsContainerQuery'],
    resourceType: ResourceType.TRADE_IN_ITEMS,
    mutations: {
      create: tradeInGuaranteedValueCreate,
      modify: tradeInGuaranteedValueModify,
    },
  },
  [BuilderType.TRADE_IN_ITEMS_SUBMIT_APPRAISAL]: {
    steps: [
      {
        id: 'SUBMIT_APPRAISAL',
        step: SubmitAppraisalStep,
        stepName: 'appraisal',
        isEnabled: true,
        fields: SubmitAppraisalFields,
        tooltip: {
          title: 'your_appraisal',
          content: 'enter_appraisal_message',
        },
      },
    ],
    requiredPermissions: [{ resource: ResourceType.TRADE_IN_APPRAISALS, level: AccessLevel.FULL }],
    resourceType: ResourceType.TRADE_IN_APPRAISALS,
    refetchQueries: ['TradeInItemConnectionQuery', 'TradeInItemDetailsContainerQuery'],
    legend: [StepLegendTooltip],
    mutations: {
      create: tradeInAppraisalInternalCreate,
      modify: tradeInAppraisalInternalModify,
    },
  },
  [BuilderType.TRADE_IN_ITEMS_INVITE_APPRAISERS]: {
    steps: [
      {
        id: 'INVITE_APPRAISERS',
        step: InviteAppraisersStep,
        stepName: 'appraisers',
        isEnabled: true,
        fields: InviteAppraisersFields,
      },
    ],
    requiredPermissions: [{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.TRADE_IN_ITEMS,
    legend: null,
    mutations: {
      modify: tradeInAppraisalExternalSendInvite,
    },
  },
};

export default TradeInItemBuilders;
