import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import CloseIcon from 'components/ui/icons/CloseIcon';
import { Clickable } from 'components/ui/shared/Button';
import { BODY_TEXT } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_100, NEUTRAL_800 } from 'styles/tokens';
import { FONT_SIZE_16 } from 'styles/typography';
import { formatTitleString } from 'utils/formatUtils';

/**
 * Generic container for all dialog content.
 *
 * Compose with <Dialog /> component for consistent dialogs/modals.
 * e.g. AddEntityDialog
 */
export const DialogContentContainer = styled.div`
  position: relative;
  background: ${NEUTRAL_0};
  overflow: auto;
  max-height: 100%;
`;

/**
 * Generic scrollable main body of dialog content.
 *
 * Compose with <Dialog /> component for consistent dialogs/modals.
 * e.g. AddEntityDialog
 */
export const DialogBodyContentContainer = styled.div`
  max-height: 50vh;
  overflow: auto;

  &:only-child {
    padding-top: 16px;
  }
`;

export const DialogBodyCSS = css`
  display: flex;
  min-width: 272px;
  padding: 0 16px 16px 16px;
  background: ${NEUTRAL_0};
  flex-direction: column;
  align-items: center;
`;

const DialogHeaderSection = styled.section<{ showDivider?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  color: ${BODY_TEXT};
  border-bottom: ${({ showDivider }) => (showDivider ? '1px solid' + NEUTRAL_100 : 'none')};
`;

const DialogButton = styled(Clickable)`
  position: absolute;
  top: 0;
  right: 0;
  width: 53px;
  height: 53px;
`;

const DialogHeaderTitle = styled(PrimaryText)`
  pointer-events: none;
  font-size: ${FONT_SIZE_16};
  color: ${NEUTRAL_800};
`;

interface Props {
  /** Header title */
  title: string;
  /** An optional callback for 'X' button in dialog header, if left null 'X' will be hidden */
  onClose?: () => void;
  /** Whether or not a divider should be shown at the bottom of the header. Defaults to false  */
  showDivider?: boolean;
}

/**
 * Generic dialog header.
 *
 * Compose with <Dialog /> component for consistent dialogs/modals.
 * e.g. AddEntityDialog
 */
export const DialogHeader = ({ title, onClose, showDivider = false }: Props) => (
  <DialogHeaderSection showDivider={showDivider}>
    <DialogHeaderTitle>{formatTitleString(title)}</DialogHeaderTitle>
    {onClose && (
      <DialogButton onClick={() => onClose()}>
        <CloseIcon />
      </DialogButton>
    )}
  </DialogHeaderSection>
);
