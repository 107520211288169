export enum ItemGridRowDisplayType {
  /** For displaying a generic list item element. */
  LIST_ITEM = 'list_item',
  /** Styles text content in a row to be bold. All text in a row is either all bold or not and never interlaced. */
  BOLD = 'bold',
  /** Styles row content as a `Multiline` `RichText` area. Will override other text specific displayTypes e.g. `BOLD` */
  RICH_TEXT = 'rich_text',
  /** Styles text content as an anchor that opens in a new tab */
  EXTERNAL_LINK = 'external_link',
}
