import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { DIVIDER } from 'styles/color';
import { SECTION_PADDING } from 'styles/spacing';
import { NEUTRAL_050 } from 'styles/tokens';

const Container = styled(Label)`
  background: ${NEUTRAL_050};
  padding: 13px ${SECTION_PADDING} 11px ${SECTION_PADDING};
  border: 1px solid ${DIVIDER};
  border-left: none;
  border-right: none;
`;

interface SectionHeaderProps {
  /** The text to display in this header */
  text: string;
}

const SectionHeader = ({ text }: SectionHeaderProps) => <Container>{text}</Container>;

export default SectionHeader;
