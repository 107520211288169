export enum StepFieldSubType {
  COLOR = 'color',
  DATE = 'date',
  MULTI_SELECT = 'multiSelect',
  TOGGLE = 'toggle',
  FIELD_GROUP = 'fieldGroup',
  TIME = 'time',
  CHECKBOX = 'checkbox',
  SORTABLE = 'sortable',
  LOCKABLE = 'lockable',
  SELECT_ALL_DISABLED = 'selectAllDisabled',
}

// List of types that require the sub panel to remain open while selections are made.
export const doNotCloseOrAdvanceTypes: StepFieldSubType[] = [
  StepFieldSubType.MULTI_SELECT,
  StepFieldSubType.TOGGLE,
  StepFieldSubType.FIELD_GROUP,
  StepFieldSubType.DATE,
  StepFieldSubType.TIME,
];
