import styled from 'styled-components/macro';

import { GREEN_500 } from 'styles/tokens';

import PrimaryText from './PrimaryText';

const MonetaryAmount = styled(PrimaryText)`
  color: ${GREEN_500};
`;

export default MonetaryAmount;
