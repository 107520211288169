/**
 * List of all custom, implementation specific GTM events.
 *
 * More info: https://edealer-wiki.netlify.app/development/common/analytics.html
 */
export enum GTMEvents {
  // Generic event called when a builder is opened from any location and is visible
  BUILDER_OPENED = 'BUILDER_OPENED',
  // Generic event called when a builder is closed via any method and is not visible
  BUILDER_CLOSED = 'BUILDER_CLOSED',
  // Generic event called when a user navigates to and views a step in the builder
  BUILDER_STEP_OPENED = 'BUILDER_STEP_OPENED',
  // Generic event called when a user navigates away from a step in the builder
  BUILDER_STEP_CLOSED = 'BUILDER_STEP_CLOSED',
}

/**
 * Log custom events with Google Tag Manager
 * @example trackEvent({ event: 'BUILDER_OPENED', builderType: 'inventory' });
 * @param {object} options
 */
export const trackEvent = options => {
  options.custom = {
    // Name of the custom GTM env
    gtmEnv: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV_NAME,
    // Api env
    apiEnv: process.env.REACT_APP_GOOGLE_TAG_MANAGER_API_ENV,
  };

  return (window as any)?.dataLayer?.push(options);
};
