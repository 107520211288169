import type { ComponentType } from 'react';

import type { RouteProps } from 'react-router-dom';

import type { RestrictedRouteProps } from 'components/core/routing/RestrictedRoute';
import { PageLoader } from 'components/ui/loading/Loader';
import BaseClass from 'components/ui/shared/BaseClass';

type DynamicImportResult<T extends ComponentType<any>> = {
  default: T;
};

interface State {
  component: ComponentType<any> | null;
}

/*
 * A higher-order component that:
 * 1) creates a JS bundle for each component instance at build time; and
 * 2) asynchronously loads the bundled component when requested at run-time
 */
const DynamicImport = <T extends ComponentType<any>>(
  importComponent: () => Promise<DynamicImportResult<T>>,
  hasPageLoader = true
): ComponentType<any> => {
  class DynamicComponent extends BaseClass<RestrictedRouteProps | RouteProps, State> {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      super.componentDidMount();
      const component = await importComponent();
      this.setState({
        component: component?.default || component,
      });
    }

    render() {
      const Component = this.state.component;

      if (Component) {
        return <Component {...this.props} />;
      } else if (hasPageLoader) {
        return <PageLoader />;
      }

      return null;
    }
  }

  return DynamicComponent;
};

export default DynamicImport;
