import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
  getTagOptions,
} from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { FeatureBundleSet } from 'enums/featureBundle';
import type {
  AppointmentCreateMutationVariables,
  AppointmentDetailsContainerQuery,
  ColorMetaQuery,
  Duration,
} from 'store/api/graph/interfaces/types';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { durationOptions, getDurationId, getJSDateFromTimeStamp } from 'utils/timeUtils';

import { AppointmentDetailsBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore<
  AppointmentDetailsContainerQuery['item'],
  ColorMetaQuery,
  AppointmentCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<
      AppointmentDetailsContainerQuery['item'],
      ColorMetaQuery,
      AppointmentCreateMutationVariables
    >,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: { data, seededData, isCreating, activeStep, entityType },
    } = props;

    const {
      subContexts: {
        userContext: {
          user,
          isWhiteLabelScoped,
          canAccessMultipleRooftops,
          featureFlags: { rooftopPackageEnabled },
        },
      },
    } = context;

    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);
    const leadId = seededData?.leadId;
    const reminder: Duration | null = data.reminder || null;

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [AppointmentDetailsBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !isCreating || !!leadId || !!seededData?.inventoryItemId,
          },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
      },
      [AppointmentDetailsBuilderFields.TAG_IDS]: {
        selectedValue: data.tagName || [],
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !rooftop?.id && isWhiteLabelScoped,
        }),
      },
      [AppointmentDetailsBuilderFields.DATE]: {
        selectedValue: data.date && getFormattedDateTimeString(data.date, DateTimeFormat.YEAR_MONTH_DAY_DASH_FORMAT),
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.OMITTED,
          active: true,
        }),
      },
      [AppointmentDetailsBuilderFields.START_TIME]: {
        selectedValue: data.dateFrom && getFormattedDateTimeString(data.dateFrom, DateTimeFormat.TIME_STAMP_FORMAT),
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.OMITTED,
          active: true,
        }),
      },
      [AppointmentDetailsBuilderFields.END_TIME]: {
        selectedValue: data.dateTo && getFormattedDateTimeString(data.dateTo, DateTimeFormat.TIME_STAMP_FORMAT),
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.OMITTED,
          active: true,
        }),
      },
      [AppointmentDetailsBuilderFields.REMINDER]: {
        selectedValue: getDurationId(reminder),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [AppointmentDetailsBuilderFields.ROOFTOP_ID]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId: seededData?.userGroupId,
                features: { appointment: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.APPOINTMENT,
                    featureFlagOn: rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      [AppointmentDetailsBuilderFields.TAG_IDS]: {
        request: () =>
          getTagOptions({
            rooftopId: [getStepField(AppointmentDetailsBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id],
            entityType: entityType!,
          }),
        disableKeywordRefetch: true,
      },
    };
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === AppointmentDetailsBuilderFields.ROOFTOP_ID) {
      removeDisplayType(
        getStepField(AppointmentDetailsBuilderFields.TAG_IDS, this.fields),
        StepFieldDisplayType.DISABLED
      );
    }
    super.onFieldSelection(stepField, value);
  }

  async save() {
    const {
      tier: { steps, seededData },
    } = this.props;
    const date = getStepField(AppointmentDetailsBuilderFields.DATE, this.fields).selectedValue;
    const startTime = getStepField(AppointmentDetailsBuilderFields.START_TIME, this.fields).selectedValue;
    const endTime = getStepField(AppointmentDetailsBuilderFields.END_TIME, this.fields).selectedValue;
    const reminderId = getStepField(AppointmentDetailsBuilderFields.REMINDER, this.fields).selectedValue;

    const reminder = durationOptions[reminderId.id] || null;

    const success = await super.save(
      {
        dateFrom: !!date && !!startTime ? getJSDateFromTimeStamp(startTime, date) : null,
        dateTo: !!date && !!endTime ? getJSDateFromTimeStamp(endTime, date) : null,
        reminder,
        leadId: seededData?.leadId,
        inventoryItemId: seededData?.inventoryItemId,
        assignedToId: seededData?.assignedToId,
      },
      { reminder }
    );

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }
}

export default DetailsStep;
