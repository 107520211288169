import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import { RetailBulkAdjustmentBuilderFields } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import ClipboardIcon from 'components/ui/icons/ClipboardIcon';
import ContractIcon from 'components/ui/icons/ContractIcon';
import DollarSignIcon from 'components/ui/icons/DollarSignIcon';
import OverlayIcon from 'components/ui/icons/OverlayIcon';
import PromotionsIcon from 'components/ui/icons/PromotionsIcon';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { RetailBulkAdjustmentModifyParameter, RetailBulkAdjustmentType } from 'store/api/graph/interfaces/types';

export const bulkAdjustmentTypeIcons = [
  {
    id: RetailBulkAdjustmentType.PRICE,
    label: 'price',
    icon: DollarSignIcon,
  },
  {
    id: RetailBulkAdjustmentType.OVERLAY,
    label: 'overlay',
    icon: OverlayIcon,
  },
  {
    id: RetailBulkAdjustmentType.DESCRIPTION,
    label: 'description',
    icon: ClipboardIcon,
  },
  {
    id: RetailBulkAdjustmentType.PROMOTION,
    label: 'promotion',
    icon: PromotionsIcon,
  },
  {
    id: RetailBulkAdjustmentType.DISCLAIMER,
    label: 'disclaimer',
    icon: ContractIcon,
  },
];

const RetailBulkAdjustmentTypeFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.TYPE]: {
    groupType: StepFieldType.TYPES,
  },
  [RetailBulkAdjustmentBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    displayType: [StepFieldDisplayType.DISABLED],
    hasSeparator: true,
  },
  [RetailBulkAdjustmentBuilderFields.NAME]: {
    label: 'name',
    clear: { field: RetailBulkAdjustmentModifyParameter._name },
  },
  [RetailBulkAdjustmentBuilderFields.ACTIVE]: {
    label: 'active',
    groupType: StepFieldType.SWITCH,
    clear: { field: RetailBulkAdjustmentModifyParameter._active },
  },
  [RetailBulkAdjustmentBuilderFields.ORDER]: {
    label: 'order',
    groupType: StepFieldType.NUMBER,
    tooltipContents: 'order_tooltip',
    clear: { field: RetailBulkAdjustmentModifyParameter._order },
  },
  [RetailBulkAdjustmentBuilderFields.SCHEDULE_ADJUSTMENT]: {
    label: 'schedule_adjustment',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RetailBulkAdjustmentBuilderFields.STARTING]: {
    label: 'start_date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    clear: { field: RetailBulkAdjustmentModifyParameter._starting },
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
  [RetailBulkAdjustmentBuilderFields.ENDING]: {
    label: 'end_date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    clear: { field: RetailBulkAdjustmentModifyParameter._ending },
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
};

export default RetailBulkAdjustmentTypeFields;
