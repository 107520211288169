import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { rooftopLeadForwardingDetailsQuery } from 'containers/rooftops/leadForward/RooftopLeadForwardContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const RooftopLeadForwardingQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopLeadForwardingDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'RooftopLeadForwardingCondensedListQuery',
    'RooftopDetailsContainerQuery',
    'RooftopLeadForwardingDetailsContainerQuery',
  ],
};

export default RooftopLeadForwardingQueryMethods;
