import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

import { InventoryItem, InventoryItemConfig } from './inventoryItem';

export enum RetailBulkAdjustment {
  // Entity base
  NAME = 'name',
  ROOFTOP_NAME = 'rooftopName',
  ACTIVE = 'active',
  STARTING = 'starting',
  ENDING = 'ending',
  TYPE = 'type',
  ORDER = 'order',

  // Price adjustment
  FIXED_AMOUNT = 'priceAttributes.fixed.amount',
  PERCENTAGE = 'priceAttributes.percentage',
  FIXED = 'priceAttributes.fixed',
  PAYMENT_OPTIONS = 'priceAttributes.paymentOptions',
  LABEL = 'priceAttributes.label',
  DISCLAIMER = 'priceAttributes.disclaimer',
  LUXURY_TAX_DISCLAIMER = 'luxuryTaxDisclaimer',
  LUXURY_TAX_ENABLED = 'luxuryTaxEnabled',
  PRICE_TYPE = 'priceAttributes.type',
  TARGETS = 'priceAttributes.targets',

  // Overlay adjustment
  OVERLAY_IMAGE_URL = 'overlayAttributes.overlayImageUrl',
  OVERLAY_IMAGE = 'overlayAttributes.overlayImage',
  PRIMARY_PHOTO_ONLY = 'overlayAttributes.primaryPhotoOnly',
  OVERLAY_POSITION = 'overlayAttributes.overlayPosition',
  OVERLAY_POSITION_NAME = 'overlayAttributes.overlayPositionName',
  OVERLAY_SCALE = 'overlayAttributes.scale',
  OVERLAY_SCALING_DIMENSION = 'overlayAttributes.scalingDimension',
  OVERLAY_SCALING_DIMENSION_NAME = 'overlayAttributes.scalingDimensionName',

  // Description adjustment
  DESCRIPTION_PLACEMENT_NAME = 'descriptionAttributes.placementName',
  DESCRIPTION_TEXT = 'descriptionAttributes.text',

  // Promotion adjustment
  ALT_TEXT = 'promotionAttributes.altText',
  BANNER_IMAGE_URL = 'promotionAttributes.bannerImageUrl',
  BANNER_URL = 'promotionAttributes.bannerUrl',
  BANNER_LINKS_TO_VDP = 'promotionAttributes.bannerLinksToVdp',
  OPEN_LINK_IN_NEW_TAB = 'promotionAttributes.openLinkInNewTab',
  POSITION_NAME = 'promotionAttributes.positionName',
  POSITION = 'promotionAttributes.position',
  SHOW_ON_VDP = 'promotionAttributes.showOnVdp',
  SHOW_ON_VLP = 'promotionAttributes.showOnVlp',

  // Disclaimer adjustment
  DISCLAIMER_TEMPLATE = 'disclaimerAttributes.disclaimerTemplate',

  // RetailBulkConnectionFilter specific to RetailBulkAdjustment
  DAYS_IN_STOCK = 'filter.daysInStock',
  YEAR_RANGE = 'filter.yearRange',
  TAG_ID = 'filter.tagId',
  MAKE_ID = 'filter.makeId',
  MODEL_ID = 'filter.modelId',
  SUB_MODEL_ID = 'filter.subModelId',

  // Not actual API fields
  SCHEDULE_ADJUSTMENT = 'schedule_adjustment',
  DISCOUNT_OR_FEE = 'discountOrFee',
  AMOUNT = 'amount',
  INCLUDE_CASH = 'includeCash',
  INCLUDE_FINANCE = 'includeFinance',
  INCLUDE_LEASE = 'includeLease',

  // Flags
  ARCHIVED = 'filter.archived',
  MAPPED = 'filter.mapped',
  COMPLETE = 'filter.complete',
  HAS_PHOTOS = 'filter.hasPhotos',
  HAS_PRICE = 'filter.hasPrice',
  HAS_MILEAGE = 'filter.hasMileage',
}

const RETAIL_BULK_ADJUSTMENT_FILTER_PREFIX = 'filter.';

// Helper method to transform inventory item field path to retailBulkAdjustment filter field path
export const getRetailBulkAdjustmentFilterField = (field: string) => `${RETAIL_BULK_ADJUSTMENT_FILTER_PREFIX}${field}`;

// Helper method to transform shared `InventoryItemConfig` settings to `RetailBulkAdjustmentConfig` entries
const transformToRetailBulkAdjustmentFilterSettings = (fields: InventoryItem[], type: FieldDataType) =>
  fields.reduce((acc, curr) => {
    acc[`${RETAIL_BULK_ADJUSTMENT_FILTER_PREFIX}${curr}`] = { ...InventoryItemConfig[curr], type };
    return acc;
  }, {});

const RetailBulkAdjustmentConfig: TableSettingsType = {
  // Entity base
  [RetailBulkAdjustment.NAME]: { label: 'name', type: FieldDataType.DEFAULT },
  [RetailBulkAdjustment.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [RetailBulkAdjustment.ACTIVE]: { label: 'active', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.ORDER]: { label: 'order', type: FieldDataType.DEFAULT, tooltip: 'order_tooltip' },
  [RetailBulkAdjustment.STARTING]: { label: 'start_date', type: FieldDataType.DATE },
  [RetailBulkAdjustment.ENDING]: { label: 'end_date', type: FieldDataType.DATE },
  [RetailBulkAdjustment.TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [RetailBulkAdjustment.TAG_ID]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [RetailBulkAdjustment.MAKE_ID]: { label: 'make_one', type: FieldDataType.BADGE_LIST },
  [RetailBulkAdjustment.MODEL_ID]: { label: 'model_one', type: FieldDataType.BADGE_LIST },
  [RetailBulkAdjustment.SUB_MODEL_ID]: { label: 'submodel_one', type: FieldDataType.BADGE_LIST },

  // Promotion adjustment fields
  [RetailBulkAdjustment.ALT_TEXT]: {
    label: 'alternative_text',
    type: FieldDataType.DEFAULT,
    tooltip: 'alternative_text_tooltip',
  },
  [RetailBulkAdjustment.BANNER_URL]: { label: 'banner_url', type: FieldDataType.DEFAULT },
  [RetailBulkAdjustment.BANNER_IMAGE_URL]: { label: 'banner', type: FieldDataType.IMAGE },
  [RetailBulkAdjustment.BANNER_LINKS_TO_VDP]: { label: 'link_to_vdp', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.OPEN_LINK_IN_NEW_TAB]: { label: 'open_link_in_new_tab', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.POSITION_NAME]: { label: 'position', type: FieldDataType.DEFAULT },
  [RetailBulkAdjustment.SHOW_ON_VDP]: { label: 'show_on_vdp', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.SHOW_ON_VLP]: { label: 'show_on_vlp', type: FieldDataType.BOOLEAN },

  // Description adjustment fields
  [RetailBulkAdjustment.DESCRIPTION_TEXT]: { label: 'text', type: FieldDataType.MULTILINGUAL_STRING },
  [RetailBulkAdjustment.DESCRIPTION_PLACEMENT_NAME]: { label: 'placement', type: FieldDataType.DEFAULT },

  // Overlay adjustment fields
  [RetailBulkAdjustment.OVERLAY_IMAGE_URL]: { label: 'photo_one', type: FieldDataType.IMAGE },
  [RetailBulkAdjustment.PRIMARY_PHOTO_ONLY]: { label: 'primary_photo_only', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.OVERLAY_POSITION_NAME]: { label: 'alignment', type: FieldDataType.DEFAULT },
  [RetailBulkAdjustment.OVERLAY_SCALE]: { label: 'percentage', type: FieldDataType.PERCENTAGE },
  [RetailBulkAdjustment.OVERLAY_SCALING_DIMENSION_NAME]: { label: 'scale_by', type: FieldDataType.DEFAULT },

  // Price adjustment fields
  [RetailBulkAdjustment.INCLUDE_CASH]: { label: 'apply_cash', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.INCLUDE_FINANCE]: { label: 'apply_finance', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.INCLUDE_LEASE]: { label: 'apply_lease', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.LABEL]: { label: 'label', type: FieldDataType.MULTILINGUAL_STRING },
  [RetailBulkAdjustment.LUXURY_TAX_ENABLED]: { label: 'luxury_tax_enabled', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.DISCLAIMER]: {
    label: 'disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'retail_bulk_adjustment_disclaimer_tooltip',
  },
  [RetailBulkAdjustment.LUXURY_TAX_DISCLAIMER]: {
    label: 'luxury_tax_disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'luxury_tax_disclaimer_tooltip',
  },
  [RetailBulkAdjustment.TARGETS]: {
    label: 'target_other',
    type: FieldDataType.BADGE_LIST,
  },

  // Disclaimer adjustment fields
  [RetailBulkAdjustment.DISCLAIMER_TEMPLATE]: {
    label: 'disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
  },

  // RetailBulkConnectionFilter specific to RetailBulkAdjustment
  [RetailBulkAdjustment.DAYS_IN_STOCK]: { label: 'days_in_stock', type: FieldDataType.CUSTOM },
  [RetailBulkAdjustment.YEAR_RANGE]: { label: 'year_one', type: FieldDataType.CUSTOM },

  // RetailBulkConnectionFilter shared InventoryItemSettings
  ...transformToRetailBulkAdjustmentFilterSettings(
    [InventoryItem.PURCHASE_PRICE, InventoryItem.MOTORCYCLE_MILEAGE, InventoryItem.VEHICLE_MILEAGE],
    FieldDataType.CUSTOM
  ),
  ...transformToRetailBulkAdjustmentFilterSettings(
    [
      InventoryItem.VEHICLE_DRIVE_TRAIN,
      InventoryItem.VEHICLE_BODY_TYPE,
      InventoryItem.VEHICLE_EXTERIOR_COLOR,
      InventoryItem.MOTORCYCLE_COLOR,
      InventoryItem.VEHICLE_TRANSMISSION,
      InventoryItem.MOTORCYCLE_FUEL_TYPE,
      InventoryItem.VEHICLE_FUEL_TYPE,
      InventoryItem.VEHICLE_OPTIONS,
      InventoryItem.STATUS,
      InventoryItem.MOTORCYCLE_CATEGORY,
    ],
    FieldDataType.BADGE_LIST
  ),
  ...transformToRetailBulkAdjustmentFilterSettings([InventoryItem.CONDITION], FieldDataType.ENUM),

  // Flags
  ...transformToRetailBulkAdjustmentFilterSettings(
    [
      InventoryItem.MAPPED,
      InventoryItem.SHOW_WEB,
      InventoryItem.AS_IS,
      InventoryItem.CERTIFIED,
      InventoryItem.DEMO,
      InventoryItem.FEATURED,
      InventoryItem.ON_ORDER,
      InventoryItem.IN_TRANSIT,
      InventoryItem.COMPLETE,
    ],
    FieldDataType.BOOLEAN
  ),
  [RetailBulkAdjustment.HAS_PHOTOS]: { label: 'has_photos', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.HAS_PRICE]: { label: 'has_price', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.HAS_MILEAGE]: { label: 'has_mileage', type: FieldDataType.BOOLEAN },

  // Not actual API fields
  [RetailBulkAdjustment.SCHEDULE_ADJUSTMENT]: { label: 'schedule_adjustment', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.DISCOUNT_OR_FEE]: { label: 'discount_or_fee', type: FieldDataType.BOOLEAN },
  [RetailBulkAdjustment.AMOUNT]: { label: 'amount', type: FieldDataType.DEFAULT },
};

export const RetailBulkAdjustmentSettings = translateFields(RetailBulkAdjustmentConfig);
