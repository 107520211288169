import { memo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import DollarSignCircledIcon from 'components/ui/icons/DollarSignCircledIcon';
import { FallbackIcon, ListItemImage } from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemSeparator, ListItemSeparatorWrapper } from 'components/ui/layouts/ListItem';
import type { TradeInAppraisal } from 'store/api/graph/interfaces/types';
import { formatAmountRounded, formatFullName } from 'utils/formatUtils';
import { getRelativeTime } from 'utils/timeUtils';

const AppraisalListItem = ({ amount, updated, displayName }: TradeInAppraisal) => (
  <ListItem css="flex-wrap: wrap;">
    <ListItemImage>
      <FallbackIcon>
        <DollarSignCircledIcon />
      </FallbackIcon>
    </ListItemImage>
    <ListItemDetails css="width: auto;">
      <TextRow>
        <PrimaryText>{formatAmountRounded(amount)}</PrimaryText>
        <SecondaryText>&bull;</SecondaryText>
        <SecondaryText>{getRelativeTime(updated)}</SecondaryText>
      </TextRow>
      <TextRow>
        <SecondaryText>{formatFullName({ displayName })}</SecondaryText>
      </TextRow>
    </ListItemDetails>
    <ListItemSeparatorWrapper>
      <ListItemSeparator />
    </ListItemSeparatorWrapper>
  </ListItem>
);

export default memo(AppraisalListItem);
