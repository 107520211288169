import { makeVar } from '@apollo/client';

/**
 * List of all reactive variables used in the applicaiton.
 *
 * More info: https://www.apollographql.com/docs/react/local-state/reactive-variables/
 */

/**
 * Map of typeName to incoming item ids.
 *
 * Used to display an incoming item count banner in a `SubscribedCondensedList`.
 */
export const typeNameToIsIncomingMapVar = makeVar({});
