import { gql } from '@apollo/client';

import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';

import { MultilingualString } from './multilingualString';

export const retailPriceAdjustment = gql`
  fragment RetailPriceAdjustmentFragment on RetailPriceAdjustment {
    id
    disclaimer {
      ...MultilingualStringFragment
    }
    fixed {
      ...MonetaryAmountFragment
    }
    label {
      ...MultilingualStringFragment
    }
    paymentOptions
    paymentOptionsNames
    percentage
    percentageAsAmount {
      ...MonetaryAmountFragment
    }
    type
  }
  ${MultilingualString}
  ${monetaryAmount}
`;
