import type { ErrorResponse } from '@apollo/client/link/error';
import type { OperationDefinitionNode } from 'graphql';
import { print } from 'graphql';

import { DEFAULT_MASK_VALUE, maskValuesInObject } from './objUtils';

const KEYS_TO_MASK = ['password', 'sin'];

/**
 * Get operation definition, graphql queries or mutations
 *
 * @param operation
 */
const getOperationDefinition = (operation: ErrorResponse['operation']) =>
  operation.query.definitions.find(q => q.kind === 'OperationDefinition') as OperationDefinitionNode;

/**
 * Consolidate and format into readable operation data
 *
 * @param operation
 */
export const getOperationData = (operation: ErrorResponse['operation']) => {
  const operationDefinition = getOperationDefinition(operation);
  const type = operationDefinition.operation;
  const query = print(operationDefinition);
  const maskedVariable = maskValuesInObject(operation.variables || {}, KEYS_TO_MASK, DEFAULT_MASK_VALUE);

  return {
    name: operation.operationName,
    type,
    query,
    variables: maskedVariable,
  };
};

/**
 * Check if we ignore certain statusCodes.
 *
 * @example 502 -> Server down such as during a deployment.
 */
export const isNetworkStatusCodeIgnored = (statusCode: number | string) => [502].includes(Number(statusCode));
