import { isNil } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';

import { formatStepError, getStepField } from './formatting/createModifyFormatUtils';
import type { Translate } from './intlUtils';

/**
 * Renders the amount of months as a remainder of years.
 * @example 13 months = 1 month, after subtracting 1 year (12 months)
 */
export function durationAsMonthsRemainder(totalDurationMonths: number | undefined | null) {
  return isNil(totalDurationMonths) ? undefined : totalDurationMonths % 12;
}

/**
 * Renders the amount of years from the item's amount of months, truncating remainders.
 * @example 13 months = 1 year (and a truncated 1 month)
 */
export function durationAsYearsTruncated(totalDurationMonths: number | undefined | null) {
  return isNil(totalDurationMonths) ? undefined : Math.floor(totalDurationMonths / 12);
}

/**
 * Used in builders.
 * Calculates the total duration, in months, from the values of the given Month and Year fields.
 * @example 2 years + 10 months = 34 months
 */
export function calculateTotalDuration(fields: StepField[], monthsFieldId: string, yearsFieldId: string) {
  const durationMonths = getStepField(monthsFieldId, fields)?.selectedValue || 0;
  const durationYears = getStepField(yearsFieldId, fields)?.selectedValue || 0;
  return Number.parseInt(durationYears) * 12 + Number.parseInt(durationMonths);
}

/** The valid range for the Duration (Months) field, used in below validations and errors messages */
const DURATION_MONTHS_RANGE = { min: 0, max: 11 };
/** The valid max num digits for the Duration (Years) field, used in below validations and errors messages */
const DURATION_YEARS_MAX_NUM_DIGITS = 2;

/** The custom error message used for the Duration (Months) field */
export const DURATION_MONTHS_CUSTOM_ERROR = (t: Translate['t']) =>
  t('value_must_be_between_x_and_y', [t('duration_months'), DURATION_MONTHS_RANGE.min, DURATION_MONTHS_RANGE.max]);

/** The custom error message used for the Duration (Years) field */
export const DURATION_YEARS_CUSTOM_ERROR = (t: Translate['t']) =>
  t('value_must_be_at most_x_digits_long', [t('duration_years'), DURATION_YEARS_MAX_NUM_DIGITS]);

/**
 * Validates the Duration Months and Years fields, if they have a `selectedValue`.
 * @returns Errors if the duration fields are invalid, nothing (undefined) otherwise
 */
export function validateDurationFields(
  fields: StepField[],
  monthsFieldId: string,
  yearsFieldId: string
): ApiError[] | undefined {
  const invalidFields: StepField[] = [];

  const monthsField = getStepField(monthsFieldId, fields);
  if (monthsField.selectedValue) {
    const months = Number.parseInt(monthsField.selectedValue);
    if (months < DURATION_MONTHS_RANGE.min || months > DURATION_MONTHS_RANGE.max) {
      invalidFields.push(monthsField);
    }
  }

  const yearsField = getStepField(yearsFieldId, fields);
  if (yearsField.selectedValue?.length > DURATION_YEARS_MAX_NUM_DIGITS) {
    invalidFields.push(yearsField);
  }

  if (invalidFields.length > 0) {
    return invalidFields.map(field => formatStepError(field));
  }
}
