import { gql } from '@apollo/client';

import { tradeInItemDetail } from 'store/api/graph/fragments/tradeInItemDetail';

export const tradeInItemModify = gql`
  mutation TradeInItemModifyMutation(
    $id: ID!
    $accidentAmount: Int
    $archived: Boolean
    $conditionScore: Int
    $conditionDeclarations: [ConditionDeclaration!]
    $leadNote: String
    $numKeys: Int
    $inTransit: Boolean
    $modelId: ID
    $motorcycleAttributes: MotorcycleAttributesInput
    $vehicleAttributes: VehicleAttributesInput
    $status: TradeInItemStatus
    $subModelId: ID
    $tagIds: [ID!]
    $trimId: ID
    $vin: String
    $carfaxReportId: ID
    $carfaxReportBadges: [CarfaxBadge!]
    $carfaxReportShowWeb: Boolean
    $year: Int
    $note: String
    $_clear: [TradeInItemModifyParameter!]
  ) {
    data: tradeInItemModify(
      id: $id
      accidentAmount: $accidentAmount
      archived: $archived
      conditionScore: $conditionScore
      conditionDeclarations: $conditionDeclarations
      leadNote: $leadNote
      numKeys: $numKeys
      inTransit: $inTransit
      modelId: $modelId
      motorcycleAttributes: $motorcycleAttributes
      vehicleAttributes: $vehicleAttributes
      status: $status
      subModelId: $subModelId
      tagIds: $tagIds
      trimId: $trimId
      vin: $vin
      carfaxReportId: $carfaxReportId
      carfaxReportBadges: $carfaxReportBadges
      carfaxReportShowWeb: $carfaxReportShowWeb
      year: $year
      note: $note
      _clear: $_clear
    ) {
      ...TradeInItemDetailFragment
    }
  }
  ${tradeInItemDetail}
`;

export const tradeInItemCreate = gql`
  mutation TradeInItemCreateMutation(
    $rooftopId: ID
    $accidentAmount: Int
    $conditionScore: Int
    $conditionDeclarations: [ConditionDeclaration!]
    $leadId: ID
    $leadNote: String
    $numKeys: Int
    $inTransit: Boolean
    $modelId: ID
    $motorcycleAttributes: MotorcycleAttributesInput
    $vehicleAttributes: VehicleAttributesInput
    $referringUrl: String
    $subModelId: ID
    $tagIds: [ID!]
    $trimId: ID
    $type: InventoryItemType
    $vin: String
    $carfaxReportId: ID
    $carfaxReportBadges: [CarfaxBadge!]
    $carfaxReportShowWeb: Boolean
    $year: Int
    $note: String
  ) {
    data: tradeInItemCreate(
      rooftopId: $rooftopId
      accidentAmount: $accidentAmount
      conditionScore: $conditionScore
      conditionDeclarations: $conditionDeclarations
      leadId: $leadId
      leadNote: $leadNote
      numKeys: $numKeys
      inTransit: $inTransit
      modelId: $modelId
      motorcycleAttributes: $motorcycleAttributes
      vehicleAttributes: $vehicleAttributes
      referringUrl: $referringUrl
      subModelId: $subModelId
      tagIds: $tagIds
      trimId: $trimId
      type: $type
      vin: $vin
      carfaxReportId: $carfaxReportId
      carfaxReportBadges: $carfaxReportBadges
      carfaxReportShowWeb: $carfaxReportShowWeb
      year: $year
      note: $note
    ) {
      ...TradeInItemDetailFragment
    }
  }
  ${tradeInItemDetail}
`;

export const tradeInItemSendAppInvite = gql`
  mutation TradeInItemSendAppInvite(
    $rooftopId: ID
    $phoneNumber: String
    $tradeInItemId: ID
    $leadId: ID
    $email: String
    $leadContactMethod: LeadContactMethod
  ) {
    tradeInItemSendAppInvite(
      rooftopId: $rooftopId
      phoneNumber: $phoneNumber
      tradeInItemId: $tradeInItemId
      leadId: $leadId
      email: $email
      leadContactMethod: $leadContactMethod
    ) {
      id
    }
  }
`;
