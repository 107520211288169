import { createContext } from 'react';

import type {
  GlobalAdminFilterProperties,
  GlobalAdminFilters,
  GlobalFilterOption,
} from 'components/ui/filters/interfaces/globalFilter';
import type { PaginationProps } from 'enums/pagination';
import type { Column, SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';

export interface ConnectionSearchContextInterface {
  /** The search parameters. */
  searchParams: any;
  /** Sets the search parameters. */
  setSearchParams: (value: any) => void;
  /** The local search parameters. */
  localSearchParams: any;
  /** Sets the local search parameters. */
  setLocalSearchParams: (value: any) => void;
  /** Updates a specific search parameter. */
  updateSearchParam: (name: string, value: any) => void;
  /** The global search parameters. */
  globalSearchParams: GlobalAdminFilters | undefined;
  /** Sets the global search parameters. */
  setGlobalSearchParams: (value: GlobalAdminFilters) => void;
  /** Updates a specific global search parameter. */
  updateGlobalSearchParams: <
    TName extends GlobalAdminFilterProperties,
    TValue = TName extends GlobalAdminFilterProperties.ROOFTOPS
      ? GlobalFilterOption[]
      : TName extends GlobalAdminFilterProperties.GROUPS
        ? GlobalFilterOption
        : never,
  >(
    name: TName,
    value: TValue | undefined
  ) => void;
  /** Clears all global search parameters. */
  clearGlobalSearchParams: () => void;
  /** The active saved filter view. */
  activeSavedFilterView?: SavedConnectionFilterFragment;
  /** Updates the active saved filter view. */
  updateActiveSavedFilterView: (filterView: SavedConnectionFilterFragment | undefined) => void;
  /** Retrieves the value of a specific search parameter. */
  getSearchParam: (name: string) => any;
  /** Retrieves the value of a specific search parameter as an array. */
  getSearchParamAsArray: (name: string) => string[];
  /** Updates pagination parameters. */
  updatePaginationParams: (params: PaginationProps) => void;
  /** Clears search parameters. */
  clearSearchParams: (clearAll?: boolean) => void;
  /** Clears keyword search parameters. */
  clearKeywordSearchParams: (keywords?: string[]) => void;
  /** Updates the sort order. */
  updateSortOrder: (columnId: string, columns?: Column[], persistent?: boolean) => void;
  /** Checks if the search context is in a pristine state. */
  isPristine: () => boolean;
}

export const ConnectionSearchContext = createContext<ConnectionSearchContextInterface | undefined>(undefined);
