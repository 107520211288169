import { gql } from '@apollo/client';

import { appointmentConnectionFilterFragment } from 'store/api/graph/fragments/appointmentConnectionFilter';
import { conversationConnectionFilterFragment } from 'store/api/graph/fragments/conversationConnectionFilter';
import { leadConnectionFilterFragment } from 'store/api/graph/fragments/leadConnectionFilter';
import { retailItemConnectionFilterFragment } from 'store/api/graph/fragments/retailItemConnectionFilter';
import { taskConnectionFilterFragment } from 'store/api/graph/fragments/taskConnectionFilter';
import { tradeInItemConnectionFilterFragment } from 'store/api/graph/fragments/tradeInItemConnectionFilter';

export const savedConnectionFilterFragment = gql`
  fragment SavedConnectionFilterFragment on SavedConnectionFilter {
    id
    name
    type
    created
    appointmentConnectionFilter: filter @include(if: $d_isAppointmentConnectionFilter) {
      ... on AppointmentConnectionFilter {
        ...AppointmentConnectionFilterFragment
      }
    }

    taskConnectionFilter: filter @include(if: $d_isTaskConnectionFilter) {
      ... on TaskConnectionFilter {
        ...TaskConnectionFilterFragment
      }
    }

    leadConnectionFilter: filter @include(if: $d_isLeadConnectionFilter) {
      ... on LeadConnectionFilter {
        ...LeadConnectionFilterFragment
      }
    }

    conversationConnectionFilter: filter @include(if: $d_isConversationConnectionFilter) {
      ... on ConversationConnectionFilter {
        ...ConversationConnectionFilterFragment
      }
    }

    tradeInItemConnectionFilter: filter @include(if: $d_isTradeInConnectionFilter) {
      ... on TradeInItemConnectionFilter {
        ...TradeInItemConnectionFilterFragment
      }
    }

    retailItemConnectionFilter: filter @include(if: $d_isRetailItemConnectionFilter) {
      ... on RetailItemConnectionFilter {
        ...RetailItemConnectionFilterFragment
      }
    }
  }

  ${appointmentConnectionFilterFragment}
  ${conversationConnectionFilterFragment}
  ${leadConnectionFilterFragment}
  ${retailItemConnectionFilterFragment}
  ${tradeInItemConnectionFilterFragment}
  ${taskConnectionFilterFragment}
`;
