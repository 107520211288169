import { get } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { MileageUnit } from 'store/api/graph/interfaces/types';
import { defineFieldValues, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { getRemovedAttributes } from 'utils/inventoryItemUtils';

class MileageAndConditionStep extends StepComponentCore {
  static contextType = CreateModifyContext;

  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, activeStep },
    } = props;

    this.context = context;

    const removedAttributes = getRemovedAttributes(data);

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [`${InventoryItemAttributesPointer.VEHICLE}.mileage`]: {
        selectedValue: get(data, `${InventoryItemAttributesPointer.VEHICLE}.mileage`) || {
          amount: null,
          unit: MileageUnit.KILOMETERS,
        },
      },
      [`${InventoryItemAttributesPointer.MOTORCYCLE}.mileage`]: {
        selectedValue: get(data, `${InventoryItemAttributesPointer.MOTORCYCLE}.mileage`) || {
          amount: null,
          unit: MileageUnit.KILOMETERS,
        },
      },
    }).filter(stepField => !removedAttributes.includes(stepField.queryVar.split('.')[0]));

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);
  }

  async onFieldChange(stepField: StepField, e) {
    super.onFieldChange(stepField, e);
  }
}

export default MileageAndConditionStep;
