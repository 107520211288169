import { gql } from '@apollo/client';

import { userDetail } from 'store/api/graph/fragments/userDetail';

export const userModify = gql`
  mutation UserModifyMutation(
    $id: ID
    $active: Boolean
    $rooftopIdsLimitedTo: [ID!]
    $avatarImage: Upload
    $cellNumber: String
    $email: String
    $subscribedEmail: Boolean
    $firstName: String
    $lastName: String
    $title: String
    $locale: String
    $password: String
    $permissions: [PermissionInput!]
    $phoneNumber: String
    $_clear: [UserModifyParameter!]
  ) {
    data: userModify(
      id: $id
      active: $active
      rooftopIdsLimitedTo: $rooftopIdsLimitedTo
      avatarImage: $avatarImage
      cellNumber: $cellNumber
      email: $email
      subscribedEmail: $subscribedEmail
      firstName: $firstName
      lastName: $lastName
      title: $title
      locale: $locale
      password: $password
      permissions: $permissions
      phoneNumber: $phoneNumber
      _clear: $_clear
    ) {
      ...UserDetailFragment
    }
  }
  ${userDetail}
`;

export const userCreate = gql`
  mutation UserCreateMutation(
    $whiteLabelId: ID
    $groupId: ID
    $rooftopIdsLimitedTo: [ID!]
    $avatarImage: Upload
    $cellNumber: String
    $email: String
    $subscribedEmail: Boolean
    $firstName: String
    $lastName: String
    $title: String
    $locale: String
    $password: String
    $permissions: [PermissionInput!]
    $phoneNumber: String
    $_submit: Boolean! = true
  ) {
    data: userCreate(
      whiteLabelId: $whiteLabelId
      groupId: $groupId
      rooftopIdsLimitedTo: $rooftopIdsLimitedTo
      avatarImage: $avatarImage
      cellNumber: $cellNumber
      email: $email
      subscribedEmail: $subscribedEmail
      firstName: $firstName
      lastName: $lastName
      title: $title
      locale: $locale
      password: $password
      permissions: $permissions
      phoneNumber: $phoneNumber
      _submit: $_submit
    ) {
      ...UserDetailFragment
    }
  }
  ${userDetail}
`;

export const userDelete = gql`
  mutation UserDeleteMutation($id: ID!) {
    data: userDelete(id: $id)
  }
`;
