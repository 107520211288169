import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum Appointment {
  TITLE = 'title',
  DATE = 'date',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  DATE_TO = 'dateTo',
  DATE_FROM = 'dateFrom',
  INVENTORY_ITEM_NAME = 'inventoryItemName',
  GROUP_NAME = 'groupName',
  ROOFTOP_NAME = 'rooftopName',
  TAG_NAME = 'tagName',
  LOCATION = 'location',
  LEAD_NAME = 'leadName',
  CREATED_BY_NAME = 'createdByName',
  ASSIGNED_TO_NAME = 'assignedToName',
  REMINDER = 'reminder',
  CREATED = 'created',
  UPDATED = 'updated',
  NOTE = 'note',
  ARCHIVED = 'archived',
}

const AppointmentConfig: TableSettingsType = {
  // Minimum required fields for table/listItem
  [Appointment.TITLE]: { label: 'title', type: FieldDataType.DEFAULT },
  [Appointment.DATE]: { label: 'date', type: FieldDataType.DAY_MONTH_YEAR },
  [Appointment.START_TIME]: { label: 'start_time', type: FieldDataType.TIME },
  [Appointment.END_TIME]: { label: 'end_time', type: FieldDataType.TIME },
  [Appointment.DATE_FROM]: { label: 'date_from', type: FieldDataType.DAY_TIME },
  [Appointment.DATE_TO]: { label: 'date_to', type: FieldDataType.DAY_TIME },

  // Directive derived columns
  [Appointment.LOCATION]: { label: 'location', type: FieldDataType.DEFAULT },
  [Appointment.REMINDER]: { label: 'reminder', type: FieldDataType.DURATION, tooltip: 'reminder_tooltip' },
  [Appointment.INVENTORY_ITEM_NAME]: { label: 'inventory_item', type: FieldDataType.YMMT },
  [Appointment.GROUP_NAME]: { label: 'group', type: FieldDataType.GROUP_NAME },
  [Appointment.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [Appointment.TAG_NAME]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [Appointment.LEAD_NAME]: { label: 'lead_one', type: FieldDataType.USER_NAME },
  [Appointment.CREATED_BY_NAME]: { label: 'created_by', type: FieldDataType.USER_NAME },
  [Appointment.ASSIGNED_TO_NAME]: { label: 'assigned_to', type: FieldDataType.USER_NAME },
  [Appointment.NOTE]: { label: 'note_other', type: FieldDataType.DEFAULT },
  [Appointment.ARCHIVED]: { label: 'archived', type: FieldDataType.BOOLEAN },
  [Appointment.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Appointment.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const AppointmentSettings = translateFields(AppointmentConfig);
