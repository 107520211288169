import { Mark, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      /**
       * Set a highlight mark
       * @example editor.commands.setLinkHighlight()
       */
      setLinkHighlight: () => ReturnType;
      /**
       * Unset a highlight mark
       * @example editor.commands.unsetLinkHighlight()
       */
      unsetLinkHighlight: () => ReturnType;
    };
  }
}

export const LinkHighlight = Mark.create({
  name: 'linkHighlight',

  renderHTML({ HTMLAttributes }) {
    return ['mark', mergeAttributes({ class: 'highlighted-link' }, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setLinkHighlight:
        () =>
        ({ commands }) =>
          commands.setMark(this.name),
      unsetLinkHighlight:
        () =>
        ({ commands }) =>
          commands.unsetMark(this.name, { extendEmptyMarkRange: true }),
    };
  },
});
