import { isFunction } from 'lodash-es';
import { unstable_LowPriority, unstable_scheduleCallback } from 'scheduler';

/**
 * A Deferred callback using react's internal scheduler
 * @param func function to be called
 * @param priority a scheduler priority, one of
 *          `unstable_LowPriority`,
 *          `unstable_ImmediatePriority`,
 *          `unstable_UserBlockingPriority`,
 *          `unstable_NormalPriority`,
 *          `unstable_IdlePriority`,
 *          `unstable_LowPriority`
 */
export const deferredRenderCall = (func: () => void, priority: any = unstable_LowPriority) => {
  unstable_scheduleCallback(priority, () => {
    func();
  });
};

/**
 * A util that tests if a value is a function, and if so calls it with the provided props,
 * else directly returns the value. Useful in render methods for children that may or may not be a render prop.
 *
 * @example
 * const children = (props) => <div>{props.title}</div>
 * const props = { title: "Example" }
 * runIfFun(children, props)
 */
export const runIfFun = <T, U>(valueOrFn: T | ((...fnArgs: U[]) => T), ...args: U[]): T =>
  isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;

export type MaybeRenderProp<P> = React.ReactNode | ((props: P) => React.ReactNode);
