import { isObject } from 'lodash-es';
import traverse from 'traverse';

export const DEFAULT_MASK_VALUE = '********';

/**
 * Traverse the object and mask any key value defined in `KEY_TO_MASK`
 * @param obj operation variables
 */
export const maskValuesInObject = (
  obj: Record<string, any>,
  keys: string[],
  maskedValue: string = DEFAULT_MASK_VALUE
) =>
  traverse(obj).map(function (value) {
    if (this.key && keys.includes(this.key) && !isObject(value)) {
      return maskedValue;
    }

    return value;
  });

/**
 * Check if an object is contained within another object.
 *
 * Returns `true` if:
 * - all enumerable keys of *subset* are also enumerable in *superset*, and
 * - every value assigned to an enumerable key of *subset* strictly equals
 *   the value assigned to the same key of *superset* – or is a subset of it.
 *
 * @param  {Object}  superset
 * @param  {Object}  subset
 *
 * @returns  {Boolean}
 *
 * @function  default
 * @alias     isSubset
 */

export const isSubset = (superset: Record<string, any>, subset: Record<string, any>): boolean => {
  if (typeof superset !== 'object' || superset === null || typeof subset !== 'object' || subset === null) {
    return false;
  }

  if (superset instanceof Date || subset instanceof Date) {
    return superset.valueOf() === subset.valueOf();
  }

  return Object.keys(subset).every(key => {
    if (!superset.propertyIsEnumerable(key)) {
      return false;
    }

    const subsetItem = subset[key];
    const supersetItem = superset[key];
    if (
      typeof subsetItem === 'object' && subsetItem !== null
        ? !isSubset(supersetItem, subsetItem)
        : supersetItem !== subsetItem
    ) {
      return false;
    }

    return true;
  });
};

/** Check if the given input is a `File` object */
export const isFile = (input: unknown): input is File => isObject(input) && 'File' in window && input instanceof File;
