/**
 * This file is meant to hold any utilities for interacting with client-side storage, be it cookies, localStorage, or
 * sessionStorage. Any individual components that need to interact with the store can create an instance of storage
 * to allow for a separate mgmt of storage. A default storage is created to start for quick usage.
 */

export enum StorageType {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION',
}

const USER_STORAGE_SEPARATOR = ':';

const STORAGE_MAPPING: { [key in StorageType]: globalThis.Storage } = {
  [StorageType.LOCAL]: window.localStorage,
  [StorageType.SESSION]: window.sessionStorage,
};

export class Storage<TData> {
  readonly name: string;
  readonly type: StorageType;
  private readonly store: globalThis.Storage;

  constructor(name: string, type = StorageType.LOCAL) {
    this.name = `EDealer.${name}`;
    this.type = type;
    this.store = STORAGE_MAPPING[this.type];
  }

  get(): TData | undefined {
    try {
      const item = this.store.getItem(this.name);
      return item === null ? undefined : JSON.parse(item);
    } catch {
      // Remove if parse fails.
      this.remove();
    }
  }

  set(value: TData) {
    try {
      if (value !== null || value !== undefined) {
        this.store.setItem(this.name, JSON.stringify(value));
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Error setting ${value} in storage ${this.name}. \n ${error}`);
    }
    return this;
  }

  remove() {
    try {
      this.store.removeItem(this.name);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Error removing storage with name ${this.name}. \n ${error}`);
    }
    return this;
  }

  getIn(key: keyof TData) {
    const storage = this.get();
    return storage?.[key];
  }

  setIn(childKey: keyof TData, value: any) {
    const toSave: any = this.get() || {};
    toSave[childKey] = value;
    this.store.setItem(this.name, JSON.stringify(toSave));
    return this;
  }

  removeIn(key: keyof TData) {
    const parent = this.get();
    if (!parent) {
      return;
    }
    delete parent[key];
    this.store.setItem(this.name, JSON.stringify(parent));
    return this;
  }
}

class StorageManager {
  // Holds references to instances of `Storage`
  private managedStorage: { [key: string]: Storage<any> } = {};

  constructor() {
    this.loadStorage();
  }

  private loadStorage() {
    for (const [type, storage] of Object.entries(STORAGE_MAPPING)) {
      this.loopAndLoad(storage, type as StorageType);
    }
  }

  private loopAndLoad(storage: globalThis.Storage, storageType: StorageType) {
    for (const k of Object.keys(storage)) {
      const removeNameSpace = k.replace('EDealer.', '');
      if (!this.managedStorage[removeNameSpace]) {
        this.managedStorage[removeNameSpace] = new Storage(removeNameSpace, storageType);
      }
    }
  }

  getStorageName(options: { withKey: string; forUserId?: string }) {
    return options.forUserId ? `${options.withKey}${USER_STORAGE_SEPARATOR}${options.forUserId}` : options.withKey;
  }

  createOrFetchStorage<TData>(name: string, type?: StorageType, forUserId?: string): Storage<TData> {
    name = this.getStorageName({ withKey: name, forUserId });
    if (this.managedStorage[name] && type === this.managedStorage[name].type) {
      return this.managedStorage[name];
    }
    const newStorage = new Storage<TData>(name, type);
    this.managedStorage[name] = newStorage;
    return newStorage;
  }

  clearStorage(options: {
    /** The specifc `StorageType` to target when clearing */
    ofType: StorageType;
    /** The specific `userId` to target when clearing */
    forUserId?: string;
    /**
     * The specific `sectionPath` to target when clearing.
     * Can be the name or full path.
     * e.g. `retail` or `/retail`
     */
    sectionPath?: string;
  }) {
    const { ofType, forUserId, sectionPath } = options;
    const storage = STORAGE_MAPPING[ofType];
    const entries = Object.entries(storage);

    if (forUserId) {
      const nameSuffix = this.getStorageName({ withKey: '', forUserId });
      // Remove user specific storage
      for (const [key, _] of entries) {
        let clearCondition = key.endsWith(nameSuffix);
        if (sectionPath) {
          const sectionPathName = sectionPath.replaceAll('/', '');
          clearCondition = key.endsWith(nameSuffix) && key.includes(sectionPathName);
        }
        if (clearCondition) {
          storage.removeItem(key);
        }
      }
    } else {
      // Remove all storage
      for (const [key, _] of entries) {
        storage.removeItem(key);
      }
    }
  }
}

export const storageManager = new StorageManager();
