import { gql } from '@apollo/client';

import { retailItemDetail } from 'store/api/graph/fragments/retailItemDetail';

export const retailItemPricingModify = gql`
  mutation RetailItemPricingMutation(
    $id: ID!
    $price1: Int
    $price2: Int
    $cost: Int
    $msrp: Int
    $cashRetailPricing: CashRetailPricingInput
    $financeRetailPricing: FinanceRetailPricingInput
    $leaseRetailPricing: LeaseRetailPricingInput
    $lockedFields: [RetailItemLockableField!]
    $_clear: [RetailItemModifyParameter!]
  ) {
    data: retailItemModify(
      id: $id

      ## Pricing Info Step
      price1: $price1
      price2: $price2
      cost: $cost
      msrp: $msrp
      lockedFields: $lockedFields

      ## Cash Step
      cashRetailPricing: $cashRetailPricing

      ## Finance Step
      financeRetailPricing: $financeRetailPricing

      # Lease Step
      leaseRetailPricing: $leaseRetailPricing

      _clear: $_clear
    ) {
      ...RetailItemDetailFragment
    }
  }
  ${retailItemDetail}
`;

export const retailItemModify = gql`
  mutation RetailItemModifyMutation(
    $id: ID!
    $archived: Boolean
    $condition: InventoryItemCondition
    $warranty: WarrantyInput
    $vin: String
    $carfaxReportId: ID
    $carfaxReportBadges: [CarfaxBadge!]
    $carfaxReportShowWeb: Boolean
    $stockNumber: String
    $year: Int
    $modelId: ID
    $subModelId: ID
    $trimId: ID
    $asIs: Boolean
    $certified: Boolean
    $demo: Boolean
    $showWeb: Boolean
    $featured: Boolean
    $onOrder: Boolean
    $inTransit: Boolean
    $price1: Int
    $price2: Int
    $cost: Int
    $msrp: Int
    $description: MultilingualStringInput
    $highlights: MultilingualStringInput
    $tagIds: [ID!]
    $status: RetailItemStatus
    $vehicleAttributes: VehicleAttributesInput
    $motorcycleAttributes: MotorcycleAttributesInput
    $disabledBulkAdjustmentIds: [ID!]
    $disabledStandardEquipmentIds: [ID!]
    $disabledTechnicalSpecificationIds: [ID!]
    $cashRetailPricing: CashRetailPricingInput
    $financeRetailPricing: FinanceRetailPricingInput
    $leaseRetailPricing: LeaseRetailPricingInput
    $note: String
    $lockedFields: [RetailItemLockableField!]
    $_clear: [RetailItemModifyParameter!]
  ) {
    data: retailItemModify(
      id: $id
      archived: $archived
      condition: $condition
      vin: $vin
      carfaxReportId: $carfaxReportId
      carfaxReportBadges: $carfaxReportBadges
      carfaxReportShowWeb: $carfaxReportShowWeb
      stockNumber: $stockNumber
      year: $year
      modelId: $modelId
      subModelId: $subModelId
      trimId: $trimId
      asIs: $asIs
      certified: $certified
      demo: $demo
      showWeb: $showWeb
      warranty: $warranty
      featured: $featured
      onOrder: $onOrder
      inTransit: $inTransit
      vehicleAttributes: $vehicleAttributes
      motorcycleAttributes: $motorcycleAttributes
      price1: $price1
      price2: $price2
      cost: $cost
      msrp: $msrp
      description: $description
      highlights: $highlights
      disabledBulkAdjustmentIds: $disabledBulkAdjustmentIds
      disabledStandardEquipmentIds: $disabledStandardEquipmentIds
      disabledTechnicalSpecificationIds: $disabledTechnicalSpecificationIds
      cashRetailPricing: $cashRetailPricing
      financeRetailPricing: $financeRetailPricing
      leaseRetailPricing: $leaseRetailPricing
      tagIds: $tagIds
      status: $status
      note: $note
      lockedFields: $lockedFields
      _clear: $_clear
    ) {
      ...RetailItemDetailFragment
    }
  }
  ${retailItemDetail}
`;

export const retailItemCreate = gql`
  mutation RetailItemCreateMutation(
    $rooftopId: ID
    $type: InventoryItemType
    $condition: InventoryItemCondition
    $warranty: WarrantyInput
    $vin: String
    $carfaxReportId: ID
    $carfaxReportBadges: [CarfaxBadge!]
    $carfaxReportShowWeb: Boolean
    $stockNumber: String
    $year: Int
    $modelId: ID
    $subModelId: ID
    $trimId: ID
    $asIs: Boolean
    $certified: Boolean
    $demo: Boolean
    $featured: Boolean
    $onOrder: Boolean
    $inTransit: Boolean
    $price1: Int
    $price2: Int
    $cost: Int
    $msrp: Int
    $description: MultilingualStringInput
    $highlights: MultilingualStringInput
    $tagIds: [ID!]
    $vehicleAttributes: VehicleAttributesInput
    $motorcycleAttributes: MotorcycleAttributesInput
    $note: String
    $showWeb: Boolean = true
    $disabledStandardEquipmentIds: [ID!]
    $disabledTechnicalSpecificationIds: [ID!]
    $lockedFields: [RetailItemLockableField!]
  ) {
    data: retailItemCreate(
      rooftopId: $rooftopId
      type: $type
      condition: $condition
      vin: $vin
      carfaxReportId: $carfaxReportId
      carfaxReportBadges: $carfaxReportBadges
      carfaxReportShowWeb: $carfaxReportShowWeb
      stockNumber: $stockNumber
      year: $year
      modelId: $modelId
      subModelId: $subModelId
      trimId: $trimId
      asIs: $asIs
      certified: $certified
      demo: $demo
      warranty: $warranty
      featured: $featured
      onOrder: $onOrder
      inTransit: $inTransit
      vehicleAttributes: $vehicleAttributes
      motorcycleAttributes: $motorcycleAttributes
      price1: $price1
      price2: $price2
      cost: $cost
      msrp: $msrp
      description: $description
      highlights: $highlights
      tagIds: $tagIds
      note: $note
      showWeb: $showWeb
      disabledStandardEquipmentIds: $disabledStandardEquipmentIds
      disabledTechnicalSpecificationIds: $disabledTechnicalSpecificationIds
      lockedFields: $lockedFields
    ) {
      ...RetailItemDetailFragment
    }
  }
  ${retailItemDetail}
`;

export const retailItemBulkModify = gql`
  mutation RetailItemBulkModifyMutation(
    $ids: [ID!]!
    $archived: Boolean
    $featured: Boolean
    $showWeb: Boolean
    $status: RetailItemStatus
  ) {
    data: retailItemBulkModify(ids: $ids, archived: $archived, featured: $featured, showWeb: $showWeb, status: $status)
  }
`;
