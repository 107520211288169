import 'styled-components/macro';
import type { ReactElement, ReactNode } from 'react';

import Label from 'components/core/typography/Label';
import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import Dialog from 'components/ui/dialogs/Dialog';
import { OptionButton, OptionButtonStyle, OptionsContainer } from 'components/ui/dialogs/PromptDialogOptions';
import { DialogBodyContentContainer, DialogContentContainer, DialogHeader } from 'components/ui/layouts/DialogLayout';
import { ElementTestId } from 'enums/testing';
import { translate } from 'utils/intlUtils';

export interface StructuredDialogButton {
  /** The text on this button */
  label: string;
  /** What happens when this button is clicked */
  onClick: () => void;
  /** Defines the style the button will have when presented */
  buttonStyle?: OptionButtonStyle;
  /** A testing ID to apply to this button */
  testId?: string;
}

interface StructuredDialogProps extends Omit<BaseDialogProps, 'isOpen' | 'onCancel' | 'children' | 'isDismissible'> {
  /** The icon of this dialog */
  icon?: ReactElement;
  /** The message or contents of this dialog */
  children: ReactNode;
  /** An optional header to add to the dialog */
  header?: string;
  /** The buttons of this dialog. A default "Got it" button if be used if undefined. */
  buttons?: StructuredDialogButton[];
  /** Whether or not this dialog is open */
  isOpen: boolean;
  /** What to call when the dialog is closed */
  onClose: () => void;
  /** Whether or not the dialog should close when you click outside of it. True by default. */
  isDismissible?: boolean;
  /** Any css to apply to the main dialog container */
  dialogCss?: string;
  /** An override for the default `ElementTestId.STRUCTURED_DIALOG_CONTAINER` */
  testId?: string;
}

/**
 * A simple dialog containing one icon, some contents, and some buttons button.
 * If buttons are undefined, a default one with the text "Got it" that calls onClose when clicked will be used instead.
 * To have /no/ buttons, set it to an empty array.
 *
 * The structure is as follows:
 *   Header
 *   Icon
 *   Contents
 *   Buttons
 *
 * Everything aside from the buttons are options.
 */
export const StructuredDialog = ({
  icon,
  children,
  header,
  buttons,
  isOpen,
  onClose,
  isDismissible = true,
  dialogCss,
  testId,
  ...props
}: StructuredDialogProps) => {
  const dialogButtons = buttons || [
    {
      label: translate.t('okay'),
      onClick: onClose,
      buttonStyle: OptionButtonStyle.CONFIRMATION,
      testId: ElementTestId.STRUCTURED_DIALOG_CLOSE_BUTTON,
    },
  ];
  return (
    <Dialog isOpen={isOpen} onCancel={onClose} isDismissible={isDismissible} {...props}>
      <DialogContentContainer css={dialogCss} data-testid={testId || ElementTestId.STRUCTURED_DIALOG_CONTAINER}>
        {header && <DialogHeader title={header} onClose={dialogButtons.length === 1 ? onClose : undefined} />}
        <DialogBodyContentContainer>
          <OptionsContainer>
            {icon}
            {children}
            {dialogButtons.length > 0 && (
              <div>
                {dialogButtons.map(button => (
                  <OptionButton
                    onClick={button.onClick}
                    key={button.label}
                    data-testid={button.testId}
                    styleVariant={button.buttonStyle}
                  >
                    <Label>{button.label}</Label>
                  </OptionButton>
                ))}
              </div>
            )}
          </OptionsContainer>
        </DialogBodyContentContainer>
      </DialogContentContainer>
    </Dialog>
  );
};
