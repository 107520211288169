import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const integrationWebsiteRoutes = gql`
  fragment IntegrationWebsiteRoutesFragment on WebsiteRoute {
    id
    name: path {
      ...MultilingualStringFragment
    }
  }
`;

export const integrationWebsite = gql`
  fragment IntegrationWebsiteFragment on Website {
    id
    name: url
    primaryText: name {
      ...MultilingualStringFragment
    }
    secondaryText: url
    routes {
      ...IntegrationWebsiteRoutesFragment
    }
  }

  ${MultilingualString}
  ${integrationWebsiteRoutes}
`;
