import styled, { css } from 'styled-components/macro';

import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { BODY_TEXT } from 'styles/color';
import {
  BLUE_500,
  GREEN_050,
  GREEN_700,
  NEUTRAL_0,
  NEUTRAL_050,
  RED_200,
  RED_600,
  YELLOW_100,
  YELLOW_700,
} from 'styles/tokens';
import {
  FONT_SIZE_11,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_BOLDER,
  LETTER_SPACING_EXPANDED_3,
} from 'styles/typography';
import { variants } from 'utils/styledUtils';

/**
 * Theme of `Panel` describing visual appearance.
 */
export enum PanelTheme {
  DEFAULT = 'default',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning',
}

interface PanelBodyProps {
  /** The theme of `Panel`. */
  theme?: PanelTheme;
}

/*
 * =====================================================================
 * Shared styles -------------------------------------------------------
 */

const sharedPanelThemeStyles = css`
  min-height: 80px; /** Sum of mandatory label and value rows */

  ${SecondaryText} {
    font-size: ${FONT_SIZE_11};
    font-weight: ${FONT_WEIGHT_BOLD};
    letter-spacing: ${LETTER_SPACING_EXPANDED_3};
  }
`;

const positivePanelThemeStyles = css`
  background: ${GREEN_050};
  ${PrimaryText}, ${SecondaryText} {
    color: ${GREEN_700};
  }

  ${sharedPanelThemeStyles}
`;

const negativePanelThemeStyles = css`
  background: ${RED_200};
  ${PrimaryText}, ${SecondaryText} {
    color: ${RED_600};
  }

  ${sharedPanelThemeStyles}
`;

const warningPanelThemeStyles = css`
  background: ${YELLOW_100};
  ${PrimaryText}, ${SecondaryText} {
    color: ${YELLOW_700};
  }

  ${sharedPanelThemeStyles}
`;

export const PanelBodyShared = css<PanelBodyProps>`
  min-height: 88px; /** Sum of mandatory label and value rows */
  background: ${NEUTRAL_050};
  color: ${BODY_TEXT};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${variants<PanelTheme>(
    'theme',
    {
      positive: positivePanelThemeStyles,
      negative: negativePanelThemeStyles,
      warning: warningPanelThemeStyles,
      default: null,
    },
    PanelTheme.DEFAULT
  )};
`;

export const PanelValue = styled(PrimaryText)`
  font-size: ${FONT_SIZE_24};
  font-weight: ${FONT_WEIGHT_BOLDER};
  margin-bottom: 10px;
`;

export const PanelLabel = styled(SecondaryText)`
  text-align: start;
`;

export const PanelValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

/*
 * =====================================================================
 * Elements ------------------------------------------------------------
 */

export const Panels = styled.div`
  display: flex;
  flex-wrap: wrap;

  /** Spacing between multiple flex rows */
  margin: -15px 0 0 0;

  > * {
    margin-top: 15px;
  }

  &:empty {
    display: none;
  }
`;

export const PanelBody = styled.div`
  justify-content: space-between;
  ${PanelBodyShared}

  ${PanelValue} {
    margin: 0;
  }
`;

export const PanelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const PanelBodyLink = styled(WrapLink)`
  ${PanelBodyShared}
  &.active {
    background: ${BLUE_500};
    &,
    ${PrimaryText}, ${SecondaryText} {
      color: ${NEUTRAL_0};
    }

    svg {
      fill: white;
      transform: rotate(180deg);
    }
  }
`;

export const PanelChevronIcon = styled(ChevronRightIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  fill: ${BODY_TEXT};
`;

export interface PanelProps {
  /** Hyperlink when clicked */
  to?: string;
  /** A generic label associated with the value to display */
  label: string | number;
  /** The value/s to display */
  value: null | string | number | (string | number)[];
  /** Whether to flip label and value rows */
  isContentFlipped?: boolean;
  /** Theme of `Panel` e.g. positive */
  theme?: PanelTheme;
}
const Panel = ({ to, label, value, isContentFlipped, theme }: PanelProps) => {
  const panelValues = Array.isArray(value) ? value : [value];
  const firstContent = isContentFlipped ? <SecondaryText>{label}</SecondaryText> : <PanelValue>{value}</PanelValue>;
  const lastContent = isContentFlipped ? (
    <PanelValueContainer>
      {panelValues.map(panelValue => (
        <PanelValue key={panelValue}>{panelValue}</PanelValue>
      ))}
    </PanelValueContainer>
  ) : (
    <SecondaryText>{label}</SecondaryText>
  );
  const panelContent = (
    <>
      {firstContent}
      {to && <PanelChevronIcon />}
      {lastContent}
    </>
  );

  if (to) {
    return (
      <PanelBodyLink theme={theme} to={to}>
        {panelContent}
      </PanelBodyLink>
    );
  }
  return <PanelBody theme={theme}>{panelContent}</PanelBody>;
};

export default Panel;
