import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { taskMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { taskDetailsQuery } from 'containers/tasks/TasksContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { TaskMetaQuery, TaskMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const TaskQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<TaskMetaQuery, TaskMetaQueryVariables>({
      query: taskMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: taskDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'TaskConnectionQuery',
    'LeadDetailsContainerQuery',
    'TradeInItemDetailsContainerQuery',
    'RetailItemDetailsContainerQuery',
    'TaskCondensedListQuery',
    'UserDetailsContainerQuery',
    'DashboardTaskShortlistQuery',
  ],
};

export default TaskQueryMethods;
