import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';

const AvailableMileageAllowanceFields: StepFields = {
  [RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields.AVAILABLE_MILEAGE_ALLOWANCES]: {
    label: 'mileage_allowance',
    groupType: StepFieldType.MILEAGE,
  },
};

export default AvailableMileageAllowanceFields;
