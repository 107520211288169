import { useCallback } from 'react';

import type { TimeListPickerSettings } from 'components/ui/forms/shared/TimeListPicker';
import TimeListPicker from 'components/ui/forms/shared/TimeListPicker';
import { getTimeObjectFromTimeStamp, getTimeStampFromTimeObject } from 'utils/timeUtils';

import { ListPickerContainer } from './ListPickerContainer';
import { Container } from './ListSelectionContainer';

type ListSelectionTimePickerProps = {
  /**
   * Callback fired when a time is selected.
   * @param {string} item The selected time in the format `DateTimeFormat.TIME_STAMP_FORMAT`
   */
  onSelect?: (item: string) => void;
  /** A string in the format `DateTimeFormat.TIME_STAMP_FORMAT` */
  selectedValue?: string;
  settings?: {
    /** Settings to be passed to the TimeListPicker component */
    timeListPickerConfig: TimeListPickerSettings;
  };
};

const ListSelectionTimePicker = ({ onSelect = () => {}, selectedValue, settings }: ListSelectionTimePickerProps) => {
  const selectedTimeObject = getTimeObjectFromTimeStamp(selectedValue);

  const onChange = useCallback(newTimeObject => onSelect(getTimeStampFromTimeObject(newTimeObject)), [onSelect]);

  return (
    <Container>
      <ListPickerContainer>
        <TimeListPicker
          onChange={onChange}
          selectedValue={selectedTimeObject}
          settings={settings?.timeListPickerConfig}
        />
      </ListPickerContainer>
    </Container>
  );
};

export default ListSelectionTimePicker;
