import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import InitialItemSortingStep from 'components/sections/createModify/shared/steps/InitialItemSortingStep';
import type { WebsiteDetails } from 'components/sections/createModify/shared/steps/interfaces';
import { InitialItemSortingBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import { getWebsiteSortOptionsFromMetadata } from 'components/sections/shared/ItemMetaHelpers';
import type {
  Sort,
  SortInput,
  WebsiteRouteCreateMutationVariables,
  WebsiteRouteModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { WebsiteRouteModifyParameter } from 'store/api/graph/interfaces/types';
import { defineFieldValues, getStepField, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

import type { FetchWebsiteRetailFilterMetaqueryMetadata } from '../websiteRouteFilterUtils';

import { parseSeededData } from './stepUtils';

class WebsiteRoutesSortingStep extends InitialItemSortingStep<
  WebsiteDetails,
  FetchWebsiteRetailFilterMetaqueryMetadata,
  WebsiteRouteCreateMutationVariables | WebsiteRouteModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<
      WebsiteDetails,
      FetchWebsiteRetailFilterMetaqueryMetadata,
      WebsiteRouteCreateMutationVariables | WebsiteRouteModifyMutationVariables
    >
  ) {
    super(props);
    const {
      tier: { activeStep, data, seededData, isCreating, metadata },
    } = props;

    const { website, route } = parseSeededData(seededData);

    if (!website) {
      return;
    }

    const initialSortRules: Sort[] = isCreating
      ? website.defaultInitialItemSorting
      : route?.initialItemSorting || website.defaultInitialItemSorting;

    // If no rooftop filters are configured for this route metadata.websiteSortOptions will be generic
    this.metadataSortOptions = metadata.websiteSortOptions
      ? getWebsiteSortOptionsFromMetadata(metadata.websiteSortOptions)
      : [];

    const sortFields = this.initSortFields(initialSortRules);

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [InitialItemSortingBuilderFields.USE_CUSTOM_SORT_RULE]: {
        selectedValue: !!(route && route.initialItemSorting),
      },
    });

    // Insert the dynamically created stepFields into the correct index
    this.fields.splice(1, 0, ...Object.values(sortFields));

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Sort fields should be disabled unless they have been customized for the route
    if (route && route.initialItemSorting) {
      this.toggleSortFields(false);
    }
  }

  async save(): Promise<boolean> {
    const {
      tier: { seededData, isCreating },
    } = this.props;

    let variableOverrides: WebsiteRouteModifyMutationVariables | WebsiteRouteCreateMutationVariables = {};

    const initialItemSorting: SortInput[] = this.formatInitialItemSortingVariable();
    const useCustomSortRuleField = getStepField(InitialItemSortingBuilderFields.USE_CUSTOM_SORT_RULE, this.fields);

    if (useCustomSortRuleField.selectedValue && initialItemSorting) {
      variableOverrides.initialItemSorting = initialItemSorting;
    }

    if (!isCreating) {
      variableOverrides = {
        ...variableOverrides,
        // When modifying the routes id must be passed instead of the websiteID
        ...(seededData?.routeId ? { id: seededData.routeId } : {}),
        ...(!useCustomSortRuleField.selectedValue || initialItemSorting.length === 0
          ? { _clear: [WebsiteRouteModifyParameter._initialItemSorting] }
          : {}),
      };
    }

    return super.save({}, variableOverrides);
  }
}

export default WebsiteRoutesSortingStep;
