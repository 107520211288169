import type { ChangeEvent, InputHTMLAttributes } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import FormDialog from 'components/ui/dialogs/FormDialog';
import AddYouTubeVideoForm from 'components/ui/dialogs/forms/AddYouTubeVideoForm';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import ImageActions from 'components/ui/images/ImageActions';
import Image, { HeroImage } from 'components/ui/images/Images';
import { Clickable } from 'components/ui/shared/Button';
import { ImageSize, ImageType } from 'enums/imageType';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT } from 'styles/color';
import { DISABLED_INPUT_OPACITY } from 'styles/forms';
import { NEUTRAL_050 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export const PhotoInputContainer = styled.div<{ isAvatar?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 138px;
  width: 100%;

  /** TODO: Move border-radius configuration into Image component directly */
  ${HeroImage} {
    border-radius: 8px;
  }
  ${({ isAvatar }) =>
    isAvatar &&
    css`
      width: calc(50% - 5px);
    `}
`;

export const EmptyPhotoPlaceholder = styled(Clickable)<{ isAvatar?: boolean; isDisabled?: boolean }>`
  background: ${NEUTRAL_050};
  border-radius: 8px;
  border: 1px dashed ${BODY_TEXT};
  width: 100%;
  height: 100%;

  ${Label} {
    text-align: center;
    white-space: wrap;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: ${DISABLED_INPUT_OPACITY};
      cursor: not-allowed;
    `}
`;

export const AddPhotoIcon = styled(CirclePlusIcon)`
  width: 21px;
  height: 21px;
  color: ${BODY_TEXT};
`;

/** Configurable settings for PhotoInput */
export interface PhotoInputSettings {
  /** If this photo input is for avatars, the photo will be sized to reflect an avatar (square) */
  isAvatar?: boolean;
  /** Whether or not this photo input supports deleting a photo */
  canDelete?: boolean;
  /** The translation key for the label used in the placeholder when there is no photo selected */
  placeholderLabelTranslationKey?: string;
}

interface PhotoInputProps {
  /** Change callback used to proxy change event */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  /** Default value used to show image preview is applicable */
  defaultValue?: string | ArrayBuffer;
  /** `HTMLInputElement` attributes to configure the actual input */
  settings?: PhotoInputSettings & InputHTMLAttributes<HTMLInputElement>;
  /** Whether or not to show a client-side preview when selecting an image */
  showPreview?: boolean;
  /** Whether or not to clear the input after registering a new image change */
  clearOnChange?: boolean;
  /** Whether or not the onChange returns a value that is recognized by the UploadScalar type in graphql */
  isUploadScalar?: boolean;
  /** Testing ID to reference this step field input */
  testId?: string;
}

/**
 * Photo input to upload photos
 */
export const PhotoInput = (props: PhotoInputProps) => {
  const {
    onChange,
    defaultValue,
    settings = {},
    showPreview,
    clearOnChange,
    isUploadScalar,
    testId,
  } = useMemo(() => props, [props]);
  const [selectedImage, setSelectedImage] = useState(defaultValue);
  const isPreviewing = showPreview && !!selectedImage;

  const inputRef = useRef<HTMLInputElement>(null);

  const onPhotoSelected = useCallback(
    e => {
      if (!!inputRef.current && !!inputRef.current.files?.[0]) {
        setSelectedImage(URL.createObjectURL(inputRef.current.files[0]));
        onChange?.(
          isUploadScalar ? { currentTarget: { value: settings?.multiple ? e.target.files : e.target.files[0] } } : e
        );

        if (clearOnChange) {
          inputRef.current.value = '';
        }
      }
    },
    [onChange, setSelectedImage, inputRef, clearOnChange, isUploadScalar, settings]
  );

  const onRemovePhoto = useCallback(() => {
    onChange?.({ currentTarget: { value: undefined } } as any);
    setSelectedImage('');
    inputRef.current!.value = '';
  }, [onChange, inputRef]);

  return (
    <PhotoInputContainer isAvatar={settings?.isAvatar}>
      <input
        data-testid={testId}
        type="file"
        name="image-upload"
        accept="image/png,image/jpeg,image/webp"
        onChange={onPhotoSelected}
        css={{ display: 'none' }}
        ref={inputRef}
        {...settings}
      />
      {isPreviewing ? (
        <ImageActions
          onEdit={() => inputRef.current?.click()}
          onDelete={settings?.canDelete ? onRemovePhoto : undefined}
        >
          <Image src={selectedImage as string} size={ImageSize.HERO} type={ImageType.HERO} />
        </ImageActions>
      ) : (
        <EmptyPhotoPlaceholder onClick={() => inputRef.current?.click()}>
          <AddPhotoIcon />
          <Label>{t(settings?.placeholderLabelTranslationKey || 'add_photo_one')}</Label>
        </EmptyPhotoPlaceholder>
      )}
    </PhotoInputContainer>
  );
};

interface YouTubeVideoInputProps {
  /** Callback to upload the given video url */
  onUpload: (url: string) => void;
}

/**
 * Photo input to upload youtube videos
 */
export const YouTubeVideoInput = ({ onUpload }: YouTubeVideoInputProps) => {
  const [isAddVideoPromptOpen, setAddVideoPromptOpen] = useState(false);

  return (
    <>
      <PhotoInputContainer isAvatar={true}>
        <EmptyPhotoPlaceholder
          onClick={() => setAddVideoPromptOpen(true)}
          data-testid={ElementTestId.VIDEO_INPUT_PLACEHOLDER}
        >
          <AddPhotoIcon />
          <Label>{t('add_x', [t('video_one')])}</Label>
        </EmptyPhotoPlaceholder>
      </PhotoInputContainer>
      <FormDialog title={t('add_x', [t('video_one')])} isOpen={isAddVideoPromptOpen} isFixedToTop={true}>
        <AddYouTubeVideoForm
          onSubmit={url => {
            onUpload(url);
            setAddVideoPromptOpen(false);
          }}
          onCancel={() => setAddVideoPromptOpen(false)}
        />
      </FormDialog>
    </>
  );
};
