import { useMemo } from 'react';

import type { DocumentNode } from 'graphql';
import { cloneDeep, set } from 'lodash-es';

import { rooftopRetailBulkAdjustmentsCountQuery } from 'containers/rooftops/retailBulkAdjustments/RooftopRetailBulkAdjustmentsContainerQuery';
import { TabType } from 'enums/tabType';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type {
  RooftopRetailBulkAdjustmentsCountQuery,
  RooftopRetailBulkAdjustmentsCountQueryVariables,
} from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

/**
 * Query override used in the nested list view. Will run the provided query, as well as querying for the total
 * count of each bulk adjustment type (price, description, overlay, etc).
 *
 * @param query - The query for retrieving the bulk adjustments
 * @param config - The QueryConfig for the provided query
 */
export const useBulkAdjustmentQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const bulkAdjustmentListResponse = useQuery(query, config);
  const countResponse = useQuery<
    RooftopRetailBulkAdjustmentsCountQuery,
    RooftopRetailBulkAdjustmentsCountQueryVariables
  >(rooftopRetailBulkAdjustmentsCountQuery, {
    variables: { keyword: config.variables.keyword, rooftopId: config.variables.filter.rooftopId },
  });

  return useMemo(() => {
    const combinedResponse = {
      ...cloneDeep(bulkAdjustmentListResponse),
      // Set `isLoading` based on parallel fetches
      isLoading: bulkAdjustmentListResponse.isLoading || countResponse.isLoading,
      isLoaded: bulkAdjustmentListResponse.isLoaded && countResponse.isLoaded,
    };

    if (!countResponse.isLoading && countResponse.isLoaded) {
      for (const key of [
        TabType.BULK_ADJUSTMENT_DESCRIPTION,
        TabType.BULK_ADJUSTMENT_PRICING,
        TabType.BULK_ADJUSTMENT_OVERLAY,
        TabType.BULK_ADJUSTMENT_PROMOTION,
        TabType.BULK_ADJUSTMENT_DISCLAIMER,
      ]) {
        set(combinedResponse, `data.${key}Count`, countResponse.data[key]?.pageInfo?.totalEdges || 0);
      }
    }

    return combinedResponse;
  }, [bulkAdjustmentListResponse, countResponse]);
};
