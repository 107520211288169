import { gql } from '@apollo/client';

import { taskDetail } from 'store/api/graph/fragments/taskDetail';

export const taskModify = gql`
  mutation TaskModifyMutation(
    $id: ID!
    $assignedToId: ID
    $leadId: ID
    $dateDue: Date
    $inventoryItemId: ID
    $note: String
    $status: TaskStatus
    $tagIds: [ID!]
    $title: String
    $archived: Boolean
    $_clear: [TaskModifyParameter!]
  ) {
    data: taskModify(
      id: $id
      assignedToId: $assignedToId
      leadId: $leadId
      dateDue: $dateDue
      inventoryItemId: $inventoryItemId
      note: $note
      status: $status
      tagIds: $tagIds
      title: $title
      archived: $archived
      _clear: $_clear
    ) {
      ...TaskDetailFragment
    }
  }
  ${taskDetail}
`;

export const taskCreate = gql`
  mutation TaskCreateMutation(
    $rooftopId: ID
    $assignedToId: ID
    $leadId: ID
    $dateDue: Date
    $inventoryItemId: ID
    $note: String
    $tagIds: [ID!]
    $title: String
  ) {
    data: taskCreate(
      rooftopId: $rooftopId
      assignedToId: $assignedToId
      leadId: $leadId
      dateDue: $dateDue
      inventoryItemId: $inventoryItemId
      note: $note
      tagIds: $tagIds
      title: $title
    ) {
      ...TaskDetailFragment
    }
  }
  ${taskDetail}
`;
