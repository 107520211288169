import { useMemo } from 'react';

import styled from 'styled-components/macro';

import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItemContainer } from 'components/ui/layouts/ListItem';
import AttachmentListItem from 'components/ui/lists/AttachmentListItem';
import { ListItemType } from 'enums/listItemType';
import type { ConversationItemAttachment } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

import type { SectionProps } from './LinkedTab';

const Container = styled(LinkedSection)`
  ${ListItemContainer} {
    padding-right: 14px;
  }
`;

interface Props extends SectionProps {
  attachments: ConversationItemAttachment[];
}

const AttachmentsSection = ({ attachments = [] }: Props) => {
  const renderedItems = useMemo(
    () =>
      attachments.map(attachment => (
        <AttachmentListItem key={attachment.id} {...attachment} listItemType={ListItemType.SECTION_LIST} />
      )),
    [attachments]
  );

  return attachments.length > 0 ? (
    <Container>
      <LinkedSectionHeader prefixIcon={null} title={translate.t('attachments')} hasItems={true} />
      {renderedItems}
    </Container>
  ) : null;
};

export default AttachmentsSection;
