import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import LocationListItem from 'components/ui/lists/LocationListItem';
import UserListItem from 'components/ui/lists/UserListItem';
import { BuilderType } from 'enums/builderType';
import { Rooftop } from 'enums/columns/rooftop';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopModifyParameter } from 'store/api/graph/interfaces/types';

import { LocationInputFields, RooftopDetailsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [RooftopDetailsBuilderFields.LOGO]: {
    label: 'add_dealership_logo',
    groupType: StepFieldType.PHOTO,
    hasSeparator: true,
    tooltipContents: 'add_dealership_logo_tooltip',
    settings: {
      canDelete: true,
      placeholderLabelTranslationKey: 'add_logo',
    },
    clear: { field: RooftopModifyParameter._logo },
  },
  [RooftopDetailsBuilderFields.NAME]: {
    queryAlias: Rooftop.GROUP_NAME,
    label: 'name',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: RooftopModifyParameter._name },
  },
  [RooftopDetailsBuilderFields.GROUP_ID]: {
    label: 'group',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    queryAlias: Rooftop.GROUP_NAME,
    subStepAddConfig: SubStepAddConfigs[BuilderType.GROUP_CREATE],
  },
  [RooftopDetailsBuilderFields.EMAIL_SUBDOMAIN]: {
    label: 'email_subdomain',
    tooltipContents: 'email_subdomain_tooltip',
  },
  [RooftopDetailsBuilderFields.LOCALE]: {
    label: 'locale',
    options: 'metadata.mutation.rooftop.locale',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    tooltipContents: 'rooftop_locale_tooltip',
    clear: { field: RooftopModifyParameter._locale },
    hasSeparator: true,
  },
  [RooftopDetailsBuilderFields.WEBSITE]: {
    label: 'website_one',
    tooltipContents: 'rooftop_website_tooltip',
    clear: { field: RooftopModifyParameter._website },
  },
  [RooftopDetailsBuilderFields.WEBSITE_PROVIDER]: {
    label: 'website_provider',
    options: 'metadata.mutation.rooftop.websiteProvider',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    clear: { field: RooftopModifyParameter._websiteProvider },
    hasSeparator: true,
  },
  [RooftopDetailsBuilderFields.EMAIL_GENERAL]: {
    label: 'general_email',
    clear: { field: RooftopModifyParameter._emailGeneral },
  },
  [RooftopDetailsBuilderFields.EMAIL_BILLING]: {
    label: 'billing_email',
    clear: { field: RooftopModifyParameter._emailBilling },
  },
  [RooftopDetailsBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: RooftopModifyParameter._phoneNumber },
  },
  [RooftopDetailsBuilderFields.FAX_NUMBER]: {
    label: 'fax_number',
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: RooftopModifyParameter._faxNumber },
    hasSeparator: true,
  },
  [RooftopDetailsBuilderFields.LOCATION]: {
    label: 'address',
    queryAlias: [
      LocationInputFields.CITY,
      LocationInputFields.REGION_CODE,
      LocationInputFields.COUNTRY_CODE,
      LocationInputFields.ADDRESS,
      LocationInputFields.ZIP_CODE,
    ],
    groupType: StepFieldType.RENDER_OBJECT,
    subStep: [SubStepType.CUSTOM],
    renderElement: LocationListItem,
    hasSeparator: true,
    clear: { field: RooftopModifyParameter._location },
  },
  [RooftopDetailsBuilderFields.WARRANTY_TYPES]: {
    label: 'warranty_type',
    queryAlias: [Rooftop.WARRANTY_TYPES],
    groupType: StepFieldType.TAGS,
    subStep: [SubStepType.DEFAULT],
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    hasSeparator: true,
  },
  [RooftopDetailsBuilderFields.CARFAX_ID]: {
    label: 'carfax_id',
    groupType: StepFieldType.NUMBER,
    clear: { field: RooftopModifyParameter._carfaxId },
  },
  [RooftopDetailsBuilderFields.DEALERTRACK_ID]: {
    label: 'dealertrack_id',
    clear: { field: RooftopModifyParameter._dealertrackId },
  },
  [RooftopDetailsBuilderFields.DEALERTRACK_CONFIRM_ID]: {
    label: 'dealertrack_confirm_id',
    clear: { field: RooftopModifyParameter._dealertrackConfirmId },
    hasSeparator: true,
  },
  [RooftopDetailsBuilderFields.ACCOUNT_REP_ID]: {
    label: 'account_rep',
    queryAlias: Rooftop.ACCOUNT_REP_NAME,
    groupType: StepFieldType.RENDER_OBJECT,
    renderElement: UserListItem,
    subStep: [SubStepType.ASYNC],
    clear: { field: RooftopModifyParameter._accountRepId },
    hasSeparator: true,
  },
};

export default DetailFields;
