import { useCallback, useMemo } from 'react';

import { get, isEmpty } from 'lodash-es';

import DynamicImport from 'components/core/DynamicImport';
import RetailItemBuilders from 'components/sections/createModify/inventoryItems/retailItem/RetailItemBuilders';
import AppraisalsSection from 'components/sections/inventoryItems/tradeInItems/AppraisalsSection';
import AppraiserSection from 'components/sections/inventoryItems/tradeInItems/AppraiserSection';
import GuaranteedTradeValueSection from 'components/sections/inventoryItems/tradeInItems/GuaranteedTradeValueSection';
import UserAppraisalsSection from 'components/sections/inventoryItems/tradeInItems/UserAppraisalsSection';
import RooftopConfigurationsSection from 'components/sections/rooftops/configurations/RooftopConfigurationsSection';
import { getShoppingToolsTitle } from 'components/sections/rooftops/shoppingTools/utils';
import PanelsSection from 'components/ui/details/PanelsSection';
import { useRooftopIntegrationsCondensedListQuery } from 'containers/rooftops/integrations/RooftopIntegrationsContainerQuery';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type {
  CreditApplication,
  LeadActivity,
  PurchaseLeadActivityAttributes,
  RetailItemDetailFragment,
  TradeInAppraisalInternal,
  TradeInAppraisals,
  TradeInItem,
} from 'store/api/graph/interfaces/types';
import {
  AccessLevel,
  EntityType,
  IntegrationType,
  ResourceType,
  TradeInItemStatus,
} from 'store/api/graph/interfaces/types';
import type {
  LeadResponseType,
  RetailItemResponseType,
  RooftopResponseType,
  TradeInItemResponseType,
  UserResponseType,
} from 'store/api/graph/responses/responseTypes';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

import AttachmentsSection from './AttachmentsSection';
import ConversationSection from './ConversationSection';
import CreditApplicationsSection from './creditApplications/CreditApplicationsSection';
import type { LinkedTabSectionProps } from './LinkedTab';
import PurchasedBySection from './PurchasedBySection';
import RooftopSection from './RooftopSection';

const AppointmentSection = DynamicImport(() => import('components/sections/shared/linkedTab/AppointmentSection'));
const InventoryItemsSection = DynamicImport(() => import('components/sections/shared/linkedTab/InventoryItemsSection'));
const LeadSection = DynamicImport(() => import('components/sections/shared/linkedTab/LeadSection'));
const RetailBulkAdjustmentSection = DynamicImport(
  () => import('components/sections/shared/linkedTab/RetailBulkAdjustmentSection')
);
const RetailIntegrationSection = DynamicImport(
  () => import('components/sections/shared/linkedTab/RetailIntegrationSection')
);
const RooftopShoppingToolsSection = DynamicImport(
  () => import('components/sections/shared/linkedTab/RooftopShoppingToolsSection')
);
const TaskSection = DynamicImport(() => import('components/sections/shared/linkedTab/TaskSection'));
const UserSection = DynamicImport(() => import('components/sections/shared/linkedTab/UserSection'));
const VehiclePurchasedSection = DynamicImport(
  () => import('components/sections/shared/linkedTab/VehiclePurchasedSection')
);
const WebsiteIntegrationSection = DynamicImport(
  () => import('components/sections/shared/linkedTab/WebsiteIntegrationSection')
);

const { t } = translate;

export const MAX_LINKED_LIST_ITEMS = 1;

export const TradeInAppraiserLinkedTabSection = ({ itemTabProps: { item }, settings = {} }: LinkedTabSectionProps) => {
  const { isEditAllowed } = settings;
  const { toggleTier } = useCreateModify();
  const { id, pendingAppraisals, status, rooftop } = item as TradeInItemResponseType;
  const canInviteAppraiser =
    ![TradeInItemStatus.DRAFT, TradeInItemStatus.COMPLETED_DEAL].includes(status) && isEditAllowed;

  const onAdd = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_INVITE_APPRAISERS,
        title: t('invite_appraisers'),
        nextButtonLabel: t('invite'),
        isCreating: true,
        seededData: {
          id,
          rooftop,
        },
        activeField: 'contactId',
      }),
    [id, toggleTier, rooftop]
  );

  return canInviteAppraiser || pendingAppraisals.length > 0 ? (
    <AppraiserSection
      appraisers={pendingAppraisals as Extract<TradeInItemResponseType, TradeInAppraisals>}
      onAdd={authorizedCallback({ cb: onAdd, isAuth: !!canInviteAppraiser })}
    />
  ) : null;
};

export const TradeInAppraisalsLinkedTabSection = ({ itemTabProps: { item } }: LinkedTabSectionProps) => {
  const { appraisals } = item as TradeInItemResponseType;
  const { hasPermissions } = useUser();

  return hasPermissions([{ resource: ResourceType.TRADE_IN_APPRAISALS, level: AccessLevel.BASIC }]) ? (
    <AppraisalsSection appraisals={appraisals as Extract<TradeInItemResponseType, TradeInAppraisals>} />
  ) : null;
};

export const TradeInUsersAppraisalsLinkedTabSection = ({
  itemTabProps: { item },
  settings = {},
}: LinkedTabSectionProps) => {
  const { isEditAllowed } = settings;
  const { toggleTier } = useCreateModify();
  const { user } = useUser();
  const { id, appraisals } = item as TradeInItem;
  const userAppraisal = (appraisals?.list as TradeInAppraisalInternal[])?.find(
    appraisal => appraisal?.user?.id === user.id
  );

  const onAdd = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_SUBMIT_APPRAISAL,
        title: t('submit_your_appraisal'),
        nextButtonLabel: t('submit'),
        isCreating: true,
        seededData: {
          id,
        },
        activeField: 'amount',
      }),
    [id, toggleTier]
  );

  const onEdit = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_SUBMIT_APPRAISAL,
        title: t('update_your_appraisal'),
        nextButtonLabel: t('update'),
        isCreating: false,
        data: {
          ...userAppraisal,
        },
        itemId: userAppraisal?.id,
      }),
    [toggleTier, userAppraisal]
  );

  return isEditAllowed || !!userAppraisal ? (
    <UserAppraisalsSection
      appraisal={userAppraisal as Extract<TradeInItemResponseType, TradeInAppraisals>}
      onAdd={authorizedCallback({ cb: userAppraisal ? onEdit : onAdd, isAuth: !!isEditAllowed })}
    />
  ) : null;
};

export const TradeInGuaranteedValueLinkedTabSection = ({
  itemTabProps: { item },
  settings = {},
}: LinkedTabSectionProps) => {
  const { isEditAllowed } = settings;
  const { toggleTier } = useCreateModify();
  const { id, guaranteedValue, leadActivityPartial, rooftop } = item as TradeInItemResponseType;

  const onAddTradeValue = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_GUARANTEED_TRADE,
        title: t('send_trade_value'),
        nextButtonLabel: t('submit'),
        isCreating: true,
        seededData: {
          tradeInItemId: id,
        },
        activeField: 'amount',
      }),
    [id, toggleTier]
  );
  const onModifyTradeValue = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_GUARANTEED_TRADE,
        title: t('send_trade_value'),
        nextButtonLabel: t('update'),
        isCreating: false,
        seededData: {
          id,
          guaranteedValue,
        },
        activeField: 'amount',
      }),
    [guaranteedValue, id, toggleTier]
  );

  return (
    <GuaranteedTradeValueSection
      rooftop={rooftop}
      leadActivityPartial={leadActivityPartial}
      value={guaranteedValue}
      onEdit={authorizedCallback({
        cb: guaranteedValue ? onModifyTradeValue : onAddTradeValue,
        isAuth: !!isEditAllowed,
      })}
    />
  );
};

export const InventoryItemsLinkedTabSection = ({
  itemTabProps: { item, metadata, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const { isEditAllowed, fieldName, onAdd, placeholderTitle, isList, sectionTitle, isReadOnly, testId } = settings;
  const inventoryItems = get(item, fieldName);

  // TODO: Make onAdd support null values so that a section in a linked tab cannot have items added to it
  const onAddCallback = useCallback(() => {
    if (onAdd) {
      onAdd();
    } else {
      onEdit(fieldName, t(builderTitle));
    }
  }, [fieldName, onEdit, builderTitle, onAdd]);

  /*
   *  Trade-in items are passed to InventoryItemSection as an array, even if there is only one item,
   * place it in an array.
   */
  const inventory = inventoryItems ? (Array.isArray(inventoryItems) && inventoryItems) || [inventoryItems] : undefined;

  return (
    <InventoryItemsSection
      item={item}
      sectionTitle={sectionTitle}
      isList={isList}
      placeholderTitle={placeholderTitle}
      // TODO [#1387]: Perform array logic internally in InventoryItemsSection
      items={inventory as [TradeInItemResponseType | RetailItemResponseType]}
      metadata={metadata}
      isNested={isNested}
      testId={testId}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !isReadOnly && !!isEditAllowed })}
    />
  );
};

export const LeadLinkedTabSection = ({
  itemTabProps: { item, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const {
    isEditAllowed,
    fieldName,
    isInteractive = true,
    sectionTitle = 'lead_one',
    onAdd,
    isReadOnly,
    isList,
  } = settings;
  const onAddCallback = useCallback(() => {
    if (onAdd) {
      onAdd();
    } else {
      onEdit(fieldName, t(builderTitle));
    }
  }, [fieldName, onEdit, builderTitle, onAdd]);

  return !isReadOnly || !isEmpty(item[fieldName]) ? (
    <LeadSection
      sectionTitle={t(sectionTitle)}
      leads={[item[fieldName]].flat().filter(Boolean)}
      isInteractive={isInteractive}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !isReadOnly && !!isEditAllowed })}
      isNested={isNested}
      isList={isList}
    />
  ) : null;
};

export const AppointmentLinkedTabSection = ({
  itemTabProps: { item, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const {
    isEditAllowed,
    fieldName,
    isInteractive = true,
    isReadOnly,
    sectionTitle = '',
    onAdd,
    isList = false,
  } = settings;
  const onAddCallback = useCallback(() => {
    if (onAdd) {
      onAdd();
    } else {
      onEdit(fieldName, translate.t(builderTitle));
    }
  }, [fieldName, onEdit, builderTitle, onAdd]);

  return !isReadOnly || !isEmpty(item[fieldName]) ? (
    <AppointmentSection
      sectionTitle={translate.tPlural(sectionTitle, item[fieldName]?.length)}
      isInteractive={isInteractive}
      isNested={isNested}
      appointments={item[fieldName]}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !!isEditAllowed })}
      isList={isList}
    />
  ) : null;
};

export const TasksLinkedTabSection = ({
  itemTabProps: { item, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const {
    isEditAllowed,
    fieldName,
    isReadOnly,
    isInteractive = true,
    sectionTitle = 'tasks_other',
    onAdd,
    isList = false,
  } = settings;
  const onAddCallback = useCallback(() => {
    if (onAdd) {
      onAdd();
    } else {
      onEdit(fieldName, t(builderTitle));
    }
  }, [fieldName, onEdit, builderTitle, onAdd]);

  return !isReadOnly || !isEmpty(item[fieldName]) ? (
    <TaskSection
      sectionTitle={t(sectionTitle)}
      isInteractive={isInteractive}
      isNested={isNested}
      tasks={item[fieldName]}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !!isEditAllowed })}
      isList={isList}
    />
  ) : null;
};

export const UserLinkedTabSection = ({
  itemTabProps: { item, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const { isEditAllowed, fieldName, sectionTitle, isReadOnly, isList, showAll, testId } = settings;
  const onAdd = useCallback(() => onEdit(fieldName, t(builderTitle)), [builderTitle, fieldName, onEdit]);
  const users: UserResponseType | UserResponseType[] = get(item, fieldName);
  const title = sectionTitle || (users ? 'assigned_to' : 'assign_user');

  const sortedUsers = [users]
    .flat()
    .sort((a, b) => {
      if (a?.active && !b?.active) {
        return -1;
      }
      if (!a?.active && b?.active) {
        return 1;
      }
      return 0;
    })
    .filter(Boolean);

  return !isReadOnly || !isEmpty(users) ? (
    <UserSection
      isNested={isNested}
      sectionTitle={t(title)}
      users={sortedUsers}
      isList={isList}
      onAdd={authorizedCallback({ cb: onAdd, isAuth: !isReadOnly && !!isEditAllowed })}
      showAll={showAll}
      testId={testId}
    />
  ) : null;
};

export const PanelsLinkedTabSection = ({ itemTabProps: { isNested }, settings = {} }: LinkedTabSectionProps) => (
  <PanelsSection panels={settings?.panels} isLinkedList={settings?.isLinkedList} isNested={isNested} />
);

export const RooftopLinkedTabSection = ({ itemTabProps: { item, isNested }, settings = {} }: LinkedTabSectionProps) => {
  const { sectionTitle = 'rooftop', fieldName, isReadOnly, isInteractive, testId } = settings;
  const rooftop = item[fieldName] || item.rooftop || item.rooftopName;

  return !isReadOnly || !isEmpty(rooftop) ? (
    <RooftopSection
      isNested={isNested}
      sectionTitle={t(sectionTitle)}
      rooftop={[rooftop].flat().filter(Boolean)}
      isInteractive={isInteractive}
      testId={testId}
    />
  ) : null;
};

export const RooftopShoppingToolsLinkedTabSection = ({
  itemTabProps: { item, isNested },
  settings = {},
}: LinkedTabSectionProps) => {
  const { toggleTier } = useCreateModify();
  const nestedViewContext = useNestedView();
  const { flags } = useFeatureFlags();

  const shoppingToolsTitle = getShoppingToolsTitle({
    isBuildAndPriceFeatureEnabled: isFeatureEnabledForRooftop({
      rooftop: item,
      feature: FeatureBundleSet.RETAIL_BUILD_AND_PRICE,
      featureFlagOn: flags.showroomConfigurationEnabled,
    }),
    isShowroomFeatureEnabled: isFeatureEnabledForRooftop({
      rooftop: item,
      feature: FeatureBundleSet.RETAIL_SHOWROOM,
      featureFlagOn: flags.showroomConfigurationEnabled,
    }),
  });

  const {
    sectionTitle = `${shoppingToolsTitle} ${t('configuration_one')}`,
    fieldName,
    isReadOnly,
    isEditAllowed,
    testId,
  } = settings;
  const configuration = item[fieldName];
  const rooftop = item as RooftopResponseType;

  const onAdd = () => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.SHOPPING_TOOLS_MODIFY,
      entityType: CustomEntity.ROOFTOP_SHOPPING_TOOLS,
      isCreating: true,
      title: t('create_x', [shoppingToolsTitle]),
      itemId: rooftop.id,
      seededData: { id: rooftop.id },
      onStepSave: async (_, data) => {
        if (!data.shoppingToolsConfiguration) {
          nestedViewContext.close();
        }
      },
    });
  };

  return (
    <RooftopShoppingToolsSection
      isNested={isNested}
      configuration={configuration ? { ...configuration, id: item.id, bundle: item.bundle } : undefined}
      onAdd={authorizedCallback({ cb: onAdd, isAuth: !isReadOnly && !!isEditAllowed })}
      sectionTitle={t(sectionTitle)}
      testId={testId}
    />
  );
};

export const AttachmentsLinkedTabSection = ({ itemTabProps: { isNested }, settings = {} }: LinkedTabSectionProps) => (
  <AttachmentsSection attachments={settings?.attachments} isNested={isNested} />
);

// TODO [#2113]: Add subscription support to LinkedTabSection
export const ConversationLinkedTabSection = ({
  itemTabProps: { item, onEdit, isNested },
  settings = {},
  builderTitle = '',
}: LinkedTabSectionProps) => {
  const { isEditAllowed, sectionTitle = 'conversation', fieldName, isReadOnly, isList } = settings;
  const onAdd = useCallback(() => onEdit(fieldName, t(builderTitle)), [builderTitle, fieldName, onEdit]);
  const conversation = get(item, fieldName);

  return !isReadOnly || !isEmpty(conversation) ? (
    <ConversationSection
      isNested={isNested}
      sectionTitle={t(sectionTitle)}
      conversation={conversation}
      isList={isList}
      onAdd={authorizedCallback({ cb: onAdd, isAuth: !isReadOnly && !!isEditAllowed })}
    />
  ) : null;
};

export const RooftopConfigurationsLinkedTabSection = ({ itemTabProps: { item, isNested } }: LinkedTabSectionProps) => (
  <RooftopConfigurationsSection isNested={isNested} rooftop={item} />
);

export const RetailIntegrationLinkedTabSection = ({
  itemTabProps: {
    item: { integrations },
    isNested,
  },
}: LinkedTabSectionProps) => {
  const { hasPermissions } = useUser();
  const importIntegrations = useMemo<RetailItemDetailFragment['integrations']>(
    () =>
      integrations?.filter(
        ({ rooftopIntegration }) => rooftopIntegration.integration.type === IntegrationType.INVENTORY_IMPORT
      ) || [],
    [integrations]
  );

  const exportIntegrations = useMemo<RetailItemDetailFragment['integrations']>(
    () =>
      integrations?.filter(
        ({ rooftopIntegration }) => rooftopIntegration.integration.type === IntegrationType.INVENTORY_EXPORT
      ) || [],
    [integrations]
  );

  const isInteractive = hasPermissions([{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }]);

  return (
    <>
      {importIntegrations.length > 0 && (
        <RetailIntegrationSection
          isNested={isNested}
          integrations={importIntegrations}
          sectionTitle={t('imported_from')}
          isInteractive={isInteractive}
        />
      )}
      {exportIntegrations.length > 0 && (
        <RetailIntegrationSection
          isNested={isNested}
          integrations={exportIntegrations}
          sectionTitle={t('exported_to')}
          isInteractive={isInteractive}
        />
      )}
    </>
  );
};

export const RetailBulkAdjustmentLinkedTabSection = ({
  itemTabProps: { item, isNested },
  settings = {},
}: LinkedTabSectionProps) => {
  const { toggleTier } = useCreateModify();
  const { isEditAllowed } = settings;

  const adjustments = get(item, InventoryItem.BULK_ADJUSTMENTS)?.filter(adjustment => !!adjustment.enabled);

  const onModifyBulkAdjustments = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.RETAIL_ITEMS_MODIFY,
        entityType: EntityType.RETAIL_ITEM,
        isCreating: false,
        title: t('modify_x', [item?.typeName]),
        itemId: item?.id,
        activeStep: RetailItemBuilders[BuilderType.RETAIL_ITEMS_MODIFY].steps.find(
          step => step.id === 'BULK_ADJUSTMENTS'
        ),
      }),
    [item, toggleTier]
  );

  return (
    <RetailBulkAdjustmentSection
      isInteractive
      adjustments={adjustments}
      isNested={isNested}
      rooftopId={item?.rooftop?.id}
      sectionTitle={t('bulk_adjustment_other')}
      onAdd={authorizedCallback({ cb: onModifyBulkAdjustments, isAuth: !!isEditAllowed })}
    />
  );
};

type PurchaseByLinkedTabSectionProps = Omit<LinkedTabSectionProps, 'sectionTitle' | 'isList'>;

export const PurchasedByLinkedTabSection = ({
  itemTabProps: { item, isNested },
  settings = {},
}: PurchaseByLinkedTabSectionProps) => {
  const { toggleTier } = useCreateModify();
  const { isEditAllowed, fieldName, isInteractive = true, isReadOnly } = settings;

  const purchaseActivity = get(item, fieldName) as LeadActivity;

  const onAddCallback = useCallback(() => {
    if (purchaseActivity) {
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.PURCHASE_ACTIVITY_MODIFY,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('modify_purchase_activity'),
        itemId: purchaseActivity?.id,
      });
    } else {
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.PURCHASE_ACTIVITY_CREATE,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('log_purchase_activity'),
        isCreating: true,
        seededData: {
          inventoryItemName: item,
          rooftopName: item?.rooftop,
        },
      });
    }
  }, [item, purchaseActivity, toggleTier]);

  return !isReadOnly || !isEmpty(purchaseActivity) ? (
    <PurchasedBySection
      purchasedBy={purchaseActivity?.lead}
      isInteractive={isInteractive}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !isReadOnly && !!isEditAllowed })}
      isNested={isNested}
    />
  ) : null;
};

type VehiclePurchasedLinkedTabSectionProps = Omit<LinkedTabSectionProps, 'sectionTitle' | 'isList'>;

export const VehiclePurchasedLinkedTabSection = ({
  itemTabProps: { item, isNested, metadata },
  settings = {},
}: VehiclePurchasedLinkedTabSectionProps) => {
  const { toggleTier } = useCreateModify();
  const { isEditAllowed, fieldName, isInteractive = true, isReadOnly } = settings;

  const purchaseActivities = get(item, fieldName) as LeadActivity[];
  const retailItemsPurchased = purchaseActivities?.map(
    pa => (pa.attributes as PurchaseLeadActivityAttributes)?.retailItemPurchased
  );

  const onAddCallback = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.PURCHASE_ACTIVITY_CREATE,
        entityType: EntityType.LEAD_ACTIVITY,
        title: t('log_purchase_activity'),
        isCreating: true,
        seededData: {
          rooftopName: item.rooftopName,
          leadName: item,
        },
      }),
    [item, toggleTier]
  );

  return !isReadOnly || !isEmpty(purchaseActivities) ? (
    <VehiclePurchasedSection
      retailItemsPurchased={retailItemsPurchased}
      isInteractive={isInteractive}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !isReadOnly && !!isEditAllowed })}
      isNested={isNested}
      metadata={metadata}
    />
  ) : null;
};

type CreditApplicationLinkedTabSectionProps = Omit<
  LinkedTabSectionProps,
  'sectionTitle' | 'isList' | 'fieldName' | 'isInteractive'
>;

export const CreditApplicationLinkedTabSection = ({
  itemTabProps: { item, isNested },
  settings = {},
}: CreditApplicationLinkedTabSectionProps) => {
  const { toggleTier } = useCreateModify();
  const { isEditAllowed, isReadOnly } = settings;

  const lead = item as LeadResponseType;
  const rooftop = lead.rooftopName as RooftopResponseType;
  const creditApplications = lead.creditApplications as CreditApplication[];

  const onAddCallback = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.CREDIT_APPLICATION_CREATE,
        entityType: EntityType.FINANCE_APPLICATION,
        title: t('submit_to_lender'),
        isCreating: true,
        seededData: {
          leadName: lead,
        },
      }),
    [lead, toggleTier]
  );

  return !isReadOnly || !isEmpty(creditApplications) ? (
    <CreditApplicationsSection
      creditApplications={creditApplications}
      onAdd={authorizedCallback({ cb: onAddCallback, isAuth: !isReadOnly && !!isEditAllowed })}
      isNested={isNested}
      featureEligibility={{
        leadHasName: !!lead.firstName && !!lead.lastName,
        rooftopHasDealerTrackInfo: !!rooftop.dealertrackId && !!rooftop.dealertrackConfirmId,
      }}
    />
  ) : null;
};

export const WebsiteIntegrationLinkedTabSection = ({
  itemTabProps: { item, isNested },
  settings,
}: LinkedTabSectionProps) => {
  const websiteId = item.id;
  const { edges, pageInfo } =
    useRooftopIntegrationsCondensedListQuery({
      filter: { websiteId, integrationType: [IntegrationType.CPP, IntegrationType.OEM_ACCOUNT_EXPORT] },
    }) || {};

  if (!edges?.length) {
    return null;
  }

  return (
    <WebsiteIntegrationSection
      websiteId={websiteId}
      isNested={isNested}
      integrations={edges.map(({ node }) => ({ ...node }))}
      totalCount={Number(pageInfo?.totalEdges)}
      sectionTitle={settings?.sectionTitle ? t(settings?.sectionTitle) : ''}
    />
  );
};
