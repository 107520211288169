import type { ReactNode } from 'react';
import { useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import { DIVIDER } from 'styles/color';
import { NEUTRAL_0 } from 'styles/tokens';
import { Z_INDEX_5 } from 'styles/z-index';

import Portal from './Portal';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: ${Z_INDEX_5};
`;

const ContainerInner = styled.div<{ isOpen: boolean }>`
  background: ${NEUTRAL_0};
  padding: 24px;
  display: flex;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${DIVIDER};
  transition: 0.3s transform;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translateY(100%);
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
    `};
`;

export type DrawerProps = {
  /** Controls the visibility of the drawer */
  isOpen: boolean;
  /** Optional id for the element to portal the drawer into. Set to null to opt out of portalling. */
  portalRootId?: string | null;
  /** The content to render inside the drawer */
  children?: ReactNode;
};

const Drawer = ({ isOpen = false, portalRootId = 'drawer-root', children }: DrawerProps) => {
  const Render = useMemo(
    () => (
      <Container>
        <ContainerInner isOpen={isOpen}>{children}</ContainerInner>
      </Container>
    ),
    [children, isOpen]
  );

  return portalRootId ? <Portal rootId={portalRootId}>{Render}</Portal> : Render;
};

export default Drawer;
