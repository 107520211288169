import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import { contactCreate, contactModify } from 'components/sections/createModify/contacts/ContactCreateModifyQueries';
import ContactQueryMethods from 'components/sections/createModify/contacts/ContactQueryMethods';
import ContactDetailFields from 'components/sections/createModify/contacts/steps/DetailsFields';
import ContactDetailStep from 'components/sections/createModify/contacts/steps/DetailsStep';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const ContactBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.CONTACT_CREATE]: {
    refetchQueries: ContactQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.CONTACTS, level: AccessLevel.FULL }],
    resourceType: ResourceType.CONTACTS,
    steps: [
      {
        id: 'CONTACT_CREATE',
        step: ContactDetailStep,
        stepName: 'create_contact',
        fields: ContactDetailFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      create: contactCreate,
    },
  },
  [BuilderType.CONTACT_MODIFY]: {
    refetchQueries: ContactQueryMethods.refetchQueries,
    getData: ContactQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.CONTACTS, level: AccessLevel.FULL }],
    resourceType: ResourceType.CONTACTS,
    steps: [
      {
        id: 'CONTACT_MODIFY',
        step: ContactDetailStep,
        stepName: 'modify_contact',
        fields: ContactDetailFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      modify: contactModify,
    },
  },
};

export default ContactBuilders;
