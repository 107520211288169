import { useContext } from 'react';

import { NestedViewContext } from 'contexts/NestedViewContext';

export const useNestedView = () => {
  const context = useContext(NestedViewContext);

  if (context === undefined) {
    throw new Error('useNestedView must be used within a NestedViewProvider');
  }
  return context;
};
