import styled, { css } from 'styled-components/macro';

import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import SemiBoldText from 'components/core/typography/SemiBoldText';
import Text from 'components/core/typography/Text';
import AppleIcon from 'components/ui/icons/AppleIcon';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import GoogleIcon from 'components/ui/icons/GoogleIcon';
import { DIVIDER } from 'styles/color';
import { SPACE_12 } from 'styles/spacing';
import { BLUE_500, NEUTRAL_0, NEUTRAL_800 } from 'styles/tokens';
import {
  FONT_SIZE_14,
  FONT_SIZE_8,
  LINE_HEIGHT_CONDENSED_ULTRA,
  LINE_HEIGHT_DEFAULT,
  LINE_HEIGHT_MINIMUM,
} from 'styles/typography';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const GatedViewContainer = styled.div`
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 171px 56px;
  background: ${DIVIDER};
  height: 100vh;

  ${PrimaryText} {
    font-size: ${FONT_SIZE_14};
    padding-bottom: 23px;
    color: ${NEUTRAL_800};
  }

  ${SecondaryText} {
    max-width: 230px;
    padding-bottom: 34px;
    line-height: ${LINE_HEIGHT_DEFAULT};
  }

  > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const LogoContainer = styled.div`
  background: ${BLUE_500};
  color: ${NEUTRAL_0};
  height: 71px;
  width: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 36px;
`;

const DownloadLink = styled(WrapLink)`
  background: ${NEUTRAL_800};
  height: 40px;
  display: flex;
  color: ${NEUTRAL_0};
  padding: 8px 12px;
  border-radius: 9px;
  align-items: center;

  &:first-child {
    margin-right: 10px;
  }

  svg {
    margin-right: ${SPACE_12};
  }

  ${SemiBoldText}, ${Text} {
    color: ${NEUTRAL_0};
    overflow: visible;
    line-height: ${LINE_HEIGHT_MINIMUM};

    /* Selecting the Text cta above the store name */
    &:first-child {
      line-height: ${LINE_HEIGHT_CONDENSED_ULTRA};
      padding-left: 0.5px;

      /* Special superscript font cause rem is not shrinking */
      font-size: 8.5px;
    }
  }

  > * {
    flex-shrink: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: end;
    height: 100%;

    > * {
      flex-shrink: 0;
    }
  }
`;

export const MobileGate = () => (
  <GatedViewContainer>
    <LogoContainer>
      <EDealerIcon width={32} height={28} />
    </LogoContainer>
    <PrimaryText>{t('download_the_app')}</PrimaryText>
    <SecondaryText css={'white-space: normal'}>{t('download_the_app_details')}</SecondaryText>
    <div css="display: flex;">
      <DownloadLink css="width: 120px;" as="a" href={process.env.REACT_APP_IOS_URL || ''} target="_blank">
        <AppleIcon />
        <div>
          <Text
            css={css`
              font-size: ${FONT_SIZE_8};
            `}
          >
            {t('get_it_on_the')}
          </Text>
          <SemiBoldText>{t('app_store')}</SemiBoldText>
        </div>
      </DownloadLink>
      <DownloadLink css="width: 134px;" as="a" href={process.env.REACT_APP_ANDROID_URL || ''} target="_blank">
        <GoogleIcon />
        <div>
          <Text
            css={css`
              font-size: ${FONT_SIZE_8};
            `}
          >
            {t('get_it_on')}
          </Text>
          <SemiBoldText>{t('google_play')}</SemiBoldText>
        </div>
      </DownloadLink>
    </div>
  </GatedViewContainer>
);
