import type { DateTimeOptions } from 'luxon';
import { DateTime, Info } from 'luxon';

import LoggingService from 'components/core/logging/LoggingService';
import { DateTimeFormat } from 'enums/dateTimeFormat';

import { localeStorage } from './storage/intl';

const {
  DEFAULT_TIME_ZONE,
  DATE_TIME_FORMAT,
  DAY_DATE_YEAR_FORMAT,
  TIME_FORMAT,
  YEAR_MONTH_DAY_SLASH_FORMAT,
  DATE_YEAR_FORMAT,
} = DateTimeFormat;

export const getCurrentLocalDateTime = () => DateTime.local();

const isJSDate = (jsDate: string | Date): jsDate is Date => Object.prototype.toString.call(jsDate) === '[object Date]';

/**
 * Gets the current time zone of user.
 * Time zone needs to be a valid IANA zone otherwise defaults to UTC.
 */
export const getTimeZone = (date?: any, defaultTimeZone = DEFAULT_TIME_ZONE) => {
  const currentDateTime = date || getCurrentLocalDateTime();
  const zoneName = currentDateTime.zoneName;

  if (Info.isValidIANAZone(zoneName)) {
    return zoneName;
  } else {
    LoggingService.warn({
      message: `DateUtils.ts: Error retrieving current timeZone, defaulting to: ${defaultTimeZone}`,
    });
    return defaultTimeZone;
  }
};

/**
 * Get DateTime object in the format 'uuuu-MM-dd'T'HH:mm:ss.SSS' (ISO-8601)
 * Should be used for everything date/time related, unless a Javascript Date Object
 * is needed, whererby `getJSDate` should be used.
 *
 * @param date JS Date, ISO dateTime or custom format date/dateTime
 * @param fromFormat Format of custom format date/dateTime
 */
export function getDateTime(): DateTime<true>;
export function getDateTime(
  date: string | null | undefined,
  fromFormat?: string,
  options?: DateTimeOptions
): DateTime<true> | null;
export function getDateTime(date: Date | null): DateTime<true> | null;
export function getDateTime(date?: string | Date | null, fromFormat?: string, options?: DateTimeOptions) {
  if (!date) {
    return getCurrentLocalDateTime();
  }

  let dateTime: DateTime<true> | DateTime<false>;
  if (typeof date === 'string' && fromFormat) {
    dateTime = DateTime.fromFormat(date, fromFormat, options);
  } else {
    dateTime = isJSDate(date) ? DateTime.fromJSDate(date) : DateTime.fromISO(date, options);
  }
  return dateTime.isValid ? dateTime : null;
}

/**
 * Get JS Date object in the format 'uuuu-MM-dd'T'HH:mm:ss.SSS' (ISO-8601)
 * Used specifically as a replacement for direct calls to a new Javascript Date Object
 * e.g. <DayPicker /> api only works with a JS Date object.
 *
 * @param date ISO dateTime
 */
export const getJSDate = (date?: string) => {
  const dateTime = date ? DateTime.fromISO(date) : getCurrentLocalDateTime();
  return dateTime.isValid ? dateTime.toJSDate() : null;
};

/**
 * Whether or not the specified date has the same 'day' as today's date.
 * e.g. '2019-06-24T12:34:25.000' -> true
 */
export const isToday = (date: string): boolean => {
  const currentDateTime = getDateTime(date);
  return !!currentDateTime && getDateTime().hasSame(currentDateTime, 'day', {});
};

/**
 * Get a formatted DateTime string from a custom supported format, otherwise return
 * the default server supported DateTime string 'uuuu-MM-dd'T'HH:mm:ss.SSS' (ISO-8601).
 * @param date JS Date or ISO dateTime
 * @param format `DateTimeFormat` of the date to return
 * @param isLong Return long variation of the format.
 *
 * TODO: Reference the formats of `DateTimeFormat` directly in method.
 */
export const getFormattedDateTimeString = (
  date: string | DateTime<true> | null,
  format?: DateTimeFormat,
  isLong?: boolean
) => {
  const selectedDate = typeof date === 'string' ? getDateTime(date) : date;

  let formattedDateTimeString: string | undefined;
  switch (format) {
    case DAY_DATE_YEAR_FORMAT: {
      formattedDateTimeString = selectedDate?.toFormat(`EEE${isLong ? 'E' : ''}, LLL dd, yyyy`);
      break;
    }

    case DATE_TIME_FORMAT: {
      formattedDateTimeString = selectedDate?.toFormat(`LLL${isLong ? 'L' : ''} dd yyyy, h:mm a`);
      break;
    }

    case undefined:
    case null: {
      // The time zone is always specified in the Time-Zone header.
      formattedDateTimeString = selectedDate?.toISO({ includeOffset: false });
      break;
    }

    default: {
      formattedDateTimeString = selectedDate?.toFormat(format);
      break;
    }
  }
  return formattedDateTimeString;
};

/**
 * Returns 'yesterday'/'today'/'tomorrow' if date is recent, null otherwise
 * @param date an ISO date string
 */
export const getRecentDateName = (date: string): string | null => {
  const unit = 'days';
  const dateTime = getDateTime(date);
  const today = getDateTime();
  const tomorrow = today.plus({ days: 1 });
  const yesterday = today.minus({ days: 1 });

  // Check is provided date is within the range of either yesterday, today or tomorrow
  const isRecent = [today, tomorrow, yesterday].some(day => dateTime?.hasSame(day, 'day'));

  return isRecent && dateTime ? dateTime?.setLocale(localeStorage.get() || '')?.toRelativeCalendar({ unit }) : null;
};

/**
 * Returns XX:XX PM/AM if date is today, 'Yesterday' or 'YYYY/MM/DD' if it's not, null otherwise
 * @param date an ISO date string
 */
export const getRecentDateTime = (date: string): string | null => {
  const dateTime = getDateTime(date);

  if (isToday(date)) {
    // XX:XX PM/AM
    return dateTime?.toLocaleString(DateTime.TIME_SIMPLE) || null;
  } else if (dateTime?.hasSame(getDateTime().minus({ days: 1 }), 'day')) {
    // Yesterday
    return getRecentDateName(date);
  }

  // YYYY/MM/DD
  return getFormattedDateTimeString(date, YEAR_MONTH_DAY_SLASH_FORMAT) || null;
};

/**
 * Returns today, h:mm a, LLL dd, h:mm a or EEEE, h:mm a
 * @param date an ISO date string
 */
/**
 * Gets a detailed string representation of a DateTime relative to now
 * e.g. today -> Today - 4:20 PM
 *      dec 25th -> Dec 25, 2021 - 4:20 PM
 *
 * @param date an ISO date string
 */
export const getRelativeDateFormat = (date: string): string => {
  const day = getRecentDateName(date) || getFormattedDateTimeString(date, DATE_YEAR_FORMAT);
  const time = getFormattedDateTimeString(date, TIME_FORMAT);

  return `${day} - ${time}`;
};
