import { memo } from 'react';
import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { BadgeColors, Badges } from 'components/ui/shared/Badge';
import { RooftopBadges } from 'components/ui/shared/RooftopBadges';
import { ImageSize, ImageType } from 'enums/imageType';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { BODY_TEXT_TERTIARY } from 'styles/color';
import { formatFullName, formatTextWithBullets } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends UserResponseType, ListItemProps {}

const UserListItem = ({
  suffixIcon,
  listItemType,
  roleName,
  groupName,
  rooftops,
  avatar,
  deleted,
  active,
  ...user
}: Props) => {
  const roles = formatTextWithBullets([roleName?.name?.value, groupName?.name?.value]);
  return (
    <ListItem suffixIcon={deleted ? undefined : suffixIcon} listItemType={listItemType}>
      <Image type={ImageType.USER} src={avatar?.url} size={ImageSize.LIST_ITEM} fallbackSrc={{ ...user, deleted }} />
      <ListItemDetails>
        <TextRow>
          <PrimaryText css={deleted ? { color: BODY_TEXT_TERTIARY } : undefined}>
            {deleted ? t('deleted_user') : formatFullName(user)}
          </PrimaryText>
        </TextRow>
        {active ? (
          <>
            {!!roles && (
              <TextRow>
                <SecondaryText css="white-space: pre;">{roles}</SecondaryText>
              </TextRow>
            )}
            <Badges>
              <RooftopBadges value={rooftops} />
              {deleted && (
                <Badge title={t('deleted')} color={BadgeColors.NEGATIVE}>
                  {t('deleted')}
                </Badge>
              )}
            </Badges>
          </>
        ) : (
          <Badges>
            <Badge title={t('inactive')} color={BadgeColors.NEGATIVE}>
              {t('inactive')}
            </Badge>
          </Badges>
        )}
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(UserListItem);
