import { gql } from '@apollo/client';

export const manufacturerOption = gql`
  fragment ManufacturerOptionFragment on ManufacturerOption {
    category
    subCategory
    name
    description
    manufacturerCode
    id: manufacturerCode
  }
`;
