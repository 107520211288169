import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { TaskModifyParameter } from 'store/api/graph/interfaces/types';
import { getJSDate } from 'utils/dateUtils';

import { TaskDetailsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [TaskDetailsBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    hasSeparator: true,
  },
  [TaskDetailsBuilderFields.TITLE]: {
    label: 'title',
    required: true,
  },
  [TaskDetailsBuilderFields.DATE_DUE]: {
    label: 'due_date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    settings: {
      matrixViewConfig: {
        disabled: [
          {
            before: getJSDate(),
          },
        ],
      },
    } as ListSelectionDatePickerSettings,
  },
  [TaskDetailsBuilderFields.STATUS]: {
    label: 'status',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.task.status',
    hasSeparator: true,
    clear: { field: TaskModifyParameter._status },
  },
  [TaskDetailsBuilderFields.NOTE]: {
    label: 'note_other',
    groupType: StepFieldType.TEXT_AREA,
    containerInnerStyles: css`
      textarea {
        height: 321px;
      }
    `,
    clear: { field: TaskModifyParameter._note },
    hasSeparator: true,
  },
  [TaskDetailsBuilderFields.TAG_IDS]: {
    label: 'tags',
    queryAlias: 'tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
  },
};

export default DetailFields;
