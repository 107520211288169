import { gql } from '@apollo/client';

import { appointment } from './appointment';
import { tag } from './tag';
import { userName } from './userName';

export const appointmentList = gql`
  fragment AppointmentListFragment on Appointment {
    ## Condensed List
    ...AppointmentFragment
    note
    assignedToName: assignedTo {
      ...UserNameFragment
    }
    rooftopName: rooftop {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    tagName: tags {
      ...TagFragment
    }
  }
  ${userName}
  ${tag}
  ${appointment}
`;
