import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import { BODY_TEXT } from 'styles/color';
import {
  BLUE_500,
  GREEN_050,
  GREEN_500,
  NEUTRAL_0,
  NEUTRAL_050,
  RED_200,
  RED_500,
  YELLOW_100,
  YELLOW_500,
  YELLOW_700,
} from 'styles/tokens';
import {
  FONT_SIZE_11,
  LETTER_SPACING_EXPANDED_1,
  LETTER_SPACING_EXPANDED_3,
  LINE_HEIGHT_CONDENSED_ULTRA,
} from 'styles/typography';
import { variants } from 'utils/styledUtils';

export enum BadgeColors {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  HIGHLIGHT = 'highlight',
}

const defaultColorStyles = css`
  color: ${BODY_TEXT};
  background: ${NEUTRAL_050};
`;

const primaryColorStyles = css`
  color: ${NEUTRAL_0};
  background: ${BLUE_500};
`;

const positiveColorStyles = css`
  color: ${GREEN_500};
  background: ${GREEN_050};
`;

const negativeColorStyles = css`
  color: ${RED_500};
  background: ${RED_200};
`;

const neutralColorStyles = css`
  color: ${YELLOW_700};
  background: ${YELLOW_100};
`;

const highlightColorStyles = css`
  background: ${YELLOW_500};
  color: ${NEUTRAL_0};
`;

export enum BadgeSizes {
  REGULAR = 'regular',
  LARGE = 'large',
}

const regularSizeHeight = 16;
const largeSizeHeight = 25;

const regularSizeStyles = css`
  font-size: 9px;
  height: ${regularSizeHeight}px;
`;

const largeSizeStyles = css`
  font-size: ${FONT_SIZE_11};
  height: ${largeSizeHeight}px;
  padding-bottom: 6px;
  padding-top: 6px;
`;

interface BadgeProps {
  /**
   * Badge title formatting:
   * - Sections with multiple Badges: "[key]: [value]", to provide additional context, and avoid redundant title text
   * - Single Badges: "[value]"
   * - Badges using boolean values in titles: [key/type]: t('yes') | t('no')
   */
  title: string;
  color?: BadgeColors;
  size?: BadgeSizes;
}

const Badge = styled(Text)<BadgeProps>`
  border-radius: 3px;
  letter-spacing: ${LETTER_SPACING_EXPANDED_3};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 3px 6px;
  min-width: 25px;
  line-height: ${LINE_HEIGHT_CONDENSED_ULTRA};

  ${variants<BadgeSizes>(
    'size',
    {
      regular: regularSizeStyles,
      large: largeSizeStyles,
    },
    BadgeSizes.REGULAR
  )};
  ${variants<BadgeColors>(
    'color',
    {
      default: defaultColorStyles,
      primary: primaryColorStyles,
      positive: positiveColorStyles,
      negative: negativeColorStyles,
      neutral: neutralColorStyles,
      highlight: highlightColorStyles,
    },
    BadgeColors.DEFAULT
  )};

  > svg {
    vertical-align: ${({ size }) => (size === BadgeSizes.LARGE ? 'sub' : 'text-top')};
  }
`;

export const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;

  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  :empty {
    display: none;
  }
`;

const getCompletionPercentageColor = props => {
  if (props.value >= 0.8) {
    return { foreground: GREEN_500, background: GREEN_050 };
  } else if (props.value >= 0.5) {
    return { foreground: YELLOW_500, background: YELLOW_100 };
  }
  return { foreground: RED_500, background: RED_200 };
};

export const CompletionPercentage = styled.div<{ value: number }>`
  height: 8px;
  width: 60px;
  border-radius: 3px;
  background-color: ${props => getCompletionPercentageColor(props).background};
  position: relative;
  margin-top: 5px;
  margin-bottom: 15px;
  display: ${props => (props.value === undefined ? 'none' : 'block')};

  ::before {
    content: '';
    height: 8px;
    left: 0;
    width: ${props => props.value * 100 + '%'};
    position: absolute;
    border-radius: 3px;
    background-color: ${props => getCompletionPercentageColor(props).foreground};
  }

  ::after {
    content: '${props => (Number.isNaN(props.value) ? '' : `${Math.round(props.value * 100)}% done`)}';
    width: 100%;
    color: ${BODY_TEXT};
    font-size: 8px;
    letter-spacing: ${LETTER_SPACING_EXPANDED_1};
    line-height: 12px;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
  }
`;

export default Badge;
