import styled from 'styled-components/macro';

import type Step from 'components/core/createModify/interfaces/step';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type Tier from 'components/core/createModify/interfaces/tier';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { getYMMTOptions, YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import CircularArrowIcon from 'components/ui/icons/CircularArrowIcon';
import { CTAButton } from 'components/ui/shared/Button';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type { ColorVariantCombination, TrimFragment } from 'store/api/graph/interfaces/types';
import { InventoryItemPhotoType } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType, SelectOption } from 'store/api/graph/responses/responseTypes';
import { NEUTRAL_800 } from 'styles/tokens';
import {
  addDisplayType,
  getStepField,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const ClearFiltersButton = styled(CTAButton)`
  width: unset;
  background: unset;
  padding: 0;

  & > div {
    display: flex;
    align-items: center;
    color: ${NEUTRAL_800};
  }
`;

export interface TrimFiltersStepSeededData {
  /** The selected type of photo */
  stockPhotoType: InventoryItemPhotoType;
  /** The base tier that opened this step */
  parentTier: Tier;
  /** The selected submodel */
  initialSubModelSelectedValue: SelectOption;
  /** The selected trim */
  initialTrimSelectedValue: SelectOption;
  /** The selected exterior colour */
  initialExteriorColorSelectedValue: SelectOption;
}

export interface TrimColourSelectOption extends StepFieldOptions {
  /** Manufacturer codes related to the trim colour */
  manufacturerCodes: string[];
}

/**
 * Used as a filter to select stock photos.
 * No BE saving is done.
 */
class TrimFiltersStep extends StepComponentCore {
  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, seededData },
    } = props;

    this.props.setParentLoader(true);

    const {
      initialSubModelSelectedValue,
      initialTrimSelectedValue,
      initialExteriorColorSelectedValue,
      stockPhotoType,
      parentTier,
    } = (seededData || {}) as TrimFiltersStepSeededData;
    const { rooftop, modelName } = parentTier.data as RetailItemResponseType;

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: { selectedValue: initialSubModelSelectedValue },
      [DetailsInventoryItemBuilderFields.TRIM_ID]: {
        selectedValue: initialTrimSelectedValue || { id: null, name: t('other_trim') },
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !initialSubModelSelectedValue,
        }),
      },
      [DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR]: {
        selectedValue: initialExteriorColorSelectedValue,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !initialTrimSelectedValue,
          },
          {
            type: StepFieldDisplayType.HIDDEN,
            active: stockPhotoType !== InventoryItemPhotoType.EXTERIOR,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: stockPhotoType !== InventoryItemPhotoType.EXTERIOR,
          },
        ]),
      },
    });

    this.asyncConfigurations = {
      [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: {
        request: () => getYMMTOptions({ rooftopId: [rooftop.id], modelIds: [modelName.id] }, YMMTTarget.SUB_MODEL_ID),
        disableKeywordRefetch: true,
      },
      [DetailsInventoryItemBuilderFields.TRIM_ID]: {
        request: () => this.getTrimOptions(),
        disableKeywordRefetch: true,
      },
      [DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR]: {
        request: () => {
          const selectedTrim = getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields)
            .selectedValue as TrimFragment;
          if (!selectedTrim?.id) {
            return null;
          }

          /**
           * Step 1:
           * Manufacturer codes map to the colour names.
           * We first analyze the list of photos and get the codes to filter by.
           * `TrimPhoto` type do not contain the colour name.
           */
          const uniqueExteriorColourManufacturerCodes = new Set(
            selectedTrim.exteriorPhotos.map(exteriorPhoto => exteriorPhoto.primaryColorManufacturerCode).filter(Boolean)
          );

          /**
           * Step 2:
           * Then we get the colour names associated to the trim.
           * We run trim query to get the manufacturer codes and associated colour names.
           * We then map the manufacturer codes to colours to get a list of unique colours.
           */
          const uniqueColourSelectOptions = (
            selectedTrim.specification?.attributes.colors as ColorVariantCombination[]
          ).reduce((acc: TrimColourSelectOption[], curr: ColorVariantCombination) => {
            const currentColour = curr.exteriorColor.primary;
            if (
              !!currentColour.manufacturerCode &&
              !!currentColour.genericColor &&
              !!currentColour.genericColorName &&
              uniqueExteriorColourManufacturerCodes.has(currentColour.manufacturerCode)
            ) {
              const colourOptionIndex = acc.findIndex(colour => colour?.id === currentColour?.genericColor);

              if (colourOptionIndex === -1) {
                // Start a new list of manufacturer codes for a colour
                acc.push({
                  id: currentColour.genericColor,
                  name: currentColour.genericColorName,
                  manufacturerCodes: [currentColour.manufacturerCode],
                  data: JSON.stringify({
                    rgbHex: currentColour.genericColorRgbHex,
                  }),
                  __typename: 'SelectStringOption',
                });
              } else {
                // Dedupe and update the list of manufactuer codes associated to a colour
                acc[colourOptionIndex].manufacturerCodes.push(currentColour.manufacturerCode);
              }
            }
            return acc;
          }, []);

          /**
           * A list of manufacturer codes that map to a colour, so that we can filter photos by colour.
           */
          return uniqueColourSelectOptions;
        },
        disableKeywordRefetch: true,
      },
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const {
      tier: { seededData },
    } = this.props;

    const { initialTrimSelectedValue: seededTrimValue } = (seededData || {}) as TrimFiltersStepSeededData;

    /**
     * Trim from retail details query do not contain stock photos info due to BE extra retail context.
     * Need to query for the trim using a separate direct trim query for proper data.
     */
    const initialTrimOptions = await this.getTrimOptions().finally(() => this.props.setParentLoader(false));
    const initialTrimSelectedValue = initialTrimOptions?.find(option => option?.id === seededTrimValue?.id);

    getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields).selectedValue = initialTrimSelectedValue;

    this.toggleClearFiltersButtonVisibility();
  }

  /**
   * Show/Hides clear filters button
   */
  toggleClearFiltersButtonVisibility() {
    const {
      tier: { seededData },
    } = this.props;

    const { parentTier } = (seededData || {}) as TrimFiltersStepSeededData;
    const { trimName, subModelName } = parentTier.data as RetailItemResponseType;

    const subModelIsSame =
      subModelName?.id === getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.fields).selectedValue?.id;
    const trimIsSame =
      trimName?.id === getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields).selectedValue?.id;
    const colourIsSet = !!getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields)
      .selectedValue;
    const shouldShowButton = !subModelIsSame || !trimIsSame || colourIsSet;

    // Reset fields to default
    const onClearFiltersButtonClicked = async () => {
      getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.fields).selectedValue = subModelName;
      const initialTrimOptions = await this.getTrimOptions().finally(() => this.props.setParentLoader(false));
      const initialTrimSelectedValue = initialTrimOptions?.find(option => option?.id === trimName?.id);
      getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields).selectedValue = initialTrimSelectedValue;
      getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields).selectedValue = null;

      void this.toggleSubPanel();
      this.setTier({
        footerContent: null,
      });
      this.forceUpdate();
    };

    this.setTier({
      footerContent: shouldShowButton ? (
        <ClearFiltersButton onClick={onClearFiltersButtonClicked}>
          <div>
            <CircularArrowIcon css="margin-right: 5px" /> {t('reset_filter_other')}
          </div>
        </ClearFiltersButton>
      ) : null,
    });
  }

  /**
   * Custom list of trim options that includes a trim count, which
   * shows the amount of stock phots a trim has.
   */
  async getTrimOptions() {
    const {
      tier: { seededData },
    } = this.props;

    const { parentTier, stockPhotoType } = (seededData || {}) as TrimFiltersStepSeededData;
    const { year, rooftop } = parentTier.data as RetailItemResponseType;

    const subModelId = getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.fields)?.selectedValue?.id;
    if (!subModelId) {
      return null;
    }

    const trimOptionsWithCount = await getYMMTOptions(
      { rooftopId: [rooftop.id], subModelIds: [subModelId], year },
      YMMTTarget.TRIM_ID
    ).then(options => {
      const getCount = (type: InventoryItemPhotoType, trim: TrimFragment) => {
        switch (type) {
          case InventoryItemPhotoType.EXTERIOR: {
            return trim?.exteriorPhotos?.length || 0;
          }

          case InventoryItemPhotoType.INTERIOR: {
            return trim?.interiorPhotos?.length || 0;
          }

          default: {
            return 0;
          }
        }
      };

      return (options as TrimFragment[]).map(option => ({
        ...option,
        name: {
          value: `${typeof option.name === 'string' ? option.name : option.name.value} (${getCount(stockPhotoType, option)})`,
        },
      }));
    });
    return trimOptionsWithCount;
  }

  /**
   * How selecting a field works:
   * 1. On selecting each field X and after super is called:
   *    a. Clear dependent fields
   *    b. If empty, disable dependent, by adding StepFieldDisplayType.DISABLED
   *    c. If non empty enable dependent, by removing StepFieldDisplayType.DISABLED
   */
  onFieldSelection(stepField: StepField, value: any) {
    super.onFieldSelection(stepField, value);

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.SUB_MODEL_ID) {
      getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields).selectedValue = null;
      getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields).selectedValue = null;

      if (getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.fields).selectedValue) {
        removeDisplayType(
          getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields),
          StepFieldDisplayType.DISABLED
        );
      } else {
        addDisplayType(
          getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields),
          StepFieldDisplayType.DISABLED
        );
        addDisplayType(
          getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields),
          StepFieldDisplayType.DISABLED
        );
      }
    }

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.TRIM_ID) {
      getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields).selectedValue = null;

      if (getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.fields).selectedValue) {
        removeDisplayType(
          getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields),
          StepFieldDisplayType.DISABLED
        );
      } else {
        addDisplayType(
          getStepField(DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR, this.fields),
          StepFieldDisplayType.DISABLED
        );
      }
    }

    this.toggleClearFiltersButtonVisibility();
  }

  async save() {
    const {
      tier: { activeStep, footerContent },
    } = this.props;

    if (!this.validateFields()) {
      return false;
    }

    this.setOnClosePrompt(undefined);

    // Pass selections to parent tier
    void this.props.tier?.onStepSave?.(activeStep as Step<any, any>, footerContent ? this.fields : []);

    return true;
  }
}

export default TrimFiltersStep;
