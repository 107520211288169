import { useCallback, useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash-es';
import styled from 'styled-components/macro';

import StepFieldInput from 'components/core/createModify/stepFields/StepFieldInput';
import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { LocationInputFields } from 'components/sections/createModify/rooftops/steps/interfaces';
import { regionAndCountryCodeQuery } from 'components/sections/shared/ItemMetaQueries';
import type { SearchInputRef } from 'components/ui/forms/shared/SearchInput';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import Select from 'components/ui/forms/shared/Select';
import NoResultsIcon from 'components/ui/icons/NoResultsIcon';
import { ListItemDetails } from 'components/ui/layouts/ListItem';
import Loader from 'components/ui/loading/Loader';
import Map from 'components/ui/maps/Map';
import Placeholder from 'components/ui/placeholders/Placeholder';
import { Clickable, CTAButton } from 'components/ui/shared/Button';
import { ApolloFetchPolicy } from 'enums/apollo';
import { builderFieldTestId, ElementTestId } from 'enums/testing';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMountEffect } from 'hooks/useMountEffect';
import type {
  CountryCode,
  LocationInput,
  PlaceAutocomplete,
  RegionAndCountryCodeQuery,
} from 'store/api/graph/interfaces/types';
import { LocationInputParameter, PlaceAutocompleteType } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { DIVIDER } from 'styles/color';
import { CARD_WIDTH } from 'styles/layouts';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_050, NEUTRAL_0, NEUTRAL_800 } from 'styles/tokens';
import { formatLocationData } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import { placeAutocompleteQuery, placeQuery } from './LocationSelectorQueries';

const { t } = translate;

interface LocationSelectorProps {
  onDone: (location?: LocationInput) => void;
  type?: PlaceAutocompleteType;
  location?: LocationInput;
  /** Optional argument to pre-seed search query */
  defaultSearchTerm?: string;
  /** Optional seeded metadata, otherwise fetches from server */
  seededMetadata?: RegionAndCountryCodeQuery;
}

const mapHeight = '226px';
const mapWidth = CARD_WIDTH;

const LocationSelectorContainer = styled.div`
  width: ${mapWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const AddressFieldsContainer = styled.div`
  width: ${mapWidth};
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const LocationMapSearchContainer = styled.div`
  width: 100%;
  position: relative;
  height: ${mapHeight};
`;

const LocationSearch = styled.div`
  position: absolute;
  bottom: ${ENTITY_PADDING};
  padding: 0 ${ENTITY_PADDING};
  width: 100%;
`;

const LocationResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const LocationSearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  position: absolute;
  background: ${NEUTRAL_0};
  top: 0;

  & > div {
    position: relative;
    overflow: auto;
  }
`;

const LocationListItemContainer = styled(Clickable)`
  padding: 20px;
  width: 100%;
  flex-shrink: 0;

  &:hover {
    background: ${BLUE_050};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${DIVIDER};
  }
`;

const MapContainer = styled(Map)`
  width: 100%;
  height: 100%;
`;

const SubmitButtonContainer = styled.div`
  background: ${NEUTRAL_0};
  padding: 15px;
  width: 100%;
  position: relative;
  min-height: 40px;
`;

const SubmitButton = styled(CTAButton)`
  background: ${NEUTRAL_800};
`;

const ToggleManualInputButton = styled(CTAButton)`
  margin: 15px auto;
  width: 75%;
  min-height: 40px;
`;

const SelectOptionContainer = styled.div`
  padding: 8px 25px;
`;

const generateAutocompleteToken = (): string => `token-${Math.round(Math.random() * 100)}-${Date.now()}`;

const AutocompleteListItem = ({
  primaryText,
  secondaryText,
  onSelect,
  ...props
}: PlaceAutocomplete & { onSelect?: (selection: PlaceAutocomplete) => void }) => (
  <LocationListItemContainer
    onClick={useCallback(
      () => onSelect?.({ primaryText, secondaryText, ...props }),
      [onSelect, primaryText, secondaryText, props]
    )}
  >
    <ListItemDetails>
      <TextRow>
        <PrimaryText title={primaryText}>{primaryText}</PrimaryText>
      </TextRow>
      <TextRow>
        <SecondaryText title={secondaryText}>{secondaryText}</SecondaryText>
      </TextRow>
    </ListItemDetails>
  </LocationListItemContainer>
);

const LocationAddressFields = ({
  location,
  disabled,
  onChange,
  seededMetadata,
}: {
  onChange: (location: Partial<LocationInput>) => void;
  location?: Partial<LocationInput>;
  disabled?: (keyof LocationInput)[];
  seededMetadata?: RegionAndCountryCodeQuery;
}) => {
  const [countryCodeOptions, setCountryCodeOptions] = useState<SelectOption[]>([]);
  const [regionCodeOptions, setRegionCodeOptions] = useState<SelectOption[]>([]);
  const [filteredRegionCodeOptions, setFilteredRegionCodeOptions] = useState<SelectOption[]>([]);

  const getCountryAndRegionCodeOptions = async () => {
    if (seededMetadata) {
      setCountryCodeOptions(seededMetadata?.metadata?.mutation?.rooftop?.location?.countryCode || []);
      setRegionCodeOptions(seededMetadata?.metadata?.mutation?.rooftop?.location?.regionCode || []);
    } else {
      const { data } = await client.query<RegionAndCountryCodeQuery>({
        query: regionAndCountryCodeQuery,
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      });

      setCountryCodeOptions(data?.metadata?.mutation?.rooftop?.location?.countryCode || []);
      setRegionCodeOptions(data?.metadata?.mutation?.rooftop?.location?.regionCode || []);
    }
  };

  const filterRegionCodeOptions = useCallback(
    (country: CountryCode | undefined) => {
      const options =
        regionCodeOptions?.filter(
          ({ data }) =>
            !!data &&
            // The `data` field is a serialized object (string), despite the schema insisting it is an `object`
            JSON.parse(data)?.countryCode === country
        ) || [];

      setFilteredRegionCodeOptions(options);
    },
    [regionCodeOptions]
  );

  const onChangeCallback = useCallback(
    (field, value) => {
      if (field === LocationInputFields.COUNTRY_CODE) {
        onChange({
          [LocationInputFields.COUNTRY_CODE]: value ?? undefined,
          [LocationInputFields.REGION_CODE]: undefined,
        });
      } else {
        onChange({ [field]: value?.trim()?.length ? value : null });
      }
    },
    [onChange]
  );

  useMountEffect(() => {
    void getCountryAndRegionCodeOptions();
  });

  useEffect(() => {
    filterRegionCodeOptions(location?.countryCode || undefined);
  }, [location?.countryCode, filterRegionCodeOptions]);

  return (
    <AddressFieldsContainer>
      <StepFieldInput
        data-testid={builderFieldTestId(LocationInputFields.ADDRESS)}
        testId={builderFieldTestId(LocationInputFields.ADDRESS)}
        label={t('address')}
        disabled={disabled?.includes('address')}
        selectedValue={location?.address}
        onChange={({ currentTarget }) => onChangeCallback(LocationInputFields.ADDRESS, currentTarget.value)}
      />
      <StepFieldInput
        data-testid={builderFieldTestId(LocationInputFields.CITY)}
        testId={builderFieldTestId(LocationInputFields.CITY)}
        label={t('city')}
        disabled={disabled?.includes('city')}
        selectedValue={location?.city}
        onChange={({ currentTarget }) => onChangeCallback(LocationInputFields.CITY, currentTarget.value)}
      />
      <SelectOptionContainer>
        <Select
          includeEmptyOption
          testId={builderFieldTestId(LocationInputFields.COUNTRY_CODE)}
          label={t('country')}
          selectedValue={location?.countryCode as string}
          selectOptions={countryCodeOptions}
          disabled={disabled?.includes('countryCode')}
          onChange={({ currentTarget }) => onChangeCallback(LocationInputFields.COUNTRY_CODE, currentTarget.value)}
        />
      </SelectOptionContainer>
      <SelectOptionContainer>
        <Select
          includeEmptyOption
          testId={builderFieldTestId(LocationInputFields.REGION_CODE)}
          label={t('region')}
          selectedValue={location?.regionCode as string}
          selectOptions={filteredRegionCodeOptions}
          disabled={disabled?.includes('regionCode') || !filteredRegionCodeOptions?.length}
          onChange={({ currentTarget }) => onChangeCallback(LocationInputFields.REGION_CODE, currentTarget.value)}
        />
      </SelectOptionContainer>
      <StepFieldInput
        data-testid={builderFieldTestId(LocationInputFields.ZIP_CODE)}
        testId={builderFieldTestId(LocationInputFields.ZIP_CODE)}
        label={t('postal_code')}
        disabled={disabled?.includes('zipCode')}
        selectedValue={location?.zipCode}
        onChange={({ currentTarget }) => onChangeCallback(LocationInputFields.ZIP_CODE, currentTarget.value)}
      />
    </AddressFieldsContainer>
  );
};

const LocationSelector = ({
  onDone,
  location,
  defaultSearchTerm,
  type = PlaceAutocompleteType.ADDRESS,
  seededMetadata,
}: LocationSelectorProps) => {
  // Default to EDealer's Toronto office for now
  const defaultLocation: Partial<LocationInput> = {
    latitude: 43.6378222,
    longitude: -79.3946826,
  };

  const { flags } = useFeatureFlags();

  const inputRef = useRef<SearchInputRef>();
  const [currentLocation, setCurrentLocation] = useState(location);
  const [token, setToken] = useState(generateAutocompleteToken());
  const [selectedAddress, setSelectedAddress] = useState(location?.address);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLocationFieldsOpen, setLocationFieldsOpen] = useState(false);
  const [isMapOpen, setMapOpen] = useState(true);

  const [searchResults, setSearchResults] = useState<PlaceAutocomplete[]>();

  useMountEffect(() => {
    if (currentLocation?.placeId || !flags.manualAddressInputEnabled) {
      // If we are viewing a location that was saved via google maps, then open up the map by default
      setMapOpen(true);
      setLocationFieldsOpen(true);
    } else if (currentLocation?.address) {
      // Since we are viewing a location that was manually inputted, open up the location fields but hide the map
      setMapOpen(false);
      setLocationFieldsOpen(true);
    }
  });

  // If the location has been removed, clear the current selections
  useEffect(() => {
    if (location === null) {
      setCurrentLocation(undefined);
      setSelectedAddress(undefined);

      setMapOpen(true);
      setLocationFieldsOpen(false);
    }
  }, [location]);

  // Search callback
  const onSearch = useCallback(
    keyword => {
      if (keyword.length >= 2) {
        setIsProcessing(true);
        void client
          .query({
            query: placeAutocompleteQuery,
            variables: {
              keyword,
              token,
              type,
            },
            fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
          })
          .then(({ data: { placeAutocomplete } }) => setSearchResults(placeAutocomplete))
          .finally(() => {
            setIsProcessing(false);
          });
      } else {
        setIsProcessing(false);
        setSearchResults(undefined);
      }

      return () => {
        setIsProcessing(false);
      };
    },
    [token, type]
  );

  const toggleManualInputCallback = useCallback(() => {
    setMapOpen(!isMapOpen);

    if (isMapOpen && !isLocationFieldsOpen) {
      setLocationFieldsOpen(true);
      setSearchResults(undefined);
    } else if (!isMapOpen && (!currentLocation?.placeId || !isEqual(location, currentLocation))) {
      /*
       * If user is not modifying a location that was previously saved via the map, or the user has manually changed
       * the location data that previously came from a map, then we need to hide the location fields and reset the
       * map selection state
       */
      setLocationFieldsOpen(false);
      onSearch(selectedAddress || '');
    }
  }, [isMapOpen, isLocationFieldsOpen, location, currentLocation, onSearch, selectedAddress]);

  // Autocomplete search result click
  const onSelection = useCallback(
    ({ id, primaryText }: PlaceAutocomplete) => {
      setIsProcessing(true);

      void client
        .query({
          query: placeQuery,
          variables: {
            id,
            token,
          },
          fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        })
        .then(({ data: { place } }) => {
          setCurrentLocation({ ...place, placeId: place.id!, id: undefined });
          setSelectedAddress(primaryText);
          setSearchResults(undefined);
          setLocationFieldsOpen(true);

          // Consume token and set up a new one for all new results
          setToken(generateAutocompleteToken());
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [token]
  );

  // Component form completion
  const onComplete = useCallback(() => {
    const formatData = { ...(formatLocationData(currentLocation) || {}) };

    // If all the fields are empty then just return undefined
    if (
      !formatData.address &&
      !formatData.city &&
      !formatData.countryCode &&
      !formatData.regionCode &&
      !formatData.zipCode
    ) {
      setCurrentLocation(undefined);
      setSelectedAddress(undefined);
      onDone(undefined);
      return;
    }

    const locationData: LocationInput = {
      address: formatData.address,
      city: formatData.city,
      zipCode: formatData.zipCode,
      regionCode: formatData.regionCode,
      countryCode: formatData.countryCode,
      longitude: formatData.longitude,
      latitude: formatData.latitude,
      placeId: formatData.placeId,
      _clear: [
        !formatData.longitude && LocationInputParameter._longitude,
        !formatData.latitude && LocationInputParameter._latitude,
        !formatData.placeId && LocationInputParameter._placeId,
        !formatData.address && LocationInputParameter._address,
        !formatData.city && LocationInputParameter._city,
        !formatData.zipCode && LocationInputParameter._zipCode,
        !formatData.regionCode && LocationInputParameter._regionCode,
        !formatData.countryCode && LocationInputParameter._countryCode,
      ].filter(Boolean),
    };

    onDone(locationData);
  }, [currentLocation, onDone]);

  useEffect(() => {
    if (defaultSearchTerm) {
      onSearch(defaultSearchTerm);
    }
  }, [defaultSearchTerm, isMapOpen, onSearch]);

  return (
    <LocationSelectorContainer>
      {isMapOpen && (
        <LocationMapSearchContainer>
          <MapContainer
            locations={[currentLocation?.longitude && currentLocation?.latitude ? currentLocation : defaultLocation]}
            isClickEnabled={false}
          />
          <LocationSearch>
            <SearchInput
              defaultValue={selectedAddress || defaultSearchTerm}
              key={token} // Using the current session token as a key ensures updates when a selection occurs
              onChange={onSearch}
              className="places-search-input"
              ref={inputRef}
              data-testid={ElementTestId.LOCATION_SEARCH_INPUT}
            />
          </LocationSearch>
        </LocationMapSearchContainer>
      )}
      {flags.manualAddressInputEnabled && (
        <ToggleManualInputButton
          onClick={() => toggleManualInputCallback()}
          data-testid={ElementTestId.LOCATION_TOGGLE_MANUAL_INPUT}
        >
          {isMapOpen ? t('manually_enter_address') : t('use_map_to_enter_address')}
        </ToggleManualInputButton>
      )}

      <LocationResultsContainer data-testid={ElementTestId.LOCATION_RESULTS_CONTAINER}>
        {isLocationFieldsOpen && (
          <LocationAddressFields
            location={currentLocation}
            disabled={
              isMapOpen
                ? [LocationInputFields.COUNTRY_CODE, LocationInputFields.REGION_CODE, LocationInputFields.CITY]
                : undefined
            }
            onChange={newLocation =>
              setCurrentLocation({
                ...(currentLocation || {}),
                ...newLocation,
                // Clear the long/lat and placeId values since the user is manually entering an address
                latitude: null,
                longitude: null,
                placeId: null,
                _clear: [
                  LocationInputParameter._longitude,
                  LocationInputParameter._latitude,
                  LocationInputParameter._placeId,
                ],
              } as LocationInput)
            }
            seededMetadata={seededMetadata}
          />
        )}

        {!!currentLocation && isLocationFieldsOpen && (
          <SubmitButtonContainer>
            <SubmitButton onClick={onComplete} data-testid={ElementTestId.LOCATION_DONE_BUTTON}>
              {t('done')}
            </SubmitButton>
          </SubmitButtonContainer>
        )}
        {searchResults && (
          <LocationSearchResultsContainer data-testid={ElementTestId.LOCATION_SEARCH_RESULTS_CONTAINER}>
            {searchResults.length > 0
              ? searchResults.map(result => <AutocompleteListItem onSelect={onSelection} key={result.id} {...result} />)
              : inputRef.current?.input().value && (
                  <Placeholder
                    testId={ElementTestId.NO_RESULTS_PLACEHOLDER}
                    icon={<NoResultsIcon />}
                    title={t('no_results')}
                    subtitle={t('no_results_for_x', [inputRef.current?.input().value])}
                  />
                )}
          </LocationSearchResultsContainer>
        )}
        {isProcessing && <Loader />}
      </LocationResultsContainer>
    </LocationSelectorContainer>
  );
};

export default LocationSelector;
