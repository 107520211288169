import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { conversationModify } from './ConversationCreateModifyQuery';
import ConversationQueryMethods from './ConversationQueryMethods';
import ConversationLinkedFields from './steps/ConversationLinkedFields';
import ConversationLinkedStep from './steps/ConversationLinkedStep';

const ConversationBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.CONVERSATION_MODIFY]: {
    getData: ConversationQueryMethods.getData,
    refetchQueries: ConversationQueryMethods.refetchQueries,
    resourceType: ResourceType.LEADS,
    steps: [
      {
        id: 'CONVERSATION_CONVERSATION_LINKED',
        step: ConversationLinkedStep,
        stepName: 'linked',
        isEnabled: true,
        fields: ConversationLinkedFields,
      },
    ],
    mutations: {
      modify: conversationModify,
    },
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }],
  },
};

export default ConversationBuilders;
