import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

// Based off of fields from `ConversationConnection`
export enum Conversation {
  GROUP_NAME = 'groupName',
  ROOFTOP_NAME = 'rooftopName',
  TYPE = 'type',
  LEAD_NAME = 'leadName',
  ASSIGNED_TO_NAME = 'leadName.assignedToName',
  SUBJECT = 'subject',
  ITEMS_COUNT = 'itemsCount',
  MESSAGE_SNIPPET = 'messageSnippet',
  PARTICIPANTS = 'participants',
  TAGS = 'tags',
  HAS_ATTACHMENTS = 'hasAttachments',
  ATTACHMENTS = 'attachments',
  READ_BY_ME = 'readByMe',
  REPLIED = 'replied',
  ARCHIVED_BY_ME = 'archivedByMe',
  CREATED = 'created',
  UPDATED = 'updated',
  LEAD = 'lead',
  INVENTORY_ITEM_NAME = 'leadActivity.attributesName.retailItemOfInterest',
}

/*
 * Settings with type derived from API docs
 * TODO: [#1117] Refactor when moving over to `columns` in connection for specifying labels
 */
const ConversationConfig: TableSettingsType = {
  [Conversation.GROUP_NAME]: { label: 'group', type: FieldDataType.GROUP_NAME },
  [Conversation.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [Conversation.TYPE]: { label: 'type', type: FieldDataType.STATUS },
  [Conversation.LEAD_NAME]: { label: 'lead_one', type: FieldDataType.USER_NAME },
  [Conversation.ASSIGNED_TO_NAME]: { label: 'assigned_to', type: FieldDataType.USER_NAME },
  [Conversation.SUBJECT]: { label: 'subject', type: FieldDataType.DEFAULT },
  [Conversation.ITEMS_COUNT]: { label: 'number_of_items', type: FieldDataType.DEFAULT },
  [Conversation.MESSAGE_SNIPPET]: { label: 'message_snippet', type: FieldDataType.DEFAULT },
  [Conversation.PARTICIPANTS]: { label: 'participant_other', type: FieldDataType.CUSTOM },
  [Conversation.TAGS]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [Conversation.HAS_ATTACHMENTS]: { label: 'attachments', type: FieldDataType.BOOLEAN },
  [Conversation.READ_BY_ME]: { label: 'read_by_me', type: FieldDataType.BOOLEAN },
  [Conversation.REPLIED]: { label: 'replied', type: FieldDataType.BOOLEAN },
  [Conversation.ARCHIVED_BY_ME]: { label: 'archived_by_me', type: FieldDataType.BOOLEAN },
  [Conversation.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Conversation.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const ConversationSettings = translateFields(ConversationConfig);
