import { useMemo } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import CoreLayout from 'components/ui/layouts/CoreLayout';
import { RoutePath } from 'enums/routePath';
import { useUser } from 'hooks/contexts/useUser';
import type { LDFeatureFlagsKey } from 'hooks/useFeatureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type { UserScope } from 'store/api/graph/interfaces/types';
import type { RequiredPermissions } from 'types/Permissions';
import { isFeatureRouteEnabledForUser } from 'utils/featureBundleUtils';

export type RestrictedRouteProps = {
  component: any;
  authorizedPermissions: RequiredPermissions;
  authorizedUserScopes?: UserScope[];
  featureFlag?: LDFeatureFlagsKey;
};

const RestrictedRoute = ({
  component,
  authorizedPermissions,
  authorizedUserScopes,
  featureFlag,
}: RestrictedRouteProps) => {
  const {
    hasPermissions,
    user: { rooftops: userRooftops, scope: userScope, id },
  } = useUser();
  const { flags } = useFeatureFlags();
  const { pathname } = useLocation();

  // If a feature flag was passed, ensure that the flag is enabled
  const featureFlagEnabled = featureFlag ? flags[featureFlag] : true;

  const isUserAllowedAccess = useMemo(() => {
    // If user has not yet loaded, allow rendering a degraded view
    if (!id) {
      return true;
    }
    // Format and validate permissions
    const hasAuthorizedPermissions = hasPermissions(authorizedPermissions);

    // If there aren't any UserScopes to validate, simply just return the permissions result.
    if (!authorizedUserScopes?.length) {
      return hasAuthorizedPermissions;
    }

    // Otherwise, validate the UserScopes and ensure both cases are true.
    const hasAuthorizedUserScope = authorizedUserScopes.includes(userScope);
    return hasAuthorizedPermissions && hasAuthorizedUserScope;
  }, [hasPermissions, userScope, authorizedUserScopes, authorizedPermissions, id]);

  const isUserAllowedForCurrentRoute = useMemo(
    () =>
      isFeatureRouteEnabledForUser({
        userRooftops,
        path: pathname,
        featureFlagOn: flags.rooftopPackageEnabled,
      }),
    [userRooftops, flags, pathname]
  );

  // Render the requested route if allowed to render; otherwise, return to the Home Dashboard.
  return isUserAllowedForCurrentRoute && isUserAllowedAccess && featureFlagEnabled ? (
    <CoreLayout component={component} />
  ) : (
    <Navigate replace to={RoutePath.HOME} />
  );
};

export default RestrictedRoute;
