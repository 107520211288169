import { gql } from '@apollo/client';

export const lender = gql`
  fragment LenderFragment on Lender {
    id
    code
    name
    productCodes
    productCodesNames
    regionCodes
  }
`;
