import type { FocusEvent, KeyboardEvent, MutableRefObject, ReactElement, ReactNode, RefObject } from 'react';
import { createRef } from 'react';

import type { ApolloClient } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client/cache';
import type { DocumentNode } from 'graphql';
import { debounce, get, isArray } from 'lodash-es';
import ReactDOM from 'react-dom';

import { SubStepController, type SubStepHandles } from 'components/core/createModify/components/SubStepController';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type Tier from 'components/core/createModify/interfaces/tier';
import StepFieldInput from 'components/core/createModify/stepFields/StepFieldInput';
import {
  FieldsContainer,
  PreviewContainer,
  StepComponentCoreContainer,
  SubStepContainer,
  SubStepLoader,
} from 'components/core/createModify/stepFields/styles';
import LoggingService, { GTMEvents } from 'components/core/logging/LoggingService';
import type { PromptConfig } from 'components/ui/dialogs/PromptDialog';
import BaseClass from 'components/ui/shared/BaseClass';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { doNotCloseOrAdvanceTypes, StepFieldSubType } from 'enums/stepFieldSubType';
import { DeselectableFieldTypes, StepFieldType } from 'enums/stepFieldType';
import { builderFieldTestId, ElementTestId } from 'enums/testing';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import { doArrayObjectsMatch } from 'utils/arrayUtils';
import { isOffscreen } from 'utils/domUtils';
import { getOptionsFromStepField, getStepField, NONE_LIST_OPTION } from 'utils/formatting/createModifyFormatUtils';
import { saveStep, ValidateFields } from 'utils/formatting/createModifySaveUtils';
import { MultilingualStringValue, translate } from 'utils/intlUtils';
import { deferredRenderCall } from 'utils/renderingUtils';
import { convertedNestedString } from 'utils/stringUtils';

// Declared props referenced in other classes
export interface StepComponentProps<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TSavingMutationVariables extends Record<string, any> = any,
> {
  /** Passed context from parent, temporary fix until [ED-4406] is adressed */
  parentContext?: CreateModifyContextInterface<TData, TMetaData>;
  stepRef: MutableRefObject<StepComponentCore<TData, TMetaData, TSavingMutationVariables> | null>;
  /** Tier data to be refenced in the step */
  tier: Tier<TData, TMetaData>;
  /** Errors that are available to show in the step */
  onError: (errors: ApiError[]) => void;
  /** Callback for when a step has been completed */
  onStepComplete: (skipSave: boolean) => void;
  /** Callback to hide the tier from a step */
  onTierHide: () => void;
  /** Parent method to inform that the step is currently loading options */
  setParentLoader: (isLoadingOptions: boolean) => void;
  /** Callback to toggle the builder prompt dialog */
  toggleClosePrompt: (config?: PromptConfig) => void;
  /** Callback to toggle the step legend */
  toggleStepLegend: (showStepLegend: boolean) => void;
  /** Whether or not the component is expanded */
  isExpanded: boolean;
}

// Declared props referenced in other classes
export interface StepComponentState<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
> {
  /** The current stepField being focused, and determines the subStep to render if applicable */
  currentStepField?: StepField<TData, TMetaData>;
  /** Any children to appear before the list */
  childrenBefore: ReactNode;
  /** Any children to appear after the list */
  childrenAfter: ReactNode;
  /** Any children to appear before the subStep list */
  childrenBeforeSubStep: ReactNode;
  /** Any children to appear after the  subStep list */
  childrenAfterSubStep: ReactNode;
  /** For internal reference for any implementations to avoid lag/delay during scroll and data load */
  isScrolling?: boolean;
  /** Internal loader boolean to determine whether or not we are currently processing data */
  isProcessing?: boolean;
  /** Whether or not the component has finished mounting */
  isMounted: false;
  /** Optional assignment that lets a step control tier adding from any substep */
  onSubStepAdd?: (preFill?: any) => void;
  /** The current field value to preview (if there is a defined preview component for the current active field) */
  previewValue?: any;
  /** The current language being used for the preview */
  previewLanguage: MultilingualStringValue;
  /**
   * Enable previewing for the current Step. This is useful for when the previewing content depends on multiple fields.
   */
  stepPreview?: (fieldValue: unknown) => ReactElement;
}

/**
 * For some steps, (VinDecodeStep and lead DetailsStep) fields dynamically change based on the state of
 * another field (ex in leadActivities, the user can select which type of lead they want and each lead has their own
 * unique set of fields). Field states are configured with the following interface, the fields (StepField[]), the
 * icon to be used as a selection choice (this could be anything: SVG, image, react component, etc), and a title as
 * well.
 */
export interface FieldConfig {
  [key: string]: { fields: StepField[]; icon: (img: any) => JSX.Element; title?: string };
}

export const defaultState: StepComponentState = {
  childrenBefore: undefined,
  childrenAfter: undefined,
  childrenBeforeSubStep: undefined,
  childrenAfterSubStep: undefined,
  currentStepField: undefined,
  isScrolling: false,
  isMounted: false,
  previewLanguage: MultilingualStringValue.EN,
};

// Debounce time when updating the values being passed to any previews used in the builder
const PREVIEW_DEBOUNCE = 500;

/**
 * The core class that handles all the rendering and shared saving/reading logic of a step.
 * Accepts a bunch of defaults from the extending classes and renders accordingly
 */
class StepComponentCore<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TSavingMutationVariables extends Record<string, any> = any,
> extends BaseClass<
  StepComponentProps<TData, TMetaData, TSavingMutationVariables>,
  StepComponentState<TData, TMetaData>
> {
  static contextType = CreateModifyContext;
  context: CreateModifyContextInterface<TData, TMetaData> | undefined;

  /** DOM & component references */
  fieldsContainerRef: RefObject<HTMLDivElement>;
  subStepControllerRef: RefObject<SubStepHandles>;

  /** The save path from to access data from query mutation */
  queryDataPath?: string;

  /** Array of fields for a particular step */
  fields: StepField<TData, TMetaData>[] = [];

  /** A master variables property that each field update */
  variables: any;

  /** Apollo client used to make calls */
  client: ApolloClient<NormalizedCacheObject>;

  /** Defined by the implementing classes, a dictionary of configurations required to make `onAsyncSubPanel` calls */
  asyncConfigurations?: {
    [key: string]: {
      /**
       * Must be async and return a Promise if using keyword search,
       * even if no async calls are made within the function,
       * or results will not update when no matches are found.
       */
      request: (keyword) => any;
      disableKeywordRefetch?: boolean;
    };
  };

  /** The default close prompt, used to warn users about destroying any unsaved changes */
  defaultClosePrompt: PromptConfig;

  state: StepComponentState<TData, TMetaData> = {
    // Casting here because `defaultState` is outside of this class
    ...(defaultState as StepComponentState<TData, TMetaData>),
  };

  constructor(props: StepComponentProps<TData, TMetaData, TSavingMutationVariables>) {
    super(props);
    this.variables = this.variables || {};
    this.client = client;

    this.onFieldSelection = this.onFieldSelection.bind(this);
    this.onMultiSelectItemChecked = this.onMultiSelectItemChecked.bind(this);
    this.onFieldSubmit = this.onFieldSubmit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onFieldActionClick = this.onFieldActionClick.bind(this);
    this.onFieldRequestMaskToggle = this.onFieldRequestMaskToggle.bind(this);
    this.onFieldLocaleChanged = this.onFieldLocaleChanged.bind(this);
    this.onError = this.onError.bind(this);
    this.onContainerScrollComplete = debounce(this.onContainerScrollComplete.bind(this), 50);
    this.onSubPanelSearch = this.onSubPanelSearch.bind(this);
    this.onItemAdd = this.onItemAdd.bind(this);
    this.onSubStepEdit = this.onSubStepEdit.bind(this);
    this.onSubStepDelete = this.onSubStepDelete.bind(this);
    this.setPreviewValue = debounce(this.setPreviewValue.bind(this), 500);
    this.setStepPreviewValue = this.setStepPreviewValue.bind(this);
    this.onButtonClick.bind(this);
    this.toggleSubPanel.bind(this);

    this.fieldsContainerRef = createRef<HTMLDivElement>();
    this.subStepControllerRef = createRef<SubStepHandles>();

    props.stepRef.current = this;

    const { t } = translate;
    this.defaultClosePrompt = {
      message: t('discard_changes_message'),
      title: t('discard_changes'),
      confirmText: t('discard'),
      onConfirm: async () => this.setOnClosePrompt(undefined),
      onComplete: success => {
        if (success) {
          this.setTier({ formData: undefined });
          this.props.tier.formData = undefined;
          this.props.onTierHide();
        }
      },
      isConfirmDestructive: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    LoggingService.trackAction(GTMEvents.BUILDER_STEP_OPENED, {
      builderType: this.props.tier.type,
      stepId: this.props.tier.activeStep?.id,
    });

    // Scrolling active field into view if applicable
    const {
      tier: { activeField, isExpanded },
    } = this.props;

    if (activeField) {
      this.scrollFieldToView(activeField, true, true);
    }

    this.setState({ isMounted: true, isExpanded: isExpanded || false });

    // Scroll event listener for scrolling container
    this.fieldsContainerRef.current!.addEventListener('scroll', this.onContainerScrollComplete);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    LoggingService.trackAction(GTMEvents.BUILDER_STEP_CLOSED, {
      builderType: this.props.tier.type,
      stepId: this.props.tier.activeStep?.id,
    });

    this.fieldsContainerRef.current!.removeEventListener('scroll', this.onContainerScrollComplete);
  }

  // A 50ms debounced method that always ensures `isScrolling:false` after hearing a scroll event
  onContainerScrollComplete() {
    this.setState({ isScrolling: false });
  }

  /**
   * This react lifecycle hook can be used by any child class that is extended from this class. We include prevState
   * in the method signature so these child classes can access the prevState of an updating Step component.
   * @param prevState, the component Step's previous state
   */
  componentDidUpdate(
    _: StepComponentProps<TData, TMetaData, TSavingMutationVariables>,
    prevState: StepComponentState<TData, TMetaData>
  ) {
    // Scrolling active field into view if applicable;
    const {
      tier: { activeField },
      toggleStepLegend,
    } = this.props;

    if (activeField) {
      this.scrollFieldToView(activeField, true);
    }

    const currentStepField = this.state.currentStepField;
    const hasSubStep = Boolean(currentStepField?.subStep?.length);
    const hasPreviewContent = Boolean(currentStepField?.previewContents);

    toggleStepLegend(!hasSubStep && !hasPreviewContent);
  }

  /**
   * When the stepField property `subStepAddConfig` is defined an add button will
   * show beside search allowing the opening of another tier.
   * Can be overridden for custom functionality.
   * This method gets passed data that can optionally preFill the next tier.
   * This is done in `SubStepController` via `onSubStepAdd`.
   *
   * TODO [#1791]: Autoselect item added to lists
   *
   * @param preFill Optional data that needs to preFill the next tier.
   *                Currently `preFill` is the keyword search string from main tier.
   */
  onItemAdd(preFill?: any) {
    const { t } = translate;
    const {
      tier: { currentStepField },
      tier: tierData,
    } = this.props;

    if (!currentStepField?.subStepAddConfig) {
      return;
    }

    const {
      builderTitle,
      builderType,
      dataFields = [],
      seedPrefillMethod,
      defaultSeededData = {},
      entityType,
    } = currentStepField.subStepAddConfig;
    const { toggleTier, topTier } = this.getContext();

    // Converting `dataFields` from the subStepAddConfig into usable derived data
    const derivedData = dataFields.reduce((acc, fieldTarget) => {
      const dataFieldValue = fieldTarget.sources.reduce(
        (acc, sourceId) => (acc.queryVar ? acc : getStepField(sourceId, this.fields)),
        {} as StepField<TData, TMetaData>
      ).selectedValue;

      return {
        ...acc,
        [fieldTarget.target]: get(dataFieldValue, fieldTarget.sourceProp || '', dataFieldValue),
      };
    }, {});
    const seededData = { ...(seedPrefillMethod?.(preFill, tierData) || {}), ...defaultSeededData };

    const tiers = Object.values(CreateModifyTiers);
    const nextTier = tiers[tiers.indexOf(topTier!.tierId) + 1];

    if (nextTier) {
      toggleTier(nextTier, {
        tierId: nextTier,
        type: builderType,
        title: t(builderTitle),
        isCreating: true,
        entityType,
        seededData: { ...seededData, ...derivedData },
        onStepSave: async (_, data: any) => {
          /*
           * Multi-select sub-step lists need to remain open after a new item has been added to it because the user
           * can choose to select additional items after creating a new one. For all other sub-step types, the sub-step
           * list gets closed after creating a new item and that item is assigned to the current step fields
           * selectedValue
           */
          if (currentStepField.groupSubTypes?.includes(StepFieldSubType.MULTI_SELECT)) {
            await this.onAsyncSubPanel(currentStepField, preFill);
            currentStepField.seededValues = [{ ...data, focus: true }];
            this.forceUpdate();
          } else {
            this.onFieldSelection(currentStepField, data);
          }
        },
      });
    } else {
      LoggingService.debug({ message: 'No builder tier available, max 3 tiers reached' });
    }
  }

  /**
   * Click callback handler for any BUTTON type StepFields. The implementing child class can override this method
   * to listen for any StepField Button clicks.
   *
   * @param stepField
   */
  async onButtonClick(stepField?: StepField<TData, TMetaData>) {
    LoggingService.debug({ message: `No callback defined for StepField button ${stepField?.queryVar}!` });
  }

  async onFieldActionClick(stepField?: StepField<TData, TMetaData>) {
    LoggingService.debug({ message: `No callback defined for StepField action input ${stepField?.queryVar}!` });
  }

  /**
   * Sets the `currentStepField` property which will render in a container alongside the fields
   *
   * @param stepField The field that is being shown
   */
  async toggleSubPanel(stepField?: StepField<TData, TMetaData>) {
    if (stepField?.displayType?.includes(StepFieldDisplayType.DISABLED)) {
      return;
    }

    const { currentStepField } = this.state;
    const {
      subContexts: {
        builderConfigContext: { builderConfig },
        userContext: { hasPermissions, user },
      },
    } = this.getContext();

    if (stepField !== currentStepField && stepField?.subStep?.includes(SubStepType.ASYNC)) {
      await this.onAsyncSubPanel(stepField);
    }

    if (!currentStepField?.subStep?.includes(SubStepType.PERSIST) || !!stepField) {
      /**
       * This is only a temporary workaround to resolve an issue where pasting content into the Rich Text Editor does
       * not immediately update. There is a refactor ticket to avoid needing to do this:
       * https://einc.atlassian.net/browse/ED-6620
       */
      if (stepField?.groupType !== StepFieldType.RICH_TEXT || currentStepField !== stepField) {
        this.setTier({ currentStepField: stepField });
        this.setState({ currentStepField: stepField });
      }
    } else {
      this.forceUpdate();
    }

    // Special handlers for different subPanels
    if (
      stepField &&
      stepField.subStepAddConfig &&
      (!stepField.subStepAddConfig.allowedScopes || stepField.subStepAddConfig.allowedScopes?.includes(user?.scope)) &&
      hasPermissions(builderConfig[stepField.subStepAddConfig.builderType].requiredPermissions)
    ) {
      this.setState({ onSubStepAdd: this.onItemAdd });
    } else if (stepField?.previewContents) {
      this.setPreviewValue(stepField.selectedValue);
    } else {
      this.setState({ onSubStepAdd: undefined });
    }
  }

  /**
   * Proxy: Loops through all the `fields` to check if they’re valid. Useful cases are for evaluating stuff
   * like empty strings. Returns a true/false boolean whether or not this check has passed
   */
  validateFields(): boolean {
    return ValidateFields(this);
  }

  /**
   * An error callback that bubbles up an error and/or handles displaying errors for the relevant fields
   *
   * @param errors the list of errors that are passed through
   */
  onError(errors: ApiError[]) {
    this.props.onError(errors);
  }

  /**
   * The callback method for whenever a multi-select substep list has an item checked/unchecked. This differs from
   * onFieldSelection callback, which gets called when the user presses the 'Done' button after making their list
   * selection changes.
   * @param stepField - The multi-select StepField
   * @param optionChecked - the option that was selected
   * @param values - All the items that are currently selected in the list
   */
  onMultiSelectItemChecked(stepField: StepField<TData, TMetaData>, optionChecked: any, values: any[]) {
    LoggingService.debug({ message: `No callback defined for MultiSelect check list ${stepField?.queryVar}!` });
  }

  /**
   * The callback method for whenever a subStep gets a selection passed back
   *
   * @param stepField The field being selected
   * @param value The value being set to `stepField`'s selectedValue, can be anything from string
   * to object to StepOption
   * @param persistSeededValues Whether or not any `stepField` seeded values should be retained, if false it
   * clears out the fields
   * @param advance Whether or not the builder should scroll to and focus any unfilled fields
   */
  onFieldSelection(stepField: StepField<TData, TMetaData>, value: any, persistSeededValues = false, advance = true) {
    const {
      tier: { isCreating, activeStep },
    } = this.props;

    /**
     * If this field has a preview, then skip this callback, its not needed as the user is not making a selection
     * nor can they advance to the next field by pressing <Enter>
     */
    if (stepField.previewContents) {
      return;
    }

    const stepFieldRef = getStepField(stepField.queryVar, this.fields);

    let doesSelectionHaveChanges;

    if (stepFieldRef.selectedValue?.id && value?.id) {
      // If the step fields current value and the new selected value both have ids, compare these ids for changes
      doesSelectionHaveChanges = stepFieldRef.selectedValue.id !== value.id;
    } else if (isArray(stepFieldRef.selectedValue) && isArray(value)) {
      /*
       * If the step field is a list of ids, and so is the newly selected values, then each list must have the same
       * length and contain the same ids, for there to not have been any changes
       */

      if (stepFieldRef.selectedValue[0]?.id) {
        doesSelectionHaveChanges =
          !(stepFieldRef.selectedValue.length === value.length) ||
          !stepFieldRef.selectedValue.every(({ id: selectedIds }) =>
            value.some(({ id: newIds }) => newIds === selectedIds)
          );
      } else {
        // If the step field is a list of objects with no id, then compare the full object in each list for any changes
        doesSelectionHaveChanges = !doArrayObjectsMatch([stepFieldRef.selectedValue].flat(), [value].flat());
      }
    } else if (['SelectStringOption', 'SelectIntOption'].includes(value?.__typename)) {
      /*
       * The newly selected value is a SelectOption, but the step fields current value is not. However, if the
       * SelectOption id matches the current step field value then this is technically an unchanged selection
       */
      doesSelectionHaveChanges = stepFieldRef.selectedValue !== value.id;
    } else {
      // As a last resort compare the shape of the current step field selected value and the new selected value
      doesSelectionHaveChanges = !doArrayObjectsMatch([stepFieldRef.selectedValue], [value]);
    }

    // If the user clicked on the dropdown option that was already selected, then clear the dropdown value
    const deselectDropdownValue =
      DeselectableFieldTypes.includes(stepFieldRef.groupType as StepFieldType) && !doesSelectionHaveChanges;

    stepFieldRef.selectedValue = deselectDropdownValue ? null : value;

    /*
     * If there is no custom onClosePrompt, and the new selection is different from the previous one,
     * then we'll include a prompt to warn the user about losing unsaved changes
     */
    if (!activeStep?.onClosePrompt && (doesSelectionHaveChanges || deselectDropdownValue)) {
      this.setOnClosePrompt(this.defaultClosePrompt);
    }

    // Clearing out any seeded values
    if (!persistSeededValues) {
      stepFieldRef.seededValues = undefined;
    }
    this.clearFieldError(stepField);

    // Checking to see if the current field should advance or toggle the sub panel when a selection is made
    const shouldCloseOrAdvance = !stepFieldRef.groupSubTypes?.some(element =>
      doNotCloseOrAdvanceTypes.includes(element)
    );

    if (shouldCloseOrAdvance) {
      // Deferring render to allow fields to update before determining the next one, only advance when creating
      if (advance && (isCreating || stepField.forceAdvance)) {
        if (!stepField.subStep?.includes(SubStepType.PERSIST)) {
          deferredRenderCall(() => this.advancedToNextEmpty(stepFieldRef));
        }
      } else {
        void this.toggleSubPanel();
      }
    }
    this.forceUpdate();
  }

  /**
   * The callback method whenever the enter key is pressed on the fields
   */
  onFieldSubmit(e: KeyboardEvent<HTMLDivElement>) {
    const { currentStepField } = this.state;
    if (
      e.key === 'Enter' &&
      !!currentStepField &&
      !!currentStepField.groupType &&
      ![StepFieldType.TEXT_AREA, StepFieldType.RICH_TEXT, StepFieldType.MULTILINGUAL_TOGGLE_FIELD].includes(
        currentStepField.groupType
      )
    ) {
      const fieldInput: HTMLInputElement | null = this.fieldsContainerRef.current!.querySelector(
        `.step-field-input-${convertedNestedString(currentStepField.queryVar)} input`
      );
      if (fieldInput) {
        fieldInput.blur();
      }

      this.onFieldSelection(currentStepField, currentStepField.selectedValue);
    }
  }

  /**
   * Getting the next empty field to advance to
   * Note, if a dropdown field has selected the NONE_LIST_OPTION, this is treated as an empty field.
   *
   * @param currentStepField The current field that has been filled out, used to determine which field to show next
   */
  advancedToNextEmpty(currentStepField: StepField<TData, TMetaData>) {
    const nextFieldIndex = this.fields.indexOf(currentStepField) + 1;
    const nextField = this.fields
      .slice(nextFieldIndex)
      .find(
        field =>
          ![StepFieldDisplayType.HIDDEN, StepFieldDisplayType.DISABLED].some(val =>
            field?.displayType?.includes(val)
          ) &&
          (!!field.selectedValue && typeof field.selectedValue === 'object'
            ? !field.selectedValue.id && field.selectedValue.length === 0
            : !field.selectedValue || field.selectedValue === NONE_LIST_OPTION.id)
      );
    void this.toggleSubPanel(nextField);

    // Scroll into view as necessary
    if (nextField) {
      this.scrollFieldToView(nextField.queryVar);
    }
  }

  /**
   * A method that finds the field in question and scrolls it into view as necessary inside of the `fieldsContainer`
   *
   * @param stepFieldQueryVar The unique `queryVar` that is from a `StepField` or `activeField` string
   * @param forceView Whether or not to force a scroll
   */
  scrollFieldToView(stepFieldQueryVar: string, forceView = false, disableScrollAnim = false) {
    const activeFieldStep = this.fields.find(
      field =>
        field.queryVar === stepFieldQueryVar ||
        field.queryAlias === stepFieldQueryVar ||
        field.queryAlias?.includes(stepFieldQueryVar)
    );
    const fieldId = `.step-field-input-${convertedNestedString(
      get(activeFieldStep, 'queryVar', convertedNestedString(stepFieldQueryVar))
    )}`;
    const fieldEl: HTMLElement | null | undefined = this.fieldsContainerRef.current?.querySelector(fieldId);

    // Focus
    if (fieldEl) {
      (fieldEl.querySelector('input') || fieldEl.querySelector('textarea') || fieldEl).focus();
    }

    // Scrolling into view
    if (!!fieldEl && (isOffscreen(fieldEl, this.fieldsContainerRef.current!) || forceView)) {
      this.fieldsContainerRef.current!.style.scrollBehavior = disableScrollAnim ? 'unset' : 'smooth';

      const targetOffset = fieldEl.offsetTop;
      const targetHeight = fieldEl.offsetHeight;
      const visibleHeight = this.fieldsContainerRef.current!.offsetHeight;
      /*
       * Scroll to top of target section (minus half of target section height), or if target is taller than
       * visible height, scroll to the bottom of the section
       */
      const scrollVal =
        targetHeight < visibleHeight ? targetOffset - targetHeight / 2 : targetOffset + targetHeight - visibleHeight;
      const currentScroll = this.fieldsContainerRef.current!.scrollTop;

      // Attempt to scroll, no change means unscrollable
      this.fieldsContainerRef.current!.scrollTop = scrollVal;
      this.setState({
        isScrolling: currentScroll !== this.fieldsContainerRef.current!.scrollTop,
      });

      if (forceView) {
        void this.toggleSubPanel(activeFieldStep);
      }
    }

    // Reset any externally set `activeField` (e.g. deeplinking)
    this.setTier({ activeField: '' });
  }

  /**
   * The callback method for whenever a field changes
   */
  onFieldChange(
    stepField: StepField<TData, TMetaData>,
    e: Record<'currentTarget', { value: any }>,
    shouldForceUpdate = false
  ) {
    const {
      tier: { activeStep },
    } = this.props;

    if (!stepField.subStep || shouldForceUpdate) {
      stepField.selectedValue = e.currentTarget.value;

      // If there is no custom onClosePrompt, then we'll include a prompt to warn the user about losing unsaved changes
      if (!activeStep?.onClosePrompt) {
        this.setOnClosePrompt(this.defaultClosePrompt);
      }
      if (
        shouldForceUpdate ||
        // These are controlled inputs and always need to be updated
        [StepFieldType.MULTILINGUAL_TOGGLE_FIELD, StepFieldType.RICH_TEXT, StepFieldType.MASKED_INPUT].includes(
          stepField.groupType as StepFieldType
        )
      ) {
        this.forceUpdate();
      }
    }

    // If previewing is enabled, update preview with the latest field changes
    if (stepField.previewContents) {
      this.setPreviewValue(e.currentTarget.value);
    }

    this.clearFieldError(stepField);
  }

  setPreviewValue(previewValue: any) {
    this.setState({ previewValue });
  }

  setPreviewLanguage(language: MultilingualStringValue) {
    this.setState({ previewLanguage: language });
  }

  setStepPreviewValue(stepPreviewValue: Record<string, unknown>, options?: { useDebounce?: boolean }) {
    if (options?.useDebounce) {
      debounce(() => this.setTier({ stepPreviewValue }), PREVIEW_DEBOUNCE);
    } else {
      this.setTier({ stepPreviewValue });
    }
  }

  onSubStepEdit(id: string) {
    LoggingService.debug({ message: 'No callback defined for onSubStepEdit!' });
  }

  onSubStepDelete(id: string) {
    LoggingService.debug({ message: 'No callback defined for onSubStepDelete!' });
  }

  /**
   * The callback method for whenever a field is blured (unfocused)
   */
  onFieldBlur(stepField: StepField<TData, TMetaData>, e: FocusEvent, shouldForceUpdate = false) {
    e.stopPropagation();
    if ((!stepField.subStep || shouldForceUpdate) && shouldForceUpdate) {
      this.forceUpdate();
    }
  }

  async onFieldRequestMaskToggle(stepField: StepField<TData, TMetaData>, isMaskOn: boolean): Promise<boolean> {
    LoggingService.debug({ message: 'No callback defined for onFieldRequestMaskToggle!' });
    return false;
  }

  /**
   * The callback method for whenever a fields locale value is changed
   */
  onFieldLocaleChanged(stepField: StepField<TData, TMetaData>, language: MultilingualStringValue) {
    if (stepField?.previewContents) {
      this.setPreviewLanguage(language);
    }
  }

  /**
   * The callback method for whenever a field is locked/unlocked
   *
   * @param queryVar The queryVar for the current StepField. Used to determine the
   *                 lockingField id to add/remove when `optionQueryVar` is not present
   *
   * @param optionQueryVar A secondary queryVar for use in StepFields with `FIELD_GROUP` groupType,
   *                       where each option is also a StepField. Used to determine the lockingField id to add/remove
   */
  onConfirmToggleFieldLock(queryVar?: string, optionQueryVar?: string) {
    LoggingService.debug({ message: 'No callback defined for onConfirmToggleFieldLock!' });
  }

  /**
   * A callback method for whenever a field remove icon is clicked
   */
  onConfirmDeleteStepField(stepField: StepField<TData, TMetaData>) {
    LoggingService.debug({ message: 'No callback defined for onConfirmDeleteStepField!' });
  }

  /**
   * Externally accessed method. Clears current substep if possible and informs container whether or not a
   * substep was succesfully cleared
   *
   * @param persistSeededValues Whether or not to clear out any seeded values in a step field as well,
   * useful for swapping between steps
   */
  clearSubStep(persistSeededValues = false): boolean {
    const { currentStepField } = this.state;

    if (!!currentStepField && !!currentStepField.subStep && !currentStepField.subStep.includes(SubStepType.PERSIST)) {
      if (!persistSeededValues) {
        currentStepField.seededValues = undefined;
      }

      void this.toggleSubPanel();
      return true;
    }
    return false;
  }

  /**
   * Proxy: Does any necessary validation through `validateFields` and then saves using the `queryMutation`
   * variable if allowed
   * @param variablePreset Variables that are preseeded in the call, can be overwritten
   * @param variableOverrides Variables will overwrite any calculated ones in this method
   * @param mutationOverride Should theoretically be very rarely used, but lets you specify the `queryMutation`
   *                         being called when saving
   */
  async save(
    variablePreset?: Partial<TSavingMutationVariables>,
    variableOverrides?: Partial<TSavingMutationVariables>,
    mutationOverride?: DocumentNode
  ): Promise<boolean> {
    return saveStep<TData, TMetaData, Partial<TSavingMutationVariables>>(
      this,
      variablePreset,
      variableOverrides,
      mutationOverride
    );
  }

  /**
   * Method that toggles a prompt over the step, used for unsaved data/changes
   *
   * @param promptConfig the configuration info for the prompt that will show up on close
   */
  setOnClosePrompt(promptConfig?: PromptConfig) {
    const {
      tier: { activeStep },
    } = this.props;
    activeStep!.onClosePrompt = promptConfig;

    this.setTier({ activeStep }, { isSynchronousUpdate: true });
  }

  /**
   * Shortcut helper for setting tier data the component step is currently on.
   *
   * @param data `Tier` data that is being set in the context
   * @param options Configuration options for setting the tier
   */
  setTier(
    data: Partial<Tier<TData, TMetaData>>,
    options?: {
      // Whether the tier data is immedidately updated or batched by react
      isSynchronousUpdate?: boolean;
    }
  ) {
    const { setTier } = this.getContext();
    const {
      tier: { tierId },
    } = this.props;

    if (options?.isSynchronousUpdate) {
      ReactDOM.flushSync(() => {
        setTier(tierId, data);
      });
    } else {
      setTier(tierId, data);
    }

    setTier(tierId, data);
  }

  /**
   * Debounced callback for whenever the subpanel does a keyword search
   *
   * @param keyword the current keyword being searched
   * @param keywordPrev the previous keyword has been used
   */
  onSubPanelSearch(keyword: string, keywordPrev?: string) {
    const { currentStepField } = this.state;
    if (
      keyword !== keywordPrev &&
      currentStepField?.subStep?.includes(SubStepType.ASYNC) &&
      !this.asyncConfigurations?.[currentStepField?.queryVar]?.disableKeywordRefetch
    ) {
      void this.onAsyncSubPanel(currentStepField, keyword);
    }
  }

  /**
   * Method to determine whether or not a field is in an invalid state
   *
   * @param stepField the StepField being checked against
   */
  isFieldInvalid(stepField?: StepField<TData, TMetaData>): boolean {
    const {
      tier: { errors = [] },
    } = this.props;

    return (
      !!stepField &&
      (stepField.invalid ||
        !!errors.some(e =>
          [stepField.queryVar, stepField.queryAlias]
            .flat()
            .filter(Boolean)
            .find(item => e.extensions?.fields?.includes(item))
        ))
    );
  }

  /**
   * Subpanel method for asynchronous options that have to be queried live. Also handles preloader for sub panels
   *
   * @param stepField the stepField that the subpanel is opening for
   * @param keyword any keywords used in the substep to filter results from the async query
   */
  async onAsyncSubPanel(stepField: StepField<TData, TMetaData>, keyword?: string) {
    const {
      subContexts: {
        globalDialogContext: { setConfig },
      },
    } = this.getContext();

    const config = this.asyncConfigurations?.[stepField.queryVar];
    if (config) {
      const { setParentLoader } = this.props;

      if (this.state.isMounted) {
        this.setState({ isProcessing: true });
      } else {
        setParentLoader(true);
      }

      try {
        stepField.options = await config.request(keyword);
        this.forceUpdate();
      } catch (error: any) {
        if (error.networkError?.statusCode === 403) {
          setConfig({
            errorsOverride: getApiErrors(error),
            onComplete: () => void this.toggleSubPanel(),
          });
        }
      }

      // Waiting for any scrolling to end before showing substep to prevent visual lag
      while (this.state.isScrolling) {
        await new Promise(resolve => setTimeout(resolve, 10));
      }

      this.setState({ isProcessing: false });
      setParentLoader(false);
    } else {
      LoggingService.debug({ message: `Unhandled Asynchronous subpanel: ${stepField.queryVar}` });
    }
  }

  /**
   * Method that clears any errors related to a specific step field
   *
   * @param stepField the StepField that is being removed from the list of errors
   */
  clearFieldError(stepField: StepField<TData, TMetaData>) {
    const {
      tier: { errors },
    } = this.props;

    if (errors?.length) {
      if (errors) {
        for (const [key, error] of errors.entries()) {
          if (
            [stepField.queryVar, stepField.queryAlias]
              .flat()
              .filter(Boolean)
              .some(item => error?.extensions?.fields?.includes(item))
          ) {
            errors.splice(key, 1);
          }
        }
      }
      this.setTier({ errors: (!!errors?.length && errors) || undefined });
    }
  }

  getContext(): CreateModifyContextInterface<TData, TMetaData> {
    if (!this.context || Object.keys(this.context).length === 0) {
      // Throw warning so hosted environments can be audited for context issues [ED-4406]
      LoggingService.warn({
        message: 'StepComponentCore: Empty context',
      });

      // Assign parent context which should not be undefined, used in storybook [ED-4406]
      if (process.env.STORYBOOK) {
        this.context = this.props.parentContext;
      }
    }
    return this.context!;
  }

  render() {
    const {
      currentStepField,
      childrenBefore,
      childrenAfter,
      childrenBeforeSubStep,
      childrenAfterSubStep,
      onSubStepAdd,
      isProcessing,
      previewValue,
      previewLanguage,
    } = this.state;
    const {
      subContexts: {
        userContext: { hasPermissions },
      },
    } = this.getContext();

    const showSubStep = !!(currentStepField && !!currentStepField.subStep);

    let PreviewComponent;

    if (currentStepField?.previewContents) {
      // If the currently focused field has a preview enabled then show it
      PreviewComponent = currentStepField.previewContents;
    }

    return (
      <StepComponentCoreContainer>
        <FieldsContainer
          ref={this.fieldsContainerRef}
          onKeyUp={this.onFieldSubmit}
          hasActiveSubStep={showSubStep}
          hasActivePreviewContent={!!PreviewComponent}
        >
          {childrenBefore}
          {this.fields
            .filter(
              field =>
                !field?.displayType?.includes(StepFieldDisplayType.HIDDEN) &&
                (field?.requiredPermissions ? hasPermissions(field.requiredPermissions) : true)
            )
            .map(stepField => (
              <StepFieldInput
                key={stepField.queryVar}
                {...stepField}
                options={getOptionsFromStepField(stepField, this.props.tier.metadata)}
                selectedValue={stepField.selectedValue}
                active={stepField.active || (currentStepField && stepField.queryVar === currentStepField.queryVar)}
                invalid={this.isFieldInvalid(stepField)}
                onClick={
                  stepField.groupType === StepFieldType.FIELD_BUTTON
                    ? () => void this.onButtonClick(stepField)
                    : () => void this.toggleSubPanel(stepField)
                }
                onFocus={() => void this.toggleSubPanel(stepField)}
                onChange={this.onFieldChange.bind(null, stepField)}
                onBlur={this.onFieldBlur.bind(null, stepField)}
                onAction={() => void this.onFieldActionClick(stepField)}
                onRequestMaskToggle={this.onFieldRequestMaskToggle.bind(null, stepField)}
                onLocaleChange={this.onFieldLocaleChanged.bind(null, stepField)}
                onConfirmToggleFieldLock={this.onConfirmToggleFieldLock.bind(this, stepField.queryVar)}
                onConfirmDeleteStepField={this.onConfirmDeleteStepField.bind(this, stepField)}
                hasSeparator={stepField.hasSeparator}
                disabled={stepField.displayType?.includes(StepFieldDisplayType.DISABLED)}
                className={`step-field-input-${convertedNestedString(stepField.queryVar)}`}
                data-testid={builderFieldTestId(stepField.queryVar)}
                testId={builderFieldTestId(stepField.queryVar)}
                data-dd-privacy={stepField.defaultPrivacyLevel}
              />
            ))}
          {childrenAfter}
        </FieldsContainer>
        {!!PreviewComponent && (
          <PreviewContainer data-testid={ElementTestId.BUILDER_PREVIEW_CONTAINER}>
            <PreviewComponent fieldValue={previewValue} language={previewLanguage} tier={this.props.tier} />
          </PreviewContainer>
        )}
        {showSubStep && (
          <SubStepContainer data-testid={ElementTestId.BUILDER_SUB_STEP_CONTAINER}>
            <SubStepController
              {...currentStepField}
              ref={this.subStepControllerRef}
              options={getOptionsFromStepField(currentStepField, this.props.tier.metadata)}
              onSelected={this.onFieldSelection}
              onMultiSelectItemChecked={this.onMultiSelectItemChecked}
              childrenBefore={childrenBeforeSubStep}
              childrenAfter={childrenAfterSubStep}
              onSubStepAdd={onSubStepAdd}
              onEdit={this.onSubStepEdit}
              onDelete={this.onSubStepDelete}
              onCancel={
                currentStepField?.subStep?.includes(SubStepType.PERSIST)
                  ? undefined
                  : () => void this.toggleSubPanel(undefined)
              }
              isShowing={showSubStep}
              onSearchOverride={
                !!currentStepField &&
                currentStepField.subStep?.includes(SubStepType.ASYNC) &&
                !this.asyncConfigurations?.[currentStepField.queryVar]?.disableKeywordRefetch
                  ? this.onSubPanelSearch
                  : undefined
              }
              onConfirmToggleFieldLock={this.onConfirmToggleFieldLock.bind(this, currentStepField?.queryVar)}
            />
          </SubStepContainer>
        )}
        {isProcessing && <SubStepLoader />}
      </StepComponentCoreContainer>
    );
  }
}

export default StepComponentCore;
