import { gql } from '@apollo/client';

import { avatar } from 'store/api/graph/fragments/avatar';

import { rooftop } from './rooftop';

export const contactList = gql`
  fragment ContactListFragment on Contact {
    id
    archived

    defaultAppraiser
    email
    phoneNumber
    firstName
    lastName
    displayName
    rgbHex
    avatar {
      ...AvatarFragment
    }
    rooftop {
      ...RooftopFragment
    }
  }
  ${rooftop}
  ${avatar}
`;
