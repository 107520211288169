import { forwardRef, type ReactNode } from 'react';

import styled from 'styled-components/macro';

import { BODY_TEXT_TERTIARY } from 'styles/color';
import { NEUTRAL_0 } from 'styles/tokens';

const Wrapper = styled.div`
  border-bottom: 1px solid ${BODY_TEXT_TERTIARY};
  position: relative;
`;

const Container = styled.div`
  background-color: ${NEUTRAL_0};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 4px 8px;
  position: relative;
`;

export const MenuBarLeft = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

export const MenuBarRight = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

interface Props {
  children: ReactNode;
}

const MenuBarContainer = forwardRef<HTMLDivElement, Props>(({ children }, ref) => (
  <Wrapper ref={ref}>
    <Container>{children}</Container>
  </Wrapper>
));

export default MenuBarContainer;
