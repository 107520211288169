import { differenceWith, isEqual, isNil } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';

/**
 * Change the index of an item in an array
 *
 * Examples:
 * move([0, 1, 2], 2, 0) => [2, 0, 1]
 * move(['foo', 'bar', 'hello', 'world'], 1, 3) => ['foo', 'hello', 'world', 'bar']
 *
 * @param array - Array of items to be shuffled
 * @param index - The index of the item to be moved
 * @param indexNext - The index of the item's next position
 */
export const move = (array: any[], index: number, indexNext: number): any[] => {
  // Hat tip to: https://stackoverflow.com/a/5306832
  if (indexNext >= array.length) {
    let k = indexNext - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(indexNext, 0, array.splice(index, 1)[0]);
  return array;
};

/**
 * Deep compare two arrays with objects.
 * This util can potentially be improved, differenceWith will calculate the differences between two array
 * objects but we only need to know if the two arrays differ -- at the first sign of a difference,
 * processing can be halted
 *
 * @param array1 - Primary array
 * @param array2 - Secondary array that will be compared against array1
 */
export const doArrayObjectsMatch = (array1?: any[], array2?: any[]): boolean => {
  // If both arrays are null/undefined then return true, technically these two have matching values
  if (isNil(array1) && isNil(array2)) {
    return true;
  }
  // If one of the arrays are null/undefined and the other isn't then they don't have matching values
  if (isNil(array1) || isNil(array2)) {
    return false;
  }
  // If lengths are not equal, arrays do not match
  if (array1.length !== array2.length) {
    return false;
  }
  // If there are no differences between the two arrays then they match
  return differenceWith(array1, array2, isEqual).length === 0;
};

/** Determines whether an array of options is of type StepField */
export const isStepFieldArray = <TData extends Record<string, any>, TMetaData extends Record<string, any>>(
  options: StepField<TData, TMetaData>['options']
): options is StepField<TData, TMetaData>[] => Array.isArray(options) && 'queryVar' in options[0];
