import { gql } from '@apollo/client';

export const tradeInGuaranteedTradeValue = gql`
  fragment TradeInGuaranteedTradeValueFragment on TradeInGuaranteedValue {
    amount {
      formattedAmountRounded
      amount
    }
    comments
    displayName
    updated
  }
`;
