import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import OverlayImagePreview from 'components/sections/createModify/retailBulkAdjustment/OverlayImagePreview';
import {
  retailBulkAdjustmentCreate,
  retailBulkAdjustmentModify,
} from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentCreateModifyQuery';
import RetailBulkAdjustmentQueryMethods from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentQueryMethods';
import { RetailBulkAdjustmentBuilderSteps } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import RetailBulkAdjustmentFields from 'components/sections/createModify/retailBulkAdjustment/steps/RetailBulkAdjustmentFields';
import RetailBulkAdjustmentFiltersStep from 'components/sections/createModify/retailBulkAdjustment/steps/RetailBulkAdjustmentFiltersStep';
import RetailBulkAdjustmentStep from 'components/sections/createModify/retailBulkAdjustment/steps/RetailBulkAdjustmentStep';
import RetailBulkAdjustmentTypeFields from 'components/sections/createModify/retailBulkAdjustment/steps/RetailBulkAdjustmentTypeFields';
import RetailBulkAdjustmentTypeStep from 'components/sections/createModify/retailBulkAdjustment/steps/RetailBulkAdjustmentTypeStep';
import RetailFilterFields from 'components/sections/createModify/shared/steps/RetailFilterFields';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const RetailBulkAdjustmentBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE]: {
    getMetaData: RetailBulkAdjustmentQueryMethods.getMetaData,
    refetchQueries: RetailBulkAdjustmentQueryMethods.refetchQueries,
    resourceType: ResourceType.RETAIL_ITEMS,
    steps: [
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_ADJUSTMENTS_TYPE,
        step: RetailBulkAdjustmentTypeStep,
        stepName: 'retail_item_adjustment_type',
        isEnabled: true,
        fields: RetailBulkAdjustmentTypeFields,
        tooltip: {
          title: 'get_started',
          content: 'bulk_adjustment_detail_step_tooltip_content',
        },
      },
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_FILTERS,
        step: RetailBulkAdjustmentFiltersStep,
        stepName: 'filter_other',
        fields: RetailFilterFields,
      },
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_ADJUSTMENTS,
        step: RetailBulkAdjustmentStep,
        stepName: 'adjustment_one',
        fields: RetailBulkAdjustmentFields,
        previewContents: OverlayImagePreview,
      },
    ],
    mutations: {
      validate: retailBulkAdjustmentCreate,
      create: retailBulkAdjustmentCreate,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
  },
  [BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY]: {
    getMetaData: RetailBulkAdjustmentQueryMethods.getMetaData,
    getData: RetailBulkAdjustmentQueryMethods.getData,
    refetchQueries: RetailBulkAdjustmentQueryMethods.refetchQueries,
    resourceType: ResourceType.RETAIL_ITEMS,
    steps: [
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_ADJUSTMENTS_TYPE,
        step: RetailBulkAdjustmentTypeStep,
        stepName: 'retail_item_adjustment_type',
        isEnabled: true,
        fields: RetailBulkAdjustmentTypeFields,
      },
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_FILTERS,
        step: RetailBulkAdjustmentFiltersStep,
        stepName: 'filter_other',
        isEnabled: true,
        fields: RetailFilterFields,
      },
      {
        id: RetailBulkAdjustmentBuilderSteps.RETAIL_ITEM_ADJUSTMENTS,
        step: RetailBulkAdjustmentStep,
        stepName: 'adjustment_one',
        isEnabled: true,
        fields: RetailBulkAdjustmentFields,
        previewContents: OverlayImagePreview,
      },
    ],
    mutations: {
      modify: retailBulkAdjustmentModify,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
  },
};

export default RetailBulkAdjustmentBuilders;
