import { gql } from '@apollo/client';

import { retailItemDetail } from 'store/api/graph/fragments/retailItemDetail';

export const retailItemPriceAdjustmentCreate = gql`
  mutation RetailItemPriceAdjustmentCreate(
    $retailItemId: ID
    $disclaimer: MultilingualStringInput
    $fixed: Int
    $label: MultilingualStringInput
    $paymentOptions: [PaymentOption!]
    $percentage: BigDecimal
  ) {
    data: retailPriceAdjustmentCreate(
      retailItemId: $retailItemId
      disclaimer: $disclaimer
      fixed: $fixed
      label: $label
      paymentOptions: $paymentOptions
      percentage: $percentage
    ) {
      ...RetailItemDetailFragment
    }
  }

  ${retailItemDetail}
`;

export const retailItemPriceAdjustmentModify = gql`
  mutation RetailItemPriceAdjustmentModify(
    $id: ID!
    $disclaimer: MultilingualStringInput
    $fixed: Int
    $label: MultilingualStringInput
    $paymentOptions: [PaymentOption!]
    $percentage: BigDecimal
    $_clear: [RetailPriceAdjustmentModifyParameter!]
  ) {
    data: retailPriceAdjustmentModify(
      id: $id
      disclaimer: $disclaimer
      fixed: $fixed
      label: $label
      paymentOptions: $paymentOptions
      percentage: $percentage
      _clear: $_clear
    ) {
      ...RetailItemDetailFragment
    }
  }

  ${retailItemDetail}
`;
