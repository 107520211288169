import { translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * Get the dynamic title when viewing a shopping tools configuration. Depending on the type of configuration enabled,
 * the title will change.
 */
export const getShoppingToolsTitle = ({
  isBuildAndPriceFeatureEnabled,
  isShowroomFeatureEnabled,
}: {
  isBuildAndPriceFeatureEnabled: boolean;
  isShowroomFeatureEnabled: boolean;
}): string => {
  if (isBuildAndPriceFeatureEnabled && isShowroomFeatureEnabled) {
    return `${t('showroom')}/${t('build_and_price')}`;
  } else if (isBuildAndPriceFeatureEnabled) {
    return t('build_and_price');
  } else if (isShowroomFeatureEnabled) {
    return t('showroom');
  }

  return '';
};
