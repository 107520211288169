import type Step from 'components/core/createModify/interfaces/step';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { retailItemModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import { LeadBuilderSteps } from 'components/sections/createModify/leads/steps/interfaces';
import { getInventoryItemOptions, getLeadSourceOptions } from 'components/sections/shared/ItemMetaHelpers';
import { RetailListItem } from 'components/ui/lists/RetailListItem';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  LeadActivityAttributes,
  MutationleadActivityCreateArgs,
  PurchaseLeadActivityAttributes,
} from 'store/api/graph/interfaces/types';
import { AccessLevel, LeadActivityType, ResourceType, RetailItemStatus } from 'store/api/graph/interfaces/types';
import type { LeadActivityResponseType, RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { getPurchaserOptions } from 'utils/purchaseActivityUtils';

import { PurchaseActivityBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore {
  private rooftop: RooftopResponseType | undefined = undefined;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);

    const {
      tier: { data: tierData, seededData, isCreating, activeStep, metadata },
    } = props;

    const {
      subContexts: {
        userContext: { user, hasPermissions },
      },
    } = context;

    const data = (tierData || {}) as LeadActivityResponseType;

    const rooftop = data.rooftopName || seededData?.rooftopName || getUsersRooftop(user);
    this.rooftop = rooftop as RooftopResponseType;

    const attributes = data.attributesName as LeadActivityAttributes as PurchaseLeadActivityAttributes | undefined;

    const lead = data.leadName || seededData?.leadName;
    const inventoryItem = attributes?.retailItemPurchased || seededData?.inventoryItemName;
    const purchaseDate = attributes?.purchaseDate;

    // Converting to readable fields and setting presets
    const fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [PurchaseActivityBuilderFields.PURCHASED_BY]: {
        selectedValue: lead,
        overrideSubStepSearchLabel: translate.t('all_leads'),
      },
      [PurchaseActivityBuilderFields.SOURCE_ID]: {
        selectedValue: data.sourceName,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !rooftop,
          },
        ]),
      },
      [PurchaseActivityBuilderFields.RETAIL_ITEM_PURCHASED_ID]: {
        renderElement: props => <RetailListItem {...props} />,
        selectedValue: inventoryItem && { ...inventoryItem, metadata },
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !rooftop || !!(isCreating && !!inventoryItem),
          },
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]),
          },
        ]),
      },
      [PurchaseActivityBuilderFields.DATE]: {
        selectedValue: purchaseDate
          ? getFormattedDateTimeString(getDateTime(purchaseDate), DateTimeFormat.YEAR_MONTH_DAY_DASH_FORMAT)
          : undefined,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
    });

    this.fields = defineFieldValues(fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [PurchaseActivityBuilderFields.PURCHASED_BY]: {
        request: getPurchaserOptions(inventoryItem?.leads || [], rooftop?.id),
      },
      [PurchaseActivityBuilderFields.SOURCE_ID]: {
        request: () => getLeadSourceOptions(rooftop?.id),
        disableKeywordRefetch: true,
      },
      [PurchaseActivityBuilderFields.RETAIL_ITEM_PURCHASED_ID]: {
        request: keyword =>
          getInventoryItemOptions(
            rooftop?.id,
            keyword,
            undefined,
            hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]),
            false,
            { status: [RetailItemStatus.FOR_SALE] }
          ),
      },
    };
  }

  /**
   * Modification for the Lead builder accessible through the `Purchased By` field:
   * After the lead is created, set them as the purchaser, go to the next field, then refresh the view.
   */
  onItemAdd(_preFill: any) {
    const {
      tier: { currentStepField },
    } = this.props;

    if (!currentStepField?.subStepAddConfig) {
      return;
    }

    const { builderTitle, builderType, entityType } = currentStepField.subStepAddConfig;
    const { toggleTier } = this.context!;

    toggleTier(CreateModifyTiers.TIER_1, {
      tierId: CreateModifyTiers.TIER_1,
      type: builderType,
      title: translate.t(builderTitle),
      isCreating: true,
      entityType,
      seededData: { rooftopName: this.rooftop },
      onStepSave: async (step: Step, data: Record<string, any>) => {
        if (step.id === LeadBuilderSteps.DETAILS) {
          getStepField(PurchaseActivityBuilderFields.PURCHASED_BY, this.fields).selectedValue = data;
          this.setTier({ activeField: PurchaseActivityBuilderFields.SOURCE_ID });
          this.forceUpdate();
        }
      },
    });
  }

  /**
   * Updates the status of the given retail item.
   * @returns true on success, false otherwise.
   */
  async updateRetailItemStatus(retailItemId: string, newStatus: RetailItemStatus) {
    try {
      await client.mutate({
        mutation: retailItemModify,
        variables: { id: retailItemId, status: newStatus },
        refetchQueries: ['RetailItemDetailsContainerQuery'],
      });
      return true;
    } catch (error) {
      logApiError(error);
      return false;
    }
  }

  async save() {
    const {
      tier: { isCreating },
    } = this.props;

    const date = getStepField(PurchaseActivityBuilderFields.DATE, this.fields).selectedValue;
    const retailItemId = getStepField(PurchaseActivityBuilderFields.RETAIL_ITEM_PURCHASED_ID, this.fields).selectedValue
      ?.id;

    // The date field is omitted, so a manual check is needed to ensure that it is given
    if (!date) {
      this.setTier({
        errors: [
          {
            message: translate.t('enter_date'),
            extensions: {
              fields: [PurchaseActivityBuilderFields.DATE],
            },
          },
        ],
      });
      return false;
    }

    const variables: MutationleadActivityCreateArgs = {
      type: isCreating ? LeadActivityType.PURCHASE : undefined,
      rooftopId: isCreating ? this.rooftop?.id : undefined,
      purchaseAttributes: {
        purchaseDate: getDateTime(date)?.toISO({ includeOffset: false }) || '',
        retailItemPurchasedId: retailItemId,
      },
    };

    const success = await super.save(variables);

    return success;
  }
}

export default DetailsStep;
