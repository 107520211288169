import { memo, type SVGProps } from 'react';

const CircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 22.203a9.945 9.945 0 0 1-3.983-.803 10.3 10.3 0 0 1-3.238-2.179 10.3 10.3 0 0 1-2.18-3.238A9.945 9.945 0 0 1 1.798 12c0-1.415.267-2.743.803-3.983a10.3 10.3 0 0 1 2.179-3.238 10.299 10.299 0 0 1 3.238-2.18A9.945 9.945 0 0 1 12 1.798c1.415 0 2.743.267 3.983.803 1.241.535 2.32 1.261 3.238 2.179a10.3 10.3 0 0 1 2.18 3.238A9.946 9.946 0 0 1 22.202 12a9.946 9.946 0 0 1-.803 3.983 10.3 10.3 0 0 1-2.179 3.238 10.3 10.3 0 0 1-3.238 2.18 9.945 9.945 0 0 1-3.983.802Zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3C4.839 7.908 4.072 9.783 4.072 12c0 2.217.767 4.093 2.3 5.627 1.535 1.534 3.41 2.301 5.628 2.301Z"
    />
  </svg>
);
export default memo(CircleIcon);
