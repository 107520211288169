import { memo, type SVGProps } from 'react';

const GoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={20} viewBox="0 0 19 20" {...props}>
    <g transform="translate(-12 -10)">
      <path
        d="M22.36 20.416l3.736 3.446-10.373 5.724c-.39.216-.815.323-1.238.323-.438 0-.874-.12-1.27-.346l9.146-9.147zm-10.012-9.235l9.098 8.392-9.121 9.121A2.328 2.328 0 0112 27.51V12.396c0-.439.127-.853.348-1.215zm14.158 5.09l2.907 1.604c.782.431 1.25 1.208 1.25 2.076 0 .869-.468 1.645-1.249 2.078l-2.171 1.198-4.003-3.691 3.266-3.265zm-13.25-5.946a2.528 2.528 0 012.467-.007l9.65 5.327-3.047 3.047z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default memo(GoogleIcon);
