import { cloneDeep } from 'lodash-es';

import type { ItemGridRowFieldProps, ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { UserScope } from 'store/api/graph/interfaces/types';
import type { UserResponseType, UserType } from 'store/api/graph/responses/responseTypes';

import { hasWhiteLabelScopedAccess } from './permissionUtils';

/**
 * Hides common cells if necessary, based on the given scope.
 * @param gridConfig - the main grid configuration object
 * @param fieldKeys - the keys in the grid config that points to the specified fields
 */
export const formatCommonCells = (
  gridConfig: ItemGridRowProps[],
  scope: UserScope,
  fieldKeys: {
    itemId?: string;
    whiteLabel: string;
    groupId?: string;
  }
): ItemGridRowProps[] =>
  cloneDeep(gridConfig)
    .map(config => {
      const fields = config.fields;
      if (fieldKeys.itemId && fields.includes(fieldKeys.itemId) && !hasWhiteLabelScopedAccess(scope)) {
        fields.splice(fields.indexOf(fieldKeys.itemId), 1);
      }
      if (fields.includes(fieldKeys.whiteLabel) && scope !== UserScope.GLOBAL) {
        fields.splice(fields.indexOf(fieldKeys.whiteLabel), 1);
      }
      if (fieldKeys.groupId && fields.includes(fieldKeys.groupId) && !hasWhiteLabelScopedAccess(scope)) {
        fields.splice(fields.indexOf(fieldKeys.groupId), 1);
      }
      return config;
    })
    .filter(config => config.fields.length > 0);

/**
 * Formats the grid section for a user. This is used in both UserDetailsTab and MyAccountDetailsTab. While both have
 * different grid configurations, they have identical requirements. If the user's scope is not FULL_GROUP or
 * PARTIAL_GROUP, then remove the rooftop cell from the grid configuration. Only a user with PARTIAL_GROUP can actually
 * edit the rooftop (provided they meet additional required permissions).
 *
 * This helper will also split the grid configuration into two separate parts - The first three rows will be to the
 * right of a hero image, while the remaining rows will be full width and below the hero image.
 * @param gridConfig
 * @param user
 * @param requiredPermissions
 */
export const formatUserGridSection = (
  gridConfig: ItemGridRowProps[],
  user: UserResponseType | UserType,
  requiredPermissions: (user?: UserResponseType | UserType) => boolean
): ItemGridRowProps[][] =>
  cloneDeep(gridConfig)
    .map(config => {
      const fields = config.fields;
      const { scope } = user;

      if (fields.includes('rooftopName') && ![UserScope.FULL_GROUP, UserScope.PARTIAL_GROUP].includes(scope)) {
        fields.splice(fields.indexOf('rooftopName'), 1);
      } else if (fields.includes('rooftopName')) {
        config.canEdit = scope === UserScope.PARTIAL_GROUP;
      }

      if (config.canEdit) {
        // All final editing abilities are determined by whether or not there is WRITE access to this resource
        config.canEdit = requiredPermissions(user);
      }

      return config;
    })
    .filter(config => config.fields.length > 0)
    .reduce(
      (acc: ItemGridRowProps[][], val: ItemGridRowProps, i, arr) =>
        (i === arr.length - 1 && [
          /**
           * The grid section to the right of the avatar image (this grid section shares its
           * row space with the avatar)
           */
          arr.slice(0, 3),
          /** The grid section below the avatar, this grid section takes up the full row width */
          arr.slice(3),
        ]) as ItemGridRowProps[][],
      []
    );

export const formatItemGridRowSectionKey = (fields: (string | ItemGridRowFieldProps)[]): string =>
  `key-grid-row--${fields.map(field => (typeof field === 'string' ? field : field.id)).join('-')}`;
