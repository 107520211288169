import { useCallback, useMemo, useState } from 'react';

import { rooftopIntegrationModify } from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationCreateModifyQueries';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { RooftopIntegrationResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

import { getEditBuilderForIntegrationType } from './utils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: RooftopIntegrationResponseType;
}

const getActiveStatusLabel = active => (active ? t('suspend_integration') : t('activate_integration'));

const RooftopIntegrationsMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { id, active } = item || {};
  const { hasPermissions, isWhiteLabelScoped } = useUser();
  const { toggleTier } = useCreateModify();
  const [isTogglingActivate, setIsTogglingActivate] = useState(false);
  const [prevActive, setPrevActive] = useState<boolean | undefined>(active);
  const activeStatusLabel = useMemo(() => getActiveStatusLabel(prevActive ?? active), [prevActive, active]);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: getEditBuilderForIntegrationType(item?.integrationType?.id),
        entityType: EntityType.ROOFTOP_INTEGRATION,
        title: t('modify_integration'),
        isCreating: false,
        itemId: item?.id,
        seededData: {
          rooftopName: item?.rooftopName,
        },
        data: item,
      }),
    [item, toggleTier]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled:
        !isWhiteLabelScoped ||
        !hasPermissions(builderConfig[BuilderType.ROOFTOP_INTEGRATION_MODIFY].requiredPermissions),
    },
    {
      label: activeStatusLabel,
      isArchivable: false,
      onClick: () => setIsTogglingActivate(true),
      disabled:
        !isWhiteLabelScoped ||
        !hasPermissions(builderConfig[BuilderType.ROOFTOP_INTEGRATION_MODIFY].requiredPermissions),
    },
  ];

  const onConfirm = useCallback(async () => {
    setPrevActive(active);
    await client.mutate({
      mutation: rooftopIntegrationModify,
      variables: { id, active: !active },
    });
  }, [id, active]);

  const onCancel = useCallback(() => {
    setIsTogglingActivate(false);
    setPrevActive(undefined);
  }, []);

  const onClose = useCallback(() => setPrevActive(undefined), []);

  return (
    <>
      <MenuItems items={menuItemsConfig} isArchived={!active} />
      <PromptDialog
        isFixedToTop
        isOpen={isTogglingActivate}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onClose={onClose}
        title={activeStatusLabel}
      />
    </>
  );
};

export default RooftopIntegrationsMenuItems;
