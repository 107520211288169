import { memo } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import type { ManufacturerOption } from 'store/api/graph/interfaces/types';
import { BODY_TEXT_TERTIARY } from 'styles/color';

const ManufacturerCode = styled(Label)`
  color: ${BODY_TEXT_TERTIARY};
  display: inline-flex;
  white-space: pre-wrap;
`;

interface Props extends ManufacturerOption, ListItemProps {}

const ManufacturerOptionListItem = ({ name, description, manufacturerCode, suffixIcon, listItemType }: Props) => (
  <ListItem suffixIcon={suffixIcon} listItemType={listItemType}>
    <ListItemDetails>
      <TextRow>
        <PrimaryText css="white-space: normal;">
          {name}
          <ManufacturerCode> &bull; {manufacturerCode}</ManufacturerCode>
        </PrimaryText>
      </TextRow>
      {!!description && (
        <TextRow>
          <Text css="white-space: normal;">{description}</Text>
        </TextRow>
      )}
    </ListItemDetails>
  </ListItem>
);

export default memo(ManufacturerOptionListItem);
