import { FeatureBundleSet } from 'enums/featureBundle';
import type { Rooftop, RooftopFragment } from 'store/api/graph/interfaces/types';
import { GuaranteedOfferPackage, PaymentOption } from 'store/api/graph/interfaces/types';

interface isFeatureEnabledForRooftopParams {
  // The rooftop being checked
  rooftop: Pick<Rooftop, 'bundle'>;
  // The feature being checked
  feature: FeatureBundleSet;
  // Whether the entire feature bundle is being feature flagged or not (if turned off, then all features are enabled)
  featureFlagOn?: boolean;
}
/**
 * Check to see if a feature is enabled on a particular rooftop
 *
 * @example:
 *  isFeatureEnabledForRooftop({
 *    rooftop,
 *    feature: FeatureBundleSet.TASK,
 *    featureFlagOn: true
 *  })
 */
export const isFeatureEnabledForRooftop = ({
  rooftop,
  feature,
  featureFlagOn,
}: isFeatureEnabledForRooftopParams): boolean => {
  switch (feature) {
    case FeatureBundleSet.CONVERSATION: {
      // Conversation has a different property path
      return !featureFlagOn || !!rooftop?.bundle?.features?.lead?.conversations;
    }

    case FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.CASH);
    }

    case FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.FINANCE);
    }

    case FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.LEASE);
    }

    case FeatureBundleSet.RETAIL_PAYMENT_OPTION: {
      const isPaymentOptionEnabled =
        !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.LEASE) ||
        !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.CASH) ||
        !!rooftop?.bundle?.features?.retail?.paymentOptions?.includes(PaymentOption.FINANCE);
      return !featureFlagOn || isPaymentOptionEnabled;
    }

    case FeatureBundleSet.RETAIL_SHOWROOM: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.retail?.showroom;
    }

    case FeatureBundleSet.RETAIL_BUILD_AND_PRICE: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.retail?.buildAndPrice;
    }

    case FeatureBundleSet.LEAD_FORWARDING: {
      return !featureFlagOn || !!rooftop?.bundle?.features?.lead?.forwarding;
    }
  }

  return !featureFlagOn || !!rooftop?.bundle?.features?.[feature]?.enabled;
};

/** Check if the rooftop has `guaranteedOfferPackage` set and is not Legacy (GT)  */
export const isGuaranteedOfferEnabledForRooftop = (rooftop: RooftopFragment) => {
  const guaranteedOfferPackage = rooftop?.bundle?.features?.tradeIn?.guaranteedOfferPackage;

  return !!guaranteedOfferPackage && guaranteedOfferPackage !== GuaranteedOfferPackage.LEGACY;
};

interface isEnabledForSomeRooftopsParams {
  // The rooftops being checked
  rooftops: Pick<Rooftop, 'bundle'>[];
  // The feature being checked
  feature: FeatureBundleSet;
  // Whether the entire feature bundle is being feature flagged or not (if turned off, then all features are enabled)
  featureFlagOn?: boolean;
}
/**
 * Check to see if a feature is enabled for at least one of the provided list of rooftops
 *
 * @example:
 *  isEnabledForSomeRooftops({
 *    rooftops: [ ...Rooftop ],
 *    feature: FeatureBundleSet.TASK,
 *    featureFlagOn: true
 *  })
 */
export const isEnabledForSomeRooftops = ({
  rooftops,
  feature,
  featureFlagOn,
}: isEnabledForSomeRooftopsParams): boolean =>
  rooftops?.some(rooftop => isFeatureEnabledForRooftop({ rooftop, feature, featureFlagOn }));

interface doUserRooftopsHaveFeatureEnabledParams {
  // The rooftops the user has access to
  userRooftops?: Pick<Rooftop, 'bundle'>[] | null;
  // The feature being checked
  feature: FeatureBundleSet;
  // Whether the entire feature bundle is being feature flagged or not (if turned off, then all features are enabled)
  featureFlagOn?: boolean;
}
/**
 * Check to see if an item in the global navigation bar is enabled, given the rooftops the user has access to it
 *
 * @example:
 *  doUserRooftopsHaveFeatureEnabled({
 *    userRooftops: user.rooftops,
 *    feature: FeatureBundleSet.TASK,
 *    featureFlagOn: true
 *  })
 */
export const doUserRooftopsHaveFeatureEnabled = ({
  userRooftops,
  feature,
  featureFlagOn,
}: doUserRooftopsHaveFeatureEnabledParams): boolean => {
  // If the user has no access to any rooftops, then they are a whitelabel scoped and can still access all routes
  if (!userRooftops?.length || !featureFlagOn) {
    return true;
  }
  return isEnabledForSomeRooftops({ rooftops: userRooftops, feature, featureFlagOn });
};
