import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

/** The value applied to the `RETAIL_ITEM_LIMIT` field to denote that is is unlimited */
export const UNLIMITED_INVENTORY_LIMIT_VALUE = -1;

export enum Rooftop {
  /** DETAILS TAB */
  ID = 'id',
  TASKS_COUNT = 'tasksCount',
  CONVERSATIONS_COUNT = 'conversationsCount',
  LEAD_ACTIVITIES_COUNT = 'leadActivitiesCount',
  LEADS_COUNT = 'leadsCount',
  RETAIL_ITEMS_COUNT = 'retailItemsCount',
  TRADE_IN_ITEMS_COUNT = 'tradeInItemsCount',
  LOGO_URL = 'logoUrl',
  // Used in tables + connection query filters
  WHITE_LABEL_NAME = 'whiteLabelName',
  // Used to render whitelabel info on details page
  WHITE_LABEL_NAME_VALUE = 'whiteLabelName.whiteLabel',
  ACCOUNT_REP_NAME = 'accountRepName',
  GROUP_NAME = 'groupName.name.value',
  GROUP_ID = 'groupName.id',
  NAME = 'name.value',
  LOCATION = 'location',
  ADDRESS = 'address',
  CITY = 'city',
  ZIP_CODE = 'zipCode',
  REGION = 'region',
  REGION_CODE = 'regionCode',
  COUNTRY = 'country',
  COUNTRY_CODE = 'countryCode',
  PLACE_ID = 'placeId',
  LOCALE = 'locale',
  TIME_ZONE = 'timeZone',
  IS_OPEN = 'isOpen',
  ACTIVE = 'active',
  WEBSITE = 'website',
  WEBSITE_PROVIDER = 'websiteProvider',
  PHONE_NUMBER = 'phoneNumber',
  FAX_NUMBER = 'faxNumber',
  EMAIL_BILLING = 'emailBilling',
  EMAIL_GENERAL = 'emailGeneral',
  EMAIL_MAILBOX_SUBDOMAIN = 'emailSubdomain',
  DESCRIPTION = 'description',
  CARFAX_ID = 'carfaxId',
  CREATED = 'created',
  CREATED_BY_NAME = 'createdByName',
  UPDATED = 'updated',
  DEALERTRACK_ID = 'dealertrackId',
  DEALERTRACK_CONFIRM_ID = 'dealertrackConfirmId',
  WARRANTY_TYPES = 'warrantyTypes',
  SHOPPING_TOOLS_CONFIGURATION = 'shoppingToolsConfiguration',
  PACKAGE = 'bundle.package',
}

/** Paths for features within a package.  */
export enum RooftopPackageFeature {
  APPOINTMENT_ENABLED = 'bundle.features.appointment.enabled',
  LEAD_ENABLED = 'bundle.features.lead.enabled',
  LEAD_CONVERSATIONS = 'bundle.features.lead.conversations',
  LEAD_SMS_MAILBOX_LIMIT = 'bundle.features.lead.smsMailboxLimit',
  LEAD_FORWARDING = 'bundle.features.lead.forwarding',
  RETAIL_BUILD_AND_PRICE = 'bundle.features.retail.buildAndPrice',
  RETAIL_ENABLED = 'bundle.features.retail.enabled',
  RETAIL_ITEM_LIMIT = 'bundle.features.retail.itemLimit',
  RETAIL_PAYMENT_OPTION = 'bundle.features.retail.paymentOptions',
  RETAIL_PREMIUM_EXPORT_INTEGRATION = 'bundle.features.retail.premiumExportIntegrations',
  RETAIL_SHOWROOM = 'bundle.features.retail.showroom',
  RETAIL_UNLIMITED_INVENTORY = 'unlimitedInventory',
  TASK_ENABLED = 'bundle.features.task.enabled',
  TRADE_IN_ENABLED = 'bundle.features.tradeIn.enabled',
  GUARANTEED_OFFER_PACKAGE = 'bundle.features.tradeIn.guaranteedOfferPackage',
  OEM = 'bundle.features.oem',
  OEM_NAME = 'bundle.features.oemName',
}

export enum RooftopPackageAddons {
  APPOINTMENT_ENABLED = 'bundle.addOns.appointment.enabled',
  LEAD_ENABLED = 'bundle.addOns.lead.enabled',
  LEAD_CONVERSATIONS = 'bundle.addOns.lead.conversations',
  LEAD_FORWARDING = 'bundle.addOns.lead.forwarding',
  LEAD_SMS_MAILBOX_LIMIT = 'bundle.addOns.lead.smsMailboxLimit',
  RETAIL_BUILD_AND_PRICE = 'bundle.addOns.retail.buildAndPrice',
  RETAIL_ENABLED = 'bundle.addOns.retail.enabled',
  RETAIL_ITEM_LIMIT = 'bundle.addOns.retail.itemLimit',
  RETAIL_PAYMENT_OPTION = 'bundle.addOns.retail.paymentOptions',
  RETAIL_PREMIUM_EXPORT_INTEGRATION = 'bundle.addOns.retail.premiumExportIntegrations',
  RETAIL_SHOWROOM = 'bundle.addOns.retail.showroom',
  RETAIL_UNLIMITED_INVENTORY = 'unlimitedInventory',
  TASK_ENABLED = 'bundle.addOns.task.enabled',
  TRADE_IN_ENABLED = 'bundle.addOns.tradeIn.enabled',
  GUARANTEED_OFFER_PACKAGE = 'bundle.addOns.tradeIn.guaranteedOfferPackage',
}

const RooftopConfig: TableSettingsType = {
  [Rooftop.ID]: { label: 'rooftop_id', type: FieldDataType.DEFAULT },
  [Rooftop.TASKS_COUNT]: { label: 'tasks_other', type: FieldDataType.DEFAULT },
  [Rooftop.CONVERSATIONS_COUNT]: { label: 'conversations', type: FieldDataType.DEFAULT },
  [Rooftop.LEAD_ACTIVITIES_COUNT]: { label: 'lead_activity_other', type: FieldDataType.DEFAULT },
  [Rooftop.LEADS_COUNT]: { label: 'lead_other', type: FieldDataType.DEFAULT },
  [Rooftop.TRADE_IN_ITEMS_COUNT]: { label: 'trade_ins_other', type: FieldDataType.DEFAULT },
  [Rooftop.RETAIL_ITEMS_COUNT]: { label: 'retail_for_sale', type: FieldDataType.DEFAULT },
  [Rooftop.WHITE_LABEL_NAME]: { label: 'white_label', type: FieldDataType.CUSTOM },
  [Rooftop.WHITE_LABEL_NAME_VALUE]: { label: 'white_label', type: FieldDataType.NAME },
  [Rooftop.GROUP_ID]: { label: 'group_id', type: FieldDataType.DEFAULT },
  [Rooftop.GROUP_NAME]: { label: 'group_name', type: FieldDataType.DEFAULT, columnIdOverride: 'groupName' },
  [Rooftop.NAME]: { label: 'name', type: FieldDataType.DEFAULT, columnIdOverride: 'name' },
  [Rooftop.ACCOUNT_REP_NAME]: { label: 'account_rep', type: FieldDataType.USER_NAME },
  [Rooftop.LOCATION]: { label: 'address', type: FieldDataType.LOCATION },
  [Rooftop.ADDRESS]: { label: 'address', type: FieldDataType.DEFAULT },
  [Rooftop.CITY]: { label: 'city', type: FieldDataType.DEFAULT },
  [Rooftop.ZIP_CODE]: { label: 'zip_code', type: FieldDataType.DEFAULT },
  [Rooftop.REGION]: { label: 'region', type: FieldDataType.DEFAULT },
  [Rooftop.REGION_CODE]: { label: 'region_code', type: FieldDataType.DEFAULT },
  [Rooftop.COUNTRY]: { label: 'country', type: FieldDataType.DEFAULT },
  [Rooftop.COUNTRY_CODE]: { label: 'country_code', type: FieldDataType.DEFAULT },
  [Rooftop.PLACE_ID]: { label: 'place_id', type: FieldDataType.DEFAULT },
  [Rooftop.LOCALE]: { label: 'locale', type: FieldDataType.DISPLAY_NAME },
  [Rooftop.TIME_ZONE]: { label: 'time_zone', type: FieldDataType.DEFAULT },
  [Rooftop.IS_OPEN]: { label: 'open', type: FieldDataType.BOOLEAN },
  [Rooftop.ACTIVE]: { label: 'active', type: FieldDataType.BOOLEAN },
  [Rooftop.WEBSITE]: { label: 'website_one', type: FieldDataType.DEFAULT },
  [Rooftop.WEBSITE_PROVIDER]: { label: 'website_provider', type: FieldDataType.ENUM },
  [Rooftop.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [Rooftop.FAX_NUMBER]: { label: 'fax_number', type: FieldDataType.DEFAULT },
  [Rooftop.EMAIL_BILLING]: { label: 'billing_email', type: FieldDataType.DEFAULT },
  [Rooftop.EMAIL_GENERAL]: { label: 'general_email', type: FieldDataType.DEFAULT },
  [Rooftop.EMAIL_MAILBOX_SUBDOMAIN]: { label: 'email_subdomain', type: FieldDataType.DEFAULT },
  [Rooftop.DESCRIPTION]: { label: 'description', type: FieldDataType.DEFAULT },
  [Rooftop.CARFAX_ID]: { label: 'carfax_id', type: FieldDataType.DEFAULT },
  [Rooftop.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Rooftop.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  [Rooftop.DEALERTRACK_ID]: { label: 'dealertrack_id', type: FieldDataType.DEFAULT },
  [Rooftop.DEALERTRACK_CONFIRM_ID]: { label: 'dealertrack_confirm_id', type: FieldDataType.DEFAULT },
  [Rooftop.WARRANTY_TYPES]: { label: 'warranty_type', type: FieldDataType.BADGE_LIST },
  [Rooftop.PACKAGE]: { label: 'rooftop_package_one', type: FieldDataType.NAME, columnIdOverride: 'featurePackage' },
  [RooftopPackageFeature.OEM_NAME]: {
    label: 'oem_program',
    type: FieldDataType.DEFAULT,
    columnIdOverride: 'oemProgram',
  },
  [RooftopPackageFeature.LEAD_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.LEAD_SMS_MAILBOX_LIMIT]: { label: 'sms_mailbox_limit', type: FieldDataType.DEFAULT },
  [RooftopPackageFeature.LEAD_CONVERSATIONS]: { label: 'conversations', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.LEAD_FORWARDING]: { label: 'forwarding', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.APPOINTMENT_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.TASK_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.TRADE_IN_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.GUARANTEED_OFFER_PACKAGE]: { label: 'guaranteed_offer_package', type: FieldDataType.ENUM },
  [RooftopPackageFeature.RETAIL_ENABLED]: { label: 'enabled', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.RETAIL_BUILD_AND_PRICE]: { label: 'build_and_price', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.RETAIL_SHOWROOM]: { label: 'showroom', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.RETAIL_PAYMENT_OPTION]: { label: 'payment_option_one', type: FieldDataType.BADGE_LIST },
  [RooftopPackageFeature.RETAIL_PREMIUM_EXPORT_INTEGRATION]: {
    label: 'premium_ad_export_other',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopPackageFeature.RETAIL_UNLIMITED_INVENTORY]: { label: 'unlimited_inventory', type: FieldDataType.BOOLEAN },
  [RooftopPackageFeature.RETAIL_ITEM_LIMIT]: { label: 'limit', type: FieldDataType.DEFAULT },
};

export const RooftopSettings = translateFields(RooftopConfig);
