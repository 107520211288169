import type { CSSProperties, ReactNode, RefObject } from 'react';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';

import { uniqueId } from 'lodash-es';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import Portal from 'components/ui/shared/Portal';
import { useMountEffect } from 'hooks/useMountEffect';
import { fadeInOut, tooltipMoveInOut } from 'styles/animations';
import { LINE_HEIGHT_2, NEUTRAL_0, NEUTRAL_900, SPACE_100, SPACE_200, SPACE_300 } from 'styles/tokens';
import { FONT_SIZE_13, FONT_WEIGHT_BOLD, FONT_WEIGHT_SEMI_BOLD, LINE_HEIGHT_CONDENSED } from 'styles/typography';
import { Z_INDEX_5 } from 'styles/z-index';
import { variants } from 'utils/styledUtils';

// The duration of the fade in and out animations
const TOOLTIP_ANIMATION_DURATION = 0.1; // Seconds
// The delay before the fade in animation starts
const TOOLTIP_FADE_IN_DELAY = 0.3; // Seconds

/**
 * CSS for applying the fade in / fade out effects
 * @param shouldFadeIn - flag that when true will start the fade in effect
 * @param shouldFadeOut - flag that when true will start the fade out effect
 */
const getFadeInOutAnimationCSS = (shouldFadeIn: boolean, shouldFadeOut: boolean) => {
  if (shouldFadeIn) {
    return css`
      opacity: 0;
      animation: show-tooltipFadeInOut ${TOOLTIP_ANIMATION_DURATION}s linear ${TOOLTIP_FADE_IN_DELAY}s;
    `;
  } else if (shouldFadeOut) {
    return css`
      opacity: 1;
      animation: hide-tooltipFadeInOut ${TOOLTIP_ANIMATION_DURATION}s linear;
    `;
  } else {
    return css`
      opacity: 0;
    `;
  }
};

/**
 * CSS for applying the move in / move out transition effects
 * @param animationId - the unique id for this animation (not all tooltips have the same move in/out transition effects)
 * @param shouldMoveIn - flag that when true will start the move in effect
 * @param shouldMoveOut - flag that when true will start the move out effect
 */
const getMoveInOutAnimationCSS = (animationId: string, shouldMoveIn: boolean, shouldMoveOut: boolean) => {
  if (shouldMoveIn) {
    return css`
      animation: show-tooltipMoveInOut-${animationId} ${TOOLTIP_ANIMATION_DURATION}s linear ${TOOLTIP_FADE_IN_DELAY}s;
    `;
  } else if (shouldMoveOut) {
    return css`
      animation: hide-tooltipMoveInOut-${animationId} ${TOOLTIP_ANIMATION_DURATION}s linear;
    `;
  }
};

const animatedTooltipFadeStyle = (shouldFadeIn: boolean, shouldFadeOut: boolean) => css`
  ${getFadeInOutAnimationCSS(shouldFadeIn, shouldFadeOut)}
  ${fadeInOut('tooltipFadeInOut')};
`;

const animatedTooltipTransitionStyle = (
  animationId: string,
  shouldMoveIn: boolean,
  shouldMoveOut: boolean,
  transforms: TooltipTransitions
) => css`
  ${getMoveInOutAnimationCSS(animationId, shouldMoveIn, shouldMoveOut)}
  ${tooltipMoveInOut(`tooltipMoveInOut-${animationId}`, transforms)};
`;

/**
 * Div element that has the tooltip fade in effect applied to it.
 */
const TooltipContainer = styled.div<{
  /** Start the fade in */
  shouldFadeIn: boolean;
  /** Start the fade out */
  shouldFadeOut: boolean;
  /** If animation is turned off, then no fade in effects are needed */
  isAnimated: boolean;
}>`
  background: ${NEUTRAL_900};
  border-radius: 8px;
  pointer-events: none;

  ${({ isAnimated, shouldFadeIn, shouldFadeOut }) =>
    isAnimated
      ? animatedTooltipFadeStyle(shouldFadeIn, shouldFadeOut)
      : css`
          display: ${shouldFadeIn ? 'block' : 'none'};
        `}
`;

/**
 * Div element that has the tooltip transition effect applied to it.
 */
const TooltipTransition = styled.div<{
  /** The unique id for this animation */
  animationId: string;
  /** Start the move in transition */
  shouldMoveIn: boolean;
  /** Start the move out transition */
  shouldMoveOut: boolean;
  /** If animation is turned off, then no transition effects are needed */
  isAnimated: boolean;
  /** The TooltipTransitions that will be applied */
  transforms: TooltipTransitions;
}>`
  ${({ isAnimated, animationId, shouldMoveIn, shouldMoveOut, transforms }) =>
    isAnimated ? animatedTooltipTransitionStyle(animationId, shouldMoveIn, shouldMoveOut, transforms) : ''}
`;

/**
 * Wrapper div element that will absolutely position the tooltip on the page and set z-index above all elements
 */
const TooltipPositioner = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_5};
  pointer-events: none;
`;

export enum TooltipStyle {
  /** Fairly thick padding with text formatted the same way as Detail view bodies */
  NORMAL = 'NORMAL',
  /** Thin padding with capitalized text, used for when there is only one or so words needed */
  CONDENSED = 'CONDENSED',
}

const normalTooltipContentsStyle = css`
  padding: ${SPACE_200} ${SPACE_300};
  font-size: ${FONT_SIZE_13};
  line-height: ${LINE_HEIGHT_CONDENSED};
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
`;
const condensedTooltipContentsStyle = css`
  font-weight: ${FONT_WEIGHT_BOLD};
  font-size: ${FONT_SIZE_13};
  line-height: ${LINE_HEIGHT_2};
  padding: ${SPACE_100} ${SPACE_200};
`;

const TooltipContents = styled.div<{ tooltipStyle?: TooltipStyle }>`
  color: ${NEUTRAL_0};
  max-width: 280px;

  ${variants<TooltipStyle>(
    'tooltipStyle',
    {
      [TooltipStyle.NORMAL]: normalTooltipContentsStyle,
      [TooltipStyle.CONDENSED]: condensedTooltipContentsStyle,
    },
    TooltipStyle.NORMAL
  )};
`;

/**  The size of the triangle pointer of the tooltip, value is used as PX  */
const ARROW_SIZE = 12;

/** This is used when calculating the placement for the tooltip, value is used as PX  */
const HALF_ARROW_SIZE = ARROW_SIZE / 2;

/**
 * This is used when calculating the how much of the arrow is visible, value is used as PX
 */
const THIRD_ARROW_SIZE = ARROW_SIZE / 3;

/**
 *  This value used when calculating the tooltip body position if the primary arrow position
 *  is TOP or BOTTOM and the secondary arrow position is LEFT or RIGHT, value is used as PX
 */
const ARROW_HYPOTENUSE_SIZE = Math.round(Math.sqrt(2 * Math.pow(ARROW_SIZE, 2)));

/** The required base transform of the arrow. To be applied in tandem with any other transforms. */
const BASE_ARROW_TRANSFORM = 'rotate(45deg)';

const Arrow = styled.div`
  width: ${ARROW_SIZE}px;
  height: ${ARROW_SIZE}px;
  left: -${HALF_ARROW_SIZE}px;
  top: ${ARROW_SIZE}px;
  position: absolute;
  border-radius: 2px;
  transform: ${BASE_ARROW_TRANSFORM};
  background: ${NEUTRAL_900};
`;

export enum PrimaryArrowPosition {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
}

export enum SecondaryArrowPosition {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
  CENTER,
}

// Used for error checking to ensure that the given positions are sensible
const ARROW_POSITIONS_SET_1 = new Set([
  PrimaryArrowPosition.LEFT,
  PrimaryArrowPosition.RIGHT,
  SecondaryArrowPosition.LEFT,
  SecondaryArrowPosition.RIGHT,
]);
const ARROW_POSITIONS_SET_2 = new Set([
  PrimaryArrowPosition.TOP,
  PrimaryArrowPosition.BOTTOM,
  SecondaryArrowPosition.TOP,
  SecondaryArrowPosition.BOTTOM,
]);

/** Denotes how to position the arrow around the anchor. */
export type ArrowPosition = {
  /** The main axis */
  primary?: PrimaryArrowPosition;
  /** The sub-axis */
  secondary?: SecondaryArrowPosition;
};

// Determines what styles to apply to the arrow in order to position it properly for the given position settings
const arrowPositionVariants: {
  [key in PrimaryArrowPosition]: { generalCss: CSSProperties } & {
    [key in SecondaryArrowPosition]?: CSSProperties;
  };
} = {
  [PrimaryArrowPosition.LEFT]: {
    generalCss: {
      right: 'unset',
      left: `-${THIRD_ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.CENTER]: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [SecondaryArrowPosition.BOTTOM]: {
      top: 'unset',
      bottom: `${ARROW_SIZE}px`,
    },
  },
  [PrimaryArrowPosition.RIGHT]: {
    generalCss: {
      left: 'unset',
      right: `-${THIRD_ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.CENTER]: {
      top: '50%',
      transform: 'translateY(50%)',
    },
    [SecondaryArrowPosition.BOTTOM]: {
      top: 'unset',
      bottom: `${ARROW_SIZE}px`,
    },
  },
  [PrimaryArrowPosition.TOP]: {
    generalCss: {
      top: 'unset',
      bottom: `-${THIRD_ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.LEFT]: {
      left: `${ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.RIGHT]: {
      left: 'unset',
      right: `${ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.CENTER]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  [PrimaryArrowPosition.BOTTOM]: {
    generalCss: {
      top: `-${THIRD_ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.LEFT]: {
      left: `${ARROW_SIZE}px`,
    },
    [SecondaryArrowPosition.RIGHT]: {
      left: 'unset',
      right: `${ARROW_SIZE + 2}px`,
    },
    [SecondaryArrowPosition.CENTER]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
};

// Basic transitions for the tooltip
export interface TooltipTransitions {
  // Starting transform
  from: FlattenSimpleInterpolation;
  // Ending transform
  to: FlattenSimpleInterpolation;
}

/**
 * Determines what transitions to apply to the tooltip. A basic slide in animation is used, depending on the
 * orientation and positioning of the tooltip, a different transform is applied
 */
const tooltipTransitionVariants: {
  [key in PrimaryArrowPosition]: TooltipTransitions;
} = {
  [PrimaryArrowPosition.LEFT]: {
    from: css`
      transform: translate(10px);
    `,
    to: css`
      transform: translate(0);
    `,
  },
  [PrimaryArrowPosition.RIGHT]: {
    from: css`
      transform: translate(-10px);
    `,
    to: css`
      transform: translate(0);
    `,
  },
  [PrimaryArrowPosition.TOP]: {
    from: css`
      transform: translateY(-10px);
    `,
    to: css`
      transform: translateY(0);
    `,
  },
  [PrimaryArrowPosition.BOTTOM]: {
    from: css`
      transform: translateY(10px);
    `,
    to: css`
      transform: translateY(0);
    `,
  },
};

/**
 * Determines what styles to apply to the tooltip in order to position it properly
 * relative to the anchor for the given position settings.
 * @param rect The object retrieved from `getBoundingClientRect()`
 * @param margin Any margins to add to the position
 */
const tooltipBodyPositionVariants: (
  rect: DOMRect,
  margin: { x: number; y: number }
) => {
  [key in PrimaryArrowPosition]: { generalCss: CSSProperties } & {
    [key in SecondaryArrowPosition]?: CSSProperties;
  };
} = (rect, margin) => ({
  [PrimaryArrowPosition.LEFT]: {
    generalCss: { left: rect.x + rect.width + margin.x },
    [SecondaryArrowPosition.TOP]: {
      top: rect.y + margin.y - HALF_ARROW_SIZE,
    },
    [SecondaryArrowPosition.CENTER]: {
      top: rect.y + rect.height / 2 - margin.y,
      transform: 'translateY(-50%)',
    },
    [SecondaryArrowPosition.BOTTOM]: {
      top: rect.y + rect.height - margin.y + HALF_ARROW_SIZE,
      transform: 'translateY(-100%)',
    },
  },
  [PrimaryArrowPosition.RIGHT]: {
    generalCss: { left: rect.x - margin.x },
    [SecondaryArrowPosition.TOP]: {
      top: rect.y + margin.y - HALF_ARROW_SIZE,
      transform: 'translateX(-100%)',
    },
    [SecondaryArrowPosition.CENTER]: {
      top: rect.y + rect.height / 2 - margin.y,
      transform: 'translate(-100%, -50%)',
    },
    [SecondaryArrowPosition.BOTTOM]: {
      top: rect.y + rect.height - margin.y + HALF_ARROW_SIZE,
      transform: 'translate(-100%, -100%)',
    },
  },
  [PrimaryArrowPosition.TOP]: {
    generalCss: { top: rect.y - margin.y },
    [SecondaryArrowPosition.LEFT]: {
      left: rect.x + rect.width / 2 - ARROW_HYPOTENUSE_SIZE,
      transform: 'translateY(-100%)',
    },
    [SecondaryArrowPosition.RIGHT]: {
      left: rect.x + rect.width / 2 + ARROW_HYPOTENUSE_SIZE,
      transform: 'translate(-100%, -100%)',
    },
    [SecondaryArrowPosition.CENTER]: {
      left: rect.x + rect.width / 2,
      transform: 'translate(-50%, -100%)',
    },
  },
  [PrimaryArrowPosition.BOTTOM]: {
    generalCss: { top: rect.y + rect.height + margin.y + HALF_ARROW_SIZE },
    [SecondaryArrowPosition.LEFT]: {
      left: rect.x + rect.width / 2 - ARROW_HYPOTENUSE_SIZE,
    },
    [SecondaryArrowPosition.RIGHT]: {
      left: rect.x + rect.width / 2 + ARROW_HYPOTENUSE_SIZE,
      transform: 'translateX(-100%)',
    },
    [SecondaryArrowPosition.CENTER]: {
      left: rect.x + rect.width / 2,
      transform: 'translateX(-50%)',
    },
  },
});

/** Any extra spacing between the anchor and the tooltip */
type Margin = {
  /** Extra spacing applied to the x-axis */
  x?: number;
  /** Extra spacing applied to the y-axis */
  y?: number;
};

export type TooltipMargin = Margin;

export interface TooltipProps {
  /** Whether or not the tooltip should be visible */
  shouldShow: boolean;
  /** The contents of the tooltip */
  children: ReactNode;
  /** The element that the tooltip is positioned against */
  anchor: RefObject<Element>;
  /** Any extra spacing between the anchor and the tooltip */
  margin?: Margin;
  /** Where the arrow should appear relative to the tooltip's body. Is `LEFT TOP` by default. */
  arrowPosition?: ArrowPosition;
  /** Whether or not the tooltip should fade in and out */
  isAnimated?: boolean;
  /** The style applied to the tooltip. `NORMAL` by default. */
  styleVariant?: TooltipStyle;
}

/**
 * A tooltip with customizable arrow and body positions.
 */
const Tooltip = ({
  shouldShow,
  children,
  anchor,
  margin,
  isAnimated,
  arrowPosition: customArrowPosition,
  styleVariant,
}: TooltipProps) => {
  const [isVisible, setVisible] = useState<boolean>(isAnimated ? false : shouldShow);
  const [startFadeout, setStartFadeout] = useState<boolean>(false);
  const [fadeInAnimationComplete, setFadeInAnimationComplete] = useState<boolean>(false);
  const [tooltipMoveInAnimationId] = useState<string>(uniqueId());
  const prevIsShown = useRef<boolean>(isVisible);
  const tooltipContainerRef = createRef<HTMLDivElement>();

  const arrowPosition: Required<ArrowPosition> = useMemo(
    () => ({
      primary: customArrowPosition?.primary ?? PrimaryArrowPosition.LEFT,
      secondary: customArrowPosition?.secondary ?? SecondaryArrowPosition.TOP,
    }),
    [customArrowPosition]
  );

  function getPosition() {
    const rect = anchor.current?.getBoundingClientRect();
    if (!rect) {
      return { top: 0, left: 0 };
    }

    const pos1 = tooltipBodyPositionVariants(rect, {
      x: margin?.x || 0,
      y: margin?.y || 0,
    })[arrowPosition.primary];
    const pos2 = pos1[arrowPosition.secondary] || 0;
    return { ...pos1.generalCss, ...pos2 };
  }

  useEffect(() => {
    if (!isAnimated) {
      setVisible(shouldShow);
    }

    // If it was just set to show, start the fade-in animation
    else if (!prevIsShown.current && shouldShow) {
      setFadeInAnimationComplete(false);
      setVisible(true);
      setStartFadeout(false);
    }
    // If it was just set to hide, start the fade-out animation
    else if (prevIsShown.current && !shouldShow) {
      setVisible(false);
      // If the fade in animation hasn't completed then there is no need to start the fade out animation
      if (fadeInAnimationComplete) {
        setStartFadeout(true);
      }
    }
    prevIsShown.current = shouldShow;
  }, [shouldShow, isAnimated, fadeInAnimationComplete]);

  /**
   * When the fade in animation ends, update fadeInAnimationComplete flag in the state. This prevents any fade out
   * animations from happening unless the tooltip has completed the fade in animation.
   */
  const onAnimationEnded = (event: AnimationEvent) => {
    if (event.animationName === 'show-tooltipFadeInOut') {
      setFadeInAnimationComplete(true);
    }
  };

  useMountEffect(() => {
    tooltipContainerRef.current?.addEventListener('animationend', onAnimationEnded);
    return () => {
      tooltipContainerRef.current?.removeEventListener('animationend', onAnimationEnded);
    };
  });

  const arrowStyle = useMemo(() => {
    const primaryPosition = arrowPositionVariants[arrowPosition.primary];
    const secondaryPosition = primaryPosition[arrowPosition.secondary] || {};
    const { transform, ...misc } = { ...primaryPosition.generalCss, ...secondaryPosition };
    return { ...misc, transform: transform ? `${transform} ${BASE_ARROW_TRANSFORM}` : BASE_ARROW_TRANSFORM };
  }, [arrowPosition]);

  useEffect(() => {
    if (
      (ARROW_POSITIONS_SET_1.has(arrowPosition.primary) && ARROW_POSITIONS_SET_1.has(arrowPosition.secondary)) ||
      (ARROW_POSITIONS_SET_2.has(arrowPosition.primary) && ARROW_POSITIONS_SET_2.has(arrowPosition.secondary))
    ) {
      LoggingService.debug({
        message: `Tooltip: The secondary arrow position must make sense in tandem with the primary one.
      Eg. if the primary position is LEFT or RIGHT, choose TOP, BOTTOM, or CENTER for the secondary one.
      Current positions: ${PrimaryArrowPosition[arrowPosition.primary]} ${
        SecondaryArrowPosition[arrowPosition.secondary]
      }`,
      });
    }
  }, [arrowPosition]);

  return (
    <Portal rootId="tooltip-root">
      <TooltipPositioner style={getPosition()}>
        <TooltipTransition
          isAnimated={!!isAnimated}
          animationId={tooltipMoveInAnimationId}
          shouldMoveIn={isVisible}
          shouldMoveOut={startFadeout}
          transforms={tooltipTransitionVariants[arrowPosition.primary]}
        >
          <TooltipContainer
            ref={tooltipContainerRef}
            shouldFadeIn={isVisible}
            shouldFadeOut={startFadeout}
            isAnimated={!!isAnimated}
          >
            {styleVariant === TooltipStyle.NORMAL && <Arrow style={arrowStyle} />}
            <TooltipContents tooltipStyle={styleVariant}>{children}</TooltipContents>
          </TooltipContainer>
        </TooltipTransition>
      </TooltipPositioner>
    </Portal>
  );
};

export default Tooltip;
