import type { ComponentProps, ComponentType } from 'react';

import styled from 'styled-components/macro';

const IconContainer = styled.div<{ height: number; width: number }>`
  align-items: center;
  display: flex;
  height: ${props => props.height}px;
  justify-content: center;
  width: ${props => props.width}px;
`;

interface Props<T extends ComponentType<any> = ComponentType<any>> {
  /** Icon component */
  icon: T;
}

/**
 * A wrapper component for icons used in the editor.
 */
const EditorIcon = <T extends ComponentType<any> = ComponentType<any>>({
  icon: Icon,
  height = 20,
  width = 20,
  ...props
}: Props<T> & ComponentProps<T>) => (
  <IconContainer height={height} width={width}>
    <Icon {...props} />
  </IconContainer>
);

export default EditorIcon;
