import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import type { PromptConfig } from 'components/ui/dialogs/PromptDialog';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

interface Props {
  children: ReactNode;
}

export const GlobalDialogProvider = ({ children }: Props) => {
  const [config, setConfig] = useState<PromptConfig | undefined>();

  const setNewConfig = useCallback(
    newConfig => {
      setConfig(newConfig);
      return newConfig;
    },
    [setConfig]
  );

  return (
    <GlobalDialogContext.Provider value={{ config, setConfig: setNewConfig }}>{children}</GlobalDialogContext.Provider>
  );
};
