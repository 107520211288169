import type { ReactNode } from 'react';
import { useCallback } from 'react';

import styled from 'styled-components/macro';

import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { BODY_TEXT } from 'styles/color';

const RowButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

interface Props {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const SectionRowButton = ({ onClick, children, disabled = false }: Props) => {
  const handleOnClick = useCallback(() => onClick?.(), [onClick]);
  if (disabled || !onClick) {
    return <>{children}</>;
  }
  return (
    <RowButtonContainer onClick={handleOnClick}>
      <div css="flex-grow: 1;">{children}</div>
      <ChevronRightIcon color={BODY_TEXT} />
    </RowButtonContainer>
  );
};

export default SectionRowButton;
