import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { retailItemPricingModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import { RetailItemPricingBuilderStep } from 'components/sections/createModify/retailItemPricing/enums/RetailItemPricingBuilder';
import RetailItemPricingQueryMethods from 'components/sections/createModify/retailItemPricing/RetailItemPricingQueryMethods';
import RetailItemPricingTypeCashStep from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeCashStep';
import RetailItemPricingTypeFields from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeFields';
import RetailItemPricingTypeFinanceStep from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeFinanceStep';
import RetailItemPricingTypeLeaseStep from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeLeaseStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const RetailItemPricingBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_ITEM_PRICING]: {
    resourceType: ResourceType.RETAIL_ITEMS,
    refetchQueries: RetailItemPricingQueryMethods.refetchQueries,
    getData: RetailItemPricingQueryMethods.getData,
    steps: [
      {
        id: RetailItemPricingBuilderStep.CASH,
        step: RetailItemPricingTypeCashStep,
        stepName: 'cash',
        isEnabled: true,
        fields: RetailItemPricingTypeFields,
      },
      {
        id: RetailItemPricingBuilderStep.FINANCE,
        step: RetailItemPricingTypeFinanceStep,
        stepName: 'finance',
        isEnabled: true,
        fields: RetailItemPricingTypeFields,
      },
      {
        id: RetailItemPricingBuilderStep.LEASE,
        step: RetailItemPricingTypeLeaseStep,
        stepName: 'lease',
        isEnabled: true,
        fields: RetailItemPricingTypeFields,
      },
    ],
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
    mutations: {
      modify: retailItemPricingModify,
    },
  },
};

export default RetailItemPricingBuilders;
