import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { flattenMetadataResponse } from 'components/sections/shared/ItemMetaHelpers';
import { websiteMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { websiteDetailQuery } from 'containers/websites/WebsitesContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { WebsiteMetaQuery, WebsiteMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const WebsiteQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const groupRooftopIds = seededData?.website?.groupName?.rooftops?.map(({ id }) => id);
    const observable = client.watchQuery<WebsiteMetaQuery, WebsiteMetaQueryVariables>({
      query: websiteMetaQuery,
      variables: {
        groupRooftopIds,
        d_websiteSortOptionsOn: !!groupRooftopIds?.length,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = flattenMetadataResponse(res);
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: websiteDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['WebsiteDetailsContainerQuery', 'WebsiteConnectionQuery'],
};

export default WebsiteQueryMethods;
