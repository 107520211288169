import { gql } from '@apollo/client';

import { leadList } from 'store/api/graph/fragments/leadList';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

import { userName } from './userName';

export const leadActivityList = gql`
  fragment LeadActivityListFragment on LeadActivity {
    id
    archived

    type
    typeName
    sourceName: source {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    leadName: lead {
      ...LeadListFragment
    }
    assignedToName: assignedTo {
      ...UserNameFragment
    }
    attributesName: attributes {
      ... on SubscriptionChangeLeadActivityAttributes {
        subscribed
      }
    }
    created
    updated
  }
  ${userName}
  ${leadList}
  ${MultilingualString}
`;
