import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  rooftopRetailFinancePricingCreate,
  rooftopRetailFinancePricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/financePricing/RooftopFinanceSegmentCreateModifyQuery';
import RooftopFinanceSegmentQueryMethods from 'components/sections/createModify/rooftopPricingSegments/financePricing/RooftopFinanceSegmentQueryMethods';
import DetailsFields from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/DetailsFields';
import DetailsStep from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/DetailsStep';
import FiltersStep from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/FiltersStep';
import { RooftopFinancePricingBuilderSteps } from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/interfaces';
import RatesFields from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/RatesFields';
import RatesStep from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/RatesStep';
import RetailFilterFields from 'components/sections/createModify/shared/steps/RetailFilterFields';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

export const detailStepTooltipConfig = {
  title: 'get_started',
  content: 'finance_pricing_segment_detail_step_tooltip_content',
};

const RooftopFinanceSegmentBuilder: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_FINANCE_SEGMENT_CREATE]: {
    getMetaData: RooftopFinanceSegmentQueryMethods.getMetaData,
    refetchQueries: RooftopFinanceSegmentQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopFinancePricingBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopFinancePricingBuilderSteps.RATES,
        step: RatesStep,
        stepName: 'configuration_one',
        isEnabled: false,
        fields: RatesFields,
      },
      {
        id: RooftopFinancePricingBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        isEnabled: false,
        fields: RetailFilterFields,
      },
    ],
    requiredPermissions: [
      {
        resource: ResourceType.ROOFTOPS,
        level: AccessLevel.ADVANCED,
      },
    ],
    mutations: {
      create: rooftopRetailFinancePricingCreate,
      validate: rooftopRetailFinancePricingCreate,
    },
  },
  [BuilderType.ROOFTOP_FINANCE_SEGMENT_MODIFY]: {
    getData: RooftopFinanceSegmentQueryMethods.getData,
    getMetaData: RooftopFinanceSegmentQueryMethods.getMetaData,
    refetchQueries: RooftopFinanceSegmentQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopFinancePricingBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopFinancePricingBuilderSteps.RATES,
        step: RatesStep,
        stepName: 'configuration_one',
        isEnabled: true,
        fields: RatesFields,
      },
      {
        id: RooftopFinancePricingBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        isEnabled: true,
        fields: RetailFilterFields,
      },
    ],
    requiredPermissions: [
      {
        resource: ResourceType.ROOFTOPS,
        level: AccessLevel.ADVANCED,
      },
    ],
    mutations: {
      modify: rooftopRetailFinancePricingModify,
    },
  },
};

export default RooftopFinanceSegmentBuilder;
