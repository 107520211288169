import { gql } from '@apollo/client';

import { leadActivityDetail } from 'store/api/graph/fragments/leadActivityDetail';

export const leadActivityCreate = gql`
  mutation LeadActivityCreateMutation(
    $rooftopId: ID
    $type: LeadActivityType
    $sourceId: ID
    $leadId: ID
    $note: String
    $tagIds: [ID!]
    $phoneCallAttributes: PhoneCallLeadActivityAttributesInput
    $purchaseAttributes: PurchaseLeadActivityAttributesInput
    $testDriveAttributes: TestDriveLeadActivityAttributesInput
    $walkInAttributes: WalkInLeadActivityAttributesInput
  ) {
    data: leadActivityCreate(
      rooftopId: $rooftopId
      type: $type
      sourceId: $sourceId
      leadId: $leadId
      note: $note
      tagIds: $tagIds
      phoneCallAttributes: $phoneCallAttributes
      purchaseAttributes: $purchaseAttributes
      testDriveAttributes: $testDriveAttributes
      walkInAttributes: $walkInAttributes
    ) {
      ...LeadActivityDetailFragment
    }
  }

  ${leadActivityDetail}
`;

export const leadActivityModify = gql`
  mutation LeadActivityModifyMutation(
    $id: ID!
    $note: String
    $leadId: ID
    $sourceId: ID
    $archived: Boolean
    $tagIds: [ID!]
    $formAttributes: FormLeadActivityAttributesInput
    $inquiryAttributes: InquiryLeadActivityAttributesInput
    $phoneCallAttributes: PhoneCallLeadActivityAttributesInput
    $purchaseAttributes: PurchaseLeadActivityAttributesInput
    $testDriveAttributes: TestDriveLeadActivityAttributesInput
    $walkInAttributes: WalkInLeadActivityAttributesInput
    $tradeInAttributes: TradeInLeadActivityAttributesInput
    $_clear: [LeadActivityModifyParameter!]
  ) {
    data: leadActivityModify(
      id: $id
      note: $note
      leadId: $leadId
      sourceId: $sourceId
      tagIds: $tagIds
      archived: $archived
      tradeInAttributes: $tradeInAttributes
      inquiryAttributes: $inquiryAttributes
      formAttributes: $formAttributes
      phoneCallAttributes: $phoneCallAttributes
      purchaseAttributes: $purchaseAttributes
      testDriveAttributes: $testDriveAttributes
      walkInAttributes: $walkInAttributes
      _clear: $_clear
    ) {
      ...LeadActivityDetailFragment
    }
  }

  ${leadActivityDetail}
`;
