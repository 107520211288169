import { gql } from '@apollo/client';

export const placeQuery = gql`
  query PlaceQuery($id: ID!, $token: String) {
    place(id: $id, token: $token) {
      id
      address
      city
      country
      countryCode
      latitude
      longitude
      region
      regionCode
      zipCode
    }
  }
`;

export const placeAutocompleteQuery = gql`
  query PlaceAutocompleteQuery($keyword: String!, $token: String, $type: PlaceAutocompleteType!) {
    placeAutocomplete(keyword: $keyword, token: $token, type: $type) {
      id
      primaryText
      secondaryText
    }
  }
`;
