import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  leadForwardCreate,
  leadForwardModify,
} from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingCreateModifyQuery';
import RooftopLeadForwardingQueryMethods from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingQueryMethods';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

export enum RooftopLeadForwardingBuilderSteps {
  DETAILS = 'DETAILS',
}

const RooftopLeadForwardingBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_LEAD_FORWARD_CREATE]: {
    refetchQueries: RooftopLeadForwardingQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopLeadForwardingBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
    mutations: {
      create: leadForwardCreate,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
  [BuilderType.ROOFTOP_LEAD_FORWARD_MODIFY]: {
    getData: RooftopLeadForwardingQueryMethods.getData,
    refetchQueries: RooftopLeadForwardingQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopLeadForwardingBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
    mutations: {
      modify: leadForwardModify,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
};

export default RooftopLeadForwardingBuilders;
