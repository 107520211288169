import styled, { css } from 'styled-components/macro';

import { Clickable } from 'components/ui/shared/Button';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_050 } from 'styles/tokens';

export const ListItemContainer = styled(Clickable)<{
  /** A boolean to control applying preset "active" styling for non-checkbox items */
  isActive?: boolean;
  /** Whether this list item shows a checkbox */
  isCheckbox?: boolean;
  /** Whether this list item is read only */
  isReadOnly?: boolean;
}>`
  width: 100%;
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      display: flex;
      flex-direction: row;
      padding: ${ENTITY_PADDING};
    `}

  ${({ isCheckbox, isActive }) =>
    !isCheckbox &&
    isActive &&
    css`
      background: ${BLUE_050};
    `};
`;
