import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  rooftopRetailLeasePricingCreate,
  rooftopRetailLeasePricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/leasePricing/RooftopLeaseSegmentCreateModifyQuery';
import RooftopLeaseSegmentQueryMethods from 'components/sections/createModify/rooftopPricingSegments/leasePricing/RooftopLeaseSegmentQueryMethods';
import AvailableMileageAllowanceFields from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/AvailableMileageAllowanceFields';
import AvailableMileageAllowanceStep from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/AvailableMileageAllowanceStep';
import DetailsFields from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/DetailsFields';
import DetailsStep from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/DetailsStep';
import FiltersStep from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/FiltersStep';
import {
  RooftopLeaseSegmentAvailableMileageAllowanceBuilderSteps,
  RooftopLeaseSegmentBuilderSteps,
} from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import RatesFields from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/RatesFields';
import RatesStep from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/RatesStep';
import RetailFilterFields from 'components/sections/createModify/shared/steps/RetailFilterFields';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

export const detailStepTooltipConfig = {
  title: 'get_started',
  content: 'lease_pricing_segment_detail_step_tooltip_content',
};

export const RooftopLeaseSegmentAvailableMileageAllowanceBuilder: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_LEASE_SEGMENT_AVAILABLE_MILEAGE_ALLOWANCE_CREATE]: {
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopLeaseSegmentAvailableMileageAllowanceBuilderSteps.DETAILS,
        step: AvailableMileageAllowanceStep,
        stepName: 'details',
        isEnabled: true,
        fields: AvailableMileageAllowanceFields,
      },
    ],
    requiredPermissions: [
      {
        resource: ResourceType.ROOFTOPS,
        level: AccessLevel.ADVANCED,
      },
    ],
  },
};

const RooftopLeaseSegmentBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_LEASE_SEGMENT_CREATE]: {
    getMetaData: RooftopLeaseSegmentQueryMethods.getMetaData,
    refetchQueries: RooftopLeaseSegmentQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopLeaseSegmentBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopLeaseSegmentBuilderSteps.RATES,
        step: RatesStep,
        stepName: 'configuration_one',
        fields: RatesFields,
      },
      {
        id: RooftopLeaseSegmentBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        fields: RetailFilterFields,
      },
    ],
    requiredPermissions: [
      {
        resource: ResourceType.ROOFTOPS,
        level: AccessLevel.ADVANCED,
      },
    ],
    mutations: {
      create: rooftopRetailLeasePricingCreate,
      validate: rooftopRetailLeasePricingCreate,
    },
  },
  [BuilderType.ROOFTOP_LEASE_SEGMENT_MODIFY]: {
    getData: RooftopLeaseSegmentQueryMethods.getData,
    getMetaData: RooftopLeaseSegmentQueryMethods.getMetaData,
    refetchQueries: RooftopLeaseSegmentQueryMethods.refetchQueries,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopLeaseSegmentBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopLeaseSegmentBuilderSteps.RATES,
        step: RatesStep,
        stepName: 'configuration_one',
        isEnabled: true,
        fields: RatesFields,
      },
      {
        id: RooftopLeaseSegmentBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        isEnabled: true,
        fields: RetailFilterFields,
      },
    ],
    requiredPermissions: [
      {
        resource: ResourceType.ROOFTOPS,
        level: AccessLevel.ADVANCED,
      },
    ],
    mutations: {
      modify: rooftopRetailLeasePricingModify,
    },
  },
};

export default RooftopLeaseSegmentBuilders;
