import type { ElementType } from 'react';
import { cloneElement } from 'react';

import { get, isBoolean, isNil, isString } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import MonetaryAmount from 'components/core/typography/MonetaryAmount';
import MultiLineText from 'components/core/typography/MultiLineText';
import PrimaryText from 'components/core/typography/PrimaryText';
import RichText from 'components/core/typography/RichText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import ItemGridCellContentExternalLink from 'components/sections/shared/ItemGridCellExternalLink';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type {
  ItemGridRowFieldProps,
  ItemGridRowSectionConfigProps,
} from 'components/sections/shared/ItemGridRowSection';
import ActionableTitle from 'components/ui/details/ActionableTitle';
import SectionRowButton from 'components/ui/details/SectionRowButton';
import Image from 'components/ui/images/Images';
import { CopyButtonGridViewWrapper, GridSection } from 'components/ui/layouts/Grid';
import Map from 'components/ui/maps/Map';
import Badge, { Badges, BadgeSizes } from 'components/ui/shared/Badge';
import { CopyButton } from 'components/ui/shared/CopyButton';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { FieldDataType } from 'enums/fieldDataType';
import { ImageSize, ImageType } from 'enums/imageType';
import { gridSectionTestId } from 'enums/testing';
import type { Location, LocationInput } from 'store/api/graph/interfaces/types';
import { PREVIEW_IMAGE_HEIGHT } from 'styles/layouts';
import { DD_PRIVACY_HTML_ATTRIBUTE } from 'types/Client';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { getField } from 'utils/formatting/inventoryItemFormatUtils';
import { formatAmountRounded, formatBoolean, formatCurrency, formatLocationData, PLACEHOLDER } from 'utils/formatUtils';
import { decimalPercentToString } from 'utils/stringUtils';
import type { BooleanFieldSettings } from 'utils/tableUtils';
import { getDurationLabel } from 'utils/timeUtils';

import { ItemColorPreview } from './ItemColorPreview';

const { DATE_YEAR_FORMAT, DAY_DATE_YEAR_FORMAT, DATE_TIME_FORMAT, TIME_STAMP_FORMAT } = DateTimeFormat;

const { BOLD, LIST_ITEM, RICH_TEXT, EXTERNAL_LINK } = ItemGridRowDisplayType;

const MapContainer = styled(Map)`
  width: 100%;
  height: 300px;
  border-radius: 5px;
`;

const ImagePreviewContainer = styled(Image)`
  height: ${PREVIEW_IMAGE_HEIGHT};

  img {
    height: auto;
  }
`;

// TODO: Refactor into a separate file if more grid cell settings added.
export type LocationFieldDataTypeSettings = {
  /** Whether or not to show the map view. Defaults to `true`. */
  showMap?: boolean;
};

export type MonetaryFieldDataTypeSettings = {
  /** Whether or not to color code prices. Default is GREEN_500 for all `MonetaryAmount`. */
  disableColorHighlighting?: boolean;
};

export type PercentageFieldDataTypeSettings = {
  /** The number of decimal places to show in a percentage (default is 2) */
  decimalPlaces?: number;
};

export interface ItemGridCellStyleProps {
  /** Styling options for the row */
  displayType?: ItemGridRowDisplayType[];
  /**
   * Generic element to be rendered depending on the `displayType`.
   * e.g. For the LIST_ITEM `displayType` it is required.
   */
  renderElement?: ElementType;
}

export interface ItemGridCellProps extends ItemGridRowFieldProps, ItemGridRowSectionConfigProps {
  id: string;
}

const withCopy =
  (Component: ElementType) =>
  ({ ...props }: { children?: string }) =>
    props.children && props.children !== PLACEHOLDER ? (
      <CopyButtonGridViewWrapper>
        <Component {...props} />
        <CopyButton textToCopy={props.children} />
      </CopyButtonGridViewWrapper>
    ) : (
      <Component {...props} />
    );

export const ItemGridCell = ({
  id,
  item,
  metadata,
  settings,
  displayType,
  onEdit,
  renderElement: RenderElement,
  gridCellRenderMethod,
  canEdit,
  defaultPrivacyLevel,
  canCopy,
  isLocked,
  ...props
}: ItemGridCellProps) => {
  let content;
  const fieldValue = get(item, `${id}.value`, get(item, id));
  const fieldValueName = getField(item, `${id}Name`);
  const TextStyleClassBase = displayType?.includes(BOLD) ? PrimaryText : SecondaryText;
  const TextStyleClass = canCopy ? withCopy(TextStyleClassBase) : TextStyleClassBase;
  const customGridCellContent = gridCellRenderMethod?.(settings, item, id, metadata);

  if (customGridCellContent) {
    // Custom grid cell content
    content = customGridCellContent;
  } else if (displayType?.includes(LIST_ITEM) && RenderElement) {
    // Content for generic `ListItem`
    content = fieldValue ? (
      <SectionRowButton>
        <RenderElement {...fieldValue} />
      </SectionRowButton>
    ) : (
      <TextStyleClass>{PLACEHOLDER}</TextStyleClass>
    );
  } else if (displayType?.includes(RICH_TEXT)) {
    content = (
      <MultiLineText>
        <RichText>{fieldValue || PLACEHOLDER}</RichText>
      </MultiLineText>
    );
  } else if (displayType?.includes(EXTERNAL_LINK)) {
    content = <ItemGridCellContentExternalLink to={fieldValue} />;
  } else {
    const tableSettings = settings[id];
    if (!tableSettings) {
      throw new Error(`No TableSettingsType found for field: ${id}`);
    }

    // Content based on `FieldDataType`
    switch (tableSettings.type) {
      case FieldDataType.IMAGE: {
        content = fieldValue ? (
          <ImagePreviewContainer src={fieldValue} size={ImageSize.FILL} type={ImageType.PHOTO} />
        ) : (
          <TextStyleClass>{PLACEHOLDER}</TextStyleClass>
        );
        break;
      }

      case FieldDataType.STATUS: {
        content = <TextStyleClass>{fieldValueName}</TextStyleClass>;
        break;
      }

      case FieldDataType.BADGE_LIST: {
        // Use server translated field value otherwise derive from metadata
        const rawFieldValueNames = get(item, `${id}Names`); // `IdNames` is a common format for an array of server translated field values
        const metadataDerivedFieldValues = getField(item, id, metadata, undefined, []);
        const displayedFieldValues = rawFieldValueNames || metadataDerivedFieldValues;
        content = (
          <Badges>
            {displayedFieldValues?.length ? (
              displayedFieldValues.map(badge => {
                const label = badge?.name?.value || badge?.name || badge;
                return (
                  <Badge title={`${tableSettings.label}: ${label}`} size={BadgeSizes.LARGE} key={badge?.id || badge}>
                    {label}
                  </Badge>
                );
              })
            ) : (
              <PrimaryText>{PLACEHOLDER}</PrimaryText>
            )}
          </Badges>
        );
        break;
      }

      case FieldDataType.COLOR_DESCRIBED:
      case FieldDataType.COLOR_PREVIEW: {
        content = (
          <TextRow
            css={css`
              grid-gap: 10px;
            `}
          >
            {!!fieldValue && <ItemColorPreview color={fieldValue} metadata={metadata} />}
            <TextStyleClass>{fieldValueName}</TextStyleClass>
          </TextRow>
        );
        break;
      }

      case FieldDataType.FORMATTED_AMOUNT: {
        content = <TextStyleClass>{fieldValue?.formattedAmount || PLACEHOLDER}</TextStyleClass>;
        break;
      }

      case FieldDataType.MONETARY: {
        const { disableColorHighlighting = false } = (props.cellSettings || {}) as MonetaryFieldDataTypeSettings;
        const MonetaryColorClass = disableColorHighlighting ? TextStyleClass : MonetaryAmount;
        const isNumeric = typeof fieldValue === 'number';

        /**
         * If the value is numeric, format it as CAD (for currency symol display purposes),
         * then put a space between the symbol and the digits for cleaner display.
         * Otherwise, just retrieve the nested field value.
         */
        const formattedValue = isNumeric
          ? formatCurrency({ amount: fieldValue }).replace(/(\D)(\d+)/, '$1 $2')
          : formatAmountRounded(fieldValue);

        content = formattedValue ? (
          <MonetaryColorClass>{formattedValue}</MonetaryColorClass>
        ) : (
          <TextStyleClass>{PLACEHOLDER}</TextStyleClass>
        );
        break;
      }

      case FieldDataType.LOCATION: {
        const { showMap = true } = (props.cellSettings || {}) as LocationFieldDataTypeSettings;

        const formattedData = formatLocationData(fieldValue || item);
        const { address, city, regionCode, country, zipCode, latitude, longitude, placeId } =
          (formattedData as Extract<Location, LocationInput>) || {};
        const formattedLocation =
          address &&
          city &&
          regionCode &&
          country &&
          zipCode &&
          `${address}, ${city}, ${regionCode}, ${country}, ${zipCode}`;

        content = formattedLocation ? (
          <>
            <TextStyleClass title={formattedLocation}>{formattedLocation}</TextStyleClass>
            {!!isString(placeId) && showMap && (
              <MapContainer zoom={15} locations={[{ latitude, longitude }]} isClickEnabled={false} />
            )}
          </>
        ) : (
          <TextStyleClass>{PLACEHOLDER}</TextStyleClass>
        );
        break;
      }

      case FieldDataType.PERCENTAGE: {
        const { decimalPlaces = 2 } = (props.cellSettings || {}) as PercentageFieldDataTypeSettings;
        content = (
          <TextStyleClass>
            {isNil(fieldValue) ? PLACEHOLDER : decimalPercentToString(fieldValue, decimalPlaces)}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.DATE: {
        content = (
          <TextStyleClass>
            {fieldValue ? getFormattedDateTimeString(fieldValue, DATE_YEAR_FORMAT) : PLACEHOLDER}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.DAY_MONTH_YEAR: {
        content = (
          <TextStyleClass>
            {fieldValue ? getFormattedDateTimeString(fieldValue, DAY_DATE_YEAR_FORMAT) : PLACEHOLDER}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.DAY_TIME: {
        content = (
          <TextStyleClass>
            {fieldValue ? getFormattedDateTimeString(fieldValue, DATE_TIME_FORMAT) : PLACEHOLDER}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.TIME: {
        content = (
          <TextStyleClass>
            {fieldValue ? getFormattedDateTimeString(fieldValue, TIME_STAMP_FORMAT) : PLACEHOLDER}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.DURATION: {
        content = <TextStyleClass>{getDurationLabel(fieldValue)}</TextStyleClass>;
        break;
      }

      case FieldDataType.DISPLAY_NAME: {
        content = <TextStyleClass>{fieldValue?.displayName || PLACEHOLDER}</TextStyleClass>;
        break;
      }

      case FieldDataType.NAME: {
        content = <TextStyleClass>{fieldValue?.name?.value || fieldValue?.name || PLACEHOLDER}</TextStyleClass>;
        break;
      }

      case FieldDataType.BOOLEAN: {
        content = (
          <TextStyleClass>
            {isBoolean(fieldValue)
              ? formatBoolean(fieldValue, (tableSettings as BooleanFieldSettings).yesNoOverride)
              : PLACEHOLDER}
          </TextStyleClass>
        );
        break;
      }

      case FieldDataType.ENUM: {
        // Use server translated field value otherwise derive from metadata
        const rawFieldValueName = get(item, `${id}Name`); // `IdName` is a common format for a single server translated field value
        const metadataDerivedFieldValue = getField(item, id, metadata);
        const displayedFieldValue = rawFieldValueName || metadataDerivedFieldValue;
        content = <TextStyleClass>{displayedFieldValue}</TextStyleClass>;
        break;
      }

      default: {
        content = <TextStyleClass>{fieldValue || PLACEHOLDER}</TextStyleClass>;
        break;
      }
    }
  }

  return (
    <GridSection data-testid={gridSectionTestId(id)}>
      <ActionableTitle
        onEdit={canEdit ? onEdit?.bind(null, id) : undefined}
        label={settings?.[id]?.label || id}
        tooltip={settings?.[id]?.tooltip}
        testId={id}
        isLocked={isLocked}
      />
      {cloneElement(content, {
        ...(!!defaultPrivacyLevel && { [DD_PRIVACY_HTML_ATTRIBUTE]: defaultPrivacyLevel }),
        title:
          typeof content?.props.children === 'string' && content?.props.children !== PLACEHOLDER
            ? content?.props.children
            : '',
      })}
    </GridSection>
  );
};
