/**
 * Used to log `breadcrumbs` or custom actions in Datadog
 * views e.g. `Sessions` for easy debugging.
 */
export enum DatadogCustomAction {
  /** Track apollo error request data */
  APOLLO_ERROR_REQUEST = 'APOLLO_ERROR_REQUEST',
  /** Track apollo error response data */
  APOLLO_ERROR_RESPONSE = 'APOLLO_ERROR_RESPONSE',
  /** Track when error boundary is shown to user */
  ERROR_BOUNDARY = 'ERROR_BOUNDARY',
}
