import { isEqual } from 'lodash-es';

import type Step from 'components/core/createModify/interfaces/step';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import LoggingService from 'components/core/logging/LoggingService';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';
import { type Mileage, PaymentOption } from 'store/api/graph/interfaces/types';
import { PricingSource } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import { getStepField, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { formatMileage } from 'utils/formatUtils';
import { translateField } from 'utils/intlUtils';

import { AutoPriceAdjustmentBaseFields } from './interfaces';
import { chromeInterestRatesToolip } from './tooltip';

type SelectOptionWithStringData = Omit<SelectOption, 'data'> & { data: string };

/**
 * Transform available mileage allowance options from metadata by parsing the data field so that the units can be
 * extracted.
 *
 * @param option - a SelectOption with string data
 */
export const transformAvailableMileageAllowanceOptions = (
  option: SelectOptionWithStringData
): SelectOptionWithStringData | null => {
  try {
    const parsedData = JSON.parse(option.data);
    // If there are no units included in these options then just ignore them
    return parsedData?.unit
      ? {
          id: option.id,
          name: option.name,
          data: parsedData.unit,
        }
      : null;
  } catch (error) {
    if (error instanceof Error) {
      LoggingService.captureException(error, { info: 'Error reading available mileage allowance metadata' });
    }
    return null;
  }
};

/**
 * Transform Mileage data (as saved by the user) by parsing the mileage amount/units and then formatting the readable
 * name based on those values.
 *
 * @param option - The Mileage data being transformed
 */
export const transformSavedAvailableMileageAllowanceOptions = (option: Mileage): SelectOptionWithStringData => ({
  id: option.amount.toString(),
  name: formatMileage(option.amount, option.unit),
  data: option.unit,
});

/** Add or remove the chrome rates tooltip based on pricing source */
export const setChromeRatesTooltip = (
  pricingSourceSelectedValue: StepField['selectedValue'],
  activeStep?: Step<any, any>
) => {
  if (activeStep) {
    const tooltipTranslated = translateField(chromeInterestRatesToolip, ['title', 'content']);
    const isCurrentTooltipNotChrome = !isEqual(tooltipTranslated, activeStep.tooltip);
    const prevOrEmptyTooltip = isCurrentTooltipNotChrome ? activeStep.tooltip : undefined;
    activeStep.tooltip =
      pricingSourceSelectedValue === PricingSource.CHROME && isCurrentTooltipNotChrome
        ? tooltipTranslated
        : prevOrEmptyTooltip;
  }
};

export const toggleAutoPriceAdjustmentField = (
  paymentOption: PaymentOption,
  fields: StepField<any, any, any>[],
  enabled: boolean
) => {
  let paymentOptionPointer;

  switch (paymentOption) {
    case PaymentOption.CASH: {
      paymentOptionPointer = RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION;
      break;
    }

    case PaymentOption.FINANCE: {
      paymentOptionPointer = RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION;
      break;
    }

    default: {
      paymentOptionPointer = RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION;
    }
  }

  const autoPriceAdjustmentEnabledField = getStepField(
    AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_ENABLED,
    fields
  );
  const targetPriceField = getStepField(
    `${paymentOptionPointer}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_TARGET_PRICE}`,
    fields
  );
  const labelField = getStepField(
    `${paymentOptionPointer}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_LABEL}`,
    fields
  );
  const disclaimerTemplateField = getStepField(
    `${paymentOptionPointer}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE}`,
    fields
  );

  autoPriceAdjustmentEnabledField.hasSeparator = !enabled;
  for (const field of [targetPriceField, labelField, disclaimerTemplateField]) {
    setDisplayTypes(
      [
        { type: StepFieldDisplayType.HIDDEN, active: !enabled },
        { type: StepFieldDisplayType.OMITTED, active: !enabled },
      ],
      field
    );
  }
};

export const getVariablePresets = (rooftopId: string | undefined) => ({
  rooftopId,
  filter: {},
});
/*
 * The enabled field is setup to clear the autoPriceAdjustmentConfiguration when null, so it should be omitted
 * in all instances execpt when modifying a segment to clear the autoPriceAdjustmentConfiguration
 */
export const prepareForAutoPriceAdjustmentClearing = (
  autoPriceAdjustmentEnabledField: StepField<any, any, boolean | null>
) => {
  if (!autoPriceAdjustmentEnabledField.selectedValue) {
    autoPriceAdjustmentEnabledField.selectedValue = null;
  }
  setDisplayTypes(
    { type: StepFieldDisplayType.OMITTED, active: !!autoPriceAdjustmentEnabledField.selectedValue },
    autoPriceAdjustmentEnabledField
  );
};
