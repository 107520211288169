export enum LeadDetailsBuilderFields {
  ROOFTOP_ID = 'rooftopId',
  AVATAR_IMAGE = 'avatarImage',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTHDATE = 'birthdate',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  CONTACT_METHOD = 'contactMethod',
  CONTACT_TIME = 'contactTime',
  STATUS = 'status',
  PAYMENT_OPTIONS = 'paymentOptions',
  NOTE = 'note',
  TAG_IDS = 'tagIds',
  SUBSCRIBED_EMAIL = 'subscribedEmail',
  SUBSCRIBED_PROMOTIONS = 'subscribedPromotions',
  LOCALE = 'locale',
}

export enum LeadGeneralInformationBuilderFields {
  SALUTATION = 'salutation',
  GENDER = 'gender',
  MARITAL_STATUS = 'maritalStatus',
  COMPANY_NAME = 'company',
  DRIVERS_LICENSE = 'driversLicenseNumber',
  DECLARED_BANKRUPTCY = 'declaredBankruptcy',
  COSIGNER = 'cosigner',
  ADDRESS = 'address',
  HOME_STATUS = 'homeStatus',
  /** Not directly used; fed into `currentAddressMonths` in tandem with DURATION_MONTHS */
  DURATION_YEARS = 'durationYears',
  /** Not directly used; fed into `currentAddressMonths` in tandem with DURATION_YEARS */
  DURATION_MONTHS = 'durationMonths',
  PREVIOUS_ADDRESS = 'previousAddress',
  /** Not directly used; fed into `previousAddressMonths` in tandem with PREVIOUS_DURATION_MONTHS */
  PREVIOUS_DURATION_YEARS = 'previousDurationYears',
  /** Not directly used; fed into `previousAddressMonths` in tandem with PREVIOUS_DURATION_YEARS */
  PREVIOUS_DURATION_MONTHS = 'previousDurationMonths',
}

export enum LeadEmploymentInformationBuilderFields {
  TYPE = 'employment.type',
  STATUS = 'employment.status',
  EMPLOYER = 'employment.employer',
  OCCUPATION = 'employment.occupation',
  SIN = 'sin',
  /** Not directly used; fed into `durationMonths` in tandem with DURATION_MONTHS */
  DURATION_YEARS = 'employment.durationYears',
  /** Not directly used; fed into `durationMonths` in tandem with DURATION_YEARS */
  DURATION_MONTHS = 'employment.durationMonths',
  PHONE_NUMBER = 'employment.phoneNumber',
  MONTHLY_INCOME = 'employment.monthlyIncome',
  OTHER_INCOME = 'employment.otherIncome',
  OTHER_INCOME_SOURCE = 'employment.otherIncomeSource',
}

export enum LeadPreviousEmploymentInformationBuilderFields {
  TYPE = 'previousEmployment.type',
  STATUS = 'previousEmployment.status',
  EMPLOYER = 'previousEmployment.employer',
  OCCUPATION = 'previousEmployment.occupation',
  /** Not directly used; fed into `durationMonths` in tandem with DURATION_MONTHS */
  DURATION_YEARS = 'previousEmployment.durationYears',
  /** Not directly used; fed into `durationMonths` in tandem with DURATION_YEARS */
  DURATION_MONTHS = 'previousEmployment.durationMonths',
  PHONE_NUMBER = 'previousEmployment.phoneNumber',
  MONTHLY_INCOME = 'previousEmployment.monthlyIncome',
  OTHER_INCOME = 'previousEmployment.otherIncome',
  OTHER_INCOME_SOURCE = 'previousEmployment.otherIncomeSource',
}

export enum LeadBuilderSteps {
  DETAILS = 'LEAD_DETAILS',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  EMPLOYMENT_INFORMATION = 'EMPLOYMENT_INFORMATION',
  PREVIOUS_EMPLOYMENT = 'PREVIOUS_EMPLOYMENT',
  LINKED = 'LEAD_LINKED',
}
