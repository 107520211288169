// Space
/** spacing: 0px */
export const SPACE_0 = '0px';
/** spacing: 2px */
export const SPACE_050 = '2px';
/** spacing: 4px */
export const SPACE_100 = '4px';
/** spacing: 6px */
export const SPACE_150 = '6px';
/** spacing: 8px */
export const SPACE_200 = '8px';
/** spacing: 12px */
export const SPACE_300 = '12px';
/** spacing: 16px */
export const SPACE_400 = '16px';
/** spacing: 20px */
export const SPACE_500 = '20px';
/** spacing: 24px */
export const SPACE_600 = '24px';
/** spacing: 32px */
export const SPACE_700 = '32px';
/** spacing: 40px */
export const SPACE_800 = '40px';
/** spacing: 48px */
export const SPACE_900 = '48px';
/** spacing: 56px */
export const SPACE_1000 = '56px';
/** spacing: 64px */
export const SPACE_1100 = '64px';
/** spacing: 72px */
export const SPACE_1200 = '72px';
/** spacing: 80px */
export const SPACE_1300 = '80px';
/** spacing: 88px */
export const SPACE_1400 = '88px';
/** spacing: 96px */
export const SPACE_1500 = '96px';
