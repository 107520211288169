import type { ReactNode } from 'react';
import { useState } from 'react';

import styled from 'styled-components/macro';

import EditIcon from 'components/ui/icons/EditIcon';
import TrashCanIcon from 'components/ui/icons/TrashCanIcon';
import { NEUTRAL_0, NEUTRAL_900 } from 'styles/tokens';
import { Z_INDEX_2, Z_INDEX_3 } from 'styles/z-index';
import { hexToRGBA } from 'utils/styledUtils';

const ImageActionsContainer = styled.div<{ isHovering?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div<{ isHovering?: boolean }>`
  height: 100%;

  > * {
    height: 100%;
  }

  ${({ isHovering }) =>
    !!isHovering &&
    `
  :before {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Z_INDEX_2};
    content:"";
    border-radius: 8px;
    background: hsla(0, 0%, 0%, 0.25); 
  }
  `}
`;

const PhotoButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: ${Z_INDEX_3};

  svg {
    margin-left: 7px;
    cursor: pointer;
    fill: ${NEUTRAL_0};
    color: ${NEUTRAL_0};
    filter: drop-shadow(2px 2px 1px ${hexToRGBA(NEUTRAL_900, '0.5')});
  }
`;

interface Props {
  onEdit: () => void;
  onDelete?: () => void;
  children: ReactNode;
}

const ImageActions = ({ onEdit, onDelete, children }: Props) => {
  const [isHovering, setHovering] = useState<boolean>(false);

  return (
    <ImageActionsContainer onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <ImageContainer isHovering={isHovering}>{children}</ImageContainer>
      <PhotoButtons>
        {!!onDelete && <TrashCanIcon fill={NEUTRAL_0} height="16px" width="16px" onClick={() => onDelete()} />}
        <EditIcon height="15px" width="15px" onClick={() => onEdit()} />
      </PhotoButtons>
    </ImageActionsContainer>
  );
};

export default ImageActions;
