import { memo, useCallback, useMemo } from 'react';

import { cloneDeep, get, isNil } from 'lodash-es';

import PrimaryText from 'components/core/typography/PrimaryText';
import { RetailItemFlags } from 'components/sections/inventoryItems/retailItems/RetailItemFlags';
import { getEditBuilderForIntegrationType } from 'components/sections/rooftops/integrations/utils';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { getFilterField, RetailFilters, RetailFiltersSettings } from 'enums/columns/retailFilters';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FieldDataType } from 'enums/fieldDataType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import { getPricingSegmentBuilderFromPaymentOption } from 'utils/formatting/pricingUtils';
import { formatRange, PLACEHOLDER } from 'utils/formatUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { getInventoryItemTypeConfig } from 'utils/inventoryItemUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const { BOLD } = ItemGridRowDisplayType;
const { t } = translate;

export const RetailFiltersTab = memo<ItemTabProps>(({ item: itemData, metadata }) => {
  const { builderConfig } = useBuilderConfig();
  const { hasPermissions } = useUser();

  const filterData = useMemo(
    () => (itemData?.filter ? itemData : { filter: itemData?.inventoryExportAttributes?.filter }),
    [itemData]
  );

  const type = get(filterData, RetailFilters.FILTER_TYPE) as InventoryItemType;
  const makeIds = get(filterData, RetailFilters.FILTER_MAKE_ID);
  const makeIdsExcluded = get(filterData, RetailFilters.FILTER_MAKE_ID_EXCLUDED);
  const modelIds = get(filterData, RetailFilters.FILTER_MODEL_ID);

  const getFilterGridConfigByType = useCallback(
    (inventoryItemType: InventoryItemType) => {
      // Filter configs
      const type = {
        fields: [RetailFilters.FILTER_TYPE],
        displayType: [BOLD],
        canEdit: true,
      };
      const tags = {
        fields: [RetailFilters.FILTER_TAG_ID],
        displayType: [BOLD],
        canEdit: true,
      };
      const status =
        itemData.__typename === 'RooftopIntegration'
          ? undefined
          : {
              fields: [RetailFilters.FILTER_STATUS],
              displayType: [BOLD],
              canEdit: true,
            };
      const condition = {
        fields: [getFilterField(InventoryItem.CONDITION)],
        displayType: [BOLD],
        canEdit: true,
      };
      const flags = {
        fields: ['flags'],
        canEdit: true,
        gridCellRenderMethod: (_, item) => {
          const flagTargets = [
            getFilterField(InventoryItem.MAPPED),
            getFilterField(InventoryItem.SHOW_WEB),
            getFilterField(InventoryItem.AS_IS),
            getFilterField(InventoryItem.CERTIFIED),
            getFilterField(InventoryItem.DEMO),
            getFilterField(InventoryItem.FEATURED),
            getFilterField(InventoryItem.ON_ORDER),
            getFilterField(InventoryItem.IN_TRANSIT),
            getFilterField(InventoryItem.COMPLETE),
            RetailFilters.FILTER_HAS_MILEAGE,
            RetailFilters.FILTER_HAS_PRICE,
          ];
          return flagTargets.some(flag => !isNil(get(item, flag))) ? (
            <RetailItemFlags flagData={item} flagTargets={flagTargets} settings={RetailFiltersSettings} />
          ) : (
            <PrimaryText>{PLACEHOLDER}</PrimaryText>
          );
        },
      };
      const inventoryItemConfig = getInventoryItemTypeConfig(inventoryItemType);
      const mileage = inventoryItemConfig.mileageField
        ? {
            fields: [getFilterField(inventoryItemConfig.mileageField)],
            displayType: [BOLD],
            canEdit: true,
            gridCellRenderMethod: (_, item, id) => {
              const mileage = get(item, id);
              return <PrimaryText>{formatRange(mileage, 'km')}</PrimaryText>;
            },
          }
        : undefined;
      const yearRange = {
        fields: [RetailFilters.FILTER_YEAR_RANGE],
        displayType: [BOLD],
        canEdit: true,
        gridCellRenderMethod: (_, item, id) => {
          const yearRange = get(item, id);
          return <PrimaryText>{formatRange(yearRange)}</PrimaryText>;
        },
      };
      const daysInStockRange = {
        fields: [RetailFilters.FILTER_DAYS_IN_STOCK],
        displayType: [BOLD],
        canEdit: true,
        gridCellRenderMethod: (_, item, id) => {
          const daysInStockRange = get(item, id);
          return <PrimaryText>{formatRange(daysInStockRange)}</PrimaryText>;
        },
      };
      const makes = {
        fields: [RetailFilters.FILTER_MAKE_ID],
        displayType: [BOLD],
        canEdit: !makeIdsExcluded?.length,
      };
      const excludedMakes = {
        fields: [RetailFilters.FILTER_MAKE_ID_EXCLUDED],
        displayType: [BOLD],
        canEdit: !makeIds?.length,
      };
      const models = {
        fields: [RetailFilters.FILTER_MODEL_ID],
        displayType: [BOLD],
        canEdit: !!makeIds?.length,
      };
      const subModels = {
        fields: [RetailFilters.FILTER_SUBMODEL_ID],
        displayType: [BOLD],
        canEdit: !!modelIds?.length,
      };
      const bodyType =
        inventoryItemType === InventoryItemType.VEHICLE
          ? {
              fields: [RetailFilters.FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE],
              displayType: [BOLD],
              canEdit: true,
            }
          : undefined;
      const fuelType =
        inventoryItemType === InventoryItemType.VEHICLE
          ? {
              fields: [RetailFilters.FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE],
              displayType: [BOLD],
              canEdit: true,
            }
          : undefined;

      // WYSIWYG inventory filter config
      return [
        type,
        tags,
        condition,
        status,
        flags,
        mileage,
        yearRange,
        daysInStockRange,
        makes,
        excludedMakes,
        models,
        subModels,
        bodyType,
        fuelType,
      ].filter(Boolean);
    },
    [itemData.__typename, makeIds?.length, makeIdsExcluded?.length, modelIds?.length]
  );

  /**
   * Note: This grid is always used in a nested view, so will always see one field per row.
   *
   * TODO [#2351]: Update custom cells
   *  - Use range localized labels for range objects from api
   */
  const gridConfig: ItemGridRowProps[] = useMemo(
    () => [...getFilterGridConfigByType(type)].filter(({ fields }) => fields.length > 0),
    [getFilterGridConfigByType, type]
  );

  const settings = useMemo(
    () => ({ ...RetailFiltersSettings, __typename: { label: 'type', type: FieldDataType.DEFAULT } }),
    []
  );

  const builderType = useMemo(() => {
    switch (itemData.__typename) {
      case 'RetailBulkAdjustment': {
        return { entityType: CustomEntity.ROOFTOP_BULK_ADJUSTMENT, type: BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY };
      }

      case 'RooftopIntegration': {
        return {
          entityType: CustomEntity.ROOFTOP_INTEGRATIONS,
          type: getEditBuilderForIntegrationType(itemData?.integrationType?.id),
        };
      }

      default: {
        return getPricingSegmentBuilderFromPaymentOption(itemData.paymentOption, { isModify: true });
      }
    }
  }, [itemData.__typename, itemData?.integrationType?.id, itemData.paymentOption]);

  const isAllowedToEdit = useMemo(
    () => !!builderType?.type && hasPermissions(builderConfig[builderType.type].requiredPermissions),
    [builderType?.type, hasPermissions, builderConfig]
  );

  const editTitle = useMemo(() => {
    switch (itemData.__typename) {
      case 'RetailBulkAdjustment': {
        return t('adjustment_one');
      }

      case 'RooftopIntegration': {
        return t('integration_one');
      }

      default: {
        return `${t('pricing_segment_one')} (${filterData.paymentOptionName})`;
      }
    }
  }, [filterData.paymentOptionName, itemData.__typename]);

  const onEdit = useEditField({
    tierData: {
      tierId: CreateModifyTiers.TIER_0,
      title: editTitle,
      itemId: itemData.id,
      ...builderType,
    },
  });

  const onEditCallback = useCallback(field => onEdit(field, undefined, cloneDeep(itemData)), [onEdit, itemData]);

  return filterData ? (
    <>
      {gridConfig.map(config => (
        <ItemGridRowSection
          key={formatItemGridRowSectionKey(config.fields)}
          item={filterData}
          metadata={metadata?.mutation}
          settings={settings}
          config={config}
          onEdit={authorizedCallback({
            cb: onEditCallback,
            isAuth: isAllowedToEdit,
          })}
        />
      ))}
    </>
  ) : null;
});
