import type { HTMLAttributes } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components/macro';

import AddEntityNavComponent from 'components/core/navigation/operations/AddEntityNavComponent';
import FeedbackMenuNavItem from 'components/core/navigation/operations/FeedbackMenuNavItem';
import UserMenuNavItem from 'components/core/navigation/operations/UserMenuNavItem';
import TitledNavLinkItem from 'components/core/navigation/TitledNavLinkItem';
import TopNavLinkItem from 'components/core/navigation/TopNavLinkItem';
import AppointmentsIcon from 'components/ui/icons/AppointmentsIcon';
import ConversationsIcon from 'components/ui/icons/ConversationsIcon';
import GlobeIcon from 'components/ui/icons/GlobeIcon';
import IncomingIcon from 'components/ui/icons/IncomingIcon';
import PersonSearchIcon from 'components/ui/icons/PersonSearchIcon';
import RooftopsIcon from 'components/ui/icons/RooftopsIcon';
import TasksIcon from 'components/ui/icons/TasksIcon';
import TradeInIcon from 'components/ui/icons/TradeInIcon';
import UsersIcon from 'components/ui/icons/UsersIcon';
import VehicleIcon from 'components/ui/icons/VehicleIcon';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import { useUser } from 'hooks/contexts/useUser';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { GLOBAL_NAV_WIDTH } from 'styles/layouts';
import { NEUTRAL_800, NEUTRAL_900, SPACE_300, SPACE_600, WIDTH_600 } from 'styles/tokens';
import { doUserRooftopsHaveFeatureEnabled } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';
import { fullGroupOrAboveScopes, nonWhiteLabelScopes } from 'utils/permissionUtils';

const GlobalNavContainer = styled.nav`
  background: ${NEUTRAL_800};
  display: flex;
  flex-direction: column;
  width: ${GLOBAL_NAV_WIDTH};
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: ${SPACE_300};
  row-gap: ${SPACE_600};
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${SPACE_300};
`;

const NavItemSeparator = styled.div`
  height: 1px;
  width: ${WIDTH_600};
  background-color: ${NEUTRAL_900};
  margin: 0 auto;
`;

const GlobalNav = (props: HTMLAttributes<HTMLElement>) => {
  const { t } = translate;
  const {
    user: { id, rooftops },
    userNotifications: { hasIncomingLeadActivities, hasUnreadConversations },
    hasPermissions,
  } = useUser();
  const flags = useFlags();

  // User has not been loaded, do not render route options
  if (!id) {
    return (
      <GlobalNavContainer {...props}>
        <NavContainer>
          <TopNavLinkItem to={RoutePath.HOME} />
        </NavContainer>
      </GlobalNavContainer>
    );
  }

  const userHasBasicRooftopPermissions = hasPermissions([
    {
      resource: ResourceType.ROOFTOPS,
      level: AccessLevel.BASIC,
    },
  ]);

  // Feature bundle checks to see if the user has access to certain features
  const isLeadFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.LEAD,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isConversationFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.CONVERSATION,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isAppointmentFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.APPOINTMENT,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isTaskFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.TASK,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isTradeInFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.TRADE_IN,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  const isRetailFeatureEnabledForUser = doUserRooftopsHaveFeatureEnabled({
    userRooftops: rooftops,
    feature: FeatureBundleSet.RETAIL,
    featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
  });

  return (
    <GlobalNavContainer {...props}>
      <TopNavLinkItem
        to={flags[LDFeatureFlags.dashboardEnabled] ? RoutePath.HOME : RoutePath.RETAIL_ITEMS}
        disabled={!userHasBasicRooftopPermissions}
      />
      <NavWrapper>
        <NavContainer>
          <AddEntityNavComponent />

          <NavItemSeparator />

          {isRetailFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<VehicleIcon />}
              to={RoutePath.RETAIL_ITEMS}
              title={t('retail_inventory')}
              resourceType={ResourceType.RETAIL_ITEMS}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_RETAIL_ITEM}
            />
          )}

          {isTradeInFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<TradeInIcon />}
              to={RoutePath.TRADE_IN_ITEMS}
              title={t('trade_in_inventory')}
              resourceType={ResourceType.TRADE_IN_ITEMS}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_TRADE_IN}
            />
          )}

          {isLeadFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<IncomingIcon />}
              to={RoutePath.LEAD_ACTIVITIES}
              title={t('incoming')}
              resourceType={ResourceType.LEADS}
              scope={nonWhiteLabelScopes}
              hasNotification={hasIncomingLeadActivities}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_LEAD_ACTIVITY}
            />
          )}

          {isLeadFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<PersonSearchIcon />}
              to={RoutePath.LEADS}
              title={t('lead_other')}
              resourceType={ResourceType.LEADS}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_LEAD}
            />
          )}

          {isConversationFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<ConversationsIcon />}
              to={RoutePath.CONVERSATIONS}
              title={t('conversations')}
              resourceType={ResourceType.LEADS}
              hasNotification={hasUnreadConversations}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_CONVERSATION}
            />
          )}

          {isAppointmentFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<AppointmentsIcon />}
              to={RoutePath.APPOINTMENTS}
              title={t('appointments_other')}
              resourceType={ResourceType.APPOINTMENTS}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_APPOINTMENT}
            />
          )}

          {isTaskFeatureEnabledForUser && (
            <TitledNavLinkItem
              icon={<TasksIcon />}
              to={RoutePath.TASKS}
              title={t('tasks_other')}
              resourceType={ResourceType.TASKS}
              testId={ElementTestId.GLOBAL_NAV_MENU_LINK_TASK}
            />
          )}
        </NavContainer>

        <NavContainer>
          {flags[LDFeatureFlags.websiteSectionEnabled] && (
            <TitledNavLinkItem
              icon={<GlobeIcon />}
              to={RoutePath.WEBSITES}
              title={t('websites')}
              resourceType={ResourceType.WEBSITES}
              scope={fullGroupOrAboveScopes}
            />
          )}

          <TitledNavLinkItem
            icon={<RooftopsIcon />}
            to={RoutePath.ROOFTOPS}
            title={t('rooftops')}
            resourceType={ResourceType.ROOFTOPS}
          />

          <TitledNavLinkItem
            icon={<UsersIcon />}
            to={RoutePath.USERS}
            title={t('users')}
            resourceType={ResourceType.USERS}
          />

          <NavItemSeparator />

          <UserMenuNavItem />

          <FeedbackMenuNavItem title={t('help')} />
        </NavContainer>
      </NavWrapper>
    </GlobalNavContainer>
  );
};

export default GlobalNav;
