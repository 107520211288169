import Badge from 'components/ui/shared/Badge';
import { StatusColors } from 'enums/statusColors';
import type {
  RetailItemResponseType,
  TradeInItemResponseType,
  WebsiteResponseType,
} from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  /** Item to retrieve status of for badge */
  item: RetailItemResponseType | TradeInItemResponseType | WebsiteResponseType;
}

const StatusBadge = ({ item, ...props }: Props) => {
  const statusColor =
    StatusColors[item.status] ||
    StatusColors[(item as RetailItemResponseType).retailItemStatus] ||
    StatusColors[(item as TradeInItemResponseType).tradeInItemStatus];
  return statusColor ? (
    <Badge title={`${t('status')}: ${item.statusName}`} color={statusColor} {...props}>
      {item.statusName}
    </Badge>
  ) : null;
};

export default StatusBadge;
