import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import RooftopDepartmentStepFields from 'components/sections/createModify/rooftopDepartments/steps/DetailsFields';
import RooftopDepartmentDetailsStep from 'components/sections/createModify/rooftopDepartments/steps/DetailsStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { rooftopDepartmentCreate, rooftopDepartmentModify } from './RooftopDepartmentsCreateModifyQueries';
import RooftopDepartmentsQueryMethods from './RooftopDepartmentsQueryMethods';

const RooftopDepartmentsBuilder: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_DEPARTMENTS_CREATE]: {
    refetchQueries: RooftopDepartmentsQueryMethods.refetchQueries,
    getMetaData: RooftopDepartmentsQueryMethods.getMetaData,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: 'ROOFTOP_DEPARTMENTS_DETAILS',
        step: RooftopDepartmentDetailsStep,
        stepName: 'details',
        fields: RooftopDepartmentStepFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      create: rooftopDepartmentCreate,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
  [BuilderType.ROOFTOP_DEPARTMENTS_MODIFY]: {
    refetchQueries: RooftopDepartmentsQueryMethods.refetchQueries,
    getMetaData: RooftopDepartmentsQueryMethods.getMetaData,
    getData: RooftopDepartmentsQueryMethods.getData,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: 'ROOFTOP_DEPARTMENTS_DETAILS',
        step: RooftopDepartmentDetailsStep,
        stepName: 'details',
        fields: RooftopDepartmentStepFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      modify: rooftopDepartmentModify,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
};

export default RooftopDepartmentsBuilder;
