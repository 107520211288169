import { useCallback, useState } from 'react';

import { gql } from '@apollo/client';
import i18next from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import MultiLineText from 'components/core/typography/MultiLineText';
import PrimaryText from 'components/core/typography/PrimaryText';
import { SecondaryMultiLineText } from 'components/core/typography/SecondaryText';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { FeatureBundleSet } from 'enums/featureBundle';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import type { TradeInAppraisalInternal } from 'store/api/graph/interfaces/types';
import { AccessLevel, EntityType, ResourceType, TradeInItemStatus } from 'store/api/graph/interfaces/types';
import type { TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { RED_500 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';
import { translate } from 'utils/intlUtils';

const tradeInItemSubmitToEBlock = gql`
  mutation TradeInItemSubmitToEBlockMutation($id: ID!) {
    data: tradeInItemSubmitToEBlock(id: $id)
  }
`;

// Translator
const { t } = translate;

interface Props extends MenuItemProps {
  item: TradeInItemResponseType;
}

export const MessageMultilineText = styled(MultiLineText)`
  && {
    display: inline;

    ${PrimaryText} {
      vertical-align: baseline;
      top: 0;
      white-space: pre-line;
    }
  }
`;

const MessageDisclaimerText = styled(SecondaryMultiLineText)`
  font-style: italic;
`;

const TradeInItemMenuItems = ({ item, onUpdateArchiveStatus }: Props) => {
  // State
  const [isSendToEblockDialogOpen, setIsSendToEblockDialogOpen] = useState<boolean>(false);

  // Contexts
  const { toggleTier } = useCreateModify();
  const { user, hasPermissions } = useUser();
  const { builderConfig } = useBuilderConfig();
  const flags = useFlags();

  // Variables
  const isArchived = isItemArchived(item);
  const { id, guaranteedValue, appraisals, status } = item || {};
  const YMMT = formatYMMT(item);
  const disableSubmitAppraisalButton = (appraisals?.list as TradeInAppraisalInternal[])?.some(
    appraisal => appraisal?.user?.id === user.id
  );

  // Callbacks
  const onSendTradeValue = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_GUARANTEED_TRADE,
        title: t('send_trade_value'),
        nextButtonLabel: t('submit'),
        isCreating: true,
        seededData: {
          tradeInItemId: id,
        },
        activeField: 'amount',
      }),
    [toggleTier, id]
  );
  const onSubmitAppraisal = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_SUBMIT_APPRAISAL,
        title: t('submit_your_appraisal'),
        nextButtonLabel: t('submit'),
        isCreating: true,
        seededData: {
          id,
        },
        activeField: 'amount',
      }),
    [toggleTier, id]
  );
  const onInviteAppraisers = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TRADE_IN_ITEMS_INVITE_APPRAISERS,
        title: t('invite_appraisers'),
        nextButtonLabel: t('invite'),
        isCreating: true,
        seededData: {
          id,
          rooftop: item?.rooftop,
        },
        activeField: 'pendingAppraisals',
      }),
    [toggleTier, id, item]
  );
  const onCreateTaskClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.TASK_CREATE,
        entityType: EntityType.TASK,
        title: t('create_task'),
        isCreating: true,
        seededData: {
          inventoryItemId: item?.id,
          rooftopName: item?.rooftop,
        },
      }),
    [toggleTier, item]
  );

  const onCreateAppointmentClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.APPOINTMENT_CREATE,
        entityType: EntityType.APPOINTMENT,
        title: t('create_appointment'),
        isCreating: true,
        seededData: {
          inventoryItemId: item?.id,
          rooftopName: item?.rooftop,
        },
      }),
    [item, toggleTier]
  );

  const onModifyClicked = useCallback(() => {
    toggleTier(CreateModifyTiers.TIER_0, {
      tierId: CreateModifyTiers.TIER_0,
      type: BuilderType.TRADE_IN_ITEMS_MODIFY,
      entityType: EntityType.TRADE_IN_ITEM,
      title: t('modify_x', [item?.typeName]),
      isCreating: false,
      itemId: item.id,
    });
  }, [toggleTier, item]);

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('send_trade_value'),
      onClick: onSendTradeValue,
      disabled:
        !!guaranteedValue ||
        !hasPermissions(builderConfig[BuilderType.TRADE_IN_ITEMS_GUARANTEED_TRADE].requiredPermissions),
    },
    {
      label: t('submit_your_appraisal'),
      onClick: onSubmitAppraisal,
      disabled:
        !(!disableSubmitAppraisalButton && status !== TradeInItemStatus.COMPLETED_DEAL) ||
        !hasPermissions(builderConfig[BuilderType.TRADE_IN_ITEMS_SUBMIT_APPRAISAL].requiredPermissions),
    },
    {
      label: t('invite_appraisers'),
      onClick: onInviteAppraisers,
      disabled:
        [TradeInItemStatus.DRAFT, TradeInItemStatus.COMPLETED_DEAL].includes(status) ||
        !hasPermissions(builderConfig[BuilderType.TRADE_IN_ITEMS_INVITE_APPRAISERS].requiredPermissions),
    },
    {
      label: t('create_appointment'),
      onClick: onCreateAppointmentClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftop,
          feature: FeatureBundleSet.APPOINTMENT,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.APPOINTMENT_CREATE].requiredPermissions),
    },
    {
      label: t('create_task'),
      onClick: onCreateTaskClicked,
      disabled:
        !isFeatureEnabledForRooftop({
          rooftop: item?.rooftop,
          feature: FeatureBundleSet.TASK,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }) || !hasPermissions(builderConfig[BuilderType.TASK_CREATE].requiredPermissions),
    },
    {
      label: t('send_to_eblock'),
      onClick: () => setIsSendToEblockDialogOpen(true),
      disabled: !hasPermissions([{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.FULL }]),
    },
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.TRADE_IN_ITEMS_MODIFY].requiredPermissions),
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      isArchivable: false,
      disabled: !onUpdateArchiveStatus,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return (
    <>
      <MenuItems items={menuItemsConfig} isArchived={isArchived} />
      <PromptDialog
        isFixedToTop
        isOpen={isSendToEblockDialogOpen}
        onCancel={() => setIsSendToEblockDialogOpen(false)}
        onConfirm={useCallback(async () => {
          await client.mutate({
            mutation: tradeInItemSubmitToEBlock,
            variables: { id },
          });
        }, [id])}
        title={t('send_to_eblock')}
        confirmText={t('send')}
        cancelText={t('cancel')}
        messageJSX={
          <>
            <MessageMultilineText>
              <Trans i18nKey="send_x_to_eblock_message" i18n={i18next}>
                You are about to send <PrimaryText as="span">{{ YMMT } as any}</PrimaryText> to EBlock. This item will
                appear in <PrimaryText as="span">Parked</PrimaryText> status. Are you sure you want to continue?
              </Trans>
            </MessageMultilineText>
            <MessageDisclaimerText>{t('send_to_eblock_message_disclaimer')}</MessageDisclaimerText>
          </>
        }
      />
    </>
  );
};

export default TradeInItemMenuItems;
