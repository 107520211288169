import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import LocationSelector from 'components/sections/shared/LocationSelector';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type { Lead } from 'store/api/graph/interfaces/types';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { formatLocationData } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import {
  calculateTotalDuration,
  DURATION_MONTHS_CUSTOM_ERROR,
  DURATION_YEARS_CUSTOM_ERROR,
  durationAsMonthsRemainder,
  durationAsYearsTruncated,
  validateDurationFields,
} from 'utils/leadUtils';

import { LeadGeneralInformationBuilderFields } from './interfaces';

const { t } = translate;

class LeadGeneralInformationStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, activeStep },
    } = props;

    const info = data as Lead;

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [LeadGeneralInformationBuilderFields.DURATION_MONTHS]: {
        selectedValue: durationAsMonthsRemainder(info.currentAddressMonths),
        customError: DURATION_MONTHS_CUSTOM_ERROR(t),
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
      [LeadGeneralInformationBuilderFields.DURATION_YEARS]: {
        selectedValue: durationAsYearsTruncated(info.currentAddressMonths),
        customError: DURATION_YEARS_CUSTOM_ERROR(t),
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
      [LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_MONTHS]: {
        selectedValue: durationAsMonthsRemainder(info.previousAddressMonths),
        customError: DURATION_MONTHS_CUSTOM_ERROR(t),
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
      [LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_YEARS]: {
        selectedValue: durationAsYearsTruncated(info.previousAddressMonths),
        customError: DURATION_YEARS_CUSTOM_ERROR(t),
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, {
      ...data,
      [LeadGeneralInformationBuilderFields.ADDRESS]: formatLocationData(data.address, true),
      [LeadGeneralInformationBuilderFields.PREVIOUS_ADDRESS]: formatLocationData(data.previousAddress, true),
    });
  }

  // TODO: Add LocationSelector as an option for ListSelection, would be good to aggregate this kind of substep
  async toggleSubPanel(stepField?: StepField) {
    if (
      stepField?.queryVar === LeadGeneralInformationBuilderFields.ADDRESS ||
      stepField?.queryVar === LeadGeneralInformationBuilderFields.PREVIOUS_ADDRESS
    ) {
      this.setState({
        childrenBeforeSubStep: (
          <LocationSelector
            location={stepField.selectedValue}
            onDone={location => this.onFieldSelection(stepField, location)}
          />
        ),
      });
    } else {
      this.setState({ childrenBeforeSubStep: null });
    }

    await super.toggleSubPanel(stepField);
  }

  async save() {
    const {
      tier: { steps },
    } = this.props;

    // If addresses were selected, remove the field's clear property
    const addressField = getStepField(LeadGeneralInformationBuilderFields.ADDRESS, this.fields);
    if (addressField.selectedValue) {
      addressField.clear = undefined;
    }
    const previousAddressField = getStepField(LeadGeneralInformationBuilderFields.PREVIOUS_ADDRESS, this.fields);
    if (previousAddressField.selectedValue) {
      previousAddressField.clear = undefined;
    }

    // Set the proper duration values
    const success = await super.save({
      currentAddressMonths: calculateTotalDuration(
        this.fields,
        LeadGeneralInformationBuilderFields.DURATION_MONTHS,
        LeadGeneralInformationBuilderFields.DURATION_YEARS
      ),
      previousAddressMonths: calculateTotalDuration(
        this.fields,
        LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_MONTHS,
        LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_YEARS
      ),
    });

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }

  validateFields() {
    const durationErrors = validateDurationFields(
      this.fields,
      LeadGeneralInformationBuilderFields.DURATION_MONTHS,
      LeadGeneralInformationBuilderFields.DURATION_YEARS
    );

    const previousDurationErrors = validateDurationFields(
      this.fields,
      LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_MONTHS,
      LeadGeneralInformationBuilderFields.PREVIOUS_DURATION_YEARS
    );

    if (!!durationErrors || !!previousDurationErrors) {
      this.setTier({ errors: [...(durationErrors || []), ...(previousDurationErrors || [])] });
      return false;
    }

    return super.validateFields();
  }

  async onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>, shouldForceUpdate?: boolean) {
    super.onFieldChange(stepField, e, shouldForceUpdate);
    if (
      (stepField.queryVar === LeadGeneralInformationBuilderFields.ADDRESS ||
        stepField.queryVar === LeadGeneralInformationBuilderFields.PREVIOUS_ADDRESS) &&
      stepField.selectedValue === null
    ) {
      void this.toggleSubPanel(stepField);
    }
  }
}

export default LeadGeneralInformationStep;
