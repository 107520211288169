import 'styled-components/macro';
import { round } from 'lodash-es';

import { InputContainer, InputSuffix } from './InputText';
import type { NumberInputSettings } from './NumberInput';
import NumberInput from './NumberInput';

interface Props {
  // The onChange handler will receive a decimal value (which is inputted value / 100)
  onChange?: (event: { currentTarget: { value: number } }) => void;
}

/**
 * Input to capture percentage amount. Suffixes input with '%' and will divide inputted value by 100
 */
const PercentageInput = ({ onChange, ...props }: Props) => (
  <InputContainer>
    <NumberInput
      css="padding-right: 30px"
      settings={{ allowDecimal: true } as NumberInputSettings}
      {...props}
      /*
       * First parse the input into a number, divide that by 100 to get the decimal value, fix the decimal point
       * to 4 and convert back to a number
       */
      onChange={e => onChange?.({ currentTarget: { value: round(Number(e.currentTarget.value) / 100, 4) } })}
    />
    <InputSuffix>%</InputSuffix>
  </InputContainer>
);

export default PercentageInput;
