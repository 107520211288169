import 'styled-components/macro';
import type { PathMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { FormControl, FormPasswordInput, FormSubmitButton } from 'components/ui/forms/shared/Form';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import AuthLayout, { SuccessLayout } from 'components/ui/layouts/AuthLayout';
import Spinner from 'components/ui/loading/Spinner';
import BaseClass from 'components/ui/shared/BaseClass';
import { userResetPassword } from 'containers/auth/AuthQueries';
import { ApolloContextHeaders } from 'enums/apollo';
import { RoutePath } from 'enums/routePath';
import { MatchPattern } from 'hooks/useRouter';
import type { RouterHooksHOCProps } from 'hooks/withRouter';
import { withRouter } from 'hooks/withRouter';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

// URL parameters
interface MatchParams {
  token?: string;
}

type Props = { router: RouterHooksHOCProps; match: Record<string, PathMatch<string>> };

interface State {
  password: string;
  isCompleted: boolean;
  isSubmitting: boolean;
  error?: ApiError;
}

/**
 * Forgot Username page.
 * Used by users to recover their username.
 */
class ResetPassword extends BaseClass<Props, State> {
  state = { password: '', isCompleted: false, isSubmitting: false, error: undefined };

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  onFormSubmit = async e => {
    e.preventDefault();
    const matches = this.props.match[MatchPattern.TOKEN];
    const token = (matches?.params as MatchParams)?.token || '';
    const newPassword = this.state.password;
    this.setState({ isSubmitting: true, error: undefined });

    await client
      .mutate({
        mutation: userResetPassword,
        variables: { newPassword, token },
        context: { headers: ApolloContextHeaders.NO_AUTH_REQUIRED },
      })
      .then(this.onFormSubmitSuccess)
      .catch(this.onFormSubmitError);
  };

  reset = () => {
    this.setState({ isCompleted: false });
  };

  onFormSubmitSuccess = () => {
    this.setState({ isCompleted: true, isSubmitting: false, error: undefined });
  };

  onFormSubmitError = (error: Error) => {
    this.setState({ isSubmitting: false, error });
  };

  render() {
    const { password, isCompleted, isSubmitting, error } = this.state;
    const { t } = translate;

    // Render completed state
    if (isCompleted) {
      return <SuccessLayout title={t('reset_password_success_title')} message={t('reset_password_success_message')} />;
    }

    // Render base state
    return (
      <AuthLayout logo={<EDealerIcon />} title={t('reset_password')} error={error}>
        <form onSubmit={event => void this.onFormSubmit(event)}>
          <FormPasswordInput
            helperLabel={'enter_new_password'}
            value={password}
            onChange={this.onPasswordChange}
            autoFocus
          />
          <FormSubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner /> : t('reset')}
          </FormSubmitButton>
          <FormControl>
            <Link to={RoutePath.LOGIN}>{t('back_to_login')}</Link>
          </FormControl>
        </form>
      </AuthLayout>
    );
  }
}

export default withRouter(ResetPassword);
