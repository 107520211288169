import { memo, type SVGProps } from 'react';

const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M4.138 21.407a1.1 1.1 0 0 1-.809-.33A1.1 1.1 0 0 1 3 20.27v-6c0-.32.11-.589.33-.808.219-.22.488-.33.808-.33.319 0 .588.11.808.33.22.22.329.489.329.808v3.265l12.26-12.259h-3.266a1.1 1.1 0 0 1-.808-.33 1.1 1.1 0 0 1-.33-.808c0-.319.11-.588.33-.808.22-.22.489-.329.808-.329h6c.32 0 .589.11.808.33.22.219.33.488.33.808v6c0 .319-.11.588-.33.808a1.1 1.1 0 0 1-.808.329 1.1 1.1 0 0 1-.808-.33 1.1 1.1 0 0 1-.33-.808V6.872L6.872 19.132h3.265c.32 0 .59.11.809.329.22.22.329.489.329.808 0 .32-.11.589-.33.808a1.1 1.1 0 0 1-.808.33h-6Z"
    />
  </svg>
);
export default memo(ExpandIcon);
