import { gql } from '@apollo/client';

export const MultilingualString = gql`
  fragment MultilingualStringFragment on MultilingualString {
    value
    en
    es
    fr
  }
`;
