import LanguageToggle from 'components/ui/editor/components/buttons/LanguageToggle';
import type { MultilingualInsertOption } from 'components/ui/editor/components/buttons/MultilingualInsertMenu';
import MultilingualInsertMenu from 'components/ui/editor/components/buttons/MultilingualInsertMenu';
import MenuBarContainer, { MenuBarLeft, MenuBarRight } from 'components/ui/editor/containers/MenuBarContainer';

interface Props {
  /** The options for the text insertion dropdown */
  insertOptions?: MultilingualInsertOption[];
}

const MultilingualMenuBar = ({ insertOptions }: Props) => (
  <MenuBarContainer>
    <MenuBarLeft>{insertOptions && <MultilingualInsertMenu options={insertOptions} />}</MenuBarLeft>
    <MenuBarRight>
      <LanguageToggle />
    </MenuBarRight>
  </MenuBarContainer>
);

export default MultilingualMenuBar;
