import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import LinkedFields from 'components/sections/createModify/shared/steps/LinkedFields';
import { Lead } from 'enums/columns/lead';

const LeadLinkedFields: StepFields = {
  assignedToId: {
    ...LinkedFields.assignedToId,
    queryAlias: Lead.ASSIGNED_TO_NAME,
    hasSeparator: true,
  },
};

export default LeadLinkedFields;
