import type { ButtonHTMLAttributes } from 'react';
import { forwardRef, memo, useMemo } from 'react';

import { merge } from 'lodash-es';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import Button from 'components/ui/shared/Button';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import { ElementTestId } from 'enums/testing';
import type { TooltipProps as WithTooltipProps } from 'hooks/withTooltip';
import { withTooltip } from 'hooks/withTooltip';
import { BODY_TEXT_TERTIARY, DIVIDER } from 'styles/color';
import {
  BLUE_500,
  BORDER_RADIUS_100,
  BORDER_RADIUS_200,
  NEUTRAL_0,
  NEUTRAL_100,
  NEUTRAL_800,
  NEUTRAL_900,
  SPACE_050,
  SPACE_100,
  SPACE_200,
} from 'styles/tokens';
import { hexToRGBA, variants } from 'utils/styledUtils';

export enum IconButtonStyle {
  BASIC = 'basic',
  SUBTLE = 'subtle',
  EDITOR = 'editor',
}

export type TooltipProps = Omit<WithTooltipProps, 'shouldShow' | 'width'>;

const defaultTooltipProps: TooltipProps = {
  arrowPosition: {
    primary: PrimaryArrowPosition.BOTTOM,
    secondary: SecondaryArrowPosition.LEFT,
  },
  children: null,
  isAnimated: true,
  shouldDisplayOnHover: false,
  wrapComponent: true,
};

const StyledIconButton = styled(withTooltip(Button))<{
  forceActive?: boolean;
  iconButtonCss?: FlattenSimpleInterpolation;
  styleVariant?: IconButtonStyle;
}>`
  border-radius: ${BORDER_RADIUS_200};
  color: ${NEUTRAL_800};
  column-gap: ${SPACE_050};

  ${variants<IconButtonStyle>('styleVariant', {
    [IconButtonStyle.BASIC]: css`
      align-items: center;
      background-color: ${NEUTRAL_0};
      box-shadow:
        0 1px 0 0 ${hexToRGBA(NEUTRAL_900, '0.04')},
        0 0 0 1px ${BODY_TEXT_TERTIARY} inset;
      display: flex;
      justify-content: center;
      padding: ${SPACE_200};

      &:not(:disabled):active {
        box-shadow: 0 1px 2px 0 ${hexToRGBA(NEUTRAL_900, '0.12')} inset;
      }
    `,
    [IconButtonStyle.SUBTLE]: css`
      background-color: transparent;
      padding: ${SPACE_200};
    `,
    [IconButtonStyle.EDITOR]: css`
      background: ${NEUTRAL_0};
      border-radius: ${BORDER_RADIUS_100};
      padding: ${SPACE_100};
    `,
  })}

  &:disabled {
    background: none;
    box-shadow: none;
    color: ${BODY_TEXT_TERTIARY};
  }

  &:not(:disabled):hover {
    background-color: ${DIVIDER};
  }

  &:not(:disabled):focus-visible {
    background-color: ${DIVIDER};
    outline-offset: 1px;
    outline: 3px solid ${BLUE_500};
  }

  &:not(:disabled):active {
    background-color: ${NEUTRAL_100};
    outline: none;
  }

  ${props => props.iconButtonCss}

  ${props =>
    props.forceActive &&
    css`
      background-color: ${NEUTRAL_100};

      &:not(:disabled):hover {
        background-color: ${NEUTRAL_100};
      }
    `}
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Optional CSS to be applied to the icon container */
  iconButtonCss?: FlattenSimpleInterpolation;
  /** Optional boolean to force the button to be in an active state */
  forceActive?: boolean;
  /** Optional tooltip props if we want to show tooltip on hover */
  tooltip?: TooltipProps;
  /** Different types of button styles */
  styleVariant?: IconButtonStyle;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ tooltip, styleVariant = IconButtonStyle.BASIC, ...props }, ref) => {
    /**
     * If `tooltip` is defined, we set `shouldDisplayOnHover` to `true`. But still allow
     * user to override the choices.
     */
    const tooltipProps = useMemo(
      () => (tooltip ? merge({}, defaultTooltipProps, { shouldDisplayOnHover: true }, tooltip) : defaultTooltipProps),
      [tooltip]
    );

    return (
      <StyledIconButton
        ref={ref}
        data-testid={ElementTestId.ICON_BUTTON}
        tooltip={tooltipProps}
        styleVariant={styleVariant}
        {...props}
      />
    );
  }
);

export default memo(IconButton);
