import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { retailItemsDetailsQuery } from 'containers/inventoryItems/RetailItemsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

import RetailItemQueryMethods from '../inventoryItems/retailItem/RetailItemQueryMethods';

const RetailItemPricingQueryMethods: QueryMethods = {
  getMetaData: RetailItemQueryMethods.getMetaData,
  getData: async (id: string) => {
    const response = await client.query({
      query: retailItemsDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['RetailItemDetailsContainerQuery'],
};

export default RetailItemPricingQueryMethods;
