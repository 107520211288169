import { memo, type SVGProps } from 'react';

const ChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m5.797 18.203-2.061 2.061c-.36.36-.774.442-1.24.245-.466-.196-.7-.55-.7-1.06V4.072c0-.63.222-1.167.666-1.61.443-.444.98-.665 1.61-.665h15.856c.63 0 1.166.222 1.61.665.444.443.665.98.665 1.61v11.856c0 .63-.221 1.166-.665 1.61-.444.444-.98.665-1.61.665H5.797ZM7 14h6c.283 0 .52-.096.713-.287A.968.968 0 0 0 14 13a.968.968 0 0 0-.287-.713A.968.968 0 0 0 13 12H7a.967.967 0 0 0-.713.287A.968.968 0 0 0 6 13c0 .283.096.52.287.713.192.191.43.287.713.287Zm0-3h10c.283 0 .52-.096.712-.287A.968.968 0 0 0 18 10a.967.967 0 0 0-.288-.713A.968.968 0 0 0 17 9H7a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 10c0 .283.096.52.287.713.192.191.43.287.713.287Zm0-3h10c.283 0 .52-.096.712-.287A.967.967 0 0 0 18 7a.967.967 0 0 0-.288-.713A.968.968 0 0 0 17 6H7a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 7c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </svg>
);

export default memo(ChatIcon);
