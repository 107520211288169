import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { Employment, Lead } from 'enums/columns/lead';
import { EmploymentInputParameter } from 'store/api/graph/interfaces/types';

import { LeadEmploymentInformationBuilderFields, LeadPreviousEmploymentInformationBuilderFields } from './interfaces';
import LeadEmploymentInformationFields from './LeadEmploymentInformationFields';

const LeadPreviousEmploymentInformationFields: StepFields = {
  [LeadPreviousEmploymentInformationBuilderFields.TYPE]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.TYPE],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.TYPE}`,
    clear: { field: EmploymentInputParameter._type, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.STATUS]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.STATUS],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.STATUS}`,
    clear: { field: EmploymentInputParameter._status, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.EMPLOYER]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.EMPLOYER],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.EMPLOYER}`,
    clear: { field: EmploymentInputParameter._employer, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.OCCUPATION]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.OCCUPATION],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.OCCUPATION}`,
    clear: { field: EmploymentInputParameter._occupation, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.DURATION_YEARS]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.DURATION_YEARS],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.DURATION_YEARS}`,
  },
  [LeadPreviousEmploymentInformationBuilderFields.DURATION_MONTHS]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.DURATION_MONTHS],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.DURATION_MONTHS}`,
  },
  [LeadPreviousEmploymentInformationBuilderFields.PHONE_NUMBER]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.PHONE_NUMBER],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.WORK_PHONE}`,
    clear: { field: EmploymentInputParameter._phoneNumber, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.MONTHLY_INCOME]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.MONTHLY_INCOME],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.MONTHLY_INCOME}`,
    clear: { field: EmploymentInputParameter._monthlyIncome, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.OTHER_INCOME]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.OTHER_INCOME],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.OTHER_INCOME}`,
    clear: { field: EmploymentInputParameter._otherIncome, target: Lead.PREVIOUS_EMPLOYMENT },
  },
  [LeadPreviousEmploymentInformationBuilderFields.OTHER_INCOME_SOURCE]: {
    ...LeadEmploymentInformationFields[LeadEmploymentInformationBuilderFields.OTHER_INCOME_SOURCE],
    queryAlias: `${Lead.PREVIOUS_EMPLOYMENT}.${Employment.OTHER_INCOME_SOURCE}`,
    clear: { field: EmploymentInputParameter._otherIncomeSource, target: Lead.PREVIOUS_EMPLOYMENT },
  },
};

export default LeadPreviousEmploymentInformationFields;
