import DynamicImport from 'components/core/DynamicImport';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import { TabType } from 'enums/tabType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import type { UserResponseType } from 'store/api/graph/responses/responseTypes';
import { formatFullName } from 'utils/formatUtils';

export const userDetailsTabs: ItemTab[] = [
  { tabId: TabType.DETAILS, component: DynamicImport(() => import('components/sections/users/UserDetailsTab')) },
  {
    tabId: TabType.USER_PERMISSIONS,
    component: DynamicImport(() => import('components/sections/users/UserPermissionsTab')),
    label: 'permissions',
    requiredPermissions: [{ resource: ResourceType.USERS, level: AccessLevel.FULL }],
  },
  { tabId: TabType.LINKED, component: DynamicImport(() => import('components/sections/users/UserLinkedTab')) },
];

export const getUserDetailsTitle = (item: UserResponseType) => formatFullName(item);
