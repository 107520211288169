import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { BODY_TEXT } from 'styles/color';
import { NEUTRAL_100, NEUTRAL_800 } from 'styles/tokens';
import { FONT_SIZE_16 } from 'styles/typography';
import { formatTitleString } from 'utils/formatUtils';

import CloseIcon from '../icons/CloseIcon';
import PopoverHeading from '../popover/PopoverHeading';
import { Clickable } from '../shared/Button';

const PopoverDialogButton = styled(Clickable)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
`;

const PopoverTitle = styled(PrimaryText)`
  pointer-events: none;
  font-size: ${FONT_SIZE_16};
  color: ${NEUTRAL_800};
`;

const HeaderSection = styled.section<{ showDivider?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${BODY_TEXT};
  border-bottom: ${({ showDivider }) => (showDivider ? '1px solid' + NEUTRAL_100 : 'none')};
  justify-content: flex-start;
  padding: 12px;
`;

type DefaultPopoverLayoutHeaderProps = {
  /** Header title */
  title: string;
  /** An optional callback for 'X' button in dialog header, if left null 'X' will be hidden */
  onClose?: () => void;
  /** Whether or not a divider should be shown at the bottom of the header. Defaults to false  */
  showDivider?: boolean;
};

const DefaultPopoverLayoutHeader = ({ title, onClose, showDivider = false }: DefaultPopoverLayoutHeaderProps) => (
  <HeaderSection showDivider={showDivider}>
    <PopoverHeading element={PopoverTitle}>{formatTitleString(title)}</PopoverHeading>
    {onClose && (
      <PopoverDialogButton onClick={onClose}>
        <CloseIcon />
      </PopoverDialogButton>
    )}
  </HeaderSection>
);

export default DefaultPopoverLayoutHeader;
