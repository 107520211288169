import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { TextCSS } from 'components/core/typography/Text';
import { OptionButton, OptionButtonStyle, OptionsContainer } from 'components/ui/dialogs/PromptDialogOptions';
import { ElementTestId } from 'enums/testing';
import { usePopoverContext } from 'hooks/contexts/usePopoverContext';
import { NEUTRAL_0 } from 'styles/tokens';
import { LINE_HEIGHT_DEFAULT } from 'styles/typography';
import { translate } from 'utils/intlUtils';

import type { StructuredDialogButton } from '../dialogs/StructuredDialog';
import PopoverDescription from '../popover/PopoverDescription';

import DefaultPopoverLayoutHeader from './PopoverLayoutHeader';

const BodyContainer = styled.div`
  ${TextCSS}
  line-height: ${LINE_HEIGHT_DEFAULT};
  text-wrap: wrap;
  white-space: break-spaces;
  text-align: left;
`;

const ButtonContainer = styled.div`
  padding-top: 12px;
`;

const Content = styled.div`
  ${TextCSS}
  line-height: ${LINE_HEIGHT_DEFAULT};
  text-wrap: wrap;
  white-space: break-spaces;
  text-align: left;
`;

const DefaultPopoverLayoutContainer = styled.div`
  box-shadow:
    0 8px 16px 0 rgba(22, 37, 50, 0.15),
    0 0 1px 0 rgba(22, 37, 50, 0.24);
  border-radius: 8px;
  overflow: hidden;
`;

const DefaultPopoverLayoutContainerInner = styled.div<{ containerWidth?: number }>`
  position: relative;
  background: ${NEUTRAL_0};
  overflow: auto;
  max-height: 100%;
  ${({ containerWidth }) =>
    containerWidth &&
    css`
      width: ${containerWidth}px;
    `}
`;

const DefaultPopoverLayoutBodyContentContainer = styled.div`
  max-height: 50vh;
  overflow: auto;

  &:only-child {
    padding-top: 16px;
  }
`;

const DefaultPopoverLayoutBodyContentContainerInner = styled(OptionsContainer)`
  padding: 0 12px 12px;
`;

type DefaultPopoverLayoutProps = {
  /** Title in the Prompt Dialog box */
  title?: string;
  /** The buttons of this dialog. A default "Got it" button if be used if undefined. */
  buttons?: StructuredDialogButton[];
  /** What to call when the dialog is closed */
  onClose?: () => void;
  /** An id for testing */
  testId?: string;
  /** What happens when the default confirm button is clicked. Only applicable if `buttons` is not provided */
  onConfirm?: () => void;
  /** Text to appear on the default confirm button */
  confirmText?: string;
  /** Content to render in the body of the popover */
  children?: ReactNode;
  /** Set the width for the popover content container (px) */
  containerWidth?: number;
};

const DefaultPopoverLayout = ({
  title,
  buttons,
  onClose,
  testId,
  onConfirm,
  confirmText = 'confirm',
  children,
  containerWidth = 296,
}: DefaultPopoverLayoutProps) => {
  const { setOpen } = usePopoverContext();

  const dialogButtons =
    buttons ||
    (onConfirm && [
      {
        label: translate.t(confirmText),
        onClick: onConfirm,
        buttonStyle: OptionButtonStyle.POPOVER,
        testId: ElementTestId.POPOVER_CONFIRM_BUTTON,
      },
    ]);

  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }

    setOpen(false);
  };

  return (
    <DefaultPopoverLayoutContainer>
      <DefaultPopoverLayoutContainerInner containerWidth={containerWidth} data-testid={testId}>
        {title && (
          <DefaultPopoverLayoutHeader title={title} onClose={dialogButtons?.length === 1 ? handleClose : undefined} />
        )}
        {children && (
          <DefaultPopoverLayoutBodyContentContainer>
            <DefaultPopoverLayoutBodyContentContainerInner>
              <BodyContainer>
                <PopoverDescription element={Content}>{children}</PopoverDescription>
              </BodyContainer>
              {!!dialogButtons?.length && (
                <ButtonContainer>
                  {dialogButtons.map(button => (
                    <OptionButton
                      onClick={button.onClick}
                      key={button.label}
                      data-testid={button.testId}
                      styleVariant={button.buttonStyle}
                    >
                      <Label>{button.label}</Label>
                    </OptionButton>
                  ))}
                </ButtonContainer>
              )}
            </DefaultPopoverLayoutBodyContentContainerInner>
          </DefaultPopoverLayoutBodyContentContainer>
        )}
      </DefaultPopoverLayoutContainerInner>
    </DefaultPopoverLayoutContainer>
  );
};

export default DefaultPopoverLayout;
