import type { OperationDefinitionNode } from 'graphql';
import { cloneDeep, isEmpty, isEqual, isNil, omitBy, pick } from 'lodash-es';

import LoggingService from 'components/core/logging/LoggingService';
import type { GlobalAdminFilters } from 'components/ui/filters/interfaces/globalFilter';
import DefaultListSettings from 'containers/DefaultListSettings';
import { saveFilterViewCreate, saveFilterViewDelete } from 'containers/filters/SavedFilterViewQueries';
import type { SavedFilterViewSettingsType } from 'containers/filters/SavedFilterViewSettings';
import {
  ROUTES_ENABLED_FOR_SAVED_FILTER_VIEWS,
  SavedFilterViewSettings,
} from 'containers/filters/SavedFilterViewSettings';
import type { ConnectionFilter, SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getInitialLocalSearchParams } from 'utils/filterUtils';
import { stripTypeNames } from 'utils/formatting/createModifyFormatUtils';

export const isSaveFilterViewAvailableForRoute = routePath => ROUTES_ENABLED_FOR_SAVED_FILTER_VIEWS.includes(routePath);

/**
 * Determine if a given set of filter search params is savable. Savable means that the existing search params are not
 * already saved, and the search params are not the default filters, or they are not empty
 */
export const isFilterViewSavable = ({
  routerPath,
  currentSearchParams,
  globalSearchParams,
  savedFilterViews,
}: {
  /** The route path of the current filter view */
  routerPath: string;
  /** The current search params of the filter view */
  currentSearchParams?: Record<string, unknown>;
  /** THe current global search params in the filter view */
  globalSearchParams?: GlobalAdminFilters;
  /** The existing saved filter views */
  savedFilterViews?: SavedConnectionFilterFragment[];
}): boolean => {
  // If the save filter view feature is not available for this route, then just return false
  if (!isSaveFilterViewAvailableForRoute(routerPath)) {
    return false;
  }

  const localFilters = getInitialLocalSearchParams({ searchParams: currentSearchParams, globalSearchParams });

  /**
   * Remove undefined/null values in the current search params, as well as any filters that are not savable in the
   * saved filter view
   */
  const settings = SavedFilterViewSettings[routerPath];
  const transformedSearchParams = omitBy(removeUnusedFilters({ settings, filters: localFilters }), isNil);

  // If the current search params are empty then there is nothing to save
  if (isEmpty(transformedSearchParams)) {
    return false;
  }

  // If the current search params are the default search params for this section, then there's no need to save these
  if (isEqual(DefaultListSettings[routerPath].filter, transformedSearchParams)) {
    return false;
  }

  // Check to make sure the current search params are not already saved
  return !savedFilterViews?.some(savedFilter =>
    isEqual(omitBy(savedFilter[settings.connectionFilterQueryAliasPath], isNil), transformedSearchParams)
  );
};

/**
 * Format the saved filter data. Formatting requires removing __typename properties from the data as well as any
 * null or undefined values.
 */
export const formatSavedFilters = ({
  filterData,
  settings,
}: {
  /** The filter data to format */
  filterData: SavedConnectionFilterFragment;
  /** The settings for this filter view */
  settings: SavedFilterViewSettingsType;
}) => {
  if (!settings?.connectionFilterQueryAliasPath) {
    return filterData;
  }

  // Remove '__typename' from the filter data
  const formattedFilters = stripTypeNames(
    cloneDeep(filterData[settings.connectionFilterQueryAliasPath] as ConnectionFilter)
  );

  return {
    ...filterData,
    [settings.connectionFilterQueryAliasPath]: formattedFilters,
  };
};

/**
 * Remove any unnecessary filter data that won't be saved
 */
export const removeUnusedFilters = ({
  settings,
  filters,
}: {
  /** The settings for this saved filter view */
  settings: SavedFilterViewSettingsType;
  /** The filter data */
  filters: Record<string, any>;
}): ConnectionFilter => {
  const connectionFilterFragment = saveFilterViewCreate.definitions.find(
    node => node.kind === 'FragmentDefinition' && node.name.value === settings.connectionFilterFragmentTypeName
  ) as OperationDefinitionNode;

  if (!connectionFilterFragment) {
    return filters;
  }

  const possibleVariables = connectionFilterFragment.selectionSet.selections.map((node: any) => node.name.value);
  return pick(filters, possibleVariables);
};

/**
 * The save method that will call the saveFilterViewCreate mutation and return the results
 */
export const saveFilterView = async ({
  name,
  routePath,
  filters,
}: {
  /** The name of the filter view being saved */
  name: string;
  /** The route that this filter view is on */
  routePath: string;
  /** The filter data for this view */
  filters: ConnectionFilter;
}) => {
  const settings = SavedFilterViewSettings[routePath];

  if (settings) {
    const results = await client.mutate({
      mutation: saveFilterViewCreate,
      variables: {
        name,
        type: settings.entityType,
        d_isAppointmentConnectionFilter: settings.entityType === EntityType.APPOINTMENT,
        d_isTaskConnectionFilter: settings.entityType === EntityType.TASK,
        d_isConversationConnectionFilter: settings.entityType === EntityType.CONVERSATION,
        d_isRetailItemConnectionFilter: settings.entityType === EntityType.RETAIL_ITEM,
        d_isTradeInConnectionFilter: settings.entityType === EntityType.TRADE_IN_ITEM,
        d_isLeadConnectionFilter: settings.entityType === EntityType.LEAD,
        [settings.connectionFilterInputPath]: removeUnusedFilters({ settings, filters }),
      },
      refetchQueries: ['SavedFilterViewListQuery'],
    });

    return formatSavedFilters({ filterData: results.data.savedConnectionFilterCreate, settings });
  } else {
    LoggingService.debug({ message: `No settings found for filter view on route ${routePath}` });
  }
};

export const deleteSavedFilterView = async (id: string) =>
  client.mutate({
    mutation: saveFilterViewDelete,
    variables: { id },
    refetchQueries: ['SavedFilterViewListQuery'],
  });
