import { memo, useMemo } from 'react';

import type StepFieldPreviewContents from 'components/core/createModify/interfaces/stepFieldPreviewContents';
import { calculateCashPaymentQuery } from 'components/sections/inventoryItems/retailItems/pricing/CalculateCashPaymentQuery';
import { calculateFinancePaymentQuery } from 'components/sections/inventoryItems/retailItems/pricing/CalculateFinancePaymentQuery';
import { calculateLeasePaymentQuery } from 'components/sections/inventoryItems/retailItems/pricing/CalculateLeasePaymentQuery';
import Preview from 'components/ui/shared/DisclaimerPreview';
import { ApolloFetchPolicy } from 'enums/apollo';
import { useQuery } from 'hooks/useQuery';
import type {
  CalculateCashPaymentQuery,
  CalculateFinancePaymentQuery,
  CalculateLeasePaymentQuery,
  MultilingualString,
} from 'store/api/graph/interfaces/types';
import { MileageUnit, PaymentOption } from 'store/api/graph/interfaces/types';
import { getDateTime } from 'utils/dateUtils';
import { getLocaleFromMultilingualValue, translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * Translation keys for the disclaimer labels. Includes the tag header, list of available tags, and the preview header
 * text
 */
const disclaimerLabels = {
  [PaymentOption.CASH]: {
    tagHeader: 'cash_disclaimer_tag_other',
    tags: 'cash_disclaimer_all_tags',
    previewHeader: 'cash_disclaimer_preview',
  },
  [PaymentOption.FINANCE]: {
    tagHeader: 'finance_disclaimer_tag_other',
    tags: 'finance_disclaimer_all_tags',
    previewHeader: 'finance_disclaimer_preview',
  },
  [PaymentOption.LEASE]: {
    tagHeader: 'lease_disclaimer_tag_other',
    tags: 'lease_disclaimer_all_tags',
    previewHeader: 'lease_disclaimer_preview',
  },
};

/**
 * Queries with sample data to generate a realistic preview.
 */
const disclaimerQueries = {
  [PaymentOption.CASH]: {
    query: calculateCashPaymentQuery,
    queryAlias: 'calculateCashPayment',
    variables: {
      ending: getDateTime().plus({ day: 1 }).toISODate(),
      purchasePrice: 20000,
      taxRate: 0.08,
      freight: 250,
    },
  },
  [PaymentOption.FINANCE]: {
    query: calculateFinancePaymentQuery,
    queryAlias: 'calculateFinancePayment',
    variables: {
      ending: getDateTime().plus({ day: 1 }).toISODate(),
      purchasePrice: 20000,
      taxRate: 0.08,
      freight: 250,
      interestRate: 0.03,
      paymentFrequency: 'MONTHLY',
      term: 24,
    },
  },
  [PaymentOption.LEASE]: {
    query: calculateLeasePaymentQuery,
    queryAlias: 'calculateLeasePayment',
    variables: {
      ending: getDateTime().plus({ day: 1 }).toISODate(),
      mileageAllowance: { amount: 10000, unit: MileageUnit.KILOMETERS },
      purchasePrice: 20000,
      taxRate: 0.08,
      freight: 250,
      interestRate: 0.03,
      paymentFrequency: 'MONTHLY',
      term: 24,
      residualRate: 0.025,
    },
  },
};

interface Props extends Omit<StepFieldPreviewContents, 'tier'> {
  /** The value of the disclaimer field. This will be used to generate a preview. Expects a Multilingual input  */
  fieldValue: MultilingualString | undefined;
  /** The PaymentOption this preview is for. Each PaymentOption has different tags for disclaimer text. */
  paymentOption: PaymentOption;
}

const DisclaimerPreview = ({ fieldValue, language, paymentOption }: Props) => {
  const labels = disclaimerLabels[paymentOption];
  const disclaimerQuery = disclaimerQueries[paymentOption];

  const { data } = useQuery<CalculateCashPaymentQuery | CalculateLeasePaymentQuery | CalculateFinancePaymentQuery>(
    disclaimerQuery.query,
    {
      variables: {
        ...disclaimerQuery.variables,
        disclaimerTemplate: { en: fieldValue?.en, fr: fieldValue?.fr, es: fieldValue?.es },
      },
      options: {
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      },
      ignore: !fieldValue?.value,
    }
  );

  const previewText = useMemo(() => {
    const disclaimer: MultilingualString | undefined = data?.[disclaimerQuery.queryAlias]?.disclaimer;
    return disclaimer?.[language] || '';
  }, [data, language, disclaimerQuery.queryAlias]);

  const lng = useMemo(() => getLocaleFromMultilingualValue(language), [language]);

  return (
    <Preview
      tags={t(labels.tags, undefined, { lng })}
      title={t(labels.tagHeader, undefined, { lng })}
      previewTitle={t(labels.previewHeader, undefined, { lng })}
      previewText={previewText}
      noteText={t(
        'disclaimer_preview_note',
        [
          t(
            {
              [PaymentOption.CASH]: 'cash',
              [PaymentOption.FINANCE]: 'finance',
              [PaymentOption.LEASE]: 'lease',
            }[paymentOption]
          ).toLowerCase(),
        ],
        { lng: language }
      )}
    />
  );
};

export default memo(DisclaimerPreview);
