import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type Tier from 'components/core/createModify/interfaces/tier';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import {
  type ContactCreateMutationVariables,
  type ContactDetailsContainerQuery,
  ContactType,
} from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

import { ContactsBuilderFields } from './interfaces';

export const seedContactStep = (prefill = '', { data: { rooftop } }: Tier) => ({ firstName: prefill, rooftop });

class DetailsStep extends StepComponentCore<
  ContactDetailsContainerQuery['item'],
  { metadata: undefined },
  ContactCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<
      ContactDetailsContainerQuery['item'],
      { metadata: undefined },
      ContactCreateMutationVariables
    >,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: { activeStep, data, seededData },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const rooftop = seededData?.rooftop || data?.rooftop || getUsersRooftop(user);

    const isTradeInEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.TRADE_IN,
      featureFlagOn: rooftopPackageEnabled,
    });

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [ContactsBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !canAccessMultipleRooftops,
          },
          { type: StepFieldDisplayType.DISABLED, active: !!rooftop },
        ]),
      },
      [ContactsBuilderFields.FIRST_NAME]: { selectedValue: data?.firstName },
      [ContactsBuilderFields.DEFAULT_APPRAISER]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isTradeInEnabled,
          },
        ]),
      },
    });

    this.fields = defineFieldValues(this.fields, data);

    this.asyncConfigurations = {
      [ContactsBuilderFields.ROOFTOP_ID]: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
    };
  }

  async save(): Promise<boolean> {
    // Hardcode the contact type as a WHOLESALER (only option atm)
    return super.save({ type: ContactType.WHOLESALER });
  }
}

export default DetailsStep;
