import type { ReactNode } from 'react';

import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

import BaseClass from 'components/ui/shared/BaseClass';
import { ElementTestId } from 'enums/testing';
import { OVERLAY_DARK } from 'styles/blanket';
import { SECTION_PADDING } from 'styles/spacing';
import { deferredRenderCall } from 'utils/renderingUtils';

const Container = styled.div<{ isVisible: boolean }>`
  position: relative;
  height: 100vh;
  width: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  background-color: ${OVERLAY_DARK};
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentInner = styled.div<{ isFixedToTop?: boolean; showBorderRadius?: boolean }>`
  box-shadow:
    0 8px 16px 0 rgba(22, 37, 50, 0.15),
    0 0 1px 0 rgba(22, 37, 50, 0.24);
  border-radius: ${({ showBorderRadius }) => (showBorderRadius ? '8px' : '0')};
  position: relative;
  overflow: hidden;
  margin: 200px ${SECTION_PADDING};
  align-self: ${props => (props.isFixedToTop ? 'flex-start' : 'center')};
`;

export interface Props {
  /** The content to render within the dialog */
  children?: ReactNode;
  /** Used to specify the the parent node in which a dialog child node will be appended */
  modalRootElement?: HTMLElement;
  /**
   * When isPortalDialog, used to specify the id of the parent node in which a dialog child node will be appended.
   * By default, will search for a DOM node with an id of 'modal-root' to use as the portal root.
   */
  modalRootId?: string;
  /** Whether the dialog will be rendered */
  isOpen: boolean;
  /** False: centered, true: fixed upper position */
  isFixedToTop?: boolean;
  /** Whether onCancel will be called on overlay click and ESC keydown */
  isDismissible?: boolean;
  /** A callback that runs on overlay click and ESC keydown when isDismissible */
  onCancel?: () => void;
  /** False: absolute positioned child, true: root portal container */
  isPortalDialog?: boolean;
  /** Flag to hide or show a border radius around the modal */
  showBorderRadius?: boolean;
  /** An id for testing */
  testId?: string;
}
interface State {
  isVisible: boolean;
}

/**
 * Generic Controlled Dialog component.
 * Based on eBlock Dialog component.
 *
 * Use to compose more specific dialogs e.g. AddEntityDialog.
 * See DialogContent.tsx for components to help with composition.
 */
export default class Dialog extends BaseClass<Props, State> {
  dialog: Element;
  modalRoot: HTMLElement | null;

  state = { isVisible: false };

  static defaultProps = {
    isPortalDialog: true,
  };

  constructor(props) {
    super(props);
    this.modalRoot = this.getModalRoot();
    this.dialog = document.createElement('div');
    this.dialog.className = 'modal-dialog';
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    const { isVisible } = this.state;

    // Set modalRoot on updates to allow dynamic containers
    this.modalRoot = this.getModalRoot();

    if (isOpen && !isVisible) {
      this.modalRoot?.appendChild(this.dialog);
      deferredRenderCall(() => this.setState({ isVisible: true }));
    } else if (!isOpen && isVisible) {
      this.removeDialog();
      this.setState({ isVisible: false });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.removeDialog();
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  removeDialog = () => {
    // Removes dialog from DOM
    if (this.dialog.parentNode === this.modalRoot) {
      this.modalRoot?.removeChild(this.dialog);
    }
  };

  getModalRoot = () =>
    this.props.modalRootElement || !!this.props.isPortalDialog
      ? document.querySelector<HTMLDivElement>(`#${this.props.modalRootId || 'modal-root'}`)
      : null;

  handleClose = () => {
    const { isOpen, onCancel, isDismissible } = this.props;

    if (isOpen && isDismissible !== false) {
      onCancel?.();
    }
  };

  handleKeyDown = e => {
    // ESC key pressed
    if (e.keyCode === 27) {
      this.handleClose();
    }
  };

  renderDialog = () => {
    const { children, isFixedToTop, showBorderRadius = true, testId } = this.props;
    const { isVisible } = this.state;

    return (
      <Container isVisible={isVisible} data-testid={testId}>
        <Overlay data-testid={ElementTestId.DIALOG_BACKGROUND} onClick={this.handleClose} />
        <Content>
          <ContentInner isFixedToTop={isFixedToTop} showBorderRadius={showBorderRadius}>
            {children}
          </ContentInner>
        </Content>
      </Container>
    );
  };

  render() {
    const { isOpen, isPortalDialog } = this.props;

    if (!isOpen) {
      return null;
    }

    return isPortalDialog ? (
      ReactDOM.createPortal(this.renderDialog(), this.dialog)
    ) : (
      <div className="modal-dialog">{this.renderDialog()}</div>
    );
  }
}
