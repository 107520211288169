import type { Location } from 'react-router-dom';

import LoggingService from 'components/core/logging/LoggingService';
import { ApolloContextHeaders, ApolloFetchPolicy } from 'enums/apollo';
import { RoutePath } from 'enums/routePath';
import { getDefaultHeaders } from 'store/api/ApiRequest';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  SsoLoginMutation,
  SsoLoginMutationVariables,
  UserLoginMutation,
  UserLoginMutationVariables,
} from 'store/api/graph/interfaces/types';
import { SSO_LOGIN_MUTATION, USER_LOGIN_MUTATION, USER_LOGOUT_MUTATION } from 'store/api/graph/mutations/auth';
import { USER_LOGIN_QUERY } from 'store/api/graph/queries/auth';

import { getDateTime } from './dateUtils';
import { isIE, isMobileDevice } from './deviceInfoUtils';
import { authStorage, lastLoginStorage, userIdStorage } from './storage/auth';

/** Auto-logout unsupported clients, otherwise check local storage for token */
export const isLoggedIn = () => (isIE() || isMobileDevice() ? false : !!authStorage.get());

const cacheAuth = (token: string, time: number) => {
  authStorage.set(`Bearer ${token}`);
  lastLoginStorage.set(time);
};

const clearCacheAuth = () => {
  authStorage.remove();
  lastLoginStorage.remove();
};

class AuthManager {
  routingTarget?: Location;
  lastLogin?: number;

  getClient = () =>
    import('store/apollo/ApolloClient').then(({ client, subscriptionClient }) => ({ client, subscriptionClient }));

  getUser = (): Promise<any> =>
    this.getClient().then(({ client }) =>
      client
        .query({ query: USER_LOGIN_QUERY, fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY })
        .then(response => {
          userIdStorage.set(response.data?.user.id);
          return { ...response, lastLogin: this.lastLogin };
        })
        .catch((error: Error) => {
          logApiError(error);
          clearCacheAuth();
          return error;
        })
    );

  /**
   * Attempt to fetch user info if valid token is available.
   */
  tryLogin = (): Promise<any> => {
    const token = authStorage.get();
    return new Promise((resolve, reject) => {
      if (token) {
        this.getUser()
          .then(resolve)
          .catch((error: Error) => reject(error));
      } else {
        resolve(undefined);
      }
    });
  };

  private endImpersonation = () =>
    import('utils/impersonationUtils').then(({ impersonationManager }) => impersonationManager.endImpersonation());

  private closeSubscription = () =>
    void this.getClient().then(({ subscriptionClient }) => {
      subscriptionClient.close();
    });

  private tryCacheAuth(token?: string | null) {
    if (token) {
      this.lastLogin = getDateTime().toMillis();
      // Cache Auth token and login timestamp, then fetch user
      cacheAuth(token, this.lastLogin);

      this.closeSubscription();
    }
  }

  /**
   * Attempt to authenticate employee with OAuth code
   */
  trySsoLogin = (authorizationCode: string): Promise<any> => {
    const ssoLoginOptions = {
      mutation: SSO_LOGIN_MUTATION,
      variables: { authorizationCode },
      context: { headers: ApolloContextHeaders.NO_AUTH_REQUIRED },
    };

    return this.getClient().then(({ client }) =>
      client.mutate<SsoLoginMutation, SsoLoginMutationVariables>(ssoLoginOptions).then(response => {
        this.tryCacheAuth(response?.data?.ssoLogin);
        return this.getUser();
      })
    );
  };

  /**
   * Attempt user login with provided token.
   * Add any required post-login functionality here.
   */
  tryUserLogin = (username: string, password: string): Promise<any> => {
    const loginOptions = {
      mutation: USER_LOGIN_MUTATION,
      variables: { username, password },
      context: { headers: ApolloContextHeaders.NO_AUTH_REQUIRED },
    };

    return this.getClient().then(({ client }) =>
      client.mutate<UserLoginMutation, UserLoginMutationVariables>(loginOptions).then(response => {
        this.tryCacheAuth(response?.data?.userLogin);
        return this.getUser();
      })
    );
  };

  /**
   * Attempts to have the API dispose of the user's active session (or all, if supplied).
   */
  tryUserLogout = (headers = {}, allSessions = false) =>
    this.getClient().then(({ client }) =>
      client
        .mutate({
          mutation: USER_LOGOUT_MUTATION,
          variables: { allSessions },
          context: { headers },
        })
        .catch((error: Error) =>
          LoggingService.warn({
            message: 'AuthUtils.ts: Auto logout, likely an invalid token',
            messageContext: error,
          })
        )
    );

  /**
   * Log user out.
   */
  logout = async () => {
    /*
     * Store headers for `tryUserLogout()`
     * Run `tryUserLogout` last so ApolloClient's `onError` isn't fired multiple times.
     */
    const headers = getDefaultHeaders();

    // Clear routing target
    this.routingTarget = undefined;

    /*
     * Clear auth and login timestamp cache, close subscriptions,
     * Reset Apollo cache store, then redirect to Login page.
     */
    clearCacheAuth();
    this.closeSubscription();
    await this.getClient().then(({ client }) => client.clearStore());
    await this.tryUserLogout(headers);
    await this.endImpersonation();
    window.location.replace(RoutePath.LOGIN);
  };
}

export const authManager = new AuthManager();
