import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { ItemsColumn } from 'enums/itemsColumn';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

const defaultStaticHeaders = {
  resizable: false,
  canReorder: false,
  isFixedColumn: true,
  width: 55,
  canEdit: false,
  content: null,
};

export const SelectHeaders = {
  ...defaultStaticHeaders,
  styles: 'justify-content: center;',
};

export const PhotoHeaders = {
  ...defaultStaticHeaders,
  styles: 'justify-content: center; padding: 10px 20px 10px 0;',
};

export const STATIC_CONFIGS = Object.freeze<TableCellData[]>([
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),
  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
  }),
]);
