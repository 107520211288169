import type { ComponentType, ReactNode } from 'react';

interface ProviderProps {
  children: ReactNode;
}

/**
 * Combines multiple React context providers into a single provider component.
 *
 * This function takes an array of provider components and returns a single
 * provider component that nests all the provided providers. The combined
 * provider component can then be used to wrap your application or parts of it,
 * simplifying the nesting of multiple providers.
 *
 * @param {ComponentType<ProviderProps>[]} providers - An array of React context provider components.
 * @returns {ComponentType<ProviderProps>} A combined provider component that nests all the input providers.
 *
 * @example
 * // Import your context providers
 * import { ThemeProvider } from './ThemeContext';
 * import { UserProvider } from './UserContext';
 *
 * // Import other providers as needed
 * import { AnotherProvider } from './AnotherContext';
 * import { YetAnotherProvider } from './YetAnotherContext';
 *
 * // Combine the providers
 * const CombinedProviders = combineProviders([
 *   ThemeProvider,
 *   UserProvider,
 *   AnotherProvider,
 *   YetAnotherProvider,
 * ]);
 *
 * // Use the combined provider to wrap your application
 * const App = () => (
 *   <CombinedProviders>
 *     <MainComponent />
 *   </CombinedProviders>
 * );
 */
export const combineProviders =
  (providers: ComponentType<ProviderProps>[]): ComponentType<ProviderProps> =>
  ({ children }: ProviderProps) =>
    providers.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, children);
