import { uniq } from 'lodash-es';

import { typeNameToIsIncomingMapVar } from './reactiveVariables';

const setIsIncoming = (typeName, newValue) => {
  if (!typeName) {
    return;
  }

  const currentValue = typeNameToIsIncomingMapVar();
  const updatedValue = { ...currentValue, [typeName]: newValue };

  return typeNameToIsIncomingMapVar(updatedValue);
};

export const getIsIncoming = typeName => {
  const currentValue = typeNameToIsIncomingMapVar();

  return currentValue[typeName] || [];
};

export const setIsIncomingItem = item => {
  const typeName = item?.__typename;
  const id = item?.id;
  if (!typeName || !id) {
    return;
  }

  // Remove duplicate items is present e.g. multiple messages for a single conversation
  setIsIncoming(typeName, uniq([...getIsIncoming(typeName), id]));
};

export const clearIsIncoming = typeName => {
  if (!!typeName && !!getIsIncoming(typeName)?.length) {
    setIsIncoming(typeName, []);
  }
};
