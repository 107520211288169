import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { retailFiltersMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const WebsiteRouteFilterQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const rooftopId = seededData?.filter?.rooftopId;
    const observable = client.watchQuery<RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables>({
      query: retailFiltersMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: getRetailFiltersMetaQueryVariables(rooftopId, seededData?.filter),
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          /**
           *  Top level `metadata` from response not used, contents are
           *  instead spread for builder `metadata` structure consistency
           */
          const metadata = { ...res.data.metadata, ...res.data, metadata: undefined };
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },
};

export default WebsiteRouteFilterQueryMethods;
