import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import InitialItemSortingStep from 'components/sections/createModify/shared/steps/InitialItemSortingStep';
import type { WebsiteDetails } from 'components/sections/createModify/shared/steps/interfaces';
import { InitialItemSortingBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import {
  getRooftopOptions,
  getWebsiteSortOptions,
  getWebsiteSortOptionsFromMetadata,
} from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type {
  WebsiteCreateMutationVariables,
  WebsiteMetaQuery,
  WebsiteModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

class WebsiteSortingStep extends InitialItemSortingStep<
  WebsiteDetails,
  WebsiteMetaQuery,
  WebsiteCreateMutationVariables | WebsiteModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<
      WebsiteDetails,
      WebsiteMetaQuery,
      WebsiteCreateMutationVariables | WebsiteModifyMutationVariables
    >,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped },
      },
    } = context;

    this.asyncConfigurations = {};

    const {
      tier: { activeStep, data, metadata, isCreating, stepFieldData },
    } = this.props;

    const initialSortRules = isCreating ? undefined : data.defaultInitialItemSorting;

    // During modify, this metadata should already be available when the builder opens
    this.metadataSortOptions = getWebsiteSortOptionsFromMetadata(metadata.websiteSortOptions);

    if (isCreating) {
      // Will be attached automatically by this.initSortFields
      this.asyncConfigurations[InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING] = {
        request: async () =>
          getRooftopOptions({
            user,
            isWhiteLabelScoped,
            // For website create, we can only narrow metadata to rooftops within the group this website belongs to
            groupId: stepFieldData?.groupId.id,
          }).then(rooftops => getWebsiteSortOptions(rooftops?.map(({ id }) => id as string) || [])),
      };
    }

    const sortFields = this.initSortFields(initialSortRules);

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [InitialItemSortingBuilderFields.USE_CUSTOM_SORT_RULE]: {
        displayType: [StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED],
      },
    });

    // Insert the dynamically created stepFields into the correct index
    this.fields.splice(1, 0, ...Object.values(sortFields));

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Sort fields should always be enabled
    this.toggleSortFields(false);
    // When creating, wait until the user adds at least one rule, before enabling the add sort rule button
    const addSortingRuleField = getStepField(InitialItemSortingBuilderFields.ADD_SORTING_RULE, this.fields);
    addSortingRuleField.displayType = setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !!isCreating });
  }

  async save(): Promise<boolean> {
    const variableOverrides: WebsiteModifyMutationVariables | WebsiteCreateMutationVariables = {
      defaultInitialItemSorting: this.formatInitialItemSortingVariable(),
    };

    return super.save({}, variableOverrides);
  }
}

export default WebsiteSortingStep;
