import styled from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import Label from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import ManufacturerOptionListItem from 'components/ui/lists/ManufacturerOptionListItem';
import StandardEquipmentListItem from 'components/ui/lists/StandardEquipmentListItem';
import TechnicalSpecificationsListItem from 'components/ui/lists/TechnicalSpecificationsListItem';
import type {
  ManufacturerOption,
  Model,
  StandardEquipment,
  TechnicalSpecification,
} from 'store/api/graph/interfaces/types';
import { DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_500 } from 'styles/tokens';
import { getGroupedOptions, parseCategoryGroups } from 'utils/formatting/createModifyFormatUtils';

const CategoryContainer = styled.div<{ isActive?: boolean }>`
  border-top: 1px solid ${DIVIDER};

  > * {
    padding-top: ${ENTITY_PADDING};
  }

  &:not(:last-child) {
    padding-bottom: ${ENTITY_PADDING};
  }

  ${({ isActive }) => isActive && `${Text} { color: ${BLUE_500}`}
`;

interface BaseOptionsFieldsProps {
  isInput: boolean;
  active?: boolean;
}

type ManufacturerOptionsProps = (ManufacturerOption | StepField) & BaseOptionsFieldsProps;

/**
 * Renderer for the Manufacturer Options list in the builder
 */
export const ManufacturerOptions = ({ isInput, active, ...props }: ManufacturerOptionsProps) => {
  // Render as a list item
  if (!isInput) {
    return <ManufacturerOptionListItem {...(props as ManufacturerOption)} />;
  }

  const { selectedValue } = props as StepField;

  // Render as a step input
  if (selectedValue?.length) {
    const sortedItems = getGroupedOptions(selectedValue, parseCategoryGroups(selectedValue));

    return (
      <>
        {sortedItems.map(({ label, items }) => (
          <CategoryContainer isActive={active} key={label}>
            <Label>{label}</Label>
            {items.map(item => (
              <ManufacturerOptionListItem key={item.id} {...(item as ManufacturerOption & SubStepOption)} />
            ))}
          </CategoryContainer>
        ))}
      </>
    );
  }
  return null;
};

type StandardEquipmentOptionsProps = (StandardEquipment | StepField) & BaseOptionsFieldsProps;

/**
 * Renderer for the Standard Equipment Options list in the builder
 */
export const StandardEquipmentOptions = ({ isInput, active, ...props }: StandardEquipmentOptionsProps) =>
  isInput ? null : <StandardEquipmentListItem {...(props as StandardEquipment)} />;

type TechnicalSpecificationsOptionsProps = (TechnicalSpecification | StepField) & BaseOptionsFieldsProps;
/**
 * Renderer for the Technical Specification Options list in the builder
 */
export const TechnicalSpecificationsOptions = ({ isInput, active, ...props }: TechnicalSpecificationsOptionsProps) =>
  isInput ? null : <TechnicalSpecificationsListItem {...(props as TechnicalSpecification)} />;

type RooftopYearModelOptionsProps = (Model | StepField) & BaseOptionsFieldsProps;

export const RooftopYearModelOptions = ({ isInput, active, ...props }: RooftopYearModelOptionsProps) => {
  if (!isInput) {
    return <Text>{(props as Model).name.value}</Text>;
  }

  const { selectedValue } = props as StepField;

  if (selectedValue?.length) {
    const sortedItems = getGroupedOptions(selectedValue, parseCategoryGroups(selectedValue));

    return (
      <>
        {sortedItems.map(({ label, items }) => (
          <CategoryContainer isActive={active} key={label}>
            <Label>{label}</Label>
            {items.map(item => (
              <PrimaryText css={'text-align: left'} key={item.id}>
                {(item as Model).name.value}
              </PrimaryText>
            ))}
          </CategoryContainer>
        ))}
      </>
    );
  }

  return null;
};
