import LoggingService from 'components/core/logging/LoggingService';
import { Locale, MultilingualStringValue } from 'utils/intlUtils';

type LocaleSettingsType = {
  /** The locale tag that maps to the locale metadata from the server */
  locale: Locale;
  /** The label that is rendered */
  label: string;
  /** The language field in the MultilingualString object that any changes to the input will save to */
  language: MultilingualStringValue;
};

/**
 * Global locale settings for Multilingual editors.
 */
export const MultilingualEditorLocaleSettings: LocaleSettingsType[] = [
  {
    locale: Locale.EN_CA,
    label: 'EN',
    language: MultilingualStringValue.EN,
  },
  {
    locale: Locale.EN_US,
    label: 'EN',
    language: MultilingualStringValue.EN,
  },
  {
    locale: Locale.FR_CA,
    label: 'FR',
    language: MultilingualStringValue.FR,
  },
  {
    locale: Locale.ES_US,
    label: 'ES',
    language: MultilingualStringValue.ES,
  },
];

/**
 * List of supported languages to be used in any Multilingual editors. Note that in order for a locale
 * to work correctly, there must be an entry in MultilingualEditorLocaleSettings for it.
 */
const defaultSupportedEditorLanguages = [MultilingualStringValue.EN, MultilingualStringValue.FR];

/**
 * Given a list of MultilingualStringValues, this util will filter out any of these items that are not supported. Not
 * supported means there are no setting configurations in MultilingualEditorLocaleSetting that correspond to a given
 * MultilingualStringValue. This will catch and log a warning if there are any mis-configured multilingual inputs.
 * @param locales
 */
export const getSupportedEditorLanguages = (
  locales: MultilingualStringValue[] = defaultSupportedEditorLanguages
): MultilingualStringValue[] =>
  locales.filter(supportedLocale => {
    const isSupported = !!MultilingualEditorLocaleSettings.some(
      localeSetting => localeSetting.language === supportedLocale
    );

    if (!isSupported) {
      LoggingService.warn({
        message: `The locale ${supportedLocale} was not found in MultilingualEditorLocaleSettings! It has been omitted from use.`,
      });
    }

    return isSupported;
  });
