import styled from 'styled-components/macro';

import TimeIcon from 'components/ui/icons/TimeIcon';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { formatInputValue } from 'utils/formUtils';

import ClickableInput from './ClickableInput';
import InputTextValue from './InputTextValue';

const TimeIconContainer = styled(TimeIcon)<{ active?: string }>`
  color: ${({ active }) => (active === 'true' ? BLUE_500 : BODY_TEXT)};
  position: absolute;
  right: 12px;
`;

interface Props {
  /** Whether or not this field is currently selected */
  isActive?: boolean;
  /** Any associated selection options required for the field to render */
  options?: any;
  /** Current value of the field. A string in the format `DateTimeFormat.TIME_STAMP_FORMAT` */
  selectedValue?: string;
}

const TimeInput = ({ isActive, options, selectedValue, ...props }: Props) => {
  const formattedValue = formatInputValue(selectedValue, options);

  return (
    <ClickableInput {...props}>
      <InputTextValue>{formattedValue}</InputTextValue>
      <TimeIconContainer active={isActive?.toString()} />
    </ClickableInput>
  );
};

export default TimeInput;
