import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getDaysOfTheWeekFromMetadata } from 'components/sections/rooftops/departments/utils';
import LocationSelector from 'components/sections/shared/LocationSelector';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import type {
  OpenHoursInput,
  RooftopDepartmentCreateMutationVariables,
  RooftopDepartmentDetailQuery,
  RooftopDepartmentsMetaQuery,
} from 'store/api/graph/interfaces/types';
import { RooftopDepartmentType } from 'store/api/graph/interfaces/types';
import { getDateTime } from 'utils/dateUtils';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { formatLocationData } from 'utils/formatUtils';
import { Locale, translate } from 'utils/intlUtils';

import { getDayClosingHoursFieldName, getDayOpeningHoursFieldName } from '../utils';

import { RooftopDepartmentsDetailsBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore<RooftopDepartmentDetailQuery['item'], RooftopDepartmentsMetaQuery> {
  constructor(props: StepComponentProps<RooftopDepartmentDetailQuery['item'], RooftopDepartmentsMetaQuery>) {
    super(props);
    const {
      tier: { activeStep, data },
    } = props;

    const showLocationField = data?.type === RooftopDepartmentType.SERVICE;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopDepartmentsDetailsBuilderFields.LOCATION]: {
        selectedValue: data?.location ? formatLocationData(data.location, true) : null,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: !showLocationField }]),
      },
    });

    this.fields = defineFieldValues(this.fields, data);
    this.renderDayFields();
  }

  private getDaysOfTheWeekFields() {
    const {
      tier: { metadata },
    } = this.props;

    return getDaysOfTheWeekFromMetadata(metadata);
  }

  private renderDayFields() {
    const {
      tier: { data },
    } = this.props;

    const daysOfTheWeek = this.getDaysOfTheWeekFields();

    const newFields: StepField[] = [];
    for (const [index, day] of daysOfTheWeek.entries()) {
      const dayHours = data?.hours?.find(hour => hour.day === day.dayValue);

      const enabledField = {
        queryVar: day.dayValue,
        label: day.dayName,
        selectedValue: dayHours?.open && dayHours?.close,
        groupType: StepFieldType.SWITCH,
        displayType: [StepFieldDisplayType.OMITTED],
      };

      const openHours = {
        queryVar: getDayOpeningHoursFieldName(day.dayValue),
        queryAlias: `hours#${index}.open`,
        label: translate.t('open'),
        groupType: StepFieldType.TIME,
        groupSubTypes: [StepFieldSubType.TIME],
        subStep: [SubStepType.DEFAULT],
        selectedValue: dayHours?.open
          ? getDateTime(dayHours.open, DateTimeFormat.HOUR_24_TIME_FORMAT)?.toFormat(DateTimeFormat.TIME_STAMP_FORMAT)
          : undefined,
        forceRequired: !!dayHours?.open,
        customError: translate.t('missing_opening_hours_for_x', [day.dayName]),
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: true },
          { type: StepFieldDisplayType.HIDDEN, active: !dayHours?.open },
        ]),
      };

      const closeHours = {
        queryVar: getDayClosingHoursFieldName(day.dayValue),
        queryAlias: `hours#${index}.close`,
        label: translate.t('close'),
        groupType: StepFieldType.TIME,
        groupSubTypes: [StepFieldSubType.TIME],
        subStep: [SubStepType.DEFAULT],
        selectedValue: dayHours?.close
          ? getDateTime(dayHours.close, DateTimeFormat.HOUR_24_TIME_FORMAT)?.toFormat(DateTimeFormat.TIME_STAMP_FORMAT)
          : undefined,
        forceRequired: !!dayHours?.close,
        customError: translate.t('missing_closing_hours_for_x', [day.dayName]),
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: true },
          { type: StepFieldDisplayType.HIDDEN, active: !dayHours?.close },
        ]),
      };

      newFields.push(enabledField, openHours, closeHours);
    }

    this.fields = [...this.fields, ...newFields];
    this.forceUpdate();
  }

  private toggleDayTimeFields(queryVar: string, isHidden: boolean) {
    const openHoursField = getStepField(getDayOpeningHoursFieldName(queryVar), this.fields);
    const closeHoursField = getStepField(getDayClosingHoursFieldName(queryVar), this.fields);

    setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: isHidden }], openHoursField);
    setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: isHidden }], closeHoursField);

    openHoursField.forceRequired = !isHidden;
    closeHoursField.forceRequired = !isHidden;

    // If the day of the week is being enabled, set the default open/close hours to 9am-5pm
    if (!isHidden && !openHoursField.selectedValue && !closeHoursField.selectedValue) {
      openHoursField.selectedValue = '09:00 a.m.';
      closeHoursField.selectedValue = '05:00 p.m.';
    }
  }

  private getDayTimeVariables(): RooftopDepartmentCreateMutationVariables['hours'] {
    const listOfDays = this.getDaysOfTheWeekFields();
    const hours: OpenHoursInput[] = [];

    for (const day of listOfDays) {
      const isEnabled = !!getStepField(day.dayValue, this.fields)?.selectedValue;

      // If the day is not enabled then theres no hours to get
      if (!isEnabled) {
        continue;
      }

      const openHoursFieldName = getDayOpeningHoursFieldName(day.dayValue);
      const closeHoursFieldName = getDayClosingHoursFieldName(day.dayValue);
      const openHours = getStepField<string>(openHoursFieldName, this.fields);
      const closeHours = getStepField<string>(closeHoursFieldName, this.fields);

      /**
       * As of writing, the luxon library is only able to parse TimeInput value (e.g. 09:00 am)
       * with format `DateTimeFormat.TIME_FORMAT`(h:mm a) only when the locale is set to
       * `en-CA` or `fr-CA`.
       *
       * Since this is not user facing, forcing a locale here is fine as we only needed it to
       * parse into the right format for the API.
       */
      const openHoursFormatted = getDateTime(openHours.selectedValue, DateTimeFormat.TIME_FORMAT, {
        locale: Locale.EN_CA,
      })?.toFormat(DateTimeFormat.HOUR_24_TIME_FORMAT);
      const closeHoursFormatted = getDateTime(closeHours.selectedValue, DateTimeFormat.TIME_FORMAT, {
        locale: Locale.EN_CA,
      })?.toFormat(DateTimeFormat.HOUR_24_TIME_FORMAT);

      if (openHoursFormatted && closeHoursFormatted) {
        hours.push({ day: day.dayValue, open: openHoursFormatted, close: closeHoursFormatted });
      }
    }

    return hours;
  }

  async toggleSubPanel(stepField?: StepField) {
    if (stepField?.queryVar === RooftopDepartmentsDetailsBuilderFields.LOCATION) {
      this.setState({
        childrenBeforeSubStep: (
          <LocationSelector
            location={stepField.selectedValue}
            onDone={location => this.onFieldSelection(stepField, location)}
          />
        ),
      });
    } else {
      this.setState({ childrenBeforeSubStep: null });
    }

    await super.toggleSubPanel(stepField);
  }

  onFieldChange(
    stepField: StepField<RooftopDepartmentDetailQuery['item'], RooftopDepartmentsMetaQuery, any>,
    e: Record<'currentTarget', { value: any }>
  ): void {
    super.onFieldChange(stepField, e);

    const listOfDays = this.getDaysOfTheWeekFields();
    if (listOfDays.some(({ dayValue }) => dayValue === stepField.queryVar)) {
      this.toggleDayTimeFields(stepField.queryVar, !e.currentTarget.value);
    }
  }

  onFieldSelection(
    stepField: StepField<RooftopDepartmentDetailQuery['item'], RooftopDepartmentsMetaQuery, any>,
    value: any
  ) {
    super.onFieldSelection(stepField, value);

    if (stepField.queryVar === RooftopDepartmentsDetailsBuilderFields.TYPE) {
      const showLocationField = value?.id === RooftopDepartmentType.SERVICE;
      const locationField = getStepField(RooftopDepartmentsDetailsBuilderFields.LOCATION, this.fields);

      setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: !showLocationField }], locationField);

      if (!showLocationField) {
        locationField.selectedValue = null;
      }
    }
  }

  save() {
    const {
      tier: { isCreating, seededData, data },
    } = this.props;

    const hours = this.getDayTimeVariables();

    if (isCreating) {
      return super.save({}, { hours, rooftopId: seededData?.rooftop?.id });
    }

    return super.save({}, { hours, id: data?.id });
  }
}

export default DetailsStep;
