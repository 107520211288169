import { memo, type SVGProps } from 'react';

const DiscountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M11.175 22.203a2.283 2.283 0 0 1-1.61-.671l-7.096-7.097a2.098 2.098 0 0 1-.5-.75 2.338 2.338 0 0 1 0-1.693c.105-.282.272-.536.5-.764l8.74-8.753c.207-.212.452-.378.734-.498.283-.12.575-.18.876-.18h7.115c.624 0 1.158.222 1.603.666.444.445.666.979.666 1.603v7.115a2.243 2.243 0 0 1-.659 1.591l-8.759 8.76a2.284 2.284 0 0 1-1.61.672Zm6.264-14.155c.414 0 .765-.145 1.055-.434.29-.29.434-.64.434-1.053 0-.414-.144-.765-.434-1.055-.289-.29-.64-.434-1.053-.434-.413 0-.765.144-1.054.433-.29.29-.435.64-.435 1.054 0 .413.145.765.434 1.054.29.29.64.435 1.053.435Z"
    />
  </svg>
);
export default memo(DiscountIcon);
