import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { InviteAppraisalBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { getContactOptions } from 'components/sections/shared/ItemMetaHelpers';
import { ContactType } from 'store/api/graph/interfaces/types';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

class InviteAppraisersStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: {
        activeStep,
        data: { rooftop },
      },
    } = this.props;
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [InviteAppraisalBuilderFields.CONTACT_ID]: {
        request: keyword => getContactOptions(ContactType.WHOLESALER, rooftop?.id, false, keyword),
      },
    };
  }

  async save() {
    if (!this.validateFields()) {
      return false;
    }

    const contactId = this.fields
      .find(field => field.queryVar === InviteAppraisalBuilderFields.CONTACT_ID)
      ?.selectedValue?.map?.(contact => contact.id);

    return super.save(undefined, { id: this.props.tier.seededData?.id, contactId });
  }
}

export default InviteAppraisersStep;
