import type { ElementType, ReactNode } from 'react';

import type { DefaultPrivacyLevel } from '@datadog/browser-rum';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';

import type StepFieldHyperlink from 'components/core/createModify/interfaces/stepFieldHyperlink';
import type StepFieldSuffixIconAttributes from 'components/core/createModify/interfaces/stepFieldSuffixIconAttributes';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepFieldInputAttributes } from 'components/core/createModify/stepFields/interfaces';
import type { BuilderType } from 'enums/builderType';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import type { StepFieldSubType } from 'enums/stepFieldSubType';
import type { StepFieldType } from 'enums/stepFieldType';
import type { UserScope } from 'store/api/graph/interfaces/types';
import type { RequiredPermissions } from 'types/Permissions';

import type { ListCondition } from '../stepFields/subSteps/listCondition';

import type ClearSettings from './clearSettings';
import type StepFieldPreviewContents from './stepFieldPreviewContents';
import type Tier from './tier';

interface FieldTarget {
  /** List of fields from where the data originates */
  sources: string[];
  /** The property from the fields specified in `sources` used to access the value needed */
  sourceProp?: string;
  /** Field to populate from the given `sources` */
  target: string;
}

export enum StepFieldDisplayType {
  /**
   * A disabled field is not accessible or modifiable in the UI, however it will still be included in
   * any API requests
   */
  DISABLED = 'disabled',
  /** A hidden field is not shown in the UI, but its value is still included in the payload of any API requests */
  HIDDEN = 'hidden',
  /** An omitted field does not have its value included in the payload of any API requests */
  OMITTED = 'omitted',
  /** If no StepFieldDisplayType is explicitly defined, the field will NOT be disabled, hidden, or omitted */
}

export interface SubStepAddConfig<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
> {
  /** Title of builder tier */
  builderTitle: string;
  /** Type of builder */
  builderType: BuilderType;
  /** TODO: Remove before closing [#2653] */
  entityType?: ExtendedEntityType;
  /** List of required fields to pull from the `data` object to prefill the new builder */
  dataFields?: FieldTarget[];
  /** Data to be seeded into the `data` object to prefill the new builder */
  defaultSeededData?: any;
  /** Method used to seed data from a main tier to a sub tier */
  seedPrefillMethod?: (preFill: any, tierData: Tier<TData, TMetaData>) => Record<string, unknown>;
  /** The user scope needed to access the sub-step. If omitted, all scopes will be allowed */
  allowedScopes?: UserScope[];
}

export enum SubStepType {
  /** The default behavior of a subStep handled by a subStep controller (opens/closes when field is selected) */
  DEFAULT = 'default',
  /** For asynchronous displays (e.g. rooftop options, YMMT options, etc) */
  ASYNC = 'async',
  /**
   * When a field is selected, the subStep cannot be closed by conventional means,
   * unless selecting a different field
   */
  PERSIST = 'persist',
  /**
   * Custom behavior and possibly rendering (e.g. vin selection shadow step,
   * which does changes depending on field value)
   */
  CUSTOM = 'custom',
}

/**
 * The data of each tier being rendered
 */
export default interface StepField<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TReturnValue = any,
> extends StepFieldInputAttributes {
  /** The label of the input */
  label?: string;
  /** The intrinsic placeholder attribute of the input */
  placeholder?: string;
  /** Suffix icon to appear right after the label */
  suffixIcon?: StepFieldSuffixIconAttributes;
  /** The sub label of the input */
  subLabel?: string;
  /** If provided, this label will override sub-step search field label */
  overrideSubStepSearchLabel?: string;
  /** The type of input this field will be rendering for */
  groupType?: StepFieldType;
  /**
   * Used for StepFieldType.RENDER_OBJECT & StepFieldType.CUSTOM, used as the component class
   * for custom substep & field renders
   */
  renderElement?: ElementType;
  /** Used to override the render element in a substep selection list */
  overrideSubStepRenderElement?: ElementType;
  /* An array of possible subTypes (multiSelect, isColor, etc.) */
  groupSubTypes?: StepFieldSubType[];
  /** The query variable that the input will be updating, doubles up as an `id` */
  queryVar: string;
  /** A secondary identifier, e.g. `vehicleAttributes.mileage` = `mileage` in the API */
  queryAlias?: string | string[];
  /** Current value of the field */
  selectedValue?: TReturnValue;
  /**
   * Used mainly for multiSelect type inputs, a list of pre-selected values that may not necessarily
   * be part of the original values
   */
  seededValues?: Array<any>;
  /** The type of display type for this field (hidden, disabled, omitted, etc) */
  displayType?: StepFieldDisplayType[];
  /** Whether or not this field is the one currently selected */
  active?: boolean;
  /** A data pointer or a list of associated selection options required for the field to render */
  options?: string | StepFieldOptions[] | StepField<TData, TMetaData>[];
  /**
   * Whether or not this field opens up a subStep (e.g. dropdown, multiSelect, etc), contains an array of types
   * describing how it renders
   */
  subStep?: SubStepType[];
  /** Used for list type substeps. A list of categories to filter the results by */
  subStepCategories?: ListCondition[];
  /**
   * Used for list type substeps, different from subStepCategories. A list of
   * groups that each option falls under when rendering. Group renders a heading
   */
  subStepGroups?: ListCondition[];
  /** Any additional css added to customize a field's container */
  containerStyles?: FlattenSimpleInterpolation;
  /** Any additional css added to customize a field's containerInner */
  containerInnerStyles?: FlattenSimpleInterpolation;
  /**
   * Whether or not this field requires a value, used when client-side validation is required.
   * Will be ignored if the field's display type is set to DISABLED, HIDDEN, or OMITTED.
   */
  required?: boolean;
  /**
   * Force this field to be required during client-side validation, regardless of display type.
   * Useful for when a field is omitted for property naming reasons, but still needs to have a value selected for it.
   * @example LeadEmploymentInformationStep
   */
  forceRequired?: boolean;
  /**
   * Permission constraints which control whether a field is rendered. If the users permissions does not meet the given
   * criteria, then the field will not be rendered. However this is an optional field, so if no permissions are given,
   * the field will be rendered regardless
   */
  requiredPermissions?: RequiredPermissions;
  /** Only used when field is required, meaning client-side validation happened and custom message is displayed */
  customError?: string;
  /** The clear variable this field targets in the `_clear` parameter of modify mutations */
  clear?: ClearSettings;
  /** Whether or not this field will always advance to the next, used for linked fields like YMMT */
  forceAdvance?: boolean;
  /** Settings are essentially props for a StepFieldInput (e.g. SliderInput require specific max & step value props) */
  settings?: any;
  /** Settings for invoking a new tier used for adding data to a list */
  subStepAddConfig?: SubStepAddConfig<TData, TMetaData>;
  /** Whether or not this field should include a separator at the bottom **/
  hasSeparator?: boolean;
  /** Used to display a link to the right of the label for this input field */
  hyperlink?: StepFieldHyperlink;
  /** Tooltip contents for this field, a tooltip info icon will be placed beside the label if this is defined */
  tooltipContents?: ReactNode;
  /**
   * Preview contents for this field. When defined, a preview will be shown whenever the field has focus. This
   * property expects a component with one prop - fieldValue which will be the current contents of the field
   * being previewed.
   */
  previewContents?: (props: StepFieldPreviewContents) => JSX.Element;
  /**
   * Whether the queryAlias, insteaad of the default queryVar, should be used to retrieve data.
   * If queryAlias is an array, the first alias will be used.
   */
  useQueryAliasForDataRetrieval?: boolean;
  /** Specifies how datadog should mask this input e.g. in a session replay. */
  defaultPrivacyLevel?: DefaultPrivacyLevel;
  /** Whether or not editing this step field should open the builder in an expanded state */
  shouldExpandBuilderOnEdit?: boolean;
  /** Whether this step field is locked */
  isLocked?: boolean;
  /** Whether this step field shows a removal icon */
  canDelete?: boolean;
}
