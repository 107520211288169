import { gql } from '@apollo/client';

export const overlayRetailBulkAdjustmentAttributes = gql`
  fragment OverlayRetailBulkAdjustmentAttributesFragment on OverlayRetailBulkAdjustmentAttributes {
    overlayImageUrl
    primaryPhotoOnly
    overlayPosition: position
    overlayPositionName: positionName
    scale
    scalingDimension
    scalingDimensionName
  }
`;
