import { useCallback, useMemo, useState } from 'react';

import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { Rooftop } from 'enums/columns/rooftop';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

import { rooftopModify } from '../createModify/rooftops/RooftopCreateModifyQuery';

interface Props extends Omit<MenuItemProps, 'searchFilters' | 'onToggleEditMode' | 'setIsDetailsUpdating'> {
  item: RooftopResponseType;
}

const { t } = translate;

const getActiveStatusLabel = active => (active ? t('suspend_rooftop') : t('activate_rooftop'));

const RooftopMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { hasPermissions, isWhiteLabelScoped } = useUser();
  const { id, active, groupName } = item || {};
  const { toggleTier } = useCreateModify();
  const [isTogglingActivate, setIsTogglingActivate] = useState(false);
  const [prevActive, setPrevActive] = useState<boolean | undefined>(active);
  const activeStatusLabel = useMemo(() => getActiveStatusLabel(prevActive ?? active), [prevActive, active]);

  // onClick callbacks
  const onModifyClicked = useCallback(
    (activeField?: string) => {
      toggleTier(CreateModifyTiers.TIER_0, {
        activeField,
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOPS_MODIFY,
        entityType: CustomEntity.ROOFTOP,
        title: t('modify_x', [item.name.value]),
        isCreating: false,
        itemId: item.id,
        data: { ...item },
      });
    },
    [toggleTier, item]
  );

  const onModifyRepClicked = useCallback(() => onModifyClicked(Rooftop.ACCOUNT_REP_NAME), [onModifyClicked]);

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('change_assigned_rep'),
      onClick: onModifyRepClicked,
      disabled: !groupName?.id || !hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
    },
    {
      label: t('modify'),
      onClick: () => onModifyClicked(),
      disabled: !hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
    },
    {
      label: activeStatusLabel,
      isArchivable: false,
      onClick: () => setIsTogglingActivate(true),
      disabled: !isWhiteLabelScoped || !hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions),
    },
  ];

  const onConfirm = useCallback(async () => {
    setPrevActive(active);
    await client.mutate({
      mutation: rooftopModify,
      variables: { id, active: !active },
    });
  }, [id, active]);

  const onCancel = useCallback(() => {
    setIsTogglingActivate(false);
    setPrevActive(undefined);
  }, []);

  const onClose = useCallback(() => setPrevActive(undefined), []);

  return (
    <>
      <MenuItems items={menuItemsConfig} isArchived={!active} />
      <PromptDialog
        isFixedToTop
        isOpen={isTogglingActivate}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onClose={onClose}
        title={activeStatusLabel}
      />
    </>
  );
};

export default RooftopMenuItems;
