import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { ContainerInnerStyles, ContainerStyles } from 'components/core/createModify/stepFields/StepFieldLayout';
import { Rooftop, RooftopPackageAddons, RooftopPackageFeature } from 'enums/columns/rooftop';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopModifyParameter } from 'store/api/graph/interfaces/types';

import { RooftopPackageBuilderFields } from './interfaces';

const PackageFields: StepFields = {
  [RooftopPackageBuilderFields.PACKAGE]: {
    label: 'rooftop_package_one',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    queryAlias: Rooftop.PACKAGE,
    clear: { field: RooftopModifyParameter._bundle },
    options: 'featurePackages',
    useQueryAliasForDataRetrieval: true,
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.OEM]: {
    label: 'oem_program',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    queryAlias: RooftopPackageFeature.OEM_NAME,
    options: 'metadata.mutation.rooftop.bundle.oem',
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.LEAD_ENABLED]: {
    label: 'lead_other',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.LEAD_ENABLED,
    useQueryAliasForDataRetrieval: true,
  },
  [RooftopPackageBuilderFields.LEAD_SMS_MAILBOX_LIMIT]: {
    label: 'sms_mailbox_limit',
    groupType: StepFieldType.NUMBER,
    queryAlias: RooftopPackageAddons.LEAD_SMS_MAILBOX_LIMIT,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
    settings: {
      maxLength: 6,
    },
  },
  [RooftopPackageBuilderFields.LEAD_CONVERSATIONS]: {
    label: 'conversations',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.LEAD_CONVERSATIONS,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
  },
  [RooftopPackageBuilderFields.LEAD_FORWARDING]: {
    label: 'forwarding',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.LEAD_FORWARDING,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.APPOINTMENT_ENABLED]: {
    label: 'appointments_other',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.APPOINTMENT_ENABLED,
    useQueryAliasForDataRetrieval: true,
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.TASK_ENABLED]: {
    label: 'tasks_other',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.TASK_ENABLED,
    useQueryAliasForDataRetrieval: true,
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.TRADE_IN_ENABLED]: {
    label: 'trade_in_inventory',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.TRADE_IN_ENABLED,
    useQueryAliasForDataRetrieval: true,
  },
  [RooftopPackageBuilderFields.GUARANTEED_OFFER_PACKAGE]: {
    label: 'guaranteed_offer_package',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT],
    queryAlias: RooftopPackageAddons.GUARANTEED_OFFER_PACKAGE,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
    hasSeparator: true,
  },
  [RooftopPackageBuilderFields.RETAIL_ENABLED]: {
    label: 'retail_inventory',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.RETAIL_ENABLED,
    useQueryAliasForDataRetrieval: true,
  },
  [RooftopPackageBuilderFields.RETAIL_BUILD_AND_PRICE]: {
    label: 'build_and_price',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.RETAIL_BUILD_AND_PRICE,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
  },
  [RooftopPackageBuilderFields.RETAIL_SHOWROOM]: {
    label: 'showroom',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.RETAIL_SHOWROOM,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
  },
  [RooftopPackageBuilderFields.RETAIL_PAYMENT_OPTION]: {
    label: 'payment_option_one',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT],
    queryAlias: RooftopPackageAddons.RETAIL_PAYMENT_OPTION,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
    containerInnerStyles: ContainerInnerStyles.INDENTED_ROUNDED_CORNERS,
  },
  [RooftopPackageBuilderFields.RETAIL_PREMIUM_EXPORT_INTEGRATION]: {
    label: 'premium_ad_export_other',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT],
    queryAlias: RooftopPackageAddons.RETAIL_PREMIUM_EXPORT_INTEGRATION,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(),
    containerInnerStyles: ContainerInnerStyles.INDENTED_ROUNDED_CORNERS,
  },
  [RooftopPackageBuilderFields.RETAIL_UNLIMITED_INVENTORY]: {
    label: 'unlimited_inventory',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopPackageAddons.RETAIL_UNLIMITED_INVENTORY,
    useQueryAliasForDataRetrieval: true,
    displayType: [StepFieldDisplayType.OMITTED],
    containerStyles: ContainerStyles.INDENTED(),
  },
  [RooftopPackageBuilderFields.RETAIL_ITEM_LIMIT]: {
    label: 'limit',
    groupType: StepFieldType.NUMBER,
    queryAlias: RooftopPackageAddons.RETAIL_ITEM_LIMIT,
    useQueryAliasForDataRetrieval: true,
    containerStyles: ContainerStyles.INDENTED(2),
    settings: {
      maxLength: 6,
    },
  },
};

export default PackageFields;
