import { memo } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TertiaryText from 'components/core/typography/TertiaryText';
import Text from 'components/core/typography/Text';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import type { Incentive } from 'store/api/graph/interfaces/types';
import { FONT_SIZE_12, FONT_WEIGHT_BOLD } from 'styles/typography';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { getIncentiveAdjustmentAmount } from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

const DisclaimerText = styled(Text)`
  white-space: pre-wrap;
  font-size: ${FONT_SIZE_12};
`;

interface Props extends Incentive, ListItemProps {}

/**
 * Incentive list item used in the builder to select which incentive to enable/disable. Renders the label, disclaimer
 * text, starting and ending dates, and adjustment amount
 */
const IncentiveOptionListItem = ({ label, starting, ending, disclaimer, attributes, listItemType, fee }: Props) => {
  const incentiveAdjustmentAmount = getIncentiveAdjustmentAmount(attributes, { hidePercentageAmount: true });
  return (
    <ListItem listItemType={listItemType}>
      <ListItemDetails>
        {!!incentiveAdjustmentAmount && (
          <TextRow>
            <PrimaryText css="white-space: normal;">{incentiveAdjustmentAmount}</PrimaryText>
            <TertiaryText
              css={css`
                font-weight: ${FONT_WEIGHT_BOLD};
              `}
            >
              &bull; {translate.t(fee ? 'fee' : 'discount')}
            </TertiaryText>
          </TextRow>
        )}
        <TextRow>
          <Text title={label.value}>{label.value}</Text>
        </TextRow>
        <TextRow>
          <SecondaryText>
            {getFormattedDateTimeString(starting, DateTimeFormat.DATE_YEAR_FORMAT)}
            {` ${translate.t('to').toLowerCase()} `}
            {getFormattedDateTimeString(ending, DateTimeFormat.DATE_YEAR_FORMAT)}
          </SecondaryText>
        </TextRow>
        {!!disclaimer?.value && (
          <TextRow>
            <DisclaimerText>{disclaimer.value}</DisclaimerText>
          </TextRow>
        )}
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(IncentiveOptionListItem);
