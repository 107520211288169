import 'styled-components/macro';

import { InputContainer, InputSuffix } from './InputText';
import NumberInput from './NumberInput';

const DisplacementInput = props => (
  <InputContainer>
    <NumberInput css="padding-right: 40px" settings={{ allowDecimal: true }} {...props} />
    <InputSuffix>L</InputSuffix>
  </InputContainer>
);

export default DisplacementInput;
