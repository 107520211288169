import { gql } from '@apollo/client';

import { carfaxReport } from 'store/api/graph/fragments/carfaxReport';

export const carfaxReportQuery = gql`
  query CarfaxReportQuery($vin: String!, $rooftopId: ID!) {
    carfaxReport(vin: $vin, rooftopId: $rooftopId) {
      ...CarfaxReportFragment
    }
  }
  ${carfaxReport}
`;
