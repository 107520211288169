import { memo } from 'react';

import styled from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import PositionBottomCenterIcon from 'components/ui/icons/PositionBottomCenterIcon';
import PositionBottomLeftIcon from 'components/ui/icons/PositionBottomLeftIcon';
import PositionBottomRightIcon from 'components/ui/icons/PositionBottomRightIcon';
import PositionCenterIcon from 'components/ui/icons/PositionCenterIcon';
import PositionCenterLeftIcon from 'components/ui/icons/PositionCenterLeftIcon';
import PositionCenterRightIcon from 'components/ui/icons/PositionCenterRightIcon';
import PositionTopCenterIcon from 'components/ui/icons/PositionTopCenterIcon';
import PositionTopLeftIcon from 'components/ui/icons/PositionTopLeftIcon';
import PositionTopRightIcon from 'components/ui/icons/PositionTopRightIcon';
import { OverlayRetailBulkAdjustmentPosition } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import { ENTITY_PADDING } from 'styles/spacing';

const Container = styled.div`
  padding: ${ENTITY_PADDING};
  display: flex;
`;

const PositionText = styled(Text)`
  margin-left: ${ENTITY_PADDING};
  align-self: center;
`;

const BulkOverlayAdjustmentImagePositionListItem = ({ id, name }: SelectOption) => {
  const icons = {
    [OverlayRetailBulkAdjustmentPosition.TOP_LEFT]: PositionTopLeftIcon,
    [OverlayRetailBulkAdjustmentPosition.TOP_CENTER]: PositionTopCenterIcon,
    [OverlayRetailBulkAdjustmentPosition.TOP_RIGHT]: PositionTopRightIcon,
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_LEFT]: PositionCenterLeftIcon,
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_CENTER]: PositionCenterIcon,
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_RIGHT]: PositionCenterRightIcon,
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_LEFT]: PositionBottomLeftIcon,
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_CENTER]: PositionBottomCenterIcon,
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_RIGHT]: PositionBottomRightIcon,
  };

  const Icon = icons[id];

  return (
    <Container>
      {Icon && <Icon />}
      <PositionText>{name}</PositionText>
    </Container>
  );
};

export default memo(BulkOverlayAdjustmentImagePositionListItem);
