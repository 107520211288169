import { useState } from 'react';

import styled from 'styled-components/macro';

import { PrimaryLabel } from 'components/core/typography/Label';
import { Clickable } from 'components/ui/shared/Button';
import Toggle from 'components/ui/shared/Toggles';
import { useSearch } from 'hooks/contexts/useSearch';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';

const ShowMore = styled(Clickable)`
  width: 100%;
  color: ${BLUE_500};
  border-top: 1px solid ${DIVIDER};
  padding: 0;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MAX_STATIC_TOGGLES = 4;

export const ToggleGroupFilters = ({ toggles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getSearchParam, updateSearchParam } = useSearch();
  const isShowMoreVisible = toggles.length > MAX_STATIC_TOGGLES;

  return (
    <>
      {toggles.slice(0, MAX_STATIC_TOGGLES).map(toggle => (
        <Toggle
          testId={toggle.id}
          key={toggle.id}
          label={toggle.name}
          value={getSearchParam(toggle.id)}
          onChange={value => updateSearchParam(toggle.id, value)}
        />
      ))}
      {isExpanded && (
        <>
          {toggles.slice(MAX_STATIC_TOGGLES).map(toggle => (
            <Toggle
              testId={toggle.id}
              key={toggle.id}
              label={toggle.name}
              value={getSearchParam(toggle.id)}
              onChange={value => updateSearchParam(toggle.id, value)}
            />
          ))}
        </>
      )}
      {isShowMoreVisible && (
        <ShowMore onClick={() => setIsExpanded(!isExpanded)}>
          <PrimaryLabel css="width: 100%">{translate.t(isExpanded ? 'show_less' : 'show_more')}</PrimaryLabel>
          {isExpanded ? <ChevronUpIcon color={BODY_TEXT} /> : <ChevronDownIcon color={BODY_TEXT} />}
        </ShowMore>
      )}
    </>
  );
};
