import { memo } from 'react';

import styled from 'styled-components/macro';

import SortDirectionUpIcon from './SortDirectionUpIcon';

const SortDirectionDownIcon = styled(SortDirectionUpIcon)`
  transform: rotate(180deg);
`;

export default memo(SortDirectionDownIcon);
