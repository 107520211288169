export enum RetailItemPriceAdjustmentBuilderFields {
  INCLUDE_CASH = 'includeCash',
  INCLUDE_FINANCE = 'includeFinance',
  INCLUDE_LEASE = 'includeLease',
  LABEL = 'label',
  DISCLAIMER = 'disclaimer',
  AMOUNT = 'amount',
  AMOUNT_FIXED = 'fixed',
  AMOUNT_PERCENTAGE = 'percentage',
  // Amount adjustment type refers to an adjustment thats either a discount or a fee
  AMOUNT_ADJUSTMENT_TYPE = 'amountAdjustmentType',
}
