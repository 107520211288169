import type { ReactNode } from 'react';

import type { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import ExternalLinkIcon from 'components/ui/icons/ExternalLinkIcon';

import { TextNavLink } from './WrapLink';

const ExternalLinkTextIcon = styled(ExternalLinkIcon)`
  margin-left: 10px;
  flex-shrink: 0;
`;

const NavLinkContainer = styled(TextNavLink)`
  display: flex;
  align-items: center;
`;

export interface TextNavLinkExternalProps extends NavLinkProps {
  /** Content to be rendered as children inside the ExternalLink component. */
  children?: ReactNode;
}

/**
 * A reusable component for rendering text and external link icon, wrapped with an anchor that opens in a new tab.
 */
export const TextNavLinkExternal = ({ children, ...props }: TextNavLinkExternalProps) => (
  <NavLinkContainer {...props} target="_blank">
    {/* For external links, `props.to` will always be a string rather than segments of a path */}
    {children || (props.to as string)}
    <ExternalLinkTextIcon />
  </NavLinkContainer>
);
