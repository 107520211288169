import styled from 'styled-components/macro';

import { NEUTRAL_300 } from 'styles/tokens';

const Swatch = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;

  /** TODO: [#1067] create utility here to parse 'info/data' from metadata */
  border: ${({ color }) =>
    ['WHITE', '#FFFFFF'].includes(color?.toUpperCase() || '') ? `1px solid ${NEUTRAL_300}` : 'none'};
  border-radius: 3px;
  background: ${({ color }) => color && color.toLowerCase()};
  opacity: ${({ color }) => (color ? 1 : 0)};
  flex-shrink: 0;
  transform: translateY(-2px);
`;

export default Swatch;
