import { useCallback, useState } from 'react';

import styled from 'styled-components/macro';

import Text, { TextCSS } from 'components/core/typography/Text';
import Spinner from 'components/ui/loading/Spinner';
import { Clickable } from 'components/ui/shared/Button';
import useOAuthDialog from 'hooks/useOAuthDialog';
import { NEUTRAL_0 } from 'styles/tokens';
import { authManager } from 'utils/authUtils';
import { translate } from 'utils/intlUtils';

const EmployeeSignInLink = styled(Text)`
  ${TextCSS}
  color: ${NEUTRAL_0};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
interface Props {
  /** Callback for when employee is successfully logged in */
  onLoginSuccess: (response: Record<string, any>) => void;
  /** Callback for the is an error authenticating employee */
  onLoginError: (error: Error) => void;
}

const LoginWithGoogleAuth = ({ onLoginSuccess, onLoginError }: Props) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const title = translate.t('employee_sign_in');
  const clientId = process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT_URI;
  const url = `${process.env.REACT_APP_OAUTH_GOOGLE_URI}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email&prompt=consent`;
  const onCodeRetrieved = useCallback(
    (code, windowRef) => {
      setIsAuthenticating(true);
      authManager
        .trySsoLogin(code)
        .then(response => {
          windowRef.current?.close?.();
          onLoginSuccess(response);
        })
        .catch((error: Error) => onLoginError(error))
        .finally(() => setIsAuthenticating(false));
    },
    [onLoginError, onLoginSuccess]
  );

  const onTriggerOAuth = useOAuthDialog({ onCodeRetrieved, title, url });

  return (
    <Clickable onClick={onTriggerOAuth} type="button">
      <EmployeeSignInLink> {isAuthenticating ? <Spinner fill={NEUTRAL_0} /> : title}</EmployeeSignInLink>
    </Clickable>
  );
};

export default LoginWithGoogleAuth;
