import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { leadMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { leadDetailsQuery } from 'containers/leads/LeadsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { LeadMetaQuery, LeadMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const LeadQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<LeadMetaQuery, LeadMetaQueryVariables>({
      query: leadMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: leadDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'LeadConnectionQuery',
    'LeadCondensedListQuery',
    'UserDetailsContainerQuery',
    'LeadDetailsContainerQuery',
    'ConversationDetailsContainerQuery',
  ],
};

export default LeadQueryMethods;
