import { useCallback } from 'react';

import type { Props } from './InputText';
import { Input } from './InputText';

/**
 * Input to capture `phoneNumber` types.
 */
const PhoneNumber = (props: Props) => (
  <Input
    type="tel"
    {...props}
    onChange={useCallback(
      e => {
        // Regex that removes all characters that are not `0-9`, `(`, `)`, `+` or `-`
        e.currentTarget.value = e.currentTarget.value.replaceAll(/[^\d()+-]/g, '');

        props.onChange?.({ ...e, currentTarget: { value: e.currentTarget.value } });
      },
      [props]
    )}
  />
);

export default PhoneNumber;
