import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import type { Spread } from 'type-fest';

import Content from 'components/ui/editor/components/core/Content';
import RichTextEditorContainer from 'components/ui/editor/containers/RichTextEditorContainer';
import MultilingualMenuBar from 'components/ui/editor/MultilingualMenuBar';
import type { RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';

type Props = Spread<
  Omit<
    ComponentProps<typeof RichTextEditorContainer>,
    'children' | 'onGenerateText' | 'textGenerationEnabledLocales' | 'isReturnedAsText'
  >,
  ComponentProps<typeof MultilingualMenuBar>
>;

const MultilingualEditor = forwardRef<RichTextEditorContextInterface, Props>(({ insertOptions, ...props }, ref) => (
  <RichTextEditorContainer ref={ref} isPlainText {...props}>
    <MultilingualMenuBar insertOptions={insertOptions} />
    <Content />
  </RichTextEditorContainer>
));

export default MultilingualEditor;
