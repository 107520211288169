import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { RooftopBuilderStep } from 'components/sections/createModify/rooftops/steps/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { featureBundleEditingRequiredPermissions } from 'utils/featureBundleUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';

import { rooftopCreate, rooftopModify } from './RooftopCreateModifyQuery';
import RooftopQueryMethods from './RooftopQueryMethods';
import RooftopDescriptionFields from './steps/DescriptionFields';
import RooftopDescriptionStep from './steps/DescriptionStep';
import RooftopDetailsFields from './steps/DetailsFields';
import RooftopDetailsStep from './steps/DetailsStep';
import RooftopPackageFields from './steps/PackageFields';
import RooftopPackageStep from './steps/PackageStep';

const RooftopBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOPS_CREATE]: {
    getMetaData: RooftopQueryMethods.getMetaData,
    refetchQueries: RooftopQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopBuilderStep.DETAILS,
        step: RooftopDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: RooftopDetailsFields,
        tooltip: {
          title: 'create_rooftop',
          content: '',
        },
      },
      {
        id: RooftopBuilderStep.PACKAGE,
        step: RooftopPackageStep,
        stepName: 'rooftop_package_one',
        isEnabled: false,
        fields: RooftopPackageFields,
        featureFlagsRequired: [LDFeatureFlags.rooftopPackageEnabled],
        permissionsRequired: [featureBundleEditingRequiredPermissions],
      },
      {
        id: RooftopBuilderStep.DESCRIPTION,
        step: RooftopDescriptionStep,
        stepName: 'description',
        isEnabled: false,
        fields: RooftopDescriptionFields,
      },
    ],
    mutations: {
      create: rooftopCreate,
      validate: rooftopCreate,
    },
  },
  [BuilderType.ROOFTOPS_MODIFY]: {
    getMetaData: RooftopQueryMethods.getMetaData,
    getData: RooftopQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopBuilderStep.DETAILS,
        step: RooftopDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: RooftopDetailsFields,
      },
      {
        id: RooftopBuilderStep.PACKAGE,
        step: RooftopPackageStep,
        stepName: 'rooftop_package_one',
        isEnabled: true,
        fields: RooftopPackageFields,
        featureFlagsRequired: [LDFeatureFlags.rooftopPackageEnabled],
        permissionsRequired: [featureBundleEditingRequiredPermissions],
      },
      {
        id: RooftopBuilderStep.DESCRIPTION,
        step: RooftopDescriptionStep,
        stepName: 'description',
        isEnabled: true,
        fields: RooftopDescriptionFields,
      },
    ],
    mutations: {
      modify: rooftopModify,
    },
  },
};

export default RooftopBuilders;
