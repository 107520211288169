import type { HTMLProps } from 'react';
import { forwardRef } from 'react';

import { FloatingPortal, useMergeRefs } from '@floating-ui/react';

import { usePopoverContext } from 'hooks/contexts/usePopoverContext';

const PopoverContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(({ style, ...props }, propRef) => {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) {
    return null;
  }

  /*
   * TODO: [ED-10762] Investigate why FloatingFocusManager causes Popover to rerender
   * with isOpen false for ASYNC stepfields
   */

  return (
    <FloatingPortal id="modal-root">
      {/* <FloatingFocusManager context={floatingContext} modal={context.modal}> */}
      <div
        ref={ref}
        style={{ ...context.floatingStyles, ...style }}
        aria-labelledby={context.labelId}
        aria-describedby={context.descriptionId}
        {...context.getFloatingProps(props)}
      >
        {props.children}
      </div>
      {/* </FloatingFocusManager> */}
    </FloatingPortal>
  );
});

export default PopoverContent;
