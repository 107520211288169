export enum RetailBulkAdjustmentBuilderFields {
  PRICE_ADJUSTMENTS = 'priceAdjustments',
  OVERLAY_ADJUSTMENTS = 'overlayAdjustments',
  DESCRIPTION_ADJUSTMENTS = 'descriptionAdjustments',
  PROMOTION_ADJUSTMENTS = 'promotionAdjustments',
  DISCLAIMER_ADJUSTMENTS = 'disclaimerAdjustments',
}

export enum RetailItemDetailsBuilderFields {
  VEHICLE_LOCKED_FIELDS = 'vehicleAttributes.lockedFields',
  LOCKED_FIELDS = 'lockedFields',
  CONDITION = 'condition',
  FLAGS = 'flags',
  TAG_IDS = 'tagIds',
  MANUFACTURER_VEHICLE_OPTION_CODES = 'vehicleAttributes.manufacturerVehicleOptionCodes',
  STANDARD_EQUIPMENT = 'standardEquipment',
  TECHNICAL_SPECIFICATIONS = 'technicalSpecification',
}

export enum RetailItemBuilderSteps {
  VIN = 'VIN',
  DETAILS = 'DETAILS',
  PRICING_AND_MILEAGE = 'PRICING_AND_MILEAGE',
  DESCRIPTION = 'DESCRIPTION',
  MEDIA = 'MEDIA',
  BULK_ADJUSTMENTS = 'BULK_ADJUSTMENTS',
}

export enum CarfaxReportErrors {
  NO_CARFAX_ACCOUNT_ON_ROOFTOP = 'NO_CARFAX_ACCOUNT_ON_ROOFTOP',
  NO_CARFAX_FOUND = 'NO_CARFAX_FOUND',
}
