import { gql } from '@apollo/client';

import { address } from './address';

export const rooftopDepartment = gql`
  fragment RooftopDepartmentFragment on RooftopDepartment {
    id
    email
    isOpen
    phoneNumber
    type
    typeName

    # This is aliased to allow searching by the department typeName
    name: typeName

    hours {
      day
      dayName
      open
      close
    }

    location {
      ...AddressFragment
    }
  }

  ${address}
`;
