import { get, isEmpty, isNil } from 'lodash-es';

import type { StepComponentProps, StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import type { MaskInputSettings } from 'components/ui/forms/shared/MaskedInput';
import { leadSINDecodeQuery } from 'containers/leads/LeadsContainerQuery';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { StepFieldType } from 'enums/stepFieldType';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import type {
  LeadDetailsContainerQuery,
  LeadMetaQuery,
  LeadModifyMutationVariables,
  LeadSINDecodeQuery,
  LeadSINDecodeQueryVariables,
} from 'store/api/graph/interfaces/types';
import { LeadModifyParameter } from 'store/api/graph/interfaces/types';
import { defineFieldValues, getStepField, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import {
  calculateTotalDuration,
  DURATION_MONTHS_CUSTOM_ERROR,
  DURATION_YEARS_CUSTOM_ERROR,
  durationAsMonthsRemainder,
  durationAsYearsTruncated,
  validateDurationFields,
} from 'utils/leadUtils';

import { LeadEmploymentInformationBuilderFields } from './interfaces';

const { t } = translate;

type LeadDetails = LeadDetailsContainerQuery['item'];

interface LeadEmploymentInformationState extends StepComponentState {
  hasUserEditedSIN: boolean;
}

class LeadEmploymentInformationStep extends StepComponentCore<LeadDetails, LeadMetaQuery, LeadModifyMutationVariables> {
  state: LeadEmploymentInformationState = {
    ...defaultState,
    hasUserEditedSIN: false,
  };

  constructor(
    props: StepComponentProps<LeadDetails, LeadMetaQuery, LeadModifyMutationVariables>,
    _context: CreateModifyContextInterface<LeadDetails, LeadMetaQuery>
  ) {
    super(props);
    const {
      tier: { data, activeStep, metadata },
    } = props;

    const durationMonths = data.employment?.durationMonths;

    // Convert the duration fields to the correct format
    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [LeadEmploymentInformationBuilderFields.DURATION_MONTHS]: {
        selectedValue: durationMonths ? durationAsMonthsRemainder(durationMonths) : null,
        customError: DURATION_MONTHS_CUSTOM_ERROR(t),
      },
      [LeadEmploymentInformationBuilderFields.DURATION_YEARS]: {
        selectedValue: durationMonths ? durationAsYearsTruncated(durationMonths) : null,
        customError: DURATION_YEARS_CUSTOM_ERROR(t),
      },
      [LeadEmploymentInformationBuilderFields.SIN]: {
        selectedValue: data.sin,
        settings: {
          isInitiallyMasked: !isNil(data.sin),
        } as MaskInputSettings,
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data, metadata);
  }

  async onFieldRequestMaskToggle(stepField, isMaskOn) {
    const {
      tier: { data },
      setParentLoader,
    } = this.props;

    const { hasUserEditedSIN } = this.state;

    if (!isNil(data.sin) && !hasUserEditedSIN && !isMaskOn) {
      try {
        setParentLoader(true);
        const response = await this.client.query<LeadSINDecodeQuery, LeadSINDecodeQueryVariables>({
          query: leadSINDecodeQuery,
          variables: { leadId: data.id },
          fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        });

        getStepField(LeadEmploymentInformationBuilderFields.SIN, this.fields).selectedValue = response.data.lead.sin;
        setParentLoader(false);
        // Now that we have the actual SIN value we can unmask the input
        return false;
      } catch (error) {
        this.setTier({
          errors: getApiErrors(error),
        });
        setParentLoader(false);
        // There was an error getting the SIN value, don't unmask the input
        return true;
      }
    }

    return isMaskOn;
  }

  onFieldChange(stepField, e: Record<'currentTarget', { value: any }>) {
    if (stepField.queryVar === LeadEmploymentInformationBuilderFields.SIN) {
      super.onFieldChange(stepField, e, true);
      this.setState({ hasUserEditedSIN: true });
    }

    super.onFieldChange(stepField, e);
  }

  /**
   * Skip this step if nothing was changed, and the data was initially blank;
   * things should only be validated if at least one value was changed.
   * Used to prevent client-side validation, and server-side sending of data.
   */
  shouldSkip() {
    const {
      tier: { data },
    } = this.props;

    return (
      (!data || this.fields.every(field => isEmpty(get(data, field.queryVar)))) &&
      this.fields.every(field =>
        isEmpty(field.groupType === StepFieldType.DROPDOWN ? field.selectedValue?.id : field.selectedValue)
      )
    );
  }

  validateFields() {
    // Skip validation if the step should be skipped based on requirements in `shouldSkip()`
    if (this.shouldSkip()) {
      return true;
    }

    const durationErrors = validateDurationFields(
      this.fields,
      LeadEmploymentInformationBuilderFields.DURATION_MONTHS,
      LeadEmploymentInformationBuilderFields.DURATION_YEARS
    );

    if (durationErrors) {
      this.setTier({ errors: durationErrors });
      return false;
    }

    return super.validateFields();
  }

  /** Enable other steps for edit/navigation */
  enableSteps() {
    const {
      tier: { steps },
    } = this.props;

    for (const step of steps!) {
      step.isEnabled = true;
    }
  }

  async save() {
    const {
      tier: { data },
    } = this.props;

    // Replace durationMonths with the total computed value
    const durationMonths = calculateTotalDuration(
      this.fields,
      LeadEmploymentInformationBuilderFields.DURATION_MONTHS,
      LeadEmploymentInformationBuilderFields.DURATION_YEARS
    );

    // Remove the SIN value if it was not changed, as it's returned from the BE with asterisks
    const sinField = getStepField(LeadEmploymentInformationBuilderFields.SIN, this.fields);
    if (get(data, sinField.queryVar) === sinField.selectedValue) {
      sinField.selectedValue = null;
    }

    const success = await super.save(undefined, {
      employment: {
        durationMonths,
      },
      _clear: [sinField.selectedValue === '' && LeadModifyParameter._sin].filter(Boolean),
    });

    if (!success) {
      return false;
    }

    this.enableSteps();
    return true;
  }
}

export default LeadEmploymentInformationStep;
