import { gql } from '@apollo/client';

import { duration } from 'store/api/graph/fragments/duration';

export const appointment = gql`
  fragment AppointmentFragment on Appointment {
    ## Minimum required fields for table/listItem
    id
    title
    dateTo
    dateFrom
    date: dateFrom
    startTime: dateFrom
    endTime: dateTo
    reminder {
      ...DurationFragment
    }
    note
    archived
  }

  ${duration}
`;
