import { gql } from '@apollo/client';

export const tradeInItemConnectionFilterFragment = gql`
  fragment TradeInItemConnectionFilterFragment on TradeInItemConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    appraisedByUser
    archived
    awaitingAppraisals
    hasAppraisals
    inTransit
    leadId
    makeId
    modelId
    motorcycleAttributes {
      category
      color
      cylinders
      fuelType
      mileage {
        gte
        lte
      }
    }
    vehicleAttributes {
      bodyType
      cabType
      cylinders
      driveTrain
      exteriorColor
      fuelType
      interiorColor
      mileage {
        gte
        lte
      }
      numberOfDoors
      numberOfPassengers
      options
      transmission
    }
    phoneNumber
    status
    subModelId
    tagId
    trimId
    type
    valueSent
    yearRange {
      gte
      lte
    }
    created {
      gte
      lte
    }
    updated {
      gte
      lte
    }
  }
`;
