import { createContext } from 'react';

import type { PromptConfig } from 'components/ui/dialogs/PromptDialog';

export interface GlobalDialogContextInterface {
  /** Generic prompt configuration object */
  config?: PromptConfig;
  /**
   * Method to show/hide the global prompt, setting to `undefined`
   * or simply `setConfig` will close the global dialog
   */
  setConfig: (config?: PromptConfig) => void;
}

/**
 * The context class used for triggering global prompts
 */
export const GlobalDialogContext = createContext<GlobalDialogContextInterface | undefined>(undefined);
