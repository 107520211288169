import { gql } from '@apollo/client';

import { priceRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/priceRetailBulkAdjustmentAttributes';

export const retailBulkAdjustmentList = gql`
  fragment RetailBulkAdjustmentListFragment on RetailBulkAdjustment {
    id
    name
    starting
    ending
    typeName
    type
    active
    attributes {
      ... on PriceRetailBulkAdjustmentAttributes {
        ...PriceRetailBulkAdjustmentAttributesFragment
      }
    }
  }

  ${priceRetailBulkAdjustmentAttributes}
`;
