import { gql } from '@apollo/client';

import { leadForwardDetail } from 'store/api/graph/fragments/leadForwardDetail';

export const leadForwardCreate = gql`
  mutation LeadForwardCreateMutation($rooftopId: ID, $sourceIds: [ID!], $email: String, $adf: Boolean) {
    data: leadForwardCreate(rooftopId: $rooftopId, sourceIds: $sourceIds, email: $email, adf: $adf) {
      ...LeadForwardDetailFragment
    }
  }

  ${leadForwardDetail}
`;

export const leadForwardModify = gql`
  mutation LeadForwardModifyMutation(
    $id: ID!
    $email: String
    $adf: Boolean
    $sourceIds: [ID!]
    $_clear: [LeadForwardModifyParameter!]
  ) {
    data: leadForwardModify(id: $id, email: $email, adf: $adf, sourceIds: $sourceIds, _clear: $_clear) {
      ...LeadForwardDetailFragment
    }
  }

  ${leadForwardDetail}
`;

export const leadForwardDelete = gql`
  mutation LeadForwardDeleteMutation($id: ID!) {
    leadForwardDelete(id: $id)
  }
`;
