import { useContext } from 'react';

import { RichTextEditorContext } from 'contexts/RichTextEditorContext';

export const useRichTextEditor = () => {
  const context = useContext(RichTextEditorContext);
  if (context === undefined) {
    throw new Error('useRichTextEditor must be used within a RichTextEditorProvider');
  }
  return context;
};
