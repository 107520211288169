import i18next from 'i18next';
import { isNil } from 'lodash-es';
import { Trans } from 'react-i18next';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import PrimaryText from 'components/core/typography/PrimaryText';
import type RetailItemDetailsStep from 'components/sections/createModify/inventoryItems/retailItem/steps/DetailsStep';
import { RetailItemDetailsBuilderFields } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import type { MMSTFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { getDependentVariablesForMMST, YMMTFields } from 'components/sections/createModify/inventoryItems/steps/utils';
import { MessageMultilineText } from 'components/sections/inventoryItems/tradeInItems/TradeInItemMenuItems';
import type { YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import { getYMMTOptions } from 'components/sections/shared/ItemMetaHelpers';
import { MakeModelSubModelTrimSource } from 'store/api/graph/interfaces/types';
import { getStepField, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export class RetailItemMMSTFieldsImplementer implements MMSTFields {
  step: RetailItemDetailsStep;

  constructor(step: RetailItemDetailsStep) {
    this.step = step;
  }

  isMMSTField(stepField: string): boolean {
    return YMMTFields.includes(stepField as DetailsInventoryItemBuilderFields);
  }

  showMMSTWarning(affectedFields: string[], onConfirmCallback: () => void) {
    const { toggleClosePrompt } = this.step.props;
    const formattedFields = affectedFields.join(', ');
    toggleClosePrompt({
      title: t('update_vehicle_details'),
      confirmText: t('yes_continue'),
      cancelText: t('dont_update'),
      onClose: () => {
        toggleClosePrompt(undefined);
      },
      onConfirm: async () => onConfirmCallback(),
      onComplete: () => {
        // On complete, close this prompt
        toggleClosePrompt(undefined);
        this.step.setOnClosePrompt(this.step.defaultClosePrompt);
      },
      onCancel: () => {
        // On cancel, close the warning prompt
        toggleClosePrompt(undefined);
      },
      messageJSX: (
        <>
          <MessageMultilineText>
            <Trans i18nKey="mmst_update_warning_message" i18n={i18next}>
              You have made an update that will clear the following vehicle details:
              <PrimaryText as="span">{{ formattedFields } as any}</PrimaryText> Are you sure you want to continue?
            </Trans>
          </MessageMultilineText>
        </>
      ),
    });
  }

  getMMSTWarningAffectedFields(currentStepField: StepField | undefined): string[] | null {
    const {
      tier: { isCreating },
    } = this.step.props;
    const { hasShownMMSTWarning } = this.step.state;

    const yearField = getStepField(DetailsInventoryItemBuilderFields.YEAR, this.step.fields);
    const makeField = getStepField(DetailsInventoryItemBuilderFields.MAKE_ID, this.step.fields);
    const modelField = getStepField(DetailsInventoryItemBuilderFields.MODEL_ID, this.step.fields);
    const submodelField = getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.step.fields);
    const trimField = getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.step.fields);

    let affectedYMMSTFields: string[];
    switch (currentStepField) {
      case yearField: {
        affectedYMMSTFields = [makeField, modelField, submodelField, trimField].map(field => field.label!);
        break;
      }

      case makeField: {
        affectedYMMSTFields = [modelField, submodelField, trimField].map(field => field.label!);
        break;
      }

      case modelField: {
        affectedYMMSTFields = [submodelField, trimField].map(field => field.label!);
        break;
      }

      case submodelField: {
        affectedYMMSTFields = [trimField].map(field => field.label!);
        break;
      }

      default: {
        affectedYMMSTFields = [];
        break;
      }
    }

    const manufacturerOptionsField = getStepField(
      RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES,
      this.step.fields
    );
    const techSpecsField = getStepField(RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS, this.step.fields);
    const standardEqField = getStepField(RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT, this.step.fields);

    const affectedFields = [manufacturerOptionsField, techSpecsField, standardEqField]
      .filter(field => field.selectedValue?.length)
      .map(field => field.label!);

    return !isCreating && !hasShownMMSTWarning && (affectedFields.length > 0 || affectedYMMSTFields.length > 0)
      ? [...affectedYMMSTFields, ...affectedFields]
      : null;
  }

  updateMMSTField(stepField: StepField, value: any) {
    const field = getStepField(stepField.queryVar, this.step.fields);
    void this.step.onFieldSelection(field, value, false, true, { skipMMSTWarning: true });

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.YEAR) {
      if (!field.selectedValue?.id) {
        this.clearMMSTFields([field.queryVar]);
      }

      if (getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.step.fields).selectedValue?.id !== null) {
        this.clearMMSTFields([DetailsInventoryItemBuilderFields.TRIM_ID], {
          /*
           * If there is no sub-model selected, the trim field should remain disabled.
           * Otherwise, it can be cleared, but remain enabled
           */
          overrideDisabledState: !getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.step.fields)
            .selectedValue,
        });
      }
    } else {
      /*
       * If this YMMT field is deselected then hide/disable all the proceeding YMMT fields, although if this YMMT
       * field has a defined value, then the next YMMT field should be enabled.
       */
      this.clearMMSTFields(
        YMMTFields.slice(
          YMMTFields.indexOf(field.queryVar as DetailsInventoryItemBuilderFields) + (field.selectedValue ? 1 : 0)
        )
      );
    }
  }

  checkForMMSTFieldWarning(stepField: StepField, value: any) {
    const affectedFields = this.getMMSTWarningAffectedFields(stepField);

    if (affectedFields) {
      this.showMMSTWarning(affectedFields, () => {
        this.updateMMSTField(stepField, value);
        // Only need to show this warning message once
        this.step.setState({ hasShownMMSTWarning: true });
      });
    } else {
      this.updateMMSTField(stepField, value);
    }
  }

  toggleMMSTSubPanel(stepField: StepField) {
    const {
      tier: {
        data: { type, rooftop },
      },
    } = this.step.props;

    const dependentFields = getDependentVariablesForMMST(stepField.queryVar as DetailsInventoryItemBuilderFields);

    let variables = {};

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.MAKE_ID) {
      // The MAKE_ID query expects the vehicle type (motorcycle/car), which is determined from the previous step
      variables = { type, rooftopId: [rooftop.id] };
    } else if (
      [
        DetailsInventoryItemBuilderFields.MODEL_ID,
        DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
        DetailsInventoryItemBuilderFields.TRIM_ID,
      ].includes(stepField.queryVar as DetailsInventoryItemBuilderFields)
    ) {
      // MODEL_ID, SUB_MODEL_ID, and TRIM_ID queries need the rooftop id
      variables = { rooftopId: [rooftop.id] };
    }

    for (const fieldName of dependentFields) {
      const field = getStepField(fieldName, this.step.fields);

      // The MAKE_ID, MODEL_ID, and SUB_MODEL_ID can be arrays, so add an 's' to the end of the variable name
      const variableName = [
        DetailsInventoryItemBuilderFields.MAKE_ID,
        DetailsInventoryItemBuilderFields.MODEL_ID,
        DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
      ].includes(fieldName)
        ? `${fieldName}s`
        : fieldName;

      // The YEAR field needs to be converted to a number, cannot send empty string if there is no year either
      if (variableName === DetailsInventoryItemBuilderFields.YEAR) {
        variables[variableName] = field.selectedValue
          ? Number.parseInt(field.selectedValue.id || field.selectedValue)
          : undefined;
      } else if (variableName === DetailsInventoryItemBuilderFields.ROOFTOP_ID) {
        variables[variableName] = [field.selectedValue?.id];
      } else {
        variables[variableName] =
          (field.selectedValue && (field.selectedValue.id || field.selectedValue.name || field.selectedValue)) || '';
      }
    }

    this.step.asyncConfigurations![stepField.queryVar] = {
      request: () => getYMMTOptions(variables, stepField.queryVar as YMMTTarget),
      disableKeywordRefetch: true,
    };
  }

  clearMMSTFields(fields: string[], options?: { overrideDisabledState?: boolean }) {
    const alwaysActiveFields = new Set([
      DetailsInventoryItemBuilderFields.ROOFTOP_ID,
      DetailsInventoryItemBuilderFields.YEAR,
    ]);
    const trimFieldValue = getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.step.fields).selectedValue;
    const trimHasChromeSource = trimFieldValue?.source === MakeModelSubModelTrimSource.CHROME;

    for (const [indx, queryVar] of fields.entries()) {
      const field: StepField = getStepField(queryVar, this.step.fields);
      field.selectedValue = undefined;
      setDisplayTypes(
        [
          {
            type: StepFieldDisplayType.DISABLED,
            active: isNil(options?.overrideDisabledState)
              ? !alwaysActiveFields.has(queryVar as DetailsInventoryItemBuilderFields) && indx > 0
              : !!options?.overrideDisabledState,
          },
        ],
        field
      );
    }
    const manufacturerField = getStepField(
      RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES,
      this.step.fields
    );
    const standardEqField = getStepField(RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT, this.step.fields);
    const techSpecsField = getStepField(RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS, this.step.fields);

    /*
     * If the trim field has been cleared, then the technical specs and std eq fields need to be
     * cleared and hidden (since there is now no options to select from). If trim field has not been cleared, and
     * the trim value comes from a chrome source, then we can refresh the trim specifications.
     */
    if (fields.includes(DetailsInventoryItemBuilderFields.TRIM_ID) || !trimHasChromeSource) {
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, standardEqField);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, techSpecsField);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, manufacturerField);

      standardEqField.selectedValue = null;
      standardEqField.options = [];

      techSpecsField.selectedValue = null;
      techSpecsField.options = [];

      manufacturerField.selectedValue = null;
    } else if (trimHasChromeSource) {
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: false }, standardEqField);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: false }, techSpecsField);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: false }, manufacturerField);

      manufacturerField.selectedValue = null;

      void this.step.refreshTrimSpecifications(trimFieldValue.id);
    }

    this.step.forceUpdate();
  }
}
