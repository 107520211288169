import { gql } from '@apollo/client';

import { initialItemSorting } from './initialItemSorting';
import { MultilingualString } from './multilingualString';
import { retailItemFilters } from './retailItemFilters';

export const websiteRoute = gql`
  fragment WebsiteRouteFragment on WebsiteRoute {
    id
    inventoryItemSegment {
      filters {
        ...RetailItemFiltersFragment
      }
      id
    }
    path {
      ...MultilingualStringFragment
    }
    initialItemSorting {
      ...InitialItemSortingFragment
    }
  }
  ${MultilingualString}
  ${retailItemFilters}
  ${initialItemSorting}
`;
