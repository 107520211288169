import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { StatusColors } from 'enums/statusColors';
import type { WebsiteResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

import Badge, { Badges } from '../shared/Badge';

const { t } = translate;

interface Props extends ListItemProps, WebsiteResponseType {}

const WebsiteListItem = ({ suffixIcon, listItemType, ...website }: Props) => (
  <ListItem suffixIcon={suffixIcon} listItemType={listItemType} isHighlighted={false}>
    <ListItemDetails>
      <TextRow>
        <PrimaryText>{website.name.value}</PrimaryText>
      </TextRow>
      {website.url && (
        <TextRow>
          <SecondaryText title={website.url}>{website.url}</SecondaryText>
        </TextRow>
      )}
      <TextRow>
        <Badges>
          {website.statusName && (
            <Badge title={`${t('status')}: ${website.statusName}`} color={StatusColors[website.status]}>
              {website.statusName}
            </Badge>
          )}
        </Badges>
      </TextRow>
    </ListItemDetails>
    <ListItemSeparator />
  </ListItem>
);

export default memo(WebsiteListItem);
