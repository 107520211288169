import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { SubmitAppraisalBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

class SubmitAppraisalStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { activeStep, data },
    } = props;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [SubmitAppraisalBuilderFields.AMOUNT]: {
        selectedValue: data?.amount?.amount,
      },
      [SubmitAppraisalBuilderFields.COMMENTS]: {
        selectedValue: data?.comments,
      },
    });
  }

  async save() {
    return super.save(undefined, this.props.tier.isCreating ? { id: this.props.tier.seededData?.id } : undefined);
  }
}

export default SubmitAppraisalStep;
