import { Fragment, memo, useCallback } from 'react';

import { sortBy } from 'lodash-es';
import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { useWebsiteDetailsMetaQueryFromWebsite } from 'components/sections/shared/ItemMetaHelpers';
import SectionHeader from 'components/ui/details/SectionHeader';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { WebsiteLeadRoute, WebsiteSettings } from 'enums/columns/website';
import { RoutePath } from 'enums/routePath';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import useEntityView from 'hooks/useEntityView';
import type { WebsiteDetailFragment, WebsiteLeadRouteFragment } from 'store/api/graph/interfaces/types';
import { EntityType, LeadRouteType } from 'store/api/graph/interfaces/types';
import { getField } from 'utils/formatting/inventoryItemFormatUtils';
import { PLACEHOLDER } from 'utils/formatUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback, fullGroupOrAboveScopes } from 'utils/permissionUtils';
import { generateUrlPathFromListFilters } from 'utils/urlUtils';

import ItemGridCellContentExternalLink from '../shared/ItemGridCellExternalLink';
import { ItemGridRowDisplayType } from '../shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from '../shared/ItemGridRowSection';
import { ItemGridRowSection } from '../shared/ItemGridRowSection';

const { t } = translate;

export const LeadRoutingDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  ${PrimaryText} {
    margin-right: 4px;
  }
`;

const { ROOFTOP_ID } = WebsiteLeadRoute;

const { BOLD } = ItemGridRowDisplayType;

const gridConfig: ItemGridRowProps[] = [{ fields: [ROOFTOP_ID], displayType: [BOLD], canEdit: true }];

const WebsiteLeadRoutingTab = memo<ItemTabProps<WebsiteDetailFragment>>(
  ({ item: website, metadata: websiteMetadata }) => {
    const { builderConfig } = useBuilderConfig();
    const { generatePath } = useEntityView(RoutePath.LEADS);
    const { data: metadata } = useWebsiteDetailsMetaQueryFromWebsite(website, websiteMetadata);
    const {
      user: { scope },
      hasPermissions,
    } = useUser();

    const onEditClicked = useEditField({
      tierData: {
        itemId: website?.id,
        seededData: {
          rooftops: metadata.mutation.rooftopId,
          vlpVdpLeadRoute: website?.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.VLP_VDP),
          otherLeadRoute: website?.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.OTHER),
        },
        title: t('lead_routing'),
        type: BuilderType.WEBSITE_LEAD_ROUTING_MODIFY,
        entityType: EntityType.WEBSITE,
      },
    });

    const authorizedOnEdit = authorizedCallback({
      cb: onEditClicked,
      isAuth:
        hasPermissions(builderConfig[BuilderType.WEBSITE_LEAD_ROUTING_MODIFY].requiredPermissions) &&
        fullGroupOrAboveScopes.includes(scope),
    }) as ItemTabProps['onEdit'];

    const customGridCellRenderMethod = useCallback(
      (_, leadRoute: WebsiteLeadRouteFragment, fieldId, _metadata) => {
        if (fieldId === ROOFTOP_ID) {
          const metadataDerivedRooftopName = getField(leadRoute, fieldId, metadata.mutation, undefined, null);
          const leadsUrl = generateUrlPathFromListFilters(generatePath(), { rooftopId: leadRoute.rooftopId });
          let leadRoutingDescription = PLACEHOLDER;
          if (leadRoute.type === LeadRouteType.VLP_VDP) {
            leadRoutingDescription = metadataDerivedRooftopName
              ? t('vlp_vdp_lead_routing_to_message')
              : t('vlp_vdp_lead_routing_no_rooftop_message');
          } else {
            leadRoutingDescription = metadataDerivedRooftopName && t('other_lead_routing_to_message');
          }
          return (
            <LeadRoutingDescriptionWrapper>
              <PrimaryText>{leadRoutingDescription}</PrimaryText>
              {metadataDerivedRooftopName && (
                <ItemGridCellContentExternalLink to={leadsUrl}>
                  {metadataDerivedRooftopName}
                </ItemGridCellContentExternalLink>
              )}
            </LeadRoutingDescriptionWrapper>
          );
        }
      },
      [generatePath, metadata.mutation]
    );

    if (!website) {
      return null;
    }

    return (
      <>
        {sortBy(website.leadRoutes, ['type'])
          .reverse()
          .map(leadRoute => (
            <Fragment key={`key-row-${leadRoute.typeName}`}>
              <SectionHeader text={`${leadRoute.typeName} ${t('lead_other')}`} />
              {gridConfig.map(config => (
                <ItemGridRowSection
                  key={`${formatItemGridRowSectionKey(config.fields)}-${leadRoute.typeName}`}
                  item={leadRoute}
                  onEdit={authorizedOnEdit}
                  settings={WebsiteSettings}
                  metadata={metadata}
                  config={config}
                  gridCellRenderMethod={customGridCellRenderMethod}
                />
              ))}
            </Fragment>
          ))}
      </>
    );
  }
);

export default WebsiteLeadRoutingTab;
