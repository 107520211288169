import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const ymmt = gql`
  fragment YMMTFragment on InventoryItem {
    year
    makeName: make {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    modelName: model {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    trimName: trim {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;
