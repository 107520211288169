import { gql } from '@apollo/client';

export const USER_LOGIN_MUTATION = gql`
  mutation UserLoginMutation($username: String!, $password: String!) {
    userLogin(username: $username, password: $password)
  }
`;

export const USER_LOGOUT_MUTATION = gql`
  mutation UserLogoutMutation($allSessions: Boolean!) {
    userLogout(allSessions: $allSessions)
  }
`;

export const SSO_LOGIN_MUTATION = gql`
  mutation SsoLoginMutation($authorizationCode: String!) {
    ssoLogin(authorizationCode: $authorizationCode)
  }
`;
