import { css } from 'styled-components/macro';

import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { DEFAULT_STEP_FIELD_HEIGHT } from 'components/core/createModify/stepFields/StepFieldLayout';
import { SubmitAppraisalBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';
import { TradeInAppraisalInternalModifyParameter } from 'store/api/graph/interfaces/types';

const SubmitAppraisalFields: StepFields = {
  [SubmitAppraisalBuilderFields.AMOUNT]: {
    label: 'your_appraisal',
    groupType: StepFieldType.CURRENCY,
    clear: { field: TradeInAppraisalInternalModifyParameter._amount },
  },
  // TODO: [#1112] Refactor to use common styling patterns in builder
  [SubmitAppraisalBuilderFields.COMMENTS]: {
    label: 'comment',
    groupType: StepFieldType.TEXT_AREA,
    containerStyles: css`
      height: calc(100% - ${DEFAULT_STEP_FIELD_HEIGHT});
    `,
    clear: { field: TradeInAppraisalInternalModifyParameter._comments },
  },
};

export default SubmitAppraisalFields;
