import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const makeCreate = gql`
  mutation MakeCreateMutation($rooftopId: ID, $name: MultilingualStringInput, $type: InventoryItemType) {
    data: makeCreate(rooftopId: $rooftopId, name: $name, type: $type) {
      id
      rooftopId
      name {
        ...MultilingualStringFragment
      }
      type
    }
  }

  ${MultilingualString}
`;

export const modelCreate = gql`
  mutation ModelCreateMutation($rooftopId: ID, $name: MultilingualStringInput, $makeId: ID) {
    data: modelCreate(rooftopId: $rooftopId, name: $name, makeId: $makeId) {
      id
      rooftopId
      name {
        ...MultilingualStringFragment
      }
    }
  }

  ${MultilingualString}
`;

export const subModelCreate = gql`
  mutation SubModelCreateMutation($rooftopId: ID, $name: MultilingualStringInput, $modelId: ID) {
    data: subModelCreate(rooftopId: $rooftopId, name: $name, modelId: $modelId) {
      id
      rooftopId
      name {
        ...MultilingualStringFragment
      }
    }
  }

  ${MultilingualString}
`;

export const trimCreate = gql`
  mutation TrimCreateMutation($rooftopId: ID, $name: MultilingualStringInput, $subModelId: ID, $year: Int) {
    data: trimCreate(rooftopId: $rooftopId, name: $name, subModelId: $subModelId, year: $year) {
      id
      rooftopId
      name {
        ...MultilingualStringFragment
      }
    }
  }

  ${MultilingualString}
`;
