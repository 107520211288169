import { useCallback } from 'react';

import styled, { css } from 'styled-components/macro';

import CircleMinusIcon from 'components/ui/icons/CircleMinusIcon';
import { BLUE_500, NEUTRAL_700 } from 'styles/tokens';
type RemovalIconProps = {
  /** A callback that runs when the removal icon is clicked */
  onRemove?: () => void;
  /** Whether to show or hide the removal icon */
  shouldHide?: boolean;
  /** An id for testing */
  testId?: string;
};

const RemovalContainer = styled.div<{ shouldHide?: boolean }>`
  position: relative;
  cursor: pointer;

  &:hover {
    svg {
      color: ${BLUE_500};
    }
  }

  ${({ shouldHide }) =>
    shouldHide &&
    css`
      display: none;
    `}
`;

const RemovalIcon = ({ onRemove, shouldHide, testId }: RemovalIconProps) => (
  <RemovalContainer
    shouldHide={shouldHide}
    onClick={useCallback(
      e => {
        e.stopPropagation();
        onRemove?.();
      },
      [onRemove]
    )}
    data-testid={testId}
  >
    <CircleMinusIcon width={16} height={16} color={NEUTRAL_700} />
  </RemovalContainer>
);

export default RemovalIcon;
