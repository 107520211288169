import type { ReactNode } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import EditIcon from 'components/ui/icons/EditIcon';
import { GridSectionTitle } from 'components/ui/layouts/Grid';
import { Clickable } from 'components/ui/shared/Button';
import { ElementTestId, gridCellItemEditIconTestId } from 'enums/testing';
import { withTooltip } from 'hooks/withTooltip';
import { NEUTRAL_800 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import LockIcon from '../icons/LockIcon';
import { PrimaryArrowPosition, SecondaryArrowPosition } from '../shared/Tooltip';
import TooltipButton from '../shared/TooltipButton';

interface Props {
  /** The lokalize key used for title of the section. */
  label: string;
  /** The onEdit callback when this section needs to be edited */
  onEdit?: () => void;
  /** Icon for onEdit button */
  icon?: ReactNode;
  /** The text for the tooltip. */
  tooltip?: string;
  /** The test ID used to target element cypress */
  testId?: string;
  /** Whether this field is locked, and should display a locked field icon */
  isLocked?: boolean;
}

const { t } = translate;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LockIconContainer = styled.div`
  display: inline-flex;
  color: ${NEUTRAL_800};
  padding-right: 4px;
`;

const LockWithTooltip = (testId?: string) =>
  withTooltip(() => (
    <LockIconContainer data-testid={testId}>
      <LockIcon />
    </LockIconContainer>
  ));

const LockTooltipButtonContainerStyle = css`
  display: inline-flex;
  padding-top: 3px;
  top: -3px;
  position: relative;
`;

export const LockingFieldIcon = ({ styles, testId }: { styles?: FlattenSimpleInterpolation; testId?: string }) => (
  <TooltipButton
    styles={styles}
    icon={LockWithTooltip(testId)}
    tooltip={{
      arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
      margin: { x: 0, y: 4 },
      styleVariant: undefined,
    }}
  >
    {t('locked')}
  </TooltipButton>
);

// Helper method that generates a dynamic title with the edit icon for opening up modify
const ActionableTitle = ({ label, onEdit, icon = <EditIcon />, tooltip, testId, isLocked }: Props) => (
  <GridSectionTitle hasIcon>
    <TertiaryLabel>
      <LabelContainer>
        {isLocked && (
          <LockingFieldIcon
            styles={LockTooltipButtonContainerStyle}
            testId={`${ElementTestId.DETAILS_VIEW_LOCK_ICON}-${testId}`}
          />
        )}
        {label}
      </LabelContainer>
    </TertiaryLabel>
    {tooltip && (
      <TooltipButton
        tooltip={{
          arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
          margin: { x: 0, y: 8 },
        }}
      >
        {tooltip}
      </TooltipButton>
    )}
    {!!onEdit && (
      <Clickable data-testid={testId ? gridCellItemEditIconTestId(testId) : undefined} onClick={() => onEdit()}>
        {icon}
      </Clickable>
    )}
  </GridSectionTitle>
);

export default ActionableTitle;
