import { flattenDeep, merge } from 'lodash-es';

import type { MetadataResponse } from 'components/core/createModify/interfaces/queryMethods';
import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { retailItemMetaQuery, rooftopImportIntegrationsCountQuery } from 'components/sections/shared/ItemMetaQueries';
import { retailItemsDetailsQuery } from 'containers/inventoryItems/RetailItemsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { LOCKING_FIELDS_REQUIRED_PERMISSIONS } from 'hooks/useIsFieldLockingVisible';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  RetailItemMetaQuery,
  RetailItemMetaQueryVariables,
  RooftopIntegrationQuery,
  RooftopIntegrationQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const RetailItemQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, userContext, seededData) => {
    const { hasPermissions } = userContext || {};

    const retailItemMetaObservable = client.watchQuery<RetailItemMetaQuery, RetailItemMetaQueryVariables>({
      query: retailItemMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
    const rooftopImportIntegrationsCountQueryObservable = client.watchQuery<
      RooftopIntegrationQuery,
      RooftopIntegrationQueryVariables
    >({
      query: rooftopImportIntegrationsCountQuery,
      variables: { rooftopId: seededData?.rooftopId },
    });

    const userCanViewLockedFields = hasPermissions?.(LOCKING_FIELDS_REQUIRED_PERMISSIONS);

    return new Promise((resolve, reject) => {
      Promise.all(
        [
          new Promise<MetadataResponse>((resolve, reject) => {
            const subscription = retailItemMetaObservable.subscribe({
              next: res => {
                const metadata = res.data.metadata;
                resolve({ subscription, metadata });
                onMetaUpdate(metadata);
              },
              error: (error: Error) => {
                logApiError(error);
                reject(error);
              },
            });
          }),
          !!seededData?.rooftopId && userCanViewLockedFields
            ? new Promise<MetadataResponse>((resolve, reject) => {
                const subscription = rooftopImportIntegrationsCountQueryObservable.subscribe({
                  next: res => {
                    const metadata = res.data;
                    resolve({
                      subscription,
                      metadata: {
                        isFieldLockingVisible: Boolean(res.data.importIntegrations?.pageInfo?.totalEdges),
                      },
                    });
                    onMetaUpdate(metadata);
                  },
                  error: (error: Error) => {
                    logApiError(error);
                    reject(error);
                  },
                });
              })
            : null,
        ].filter(Boolean)
      )
        .then(values => {
          const allMetadata = merge({}, values[0].metadata, values[1]?.metadata);
          resolve({ subscription: flattenDeep(values.map(({ subscription }) => subscription)), metadata: allMetadata });
          onMetaUpdate(allMetadata);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  },

  getData: async (id: string) => {
    const metaResponse = await client.query({
      query: retailItemsDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
    return metaResponse.data.item;
  },

  refetchQueries: ['RetailItemConnectionQuery', 'RetailItemCondensedListQuery'],
};

export default RetailItemQueryMethods;
