import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const rooftopName = gql`
  fragment RooftopNameFragment on Rooftop {
    id
    name {
      ...MultilingualStringFragment
    }
  }
  ${MultilingualString}
`;
