import { memo, type SVGProps } from 'react';

const EyeSlashedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m18.998 21.942-3.362-3.319c-.587.192-1.185.333-1.793.425a12.34 12.34 0 0 1-1.843.137c-2.257 0-4.323-.604-6.197-1.812A12.75 12.75 0 0 1 1.4 12.575a2.07 2.07 0 0 1-.208-.522 2.317 2.317 0 0 1-.066-.553c0-.19.022-.375.066-.553a2.07 2.07 0 0 1 .208-.522c.355-.635.737-1.252 1.146-1.853.408-.6.88-1.142 1.414-1.626L1.944 4.912a.98.98 0 0 1-.293-.72c0-.285.103-.532.311-.74a.994.994 0 0 1 .734-.293c.293 0 .537.098.732.293l17.03 17.03c.195.195.296.436.303.72a.977.977 0 0 1-.303.74.99.99 0 0 1-.73.293.99.99 0 0 1-.73-.293ZM12 16c.163 0 .32-.007.468-.022a2.32 2.32 0 0 0 .45-.091L7.6 10.582c-.042.151-.07.301-.082.45a5.529 5.529 0 0 0-.019.468c0 1.25.438 2.313 1.313 3.188C9.687 15.562 10.75 16 12 16Zm0-12.185c2.257 0 4.327.608 6.21 1.824a12.8 12.8 0 0 1 4.415 4.817c.092.157.16.323.206.5.046.176.069.357.069.544 0 .187-.02.37-.056.547a1.68 1.68 0 0 1-.194.503 14.203 14.203 0 0 1-.99 1.646c-.364.52-.77 1.01-1.217 1.47-.257.268-.565.396-.924.381a1.364 1.364 0 0 1-.935-.42l-2.006-2.006a.911.911 0 0 1-.255-.466 1.164 1.164 0 0 1 .019-.547c.055-.19.095-.37.12-.545a3.92 3.92 0 0 0 .038-.563c0-1.25-.438-2.313-1.313-3.188C14.313 7.439 13.25 7 12 7c-.201 0-.39.014-.563.041a4.397 4.397 0 0 0-.544.123c-.187.046-.373.05-.558.013a.915.915 0 0 1-.475-.255l-.742-.741c-.356-.357-.472-.771-.348-1.244.124-.472.423-.769.895-.89.385-.07.771-.127 1.158-.17.387-.041.78-.062 1.177-.062Zm1.867 5.87c.148.174.272.363.373.57.101.206.166.423.196.651.016.11-.026.186-.126.23-.1.044-.193.024-.277-.06L12.4 9.444c-.084-.084-.1-.176-.05-.277.05-.1.132-.149.245-.145.253.03.485.101.696.215.21.114.403.263.575.449Z"
    />
  </svg>
);
export default memo(EyeSlashedIcon);
