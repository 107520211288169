import styled from 'styled-components/macro';

import Appointments from 'components/ui/icons/AppointmentsIcon';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import { formatInputValue } from 'utils/formUtils';

import ClickableInput from './ClickableInput';
import InputTextValue from './InputTextValue';

const { DATE_YEAR_FORMAT } = DateTimeFormat;

const AppointmentsIcon = styled(Appointments)<{ active?: string }>`
  color: ${({ active }) => (active === 'true' ? BLUE_500 : BODY_TEXT)};
  position: absolute;
  right: 12px;
`;

interface Props {
  isActive?: boolean;
  options?: any;
  selectedValue?: any;
}

const DateInput = ({ options, selectedValue, isActive, ...props }: Props) => {
  const formattedValue = formatInputValue(selectedValue, options);
  const formattedDateTimeString =
    !!selectedValue && getFormattedDateTimeString(getDateTime(selectedValue), DATE_YEAR_FORMAT);

  return (
    <ClickableInput {...props}>
      <InputTextValue>{formattedValue || formattedDateTimeString}</InputTextValue>
      <AppointmentsIcon active={isActive?.toString()} />
    </ClickableInput>
  );
};

export default DateInput;
