import type { BuilderConfigContextInterface } from 'contexts/BuilderConfigContext';
import type { UserContextInterface } from 'contexts/UserContext';
import { BuilderType } from 'enums/builderType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import type { Rooftop } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { doUserRooftopsHaveFeatureEnabled } from './featureBundleRooftopUtils';

export const featureBundleEditingRequiredPermissions = {
  resource: ResourceType.FEATURE_BUNDLES,
  level: AccessLevel.FULL,
};

interface isFeatureBuilderEnabledForUserParams {
  /** The builder config context */
  builderConfigContext: BuilderConfigContextInterface;
  /** The current user context */
  userContext: UserContextInterface;
  /** The builder the user is trying to access */
  builderType: BuilderType;
  /** The feature being checked */
  feature: FeatureBundleSet;
  /**
   * Whether the entire feature bundle is being feature flagged or not (if turned off,
   * the all the features are enabled)
   */
  featureFlagOn?: boolean;
}
/**
 * Check to see if a builder for a particular feature is accessible for a certain user.
 *
 * @example:
 *  isFeatureBuilderEnabledForUser({
 *    userContext: useUser(),
 *    builderType: BuilderType.TASK_CREATE,
 *    feature: FeatureBundleSet.TASK,
 *    featureFlagOn: true
 *  })
 */
export const isFeatureBuilderEnabledForUser = ({
  builderConfigContext: { builderConfig },
  userContext,
  builderType,
  feature,
  featureFlagOn,
}: isFeatureBuilderEnabledForUserParams): boolean => {
  const { hasPermissions, user } = userContext;
  return (
    doUserRooftopsHaveFeatureEnabled({ userRooftops: user.rooftops, feature, featureFlagOn }) &&
    hasPermissions(builderConfig[builderType].requiredPermissions)
  );
};

/**
 * Check to see if a route path is enabled for a particular user
 *
 * @example:
 *  isFeatureRouteEnabledForUserParams({
 *    userRooftops: user.rooftops,
 *    path: '/appointment/123',
 *    featureFlagOn: true
 *  })
 */
interface isFeatureRouteEnabledForUserParams {
  // The rooftops the user has access to
  userRooftops?: Pick<Rooftop, 'bundle'>[] | null;
  // The route being checked
  path: string;
  // Whether the entire feature bundle is being feature flagged or not (if turned off, then all features are enabled)
  featureFlagOn?: boolean;
}
export const isFeatureRouteEnabledForUser = ({
  userRooftops,
  path,
  featureFlagOn,
}: isFeatureRouteEnabledForUserParams): boolean => {
  const PATH_MAPPING = {
    [RoutePath.APPOINTMENTS]: FeatureBundleSet.APPOINTMENT,
    [RoutePath.TASKS]: FeatureBundleSet.TASK,
    [RoutePath.CONVERSATIONS]: FeatureBundleSet.CONVERSATION,
    [RoutePath.LEADS]: FeatureBundleSet.LEAD,
    [RoutePath.TRADE_IN_ITEMS]: FeatureBundleSet.TRADE_IN,
    [RoutePath.RETAIL_ITEMS]: FeatureBundleSet.RETAIL,
  };

  const mappedFeature = Object.keys(PATH_MAPPING).find(key => path.startsWith(key));

  // If no RoutePath -> FeatureBundleSet mapping was found, then just assume the route is enabled
  if (!mappedFeature) {
    return true;
  }

  return doUserRooftopsHaveFeatureEnabled({ userRooftops, feature: PATH_MAPPING[mappedFeature], featureFlagOn });
};

interface canUserEditFeatureBundlesParams {
  /** The builder config context */
  builderConfigContext: BuilderConfigContextInterface;
  /** The current user context */
  userContext: UserContextInterface;
  /**
   * Whether the entire feature bundle is being feature flagged or not
   * (if turned off, then all features are enabled)
   */
  featureFlagOn?: boolean;
}
/**
 * Check to see if a user as the ability to edit a rooftops feature bundle
 *
 * @example canUserEditFeatureBundles({ userContext: useUser(), featureFlagOn: true })
 */
export const canUserEditFeatureBundles = ({
  builderConfigContext: { builderConfig },
  userContext,
  featureFlagOn,
}: canUserEditFeatureBundlesParams): boolean => {
  const { hasPermissions } = userContext;
  return (
    hasPermissions([{ resource: ResourceType.FEATURE_BUNDLES, level: AccessLevel.FULL }]) &&
    hasPermissions(builderConfig[BuilderType.ROOFTOPS_MODIFY].requiredPermissions) &&
    !!featureFlagOn
  );
};
