import { useCallback } from 'react';

import type Tier from 'components/core/createModify/interfaces/tier';
import LoggingService from 'components/core/logging/LoggingService';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { translate } from 'utils/intlUtils';

interface EditFieldConfig {
  /**
   * The Tier configuration for the builder. All Tier properties are optional, however if no
   * `entityType` (EntityType) or `type` (BuilderType) is provided, the builder will not open.
   */
  tierData: Partial<Tier>;
}

/**
 * Opens up a builder and auto selects the provided `activeField`. Accepts a single config object (EditFieldConfig).
 */
const useEditField = ({ tierData }: EditFieldConfig) => {
  const { builderConfig } = useBuilderConfig();
  const { findActiveStep, toggleTier } = useCreateModify();

  return useCallback(
    // TODO: support non-connection query data
    (activeField: string, customTitle?: string, itemData?: any) => {
      if (!tierData?.type || !tierData?.entityType) {
        LoggingService.debug({ message: `No editor or entitytype found for editing: ${activeField}` });
        return;
      }
      const title = translate.t('modify_x', [customTitle || tierData.title || '']);
      const foundActiveStep = findActiveStep(activeField, builderConfig[tierData.type]);
      const shouldExpandBuilderOnEdit = !!foundActiveStep?.fields?.[activeField]?.shouldExpandBuilderOnEdit;

      const tier = {
        ...tierData,
        tierId: CreateModifyTiers.TIER_0,
        isCreating: false,
        data: { ...itemData },
        activeField,
        title,
        isExpanded: shouldExpandBuilderOnEdit,
        ...(!!foundActiveStep && { activeStep: foundActiveStep }),
      };

      toggleTier(tier.tierId, tier as Tier);
    },
    [tierData, findActiveStep, builderConfig, toggleTier]
  );
};

export default useEditField;
