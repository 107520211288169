import { useMemo } from 'react';

import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import CirclePlusIcon from 'components/ui/icons/CirclePlusIcon';
import EditIcon from 'components/ui/icons/EditIcon';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import LeadListItem from 'components/ui/lists/LeadListItem';
import ListItemClickable from 'components/ui/lists/ListItemClickable';
import { ListItemType } from 'enums/listItemType';
import { linkedSectionItemTestId } from 'enums/testing';
import type { Lead } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';
import { NEUTRAL_700 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

import type { SectionProps } from './LinkedTab';

const { t } = translate;

interface Props extends Omit<SectionProps, 'sectionTitle' | 'isList'> {
  purchasedBy: Lead;
}

const PurchasedBySection = ({ purchasedBy, isInteractive, onAdd, isNested }: Props) => {
  const renderedPurchaser = useMemo(() => {
    if (purchasedBy === undefined) {
      return null;
    }
    return isInteractive ? (
      <ListItemClickable
        key={purchasedBy.id}
        item={purchasedBy}
        entity={EntityType.LEAD}
        isNested={isNested}
        renderElement={LeadListItem}
        renderSettings={{ listItemType: ListItemType.SECTION_LIST }}
      />
    ) : (
      <LeadListItem
        key={purchasedBy.id}
        {...(purchasedBy as unknown as LeadResponseType)}
        listItemType={ListItemType.SECTION_LIST}
      />
    );
  }, [purchasedBy, isInteractive, isNested]);

  return (
    <LinkedSection>
      <LinkedSectionHeader
        title={t('purchased_by')}
        onAdd={onAdd}
        suffixIcon={purchasedBy ? <EditIcon /> : <CirclePlusIcon color={NEUTRAL_700} />}
        hasItems={purchasedBy !== undefined}
        testId={linkedSectionItemTestId(EntityType.LEAD_SOURCE)}
      />
      <ListItems>{renderedPurchaser}</ListItems>
    </LinkedSection>
  );
};

export default PurchasedBySection;
