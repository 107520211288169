import { gql } from '@apollo/client';

import { selectStringOption } from './selectOption';

export const websiteSortOption = gql`
  fragment WebsiteSortOptionFragment on WebsiteSortOption {
    columnId
    customOrderOptions {
      ...SelectStringOptionFragment
    }
    direction
    directionName
    name
  }
  ${selectStringOption}
`;
