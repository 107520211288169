import { memo } from 'react';

import { get } from 'lodash-es';
import 'styled-components/macro';

import MonetaryAmount from 'components/core/typography/MonetaryAmount';
import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { TradeInItemBadges } from 'components/sections/inventoryItems/tradeInItems/TradeInItemBadges';
import { ItemColorPreview } from 'components/sections/shared/ItemColorPreview';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemImageContainer, ListItemLabel } from 'components/ui/layouts/ListItem';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { ImageType } from 'enums/imageType';
import type { TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';
import { formatInventoryItemDetails, formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';
import { formatAmount, formatAmountRounded } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import type { ListItemProps } from './interfaces/ListItemProps';

const getAttribute = (tradeInItem: TradeInItemResponseType, vehicleAttribute = '', motorcycleAttribute = '') =>
  get(tradeInItem, vehicleAttribute, get(tradeInItem, motorcycleAttribute));

const { VEHICLE_EXTERIOR_COLOR, VEHICLE_MILEAGE, MOTORCYCLE_COLOR, MOTORCYCLE_MILEAGE } = InventoryItem;

interface TradeInListItemProps extends ListItemProps, TradeInItemResponseType {
  isHighlighted?: boolean;
}

export const TradeInListItem = memo(
  ({ suffixIcon, listItemType, metadata, isHighlighted, ...tradeInItem }: TradeInListItemProps) => {
    const exteriorColor = getAttribute(tradeInItem, VEHICLE_EXTERIOR_COLOR, MOTORCYCLE_COLOR);
    const mileage = formatAmount(getAttribute(tradeInItem, VEHICLE_MILEAGE, MOTORCYCLE_MILEAGE));
    const averageAppraisalAmount = formatAmountRounded(get(tradeInItem, 'appraisals.averageAppraisalAmount'));
    const ymmt = formatYMMT(tradeInItem);
    const details = formatInventoryItemDetails(tradeInItem);
    const vin = tradeInItem.vin || '';
    const imageSrc = tradeInItem.primaryPhoto?.listPhoto;

    return (
      <ListItem
        isHighlighted={isHighlighted}
        suffixIcon={suffixIcon}
        listItemType={listItemType}
        isArchived={isItemArchived(tradeInItem)}
      >
        <section>
          <ListItemImageContainer>
            <Image src={imageSrc} type={ImageType.INVENTORY_ITEM} />
            <ListItemLabel hasImage={!!imageSrc}>{translate.t('trade')}</ListItemLabel>
          </ListItemImageContainer>
        </section>
        <ListItemDetails css="text-align: left;">
          <PrimaryText title={ymmt}>{ymmt}</PrimaryText>
          {(averageAppraisalAmount || mileage) && (
            <TextRow>
              <MonetaryAmount as="span">{averageAppraisalAmount}</MonetaryAmount>
              {averageAppraisalAmount && mileage && <SecondaryText>&bull;</SecondaryText>}
              <SecondaryText>{mileage}</SecondaryText>
            </TextRow>
          )}
          {details && (
            <TextRow>
              {exteriorColor && (
                <ItemColorPreview color={exteriorColor} metadata={metadata} css="transform: translateY(-1px)" />
              )}
              <SecondaryText css="text-transform: capitalize;" title={details}>
                {details}
              </SecondaryText>
            </TextRow>
          )}
          <SecondaryText title={vin}>{vin}</SecondaryText>
          <TradeInItemBadges tradeInItem={tradeInItem} css="margin-top: 6px;" />
        </ListItemDetails>
      </ListItem>
    );
  }
);
