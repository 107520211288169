import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum Website {
  WEBSITE_ID = 'id',
  NAME = 'name',
  STATUS = 'status',
  URL = 'url',
  GROUP_NAME = 'groupName.name.value',
  GROUP_ID = 'groupName.id',
  ROUTES = 'routes',
  LEAD_ROUTES = 'leadRoutes',
  LOCATION = 'location',
  PHONE_NUMBER = 'phoneNumber',
  CREATED_BY_NAME = 'createdByName',
  CREATED = 'created',
  UPDATED = 'updated',
  PRIMARY_ROOFTOP_NAME = 'primaryRooftop.name.value',
  PRIMARY_ROOFTOP_INTEGRATIONS = 'primaryRooftop.integrations',
  GOOGLE_ADS_ID = 'googleAdsId',
  GA4_PROPERTY_ID = 'ga4PropertyId',
  LOCALES = 'locales',
  PRIMARY_LOCALE = 'primaryLocale',
  DEFAULT_INITIAL_ITEM_SORTING = 'defaultInitialItemSorting',
}

export enum WebsiteRoute {
  PATH = 'path',
  FILTERS = 'filters',
}

export enum WebsiteLeadRoute {
  WEBSITE_ID = 'websiteId',
  TYPE = 'type',
  ROOFTOP_ID = 'rooftopId',
}

const WebsiteConfig: TableSettingsType = {
  [Website.WEBSITE_ID]: { label: 'website_id', type: FieldDataType.DEFAULT },
  [Website.NAME]: { label: 'name', type: FieldDataType.DEFAULT },
  [Website.STATUS]: { label: 'status', type: FieldDataType.STATUS },
  [Website.URL]: { label: 'url_one', type: FieldDataType.DEFAULT },
  [Website.LOCATION]: { label: 'address', type: FieldDataType.LOCATION },
  [Website.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [Website.GROUP_NAME]: { label: 'group_name', type: FieldDataType.GROUP_NAME },
  [Website.GROUP_ID]: { label: 'group_id', type: FieldDataType.DEFAULT },
  [Website.PRIMARY_ROOFTOP_NAME]: { label: 'primary_rooftop_name', type: FieldDataType.DEFAULT },
  [Website.CREATED_BY_NAME]: { label: 'created_by', type: FieldDataType.USER_NAME },
  [Website.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Website.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  [Website.DEFAULT_INITIAL_ITEM_SORTING]: { label: 'default_sort_rule', type: FieldDataType.CUSTOM },
  [Website.GOOGLE_ADS_ID]: { label: 'google_ads_id', type: FieldDataType.DEFAULT },
  [Website.GA4_PROPERTY_ID]: { label: 'ga4_property_id', type: FieldDataType.DEFAULT },
  [Website.LOCALES]: { label: 'supported_locales', type: FieldDataType.BADGE_LIST },
  [Website.PRIMARY_LOCALE]: { label: 'primary_locale', type: FieldDataType.NAME },
  [WebsiteRoute.PATH]: { label: 'path', type: FieldDataType.DEFAULT },
  [WebsiteRoute.FILTERS]: { label: 'filter_other', type: FieldDataType.CUSTOM },
  [WebsiteLeadRoute.WEBSITE_ID]: { label: 'website_id', type: FieldDataType.DEFAULT },
  [WebsiteLeadRoute.TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [WebsiteLeadRoute.ROOFTOP_ID]: { label: 'lead_routing', type: FieldDataType.CUSTOM },
};

export const WebsiteSettings = translateFields(WebsiteConfig);
