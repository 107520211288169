import type { DocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { cloneDeep } from 'lodash-es';

import type { QueryConfig } from 'hooks/useQuery';
import { appointmentList } from 'store/api/graph/fragments/appointmentList';
import { leadList } from 'store/api/graph/fragments/leadList';
import { retailItemDetail } from 'store/api/graph/fragments/retailItemDetail';
import { taskList } from 'store/api/graph/fragments/taskList';
import { userList } from 'store/api/graph/fragments/userList';
import { vehicleAttributesMetadata } from 'store/api/graph/fragments/vehicleAttributesMetadata';
import type { CustomItemResponseType } from 'store/api/graph/responses/responseTypes';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

// TODO: File can be removed after [ED-8463]

const retailItemFromParent = gql`
  fragment RetailItemFromParentFragment on RetailItem {
    ...RetailItemDetailFragment
    leadActivitiesCount
    phoneCallsCount: leadActivitiesCount(type: PHONE_CALL)
    conversationsCount
    testDrivesCount: leadActivitiesCount(type: TEST_DRIVE)
    walkInsCount: leadActivitiesCount(type: WALK_IN)
    createdBy {
      ...UserListFragment
    }

    leads {
      ...LeadListFragment
    }

    appointments {
      ...AppointmentListFragment
    }
    tasks {
      ...TaskListFragment
    }
  }
  ${retailItemDetail}
  ${userList}
  ${leadList}
  ${taskList}
  ${appointmentList}
`;

const leadActivityRetailItemFromParent = gql`
  fragment LeadActivityRetailItemFromParent on LeadActivity {
    attributes {
      ... on FormLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
      ... on InquiryLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
      ... on TradeInLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
      ... on PhoneCallLeadActivityAttributes {
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
      ... on PurchaseLeadActivityAttributes {
        purchaseDate
        retailItemPurchased {
          ...RetailItemFromParentFragment
        }
      }
      ... on SubscriptionChangeLeadActivityAttributes {
        subscribed
      }
      ... on TestDriveLeadActivityAttributes {
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
      ... on WalkInLeadActivityAttributes {
        retailItemOfInterest {
          ...RetailItemFromParentFragment
        }
      }
    }
  }
  ${retailItemFromParent}
`;

export const leadActivityRetailItemOfInterestDetailsQuery = gql`
  query LeadActivityRetailItemOfInterestDetailsQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: leadActivity(id: $id) {
      ...LeadActivityRetailItemFromParent
    }
    metadata {
      mutation {
        item: inventoryItem {
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
  }
  ${leadActivityRetailItemFromParent}
  ${vehicleAttributesMetadata}
`;

export const conversationRetailItemOfInterestDetailsQuery = gql`
  query ConversationRetailItemOfInterestDetailsQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: conversation(id: $id) {
      leadActivity {
        ...LeadActivityRetailItemFromParent
      }
    }
    metadata {
      mutation {
        item: inventoryItem {
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
  }
  ${leadActivityRetailItemFromParent}
  ${vehicleAttributesMetadata}
`;

export const leadRetailItemOfInterestDetailsQuery = gql`
  query LeadRetailItemOfInterestDetailsQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: lead(id: $id) {
      retailItemsOfInterest {
        ...RetailItemFromParentFragment
      }
    }

    metadata {
      mutation {
        item: inventoryItem {
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
  }
  ${vehicleAttributesMetadata}
  ${retailItemFromParent}
`;

type RetailItemQueryData = {
  query: DocumentNode;
  config: QueryConfig;
};

/** Returns the needed query to get the retail item based on the parents type. */
export function getQueryForRetailItem(
  /** Default query to use if none of the custom parent queries need to be used */
  defaultQuery: DocumentNode,
  /** Default QueryConfig to use if none of the custom parent queries need to be used  */
  defaultConfig: QueryConfig,
  /** SeededData containing the typeName and id of the parent to determine which query should be used */
  seededData: CustomItemResponseType,
  /** If hasItemAccess is true then the default query will be used */
  hasItemAccess: boolean
): RetailItemQueryData {
  if (hasItemAccess) {
    return {
      query: defaultQuery,
      config: defaultConfig,
    };
  }
  const customConfig: QueryConfig = { variables: { id: seededData.parentId } };
  switch (seededData.parentTypeName) {
    case 'Lead': {
      return {
        query: leadRetailItemOfInterestDetailsQuery,
        config: customConfig,
      };
    }

    case 'LeadActivity': {
      return {
        query: leadActivityRetailItemOfInterestDetailsQuery,
        config: customConfig,
      };
    }

    case 'Conversation': {
      return {
        query: conversationRetailItemOfInterestDetailsQuery,
        config: customConfig,
      };
    }

    default: {
      return {
        query: defaultQuery,
        config: defaultConfig,
      };
    }
  }
}

/** Extracts retail item from parent query and returns it in a CustomQueryResult */
export function getRetailItemFromQueryResponse(
  response: CustomQueryResult,
  seededData: CustomItemResponseType,
  /** If hasItemAccess is true then response can be returned without modifications */
  hasItemAccess: boolean
): CustomQueryResult {
  if (hasItemAccess) {
    return response;
  }

  const { item, metadata } = response.data;
  let retailItem: CustomItemResponseType;

  switch (seededData.parentTypeName) {
    case 'Lead': {
      const retailItemsOfInterest = item?.retailItemsOfInterest;
      retailItem = retailItemsOfInterest?.find(rooftop => rooftop.id === seededData.id);
      break;
    }

    case 'LeadActivity': {
      retailItem = item?.attributes?.retailItemOfInterest;
      break;
    }

    case 'Conversation': {
      retailItem = item?.leadActivity?.attributes?.retailItemOfInterest;
      break;
    }

    default: {
      return response;
    }
  }

  const updatedResponse = {
    ...cloneDeep(response),
    data: {
      item: {
        ...cloneDeep(retailItem),
        hasItemAccess: false,
        // Hijacking archived flag to make details read-only
        archived: true,
      } as CustomItemResponseType,
      metadata,
    },
  };
  return updatedResponse;
}
