import styled, { css } from 'styled-components/macro';

import CloseIcon from 'components/ui/icons/CloseIcon';
import { Clickable } from 'components/ui/shared/Button';
import { NEUTRAL_0, RED_500 } from 'styles/tokens';
import { Z_INDEX_5 } from 'styles/z-index';
import { clearGraphBaseURL, getGraphURL } from 'utils/apiUtils';
import { translate } from 'utils/intlUtils';
import { disableLogging, isLoggingEnabledLocally } from 'utils/loggingUtils';
import { hexToRGBA } from 'utils/styledUtils';

const ProductionModeWarning = styled.div<{ isClickable?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px 10px;
  background: ${hexToRGBA(RED_500, '0.75')};
  color: ${NEUTRAL_0};
  z-index: ${Z_INDEX_5};
  pointer-events: none;
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      pointer-events: auto;
    `};
`;

const ProductionClearButton = styled(Clickable)`
  position: fixed;
  width: 20px;
  top: 0;
  right: -20px;
  padding: 8px 0;
  background: ${hexToRGBA(RED_500, '0.75')};
  color: ${NEUTRAL_0};
  z-index: ${Z_INDEX_5};
  pointer-events: auto;
`;

const WarningLabel = styled.span`
  pointer-events: none;
  user-select: none;
`;

const EnvironmentModeWarning = () => {
  const getNonDebugWarning = () => {
    let nonDebugWarning;
    if (process.env.REACT_APP_GRAPH_GRAPHQL?.includes('dev')) {
      nonDebugWarning = 'DEV';
    } else if (process.env.REACT_APP_GRAPH_GRAPHQL?.includes('stage')) {
      nonDebugWarning = 'STAGE';
    } else if (process.env.REACT_APP_GRAPH_GRAPHQL?.includes('localhost')) {
      nonDebugWarning = 'LOCALHOST';
    } else if (process.env.REACT_APP_GRAPH_GRAPHQL?.includes('faker')) {
      nonDebugWarning = 'FAKER';
    } else if (
      process.env.REACT_APP_GRAPH_GRAPHQL?.includes('ed3') ||
      process.env.REACT_APP_GRAPH_GRAPHQL?.includes('beta')
    ) {
      nonDebugWarning = 'PROD';
    } else {
      nonDebugWarning = 'Warning: Env N/A';
    }

    return nonDebugWarning;
  };
  const isDifferentEnv = getGraphURL() !== process.env.REACT_APP_GRAPH_BASE;
  const debugWarning = `${translate.t('debug_mode')}: ${getGraphURL()}`;
  let warningLabel = isDifferentEnv ? debugWarning : getNonDebugWarning();

  if (isLoggingEnabledLocally()) {
    warningLabel += ' (WARNING: Logging enabled locally, click HERE to disable)';
  }

  const onDisableLoggingClicked = () => (isLoggingEnabledLocally() ? disableLogging() : undefined);

  return process.env.REACT_APP_FEATURE_ENV_DEBUGGING === 'true' ? (
    <div>
      <ProductionModeWarning isClickable={isLoggingEnabledLocally()} onClick={onDisableLoggingClicked}>
        <WarningLabel>{warningLabel}</WarningLabel>
        {isDifferentEnv && (
          <ProductionClearButton onClick={() => clearGraphBaseURL()}>
            <CloseIcon />
          </ProductionClearButton>
        )}
      </ProductionModeWarning>
    </div>
  ) : null;
};

export default EnvironmentModeWarning;
