import { cloneDeep } from 'lodash-es';

import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { RetailFilterBuilderFields } from '../shared/steps/interfaces';
import RetailFilterFields from '../shared/steps/RetailFilterFields';

import FilterStep from './steps/FiltersStep';
import WebsiteRouteFilterQueryMethods from './WebsiteRouteFilterQueryMethods';

const fields = cloneDeep(RetailFilterFields);
fields[RetailFilterBuilderFields.FILTER_TAG_ID].subStepAddConfig = undefined;

const WebsiteRouteFilterBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_FILTER]: {
    getMetaData: WebsiteRouteFilterQueryMethods.getMetaData,
    steps: [
      {
        id: 'RETAIL_FILTER',
        step: FilterStep,
        stepName: 'filter_other',
        fields,
      },
    ],
    legend: null,
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }],
    resourceType: ResourceType.RETAIL_ITEMS,
  },
};

export default WebsiteRouteFilterBuilders;
