// Border
/** border-radius: 0px */
export const BORDER_RADIUS_0 = '0px';
/** border-radius: 2px */
export const BORDER_RADIUS_050 = '2px';
/** border-radius: 4px */
export const BORDER_RADIUS_100 = '4px';
/** border-radius: 6px */
export const BORDER_RADIUS_150 = '6px';
/** border-radius: 8px */
export const BORDER_RADIUS_200 = '8px';
/** border-radius: 12px */
export const BORDER_RADIUS_300 = '12px';
/** border-radius: 16px */
export const BORDER_RADIUS_400 = '16px';
/** border-radius: 9999px */
export const BORDER_RADIUS_FULL = '9999px';
