import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { DefaultImage, FallbackImage } from 'components/ui/images/Images';
import { ListItemType } from 'enums/listItemType';
import { BODY_TEXT } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_100, YELLOW_050 } from 'styles/tokens';
import { FONT_SIZE_8, FONT_WEIGHT_NORMAL, LETTER_SPACING_EXPANDED_1 } from 'styles/typography';
import { variants } from 'utils/styledUtils';

/**
 * Max square image size (width/height) of a list item photo,
 * maintaining aspect ratio.
 */
export const LIST_ITEM_MAX_SQUARE_IMAGE_PX = 275;

/**
 * Approximately 3 lines of text.
 *
 * 2.75 lines of actual text and
 * 0.25 line buffer in case words wrap.
 */
export const MAX_LIST_ITEM_TEXT_LENGTH = 90;

// Generic container for ListItem Images useful for composition e.g. with `ListItemLabel`.
export const ListItemImageContainer = styled.div`
  position: relative;
  text-align: center;

  ${FallbackImage} {
    margin-top: 14px;
    background: none;
    width: auto;
    height: auto;
  }

  ${DefaultImage} {
    background: ${NEUTRAL_050};
  }
`;

// A caption/label for a ListItem.
export const ListItemLabel = styled(Label)<{ hasImage }>`
  color: ${({ hasImage }) => (hasImage ? NEUTRAL_0 : BODY_TEXT)};
  font-size: ${FONT_SIZE_8};
  letter-spacing: ${LETTER_SPACING_EXPANDED_1};
  font-weight: ${FONT_WEIGHT_NORMAL};
  position: absolute;
  bottom: 8px;
  left: 50%;
  top: initial;
  transform: translate(-50%);
`;

export const ListItemSeparator = styled.div`
  width: 2px;
  height: 11px;
  justify-self: center;
  background-color: ${NEUTRAL_050};
  display: none;
`;

export const ListItemSeparatorWrapper = styled.div`
  flex-basis: 100%;
  margin: 6px 0 6px 19px;
  display: none;

  ${ListItemSeparator} {
    display: block;
  }
`;

export enum SuffixIconDisplayType {
  ALWAYS,
  ON_HOVER,
}

const listItemContainerOnHoverStyles = `
  > svg { display: none; }
  :hover > svg { display: block; }
`;

export const ListItemContainer = styled.div<{
  isHighlighted?: boolean;
  isArchived?: boolean;
  listItemType?: ListItemType;
  showSuffixIcon?: SuffixIconDisplayType;
}>`
  display: flex;
  position: relative;
  ${props =>
    props.isHighlighted &&
    css`
      background: ${YELLOW_050};
    `};
  ${props =>
    props.isArchived &&
    css`
      background: ${NEUTRAL_100};
    `};
  ${variants<ListItemType>('listItemType', {
    [ListItemType.SECTION_LIST]: css`
      padding: 10px 27px 10px 15px;
      margin-bottom: 10px;
    `,
    [ListItemType.CONDENSED_LIST]: css`
      padding: 17px ${ENTITY_PADDING};
    `,
    [ListItemType.BUILDER_CHECKBOX_LIST]: css`
      padding: 2px 0 0;
    `,
  })}

  > :first-child {
    margin-right: ${ENTITY_PADDING};
  }

  > svg {
    position: absolute;
    ${variants<ListItemType>('listItemType', {
      [ListItemType.SECTION_LIST]: css`
        top: 10px;
        right: 12px;
      `,
      [ListItemType.CONDENSED_LIST]: css`
        top: 10px;
        right: 14px;
      `,
      [ListItemType.BUILDER_CHECKBOX_LIST]: css``,
    })}
  }

  ${({ showSuffixIcon }) => (showSuffixIcon === SuffixIconDisplayType.ON_HOVER ? listItemContainerOnHoverStyles : '')}
`;

export const ListItemIcon = styled(PrimaryText)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${NEUTRAL_050};
`;

export const ListItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  > :not(:first-child) {
    margin-top: 6px;
  }

  ${TextRow} {
    text-align: left;
  }
`;

// TODO: [#1216] - Add Variant support for list items margins
export const ListItems = styled.div`
  display: flex;
  flex-direction: column;

  ${ListItemContainer} {
    margin-bottom: 8px;
  }

  ${ListItemContainer} > ${ListItemSeparatorWrapper} {
    display: block;
  }

  ${ListItemContainer}:last-of-type > ${ListItemSeparatorWrapper} {
    display: none;
  }

  > :last-child {
    ${ListItemContainer} {
      padding-bottom: 20px;
      margin-bottom: 0;
    }
  }

  :empty {
    display: none;
  }
`;

interface Props {
  children: ReactNode;
  /** The icon to be displayed. If set to `true`, uses default chevron icon, else use a rendered element */
  suffixIcon?: ReactNode;
  /** Whether or not to highlight this this list item */
  isHighlighted?: boolean;
  /** Whether or not to highlight this list item red to show its been archived */
  isArchived?: boolean;
  /** The type of list item being rendered, used to determine css variant */
  listItemType?: ListItemType;
  /** Standard `onClick` callback passed along to container */
  onClick?: () => void;
  /** Whether to always show the suffix icon, or only on hover */
  showSuffixIcon?: SuffixIconDisplayType;
}

export const ListItem = ({ suffixIcon, children, ...props }: Props) => (
  <ListItemContainer {...props}>
    {children}
    {typeof suffixIcon === 'object' ? suffixIcon : suffixIcon && <ChevronRightIcon color={BODY_TEXT} />}
  </ListItemContainer>
);
