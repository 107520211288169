import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getInventoryItemCombinedConfigurations } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { ItemsColumn } from 'enums/itemsColumn';
import { useQuery } from 'hooks/useQuery';
import { appointmentList } from 'store/api/graph/fragments/appointmentList';
import colorTableMetadata from 'store/api/graph/fragments/colorTableMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { conversationList } from 'store/api/graph/fragments/conversationList';
import { filters } from 'store/api/graph/fragments/filters';
import { leadList } from 'store/api/graph/fragments/leadList';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { taskList } from 'store/api/graph/fragments/taskList';
import { tradeInAppraisalExternal } from 'store/api/graph/fragments/tradeInAppraisalExternal';
import { tradeInAppraisals } from 'store/api/graph/fragments/tradeInAppraisals';
import { tradeInGuaranteedTradeValue } from 'store/api/graph/fragments/tradeInGuaranteedTradeValue';
import { tradeInItemDetail } from 'store/api/graph/fragments/tradeInItemDetail';
import { tradeInItemList } from 'store/api/graph/fragments/tradeInItemList';
import { userList } from 'store/api/graph/fragments/userList';
import { vehicleAttributesMetadata } from 'store/api/graph/fragments/vehicleAttributesMetadata';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const tradeInItemsDetailsQuery = gql`
  query TradeInItemDetailsContainerQuery($id: ID!) {
    ## Important: 'inventoryItem' alias is required for data reading
    item: tradeInItem(id: $id) {
      ## ACTIVITY TAB
      guaranteedValue {
        ...TradeInGuaranteedTradeValueFragment
      }
      appraisals {
        ...TradeInAppraisalsFragment
      }
      pendingAppraisals {
        ...TradeInAppraisalExternalFragment
      }

      ### Linked TAB
      lead {
        ...LeadListFragment
      }
      appointments {
        ...AppointmentListFragment
      }
      tasks {
        ...TaskListFragment
      }
      createdBy {
        ...UserListFragment
      }
      leadActivityPartial {
        id
        conversation {
          ...ConversationListFragment
        }
      }

      ## DETAILS TAB
      ...TradeInItemDetailFragment
    }
    metadata {
      mutation {
        item: inventoryItem {
          conditionDeclarations {
            ...SelectStringOptionFragment
          }
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
  }
  ${leadList}
  ${tradeInAppraisals}
  ${tradeInAppraisalExternal}
  ${tradeInGuaranteedTradeValue}
  ${vehicleAttributesMetadata}
  ${tradeInItemDetail}
  ${taskList}
  ${userList}
  ${appointmentList}
  ${conversationList}
`;

export const tradeInItemsCondensedListQuery = gql`
  query TradeInItemCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: TradeInItemConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: tradeInItemConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...TradeInItemListFragment
        }
      }
    }
    metadata {
      ...ColorTableMetadataFragment
    }
  }
  ${colorTableMetadata}
  ${tradeInItemList}
  ${pageInfo}
`;

export const tradeInItemsContainerQuery = gql`
  query TradeInItemConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: TradeInItemConnectionFilterInput
    $d_noteOn: Boolean!
    $d_typeOn: Boolean!
    $d_conditionOn: Boolean!
    $d_tasksCountOn: Boolean!
    $d_videosCountOn: Boolean!
    $d_photosCountInteriorOn: Boolean!
    $d_photosCountExteriorOn: Boolean!
    $d_photosCountDamageOn: Boolean!
    $d_createdByNameOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: tradeInItemConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...TradeInItemListFragment
          note @include(if: $d_noteOn)
          status
          type
          typeName @include(if: $d_typeOn)
          condition @include(if: $d_conditionOn)
          conditionName @include(if: $d_conditionOn)
          tasksCount @include(if: $d_tasksCountOn)
          videosCount @include(if: $d_videosCountOn)
          photosCountInterior: photosCount(type: INTERIOR) @include(if: $d_photosCountInteriorOn)
          photosCountExterior: photosCount(type: EXTERIOR) @include(if: $d_photosCountExteriorOn)
          photosCountDamage: photosCount(type: DAMAGE) @include(if: $d_photosCountDamageOn)
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
          createdByName: createdBy @include(if: $d_createdByNameOn) {
            id
            firstName
            lastName
          }
        }
      }
    }
    metadata {
      ...ColorTableMetadataFragment
    }
  }
  ${columns}
  ${colorTableMetadata}
  ${filters}
  ${pageInfo}
  ${tradeInItemList}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  InventoryItem.YEAR,
  InventoryItem.MAKE_NAME,
  InventoryItem.MODEL_NAME,
  InventoryItem.TRIM_NAME,
  InventoryItem.STATUS,
  InventoryItem.VIN,
  InventoryItem.VEHICLE_MILEAGE,
  InventoryItem.MOTORCYCLE_MILEAGE,
] as string[];

export const useTradeInItemConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getInventoryItemCombinedConfigurations(
    formattedConnectionParams.filter.type,
    TableType.TRADE_IN_ITEMS
  );

  const formattedTableColumns = gqlFormatTableColumnFields(
    tradeInItemsContainerQuery,
    tableConfigurationNext,
    staticColumns
  );

  return useQuery(tradeInItemsContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};
