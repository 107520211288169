import { gql } from '@apollo/client';

import { inventoryItemPhoto } from 'store/api/graph/fragments/photos';
import { retailItemDetail } from 'store/api/graph/fragments/retailItemDetail';
import { inventoryItemVideo } from 'store/api/graph/fragments/video';

export const inventoryItemPhotoModify = gql`
  mutation InventoryItemPhotoModifyMutation($inventoryItemId: ID!, $photos: [InventoryItemPhotoInput!]!) {
    data: inventoryItemPhotoModify(inventoryItemId: $inventoryItemId, photos: $photos) {
      # TODO: [#1111] fetch separate photos for thumbnails/large items
      id
      photos {
        ...InventoryItemPhotoFragment
      }
    }
  }
  ${inventoryItemPhoto}
`;

export const inventoryItemPhotoDelete = gql`
  mutation InventoryItemPhotoDeleteMutation($inventoryItemId: ID!, $photoIds: [ID!]!) {
    data: inventoryItemPhotoDelete(inventoryItemId: $inventoryItemId, photoIds: $photoIds) {
      id
      photos {
        ...InventoryItemPhotoFragment
      }
    }
  }
  ${inventoryItemPhoto}
`;

export const inventoryItemVideoCreate = gql`
  mutation InventoryItemVideoCreateMutation(
    $inventoryItemId: ID
    $shotCode: Int
    $source: InventoryItemVideoSource
    $url: String
  ) {
    data: inventoryItemVideoCreate(inventoryItemId: $inventoryItemId, shotCode: $shotCode, source: $source, url: $url) {
      ...InventoryItemVideoFragment
    }
  }
  ${inventoryItemVideo}
`;

export const inventoryItemVideoDelete = gql`
  mutation InventoryItemVideoDeleteMutation($inventoryItemId: ID!, $videoIds: [ID!]!) {
    data: inventoryItemVideoDelete(inventoryItemId: $inventoryItemId, videoIds: $videoIds) {
      id
      videos {
        ...InventoryItemVideoFragment
      }
    }
  }
  ${inventoryItemVideo}
`;

/**
 * ADD STOCK PHOTOS
 */

export const retailItemAddTrimPhotos = gql`
  mutation RetailItemAddTrimPhotosMutation($id: ID!, $trimPhotoIds: [ID!]) {
    retailItemAddTrimPhotos(id: $id, trimPhotoIds: $trimPhotoIds) {
      ...RetailItemDetailFragment
    }
  }
  ${retailItemDetail}
`;
