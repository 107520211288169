// The type of adjustment amount can be either a fixed value or a percentage
export enum RetailAdjustmentAmountType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

// The type of price adjustment refers to whether the adjustment amount is a discount or a fee
export enum RetailAdjustmentAmountAdjustmentType {
  FEE = 'fee',
  DISCOUNT = 'discount',
}
