import styled from 'styled-components/macro';

import { Clickable } from 'components/ui/shared/Button';
import { NEUTRAL_050, NEUTRAL_300, SPACE_200 } from 'styles/tokens';

const ClickableInput = styled(Clickable)`
  position: relative;
  width: 100%;
  min-height: 40px;
  background: ${NEUTRAL_050};
  border: 1px solid ${NEUTRAL_300};
  border-radius: 8px;
  margin-top: ${SPACE_200};
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 45px;
`;

export default ClickableInput;
