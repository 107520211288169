import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopCashSegmentDetailsBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/cashPricing/steps/interfaces';
import DisclaimerPreview from 'components/sections/createModify/rooftopPricingSegments/DisclaimerPreview';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import { RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  AutoPriceAdjustmentConfigurationInputParameter,
  CashRetailPricingConfigurationInputParameter,
  PaymentOption,
  RooftopRetailPricingModifyParameter,
} from 'store/api/graph/interfaces/types';
import { getNestedConfigurationField } from 'utils/formatting/pricingUtils';

const DetailFields: StepFields = {
  [RooftopCashSegmentDetailsBuilderFields.NAME]: {
    label: 'segment_configuration_name',
    queryAlias: [RooftopPricingSegment.NAME],
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_ENABLED]: {
    label: 'enabled',
    groupType: StepFieldType.SWITCH,
    queryAlias: [RooftopPricingSegment.CONFIGURATION_ENABLED],
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.TARGETS]: {
    label: 'target_other',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopRetailPricing.targets',
    required: true,
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_PRICING_SOURCE]: {
    label: 'incentives_and_rates_source',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE_NAME,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
    label: 'include_after_tax_rebates',
    groupType: StepFieldType.SWITCH,
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES),
    ],
    clear: {
      field: CashRetailPricingConfigurationInputParameter._includeAfterTaxRebates,
      target: RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_STARTING_PRICE_FIELD]: {
    label: 'starting_cash_price',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD_NAME,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopRetailPricing.cashConfiguration.startingPriceField',
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED]: {
    label: 'automated_price_adjustment_one',
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'automated_price_adjustment_tooltip',
    displayType: [StepFieldDisplayType.OMITTED],
    clear: {
      field: CashRetailPricingConfigurationInputParameter._autoPriceAdjustmentConfiguration,
      target: RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE]: {
    label: 'target_price',
    placeholder: 'target_price',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE_FIELD),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
    options: 'mutation.rooftopRetailPricing.cashConfiguration.autoPriceAdjustmentConfiguration.targetPriceField',
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]: {
    label: 'label',
    placeholder: 'label',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL),
    ],
    required: true,
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE]: {
    label: 'disclaimer',
    placeholder: 'disclaimer',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE),
    ],
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    clear: {
      field: AutoPriceAdjustmentConfigurationInputParameter._disclaimerTemplate,
      target: RooftopPricingSegmentConfigurationPointer.AUTO_PRICE_ADJUSTMENT_CONFIGURATION,
    },
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_TAX]: {
    label: 'include_tax',
    groupType: StepFieldType.SWITCH,
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX),
    ],
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_FREIGHT]: {
    label: 'include_freight',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_FREIGHT,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_FREIGHT),
    ],
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    groupType: StepFieldType.CURRENCY,
    tooltipContents: 'minimum_display_price_tooltip',
    hasSeparator: true,
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_MIN_DISPLAY_PRICE,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_MIN_DISPLAY_PRICE),
    ],
    clear: {
      field: CashRetailPricingConfigurationInputParameter._minDisplayPrice,
      target: RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION,
    },
  },
  [RooftopCashSegmentDetailsBuilderFields.ORDER]: {
    label: 'order',
    groupType: StepFieldType.NUMBER,
    tooltipContents: 'order_tooltip',
    clear: { field: RooftopRetailPricingModifyParameter._order },
    hasSeparator: true,
  },
  [RooftopCashSegmentDetailsBuilderFields.DESCRIPTION]: {
    label: 'description',
    queryAlias: [RooftopPricingSegment.DESCRIPTION],
    groupType: StepFieldType.TEXT_AREA,
    hasSeparator: true,
    clear: { field: RooftopRetailPricingModifyParameter._description },
  },
  [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_DISCLAIMER_TEMPLATE]: {
    label: 'cash_disclaimer',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_DISCLAIMER,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_DISCLAIMER),
    ],
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    tooltipContents: 'pricing_segment_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    hasSeparator: true,
    previewContents: ({ fieldValue, language }) => (
      <DisclaimerPreview fieldValue={fieldValue} language={language} paymentOption={PaymentOption.CASH} />
    ),
    required: true,
  },
};

export default DetailFields;
