import AppointmentsIcon from 'components/ui/icons/AppointmentsIcon';
import EmailIcon from 'components/ui/icons/EmailIcon';
import PhoneIcon from 'components/ui/icons/PhoneIcon';
import SMSIcon from 'components/ui/icons/SMSIcon';
import TestDriveIcon from 'components/ui/icons/TestDriveIcon';
import WalkInIcon from 'components/ui/icons/WalkInIcon';
import Image from 'components/ui/images/Images';
import { ImageSize, ImageType } from 'enums/imageType';

enum RetailItemActivityType {
  SMS = 'SMS',
  PHONE_CALL = 'PHONE_CALL',
  EMAIL = 'EMAIL',
  WALK_IN = 'WALK_IN',
  FACEBOOK_MESSENGER = 'FACEBOOK_MESSENGER',
  TEST_DRIVE = 'TEST_DRIVE',
  APPOINTMENT = 'APPOINTMENT',
}

export const RetailItemActivityIcon = ({ type }) => {
  let fallbackSrc;
  switch (type) {
    case RetailItemActivityType.SMS: {
      fallbackSrc = <SMSIcon />;
      break;
    }

    case RetailItemActivityType.PHONE_CALL: {
      fallbackSrc = <PhoneIcon />;
      break;
    }

    case RetailItemActivityType.EMAIL: {
      fallbackSrc = <EmailIcon />;
      break;
    }

    case RetailItemActivityType.WALK_IN: {
      fallbackSrc = <WalkInIcon />;
      break;
    }

    case RetailItemActivityType.FACEBOOK_MESSENGER: {
      fallbackSrc = <SMSIcon />;
      break;
    }

    case RetailItemActivityType.TEST_DRIVE: {
      fallbackSrc = <TestDriveIcon />;
      break;
    }

    case RetailItemActivityType.APPOINTMENT: {
      fallbackSrc = <AppointmentsIcon />;
      break;
    }
  }
  return <Image type={ImageType.ICON} size={ImageSize.LIST_ITEM} fallbackSrc={fallbackSrc} />;
};
