import { memo, useCallback } from 'react';

import type { LocationFieldDataTypeSettings } from 'components/sections/shared/ItemGridCell';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopDepartment, RooftopDepartmentSettings } from 'enums/columns/rooftopDepartment';
import { RooftopDepartmentType } from 'store/api/graph/interfaces/types';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import DepartmentWeeklyHours from './DepartmentWeeklyHours';
import { getDaysOfTheWeekFromMetadata } from './utils';

const { HOURS, TYPE_NAME, PHONE_NUMBER, EMAIL, LOCATION } = RooftopDepartment;

const { BOLD } = ItemGridRowDisplayType;

export const RooftopDepartmentDetailsTab = memo<ItemTabProps>(({ item: department, metadata, onEdit }) => {
  const gridConfig: ItemGridRowProps[] = [
    { fields: [TYPE_NAME], displayType: [BOLD], canEdit: true },
    { fields: [EMAIL], displayType: [BOLD], canEdit: true },
    { fields: [PHONE_NUMBER], displayType: [BOLD], canEdit: true },
    { fields: [LOCATION], canEdit: true, cellSettings: { showMap: false } as LocationFieldDataTypeSettings },
    {
      fields: [HOURS],
      canEdit: true,
      gridCellRenderMethod: (settings: TableSettingsType, item: any, id: string, metadata: any) => (
        <DepartmentWeeklyHours days={getDaysOfTheWeekFromMetadata(metadata)} openHours={item.hours} />
      ),
    },
  ];

  // Callbacks
  const onEditCallback = useCallback((field?: string) => onEdit(field, translate.t('department')), [onEdit]);

  return department ? (
    <>
      {gridConfig
        // Only SERVICE departments should show the location field
        .filter(config => !config.fields.includes(LOCATION) || department.type === RooftopDepartmentType.SERVICE)
        .map(config => (
          <ItemGridRowSection
            key={formatItemGridRowSectionKey(config.fields)}
            item={department}
            metadata={metadata}
            settings={RooftopDepartmentSettings}
            onEdit={authorizedCallback({ cb: onEditCallback, isAuth: !!onEdit })}
            config={config}
          />
        ))}
    </>
  ) : null;
});
