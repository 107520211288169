import { useCallback, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash-es';

import { rooftopRetailPricingDelete } from 'components/sections/createModify/rooftopPricingSegments/cashPricing/RooftopCashSegmentCreateModifyQuery';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopRetailPricingResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { getPricingSegmentBuilderFromPaymentOption } from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends Omit<MenuItemProps, 'item'> {
  item: RooftopRetailPricingResponseType | undefined;
}

const PricingSegmentMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { id } = item || {};
  const [isHardDeleteDialogOpen, setIsHardDeleteDialogOpen] = useState<boolean>(false);

  const { toggleTier } = useCreateModify();
  const { back } = useNestedView();
  const { hasPermissions } = useUser();

  const builderType = useMemo(
    () => getPricingSegmentBuilderFromPaymentOption(item?.paymentOption, { isModify: true }),
    [item]
  );

  const isAllowedToEdit = useMemo(
    () => !!builderType?.type && hasPermissions(builderConfig[builderType.type].requiredPermissions),
    [builderType?.type, hasPermissions, builderConfig]
  );

  const onModifyClicked = useCallback(() => {
    if (builderType?.type) {
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        title: `${t('modify_x', [t('pricing_segment_one')])} (${item?.paymentOptionName})`,
        isCreating: false,
        itemId: item?.id,
        type: builderType.type,
        entityType: builderType.entityType,
        data: cloneDeep(item),
      });
    }
  }, [item, toggleTier, builderType]);

  const onHardDeleteClicked = useCallback(
    async () =>
      client.mutate({
        mutation: rooftopRetailPricingDelete,
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: ['RooftopRetailPricingContainerQuery', 'RooftopDetailsContainerQuery'],
      }),
    [id]
  );

  // In the case of Adjustments, we want to be able to modify/delete it even when it is not active
  const menuItemsConfig: MenuItemConfig[] = [
    { label: t('modify'), onClick: onModifyClicked, disabled: !isAllowedToEdit, isArchivable: false },
    {
      label: t('delete'),
      onClick: () => setIsHardDeleteDialogOpen(true),
      disabled: !isAllowedToEdit,
      isArchivable: false,
    },
  ];

  return (
    <>
      <MenuItems items={menuItemsConfig} />
      {/* Delete Dialog */}
      <PromptDialog
        isConfirmDestructive
        isOpen={isHardDeleteDialogOpen}
        onConfirm={onHardDeleteClicked}
        onCancel={() => setIsHardDeleteDialogOpen(false)}
        onComplete={(success: boolean) => {
          if (success) {
            // Hide the success dialog after 0.7 seconds, if the user hasn't already dismissed it
            setTimeout(() => {
              setIsHardDeleteDialogOpen(false);
              back();
            }, 700);
          }
        }}
        confirmText={t('delete')}
        cancelText={t('cancel')}
        title={t('hard_delete_title')}
        message={t('hard_delete_message')}
      />
    </>
  );
};

export default PricingSegmentMenuItems;
