import { gql } from '@apollo/client';

export const tradeInAppraisal = gql`
  fragment TradeInAppraisalFragment on TradeInAppraisal {
    id
    displayName
    amount {
      formattedAmountRounded
      amount
    }
    updated
  }
`;
