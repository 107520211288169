import { memo } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { decimalPercentToString } from 'utils/stringUtils';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  // The percent value (between 0 and 1)
  percent: number;
  // Decimal places to show for the percentages */
  decimals?: number;
  // Label to show on the left-hand side
  label: string;
  // Colour to use for the percentage value
  colour: string;
}

const PercentageListItem = ({ percent, decimals, label, colour }: Props) => (
  <Container>
    <Text>{label}</Text>
    <PrimaryText
      css={css`
        color: ${colour};
      `}
    >
      {decimalPercentToString(percent, decimals || 0)}
    </PrimaryText>
  </Container>
);

export default memo(PercentageListItem);
