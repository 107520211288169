import { gql } from '@apollo/client';

import { monetaryAmount } from './monetaryAmount';
import { MultilingualString } from './multilingualString';

export const priceRetailBulkAdjustmentAttributes = gql`
  fragment PriceRetailBulkAdjustmentAttributesFragment on PriceRetailBulkAdjustmentAttributes {
    disclaimer {
      ...MultilingualStringFragment
    }
    fixed {
      ...MonetaryAmountFragment
    }
    label {
      ...MultilingualStringFragment
    }
    paymentOptions
    paymentOptionsNames
    percentage
    percentageAsAmount {
      ...MonetaryAmountFragment
    }
    targets
    targetsNames
    type
    typeName
  }
  ${MultilingualString}
  ${monetaryAmount}
`;
