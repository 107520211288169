import { memo, type SVGProps } from 'react';

import { NEUTRAL_200 } from 'styles/tokens';

const SortDirectionUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 6.167 9.747 8.421c-.228.228-.5.342-.818.342-.317 0-.59-.114-.818-.342a1.12 1.12 0 0 1-.341-.82c0-.32.113-.594.341-.822l3.087-3.087a1.05 1.05 0 0 1 .373-.245c.137-.052.28-.077.429-.077.15 0 .292.025.429.077.136.052.26.134.373.245l3.087 3.087c.227.228.341.502.341.821 0 .32-.114.593-.341.82a1.13 1.13 0 0 1-.821.337 1.12 1.12 0 0 1-.82-.342L12 6.167Z"
    />
    <path
      fill={NEUTRAL_200}
      d="m12 17.915 2.253-2.254c.228-.227.5-.341.818-.341.317 0 .59.114.818.341.227.228.341.502.341.821 0 .32-.114.593-.341.82l-3.087 3.088a1.05 1.05 0 0 1-.373.245c-.137.052-.28.077-.429.077-.15 0-.292-.025-.429-.077a1.05 1.05 0 0 1-.373-.245l-3.087-3.087a1.12 1.12 0 0 1-.341-.82c0-.32.113-.594.341-.822a1.13 1.13 0 0 1 .821-.335c.32 0 .593.114.82.341L12 17.915Z"
    />
  </svg>
);

export default memo(SortDirectionUpIcon);
