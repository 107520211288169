import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';

import { CreditApplicationBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [CreditApplicationBuilderFields.FUNDING_BY]: {
    label: 'funding_by',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
    displayType: [StepFieldDisplayType.OMITTED, StepFieldDisplayType.DISABLED],
  },
  [CreditApplicationBuilderFields.LENDER_ID]: {
    label: 'lender',
    queryAlias: 'lender',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    settings: {
      hideSearchHeader: true,
    } as ListSelectionSettings,
    hasSeparator: true,
  },
  [CreditApplicationBuilderFields.PRODUCT_CODE]: {
    label: 'product',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
    displayType: [StepFieldDisplayType.DISABLED],
  },
};

export default DetailFields;
