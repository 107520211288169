import { useEffect, useMemo, useState } from 'react';

import type { DocumentNode } from 'graphql';

import { retailFiltersMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import {
  rooftopRetailPricingCashDetailQuery,
  rooftopRetailPricingFinanceDetailQuery,
  rooftopRetailPricingLeaseDetailQuery,
} from 'containers/rooftops/pricingSegments/RooftopRetailPricingContainerQuery';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type { RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { combineDetailAndMetadataResponses, getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const paymentOptionQueryMap = {
  [PaymentOption.FINANCE]: rooftopRetailPricingFinanceDetailQuery,
  [PaymentOption.CASH]: rooftopRetailPricingCashDetailQuery,
  [PaymentOption.LEASE]: rooftopRetailPricingLeaseDetailQuery,
};

export const useRetailFiltersDetailsQuery = (query: DocumentNode, config: QueryConfig, itemData): CustomQueryResult => {
  const [requestOptionQuery, setRequestOptionQuery] = useState<DocumentNode>(query);

  useEffect(() => {
    const paymentOptionQuery = paymentOptionQueryMap[itemData?.paymentOption];
    if (!paymentOptionQuery) {
      return;
    }

    setRequestOptionQuery(paymentOptionQuery);
  }, [itemData?.paymentOption]);

  const detailsResponse = useQuery(requestOptionQuery, config);
  const metadataResponse = useQuery<RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables>(retailFiltersMetaQuery, {
    variables: getRetailFiltersMetaQueryVariables(
      detailsResponse?.data?.item?.rooftopName?.id,
      detailsResponse?.data?.item?.filter
    ),
    ignore: detailsResponse.isLoading,
  });

  return useMemo(
    () => combineDetailAndMetadataResponses(detailsResponse, metadataResponse),
    [detailsResponse, metadataResponse]
  );
};
