import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import type {
  InventoryItemDetailFragment,
  RetailItemLockableField,
  StandardEquipment,
  TechnicalSpecification,
  VehicleAttributes,
  VehicleAttributesLockableField,
} from 'store/api/graph/interfaces/types';
import { InventoryItemType, PriceField } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType, TradeInItemResponseType } from 'store/api/graph/responses/responseTypes';

/**
 * Given sorted technical specs or standard equipment data, determine the last category.
 * @param categories - The SortedTechnicalSpecifications or SortedStandardEquipment data
 */
export const getLastCategory = (
  categories: Record<string, TechnicalSpecification[]> | Record<string, StandardEquipment[]> | undefined
): string | null => {
  if (!categories) {
    return null;
  }

  const enabledCategories = Object.keys(categories);

  return enabledCategories.length > 0 ? enabledCategories.at(-1) || null : null;
};

type InventoryItemReponse = RetailItemResponseType | TradeInItemResponseType | InventoryItemDetailFragment;

const isRetailItem = (inventoryItem: InventoryItemReponse): inventoryItem is RetailItemResponseType =>
  inventoryItem.__typename === 'RetailItem';

/**
 * A method for checking if retail item currently has a specific lockedField.
 * Returns a boolean, or undefined if the provided item is not lockable (ie. not a RetailItem and VEHICLE)
 *
 * @param lockedFieldId A pointer to the field in InventoryItemSettings,
 *                      or RetailItemLockableField or VehicleAttributesLockableField
 * @param inventoryItem The retailItem to check for locked fields
 */
export const hasLockedField = (
  lockedFieldId: string | RetailItemLockableField | VehicleAttributesLockableField,
  inventoryItem: InventoryItemReponse
): boolean | undefined => {
  if (!isRetailItem(inventoryItem) || inventoryItem.type !== InventoryItemType.VEHICLE) {
    return;
  }

  const lockedField: RetailItemLockableField | VehicleAttributesLockableField | undefined = lockedFieldId.startsWith(
    '_'
  )
    ? lockedFieldId
    : InventoryItemSettings[lockedFieldId]?.lockedFieldId;

  return (
    (!!lockedField &&
      (inventoryItem.lockedFields?.includes(lockedField as RetailItemLockableField) ||
        (inventoryItem.vehicleAttributes as VehicleAttributes)?.lockedFields.includes(
          lockedField as VehicleAttributesLockableField
        ))) ||
    false
  );
};

export const isPurchasePriceLocked = (retailItem: RetailItemResponseType) =>
  !!retailItem?.cashRetailPricing &&
  ((retailItem.cashRetailPricing.configuration.startingPriceField === PriceField.MSRP &&
    hasLockedField(InventoryItem.MSRP, retailItem)) ||
    (retailItem.cashRetailPricing.configuration.startingPriceField === PriceField.PRICE_1 &&
      hasLockedField(InventoryItem.PRICE_1, retailItem)) ||
    (retailItem.cashRetailPricing.configuration.startingPriceField === PriceField.PRICE_2 &&
      hasLockedField(InventoryItem.PRICE_2, retailItem)));
