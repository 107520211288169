import { memo, type SVGProps } from 'react';

const TrueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path
      d="M9 0c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9zm4.562 6.032a.749.749 0 00-1.06 0l-4.346 4.345L6.25 8.47a.749.749 0 10-1.06 1.06l2.437 2.438a.748.748 0 001.06 0l4.876-4.875a.75.75 0 000-1.06z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default memo(TrueIcon);
