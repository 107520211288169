import { memo, type SVGProps } from 'react';

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M7 17.203c-1.44 0-2.667-.507-3.681-1.521C2.304 14.666 1.797 13.44 1.797 12c0-1.44.507-2.667 1.522-3.682C4.333 7.304 5.56 6.797 7 6.797h2.773c.32 0 .588.11.808.329.22.22.33.49.33.808 0 .32-.11.589-.33.808-.22.22-.489.33-.808.33H7c-.813 0-1.505.284-2.074.854-.57.57-.854 1.26-.854 2.074 0 .813.284 1.505.854 2.074.57.57 1.26.854 2.074.854h2.773c.32 0 .588.11.808.33.22.22.33.489.33.808 0 .319-.11.588-.33.808-.22.22-.489.33-.808.33H7Zm1.91-4.155c-.297 0-.545-.1-.746-.302-.201-.2-.301-.45-.301-.746 0-.297.1-.546.3-.746.202-.201.45-.302.747-.302h6.18c.297 0 .545.1.746.302.201.2.301.45.301.746 0 .297-.1.546-.3.746-.202.201-.45.302-.747.302H8.91Zm5.317 4.155c-.32 0-.588-.11-.808-.329a1.1 1.1 0 0 1-.33-.808c0-.32.11-.589.33-.808.22-.22.489-.33.808-.33H17c.813 0 1.505-.284 2.074-.854.57-.57.854-1.26.854-2.074 0-.813-.284-1.505-.854-2.074A2.825 2.825 0 0 0 17 9.072h-2.773c-.32 0-.588-.11-.808-.33a1.1 1.1 0 0 1-.33-.808c0-.319.11-.588.33-.808.22-.22.489-.33.808-.33H17c1.44 0 2.667.508 3.681 1.522 1.015 1.015 1.522 2.242 1.522 3.681 0 1.44-.507 2.667-1.522 3.682-1.014 1.015-2.242 1.522-3.681 1.522h-2.773Z"
    />
  </svg>
);

export default memo(LinkIcon);
