import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import LinkedStep from '../shared/steps/LinkedStep';

import TaskDetailsFields from './steps/DetailsFields';
import TaskDetailsStep from './steps/DetailsStep';
import TaskLinkedFields from './steps/TasksLinkedFields';
import { taskCreate, taskModify } from './TaskCreateModifyQuery';
import TaskQueryMethods from './TaskQueryMethods';

const TaskBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.TASK_CREATE]: {
    getMetaData: TaskQueryMethods.getMetaData,
    refetchQueries: TaskQueryMethods.refetchQueries,
    resourceType: ResourceType.TASKS,
    steps: [
      {
        id: 'TASK_DETAILS',
        step: TaskDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: TaskDetailsFields,
      },
      {
        id: 'TASK_LINKED',
        step: LinkedStep,
        stepName: 'linked',
        isEnabled: false,
        fields: TaskLinkedFields,
      },
    ],
    mutations: {
      modify: taskModify,
      create: taskCreate,
    },
    requiredPermissions: [{ resource: ResourceType.TASKS, level: AccessLevel.ADVANCED }],
  },
  [BuilderType.TASK_MODIFY]: {
    getMetaData: TaskQueryMethods.getMetaData,
    refetchQueries: TaskQueryMethods.refetchQueries,
    resourceType: ResourceType.TASKS,
    getData: TaskQueryMethods.getData,
    steps: [
      {
        id: 'TASK_DETAILS',
        step: TaskDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: TaskDetailsFields,
      },
      {
        id: 'TASK_LINKED',
        step: LinkedStep,
        stepName: 'linked',
        isEnabled: true,
        fields: TaskLinkedFields,
      },
    ],
    mutations: {
      modify: taskModify,
    },
    requiredPermissions: [{ resource: ResourceType.TASKS, level: AccessLevel.ADVANCED }],
  },
};

export default TaskBuilders;
