import type { YearModelOption } from 'components/sections/createModify/shoppingTools/steps/interfaces';
import type { Make, Model, MultilingualString, YearModels } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

/**
 * Return the available showroom years, which will be the current year, and the following year
 */
export const getShowroomYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  return [currentYear, currentYear + 1];
};

/**
 * Given a make and model, format the name displayed in the selection list, which will basically be make + model
 */
export const formatModelName = (model: Model, make: Make): MultilingualString => ({
  value: `${make.name.value} ${model.name.value}`,
});

/**
 * Given a year, format the label used in the category header
 * @param year - The year
 */
export const formatCategoryLabel = (year: number): string => {
  const [currentYear] = getShowroomYears();
  return `${year} - ${year === currentYear ? translate.t('current_year') : translate.t('next_year')}`;
};

/**
 * Since multiple models may be used in the same list each item needs a unique id, which can be derived by including
 * the year with the model id
 * @param model - The Model
 * @param year - The year of the Model
 */
export const getModelYearId = (model: Model, year: number): string => `${year}-${model.id}`;

/**
 * Given two YearModelOption items, sort the items based on the name
 * @param model1 - YearModelOption
 * @param model2 - YearModelOption
 */
export const sortYearModelOption = (model1: YearModelOption, model2: YearModelOption): number => {
  if (model1.name.value < model2.name.value) {
    return -1;
  } else if (model1.name.value > model2.name.value) {
    return 1;
  }
  return 0;
};

/**
 * Get the list of YearModelOption from provided YearModels list and Make list. The YearModel object has a list of
 * models for a given year, this util will go through each year and create a single list with all available models
 * with a category property to categorize the models by year. The model name is formatted by including the name of the
 * make.
 * @param yearModels - list of YearModels
 * @param makes - list of Make
 */
export const getModelOptions = (yearModels: YearModels[], makes: Make[]): YearModelOption[] =>
  yearModels
    .flatMap(yearModel =>
      yearModel.models.reduce((allModels, model) => {
        const make = makes.find(item => item.id === model.makeId);

        return make
          ? [
              ...allModels,
              {
                name: formatModelName(model, make),
                category: formatCategoryLabel(yearModel.year),
                id: getModelYearId(model, yearModel.year),
                modelId: model.id,
                makeId: model.makeId,
                year: yearModel.year,
              },
            ]
          : allModels;
      }, [] as YearModelOption[])
    )
    .sort(sortYearModelOption);
