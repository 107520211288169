import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

/*
 * Based off of fields from `TaskConnection`
 * TODO: [#1117] Derive from columns in connection
 */
export enum Task {
  GROUP_NAME = 'groupName',
  ROOFTOP_NAME = 'rooftopName',
  STATUS = 'status',
  DUE_DATE = 'dateDue',
  TITLE = 'title',
  NOTE = 'note',
  ASSIGNED_TO_NAME = 'assignedToName',
  TAGS = 'tagName',
  LEAD_NAME = 'leadName',
  INVENTORY_ITEM_NAME = 'inventoryItemName',
  ARCHIVED = 'archived',
  CREATED_BY_NAME = 'createdByName',
  CREATED = 'created',
  UPDATED = 'updated',
}

/*
 * Settings with type derived from API docs
 * TODO: [#1117] Refactor when moving over to `columns` in connection for specifying labels
 */
const TaskConfig: TableSettingsType = {
  [Task.GROUP_NAME]: { label: 'group', type: FieldDataType.GROUP_NAME },
  [Task.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [Task.STATUS]: { label: 'status', type: FieldDataType.STATUS },
  [Task.DUE_DATE]: { label: 'due_date', type: FieldDataType.DATE },
  [Task.TITLE]: { label: 'title', type: FieldDataType.DEFAULT },
  [Task.NOTE]: { label: 'note_other', type: FieldDataType.DEFAULT },
  [Task.ASSIGNED_TO_NAME]: { label: 'assigned_to', type: FieldDataType.USER_NAME },
  [Task.TAGS]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [Task.LEAD_NAME]: { label: 'lead_one', type: FieldDataType.USER_NAME },
  [Task.INVENTORY_ITEM_NAME]: { label: 'item', type: FieldDataType.YMMT },
  [Task.ARCHIVED]: { label: 'archived', type: FieldDataType.BOOLEAN },
  [Task.CREATED_BY_NAME]: { label: 'created_by', type: FieldDataType.USER_NAME },
  [Task.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Task.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const TaskSettings = translateFields(TaskConfig);
