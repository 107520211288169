import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { merge } from 'lodash-es';

import LoggingService from 'components/core/logging/LoggingService';
import { DetailsViewExceptions } from 'components/ui/lists/ListItemClickable';
import { SecondaryViewType, SecondaryViewWidth } from 'containers/nestedView/interfaces';
import type { SecondaryListViewProps } from 'containers/nestedView/NestedViewSettings';
import { SecondaryViewsConfig } from 'containers/nestedView/NestedViewSettings';
import type { NestedPaginationState, SecondaryViewItem } from 'contexts/NestedViewContext';
import { NestedViewContext } from 'contexts/NestedViewContext';
import { Pagination } from 'enums/pagination';
import { TabType } from 'enums/tabType';

interface NestedViewProviderProps {
  children: ReactNode;
}

export type SetViewParameters = Pick<SecondaryViewItem, 'title' | 'entityType' | 'queryVars'> & {
  /** The view type */
  viewType: SecondaryViewItem['type'];
  /** Data that will be passed to seededData */
  data?: SecondaryViewItem['seededData'];
  /** Whether this view being set should fully replace viewItems */
  replaceViewItems?: boolean;
  /** Used to override the configuration for this view */
  configOverride?: Pick<SecondaryListViewProps, 'alwaysShowCreateButton'>;
};

export const NestedViewProvider = ({ children }: NestedViewProviderProps) => {
  const [viewItems, setViewItems] = useState<SecondaryViewItem[]>([]);

  const [activeDetailsTab, setActiveDetailsTab] = useState<TabType>();
  const [activeListTab, setActiveListTab] = useState<TabType>();
  const [lastOpenedItem, setLastOpenedItem] = useState();
  const [pagination, setPagination] = useState<NestedPaginationState[]>();
  const [keyword, setKeyword] = useState<string>();

  const isDetailsShown = useMemo(
    () => !!viewItems.some(viewItem => viewItem.type === SecondaryViewType.DETAILS),
    [viewItems]
  );

  const setView = useCallback(
    ({
      entityType,
      viewType,
      title,
      queryVars,
      replaceViewItems: replace = false,
      data,
      configOverride,
    }: SetViewParameters) => {
      if (!queryVars && viewType === SecondaryViewType.LIST) {
        LoggingService.debug({
          message: `No 'primaryEntityTarget' list settings found for entityType: '${entityType}'.
        List queries must specify at least the number of items to fetch e.g. 'first: 100'`,
        });
      }

      let secondaryViewItem;

      if (SecondaryViewsConfig[entityType]?.[viewType]) {
        if (viewType === SecondaryViewType.LIST) {
          /**
           * List configurations may come in as a single configuration object, or an array of configuration objects.
           * In order to make the configurations easier to work with we ensure that arrays will always be used, so if
           * a single config is encountered, we convert it to a single item array.
           */
          const config = {
            tabs: SecondaryViewsConfig[entityType][viewType].tabs?.length
              ? SecondaryViewsConfig[entityType][viewType].tabs?.map(item => ({
                  ...item,
                  queryVars: { ...merge({}, item.queryVars || {}, queryVars), first: Pagination.LIST_LENGTH },
                }))
              : [
                  {
                    ...SecondaryViewsConfig[entityType][viewType],
                    ...configOverride,
                    queryVars: { ...queryVars, first: Pagination.LIST_LENGTH },
                    tabId: TabType.DEFAULT,
                  },
                ],
          };
          secondaryViewItem = {
            title,
            entityType,
            type: viewType,
            width: SecondaryViewWidth.DEFAULT,
            config,
          } as SecondaryViewItem;
        } else if (viewType === SecondaryViewType.DETAILS) {
          secondaryViewItem = {
            title,
            entityType,
            type: viewType,
            width: SecondaryViewsConfig[entityType][viewType]?.width || SecondaryViewWidth.DEFAULT,
            queryVars,
            config: SecondaryViewsConfig[entityType][viewType],
          } as SecondaryViewItem;
        }

        secondaryViewItem.seededData = data;

        if (
          (viewType === SecondaryViewType.DETAILS && isDetailsShown && !DetailsViewExceptions.includes(entityType)) ||
          replace
        ) {
          clearState();
          setViewItems([secondaryViewItem]);
        } else {
          // Push new view item
          viewItems.unshift(secondaryViewItem);
          setViewItems([...viewItems]);
        }
      } else {
        LoggingService.debug({
          message: `No secondary view rendered, config not set up for ${viewType} ${entityType}`,
        });
      }
    },
    [viewItems, setViewItems, isDetailsShown]
  );

  const back = useCallback(() => {
    // Set view items
    setViewItems(viewItems.slice(1));
  }, [viewItems, setViewItems]);

  const close = useCallback(() => {
    viewItems.pop();
    // Set view items
    setViewItems([]);
    clearState();
  }, [viewItems, setViewItems]);

  const clearState = () => {
    // Clear the state of the nested view
    setActiveListTab(undefined);
    setActiveDetailsTab(undefined);
    setLastOpenedItem(undefined);
    setPagination(undefined);
    setKeyword(undefined);
  };

  const updateCurrentView = useCallback(
    <T = any,>(seededData?: T) =>
      setViewItems(previousViewItem => {
        if (!seededData) {
          return [...previousViewItem];
        }

        return previousViewItem.map(props => ({
          ...props,
          seededData,
        }));
      }),
    [setViewItems]
  );

  return (
    <NestedViewContext.Provider
      value={{
        viewItems,
        setView,
        updateCurrentView,
        back,
        close,
        isDetailsShown,
        activeDetailsTab,
        setActiveDetailsTab,
        activeListTab,
        setActiveListTab,
        lastOpenedItem,
        setLastOpenedItem,
        pagination,
        setPagination,
        keyword,
        setKeyword,
      }}
    >
      {children}
    </NestedViewContext.Provider>
  );
};
