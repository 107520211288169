import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  rooftopRetailCashPricingCreate,
  rooftopRetailCashPricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/cashPricing/RooftopCashSegmentCreateModifyQuery';
import RooftopCashSegmentQueryMethods from 'components/sections/createModify/rooftopPricingSegments/cashPricing/RooftopCashSegmentQueryMethods';
import FiltersStep from 'components/sections/createModify/rooftopPricingSegments/cashPricing/steps/FiltersStep';
import { RooftopCashSegmentBuilderSteps } from 'components/sections/createModify/rooftopPricingSegments/cashPricing/steps/interfaces';
import RetailFilterFields from 'components/sections/createModify/shared/steps/RetailFilterFields';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

export const detailStepTooltipConfig = {
  title: 'get_started',
  content: 'cash_pricing_segment_detail_step_tooltip_content',
};

const RooftopCashSegmentBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_CASH_SEGMENT_CREATE]: {
    refetchQueries: RooftopCashSegmentQueryMethods.refetchQueries,
    getMetaData: RooftopCashSegmentQueryMethods.getMetaData,
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopCashSegmentBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopCashSegmentBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        isEnabled: false,
        fields: RetailFilterFields,
      },
    ],
    mutations: {
      create: rooftopRetailCashPricingCreate,
      validate: rooftopRetailCashPricingCreate,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
  [BuilderType.ROOFTOP_CASH_SEGMENT_MODIFY]: {
    refetchQueries: RooftopCashSegmentQueryMethods.refetchQueries,
    getMetaData: RooftopCashSegmentQueryMethods.getMetaData,
    resourceType: ResourceType.ROOFTOPS,
    getData: RooftopCashSegmentQueryMethods.getData,
    steps: [
      {
        id: RooftopCashSegmentBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
      {
        id: RooftopCashSegmentBuilderSteps.FILTERS,
        step: FiltersStep,
        stepName: 'filter_other',
        isEnabled: true,
        fields: RetailFilterFields,
      },
    ],
    mutations: {
      modify: rooftopRetailCashPricingModify,
    },
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
  },
};

export default RooftopCashSegmentBuilders;
