import { gql } from '@apollo/client';

import { monetaryAmount } from './monetaryAmount';
import { MultilingualString } from './multilingualString';

export const retailPricingConfiguration = gql`
  fragment RetailPricingConfigurationFragment on RetailPricingConfiguration {
    id
    enabled
    paymentOption
    paymentOptionName
    startingPriceField
    startingPriceFieldName
    taxRate
    includeTax
    includeFreight
    includeAfterTaxRebates
    minDisplayPrice {
      ...MonetaryAmountFragment
    }
    disclaimerTemplate {
      ...MultilingualStringFragment
    }
    pricingSource
    pricingSourceName
    autoPriceAdjustmentConfiguration {
      targetPriceFieldName
      targetPriceField
      label {
        ...MultilingualStringFragment
      }
      disclaimerTemplate {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
  ${monetaryAmount}
`;
