import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { PasswordBuilderFields } from 'components/sections/createModify/settings/steps/ModifyPasswordFields';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getStepField, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

class ModifyPasswordStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep },
    } = props;

    const {
      subContexts: {
        userContext: {
          user: { id: userId },
        },
      },
    } = context;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [PasswordBuilderFields.ID]: { selectedValue: userId },
    });
  }

  componentDidMount() {
    // Focus on the password field when builder is first opened
    this.setTier({ activeField: PasswordBuilderFields.PASSWORD });
    super.componentDidMount();
  }

  async save() {
    const password1 = getStepField(PasswordBuilderFields.PASSWORD, this.fields).selectedValue;
    const password2 = getStepField(PasswordBuilderFields.PASSWORD_CONFIRM, this.fields).selectedValue;

    if (password1 !== password2) {
      this.setTier({
        errors: [
          {
            message: translate.t('passwords_not_matching_message'),
            extensions: { fields: [PasswordBuilderFields.PASSWORD, PasswordBuilderFields.PASSWORD_CONFIRM] },
          } as ApiError,
        ],
      });
      return false;
    }

    return super.save();
  }
}

export default ModifyPasswordStep;
