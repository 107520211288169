import { gql } from '@apollo/client';

import { mileage } from 'store/api/graph/fragments/mileage';
import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';

import { MultilingualString } from './multilingualString';

export const incentive = gql`
  fragment IncentiveFragment on Incentive {
    id
    disclaimer {
      ...MultilingualStringFragment
    }
    requirements {
      ...MultilingualStringFragment
    }
    label {
      ...MultilingualStringFragment
    }
    fee
    payee
    payeeName
    paymentOptions
    paymentOptionsNames
    enabled
    ending
    starting
    stackableIncentiveIds
    attributes {
      ... on InterestRateIncentiveAttributes {
        interestRates {
          interestRate
          term
        }
      }
      ... on InterestRateDiscountIncentiveAttributes {
        interestRateDiscounts {
          interestRate
          term
        }
      }
      ... on PurchasePriceFixedIncentiveAttributes {
        fixed {
          ...MonetaryAmountFragment
        }
      }
      ... on PurchasePricePercentageIncentiveAttributes {
        percentage
        percentageAsAmount {
          ...MonetaryAmountFragment
        }
      }
      ... on PurchasePriceFixedByTermIncentiveAttributes {
        fixedAmounts {
          amount {
            ...MonetaryAmountFragment
          }
          term
        }
      }
      ... on ResidualRateIncentiveAttributes {
        residualRates {
          mileageAllowance {
            ...MileageFragment
          }
          residualRate
          term
        }
      }
    }
  }

  ${mileage}
  ${monetaryAmount}
  ${MultilingualString}
`;
