import { useCallback, useState } from 'react';

import styled from 'styled-components/macro';

import TertiaryText from 'components/core/typography/TertiaryText';
import Text from 'components/core/typography/Text';
import Checkbox, { CheckboxSizes } from 'components/ui/forms/shared/Checkbox';
import { LINE_HEIGHT_DEFAULT } from 'styles/typography';

const RadioContainer = styled.div`
  display: flex;
  text-align: left;
  margin-bottom: 15px;

  /* Target the checkbox element */
  > :first-child {
    margin-right: 15px;
  }

  ${Text} {
    top: 3px;
  }

  ${TertiaryText} {
    padding-top: 10px;
    white-space: normal;
    line-height: ${LINE_HEIGHT_DEFAULT};
  }
`;

export interface RadioInputSettings {
  // ID of the option
  id: string;
  // Label of the option
  label: string;
  // Sub-label that appears below the main label
  subLabel?: string;
}

interface RadioInputProps {
  /** Settings for each option */
  settings: RadioInputSettings[];
  /** The default option selected */
  defaultValue?: string;
  /** Callback for when a new option is selected, returns the ID of the selected option, or null if none are selected */
  onChange?: (e: Record<'currentTarget', { value: string | null }>) => void;
  /** Testing ID to reference this step field input */
  testId?: string;
}

const RadioInput = ({ defaultValue, settings, onChange, testId, ...props }: RadioInputProps) => {
  const [checkedOption, setCheckedOption] = useState<string | null>(defaultValue || null);

  const onChangeCheck = useCallback(
    (value, id) => {
      const checkedValue = value ? id : null;
      setCheckedOption(checkedValue);
      onChange?.({ currentTarget: { value: checkedValue } });
    },
    [setCheckedOption, onChange]
  );

  return (
    <>
      {settings.map(({ id, label, subLabel }) => (
        <RadioContainer key={id} {...props}>
          <Checkbox
            round
            size={CheckboxSizes.LARGE}
            checked={checkedOption === id}
            onChange={checked => onChangeCheck(checked, id)}
            testId={testId}
          />
          <div>
            <Text>{label}</Text>
            <TertiaryText>{subLabel}</TertiaryText>
          </div>
        </RadioContainer>
      ))}
    </>
  );
};

export default RadioInput;
