import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { TradeInItemCaptureVinDecodeImplementer } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/implementers/TradeInItemCaptureVinDecode';
import { TrimSelectionFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { StepFieldType } from 'enums/stepFieldType';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import {
  getOptionsFromStepField,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

class CaptureVinStep extends StepComponentCore {
  static contextType = CreateModifyContext;
  selectedTrim: StepField;

  vinDecoder: TradeInItemCaptureVinDecodeImplementer;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { metadata, activeStep },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
      },
    } = context;

    this.context = context;

    this.vinDecoder = new TradeInItemCaptureVinDecodeImplementer(this);

    this.selectedTrim = {
      label: translate.t('trim'),
      groupType: StepFieldType.DROPDOWN,
      queryVar: TrimSelectionFields.DECODED_TRIM_ID,
      subStep: [SubStepType.DEFAULT],
      options: [],
    };

    const currentType = metadata.type || InventoryItemType.VEHICLE;
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      type: {
        selectedValue: currentType,
      },
      rooftopId: {
        selectedValue: getUsersRooftop(user),
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops }),
      },
    });

    // Async subpanel configurations
    this.asyncConfigurations = {
      rooftopId: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
    };
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === TrimSelectionFields.DECODED_TRIM_ID) {
      this.selectedTrim.selectedValue = value;
      this.forceUpdate();
    } else {
      super.onFieldSelection(stepField, value);
    }
  }

  /** Overriding toggleSubPanel */
  async toggleSubPanel(stepField?: StepField) {
    const {
      tier: { metadata },
    } = this.props;
    const isTrimSelectionRequired =
      stepField && stepField.queryVar === 'vin' && getOptionsFromStepField(this.selectedTrim, metadata).length > 0;

    if (isTrimSelectionRequired) {
      stepField.active = true;
      this.setState({
        currentStepField: this.selectedTrim,
        childrenBeforeSubStep: this.vinDecoder.renderTrimWarning(),
      });
    } else {
      void super.toggleSubPanel(stepField);
      this.setState({ childrenBeforeSubStep: undefined });
    }
    if (!stepField || stepField.queryVar !== 'vin') {
      // Manual override to disable vin active
      getStepField('vin', this.fields).active = false;
      this.setTier({
        alert: undefined,
      });
    }
  }

  // Overriding field change
  onFieldChange(stepField: StepField, e) {
    // Clear trims if the vin field changes its user input value
    if (
      stepField.queryVar === 'vin' &&
      getOptionsFromStepField(this.selectedTrim, this.props.tier.metadata).length > 0
    ) {
      this.vinDecoder.clearTrimSubStep();
      this.setState({ currentStepField: stepField });
      this.setTier({
        alert: undefined,
      });
    } else if (stepField.queryVar === 'type') {
      this.vinDecoder.clearTrimSubStep();
    }
    super.onFieldChange(stepField, e, true);
  }

  async save(): Promise<boolean> {
    if (!this.validateFields()) {
      return false;
    }

    const {
      tier: { data },
    } = this.props;

    const {
      subContexts: {
        userContext: { user },
      },
    } = this.context!;

    const rooftopValue = data?.rooftop || user.rooftops?.[0];
    const vin = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields).selectedValue;

    const decodedVin = await this.vinDecoder.decodeVin({ rooftop: rooftopValue });

    if (decodedVin === null) {
      return false;
    } else {
      const carfaxReportResponse = await this.vinDecoder.getCarfaxReport(vin, rooftopValue);
      this.vinDecoder.saveVinDecodedDataAndNavigateNextStep(decodedVin, carfaxReportResponse.carfaxReport ?? null);
      return true;
    }
  }
}

export default CaptureVinStep;
