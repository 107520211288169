// Line-height
/** line-height: 16px */
export const LINE_HEIGHT_1 = '1rem';
/** line-height: 20px */
export const LINE_HEIGHT_2 = '1.25rem';
/** line-height: 24px */
export const LINE_HEIGHT_3 = '1.5rem';
/** line-height: 32px */
export const LINE_HEIGHT_4 = '2rem';
/** line-height: 40px */
export const LINE_HEIGHT_5 = '2.5rem';
/** line-height: 48px */
export const LINE_HEIGHT_6 = '3rem';
