import { gql } from '@apollo/client';

import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const calculateFinancePaymentQuery = gql`
  query CalculateFinancePayment(
    $interestRate: BigDecimal!
    $term: Int!
    $taxRate: BigDecimal
    $paymentFrequency: PaymentFrequency!
    $purchasePrice: Int!
    $disclaimerTemplate: MultilingualStringInput!
    $freight: Int! = 0
    $additionalFixedAmount: Int! = 0
    $pricingSource: PricingSource! = CUSTOM
    $ending: Date
  ) {
    calculateFinancePayment(
      interestRate: $interestRate
      term: $term
      taxRate: $taxRate
      paymentFrequency: $paymentFrequency
      purchasePrice: $purchasePrice
      disclaimerTemplate: $disclaimerTemplate
      freight: $freight
      additionalFixedAmount: $additionalFixedAmount
      pricingSource: $pricingSource
      ending: $ending
    ) {
      payment {
        ...MonetaryAmountFragment
      }
      paymentFrequency
      paymentFrequencyName
      totalObligation {
        ...MonetaryAmountFragment
      }
      borrowingCost {
        ...MonetaryAmountFragment
      }
      effectiveAnnualRate
      disclaimer {
        ...MultilingualStringFragment
      }
      taxAmount {
        ...MonetaryAmountFragment
      }
    }
  }
  ${monetaryAmount}
  ${MultilingualString}
`;
