import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { AnyExtension, Range } from '@tiptap/core';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Color } from '@tiptap/extension-color';
import { Document } from '@tiptap/extension-document';
import { Heading } from '@tiptap/extension-heading';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Text } from '@tiptap/extension-text';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';
import type { EditorEvents } from '@tiptap/react';
import { useEditor } from '@tiptap/react';
import { isEqual } from 'lodash-es';
import { useUpdateEffect } from 'react-use';

import LoggingService from 'components/core/logging/LoggingService';
import { getSelectionRange } from 'components/ui/editor/helpers/selection';
import { getSupportedEditorLanguages } from 'components/ui/editor/helpers/utils';
import { LinkHighlight } from 'components/ui/editor/marks/linkHighlight';
import LanguageToggle from 'components/ui/editor/plugins/language';
import { RichTextClientType, RichTextEditorContext } from 'contexts/RichTextEditorContext';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { MultilingualStringValue } from 'utils/intlUtils';

const baseExtensions: AnyExtension[] = [Document, History, Paragraph, Text];

export const richTextEditorExtensions: AnyExtension[] = [
  ...baseExtensions,
  Bold,
  BulletList,
  Color,
  Heading.configure({ levels: [1, 2, 3, 4] }),
  Italic,
  Link.extend({ inclusive: false }).configure({ openOnClick: false }),
  LinkHighlight,
  ListItem,
  OrderedList,
  TextStyle,
  Underline,
];

const plainTextEditorExtensions: AnyExtension[] = [...baseExtensions];

const defaultMultilingualStringContent: MultilingualString = {
  __typename: 'MultilingualString',
  value: '',
};

interface Props {
  /**
   * List of available languages the user can select from
   */
  availableLanguages: MultilingualStringValue[];
  /**
   * The children of the component.
   */
  children: ReactNode;
  /**
   * The default content of the editor.
   */
  defaultContent: MultilingualString | null;
  /**
   * The default language of the editor.
   */
  defaultLanguage?: MultilingualStringValue;
  /**
   * Whether or not the editor should return the value as a string instead of a html
   */
  isPlainText?: boolean;
  /**
   * Callback run when the editor updates.
   */
  onEditorUpdate?: (value: MultilingualString | null) => void;
  /**
   * Callback run when text generation button is clicked. The return value is expected to be generated text.
   */
  onGenerateText?: (language: MultilingualStringValue) => Promise<string>;
  /**
   * Callback run when the language changes.
   */
  onLanguageChange?: (language: MultilingualStringValue) => void;
  /**
   * Placeholder text for the editor.
   */
  placeholder?: string;
  /**
   * List of languages to enable text generation.
   */
  textGenerationEnabledLanguages?: MultilingualStringValue[];
}

const RichTextEditorProvider = ({
  availableLanguages,
  children,
  defaultContent = null,
  defaultLanguage = MultilingualStringValue.EN,
  isPlainText = false,
  onEditorUpdate,
  onGenerateText,
  onLanguageChange,
  placeholder,
  textGenerationEnabledLanguages,
}: Props) => {
  const supportedLanguages = useMemo(() => getSupportedEditorLanguages(availableLanguages), [availableLanguages]);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const shouldUpdateEditor = useRef<boolean>(true);
  const shouldTriggerOnEditorUpdate = useRef<boolean>(false);

  const [language, setLanguage] = useState<MultilingualStringValue>(defaultLanguage);

  const [multilingualStringContent, setMultilingualStringContent] = useState<MultilingualString>(
    defaultContent ?? defaultMultilingualStringContent
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);
  const [clientType, setClientType] = useState<RichTextClientType>(RichTextClientType.WEB);
  const [, setLinkSelectedRange] = useState<Range>({ from: 0, to: 0 });

  const shouldCancelTextGeneration = useRef<boolean>(false);

  const isTextGenerationButtonVisible = useMemo(() => !!onGenerateText, [onGenerateText]);
  const isTextGenerationButtonDisabled = useMemo(
    () => !textGenerationEnabledLanguages?.includes(language) || isGeneratingText,
    [isGeneratingText, language, textGenerationEnabledLanguages]
  );
  const contentValue = useMemo(
    () => multilingualStringContent?.[language] || '',
    [multilingualStringContent, language]
  );

  /**
   * Returns the content of the editor.
   */
  const getContent = useCallback(() => multilingualStringContent, [multilingualStringContent]);

  /**
   * Handles the formatting of the editor content.
   */
  const setContentValue = useCallback(
    (value: string, language: MultilingualStringValue) =>
      setMultilingualStringContent(previousContent => {
        const isLanguageSupported = supportedLanguages.includes(language);
        if (!previousContent || !isLanguageSupported) {
          return previousContent;
        }

        const updatedContent = { ...previousContent, [language]: value, value };
        if (isEqual(updatedContent, previousContent)) {
          return previousContent;
        }

        // Trigger `onEditorUpdate` callback
        shouldTriggerOnEditorUpdate.current = true;

        return updatedContent;
      }),
    [supportedLanguages]
  );

  /**
   * Handles the editor update, update the content state with the new value.
   */
  const handleEditorUpdate = useCallback(
    ({ editor }: EditorEvents['update']) =>
      setContentValue(isPlainText ? editor.getText() : editor.getHTML(), editor.storage.language.value),
    [isPlainText, setContentValue]
  );

  const editor = useEditor({
    content: contentValue,
    extensions: [
      LanguageToggle.configure({ defaultLanguage }),
      ...(isPlainText ? plainTextEditorExtensions : richTextEditorExtensions),
      !!placeholder && Placeholder.configure({ placeholder }),
    ].filter(Boolean),
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
  });

  const setContent = useCallback((value: MultilingualString | null) => {
    setMultilingualStringContent(() => value ?? defaultMultilingualStringContent);
  }, []);

  /**
   * Handles the editor update, update the content state with the new value.
   */
  useEffect(() => {
    editor.on('update', handleEditorUpdate);

    return () => {
      editor.destroy();
    };
  }, [editor, handleEditorUpdate]);

  /**
   * Update the content when the language changes.
   */
  useUpdateEffect(() => {
    setMultilingualStringContent(previousContent => {
      // We should update editor with new content value when the language changed
      shouldUpdateEditor.current = true;
      shouldTriggerOnEditorUpdate.current = true;

      const languageContentValue = previousContent[language] || '';
      return { ...previousContent, value: languageContentValue };
    });

    // Update the language in the editor
    editor?.commands.setLanguage(language);

    // Trigger callback when language changed
    onLanguageChange?.(language);
  }, [editor, language, onLanguageChange]);

  /**
   * Handles the editor update, update the content state with the new value.
   */
  useUpdateEffect(() => {
    if (shouldUpdateEditor.current) {
      editor?.commands.setContent(multilingualStringContent.value, false);
    }

    if (shouldTriggerOnEditorUpdate.current) {
      /**
       * Adding `setTimeout` here to remove the following warning.
       *
       * Warning: flushSync was called from inside a lifecycle method. React cannot flush when
       * React is already rendering. Consider moving this call to a scheduler task or micro task.
       *
       * @link https://github.com/ueberdosis/tiptap/issues/3764
       */
      setTimeout(() => {
        onEditorUpdate?.(multilingualStringContent);
      });
    }

    shouldUpdateEditor.current = false;
    shouldTriggerOnEditorUpdate.current = false;
  }, [editor, multilingualStringContent, onEditorUpdate]);

  /**
   * Handles the text generation callback
   */
  const handleGenerateText = useCallback(
    () =>
      onGenerateText?.(language)
        .then((generatedText: string) => {
          if (shouldCancelTextGeneration.current) {
            shouldCancelTextGeneration.current = false;
            return;
          }

          // We should update editor with new generated content
          shouldUpdateEditor.current = true;

          setContentValue(generatedText, language);
        })
        .catch((error: Error) => {
          LoggingService.captureException(error, {
            info: 'Error generating text',
            scope: 'generate-text',
          });
        }),
    [language, onGenerateText, setContentValue]
  );

  /**
   * Handles the text generation cancel callback
   */
  const handleGenerateTextCancel = useCallback(() => {
    shouldCancelTextGeneration.current = true;
    setIsGeneratingText(false);
  }, []);

  /**
   * Handles the opening of the link dialog
   */
  const openEditLinkDialog = useCallback(() => {
    setLinkSelectedRange(() => {
      editor?.commands.setLinkHighlight();

      return getSelectionRange(editor?.state.selection);
    });

    return setIsLinkDialogOpen(true);
  }, [editor?.commands, editor?.state.selection]);

  /**
   * Handles the closing of the link dialog
   */
  const closeEditLinkDialog = useCallback(() => {
    setLinkSelectedRange(previousRange => {
      editor?.commands.setTextSelection(previousRange);
      editor?.commands.unsetLinkHighlight();

      return { from: 0, to: 0 };
    });

    return setIsLinkDialogOpen(false);
  }, [editor?.commands]);

  return (
    <RichTextEditorContext.Provider
      value={{
        clientType,
        closeEditLinkDialog,
        contentRef,
        editor,
        generateText: handleGenerateText,
        generateTextCancel: handleGenerateTextCancel,
        getContent,
        isGeneratingText,
        isLinkDialogOpen,
        isLoading,
        isTextGenerationButtonDisabled,
        isTextGenerationButtonVisible,
        language,
        openEditLinkDialog,
        setClientType,
        setContent,
        setIsGeneratingText,
        setIsLoading,
        setLanguage,
        supportedLanguages,
      }}
    >
      {children}
    </RichTextEditorContext.Provider>
  );
};

export default RichTextEditorProvider;
