import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const disclaimerRetailBulkAdjustmentAttributes = gql`
  fragment DisclaimerRetailBulkAdjustmentAttributesFragment on DisclaimerRetailBulkAdjustmentAttributes {
    disclaimerTemplate {
      ...MultilingualStringFragment
    }
  }

  ${MultilingualString}
`;
