import { memo, type SVGProps } from 'react';

import { NEUTRAL_700 } from 'styles/tokens';

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill={props?.color || NEUTRAL_700}
      d="M5.066 18.928h1.43l9.37-9.368-1.42-1.425-9.38 9.38v1.413Zm-1.132 2.275a1.1 1.1 0 0 1-.808-.329 1.1 1.1 0 0 1-.33-.808v-2.557a2.248 2.248 0 0 1 .66-1.597l12.529-12.51c.208-.196.439-.345.692-.45.254-.103.52-.155.799-.155.274 0 .543.052.805.156.261.104.492.26.691.468l1.632 1.639c.208.195.361.425.459.689.097.264.146.529.146.795 0 .278-.049.545-.146.8-.098.256-.25.488-.459.696L8.1 20.545a2.245 2.245 0 0 1-1.597.659H3.934Zm11.212-12.35-.7-.718 1.42 1.425-.72-.706Z"
    />
  </svg>
);
export default memo(EditIcon);
