import type { DocumentNode } from 'graphql';

import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

export interface CreditApplicationQueryVariables {
  id: string;
  leadId: string;
}

/** Returns a list of credit applications from the given lead. */
export const useCreditApplicationQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const { data, ...queryResult } = useQuery(query, {
    ...config,
    ignore: (config?.variables as CreditApplicationQueryVariables)?.leadId === undefined,
  });
  const creditApplications = data?.connection?.creditApplications || [];

  return {
    ...queryResult,
    data: {
      connection: {
        edges: creditApplications,
        pageInfo: {
          endCursor: creditApplications.length.toString(),
          hasNextPage: false,
          hasPreviousPage: false,
          totalEdges: creditApplications.length,
          startCursor: '1',
        },
      },
      metadata: data?.metadata,
    },
  };
};
