import type { ComponentProps } from 'react';

import styled from 'styled-components/macro';

import ClickableInput from 'components/core/createModify/stepFields/fields/ClickableInput';
import InputTextValue from 'components/core/createModify/stepFields/fields/InputTextValue';
import { multilineCss } from 'components/core/typography/MultiLineText';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';

const ClickableContainer = styled(ClickableInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px 10px 15px;

  ${InputTextValue} {
    ${multilineCss}
  }

  svg {
    margin-left: auto;
  }
`;

interface DropDownInputProps extends ComponentProps<typeof ClickableContainer> {
  defaultValue: string;
  isActive?: boolean;
}

const DropDownInput = ({ 'data-testid': dataTestId, defaultValue, disabled, isActive = false }: DropDownInputProps) => (
  <ClickableContainer data-testid={dataTestId} disabled={disabled}>
    <InputTextValue>{defaultValue}</InputTextValue>
    <ChevronRightIcon color={isActive ? BLUE_500 : BODY_TEXT} />
  </ClickableContainer>
);

export default DropDownInput;
