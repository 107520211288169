import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import styled, { css } from 'styled-components/macro';

import Editor from 'components/ui/editor/components/core/Editor';
import { GlobalStyle } from 'components/ui/editor/constants/styles';
import type { RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';
import type { ElementTestId } from 'enums/testing';
import RichTextEditorProvider from 'providers/RichTextEditorProvider';
import { BODY_TEXT_TERTIARY } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_050, RED_500 } from 'styles/tokens';
import { MultilingualStringValue } from 'utils/intlUtils';

const Container = styled(GlobalStyle)<{ isActive?: boolean; isInvalid: boolean }>`
  background-color: ${NEUTRAL_0};
  border-radius: 8px;
  border: 1px solid ${BODY_TEXT_TERTIARY};
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;

  ${props =>
    props.isActive !== undefined &&
    css`
      background-color: ${props.isActive ? NEUTRAL_0 : NEUTRAL_050};
      border-color: ${props.isActive ? BLUE_500 : BODY_TEXT_TERTIARY};
    `}
  ${props =>
    props.isInvalid &&
    css`
      border-color: ${RED_500};
    `}
`;

interface Props extends ComponentProps<typeof RichTextEditorProvider> {
  /** Flag for when this field has focus in the builder */
  isActive?: boolean;
  /** Flag for when this field has an error in the builder */
  isInvalid?: boolean;
  /** Testing id for this component */
  testId?: ElementTestId;
}

const RichTextEditorContainer = forwardRef<RichTextEditorContextInterface, Props>(
  ({ children, defaultLanguage = MultilingualStringValue.EN, isActive, isInvalid, testId, ...props }, ref) => (
    <Container data-testid={testId} isActive={isActive} isInvalid={!!isInvalid}>
      <RichTextEditorProvider defaultLanguage={defaultLanguage} {...props}>
        <Editor ref={ref}>{children}</Editor>
      </RichTextEditorProvider>
    </Container>
  )
);

export default RichTextEditorContainer;
