import { chunk, get, isEmpty, isNil, set, uniqWith } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RooftopLeaseSegmentRatesBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import {
  setChromeRatesTooltip,
  transformAvailableMileageAllowanceOptions,
  transformSavedAvailableMileageAllowanceOptions,
} from 'components/sections/createModify/rooftopPricingSegments/utils';
import { getPricingSourceOptions } from 'components/sections/shared/ItemMetaHelpers';
import { RooftopLeasePricingSegment, RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { StepFieldType } from 'enums/stepFieldType';
import type {
  InterestRateEntry,
  InterestRateEntryInput,
  MileageInput,
  ResidualRateEntry,
  ResidualRateEntryInput,
  RooftopLeasePricingSegmentMetaQuery,
  RooftopRetailLeasePricingDetailQuery,
  SelectIntOptionFragment,
  SelectStringOptionFragment,
} from 'store/api/graph/interfaces/types';
import {
  DefaultMileageAllowanceType,
  DefaultTermType,
  InterestRateType,
  LeaseRetailPricingConfigurationInputParameter,
  PricingSource,
  ResidualRateType,
} from 'store/api/graph/interfaces/types';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { getStepField, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import {
  getInterestRateQueryAlias,
  getInterestRateQueryVar,
  getMileageRateQueryVar,
  getNestedConfigurationField,
  getResidualRateQueryAlias,
} from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

class RatesStep extends StepComponentCore {
  /*
   * This is the number of non-dynamic static fields. Any dynamically generated fields will be below the static fields,
   * so this value represents the length of the static fields, and the array index in which dynamic fields should be
   * inserted after.
   */
  staticFieldsLength: number = [
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
    RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE,
  ].length;

  constructor(props: StepComponentProps, context) {
    super(props);
    const {
      tier: { isCreating, data, activeStep, formData, metadata, seededData },
    } = props;
    const {
      subContexts: { featureFlags },
    } = context;

    // The list of available term options come from metadata
    const {
      rooftopRetailPricing: {
        leaseConfiguration: {
          availableTerms: availableTermOptions,
          interestRateType: interestRateTypeOptions,
          defaultTermType: defaultTermTypeOptions,
          defaultMileageAllowanceType: defaultMileageAllowanceTypeOptions,
          defaultMileageAllowances: standardMileageAllowanceOptions,
          pricingSource: pricingSourceOptions,
        },
      },
    } = metadata.mutation;

    /*
     * If data is null or empty object, then use the formData from the previous step
     */
    const configurationData =
      Object.keys(data || {}).length > 0
        ? get(data, RooftopPricingSegment.CONFIGURATION)
        : formData?.leaseConfiguration;

    const transformedAvailableMileageAllowanceOptions = standardMileageAllowanceOptions
      .map(option => transformAvailableMileageAllowanceOptions(option))
      .filter(Boolean);

    const transformedSavedAvailableMileageAllowanceOptions = (configurationData?.availableMileageAllowances || []).map(
      option => transformSavedAvailableMileageAllowanceOptions(option)
    );

    const availableMileageAllowanceOptions: SelectIntOptionFragment[] = uniqWith(
      // Combine the default mileage allowance options, with the ones already saved by the user
      [...transformedAvailableMileageAllowanceOptions, ...transformedSavedAvailableMileageAllowanceOptions],
      // If there are any duplicated options, remove them
      (a, b) => Number(a.id) === Number(b.id) && a.data === b.data
    ).sort((a, b) => Number(a.id) - Number(b.id));

    // Term options are Ints, but the Badge rendering expects a string
    const defaultTermOptions = availableTermOptions.filter(option =>
      configurationData?.availableTerms?.includes(option.id)
    );

    const defaultTerm = availableTermOptions.find(option => option.id === configurationData?.defaultTerm);

    // The default mileage allowance options come from the current list of available mileage allowances
    const defaultMileageAllowanceOptions = availableMileageAllowanceOptions.filter(option =>
      configurationData?.availableMileageAllowances?.some(
        mileageAllowance => Number(mileageAllowance.amount) === Number(option.id)
      )
    );
    const defaultMileageAllowance = defaultMileageAllowanceOptions.find(option =>
      isCreating
        ? Number(option.id) === Number(configurationData?.defaultMileageAllowance)
        : Number(option.id) === Number(configurationData?.defaultMileageAllowance?.amount)
    );

    const rooftop = data?.rooftopName || seededData?.rooftopName;
    const pricingSourceOptionsFiltered = getPricingSourceOptions(
      rooftop?.bundle?.features?.oem,
      pricingSourceOptions,
      featureFlags?.[LDFeatureFlags.oemPricingSourcesEnabled]
    );

    const hasPricingSource = configurationData?.pricingSource !== undefined;
    const isCustomSource = configurationData?.pricingSource === PricingSource.CUSTOM;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE]: {
        selectedValue: pricingSourceOptions.find(
          option => option.id === get(data, RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE)
        ),
        options: pricingSourceOptionsFiltered,
        hasSeparator: !hasPricingSource || isCustomSource,
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES, false),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !hasPricingSource || isCustomSource,
          },
        ]),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES]: {
        options: availableMileageAllowanceOptions as StepFieldOptions[],
        selectedValue: defaultMileageAllowanceOptions,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isCustomSource,
        }),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE]: {
        options: defaultMileageAllowanceOptions as StepFieldOptions[],
        selectedValue: defaultMileageAllowance,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !defaultMileageAllowance },
          { type: StepFieldDisplayType.HIDDEN, active: !isCustomSource },
        ]),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS]: {
        selectedValue: availableTermOptions.filter(option => configurationData?.availableTerms?.includes(option.id)),
        options: availableTermOptions,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isCustomSource,
        }),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM]: {
        selectedValue: defaultTerm,
        options: defaultTermOptions,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !defaultTerm },
          { type: StepFieldDisplayType.HIDDEN, active: !isCustomSource },
        ]),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE]: {
        selectedValue: interestRateTypeOptions.find(option => option.id === configurationData?.interestRateType),
        // Hide interest rate type if pricing source is custom
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isNil(configurationData?.pricingSource) || isCustomSource,
        }),
        options: interestRateTypeOptions.filter(option => option.id !== InterestRateType.CUSTOM_RATES),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE]: {
        selectedValue: defaultTermTypeOptions.find(option => option.id === configurationData?.defaultTermType),
        // Hide default term type if pricing source is custom
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isNil(configurationData?.pricingSource) || isCustomSource,
        }),
        options: defaultTermTypeOptions.filter(option => option.id !== DefaultTermType.CUSTOM),
      },
      [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE]: {
        selectedValue: defaultMileageAllowanceTypeOptions.find(
          option => option.id === configurationData?.defaultMileageAllowanceType
        ),
        // Hide default term type if pricing source is custom
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isNil(configurationData?.pricingSource) || isCustomSource,
        }),
        options: defaultMileageAllowanceTypeOptions.filter(option => option.id !== DefaultMileageAllowanceType.CUSTOM),
      },
    });

    this.generateRateFields();
    setChromeRatesTooltip(
      getStepField(RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE, this.fields).selectedValue
        ?.id,
      activeStep
    );
  }

  onItemAdd(seedText) {
    const {
      tier: { currentStepField },
      tier: tierData,
    } = this.props;

    if (!currentStepField?.subStepAddConfig) {
      return;
    }

    const { builderTitle, builderType, seedPrefillMethod, entityType } = currentStepField.subStepAddConfig;
    const { toggleTier } = this.context!;

    toggleTier(CreateModifyTiers.TIER_1, {
      tierId: CreateModifyTiers.TIER_1,
      type: builderType,
      title: translate.t(builderTitle),
      isCreating: true,
      entityType,
      seededData: seedPrefillMethod?.(seedText, tierData),
      onStepSave: async (_, data: any) => {
        // Update the list of available mileage options
        const availableMileageAllowanceField = getStepField(
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
          this.fields
        );
        const availableMileageAllowanceFieldOptions = (availableMileageAllowanceField?.options ||
          []) as StepFieldOptions[];

        /*
         * Update the list of available mileage options, but remove duplicates in case the user added
         * one that already exists
         */
        availableMileageAllowanceField.options = uniqWith(
          [...availableMileageAllowanceFieldOptions, data as StepFieldOptions],
          (a, b) => Number(a.id) === Number(b.id)
        ).sort((a, b) => Number(a.id) - Number(b.id));

        // Pre-check the new available mileage option that was just created
        currentStepField.seededValues = [{ ...data, focus: true }];
        this.forceUpdate();
      },
    });
  }

  generateRateFields() {
    const {
      tier: { isCreating, formData, data },
    } = this.props;

    // If the pricing source is not CUSTOM, then these rate fields will be hidden
    const isPricingSourceCustom =
      getStepField(RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE, this.fields).selectedValue
        ?.id === PricingSource.CUSTOM;

    // Get the available terms the user has selected, or if modifying, get the saved num of terms
    const availableTerms = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS,
      this.fields
    ).selectedValue;

    // If modifying existing rates, get the saved residual rates and interest rates
    const residualRates = isCreating
      ? formData?.leaseConfiguration?.residualRates
      : (get(data, RooftopLeasePricingSegment.CONFIGURATION_RESIDUAL_RATES) as ResidualRateEntry[]);
    const interestRates = isCreating
      ? formData?.leaseConfiguration?.interestRates
      : (get(data, RooftopLeasePricingSegment.CONFIGURATION_INTEREST_RATES) as InterestRateEntry[]);

    // Get the available mileage allowances the user has selected
    const availableMileageAllowance = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
      this.fields
    ).selectedValue;

    // If there is no available mileage allowance, or there are no terms, then there are no rate fields to generate
    if (!availableMileageAllowance?.length || !availableTerms?.length) {
      this.fields = this.fields.slice(0, this.staticFieldsLength);
      this.forceUpdate();
      return;
    }

    /*
     * All the fields in this step are dynamically generated by iterating through each available term. Each term
     * will have a header, followed by a field for the interest rate, and then a field to capture the residual rate
     * for each mileage allowance
     */
    let residualRateIndex = 0; // Count of the residual rate fields, this is used in the query alias of each field

    const rateFields = availableTerms
      ?.map((term, termIndex) => {
        // Generate each residual rate input field by iterating through the available mileage allowances
        const availableMileageFields = availableMileageAllowance?.map((mileageAllowance, index) => {
          // See if this field has already been dynamically generated. If so, we'll need to use its selectedValue
          const existingField = getStepField(`${term.id}-${mileageAllowance.name}`, this.fields);
          return {
            // Each field requires a queryVar as a unique ID, there is nothing special about this value
            queryVar: getMileageRateQueryVar(term.id, mileageAllowance.name),
            label: `${mileageAllowance.name} ${t('residual_rate')}`,
            queryAlias: [getResidualRateQueryAlias(residualRateIndex++)],
            // Each field is OMITTED, as the save() method in this class will dynamically generate the payload data
            displayType: setDisplayTypes([
              { type: StepFieldDisplayType.HIDDEN, active: !isPricingSourceCustom },
              { type: StepFieldDisplayType.OMITTED, active: true },
            ]),
            groupType: StepFieldType.PERCENTAGE,
            /*
             * If this field has existed before, then we need to use its previous value, if not we can attempt to use
             * any previously saved data for modifying.
             */
            selectedValue: isEmpty(existingField)
              ? residualRates?.find(
                  residualRate =>
                    Number(residualRate.term) === Number(term.id) &&
                    Number(residualRate.mileageAllowance.amount) === Number(mileageAllowance.id)
                )?.residualRate
              : existingField.selectedValue,
            // Last dynamic field should have a separator
            hasSeparator: index === availableMileageAllowance?.length - 1,
          };
        });
        // See if this interest rate field has been generated before, if so we'll need to use the fields current value
        const existingField = getStepField(getInterestRateQueryVar(term.id), this.fields);
        return [
          // This is the header for this term
          {
            queryVar: term.id.toString(),
            label: `${term.id} ${t('month_term')}`,
            queryAlias: [getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_INTEREST_RATES)],
            groupType: StepFieldType.PLAIN_TEXT,
            displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isPricingSourceCustom }),
          },
          // This is the input for the interest rate
          {
            // Each field requires a queryVar as a unique ID, there is nothing special about this value
            queryVar: getInterestRateQueryVar(term.id),
            label: t('interest_rate'),
            queryAlias: [getInterestRateQueryAlias(termIndex)],
            // Field is OMITTED, as the save() method in this class will dynamically generate the payload data

            displayType: setDisplayTypes([
              { type: StepFieldDisplayType.HIDDEN, active: !isPricingSourceCustom },
              { type: StepFieldDisplayType.OMITTED, active: true },
            ]),
            groupType: StepFieldType.PERCENTAGE,
            /*
             * If this field has existed before, then we need to use its previous value, if not we can attempt to use
             * any previously saved data for modifying.
             */
            selectedValue: isEmpty(existingField)
              ? interestRates?.find(interestRate => Number(interestRate.term) === Number(term.id))?.interestRate
              : existingField.selectedValue,
          },
          // These are the inputs for capturing the residual rates for each available mileage allowance
          ...availableMileageFields,
        ] as StepField[];
      })
      .flat();

    this.fields = [...this.fields.slice(0, this.staticFieldsLength), ...rateFields];
    this.forceUpdate();
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE) {
      const prevInterestRateTypeFieldValue = stepField.selectedValue?.id;
      const pricingSource = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE,
        this.fields
      );
      // Prevent unsetting interest rate type when pricing source is chrome
      if (pricingSource.selectedValue?.id === PricingSource.CHROME && prevInterestRateTypeFieldValue === value?.id) {
        return;
      }
    }

    super.onFieldSelection(stepField, value);
    /**
     * Whenever the list of available mileage allowances change, we must update the list of options that the user
     * can select for the default mileage allowance. Same goes for available terms.
     */
    if (stepField.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES) {
      const defaultMileageAllowanceField = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE,
        this.fields
      );
      defaultMileageAllowanceField.options = value;

      // Enable the default mileage allowance field if there are options available
      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !value?.length }, defaultMileageAllowanceField);

      // If the default mileage allowance is no longer in the available mileage allowances, then clear the field
      if (!value.some(item => item.id === defaultMileageAllowanceField.selectedValue?.id)) {
        defaultMileageAllowanceField.selectedValue = null;
      }

      // Whenever the available mileage allowance selection changes, we need to re-generate the rate fields
      this.generateRateFields();
    }

    if (stepField.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS) {
      const defaultTermField = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM,
        this.fields
      );
      defaultTermField.options = value;

      // Enable the default term field if there are options available
      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !value?.length }, defaultTermField);

      // If the default term is no longer in the available terms, then clear the field
      if (!value.some(item => item.id === defaultTermField.selectedValue?.id)) {
        defaultTermField.selectedValue = null;
      }

      // Whenever the available terms selection changes, we need to re-generate the rate fields
      this.generateRateFields();
    }

    if (stepField.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE) {
      this.onPricingSourceChanged(value.id as PricingSource);
    }
  }

  onPricingSourceChanged(value: PricingSource) {
    setChromeRatesTooltip(value, this.props.tier.activeStep);

    const pricingSourceField = getStepField<
      SelectStringOptionFragment,
      RooftopRetailLeasePricingDetailQuery['item'],
      RooftopLeasePricingSegmentMetaQuery['metadata']
    >(RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE, this.fields);

    const hasPricingSource = pricingSourceField?.selectedValue?.id !== undefined;
    const isCustomSource = pricingSourceField?.selectedValue?.id === PricingSource.CUSTOM;

    for (const field of this.fields) {
      if (
        [
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE,
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE,
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE,
        ].includes(field.queryVar as RooftopLeaseSegmentRatesBuilderFields)
      ) {
        // If the pricing source is a custom one, then hide the incentive related fields
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: isCustomSource }, field);
      } else if (
        ![
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE,
          RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATE_TYPE,
        ].includes(field.queryVar as RooftopLeaseSegmentRatesBuilderFields)
      ) {
        // If the pricing is not a custom one, then hide the dynamically generated term and interest rate fields
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isCustomSource }, field);
      }

      if (field.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE) {
        field.hasSeparator = !hasPricingSource || isCustomSource;
      }

      if (field.queryVar === RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES) {
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !hasPricingSource || isCustomSource }, field);
      }
    }

    /*
     * When modifying, if the original pricing source was CUSTOM, then the default term type and interest rate type
     * fields would have a CUSTOM value, however this value is hidden from the user, so these fields will be cleared
     * allowing the user to select a new option.
     */
    if (value === PricingSource.CHROME) {
      const defaultTermField = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE,
        this.fields
      );
      const interestRateField = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE,
        this.fields
      );
      const defaultMileageAllowanceField = getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE,
        this.fields
      );

      if (defaultTermField.selectedValue?.id === DefaultTermType.CUSTOM) {
        defaultTermField.selectedValue = null;
      }

      if (interestRateField.selectedValue?.id === InterestRateType.CUSTOM_RATES) {
        interestRateField.selectedValue = null;
      }

      if (defaultMileageAllowanceField.selectedValue?.id === DefaultMileageAllowanceType.CUSTOM) {
        defaultMileageAllowanceField.selectedValue = null;
      }
    }

    this.forceUpdate();
  }

  showValidationError(fields: string[]) {
    this.setTier({
      errors: [
        {
          message: translate.t('please_enter_an_amount'),
          extensions: {
            fields,
          },
        },
      ],
    });
  }

  getCustomConfigurationFieldData(): {
    interestRates: InterestRateEntryInput[];
    residualRates: ResidualRateEntryInput[];
    availableMileageAllowances: MileageInput[];
  } | null {
    // Get the available mileage allowance that the user has selected
    const availableMileageAllowancesField = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
      this.fields
    ).selectedValue;

    // Map the SelectOption id to the full mileage allowance data (API expects the full mileage allowance data)
    const availableMileageAllowances = availableMileageAllowancesField
      // Only fields needed for the mileage allowance save payload is the amount and unit (exclude formattedAmount)
      .map(option => ({ amount: option.id, unit: option.data }));

    // Get the available terms that the user has selected
    const availableTerms = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS,
      this.fields
    ).selectedValue;

    // This will hold the interest rates per term
    const interestRates: InterestRateEntryInput[] = [];
    // This will hold the residual rates per term and mileage allowance
    const residualRates: ResidualRateEntryInput[] = [];

    // Keep track of any empty/invalid fields
    const invalidInterestRates: string[] = [];
    const invalidResidualRates: string[] = [];

    /*
     * Each term will have a header field for displaying the term number, an input field for the interest rate, and
     * then input fields for the residual rates on each mileage allowance. We will split the fields up by this grouping,
     * which means each group will have 2 fields (interest rate, and term header) plus however many mileage allowances
     * have been selected.
     */

    /*
     * Each field group size is determined by the num of available mileage allowances, plus a header field and
     * interest rate field (hence availableMileageAllowances.length + 2).
     */
    const fieldGroupsLength = availableMileageAllowances?.length + 2;
    const fieldGroups = chunk(this.fields.slice(this.staticFieldsLength), fieldGroupsLength);

    for (const [termIndex, fieldGroup] of fieldGroups.entries()) {
      // The nth term for this group corresponds to the nth term selected by the user
      const term = availableTerms[termIndex]?.id;

      // Get the interest rate field
      const interestRateField = getStepField(getInterestRateQueryVar(term), fieldGroup);

      // If field is empty, include it in the list of invalid interest rate fields
      if (!interestRateField.selectedValue) {
        invalidInterestRates.push(interestRateField.queryVar);
      }

      // Include the value of the interest rate input field along with the term value in the interestRates array
      interestRates.push({
        interestRate: interestRateField.selectedValue,
        term: Number(term),
      } as InterestRateEntryInput);

      // Available mileage field group immediately proceeds the interest rate field
      const mileageAllowanceFieldGroupIndex =
        fieldGroup.findIndex(field => field.queryVar === interestRateField.queryVar) + 1;

      // Iterate through each mileage allowance field
      for (const [mileageAllowanceIndex, mileageAllowanceField] of fieldGroup
        .slice(mileageAllowanceFieldGroupIndex)
        .entries()) {
        // The nth mileage allowance field corresponds to the nth available mileage allowance selected by the user
        const mileageAllowance = availableMileageAllowances[mileageAllowanceIndex];

        // If field is empty, include it in the list of invalid residual rate fields
        if (!mileageAllowanceField.selectedValue) {
          invalidResidualRates.push(mileageAllowanceField.queryVar);
        }

        // Include the term, mileage allowance, and the inputted residual rate in the residualRates array
        residualRates.push({
          term: Number(term),
          mileageAllowance: {
            amount: mileageAllowance.amount,
            unit: mileageAllowance.unit,
          },
          residualRate: mileageAllowanceField.selectedValue,
        } as ResidualRateEntryInput);
      }
    }

    // If there is an interest rate field or residual rate field that is empty, show a validation error
    if (invalidInterestRates.length > 0 || invalidResidualRates.length > 0) {
      this.showValidationError([...invalidResidualRates, ...invalidInterestRates]);
      return null;
    } else {
      return { interestRates, residualRates, availableMileageAllowances };
    }
  }

  async save() {
    const variableOverrides = {};

    const pricingSource = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE,
      this.fields
    ).selectedValue?.id;

    const defaultTermTypeField = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE,
      this.fields
    );
    const defaultMileageAllowanceTypeField = getStepField(
      RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE,
      this.fields
    );

    if (!defaultTermTypeField.selectedValue?.id || pricingSource === PricingSource.CUSTOM) {
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE,
        DefaultTermType.CUSTOM
      );
    }
    if (!defaultMileageAllowanceTypeField.selectedValue?.id || pricingSource === DefaultMileageAllowanceType.CUSTOM) {
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE,
        DefaultMileageAllowanceType.CUSTOM
      );
    }

    if (pricingSource === PricingSource.CUSTOM) {
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE,
        InterestRateType.CUSTOM_RATES
      );
      /*
       * If this is a CUSTOM pricing source, then we need to get the interest rates and terms from the dynamically
       * generated fields, and set the interest rate type and default term type to CUSTOM
       */
      getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATE_TYPE,
        this.fields
      ).selectedValue = ResidualRateType.CUSTOM_RATES;

      const data = this.getCustomConfigurationFieldData();

      // There was an error getting the users custom configuration data, a field was probably left empty
      if (!data) {
        return false;
      }

      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATES,
        data.interestRates
      );
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATES,
        data.residualRates
      );
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
        data.availableMileageAllowances
      );
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE,
        Number(
          getStepField(RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE, this.fields)
            .selectedValue?.id
        )
      );

      /**
       * Set `include after-tax rebates` to null if pricing source is custom
       */
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES, null);
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_CLEAR, [
        LeaseRetailPricingConfigurationInputParameter._includeAfterTaxRebates,
      ]);
    } else {
      getStepField(
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATE_TYPE,
        this.fields
      ).selectedValue = ResidualRateType.SPECIAL_OEM_RATES;
      /*
       * When saving a non-custom pricing source, we need to clear any of the custom values entered by the user for
       * for terms, interest rates and mileage allowances
       */
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS, null);
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM, null);
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATES, null);
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATES, null);
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE, null);
      set(
        variableOverrides,
        RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
        null
      );
      set(variableOverrides, RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_CLEAR, [
        LeaseRetailPricingConfigurationInputParameter._defaultTerm,
        LeaseRetailPricingConfigurationInputParameter._interestRates,
        LeaseRetailPricingConfigurationInputParameter._availableTerms,
        LeaseRetailPricingConfigurationInputParameter._availableMileageAllowances,
        LeaseRetailPricingConfigurationInputParameter._defaultMileageAllowance,
        LeaseRetailPricingConfigurationInputParameter._residualRates,
      ]);
    }

    return super.save({}, variableOverrides);
  }
}

export default RatesStep;
