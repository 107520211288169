import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum RooftopShoppingTools {
  SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG = 'shoppingToolsConfiguration.showroomConfiguration',
  SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG = 'shoppingToolsConfiguration.buildAndPriceConfiguration',
  SHOPPING_TOOLS_CONFIG_MAKES = 'shoppingToolsConfiguration.makes',
  SHOPPING_TOOLS_CONFIG_YEAR_MODELS = 'shoppingToolsConfiguration.yearModels',
  SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES = 'shoppingToolsConfiguration.includeCommercialTrims',
  SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS = 'shoppingToolsConfiguration.includeBulkAdjustments',
  SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES = 'shoppingToolsConfiguration.includeIncentives',
  SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS = 'shoppingToolsConfiguration.showFinancePayments',
  SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS = 'shoppingToolsConfiguration.showLeasePayments',
  SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE = 'shoppingToolsConfiguration.showroomConfiguration.disclaimerTemplate',
  SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE = 'shoppingToolsConfiguration.buildAndPriceConfiguration.disclaimerTemplate',
}

const RooftopShoppingToolsConfig: TableSettingsType = {
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_CONFIG]: {
    label: 'showroom',
    type: FieldDataType.CUSTOM,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_CONFIG]: {
    label: 'build_and_price',
    type: FieldDataType.CUSTOM,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_MAKES]: {
    label: 'make_one',
    type: FieldDataType.BADGE_LIST,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_YEAR_MODELS]: {
    label: 'make_and_year_selection',
    type: FieldDataType.CUSTOM,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES]: {
    label: 'include_commercial_vehicle_other',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS]: {
    label: 'include_bulk_adjustment_in_price',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES]: {
    label: 'include_manufacturer_incentives_in_price',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS]: {
    label: 'show_finance_payment_other',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS]: {
    label: 'show_lease_payment_other',
    type: FieldDataType.BOOLEAN,
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE]: {
    label: 'showroom_disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'showroom_disclaimer_tooltip',
  },
  [RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE]: {
    label: 'build_and_price_disclaimer',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'build_and_price_disclaimer_tooltip',
  },
};

export const RooftopShoppingToolsSettings = translateFields(RooftopShoppingToolsConfig);
