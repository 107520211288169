import type { MouseEvent } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import { CTAButton } from 'components/ui/shared/Button';
import { ElementTestId } from 'enums/testing';
import { NEUTRAL_800 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_SIZE_16, FONT_WEIGHT_BOLD, LINE_HEIGHT_DEFAULT } from 'styles/typography';
import { translate } from 'utils/intlUtils';

import type { ListSelectionEmptyPlaceholderProps } from './interfaces';

const { t } = translate;

const Container = styled.div<{ containerCss?: FlattenSimpleInterpolation }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 15px;

  ${({ containerCss }) => containerCss}

  svg {
    margin: 0 auto 24px;
  }
`;

const Title = styled(Text)`
  font-size: ${FONT_SIZE_16};
  font-weight: ${FONT_WEIGHT_BOLD};
  line-height: ${LINE_HEIGHT_DEFAULT};
  color: ${NEUTRAL_800};
`;

const SubTitle = styled(Text)`
  font-size: ${FONT_SIZE_14};
  line-height: ${LINE_HEIGHT_DEFAULT};
  text-wrap: wrap;
  white-space: break-spaces;

  & + ${CTAButton} {
    margin-top: 30px;
  }
`;

interface Props {
  label?: string;
  searchKeyword?: string;
  onAdd?: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSearch: (keyword: string) => void | undefined;
}

const ListSelectionPlaceHolder = ({ label, searchKeyword, onAdd, onSearch }: Props) => (
  <Container>
    <Title>{t('no_results')}</Title>
    <SubTitle>{t('no_results_for_x', [searchKeyword || ''])}</SubTitle>
    <CTAButton onClick={onAdd || onSearch.bind(null, '')}>{t(onAdd && label ? label : 'clear_search')}</CTAButton>
  </Container>
);

/**
 * Empty placeholder UI shown when a list view is by default empty.
 * In other words there is no data to show when initially opened.
 */
export const ListSelectionEmptyPlaceHolder = ({
  title = t('no_items_to_show'),
  subtitle = t('empty_list_placeholder_subtitle'),
  icon,
  buttonLabel,
  onClick,
  containerCss,
}: ListSelectionEmptyPlaceholderProps & { containerCss?: FlattenSimpleInterpolation }) => (
  <Container containerCss={containerCss} data-testid={ElementTestId.LIST_SELECTION_EMPTY_LIST_PLACEHOLDER}>
    {!!icon && icon}
    <Title>{title}</Title>
    <SubTitle>{subtitle}</SubTitle>
    {!!onClick && !!buttonLabel && <CTAButton onClick={onClick}>{t(buttonLabel)}</CTAButton>}
  </Container>
);

export default ListSelectionPlaceHolder;
