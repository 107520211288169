import { memo, type SVGProps } from 'react';

const OverlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3.828 15.232a1.056 1.056 0 0 1-.441-.894c.004-.37.157-.668.46-.895.207-.154.436-.232.687-.233.25-.003.479.075.686.232l6.774 5.25 6.786-5.25a1.14 1.14 0 0 1 .683-.232c.25-.003.477.073.684.226.303.228.457.528.463.899.007.37-.14.67-.438.897l-6.786 5.274c-.418.32-.883.48-1.395.482a2.188 2.188 0 0 1-1.39-.482l-6.773-5.274Zm6.774-.122-5.63-4.385c-.59-.456-.883-1.053-.883-1.79 0-.738.294-1.335.882-1.791l5.63-4.385a2.24 2.24 0 0 1 1.393-.479c.51 0 .974.16 1.393.479l5.63 4.385c.588.456.883 1.053.883 1.79 0 .738-.295 1.335-.883 1.79l-5.63 4.386c-.419.319-.883.479-1.393.479s-.974-.16-1.392-.48Z"
    />
  </svg>
);

export default memo(OverlayIcon);
