import { gql } from '@apollo/client';

export const columns = gql`
  fragment ColumnsFragment on Column {
    id
    sortDirection
    editable
    name
    sortPriority
    sortable
    exportable
  }
`;
