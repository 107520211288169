import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import ListItemClickable from 'components/ui/lists/ListItemClickable';
import RooftopListItem from 'components/ui/lists/RooftopListItem';
import { CustomEntity } from 'enums/extendedEntityType';
import { ListItemType } from 'enums/listItemType';
import type { Rooftop } from 'store/api/graph/interfaces/types';

import type { SectionProps } from './LinkedTab';

interface Props extends SectionProps {
  rooftop: Rooftop[];
}

const RooftopSection = ({ rooftop, isNested, sectionTitle, isInteractive, testId }: Props) => (
  <LinkedSection>
    <LinkedSectionHeader title={sectionTitle!} hasItems={!!rooftop?.length} testId={testId} />
    <ListItems>
      {rooftop?.map(item => (
        <ListItemClickable
          key={item.id}
          item={item}
          disabled={isInteractive === false}
          entity={CustomEntity.ROOFTOP}
          isNested={isNested}
          renderElement={RooftopListItem}
          renderSettings={{ listItemType: ListItemType.SECTION_LIST }}
          testId={testId}
        />
      ))}
    </ListItems>
  </LinkedSection>
);

export default RooftopSection;
