import { AutoPriceAdjustmentBaseFields } from 'components/sections/createModify/rooftopPricingSegments/interfaces';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';

export enum RooftopLeaseSegmentDetailsBuilderFields {
  LEASE_CONFIGURATION_ENABLED = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.enabled`,
  LEASE_CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.availablePaymentFrequencies`,
  LEASE_CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.defaultPaymentFrequency`,
  LEASE_CONFIGURATION_STARTING_PRICE_FIELD = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.startingPriceField`,
  LEASE_CONFIGURATION_INCLUDE_TAX = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.includeTax`,
  LEASE_CONFIGURATION_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.disclaimerTemplate`,
  LEASE_CONFIGURATION_INCLUDE_FREIGHT = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.includeFreight`,
  LEASE_CONFIGURATION_MIN_DISPLAY_PRICE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.minDisplayPrice`,
  LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED = AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_ENABLED,
  LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_TARGET_PRICE}`,
  LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_LABEL}`,
  LEASE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE}`,
  NAME = 'name',
  DESCRIPTION = 'description',
  ORDER = 'order',
  RESIDUAL_RATE = 'residualRate',
  TARGETS = 'targets',
}

export enum RooftopLeaseSegmentRatesBuilderFields {
  LEASE_CONFIGURATION_PRICING_SOURCE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.pricingSource`,
  LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.includeAfterTaxRebates`,
  LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.defaultMileageAllowance`,
  LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.availableMileageAllowances`,
  LEASE_CONFIGURATION_AVAILABLE_TERMS = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.availableTerms`,
  LEASE_CONFIGURATION_DEFAULT_TERM = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.defaultTerm`,
  LEASE_CONFIGURATION_DEFAULT_TERM_TYPE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.defaultTermType`,
  LEASE_CONFIGURATION_INTEREST_RATE_TYPE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.interestRateType`,
  LEASE_CONFIGURATION_INTEREST_RATES = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.interestRates`,
  LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.defaultMileageAllowanceType`,
  LEASE_CONFIGURATION_RESIDUAL_RATE_TYPE = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.residualRateType`,
  LEASE_CONFIGURATION_RESIDUAL_RATES = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}.residualRates`,
  LEASE_CONFIGURATION_CLEAR = `${RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION}._clear`,
}

export enum RooftopLeaseSegmentAvailableMileageAllowanceBuilderFields {
  AVAILABLE_MILEAGE_ALLOWANCES = 'availableMileageAllowances',
}

export enum RooftopLeaseSegmentBuilderSteps {
  DETAILS = 'DETAILS',
  RATES = 'RATES',
  FILTERS = 'FILTERS',
}

export enum RooftopLeaseSegmentAvailableMileageAllowanceBuilderSteps {
  DETAILS = 'DETAILS',
}
