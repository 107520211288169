import { useContext } from 'react';

import { ConnectionSearchContext } from 'contexts/ConnectionSearchContext';

export function useSearch() {
  const context = useContext(ConnectionSearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a ConnectionSearchProvider');
  }
  return context;
}
