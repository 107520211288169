import { cloneDeep, debounce, get, isEqual, set } from 'lodash-es';
import styled from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import PrimaryText from 'components/core/typography/PrimaryText';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { retailBulkAdjustmentAffectVehicles } from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentCreateModifyQuery';
import { RetailFilterBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import { getTagOptions, getYMMTOptions, YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { getFilterField, RetailFilters, RetailFiltersSettings } from 'enums/columns/retailFilters';
import { ElementTestId } from 'enums/testing';
import type { RetailItemConnectionFilterInput } from 'store/api/graph/interfaces/types';
import { EntityType, InventoryItemType, PricingTarget } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
  stripTypeNames,
} from 'utils/formatting/createModifyFormatUtils';
import { GetApiValuesForStep } from 'utils/formatting/createModifySaveUtils';
import { translate } from 'utils/intlUtils';
import { getInventoryItemTypeConfig } from 'utils/inventoryItemUtils';
import { DEFAULT_SEARCH_DEBOUNCE } from 'utils/timeUtils';

const AffectedVehiclesContainer = styled.div`
  color: ${BODY_TEXT};
  display: flex;
  flex-direction: row;
  align-content: center;

  svg {
    margin-right: ${ENTITY_PADDING};
  }
`;
interface AffectedVehiclesProps {
  count?: number;
  vehicleType: InventoryItemType;
}

/**
 * Footer component that displays the number of affected vehicles given the current filter data
 * @param count - Number of affected vehicles, if no count is provided, then an empty placeholder is used
 * @param vehicleType - The type of vehicle
 */
const AffectedVehicles = ({ count, vehicleType }: AffectedVehiclesProps) => {
  const IconComponent = getInventoryItemTypeConfig(vehicleType).icon;
  return (
    <AffectedVehiclesContainer data-testid={ElementTestId.VEHICLES_AFFECTED_FOOTER}>
      <IconComponent height={18} width={18} />
      <PrimaryText>
        {translate.tPlural(getInventoryItemTypeConfig(vehicleType).affectedItemsLabel, count === 1 ? 1 : 0, [
          count ?? '--',
        ])}
      </PrimaryText>
    </AffectedVehiclesContainer>
  );
};

const AdjustmentFilterType = getFilterField(InventoryItem.TYPE);
const makeFields = new Set([
  RetailFilterBuilderFields.FILTER_MAKE_ID,
  RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED,
]);
const MMSFields = [
  RetailFilterBuilderFields.FILTER_MAKE_ID,
  RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED,
  RetailFilterBuilderFields.FILTER_MODEL_ID,
  RetailFilterBuilderFields.FILTER_SUBMODEL_ID,
];

class RetailFilterStep extends StepComponentCore {
  currentFilter?: RetailItemConnectionFilterInput;
  fieldConfigs: { [key: string]: StepField[] };

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    this.context = context;

    const {
      tier: { activeStep, data: currentData, formData, seededData, metadata },
    } = props;

    const filterData = { ...seededData, ...formData, ...currentData };

    const data = stripTypeNames(cloneDeep(filterData));

    const rooftopId = data.rooftopId || data.rooftopName?.id || data.rooftop?.id;

    // Converting to readable fields and setting presets
    const fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailFilterBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftopId,
      },
      [RetailFilterBuilderFields.FILTER_MAKE_ID]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!data.filter?.makeIdExcluded?.length,
          },
        ]),
      },
      [RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED]: {
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.DISABLED, active: !!data.filter?.makeId?.length }]),
      },
      [RetailFilterBuilderFields.FILTER_MODEL_ID]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !data.filter?.makeId?.length || !!data.filter?.makeIdExcluded?.length,
          },
        ]),
      },
      [RetailFilterBuilderFields.FILTER_SUBMODEL_ID]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !data.filter?.modelId?.length || !!data.filter?.makeIdExcluded?.length,
          },
        ]),
      },
      [RetailFilterBuilderFields.FILTER_TYPE]: {
        selectedValue: data.filter?.type || InventoryItemType.VEHICLE,
      },
      [RetailFilterBuilderFields.FLAGS]: {
        options: [
          // Ordered to match filters in regular connection
          InventoryItem.MAPPED,
          InventoryItem.SHOW_WEB,
          InventoryItem.AS_IS,
          InventoryItem.CERTIFIED,
          InventoryItem.DEMO,
          InventoryItem.FEATURED,
          InventoryItem.ON_ORDER,
          InventoryItem.IN_TRANSIT,
          InventoryItem.COMPLETE,
          RetailFilters.FILTER_HAS_MILEAGE,
          RetailFilters.FILTER_HAS_PRICE,
        ].map(flag => {
          const filterFlagName = [RetailFilters.FILTER_HAS_MILEAGE, RetailFilters.FILTER_HAS_PRICE].includes(
            flag as RetailFilters
          )
            ? flag
            : getFilterField(flag as InventoryItem);
          return {
            label: RetailFiltersSettings[flag]?.label || InventoryItemSettings[flag]?.label,
            queryVar: filterFlagName,
            /*
             * TODO: Hook this up when clearable
             * Clear: { field: flag },
             */
            settings: {
              isClearable: true,
            },
            selectedValue: get(data, filterFlagName),
          } as StepField;
        }),
        selectedValue: null,
      },
    });

    // Assigning pre-defined values
    const definedFields = defineFieldValues(fields, data, metadata);

    // Mapping options to connection metadata if applicable
    for (const fieldId of [
      RetailFilterBuilderFields.FILTER_MAKE_ID,
      RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED,
      RetailFilterBuilderFields.FILTER_MODEL_ID,
      RetailFilterBuilderFields.FILTER_SUBMODEL_ID,
      RetailFilterBuilderFields.FILTER_TAG_ID,
    ]) {
      const field = getStepField(fieldId, fields);
      const dataTarget = get(data, fieldId) || [];

      const mappedOptions = dataTarget
        .map(target => get(metadata, field.options as string, []).find(({ id }) => id === target || id === target?.id))
        .filter(Boolean);

      field.selectedValue = mappedOptions?.length ? mappedOptions : undefined;
    }
    // TODO: [ED-8911]
    this.fieldConfigs = {
      [InventoryItemType.VEHICLE]: this.getFieldsByType(definedFields, InventoryItemType.VEHICLE),
      [InventoryItemType.MOTORCYCLE]: this.getFieldsByType(definedFields, InventoryItemType.MOTORCYCLE),
    };

    this.asyncConfigurations = {
      [RetailFilterBuilderFields.FILTER_TAG_ID]: {
        request: () => getTagOptions({ rooftopId: [rooftopId], entityType: EntityType.RETAIL_ITEM }),
        disableKeywordRefetch: true,
      },
    };

    const filterType: InventoryItemType = getStepField(AdjustmentFilterType, definedFields).selectedValue;

    this.fields = this.fieldConfigs[filterType];
  }

  getFieldsByType = (fields, type: InventoryItemType): StepField[] =>
    fields.filter(field => {
      const queryVar = field.queryVar.split('.');
      const hasAttributes = queryVar.length > 2;
      return hasAttributes ? !!queryVar.includes(`${type.toLowerCase()}Attributes`) : true;
    });

  componentDidMount() {
    super.componentDidMount();
    void this.getAffectedVehicles();
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>, shouldForceUpdate = false) {
    if (stepField.queryVar === AdjustmentFilterType) {
      // Updating available fields to the type selected
      this.fields = this.fieldConfigs[e.currentTarget.value];

      // Clearing MMS fields
      getStepField(RetailFilterBuilderFields.FILTER_MAKE_ID, this.fields).selectedValue = null;
      getStepField(RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED, this.fields).selectedValue = null;
      this.clearFields(
        [RetailFilterBuilderFields.FILTER_MODEL_ID, RetailFilterBuilderFields.FILTER_SUBMODEL_ID],
        false
      );
    }

    super.onFieldChange(stepField, e, shouldForceUpdate);

    if (!stepField.subStep) {
      void this.getAffectedVehicles();
    }
  }

  /**
   * Callback override for MMS handling
   */
  async onFieldSelection(stepField: StepField, value: any, persistSeededValues: boolean, disableAdvance: boolean) {
    super.onFieldSelection(stepField, value, persistSeededValues, disableAdvance);

    const stepFieldQueryVar = stepField.queryVar as RetailFilterBuilderFields;

    if (MMSFields.includes(stepFieldQueryVar)) {
      // All dependent fields must be disabled for FILTER_MAKE_ID_EXCLUDED because they are no longer valid selections
      const enableNextField =
        stepFieldQueryVar === RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED ? false : value.length > 0;
      let fieldsToClear = MMSFields.slice(MMSFields.indexOf(stepFieldQueryVar) + 1);

      if (makeFields.has(stepFieldQueryVar)) {
        this.toggleMakeFields(stepFieldQueryVar, value);
        // Filter out makeFields because their state is handled in `toggleMakeFields`
        fieldsToClear = fieldsToClear.filter(field => !makeFields.has(field));
      }

      // Clearing all fields after this field
      this.clearFields(fieldsToClear, enableNextField);
    }

    void this.getAffectedVehicles();
  }

  /**
   * Will clear the selected value and disable the make field that is not in use and will remove OMITTED from
   * the make field that is in use (e.g If the updated field is FILTER_MAKE_ID then FILTER_MAKE_ID_EXCLUDED
   * will be cleared and disabled and FILTER_MAKE_ID will no longer be OMITTED) If no selections are made then
   * both fields are enabled and omitted.
   */
  toggleMakeFields(updatedFieldId: RetailFilterBuilderFields, value: any) {
    const fieldToDisableId =
      updatedFieldId === RetailFilterBuilderFields.FILTER_MAKE_ID
        ? RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED
        : RetailFilterBuilderFields.FILTER_MAKE_ID;

    const fieldToDisable = getStepField(fieldToDisableId, this.fields);
    fieldToDisable.selectedValue = undefined;

    setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: value.length > 0 }, fieldToDisable);
  }

  /**
   * Handles clearing and disabling of fields based on an array of RetailFilterBuilderFields
   */
  clearFields(fields: RetailFilterBuilderFields[], enableNextField: boolean) {
    for (const [index, queryVar] of fields.entries()) {
      const field: StepField = getStepField(queryVar, this.fields);
      field.selectedValue = undefined;
      setDisplayTypes(
        [
          {
            type: StepFieldDisplayType.DISABLED,
            active: !enableNextField || index > 0,
          },
        ],
        field
      );
    }
  }

  /**
   * Callback override for MMS handling
   */
  async toggleSubPanel(stepField: StepField) {
    const { currentStepField } = this.state;

    // Making the call to get the next set of data, do not execute calls again if the step field is the same
    if (currentStepField !== stepField) {
      const stepFieldQueryVar = stepField?.queryVar as RetailFilterBuilderFields;

      // Dynamically formatting this.asyncConfigurations based on selecting field
      if (MMSFields.includes(stepFieldQueryVar)) {
        // The MAKE_IDs need the vehicle type (motorcycle/car), which comes from the previous builder step
        const variables = makeFields.has(stepFieldQueryVar)
          ? {
              type: getStepField(AdjustmentFilterType, this.fields).selectedValue,
            }
          : {};
        const makeFieldAlias = {
          fieldVarName: 'makeIds',
          target: YMMTTarget.MAKE_ID,
          metadataField: 'makes',
          dependentFields: [DetailsInventoryItemBuilderFields.ROOFTOP_ID],
        };
        const fieldAliases = {
          [RetailFilterBuilderFields.FILTER_MAKE_ID]: makeFieldAlias,
          [RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED]: makeFieldAlias,
          [RetailFilterBuilderFields.FILTER_MODEL_ID]: {
            fieldVarName: 'modelIds',
            target: YMMTTarget.MODEL_ID,
            metadataField: 'models',
            dependentFields: [RetailFilterBuilderFields.FILTER_MAKE_ID, DetailsInventoryItemBuilderFields.ROOFTOP_ID],
          },
          [RetailFilterBuilderFields.FILTER_SUBMODEL_ID]: {
            target: YMMTTarget.SUB_MODEL_ID,
            metadataField: 'subModels',
            dependentFields: [RetailFilterBuilderFields.FILTER_MODEL_ID, RetailFilterBuilderFields.ROOFTOP_ID],
          },
        };

        const fieldAlias = fieldAliases[stepFieldQueryVar];
        // Iterate through the dependent fields and include them in the list of variables
        if (fieldAlias.dependentFields) {
          for (const fieldName of fieldAlias.dependentFields) {
            const variableName = fieldAliases[fieldName]?.fieldVarName || fieldName;
            const dependentStepField = getStepField(fieldName, this.fields);
            variables[variableName] = [dependentStepField.selectedValue].flat().map(item => item.id || item);
          }
        }

        this.asyncConfigurations = {
          ...this.asyncConfigurations,
          [stepFieldQueryVar]: {
            request: async () => {
              const fieldAlias = fieldAliases[stepFieldQueryVar];
              const results = await getYMMTOptions(variables, fieldAlias.target);

              // Persisting asynchronous metadata special to adjustments builder
              this.setTier({ metadata: { ...this.props.tier.metadata, [fieldAlias.metadataField]: results } });

              return results;
            },
            disableKeywordRefetch: true,
          },
        };
      }
    }
    await super.toggleSubPanel(stepField);
  }

  getAffectedVehicles = debounce(async () => {
    const {
      tier: { data: currentData, formData, seededData },
    } = this.props;

    const data = { ...seededData, ...formData, ...currentData };

    const rooftopId = [getStepField(RetailFilterBuilderFields.ROOFTOP_ID, this.fields).selectedValue]
      .flat()
      .map(item => item?.id || item);

    const targets = data?.targets || data?.priceAttributes?.targets || [];
    // If the parent entities targets do not include `INVENTORY` do not display affected vehicles
    const doesNotHaveInventoryTarget = targets.length > 0 && !targets.includes(PricingTarget.INVENTORY);
    // RooftopID is required to fetch affected vehicles, without it query would return all retail items
    if (rooftopId.length === 0 || doesNotHaveInventoryTarget) {
      this.setTier({
        footerContent: undefined,
      });
      return false;
    }

    const type = getStepField(AdjustmentFilterType, this.fields).selectedValue;
    const variables = stripTypeNames(GetApiValuesForStep(this, { filter: { type } }));
    // Don't need 'id' or 'flags' parameters for this API call
    delete variables?.id;
    delete variables?.flags;
    delete variables?.rooftopId;
    if (variables?.filter) {
      if (variables.filter.makeId?.length) {
        delete variables.filter.makeIdExcluded;
      }
      if (variables.filter.makeIdExcluded?.length) {
        delete variables.filter.makeId;
        delete variables.filter.modelId;
        delete variables.filter.subModelId;
      }
    }

    this.currentFilter = variables;
    variables.filter = { ...variables.filter, rooftopId };
    // TODO: [ED-8911]
    switch (type) {
      case InventoryItemType.VEHICLE: {
        set(variables, 'filter.motorcycleAttributes', null);
        break;
      }

      case InventoryItemType.MOTORCYCLE: {
        set(variables, 'filter.vehicleAttributes', null);
        break;
      }

      default: {
        break;
      }
    }

    try {
      const results = await this.client.query({
        query: retailBulkAdjustmentAffectVehicles,
        variables: {
          ...variables,
        },
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });

      const { getTier } = this.context!;
      const { tier } = this.props;

      const currentTier = getTier(tier.tierId);

      if (currentTier?.type === tier.type && isEqual(this.currentFilter, variables)) {
        this.setTier({
          footerContent: <AffectedVehicles count={results.data.data.pageInfo.totalEdges} vehicleType={type} />,
        });
      }
    } catch (error: any) {
      // 400 errors fail silently as to not disrupt the experience, all others throw as normal
      if (error.networkError.statusCode === 400) {
        this.setTier({
          // Since there was an error getting the number of affected vehicles, there is no count to show
          footerContent: <AffectedVehicles vehicleType={type} />,
        });
      } else {
        throw error;
      }
    }
  }, DEFAULT_SEARCH_DEBOUNCE);

  /*
   * Overriding core async save method with custom formatted fields, preset/overriden variables,
   * and dynamic queries based on create/modify context
   */
  async save(mutation, variableOverrides: Partial<any> | undefined = undefined): Promise<boolean> {
    const type = getStepField(AdjustmentFilterType, this.fields).selectedValue;

    const overrides = variableOverrides || {
      // TODO: [ED-8911]
      filter: {
        ...(type !== InventoryItemType.VEHICLE && { vehicleAttributes: null }),
        ...(type !== InventoryItemType.MOTORCYCLE && { motorcycleAttributes: null }),
      },
    };

    return super.save(undefined, overrides, mutation);
  }
}

export default RetailFilterStep;
