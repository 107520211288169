import { gql } from '@apollo/client';

import { leadActivityDetail } from 'store/api/graph/fragments/leadActivityDetail';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';

import { conversationItem } from './conversationItem';
import { conversationItemAttachment } from './conversationItemAttachment';
import { conversationList } from './conversationList';
import { rooftopList } from './rooftopList';
import { tag } from './tag';
import { userList } from './userList';

export const conversationDetail = gql`
  fragment ConversationDetailFragment on Conversation {
    ...ConversationListFragment

    items {
      ...ConversationItemFragment
    }
    assignedToName: assignedTo {
      ...UserListFragment
    }
    leadActivity {
      ...LeadActivityDetailFragment
    }
    tagName: tags {
      ...TagFragment
    }

    # LinkedTab
    participants {
      ...UserListFragment
    }
    rooftopName: rooftop {
      ...RooftopListFragment
    }
    attachments {
      ...ConversationItemAttachmentFragment
    }
    leadName: lead {
      ...LeadListFragment
      assignedToName: assignedTo {
        ...UserListFragment
      }
    }
  }
  ${userList}
  ${conversationList}
  ${leadActivityDetail}
  ${tag}
  ${rooftopList}
  ${conversationItemAttachment}
  ${conversationItem}
  ${retailItemList}
`;
