import { gql } from '@apollo/client';

import { inventoryItemDetail } from './inventoryItemDetail';
import { tag } from './tag';

export const tradeInItemDetail = gql`
  fragment TradeInItemDetailFragment on TradeInItem {
    # Main Details Grid
    ...InventoryItemDetailFragment
    leadNote
    status
    statusName
    tagName: tags {
      ...TagFragment
    }

    # Guaranteed Offer/Trade (GT/GO) fields

    ## Condition Panel
    conditionScore
    accidentAmount {
      amount
      formattedAmountRounded
    }

    ## Details Grid
    numKeys
    conditionDeclarations
    conditionDeclarationsNames
  }
  ${inventoryItemDetail}
  ${tag}
`;
