import { get, isNil } from 'lodash-es';
import styled from 'styled-components/macro';

import type Step from 'components/core/createModify/interfaces/step';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import MultiLineText from 'components/core/typography/MultiLineText';
import { RetailItemPricingBuilderStep } from 'components/sections/createModify/retailItemPricing/enums/RetailItemPricingBuilder';
import { RetailItemPricingTypeBuilderFields } from 'components/sections/createModify/retailItemPricing/steps/interfaces';
import { PromptDialogIcon } from 'components/ui/dialogs/PromptDialog';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import type { Incentive, PaymentOption, RetailItem } from 'store/api/graph/interfaces/types';
import { GREEN_500 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_WEIGHT_BOLD, LETTER_SPACING_DENSE } from 'styles/typography';
import { doArrayObjectsMatch } from 'utils/arrayUtils';
import { calcPriceAdjustments } from 'utils/calcUtils';
import { getStepField, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { getRetailItemsPricingPropertyPath } from 'utils/formatting/pricingUtils';
import { formatCurrency, PLACEHOLDER } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import { getAvailablePaymentMethods } from '../helpers/RetailItemPricingBuilders';

import {
  getBuilderStepPaymentOption,
  getIncentiveAdjustmentOptions,
  getIncompatibleIncentives,
  getPriceAdjustmentOptions,
  getPricingData,
  IncentiveListGroups,
} from './utils';

const { t } = translate;

const PricingFooter = styled.div`
  display: flex;

  > p {
    font-size: ${FONT_SIZE_14};
    letter-spacing: ${LETTER_SPACING_DENSE};
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  > :first-child {
    margin-right: auto;
  }

  > :nth-child(2) {
    color: ${GREEN_500};
  }
`;

const IncentiveWarningPromptContainer = styled.div`
  padding: 15px 0 20px 0;
  text-align: center;
`;

/** Filter out builder steps that have no configuration available */
export const gateRetailItemPricingBuilderSteps = (steps: Step[], data: RetailItem) =>
  steps.filter(
    step =>
      (step.id === RetailItemPricingBuilderStep.CASH && get(data, InventoryItem.CASH_CONFIGURATION_ENABLED)) ||
      (step.id === RetailItemPricingBuilderStep.FINANCE && get(data, InventoryItem.FINANCE_CONFIGURATION_ENABLED)) ||
      (step.id === RetailItemPricingBuilderStep.LEASE && get(data, InventoryItem.LEASE_CONFIGURATION_ENABLED))
  );

const getIncentiveWarningPromptMessage = (selectedIncentive: Incentive, incompatibleIncentives: Incentive[]) => (
  <IncentiveWarningPromptContainer>
    <MultiLineText>
      {t('incompatible_incentives_warning')}
      <b>{selectedIncentive.label.value}</b>. <br />
      {t('would_you_like_to_continue')}
      <div css={'padding-top: 20px'}>{incompatibleIncentives.map(item => item.label.value).join('\n')}</div>
    </MultiLineText>
  </IncentiveWarningPromptContainer>
);

class RetailItemPricingTypeStep extends StepComponentCore {
  /*
   * If the user selects an incentive that is incompatible with any of the currently selected incentives, then
   * this change will be put in a pending state, until the user has clicked 'Continue' in the warning prompt. Any
   * other action (quitting the builder, clicking cancel in the warning prompt, etc) will cancel the pending change.
   */
  pendingIncentiveChanges: { selectedIncentive: Incentive; allSelectedIncentives: Incentive[] } | null;

  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { tierId, data, steps, activeStep },
    } = props;

    const { setTier } = context;

    this.context = context;

    // Filter out the payment steps that do not have an active configuration
    const filteredSteps = gateRetailItemPricingBuilderSteps(steps, data);
    setTier(tierId, { steps: filteredSteps });

    const { priceAdjustments } = data;

    const pricingData = getPricingData(
      data as RetailItem,
      getBuilderStepPaymentOption(activeStep?.id as RetailItemPricingBuilderStep)
    );

    const initialEnabledIncentives = pricingData?.incentives?.filter(incentive => incentive.enabled);

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [RetailItemPricingTypeBuilderFields.ADJUSTMENTS]: {
        options: getPriceAdjustmentOptions(priceAdjustments),
      },
      [RetailItemPricingTypeBuilderFields.INCENTIVES]: {
        options: getIncentiveAdjustmentOptions(data, activeStep.id),
        selectedValue: initialEnabledIncentives,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
        subStepGroups: [
          {
            conditionProp: 'category',
            conditionValue: IncentiveListGroups.AVAILABLE,
            label: t('available_incentive_other'),
          },
          {
            conditionProp: 'category',
            conditionValue: IncentiveListGroups.TARGETED,
            label: t('targeted_incentive_other'),
          },
        ],
      },
    });

    this.defaultClosePrompt = {
      message: t('discard_changes_message'),
      title: t('discard_changes'),
      confirmText: t('discard'),
      onConfirm: async () => {
        this.cancelPendingIncentiveChange();
        this.setOnClosePrompt(undefined);
      },
      onComplete: success => {
        this.cancelPendingIncentiveChange();
        if (success) {
          this.setTier({ formData: undefined });
          props.onTierHide();
        }
      },
      isConfirmDestructive: true,
    };

    this.pendingIncentiveChanges = null;
  }

  /**
   * When a Price Adjustment is modified from a higher Tier builder, the Tier data for this Step is updated with the
   * latest RetailItem data (which contains the updated Price Adjustments). However that data is only consumed when the
   * Step is first mounted. By explicitly updating the selected adjustments and adjustment options whenever the
   * Tier data changes, we can ensure that this Step will always have the latest Price Adjustment data.
   */
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const {
      tier: { data },
    } = this.props;

    const {
      tier: { data: prevData },
    } = prevProps;

    /**
     * We only want to update the adjustment data if it has changed, otherwise we may overwrite the user's changes that
     * haven't been saved yet. If the prev adjustments list has a different length then we know an adjustment has
     * been added. We use differenceWith and isEqual lodash functions to deep compare the previous priceAdjustments
     * against the current ones.
     */
    const wasAdjustmentsModified = !doArrayObjectsMatch(prevData?.priceAdjustments, data?.priceAdjustments);

    if (wasAdjustmentsModified) {
      this.setSelectedAdjustments(data as RetailItem);
      this.setAdjustmentOptions(data as RetailItem);
    }
  }

  onItemAdd(seedData) {
    const {
      tier: { currentStepField },
      tier: tierData,
    } = this.props;

    if (!currentStepField?.subStepAddConfig) {
      return;
    }

    const { builderTitle, builderType, seedPrefillMethod, entityType } = currentStepField.subStepAddConfig;
    const { toggleTier } = this.context!;

    const seededData = seedPrefillMethod?.(seedData, tierData) || {};
    toggleTier(CreateModifyTiers.TIER_1, {
      tierId: CreateModifyTiers.TIER_1,
      type: builderType,
      title: translate.t(builderTitle),
      isCreating: true,
      entityType,
      seededData,
      onStepSave: async (_, data: any) => {
        // Update the list of adjustment options
        this.setAdjustmentOptions(data as RetailItem);
        // Pre-check the new adjustment option that was just created
        currentStepField.seededValues = [{ ...data, focus: true }];
        // Update the current step's selected adjustments
        this.setSelectedAdjustments(data);
        // Update the Tier data, so that other steps will have the latest adjustments
        this.setTier({ data });
        this.forceUpdate();
      },
    });
  }

  onSubStepEdit(id: string) {
    const {
      tier: { data: retailItem },
    } = this.props;

    const {
      toggleTier,
      setTier,
      subContexts: {
        userContext: {
          featureFlags: { rooftopPackageEnabled },
        },
      },
    } = this.getContext();

    const data = retailItem.priceAdjustments?.find(adjustment => adjustment.id === id);

    if (data) {
      toggleTier(CreateModifyTiers.TIER_1, {
        tierId: CreateModifyTiers.TIER_1,
        type: BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_MODIFY,
        title: t('modify_adjustment'),
        isCreating: false,
        data: { ...data },
        seededData: {
          availablePaymentOptions: getAvailablePaymentMethods(retailItem as RetailItem, rooftopPackageEnabled),
        },
        onStepSave: async (_, data: any) => {
          // When modifying a price adjustment the data in the underlying retail pricing builder will need to be updated
          setTier(CreateModifyTiers.TIER_0, { data });
        },
      });
    }
  }

  onMultiSelectItemChecked(stepField: StepField, option: any, values: any[]) {
    if (stepField.queryVar === RetailItemPricingTypeBuilderFields.INCENTIVES) {
      // Given the current incentives selected, disable any incentive options that are not stackable
      const incompatibleItems = getIncompatibleIncentives(option, values);

      if (incompatibleItems?.length) {
        // Show warning prompt
        this.pendingIncentiveChanges = { selectedIncentive: option, allSelectedIncentives: values };
        this.showWarningPromptForIncompatibleIncentives(incompatibleItems, option, values);
      }
    }
  }

  showWarningPromptForIncompatibleIncentives(
    incompatibleItems: Incentive[],
    selectedOption: Incentive,
    allSelectedOptions: Incentive[]
  ) {
    const { toggleClosePrompt } = this.props;

    toggleClosePrompt({
      messageJSX: getIncentiveWarningPromptMessage(selectedOption, incompatibleItems),
      messageIcon: PromptDialogIcon.WARNING,
      confirmText: t('yes_continue'),
      cancelText: t('cancel'),
      onClose: () => {
        toggleClosePrompt(undefined);
        this.cancelPendingIncentiveChange();
      },
      onConfirm: async () => this.deselectIncentives(incompatibleItems, allSelectedOptions),
      onComplete: () => {
        // On complete, close this prompt
        toggleClosePrompt(undefined);
      },
      onCancel: () => {
        // On cancel, close the warning prompt
        toggleClosePrompt(undefined);
        this.cancelPendingIncentiveChange();
      },
    });
  }

  /**
   * Given all currently selected incentive options, deselect all the options from the provided list of
   * optionsToDeselect.
   */
  deselectIncentives(optionsToDeselect: Incentive[], allOptions: Incentive[]) {
    const newOptions = allOptions.filter(
      option => !optionsToDeselect.some(optionToDeselect => optionToDeselect.id === option.id)
    );

    this.subStepControllerRef.current?.overrideSelectedOptions(newOptions);
    this.pendingIncentiveChanges = null;
  }

  /**
   * Cancel the current pending incentive changes, the user did not accept the changes
   */
  cancelPendingIncentiveChange() {
    if (this.pendingIncentiveChanges?.selectedIncentive && this.pendingIncentiveChanges?.allSelectedIncentives) {
      this.deselectIncentives(
        [this.pendingIncentiveChanges.selectedIncentive],
        this.pendingIncentiveChanges.allSelectedIncentives
      );
    }
  }

  onFieldSelection(stepField: StepField, value: any, persistSeededValues = false, advance = true) {
    super.onFieldSelection(stepField, value, persistSeededValues, advance);
    this.setPricingFooter();
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>, shouldForceUpdate = false) {
    super.onFieldChange(stepField, e, shouldForceUpdate);
    this.setPricingFooter();
  }

  setSelectedAdjustments(retailItem?: RetailItem) {
    const {
      tier: { data, activeStep },
    } = this.props;

    const paymentMethodField = getRetailItemsPricingPropertyPath(
      getBuilderStepPaymentOption(activeStep?.id as RetailItemPricingBuilderStep)
    );

    if (!paymentMethodField) {
      return;
    }

    const seededData = retailItem || data;

    const selectedAdjustments = getStepField(RetailItemPricingTypeBuilderFields.ADJUSTMENTS, this.fields).selectedValue;

    /*
     * Set price adjustments based on seededData otherwise use selectedData,
     * so user selections are not overridden by server data
     */
    getStepField(RetailItemPricingTypeBuilderFields.ADJUSTMENTS, this.fields).selectedValue = retailItem
      ? retailItem?.[paymentMethodField]?.priceAdjustments
      : selectedAdjustments;

    /*
     * Set price adjustments based on seededData always as it is not modifiable in this builder,
     * thus no user selections exist to be overridden
     */
    getStepField(RetailItemPricingTypeBuilderFields.BULK_ADJUSTMENTS, this.fields).selectedValue =
      seededData?.[paymentMethodField]?.priceBulkAdjustments;

    this.setPricingFooter();
  }

  setAdjustmentOptions(retailItem: RetailItem) {
    getStepField(RetailItemPricingTypeBuilderFields.ADJUSTMENTS, this.fields).options = getPriceAdjustmentOptions(
      retailItem.priceAdjustments || []
    );
  }

  // This method updates the builder title with the step name (cash, finance, lease) as a suffix.
  setTitleStepName(stepName: string) {
    const baseTitle = t('modify_x', [t('pricing')]);
    this.setTier({ title: `${baseTitle} - ${stepName}` });
  }

  setPricingFooter() {
    const {
      tier: { data, activeStep },
    } = this.props;

    const { priceBulkAdjustments } = data;
    const startingPrice = getPricingData(
      data as RetailItem,
      getBuilderStepPaymentOption(activeStep?.id as RetailItemPricingBuilderStep)
    )?.startingPrice;

    const selectedPriceAdjustments = getStepField(
      RetailItemPricingTypeBuilderFields.ADJUSTMENTS,
      this.fields
    ).selectedValue;

    const selectedIncentives = getStepField(RetailItemPricingTypeBuilderFields.INCENTIVES, this.fields).selectedValue;

    const purchasePrice = calcPriceAdjustments(
      startingPrice,
      selectedPriceAdjustments,
      priceBulkAdjustments,
      selectedIncentives
    );
    const footerContent = (
      <PricingFooter>
        <p>{translate.t('purchase_price')}:</p>
        <p>{isNil(purchasePrice) ? PLACEHOLDER : formatCurrency({ amount: Math.round(purchasePrice) })}</p>
      </PricingFooter>
    );
    this.setTier({ footerContent });
  }

  async savePaymentStep(
    /** The payment method associated with the step */
    paymentMethod: string,
    /** The payment option associated with the step */
    paymentOption: PaymentOption
  ) {
    const {
      tier: { data },
    } = this.props;

    const allIncentiveIds = getPricingData(data as RetailItem, paymentOption)?.incentives || [];

    const selectedIncentiveIds = getStepField(
      RetailItemPricingTypeBuilderFields.INCENTIVES,
      this.fields
    ).selectedValue?.map(incentive => incentive.id);

    const disabledIncentiveIds = allIncentiveIds
      .filter(incentive => !selectedIncentiveIds.includes(incentive.id))
      .map((incentive: Incentive) => incentive.id);

    const priceAdjustmentIds = getStepField(
      RetailItemPricingTypeBuilderFields.ADJUSTMENTS,
      this.fields
    ).selectedValue?.map(priceAdjustment => priceAdjustment.id);

    const payload = {
      priceAdjustmentIds,
      disabledIncentiveIds,
    };
    return super.save(undefined, { [paymentMethod]: payload });
  }
}

export default RetailItemPricingTypeStep;
