import { gql } from '@apollo/client';

import { ApolloFetchPolicy } from 'enums/apollo';
import { getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import type { UserType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { impersonationStorage } from 'utils/storage/impersonation';

import { userIdStorage } from './storage/auth';

const IMPERSONATING_USER_QUERY = gql`
  query ImpersonatingUserQuery {
    user {
      id
    }
  }
`;

class ImpersonationManager {
  /**
   * Stores the user you're impersonating as for this session
   */
  private setImpersonatingAsUser = (impersonatedUserId: string, actualUser: UserType) => {
    impersonationStorage.set({ impersonatedUserId, actualUser });
  };

  /**
   * Retrieves the user you're impersonating as for this session
   */
  getImpersonatedUserId = () => impersonationStorage.get()?.impersonatedUserId;

  /**
   * "Logs out" of the user you're impersonating as for this session
   */
  endImpersonation = () => {
    /**
     * Set actual user id back to local storage when we stop impersonating
     */
    const actualUser = impersonationStorage.get()?.actualUser;
    if (actualUser) {
      userIdStorage.set(actualUser.id);
    }

    impersonationStorage.remove();
  };

  /**
   * Whether or not you are currently impersonating a user (eg. logged in as someone else)
   */
  get isCurrentlyImpersonating() {
    return impersonationStorage.get() !== undefined;
  }

  /**
   * Retrieves the user that is currently doing the impersonation
   */
  getActualUser = () => impersonationStorage.get()?.actualUser;

  /**
   * Test if you are able to impersonate the passed-in user
   */
  tryLoginAs = async (
    impersonatedUserId: string,
    actualUser: UserType
  ): Promise<{ success: boolean; errorMessage?: string }> => {
    try {
      this.setImpersonatingAsUser(impersonatedUserId, actualUser);
      await client.query({ query: IMPERSONATING_USER_QUERY, fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY });
    } catch (error) {
      this.endImpersonation();
      return {
        success: false,
        errorMessage: getApiErrorsMessage(error),
      };
    }
    return { success: true };
  };
}

export const impersonationManager = new ImpersonationManager();
