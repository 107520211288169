/**
 * THIS FILE IS AUTO-GENERATED, DO NOT EDIT
 */

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ConnectionFilter": [
      "AppointmentConnectionFilter",
      "ConversationConnectionFilter",
      "LeadConnectionFilter",
      "RetailItemConnectionFilter",
      "TaskConnectionFilter",
      "TradeInItemConnectionFilter"
    ],
    "Event": [
      "ConversationConnectionUpdatedEvent",
      "ConversationItemCreatedEvent",
      "LeadActivityConnectionUpdatedEvent",
      "PushNotificationEvent"
    ],
    "Filter": [
      "DateTimeFilter",
      "FacetFilter",
      "RangeFilter",
      "ToggleGroupFilter"
    ],
    "Identity": [
      "Contact",
      "Lead",
      "User"
    ],
    "IncentiveAttributes": [
      "InterestRateDiscountIncentiveAttributes",
      "InterestRateIncentiveAttributes",
      "PurchasePriceFixedByTermIncentiveAttributes",
      "PurchasePriceFixedIncentiveAttributes",
      "PurchasePricePercentageIncentiveAttributes",
      "ResidualRateIncentiveAttributes"
    ],
    "InventoryItem": [
      "RetailItem",
      "TradeInItem"
    ],
    "InventoryItemAttributes": [
      "MotorcycleAttributes",
      "VehicleAttributes"
    ],
    "InventoryItemFilter": [
      "RetailItemConnectionFilter"
    ],
    "InventoryItemSpecificationAttributes": [
      "VehicleSpecificationAttributes"
    ],
    "LeadActivityAttributes": [
      "FormLeadActivityAttributes",
      "InquiryLeadActivityAttributes",
      "PhoneCallLeadActivityAttributes",
      "PurchaseLeadActivityAttributes",
      "SubscriptionChangeLeadActivityAttributes",
      "TestDriveLeadActivityAttributes",
      "TradeInLeadActivityAttributes",
      "WalkInLeadActivityAttributes"
    ],
    "ManufacturerOption": [
      "ManufacturerVehicleOption"
    ],
    "PaymentCalculation": [
      "CashPaymentCalculation",
      "FinancePaymentCalculation",
      "LeasePaymentCalculation"
    ],
    "RetailBulkAdjustmentAttributes": [
      "DescriptionRetailBulkAdjustmentAttributes",
      "DisclaimerRetailBulkAdjustmentAttributes",
      "OverlayRetailBulkAdjustmentAttributes",
      "PriceRetailBulkAdjustmentAttributes",
      "PromotionRetailBulkAdjustmentAttributes"
    ],
    "RetailPricing": [
      "CashRetailPricing",
      "FinanceRetailPricing",
      "LeaseRetailPricing"
    ],
    "RetailPricingConfiguration": [
      "CashRetailPricingConfiguration",
      "FinanceRetailPricingConfiguration",
      "LeaseRetailPricingConfiguration"
    ],
    "RooftopIntegrationAttributes": [
      "CppRooftopIntegrationAttributes",
      "InventoryExportRooftopIntegrationAttributes",
      "LeadDistributionRooftopIntegrationAttributes",
      "OemAccountExportRooftopIntegrationAttributes",
      "PhoneCallTrackingRooftopIntegrationAttributes"
    ],
    "TradeInAppraisal": [
      "TradeInAppraisalExternal",
      "TradeInAppraisalInternal"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ConnectionFilter": [
      "AppointmentConnectionFilter",
      "ConversationConnectionFilter",
      "LeadConnectionFilter",
      "RetailItemConnectionFilter",
      "TaskConnectionFilter",
      "TradeInItemConnectionFilter"
    ],
    "Event": [
      "ConversationConnectionUpdatedEvent",
      "ConversationItemCreatedEvent",
      "LeadActivityConnectionUpdatedEvent",
      "PushNotificationEvent"
    ],
    "Filter": [
      "DateTimeFilter",
      "FacetFilter",
      "RangeFilter",
      "ToggleGroupFilter"
    ],
    "Identity": [
      "Contact",
      "Lead",
      "User"
    ],
    "IncentiveAttributes": [
      "InterestRateDiscountIncentiveAttributes",
      "InterestRateIncentiveAttributes",
      "PurchasePriceFixedByTermIncentiveAttributes",
      "PurchasePriceFixedIncentiveAttributes",
      "PurchasePricePercentageIncentiveAttributes",
      "ResidualRateIncentiveAttributes"
    ],
    "InventoryItem": [
      "RetailItem",
      "TradeInItem"
    ],
    "InventoryItemAttributes": [
      "MotorcycleAttributes",
      "VehicleAttributes"
    ],
    "InventoryItemFilter": [
      "RetailItemConnectionFilter"
    ],
    "InventoryItemSpecificationAttributes": [
      "VehicleSpecificationAttributes"
    ],
    "LeadActivityAttributes": [
      "FormLeadActivityAttributes",
      "InquiryLeadActivityAttributes",
      "PhoneCallLeadActivityAttributes",
      "PurchaseLeadActivityAttributes",
      "SubscriptionChangeLeadActivityAttributes",
      "TestDriveLeadActivityAttributes",
      "TradeInLeadActivityAttributes",
      "WalkInLeadActivityAttributes"
    ],
    "ManufacturerOption": [
      "ManufacturerVehicleOption"
    ],
    "PaymentCalculation": [
      "CashPaymentCalculation",
      "FinancePaymentCalculation",
      "LeasePaymentCalculation"
    ],
    "RetailBulkAdjustmentAttributes": [
      "DescriptionRetailBulkAdjustmentAttributes",
      "DisclaimerRetailBulkAdjustmentAttributes",
      "OverlayRetailBulkAdjustmentAttributes",
      "PriceRetailBulkAdjustmentAttributes",
      "PromotionRetailBulkAdjustmentAttributes"
    ],
    "RetailPricing": [
      "CashRetailPricing",
      "FinanceRetailPricing",
      "LeaseRetailPricing"
    ],
    "RetailPricingConfiguration": [
      "CashRetailPricingConfiguration",
      "FinanceRetailPricingConfiguration",
      "LeaseRetailPricingConfiguration"
    ],
    "RooftopIntegrationAttributes": [
      "CppRooftopIntegrationAttributes",
      "InventoryExportRooftopIntegrationAttributes",
      "LeadDistributionRooftopIntegrationAttributes",
      "OemAccountExportRooftopIntegrationAttributes",
      "PhoneCallTrackingRooftopIntegrationAttributes"
    ],
    "TradeInAppraisal": [
      "TradeInAppraisalExternal",
      "TradeInAppraisalInternal"
    ]
  }
};
      export default result;
    