import { gql } from '@apollo/client';

export const leadName = gql`
  fragment LeadNameFragment on Lead {
    id
    firstName
    lastName
    displayName
    rgbHex
  }
`;
