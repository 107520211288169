import { useCallback } from 'react';

import styled from 'styled-components/macro';

import type { BubbleMenuProps } from 'components/ui/editor/components/shared/BubbleMenu';
import BubbleMenu from 'components/ui/editor/components/shared/BubbleMenu';
import EditorIconButton from 'components/ui/editor/components/shared/EditorIconButton';
import EditLinkIcon from 'components/ui/icons/EditLinkIcon';
import LinkExternalIcon from 'components/ui/icons/LinkExternalIcon';
import LinkUnsetIcon from 'components/ui/icons/LinkUnsetIcon';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { translate } from 'utils/intlUtils';
import { openNewTab } from 'utils/urlUtils';

const { t } = translate;

const ActiveLinkBubbleMenu = styled(BubbleMenu)`
  padding: 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const ActiveLinkDialog = () => {
  const { clientType, editor, isLinkDialogOpen, openEditLinkDialog } = useRichTextEditor();
  const isMobileClientType = clientType === RichTextClientType.MOBILE;

  /**
   * Opens set link dialog to modify link
   */
  const handleEditLinkClick = useCallback(() => {
    if (!editor) {
      return;
    }

    openEditLinkDialog();
  }, [editor, openEditLinkDialog]);

  /**
   * Unset link
   */
  const handleUnsetLinkClick = useCallback(() => {
    editor?.commands.unsetLink();
  }, [editor?.commands]);

  /**
   * Open link in new tab
   */
  const handleExternalLinkClick = useCallback(() => {
    const url = editor?.getAttributes('link').href || '';

    if (url !== '') {
      openNewTab(url);
    }
  }, [editor]);

  /**
   * When text selection, only show active link dialogs on active links
   */
  const handleOnSelectionUpdate = useCallback<NonNullable<BubbleMenuProps['shouldShowOnTextSelection']>>(
    editor => editor.isActive('link'),
    []
  );

  return (
    <ActiveLinkBubbleMenu
      isOpen={!!editor?.isActive('link') && !isLinkDialogOpen}
      shouldShowOnTextSelection={handleOnSelectionUpdate}
    >
      <Container>
        <EditorIconButton
          data-testid={ElementTestId.EDITOR_BUTTON_EDIT_LINK}
          icon={EditLinkIcon}
          iconProps={{ height: 16, width: 16 }}
          onClick={handleEditLinkClick}
          tooltip={isMobileClientType ? undefined : { children: t('edit_link') }}
        />
        {!isMobileClientType && (
          <EditorIconButton
            data-testid={ElementTestId.EDITOR_BUTTON_EXTERNAL_LINK}
            icon={LinkExternalIcon}
            iconProps={{ height: 16, width: 16 }}
            onClick={handleExternalLinkClick}
            tooltip={{ children: t('visit_url') }}
          />
        )}
        <EditorIconButton
          data-testid={ElementTestId.EDITOR_BUTTON_UNLINK}
          icon={LinkUnsetIcon}
          iconProps={{ height: 16, width: 16 }}
          onClick={handleUnsetLinkClick}
          tooltip={isMobileClientType ? undefined : { children: t('unlink') }}
        />
      </Container>
    </ActiveLinkBubbleMenu>
  );
};

export default ActiveLinkDialog;
