import { gql } from '@apollo/client';

export const inventoryItemPhoto = gql`
  fragment InventoryItemPhotoFragment on InventoryItemPhoto {
    id
    damageLocation
    damageLocationName
    shotCode
    type
    typeName
    lightboxPhoto: url(width: 1280, height: 960, crop: LIMIT)
    gridPhoto: url(width: 410, height: 308)
  }
`;
