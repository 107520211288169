import { gql } from '@apollo/client';

export const mailboxList = gql`
  fragment MailboxListFragment on Mailbox {
    id
    name
    active
    conversationType
    conversationTypeName
    created
    updated
  }
`;
