import type StepField from 'components/core/createModify/interfaces/stepField';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { FeatureBundleSet } from 'enums/featureBundle';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import type { Rooftop } from 'store/api/graph/interfaces/types';
import { RetailItemLockableField, VehicleAttributesLockableField } from 'store/api/graph/interfaces/types';
import { isStepFieldArray } from 'utils/arrayUtils';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';

import { RetailItemDetailsBuilderFields } from './interfaces';

/**
 * Determine if the bulk adjustment builder step is available. This is determined by seeing if the rooftop has at least
 * one payment option enabled
 */
export const isBulkAdjustmentStepEnabled = ({
  rooftop,
  featureFlagRooftopPackageEnabled,
}: {
  rooftop: Rooftop;
  featureFlagRooftopPackageEnabled: boolean;
}) => {
  const isRetailCashPaymentOptionEnabled = isFeatureEnabledForRooftop({
    rooftop,
    feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
    featureFlagOn: featureFlagRooftopPackageEnabled,
  });
  const isRetailFinancePaymentOptionEnabled = isFeatureEnabledForRooftop({
    rooftop,
    feature: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
    featureFlagOn: featureFlagRooftopPackageEnabled,
  });
  const isRetailLeasePaymentOptionEnabled = isFeatureEnabledForRooftop({
    rooftop,
    feature: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
    featureFlagOn: featureFlagRooftopPackageEnabled,
  });

  return isRetailCashPaymentOptionEnabled || isRetailLeasePaymentOptionEnabled || isRetailFinancePaymentOptionEnabled;
};

/** A list of queryVar that are lockable on the FE only, and are omitted from mutations */
export const NoOpLockableFields: string[] = [DetailsInventoryItemBuilderFields.MAKE_ID];

/**
 * A map of stepfield queryVars and their associated RetailItemLockableField.
 * Necessary because queryVar and RetailItemLockableField don't always align automatically.
 */
export const lockableFieldQueryVarMap = {
  [DetailsInventoryItemBuilderFields.MODEL_ID]: RetailItemLockableField._model,
  [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: RetailItemLockableField._subModel,
  [DetailsInventoryItemBuilderFields.TRIM_ID]: RetailItemLockableField._trim,
};

/** Get the value from the API defined LockableField enums that corresponds to the provided queryVar */
export const getLockableFieldId = (queryVar: string): string | undefined => {
  const id =
    lockableFieldQueryVarMap[queryVar] || `_${queryVar.replace(InventoryItemAttributesPointer.VEHICLE + '.', '')}`;

  return Object.values(RetailItemLockableField).includes(id) ||
    Object.values(VehicleAttributesLockableField).includes(id)
    ? id
    : undefined;
};

/** A method for updating the selectedValue for LOCKED_FIELDS and VEHICLE_LOCKED_FIELDS stepfields */
export const updateLockedFieldsSelectedValue = (fields: StepField[]) => {
  if (fields.length === 0) {
    return;
  }

  const lockedFieldsField = getStepField(RetailItemDetailsBuilderFields.LOCKED_FIELDS, fields);
  const vehicleLockedFieldsField = getStepField(RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS, fields);
  const nextLockedFields: string[] = [
    ...(lockedFieldsField.selectedValue || []),
    ...(vehicleLockedFieldsField.selectedValue || []),
  ];

  // Using the field `isLocked` property, add/remove the lockable field id from the next set of selectedValues
  const toggleLock = (field, fieldId) => {
    const currentIndex = nextLockedFields.indexOf(fieldId);

    // Avoid adding noop fields or duplicates
    if (field.isLocked && !NoOpLockableFields.includes(field.queryVar) && currentIndex === -1) {
      nextLockedFields.push(fieldId);
    }
    // Remove existing field
    if (field.isLocked === false && currentIndex > -1) {
      nextLockedFields.splice(currentIndex, 1);
    }
  };

  // Iterate each stepfield to determine whether it should be added/removed from nextLockedFields
  for (const field of fields) {
    const fieldId = getLockableFieldId(field.queryVar);

    // StepFields with groupSubType FIELD_GROUP contain options that can also be lockable StepFields
    if (field.groupSubTypes?.includes(StepFieldSubType.FIELD_GROUP) && isStepFieldArray(field.options)) {
      for (let optionIndex = 0; optionIndex < field.options.length; optionIndex++) {
        const subField = field.options[optionIndex];
        const subFieldId = getLockableFieldId(subField.queryVar);

        if (!subFieldId) {
          continue;
        }

        toggleLock(subField, subFieldId);
      }
    }

    if (!fieldId) {
      continue;
    }

    toggleLock(field, fieldId);
  }

  lockedFieldsField.selectedValue = nextLockedFields.filter(fieldId =>
    Object.keys(RetailItemLockableField).includes(fieldId)
  );
  vehicleLockedFieldsField.selectedValue = nextLockedFields.filter(fieldId =>
    Object.keys(VehicleAttributesLockableField).includes(fieldId)
  );
};

type HandleConfirmToggleFieldLockOptions = {
  /** The queryVar for the stepfield */
  queryVar: string;
  /** The queryVar for a specific option within a stepField of groupType FIELD_GROUP */
  optionQueryVar?: string;
  /** Allows for providing an alternative array to reference when this.fields is not used */
  fields: StepField[];
};

/** Updates the `isLocked` key for the stepField identified by queryVar or optionQueryVar depending on groupSubType */
export const handleConfirmToggleFieldLock = ({
  queryVar,
  optionQueryVar,
  fields,
}: HandleConfirmToggleFieldLockOptions) => {
  if (fields.length === 0) {
    return;
  }

  const linkedLockableFields: string[] = [
    DetailsInventoryItemBuilderFields.YEAR,
    DetailsInventoryItemBuilderFields.MAKE_ID,
    DetailsInventoryItemBuilderFields.MODEL_ID,
    DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
    DetailsInventoryItemBuilderFields.TRIM_ID,
  ];

  // Update the isLocked status for the option within a stepfield of groupType FIELD_GROUP
  if (optionQueryVar) {
    const field = getStepField(queryVar, fields);

    if (field.groupSubTypes?.includes(StepFieldSubType.FIELD_GROUP) && isStepFieldArray(field.options)) {
      const option = field.options.find(option => option.queryVar === optionQueryVar);

      if (option) {
        option.isLocked = !option.isLocked;
      }
    }
  } else {
    const field = getStepField(queryVar, fields);
    const isLocked = field?.isLocked;
    // Update isLocked key for dependent fields
    if (linkedLockableFields.includes(queryVar)) {
      for (const key of linkedLockableFields) {
        const field = getStepField(key, fields);
        field.isLocked = !isLocked;
      }
    } else {
      field.isLocked = !isLocked;
    }
  }
};

/**
 * A callback function to set up locking for a stepField within the constructor
 * TODO: [ED-10856] Improve type for `data` argument to be more specific
 */
export const initLockingField = (stepField: StepField, data: any, isFieldLockingVisible: boolean) => {
  const fieldId = getLockableFieldId(stepField.queryVar);
  const isLockable = isFieldLockingVisible && (fieldId || NoOpLockableFields.includes(stepField.queryVar));

  if (isLockable) {
    stepField.groupSubTypes = stepField.groupSubTypes || [];

    if (!stepField.groupSubTypes.includes(StepFieldSubType.LOCKABLE)) {
      stepField.groupSubTypes.push(StepFieldSubType.LOCKABLE);
    }

    // Make is not actually lockable in the BE, so isLocked is based on it's dependent fields
    const isMakeIdLocked =
      stepField.queryVar === DetailsInventoryItemBuilderFields.MAKE_ID &&
      [
        DetailsInventoryItemBuilderFields.YEAR,
        DetailsInventoryItemBuilderFields.MODEL_ID,
        DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
        DetailsInventoryItemBuilderFields.TRIM_ID,
      ]
        .map(id => getLockableFieldId(id))
        .some(fieldId => data?.lockedFields?.includes(fieldId));

    stepField.isLocked =
      data?.lockedFields?.includes(fieldId) ||
      data?.vehicleAttributes?.lockedFields?.includes(fieldId) ||
      isMakeIdLocked;
  }

  return stepField;
};
