import { cloneDeep, get, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type StepFieldHyperlink from 'components/core/createModify/interfaces/stepFieldHyperlink';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps, StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import { RETAIL_ITEM_FLAGS } from 'components/sections/createModify/inventoryItems/retailItem/steps/constants';
import { RetailItemDetailVinDecodeImplementer } from 'components/sections/createModify/inventoryItems/retailItem/steps/implementers/RetailItemDetailVinDecode';
import { RetailItemMMSTFieldsImplementer } from 'components/sections/createModify/inventoryItems/retailItem/steps/implementers/RetailItemMMSTFields';
import {
  CarfaxReportErrors,
  RetailItemBuilderSteps,
  RetailItemDetailsBuilderFields,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import {
  handleConfirmToggleFieldLock,
  initLockingField,
  isBulkAdjustmentStepEnabled,
  updateLockedFieldsSelectedValue,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/utils';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import {
  ManufacturerOptions,
  StandardEquipmentOptions,
  TechnicalSpecificationsOptions,
} from 'components/sections/createModify/inventoryItems/steps/OptionsFields';
import { TrimSelectionFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { isWarrantyAvailable } from 'components/sections/inventoryItems/retailItems/warrantyUtils';
import {
  getManufacturerOptions,
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
  getStandardEquipmentOptions,
  getTagOptions,
  getTechnicalSpecificationsOptions,
} from 'components/sections/shared/ItemMetaHelpers';
import { vehicleManufacturerOptionsMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { FeatureBundleSet } from 'enums/featureBundle';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { StepFieldType } from 'enums/stepFieldType';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import {
  InventoryItemCondition,
  InventoryItemType,
  MakeModelSubModelTrimSource,
  VehicleBodyType,
} from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType, SelectOption } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  addDisplayType,
  defineFieldValues,
  getOptionsFromStepField,
  getStepField,
  objectToStepFieldArray,
  parseCategoryGroups,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { getRemovedAttributes } from 'utils/inventoryItemUtils';

const { t } = translate;

interface InventoryDetailsStepState extends StepComponentState {
  hasShownMMSTWarning: boolean;
}

class RetailItemDetailsStep extends StepComponentCore {
  state: InventoryDetailsStepState = {
    ...defaultState,
    hasShownMMSTWarning: false,
  };
  selectedTrim: StepField;

  // Implementers
  mmstFields: RetailItemMMSTFieldsImplementer;
  vinDecoder: RetailItemDetailVinDecodeImplementer;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { tierId, data, steps, metadata, isCreating, entityType, activeStep },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags,
      },
      setTier,
    } = context;
    this.context = context;

    const { rooftop } = data;

    const isFieldLockingVisible = metadata?.isFieldLockingVisible;

    if (
      !isBulkAdjustmentStepEnabled({ rooftop, featureFlagRooftopPackageEnabled: featureFlags.rooftopPackageEnabled })
    ) {
      // Hide the bulk adjustment step if none of the retail payment options are available
      setTier(tierId, { steps: steps?.filter(step => step.id !== RetailItemBuilderSteps.BULK_ADJUSTMENTS) });
    }

    // Initialize implementer classes
    this.vinDecoder = new RetailItemDetailVinDecodeImplementer(this);
    this.mmstFields = new RetailItemMMSTFieldsImplementer(this);

    this.selectedTrim = {
      label: t('trim'),
      groupType: StepFieldType.DROPDOWN,
      queryVar: TrimSelectionFields.DECODED_TRIM_ID,
      subStep: [SubStepType.DEFAULT],
      options: [],
    };

    const removedAttributes = getRemovedAttributes(data);

    // If this vehicle is not a truck or commercial type, then the cab type and cargo bed fields need to be hidden
    const isTruckOrCommercialVehicle = [VehicleBodyType.COMMERCIAL, VehicleBodyType.TRUCK].includes(
      data.vehicleAttributes?.bodyType
    );

    const trimHasChromeSource = data.trimName?.source === MakeModelSubModelTrimSource.CHROME;

    const selectedStandardEquipment = get(data, InventoryItem.STANDARD_EQUIPMENT)?.filter(item => item.enabled);
    const selectedTechnicalSpecs = get(data, InventoryItem.TECHNICAL_SPECIFICATIONS)?.filter(item => item.enabled);

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
        selectedValue: data?.lockedFields || [],
      },
      [RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS]: {
        selectedValue: data?.[InventoryItemAttributesPointer.VEHICLE]?.lockedFields || [],
      },
      // Presets
      [RetailItemDetailsBuilderFields.CONDITION]: {
        selectedValue: data?.condition || InventoryItemCondition.USED,
      },
      [DetailsInventoryItemBuilderFields.STATUS]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !!isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !!isCreating },
        ]),
      },
      [DetailsInventoryItemBuilderFields.ROOFTOP_ID]: {
        selectedValue: { ...data.rooftop, name: data.rooftop.name?.value },
        /**
         * Rooftop is only visible when `isCreating` an `EntityType.RETAIL_ITEM` with GLOBAL
         * or WHITE_LABEL user scope
         */
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isCreating || !canAccessMultipleRooftops,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isCreating,
          },
        ]),
      },
      [DetailsInventoryItemBuilderFields.YEAR]: {
        selectedValue: data.year ?? null,
      },
      [DetailsInventoryItemBuilderFields.MAKE_ID]: {
        selectedValue: data.makeName
          ? {
              id: data.makeName.id,
              name: data.makeName.name?.value,
            }
          : null,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.OMITTED, active: true }]),
      },
      [DetailsInventoryItemBuilderFields.MODEL_ID]: {
        selectedValue: data.modelName
          ? {
              id: data.modelName.id,
              name: data.modelName.name?.value,
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.makeName?.id }),
      },
      [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: {
        selectedValue: data.subModelName
          ? {
              id: data.subModelName.id,
              name: data.subModelName.name?.value,
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.modelName?.id }),
      },
      [DetailsInventoryItemBuilderFields.TRIM_ID]: {
        label: t('trim'),
        selectedValue: data.subModelName
          ? {
              ...(data.trimName || {}),
              id: data.trimName?.id || null,
              name: data.trimName?.name?.value || t('other_trim'),
            }
          : null,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !data.subModelName?.id }),
      },
      [RetailItemDetailsBuilderFields.TAG_IDS]: {
        selectedValue: data.tagName || [],
      },
      [DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID]: {
        selectedValue: data.carfaxReport?.id,
        hyperlink: data.carfaxReport?.url
          ? ({
              url: data.carfaxReport.url,
              title: t('view_report'),
            } as StepFieldHyperlink)
          : undefined,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: data.type !== InventoryItemType.VEHICLE,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: data.type !== InventoryItemType.VEHICLE,
          },
        ]),
        settings: {
          disabled: !data.rooftop.carfaxId,
        },
      },
      [DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB]: {
        selectedValue: data.carfaxReport?.showWeb,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: data.type !== InventoryItemType.VEHICLE,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: data.type !== InventoryItemType.VEHICLE,
          },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !data.carfaxReport?.id,
          },
        ]),
        hasSeparator: true,
      },
      [DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES]: {
        selectedValue: data.carfaxReport?.badges,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: true }]),
      },
      [InventoryItem.VEHICLE_CAB_TYPE]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isTruckOrCommercialVehicle,
          },
        ]),
      },
      [InventoryItem.VEHICLE_CARGO_BED_LENGTH]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isTruckOrCommercialVehicle,
          },
        ]),
      },
      [RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT]: {
        selectedValue: selectedStandardEquipment,
        renderElement: StandardEquipmentOptions,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !!isCreating || !trimHasChromeSource },
          { type: StepFieldDisplayType.OMITTED, active: true },
        ]),
      },
      [RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS]: {
        selectedValue: selectedTechnicalSpecs,
        renderElement: TechnicalSpecificationsOptions,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !!isCreating || !trimHasChromeSource },
          { type: StepFieldDisplayType.OMITTED, active: true },
        ]),
      },
      [DetailsInventoryItemBuilderFields.MSRP]: {
        selectedValue: data?.msrp,
      },
      [RetailItemDetailsBuilderFields.FLAGS]: {
        displayType: setDisplayTypes({ type: StepFieldDisplayType.OMITTED, active: true }),
        options: RETAIL_ITEM_FLAGS.map(([flag, isSelected]) => ({
          label: InventoryItemSettings[flag].label,
          queryVar: flag,
          selectedValue: data[flag] ?? isSelected,
        })).map(stepField => initLockingField(stepField, data, isFieldLockingVisible)),
      },
      [RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !trimHasChromeSource,
        }),
        selectedValue: get(data, InventoryItem.VEHICLE_MANUFACTURER_OPTIONS),
        renderElement: ManufacturerOptions,
      },
      // TODO: Improve filter logic or use setter for attribute names
    })
      .filter(stepField => !removedAttributes.includes(stepField.queryVar.split('.')[0]))
      .map(stepField => initLockingField(stepField, data, isFieldLockingVisible));

    // Setting predefined values where applicable
    this.fields = defineFieldValues(this.fields, data, metadata);

    const trimField = getStepField('trimId', this.fields);
    const manufacturerOptionsField = getStepField(InventoryItem.VEHICLE_MANUFACTURER_OPTIONS, this.fields);
    const standardEqField = getStepField(RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT, this.fields);
    const techSpecsField = getStepField(RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS, this.fields);

    // Async subpanel configurations
    this.asyncConfigurations = {
      rooftopId: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                features: { retail: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.RETAIL,
                    featureFlagOn: featureFlags.rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      tagIds: {
        request: () =>
          getTagOptions({
            rooftopId: [getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id],
            entityType: entityType!,
          }),
        disableKeywordRefetch: true,
      },
      [RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES]: {
        request: async () => {
          const data = await getManufacturerOptions(trimField.selectedValue?.id);
          manufacturerOptionsField.subStepGroups = parseCategoryGroups(data);
          return data;
        },
        disableKeywordRefetch: true,
      },
      [RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS]: {
        request: async () => {
          const data = await getTechnicalSpecificationsOptions(trimField.selectedValue?.id);
          techSpecsField.subStepGroups = parseCategoryGroups(data);
          return data;
        },
        disableKeywordRefetch: true,
      },
      [RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT]: {
        request: async () => {
          const data = await getStandardEquipmentOptions(trimField.selectedValue?.id);
          standardEqField.subStepGroups = parseCategoryGroups(data);
          return data;
        },
        disableKeywordRefetch: true,
      },
    };
  }

  onConfirmToggleFieldLock(queryVar, optionQueryVar) {
    handleConfirmToggleFieldLock({ fields: this.fields, queryVar, optionQueryVar });

    this.forceUpdate();

    updateLockedFieldsSelectedValue(this.fields);
  }

  onItemAdd(preFill?: any) {
    const {
      tier: { currentStepField },
    } = this.props;

    const affectedFields = this.mmstFields.getMMSTWarningAffectedFields(currentStepField);

    // If the user is adding a custom MMST value show a warning dialog that any changes will clear tech specs/eq options
    if (this.mmstFields.isMMSTField(currentStepField!.queryVar) && affectedFields) {
      this.mmstFields.showMMSTWarning(affectedFields, () => {
        super.onItemAdd(preFill);
        // Only need to show this warning message once
        this.setState({ hasShownMMSTWarning: true });
      });
    } else {
      super.onItemAdd(preFill);
    }
  }

  async refreshVin() {
    const { toggleClosePrompt } = this.props;

    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields);

    // Clear any open alerts
    this.setTier({ alert: undefined });

    if (vinField.selectedValue) {
      // Warn the user first that updating the VIN will change vehicle data
      toggleClosePrompt({
        message: t('update_vin_message_vehicle_details_only'),
        title: t('change_vin'),
        confirmText: t('yes_continue'),
        cancelText: t('dont_update'),
        onClose: () => {
          toggleClosePrompt(undefined);
        },
        onConfirm: async () => this.vinDecoder.performVinDecode({ isRefreshing: true }),
        onComplete: success => {
          // On complete, close this prompt
          toggleClosePrompt(undefined);
          this.setOnClosePrompt(success ? undefined : this.defaultClosePrompt);
        },
        onCancel: () => {
          // On cancel, clear the close prompt and mark this step as complete
          this.setOnClosePrompt(undefined);
        },
      });
    }
  }

  async refreshCarfax() {
    const { setParentLoader, toggleClosePrompt } = this.props;
    const rooftop = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields).selectedValue;

    setParentLoader(true);
    const errors = await this.vinDecoder.performGetCarfaxReport({ rooftop });
    setParentLoader(false);

    if (errors) {
      toggleClosePrompt({
        title: t('carfax_report'),
        errorsOverride: [
          {
            message:
              errors === CarfaxReportErrors.NO_CARFAX_FOUND
                ? t('no_carfax_report_found')
                : t('no_carfax_account_on_this_rooftop'),
          },
        ],
        onComplete: () => {
          toggleClosePrompt(undefined);
        },
      });
    }
  }

  async onFieldActionClick(stepField): Promise<void> {
    if (stepField.queryVar === DetailsInventoryItemBuilderFields.VIN) {
      await this.refreshVin();
    } else if (stepField.queryVar === DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID) {
      await this.refreshCarfax();
    }
  }

  /**
   * Callback override for YMMT handling & cabType handling
   */
  async onFieldSelection(
    selectedField: StepField,
    value: any,
    persistSeededValues = false,
    advance = true,
    options?: { skipMMSTWarning: boolean }
  ) {
    const { setParentLoader } = this.props;

    const stepField = getStepField(selectedField.queryVar, this.fields);

    if (this.mmstFields.isMMSTField(stepField.queryVar) && !options?.skipMMSTWarning) {
      this.mmstFields.checkForMMSTFieldWarning(stepField, value);
      return;
    }

    super.onFieldSelection(selectedField, value);

    switch (selectedField.queryVar) {
      case InventoryItem.VEHICLE_BODY_TYPE: {
        /**
         * If the body type has changed to non-commercial or truck, then clear and hide the
         * cargo bed and cab type fields
         */
        const isTruckOrCommercial =
          !!stepField.selectedValue?.id &&
          [VehicleBodyType.COMMERCIAL, VehicleBodyType.TRUCK].includes(stepField.selectedValue?.id);

        const cabField = getStepField(InventoryItem.VEHICLE_CAB_TYPE, this.fields);
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isTruckOrCommercial }, cabField);
        if (!isTruckOrCommercial) {
          cabField.selectedValue = null;
        }

        const cargoBedField = getStepField(InventoryItem.VEHICLE_CARGO_BED_LENGTH, this.fields);
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isTruckOrCommercial }, cargoBedField);
        if (!isTruckOrCommercial) {
          cargoBedField.selectedValue = null;
        }
        this.forceUpdate();
        break;
      }

      case 'flags': {
        getStepField(stepField.queryVar, this.fields).options = value;
        break;
      }

      case TrimSelectionFields.DECODED_TRIM_ID: {
        this.selectedTrim.selectedValue = value;
        this.forceUpdate();

        setParentLoader(true);

        if (selectedField.queryAlias?.includes(TrimSelectionFields.VIN_REFRESHING)) {
          // If the selected trim came from vin refreshing, then just decode the vin data
          await this.vinDecoder.performVinDecode();
        } else {
          // If the selected trim came from saving the current step, then decode the vin and get new carfax report
          const rooftopField = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields);
          await this.vinDecoder.performVinDecodeAndGetCarfaxReport({ rooftop: rooftopField.selectedValue });
        }

        await this.refreshTrimSpecifications(value.id);
        this.setOnClosePrompt(this.defaultClosePrompt);
        setParentLoader(false);
        break;
      }
    }
  }

  onFieldChange(stepField: StepField, e) {
    super.onFieldChange(stepField, e, true);

    const {
      tier: {
        data: { carfaxReport },
      },
    } = this.props;

    // Clear trims if the vin field changes its user input value
    if (
      stepField.queryVar === DetailsInventoryItemBuilderFields.VIN &&
      getOptionsFromStepField(this.selectedTrim, this.props.tier.metadata).length > 0
    ) {
      this.vinDecoder.clearTrimSubStep();
      this.setState({ currentStepField: stepField });
      this.setTier({
        alert: undefined,
      });
    } else if (stepField.queryVar === 'type') {
      this.vinDecoder.clearTrimSubStep();
    }

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID) {
      const carfaxReportField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID, this.fields);
      const carfaxBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES, this.fields);
      const carfaxShowBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB, this.fields);

      // If the user has manually changed the carfax report id, then clear the 'View Report' link
      carfaxReportField.hyperlink =
        carfaxReportField.selectedValue === carfaxReport?.id
          ? ({
              url: carfaxReport.url,
              title: t('view_report'),
            } as StepFieldHyperlink)
          : undefined;

      if (carfaxReportField.selectedValue === carfaxReport?.id) {
        // If the user has re-entered the same carfax report id, then re-assign the carfax badges
        carfaxBadgesField.selectedValue = carfaxReport?.badges;
        removeDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      } else if (carfaxReportField.selectedValue) {
        /*
         * If the user has entered a carfax report id, then set the carfax badges to empty list ([]). This is a
         * workaround as the server doesn't know the badges associated with this report id. Also, now that there is
         * a carfax ID set, the show badges toggle can be enabled
         */
        carfaxBadgesField.selectedValue = [];
        removeDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      } else {
        /*
         * If the user has cleared the field, then mark the badges as null so that they can be cleared. Also, now
         * that there is no carfax report id, disable the show badges field
         */
        carfaxBadgesField.selectedValue = null;
        addDisplayType(carfaxShowBadgesField, StepFieldDisplayType.DISABLED);
      }

      this.forceUpdate();
    }
  }

  /**
   * Callback override for YMMT handling
   */
  async toggleSubPanel(stepField: StepField) {
    const { currentStepField } = this.state;
    const {
      tier: { metadata },
    } = this.props;

    // Making the call to get the next set of data, do not execute calls again if the step field is the same
    if (currentStepField !== stepField) {
      // Resetting any previously seeded values when opening fresh
      if (currentStepField) {
        currentStepField.seededValues = undefined;
      }

      // Dynamically formatting this.asyncConfigurations based on selecting field

      if (this.mmstFields.isMMSTField(stepField?.queryVar)) {
        this.mmstFields.toggleMMSTSubPanel(stepField);
      }

      if (
        stepField &&
        stepField.queryVar === DetailsInventoryItemBuilderFields.VIN &&
        getOptionsFromStepField(this.selectedTrim, metadata).length > 0
      ) {
        stepField.active = true;
        this.setState({
          currentStepField: this.selectedTrim,
          childrenBeforeSubStep: this.vinDecoder.renderTrimWarning(),
        });
      } else {
        this.setState({ childrenBeforeSubStep: undefined });
      }
      if (!stepField || stepField.queryVar !== DetailsInventoryItemBuilderFields.VIN) {
        // Manual override to disable vin active
        getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields).active = false;
        this.setTier({
          alert: undefined,
        });
      }

      void super.toggleSubPanel(stepField);
    }
  }

  /**
   * Given a trimId, refresh the trim specification data and update the standard equipment and technical specifications
   * fields with any new data
   * @param trimId - the trimId (must have a chrome source)
   */
  async refreshTrimSpecifications(trimId: string) {
    const technicalSpecField = getStepField(RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS, this.fields);
    const standardEqField = getStepField(RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT, this.fields);
    try {
      const response = await client.query({
        query: vehicleManufacturerOptionsMetaQuery,
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
        variables: { trimId },
      });

      const technicalSpecOptions = response.data?.trimSpecification.technicalSpecifications;
      const standardEqOptions = response.data?.trimSpecification.standardEquipment;

      technicalSpecField.options = technicalSpecOptions;
      standardEqField.options = standardEqOptions;

      // These new options should be pre-selected by default
      technicalSpecField.selectedValue = technicalSpecOptions;
      standardEqField.selectedValue = standardEqOptions;
    } catch (error) {
      logApiError(error);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, standardEqField);
      setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: true }, technicalSpecField);

      standardEqField.options = [];
      technicalSpecField.options = [];

      standardEqField.selectedValue = null;
      technicalSpecField.selectedValue = null;
      this.forceUpdate();

      return;
    }
  }

  async performSave(): Promise<boolean> {
    const { setTier } = this.context!;
    const {
      tier: {
        tierId,
        steps,
        isCreating,
        data: { type, condition = 'USED' },
        data,
      },
    } = this.props;
    const rooftopId = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id;

    const carfaxReportField = getStepField(
      DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID,
      this.fields
    ).selectedValue;
    const carfaxShowBadgesField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB, this.fields);

    /**
     * If there is no carfax report, then the show badges should be cleared (ie set to null). If there is a carfax
     * report, then ensure that the show badges does not have a null value as it can not be cleared when
     * there is an active report.
     */
    carfaxShowBadgesField.selectedValue = carfaxReportField ? !!carfaxShowBadgesField.selectedValue : null;

    // Get the value of the condition field and body type field to see if a warranty is available
    const conditionFieldValue = getStepField(RetailItemDetailsBuilderFields.CONDITION, this.fields).selectedValue;
    const bodyTypeFieldValue = getStepField(InventoryItem.VEHICLE_BODY_TYPE, this.fields).selectedValue?.id;
    const showWarrantyField = getStepField(DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB, this.fields);
    const warrantyCategoryOverrideField = getStepField(
      DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE,
      this.fields
    );

    // Take the existing retail item and update the condition and bodyType to the latest field values
    const updatedRetailItem = cloneDeep(data);
    set(updatedRetailItem, RetailItemDetailsBuilderFields.CONDITION, conditionFieldValue);
    set(updatedRetailItem, InventoryItem.VEHICLE_BODY_TYPE, bodyTypeFieldValue);

    /*
     * Now check to see if there is a warranty available for this retail item. If not, then we'll clear the show
     * warranty field
     */
    const isWarrantyEnabled =
      isWarrantyAvailable(updatedRetailItem as RetailItemResponseType) && !!get(data, InventoryItem.VEHICLE_MILEAGE);
    showWarrantyField.selectedValue = isWarrantyEnabled ? true : null;

    if (!isWarrantyEnabled) {
      warrantyCategoryOverrideField.selectedValue = null;
    }

    const standardEquipmentField = getStepField(RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT, this.fields);
    const techSpecsField = getStepField(RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS, this.fields);

    const variableOverrides = {};
    if (isCreating) {
      variableOverrides['rooftopId'] = rooftopId;
    }
    // Get the IDs of the standard equipment items that are not selected
    variableOverrides['disabledStandardEquipmentIds'] = (standardEquipmentField.options as SelectOption[])
      ?.filter(
        allItems => !standardEquipmentField.selectedValue?.some(selectedItems => selectedItems.id === allItems.id)
      )
      .map(disabledItems => disabledItems.id);

    // Get the IDs of the technical specification items that are not selected
    variableOverrides['disabledTechnicalSpecificationIds'] = (techSpecsField.options as SelectOption[])
      ?.filter(allItems => !techSpecsField.selectedValue?.some(selectedItems => selectedItems.id === allItems.id))
      .map(disabledItems => disabledItems.id);

    const variablePresets = { condition };

    if (isCreating) {
      variablePresets['type'] = type;
    }

    const success = await super.save(variablePresets, variableOverrides);
    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      if (!['VIN', 'CAPTURE'].includes(step.id)) {
        step.isEnabled = true;
      }
    }
    setTier(tierId, { steps });
    return true;
  }

  /*
   * Overriding core async save method with custom formatted fields, preset/overriden variables,
   * and dynamic queries based on create/modify context
   */
  async save(): Promise<boolean> {
    const {
      tier: { data },
      toggleClosePrompt,
      onStepComplete,
    } = this.props;

    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.fields);
    const rooftopField = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.fields);

    if (vinField.selectedValue === data.vin) {
      return this.performSave();
    } else {
      // If the user has entered a different VIN, show prompt for decoding this new VIN
      toggleClosePrompt({
        message: t('change_vin_message'),
        title: t('change_vin'),
        confirmText: t('yes_continue'),
        cancelText: t('dont_update'),
        onClose: () => {
          toggleClosePrompt(undefined);
        },
        onConfirm: async () =>
          this.vinDecoder.performVinDecodeAndGetCarfaxReport({ rooftop: rooftopField.selectedValue }),
        onComplete: success => {
          // On complete, close this prompt
          toggleClosePrompt(undefined);

          if (success) {
            // If VIN decode was a success, clear the close prompt and mark this step complete
            this.setOnClosePrompt(undefined);
            onStepComplete(false);
          } else {
            // If VIN decode was not a success, then reset the close prompt to default settings
            this.setOnClosePrompt(this.defaultClosePrompt);
          }
        },
        onCancel: () => {
          // On cancel, clear the close prompt and mark this step as complete
          this.setOnClosePrompt(undefined);
          onStepComplete(true);
        },
      });
      return false;
    }
  }
}

export default RetailItemDetailsStep;
