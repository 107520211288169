import { FeatureBundleSet } from 'enums/featureBundle';
import type { RetailItem } from 'store/api/graph/interfaces/types';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';

/** Get available payment types based on available configuration */
export const getAvailablePaymentMethods = (data: RetailItem, isRooftopPackageFlagEnabled: boolean): PaymentOption[] => {
  const isCashPaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: data.rooftop,
    feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
    featureFlagOn: isRooftopPackageFlagEnabled,
  });

  const isFinancePaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: data.rooftop,
    feature: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
    featureFlagOn: isRooftopPackageFlagEnabled,
  });

  const isLeasePaymentOptionFeatureEnabled = isFeatureEnabledForRooftop({
    rooftop: data.rooftop,
    feature: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
    featureFlagOn: isRooftopPackageFlagEnabled,
  });

  return [PaymentOption.FINANCE, PaymentOption.CASH, PaymentOption.LEASE].filter(
    option =>
      (option === PaymentOption.CASH &&
        data?.cashRetailPricing?.configuration?.enabled &&
        isCashPaymentOptionFeatureEnabled) ||
      (option === PaymentOption.FINANCE &&
        data?.financeRetailPricing?.configuration?.enabled &&
        isFinancePaymentOptionFeatureEnabled) ||
      (option === PaymentOption.LEASE &&
        data?.leaseRetailPricing?.configuration?.enabled &&
        isLeasePaymentOptionFeatureEnabled)
  );
};
