import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import DollarSignIcon from 'components/ui/icons/DollarSignIcon';
import EmailIcon from 'components/ui/icons/EmailIcon';
import FormIcon from 'components/ui/icons/FormIcon';
import PhoneIcon from 'components/ui/icons/PhoneIcon';
import TestDriveIcon from 'components/ui/icons/TestDriveIcon';
import TradeInIcon from 'components/ui/icons/TradeInIcon';
import WalkInIcon from 'components/ui/icons/WalkInIcon';
import Image, { FallbackIcon } from 'components/ui/images/Images';
import type {
  LeadActivityAttributes,
  SubscriptionChangeLeadActivityAttributes,
} from 'store/api/graph/interfaces/types';
import { LeadActivityType } from 'store/api/graph/interfaces/types';
import type { LeadActivityResponseType } from 'store/api/graph/responses/responseTypes';
import { BODY_TEXT_TERTIARY, DIVIDER } from 'styles/color';
import { BLUE_500, GREEN_600, NEUTRAL_0 } from 'styles/tokens';
import { FONT_WEIGHT_NORMAL } from 'styles/typography';

import { translate } from './intlUtils';

const { t } = translate;

interface LeadActivityIconStyledProps {
  background?: string;
  color?: string;
}

export const LeadActivityIcon = styled(Image)<LeadActivityIconStyledProps>`
  flex-shrink: 0;

  ${FallbackIcon} {
    background: ${({ background }) => background || BLUE_500};
  }

  svg {
    color: ${({ color }) => color || NEUTRAL_0};
  }
`;

/** The icon for the given lead activity type */
const iconImageByType: {
  [key in LeadActivityType]: (leadActivity: LeadActivityResponseType, sizeOverride: number | undefined) => JSX.Element;
} = {
  [LeadActivityType.FORM]: (_leadActivity, sizeOverride) => (
    <FormIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.INQUIRY]: (_leadActivity, sizeOverride) => (
    <EmailIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.TRADE_IN]: (_leadActivity, sizeOverride) => (
    <TradeInIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.PHONE_CALL]: (_leadActivity, sizeOverride) => (
    <PhoneIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.WALK_IN]: (_leadActivity, sizeOverride) => (
    <WalkInIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.TEST_DRIVE]: (_leadActivity, sizeOverride) => (
    <TestDriveIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.PURCHASE]: (_leadActivity, sizeOverride) => (
    <DollarSignIcon {...(sizeOverride && { width: sizeOverride, height: sizeOverride })} />
  ),
  [LeadActivityType.SUBSCRIPTION_CHANGE]: ({ attributesName }, sizeOverride) => (
    <EmailIcon
      {...(sizeOverride && { width: sizeOverride, height: sizeOverride })}
      color={(attributesName as SubscriptionChangeLeadActivityAttributes).subscribed ? undefined : BODY_TEXT_TERTIARY}
    />
  ),
};

/**
 * Any background or color overrides for the given lead activity type.
 * Defaults to a `NEUTRAL_0` icon on a `BLUE_500` background.
 */
const iconStyledPropsOverrideByType: {
  [type in LeadActivityType]?: (leadActivity: LeadActivityResponseType) => LeadActivityIconStyledProps;
} = {
  [LeadActivityType.PURCHASE]: () => ({ background: GREEN_600 }),
  [LeadActivityType.SUBSCRIPTION_CHANGE]: ({ attributesName }) => {
    const isSubscribed = (attributesName as SubscriptionChangeLeadActivityAttributes).subscribed;
    return {
      background: isSubscribed ? GREEN_600 : DIVIDER,
      color: isSubscribed ? undefined : BODY_TEXT_TERTIARY,
    };
  },
};

/**
 * Any primary text overrides for the given lead activity type.
 * Defaults to `typeName` and `sourceName` from the BE, with the 'x_from_y' sentence structure.
 */
const primaryTextOverrideByType: {
  [type in LeadActivityType]?: (leadActivity: LeadActivityResponseType) => {
    sentenceStructure?: string;
    typeName?: string;
    sourceName?: string;
  };
} = {
  [LeadActivityType.PURCHASE]: () => ({ typeName: t('vehicle_purchase') }),
  [LeadActivityType.SUBSCRIPTION_CHANGE]: ({ attributesName }) => {
    const isSubscribed = (attributesName as SubscriptionChangeLeadActivityAttributes).subscribed;
    return {
      sentenceStructure: isSubscribed ? 'x_to_y' : undefined,
      typeName: t(isSubscribed ? 'resubscribed' : 'unsubscribed'),
      sourceName: t('email_other'),
    };
  },
};

/** A set of functions used to get icon, background colour, and name display data for a Lead Activity item. */
export const leadActivityItemDisplayData = {
  getIconImage: (leadActivity: LeadActivityResponseType, sizeOverride?: number) =>
    iconImageByType[leadActivity.type]?.(leadActivity, sizeOverride),
  getIconStyledProps: (leadActivity: LeadActivityResponseType) =>
    iconStyledPropsOverrideByType[leadActivity.type]?.(leadActivity) || {},
  getPrimaryText: (leadActivity: LeadActivityResponseType) => {
    const overrides = primaryTextOverrideByType[leadActivity.type]?.(leadActivity);
    const typeName = overrides?.typeName || leadActivity.typeName;
    const sourceName = overrides?.sourceName || leadActivity.sourceName.name.value;
    const unformattedText = t(overrides?.sentenceStructure || 'x_from_y', [typeName, sourceName]);
    const particle = unformattedText.replace(typeName, '').replace(sourceName, '');
    return (
      <PrimaryText title={unformattedText}>
        {typeName}
        <span css={{ fontWeight: FONT_WEIGHT_NORMAL }}>{particle}</span>
        {sourceName}
      </PrimaryText>
    );
  },
};

/** Retrieves the inventoryItem property from the attributes of the given lead activity, depending on type. */
export const getLeadActivityInventoryItem = (attributes: LeadActivityAttributes) => {
  switch (attributes?.__typename) {
    case 'InquiryLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }

    case 'FormLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }

    case 'PurchaseLeadActivityAttributes': {
      return attributes.retailItemPurchased;
    }

    case 'WalkInLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }

    case 'TestDriveLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }

    case 'PhoneCallLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }

    case 'TradeInLeadActivityAttributes': {
      return attributes.retailItemOfInterest;
    }
  }
  return undefined;
};
