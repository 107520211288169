import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getLeadSourceOptions, getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { ConversationType } from 'store/api/graph/interfaces/types';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

import { MailboxBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore {
  static contextType = CreateModifyContext;

  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, data, seededData, isCreating },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
      },
    } = context;

    const rooftopName = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);

    const currentType = data?.conversationType || ConversationType.EMAIL;

    this.fields = defineFieldValues(
      objectToStepFieldArray(activeStep?.fields as StepFields, {
        [MailboxBuilderFields.CONVERSATION_TYPE]: {
          selectedValue: currentType,
          displayType: setDisplayTypes({
            type: StepFieldDisplayType.DISABLED,
            active: !isCreating,
          }),
        },
        [MailboxBuilderFields.ROOFTOP_ID]: {
          selectedValue: rooftopName,
          displayType: setDisplayTypes([
            {
              type: StepFieldDisplayType.DISABLED,
              active: !!rooftopName || !isCreating,
            },
            {
              type: StepFieldDisplayType.HIDDEN,
              active: !canAccessMultipleRooftops,
            },
          ]),
        },
        [MailboxBuilderFields.EMAIL_LOCAL_PART]: {
          selectedValue: data?.email,
          displayType: setDisplayTypes([
            { type: StepFieldDisplayType.DISABLED, active: !isCreating },
            { type: StepFieldDisplayType.HIDDEN, active: currentType !== ConversationType.EMAIL },
          ]),
        },
        [MailboxBuilderFields.AREA_CODE]: {
          displayType: setDisplayTypes([
            { type: StepFieldDisplayType.HIDDEN, active: currentType !== ConversationType.SMS },
            { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          ]),
        },
        [MailboxBuilderFields.SOURCE_ID]: {
          selectedValue: data?.sourceName,
          displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !isCreating }),
        },
      }),
      data
    );

    // Async subpanel configurations
    this.asyncConfigurations = {
      [MailboxBuilderFields.ROOFTOP_ID]: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
      [MailboxBuilderFields.SOURCE_ID]: {
        request: () =>
          getLeadSourceOptions(getStepField(MailboxBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id),
        disableKeywordRefetch: true,
      },
    };
  }

  // Overriding field selection callback
  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    if (stepField.queryVar === MailboxBuilderFields.CONVERSATION_TYPE) {
      const isEmail = e.currentTarget.value === ConversationType.EMAIL;
      setDisplayTypes(
        [
          { type: StepFieldDisplayType.HIDDEN, active: !isEmail },
          { type: StepFieldDisplayType.OMITTED, active: !isEmail },
        ],
        getStepField(MailboxBuilderFields.EMAIL_LOCAL_PART, this.fields)
      );

      setDisplayTypes(
        [
          { type: StepFieldDisplayType.HIDDEN, active: isEmail },
          { type: StepFieldDisplayType.OMITTED, active: isEmail },
        ],
        getStepField(MailboxBuilderFields.AREA_CODE, this.fields)
      );
    }
    super.onFieldChange(stepField, e);
  }
}

export default DetailsStep;
