import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Website, WebsiteSettings } from 'enums/columns/website';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { STATIC_CONFIGS } from './staticTableConfig';

export const websitesDefaultSortOrder = [];

export const WEBSITES = Object.freeze<TableCellData[]>([
  ...STATIC_CONFIGS,
  getCellDataByItemColumnType(Website.NAME, WebsiteSettings, { width: 320 }),
  getCellDataByItemColumnType(Website.URL, WebsiteSettings, { width: 320 }),
  getCellDataByItemColumnType(Website.STATUS, WebsiteSettings),
]);
