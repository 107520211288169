import { BadgeColors } from 'components/ui/shared/Badge';
import {
  LeadStatus,
  RetailItemStatus,
  TaskStatus,
  TradeInItemStatus,
  WebsiteStatus,
} from 'store/api/graph/interfaces/types';

const RetailStatusColors: { [key: string]: BadgeColors } = {
  [RetailItemStatus.DRAFT]: BadgeColors.NEUTRAL,
  [RetailItemStatus.FOR_SALE]: BadgeColors.DEFAULT,
  [RetailItemStatus.SOLD]: BadgeColors.POSITIVE,
};

const TradeInStatusColors: { [key: string]: BadgeColors } = {
  [TradeInItemStatus.DRAFT]: BadgeColors.NEUTRAL,
  [TradeInItemStatus.PENDING]: BadgeColors.DEFAULT,
  [TradeInItemStatus.COMPLETED_DEAL]: BadgeColors.POSITIVE,
  [TradeInItemStatus.LOST_DEAL]: BadgeColors.NEGATIVE,
};

const TaskStatusColors: { [key: string]: BadgeColors } = {
  [TaskStatus.NOT_STARTED]: BadgeColors.NEUTRAL,
  [TaskStatus.IN_PROGRESS]: BadgeColors.POSITIVE,
  [TaskStatus.COMPLETE]: BadgeColors.POSITIVE,
};
const LeadStatusColors: { [key: string]: BadgeColors } = {
  [LeadStatus.ACTIVE]: BadgeColors.POSITIVE,
  [LeadStatus.DORMANT]: BadgeColors.NEUTRAL,
  [LeadStatus.LOST]: BadgeColors.NEGATIVE,
  [LeadStatus.SOLD]: BadgeColors.POSITIVE,
  [LeadStatus.NEW]: BadgeColors.POSITIVE,
};

const WebsiteStatusColors: { [key: string]: BadgeColors } = {
  [WebsiteStatus.ACTIVE]: BadgeColors.POSITIVE,
  [WebsiteStatus.DRAFT]: BadgeColors.NEUTRAL,
  [WebsiteStatus.INACTIVE]: BadgeColors.NEGATIVE,
};

/**
 * Note that if there are duplicate keys, the last resolved entry will be returned.
 */
export const StatusColors: { [key: string]: BadgeColors } = {
  ...RetailStatusColors,
  ...TradeInStatusColors,
  ...TaskStatusColors,
  ...LeadStatusColors,
  ...WebsiteStatusColors,
};
