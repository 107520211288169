import { gql } from '@apollo/client';

import { mileage } from 'store/api/graph/fragments/mileage';

import { manufacturerOption } from './manufacturerOption';

export const vehicleAttributes = gql`
  fragment VehicleAttributesFragment on VehicleAttributes {
    options
    optionsNames
    manufacturerVehicleOptions {
      ...ManufacturerOptionFragment
    }
    mileage {
      ...MileageFragment
    }
    bodyType
    bodyTypeName
    cabType
    cabTypeName
    cargoBedLength
    exteriorColor
    exteriorColorName
    interiorColor
    interiorColorName
    numberOfDoors
    numberOfPassengers
    transmission
    transmissionName
    driveTrain
    driveTrainName
    displacement {
      amount
      formattedAmount
    }
    cylinders
    fuelType
    fuelTypeName
    lockedFields
  }
  ${mileage}
  ${manufacturerOption}
`;
