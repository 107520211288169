import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type StepField from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { RenderObjectSettings } from 'components/core/createModify/stepFields/interfaces';
import RooftopRetailBulkAdjustmentListItem from 'components/ui/lists/RooftopRetailBulkAdjustmentListItem';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

import { RetailBulkAdjustmentBuilderFields } from './interfaces';

const defaultFieldConfig: Partial<StepField> = {
  groupType: StepFieldType.RENDER_OBJECT,
  displayType: [StepFieldDisplayType.OMITTED],
  groupSubTypes: [StepFieldSubType.CHECKBOX, StepFieldSubType.MULTI_SELECT],
  renderElement: RooftopRetailBulkAdjustmentListItem,
  settings: {
    disableRemoveIcon: true,
  } as RenderObjectSettings,
  subStep: [SubStepType.DEFAULT],
  hasSeparator: true,
};

const RetailBulkAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.PRICE_ADJUSTMENTS]: {
    label: 'price_adjustment_other',
    ...defaultFieldConfig,
  },
  [RetailBulkAdjustmentBuilderFields.OVERLAY_ADJUSTMENTS]: {
    label: 'overlay_adjustment_other',
    ...defaultFieldConfig,
  },
  [RetailBulkAdjustmentBuilderFields.DESCRIPTION_ADJUSTMENTS]: {
    label: 'description_adjustment_other',
    ...defaultFieldConfig,
  },
  [RetailBulkAdjustmentBuilderFields.PROMOTION_ADJUSTMENTS]: {
    label: 'promotion_adjustment_other',
    ...defaultFieldConfig,
  },
  [RetailBulkAdjustmentBuilderFields.DISCLAIMER_ADJUSTMENTS]: {
    label: 'disclaimer_adjustment_other',
    ...defaultFieldConfig,
  },
};

export default RetailBulkAdjustmentFields;
