import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import routes from './RouterRoutes';

const Router = () => {
  const router = createBrowserRouter(createRoutesFromElements(routes));
  return <RouterProvider router={router} />;
};

export default Router;
