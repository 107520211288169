import {
  LEADS,
  LEADS_LIMITED,
  leadsDefaultSortOrder,
} from 'components/ui/tables/tableSettings/configs/leadsTableConfig';
import { getSortedColumns } from 'utils/tableUtils';

import { APPOINTMENTS, APPOINTMENTS_LIMITED, appointmentsDefaultSortOrder } from './configs/appointmentsTableConfig';
import { CONVERSATIONS, conversationsDefaultSortOrder } from './configs/conversationsTableConfig';
import {
  RETAIL_ITEMS_BASE,
  RETAIL_ITEMS_MOTORCYCLES,
  RETAIL_ITEMS_VEHICLES,
  retailItemsDefaultSortOrder,
} from './configs/retailItemsTableConfig';
import { Rooftops, rooftopsDefaultSortOrder } from './configs/rooftopsTableConfig';
import { TASKS, tasksDefaultSortOrder } from './configs/tasksTableConfig';
import {
  TRADE_IN_ITEMS_BASE,
  TRADE_IN_ITEMS_MOTORCYCLES,
  TRADE_IN_ITEMS_VEHICLES,
  tradeInItemsDefaultSortOrder,
} from './configs/tradeInItemsTableConfig';
import { USERS, usersDefaultSortOrder } from './configs/usersTableConfig';
import { WEBSITES, websitesDefaultSortOrder } from './configs/websitesTableConfig';

export enum TableType {
  // Top level targets
  APPOINTMENTS = 'appointments',
  CONVERSATIONS = 'conversations',
  LEADS = 'leads',
  RETAIL_ITEMS = 'retailItems',
  ROOFTOPS = 'rooftops',
  TASKS = 'tasks',
  TRADE_IN_ITEMS = 'tradeInItems',
  USERS = 'users',
  WEBSITES = 'websites',
}

export enum TableSubType {
  // Generic sub createModify
  BASE = 'base',
  ALL = 'all',
  VEHICLES = 'vehicles',
  MOTORCYCLES = 'motorcycles',
  LIMITED = 'limited',
}

export const TableSettings = {
  // Retail Items
  [`${TableType.RETAIL_ITEMS}-${TableSubType.BASE}`]: RETAIL_ITEMS_BASE,
  [`${TableType.RETAIL_ITEMS}-${TableSubType.VEHICLES}`]: getSortedColumns(
    [...RETAIL_ITEMS_BASE, ...RETAIL_ITEMS_VEHICLES],
    retailItemsDefaultSortOrder
  ),
  [`${TableType.RETAIL_ITEMS}-${TableSubType.MOTORCYCLES}`]: getSortedColumns(
    [...RETAIL_ITEMS_BASE, ...RETAIL_ITEMS_MOTORCYCLES],
    retailItemsDefaultSortOrder
  ),
  [`${TableType.RETAIL_ITEMS}-${TableSubType.ALL}`]: getSortedColumns(
    [...RETAIL_ITEMS_BASE, ...RETAIL_ITEMS_VEHICLES, ...RETAIL_ITEMS_MOTORCYCLES],
    retailItemsDefaultSortOrder
  ),

  // Trade-In Items
  [`${TableType.TRADE_IN_ITEMS}-${TableSubType.BASE}`]: TRADE_IN_ITEMS_BASE,
  [`${TableType.TRADE_IN_ITEMS}-${TableSubType.VEHICLES}`]: getSortedColumns(
    [...TRADE_IN_ITEMS_BASE, ...TRADE_IN_ITEMS_VEHICLES],
    tradeInItemsDefaultSortOrder
  ),
  [`${TableType.TRADE_IN_ITEMS}-${TableSubType.MOTORCYCLES}`]: getSortedColumns(
    [...TRADE_IN_ITEMS_BASE, ...TRADE_IN_ITEMS_VEHICLES],
    tradeInItemsDefaultSortOrder
  ),
  [`${TableType.TRADE_IN_ITEMS}-${TableSubType.ALL}`]: getSortedColumns(
    [...TRADE_IN_ITEMS_BASE, ...TRADE_IN_ITEMS_VEHICLES, ...TRADE_IN_ITEMS_MOTORCYCLES],
    tradeInItemsDefaultSortOrder
  ),

  // Leads
  [`${TableType.LEADS}-${TableSubType.ALL}`]: getSortedColumns([...LEADS], leadsDefaultSortOrder),
  [`${TableType.LEADS}-${TableSubType.LIMITED}`]: getSortedColumns([...LEADS_LIMITED], leadsDefaultSortOrder),

  // Rooftops
  [`${TableType.ROOFTOPS}`]: getSortedColumns([...Rooftops], rooftopsDefaultSortOrder),

  // Users
  [`${TableType.USERS}`]: getSortedColumns([...USERS], usersDefaultSortOrder),

  // Tasks
  [`${TableType.TASKS}-${TableSubType.ALL}`]: getSortedColumns([...TASKS], tasksDefaultSortOrder),
  [`${TableType.TASKS}-${TableSubType.LIMITED}`]: getSortedColumns([...TASKS], tasksDefaultSortOrder),

  // Conversations
  [`${TableType.CONVERSATIONS}`]: getSortedColumns([...CONVERSATIONS], conversationsDefaultSortOrder),

  // Appointments
  [`${TableType.APPOINTMENTS}-${TableSubType.ALL}`]: getSortedColumns([...APPOINTMENTS], appointmentsDefaultSortOrder),
  [`${TableType.APPOINTMENTS}-${TableSubType.LIMITED}`]: getSortedColumns(
    [...APPOINTMENTS_LIMITED],
    appointmentsDefaultSortOrder
  ),

  // Websites
  [`${TableType.WEBSITES}`]: getSortedColumns([...WEBSITES], websitesDefaultSortOrder),
};
