import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import {
  defineFieldValues,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

import { RooftopTagDetailsBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, data, seededData, isCreating },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
      },
    } = context;

    const rooftop = seededData?.rooftop || data.rooftop || getUsersRooftop(user);

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopTagDetailsBuilderFields.TYPE]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
      },
      [RooftopTagDetailsBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!rooftop,
          },
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
      },
    });

    this.fields = defineFieldValues(this.fields, data);

    this.asyncConfigurations = {
      [RooftopTagDetailsBuilderFields.ROOFTOP_ID]: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
    };
  }
}

export default DetailsStep;
