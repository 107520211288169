import { gql } from '@apollo/client';

import { contactDetail } from 'store/api/graph/fragments/contactDetail';
import { contactList } from 'store/api/graph/fragments/contactList';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';

export const rooftopContactsDetailsQuery = gql`
  query RooftopContactsDetailsQuery($id: ID!) {
    item: contact(id: $id) {
      ...ContactDetailFragment
    }
  }
  ${contactDetail}
`;

export const rooftopContactsCondensedListQuery = gql`
  query RooftopContactsCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $filter: ContactConnectionFilterInput
  ) {
    connection: contactConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ContactListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${contactList}
`;
