import { gql } from '@apollo/client';

import { descriptionRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/descriptionRetailBulkAdjustmentAttributes';
import { promotionRetailBulkAdjustmentAttributes } from 'store/api/graph/fragments/promotionRetailBulkAdjustmentAttributes';

import { overlayRetailBulkAdjustmentAttributes } from './overlayRetailBulkAdjustmentAttributes';
import { priceRetailBulkAdjustmentAttributes } from './priceRetailBulkAdjustmentAttributes';

export const retailBulkAdjustment = gql`
  fragment RetailBulkAdjustmentFragment on RetailBulkAdjustment {
    id
    name
    type
    typeName
    type
    enabled
    starting
    ending
    order
    attributes {
      ... on PriceRetailBulkAdjustmentAttributes {
        ...PriceRetailBulkAdjustmentAttributesFragment
      }
      ... on OverlayRetailBulkAdjustmentAttributes {
        ...OverlayRetailBulkAdjustmentAttributesFragment
      }
      ... on DescriptionRetailBulkAdjustmentAttributes {
        ...DescriptionRetailBulkAdjustmentAttributesFragment
      }
      ... on PromotionRetailBulkAdjustmentAttributes {
        ...PromotionRetailBulkAdjustmentAttributesFragment
      }
    }
  }
  ${overlayRetailBulkAdjustmentAttributes}
  ${priceRetailBulkAdjustmentAttributes}
  ${descriptionRetailBulkAdjustmentAttributes}
  ${promotionRetailBulkAdjustmentAttributes}
`;
