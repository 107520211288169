import { useCallback, useState } from 'react';
import 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import InputText from 'components/ui/forms/shared/InputText';
import { ElementTestId } from 'enums/testing';
import { translate } from 'utils/intlUtils';

import { OptionButton, OptionButtonStyle, OptionsContainer } from '../PromptDialogOptions';

const { t } = translate;

interface AddYouTubeVideoPromptProps {
  /**
   * Callback for when the user has clicked 'Upload' button. Will pass whatever value is in the InputText.
   * @param url - the youtube video url that will be saved
   */
  onSubmit: (url: string) => void;
  /** Callback for when the user has clicked 'Cancel' button. */
  onCancel: () => void;
}

const AddYouTubeVideoForm = ({ onSubmit, onCancel }: AddYouTubeVideoPromptProps) => {
  const [videoUrl, setVideoUrl] = useState('');

  const onSubmitCallback = useCallback(() => onSubmit(videoUrl), [videoUrl, onSubmit]);

  return (
    <>
      <Text css="margin: 15px 0 15px 0">{t('link_video_from_youtube')}</Text>
      <InputText
        css="margin-bottom: 15px"
        placeholder={t('paste_video_link')}
        onChange={e => setVideoUrl(e.currentTarget.value)}
      />
      <OptionsContainer css="box-shadow:none; padding: 0px; width: 100%;">
        <div>
          <OptionButton onClick={onCancel} styleVariant={OptionButtonStyle.CANCEL}>
            <Label>{t('cancel')}</Label>
          </OptionButton>
          <OptionButton
            onClick={onSubmitCallback}
            styleVariant={OptionButtonStyle.CONFIRMATION}
            data-testid={ElementTestId.VIDEO_UPLOAD_BUTTON}
          >
            <Label>{t('upload')}</Label>
          </OptionButton>
        </div>
      </OptionsContainer>
    </>
  );
};

export default AddYouTubeVideoForm;
