import { ApolloClient, ApolloLink, from, Observable, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import type { ServerError } from '@apollo/client/link/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import isChromatic from 'chromatic';
import { sha256 } from 'crypto-hash';
import { omit } from 'lodash-es';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import DatadogLogger from 'components/core/logging/DatadogLogger';
import LoggingService from 'components/core/logging/LoggingService';
import { ApolloContextHeaders } from 'enums/apollo';
import { DatadogCustomAction } from 'enums/datadogCustomAction';
import { getDefaultHeaders } from 'store/api/ApiRequest';
import { APIType, getGraphURL } from 'utils/apiUtils';
import { getOperationData, isNetworkStatusCodeIgnored } from 'utils/apolloUtils';
import { authManager, isLoggedIn } from 'utils/authUtils';
import { gqlFilterFields } from 'utils/gqlUtils';

import possibleTypes from './possibleTypes';
import { typePolicies } from './typePolicies';

/**
 * Centralized error capturing.
 * - Logout when unauthorized
 * - Display error dialog with message upon 5xx
 *
 * TODO: [#3013] Add more robust error handling
 */
const apolloErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const error = networkError as ServerError;
  const statusCode = error?.statusCode ?? 'N/A';

  if (!networkError) {
    return;
  }

  /** Provide Datadog with request/response data for this networkError */
  const trackActions = () => {
    const request = getOperationData(operation);
    const response = {
      ...graphQLErrors,
      statusCode,
      name: networkError.name,
      error: networkError,
    };
    DatadogLogger.trackAction(DatadogCustomAction.APOLLO_ERROR_REQUEST, request);
    DatadogLogger.trackAction(DatadogCustomAction.APOLLO_ERROR_RESPONSE, response);
  };

  trackActions();

  if (isLoggedIn() && statusCode === 401) {
    void authManager.logout();
    LoggingService.clearUser();
  } else if (isNetworkStatusCodeIgnored(statusCode)) {
    // Info level because error is expected
    LoggingService.log({
      message: 'ApolloClient: Expected ignored status code error',
      messageContext: { graphQLErrors, networkError },
      error: networkError as Error,
    });

    return Observable.of();
  }
});

/**
 * Terminating link (last link in composed network link)
 * requesting graphql results from our server.
 */
const apolloLink = createUploadLink({
  uri: getGraphURL(APIType.GRAPHQL),
  // Workaround to force Content-Type on every upload request
  formDataAppendFile: (formData: FormData, fieldName, file) => {
    const operations = formData.get('operations');
    // Only set if the `operations` value is a regular string which means it has not yet been set
    if (typeof operations === 'string') {
      formData.set(
        'operations',
        Object.assign(
          new Blob([`${operations}`], {
            type: 'application/json; charset=utf-8',
          })
        )
      );
      formData.set(
        'map',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        new Blob([`${formData.get('map')}`], {
          type: 'application/json; charset=utf-8',
        })
      );
    }

    formData.append(fieldName, file.blob || file);
  },
});

export const subscriptionClient = new SubscriptionClient(getGraphURL(APIType.SUBSCRIPTION), {
  reconnect: true,
  connectionParams: () => ({ ...getDefaultHeaders() }),
  // Type response `any` because the types file for this is incorrect
  connectionCallback: (response: any) => {
    if (response?.errors?.find(error => error.extensions?.status === 401)) {
      void authManager.logout();
      LoggingService.clearUser();
    }
  },
});

export const wsLink = new WebSocketLink(subscriptionClient);

/**
 * SplitLink - Determines which link is returned (http or websocket)
 *
 * The function takes three parameters:
 * - A function that's called for each operation to execute
 * - The Link to use for an operation if the function returns a "truthy" value
 * - The Link to use for an operation if the function returns a "falsy" value
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  apolloLink
);

/**
 * Custom authentication link.
 * Adds custom headers to all network calls.
 */
const authLink = () =>
  setContext(async (_, { authToken, headers }) => {
    const defaultHeaders = { ...getDefaultHeaders() };

    if (headers === ApolloContextHeaders.NO_AUTH_REQUIRED) {
      /*
       * Omit authorization header; generally reserved for mutations that don't require auth
       * See: ForgotPassword@onFormSubmit()
       */
      return { headers: omit(defaultHeaders, 'Authorization') };
    }

    if (authToken) {
      defaultHeaders.Authorization = `Bearer ${authToken}`;
    }

    return { headers: { ...defaultHeaders, ...headers } };
  });

/**
 * Custom link that will filter out unnecessary variables from gql operations. This is needed as the api will throw
 * errors if extra unneeded variables are sent in the request. Variables should still not include extra fields, but
 * this ensures the request won't fail because of that.
 */
const filterUnneededFieldsLink = new ApolloLink((operation, forward) => {
  operation.variables = gqlFilterFields(operation.query, operation.variables);
  return forward(operation);
});

/**
 * Determines whether to enable persisted queries.
 */
const shouldEnablePersistedQuery = () => {
  /**
   * Disable persisted queries on cypress test runs until ED-6394 is resolved.
   * Need to look further into solution as previous attempt outweighed cost-benefit with time.
   */
  if (window['Cypress']) {
    return false;
  }

  /**
   * Disable persisted query for storybook and chromatic. Does not play well with MSW.
   */
  if (process.env.STORYBOOK === 'true' || isChromatic()) {
    return false;
  }

  return process.env.REACT_APP_APOLLO_PERSISTED_QUERY_ENABLED === 'true';
};

export const client = new ApolloClient({
  connectToDevTools: process.env.REACT_APP_APOLLO_DEV_TOOLS_ENABLED === 'true',
  link: from(
    [
      authLink(),
      shouldEnablePersistedQuery() && createPersistedQueryLink({ sha256 }),
      filterUnneededFieldsLink,
      apolloErrorLink,
      splitLink,
    ].filter(Boolean)
  ),
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies,
  }),
});
