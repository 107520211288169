import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableSubType, TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { Appointment } from 'enums/columns/appointment';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import { useQuery } from 'hooks/useQuery';
import { appointmentDetail } from 'store/api/graph/fragments/appointmentDetail';
import { appointmentList } from 'store/api/graph/fragments/appointmentList';
import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { inventoryItemDetail } from 'store/api/graph/fragments/inventoryItemDetail';
import { leadName } from 'store/api/graph/fragments/leadName';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const appointmentDetailsQuery = gql`
  query AppointmentDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: appointment(id: $id) {
      ...AppointmentDetailFragment
    }

    metadata {
      ...ColorMetadataFragment
    }
  }
  ${colorMetadata}
  ${appointmentDetail}
`;

export const appointmentCondensedListQuery = gql`
  query AppointmentCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $filter: AppointmentConnectionFilterInput
  ) {
    connection: appointmentConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...AppointmentListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${appointmentList}
`;

export const appointmentContainerQuery = gql`
  query AppointmentConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $searchFilter: FacetSearchInput
    $filter: AppointmentConnectionFilterInput
    $d_leadNameOn: Boolean!
    $d_groupNameOn: Boolean!
    $d_inventoryItemNameOn: Boolean!
    $d_createdByNameOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
  ) {
    connection: appointmentConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...AppointmentListFragment

          leadName: lead @include(if: $d_leadNameOn) {
            ...LeadNameFragment
          }
          groupName: rooftop @include(if: $d_groupNameOn) {
            id
            group {
              id
              name {
                ...MultilingualStringFragment
              }
            }
          }
          inventoryItemName: inventoryItem @include(if: $d_inventoryItemNameOn) {
            ...InventoryItemDetailFragment
          }
          createdByName: createdBy @include(if: $d_createdByNameOn) {
            ...UserNameFragment
            rgbHex
          }
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${inventoryItemDetail}
  ${appointmentList}
  ${leadName}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  Appointment.TITLE,
  Appointment.DATE,
  Appointment.NOTE,
  Appointment.TAG_NAME,
  Appointment.ROOFTOP_NAME,
  Appointment.ASSIGNED_TO_NAME,
  Appointment.DATE_TO,
] as string[];

export const useAppointmentConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const { isWhiteLabelScoped, canAccessMultipleRooftops } = useUser();
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(
    TableType.APPOINTMENTS,
    isWhiteLabelScoped || canAccessMultipleRooftops ? TableSubType.ALL : TableSubType.LIMITED
  );

  const formattedTableColumns = gqlFormatTableColumnFields(
    appointmentContainerQuery,
    tableConfigurationNext,
    staticColumns
  );

  return useQuery(appointmentContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};
