import { t } from 'i18next';
import styled from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import InitialItemSortingSection from 'components/sections/websites/InitialItemSortingSection';
import type { SortingSectionProps } from 'components/sections/websites/interfaces';

import { CollapsibleSectionHeaderWrapper } from './collapsibleSections/CollapsibleSection';

const WebsiteRouteSortingSectionHeaderWrapper = styled(CollapsibleSectionHeaderWrapper)`
  margin-bottom: 0;
`;
const WebsiteRouteSortingSection = ({ items, metadata }: SortingSectionProps) => (
  <>
    <WebsiteRouteSortingSectionHeaderWrapper>
      <TertiaryLabel>{t('sort_rule')}</TertiaryLabel>
    </WebsiteRouteSortingSectionHeaderWrapper>
    <InitialItemSortingSection items={items} metadata={metadata} />
  </>
);

export default WebsiteRouteSortingSection;
