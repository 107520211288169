import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const group = gql`
  fragment GroupFragment on Group {
    id
    name {
      ...MultilingualStringFragment
    }
    whiteLabel {
      id
    }
  }
  ${MultilingualString}
`;
