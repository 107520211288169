import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { InviteAppraisalBuilderFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import ContactListItem from 'components/ui/lists/ContactListItem';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

const InviteAppraisersFields: StepFields = {
  [InviteAppraisalBuilderFields.CONTACT_ID]: {
    label: 'appraisers',
    queryAlias: 'appraisers',
    required: true,
    groupType: StepFieldType.RENDER_OBJECT,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    renderElement: ContactListItem,
    subStep: [SubStepType.PERSIST, SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.CONTACT_CREATE],
    hasSeparator: true,
  },
};

export default InviteAppraisersFields;
