import { createContext } from 'react';

import type { BuilderConfig } from 'components/sections/createModify/builders';

export interface BuilderConfigContextInterface {
  builderConfig: BuilderConfig;
}

export const BuilderConfigContext = createContext<BuilderConfigContextInterface>({
  /**
   * This should always be defined since the list of builders are manually defined in
   * 'components/sections/createModify/builders'. We could load `Builders` as default value
   * here but it would cause a circular dependency error.
   *
   * Builders are loaded on BuilderConfigProvider instead.
   */
  builderConfig: {} as BuilderConfig,
});
