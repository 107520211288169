import styled from 'styled-components/macro';

import Label, { SecondaryLabel } from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { Input } from 'components/ui/forms/shared/InputText';
import type { MaskInputConfig, MaskInputSettings } from 'components/ui/forms/shared/MaskedInput';
import MaskedInput from 'components/ui/forms/shared/MaskedInput';
import { CTAButton } from 'components/ui/shared/Button';
import { BLUE_500, NEUTRAL_0, SPACE_100, SPACE_200 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * A helper label used to add context to a form element.
 * Use within a <FormControl /> component.
 */
export const FormHelperLabel = styled(SecondaryLabel)`
  color: ${NEUTRAL_0};
  opacity: 0.6;
  text-align: left;
`;

// Settings for the password form input
export interface FormPasswordInputSettings {
  // Sublabel for this input
  helperLabel?: string;
}

interface Props extends FormPasswordInputSettings, MaskInputSettings, MaskInputConfig {}

/**
 * A form input with helper label that uses PasswordInput with default settings
 */
export const FormPasswordInput = ({
  value,
  onChange,
  inputLabel = 'password',
  helperLabel = '',
  autoFocus = false,
  suffixIconColour = NEUTRAL_0,
}: Props) => (
  <FormControl>
    <Label>{t(inputLabel)}</Label>
    {helperLabel && <FormHelperLabel>{t(helperLabel)}</FormHelperLabel>}
    <MaskedInput
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      inputLabel={inputLabel}
      suffixIconColour={suffixIconColour}
    />
  </FormControl>
);

/**
 * A submit form button used to submit data to a form handler.
 */
export const FormSubmitButton = styled(CTAButton)`
  margin: 18px 0;
  width: 100%;
  background: ${NEUTRAL_0};

  > ${Text} {
    margin: auto;
    color: ${BLUE_500};
  }
`;

/**
 * Used to compose different form elements e.g. Inputs.
 */
export const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${SPACE_200};

  ${Input} {
    margin-top: 0;
  }

  > ${FormHelperLabel} {
    margin-top: ${SPACE_100};
  }
`;
