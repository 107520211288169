import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type Step from 'components/core/createModify/interfaces/step';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { leadCreate, leadModify } from 'components/sections/createModify/leads/LeadCreateModifyQuery';
import LeadQueryMethods from 'components/sections/createModify/leads/LeadQueryMethods';
import LeadLinkedFields from 'components/sections/createModify/leads/steps/LeadLinkedFields';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import LinkedStep from '../shared/steps/LinkedStep';

import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';
import { LeadBuilderSteps } from './steps/interfaces';
import LeadEmploymentInformationFields from './steps/LeadEmploymentInformationFields';
import LeadEmploymentInformationStep from './steps/LeadEmploymentInformationStep';
import LeadGeneralInformationFields from './steps/LeadGeneralInformationFields';
import LeadGeneralInformationStep from './steps/LeadGeneralInformationStep';
import LeadPreviousEmploymentInformationFields from './steps/LeadPreviousEmploymentInformationFields';
import LeadPreviousEmploymentInformationStep from './steps/LeadPreviousEmploymentInformationStep';

const steps = (options: { enableAllSteps: boolean }): Step[] => [
  {
    id: LeadBuilderSteps.DETAILS,
    step: DetailsStep,
    stepName: 'details',
    isEnabled: true,
    fields: DetailsFields,
  },
  {
    id: LeadBuilderSteps.GENERAL_INFORMATION,
    step: LeadGeneralInformationStep,
    stepName: 'general_information',
    isEnabled: options.enableAllSteps,
    fields: LeadGeneralInformationFields,
  },
  {
    id: LeadBuilderSteps.EMPLOYMENT_INFORMATION,
    step: LeadEmploymentInformationStep,
    stepName: 'employment_information',
    isEnabled: options.enableAllSteps,
    fields: LeadEmploymentInformationFields,
  },
  {
    id: LeadBuilderSteps.PREVIOUS_EMPLOYMENT,
    step: LeadPreviousEmploymentInformationStep,
    stepName: 'previous_employment',
    isEnabled: options.enableAllSteps,
    fields: LeadPreviousEmploymentInformationFields,
  },
  {
    id: LeadBuilderSteps.LINKED,
    step: LinkedStep,
    stepName: 'linked',
    isEnabled: options.enableAllSteps,
    fields: LeadLinkedFields,
  },
];

const LeadBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.LEAD_CREATE]: {
    getMetaData: LeadQueryMethods.getMetaData,
    refetchQueries: LeadQueryMethods.refetchQueries,
    resourceType: ResourceType.LEADS,
    steps: steps({ enableAllSteps: false }),
    legend: [StepLegendNav],
    mutations: {
      create: leadCreate,
      modify: leadModify,
    },
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.ADVANCED }],
  },
  [BuilderType.LEAD_MODIFY]: {
    getMetaData: LeadQueryMethods.getMetaData,
    getData: LeadQueryMethods.getData,
    refetchQueries: LeadQueryMethods.refetchQueries,
    resourceType: ResourceType.LEADS,
    steps: steps({ enableAllSteps: true }),
    mutations: {
      modify: leadModify,
    },
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }],
  },
};

export default LeadBuilders;
