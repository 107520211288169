import { memo, type SVGProps } from 'react';

import { ElementTestId } from 'enums/testing';

const LinkedClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props} data-testid={ElementTestId.LINKED_CLOSE_ICON}>
    <path
      fill="currentColor"
      d="M9.583 12.333V7.667c0-.195-.083-.327-.25-.396-.166-.07-.32-.035-.458.104L6.833 9.417a.8.8 0 0 0-.25.583.8.8 0 0 0 .25.583l2.042 2.042c.139.139.292.174.458.104.167-.07.25-.201.25-.396ZM4.167 17.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V4.167c0-.459.163-.851.49-1.177.326-.327.718-.49 1.177-.49h11.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v11.666c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H4.167Zm9.166-1.667h2.5V4.167h-2.5v11.666Zm-1.666 0V4.167h-7.5v11.666h7.5Z"
    />
  </svg>
);

export default memo(LinkedClose);
