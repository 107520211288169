import { gql } from '@apollo/client';

import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';

import { MultilingualString } from './multilingualString';

export const rooftopList = gql`
  fragment RooftopListFragment on Rooftop {
    id
    active
    groupName: group {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    address: location {
      value: address
    }
    city: location {
      value: city
    }
    regionCode: location {
      value: regionCode
    }
    name {
      ...MultilingualStringFragment
    }

    ## For Feature Bundles
    bundle {
      ...RooftopFeatureBundle
    }
  }
  ${MultilingualString}
  ${rooftopFeatureBundle}
`;
