import { gql } from '@apollo/client';

export const pageInfo = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    totalEdges
    startCursor
  }
`;
