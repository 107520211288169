import styled, { css } from 'styled-components/macro';

import SpinnerIcon from 'components/ui/icons/SpinnerIcon';
import { ElementTestId } from 'enums/testing';
import { OVERLAY_LIGHT } from 'styles/blanket';
import { BODY_TEXT_SECONDARY, DIVIDER } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_900 } from 'styles/tokens';
import { Z_INDEX_3 } from 'styles/z-index';
import { hexToRGBA } from 'utils/styledUtils';

const LoaderContainer = styled.div<{ zIndex?: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex ?? Z_INDEX_3};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: ${OVERLAY_LIGHT};
`;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  padding: 25px;
  background: ${NEUTRAL_0};
  border-radius: 50%;
  box-shadow: 0 0 12px 0 ${hexToRGBA(NEUTRAL_900, '0.13')};
`;

export const Spinner = styled(SpinnerIcon)`
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ListItemSpinner = styled(Spinner)`
  width: 14px;
  height: 14px;
  fill: ${BODY_TEXT_SECONDARY};
`;

const SpinnerLoader = () => (
  <CircleContainer data-testid={ElementTestId.SPINNING_LOADER}>
    <Spinner />
  </CircleContainer>
);

const Loader = props => (
  <LoaderContainer {...props}>
    <SpinnerLoader />
  </LoaderContainer>
);

export const PageLoader = ({ zIndex }: { zIndex?: number }) => (
  <Loader
    zIndex={zIndex}
    css={css`
      background: ${DIVIDER};
    `}
  />
);

export default Loader;
