import { gql } from '@apollo/client';

import { retailPricingConfiguration } from 'store/api/graph/fragments/retailPricingConfiguration';
import { rooftopRetailPricing } from 'store/api/graph/fragments/rooftopRetailPricing';

export const rooftopRetailCashPricingCreate = gql`
  mutation RooftopRetailCashPricingCreate(
    $rooftopId: ID
    $cashConfiguration: CashRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_submit: Boolean! = true
  ) {
    data: rooftopRetailPricingCreate(
      rooftopId: $rooftopId
      cashConfiguration: $cashConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _submit: $_submit
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingConfigurationFragment
      }
    }
  }

  ${retailPricingConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailCashPricingModify = gql`
  mutation RooftopRetailCashPricingModify(
    $id: ID!
    $cashConfiguration: CashRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_clear: [RooftopRetailPricingModifyParameter!]
  ) {
    data: rooftopRetailPricingModify(
      id: $id
      cashConfiguration: $cashConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _clear: $_clear
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingConfigurationFragment
      }
    }
  }

  ${retailPricingConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailPricingDelete = gql`
  mutation RooftopRetailPricingDelete($id: ID!) {
    data: rooftopRetailPricingDelete(id: $id)
  }
`;
