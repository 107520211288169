import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { retailItemPricingModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import RetailItemPricingInfoFields from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/RetailItemPricingInfoFields';
import RetailItemPricingInfoStep from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/RetailItemPricingInfoStep';
import RetailItemPricingQueryMethods from 'components/sections/createModify/retailItemPricing/RetailItemPricingQueryMethods';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

export enum RetailItemPricingInfoBuilderStep {
  PRICING_INFO = 'RETAIL_ITEM_PRICING_INFO',
}

const RetailItemPricingInfoBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_ITEM_PRICING_INFO]: {
    getMetaData: RetailItemPricingQueryMethods.getMetaData,
    resourceType: ResourceType.RETAIL_ITEMS,
    refetchQueries: RetailItemPricingQueryMethods.refetchQueries,
    getData: RetailItemPricingQueryMethods.getData,
    steps: [
      {
        id: RetailItemPricingInfoBuilderStep.PRICING_INFO,
        step: RetailItemPricingInfoStep,
        stepName: 'pricing_info',
        isEnabled: true,
        fields: RetailItemPricingInfoFields,
      },
    ],
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
    mutations: {
      modify: retailItemPricingModify,
    },
  },
};

export default RetailItemPricingInfoBuilders;
