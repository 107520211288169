import type { DocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { merge } from 'lodash-es';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getInventoryItemCombinedConfigurations } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { appointmentList } from 'store/api/graph/fragments/appointmentList';
import colorTableMetadata from 'store/api/graph/fragments/colorTableMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { leadList } from 'store/api/graph/fragments/leadList';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { retailItemDetail } from 'store/api/graph/fragments/retailItemDetail';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';
import { tag } from 'store/api/graph/fragments/tag';
import { taskList } from 'store/api/graph/fragments/taskList';
import { userList } from 'store/api/graph/fragments/userList';
import { vehicleAttributesMetadata } from 'store/api/graph/fragments/vehicleAttributesMetadata';
import type {
  RetailItemCondensedListQuery,
  RetailItemCondensedListQueryVariables,
} from 'store/api/graph/interfaces/types';
import { RetailItemStatus } from 'store/api/graph/interfaces/types';
import type { CustomItemResponseType } from 'store/api/graph/responses/responseTypes';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';
import { hasWhiteLabelScopedAccess } from 'utils/permissionUtils';

import { getQueryForRetailItem, getRetailItemFromQueryResponse } from './RetailItemsCustomQueries';

export const retailItemsDetailsQuery = gql`
  query RetailItemDetailsContainerQuery($id: ID!) {
    ## Important: 'inventoryItem' alias is required for data reading
    item: retailItem(id: $id) {
      ## ACTIVITY TAB
      ...RetailItemDetailFragment
      leadActivitiesCount
      phoneCallsCount: leadActivitiesCount(type: PHONE_CALL)
      conversationsCount
      testDrivesCount: leadActivitiesCount(type: TEST_DRIVE)
      walkInsCount: leadActivitiesCount(type: WALK_IN)
      leadsCount

      createdBy {
        ...UserListFragment
      }

      leads {
        ...LeadListFragment
      }

      appointments {
        ...AppointmentListFragment
      }
      tasks {
        ...TaskListFragment
      }
    }
    metadata {
      mutation {
        item: inventoryItem {
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
  }
  ${vehicleAttributesMetadata}
  ${retailItemDetail}
  ${userList}
  ${leadList}
  ${taskList}
  ${appointmentList}
`;

export const retailItemsCondensedListQuery = gql`
  query RetailItemCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: RetailItemConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: retailItemConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RetailItemListFragment
        }
      }
    }
    metadata {
      ...ColorTableMetadataFragment
    }
  }
  ${colorTableMetadata}
  ${retailItemList}
  ${pageInfo}
`;

export const retailItemsContainerQuery = gql`
  query RetailItemConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: RetailItemConnectionFilterInput
    $d_noteOn: Boolean!
    $d_rooftopNameOn: Boolean!
    $d_conditionOn: Boolean!
    $d_tagNameOn: Boolean!
    $d_descriptionOn: Boolean!
    $d_highlightsOn: Boolean!
    $d_costOn: Boolean!
    $d_msrpOn: Boolean!
    $d_asIsOn: Boolean!
    $d_demoOn: Boolean!
    $d_featuredOn: Boolean!
    $d_onOrderOn: Boolean!
    $d_inTransitOn: Boolean!
    $d_certifiedOn: Boolean!
    $d_showWebOn: Boolean!
    $d_completeOn: Boolean!
    $d_lockedOn: Boolean!
    $d_mappedOn: Boolean!
    $d_appointmentsCountOn: Boolean!
    $d_conversationsCountOn: Boolean!
    $d_videosCountOn: Boolean!
    $d_photosCountInteriorOn: Boolean!
    $d_photosCountExteriorOn: Boolean!
    $d_photosCountDamageOn: Boolean!
    $d_tasksCountOn: Boolean!
    $d_createdByNameOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
    $d_soldOn: Boolean!
    $d_price1On: Boolean!
    $d_price2On: Boolean!
    $d_motorcycleAttributes_categoryOn: Boolean!
    $d_motorcycleAttributes_colorOn: Boolean!
    $d_vehicleAttributes_bodyTypeOn: Boolean!
    $d_vehicleAttributes_cabTypeOn: Boolean!
    $d_vehicleAttributes_interiorColorOn: Boolean!
    $d_vehicleAttributes_numberOfDoorsOn: Boolean!
    $d_vehicleAttributes_numberOfPassengersOn: Boolean!
    $d_vehicleAttributes_driveTrainOn: Boolean!
    $d_vehicleAttributes_optionsOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: retailItemConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RetailItemListFragment
          note @include(if: $d_noteOn)
          status ## This is required for sorting because the list does not declare it due to conflicting usage
          rooftopName: rooftop @include(if: $d_rooftopNameOn) {
            id
            name {
              ...MultilingualStringFragment
            }
          }
          condition @include(if: $d_conditionOn)
          conditionName @include(if: $d_conditionOn)
          tagName: tags @include(if: $d_tagNameOn) {
            ...TagFragment
          }
          description @include(if: $d_descriptionOn) {
            value
          }
          highlights @include(if: $d_highlightsOn) {
            value
          }
          cost @include(if: $d_costOn) {
            formattedAmountRounded
          }
          msrp @include(if: $d_msrpOn) {
            formattedAmountRounded
          }
          price1 @include(if: $d_price1On) {
            formattedAmountRounded
          }
          price2 @include(if: $d_price2On) {
            formattedAmountRounded
          }
          asIs @include(if: $d_asIsOn)
          featured @include(if: $d_featuredOn)
          onOrder @include(if: $d_onOrderOn)
          inTransit @include(if: $d_inTransitOn)
          demo @include(if: $d_demoOn)
          certified @include(if: $d_certifiedOn)
          showWeb @include(if: $d_showWebOn)
          complete @include(if: $d_completeOn)
          locked @include(if: $d_lockedOn)
          mapped @include(if: $d_mappedOn)
          appointmentsCount @include(if: $d_appointmentsCountOn)
          conversationsCount @include(if: $d_conversationsCountOn)
          tasksCount @include(if: $d_tasksCountOn)
          videosCount @include(if: $d_videosCountOn)
          photosCountInterior: photosCount(type: INTERIOR) @include(if: $d_photosCountInteriorOn)
          photosCountExterior: photosCount(type: EXTERIOR) @include(if: $d_photosCountExteriorOn)
          photosCountDamage: photosCount(type: DAMAGE) @include(if: $d_photosCountDamageOn)
          createdByName: createdBy @include(if: $d_createdByNameOn) {
            id
            firstName
            lastName
          }
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
          sold @include(if: $d_soldOn)
          motorcycleAttributes: attributes {
            ... on MotorcycleAttributes {
              category @include(if: $d_motorcycleAttributes_categoryOn)
              categoryName @include(if: $d_motorcycleAttributes_categoryOn)
              color @include(if: $d_motorcycleAttributes_colorOn)
            }
          }
          vehicleAttributes: attributes {
            ... on VehicleAttributes {
              bodyType @include(if: $d_vehicleAttributes_bodyTypeOn)
              bodyTypeName @include(if: $d_vehicleAttributes_bodyTypeOn)
              cabType @include(if: $d_vehicleAttributes_cabTypeOn)
              cabTypeName @include(if: $d_vehicleAttributes_cabTypeOn)
              interiorColor @include(if: $d_vehicleAttributes_interiorColorOn)
              interiorColorName @include(if: $d_vehicleAttributes_interiorColorOn)
              numberOfDoors @include(if: $d_vehicleAttributes_numberOfDoorsOn)
              numberOfPassengers @include(if: $d_vehicleAttributes_numberOfPassengersOn)
              driveTrain @include(if: $d_vehicleAttributes_driveTrainOn)
              driveTrainName @include(if: $d_vehicleAttributes_driveTrainOn)
              options @include(if: $d_vehicleAttributes_optionsOn)
              optionsNames @include(if: $d_vehicleAttributes_optionsOn)
            }
          }
        }
      }
    }
    metadata {
      ...ColorTableMetadataFragment
    }
  }
  ${columns}
  ${colorTableMetadata}
  ${filters}
  ${pageInfo}
  ${tag}
  ${retailItemList}
  ${MultilingualString}
`;
//  TODO: Can remove after [ED-8463]
/**
 * If the user has access to the attached retail item it returns a normal query response.
 * If the user does not have access to the attached retail item it will query for it through its
 * parent entity to bypass permission restrictions.
 */
export const useRetailItemDetailsQuery = (
  query: DocumentNode,
  config: QueryConfig,
  seededData: CustomItemResponseType
): CustomQueryResult => {
  const {
    user: { rooftops, scope },
  } = useUser();
  const { rooftop } = seededData;
  let hasItemAccess = true;

  if (rooftop) {
    const { id } = rooftop;
    if (rooftops) {
      hasItemAccess = hasWhiteLabelScopedAccess(scope) ? true : rooftops.some(rooftop => rooftop.id === id);
    }
  }

  const queryData = getQueryForRetailItem(query, config, seededData, hasItemAccess);

  const response = useQuery(queryData.query, queryData.config);

  return getRetailItemFromQueryResponse(response, seededData, hasItemAccess);
};

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  InventoryItem.YEAR,
  InventoryItem.MAKE_NAME,
  InventoryItem.MODEL_NAME,
  InventoryItem.TRIM_NAME,
  InventoryItem.STATUS,
  InventoryItem.RETAIL_ITEM_STATUS,
  InventoryItem.VIN,
  InventoryItem.STOCK_NUMBER,
  InventoryItem.COMPLETE_PERCENT,
  InventoryItem.LEAD_ACTIVITIES_COUNT,
  InventoryItem.LEADS_COUNT,
  InventoryItem.VEHICLE_EXTERIOR_COLOR,
  InventoryItem.VEHICLE_TRANSMISSION,
  InventoryItem.VEHICLE_MILEAGE,
  InventoryItem.VEHICLE_DISPLACEMENT,
  InventoryItem.VEHICLE_CYLINDERS,
  InventoryItem.VEHICLE_FUEL_TYPE,
  InventoryItem.MOTORCYCLE_MILEAGE,
  InventoryItem.MOTORCYCLE_DISPLACEMENT,
  InventoryItem.MOTORCYCLE_CYLINDERS,
  InventoryItem.MOTORCYCLE_FUEL_TYPE,
] as string[];

export const useRetailItemConnectionQuery = (variables: Record<string, any> = {}, options?: any): CustomQueryResult => {
  /*
   * `cargoBedLength` is expected to be an array of strings when passed into the RetailItemConnectionQuery.
   * Our custom url decoder found in `getUrlSearchParams` converts string values in the search params into
   * numbers to accomodate filters with `gte` and `lte` values. This results in us needing to cast the floats
   * back to strings here if the search params are being read from the url (e.g user sends a link or refreshes
   * the page with the cargo bed length filter selected).
   */
  if (variables?.vehicleAttributes?.cargoBedLength) {
    variables.vehicleAttributes.cargoBedLength = variables.vehicleAttributes.cargoBedLength.map(String);
  }

  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getInventoryItemCombinedConfigurations(
    formattedConnectionParams.filter.type,
    TableType.RETAIL_ITEMS
  );

  const formattedTableColumns = gqlFormatTableColumnFields(
    retailItemsContainerQuery,
    tableConfigurationNext,
    staticColumns
  );

  return useQuery(retailItemsContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};

export const useRetailItemForSaleListQuery = (
  query: DocumentNode,
  config: QueryConfig<RetailItemCondensedListQuery, RetailItemCondensedListQueryVariables>
) => useQuery(query, merge({}, config, { variables: { filter: { status: RetailItemStatus.FOR_SALE } } }));
