import { gql } from '@apollo/client';

export const locale = gql`
  fragment LocaleFragment on Locale {
    country
    displayName
    language
    languageTag
  }
`;
