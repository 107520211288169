import { gql } from '@apollo/client';

import { contactList } from 'store/api/graph/fragments/contactList';

export const contactDetail = gql`
  fragment ContactDetailFragment on Contact {
    ...ContactListFragment
    type
    typeName
    company
    created
    updated
    archived
  }
  ${contactList}
`;
