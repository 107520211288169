import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { purchaseActivityCreate, purchaseActivityModify } from './PurchaseActivityCreateModifyQuery';
import PurchaseActivityQueryMethods from './PurchaseActivityQueryMethods';
import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

export enum PurchaseActivityBuilderStep {
  DETAILS = 'PURCHASE_ACTIVITY_DETAILS',
}

const PurchaseActivityBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.PURCHASE_ACTIVITY_CREATE]: {
    refetchQueries: PurchaseActivityQueryMethods.refetchQueries,
    getMetaData: PurchaseActivityQueryMethods.getMetaData,
    legend: [StepLegendNav],
    resourceType: ResourceType.LEADS,
    requiredPermissions: [
      { resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.ADVANCED },
      { resource: ResourceType.LEADS, level: AccessLevel.ADVANCED },
    ],
    mutations: {
      create: purchaseActivityCreate,
      modify: purchaseActivityModify,
    },
    steps: [
      {
        id: PurchaseActivityBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'purchase_details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
  },
  [BuilderType.PURCHASE_ACTIVITY_MODIFY]: {
    refetchQueries: PurchaseActivityQueryMethods.refetchQueries,
    getData: PurchaseActivityQueryMethods.getData,
    getMetaData: PurchaseActivityQueryMethods.getMetaData,
    legend: [StepLegendNav],
    resourceType: ResourceType.LEADS,
    requiredPermissions: [
      { resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.ADVANCED },
      { resource: ResourceType.LEADS, level: AccessLevel.ADVANCED },
    ],
    mutations: {
      modify: purchaseActivityModify,
    },
    steps: [
      {
        id: PurchaseActivityBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'lead_details',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
  },
};

export default PurchaseActivityBuilders;
