import type { ElementType, ReactElement } from 'react';

import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { ListCondition } from 'components/core/createModify/stepFields/subSteps/listCondition';
import {
  getInventoryItemOptions,
  getLeadOptions,
  getUsersAssignable,
} from 'components/sections/shared/ItemMetaHelpers';
import { RetailListItem } from 'components/ui/lists/RetailListItem';
import { TradeInListItem } from 'components/ui/lists/TradeInListItem';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { defineFieldValues, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

class LinkedStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, metadata, activeStep, isCreating, seededData, type },
    } = props;

    const {
      subContexts: {
        builderConfigContext: { builderConfig },
        userContext: { user, hasPermissions },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const resourceType = builderConfig[type].resourceType;
    const inventoryItemName = seededData?.inventoryItemName || data.inventoryItemName;
    const assignedToName = seededData?.assignedToName || data.assignedToName;
    const leadName = seededData?.leadName || data.leadName;

    const isTradeInEnabled = isFeatureEnabledForRooftop({
      rooftop: data.rooftopName,
      feature: FeatureBundleSet.TRADE_IN,
      featureFlagOn: rooftopPackageEnabled,
    });

    const isRetailEnabled = isFeatureEnabledForRooftop({
      rooftop: data.rooftopName,
      feature: FeatureBundleSet.RETAIL,
      featureFlagOn: rooftopPackageEnabled,
    });

    const assignee =
      (isCreating && !hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]) && user) ||
      (assignedToName && { ...assignedToName });

    const categories: ListCondition[] = [];

    if (isTradeInEnabled) {
      categories.push({
        conditionProp: '__typename',
        conditionValue: 'TradeInItem',
        label: translate.t('trade_ins_other'),
      });
    }

    if (isRetailEnabled) {
      categories.push({
        conditionProp: '__typename',
        conditionValue: 'RetailItem',
        label: translate.t('retail_items_other'),
      });
    }

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      inventoryItemId: {
        renderElement: this.renderInventoryItem as ElementType,
        selectedValue: inventoryItemName && { ...inventoryItemName, metadata },
        subStepCategories: categories,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!(isCreating && !!inventoryItemName),
          },
          /**
           * This is a special case to handle a different set of permission requirements for the inventory item
           * selection. Since both Trade-In and Retail items can be selected at the same time, this field should be
           * hidden only if the user does not have permission for BOTH Trade and Retail or if both features are not
           * enabled.
           */
          {
            type: StepFieldDisplayType.HIDDEN,
            active:
              (!hasPermissions([{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.BASIC }]) &&
                !hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }])) ||
              categories.length === 0,
          },
        ]),
      },
      assignedToId: {
        selectedValue: assignee,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !!(isCreating && !!assignedToName),
        }),
      },
      leadId: {
        selectedValue: leadName && { ...leadName },
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!(isCreating && !!leadName),
          },
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isFeatureEnabledForRooftop({
              rooftop: data.rooftopName,
              feature: FeatureBundleSet.LEAD,
              featureFlagOn: rooftopPackageEnabled,
            }),
          },
        ]),
      },
    });
    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Async subpanel configurations
    const rooftopId = seededData?.rooftopName?.id || data.rooftopName?.id;

    this.asyncConfigurations = {
      assignedToId: {
        request: () => getUsersAssignable(rooftopId, resourceType),
        disableKeywordRefetch: true,
      },
      leadId: {
        request: keyword => getLeadOptions(rooftopId, keyword),
      },
      inventoryItemId: {
        request: keyword =>
          // TODO: Provide these categories  with their own connections  to show errors
          getInventoryItemOptions(
            rooftopId,
            keyword,
            undefined,
            hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]),
            hasPermissions([{ resource: ResourceType.TRADE_IN_ITEMS, level: AccessLevel.BASIC }])
          ),
      },
    };
  }

  renderInventoryItem = (props): ReactElement =>
    props.__typename === 'RetailItem' ? <RetailListItem {...props} /> : <TradeInListItem {...props} />;
}

export default LinkedStep;
