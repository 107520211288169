import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { FieldDataType } from 'enums/fieldDataType';
import { ImageType } from 'enums/imageType';
import { ItemsColumn } from 'enums/itemsColumn';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { PhotoHeaders, SelectHeaders } from './staticTableConfig';

const defaultRetailItemHeaders: Partial<TableCellData> = {
  width: 90,
  minWidth: 60,
  resizable: true,
  canReorder: true,
};

export const retailItemsDefaultSortOrder = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  InventoryItem.ROOFTOP_NAME,
  InventoryItem.VIN,
  InventoryItem.STOCK_NUMBER,
  InventoryItem.CONDITION,
  InventoryItem.STATUS,
  InventoryItem.TYPE,
  InventoryItem.YEAR,
  InventoryItem.MAKE_NAME,
  InventoryItem.MODEL_NAME,
  InventoryItem.SUB_MODEL_NAME,
  InventoryItem.TRIM_NAME,
  InventoryItem.TAG_NAME,
  InventoryItem.DESCRIPTION,
  InventoryItem.HIGHLIGHTS,
  InventoryItem.VEHICLE_MILEAGE,
  InventoryItem.MOTORCYCLE_MILEAGE,
  InventoryItem.MOTORCYCLE_CATEGORY,
  InventoryItem.MOTORCYCLE_COLOR,
  InventoryItem.VEHICLE_BODY_TYPE,
  InventoryItem.VEHICLE_CAB_TYPE,
  InventoryItem.VEHICLE_EXTERIOR_COLOR,
  InventoryItem.VEHICLE_INTERIOR_COLOR,
  InventoryItem.VEHICLE_NUMBER_OF_DOORS,
  InventoryItem.VEHICLE_NUMBER_OF_PASSENGERS,
  InventoryItem.VEHICLE_TRANSMISSION,
  InventoryItem.VEHICLE_DRIVE_TRAIN,
  InventoryItem.VEHICLE_DISPLACEMENT,
  InventoryItem.MOTORCYCLE_DISPLACEMENT,
  InventoryItem.VEHICLE_CYLINDERS,
  InventoryItem.MOTORCYCLE_CYLINDERS,
  InventoryItem.MOTORCYCLE_FUEL_TYPE,
  // InventoryItem.MOTORCYCLE_OPTIONS,
  InventoryItem.VEHICLE_FUEL_TYPE,
  InventoryItem.VEHICLE_OPTIONS,
  InventoryItem.MSRP,
  InventoryItem.PRICE_1,
  InventoryItem.PRICE_2,
  InventoryItem.PURCHASE_PRICE,
  InventoryItem.COST,
  InventoryItem.FEATURED,
  InventoryItem.AS_IS,
  InventoryItem.DEMO,
  InventoryItem.ON_ORDER,
  InventoryItem.IN_TRANSIT,
  InventoryItem.CERTIFIED,
  InventoryItem.SHOW_WEB,
  InventoryItem.ARCHIVED,
  InventoryItem.COMPLETE,
  InventoryItem.COMPLETE_PERCENT,
  InventoryItem.LOCKED,
  InventoryItem.MAPPED,
  InventoryItem.APPOINTMENTS_COUNT,
  InventoryItem.CONVERSATIONS_COUNT,
  InventoryItem.LEADS_COUNT,
  InventoryItem.LEAD_ACTIVITIES_COUNT,
  InventoryItem.TASKS_COUNT,
  InventoryItem.CREATED_BY_NAME,
  InventoryItem.CREATED,
  InventoryItem.UPDATED,
  InventoryItem.SOLD,
  InventoryItem.DAYS_IN_STOCK,
];

export const RETAIL_ITEMS_BASE = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),
  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: ImageType.INVENTORY_ITEM,
  }),
  // ...Rest
  getCellDataByItemColumnType(InventoryItem.ROOFTOP_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 220,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VIN, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
  }),
  getCellDataByItemColumnType(InventoryItem.STOCK_NUMBER, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 180,
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(InventoryItem.STATUS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.TYPE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.YEAR, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
  }),
  getCellDataByItemColumnType(InventoryItem.MAKE_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
  }),
  getCellDataByItemColumnType(InventoryItem.MODEL_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
  }),
  getCellDataByItemColumnType(InventoryItem.SUB_MODEL_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 150,
  }),
  getCellDataByItemColumnType(InventoryItem.TRIM_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 290,
  }),
  getCellDataByItemColumnType(InventoryItem.CONDITION, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 150,
  }),
  getCellDataByItemColumnType(InventoryItem.TAG_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.NOTE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.DESCRIPTION, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 220,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.HIGHLIGHTS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 220,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MSRP, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.PRICE_1, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 150,
  }),
  getCellDataByItemColumnType(InventoryItem.PRICE_2, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 150,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.PURCHASE_PRICE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 180,
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(InventoryItem.COST, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.FEATURED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.AS_IS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.DEMO, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.ON_ORDER, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.IN_TRANSIT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CERTIFIED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.SHOW_WEB, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.ARCHIVED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.COMPLETE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.COMPLETE_PERCENT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 90,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.LOCKED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MAPPED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.APPOINTMENTS_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CONVERSATIONS_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.LEADS_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.LEAD_ACTIVITIES_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.TASKS_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CREATED_BY_NAME, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CREATED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 160,
    cellType: FieldDataType.CUSTOM,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.UPDATED, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.SOLD, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.DAYS_IN_STOCK, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 130,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VIDEOS_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
    width: 120,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_DAMAGE_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
    width: 180,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_EXTERIOR_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
    width: 180,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_INTERIOR_COUNT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
    width: 180,
  }),
]);

export const RETAIL_ITEMS_VEHICLES = Object.freeze<TableCellData[]>([
  // ## attributes of VEHICLE
  getCellDataByItemColumnType(InventoryItem.VEHICLE_MILEAGE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 150,
  }),

  getCellDataByItemColumnType(InventoryItem.VEHICLE_BODY_TYPE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_CAB_TYPE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_EXTERIOR_COLOR, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_INTERIOR_COLOR, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_NUMBER_OF_DOORS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_NUMBER_OF_PASSENGERS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_TRANSMISSION, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_DRIVE_TRAIN, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 90,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_DISPLACEMENT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_CYLINDERS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_FUEL_TYPE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VEHICLE_OPTIONS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
]);

export const RETAIL_ITEMS_MOTORCYCLES = Object.freeze<TableCellData[]>([
  // ## attributes of MOTORCYCLE
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_MILEAGE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 100,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_CATEGORY, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_COLOR, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_DISPLACEMENT, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_CYLINDERS, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_FUEL_TYPE, InventoryItemSettings, {
    ...defaultRetailItemHeaders,
    width: 120,
    enabled: false,
  }),
  /**
   *{
   *  ...defaultRetailItemHeaders(InventoryItem.MOTORCYCLE_OPTIONS),
   *  Width: 120,
   *  Enabled: false,
   *},
   */
]);
