import type { StyledProps } from 'styled-components/macro';
import { css } from 'styled-components/macro';

import type { TooltipTransitions } from 'components/ui/shared/Tooltip';

/**
 * Simple fade animation
 * @param animName the unique identified for this animation
 */
export const fadeInOut: (animName: string) => StyledProps<any> = animName => css`
  ${`
      @keyframes show-${animName} {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes hide-${animName} {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
      animation-fill-mode: forwards;
  `}
`;

/**
 * Move in/out animation for tooltips.
 * @param animName - Name of animation
 * @param transforms - The TooltipTransitions to apply
 */
export const tooltipMoveInOut: (animName: string, transforms: TooltipTransitions) => StyledProps<any> = (
  animName,
  transforms
) => css`
  @keyframes show-${animName} {
    from {
      ${transforms.from}
    }

    to {
      ${transforms.to}
    }
  }

  @keyframes hide-${animName} {
    from {
      ${transforms.to}
    }

    to {
      ${transforms.from}
    }
  }
`;

/**
 * Animation in from right side of screen
 * @param animName the unique identified for this animation
 * @param offsetWidth the width required to put hte element offscreen to the right, number is in pixel values
 */
export const slideInOutFromRight: (animName: string, offsetWidth: number) => StyledProps<any> = (
  animName,
  offsetWidth
) => css`
  ${`
      @keyframes show-${animName} {
        from {
          left: 100%;
        }
        to {
          left: ${`calc(100% - ${offsetWidth}px)`};
        }
      }
      @keyframes hide-${animName} {
        from {
          left: ${`calc(100% - ${offsetWidth}px)`};
        }
        to {
          left: 100%;
        }
      }
      animation-fill-mode: forwards;
  `}
`;
