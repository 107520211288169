import { omit } from 'lodash-es';

import type { GlobalAdminFilters, TransformedGlobalAdminFilters } from 'components/ui/filters/interfaces/globalFilter';
import { GlobalAdminFilterProperties } from 'components/ui/filters/interfaces/globalFilter';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import type { Rooftop, Tag } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';

import { isFeatureEnabledForRooftop } from './featureBundleRooftopUtils';
import { LDFeatureFlags } from './featureFlagUtils';

// Using Params naming convention similar to `featureBundleUtils`
export interface FilterTagParams {
  /** The list of tags available */
  response: Array<Tag>;
  /** The Rooftop */
  rooftop?: Pick<Rooftop, 'bundle'>;
  /** The Feature Flag Options to check against */
  flags?: object;
}

/**
 * Filter out Tags depending on if the Feature Bundle is active via Feature Flags
 */
export const filterTagItems = (options: FilterTagParams): Array<Tag> => {
  const { response = [], rooftop, flags } = options;
  const results = response.filter(tag => {
    /**
     * Filter out by Feature Flag settings
     */
    if (flags && rooftop) {
      const isRetailEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.RETAIL,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      const isAppointmentEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.APPOINTMENT,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      const isTaskEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.TASK,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      const isLeadEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.LEAD,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      const isTradeInEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.TRADE_IN,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      const isConversationsEnabled = isFeatureEnabledForRooftop({
        rooftop,
        feature: FeatureBundleSet.CONVERSATION,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      });

      if (!isAppointmentEnabled && [tag.id, tag.type].includes(EntityType.APPOINTMENT)) {
        return false;
      }

      if (!isRetailEnabled && [tag.id, tag.type].includes(EntityType.RETAIL_ITEM)) {
        return false;
      }

      if (!isTaskEnabled && [tag.id, tag.type].includes(EntityType.TASK)) {
        return false;
      }

      if (!isTradeInEnabled && [tag.id, tag.type].includes(EntityType.TRADE_IN_ITEM)) {
        return false;
      }

      if (
        !isLeadEnabled &&
        ([tag.id, tag.type].includes(EntityType.LEAD) || [tag.id, tag.type].includes(EntityType.MAILBOX))
      ) {
        return false;
      }

      if (!isConversationsEnabled && [tag.id, tag.type].includes(EntityType.CONVERSATION)) {
        return false;
      }
    }
    return true;
  });

  return results;
};

/* Names of searchParam filters where an empty string value is acceptable */
export const acceptableEmptyParams = ['type'];

export const removePaginationParams = searchParams => omit(searchParams, ['first', 'after', 'last', 'before']);

/** The list of routes where the global filtering is turned off */
export const GLOBAL_FILTER_DISABLED_ROUTES = [RoutePath.ROOFTOPS, RoutePath.WEBSITES];

/**
 * Transform the saved global admin filters to a format that can be used in the search filtering. The saved global admin
 * filters are saved as SubStepOption, and then transformed to TransformedGlobalAdminFilters format, which is just
 * the ids of the entities
 */
export const transformGlobalAdminFilters = (data?: GlobalAdminFilters): TransformedGlobalAdminFilters | undefined => {
  const transformedFilters = {
    groupId: data?.[GlobalAdminFilterProperties.GROUPS]
      ? String(data[GlobalAdminFilterProperties.GROUPS].id)
      : undefined,
    rooftopId: data?.[GlobalAdminFilterProperties.ROOFTOPS]
      ? data[GlobalAdminFilterProperties.ROOFTOPS].map(rooftop => String(rooftop.id))
      : undefined,
  };

  if (!transformedFilters.rooftopId && !transformedFilters.groupId) {
    return undefined;
  }
  if (!transformedFilters.rooftopId) {
    delete transformedFilters.rooftopId;
  }
  if (!transformedFilters.groupId) {
    delete transformedFilters.groupId;
  }

  return transformedFilters;
};

/**
 * Determine the initial local search params, which is derived by taking in a set of search params and removing
 * any active global search params.
 */
export const getInitialLocalSearchParams = ({
  searchParams,
  globalSearchParams,
}: {
  /** The search params to initialize with */
  searchParams?: Record<string, unknown>;
  /** The current active global search params */
  globalSearchParams?: GlobalAdminFilters;
}) => {
  const searchParamsCopy = { ...searchParams };

  if (globalSearchParams?.rooftops) {
    delete searchParamsCopy.rooftopId;
  }

  if (globalSearchParams?.groups) {
    delete searchParamsCopy.groupId;
  }

  return searchParamsCopy;
};

/**
 * Merge together the search filters that are local to the current route with the search filters that are globally
 * applied
 */
export const mergeGlobalAdminAndLocalFilters = ({
  globalAdminFilters,
  localSearchFilters,
  requestedRoute,
}: {
  /** The local search filters */
  localSearchFilters?: Record<string, any>;
  /** The global search filters */
  globalAdminFilters?: TransformedGlobalAdminFilters;
  /** The requested route */
  requestedRoute?: RoutePath;
}): Record<string, any> => {
  /*
   * If user is accessing a route where the global filters should be disabled then skip merging any of the
   * global filters
   */
  if (requestedRoute && !isGlobalFiltersAvailableForRoute(requestedRoute)) {
    return localSearchFilters || {};
  }

  let mergedFilters;

  if (globalAdminFilters?.rooftopId || globalAdminFilters?.groupId) {
    mergedFilters = {
      ...localSearchFilters,
      rooftopId: globalAdminFilters?.rooftopId?.length ? globalAdminFilters?.rooftopId : undefined,
      groupId: globalAdminFilters?.groupId,
    };
  } else {
    mergedFilters = {
      ...localSearchFilters,
    };
  }

  // If there is only one item in an array then the filters expect this to be a single string value
  if (mergedFilters.rooftopId?.length === 1) {
    mergedFilters.rooftopId = mergedFilters.rooftopId[0];
  }

  // If there are no rooftopId or groupId values, then remove them from the object entirely
  if (!mergedFilters.rooftopId) {
    delete mergedFilters.rooftopId;
  }
  if (!mergedFilters.groupId) {
    delete mergedFilters.groupId;
  }

  return mergedFilters;
};

/**
 * Determine if a given filter is enabled, based on whether there are active global filters being applied
 */
export const isFilterPanelEnabledGivenActiveGlobalFilters = ({
  globalAdminFilters,
  searchParamName,
}: {
  /** The current active global filters */
  globalAdminFilters?: GlobalAdminFilters;
  /** The filter parameter name */
  searchParamName: string;
}): boolean => {
  // If there are active global filters then the filter should be disabled in the filter panel
  if (['rooftopId', 'groupId'].includes(searchParamName)) {
    return (
      !globalAdminFilters?.[GlobalAdminFilterProperties.ROOFTOPS]?.length &&
      !globalAdminFilters?.[GlobalAdminFilterProperties.GROUPS]
    );
  }

  return true;
};

export const isGlobalFiltersEmpty = (filters?: GlobalAdminFilters): boolean =>
  !filters?.[GlobalAdminFilterProperties.ROOFTOPS]?.length && !filters?.[GlobalAdminFilterProperties.GROUPS];

/**
 * Check to see if the global filters are available for a certain route
 */
export const isGlobalFiltersAvailableForRoute = (route: RoutePath): boolean =>
  !GLOBAL_FILTER_DISABLED_ROUTES.includes(route);
