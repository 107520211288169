import type Tier from 'components/core/createModify/interfaces/tier';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { MMSTBuilderFields } from 'components/sections/createModify/MMST/steps/interfaces';
import { YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import type { Locale } from 'utils/intlUtils';
import { getMultilingualValueFromLocale } from 'utils/intlUtils';

// Used to seed data from a main tier to a MMST sub tier.
export const seedMMSTStep = (prefill = '', { data: { type } }: Tier) => ({ name: prefill, type });

class MMSTStep extends StepComponentCore {
  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, seededData, data },
    } = props;

    const {
      subContexts: {
        userContext: {
          user: { locale },
        },
      },
    } = context;

    const name = seededData?.name || data?.name;
    const multiLingualValue = getMultilingualValueFromLocale(locale.languageTag as Locale);

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [MMSTBuilderFields.NAME]: { selectedValue: { [multiLingualValue]: name } },
    });
  }

  async save() {
    const {
      tier: { seededData },
    } = this.props;

    const variablePresets: {
      year?: number;
      makeId?: string;
      modelId?: string;
      subModelId?: string;
      rooftopId?: string;
      type?: string;
    } = {};

    variablePresets.rooftopId = seededData?.rooftopId;

    switch (seededData?.field) {
      case YMMTTarget.MAKE_ID: {
        variablePresets.type = seededData.type;
        break;
      }

      case YMMTTarget.MODEL_ID: {
        variablePresets.makeId = seededData.makeId;
        break;
      }

      case YMMTTarget.SUB_MODEL_ID: {
        variablePresets.modelId = seededData.modelId;
        break;
      }

      case YMMTTarget.TRIM_ID: {
        variablePresets.subModelId = seededData.subModelId;
        variablePresets.year = Number.parseInt(seededData.year?.id || seededData.year);
        break;
      }
    }

    return super.save(variablePresets);
  }
}

export default MMSTStep;
