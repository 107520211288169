import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { tradeInItemMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { tradeInItemsDetailsQuery } from 'containers/inventoryItems/TradeInItemsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { TradeInItemMetaQuery, TradeInItemMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const TradeInItemQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<TradeInItemMetaQuery, TradeInItemMetaQueryVariables>({
      query: tradeInItemMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const metaResponse = await client.query({
      query: tradeInItemsDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
    return metaResponse.data.item;
  },

  refetchQueries: ['TradeInItemConnectionQuery', 'LeadDetailsContainerQuery', 'TradeInItemCondensedListQuery'],
};

export default TradeInItemQueryMethods;
