import styled, { css } from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { Clickable } from 'components/ui/shared/Button';
import { DIVIDER } from 'styles/color';
import { NEUTRAL_700 } from 'styles/tokens';

export const GridSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  min-width: 0;

  > :not(:first-child) {
    margin-top: 8px;
  }

  ::before {
    content: ' ';
    position: absolute;
    bottom: -15px;
    height: 1px;
    width: calc(100% + 30px);
    background: ${DIVIDER};
  }

  ::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: -15px;
    width: 1px;
    bottom: 0;
    background: ${DIVIDER};
  }
`;

/**
 * If a GridSectionTitle has an icon (hasIcon = true), then its display is set to flex in order to position the icon
 * on the same line. However this will cause text to not overflow. If you are using GridSectionTitle with an icon
 * ensure the text is contained in its own <Text> component.
 */
export const GridSectionTitle = styled(TertiaryLabel)<{ hasIcon?: boolean }>`
  ${({ hasIcon }) =>
    !!hasIcon &&
    css`
      display: flex;
    `};
  line-height: unset;
  align-items: center;
  top: 0;
  width: 100%;

  ${Text} {
    top: 0;
    line-height: unset;
    padding-right: 4px;
  }

  ${Clickable} {
    margin-left: auto;
    display: flex;
    align-self: center;
  }

  svg {
    fill: ${NEUTRAL_700};
  }
`;

/**
 * Use as a wrapper for any Text component and a CopyButton component to allow user to copy the fields value
 */
export const CopyButtonGridViewWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 7px;
  }
`;
