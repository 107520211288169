import { useCallback, useMemo } from 'react';

import { isEmpty } from 'lodash-es';

import { SecondaryViewType } from 'containers/nestedView/interfaces';
import type { SecondaryListViewProps } from 'containers/nestedView/NestedViewSettings';
import { NestedViewListSettings } from 'containers/nestedView/NestedViewSettings';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import { CustomEntity } from 'enums/extendedEntityType';
import { RoutePath } from 'enums/routePath';
import { useNestedView } from 'hooks/contexts/useNestedView';
import useEntityView from 'hooks/useEntityView';
import { EntityType } from 'store/api/graph/interfaces/types';

import { usePrevious } from './usePrevious';

export interface LinkedListProps {
  /** Type of entity shown in linked list */
  listEntity: ExtendedEntityType;
  /** Title of linked list */
  title: string;
  /** Whether or not this section is nested */
  isNested?: boolean;
}

/**
 * Custom hook used to help interact with nested view linked lists.
 *
 * e.g. Useful for common logic used in UI components that show a nested linked list e.g. LinkedListViewer
 */
const useLinkedList = (
  listEntity: ExtendedEntityType,
  title: string,
  isNested?: boolean,
  item?: any,
  configOverride?: Pick<SecondaryListViewProps, 'alwaysShowCreateButton'>
) => {
  const { section, itemId } = useEntityView();
  const { setView, viewItems, close: closeList } = useNestedView();
  const viewItemsPrev = usePrevious(viewItems);

  const autoClose = !isEmpty(viewItemsPrev) && isEmpty(viewItems);

  // Extracting primary from current visible viewItem if applicable, extract from route otherwise
  const primaryEntityTarget = useMemo(() => {
    const supportedRoutes = {
      [RoutePath.APPOINTMENTS]: EntityType.APPOINTMENT,
      [RoutePath.LEADS]: EntityType.LEAD,
      [RoutePath.TASKS]: EntityType.TASK,
      [RoutePath.RETAIL_ITEMS]: EntityType.RETAIL_ITEM,
      [RoutePath.TRADE_IN_ITEMS]: EntityType.TRADE_IN_ITEM,
      [RoutePath.LEAD_ACTIVITIES]: EntityType.LEAD_ACTIVITY,
      [RoutePath.CONVERSATIONS]: EntityType.CONVERSATION,
      [RoutePath.USERS]: CustomEntity.USER,
      [RoutePath.ROOFTOPS]: EntityType.ROOFTOP,
    };

    return isNested
      ? viewItems[0]?.entityType
      : Object.entries(supportedRoutes).find(([key]) => key.toLowerCase() === `/${section?.toLowerCase()}`)?.[1] || '';
  }, [isNested, viewItems, section]);

  /*
   * Extracting the entity id from current visible viewItem if applicable, extract from route otherwise. This id will
   * be used when getting the queryVars from the NestedViewListSettings configuration object. However some
   * lists require queryVars that do not need the entity id, but some other property instead. Example, the bulk
   * adjustment list query in the retail item linked section requires a rooftop id, not the retail item id. We can
   * pass in custom queryVars when calling the showList method (see below).
   */
  const primaryEntityId = useMemo(
    () => (isNested ? viewItems[0]?.queryVars?.id : itemId),
    [isNested, viewItems, itemId]
  ) as string;

  const queryVars = useMemo(
    () => NestedViewListSettings[primaryEntityTarget]?.[listEntity]?.(primaryEntityId),
    [primaryEntityTarget, listEntity, primaryEntityId]
  );

  const showList = useCallback(
    (overrideQueryVars?: any) =>
      setView({
        entityType: listEntity,
        viewType: SecondaryViewType.LIST,
        title,
        queryVars: overrideQueryVars || queryVars,
        replaceViewItems: !isNested,
        data: item,
        configOverride,
      }),
    [setView, listEntity, title, queryVars, isNested, item, configOverride]
  );

  return { showList, closeList, autoClose };
};

export default useLinkedList;
