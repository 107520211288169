import { InventoryItem, InventoryItemConfig } from 'enums/columns/inventoryItem';
import { FieldDataType } from 'enums/fieldDataType';
import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

export const getFilterField = (field: InventoryItem) => `filter.${field}`;

const transformToFilterSettings = (fields: InventoryItem[], type: FieldDataType) =>
  fields.reduce((acc, curr) => {
    acc[`filter.${curr}`] = { ...InventoryItemConfig[curr], type };
    return acc;
  }, {});

export enum RetailFilters {
  FILTER_DAYS_IN_STOCK = 'filter.daysInStock',
  FILTER_TYPE = 'filter.type',
  FILTER_CONDITION = 'filter.condition',
  FILTER_STATUS = 'filter.status',
  FILTER_TAG_ID = 'filter.tagId',
  FLAGS = 'flags',
  FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE = 'filter.vehicleAttributes.fuelType',
  FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE = 'filter.vehicleAttributes.bodyType',
  FILTER_VEHICLE_ATTRIBUTES_MILEAGE = 'filter.vehicleAttributes.mileage',
  FILTER_MOTORCYCLE_ATTRIBUTES_MILEAGE = 'filter.motorcycleAttributes.mileage',
  FILTER_YEAR_RANGE = 'filter.yearRange',
  FILTER_MAKE_ID = 'filter.makeId',
  FILTER_MAKE_ID_EXCLUDED = 'filter.makeIdExcluded',
  FILTER_MODEL_ID = 'filter.modelId',
  FILTER_SUBMODEL_ID = 'filter.subModelId',
  FILTER_HAS_PRICE = 'filter.hasPrice',
  FILTER_HAS_MILEAGE = 'filter.hasMileage',
}

const RetailFiltersConfig: TableSettingsType = {
  [RetailFilters.FILTER_DAYS_IN_STOCK]: { label: 'days_in_stock', type: FieldDataType.CUSTOM },
  [RetailFilters.FILTER_TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [RetailFilters.FILTER_CONDITION]: { label: 'condition', type: FieldDataType.ENUM },
  [RetailFilters.FILTER_STATUS]: { label: 'status', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_TAG_ID]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_VEHICLE_ATTRIBUTES_FUEL_TYPE]: { label: 'fuel_type', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_VEHICLE_ATTRIBUTES_BODY_TYPE]: { label: 'body_type', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_VEHICLE_ATTRIBUTES_MILEAGE]: { label: 'mileage', type: FieldDataType.DEFAULT },
  [RetailFilters.FILTER_MOTORCYCLE_ATTRIBUTES_MILEAGE]: { label: 'mileage', type: FieldDataType.DEFAULT },
  [RetailFilters.FILTER_YEAR_RANGE]: { label: 'year_one', type: FieldDataType.CUSTOM },
  [RetailFilters.FILTER_MAKE_ID]: { label: 'included_make', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_MAKE_ID_EXCLUDED]: { label: 'excluded_make', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_MODEL_ID]: { label: 'model_one', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_SUBMODEL_ID]: { label: 'submodel_one', type: FieldDataType.BADGE_LIST },
  [RetailFilters.FILTER_HAS_PRICE]: { label: 'has_price', type: FieldDataType.BOOLEAN },
  [RetailFilters.FILTER_HAS_MILEAGE]: { label: 'has_mileage', type: FieldDataType.BOOLEAN },

  // Flags
  ...transformToFilterSettings(
    [
      InventoryItem.MAPPED,
      InventoryItem.SHOW_WEB,
      InventoryItem.AS_IS,
      InventoryItem.CERTIFIED,
      InventoryItem.DEMO,
      InventoryItem.FEATURED,
      InventoryItem.ON_ORDER,
      InventoryItem.IN_TRANSIT,
      InventoryItem.COMPLETE,
    ],
    FieldDataType.BOOLEAN
  ),
};

export const RetailFiltersSettings = translateFields(RetailFiltersConfig);
