import type { ElementType, ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import { InputToggleButton } from 'components/ui/shared/Button';

import { Input, InputContainer, InputSuffix } from './InputText';
import type { NumberInputSettings } from './NumberInput';

const InputTypes = styled(InputSuffix)`
  pointer-events: all;
  right: 8px;
  display: flex;
  transform: translateY(-50%); /* - half height */
`;

const ToggleInputContainer = styled(InputContainer)<{ toggleButtonsWidth?: number }>`
  ${
    /** A right padding is applied to ensure text field does not overlap with the toggle buttons. */
    props => props.toggleButtonsWidth && `> :first-child { padding-right: ${props.toggleButtonsWidth}px; }`
  };
`;

/*
 * A ToggleType requires an ID, when the inputs onChange is called, this ID is what will be sent
 * - label: string to use in the toggle button
 * - icon: icon to use in the toggle button
 */
export interface ToggleType {
  icon?: ReactNode;
  label?: string;
  id: string;
  settings?: NumberInputSettings;
}

/*
 * The ToggleValue is the format used for the ToggleInput's `selectedValue`
 */
export interface ToggleValue {
  id: string;
  value?: string | number;
}

export interface ToggleInputSettings {
  input: ElementType;
}

/*
 * The ToggleInput is configured with the following:
 * - types: array of possible toggle types to use
 * - onChange: passes the currently selected toggle type id, and the value of the input field
 */
export interface ToggleInputType {
  types: ToggleType[];
  onChange?: (e: Record<'currentTarget', { value: { id: string; value?: string | number } }>) => void;
  selectedValue?: ToggleValue;
  settings?: ToggleInputSettings;
}

const ToggleInput = ({ selectedValue, types, onChange, settings, ...props }: ToggleInputType) => {
  // Select the first toggle type by default
  const [inputValue, setInputValue] = useState(selectedValue);
  const [currentType, setCurrentType] = useState(types.find(({ id }) => id === selectedValue?.id) || types[0]);
  const { input: ElementInput, ...otherSettings } = useMemo(() => ({ input: Input, ...settings }), [settings]);

  // We need a ref to get the cumulative width of all the toggle buttons so we can set the offset width of the input box
  const toggleTypeRef = useRef<HTMLDivElement>(null);

  const onInputChange = useCallback(
    e => {
      if (onChange) {
        const value = { id: currentType?.id, value: e.currentTarget.value };
        setInputValue(value);
        onChange?.({ currentTarget: { value } });
      }
    },
    [onChange, setInputValue, currentType]
  );

  const onTypeChange = useCallback(
    (type: ToggleType) => () => {
      if (onChange) {
        const value = { ...inputValue, id: type.id };
        setInputValue(value);
        setCurrentType(type);
        onChange?.({ currentTarget: { value } });
      }
    },
    [onChange, setCurrentType, inputValue]
  );

  // An additional 10px is added for spacing between the first toggle button and the end of the text field.
  const toggleButtonsWidth = toggleTypeRef?.current ? toggleTypeRef?.current?.offsetWidth + 10 : 0;

  return (
    <ToggleInputContainer toggleButtonsWidth={toggleButtonsWidth}>
      {ElementInput && (
        <ElementInput
          onChange={onInputChange}
          defaultValue={inputValue?.value}
          settings={{ ...otherSettings, ...currentType?.settings }}
          {...props}
        />
      )}
      <InputTypes ref={toggleTypeRef}>
        {types.map(type => (
          <InputToggleButton selected={currentType === type} onClick={onTypeChange(type)} key={type?.id}>
            {type?.icon && type.icon}
            {type?.label && (
              <span
                css={
                  type?.icon
                    ? css`
                        margin-left: 7px;
                      `
                    : undefined
                }
              >
                {type?.label}
              </span>
            )}
          </InputToggleButton>
        ))}
      </InputTypes>
    </ToggleInputContainer>
  );
};

export default ToggleInput;
