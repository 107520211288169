import type { ContextType, ReactNode } from 'react';
import { Component } from 'react';

import type { Location } from 'react-router-dom';

import LoggingService from 'components/core/logging/LoggingService';
import { UserContext } from 'contexts/UserContext';
import { RoutePath } from 'enums/routePath';
import type { RouterHooksHOCProps } from 'hooks/withRouter';
import { withRouter } from 'hooks/withRouter';
import type { UserType } from 'store/api/graph/responses/responseTypes';
import { authManager, isLoggedIn } from 'utils/authUtils';
import { reloadAppIfOutdated } from 'utils/deviceUtils';
import { lastLoginStorage } from 'utils/storage/auth';

enum RouteSegment {
  TOKEN = '/\\S+$',
}

/* A list of paths that should not be accessible if logged in */
export const noAuthPaths = [
  RoutePath.FORGOT_PASSWORD,
  RoutePath.LOGIN,
  RoutePath.MOBILE_RICH_TEXT_EDITOR,
  RoutePath.REQUEST_INVITE,
  RoutePath.RESET_PASSWORD + RouteSegment.TOKEN, // Requires a token: `/reset-password/token123`
];

interface Props {
  children: ReactNode;
  router: RouterHooksHOCProps;
}

class RouterRoot extends Component<Props> {
  static contextType = UserContext;
  context: ContextType<typeof UserContext>;

  async componentDidMount() {
    const { location } = this.props.router;

    // Conditionally set routing target
    this.conditionallySetRoutingTarget(location);

    if (this.requiresUnauthorizedRedirect()) {
      return;
    }

    if (process.env.NODE_ENV === 'production') {
      // Set a 5 minute interval to check if we're running the latest version of the application
      setInterval(() => void reloadAppIfOutdated(), 1000 * 60 * 5);
    }

    void this.fetchUserInfo();
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { location } = this.props.router;
    const { location: locationPrev } = prevProps.router;
    const isAuthenticated = isLoggedIn();

    if (this.requiresUnauthorizedRedirect()) {
      return;
    }

    // If we are changing routes, set the routing target
    if (location.pathname !== locationPrev.pathname) {
      this.conditionallySetRoutingTarget(location);

      // If a login occurred in another session, refetch the user info
      if ((this.context?.lastLogin || 0) < (lastLoginStorage.get() || 0)) {
        await this.fetchUserInfo();
      }

      if (!isAuthenticated) {
        // If not authenticated, clear the UserContext regardless of whether or not a protected route
        this.context?.setUser({} as UserType);
      }
    }
  }

  async fetchUserInfo() {
    const isAuthenticated = isLoggedIn();

    // Attempt fetching user info
    const response = await authManager.tryLogin();

    LoggingService.setUser(response?.data?.user);

    this.context?.setUser(
      response?.data?.user
        ? { ...response?.data.user, metadata: response?.data.metadata.mutation.user }
        : ({} as UserType)
    );
    // Always set the lastLogin user context to mirror localStorage on fetch
    this.context?.setLastLogin(lastLoginStorage.get());

    if (isAuthenticated && noAuthPaths.includes(this.props.router?.location?.pathname as RoutePath)) {
      this.props.router?.replace(RoutePath.HOME);
    }
  }

  conditionallySetRoutingTarget(location: Location) {
    if (this.requiresAuth(location)) {
      authManager.routingTarget = location;
    }
  }

  requiresAuth(location: Location): boolean {
    const { pathname } = location;
    return !noAuthPaths.reduce((matches, noAuthPath) => matches || !!new RegExp(noAuthPath).test(pathname), false);
  }

  requiresUnauthorizedRedirect(): boolean {
    const isAuthenticated = isLoggedIn();

    if (!isAuthenticated && this.requiresAuth(this.props.router?.location)) {
      // Not logged in on a page requiring valid authentication? Redirect to login
      this.props.router?.replace(RoutePath.LOGIN);
      return true;
    }
    return false;
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RouterRoot);
