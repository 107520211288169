import type { ElementType, ReactElement } from 'react';

import { css } from 'styled-components/macro';

import type ClearSettings from 'components/core/createModify/interfaces/clearSettings';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  getInventoryItemOptions,
  getLeadOptions,
  getLeadSourceOptions,
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
} from 'components/sections/shared/ItemMetaHelpers';
import EmailIcon from 'components/ui/icons/EmailIcon';
import FormIcon from 'components/ui/icons/FormIcon';
import PhoneIcon from 'components/ui/icons/PhoneIcon';
import TestDriveIcon from 'components/ui/icons/TestDriveIcon';
import TradeInIcon from 'components/ui/icons/TradeInIcon';
import WalkInIcon from 'components/ui/icons/WalkInIcon';
import { RetailListItem } from 'components/ui/lists/RetailListItem';
import { TradeInListItem } from 'components/ui/lists/TradeInListItem';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import type {
  FormLeadActivityAttributesInput,
  InquiryLeadActivityAttributesInput,
  PhoneCallLeadActivityAttributesInput,
  TestDriveLeadActivityAttributesInput,
  TradeInLeadActivityAttributesInput,
  WalkInLeadActivityAttributesInput,
} from 'store/api/graph/interfaces/types';
import {
  AccessLevel,
  FormLeadActivityAttributesInputParameter,
  InquiryLeadActivityAttributesInputParameter,
  LeadActivityType,
  PhoneCallLeadActivityAttributesInputParameter,
  ResourceType,
  TestDriveLeadActivityAttributesInputParameter,
  TradeInLeadActivityAttributesInputParameter,
  WalkInLeadActivityAttributesInputParameter,
} from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { getLeadActivityInventoryItem } from 'utils/leadActivityUtils';

import { DetailsLeadActivityBuilderFields } from './interfaces';

const { t } = translate;

class DetailsStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: {
        data = {},
        seededData,
        isCreating,
        activeStep,
        metadata: {
          mutation: { leadActivity: leadActivityTypesMetadata },
        },
        metadata,
      },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, hasPermissions, canAccessMultipleRooftops },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const currentType = data.type || LeadActivityType.PHONE_CALL;
    const canEditCurrentType = [
      LeadActivityType.WALK_IN,
      LeadActivityType.TEST_DRIVE,
      LeadActivityType.PHONE_CALL,
    ].includes(currentType);

    const rooftop = seededData?.rooftopName || data.rooftopName;
    const lead = seededData?.leadName || data.leadName;
    const inventoryItemName = seededData?.inventoryItemName || getLeadActivityInventoryItem(data.attributesName);
    const isTradeInItem = inventoryItemName?.__typename === 'TradeInItem';
    // LeadActivity builder specific: TradeIns cannot be modified.
    const isModifyingTradeInItem = !isCreating && isTradeInItem;

    const selectedRooftopValue = rooftop || getUsersRooftop(user);

    const isRetailFeatureEnabled = isFeatureEnabledForRooftop({
      rooftop: selectedRooftopValue,
      feature: FeatureBundleSet.RETAIL,
      featureFlagOn: rooftopPackageEnabled,
    });

    // Converting to readable fields and setting presets
    const fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [DetailsLeadActivityBuilderFields.TYPE]: {
        hasSeparator: !isWhiteLabelScoped,
        selectedValue: currentType,
        settings: {
          types: [
            {
              id: LeadActivityType.WALK_IN,
              icon: WalkInIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.WALK_IN)?.name,
            },
            {
              id: LeadActivityType.TEST_DRIVE,
              icon: TestDriveIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.TEST_DRIVE)?.name,
            },
            {
              id: LeadActivityType.PHONE_CALL,
              icon: PhoneIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.PHONE_CALL)?.name,
            },

            /*
             * LeadActivity types SMS, CHAT, and EMAIL cannot be created through the lead builder,
             * but the fields notes, and assigned to can be edited, so if the user is modifying a
             * lead we'll need to include all lead types in the field configs.
             */
            !isCreating && {
              id: LeadActivityType.FORM,
              icon: FormIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.FORM)?.name,
            },

            !isCreating && {
              id: LeadActivityType.INQUIRY,
              icon: EmailIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.INQUIRY)?.name,
            },

            !isCreating && {
              id: LeadActivityType.TRADE_IN,
              icon: TradeInIcon,
              label: leadActivityTypesMetadata?.type.find(type => type.id === LeadActivityType.TRADE_IN)?.name,
            },
          ].filter(Boolean),
          disabled: !isCreating,
          customStyles: isCreating
            ? css`
                grid-template-columns: repeat(3, 1fr);

                > *:nth-child(odd):last-child {
                  grid-column: initial;
                }
              `
            : undefined,
        },
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.OMITTED,
          active: !isCreating,
        }),
      },
      [DetailsLeadActivityBuilderFields.ROOFTOP_ID]: {
        selectedValue: selectedRooftopValue,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !isCreating || !!lead || !!inventoryItemName || (!isCreating && !canEditCurrentType),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isCreating,
          },
        ]),
      },
      [DetailsLeadActivityBuilderFields.SOURCE_ID]: {
        selectedValue: data.sourceName,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !selectedRooftopValue || (!isCreating && !canEditCurrentType),
          },
        ]),
      },
      [DetailsLeadActivityBuilderFields.LEAD_ID]: {
        selectedValue: lead,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !selectedRooftopValue || (isCreating && lead) || (!isCreating && !canEditCurrentType),
          },
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isCreating,
          },
        ]),
      },
      [DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID]: {
        renderElement: this.renderInventoryItem as ElementType,
        selectedValue: inventoryItemName && { ...inventoryItemName, metadata },
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !selectedRooftopValue || !!(isCreating && !!inventoryItemName) || isModifyingTradeInItem,
          },
          /**
           * This is a special case to handle a different set of permission requirements for the inventory item
           * selection. Since both Trade-In and Retail items can be selected at the same time, this field should be
           * hidden only if the user does not have permission for BOTH Trade and Retail.
           */
          {
            type: StepFieldDisplayType.HIDDEN,
            active:
              !hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]) ||
              !isRetailFeatureEnabled,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
        clear: this.getClearPropertyByType(currentType),
      },
    });

    this.fields = defineFieldValues(fields, data);

    this.setupInventoryItemStepField();

    // Async subpanel configurations
    this.asyncConfigurations = {
      [DetailsLeadActivityBuilderFields.ROOFTOP_ID]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId: seededData?.userGroupId,
                features: { lead: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.LEAD,
                    featureFlagOn: rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      [DetailsLeadActivityBuilderFields.SOURCE_ID]: {
        request: () =>
          getLeadSourceOptions(
            getStepField(DetailsLeadActivityBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id
          ),
        disableKeywordRefetch: true,
      },
      [DetailsLeadActivityBuilderFields.LEAD_ID]: {
        request: keyword =>
          getLeadOptions(
            getStepField(DetailsLeadActivityBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id,
            keyword
          ),
      },
      [DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID]: {
        request: keyword =>
          // TODO: Provide these categories  with their own connections  to show errors
          getInventoryItemOptions(
            getStepField(DetailsLeadActivityBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id,
            keyword,
            undefined,
            hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }]),
            false
          ),
      },
    };
  }

  /** Returns the clear property needed for the Retail Item field, based on the activity type */
  private getClearPropertyByType(type: LeadActivityType): ClearSettings | undefined {
    switch (type) {
      case LeadActivityType.WALK_IN: {
        return {
          field: WalkInLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'walkInAttributes',
        };
      }

      case LeadActivityType.TEST_DRIVE: {
        return {
          field: TestDriveLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'testDriveAttributes',
        };
      }

      case LeadActivityType.PHONE_CALL: {
        return {
          field: PhoneCallLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'phoneCallAttributes',
        };
      }

      case LeadActivityType.TRADE_IN: {
        return {
          field: TradeInLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'tradeInAttributes',
        };
      }

      case LeadActivityType.FORM: {
        return {
          field: FormLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'formAttributes',
        };
      }

      case LeadActivityType.INQUIRY: {
        return {
          field: InquiryLeadActivityAttributesInputParameter._retailItemOfInterestId,
          target: 'inquiryAttributes',
        };
      }

      default: {
        return undefined;
      }
    }
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    const {
      subContexts: {
        userContext: { hasPermissions },
        featureFlags: { rooftopPackageEnabled },
      },
    } = this.getContext();

    if (stepField.queryVar === DetailsLeadActivityBuilderFields.ROOFTOP_ID) {
      removeDisplayType(
        getStepField(DetailsLeadActivityBuilderFields.LEAD_ID, this.fields),
        StepFieldDisplayType.DISABLED
      );
      removeDisplayType(
        getStepField(DetailsLeadActivityBuilderFields.SOURCE_ID, this.fields),
        StepFieldDisplayType.DISABLED
      );
      removeDisplayType(
        getStepField(DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID, this.fields),
        StepFieldDisplayType.DISABLED
      );

      const isRetailFeatureEnabled = isFeatureEnabledForRooftop({
        rooftop: value,
        feature: FeatureBundleSet.RETAIL,
        featureFlagOn: rooftopPackageEnabled,
      });

      if (
        isRetailFeatureEnabled &&
        hasPermissions([{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.BASIC }])
      ) {
        removeDisplayType(
          getStepField(DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID, this.fields),
          StepFieldDisplayType.HIDDEN
        );
      }
    }
    if (stepField.queryVar === DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID) {
      const isTradeInItem = value?.__typename === 'TradeInItem';
      getStepField(DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID, this.fields).label = isTradeInItem
        ? t('trade_ins_one')
        : t('retail_items_one');
    }
    super.onFieldSelection(stepField, value);
  }

  renderInventoryItem = (props): ReactElement =>
    props.__typename === 'RetailItem' ? <RetailListItem {...props} /> : <TradeInListItem {...props} />;

  // LeadActivity builder specific: inventoryItem logic
  setupInventoryItemStepField = () => {
    const {
      tier: { isCreating },
    } = this.props;

    const inventoryItemStepField = getStepField(
      DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID,
      this.fields
    );
    const isTradeInItem = inventoryItemStepField.selectedValue?.__typename === 'TradeInItem';

    // Show specific inventoryItem copy
    if (!isCreating || !!inventoryItemStepField.selectedValue) {
      inventoryItemStepField.label = isTradeInItem ? t('trade_ins_one') : t('retail_items_one');
    }

    // When modifying, only retail items should be available for selection.
    if (!isCreating && inventoryItemStepField.subStepCategories) {
      inventoryItemStepField.subStepCategories = inventoryItemStepField.subStepCategories.filter(
        ({ conditionValue }) => conditionValue === 'RetailItem'
      );
    }
  };

  async save() {
    const type = getStepField(DetailsLeadActivityBuilderFields.TYPE, this.fields).selectedValue;
    const inventoryItemField = getStepField(DetailsLeadActivityBuilderFields.RETAIL_ITEM_OF_INTEREST_ID, this.fields);
    const inventoryItem = inventoryItemField.selectedValue;

    /*
     * If no inventory item is selected, remove the OMITTED display type so that
     * the `clear` fields can be added to the payload
     */
    if (!inventoryItem) {
      removeDisplayType(inventoryItemField, StepFieldDisplayType.OMITTED);
      return super.save();
    }

    // If an inventory item is selected, set its ID under the proper attribute's name
    let attributes;

    switch (type) {
      case LeadActivityType.WALK_IN: {
        attributes = {
          walkInAttributes: { retailItemOfInterestId: inventoryItem?.id } as WalkInLeadActivityAttributesInput,
        };
        break;
      }

      case LeadActivityType.TEST_DRIVE: {
        attributes = {
          testDriveAttributes: { retailItemOfInterestId: inventoryItem?.id } as TestDriveLeadActivityAttributesInput,
        };
        break;
      }

      case LeadActivityType.PHONE_CALL: {
        attributes = {
          phoneCallAttributes: { retailItemOfInterestId: inventoryItem?.id } as PhoneCallLeadActivityAttributesInput,
        };
        break;
      }

      case LeadActivityType.TRADE_IN: {
        attributes = {
          tradeInAttributes: {
            retailItemOfInterestId: inventoryItem?.id,
          } as TradeInLeadActivityAttributesInput,
        };
        break;
      }

      case LeadActivityType.FORM: {
        attributes = {
          formAttributes: {
            retailItemOfInterestId: inventoryItem?.id,
          } as FormLeadActivityAttributesInput,
        };
        break;
      }

      case LeadActivityType.INQUIRY: {
        attributes = {
          inquiryAttributes: {
            retailItemOfInterestId: inventoryItem?.id,
          } as InquiryLeadActivityAttributesInput,
        };
        break;
      }

      default: {
        attributes = {};
      }
    }

    return super.save(attributes);
  }
}

export default DetailsStep;
