import type { HTMLAttributes, ReactNode, UIEvent } from 'react';
import { useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { LIST_ITEM_HEIGHT } from 'styles/layouts';
import { BLUE_500, NEUTRAL_0, NEUTRAL_050, NEUTRAL_200 } from 'styles/tokens';
import { convertedNestedString } from 'utils/stringUtils';
import { getCellClassName } from 'utils/tableUtils';

import EditIcon from '../icons/EditIcon';

const EditButton = styled.div`
  background: ${NEUTRAL_200};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    fill: ${NEUTRAL_0};
  }
`;

const Cell = styled.div<{ cellStyles?: string | string[]; canEdit: boolean; isEditing?: boolean; width: number }>`
  display: flex;
  align-items: center;
  height: ${LIST_ITEM_HEIGHT};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  color: black;
  text-align: left;
  position: relative;

  ${({ cellStyles }) => (Array.isArray(cellStyles) ? cellStyles?.join(' ') : cellStyles || '')}
  ${({ canEdit, isEditing }) =>
    isEditing &&
    css`
      cursor: ${canEdit ? 'pointer' : 'not-allowed'};
    `}
  &:hover {
    ${({ canEdit }) =>
      canEdit &&
      css`
        background: ${NEUTRAL_050};
      `};
    ${EditButton} {
      background: ${BLUE_500};
    }
  }
`;

export interface TableCellProps {
  cellData: TableCellData;
  cellStyles?: string | string[];
  isEditing?: boolean;
  onClick?: (e: UIEvent<HTMLElement>, data: TableCellData) => void;
  contentWidth?: any;
}

type Props = TableCellProps & HTMLAttributes<HTMLDivElement>;

const TableCell = (props: Props) => {
  const { cellData, cellStyles, isEditing, contentWidth } = useMemo(() => props, [props]);

  const canEdit = useMemo<boolean>(() => !!isEditing && !!cellData.canEdit, [cellData.canEdit, isEditing]);

  // This has to be disabled because cellData.content and cellData.render are actually required dependencies
  /* eslint-disable react-hooks/exhaustive-deps */
  const cellContent = useMemo<ReactNode>(
    () => (cellData.render ? cellData.render() : cellData.content),
    [cellData.render, cellData.content]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const styles = useMemo<string[]>(
    () => [cellStyles, cellData.styles].flat() as string[],
    [cellData.styles, cellStyles]
  );
  const cellId = useMemo(() => getCellClassName({ columnId: cellData.columnId }), [cellData.columnId]);
  const onCellClick = useCallback(e => cellData.onClick?.(e, cellData), [cellData]);

  const width = useMemo(
    () =>
      typeof cellData.width === 'string'
        ? (Number.parseInt(cellData.width.replace('%', '')) / 100) * contentWidth
        : cellData.width,
    [contentWidth, cellData.width]
  );

  return (
    <Cell
      className={convertedNestedString(cellId)}
      isEditing={isEditing}
      canEdit={canEdit}
      cellStyles={styles}
      onClick={onCellClick}
      width={width}
    >
      {cellContent}
      {canEdit && (
        <EditButton>
          <EditIcon width={16} height={16} color={NEUTRAL_0} />
        </EditButton>
      )}
    </Cell>
  );
};

export default TableCell;
