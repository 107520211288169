import { gql } from '@apollo/client';

export const retailItemConnectionFilterFragment = gql`
  fragment RetailItemConnectionFilterFragment on RetailItemConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    archived
    asIs
    certified
    complete
    condition
    daysInStock {
      gte
      lte
    }
    demo
    featured
    hasDescription
    hasLeads
    hasMileage
    hasPhotos
    hasPrice
    hasSubModel
    hasVideos
    inTransit
    leadId
    makeId
    mapped
    modelId
    motorcycleAttributes {
      category
      color
      cylinders
      fuelType
      mileage {
        gte
        lte
      }
    }
    vehicleAttributes {
      bodyType
      cabType
      cylinders
      driveTrain
      exteriorColor
      fuelType
      interiorColor
      mileage {
        gte
        lte
      }
      numberOfDoors
      numberOfPassengers
      options
      transmission
    }
    onOrder
    purchasePrice {
      gte
      lte
    }
    rooftopCity
    rooftopDistance
    rooftopLatitude
    rooftopLocation
    rooftopLongitude
    rooftopRegionCode
    showWeb
    sold {
      gte
      lte
    }
    status
    subModelId
    tagId
    type
    year
    yearRange {
      gte
      lte
    }
    created {
      gte
      lte
    }
    updated {
      gte
      lte
    }
  }
`;
