import type { DocumentNode } from 'graphql';
import { get, merge, mergeWith, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import LoggingService from 'components/core/logging/LoggingService';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { is32IntCompatible } from 'utils/formatUtils';
import { checkForMatchingRefetchQueries } from 'utils/gqlUtils';
import { translate } from 'utils/intlUtils';

import {
  formatApiValues,
  formatClearFields,
  formatFieldValues,
  formatStepError,
  getStepField,
  getStepFieldData,
} from './createModifyFormatUtils';

/**
 * Does any necessary validation through `validateFields` and then saves using the `queryMutation` variable if allowed
 * @param stepController The controller that this proxy method is applied to
 * @param variablePreset Variables that are preseeded in the call, can be overwritten
 * @param variableOverrides Variables will overwrite any calculated ones in this method
 * @param mutationOverride Should theoretically be very rarely used, but lets you specify the `queryMutation`
 * being called when saving
 */
export const saveStep = async <
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TVariables extends Record<string, any> = any,
>(
  stepController: StepComponentCore<TData, TMetaData, TVariables>,
  variablePreset?: TVariables,
  variableOverrides?: TVariables,
  mutationOverride?: DocumentNode
): Promise<boolean> => {
  const {
    tier: {
      type,
      data: { id },
      formData,
      steps,
      onStepSave,
      activeStep,
      isCreating,
      stepFieldData: tierStepFieldData,
    },
  } = stepController.props;
  const { currentStepField } = stepController.state;

  // Applying any open sub-panel selections, else close
  if (
    [StepFieldSubType.MULTI_SELECT, StepFieldSubType.FIELD_GROUP].some(subType =>
      currentStepField?.groupSubTypes?.includes(subType)
    )
  ) {
    stepController.onFieldSelection(
      currentStepField!,
      stepController.subStepControllerRef?.current?.selectedOptions,
      false,
      false
    );
  } else {
    void stepController.toggleSubPanel();
  }

  // Clearing out any errors
  stepController.setTier({ errors: [] });

  if (!stepController.validateFields()) {
    return false;
  }

  const { builderConfig } = stepController.context?.subContexts.builderConfigContext || {};

  let queryMutation: DocumentNode | undefined;
  let shouldAllowClearableFields = !isCreating;
  const isLastStep = steps?.slice(-1).includes(activeStep!);
  const validationExists = !!builderConfig?.[type].mutations?.validate;
  const isValidating = validationExists && !isLastStep;

  // Determining the mutation to use implicitly based on available mutations
  if (mutationOverride) {
    queryMutation = mutationOverride;
    shouldAllowClearableFields = !isCreating || !!id;
  } else if (isValidating) {
    // Validate first, create on the very last step
    queryMutation = builderConfig?.[type].mutations?.validate;
  } else if (isCreating && !id && builderConfig?.[type].mutations?.create) {
    // Create, modify on any following steps
    queryMutation = builderConfig?.[type].mutations?.create;
    shouldAllowClearableFields = false;
  } else {
    // Modify only
    queryMutation = builderConfig?.[type].mutations?.modify;
    shouldAllowClearableFields = true;
  }

  if (!queryMutation) {
    LoggingService.debug({ message: 'No mutation specified from Builder configs' });

    return false;
  }

  // Dynamic variable, don't include in query if not needed to prevent apollo warnings
  const submitVariable = validationExists && { _submit: !isValidating };

  const variables = GetApiValuesForStep(
    stepController,
    { ...variablePreset, ...formData, ...submitVariable },
    variableOverrides,
    shouldAllowClearableFields,
    isValidating
  );

  const refetchQueries = builderConfig?.[type].refetchQueries;

  // Preparing the request
  const options = {
    variables,
  };
  let response;
  try {
    response = await stepController.client.mutate({
      ...options,
      ...checkForMatchingRefetchQueries(refetchQueries),
      mutation: queryMutation,
    });
  } catch (error: any) {
    // Always throw regular errors when not validating, or if the error is not a 400
    if (!isValidating || error.networkError.statusCode !== 400) {
      throw error;
    }

    // Filter out 400 errors for fields that are not in this step
    const filteredErrors: ApiError[] = get(error, 'networkError.result.errors', []).filter(
      error =>
        !error.extensions?.fields ||
        error.extensions?.fields?.some(field => !!getStepField(field, stepController.fields).queryVar)
    );

    if (filteredErrors.length > 0) {
      set(error, 'networkError.result.errors', filteredErrors);
      throw error;
    }
  }

  if (activeStep?.onClosePrompt === stepController.defaultClosePrompt) {
    stepController.setOnClosePrompt(undefined);
  }

  const data = get(response, stepController.queryDataPath || 'data.data');

  /**
   * Saving data where necessary, validations persist `variables` to `formData`.
   * Everything else saves the server response to `data`
   */
  if (isValidating) {
    // Enabling the next step if in validation mode
    if (!!steps && !!activeStep) {
      steps[steps.indexOf(activeStep) + 1].isEnabled = true;
    }
    const stepFieldData = merge(tierStepFieldData, getStepFieldData(stepController.fields));
    stepController.setTier({ stepFieldData, formData: variables });
  } else {
    const stepFieldData = getStepFieldData(stepController.fields);
    stepController.setTier({ data, stepFieldData, formData: undefined }, { isSynchronousUpdate: true });
  }

  // Optional callback that passes through server response on step save
  if (onStepSave) {
    void onStepSave(activeStep!, data);
  }

  return true;
};

/**
 * Method that prepares formatted api values for a specified step with the help of `formatApiValues`
 * e.g. {[filter.rooftop]: {id: 1234, name: "test name"}} => {filter:{rooftop: "1234"}}
 *
 * @param stepController The controller that this proxy method is applied to
 * @param variablePreset Variables that are preseeded in the call, can be overwritten
 * @param variableOverrides Variables will overwrite any calculated ones in this method
 * @param shouldAllowClearableFields When `true`, allow clearable fields
 * @param isValidating When `true` array values bre overwritten on merge rather than concatenated.
 */
export const GetApiValuesForStep = <
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
>(
  stepController: StepComponentCore<TData, TMetaData>,
  variablePreset: any = {},
  variableOverrides: any = {},
  shouldAllowClearableFields = false,
  isValidating = false
): Record<string, unknown> => {
  const {
    tier: {
      data: { id },
      isCreating,
    },
  } = stepController.props;

  const {
    subContexts: {
      userContext: { hasPermissions },
    },
  } = stepController.context!;

  // Duplicating fields array and scrubbing out any omitted fields to exclude from the api call
  const fieldsToFormat = stepController.fields
    .concat()
    .filter(
      field =>
        !field.displayType?.includes(StepFieldDisplayType.OMITTED) &&
        field.groupType !== StepFieldType.FIELD_BUTTON &&
        field.groupType !== StepFieldType.PLAIN_TEXT &&
        (field.requiredPermissions ? hasPermissions(field.requiredPermissions) : true)
    );

  for (const field of stepController.fields) {
    // Converting grouped fields to variables object
    if (
      field.groupSubTypes?.includes(StepFieldSubType.FIELD_GROUP) &&
      (field.options as StepField<TData, TMetaData>[])
    ) {
      for (const item of field.options as StepField<TData, TMetaData>[]) {
        fieldsToFormat.push(item);
      }
    }
  }

  const isClearable = shouldAllowClearableFields || !isCreating;
  const clearFields = isClearable && formatClearFields(fieldsToFormat);

  // Dynamic variable, don't include in query if not needed to prevent apollo warnings
  const idVariable = id ? { id } : {};

  const mergeCustomizer = (obj, src) => {
    /*
     * By default the merge function will concatenate array values. This results in the inability to deselect
     * values properly if an array is set in the `variablePreset` object (formData is merged with variablePresets
     * so this is currently an issue for validate first builders). To properly favour the field values and
     * variableOverrides we want arrays in the source objects to overwrite the values in the target object
     * rather than concatenate.
     */
    if (Array.isArray(obj)) {
      return src;
    }
  };

  return merge(
    formatApiValues(
      mergeWith(
        variablePreset,
        formatFieldValues(fieldsToFormat, idVariable),
        variableOverrides,
        /*
         * To prevent potential regressions we're only using this customizer in validate first builders.
         * Once we have better test coverage for our builders TODO: [ED-11337] we should be able to enable
         * this regardless of if it's a validate builder or not as it is the intended functionality we want.
         */
        isValidating ? mergeCustomizer : undefined
      )
    ),
    clearFields
  );
};

/**
 * Loops through all the `fields` to check if they’re valid.
 * Useful cases are for evaluating stuff like empty strings.
 * Returns a true/false boolean whether or not this check has passed
 *
 *  @param stepController The controller that this proxy method is applied to
 */
export const ValidateFields = <TData extends Record<string, any> = any, TMetaData extends Record<string, any> = any>(
  stepController: StepComponentCore<TData, TMetaData>
): boolean => {
  const invalidFields: StepField<TData, TMetaData>[] = [];
  // Clearing out any errors and saving while specifying which queries need to be refetched
  void stepController.toggleSubPanel();
  stepController.setTier({ errors: [] });

  for (const field of stepController.fields) {
    const isInvalidMultilingualToggleFieldValue = toggleField => {
      if (toggleField.groupType !== StepFieldType.MULTILINGUAL_TOGGLE_FIELD) {
        return false;
      }

      const toggleValue = field.selectedValue as MultilingualString;
      return !toggleValue?.en && !toggleValue?.es && !toggleValue?.fr;
    };

    const isValueInvalid: boolean =
      isInvalidMultilingualToggleFieldValue(field) ||
      (field.groupSubTypes?.includes(StepFieldSubType.MULTI_SELECT) && !field.selectedValue?.length) ||
      (field.selectedValue?.hasOwnProperty('id') && field.selectedValue.id === undefined) ||
      (typeof field.selectedValue === 'number' && !is32IntCompatible(field.selectedValue)) ||
      !field.selectedValue;

    const isFieldRequired =
      field.forceRequired ||
      (field.required &&
        [StepFieldDisplayType.DISABLED, StepFieldDisplayType.HIDDEN, StepFieldDisplayType.OMITTED].every(
          val => !field?.displayType?.includes(val)
        ));

    if (isValueInvalid && isFieldRequired) {
      invalidFields.push(field);
    }
  }

  // General no value validation
  if (invalidFields.length > 0) {
    // Show errors
    stepController.setTier({
      errors: invalidFields.map(field => formatStepError(field)),
    });
    return false;
  } else {
    // Int32 validation
    for (const field of stepController.fields) {
      const value = typeof field.selectedValue === 'object' ? field.selectedValue?.amount : field.selectedValue;

      if (
        (typeof value === 'number' ||
          [StepFieldType.CURRENCY, StepFieldType.MILEAGE, StepFieldType.NUMBER].includes(field.groupType!)) &&
        !!value &&
        !is32IntCompatible(value)
      ) {
        invalidFields.push(field);
      }
    }

    if (invalidFields.length > 0) {
      // Show errors
      stepController.setTier({
        errors: invalidFields.map(field =>
          formatStepError(field, translate.t('x_invalid_number', [field.label || '']))
        ),
      });
      return false;
    }
  }
  return true;
};
