import type { BuilderEntry } from 'components/sections/createModify/interfaces';

export enum BuilderType {
  // ED3
  APPOINTMENT_CREATE = 'appointmentCreate',
  APPOINTMENT_MODIFY = 'appointmentModify',
  CONVERSATION_MODIFY = 'conversationModify',
  CONTACT_CREATE = 'contactCreate',
  CONTACT_MODIFY = 'contactModify',
  LEAD_CREATE = 'leadCreate',
  LEAD_MODIFY = 'leadModify',
  GROUP_CREATE = 'groupCreate',
  LEAD_ACTIVITY_CREATE = 'leadActivityCreate',
  LEAD_ACTIVITY_CREATE_LINKED_RETAIL_ITEMS = 'leadActivityCreateLinkedRetailItems',
  LEAD_ACTIVITY_CREATE_REPLY = 'leadActivityCreateReply',
  LEAD_ACTIVITY_NOTE = 'leadActivityNote',
  LEAD_ACTIVITY_MODIFY = 'leadActivityModify',
  ROOFTOP_LEAD_FORWARD_CREATE = 'rooftopLeadForwardCreate',
  ROOFTOP_LEAD_FORWARD_MODIFY = 'rooftopLeadForwardModify',
  MAILBOX_CREATE = 'mailboxCreate',
  MAILBOX_MODIFY = 'mailboxModify',
  MAKE_CREATE = 'makeCreate',
  MODEL_CREATE = 'modelCreate',
  RETAIL_ITEMS_CREATE = 'retailItemsCreate',
  RETAIL_ITEMS_MODIFY = 'retailItemsModify',
  RETAIL_BULK_ADJUSTMENT_CREATE = 'retailBulkAdjustmentCreate',
  RETAIL_BULK_ADJUSTMENT_MODIFY = 'retailBulkAdjustmentModify',
  RETAIL_ITEM_PRICE_ADJUSTMENT_CREATE = 'retailItemPriceAdjustmentCreate',
  RETAIL_ITEM_PRICE_ADJUSTMENT_MODIFY = 'retailItemPriceAdjustmentModify',
  RETAIL_ITEM_PRICING = 'retailItemPricing',
  RETAIL_ITEM_PRICING_INFO = 'retailItemPricingInfo',
  ROOFTOPS_MODIFY = 'rooftopModify',
  ROOFTOPS_CREATE = 'rooftopCreate',
  ROOFTOP_INTEGRATION_INVENTORY_EXPORT_CREATE = 'rooftopIntegrationInventoryExportCreate',
  ROOFTOP_INTEGRATION_INVENTORY_EXPORT_MODIFY = 'rooftopIntegrationInventoryExportModify',
  ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_CREATE = 'rooftopIntegrationOemAccountExportCreate',
  ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_MODIFY = 'rooftopIntegrationOemAccountExportModify',
  ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_CREATE = 'rooftopIntegrationPhoneCallTrackingCreate',
  ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_MODIFY = 'rooftopIntegrationPhoneCallTrackingModify',
  ROOFTOP_INTEGRATION_CREATE = 'rooftopIntegrationCreate',
  ROOFTOP_INTEGRATION_MODIFY = 'rooftopIntegrationModify',
  ROOFTOP_TAGS_CREATE = 'rooftopTagsCreate',
  ROOFTOP_TAGS_MODIFY = 'rooftopTagsModify',
  ROOFTOP_FINANCE_SEGMENT_CREATE = 'rooftopFinanceSegmentCreate',
  ROOFTOP_FINANCE_SEGMENT_MODIFY = 'rooftopFinanceSegmentModify',
  ROOFTOP_CASH_SEGMENT_CREATE = 'rooftopCashSegmentCreate',
  ROOFTOP_CASH_SEGMENT_MODIFY = 'rooftopCashSegmentModify',
  ROOFTOP_LEASE_SEGMENT_CREATE = 'rooftopLeaseSegmentCreate',
  ROOFTOP_LEASE_SEGMENT_MODIFY = 'rooftopLeaseSegmentModify',
  ROOFTOP_LEASE_SEGMENT_AVAILABLE_MILEAGE_ALLOWANCE_CREATE = 'rooftopLeaseSegmentAvailableMileageAllowanceCreate',
  ROOFTOP_DEPARTMENTS_CREATE = 'rooftopDepartmentsCreate',
  ROOFTOP_DEPARTMENTS_MODIFY = 'rooftopDepartmentsModify',
  SHOPPING_TOOLS_MODIFY = 'shoppingToolsModify',
  SUBMODEL_CREATE = 'subModelCreate',
  TAGS_CREATE = 'tagsCreate',
  TAGS_MODIFY = 'tagsModify',
  TASK_CREATE = 'taskCreate',
  TASK_MODIFY = 'taskModify',
  TRADE_IN_ITEMS_CREATE = 'tradeInItemsCreate',
  TRADE_IN_ITEMS_GUARANTEED_TRADE = 'tradeInItemsGuaranteedTrade',
  TRADE_IN_ITEMS_INVITE_APPRAISERS = 'tradeInItemsInviteAppraisers',
  TRADE_IN_ITEMS_MODIFY = 'tradeInItemsModify',
  TRADE_IN_ITEMS_SUBMIT_APPRAISAL = 'tradeInItemsSubmitAppraisal',
  TRIM_CREATE = 'trimCreate',
  TRIM_PHOTOS_FILTER = 'trimPhotosFilter',
  USER_CREATE = 'userCreate',
  USER_MODIFY = 'userModify',
  USER_PASSWORD_MODIFY = 'userPasswordModify',
  PURCHASE_ACTIVITY_CREATE = 'purchaseActivityCreate',
  PURCHASE_ACTIVITY_MODIFY = 'purchaseActivityModify',
  CREDIT_APPLICATION_CREATE = 'creditApplicationCreate',
  WEBSITE_CREATE = 'websiteCreate',
  WEBSITE_MODIFY = 'websiteModify',
  WEBSITE_LEAD_ROUTING_MODIFY = 'websiteLeadRoutingModify',
  WEBSITE_ROUTE_CREATE = 'websiteRouteCreate',
  WEBSITE_ROUTE_MODIFY = 'websiteRouteModify',
  RETAIL_FILTER = 'retailFilter',
}

export enum DemoBuilderType {
  // Storybook
  DEMO = 'demo',
  DEMO_WARNING = 'demoWarning',
  DEMO_RICH_TEXT = 'demoRichText',
  DEMO_SWITCH = 'demoSwitch',
  DEMO_RANGE = 'demoRange',
  DEMO_NUMBER_RANGE = 'demoNumberRange',
  DEMO_MODAL = 'demoModal',
  DEMO_MODAL_WARNING = 'demoModalWarning',
  DEMO_MODAL_DISCARD = 'demoModalDiscard',
  DEMO_DROPDOWN = 'demoDropdown',
  DEMO_CATEGORY_DROPDOWN = 'demoCategoryDropdown',
  DEMO_SORTABLE_DROPDOWN = 'demoSortableDropdown',
  DEMO_COLOR_PICKER = 'demoColorPicker',
  DEMO_TOGGLE_GROUP = 'demoToggleGroup',
  DEMO_MAP = 'demoMap',
  DEMO_MAP_SELECTED_LOCATION = 'demoMapSelectedLocation',
  DEMO_MASKED_INPUT = 'demoMaskedInput',
  DEMO_MEDIA_PHOTO = 'demoMediaPhoto',
  DEMO_MEDIA_VIDEO = 'demoMediaVideo',
  DEMO_MULTILINGUAL_INPUT = 'demoMultilingualInput',
  DEMO_MULTILINGUAL_TEXTAREA = 'demoMultilingualTextarea',
  DEMO_MULTILINGUAL_PREVIEW = 'demoMultilingualPreview',
  DEMO_MULTISELECT = 'demoMultiSelect',
  DEMO_DATE_PICKER = 'demoDatePicker',
  DEMO_DATE_LIST_PICKER = 'demoDateListPicker',
  DEMO_RADIO = 'demoRadio',
  DEMO_TIME_LIST_PICKER = 'demoTimeListPicker',
  DEMO_TYPES_TEXT = 'demoTypesText',
  DEMO_TYPES_ICON = 'demoTypesIcon',
  DEMO_TYPES_SUBLABEL = 'demoTypesSublabel',
  DEMO_ACTION_INPUT = 'demoActionInput',
  DEMO_COUNTER_INPUT = 'demoCounterInput',
  DEMO_EXPANDABLE = 'demoExpandable',
}

export type BuilderConfig = Record<BuilderType, BuilderEntry>;
export type DemoBuilderConfig = Record<DemoBuilderType, BuilderEntry>;
