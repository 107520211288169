import { gql } from '@apollo/client';

import { contactDetail } from 'store/api/graph/fragments/contactDetail';
import { contactList } from 'store/api/graph/fragments/contactList';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';

export const contactDetailsQuery = gql`
  query ContactDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: contact(id: $id) {
      ...ContactDetailFragment
    }
  }
  ${contactDetail}
`;

export const contactCondensedListQuery = gql`
  query ContactCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: ContactConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: contactConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ContactListFragment
        }
      }
    }
  }
  ${contactList}
  ${pageInfo}
`;
