import type { HTMLAttributes, TextareaHTMLAttributes } from 'react';
import { useCallback } from 'react';

import type { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { isNil, round } from 'lodash-es';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import type {
  RenderObjectSettings,
  StepFieldInputAttributes,
  StepFieldInputConfig,
} from 'components/core/createModify/stepFields/interfaces';
import LoggingService from 'components/core/logging/LoggingService';
import Label, { SecondaryLabel } from 'components/core/typography/Label';
import ActionInput from 'components/ui/forms/shared/ActionInput';
import CounterInput from 'components/ui/forms/shared/CounterInput';
import CurrencyInput from 'components/ui/forms/shared/CurrencyInput';
import DisplacementInput from 'components/ui/forms/shared/DisplacementInput';
import DropDownInput from 'components/ui/forms/shared/DropDownInput';
import InputText, { Input, InputTextArea } from 'components/ui/forms/shared/InputText';
import MaskedInput from 'components/ui/forms/shared/MaskedInput';
import { PhotoInput, PhotoInputContainer } from 'components/ui/forms/shared/MediaInput';
import type { MileageInputType } from 'components/ui/forms/shared/MileageInput';
import MileageInput from 'components/ui/forms/shared/MileageInput';
import MultiInput from 'components/ui/forms/shared/MultiInput';
import MultilingualToggleInput from 'components/ui/forms/shared/MultilingualToggleInput';
import NumberInput from 'components/ui/forms/shared/NumberInput';
import PercentageInput from 'components/ui/forms/shared/PercentageInput';
import PhoneNumberInput from 'components/ui/forms/shared/PhoneNumberInput';
import PrefixedInput from 'components/ui/forms/shared/PrefixedInput';
import RadioInput from 'components/ui/forms/shared/RadioInput';
import RangeInput from 'components/ui/forms/shared/RangeInput';
import RichTextInput from 'components/ui/forms/shared/RichTextInput';
import SliderInput from 'components/ui/forms/shared/SliderInput';
import SwitchInput from 'components/ui/forms/shared/SwitchInput';
import ToggleInput from 'components/ui/forms/shared/ToggleInput';
import ToggleSelect from 'components/ui/forms/shared/ToggleSelect';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { ListItemLabel } from 'components/ui/layouts/ListItem';
import { BuilderButton, Clickable } from 'components/ui/shared/Button';
import LockButton from 'components/ui/shared/LockButton';
import Swatch from 'components/ui/shared/Swatch';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import TooltipButton from 'components/ui/shared/TooltipButton';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { DISABLED_INPUT_OPACITY } from 'styles/forms';
import { ENTITY_PADDING, SPACE_12 } from 'styles/spacing';
import { BLUE_050, BLUE_500, NEUTRAL_0, RED_050, RED_500, SPACE_200 } from 'styles/tokens';
import type { DD_PRIVACY_HTML_ATTRIBUTE } from 'types/Client';
import { formatColorFromData } from 'utils/formatUtils';
import { formatInputValue } from 'utils/formUtils';
import { Locale, translate } from 'utils/intlUtils';
import { localeStorage } from 'utils/storage/intl';

import type StepField from '../interfaces/stepField';

import { Badges, Flags, Tags } from './Badges';
import ClickableInput from './fields/ClickableInput';
import DateInput from './fields/DateInput';
import InputTextValue from './fields/InputTextValue';
import TimeInput from './fields/TimeInput';
import TypeInput from './fields/TypeInput';
import RemovalIcon from './RemovalIcon';

/*
 * =====================================================================
 * Elements ------------------------------------------------------------
 */

const Separator = css`
  border-bottom: 1px solid ${DIVIDER};

  /* For regular fields, the highlighted area does not extend to the edge of the container, so spacing is
     divided in half with one half being controlled with padding and the other with margins
   */
  > :first-child {
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  /* For fields that renders entities, the higlighted area extends to the edge of the container, so all spacing is controlled
     by padding instead of margins
   */
  &.isRenderObject {
    > :first-child {
      margin-bottom: 0;
    }
  }

  /* Same comments as above, except this selects the field immediately proceeding this separator */
  + div {
    > :first-child {
      padding-top: 8px;
      margin-top: 8px;
    }

    :last-of-type {
      margin-bottom: 8px;
    }
  }

  + div.isRenderObject {
    > :first-child {
      padding-top: 17px;
      margin: 0;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

interface StepFieldContainerProps {
  isCustomComponent?: boolean;
  isRenderElementComponent?: boolean;
  hasSeparator?: boolean;
  containerStyles?: FlattenSimpleInterpolation;
}
export const StepFieldContainer = styled.div<StepFieldContainerProps>`
  ${props => props.isCustomComponent && customContainer}
  ${props => props.isRenderElementComponent && renderElementContainer}
  ${props =>
    !props.isRenderElementComponent &&
    css`
      padding: 0 ${ENTITY_PADDING};

      &:first-child {
        padding-top: 17px;
      }

      &:last-of-type:not(:only-child) {
        margin-bottom: 8px;
      }

      &:only-child {
        padding-bottom: 17px;
      }
    `}
  ${SecondaryLabel} {
    text-align: left;
    margin-top: 4px;
  }
  ${props => props.containerStyles}
  ${props => props.hasSeparator && Separator}
`;

interface StepFieldContainerInnerProps {
  active?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  isCustomComponent?: boolean;
  isStacked: boolean;
  containerInnerStyles?: FlattenSimpleInterpolation;
  type?: StepFieldType | string;
}
export const StepFieldContainerInner = styled.div<Partial<StepField> & StepFieldContainerInnerProps>`
  position: relative;
  padding: 8px 10px;
  border-radius: 8px;
  ${props => props.invalid && (props.isCustomComponent ? invalidCustomContainer : invalidContainer)};
  ${props =>
    props.active &&
    ![StepFieldType.TYPES, StepFieldType.TOGGLE_SELECT].includes(props.type as StepFieldType) &&
    activeContainer};
  ${props => props.disabled && disabledContainer};
  ${() => containerInnerContainer}
  ${({ isStacked }) =>
    !isStacked &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  ${PhotoInputContainer} {
    margin-top: ${SPACE_200};
    ${Label} {
      color: ${BODY_TEXT}; /** Overriding active styles to maintain colour */
    }
  }
`;

const CustomInputContainer = styled.div`
  margin-top: ${SPACE_200};

  &:empty {
    display: none;
  }
`;

const RenderElementContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  padding: 19px 0 7px;
`;

const SwatchLarge = styled(Swatch)`
  width: 24px;
  height: 24px;
  margin-left: -5px;
  margin-right: 15px;
  transform: none;
`;

const ChevronRightIconContainer = styled(ChevronRightIcon)`
  position: absolute;
  right: 13px;
  fill: ${BODY_TEXT};
`;

/*
 * =====================================================================
 * Shared styles -------------------------------------------------------
 */

const customContainer = css`
  & + ${StepFieldContainer} {
    margin-top: 0;
  }
  ${StepFieldContainerInner} {
    padding: 8px 25px 8px;
    border-radius: 0;
  }
  ${Clickable} {
    width: 100%;
    padding-bottom: 10px;
  }
`;

const renderElementContainer = css`
  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    padding-bottom: 0;
  }
  ${StepFieldContainerInner} {
    padding-top: 18px;
  }
`;

const activeContainer = css`
  background: ${BLUE_050};
  ${Label},
  ${SecondaryLabel} {
    color: ${BLUE_500};
  }
  ${ListItemLabel} {
    color: ${NEUTRAL_0};
  }
  ${ClickableInput} {
    background: none;
    border: 1px solid ${BLUE_500};
    ${InputTextValue} {
      color: ${BLUE_500};
    }
  }
  ${ChevronRightIconContainer} path {
    color: ${BLUE_500};
  }
`;

const disabledContainer = css`
  pointer-events: none;
  opacity: ${DISABLED_INPUT_OPACITY};
  ${Input} {
    opacity: 1;
  }
`;

const invalidContainer = css`
  ${Label},
  ${SecondaryLabel} {
    color: ${RED_500};
  }
  ${ClickableInput} {
    background: ${RED_050};
    border: 1px solid ${RED_500};
    ${InputTextValue} {
      color: ${RED_500};
    }
    ${ChevronRightIconContainer} path {
      color: ${RED_500};
    }
  }
`;

const invalidCustomContainer = css`
  background: ${RED_050};
  ${Label},
  ${SecondaryLabel} {
    color: ${RED_500};
  }
  ${ChevronRightIconContainer} path {
    color: ${RED_500};
  }
`;

const containerInnerContainer = css<Partial<StepField> & StepFieldContainerInnerProps>`
  /** Defaults */

  ${props =>
    (props.type === StepFieldType.TEXT_AREA || !!props.settings?.textAreaSize) &&
    css`
      display: flex;
      height: 100%;
      flex-direction: column;
      padding-bottom: 17px;
    `}
  /** Overrides */
  ${props => props.containerInnerStyles}
`;

const BuilderLabel = styled(Label)`
  display: flex;
  align-items: center;
  white-space: break-spaces;
`;

const BuilderSecondaryLabel = styled(SecondaryLabel)`
  white-space: break-spaces;
`;

const specialGroupTypes = new Set<StepFieldType>([
  StepFieldType.TAGS,
  StepFieldType.BADGES,
  StepFieldType.FLAGS,
  StepFieldType.RENDER_OBJECT,
  StepFieldType.CUSTOM,
]);

const CenteredFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SuffixIconContainer = styled.span`
  vertical-align: middle;
  margin-left: ${SPACE_12};
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

type Props = StepFieldInputConfig &
  StepFieldInputAttributes &
  MileageInputType &
  HTMLAttributes<HTMLInputElement> & {
    /** Data test ID */
    'data-testid': string;
    /** Privacy level of the field */
    [DD_PRIVACY_HTML_ATTRIBUTE]?: DefaultPrivacyLevel;
  };

const StepFieldInput = ({
  'data-testid': dataTestId,
  hasSeparator,
  label,
  suffixIcon,
  subLabel,
  hyperlink,
  groupType,
  containerStyles,
  containerInnerStyles,
  tooltipContents,
  testId: propTestId,
  renderElement: RenderElement,
  onAction,
  onRequestMaskToggle,
  onLocaleChange,
  onConfirmToggleFieldLock,
  onConfirmDeleteStepField,
  ...props
}: Props) => {
  const testId = propTestId || dataTestId;
  const formattedValue = formatInputValue(props.selectedValue, props.options);
  const isStacked = ![StepFieldType.SWITCH, StepFieldType.TOGGLE_SELECT, StepFieldType.COUNTER].includes(groupType!);

  // Removal callback for CUSTOM or RENDER_OBJECT list items if applicable
  const onRemove = useCallback(
    (removedId?: string) => () => {
      const { selectedValue, onChange } = props;

      // Determining how to remove the value if it is an array or not
      const value = selectedValue?.filter?.(({ id }) => id !== removedId) || null;

      // Force update with new value
      onChange?.(
        {
          currentTarget: {
            value,
          },
        },
        true
      );
    },
    [props]
  );

  const renderInput = () => {
    switch (groupType) {
      case StepFieldType.FIELD_BUTTON: {
        const settings = (props as StepField).settings;
        return (
          <BuilderButton data-testid={testId} colour={settings.colour}>
            {translate.t(settings.buttonLabel)}
          </BuilderButton>
        );
      }

      case StepFieldType.COLOR: {
        const colorInfo = formatInputValue(props.selectedValue, props.options, 'data');
        return (
          <ClickableInput data-testid={testId}>
            <SwatchLarge color={formatColorFromData(colorInfo, formattedValue)} />
            <InputTextValue>{formattedValue}</InputTextValue>
            <ChevronRightIconContainer />
          </ClickableInput>
        );
      }

      case StepFieldType.CURRENCY: {
        return (
          <CurrencyInput
            data-testid={testId}
            {...props}
            defaultValue={formattedValue}
            locale={localeStorage.get() || Locale.EN_CA}
          />
        );
      }

      case StepFieldType.CUSTOM: {
        if (RenderElement) {
          return (
            <RenderElement
              data-testid={testId}
              {...props}
              isInput
              {...(props as StepField).settings}
              suffixIcon={<RemovalIcon shouldHide={props.disabled} onRemove={onRemove()} />}
            />
          );
        } else {
          LoggingService.debug({ message: `No RenderElement class provided for field: ${label}` });
          return null;
        }
      }

      case StepFieldType.DATE: {
        return (
          <DateInput
            data-testid={testId}
            isActive={props.active}
            options={props.options}
            selectedValue={props.selectedValue}
          />
        );
      }

      case StepFieldType.DISPLACEMENT: {
        return <DisplacementInput data-testid={testId} {...props} defaultValue={formattedValue} />;
      }

      case StepFieldType.DROPDOWN: {
        return <DropDownInput data-testid={testId} {...props} defaultValue={formattedValue} isActive={props.active} />;
      }

      case StepFieldType.FLAGS: {
        return <Flags data-testid={testId} {...props} />;
      }

      case StepFieldType.MILEAGE: {
        return <MileageInput data-testid={testId} {...props} mileage={props.selectedValue} />;
      }

      case StepFieldType.MULTI_FIELD: {
        return (
          <MultiInput
            data-testid={testId}
            values={props.selectedValue}
            onChange={props.onChange!}
            {...props}
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.MULTILINGUAL_TOGGLE_FIELD: {
        return (
          <MultilingualToggleInput
            data-testid={testId}
            onLocaleChange={onLocaleChange}
            onChange={props.onChange}
            value={props.selectedValue}
            settings={(props as StepField).settings}
            {...props}
          />
        );
      }

      case StepFieldType.PREFIXED_INPUT: {
        return (
          <PrefixedInput
            data-testid={testId}
            values={props.selectedValue}
            onChange={props.onChange}
            {...props}
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.PERCENTAGE: {
        return (
          <PercentageInput
            data-testid={testId}
            onChange={props.onChange}
            defaultValue={isNil(props.selectedValue) ? undefined : round(props.selectedValue * 100, 2)}
            {...props}
          />
        );
      }

      case StepFieldType.PHOTO: {
        return <PhotoInput testId={testId} {...props} isUploadScalar showPreview defaultValue={props.selectedValue} />;
      }

      case StepFieldType.RENDER_OBJECT: {
        if (RenderElement) {
          const stepField = props as StepField;
          const deleteIcon = callback =>
            (stepField.settings as RenderObjectSettings)?.disableRemoveIcon ? undefined : (
              <RemovalIcon shouldHide={props.disabled} onRemove={callback} />
            );

          return Array.isArray(props.selectedValue) ? (
            props.selectedValue.map(selection => (
              <RenderElement
                data-testid={testId}
                key={selection.id}
                {...selection}
                {...stepField.settings}
                isInput
                suffixIcon={deleteIcon(onRemove(selection.id))}
              />
            ))
          ) : (
            <RenderElement
              data-testid={testId}
              {...props.selectedValue}
              {...stepField.settings}
              isInput
              suffixIcon={deleteIcon(onRemove())}
            />
          );
        } else {
          LoggingService.debug({ message: `No RenderElement class provided for field: ${label}` });
          return null;
        }
      }

      case StepFieldType.SLIDER: {
        return (
          <SliderInput
            testId={testId}
            {...props}
            settings={(props as StepField).settings}
            defaultValue={props.selectedValue}
          />
        );
      }

      case StepFieldType.SWITCH: {
        return <SwitchInput testId={testId} {...props} />;
      }

      case StepFieldType.TYPES: {
        return <TypeInput testId={testId} {...props} settings={(props as StepField).settings} />;
      }

      case StepFieldType.TAGS: {
        return <Tags {...props} />;
      }

      case StepFieldType.TOGGLE: {
        return <ToggleInput data-testid={testId} types={(props as StepField).settings.types} {...props} />;
      }

      case StepFieldType.TOGGLE_SELECT: {
        return (
          <ToggleSelect {...props} options={(props as StepField).settings.options} defaultValue={props.selectedValue} />
        );
      }

      case StepFieldType.BADGES: {
        return <Badges {...props} />;
      }

      case StepFieldType.RADIO: {
        return (
          <RadioInput
            testId={testId}
            defaultValue={props.selectedValue}
            {...props}
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.RANGE: {
        return <RangeInput {...props} {...(props as StepField).settings} testId={testId} />;
      }

      case StepFieldType.TEXT_AREA: {
        return (
          <InputTextArea
            data-testid={testId}
            {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)}
            defaultValue={props.selectedValue}
          />
        );
      }

      case StepFieldType.TIME: {
        return (
          <TimeInput
            data-testid={testId}
            isActive={props.active}
            options={props.options}
            selectedValue={props.selectedValue}
          />
        );
      }

      case StepFieldType.NUMBER: {
        const { selectedValue, ...numberInputProps } = props;
        return (
          <NumberInput
            data-testid={testId}
            defaultValue={selectedValue}
            {...numberInputProps}
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.PHONE_NUMBER: {
        return (
          <PhoneNumberInput
            data-testid={testId}
            defaultValue={props.selectedValue}
            {...props}
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.MASKED_INPUT: {
        return (
          <MaskedInput
            {...props}
            {...(props as StepField).settings}
            testId={testId}
            value={props.selectedValue}
            onRequestToggleMask={onRequestMaskToggle}
          />
        );
      }

      case StepFieldType.RICH_TEXT: {
        return (
          <RichTextInput
            isActive={props.active}
            isInvalid={props.invalid}
            onChange={props.onChange}
            placeholder={props.placeholder}
            selectedValue={props.selectedValue}
            testId={testId}
            height="300px"
            {...(props as StepField).settings}
          />
        );
      }

      case StepFieldType.ACTION_INPUT: {
        return (
          <ActionInput data-testid={testId} {...props} settings={(props as StepField).settings} onAction={onAction} />
        );
      }

      case StepFieldType.COUNTER: {
        return <CounterInput testId={testId} {...props} count={props.selectedValue?.length || 0} />;
      }

      default: {
        return <InputText data-testid={testId} {...props} defaultValue={props.selectedValue} />;
      }
    }
  };

  // Hyperlink for this step field
  const stepFieldHyperlink = hyperlink ? (
    <a css="float: right" className="rich-text-link" href={hyperlink.url} target="_blank" rel="noreferrer">
      {hyperlink.title}
    </a>
  ) : null;

  // Suffix icon next to the label
  const suffixIconElement = suffixIcon ? (
    <SuffixIconContainer css={suffixIcon.css}>{suffixIcon.icon}</SuffixIconContainer>
  ) : null;

  const lockButtonElement = props.groupSubTypes?.includes(StepFieldSubType.LOCKABLE) ? (
    <LockButton onConfirm={onConfirmToggleFieldLock} testId={testId} isLocked={props.isLocked} />
  ) : null;

  if (specialGroupTypes.has(groupType!)) {
    const ContainerClass = groupType === StepFieldType.RENDER_OBJECT ? RenderElementContainer : CustomInputContainer;
    const isArray = Array.isArray(props.selectedValue);
    const allowRender =
      // Any custom group that is not a RENDER_OBJECT may render
      groupType !== StepFieldType.RENDER_OBJECT ||
      // If the value is an array, only render if the array is filled
      (isArray && props.selectedValue.length > 0) ||
      // If the value is not an array, only render if the value is not undefined/null
      (!isArray && !isNil(props.selectedValue));

    const content = allowRender ? <ContainerClass>{renderInput()}</ContainerClass> : null;

    return (
      <StepFieldContainer
        className={'isRenderObject'}
        isRenderElementComponent={true}
        containerStyles={containerStyles}
        hasSeparator={hasSeparator}
        isCustomComponent
      >
        <StepFieldContainerInner
          isCustomComponent
          isStacked={isStacked}
          type={groupType}
          // TODO: Do not spread all the props as its causing duplicate onBlur and onFocus events (See: ED-6620)
          {...props}
          containerInnerStyles={containerInnerStyles}
        >
          <Clickable data-testid={testId} onMouseDown={e => e.preventDefault()} disabled={props.disabled}>
            <CenteredFlexContainer>
              {lockButtonElement}
              <Label>
                {label}
                {stepFieldHyperlink}
              </Label>
            </CenteredFlexContainer>
            <SecondaryLabel>{subLabel}</SecondaryLabel>
            {!props.disabled && <ChevronRightIconContainer css="top: 14px; right: 20px;" />}
            {content}
          </Clickable>
        </StepFieldContainerInner>
      </StepFieldContainer>
    );
  }

  return groupType === StepFieldType.PLAIN_TEXT ? (
    <StepFieldContainer containerStyles={containerStyles}>
      <StepFieldContainerInner isStacked={false}>
        <BuilderLabel css={'padding: 2px 0 2px 0'}>{label}</BuilderLabel>
      </StepFieldContainerInner>
    </StepFieldContainer>
  ) : (
    <StepFieldContainer containerStyles={containerStyles} hasSeparator={hasSeparator}>
      <StepFieldContainerInner
        // TODO: Do not spread all the props as its causing duplicate onBlur and onFocus events (See: ED-6620)
        {...props}
        type={groupType}
        containerInnerStyles={containerInnerStyles}
        onChange={undefined}
        isStacked={isStacked}
      >
        <CenteredFlexContainer>
          {lockButtonElement}
          <BuilderLabel>
            {label} {suffixIconElement}
            {stepFieldHyperlink}
          </BuilderLabel>
          {props.canDelete ? (
            <DeleteButtonContainer>
              <RemovalIcon
                shouldHide={false}
                onRemove={onConfirmDeleteStepField}
                testId={`${ElementTestId.ICON_REMOVE}-${testId}`}
              ></RemovalIcon>
            </DeleteButtonContainer>
          ) : null}
          {tooltipContents && (
            <TooltipButton
              styles={css`
                margin-left: 5px;
                margin-top: 1px;
              `}
              tooltip={{
                arrowPosition: { primary: PrimaryArrowPosition.LEFT, secondary: SecondaryArrowPosition.CENTER },
                margin: { x: 12, y: 0 },
              }}
            >
              {tooltipContents}
            </TooltipButton>
          )}
        </CenteredFlexContainer>
        <BuilderSecondaryLabel>{subLabel}</BuilderSecondaryLabel>
        {renderInput()}
      </StepFieldContainerInner>
    </StepFieldContainer>
  );
};

export default StepFieldInput;
