import { InventoryItem } from 'enums/columns/inventoryItem';

export const RETAIL_ITEM_FLAGS = [
  // [InventoryItem.MAPPED, false],  // TODO: Add in when modifiable
  [InventoryItem.SHOW_WEB, true],
  [InventoryItem.FEATURED, false],
  [InventoryItem.AS_IS, false],
  [InventoryItem.CERTIFIED, false],
  [InventoryItem.DEMO, false],
  [InventoryItem.ON_ORDER, false],
  [InventoryItem.IN_TRANSIT, false],
  // [InventoryItem.COMPLETE, false],  // TODO: Add in when modifiable
] as const;
