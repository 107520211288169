import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  makeFields,
  modelFields,
  subModelFields,
  trimFields,
  trimFiltersFields,
} from 'components/sections/createModify/MMST/MMSTFields';
import MMSTStep from 'components/sections/createModify/MMST/steps/MMSTStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { makeCreate, modelCreate, subModelCreate, trimCreate } from './MMSTCreateModifyQueries';
import TrimFiltersStep from './steps/TrimFiltersStep';

// MMST builders are gated at the substep level
const MMSTBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.MAKE_CREATE]: {
    steps: [{ id: 'MAKE_CREATE', step: MMSTStep, stepName: 'make_one', fields: makeFields }],
    legend: null,
    mutations: {
      create: makeCreate,
    },
    requiredPermissions: [{ resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS,
  },
  [BuilderType.MODEL_CREATE]: {
    steps: [{ id: 'MODEL_CREATE', step: MMSTStep, stepName: 'model_one', fields: modelFields }],
    legend: null,
    mutations: {
      create: modelCreate,
    },
    requiredPermissions: [{ resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS,
  },
  [BuilderType.SUBMODEL_CREATE]: {
    steps: [{ id: 'SUBMODEL_CREATE', step: MMSTStep, stepName: 'submodel_one', fields: subModelFields }],
    legend: null,
    mutations: {
      create: subModelCreate,
    },
    requiredPermissions: [{ resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS,
  },
  [BuilderType.TRIM_CREATE]: {
    steps: [{ id: 'TRIM_CREATE', step: MMSTStep, stepName: 'trim', fields: trimFields }],
    legend: null,
    mutations: {
      create: trimCreate,
    },
    requiredPermissions: [{ resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS,
  },
  [BuilderType.TRIM_PHOTOS_FILTER]: {
    steps: [{ id: 'TRIM_PHOTOS_FILTER', step: TrimFiltersStep, stepName: 'trim', fields: trimFiltersFields }],
    legend: null,
    requiredPermissions: [{ resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS,
  },
};

export default MMSTBuilders;
