import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { createPortal } from 'react-dom';

interface PortalProps {
  rootId: string;
  children: ReactNode;
}

/**
 * A helper HOC that handles creating a portal, and mounting the given children into it.
 * If the given `rootId` does not exist, it will be created. (This should only happen with storybook)
 */
const Portal = ({ rootId, children }: PortalProps) => {
  const root = useMemo(() => {
    const base = document.querySelector(`#${rootId}`);
    if (base === null) {
      const elem = document.createElement('div');
      elem.id = rootId;
      elem.setAttribute('style', 'z-index: 999');
      document.body.appendChild(elem);
      return elem;
    }
    return base;
  }, [rootId]);

  return createPortal(children, root);
};

export default Portal;
