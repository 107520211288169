import { gql } from '@apollo/client';

import { websiteDetail } from 'store/api/graph/fragments/websiteDetail';

export const websiteCreate = gql`
  mutation WebsiteCreateMutation(
    $groupId: ID
    $primaryRooftopId: ID
    $location: LocationInput
    $name: MultilingualStringInput
    $phoneNumber: String
    $status: WebsiteStatus
    $url: String
    $ga4PropertyId: ID
    $googleAdsId: ID
    $defaultInitialItemSorting: [SortInput!]
    $locales: [String!]
    $primaryLocale: String
    $_submit: Boolean! = true
  ) {
    data: websiteCreate(
      groupId: $groupId
      primaryRooftopId: $primaryRooftopId
      location: $location
      name: $name
      phoneNumber: $phoneNumber
      status: $status
      url: $url
      ga4PropertyId: $ga4PropertyId
      googleAdsId: $googleAdsId
      defaultInitialItemSorting: $defaultInitialItemSorting
      locales: $locales
      primaryLocale: $primaryLocale
      _submit: $_submit
    ) {
      ...WebsiteDetailFragment
    }
  }
  ${websiteDetail}
`;

export const websiteModify = gql`
  mutation WebsiteModifyMutation(
    $id: ID!
    $primaryRooftopId: ID
    $location: LocationInput
    $name: MultilingualStringInput
    $phoneNumber: String
    $status: WebsiteStatus
    $url: String
    $ga4PropertyId: ID
    $googleAdsId: ID
    $defaultInitialItemSorting: [SortInput!]
    $locales: [String!]
    $primaryLocale: String
    $_clear: [WebsiteModifyParameter!]
  ) {
    data: websiteModify(
      id: $id
      primaryRooftopId: $primaryRooftopId
      location: $location
      name: $name
      phoneNumber: $phoneNumber
      status: $status
      url: $url
      ga4PropertyId: $ga4PropertyId
      googleAdsId: $googleAdsId
      defaultInitialItemSorting: $defaultInitialItemSorting
      locales: $locales
      primaryLocale: $primaryLocale
      _clear: $_clear
    ) {
      ...WebsiteDetailFragment
    }
  }
  ${websiteDetail}
`;
