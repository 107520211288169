// Width
/** width: 16px */
export const WIDTH_400 = '16px';
/** width: 20px */
export const WIDTH_500 = '20px';
/** width: 24px */
export const WIDTH_600 = '24px';
/** width: 28px */
export const WIDTH_650 = '28px';
/** width: 32px */
export const WIDTH_700 = '32px';
/** width: 36px */
export const WIDTH_750 = '36px';
/** width: 40px */
export const WIDTH_800 = '40px';

// Height
/** height: 16px */
export const HEIGHT_400 = '16px';
/** height: 20px */
export const HEIGHT_500 = '20px';
/** height: 24px */
export const HEIGHT_600 = '24px';
/** height: 28px */
export const HEIGHT_650 = '28px';
/** height: 32px */
export const HEIGHT_700 = '32px';
/** height: 36px */
export const HEIGHT_750 = '36px';
/** height: 40px */
export const HEIGHT_800 = '40px';
