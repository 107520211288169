import type { ComponentProps } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { getSupportedEditorLanguages, MultilingualEditorLocaleSettings } from 'components/ui/editor/helpers/utils';
import MultilingualEditor from 'components/ui/editor/MultilingualEditor';
import { Clickable } from 'components/ui/shared/Button';
import { useUser } from 'hooks/contexts/useUser';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { SPACE_12 } from 'styles/spacing';
import { BLUE_500, SPACE_200, SPACE_300 } from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import type { MultilingualStringValue } from 'utils/intlUtils';

import type { Props as InputTextProps } from './InputText';
import InputText, { InputContainer } from './InputText';

const MultilingualInputContainer = styled(InputContainer)`
  > ${InputContainer} {
    padding: 0;

    > input {
      padding-right: 65px;
    }
  }
`;

const LanguageToggleContainer = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${Z_INDEX_1};
`;

const LanguageToggle = styled(Clickable)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${SPACE_200} ${SPACE_300};

  ${Label} {
    color: ${BLUE_500};
    text-decoration: underline;
  }
`;

const MultilingualEditorWrapper = styled.div<{ $height?: string }>`
  margin-top: ${SPACE_12};

  ${props =>
    props.$height &&
    css`
      height: ${props.$height};
    `}
`;

export enum TextAreaSize {
  LARGE = '525px',
  MEDIUM = '285px',
  SMALL = '121px',
}

type MultilingualEditorProps = ComponentProps<typeof MultilingualEditor>;

export interface MultilingualToggleInputSettings extends Pick<MultilingualEditorProps, 'insertOptions'> {
  /** Size of the inputs text area. If not set input will render as a single line. */
  textAreaSize?: TextAreaSize;
}

interface Props extends Omit<InputTextProps, 'onChange' | 'type' | 'defaultValue'> {
  /** Settings for the input */
  settings?: MultilingualToggleInputSettings;
  /** The onChange callback for the input */
  onChange?: (event: { currentTarget: { value: MultilingualString } }) => void;
  /** The onLocaleChange callback for the input */
  onLocaleChange?: (value: MultilingualStringValue) => void;
  /** The value of this Multilingual input */
  value: MultilingualString | undefined;
}

const MultilingualToggleInput = ({ settings, onChange, onLocaleChange, value, ...props }: Props) => {
  const { user } = useUser();
  const onChangeRef = useRef(onChange);
  const onLocaleChangeRef = useRef(onLocaleChange);

  const isTextArea = !!settings?.textAreaSize;
  const [currentLocale, setCurrentLocale] = useState<MultilingualStringValue>(
    MultilingualEditorLocaleSettings.find(({ locale }) => locale === user.locale?.languageTag)?.language ||
      MultilingualEditorLocaleSettings[0]?.language
  );
  const availableLocaleOptions = useMemo(() => getSupportedEditorLanguages(), []);
  const currentValueObject = useMemo<MultilingualString>(() => (value ? { ...value } : { value: '' }), [value]);
  const currentValue = useMemo<string | null>(
    () => currentValueObject[currentLocale] || null,
    [currentValueObject, currentLocale]
  );

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const onToggle = useCallback(() => {
    const targetLocaleIndex = (availableLocaleOptions.indexOf(currentLocale) + 1) % availableLocaleOptions.length;
    const selectedLocale = availableLocaleOptions[targetLocaleIndex];

    setCurrentLocale(selectedLocale);
    inputRef.current!.value = currentValueObject[availableLocaleOptions[targetLocaleIndex]] || '';
    currentValueObject.value = inputRef.current!.value;
    // When the locale changes, call the onChange callback as the current text has changed
    onChange?.({ currentTarget: { value: currentValueObject } });
    // Call onLocaleChange after onChange so stepField has updated value
    onLocaleChange?.(selectedLocale);
  }, [availableLocaleOptions, currentLocale, currentValueObject, onChange, onLocaleChange]);

  const handleChange = useCallback(
    (value: string) => {
      currentValueObject[currentLocale] = value || null;
      currentValueObject.value = currentValueObject[currentLocale] || '';

      onChange?.({ currentTarget: { value: currentValueObject } });
    },
    [currentLocale, currentValueObject, onChange]
  );

  const onChangeCallback = useCallback(
    event => {
      handleChange(event.currentTarget.value);
    },
    [handleChange]
  );

  /**
   * Handles editor `onChange` event, return changed values when editor updates
   */
  const handleOnEditorUpdate = useCallback<NonNullable<MultilingualEditorProps['onEditorUpdate']>>(
    value => value && onChangeRef.current?.({ currentTarget: { value } }),
    []
  );

  /**
   * Handles editor `onLocaleChange` event, return changed values when editor updates
   */
  const handleEditorLanguageChange = useCallback<NonNullable<MultilingualEditorProps['onLanguageChange']>>(
    language => onLocaleChangeRef.current?.(language),
    []
  );

  if (isTextArea) {
    const { active, invalid, ...restProps } = props;
    return (
      <MultilingualEditorWrapper $height={settings?.textAreaSize}>
        <MultilingualEditor
          availableLanguages={availableLocaleOptions}
          defaultContent={value || null}
          defaultLanguage={currentLocale}
          insertOptions={settings?.insertOptions}
          isActive={active}
          isInvalid={invalid}
          onEditorUpdate={handleOnEditorUpdate}
          onLanguageChange={handleEditorLanguageChange}
          testId={restProps['data-testid']}
          {...restProps}
        />
      </MultilingualEditorWrapper>
    );
  }

  return (
    <MultilingualInputContainer>
      <LanguageToggleContainer>
        <LanguageToggle onClick={onToggle}>
          <Label>{MultilingualEditorLocaleSettings.find(setting => setting.language === currentLocale)?.label}</Label>
        </LanguageToggle>
      </LanguageToggleContainer>
      <InputText ref={inputRef} onChange={onChangeCallback} value={currentValue || ''} {...props} />
    </MultilingualInputContainer>
  );
};

export default MultilingualToggleInput;
