import { useEffect, useState } from 'react';

import { ImageLoadingState } from 'components/ui/images/helpers/Images';

/**
 * This useImageLoader hook will, given an image url, load the image and return the loading state
 * @param imageUrl - the url for this image
 * @param initialLoadingFlag - the initial loading flag value (by default initial loading state is false)
 */
const useImageLoader = (imageUrl: string | undefined, initialLoadingFlag = false) => {
  const [isLoading, setIsLoading] = useState(initialLoadingFlag);

  useEffect(() => {
    if (imageUrl) {
      const isPerpetualLoad = imageUrl === ImageLoadingState.LOADING;
      const onLoadComplete = () => setIsLoading(false);
      const onError = () => setIsLoading(false);

      const image = document.createElement('img');
      if (!isPerpetualLoad) {
        image.addEventListener('load', onLoadComplete);
        image.addEventListener('error', onError);
        image.src = imageUrl;
      }
      setIsLoading(isPerpetualLoad ? true : !image.complete);

      return () => {
        setIsLoading(false);
        image.removeEventListener('load', onLoadComplete);
        image.removeEventListener('error', onError);
        image.remove();
      };
    }
  }, [imageUrl, setIsLoading]);

  return isLoading;
};

export default useImageLoader;
