/**
 *   For reference when accessing api data to render.
 *   e.g. retailItem.demo is a BOOLEAN,
 *        retailItem.createdBy is a USER_NAME
 *        retailItem.status is an ENUM
 */
export enum FieldDataType {
  /** Requires object of format `{name:string|MultilingualString}` e.g. WhiteLabel, Group */
  NAME = 'NAME',
  /** Requires object of format `{displayName:string}` e.g. User, Appraisal */
  DISPLAY_NAME = 'DISPLAY_NAME',
  /** Requires object of format `{firstName:string, lastName:string}` */
  USER_NAME = 'USER_NAME',
  /** Requires object of format `{group: {name: string}}` */
  GROUP_NAME = 'GROUP_NAME',
  /**
   * Requires format: .formattedAmountRounded. Green Dollar value
   * If the object is a number, it will be formatted using CAD currency.
   */
  MONETARY = 'MONETARY',
  /** Parses date to `LLL dd, yyy` (e.g. Sep 21, 2019) */
  DATE = 'DATE',
  /** Parses date to `EEE, LLL dd, yyy` (e.g. Mon, Sep 21, 2019) */
  DAY_MONTH_YEAR = 'DAY_MONTH_YEAR',
  /** Parses date to `LLLL dd, h:mm a` (e.g. September 21, 1:00 PM) */
  DAY_TIME = 'DAY_TIME',
  /** Parses date to `h:mm a` (e.g. 1:00 PM) */
  TIME = 'TIME',
  /** (e.g. DRIVE_TRAIN -> Drive Train) */
  ENUM = 'ENUM',
  /** Requires true/false values, returns check or X icons */
  BOOLEAN = 'BOOLEAN',
  /** Requires array values */
  BADGE_LIST = 'BADGE_LIST',
  /** Requires format accessor: .formattedAmount */
  FORMATTED_AMOUNT = 'FORMATTED_AMOUNT',
  /** Requires a value from 0 to 1 */
  PERCENTAGE = 'PERCENTAGE',
  /** Displays color palette and color in text beside it */
  COLOR_DESCRIBED = 'COLOR_DESCRIBED',
  /** Displays just the color palette */
  COLOR_PREVIEW = 'COLOR_PREVIEW',
  /** Is ignored, these are handled by the implementing class */
  CUSTOM = 'CUSTOM',
  /** For formatting specialized multilingual strings */
  MULTILINGUAL_STRING = 'MULTILINGUAL_STRING',
  /** Returns a simple text field */
  DEFAULT = 'DEFAULT',
  /** For rendering a status badge with specific metadata labels & StatusColors enum */
  STATUS = 'STATUS',
  /** For rendering fields that reference Year, Make, Model, Trim */
  YMMT = 'YMMT',
  /** Requires object of format `Location` */
  LOCATION = 'LOCATION',
  /** Requires an image url */
  IMAGE = 'IMAGE',
  /** For rendering a Duration type value (amount of time + unit of time) */
  DURATION = 'DURATION',
}
