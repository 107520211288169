import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import { StepFieldType } from 'enums/stepFieldType';
import {
  InventoryExportRooftopIntegrationAttributesInputParameter,
  RooftopIntegrationModifyParameter,
} from 'store/api/graph/interfaces/types';

const DetailFields: StepFields = {
  [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopIntegration.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
    label: 'type',
    placeholder: 'type',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
    label: 'integration_one',
    placeholder: 'integration_one',
    queryAlias: RooftopIntegration.INTEGRATION_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.INVENTORY_EXPORT_THIRD_PARTY_ID]: {
    label: 'third_party_id',
    queryAlias: RooftopIntegration.INVENTORY_EXPORT_THIRD_PARTY_ID,
    placeholder: 'third_party_id',
    hasSeparator: true,
  },
  [RooftopIntegrationDetailsFields.INVENTORY_EXPORT_WEBSITE_BRAND]: {
    label: 'website_brand',
    queryAlias: RooftopIntegration.INVENTORY_EXPORT_WEBSITE_BRAND,
    placeholder: 'website_brand',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.rooftopIntegration.inventoryExportAttributes.websiteBrand',
    hasSeparator: true,
    tooltipContents: 'inventory_export_website_brand_tooltip',
    clear: {
      field: InventoryExportRooftopIntegrationAttributesInputParameter._websiteBrand,
      target: RooftopIntegrationAttributesPointer.INVENTORY_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.ACTIVE]: {
    label: 'active',
    queryAlias: RooftopIntegration.ACTIVE,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._active },
  },
  [RooftopIntegrationDetailsFields.PRIMARY]: {
    label: 'primary',
    queryAlias: RooftopIntegration.PRIMARY,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._primary },
  },
  [RooftopIntegrationDetailsFields.RUN_ONCE]: {
    label: 'run_once',
    queryAlias: RooftopIntegration.RUN_ONCE,
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'run_once_tooltip',
    hasSeparator: true,
    clear: { field: RooftopIntegrationModifyParameter._runOnce },
  },
  [RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL]: {
    label: 'privacy_policy_url',
    placeholder: 'privacy_policy_url',
    queryAlias: RooftopIntegration.PRIVACY_POLICY_URL,
    clear: { field: RooftopIntegrationModifyParameter._privacyPolicyUrl },
  },
};

export default DetailFields;
