import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, useParams } from 'react-router-dom';

import ErrorBoundary from 'components/core/errors/ErrorBoundary';
import LoggingService from 'components/core/logging/LoggingService';
import EnvironmentModeWarning from 'components/ui/shared/EnvironmentModeWarning';
import LoggedInAsBanner from 'components/ui/shared/LoggedInAsBanner';
import { usePathPattern } from 'hooks/usePathPattern';
import EDealerProvider from 'providers/EDealerProvider';
import { client } from 'store/apollo/ApolloClient';

import RouterRoot from './RouterRoot';

const CoreRoute = () => {
  const pathPattern = usePathPattern();
  const params = useParams();

  /**
   * Adding params as path dependency so that we log the path pattern (e.g. /retail/:id)
   * whenever the parameter gets update, which signifies a route change
   */
  useEffect(() => {
    LoggingService.trackPageView(pathPattern);
  }, [params, pathPattern]);

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <EDealerProvider>
          <DndProvider backend={HTML5Backend}>
            <LoggedInAsBanner />
            <RouterRoot>
              <Outlet />
            </RouterRoot>
            <EnvironmentModeWarning />
          </DndProvider>
        </EDealerProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default CoreRoute;
