import { gql } from '@apollo/client';

import { buildAndPrice } from 'store/api/graph/fragments/buildAndPrice';
import { showroom } from 'store/api/graph/fragments/showroom';

export const shoppingToolsConfiguration = gql`
  fragment ShoppingToolsConfigurationFragment on ShoppingToolsConfiguration {
    includeBulkAdjustments
    includeIncentives
    includeCommercialTrims
    showFinancePayments
    showLeasePayments
    makes {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    yearModels {
      models {
        id
        makeId
        name {
          ...MultilingualStringFragment
        }
        source
        sourceName
      }
      year
    }

    showroomConfiguration {
      ...ShowroomFragment
    }

    buildAndPriceConfiguration {
      ...BuildAndPriceFragment
    }
  }

  ${showroom}
  ${buildAndPrice}
`;
