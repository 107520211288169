import { gql } from '@apollo/client';

import { websiteRoute } from 'store/api/graph/fragments/websiteRoute';

export const websiteRouteCreate = gql`
  mutation WebsiteRouteCreateMutation(
    $websiteId: ID
    $filters: [RetailItemConnectionFilterInput!]
    $path: MultilingualStringInput
    $initialItemSorting: [SortInput!]
    $_submit: Boolean! = true
  ) {
    data: websiteRouteCreate(
      websiteId: $websiteId
      filters: $filters
      path: $path
      initialItemSorting: $initialItemSorting
      _submit: $_submit
    ) {
      ...WebsiteRouteFragment
    }
  }
  ${websiteRoute}
`;

export const websiteRouteModify = gql`
  mutation WebsiteRouteModifyMutation(
    $id: ID!
    $filters: [RetailItemConnectionFilterInput!]
    $path: MultilingualStringInput
    $initialItemSorting: [SortInput!]
    $_clear: [WebsiteRouteModifyParameter!]
  ) {
    data: websiteRouteModify(
      id: $id
      filters: $filters
      path: $path
      initialItemSorting: $initialItemSorting
      _clear: $_clear
    ) {
      ...WebsiteRouteFragment
    }
  }
  ${websiteRoute}
`;

export const websiteRouteDelete = gql`
  mutation WebsiteRouteDeleteMutation($id: ID!) {
    data: websiteRouteDelete(id: $id)
  }
`;
