export enum TradeCaptureBuilderFields {
  ROOFTOP = 'rooftop',
  CAPTURE_METHOD = 'captureMethod',
  LEAD = 'lead',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
  COMMUNICATION_PREFERENCE = 'communicationPreference',
}

export enum TrimSelectionFields {
  DECODED_TRIM_ID = 'decodedTrimId',
  VIN_REFRESHING = 'vinRefreshing',
}

export enum CaptureMethods {
  CAPTURE_MANUALLY = 'captureManually',
  SEND_TO_LEAD = 'sendToLead',
  SEND_TO_PHONE = 'sendToPhone',
  SEND_TO_EMAIL = 'sendToEmail',
}

export enum CommunicationPreferenceOptions {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  PHONE_AND_EMAIL = 'PHONE_AND_EMAIL',
}

export enum GuaranteedTradeBuilderFields {
  AMOUNT = 'amount',
  COMMENTS = 'comments',
}

export enum SubmitAppraisalBuilderFields {
  AMOUNT = 'amount',
  COMMENTS = 'comments',
}

export enum InviteAppraisalBuilderFields {
  CONTACT_ID = 'contactId',
}
