import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { Lead } from 'enums/columns/lead';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { LeadModifyParameter } from 'store/api/graph/interfaces/types';

import { LeadDetailsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [LeadDetailsBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: Lead.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.AVATAR_IMAGE]: {
    label: 'avatar_one',
    groupType: StepFieldType.PHOTO,
    settings: {
      isAvatar: true,
      canDelete: true,
    },
    hasSeparator: true,
    clear: { field: LeadModifyParameter._avatarImage },
  },
  [LeadDetailsBuilderFields.FIRST_NAME]: {
    label: 'first_name',
    placeholder: 'first_name',
    queryAlias: Lead.FIRST_NAME,
    clear: { field: LeadModifyParameter._firstName },
  },
  [LeadDetailsBuilderFields.LAST_NAME]: {
    label: 'last_name',
    queryAlias: Lead.LAST_NAME,
    placeholder: 'last_name',
    clear: { field: LeadModifyParameter._lastName },
  },
  [LeadDetailsBuilderFields.BIRTHDATE]: {
    label: 'date_of_birth',
    queryAlias: Lead.BIRTHDATE,
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
    settings: {
      listViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
  [LeadDetailsBuilderFields.EMAIL]: {
    label: 'email_one',
    queryAlias: Lead.EMAIL,
    placeholder: 'email_one',
    clear: { field: LeadModifyParameter._email },
  },
  [LeadDetailsBuilderFields.PHONE_NUMBER]: {
    label: 'phone',
    queryAlias: Lead.PHONE_NUMBER,
    groupType: StepFieldType.PHONE_NUMBER,
    placeholder: 'phone',
    clear: { field: LeadModifyParameter._phoneNumber },
  },
  [LeadDetailsBuilderFields.CONTACT_METHOD]: {
    label: 'preferred_contact_method',
    queryAlias: Lead.CONTACT_METHOD_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.contactMethod',
    clear: { field: LeadModifyParameter._contactMethod },
  },
  [LeadDetailsBuilderFields.CONTACT_TIME]: {
    label: 'preferred_contact_time',
    queryAlias: Lead.CONTACT_TIME_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.contactTime',
    hasSeparator: true,
    clear: { field: LeadModifyParameter._contactTime },
  },
  [LeadDetailsBuilderFields.SUBSCRIBED_EMAIL]: {
    label: 'email_subscription',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.SUBSCRIBED_PROMOTIONS]: {
    label: 'promotion_subscription',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.LOCALE]: {
    label: 'locale',
    options: 'mutation.rooftop.locale',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    clear: { field: LeadModifyParameter._locale },
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.STATUS]: {
    label: 'status',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.status',
    hasSeparator: true,
    clear: { field: LeadModifyParameter._status },
  },
  [LeadDetailsBuilderFields.PAYMENT_OPTIONS]: {
    label: 'payment_option_other',
    groupType: StepFieldType.TAGS,
    queryAlias: Lead.PAYMENT_OPTIONS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.lead.paymentOptions',
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.NOTE]: {
    label: 'note',
    queryAlias: Lead.NOTE,
    placeholder: 'note',
    groupType: StepFieldType.TEXT_AREA,
    clear: { field: LeadModifyParameter._note },
    tooltipContents: 'for_internal_dealer_use_only',
    containerInnerStyles: css`
      textarea {
        height: 162px;
      }
    `,
    hasSeparator: true,
  },
  [LeadDetailsBuilderFields.TAG_IDS]: {
    label: 'tags',
    queryAlias: Lead.TAG_NAME,
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
  },
};

export default DetailFields;
