import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Appointment, AppointmentSettings } from 'enums/columns/appointment';
import { FieldDataType } from 'enums/fieldDataType';
import { ItemsColumn } from 'enums/itemsColumn';
import { BODY_TEXT } from 'styles/color';
import { FONT_WEIGHT_BOLD } from 'styles/typography';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { PhotoHeaders, SelectHeaders } from './staticTableConfig';

// TODO: [#1123] determine default sort order
export const appointmentsDefaultSortOrder = [];

export const APPOINTMENTS_BASE = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),
  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: FieldDataType.CUSTOM,
  }),

  getCellDataByItemColumnType(Appointment.DATE_TO, AppointmentSettings, { width: 200, enabled: false }),
  getCellDataByItemColumnType(Appointment.DATE_FROM, AppointmentSettings, { width: 200, enabled: false }),
  getCellDataByItemColumnType(Appointment.START_TIME, AppointmentSettings, { width: 200, enabled: false }),
  getCellDataByItemColumnType(Appointment.END_TIME, AppointmentSettings, { width: 200, enabled: false }),
  getCellDataByItemColumnType(Appointment.GROUP_NAME, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.INVENTORY_ITEM_NAME, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.LEAD_NAME, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.CREATED_BY_NAME, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.ARCHIVED, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.CREATED, AppointmentSettings, { enabled: false }),
  getCellDataByItemColumnType(Appointment.UPDATED, AppointmentSettings, { enabled: false }),
]);

const styles = {
  [Appointment.TITLE]: `
    > div { 
      color: ${BODY_TEXT}; 
      font-weight: ${FONT_WEIGHT_BOLD}; 
    }
  `,
};

export const APPOINTMENTS = Object.freeze<TableCellData[]>([
  // Defaults
  ...APPOINTMENTS_BASE,

  getCellDataByItemColumnType(Appointment.DATE, AppointmentSettings, {
    width: '10%',
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(Appointment.TITLE, AppointmentSettings, {
    width: '30%',
    styles: styles[Appointment.TITLE],
  }),
  getCellDataByItemColumnType(Appointment.NOTE, AppointmentSettings, { width: '30%' }),
  getCellDataByItemColumnType(Appointment.TAG_NAME, AppointmentSettings, { width: '10%' }),
  getCellDataByItemColumnType(Appointment.ROOFTOP_NAME, AppointmentSettings, { width: '10%' }),
  getCellDataByItemColumnType(Appointment.ASSIGNED_TO_NAME, AppointmentSettings, { width: '10%' }),
]);

export const APPOINTMENTS_LIMITED = Object.freeze<TableCellData[]>([
  // Defaults
  ...APPOINTMENTS_BASE,

  getCellDataByItemColumnType(Appointment.DATE, AppointmentSettings, {
    width: '10%',
    cellType: FieldDataType.CUSTOM,
  }),
  getCellDataByItemColumnType(Appointment.TITLE, AppointmentSettings, {
    width: '40%',
    styles: styles[Appointment.TITLE],
  }),
  getCellDataByItemColumnType(Appointment.NOTE, AppointmentSettings, { width: '40%' }),
  getCellDataByItemColumnType(Appointment.TAG_NAME, AppointmentSettings, { width: '10%' }),
  getCellDataByItemColumnType(Appointment.ASSIGNED_TO_NAME, AppointmentSettings, { enabled: false }),
]);
