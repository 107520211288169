import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { User, UserSettings } from 'enums/columns/user';
import { ImageType } from 'enums/imageType';
import { ItemsColumn } from 'enums/itemsColumn';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { PhotoHeaders, SelectHeaders } from './staticTableConfig';

// TODO: [#1123] determine default sort order
export const usersDefaultSortOrder = [];

export const USERS = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),

  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: ImageType.USER,
  }),

  // Minimum required fields for table/listItem
  getCellDataByItemColumnType(User.FIRST_NAME, UserSettings),
  getCellDataByItemColumnType(User.LAST_NAME, UserSettings),
  getCellDataByItemColumnType(User.ROOFTOP_NAME, UserSettings),

  // Directive derived columns
  getCellDataByItemColumnType(User.PHONE_NUMBER, UserSettings, { enabled: true }),
  getCellDataByItemColumnType(User.CELL_NUMBER, UserSettings, { enabled: true }),
  getCellDataByItemColumnType(User.EMAIL, UserSettings, { enabled: true, width: 220 }),
  getCellDataByItemColumnType(User.WHITE_LABEL_NAME, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.GROUP_NAME, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.ROLE_NAME, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.LOCALE, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.ACTIVE, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.SUBSCRIBED_EMAIL, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.LAST_ACTIVE, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.CREATED, UserSettings, { enabled: false }),
  getCellDataByItemColumnType(User.UPDATED, UserSettings, { enabled: false }),
]);
