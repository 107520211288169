import { memo, type SVGProps } from 'react';

const FormatSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12.104 5.625H8.958c-.318 0-.589-.112-.812-.335a1.107 1.107 0 0 1-.334-.812c0-.319.112-.59.334-.811.223-.223.494-.334.812-.334h8.562c.319 0 .59.112.812.335.223.223.334.494.334.812 0 .319-.111.59-.334.812a1.106 1.106 0 0 1-.812.333h-3.145v9.917c0 .312-.11.578-.329.796a1.085 1.085 0 0 1-.796.329c-.319 0-.59-.112-.812-.335a1.105 1.105 0 0 1-.334-.811V5.625ZM4 10.083H2.479c-.318 0-.589-.113-.812-.339a1.128 1.128 0 0 1-.334-.823c0-.322.111-.595.334-.817.223-.222.494-.333.812-.333H7.79c.319 0 .59.111.812.334.223.223.334.494.334.813 0 .318-.111.592-.334.822a1.09 1.09 0 0 1-.812.343H6.27v5.459c0 .312-.11.578-.329.796a1.085 1.085 0 0 1-.796.329c-.319 0-.59-.112-.812-.335A1.105 1.105 0 0 1 4 15.521v-5.438Z"
    />
  </svg>
);

export default memo(FormatSizeIcon);
