import { isNil } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { MMSTFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { getDependentVariablesForMMST, YMMTFields } from 'components/sections/createModify/inventoryItems/steps/utils';
import type TradeInDetailsStep from 'components/sections/createModify/inventoryItems/tradeInItem/steps/DetailsStep';
import type { YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import { getYMMTOptions } from 'components/sections/shared/ItemMetaHelpers';
import { getStepField, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';

export class TradeInItemMMSTFieldsImplementer implements MMSTFields {
  step: TradeInDetailsStep;

  constructor(step: TradeInDetailsStep) {
    this.step = step;
  }

  isMMSTField(stepField: string): boolean {
    return YMMTFields.includes(stepField as DetailsInventoryItemBuilderFields);
  }

  onMMSTFieldSelection(stepField: StepField, value: any) {
    const field = getStepField(stepField.queryVar, this.step.fields);

    if (stepField.queryVar === DetailsInventoryItemBuilderFields.YEAR) {
      if (!field.selectedValue?.id) {
        this.clearMMSTFields([field.queryVar]);
      }

      if (getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.step.fields).selectedValue?.id !== null) {
        this.clearMMSTFields([DetailsInventoryItemBuilderFields.TRIM_ID], {
          /*
           * If there is no sub-model selected, the trim field should remain disabled.
           * Otherwise, it can be cleared, but remain enabled
           */
          overrideDisabledState: !getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.step.fields)
            .selectedValue,
        });
      }
    } else {
      /*
       * If this YMMT field is deselected then hide/disable all the proceeding YMMT fields, although if this YMMT
       * field has a defined value, then the next YMMT field should be enabled.
       */
      this.clearMMSTFields(
        YMMTFields.slice(
          YMMTFields.indexOf(field.queryVar as DetailsInventoryItemBuilderFields) + (field.selectedValue ? 1 : 0)
        )
      );
    }
  }

  toggleMMSTSubPanel(stepField: StepField) {
    const {
      tier: {
        data: { type, rooftop },
      },
    } = this.step.props;

    const dependentFields = getDependentVariablesForMMST(stepField.queryVar as DetailsInventoryItemBuilderFields);
    // The MAKE_ID field expects the vehicle type (motorcycle/car), which is determined from the previous step
    const variables =
      stepField.queryVar === DetailsInventoryItemBuilderFields.MAKE_ID
        ? { type, rooftopId: [rooftop.id] }
        : { rooftopId: [rooftop.id] };

    for (const fieldName of dependentFields) {
      const field = getStepField(fieldName, this.step.fields);

      // The MAKE_ID, MODEL_ID, and SUB_MODEL_ID can be arrays, so add an 's' to the end of the variable name
      const variableName = [
        DetailsInventoryItemBuilderFields.MAKE_ID,
        DetailsInventoryItemBuilderFields.MODEL_ID,
        DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
      ].includes(fieldName)
        ? `${fieldName}s`
        : fieldName;

      // The YEAR field needs to be converted to a number, cannot send empty string if there is no year either
      if (variableName === DetailsInventoryItemBuilderFields.YEAR) {
        variables[variableName] = field.selectedValue
          ? Number.parseInt(field.selectedValue.id || field.selectedValue)
          : undefined;
      } else if (variableName === DetailsInventoryItemBuilderFields.ROOFTOP_ID) {
        variables[variableName] = [field.selectedValue?.id];
      } else {
        variables[variableName] =
          (field.selectedValue && (field.selectedValue.id || field.selectedValue.name || field.selectedValue)) || '';
      }
    }

    this.step.asyncConfigurations![stepField.queryVar] = {
      request: () => getYMMTOptions(variables, stepField.queryVar as YMMTTarget),
      disableKeywordRefetch: true,
    };
  }

  clearMMSTFields(fields: string[], options?: { overrideDisabledState?: boolean }) {
    const alwaysActiveFields = new Set([DetailsInventoryItemBuilderFields.YEAR]);

    for (const [indx, queryVar] of fields.entries()) {
      const field: StepField = getStepField(queryVar, this.step.fields);
      field.selectedValue = undefined;
      setDisplayTypes(
        [
          {
            type: StepFieldDisplayType.DISABLED,
            active: isNil(options?.overrideDisabledState)
              ? !alwaysActiveFields.has(queryVar as DetailsInventoryItemBuilderFields) && indx > 0
              : !!options?.overrideDisabledState,
          },
        ],
        field
      );
    }

    this.step.forceUpdate();
  }
}
