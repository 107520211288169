import type { ContextType, FormEvent } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import DynamicImport from 'components/core/DynamicImport';
import LoggingService from 'components/core/logging/LoggingService';
import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { FormControl, FormPasswordInput, FormSubmitButton } from 'components/ui/forms/shared/Form';
import { Input } from 'components/ui/forms/shared/InputText';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import AuthLayout from 'components/ui/layouts/AuthLayout';
import Spinner from 'components/ui/loading/Spinner';
import BaseClass from 'components/ui/shared/BaseClass';
import { UserContext } from 'contexts/UserContext';
import { RoutePath } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import type { RouterHooksHOCProps } from 'hooks/withRouter';
import { withRouter } from 'hooks/withRouter';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { SPACE_400 } from 'styles/tokens';
import { authManager } from 'utils/authUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';

import LoginWithGoogleAuth from './LoginWithGoogleAuth';

const DebugLogo = DynamicImport(() => import('components/ui/dialogs/LogoEnvironmentDialog'), false);

const AlternativeLoginOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${SPACE_400};
`;

interface Props {
  router: RouterHooksHOCProps;
}
interface State {
  username: string;
  password: string;
  isSubmitting: boolean;
  error?: ApiError;
}

/**
 * App login page.
 * Used by users to login to the main application.
 */
class Login extends BaseClass<Props, State> {
  static contextType = UserContext;
  context: ContextType<typeof UserContext>;

  state = { username: '', password: '', isSubmitting: false, error: undefined };

  onEmailChange = e => {
    this.setState({ username: e.target.value });
  };

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Reset error and apply loading state
    this.setState({ isSubmitting: true, error: undefined });

    authManager
      .tryUserLogin(this.state.username, this.state.password)
      .then(this.onFormSubmitSuccess)
      .catch(this.onFormSubmitError);
  };

  onFormSubmitSuccess = (response: Record<string, any>) => {
    const user = { ...response?.data.user, metadata: response?.data.metadata.mutation.user };
    this.context?.setUser(user);
    LoggingService.setUser(user);

    this.context?.setLastLogin(response?.lastLogin);

    if (authManager.routingTarget) {
      this.props.router.replace(authManager.routingTarget);
      authManager.routingTarget = undefined;
    } else {
      this.props.router.replace(RoutePath.HOME);
    }
  };

  onFormSubmitError = (error: Error) => {
    this.setState({ isSubmitting: false, error });
  };

  render() {
    const { username, password, isSubmitting, error } = this.state;
    const { t } = translate;
    const { featureFlags } = this.context || {};

    return (
      <AuthLayout
        logo={process.env.REACT_APP_FEATURE_ENV_DEBUGGING ? <DebugLogo /> : <EDealerIcon />}
        title={t('sign_in_to_edealer')}
        error={error}
      >
        <Form onSubmit={this.onFormSubmit}>
          <FormControl>
            <Label>{t('email_one')}</Label>
            <Input
              data-testid={ElementTestId.LOGIN_USERNAME_FIELD}
              type="text"
              placeholder={t('email_one')}
              value={username}
              onChange={this.onEmailChange}
              autoFocus
            />
          </FormControl>
          <FormPasswordInput value={password} onChange={this.onPasswordChange} />
          <FormSubmitButton type="submit" disabled={isSubmitting} data-testid="submit">
            {isSubmitting ? <Spinner /> : t('login')}
          </FormSubmitButton>

          {featureFlags?.[LDFeatureFlags.ssoEnabled] ? (
            <AlternativeLoginOptions>
              <LoginWithGoogleAuth onLoginSuccess={this.onFormSubmitSuccess} onLoginError={this.onFormSubmitError} />
              <Text> | </Text>
              <Link to={RoutePath.FORGOT_PASSWORD}>{t('forgot_password')}?</Link>
            </AlternativeLoginOptions>
          ) : (
            <FormControl>
              <Link to={RoutePath.FORGOT_PASSWORD}>{t('forgot_password')}?</Link>
            </FormControl>
          )}
        </Form>
      </AuthLayout>
    );
  }
}

export default withRouter(Login);
