import type { ComponentProps } from 'react';

import styled from 'styled-components/macro';

import Switch from './Switch';

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface SwitchInputProps extends Omit<ComponentProps<typeof Switch>, 'value'> {
  selectedValue?: ComponentProps<typeof Switch>['value'];
}

const SwitchInput = ({ selectedValue, onChange, ...props }: SwitchInputProps) => (
  <SwitchContainer>
    <Switch {...props} value={selectedValue} onChange={onChange} />
  </SwitchContainer>
);

export default SwitchInput;
