const ErrorBoundaryIcon = props => (
  <svg width="193" height="145" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          d="M1 20.5l.644-.104a6.894 6.894 0 0 0 5.659-5.43L7.5 14l.096.63a6.894 6.894 0 0 0 5.774 5.774l.63.096-.966.197a6.894 6.894 0 0 0-5.43 5.66L7.5 27l-.213-.993a6.894 6.894 0 0 0-5.294-5.294L1 20.5zM13 31l.495-.08a5.303 5.303 0 0 0 4.353-4.177L18 26l.074.485a5.303 5.303 0 0 0 4.441 4.44L23 31l-.743.152a5.303 5.303 0 0 0-4.177 4.353L18 36l-.164-.764a5.303 5.303 0 0 0-4.072-4.072L13 31z"
          fill="#D8D8D8"
        />
        <ellipse fill="#E4E4E4" cx="96.5" cy="141" rx="49.5" ry="4" />
        <path
          d="M165 55.659v8.294a1 1 0 0 0 1.467.884l7.857-4.147a1 1 0 0 0 0-1.769l-7.857-4.147a1 1 0 0 0-1.467.885z"
          fill="#D8D8D8"
        />
        <circle stroke="#E4E4E4" strokeWidth="2" cx="187.5" cy="74.5" r="4.5" />
        <g stroke="#E5E5E5" strokeLinecap="round" strokeWidth="2">
          <path d="M169.5 7.5v12M175.5 13.5h-12" />
        </g>
        <g stroke="#E5E5E5" strokeLinecap="round" strokeWidth="1.08">
          <path d="M182.129 1.129l4.242 4.242M186.371 1.129L182.13 5.37" />
        </g>
        <circle fill="#E4E4E4" cx="25" cy="66" r="3" />
        <path
          d="M6.447 84.224l5 2.5a1 1 0 0 1 .553.894v5.808a1 1 0 0 1-.496.863l-5 2.917a1 1 0 0 1-1.008 0l-5-2.917A1 1 0 0 1 0 93.426v-5.808a1 1 0 0 1 .553-.894l5-2.5a1 1 0 0 1 .894 0z"
          fill="#D8D8D8"
        />
      </g>
      <g transform="translate(50 16)">
        <rect stroke="#A7A9AC" strokeWidth="3" fill="#F8F8F8" x="26" y="84" width="41" height="8" rx="4" />
        <rect stroke="#A7A9AC" strokeWidth="3" fill="#F8F8F8" x="1" width="92" height="73" rx="5" />
        <circle fill="#A7A9AC" cx="47" cy="67" r="2.5" />
        <circle fill="#A7A9AC" cx="47" cy="4" r="1.5" />
        <g transform="translate(9 8)">
          <path
            stroke="#A7A9AC"
            strokeWidth="3"
            fill="#F8F8F8"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0 0h76v44H0z"
          />
          <path
            d="M30.256 15.007c1.136-1.705 2.84-4.262 5.114-7.67a3 3 0 0 1 5.01.026L56.372 31.91a3 3 0 0 1-2.514 4.638H21.5a3 3 0 0 1-2.496-4.664l6.692-10.038.817-1.226 3.743-5.614z"
            stroke="#A7A9AC"
            strokeWidth="3"
            fill="#E4E4E4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle fill="#A7A9AC" cx="38" cy="29.75" r="1.75" />
          <path d="M38 16v8" stroke="#A7A9AC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <path d="M.5 61h92.012" stroke="#A7A9AC" strokeWidth="3" />
        <g stroke="#A7A9AC" strokeWidth="3">
          <path d="M34.5 74.5V84M58.5 74.5V84" />
        </g>
      </g>
    </g>
  </svg>
);

export default ErrorBoundaryIcon;
