import { gql } from '@apollo/client';

export const leadConnectionFilterFragment = gql`
  fragment LeadConnectionFilterFragment on LeadConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    archived
    assigned
    assignedToId
    assignedToMe
    createdById
    created
    hasTradeIn
    hasUnprocessed
    lastActive
    leadSourceId
    leadActivityType
    paymentOption
    retailItemOfInterestId
    spam
    status
    tagId
  }
`;
