import { memo, useMemo } from 'react';

import type StepFieldPreviewContents from 'components/core/createModify/interfaces/stepFieldPreviewContents';
import Preview from 'components/ui/shared/DisclaimerPreview';
import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { replaceDislaimerTemplateTags } from 'utils/disclaimerTemplateUtils';
import { getLocaleFromMultilingualValue, MultilingualStringValue, translate } from 'utils/intlUtils';

const { t } = translate;

const tags = {
  [MultilingualStringValue.EN]: {
    make: 'make',
    model: 'model',
    subModel: 'subModel',
    trim: 'trim',
    year: 'year',
    rooftop: 'rooftop',
  },
  [MultilingualStringValue.FR]: {
    make: 'marque',
    model: 'modele',
    subModel: 'version',
    trim: 'garnitures',
    year: 'annee',
    rooftop: 'nomConcession',
  },
} as const;

const placeholderData = {
  year: '2002',
  make: 'Toyota',
  model: 'Corolla',
  subModel: 'LE',
  trim: 'LE CVT',
} as const;

interface Props extends Omit<StepFieldPreviewContents, 'tier'> {
  /** The value of the disclaimer field. This will be used to generate a preview. Expects a Multilingual input  */
  fieldValue: MultilingualString | undefined;
  /**  */
  rooftopName: string;
}

const DisclaimerPreview = ({ fieldValue, language, rooftopName }: Props) => {
  const lng = useMemo(() => getLocaleFromMultilingualValue(language), [language]);
  const fieldValueLanguage = fieldValue?.[language];
  const placeholderWithRooftop = useMemo(() => {
    const languageTags = tags[language];
    const disclaimerPlaceholder = { ...placeholderData, rooftop: rooftopName };

    return Object.fromEntries(
      Object.entries<string>(languageTags).map(([key, value]) => [value, disclaimerPlaceholder[key]])
    );
  }, [language, rooftopName]);

  const previewText = useMemo(
    () => (fieldValueLanguage ? replaceDislaimerTemplateTags(fieldValueLanguage, placeholderWithRooftop) : ''),
    [fieldValueLanguage, placeholderWithRooftop]
  );

  return (
    <Preview
      tags={t('inventory_disclaimer_all_tags', undefined, { lng })}
      title={t('inventory_disclaimer_tag_other', undefined, { lng })}
      previewTitle={t('inventory_disclaimer_preview', undefined, { lng })}
      previewText={previewText}
      noteText={t('disclaimer_preview_note', [t('inventory_disclaimer')], { lng })}
    />
  );
};

export default memo(DisclaimerPreview);
