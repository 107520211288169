import { useCallback } from 'react';

import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { MailboxResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: MailboxResponseType;
}

const RooftopMailboxMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { toggleTier } = useCreateModify();
  const { hasPermissions } = useUser();

  const { id } = item || {};
  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.MAILBOX_MODIFY,
        entityType: EntityType.MAILBOX,
        title: t('modify_x', [t('mailbox')]),
        isCreating: false,
        itemId: id,
      }),
    [id, toggleTier]
  );

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.MAILBOX_MODIFY].requiredPermissions),
    },
  ];

  return <MenuItems items={menuItemsConfig} />;
};

export default RooftopMailboxMenuItems;
