import styled from 'styled-components/macro';

import Loader from 'components/ui/loading/Loader';
import { BUILDER_FIELDS_CONTAINER_BASE_WIDTH, BUILDER_STEP_LEGEND_WIDTH, CARD_WIDTH } from 'styles/layouts';
import { SECTION_PADDING } from 'styles/spacing';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_075, SPACE_050 } from 'styles/tokens';
import { convertPixelToNumber } from 'utils/stringUtils';

export const SubStepLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: ${convertPixelToNumber(CARD_WIDTH) - 6}px; /** subtracting margin **/
  margin-left: 6px;
`;

export const StepComponentCoreContainer = styled.div`
  background-color: ${NEUTRAL_075};
  display: flex;
  height: 100%;
  position: relative;
`;

export const FieldsContainer = styled.div<{ hasActiveSubStep: boolean; hasActivePreviewContent: boolean }>`
  background-color: ${NEUTRAL_0};
  overflow: auto;
  position: relative;
  width: ${({ hasActiveSubStep, hasActivePreviewContent }) =>
    hasActiveSubStep || hasActivePreviewContent ? BUILDER_FIELDS_CONTAINER_BASE_WIDTH : '100%'};
`;

export const SubStepContainer = styled.div`
  overflow: auto;
  padding-left: ${SPACE_050};
  width: ${BUILDER_STEP_LEGEND_WIDTH};
`;

/**
 * Container for rendering components that are used for previewing purposes
 */
export const PreviewContainer = styled(SubStepContainer)`
  display: flex;
  flex-direction: column;
  background: ${NEUTRAL_050};
  padding: ${SECTION_PADDING};
`;
