import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { subscribeWithMetadata } from 'components/sections/createModify/shared/utils';
import { integrationInventoryExportMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { ApolloFetchPolicy } from 'enums/apollo';
import type {
  IntegrationInventoryExportMetaQuery,
  IntegrationInventoryExportMetaQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const InventoryExportQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const rooftopId = seededData?.rooftopName?.id || defaultData.rooftopName?.id;

    const filter = defaultData.filter || defaultData.inventoryExportAttributes?.filter;

    const integrationInventoryExportObservable = client.watchQuery<
      IntegrationInventoryExportMetaQuery,
      IntegrationInventoryExportMetaQueryVariables
    >({
      query: integrationInventoryExportMetaQuery,
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      variables: getRetailFiltersMetaQueryVariables(rooftopId, filter),
    });

    return subscribeWithMetadata<IntegrationInventoryExportMetaQuery, IntegrationInventoryExportMetaQueryVariables>(
      integrationInventoryExportObservable,
      onMetaUpdate
    );
  },
};

export default InventoryExportQueryMethods;
