import { gql } from '@apollo/client';

/**
 * Note queries below are whitelisted by the graphql server.
 * You can never request more fields than
 * what is in the whitelsited query.
 */

export const userForgotPassword = gql`
  mutation UserForgotPasswordMutation($username: String!) {
    userForgotPassword(username: $username)
  }
`;

export const userResetPassword = gql`
  mutation UserResetPasswordMutation($newPassword: String!, $token: String!) {
    userResetPassword(newPassword: $newPassword, token: $token) {
      id
    }
  }
`;
