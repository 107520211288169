import { StepLegendNav, StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import RooftopIntegrationQueryMethods from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationQueryMethods';
import { RooftopIntegrationBuilderStep } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import {
  rooftopIntegrationPhoneCallTrackingCreate,
  rooftopIntegrationPhoneCallTrackingModify,
} from './PhoneCallTrackingCreateModifyQueries';
import DetailFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

const refetchQueries = [
  'RooftopIntegrationsCondensedListQuery',
  'RooftopIntegrationsDetailsQuery',
  'RooftopDetailsContainerQuery',
];

const PhoneCallTrackingBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_CREATE]: {
    refetchQueries,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        fields: DetailFields,
        isEnabled: true,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      create: rooftopIntegrationPhoneCallTrackingCreate,
      modify: rooftopIntegrationPhoneCallTrackingModify,
    },
  },
  [BuilderType.ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_MODIFY]: {
    refetchQueries,
    getData: RooftopIntegrationQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        fields: DetailFields,
        isEnabled: true,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      modify: rooftopIntegrationPhoneCallTrackingModify,
    },
  },
};

export default PhoneCallTrackingBuilders;
