import type { ElementType } from 'react';
import { Fragment, useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import { Clickable } from 'components/ui/shared/Button';
import { ListItemType } from 'enums/listItemType';
import type { ListSelectionStyle } from 'enums/listSelection';
import { ElementTestId, listSelectionItemTestId } from 'enums/testing';
import { DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_050 } from 'styles/tokens';
import { getGroupedOptions } from 'utils/formatting/createModifyFormatUtils';

import type { ListSelectionProps } from './interfaces';
import type { ListCondition } from './listCondition';
import ListSelectionCheckbox from './ListSelectionCheckbox';
import ListSelectionGroupHeader from './ListSelectionGroupHeader';
import { isGroupChecked, isGroupFullyDisabled, isGroupIndeterminate, onSelectAll } from './listSelectionUtils';

const ListItemContainer = styled(Clickable)<{ isActive?: boolean; isCheckbox?: boolean; isReadOnly?: boolean }>`
  border-bottom: 1px solid ${DIVIDER};
  width: 100%;
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      display: flex;
      flex-direction: row;
      padding: ${ENTITY_PADDING};
    `}

  ${({ isCheckbox, isActive }) =>
    !isCheckbox &&
    isActive &&
    css`
      background: ${BLUE_050};
    `};
`;

type ListSelectionCustomRenderProps = {
  /** The filtered list of available options */
  filteredOptions?: SubStepOption[];
  /** The selected options */
  selectedOptions?: SubStepOption[];
  /** The groups that the options can be sorted into */
  subStepGroups?: ListCondition[];
  /** Callback for when an option is selected */
  onSelect?: (item: SubStepOption | StepField[]) => void;
  /** Callback for when a group option is selected */
  onSelectGroup?: (item: SubStepOption) => void;
  /** Callback for when an option is requesting to be edited */
  onEdit?: (id: string) => void;
  /** Callback for when an option is requesting to be deleted */
  onDelete?: (id: string) => void;
  /** The custom rendered element */
  renderElement: ElementType;
  /** Can multiple items in this list be selected */
  isMultiSelect?: boolean;
  /** Does this list use a checkbox for each option */
  isCheckbox?: boolean;
  /** Whether this list has a Select All Header */
  isSelectAllDisabled?: boolean;
  /** The base class name */
  baseClassName: string;
  /** The style variant of this list */
  styleVariant?: ListSelectionStyle;
} & Pick<ListSelectionProps, 'settings' | 'label'>;

const ListSelectionCustomRender = ({
  selectedOptions = [],
  filteredOptions = [],
  onSelect = () => {},
  onEdit,
  onDelete,
  renderElement: RenderElement,
  isMultiSelect,
  isSelectAllDisabled,
  onSelectGroup,
  isCheckbox,
  baseClassName,
  subStepGroups,
  styleVariant,
  label,
}: ListSelectionCustomRenderProps) => {
  const filteredGroups = useMemo(
    () => getGroupedOptions(filteredOptions, subStepGroups),
    [subStepGroups, filteredOptions]
  );

  const itemIsActive = useCallback(
    (itemId: string) => !!selectedOptions.some(entry => entry === itemId || entry?.id === itemId),
    [selectedOptions]
  );

  const handleSelectAll = useCallback(
    (id: string | undefined, groupItems: SubStepOption[]) => {
      onSelectAll(id, selectedOptions, groupItems, onSelect);
    },
    [onSelect, selectedOptions]
  );

  const onGroupHeaderClick = useCallback(
    (id: string | undefined, label: string | undefined, items: SubStepOption[]) => {
      if (isSelectAllDisabled) {
        return undefined;
      }
      if (onSelectGroup) {
        return () => onSelectGroup({ id, label });
      }

      if (isMultiSelect) {
        return () => handleSelectAll(id || label, items);
      }
    },
    [handleSelectAll, isMultiSelect, isSelectAllDisabled, onSelectGroup]
  );

  return (
    <>
      {filteredGroups.map(({ items, label: groupLabel, id: groupId }) => (
        <Fragment key={`${groupLabel || 'filteredGroup'}-${items.length}`}>
          <ListSelectionGroupHeader
            label={
              /*
               * Multiselects and category lists should display a header using the group label
               * when mutliple groups exist, or the field label when 1 group exists
               */
              filteredGroups.length > 1 || (filteredGroups.length === 1 && isMultiSelect)
                ? groupLabel || label
                : undefined
            }
            onClick={onGroupHeaderClick(groupId, groupLabel, items)}
            styleVariant={styleVariant}
            isMultiSelect={isMultiSelect}
            isChecked={
              onSelectGroup
                ? // This is to accomodate GlobalRooftopGroupSelector
                  !!groupId && itemIsActive(groupId)
                : isGroupChecked(groupId || groupLabel, selectedOptions, items)
            }
            disabled={isGroupFullyDisabled(items)}
            isIndeterminate={isGroupIndeterminate(groupId || groupLabel, selectedOptions, items)}
            testId={listSelectionItemTestId(ElementTestId.SELECT_ALL_TOGGLE)}
          />
          {items.map(item => (
            <ListItemContainer
              isCheckbox={isCheckbox}
              key={item.id}
              onClick={!item.isReadOnly && !item.disabled ? () => onSelect(item) : undefined}
              className={`${baseClassName}-${item.id}`}
              isActive={itemIsActive(item.id)}
              isReadOnly={item.disabled || item.isReadOnly}
              data-testid={item?.id ? listSelectionItemTestId(item.id) : undefined}
            >
              {isCheckbox && (
                <ListSelectionCheckbox
                  css={
                    item.isReadOnly &&
                    css`
                      visibility: hidden;
                    `
                  }
                  disabled={item.disabled}
                  round={!isMultiSelect}
                  checked={itemIsActive(item.id)}
                />
              )}
              <RenderElement
                {...item}
                onEdit={onEdit}
                onDelete={onDelete}
                listItemType={isCheckbox ? ListItemType.BUILDER_CHECKBOX_LIST : ListItemType.CONDENSED_LIST}
              />
            </ListItemContainer>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default ListSelectionCustomRender;
