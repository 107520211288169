import { FieldDataType } from 'enums/fieldDataType';
import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

export enum Mailbox {
  ROOFTOP_NAME = 'rooftopName',
  ACTIVE = 'active',
  CONVERSATION = 'conversation',
  CONVERSATION_TYPE = 'conversationType',
  DESCRIPTION = 'description',
  EMAIL = 'email',
  FACEBOOK_ID = 'facebookId',
  NAME = 'name',
  PHONE_NUMBER = 'phoneNumber',
  SOURCE_NAME = 'sourceName',
  TAG_NAME = 'tagName',
  CREATED = 'created',
  UPDATED = 'updated',
}

const MailboxConfig: TableSettingsType = {
  [Mailbox.NAME]: { label: 'name', type: FieldDataType.DEFAULT },
  [Mailbox.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [Mailbox.ACTIVE]: { label: 'active', type: FieldDataType.BOOLEAN },
  [Mailbox.CONVERSATION_TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [Mailbox.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
  [Mailbox.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [Mailbox.DESCRIPTION]: { label: 'description', type: FieldDataType.DEFAULT },
  [Mailbox.SOURCE_NAME]: { label: 'lead_source', type: FieldDataType.NAME },
  [Mailbox.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [Mailbox.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const MailboxSettings = translateFields(MailboxConfig);
