import { gql } from '@apollo/client';

import { selectStringOption } from './selectOption';

export const colorMetadata = gql`
  fragment ColorMetadataFragment on Metadata {
    mutation {
      item: inventoryItem {
        vehicleAttributes {
          exteriorColor {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;
