import { gql } from '@apollo/client';

import { creditApplication } from 'store/api/graph/fragments/creditApplication';
import { leadList } from 'store/api/graph/fragments/leadList';
import { rooftopList } from 'store/api/graph/fragments/rooftopList';

import { address } from './address';
import { appointmentList } from './appointmentList';
import { avatar } from './avatar';
import { employment } from './employment';
import { locale } from './locale';
import { MultilingualString } from './multilingualString';
import { retailItemList } from './retailItemList';
import { tag } from './tag';
import { taskList } from './taskList';
import { tradeInItemList } from './tradeInItemList';
import { userList } from './userList';

// TODO [#1457]: Remove duplicate attributes from all lead fragments/queries
export const leadDetail = gql`
  fragment LeadDetailFragment on Lead {
    ### Details Tab
    ...LeadListFragment
    leadActivitiesCount

    tagName: tags {
      ...TagFragment
    }
    rooftopName: rooftop {
      ...RooftopListFragment
      dealertrackId
      dealertrackConfirmId
    }

    avatar {
      ...AvatarFragment
    }
    email
    paymentOptionsNames
    paymentOptions
    note
    contactTime
    contactMethod
    contactTimeName
    contactMethodName
    phoneNumber
    birthdate
    statusName
    created
    updated
    spam
    locale {
      ...LocaleFragment
    }

    ### Linked Tab
    assignedToName: assignedTo {
      ...UserListFragment
    }
    tradeInItems {
      ...TradeInItemListFragment
    }
    retailItemsOfInterest {
      ...RetailItemListFragment
    }
    appointments {
      ...AppointmentListFragment
    }
    tasks {
      ...TaskListFragment
    }
    createdByName: createdBy {
      ...UserListFragment
    }
    purchaseActivity: leadActivities(type: PURCHASE) {
      id
      attributes {
        ... on PurchaseLeadActivityAttributes {
          retailItemPurchased {
            ...RetailItemListFragment
          }
        }
      }
    }

    creditApplications {
      ...CreditApplicationFragment
    }

    ### Finance Tab
    salutation
    salutationName
    gender
    genderName
    maritalStatus
    maritalStatusName
    company
    driversLicenseNumber
    declaredBankruptcy
    cosigner
    homeStatus
    homeStatusName
    currentAddressMonths
    previousAddressMonths
    sin

    address {
      ...AddressFragment
    }
    previousAddress {
      ...AddressFragment
    }

    employment {
      ...EmploymentFragment
    }

    previousEmployment {
      ...EmploymentFragment
    }
    subscribedPromotions
  }
  ${leadList}
  ${tag}
  ${MultilingualString}
  ${tradeInItemList}
  ${retailItemList}
  ${appointmentList}
  ${taskList}
  ${rooftopList}
  ${userList}
  ${avatar}
  ${retailItemList}
  ${creditApplication}
  ${address}
  ${employment}
  ${locale}
`;
