import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { Website } from 'enums/columns/website';
import { ItemsColumn } from 'enums/itemsColumn';
import { useQuery } from 'hooks/useQuery';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { retailItemMetadata } from 'store/api/graph/fragments/retailItemMetadata';
import { selectStringOption } from 'store/api/graph/fragments/selectOption';
import { websiteDetail } from 'store/api/graph/fragments/websiteDetail';
import { websiteList } from 'store/api/graph/fragments/websiteList';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const websiteDetailQuery = gql`
  query WebsiteDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: website(id: $id) {
      ...WebsiteDetailFragment
    }

    metadata {
      mutation {
        inventoryItem {
          condition {
            ...SelectStringOptionFragment
          }
          type {
            ...SelectStringOptionFragment
          }
          ...RetailItemMetadataFragment
        }
      }
    }
  }
  ${websiteDetail}
  ${selectStringOption}
  ${retailItemMetadata}
`;

export const websiteContainerQuery = gql`
  query WebsiteConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: WebsiteConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: websiteConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...WebsiteListFragment
        }
      }
    }
    metadata {
      mutation {
        item: website {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${selectStringOption}
  ${MultilingualString}
  ${websiteList}
`;

const staticColumns = [ItemsColumn.SELECT, ItemsColumn.PHOTOS, Website.NAME, Website.URL, Website.STATUS] as string[];

export const useWebsiteConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(TableType.WEBSITES);

  const formattedTableColumns = gqlFormatTableColumnFields(
    websiteContainerQuery,
    tableConfigurationNext,
    staticColumns
  );

  return useQuery(websiteContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};
