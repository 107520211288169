import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_600 } from 'styles/tokens';
import { Z_INDEX_0, Z_INDEX_1 } from 'styles/z-index';

export const Tabs = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
`;

export const Tab = styled.button<{ selected?: boolean; isInteractive?: boolean }>`
  border: none;
  height: 44px;
  background: ${props => (props.selected ? NEUTRAL_0 : NEUTRAL_050)};
  padding: 0 ${ENTITY_PADDING};
  ${({ isInteractive = true }) =>
    isInteractive &&
    css`
      cursor: pointer;
    `}
  z-index: ${props => (props.selected ? Z_INDEX_1 : Z_INDEX_0)};
  flex: 1;

  ${Label} {
    color: ${props => (props.selected ? BODY_TEXT : NEUTRAL_600)};
    text-align: center;
  }

  :focus {
    outline: none;
  }

  box-shadow: -1px 0 0 ${props => (props.selected ? NEUTRAL_0 : DIVIDER)};

  &:first-child {
    box-shadow: 0 0 0 ${props => (props.selected ? NEUTRAL_0 : DIVIDER)};
  }
`;
