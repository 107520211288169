import { useCallback } from 'react';

import { generatePath, matchPath } from 'react-router-dom';

import { ItemViewType } from 'enums/ItemViewType';
import { RoutePath } from 'enums/routePath';
import { MatchPattern, useRouter } from 'hooks/useRouter';
import { getInitialLayoutView, getInitialLinkedSectionToggledState } from 'utils/persistanceUtils';
import { getSectionPath } from 'utils/urlUtils';

type EntityViewPatterns =
  | MatchPattern.ROOT_SECTION_VIEW
  | MatchPattern.SPLIT_VIEW
  | MatchPattern.TABLE_VIEW
  | MatchPattern.TABLE_DETAILS_VIEW;

interface UseEntityView {
  /** Generate url path based on the current `viewMode` */
  generatePath: (id?: string) => string;
  /** The current expanded/collapsed state of the linked section for the entity */
  isLinkedSectionCollapsed: boolean;
  /** Unique identifier for the entity */
  itemId?: string;
  /** Section name for the entity */
  section?: string;
  /** The current view mode for the entity */
  viewMode: ItemViewType;
}

/**
 * A hook that provides functionality for managing entity views based on the current URL pathname.
 *
 * @param {string} [pathnameOverride] - An optional parameter allowing overriding the pathname
 */
const useEntityView = (pathnameOverride?: RoutePath): UseEntityView => {
  const { checkIsDefaultSectionPath, pathname: routerPathname, sectionPath: routerSectionPath } = useRouter();
  const pathname = pathnameOverride || routerPathname;
  const sectionPath = pathnameOverride ? getSectionPath(pathnameOverride) : routerSectionPath;
  const rootEntityView = matchPath(MatchPattern.ROOT_SECTION_VIEW, pathname);
  const tableDetailsView = matchPath(MatchPattern.TABLE_DETAILS_VIEW, pathname);
  const tableView = matchPath(MatchPattern.TABLE_VIEW, pathname);
  const splitView = matchPath(MatchPattern.SPLIT_VIEW, pathname);

  /**
   * Get the correct path pattern based on the given `id`. Return MatchPattern for details view
   * if an `id` is defined.
   */
  const getPathPattern = useCallback((id: string | undefined, pattern: EntityViewPatterns) => {
    /**
     * If `id` is defined, we would want to show a details view.
     */
    if (id) {
      /**
       * If the given pattern for this entity is `/:entity` or `/:entity/:itemId`,
       * then we would want to return `/:entity/:itemId`
       */
      if (pattern === MatchPattern.ROOT_SECTION_VIEW || pattern === MatchPattern.SPLIT_VIEW) {
        return MatchPattern.SPLIT_VIEW;
      }

      /**
       * If the given pattern for this entity is `/:entity/table` or `/:entity/table/:itemId`,
       * then we would want to return `/:entity/table/:itemId`
       */
      if (pattern === MatchPattern.TABLE_VIEW || pattern === MatchPattern.TABLE_DETAILS_VIEW) {
        return MatchPattern.TABLE_DETAILS_VIEW;
      }
    }

    /**
     * Return default defined pattern
     */
    return pattern;
  }, []);

  /**
   * Helper method to generate url path based on existing view mode. If an `id` is provided, we will
   * return the details view.
   */
  const generateUrlPath = useCallback(
    (pattern: EntityViewPatterns, section: string, itemIdOverride?: string) =>
      (id = itemIdOverride) => {
        const pathPattern = getPathPattern(id, pattern);
        return generatePath(pathPattern, { section, itemId: id ?? null });
      },
    [getPathPattern]
  );

  /**
   * Get initial layout view from local storage
   */
  const initialLayoutView = getInitialLayoutView({
    forPathname: pathname,
    usePersistedView: checkIsDefaultSectionPath(pathname),
  });

  /**
   * Get initial linked section toggled state from local storage
   */
  const initialLinkedSectionToggledState = getInitialLinkedSectionToggledState({
    forPathname: sectionPath,
  });

  /**
   * Returns initialLayout view if matches the pattern below
   *
   * @example /:section
   */
  if (rootEntityView && rootEntityView.params.section) {
    const pattern =
      initialLayoutView === ItemViewType.SPLIT_VIEW ? MatchPattern.ROOT_SECTION_VIEW : MatchPattern.TABLE_VIEW;
    const section = rootEntityView.params.section;
    return {
      generatePath: generateUrlPath(pattern, section),
      isLinkedSectionCollapsed: true,
      section,
      viewMode: initialLayoutView,
    };
  }

  /**
   * Return table details view if matches the pattern below
   *
   * @example /:section/table/:itemId
   */
  if (tableDetailsView && tableDetailsView.params.section) {
    const itemId = tableDetailsView.params.itemId;
    const section = tableDetailsView.params.section;

    return {
      generatePath: generateUrlPath(MatchPattern.TABLE_DETAILS_VIEW, section, itemId),
      isLinkedSectionCollapsed: initialLinkedSectionToggledState,
      itemId,
      section,
      viewMode: ItemViewType.TABLE_DETAILS_VIEW,
    };
  }

  /**
   * Returns table view if matches the pattern below.
   * `isLinkedSectionCollapsed` is marked as `true` as it is not a details view
   *
   * @example /:section/table
   */
  if (tableView && tableView.params.section) {
    return {
      generatePath: generateUrlPath(MatchPattern.TABLE_VIEW, tableView.params.section),
      isLinkedSectionCollapsed: true,
      section: tableView.params.section,
      viewMode: ItemViewType.TABLE_VIEW,
    };
  }

  /**
   * Returns split view if matches the pattern below
   *
   * @example /:section/:itemId
   */
  if (splitView && splitView.params.section) {
    const itemId = splitView.params.itemId;
    const section = splitView.params.section;
    return {
      generatePath: generateUrlPath(MatchPattern.SPLIT_VIEW, section, itemId),
      isLinkedSectionCollapsed: initialLinkedSectionToggledState,
      itemId,
      section,
      viewMode: ItemViewType.SPLIT_VIEW,
    };
  }

  /**
   * The only time this `return` will hit is if we access `RoutePath.HOME`, which is not
   * a proper entity listing or details view.
   *
   * Defining `generatePath` and `viewMode` here is just to ensure `typescript` always
   * marks these 2 field as defined.
   */
  return {
    generatePath: () => RoutePath.HOME,
    isLinkedSectionCollapsed: true,
    viewMode: initialLayoutView,
  };
};

export default useEntityView;
