import { InventoryItem } from './inventoryItem';

export enum InventoryItemSegmentFilter {
  DAYS_IN_STOCK = 'daysInStock',
  MAKE_ID = 'makeId',
  MAKE_ID_EXCLUDED = 'makeIdExcluded',
  MODEL_ID = 'modelId',
  PURCHASE_PRICE = 'purchasePrice',
  SUBMODEL_ID = 'subModelId',
  TAG_ID = 'tagId',
  ROOFTOP_ID = 'rooftopId',
  HAS_PRICE = 'hasPrice',
  HAS_MILEAGE = 'hasMileage',
  HAS_PHOTOS = 'hasPhotos',
  YEAR = 'yearRange',
}

export type SegmentFilterFieldSettings = { key: string; label: string };

export const inventoryItemSegmentFilterFields: SegmentFilterFieldSettings[] = [
  { key: InventoryItemSegmentFilter.ROOFTOP_ID, label: 'rooftop' },
  { key: InventoryItem.CONDITION, label: 'condition' },
  { key: InventoryItemSegmentFilter.MAKE_ID, label: 'included_make' },
  { key: InventoryItemSegmentFilter.MAKE_ID_EXCLUDED, label: 'excluded_make' },
  { key: InventoryItemSegmentFilter.MODEL_ID, label: 'model_one' },
  { key: InventoryItemSegmentFilter.SUBMODEL_ID, label: 'submodel_one' },
  { key: InventoryItemSegmentFilter.TAG_ID, label: 'tag' },
  { key: InventoryItemSegmentFilter.PURCHASE_PRICE, label: 'price' },
  { key: InventoryItemSegmentFilter.DAYS_IN_STOCK, label: 'days_in_stock' },
  { key: InventoryItemSegmentFilter.YEAR, label: 'year_other' },
  { key: InventoryItem.STATUS, label: 'status' },
  { key: InventoryItem.VEHICLE_MILEAGE, label: 'mileage' },
  { key: InventoryItem.VEHICLE_FUEL_TYPE, label: 'fuel_type' },
  { key: InventoryItem.VEHICLE_BODY_TYPE, label: 'body_type' },
  { key: InventoryItem.TYPE, label: 'type' },
  { key: InventoryItemSegmentFilter.HAS_MILEAGE, label: 'has_mileage' },
  { key: InventoryItemSegmentFilter.HAS_PHOTOS, label: 'has_photos' },
  { key: InventoryItemSegmentFilter.HAS_PRICE, label: 'has_price' },
  { key: InventoryItem.MAPPED, label: 'mapped' },
  { key: InventoryItem.SHOW_WEB, label: 'show_web' },
  { key: InventoryItem.AS_IS, label: 'as_is' },
  { key: InventoryItem.CERTIFIED, label: 'certified' },
  { key: InventoryItem.DEMO, label: 'demo' },
  { key: InventoryItem.FEATURED, label: 'featured' },
  { key: InventoryItem.ON_ORDER, label: 'on_order' },
  { key: InventoryItem.IN_TRANSIT, label: 'in_transit' },
  { key: InventoryItem.COMPLETE, label: 'complete' },
];
