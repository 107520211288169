import type { ReactNode } from 'react';
import { memo, useCallback, useMemo, useRef } from 'react';

import type { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { chunk } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import { Section } from 'components/ui/layouts/CardLayout';
import type { FlexGridProps } from 'components/ui/layouts/FlexGrid';
import { FlexGrid, FlexGridType } from 'components/ui/layouts/FlexGrid';
import { useFlexGrid } from 'hooks/useFlexGrid';
import type { TableSettingsType } from 'utils/tableUtils';

import type {
  ItemGridCellStyleProps,
  LocationFieldDataTypeSettings,
  MonetaryFieldDataTypeSettings,
  PercentageFieldDataTypeSettings,
} from './ItemGridCell';
import { ItemGridCell } from './ItemGridCell';

export const MIN_GRID_SECTION_WIDTH = 172; // px

export const GRID_ITEM_GAP = 30; // px

const { AUTO_FILL } = FlexGridType;

const GridRowContainer = styled(FlexGrid)<FlexGridProps>`
  overflow: hidden;

  /** Custom grid item margins */
  > {
    :nth-child(n + ${({ numColumns }) => numColumns + 1}) {
      margin-top: 27px;
    }

    * {
      ${({ type }) =>
        type === AUTO_FILL &&
        css`
          width: ${MIN_GRID_SECTION_WIDTH}px;
        `};
    }
  }
`;

export interface GridCellRenderMethod {
  (settings: TableSettingsType, item: any, id: string, metadata: any): string | ReactNode;
}

export interface ItemGridRowFieldProps extends ItemGridCellStyleProps {
  id: string;
  /** Whether or not this field can be edited. */
  canEdit?: boolean;
  /** Whether this field will be enhanced with "copy to clipboard" behaviour and icon. */
  canCopy?: boolean;

  /** Specifies how datadog should mask this input e.g. in a session replay. */
  defaultPrivacyLevel?: DefaultPrivacyLevel;
  /**
   * Optional callback for a grid item. If supplied an edit icon will be shown.
   * Used to override the default `onEdit` for an `overrideCellConfig`.
   */
  onEdit?: (field?: string, label?: string) => void;
  /** Settings used to configure grid cell behaviour. */
  cellSettings?: PercentageFieldDataTypeSettings | MonetaryFieldDataTypeSettings | LocationFieldDataTypeSettings;
  /** Optional custom render method */
  gridCellRenderMethod?: GridCellRenderMethod;
  /** Whether this field is locked, and should display a locked field icon */
  isLocked?: boolean;
}

export interface ItemGridRowProps extends ExcludeProps<ItemGridRowFieldProps, 'id'> {
  /**
   * An array of field configs representing a row of the grid. Accepts 2 types.
   * Default input is the active field ID represented by a string.
   * Can be overriden as an `overrideCellConfig` using an `ItemGridRowFieldProps` entry.
   */
  fields: (string | ItemGridRowFieldProps)[];
}

export interface ItemGridRowSectionConfigProps {
  /** The data returned from server to be parsed and displayed in the grid. */
  item: any;
  /** Settings used to configure displayed grid items in the grid. e.g. RooftopIntegrationSettings */
  settings: TableSettingsType;
  /** Optional metadata associated to `item`. e.g. to get server translated values. */
  metadata?: any;
  /** Optional callback for a grid item. If supplied an edit icon will be shown. */
  onEdit?: (field?: string, label?: string) => void;
  /** Optional custom render method for grid cells. */
  gridCellRenderMethod?: GridCellRenderMethod;
}

interface ItemGridRowSectionProps extends ExcludeProps<ItemGridRowProps, 'fields'>, ItemGridRowSectionConfigProps {
  /** Type of custom FlexGrid. */
  flexGridType?: FlexGridType;
  /** Optional method to override the grid fill behaviour. */
  customColumnFillMethod?: (totalColumns: number) => number;
  /** Grid configuration options. */
  config: ItemGridRowProps;
}

// TODO [#943]: Performance Improvements
/**
 * ItemGridRowSection - A Section that is a single grid row, used to display item details.
 *                      Will display the grid in a WYSIWYG format and adhere to a 4/2/1 grid layout.
 *                      Breakpoints will take effect when the minimum width of row items are reached.
 *
 * Example:
 *
 * Item row data seen at column width 4 (large viewport):
 * [
 *  [1,2,3,4]
 * ]
 *
 * Item row data seen at column width 2 (smaller viewport, 1 row split into 2 separate rows with 2 columns each):
 * [
 *  [1,2]
 *  [3,4]
 * ]
 *
 */
export const ItemGridRowSection = memo<ItemGridRowSectionProps>(
  ({ config: { fields, ...defaultCellConfig }, flexGridType = AUTO_FILL, customColumnFillMethod, ...rowProps }) => {
    const defaultColumnFillMethod = useCallback((totalColumns: number) => {
      let customColumnsCount;

      if (totalColumns >= 4) {
        customColumnsCount = 4;
      } else if (totalColumns >= 2) {
        customColumnsCount = 2;
      } else {
        customColumnsCount = 1;
      }

      return customColumnsCount;
    }, []);

    const gridRef = useRef<HTMLDivElement>(null);
    const flexGridInfo = useFlexGrid(
      gridRef,
      MIN_GRID_SECTION_WIDTH,
      GRID_ITEM_GAP,
      customColumnFillMethod || defaultColumnFillMethod
    );

    // 4/2/1 WYSIWYG grid layout
    const gridLayout = useMemo(
      () =>
        chunk(fields, flexGridInfo.customColumnsCount).map(rowFields => (
          <Section
            key={(rowFields as ItemGridRowFieldProps[]).reduce(
              (acc, curr) => `key-grid-row-section-${acc}-${typeof curr === 'string' ? curr : curr?.id}`,
              ''
            )}
          >
            <GridRowContainer
              type={flexGridType}
              ref={gridRef}
              itemGap={GRID_ITEM_GAP}
              numColumns={flexGridInfo.columnsToFill}
              itemWidth={flexGridInfo.columnTotalWidth}
            >
              {(rowFields as ItemGridRowFieldProps[]).map(rowField => {
                const isOverrideConfig = typeof rowField !== 'string';
                const id = isOverrideConfig ? rowField.id : rowField;
                const overrideCellConfig = isOverrideConfig ? { ...rowField } : {};
                return <ItemGridCell key={id} id={id} {...defaultCellConfig} {...rowProps} {...overrideCellConfig} />;
              })}
            </GridRowContainer>
          </Section>
        )),
      [
        fields,
        flexGridInfo.columnTotalWidth,
        flexGridInfo.columnsToFill,
        flexGridInfo.customColumnsCount,
        rowProps,
        defaultCellConfig,
        flexGridType,
      ]
    );

    return <>{gridLayout}</>;
  }
);
