import { gql } from '@apollo/client';

import { tag } from 'store/api/graph/fragments/tag';

export const tagCreate = gql`
  mutation TagCreateMutation($rooftopId: ID, $name: MultilingualStringInput, $type: EntityType) {
    data: tagCreate(rooftopId: $rooftopId, name: $name, type: $type) {
      ...TagFragment
    }
  }
  ${tag}
`;

export const tagModify = gql`
  mutation TagModifyMutation($id: ID!, $name: MultilingualStringInput) {
    data: tagModify(id: $id, name: $name) {
      ...TagFragment
    }
  }
  ${tag}
`;
