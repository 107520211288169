import {
  rooftopRetailCashPricingCreate,
  rooftopRetailCashPricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/cashPricing/RooftopCashSegmentCreateModifyQuery';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';

class FiltersStep extends RetailFilterStep {
  async save(): Promise<boolean> {
    const {
      tier: { isCreating },
    } = this.props;

    return super.save(isCreating ? rooftopRetailCashPricingCreate : rooftopRetailCashPricingModify);
  }
}

export default FiltersStep;
