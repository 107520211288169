import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { ContainerStyles } from 'components/core/createModify/stepFields/StepFieldLayout';
import { StepFieldType } from 'enums/stepFieldType';
import { TradeInItemModifyParameter } from 'store/api/graph/interfaces/types';

const LeadNotesFields: StepFields = {
  leadNote: {
    label: 'notes_from_lead',
    placeholder: 'enter_notes',
    groupType: StepFieldType.TEXT_AREA,
    active: true,
    containerStyles: ContainerStyles.FULL_HEIGHT,
    clear: { field: TradeInItemModifyParameter._leadNote },
  },
};

export default LeadNotesFields;
