import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { AppointmentModifyParameter } from 'store/api/graph/interfaces/types';
import { NONE_LIST_OPTION } from 'utils/formatting/createModifyFormatUtils';
import { durationOptions } from 'utils/timeUtils';

import { AppointmentDetailsBuilderFields } from './interfaces';

const durationFormattedOptions = [
  NONE_LIST_OPTION,
  ...Object.keys(durationOptions).map(option => ({
    id: option,
    name: option,
  })),
];

const DetailFields: StepFields = {
  [AppointmentDetailsBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
    hasSeparator: true,
  },
  [AppointmentDetailsBuilderFields.TITLE]: {
    label: 'title',
    required: true,
    hasSeparator: true,
  },
  // NOTE: This is not actually a mutation parameter, but used in conjunction with dateTo and dateFrom to create values
  [AppointmentDetailsBuilderFields.DATE]: {
    label: 'date',
    queryAlias: ['dateFrom', 'dateTo'],
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
  [AppointmentDetailsBuilderFields.START_TIME]: {
    label: 'start_time',
    queryAlias: ['dateFrom'],
    groupType: StepFieldType.TIME,
    groupSubTypes: [StepFieldSubType.TIME],
    subStep: [SubStepType.DEFAULT],
  },
  [AppointmentDetailsBuilderFields.END_TIME]: {
    label: 'end_time',
    queryAlias: ['dateTo'],
    groupType: StepFieldType.TIME,
    groupSubTypes: [StepFieldSubType.TIME],
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [AppointmentDetailsBuilderFields.REMINDER]: {
    label: 'reminder',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
    tooltipContents: 'reminder_tooltip',
    options: durationFormattedOptions as StepFieldOptions[],
    clear: { field: AppointmentModifyParameter._reminder },
  },
  [AppointmentDetailsBuilderFields.LOCATION]: {
    label: 'location',
    queryAlias: ['address'],
    clear: { field: AppointmentModifyParameter._location },
    hasSeparator: true,
  },
  [AppointmentDetailsBuilderFields.NOTE]: {
    label: 'note_other',
    groupType: StepFieldType.TEXT_AREA,
    containerInnerStyles: css`
      textarea {
        height: 321px;
      }
    `,
    clear: { field: AppointmentModifyParameter._note },
    hasSeparator: true,
  },
  [AppointmentDetailsBuilderFields.TAG_IDS]: {
    label: 'tags',
    queryAlias: 'tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
  },
};

export default DetailFields;
