import { memo } from 'react';

import styled from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import Image from 'components/ui/images/Images';
import { ImageSize, ImageType } from 'enums/imageType';
import type { TrimPhotoFragment } from 'store/api/graph/interfaces/types';

import { PhotoInputContainer } from '../forms/shared/MediaInput';
import { LIST_ITEM_MAX_SQUARE_IMAGE_PX } from '../layouts/ListItem';

const TrimImageContainer = styled(PhotoInputContainer)`
  margin: 0 auto;

  /* Maintain width/height to prevent resize flicker */
  width: ${LIST_ITEM_MAX_SQUARE_IMAGE_PX}px;
  height: ${LIST_ITEM_MAX_SQUARE_IMAGE_PX}px;

  img {
    object-fit: scale-down;
  }
`;

const TrimPhotoListItem = ({ suffixIcon, listItemType, ...trimPhoto }) => {
  const { typeName, gridPhoto } = (trimPhoto as TrimPhotoFragment) || {};

  return (
    <>
      <TrimImageContainer>
        <Image src={gridPhoto} type={ImageType.INVENTORY_ITEM} size={ImageSize.FILL} />
      </TrimImageContainer>
      <Text>{typeName}</Text>
    </>
  );
};

export default memo(TrimPhotoListItem);
