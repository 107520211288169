import { memo } from 'react';

import styled from 'styled-components/macro';

import ChevronDownIcon from './ChevronDownIcon';

const ChevronRightIcon = styled(ChevronDownIcon)`
  transform: rotate(270deg);
`;

export default memo(ChevronRightIcon);
