import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TertiaryText from 'components/core/typography/TertiaryText';
import TextRow from 'components/core/typography/TextRow';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import { ImageSize, ImageType } from 'enums/imageType';
import { formatFullName } from 'utils/formatUtils';

// TODO: This is no longer used, remove this class
const UserActivity = ({ user, action, details, created }) => (
  <ListItem>
    <Image type={ImageType.USER} size={ImageSize.LIST_ITEM} fallbackSrc={user} />
    <ListItemDetails>
      <TextRow>
        <PrimaryText>
          {formatFullName(user)} {action}
        </PrimaryText>
        <TertiaryText>&bull; {created}</TertiaryText>
      </TextRow>
      <SecondaryText>{details}</SecondaryText>
    </ListItemDetails>
  </ListItem>
);

export default UserActivity;
