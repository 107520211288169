import { css } from 'styled-components/macro';

import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import DisclaimerPreview from 'components/sections/createModify/retailBulkAdjustment/DisclaimerPreview';
import { RetailBulkAdjustmentBuilderFields } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import type { NumberInputSettings } from 'components/ui/forms/shared/NumberInput';
import NumberInput from 'components/ui/forms/shared/NumberInput';
import type { SliderInputSettings } from 'components/ui/forms/shared/SliderInput';
import type { ToggleType } from 'components/ui/forms/shared/ToggleInput';
import type { ToggleSelectOptionConfig } from 'components/ui/forms/shared/ToggleSelect';
import ArrowLeftToRightIcon from 'components/ui/icons/ArrowLeftToRightIcon';
import ArrowTopToBottomIcon from 'components/ui/icons/ArrowTopToBottomIcon';
import DiscountIcon from 'components/ui/icons/DiscountIcon';
import DollarSignCircledIcon from 'components/ui/icons/DollarSignCircledIcon';
import DollarSignIcon from 'components/ui/icons/DollarSignIcon';
import PercentIcon from 'components/ui/icons/PercentIcon';
import BulkOverlayAdjustmentImagePositionListItem from 'components/ui/lists/BulkOverlayAdjustmentImagePositionListItem';
import { RetailBulkAdjustment } from 'enums/columns/retailBulkAdjustment';
import { RetailAdjustmentAmountAdjustmentType, RetailAdjustmentAmountType } from 'enums/retailBulkAdjustment';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  DisclaimerRetailBulkAdjustmentAttributesInputParameter,
  OverlayRetailBulkAdjustmentAttributesInputParameter,
  OverlayRetailBulkAdjustmentScalingDimension,
  PriceRetailBulkAdjustmentAttributesInputParameter,
  PromotionRetailBulkAdjustmentAttributesInputParameter,
} from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';

const getNestedAttribute = field => field.split('.')[1];

const PriceAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE]: {
    groupType: StepFieldType.TYPES,
    displayType: [StepFieldDisplayType.OMITTED],
    settings: {
      types: [
        {
          id: RetailAdjustmentAmountAdjustmentType.DISCOUNT,
          label: RetailAdjustmentAmountAdjustmentType.DISCOUNT,
          icon: DiscountIcon,
        },
        {
          id: RetailAdjustmentAmountAdjustmentType.FEE,
          label: RetailAdjustmentAmountAdjustmentType.FEE,
          icon: DollarSignCircledIcon,
        },
      ],
      customStyles: css`
        svg {
          width: 35px;
          height: 32px;
        }
      `,
    },
  },
  [RetailBulkAdjustmentBuilderFields.INCLUDE_CASH]: {
    label: 'apply_cash',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RetailBulkAdjustmentBuilderFields.INCLUDE_FINANCE]: {
    label: 'apply_finance',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RetailBulkAdjustmentBuilderFields.INCLUDE_LEASE]: {
    label: 'apply_lease',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX]: {
    label: 'enable_luxury_tax',
    queryAlias: [RetailBulkAdjustment.LUXURY_TAX_ENABLED],
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
  },
  [RetailBulkAdjustmentBuilderFields.TARGETS]: {
    label: 'target_other',
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    groupType: StepFieldType.TAGS,
    options: 'mutation.retailBulkAdjustment.priceAttributes.targets',
    required: true,
    subStep: [SubStepType.DEFAULT],
  },
  [RetailBulkAdjustmentBuilderFields.LABEL]: {
    label: 'label',
    queryAlias: [getNestedAttribute(RetailBulkAdjustmentBuilderFields.LABEL)],
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: PriceRetailBulkAdjustmentAttributesInputParameter._label, target: 'priceAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.DISCLAIMER]: {
    label: 'disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    queryAlias: [RetailBulkAdjustment.DISCLAIMER, RetailBulkAdjustment.LUXURY_TAX_DISCLAIMER],
    tooltipContents: 'retail_bulk_adjustment_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    clear: { field: PriceRetailBulkAdjustmentAttributesInputParameter._disclaimer, target: 'priceAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.AMOUNT]: {
    label: 'amount',
    groupType: StepFieldType.TOGGLE,
    displayType: [StepFieldDisplayType.OMITTED],
    settings: {
      input: NumberInput,
      types: [
        {
          icon: <PercentIcon />,
          id: RetailAdjustmentAmountType.PERCENTAGE,
          settings: { allowDecimal: true, shouldSanitizeInput: true },
        },
        {
          icon: <DollarSignIcon />,
          id: RetailAdjustmentAmountType.FIXED,
          settings: { allowDecimal: false, shouldSanitizeInput: true },
        },
      ] as ToggleType[],
    } as NumberInputSettings,
  },
  /*
   * These two fields will be hidden from the user, but they hold the respective fixed/percentage amounts that will be
   * included in the save request
   */
  [RetailBulkAdjustmentBuilderFields.FIXED]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: PriceRetailBulkAdjustmentAttributesInputParameter._fixed, target: 'priceAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.PERCENTAGE]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: PriceRetailBulkAdjustmentAttributesInputParameter._percentage, target: 'priceAttributes' },
  },
};

const OverlayAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE]: {
    label: 'photo_one',
    queryAlias: [
      RetailBulkAdjustment.OVERLAY_IMAGE_URL,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE),
    ],
    groupType: StepFieldType.PHOTO,
    clear: { field: OverlayRetailBulkAdjustmentAttributesInputParameter._overlayImage, target: 'overlayAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.PRIMARY_PHOTO_ONLY]: {
    label: 'primary_photo_only',
    queryAlias: [
      RetailBulkAdjustment.PRIMARY_PHOTO_ONLY,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.PRIMARY_PHOTO_ONLY),
    ],
    groupType: StepFieldType.SWITCH,
    clear: {
      field: OverlayRetailBulkAdjustmentAttributesInputParameter._primaryPhotoOnly,
      target: 'overlayAttributes',
    },
  },
  [RetailBulkAdjustmentBuilderFields.OVERLAY_POSITION]: {
    label: 'alignment',
    queryAlias: [
      RetailBulkAdjustment.OVERLAY_POSITION_NAME,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.OVERLAY_POSITION),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    overrideSubStepRenderElement: BulkOverlayAdjustmentImagePositionListItem,
    options: 'mutation.retailBulkAdjustment.overlayAttributes.position',
    hasSeparator: true,
    clear: {
      field: OverlayRetailBulkAdjustmentAttributesInputParameter._position,
      target: 'overlayAttributes',
    },
  },
  [RetailBulkAdjustmentBuilderFields.SCALING_DIMENSION]: {
    label: 'scale_by',
    groupType: StepFieldType.TOGGLE_SELECT,
    settings: {
      options: [
        {
          id: OverlayRetailBulkAdjustmentScalingDimension.WIDTH,
          label: 'width',
          labelColour: BODY_TEXT,
          icon: ArrowLeftToRightIcon,
        },
        {
          id: OverlayRetailBulkAdjustmentScalingDimension.HEIGHT,
          label: 'height',
          labelColour: BODY_TEXT,
          icon: ArrowTopToBottomIcon,
        },
      ] as ToggleSelectOptionConfig[],
    },
    queryAlias: [
      RetailBulkAdjustment.OVERLAY_SCALING_DIMENSION_NAME,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.SCALING_DIMENSION),
    ],
    clear: {
      field: OverlayRetailBulkAdjustmentAttributesInputParameter._scalingDimension,
      target: 'overlayAttributes',
    },
  },
  [RetailBulkAdjustmentBuilderFields.SCALE]: {
    label: 'percentage',
    queryAlias: [RetailBulkAdjustment.OVERLAY_SCALE, getNestedAttribute(RetailBulkAdjustmentBuilderFields.SCALE)],
    groupType: StepFieldType.SLIDER,
    settings: {
      max: 100,
      min: 0,
      step: 1,
      showPercentage: true,
      labelColour: BODY_TEXT,
    } as SliderInputSettings,
    clear: { field: OverlayRetailBulkAdjustmentAttributesInputParameter._scale, target: 'overlayAttributes' },
    hasSeparator: true,
  },
};

const DescriptionAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.TEXT]: {
    label: 'adjustment_description',
    queryAlias: [RetailBulkAdjustment.DESCRIPTION_TEXT, getNestedAttribute(RetailBulkAdjustmentBuilderFields.TEXT)],
    groupType: StepFieldType.RICH_TEXT,
  },
  [RetailBulkAdjustmentBuilderFields.PLACEMENT]: {
    label: 'placement',
    queryAlias: [
      RetailBulkAdjustment.DESCRIPTION_PLACEMENT_NAME,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.PLACEMENT),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.retailBulkAdjustment.descriptionAttributes.placement',
  },
};

const PromotionAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.BANNER_IMAGE]: {
    label: 'image',
    groupType: StepFieldType.PHOTO,
    queryAlias: [
      RetailBulkAdjustment.BANNER_IMAGE_URL,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.BANNER_IMAGE),
    ],
  },
  [RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP]: {
    label: 'show_on_vlp',
    groupType: StepFieldType.SWITCH,
    queryAlias: [RetailBulkAdjustment.SHOW_ON_VLP, getNestedAttribute(RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP)],
  },
  [RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP]: {
    label: 'show_on_vdp',
    groupType: StepFieldType.SWITCH,
    queryAlias: [RetailBulkAdjustment.SHOW_ON_VDP, getNestedAttribute(RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP)],
  },
  [RetailBulkAdjustmentBuilderFields.IMAGE_POSITION]: {
    label: 'image_position',
    groupType: StepFieldType.DROPDOWN,
    queryAlias: [
      RetailBulkAdjustment.POSITION_NAME,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.IMAGE_POSITION),
    ],
    subStep: [SubStepType.DEFAULT],
    clear: { field: PromotionRetailBulkAdjustmentAttributesInputParameter._position, target: 'promotionAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.ALTERNATIVE_TEXT]: {
    label: 'alternative_text',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    queryAlias: [RetailBulkAdjustment.ALT_TEXT, getNestedAttribute(RetailBulkAdjustmentBuilderFields.ALTERNATIVE_TEXT)],
    hasSeparator: true,
    tooltipContents: 'alternative_text_tooltip',
    clear: { field: PromotionRetailBulkAdjustmentAttributesInputParameter._altText, target: 'promotionAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.BANNER_URL]: {
    label: 'banner_url',
    queryAlias: [RetailBulkAdjustment.BANNER_URL, getNestedAttribute(RetailBulkAdjustmentBuilderFields.BANNER_URL)],
    clear: { field: PromotionRetailBulkAdjustmentAttributesInputParameter._bannerUrl, target: 'promotionAttributes' },
  },
  [RetailBulkAdjustmentBuilderFields.OPEN_LINK_IN_NEW_TAB]: {
    label: 'open_link_in_new_tab',
    groupType: StepFieldType.SWITCH,
    queryAlias: [
      RetailBulkAdjustment.OPEN_LINK_IN_NEW_TAB,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.OPEN_LINK_IN_NEW_TAB),
    ],
  },
  [RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP]: {
    label: 'link_to_vdp',
    groupType: StepFieldType.SWITCH,
    queryAlias: [
      RetailBulkAdjustment.BANNER_LINKS_TO_VDP,
      getNestedAttribute(RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP),
    ],
    clear: {
      field: PromotionRetailBulkAdjustmentAttributesInputParameter._bannerLinksToVdp,
      target: 'promotionAttributes',
    },
  },
};

const DislaimerAdjustmentFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.DISCLAIMER_TEMPLATE]: {
    label: 'disclaimer',
    placeholder: 'disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    clear: {
      field: DisclaimerRetailBulkAdjustmentAttributesInputParameter._disclaimerTemplate,
      target: 'disclaimerAttributes',
    },
    previewContents: ({ fieldValue, language, tier }) => (
      <DisclaimerPreview
        fieldValue={fieldValue}
        language={language}
        rooftopName={tier.seededData?.rooftopName?.name.value || tier.data.rooftopName?.name.value || ''}
      />
    ),
  },
};

const RetailBulkAdjustmentFields: StepFields = {
  ...PriceAdjustmentFields,
  ...OverlayAdjustmentFields,
  ...DescriptionAdjustmentFields,
  ...PromotionAdjustmentFields,
  ...DislaimerAdjustmentFields,
};

export default RetailBulkAdjustmentFields;
