import { saveFilterViewList } from 'containers/filters/SavedFilterViewQueries';
import type { SavedFilterViewSettingsType } from 'containers/filters/SavedFilterViewSettings';
import { formatSavedFilters } from 'containers/filters/savedFilterViewUtils';
import { useQuery } from 'hooks/useQuery';
import { EntityType } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';

export const useSavedFilterQuery = (settings?: SavedFilterViewSettingsType): CustomQueryResult => {
  const variables = {
    type: settings?.entityType,
    d_isAppointmentConnectionFilter: settings?.entityType === EntityType.APPOINTMENT,
    d_isTaskConnectionFilter: settings?.entityType === EntityType.TASK,
    d_isConversationConnectionFilter: settings?.entityType === EntityType.CONVERSATION,
    d_isRetailItemConnectionFilter: settings?.entityType === EntityType.RETAIL_ITEM,
    d_isTradeInConnectionFilter: settings?.entityType === EntityType.TRADE_IN_ITEM,
    d_isLeadConnectionFilter: settings?.entityType === EntityType.LEAD,
  };

  const response = useQuery(saveFilterViewList, {
    variables,
    ignore: !settings,
  });

  return settings
    ? {
        ...response,
        data: response?.data?.savedConnectionFilters?.map(filter =>
          formatSavedFilters({ filterData: filter, settings })
        ),
      }
    : { ...response };
};
