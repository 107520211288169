export enum FeatureBundleSet {
  APPOINTMENT = 'appointment',
  TASK = 'task',
  /**
   * Lead feature bundle includes lead activities
   */
  LEAD = 'lead',
  LEAD_FORWARDING = 'leadForwarding',
  CONVERSATION = 'conversation',
  TRADE_IN = 'tradeIn',
  RETAIL = 'retail',
  RETAIL_CASH_PAYMENT_OPTION = 'retailCashPaymentOption',
  RETAIL_FINANCE_PAYMENT_OPTION = 'retailFinancePaymentOption',
  RETAIL_LEASE_PAYMENT_OPTION = 'retailLeasePaymentOption',
  /**
   * Whether a single payment option is enabled
   */
  RETAIL_PAYMENT_OPTION = 'retailPaymentOption',

  RETAIL_SHOWROOM = 'retailShowroom',
  RETAIL_BUILD_AND_PRICE = 'retailBuildAndPrice',
}

/**
 * Feature Bundle Package reference sheet:
 * https://docs.google.com/spreadsheets/d/1490t6uuvf56fPdpjN7He57xuIUxR-tOxL93Pdr1lkC0/edit#gid=1745071040
 */
export enum FeatureBundlePackage {
  /*
   * Edealer Prestige package
   * Features enabled:
   * - Leads
   * - SMS Mailbox Limit (1)
   * - Conversations
   * - Appointments
   * - Tasks
   * - Trade-In
   * - Retail
   * - Build and Price
   * - Showroom
   * - Payment Option (Cash, Finance, Lease)
   * - Premium Ad Exports (Facebook Inventory Export)
   * - Unlimited Inventory
   *
   * Features disabled:
   * - Premium Ad Exports (Google Business Inventory Export)
   *
   */
  EDEALER_PRESTIGE = 'edealer_prestige',
  /* No features enabled */
  EMPTY = 'empty',
}
