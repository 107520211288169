import type { VehicleAttributes } from 'store/api/graph/interfaces/types';
import { InventoryItemCondition, InventoryItemType, VehicleBodyType } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';

export function isWarrantyAvailable(retailItem: RetailItemResponseType) {
  return (
    retailItem.__typename === 'RetailItem' &&
    retailItem.rooftop?.warrantyAvailable &&
    retailItem.type !== InventoryItemType.MOTORCYCLE &&
    !!(retailItem.vehicleAttributes as VehicleAttributes)?.bodyType &&
    (retailItem.vehicleAttributes as VehicleAttributes)?.bodyType !== VehicleBodyType.COMMERCIAL &&
    retailItem.condition !== InventoryItemCondition.NEW
  );
}
