import { memo, type SVGProps } from 'react';

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2.79 21.102a1.07 1.07 0 0 1-.572-.157 1.108 1.108 0 0 1-.397-.415 1.22 1.22 0 0 1-.155-.554c-.009-.2.043-.394.155-.584L11.005 3.5a1.08 1.08 0 0 1 .44-.426c.18-.093.365-.14.556-.14.19 0 .375.047.555.14.18.094.327.236.439.426l9.184 15.892c.112.19.164.385.155.584-.008.2-.06.384-.155.554-.096.17-.228.308-.398.413-.17.106-.36.159-.572.159H2.791Zm1.81-2.186h14.8L12 6.168 4.6 18.916Zm7.4-.934c.287 0 .53-.1.73-.3.2-.199.3-.442.3-.73 0-.287-.1-.53-.3-.727a1 1 0 0 0-.73-.297.999.999 0 0 0-.73.297c-.2.197-.3.44-.3.727 0 .288.1.531.3.73.2.2.443.3.73.3ZM12 15c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 14v-2.934a.968.968 0 0 0-.287-.713.968.968 0 0 0-.713-.287.967.967 0 0 0-.713.287.967.967 0 0 0-.287.713V14c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </svg>
);
export default memo(WarningIcon);
