import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { DialogBodyCSS } from 'components/ui/layouts/DialogLayout';
import Button from 'components/ui/shared/Button';
import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_800, RED_500 } from 'styles/tokens';
import { variants } from 'utils/styledUtils';

export enum OptionButtonStyle {
  CONFIRMATION = 'CONFIRMATION',
  DESTRUCTIVE = 'DESTRUCTIVE',
  CANCEL = 'CANCEL',
  POPOVER = 'POPOVER',
}

export const OptionButton = styled(Button)<{ styleVariant?: OptionButtonStyle }>`
  height: 40px;
  min-width: 127px;
  margin-bottom: 10px;
  width: 100%;

  ${Label} {
    margin: auto;
    color: ${NEUTRAL_0};
  }

  ${variants<OptionButtonStyle>('styleVariant', {
    [OptionButtonStyle.CONFIRMATION]: css`
      background: ${BLUE_500};
    `,
    [OptionButtonStyle.DESTRUCTIVE]: css`
      background: ${RED_500};
    `,
    [OptionButtonStyle.CANCEL]: css`
      background: ${NEUTRAL_0};
      color: ${BODY_TEXT_TERTIARY};
      border: 1px solid ${BODY_TEXT_TERTIARY};
      ${Label} {
        color: ${BODY_TEXT};
      }
    `,
    [OptionButtonStyle.POPOVER]: css`
      background: ${NEUTRAL_800};
      && ${Label} {
        color: ${NEUTRAL_0};
      }
    `,
  })}
`;

export const OptionsContainer = styled.div`
  ${DialogBodyCSS}

  > ${Text} {
    text-align: center;
    justify-content: center;
    padding: 8px 8px 24px 8px;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-self: normal;
    width: 100%;

    ${OptionButton} {
      margin-bottom: 0;
    }
  }
`;
