import { get } from 'lodash-es';

import Paragraph from 'components/core/typography/Paragraph';
import type RetailItemCaptureVinStep from 'components/sections/createModify/inventoryItems/retailItem/steps/CaptureVinStep';
import { CarfaxReportErrors } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import { carfaxReportQuery } from 'components/sections/createModify/inventoryItems/steps/carfaxReportQuery';
import type { VinDecode } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { WarningContainer } from 'components/sections/createModify/inventoryItems/steps/utils';
import { vinDecodeQuery } from 'components/sections/createModify/inventoryItems/steps/VinDecodeQuery';
import WarningIcon from 'components/ui/icons/WarningIcon';
import { ApolloFetchPolicy } from 'enums/apollo';
import { getApiErrors, getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import type { CarfaxReport, Rooftop } from 'store/api/graph/interfaces/types';
import type { VinDecodeResponseType } from 'store/api/graph/responses/responseTypes';
import { YELLOW_500 } from 'styles/tokens';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export class RetailItemCaptureVinDecodeImplementer implements VinDecode {
  step: RetailItemCaptureVinStep;

  constructor(step: RetailItemCaptureVinStep) {
    this.step = step;
  }

  async getCarfaxReport(
    vin: string,
    rooftop: Partial<Rooftop>
  ): Promise<{ carfaxReport?: CarfaxReport; errorType?: CarfaxReportErrors }> {
    // If no rooftop id is provided, or there is no carfaxId associated with this rooftop, then we cannot get a report
    if (!rooftop?.id || !rooftop?.carfaxId) {
      return { errorType: CarfaxReportErrors.NO_CARFAX_ACCOUNT_ON_ROOFTOP };
    }

    try {
      const { data } = await this.step.client.query({
        query: carfaxReportQuery,
        variables: {
          vin,
          rooftopId: rooftop.id,
        },
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });
      return { carfaxReport: data.carfaxReport };
    } catch (error) {
      const errors = getApiErrors(error);

      /**
       * If there was no carfax report found, the error status will be 400 - we don't need to show any error message
       * in this scenario. However any other errors (network errors, etc) we will need to show an error message.
       */
      if (errors.some(({ extensions }) => extensions?.status !== 400)) {
        this.step.setTier({
          errors: getApiErrors(error),
        });
      }
      return { errorType: CarfaxReportErrors.NO_CARFAX_FOUND };
    }
  }

  async decodeVin({ rooftop }): Promise<Partial<VinDecodeResponseType> | null> {
    const {
      tier: { activeStep },
    } = this.step.props;
    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.step.fields);
    const emptyVinResult = {};

    let vinDecode;

    try {
      const { data } = await this.step.client.query({
        query: vinDecodeQuery,
        variables: {
          vin: vinField.selectedValue.trim(),
          trimId: this.step.selectedTrim?.selectedValue?.id,
        },
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });

      vinDecode = data.vinDecode;
    } catch (error) {
      this.step.setTier({
        alert: {
          content: getApiErrorsMessage(error),
          onClick: () => this.saveVinDecodedDataAndNavigateNextStep(null, null),
          buttonText: t('proceed_with_vin'),
        },
      });
      return null;
    } finally {
      /*
       * Clear the default close prompt, even though the API response may have failed, we can proceed
       * to the next step
       */
      if (activeStep?.onClosePrompt === this.step.defaultClosePrompt) {
        this.step.setOnClosePrompt(undefined);
      }
    }

    const matchedTrim =
      vinDecode.matchingTrims.length === 1
        ? vinDecode.matchingTrims[0]
        : vinDecode.matchingTrims.find(trim => trim.id === this.step.selectedTrim?.selectedValue?.id);

    // More than one matching trim, queue subStep to select a match and make a call again
    if (vinDecode.matchingTrims.length > 1) {
      // VinField and selectedTrim are deeply coupled, manually set vinField visuals to `active` override
      this.step.selectedTrim.options = vinDecode.matchingTrims.map(item => ({ ...item, name: item.name.value }));
      vinField.active = true;
      this.step.setState({
        childrenBeforeSubStep: this.renderTrimWarning(),
        currentStepField: this.step.selectedTrim,
        childrenAfter: undefined,
      });
      return null;
    } else if (matchedTrim) {
      return { ...vinDecode.vinMatch, trimName: matchedTrim };
    } else {
      return emptyVinResult;
    }
  }

  renderTrimWarning() {
    return (
      <WarningContainer>
        <WarningIcon color={YELLOW_500} />
        <Paragraph>{t('partial_vin_decode_message')}</Paragraph>
      </WarningContainer>
    );
  }

  clearTrimSubStep() {
    // Clearing substep where applicable
    this.step.selectedTrim.options = [];
    this.step.selectedTrim.selectedValue = undefined;
    this.step.setState({ childrenBeforeSubStep: undefined });
  }

  /**
   * This step doesn't actually make any API requests to save data, instead it will persist the VinDecodeResponseType
   * data into the Tier, so that the decoded vin data can be used in other Steps of the builder. After updating
   * Tier data, we enable the DETAILS Steps and proceed to the next Step (which is the DETAILS step).
   * @param vinDecodedData
   * @param carfaxReport
   */
  saveVinDecodedDataAndNavigateNextStep(
    vinDecodedData: Partial<VinDecodeResponseType> | null,
    carfaxReport: CarfaxReport | null
  ) {
    const {
      tier: { steps, activeStep, data },
      onStepComplete,
    } = this.step.props;

    activeStep!.isEnabled = false;
    steps!.find(step => step.id === 'DETAILS')!.isEnabled = true;

    const rooftopValue =
      getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.step.fields).selectedValue || data.rooftop;
    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.step.fields);
    const updatedFields = {
      stockNumber: getStepField(DetailsInventoryItemBuilderFields.STOCK_NUMBER, this.step.fields)?.selectedValue,
      status: get(getStepField('status', this.step.fields), 'selectedValue.name'),
      type: getStepField(DetailsInventoryItemBuilderFields.TYPE, this.step.fields).selectedValue,
      vin: vinField.selectedValue || null,
    };

    this.step.setTier({
      steps,
      data: {
        ...data,
        ...updatedFields,
        ...vinDecodedData,
        carfaxReport,
        rooftop: { ...rooftopValue, name: { value: rooftopValue?.name?.value || rooftopValue?.name } },
      },
      alert: undefined,
    });

    onStepComplete?.(true);
  }
}
