import { useCallback, useEffect, useState } from 'react';

import type { LDContextCommon } from 'launchdarkly-react-client-sdk';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { camelCase, merge } from 'lodash-es';

import type { FeatureFlagSet, LDContextKinds } from 'utils/featureFlagUtils';
import { getFeatureFlagsOverride } from 'utils/featureFlagUtils';
export type { LDFeatureFlagsKey, FeatureFlagSet } from 'utils/featureFlagUtils';

export interface ContextKindsArgs {
  kind: LDContextKinds;
  context: LDContextCommon;
}

export const useFeatureFlags = () => {
  const featureFlagsOverride = getFeatureFlagsOverride();
  const client = useLDClient();
  const flags = useFlags<FeatureFlagSet>();

  const [hasFlags, setHasFlags] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    void client?.waitUntilReady().then(() => {
      setReady(true);
      setHasFlags(Object.keys(flags).length > 0);
    });
  }, [client, flags]);

  /**
   * Helper function to retrieve additional context
   */
  const updateFeatureFlagContext = useCallback(
    async (contexts: ContextKindsArgs[]): Promise<FeatureFlagSet | undefined> => {
      await client?.waitUntilReady();
      const currentLDContext = client?.getContext();

      const updatedFlags = await client?.identify({
        ...currentLDContext,
        ...Object.fromEntries(contexts.map(({ kind, context }) => [kind, context])),
      });

      if (!updatedFlags) {
        return undefined;
      }

      const camelizedFlags = Object.entries(updatedFlags).reduce(
        (acc, [key, value]) => ({ ...acc, [camelCase(key)]: value }),
        {} as FeatureFlagSet
      );

      return camelizedFlags;
    },
    [client]
  );

  return {
    ready,
    flags: process.env.STORYBOOK ? merge({}, flags, featureFlagsOverride) : flags,
    hasFlags,
    updateFeatureFlagContext,
  };
};
