import type { ReactNode, UIEvent } from 'react';

import styled from 'styled-components/macro';

import MultiLineText from 'components/core/typography/MultiLineText';
import PrimaryText from 'components/core/typography/PrimaryText';
import { FormSubmitButton } from 'components/ui/forms/shared/Form';
import { Input } from 'components/ui/forms/shared/InputText';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import { MobileGate } from 'components/ui/layouts/MobileLayout';
import { CTAButton } from 'components/ui/shared/Button';
import { RoutePath } from 'enums/routePath';
import { useMountEffect } from 'hooks/useMountEffect';
import { useRouter } from 'hooks/useRouter';
import { getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import { LIST_ITEM_HEIGHT_WITH_BORDER } from 'styles/layouts';
import { NEUTRAL_0, RED_500 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_SIZE_24, FONT_WEIGHT_BOLDER } from 'styles/typography';
import { isLoggedIn } from 'utils/authUtils';
import { isIE, isMobileDevice } from 'utils/deviceInfoUtils';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';

import CheckEmptyIcon from '../icons/CheckEmptyIcon';

const { t } = translate;

const LayoutContainer = styled.div`
  height: 100vh;
  overflow: auto;
  position: relative;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 1000px;
    background-color: '#6CBBF0'; /* For browsers that do not support gradients */
    background-image: linear-gradient(#6cbbf0, #3287c2);
    z-index: -1;
  }
`;

const LayoutContainerInner = styled.div`
  width: 344px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: center;
`;

const ContentContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  font-size: ${FONT_SIZE_14};
  width: 100%;

  * {
    color: ${NEUTRAL_0};
  }

  ${CTAButton}, ${Input} {
    height: 45px;
  }

  ${Input} {
    border: none;
    background-color: #3388c2;
  }
`;

const Title = styled(PrimaryText)`
  font-size: ${FONT_SIZE_24};
  font-weight: ${FONT_WEIGHT_BOLDER};
  color: ${NEUTRAL_0};
  white-space: normal;
  margin: 68px 0 64px;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  width: 100%;
  padding: 20px 25px;
  background-color: ${NEUTRAL_0};
  border-radius: 8px;
  color: ${RED_500};
  margin: ${LIST_ITEM_HEIGHT_WITH_BORDER} 0;
`;

interface AuthLayoutProps {
  children?: ReactNode;
  error?: Error;
  logo?: any;
  title: string;
}

const UnsupportedBrowserGate = () => (
  <LayoutContainer>
    <LayoutContainerInner>
      <EDealerIcon />
      <ContentContainer>
        <Content>
          <ContentHeader>
            <Title>{t('browser_unsupported_title')}</Title>
          </ContentHeader>
          <MultiLineText>{t('browser_unsupported_message')}</MultiLineText>
        </Content>
      </ContentContainer>
    </LayoutContainerInner>
  </LayoutContainer>
);

const getGatedPage = () => {
  if (isMobileDevice()) {
    return <MobileGate />;
  } else if (isIE()) {
    return <UnsupportedBrowserGate />;
  }
};

/**
 * Wrapper component used to create landing pages by wrapping the children content.
 * e.g. <Login />
 */
const AuthLayout = ({ logo, title, children, error }: AuthLayoutProps) => {
  const gatedPage = getGatedPage();

  /**
   * Ensure that you are logged out of any impersonation; you may be still
   * logged in as someone if your session timed out while you haven't logged out
   * or closed the tab.
   */
  useMountEffect(() => {
    if (isLoggedIn()) {
      return;
    }
    if (impersonationManager.isCurrentlyImpersonating) {
      impersonationManager.endImpersonation();
    }
  });

  if (gatedPage) {
    return gatedPage;
  }

  return (
    <LayoutContainer>
      <LayoutContainerInner>
        {logo}
        <ContentContainer>
          <Content>
            <ContentHeader>
              {error ? (
                <ErrorMessage data-testid="form-errors">{getApiErrorsMessage(error)}</ErrorMessage>
              ) : (
                <Title>{title}</Title>
              )}
            </ContentHeader>
            {children}
          </Content>
        </ContentContainer>
      </LayoutContainerInner>
    </LayoutContainer>
  );
};

export default AuthLayout;

interface SuccessLayoutProps {
  title: string;
  message: string;
  logo?: ReactNode;
  buttonText?: string;
  onClick?: (e: UIEvent<HTMLElement>) => void;
}

/**
 * Used for AuthLayout success pages
 */
export const SuccessLayout = ({
  title,
  message,
  logo = <CheckEmptyIcon width={90} height={90} color={NEUTRAL_0} />,
  buttonText = 'Got it',
  onClick,
}: SuccessLayoutProps) => {
  const router = useRouter();

  const redirectToLogin = () => {
    router.push(RoutePath.LOGIN);
  };

  return (
    <AuthLayout logo={logo} title={title}>
      <MultiLineText>{message}</MultiLineText>
      <FormSubmitButton type="button" onClick={onClick || redirectToLogin}>
        {buttonText}
      </FormSubmitButton>
    </AuthLayout>
  );
};
