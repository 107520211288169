import { gql } from '@apollo/client';

import { conversationDetail } from 'store/api/graph/fragments/conversationDetail';
import { conversationItem } from 'store/api/graph/fragments/conversationItem';

export const conversationModify = gql`
  mutation ConversationModifyMutation(
    $id: ID!
    $archivedByMe: Boolean
    $readByMe: Boolean
    $tagIds: [ID!]
    $participants: [ID!]
    $_clear: [ConversationModifyParameter!]
  ) {
    data: conversationModify(
      id: $id
      archivedByMe: $archivedByMe
      readByMe: $readByMe
      tagIds: $tagIds
      participantIds: $participants
      _clear: $_clear
    ) {
      ...ConversationDetailFragment
    }
  }

  ${conversationDetail}
`;

export const conversationItemCreate = gql`
  mutation ConversationItemCreateMutation(
    $conversationId: ID
    $leadActivityId: ID
    $message: String
    $type: ConversationItemType
    $attachments: [Upload!]
  ) {
    data: conversationItemCreate(
      conversationId: $conversationId
      leadActivityId: $leadActivityId
      message: $message
      type: $type
      attachments: $attachments
    ) {
      ...ConversationItemFragment
    }
  }

  ${conversationItem}
`;
