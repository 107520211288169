import { useCallback, useMemo } from 'react';

import styled from 'styled-components/macro';

import Button from 'components/ui/shared/Button';
import type { SecondaryListViewProps } from 'containers/nestedView/NestedViewSettings';
import { ElementTestId } from 'enums/testing';
import type { LinkedListProps } from 'hooks/useLinkedList';
import useLinkedList from 'hooks/useLinkedList';
import { ENTITY_PADDING } from 'styles/spacing';
import { translate } from 'utils/intlUtils';

interface LinkedListViewerProps extends LinkedListProps {
  /** Config override for nested view settings */
  overrideConfig?: Pick<SecondaryListViewProps, 'alwaysShowCreateButton'>;
  /** QueryVars override for the underlying network call */
  overrideQueryVars?: Record<string, any>;
  /** Remaining number of items to display */
  remainingItems: number;
  /** Translation key for view more button */
  viewMoreTranslationKey: string;
}

const LinkedListViewerButton = styled(Button)`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 40px;
  margin: 0 ${ENTITY_PADDING} ${ENTITY_PADDING};
`;

const LinkedListViewer = (props: LinkedListViewerProps) => {
  const { overrideConfig, listEntity, remainingItems, viewMoreTranslationKey, title, isNested, overrideQueryVars } =
    useMemo(() => props, [props]);
  const { showList } = useLinkedList(listEntity, title, isNested, undefined, overrideConfig);

  return (
    <LinkedListViewerButton
      data-testid={ElementTestId.LINKED_LIST_VIEWER_BUTTON}
      onClick={useCallback(() => showList(overrideQueryVars), [showList, overrideQueryVars])}
    >
      {translate.tPlural(viewMoreTranslationKey, remainingItems === 1 ? 1 : 0, [remainingItems])}
    </LinkedListViewerButton>
  );
};

export default LinkedListViewer;
