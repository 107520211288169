import styled from 'styled-components/macro';

import { NEUTRAL_800 } from 'styles/tokens';
import { FONT_WEIGHT_BOLD } from 'styles/typography';

import Text from './Text';

const PrimaryText = styled(Text)`
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${NEUTRAL_800};
`;

export default PrimaryText;
