import { memo, type SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 17.09c.309 0 .568-.105.777-.314.208-.209.313-.467.313-.776v-3.91c0-.31-.105-.568-.313-.777A1.055 1.055 0 0 0 12 11c-.309 0-.568.104-.777.313a1.054 1.054 0 0 0-.313.777V16c0 .309.105.567.313.776.21.21.468.314.777.314Zm0-7.97c.317 0 .583-.108.798-.322.214-.215.322-.48.322-.798 0-.317-.108-.583-.322-.798A1.084 1.084 0 0 0 12 6.88c-.317 0-.583.108-.798.322-.214.215-.322.48-.322.798 0 .317.108.583.322.798.215.214.48.322.798.322Zm0 13.083a9.946 9.946 0 0 1-3.983-.803 10.3 10.3 0 0 1-3.238-2.179 10.3 10.3 0 0 1-2.18-3.238A9.945 9.945 0 0 1 1.798 12c0-1.415.267-2.743.803-3.983a10.3 10.3 0 0 1 2.179-3.238 10.3 10.3 0 0 1 3.238-2.18A9.945 9.945 0 0 1 12 1.798c1.415 0 2.743.267 3.983.803a10.3 10.3 0 0 1 3.238 2.179 10.3 10.3 0 0 1 2.18 3.238A9.946 9.946 0 0 1 22.202 12a9.946 9.946 0 0 1-.803 3.983 10.3 10.3 0 0 1-2.179 3.238 10.3 10.3 0 0 1-3.238 2.18 9.946 9.946 0 0 1-3.983.802Zm0-2.275c2.217 0 4.093-.767 5.627-2.3 1.534-1.535 2.301-3.41 2.301-5.628 0-2.217-.767-4.093-2.3-5.627-1.535-1.534-3.41-2.301-5.628-2.301-2.217 0-4.093.767-5.627 2.3C4.839 7.908 4.072 9.783 4.072 12c0 2.217.767 4.093 2.3 5.627 1.535 1.534 3.41 2.301 5.628 2.301Z"
    />
  </svg>
);

export default memo(InfoIcon);
