import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import LinkedFields from 'components/sections/createModify/shared/steps/LinkedFields';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { Task } from 'enums/columns/task';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const TasksLinkedFields: StepFields = {
  assignedToId: {
    ...LinkedFields.assignedToId,
    queryAlias: Task.ASSIGNED_TO_NAME,
    hasSeparator: true,
  },
  leadId: {
    ...LinkedFields.leadId,
    queryAlias: Task.LEAD_NAME,
    subStepAddConfig: SubStepAddConfigs[BuilderType.LEAD_CREATE],
    hasSeparator: true,
    requiredPermissions: [{ resource: ResourceType.LEADS, level: AccessLevel.BASIC }],
  },
  inventoryItemId: {
    ...LinkedFields.inventoryItemId,
    queryAlias: Task.INVENTORY_ITEM_NAME,
    hasSeparator: true,
  },
};

export default TasksLinkedFields;
