import { type ComponentProps, type ComponentType, forwardRef, type ForwardRefRenderFunction } from 'react';

import { merge } from 'lodash-es';
import styled from 'styled-components/macro';

import EditorIcon from 'components/ui/editor/components/shared/EditorIcon';
import ChevronDownIcon from 'components/ui/icons/ChevronDownIcon';
import type { TooltipProps } from 'components/ui/shared/IconButton';
import IconButton, { IconButtonStyle } from 'components/ui/shared/IconButton';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import { ElementTestId } from 'enums/testing';

const defaultTooltipProps: TooltipProps = {
  arrowPosition: {
    primary: PrimaryArrowPosition.TOP,
    secondary: SecondaryArrowPosition.CENTER,
  },
  children: '',
  margin: { y: 4 },
};

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export interface Props<T extends ComponentType<any>> extends Omit<ComponentProps<typeof IconButton>, 'styleVariant'> {
  /** Icon to be displayed in the button */
  icon: T;
  /** Optional props to be passed to the icon component */
  iconProps?: ComponentProps<T>;
  /** Show or hide the chevron icon next to the icon */
  showChevron?: boolean;
  /** Optional label text to render after the icon and before the chevron */
  label?: string;
}

/**
 * A wrapper component for icon buttons used in the editor.
 */
const EditorIconButton: ForwardRefRenderFunction<HTMLButtonElement, Props<ComponentType<any>>> = (
  { showChevron = false, icon, iconProps, tooltip, label, ...props },
  ref
) => (
  <IconButtonContainer>
    <IconButton
      ref={ref}
      styleVariant={IconButtonStyle.EDITOR}
      tooltip={tooltip ? merge({}, defaultTooltipProps, tooltip) : undefined}
      {...props}
    >
      <EditorIcon icon={icon} {...iconProps} />
      {label}
      {showChevron && <EditorIcon icon={ChevronDownIcon} data-testid={ElementTestId.CHEVRON_DOWN_ICON} />}
    </IconButton>
  </IconButtonContainer>
);

export default forwardRef(EditorIconButton);
