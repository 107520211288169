import { gql } from '@apollo/client';

import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';

import { listPhoto } from './listPhoto';
import { MultilingualString } from './multilingualString';

export const retailItemList = gql`
  fragment RetailItemListFragment on RetailItem {
    id
    archived

    primaryPhoto {
      ...ListPhotoFragment
    }
    year
    makeName: make {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    modelName: model {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModelName: subModel {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    trimName: trim {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    cashRetailPricing {
      purchasePrice {
        ...MonetaryAmountFragment
      }
      purchasePriceBelowMin
    }
    retailItemStatus: status ## This is a special case for retail because status is also used in tradeIn and causes errors when the two fragments are used in conjunction
    statusName
    vin
    stockNumber
    daysInStock
    completePercent
    leadsCount
    leadActivitiesCount
    conversationsCount
    testDrivesCount: leadActivitiesCount(type: TEST_DRIVE)
    type
    typeName
    motorcycleAttributes: attributes {
      ... on MotorcycleAttributes {
        mileage {
          formattedAmount
        }
        displacement {
          formattedAmount
        }
        cylinders
        fuelType
        fuelTypeName
      }
    }
    vehicleAttributes: attributes {
      ... on VehicleAttributes {
        exteriorColor
        exteriorColorName
        transmission
        transmissionName
        mileage {
          formattedAmount
        }
        displacement {
          formattedAmount
        }
        cylinders
        fuelType
        fuelTypeName
      }
    }
  }
  ${MultilingualString}
  ${monetaryAmount}
  ${listPhoto}
`;
