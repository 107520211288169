import { gql } from '@apollo/client';

import { contactDetail } from 'store/api/graph/fragments/contactDetail';

export const contactCreate = gql`
  mutation ContactCreate(
    $rooftopId: ID
    $type: ContactType
    $company: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $defaultAppraiser: Boolean
  ) {
    data: contactCreate(
      rooftopId: $rooftopId
      type: $type
      company: $company
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      defaultAppraiser: $defaultAppraiser
    ) {
      ...ContactDetailFragment
    }
  }

  ${contactDetail}
`;

export const contactModify = gql`
  mutation ContactModify(
    $id: ID!
    $archived: Boolean
    $company: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $defaultAppraiser: Boolean
    $_clear: [ContactModifyParameter!]
  ) {
    data: contactModify(
      id: $id
      archived: $archived
      company: $company
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      defaultAppraiser: $defaultAppraiser
      _clear: $_clear
    ) {
      ...ContactDetailFragment
    }
  }

  ${contactDetail}
`;
