import { gql } from '@apollo/client';

import { retailBulkAdjustmentDetail } from 'store/api/graph/fragments/retailBulkAdjustmentDetail';

export const retailBulkAdjustmentCreate = gql`
  mutation RetailBulkAdjustmentCreate(
    $rooftopId: ID
    $active: Boolean
    $ending: DateTime
    $filter: RetailItemConnectionFilterInput
    $name: String
    $overlayAttributes: OverlayRetailBulkAdjustmentAttributesInput
    $priceAttributes: PriceRetailBulkAdjustmentAttributesInput
    $descriptionAttributes: DescriptionRetailBulkAdjustmentAttributesInput
    $promotionAttributes: PromotionRetailBulkAdjustmentAttributesInput
    $disclaimerAttributes: DisclaimerRetailBulkAdjustmentAttributesInput
    $starting: DateTime
    $type: RetailBulkAdjustmentType
    $order: Int
    $_submit: Boolean! = true
  ) {
    data: retailBulkAdjustmentCreate(
      rooftopId: $rooftopId
      active: $active
      ending: $ending
      filter: $filter
      name: $name
      overlayAttributes: $overlayAttributes
      priceAttributes: $priceAttributes
      descriptionAttributes: $descriptionAttributes
      promotionAttributes: $promotionAttributes
      disclaimerAttributes: $disclaimerAttributes
      starting: $starting
      type: $type
      order: $order
      _submit: $_submit
    ) {
      ...RetailBulkAdjustmentDetailFragment
    }
  }
  ${retailBulkAdjustmentDetail}
`;

export const retailBulkAdjustmentModify = gql`
  mutation RetailBulkAdjustmentModify(
    $id: ID!
    $active: Boolean
    $ending: DateTime
    $name: String
    $overlayAttributes: OverlayRetailBulkAdjustmentAttributesInput
    $priceAttributes: PriceRetailBulkAdjustmentAttributesInput
    $promotionAttributes: PromotionRetailBulkAdjustmentAttributesInput
    $descriptionAttributes: DescriptionRetailBulkAdjustmentAttributesInput
    $disclaimerAttributes: DisclaimerRetailBulkAdjustmentAttributesInput
    $starting: DateTime
    $order: Int
    $filter: RetailItemConnectionFilterInput
    $_clear: [RetailBulkAdjustmentModifyParameter!]
  ) {
    data: retailBulkAdjustmentModify(
      id: $id
      active: $active
      ending: $ending
      name: $name
      overlayAttributes: $overlayAttributes
      priceAttributes: $priceAttributes
      descriptionAttributes: $descriptionAttributes
      promotionAttributes: $promotionAttributes
      disclaimerAttributes: $disclaimerAttributes
      starting: $starting
      order: $order
      filter: $filter
      _clear: $_clear
    ) {
      ...RetailBulkAdjustmentDetailFragment
    }
  }
  ${retailBulkAdjustmentDetail}
`;

export const retailBulkAdjustmentAffectVehicles = gql`
  query RetailBulkAdjustmentAffectedVehicles($filter: RetailItemConnectionFilterInput) {
    data: retailItemConnection(first: 0, filter: $filter) {
      pageInfo {
        totalEdges
      }
    }
  }
`;

export const retailBulkAdjustmentDelete = gql`
  mutation RetailBulkAdjustmentDelete($id: ID!) {
    data: retailBulkAdjustmentDelete(id: $id)
  }
`;
