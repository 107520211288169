import type { TypePolicies } from '@apollo/client';

// For more info see https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
export const typePolicies = {
  /**
   * Custom client side only TypePolicies.
   * See `customPossibleTypes.js`.
   */
  NonNormalizedMergedTypes: {
    merge: true,
  },
  NonNormalizedUnMergedTypes: {
    keyFields: false,
  },
  SingletonTypes: {
    keyFields: [],
  },
  /**
   * Non-unique types.
   * Provide a more specific key for data normalization to happen.
   */
  SelectStringOption: {
    keyFields: ['id', 'name'], // Specifically fixes infinite network request loops.
  },
  SelectIntOption: {
    keyFields: ['id', 'name'], // Specifically fixes infinite network request loops.
  },
  SelectMultilingualStringOption: {
    keyFields: ['id', 'name'],
  },
  Incentive: {
    keyFields: ['id', 'enabled'],
  },
} as TypePolicies;
