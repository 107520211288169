import { gql } from '@apollo/client';

export const conversationConnectionFilterFragment = gql`
  fragment ConversationConnectionFilterFragment on ConversationConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    archivedByMe
    assigned
    assignedToId
    assignedToMe
    leadId
    leadSourceId
    hasAttachments
    inventoryItemId
    participantId
    readByMe
    received
    replied
    tagId
    type
  }
`;
