import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import type { TrackingPhoneNumber } from 'store/api/graph/interfaces/types';
import { NEUTRAL_0, SPACE_200 } from 'styles/tokens';
import { PLACEHOLDER } from 'utils/formatUtils';

const Container = styled.div`
  background: ${NEUTRAL_0};
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${SPACE_200};
`;

/**
 * Render a list of tracking phone numbers
 */
const RooftopIntegrationPhoneNumbers = ({
  phoneNumbers,
}: {
  /** The list of tracking phone numbers */
  phoneNumbers: TrackingPhoneNumber[];
}) => (
  <Container>
    {phoneNumbers.length > 0 ? (
      phoneNumbers.map(trackingPhoneNumber => (
        <LabelContainer key={trackingPhoneNumber.phoneNumber}>
          <PrimaryText>{`${trackingPhoneNumber.deviceCategoryName} ${trackingPhoneNumber.departmentName}`}</PrimaryText>
          <SecondaryText>{trackingPhoneNumber.phoneNumber}</SecondaryText>
        </LabelContainer>
      ))
    ) : (
      <PrimaryText>{PLACEHOLDER}</PrimaryText>
    )}
  </Container>
);

export default RooftopIntegrationPhoneNumbers;
