import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getWhiteLabels } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { type Group, type GroupCreateMutationVariables, UserScope } from 'store/api/graph/interfaces/types';
import { getStepField, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';

import { GroupDetailsBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore<Group, { metadata: undefined }, GroupCreateMutationVariables> {
  constructor(
    props: StepComponentProps<Group, { metadata: undefined }, GroupCreateMutationVariables>,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: { activeStep },
    } = props;

    const {
      subContexts: {
        userContext: { user },
      },
    } = context;
    this.context = context;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [GroupDetailsBuilderFields.WHITE_LABEL_ID]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: user?.scope !== UserScope.GLOBAL,
        }),
      },
    });

    this.asyncConfigurations = {
      [GroupDetailsBuilderFields.WHITE_LABEL_ID]: {
        request: () => getWhiteLabels(),
        disableKeywordRefetch: true,
      },
    };
  }

  async save() {
    const {
      subContexts: {
        userContext: { user },
      },
    } = this.context!;

    const whiteLabelId =
      user?.scope === UserScope.WHITE_LABEL
        ? user?.whiteLabel?.id
        : getStepField(GroupDetailsBuilderFields.WHITE_LABEL_ID, this.fields).selectedValue?.id;

    return super.save({}, { whiteLabelId });
  }
}

export default DetailsStep;
