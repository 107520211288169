import { AutoPriceAdjustmentBaseFields } from 'components/sections/createModify/rooftopPricingSegments/interfaces';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';

export enum RooftopFinanceSegmentDetailsBuilderFields {
  FINANCE_CONFIGURATION_ENABLED = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.enabled`,
  FINANCE_CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.availablePaymentFrequencies`,
  FINANCE_CONFIGURATION_AVAILABLE_TERMS = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.availableTerms`,
  FINANCE_CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.defaultPaymentFrequency`,
  FINANCE_CONFIGURATION_DEFAULT_TERM = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.defaultTerm`,
  FINANCE_CONFIGURATION_STARTING_PRICE_FIELD = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.startingPriceField`,
  FINANCE_CONFIGURATION_INCLUDE_TAX = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.includeTax`,
  FINANCE_CONFIGURATION_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.disclaimerTemplate`,
  FINANCE_CONFIGURATION_INCLUDE_FREIGHT = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.includeFreight`,
  FINANCE_CONFIGURATION_MIN_DISPLAY_PRICE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.minDisplayPrice`,
  FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED = AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_ENABLED,
  FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_TARGET_PRICE}`,
  FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_LABEL}`,
  FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE}`,
  NAME = 'name',
  DESCRIPTION = 'description',
  ORDER = 'order',
  TARGETS = 'targets',
}

export enum RooftopFinanceSegmentRatesBuilderFields {
  FINANCE_CONFIGURATION_PRICING_SOURCE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.pricingSource`,
  FINANCE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.includeAfterTaxRebates`,
  FINANCE_CONFIGURATION_AVAILABLE_TERMS = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.availableTerms`,
  FINANCE_CONFIGURATION_DEFAULT_TERM = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.defaultTerm`,
  FINANCE_CONFIGURATION_INTEREST_RATE_TYPE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.interestRateType`,
  FINANCE_CONFIGURATION_DEFAULT_TERM_TYPE = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.defaultTermType`,
  FINANCE_CONFIGURATION_INTEREST_RATES = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}.interestRates`,
  FINANCE_CONFIGURATION_CLEAR = `${RooftopPricingSegmentConfigurationPointer.FINANCE_CONFIGURATION}._clear`,
}

export enum RooftopFinancePricingBuilderSteps {
  DETAILS = 'DETAILS',
  FILTERS = 'FILTERS',
  RATES = 'RATES',
}
