import type { ComponentPropsWithRef, ReactElement, RefObject } from 'react';
import { cloneElement, isValidElement, useEffect } from 'react';

interface Props {
  onClick: () => void;
  directionalKeys?: number;
  ignoredElements?: RefObject<HTMLElement>['current'][];
  stopPropagation?: boolean;
  children?: ReactElement;
}

/**
 * Component enhancer that provides click outside detection, and ESC key to trigger click.
 * Directional keys optional.
 */
const OutsideClick = ({ onClick, directionalKeys, ignoredElements = [], stopPropagation, children }: Props) => {
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    window.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  let wrapped: HTMLElement;

  const handleKeyDown = e => {
    const esc = 27; // ESC
    const directions = [37, 38, 39, 40]; // Left, up, right, down

    if (e.keyCode === esc || (directionalKeys && directions.includes(e.keyCode))) {
      onClick();
    }
  };

  const handleClickOutside = event => {
    const isIgnoredItem = ignoredElements.find(e => e && e.contains(event.target));
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (wrapped && !wrapped.contains(event.target) && !isIgnoredItem) {
      onClick();
    }
  };

  if (isValidElement(children)) {
    return cloneElement<ComponentPropsWithRef<any>>(children, {
      ref: (element: HTMLElement) => {
        wrapped = element;
      },
    });
  }

  return children || null;
};

export default OutsideClick;
