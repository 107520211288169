import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type Tier from 'components/core/createModify/interfaces/tier';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
  getTagOptions,
} from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import type {
  LeadCreateMutationVariables,
  LeadDetailsContainerQuery,
  LeadMetaQuery,
} from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { isFile } from 'utils/objUtils';

import { LeadDetailsBuilderFields } from './interfaces';

// Used to seed data from a main tier to a tag sub tier.
export const seedLeadStepData = (preFill: any, { data }: Tier) => ({
  firstName: preFill || '',
  rooftopName: data?.rooftopName || data?.rooftop || data?.rooftopId,
});

class DetailsStep extends StepComponentCore<
  LeadDetailsContainerQuery['item'],
  LeadMetaQuery,
  LeadCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<LeadDetailsContainerQuery['item'], LeadMetaQuery, LeadCreateMutationVariables>,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: { data, seededData, isCreating, activeStep, entityType },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const rooftopName = seededData?.rooftopName || seededData?.rooftopId || data.rooftopName || getUsersRooftop(user);

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [LeadDetailsBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftopName,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !canAccessMultipleRooftops,
          },
          {
            type: StepFieldDisplayType.DISABLED,
            active: !isCreating || !!seededData?.rooftopId || !!rooftopName,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isCreating,
          },
        ]),
      },
      [LeadDetailsBuilderFields.STATUS]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !!isCreating,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !!isCreating,
          },
        ]),
      },
      [LeadDetailsBuilderFields.LOCALE]: {
        selectedValue: data?.locale && { id: data.locale.languageTag, name: data.locale.displayName },
      },
      [LeadDetailsBuilderFields.TAG_IDS]: {
        selectedValue: data.tagName || [],
        // TODO [#2506]: Investigate tadIds disabled state
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !rooftopName && isWhiteLabelScoped,
        }),
      },
      [LeadDetailsBuilderFields.FIRST_NAME]: {
        selectedValue: seededData?.firstName || data.firstName,
      },
      [LeadDetailsBuilderFields.AVATAR_IMAGE]: {
        selectedValue: data.avatar?.url,
      },
      [LeadDetailsBuilderFields.SUBSCRIBED_EMAIL]: {
        selectedValue: isCreating ? true : data.subscribedEmail,
      },
      [LeadDetailsBuilderFields.SUBSCRIBED_PROMOTIONS]: {
        selectedValue: isCreating ? null : data.subscribedPromotions,
        displayType: [StepFieldDisplayType.DISABLED],
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      [LeadDetailsBuilderFields.ROOFTOP_ID]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId: seededData?.userGroupId,
                features: { lead: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.LEAD,
                    featureFlagOn: rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      [LeadDetailsBuilderFields.TAG_IDS]: {
        request: () =>
          getTagOptions({
            rooftopId: [getStepField(LeadDetailsBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id],
            entityType: entityType!,
          }),
        disableKeywordRefetch: true,
      },
    };
  }

  // TODO: Add LocationSelector as an option for ListSelection, would be good to aggregate this kind of substep
  async toggleSubPanel(stepField?: StepField) {
    this.setState({ childrenBeforeSubStep: null });
    await super.toggleSubPanel(stepField);
  }

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === LeadDetailsBuilderFields.ROOFTOP_ID) {
      removeDisplayType(getStepField(LeadDetailsBuilderFields.TAG_IDS, this.fields), StepFieldDisplayType.DISABLED);
    }
    super.onFieldSelection(stepField, value);
  }

  async save() {
    const {
      tier: { steps },
    } = this.props;

    const avatarFieldValue = getStepField(LeadDetailsBuilderFields.AVATAR_IMAGE, this.fields).selectedValue;

    const success = await super.save(
      {},
      // If the avatar is not a File, then send null, as the avatar image has not been changed
      {
        avatarImage: isFile(avatarFieldValue) ? avatarFieldValue : null,
      }
    );

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }
}

export default DetailsStep;
