import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import type { IntegrationWebsiteFragment } from 'store/api/graph/interfaces/types';

const Details = styled(ListItemDetails)`
  align-items: flex-start;
`;

type Props = IntegrationWebsiteFragment & ListItemProps;

const IntegrationWebsiteListItem = ({ id, primaryText, secondaryText }: Props) => (
  <Details key={id}>
    <PrimaryText>{primaryText.value}</PrimaryText>
    <Text>{secondaryText}</Text>
  </Details>
);

export default memo(IntegrationWebsiteListItem);
