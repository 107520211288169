import { createContext, type MutableRefObject } from 'react';

import type { Editor } from '@tiptap/react';

import type { MultilingualString } from 'store/api/graph/interfaces/types';
import { MultilingualStringValue } from 'utils/intlUtils';

export enum RichTextClientType {
  MOBILE = 'mobile',
  WEB = 'web',
}

export interface RichTextEditorContextInterface {
  /**
   * The current client type for the editor.
   * @type {RichTextClientType}
   */
  clientType: RichTextClientType;
  /**
   * A function to close the link dialog
   */
  closeEditLinkDialog: () => void;
  /**
   * A mutable reference object pointing to the HTMLDivElement that contains the editor content.
   * @type {MutableRefObject<HTMLDivElement | null>}
   */
  contentRef?: MutableRefObject<HTMLDivElement | null>;
  /**
   * The instance of the Tiptap Editor or null if the editor is not initialized.
   */
  editor: Editor | null;
  /**
   * A function to asynchronously generate text. It returns a Promise which resolves when
   * text generation is complete or undefined if the generation is not possible.
   */
  generateText: () => Promise<void> | undefined;
  /**
   * A function to cancel the ongoing text generation process.
   */
  generateTextCancel: () => void;
  /**
   * A function to get the current content value of the editor as a multilingual string.
   */
  getContent: () => MultilingualString | null;
  /**
   * A boolean indicating whether text is currently being generated.
   */
  isGeneratingText: boolean;
  /**
   * A boolean indicating whether the link dialog is currently open.
   */
  isLinkDialogOpen: boolean;
  /**
   * A boolean indicating whether the editor is in a loading state.
   */
  isLoading: boolean;
  /**
   * A boolean indicating whether the text generation button should be disabled.
   */
  isTextGenerationButtonDisabled: boolean;
  /**
   * A boolean indicating whether the text generation button should be visible.
   */
  isTextGenerationButtonVisible: boolean;
  /**
   * The current language of the editor content, represented as a multilingual string value.
   */
  language: MultilingualStringValue;
  /**
   * A function to open the link dialog
   */
  openEditLinkDialog: () => void;
  /**
   * A function to set the current client type for the editor
   * @param value - The new client type to be set in the editor.
   */
  setClientType: (value: RichTextClientType) => void;
  /**
   * A function to set the editor content to the given value.
   * @param value - The new content to be set in the editor.
   */
  setContent: (value: MultilingualString | null) => void;
  /**
   * A function to set the state of text generation.
   * @param value - A boolean indicating whether text generation is in progress.
   */
  setIsGeneratingText: (value: boolean) => void;
  /**
   * A function to set the loading state of the editor.
   * @param value - A boolean indicating whether the editor is loading.
   */
  setIsLoading: (value: boolean) => void;
  /**
   * A function to set the current language of the editor content.
   * @param value - The new language to be set, represented as a multilingual string value.
   */
  setLanguage: (value: MultilingualStringValue) => void;
  /**
   * An array of supported languages for the editor content.
   */
  supportedLanguages: MultilingualStringValue[];
}

export const RichTextEditorContext = createContext<RichTextEditorContextInterface>({
  clientType: RichTextClientType.WEB,
  closeEditLinkDialog: () => {},
  contentRef: { current: null },
  editor: null,
  generateText: () => Promise.resolve(),
  generateTextCancel: () => {},
  getContent: () => null,
  isGeneratingText: false,
  isLinkDialogOpen: false,
  isLoading: false,
  isTextGenerationButtonDisabled: false,
  isTextGenerationButtonVisible: false,
  language: MultilingualStringValue.EN,
  openEditLinkDialog: () => {},
  setClientType: () => {},
  setContent: () => {},
  setIsGeneratingText: () => {},
  setIsLoading: () => {},
  setLanguage: () => {},
  supportedLanguages: [],
});
