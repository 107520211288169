import type { ReactNode, UIEvent } from 'react';

import styled, { css } from 'styled-components/macro';

import SemiBoldText from 'components/core/typography/SemiBoldText';
import Button, { Buttons } from 'components/ui/shared/Button';
import { BODY_TEXT, BORDER_DEFAULT } from 'styles/color';
import { BORDER_RADIUS_200, NEUTRAL_0, SPACE_200 } from 'styles/tokens';
import { variants } from 'utils/styledUtils';

export enum ActionBarButtonSize {
  REGULAR = 'regular',
  DEFAULT = 'default',
}

const ActionButton = styled(Button)<{
  leftIcon?: boolean;
  rightIcon?: boolean;
  size?: ActionBarButtonSize;
  disabled?: boolean;
}>`
  padding: ${SPACE_200};
  color: ${BODY_TEXT};
  background: ${NEUTRAL_0};
  border-radius: ${BORDER_RADIUS_200};
  box-shadow: 0 0 0 1px ${BORDER_DEFAULT} inset;
  fill: ${BODY_TEXT};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `};

  & > ${SemiBoldText} {
    margin: 0 ${props => (props.rightIcon ? `10px` : `6px`)} 0 ${props => (props.leftIcon ? `10px` : `6px`)};
  }

  ${variants<ActionBarButtonSize>(
    'size',
    {
      [ActionBarButtonSize.REGULAR]: css`
        width: 95px;
        height: 34px;
      `,
      [ActionBarButtonSize.DEFAULT]: css``,
    },
    ActionBarButtonSize.DEFAULT
  )};
`;

export const ActionBarButtons = styled(Buttons)`
  height: 44px;
`;

interface Props {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  text?: string;
  onClick?: (e: UIEvent<HTMLElement>) => void;
  size?: ActionBarButtonSize;
  testId?: string;
  disabled?: boolean;
}

const ActionsBarButton = ({
  rightIcon = null,
  leftIcon = null,
  text = '',
  onClick = e => {},
  disabled = false,
  testId,
  ...props
}: Props) => {
  if (disabled) {
    onClick = e => {};
  }

  return (
    <ActionButton
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      {...props}
    >
      {leftIcon}
      <SemiBoldText>{text}</SemiBoldText>
      {rightIcon}
    </ActionButton>
  );
};

export default ActionsBarButton;
