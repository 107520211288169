import DynamicImport from 'components/core/DynamicImport';
import { TabType } from 'enums/tabType';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';
import { formatFullName } from 'utils/formatUtils';

export const leadDetailsTabs = [
  { tabId: TabType.DETAILS, component: DynamicImport(() => import('components/sections/leads/LeadDetailsTab')) },
  { tabId: TabType.FINANCE, component: DynamicImport(() => import('components/sections/leads/LeadFinanceTab')) },
  { tabId: TabType.LINKED, component: DynamicImport(() => import('components/sections/leads/LeadLinkedTab')) },
];

export const getLeadDetailsTitle = (item: LeadResponseType) => formatFullName(item || {}, '?');
