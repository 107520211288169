import styled, { css } from 'styled-components/macro';

import { LINE_HEIGHT_DEFAULT } from 'styles/typography';

import Text from './Text';

const sharedCss = css`
  white-space: pre-line;
  overflow: visible;
`;

export const multilineCss = css`
  overflow-wrap: break-word;
  text-overflow: unset;
  text-align: start;
  ${sharedCss}
`;

const MultiLineText = styled(Text)`
  line-height: ${LINE_HEIGHT_DEFAULT};
  ${sharedCss}
`;

export default MultiLineText;
