import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import {
  RetailItemBuilderSteps,
  RetailItemDetailsBuilderFields,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import {
  handleConfirmToggleFieldLock,
  initLockingField,
  isBulkAdjustmentStepEnabled,
  updateLockedFieldsSelectedValue,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/utils';
import type { RichTextInputSettings } from 'components/ui/forms/shared/RichTextInput';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import type {
  RetailItemGenerateDescriptionQuery,
  RetailItemGenerateDescriptionQueryVariables,
} from 'store/api/graph/interfaces/types';
import { RETAIL_ITEM_GENERATE_DESCRIPTION } from 'store/api/graph/queries/generate';
import { client } from 'store/apollo/ApolloClient';
import { LDContextKinds } from 'utils/featureFlagUtils';
import { defineFieldValues, objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

class DescriptionStep extends StepComponentCore {
  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { tierId, data, steps, activeStep, metadata },
    } = props;

    const {
      subContexts: { featureFlags, updateFeatureFlagContext },
      setTier,
    } = context;

    const { id, rooftop } = data;
    const isFieldLockingVisible = metadata?.isFieldLockingVisible;

    if (
      !isBulkAdjustmentStepEnabled({ rooftop, featureFlagRooftopPackageEnabled: featureFlags.rooftopPackageEnabled })
    ) {
      // Hide the bulk adjustment step if none of the retail payment options are available
      setTier(tierId, { steps: steps?.filter(step => step.id !== RetailItemBuilderSteps.BULK_ADJUSTMENTS) });
    }

    void updateFeatureFlagContext([
      { kind: LDContextKinds.rooftop, context: { key: rooftop.id, name: rooftop.name.en } },
    ]);

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
        selectedValue: data?.lockedFields || [],
      },
      description: {
        settings: {
          onGenerateText: async () => {
            const { data } = await client.query<
              RetailItemGenerateDescriptionQuery,
              RetailItemGenerateDescriptionQueryVariables
            >({
              query: RETAIL_ITEM_GENERATE_DESCRIPTION,
              variables: { id },
              fetchPolicy: ApolloFetchPolicy.NO_CACHE,
            });

            return data.retailItemGenerateDescription;
          },
        } as RichTextInputSettings,
      },
    }).map(stepField => initLockingField(stepField, data, isFieldLockingVisible));

    this.fields = defineFieldValues(this.fields, data);
  }

  onConfirmToggleFieldLock(queryVar, optionQueryVar) {
    handleConfirmToggleFieldLock({ fields: this.fields, queryVar, optionQueryVar });

    this.forceUpdate();

    updateLockedFieldsSelectedValue(this.fields);
  }
}

export default DescriptionStep;
