import { gql } from '@apollo/client';

import { avatar } from '../fragments/avatar';
import { selectStringOption } from '../fragments/selectOption';
import { user } from '../fragments/user';

export const USER_LOGIN_QUERY = gql`
  query UserLoginQuery {
    user {
      avatar {
        ...AvatarFragment
      }
      ...UserFragment
    }
    metadata {
      mutation {
        user {
          locale {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${user}
  ${avatar}
  ${selectStringOption}
`;
