import { memo } from 'react';

import styled from 'styled-components/macro';

import ChevronRightIcon from './ChevronRightIcon';

const ChevronLeftIcon = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`;

export default memo(ChevronLeftIcon);
