import styled, { css } from 'styled-components/macro';

import { BLUE_100, NEUTRAL_0, NEUTRAL_300, NEUTRAL_800 } from 'styles/tokens';
import {
  FONT_FAMILY,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_SIZE_28,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_SEMI_BOLD,
} from 'styles/typography';
import { Z_INDEX_1 } from 'styles/z-index';
import { hexToRGBA } from 'utils/styledUtils';

export const CONTENT_PADDING = 12;

export const GlobalStyle = styled.div`
  /** Base Editor Override */
  .ProseMirror:focus {
    outline: none;
  }

  p.is-editor-empty:first-child::before {
    color: ${NEUTRAL_300};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  /********************** */

  h1 {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_28};
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  h2 {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_20};
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  h3 {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_16};
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  h4 {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_14};
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  p {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_14};
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  }

  button {
    font-family: ${FONT_FAMILY};
    font-size: ${FONT_SIZE_14};
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  }

  p,
  ol,
  ul {
    &:first-child {
      margin-top: unset;
    }

    &:last-child {
      margin-bottom: unset;
    }
  }

  mark.highlighted-link {
    background-color: ${BLUE_100};
  }
`;

export const BaseDialogContainer = css`
  background-color: ${NEUTRAL_0};
  border-radius: 8px;
  box-shadow:
    0 0 1px 0 ${hexToRGBA(NEUTRAL_800, '0.24')},
    0 8px 16px 0 ${hexToRGBA(NEUTRAL_800, '0.15')};
  z-index: ${Z_INDEX_1};
`;
