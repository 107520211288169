import { gql } from '@apollo/client';

import { integrationWebsite, integrationWebsiteRoutes } from 'store/api/graph/fragments/integrationWebsite';

import { inventoryExportRooftopIntegrationAttributes } from './inventoryExportRooftopIntegrationAttributes';
import { oemAccountExportRooftopIntegrationAttributes } from './oemAccountExportRooftopIntegrationAttributes';
import { phoneCallTrackingRooftopIntegrationAttributes } from './phoneCallTrackingRooftopIntegrationAttributes';
import { rooftop } from './rooftop';

export const rooftopIntegrationDetails = gql`
  fragment RooftopIntegrationFragment on RooftopIntegration {
    active
    cppAttributes: attributes {
      ... on CppRooftopIntegrationAttributes {
        website {
          ...IntegrationWebsiteFragment
        }
        websiteRoutes {
          ...IntegrationWebsiteRoutesFragment
        }
        websitePageType
        websitePageTypeName
      }
    }
    oemAccountExportAttributes: attributes {
      ... on OemAccountExportRooftopIntegrationAttributes {
        ...OemAccountExportRooftopIntegrationAttributesFragment
      }
    }
    leadDistributionAttributes: attributes {
      ... on LeadDistributionRooftopIntegrationAttributes {
        website {
          ...IntegrationWebsiteFragment
        }
        thirdPartyId
      }
    }
    inventoryExportAttributes: attributes {
      ... on InventoryExportRooftopIntegrationAttributes {
        ...InventoryExportRooftopIntegrationAttributesFragment
      }
    }
    phoneCallTrackingAttributes: attributes {
      ... on PhoneCallTrackingRooftopIntegrationAttributes {
        ...PhoneCallTrackingRooftopIntegrationAttributesFragment
      }
    }
    integrationName: integration {
      id
      name: provider
    }
    integrationType: integration {
      id: type
      name: typeName
    }
    id
    importPhotos
    primary
    privacyPolicyUrl
    rooftopName: rooftop {
      ...RooftopFragment
    }
    runOnce
    thirdPartyRooftopId
    created
    updated
  }
  ${rooftop}
  ${integrationWebsiteRoutes}
  ${integrationWebsite}
  ${oemAccountExportRooftopIntegrationAttributes}
  ${inventoryExportRooftopIntegrationAttributes}
  ${phoneCallTrackingRooftopIntegrationAttributes}
`;
