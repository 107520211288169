import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { Badges } from 'components/ui/shared/Badge';
import type { LeadForwardResponseType } from 'store/api/graph/responses/responseTypes';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

interface Props extends LeadForwardResponseType, ListItemProps {}

const RooftopLeadForwardListItem = ({ email, adf, sourceNames, suffixIcon, listItemType }: Props) => {
  const { t } = translate;

  return (
    <ListItem suffixIcon={suffixIcon} listItemType={listItemType}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{email}</PrimaryText>
        </TextRow>
        <TextRow>
          <Badges>
            <Badge title={`${t('adf_tooltip')}: ${adf ? t('yes') : t('no')}`}>
              {t('adf')}{' '}
              {adf ? (
                <TrueIcon width={10} height={10} color={GREEN_500} css="margin-left: 1ch;" />
              ) : (
                <FalseIcon width={10} height={10} color={RED_500} css="margin-left: 1ch;" />
              )}
            </Badge>
            <Badge title={t('sources_x', [sourceNames.length])}>{t('sources_x', [sourceNames.length])}</Badge>
          </Badges>
        </TextRow>
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(RooftopLeadForwardListItem);
