import type { MouseEventHandler } from 'react';
import { useCallback, useState } from 'react';

import PromptDialog from 'components/ui/dialogs/PromptDialog';
import EditorIconButton from 'components/ui/editor/components/shared/EditorIconButton';
import SparkleIcon from 'components/ui/icons/SparkleIcon';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const GenerateDescription = () => {
  const { clientType, editor, isTextGenerationButtonDisabled, generateText, setIsGeneratingText } = useRichTextEditor();
  const isMobileClientType = clientType === RichTextClientType.MOBILE;

  const [showOverridePrompt, setShowOverridePrompt] = useState<boolean>(false);

  /**
   * Handles generated text callback
   */
  const handleOverrideWithGeneratedText = useCallback(async () => {
    setShowOverridePrompt(false);
    setIsGeneratingText(true);

    void generateText()?.finally(() => {
      setIsGeneratingText(false);
    });
  }, [generateText, setIsGeneratingText]);

  /**
   * Handles generate text button click event
   */
  const handleGenerateTextButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (editor?.isEmpty) {
      void handleOverrideWithGeneratedText();
    } else {
      setShowOverridePrompt(true);
    }
  }, [editor?.isEmpty, handleOverrideWithGeneratedText]);

  return (
    <>
      <EditorIconButton
        data-testid={ElementTestId.EDITOR_BUTTON_GENERATE_DESCRIPTION}
        disabled={isTextGenerationButtonDisabled}
        icon={SparkleIcon}
        onClick={handleGenerateTextButtonClick}
        tooltip={isMobileClientType ? undefined : { children: t('generate_description') }}
      />
      <PromptDialog
        isOpen={showOverridePrompt}
        title={t('generate_description')}
        message={t('generate_description_message')}
        onConfirm={handleOverrideWithGeneratedText}
        onCancel={() => setShowOverridePrompt(false)}
        onComplete={() => setShowOverridePrompt(false)}
        confirmText={t('generate')}
      />
    </>
  );
};

export default GenerateDescription;
