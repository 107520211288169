import { PureComponent } from 'react';

import ReactDOM from 'react-dom';

/**
 * The core class that handles async forceUpdate and setState calls and allows it to silently be disregarded
 */
class BaseClass<Props, State> extends PureComponent<Props, State> {
  /** Whether or not the component is currently mounted */
  _isMounted = false;

  /** IMPORTANT: whenever we override this, need to call super.componentDidMount() in the overriding class */
  componentDidMount() {
    this._isMounted = true;
  }

  /** IMPORTANT: whenever we override this, need to call super.componentWillUnmount() in the overriding class */
  componentWillUnmount() {
    this._isMounted = false;
  }

  forceUpdate() {
    if (this._isMounted) {
      super.forceUpdate();
    }
  }

  setState(state: any, callback?: () => void) {
    if (this._isMounted) {
      super.setState(state, callback);
    }
  }

  setStateSynchronous(state: any, callback?: () => void) {
    if (this._isMounted) {
      ReactDOM.flushSync(() => {
        super.setState(state, callback);
      });
    }
  }
}

export default BaseClass;
