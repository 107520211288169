import type { ReactNode } from 'react';

import { PopoverContext } from 'contexts/PopoverContext';
import type { PopoverOptions } from 'hooks/interfaces';
import usePopover from 'hooks/usePopover';

interface PopoverProps extends PopoverOptions {
  children: ReactNode;
}

/**
 * A reusable popover component designed to be used with child components:
 * PopoverTrigger, PopoverContent, PopoverHeading and PopoverDescription.
 * Each child component will consume the context provider supplied by this component.
 */
const Popover = ({ children, ...props }: PopoverProps) => {
  /*
   * This can accept any props as options, e.g. `placement`,
   * or other positioning options.
   */
  const popover = usePopover(props);
  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

export default Popover;
