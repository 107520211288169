import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import EmailIcon from 'components/ui/icons/EmailIcon';
import SMSIcon from 'components/ui/icons/SMSIcon';
import { StepFieldType } from 'enums/stepFieldType';
import { ConversationType, MailboxModifyParameter } from 'store/api/graph/interfaces/types';

import { MailboxBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [MailboxBuilderFields.CONVERSATION_TYPE]: {
    groupType: StepFieldType.TYPES,
    settings: {
      types: [
        {
          id: ConversationType.EMAIL,
          icon: EmailIcon,
          label: 'email_one',
        },
        {
          id: ConversationType.SMS,
          icon: SMSIcon,
          label: 'sms',
        },
      ],
      customStyles: css`
        svg {
          width: 28px;
          height: 28px;
        }
      `,
    },
  },
  [MailboxBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
  },
  [MailboxBuilderFields.AREA_CODE]: {
    label: 'area_code',
    hasSeparator: true,
    groupType: StepFieldType.NUMBER,
  },
  [MailboxBuilderFields.EMAIL_LOCAL_PART]: {
    label: 'email_local_part',
    hasSeparator: true,
    tooltipContents: 'email_local_part_tooltip',
  },
  [MailboxBuilderFields.SOURCE_ID]: {
    label: 'lead_source',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
  },
  [MailboxBuilderFields.NAME]: {
    label: 'name',
    clear: { field: MailboxModifyParameter._name },
  },
  [MailboxBuilderFields.DESCRIPTION]: {
    label: 'description',
    clear: { field: MailboxModifyParameter._description },
    groupType: StepFieldType.TEXT_AREA,
    containerInnerStyles: css`
      textarea {
        height: 162px;
      }
    `,
    hasSeparator: true,
  },
};

export default DetailFields;
