import { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components/macro';

import DynamicImport from 'components/core/DynamicImport';
import NoConnection from 'components/core/errors/NoConnection';
import GlobalNav from 'components/core/navigation/GlobalNav';
import PhotoPreviewsContainer from 'containers/photoPreviewsContainer/PhotoPreviewsContainer';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useGlobalDialog } from 'hooks/contexts/useGlobalDialog';
import { DIVIDER } from 'styles/color';
import { BREAKPOINTS } from 'styles/layouts';
import { impersonationManager } from 'utils/impersonationUtils';

import PromptDialog from '../dialogs/PromptDialog';

const CreateModifyContainer = DynamicImport(() => import('containers/createModify/CreateModifyContainer'), false);
const SubscribedNotifications = DynamicImport(
  () => import('components/sections/notifications/SubscribedNotifications'),
  false
);

export const CoreLayoutContainer = styled.div<{ isBannerVisible: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${({ isBannerVisible }) =>
    /**
     * 40px is the height of the `Logged In As` banner, while the banner is visible
     * we need to account for it's height here to prevent the layout shifting off screen
     */
    isBannerVisible ? `calc(100vh - 40px)` : '100vh'};
  width: 100%;
  min-width: ${BREAKPOINTS.LARGE};
  background: ${DIVIDER};
`;

export const CoreLayoutContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  > * {
    flex-shrink: 0;
  }
`;

interface Props {
  component: any;
}

const CoreLayout = ({ component: Component, ...props }: Props) => {
  const [isConnected, setIsConnected] = useState(window.navigator.onLine);
  const { config, setConfig } = useGlobalDialog();
  const { builderConfig } = useBuilderConfig();

  const connectionCallback = useCallback(event => setIsConnected(event.type === 'online'), [setIsConnected]);
  const onDialogComplete = useCallback(
    success => {
      setConfig();
      void config?.onComplete?.(success);
    },
    [config, setConfig]
  );

  useEffect(() => {
    window.addEventListener('online', connectionCallback);
    window.addEventListener('offline', connectionCallback);
    return () => {
      window.removeEventListener('online', connectionCallback);
      window.removeEventListener('offline', connectionCallback);
    };
  }, [connectionCallback]);

  return isConnected ? (
    <>
      <CoreLayoutContainer isBannerVisible={impersonationManager.isCurrentlyImpersonating}>
        <GlobalNav />
        <CoreLayoutContentContainer>
          <Component {...props} />
        </CoreLayoutContentContainer>
        <CreateModifyContainer builderConfig={builderConfig} />
        <PhotoPreviewsContainer />
      </CoreLayoutContainer>
      {/* 
      TODO: This is a temporary implementation, remove either comment or entire implementation when done properly 
      */}
      <SubscribedNotifications />
      <PromptDialog {...config} isOpen={!!config} onComplete={onDialogComplete} />
    </>
  ) : (
    <NoConnection />
  );
};

export default CoreLayout;
