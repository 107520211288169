import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type {
  ApolloClient,
  DocumentNode,
  ObservableSubscription,
  OperationVariables,
  TypedDocumentNode,
  WatchQueryOptions,
} from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client/cache';
import { print } from 'graphql/language/printer';
import { debounce, isEqual } from 'lodash-es';

import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { getJSDate } from 'utils/dateUtils';

import { useMountEffect } from './useMountEffect';
import { usePrevious } from './usePrevious';

/**
 * Config that patches into Apollo client
 */
export interface QueryConfig<TData = any, TVariables = any, TModifiedData = TData> {
  /** Variables conforms to Apollo variables config */
  variables?: TVariables;
  /** Options conforms to Apollo options config */
  options?: Omit<WatchQueryOptions<OperationVariables, TData>, 'query'> | null;
  /** Ignore internal flag that determines whether or not to make a fetch data call */
  ignore?: boolean;
  /** QueryAdapter custom adapter method that transforms the query response as necessary */
  queryAdapter?: (data: TData) => TModifiedData;
}

/**
 * An Apollo client.query wrapper that provides the option to use defaults and such
 * @param query The query string that is being called
 * @param config The configuration object used by ApolloClient, made up of `variables: object, options: object`
 * @param defaults The default data values, merges with `data` when there is data
 */
export const useQuery = <TData = any, TVariables = any, TModifiedData = TData>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  /**
   * Config that patches into Apollo client
   * @var variables conforms to Apollo variables config
   * @var options conforms to Apollo options config
   * @var ignore internal flag that determines whether or not to make a fetch data call
   * @var queryAdapter custom adapter method that transforms the query response as necessary
   */
  config?: QueryConfig<TData, TVariables, TModifiedData>,
  defaults?: TData
): CustomQueryResult<TData, TVariables> => {
  const queryVars = useRef<TVariables>();
  const queryOpts = useRef(config?.options || {});
  const queryNode = useMemo(() => query, [query]);
  const queryAdapter = useRef(config?.queryAdapter);
  const queryString = useMemo(() => print(query), [query]);
  const queryStringPrev = usePrevious(queryString);

  const apolloClient = useRef<ApolloClient<NormalizedCacheObject>>(client);
  const apolloClientObservable = useRef<ObservableSubscription>();

  const [response, setResponse] = useState<CustomQueryResult<TData, TVariables>>({
    error: undefined,
    isLoaded: false,
    isLoading: true,
    loadedDate: undefined,
    data: defaults || ({} as TData),
    refetch: () => {},
    queryString: '',
    queryVars: {} as TVariables,
  });

  // When using debounce in useCallback linter complains about unknown dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(res => {
      setResponse({
        ...response,
        queryVars: queryVars.current,
        isLoaded: true,
        data: { ...defaults, ...(queryAdapter.current?.(res.data) || res.data) },
        loadedDate: getJSDate()?.getTime(),
        isLoading: false,
        error: undefined,
      });
    }),
    [defaults]
  );

  type QueryConfigInternal = QueryConfig<TData, TVariables, TModifiedData>;

  const fetchData = useCallback(
    (
      vars?: QueryConfigInternal['variables'],
      opts?: QueryConfigInternal['options'],
      adapter?: QueryConfigInternal['queryAdapter']
    ) => {
      queryOpts.current = opts || queryOpts.current;
      queryVars.current = vars || queryVars.current;
      queryAdapter.current = adapter || queryAdapter.current;

      setResponse({ ...response, isLoading: true });
      /**
       * NOTE: Force an unsubscribe from any active observable; although this
       * should be handled when resubscribing with `watchQuery`, we've run
       * into memory leaks in the past.
       */
      apolloClientObservable.current?.unsubscribe();
      apolloClientObservable.current = apolloClient.current
        .watchQuery({
          query: queryNode,
          variables: queryVars.current!,
          fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
          ...queryOpts.current,
        })
        .subscribe({
          next: res => {
            debouncedUpdate(res);
          },
          error: err => {
            setResponse({ ...response, isLoading: false, error: err });
            logApiError(err);
          },
        });
    },
    [debouncedUpdate, queryNode, response]
  );

  useMountEffect(
    () =>
      // Unsubscribe from the active observable on unMount
      () =>
        apolloClientObservable.current?.unsubscribe()
  );

  useEffect(() => {
    if ((!isEqual(queryVars.current, config?.variables) || !isEqual(queryString, queryStringPrev)) && !config?.ignore) {
      fetchData(config?.variables, config?.options, config?.queryAdapter);
    }
  }, [config, config?.ignore, config?.options, config?.variables, fetchData, queryString, queryStringPrev]);

  return {
    ...response,
    isLoading: queryString !== queryStringPrev || response.isLoading,
    queryString,
    refetch: fetchData,
  };
};
