import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const showroom = gql`
  fragment ShowroomFragment on ShowroomConfiguration {
    disclaimerTemplate {
      ...MultilingualStringFragment
    }
  }
  ${MultilingualString}
`;
