import { gql } from '@apollo/client';

import { group } from 'store/api/graph/fragments/group';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const groupCreate = gql`
  mutation GroupCreateMutation($whiteLabelId: ID, $name: MultilingualStringInput) {
    data: groupCreate(whiteLabelId: $whiteLabelId, name: $name) {
      ...GroupFragment
      rooftops {
        id
        name {
          ...MultilingualStringFragment
        }
      }
    }
  }
  ${group}
  ${MultilingualString}
`;
