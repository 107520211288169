import { useCallback, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import type Step from 'components/core/createModify/interfaces/step';
import { LeadActivityBuilderStep } from 'components/sections/createModify/leadActivities/steps/interfaces';
import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import FilterableListDialog from 'components/ui/dialogs/FilterableListDialog';
import AddIcon from 'components/ui/icons/AddIcon';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { useRouter } from 'hooks/useRouter';
import { EntityType, LeadActivityStatus, UserScope } from 'store/api/graph/interfaces/types';
import type { LeadActivityResponseType } from 'store/api/graph/responses/responseTypes';
import { GREEN_500, NEUTRAL_0 } from 'styles/tokens';
import { isFeatureBuilderEnabledForUser } from 'utils/featureBundleUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';
import { fullGroupOrAboveScopes } from 'utils/permissionUtils';

import { NavActionItem } from '../shared/CommonNavItems';

/**
 * Dialog that displays a list of entities that users can easily add.
 * e.g. Add Vehicle
 */
const AddEntityDialog = (props: BaseDialogProps) => {
  const { t } = translate;
  const userContext = useUser();
  const builderConfigContext = useBuilderConfig();
  const { isWhiteLabelScoped, hasPermissions, user } = userContext;
  const { toggleTier } = useCreateModify();
  const router = useRouter();
  const flags = useFlags();

  // Dynamic lead redirect based on permissions and leadStatus
  const leadRedirectCallback = useCallback(
    async (step: Step, data: Record<string, any>) => {
      if (step.id !== LeadActivityBuilderStep.DETAILS) {
        return;
      }
      const leadActivityData = data as LeadActivityResponseType;
      const isLeadsRoute = isWhiteLabelScoped || leadActivityData?.status === LeadActivityStatus.PROCESSED;
      const redirectBaseUrl = isLeadsRoute ? RoutePath.LEADS : RoutePath.LEAD_ACTIVITIES;
      const searchParams = router.location.pathname.includes(redirectBaseUrl) ? router.location.search : '';
      const id = isLeadsRoute ? leadActivityData?.leadName?.id : leadActivityData?.id;
      router.push(`${redirectBaseUrl}/${id}${searchParams}`);
    },
    [isWhiteLabelScoped, router]
  );

  const entityList = [
    {
      id: BuilderType.RETAIL_ITEMS_CREATE,
      label: t('create_retail_item'),
      testId: ElementTestId.NAV_ENTITY_CREATE_RETAIL_ITEM,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.RETAIL_ITEMS_CREATE,
          entityType: EntityType.RETAIL_ITEM,
          title: t('create_retail_item'),
          isCreating: true,
          redirectBaseUrl: RoutePath.RETAIL_ITEMS,
        });
      },
      restricted: !isFeatureBuilderEnabledForUser({
        builderConfigContext,
        userContext,
        builderType: BuilderType.RETAIL_ITEMS_CREATE,
        feature: FeatureBundleSet.RETAIL,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      }),
    },
    {
      id: BuilderType.TRADE_IN_ITEMS_CREATE,
      label: t('create_trade_in'),
      testId: ElementTestId.NAV_ENTITY_CREATE_TRADE_IN,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.TRADE_IN_ITEMS_CREATE,
          entityType: EntityType.TRADE_IN_ITEM,
          title: t('create_trade_in'),
          isCreating: true,
          redirectBaseUrl: RoutePath.TRADE_IN_ITEMS,
        });
      },
      restricted: !isFeatureBuilderEnabledForUser({
        builderConfigContext,
        userContext,
        builderType: BuilderType.TRADE_IN_ITEMS_CREATE,
        feature: FeatureBundleSet.TRADE_IN,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      }),
    },
    {
      id: BuilderType.LEAD_ACTIVITY_CREATE,
      label: t('create_lead_log_activity'),
      testId: ElementTestId.NAV_ENTITY_LOG_LEAD_ACTIVITY,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.LEAD_ACTIVITY_CREATE,
          entityType: EntityType.LEAD_ACTIVITY,
          title: t('log_lead_activity'),
          isCreating: true,
          onStepSave: leadRedirectCallback,
        });
      },
      restricted: !isFeatureBuilderEnabledForUser({
        builderConfigContext,
        userContext,
        builderType: BuilderType.LEAD_ACTIVITY_CREATE,
        feature: FeatureBundleSet.LEAD,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      }),
    },
    {
      id: BuilderType.TASK_CREATE,
      label: t('create_task'),
      testId: ElementTestId.NAV_ENTITY_CREATE_TASK,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.TASK_CREATE,
          entityType: EntityType.TASK,
          title: t('create_task'),
          isCreating: true,
          redirectBaseUrl: RoutePath.TASKS,
        });
      },
      restricted: !isFeatureBuilderEnabledForUser({
        builderConfigContext,
        userContext,
        builderType: BuilderType.TASK_CREATE,
        feature: FeatureBundleSet.TASK,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      }),
    },
    {
      id: BuilderType.APPOINTMENT_CREATE,
      label: t('create_appointment'),
      testId: ElementTestId.NAV_ENTITY_CREATE_APPOINTMENT,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.APPOINTMENT_CREATE,
          entityType: EntityType.APPOINTMENT,
          title: t('create_appointment'),
          isCreating: true,
          redirectBaseUrl: RoutePath.APPOINTMENTS,
        });
      },
      restricted: !isFeatureBuilderEnabledForUser({
        builderConfigContext,
        userContext,
        builderType: BuilderType.APPOINTMENT_CREATE,
        feature: FeatureBundleSet.APPOINTMENT,
        featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
      }),
    },
    {
      id: BuilderType.ROOFTOPS_CREATE,
      label: t('create_rooftop'),
      testId: ElementTestId.NAV_ENTITY_CREATE_ROOFTOP,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.ROOFTOPS_CREATE,
          entityType: CustomEntity.ROOFTOP,
          title: t('create_rooftop'),
          isCreating: true,
          redirectBaseUrl: RoutePath.ROOFTOPS,
        });
      },
      restricted:
        !hasPermissions(builderConfigContext.builderConfig[BuilderType.ROOFTOPS_CREATE].requiredPermissions) ||
        // Partial group users are not able to create new rooftops
        user.scope === UserScope.PARTIAL_GROUP,
    },
    {
      id: BuilderType.USER_CREATE,
      label: t('create_user'),
      testId: ElementTestId.NAV_ENTITY_CREATE_USER,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.USER_CREATE,
          entityType: CustomEntity.USER,
          title: t('create_user'),
          isCreating: true,
          redirectBaseUrl: RoutePath.USERS,
        });
      },
      restricted: !hasPermissions(builderConfigContext.builderConfig[BuilderType.USER_CREATE].requiredPermissions),
    },
    {
      id: BuilderType.WEBSITE_CREATE,
      label: t('create_website'),
      testId: ElementTestId.NAV_ENTITY_CREATE_WEBSITE,
      onClick: () => {
        const { onCancel } = props;
        onCancel?.();
        toggleTier(CreateModifyTiers.TIER_0, {
          tierId: CreateModifyTiers.TIER_0,
          type: BuilderType.WEBSITE_CREATE,
          entityType: EntityType.WEBSITE,
          title: t('create_website'),
          isCreating: true,
          redirectBaseUrl: RoutePath.WEBSITES,
        });
      },
      restricted:
        !hasPermissions(builderConfigContext.builderConfig[BuilderType.WEBSITE_CREATE].requiredPermissions) ||
        !fullGroupOrAboveScopes.includes(user.scope),
    },
  ].filter(item => !item.restricted);

  return (
    <FilterableListDialog
      listItems={entityList}
      searchPlaceholderText={t('search_actions')}
      headerTitle={t('actions')}
      isFixedToTop
      {...props}
    />
  );
};

/**
 * NavItem that displays an AddEntityDialog
 */
const AddEntityNavComponent = () => {
  const [isAddEntityDialogOpen, setIsAddEntityDialogOpen] = useState<boolean>(false);
  return (
    <>
      <NavActionItem
        data-testid={ElementTestId.GLOBAL_NAV_ACTION_BUTTON}
        style={{ background: GREEN_500 }}
        onClick={useCallback(() => setIsAddEntityDialogOpen(true), [])}
      >
        <AddIcon color={NEUTRAL_0} />
      </NavActionItem>
      <AddEntityDialog
        isOpen={isAddEntityDialogOpen}
        onCancel={useCallback(() => setIsAddEntityDialogOpen(false), [])}
      />
    </>
  );
};

export default AddEntityNavComponent;
