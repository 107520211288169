import { gql } from '@apollo/client';

import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';

import { MultilingualString } from './multilingualString';

export const rooftop = gql`
  fragment RooftopFragment on Rooftop {
    id
    name {
      ...MultilingualStringFragment
    }
    group {
      id
    }
    ## For Feature Bundles
    bundle {
      ...RooftopFeatureBundle
    }
  }
  ${MultilingualString}
  ${rooftopFeatureBundle}
`;
