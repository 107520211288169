export enum StepFieldType {
  // Cannot use 'button' as an enum value, causes StepField element to receive (type=button), which breaks styling
  FIELD_BUTTON = 'fieldButton',
  COLOR = 'color',
  CURRENCY = 'currency',
  DISPLACEMENT = 'displacement',
  DROPDOWN = 'dropdown',
  PREFIXED_INPUT = 'PrefixedInput',
  RENDER_OBJECT = 'renderObject',
  MASKED_INPUT = 'maskedInput',
  MILEAGE = 'mileage',
  MULTI_FIELD = 'multiField',
  MULTILINGUAL_FIELD = 'multiLingualField',
  MULTILINGUAL_TOGGLE_FIELD = 'multilingualToggleField',
  TAGS = 'tags',
  TOGGLE = 'toggle',
  TOGGLE_SELECT = 'toggleSelect',
  BADGES = 'badges',
  FLAGS = 'flags',
  DATE = 'date',
  TIME = 'time',
  TEXT_AREA = 'textArea',
  TYPES = 'types',
  RADIO = 'radio',
  RANGE = 'range',
  SLIDER = 'slider',
  SWITCH = 'switch',
  CUSTOM = 'custom',
  PHOTO = 'photo',
  NUMBER = 'number',
  PHONE_NUMBER = 'phoneNumber',
  PERCENTAGE = 'percentage',
  PLAIN_TEXT = 'plainText',
  RICH_TEXT = 'richText',
  ACTION_INPUT = 'actionInput',
  COUNTER = 'counter',
}

// Field types where clicking on the currently selected option will clear the fields value
export const DeselectableFieldTypes = [StepFieldType.DROPDOWN, StepFieldType.COLOR];
