import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import Content from 'components/ui/editor/components/core/Content';
import RichTextEditorContainer from 'components/ui/editor/containers/RichTextEditorContainer';
import RichTextMenuBar from 'components/ui/editor/RichTextMenuBar';
import type { RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';

type Props = Omit<ComponentProps<typeof RichTextEditorContainer>, 'children'>;

const RichTextEditor = forwardRef<RichTextEditorContextInterface, Props>((props, ref) => (
  <RichTextEditorContainer ref={ref} {...props}>
    <RichTextMenuBar />
    <Content />
  </RichTextEditorContainer>
));

export default RichTextEditor;
