import { get, isNil } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type StepFieldSuffixIconAttributes from 'components/core/createModify/interfaces/stepFieldSuffixIconAttributes';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import LoggingService from 'components/core/logging/LoggingService';
import { retailItemModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import { RetailItemDetailsBuilderFields } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import {
  handleConfirmToggleFieldLock,
  initLockingField,
  updateLockedFieldsSelectedValue,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/utils';
import { RetailItemPricingInfoBuilderFields } from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/interfaces';
import { PromptDialogIcon } from 'components/ui/dialogs/PromptDialog';
import LinkIcon from 'components/ui/icons/LinkIcon';
import LinkUnsetIcon from 'components/ui/icons/LinkUnsetIcon';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import type { RetailItem } from 'store/api/graph/interfaces/types';
import { RED_500 } from 'styles/tokens';
import {
  addDisplayType,
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import type { InventoryPaymentOption } from 'utils/formatting/pricingUtils';
import {
  getPaymentOptionConfigurationOverride,
  getPricingConfigurationEnabledPath,
  getPricingConfigurationModifyParameter,
} from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * Helper util that will determine which field needs a separator. The last toggle in the group needs to have a
 * bottom separator, however the Cash, Finance, Lease toggles are dynamically hidden or shown, so this util determines
 * which toggle comes last.
 *
 * @param field - the toggle field being tested to see if it needs a bottom separator
 * @param isCashToggleHidden - whether the cash toggle is currently hidden (true = it is hidden)
 * @param isFinanceToggleHidden - whether the finance toggle is currently hidden (true = it is hidden)
 * @param isLeaseToggleHidden - whether the lease toggle is currently hidden (true = it is hidden)
 */
const doesToggleFieldHaveSeparator = (
  field: RetailItemPricingInfoBuilderFields,
  isCashToggleHidden: boolean,
  isFinanceToggleHidden: boolean,
  isLeaseToggleHidden: boolean
): boolean => {
  switch (field) {
    case RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED: {
      return !isCashToggleHidden && isFinanceToggleHidden && isLeaseToggleHidden;
    }

    case RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED: {
      return !isFinanceToggleHidden && isLeaseToggleHidden;
    }

    case RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED: {
      return !isLeaseToggleHidden;
    }

    default: {
      return false;
    }
  }
};

class RetailItemPricingInfoStep extends StepComponentCore {
  // The config used to show a link icon, which indicates a toggle field is not using an overridden value
  linkIconConfig: StepFieldSuffixIconAttributes = {
    icon: <LinkIcon width={16} height={16} />,
  };
  // The config used to show a broken red icon, which indicates a toggle field is using an overridden value
  unlinkIconConfig: StepFieldSuffixIconAttributes = {
    icon: <LinkUnsetIcon width={16} height={16} color={RED_500} />,
  };

  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, activeStep, metadata },
    } = props;

    const isFieldLockingVisible = metadata?.isFieldLockingVisible;

    const isCashEnabled = get(data, InventoryItem.CASH_CONFIGURATION_ENABLED);
    const isFinanceEnabled = get(data, InventoryItem.FINANCE_CONFIGURATION_ENABLED);
    const isLeaseEnabled = get(data, InventoryItem.LEASE_CONFIGURATION_ENABLED);

    const isCashToggleFieldHidden = isNil(isCashEnabled);
    const isFinanceToggleFieldHidden = isNil(isFinanceEnabled);
    const isLeaseToggleFieldHidden = isNil(isLeaseEnabled);

    const isCashOverridden = !isNil(get(data, InventoryItem.CASH_CONFIGURATION_OVERRIDE_ENABLED));
    const isFinanceOverridden = !isNil(get(data, InventoryItem.FINANCE_CONFIGURATION_OVERRIDE_ENABLED));
    const isLeaseOverridden = !isNil(get(data, InventoryItem.LEASE_CONFIGURATION_OVERRIDE_ENABLED));

    const fields = objectToStepFieldArray(activeStep?.fields, {
      [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
        selectedValue: data?.lockedFields || [],
      },
      [RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS]: {
        selectedValue: data?.[InventoryItemAttributesPointer.VEHICLE]?.lockedFields || [],
      },
      [RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED]: {
        selectedValue: isCashEnabled,
        suffixIcon: isCashOverridden ? this.unlinkIconConfig : this.linkIconConfig,
        hasSeparator: doesToggleFieldHaveSeparator(
          RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED,
          isCashToggleFieldHidden,
          isFinanceToggleFieldHidden,
          isLeaseToggleFieldHidden
        ),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCashToggleFieldHidden,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED]: {
        selectedValue: isFinanceEnabled,
        suffixIcon: isFinanceOverridden ? this.unlinkIconConfig : this.linkIconConfig,
        hasSeparator: doesToggleFieldHaveSeparator(
          RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED,
          isCashToggleFieldHidden,
          isFinanceToggleFieldHidden,
          isLeaseToggleFieldHidden
        ),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isFinanceToggleFieldHidden,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED]: {
        selectedValue: isLeaseEnabled,
        suffixIcon: isLeaseOverridden ? this.unlinkIconConfig : this.linkIconConfig,
        hasSeparator: doesToggleFieldHaveSeparator(
          RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED,
          isCashToggleFieldHidden,
          isFinanceToggleFieldHidden,
          isLeaseToggleFieldHidden
        ),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isLeaseToggleFieldHidden,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPricingInfoBuilderFields.REVERT_CONFIGURATION]: {
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            // If the cash, finance, and lease toggle are all hidden, then there is no configuration to revert
            active: !isCashOverridden && !isFinanceOverridden && !isLeaseOverridden,
          },
        ]),
      },
    }).map(stepField => initLockingField(stepField, data, isFieldLockingVisible));

    this.fields = defineFieldValues(fields, data);
  }

  onConfirmToggleFieldLock(queryVar, optionQueryVar) {
    handleConfirmToggleFieldLock({ fields: this.fields, queryVar, optionQueryVar });

    this.forceUpdate();

    updateLockedFieldsSelectedValue(this.fields);
  }

  /*
   * If configuration overrides were successfully reverted, then update the suffix icons for the fields that were
   * included in the revert, and hide the revert configuration button (as its no longer needed)
   */
  successfullyRevertedPaymentConfigurationOverrides(paymentToggleFields: StepField[], result: RetailItem) {
    for (const field of paymentToggleFields) {
      // Update suffix icons to show toggle fields are now longer using overridden values
      field.suffixIcon = this.linkIconConfig;
      // Set the toggle state to the original segment configuration values
      field.selectedValue = get(
        result,
        getPricingConfigurationEnabledPath(field.queryAlias![0] as InventoryPaymentOption)
      );
    }

    // Hide the 'Revert to Segment Values' button
    addDisplayType(
      getStepField(RetailItemPricingInfoBuilderFields.REVERT_CONFIGURATION, this.fields),
      StepFieldDisplayType.HIDDEN
    );

    this.setTier({ data: result });
    this.forceUpdate();
  }

  async revertPaymentConfigurationOverrides() {
    const {
      tier: { data },
    } = this.props;

    const availablePaymentToggleFields = this.fields
      // Only need the CASH, FINANCE and LEASE toggle fields
      .filter(field =>
        [
          RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED,
          RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED,
          RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED,
        ].includes(field.queryVar as RetailItemPricingInfoBuilderFields)
      )
      // If a payment toggle is hidden, or has no queryAlias, then its configuration cannot be reverted
      .filter(field => {
        if (!field.queryAlias?.length) {
          LoggingService.debug({
            message: `${field.queryVar} does not have a queryAlias defined, its configuration cannot be overridden!`,
          });
        }
        return field.queryAlias?.length && !field.displayType?.includes(StepFieldDisplayType.HIDDEN);
      });

    const revertConfigurationVariables = availablePaymentToggleFields
      .map(field => {
        // The queryAlias of the toggle field refers to the gql parameter (financeRetailPricing, cashRetailPricing, etc)
        const paymentMethod = field.queryAlias![0];
        const modifyParameter = getPricingConfigurationModifyParameter(paymentMethod as InventoryPaymentOption);

        return {
          [paymentMethod]: {
            configurationOverride: {
              _clear: modifyParameter._enabled,
            },
          },
        };
      })
      // Combine each configuration override payload into a single object
      .reduce((payload, item) => ({ ...payload, ...item }), {});

    const { data: result } = await this.client.mutate({
      mutation: retailItemModify,
      variables: {
        id: data.id,
        ...revertConfigurationVariables,
      },
    });

    // If results were successful, then configuration override has been reverted
    if (result) {
      this.successfullyRevertedPaymentConfigurationOverrides(availablePaymentToggleFields, result.data as RetailItem);
    }

    return result;
  }

  openRevertConfigurationPrompt() {
    const { toggleClosePrompt } = this.props;

    toggleClosePrompt({
      message: t('revert_to_segment_values_prompt_message'),
      messageIcon: PromptDialogIcon.WARNING,
      title: t('revert_to_segment_values'),
      confirmText: t('yes_continue'),
      cancelText: t('cancel'),
      onConfirm: async () => {
        await this.revertPaymentConfigurationOverrides();
      },
      onComplete: success => {
        if (success) {
          toggleClosePrompt(undefined);
        }
      },
      onClose: () => {
        toggleClosePrompt(undefined);
      },
      onCancel: () => {
        toggleClosePrompt(undefined);
      },
    });
  }

  async onButtonClick(stepField: StepField) {
    // The only available button in this step is the REVERT_CONFIGURATION one
    if (stepField.queryVar !== RetailItemPricingInfoBuilderFields.REVERT_CONFIGURATION) {
      return;
    }

    this.openRevertConfigurationPrompt();
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    const {
      tier: { data },
    } = this.props;

    super.onFieldChange(stepField, e);

    /**
     * If a toggle field is not overridden, and the user has modified the toggle state, then update the suffix icon
     * to show that changing the toggle state will result in using an overridden value
     */
    switch (stepField.queryVar) {
      case RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED: {
        const isFieldOverridden = !isNil(get(data, InventoryItem.CASH_CONFIGURATION_OVERRIDE_ENABLED));
        const isPricingConfigurationEnabled = get(data, InventoryItem.CASH_CONFIGURATION_ENABLED);

        if (!isFieldOverridden) {
          stepField.suffixIcon =
            isPricingConfigurationEnabled === stepField.selectedValue ? this.linkIconConfig : this.unlinkIconConfig;
        }
        break;
      }

      case RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED: {
        const isFieldOverridden = !isNil(get(data, InventoryItem.FINANCE_CONFIGURATION_OVERRIDE_ENABLED));
        const isPricingConfigurationEnabled = get(data, InventoryItem.FINANCE_CONFIGURATION_ENABLED);

        if (!isFieldOverridden) {
          stepField.suffixIcon =
            isPricingConfigurationEnabled === stepField.selectedValue ? this.linkIconConfig : this.unlinkIconConfig;
        }
        break;
      }

      case RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED: {
        const isFieldOverridden = !isNil(get(data, InventoryItem.LEASE_CONFIGURATION_OVERRIDE_ENABLED));
        const isPricingConfigurationEnabled = get(data, InventoryItem.LEASE_CONFIGURATION_ENABLED);

        if (!isFieldOverridden) {
          stepField.suffixIcon =
            isPricingConfigurationEnabled === stepField.selectedValue ? this.linkIconConfig : this.unlinkIconConfig;
        }
        break;
      }
    }
  }

  async save(variablePreset: any = {}, variableOverrides: any = {}): Promise<boolean> {
    const {
      tier: { data },
    } = this.props;

    const paymentMethodsEnabledPayload = getPaymentOptionConfigurationOverride(
      data as RetailItem,
      [
        RetailItemPricingInfoBuilderFields.CASH_CONFIGURATION_ENABLED,
        RetailItemPricingInfoBuilderFields.FINANCE_CONFIGURATION_ENABLED,
        RetailItemPricingInfoBuilderFields.LEASE_CONFIGURATION_ENABLED,
      ]
        .map(field => getStepField(field, this.fields))
        // If the payment method toggle is hidden, then its configuration does not need to be overridden
        .filter(field => !field.displayType?.includes(StepFieldDisplayType.HIDDEN))
    );

    return super.save(undefined, paymentMethodsEnabledPayload);
  }
}

export default RetailItemPricingInfoStep;
