import { gql } from '@apollo/client';

import { selectStringOption } from 'store/api/graph/fragments/selectOption';

export default gql`
  fragment ColorTableMetadataFragment on Metadata {
    mutation {
      item: inventoryItem {
        vehicleAttributes {
          exteriorColor {
            ...SelectStringOptionFragment
          }
          interiorColor {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;
