import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import UserDetailsFields from './steps/DetailsFields';
import UserDetailsStep from './steps/DetailsStep';
import PermissionsFields from './steps/PermissionsFields';
import PermissionsStep from './steps/PermissionsStep';
import { userCreate, userModify } from './UserCreateModifyQuery';
import UserQueryMethods from './UserQueryMethods';

const UserBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.USER_CREATE]: {
    getMetaData: UserQueryMethods.getMetaData,
    refetchQueries: UserQueryMethods.refetchQueries,
    resourceType: ResourceType.USERS,
    steps: [
      {
        id: 'USER_DETAILS',
        step: UserDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: UserDetailsFields,
      },
      {
        id: 'USER_PERMISSIONS',
        step: PermissionsStep,
        fields: PermissionsFields,
        stepName: 'permissions',
        isEnabled: false,
      },
    ],
    mutations: {
      modify: userModify,
      create: userCreate,
      validate: userCreate,
    },
    requiredPermissions: [{ resource: ResourceType.USERS, level: AccessLevel.FULL }],
  },
  [BuilderType.USER_MODIFY]: {
    getMetaData: UserQueryMethods.getMetaData,
    refetchQueries: UserQueryMethods.refetchQueries,
    getData: UserQueryMethods.getData,
    resourceType: ResourceType.USERS,
    steps: [
      {
        id: 'USER_DETAILS',
        step: UserDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: UserDetailsFields,
      },
      {
        id: 'USER_PERMISSIONS',
        step: PermissionsStep,
        stepName: 'permissions',
        fields: PermissionsFields,
        isEnabled: true,
        permissionsRequired: [{ resource: ResourceType.USERS, level: AccessLevel.FULL }],
      },
    ],
    mutations: {
      modify: userModify,
    },
    requiredPermissions: [{ resource: ResourceType.USERS, level: AccessLevel.ADVANCED }],
  },
};

export default UserBuilders;
