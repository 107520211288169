import { memo, type SVGProps } from 'react';

const PromotionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M21.275 13.09h-2.096c-.307 0-.565-.105-.775-.315A1.054 1.054 0 0 1 18.09 12c0-.307.105-.566.314-.775.21-.21.468-.315.775-.315h2.096c.307 0 .566.105.775.315.21.21.315.468.315.775s-.105.566-.315.775c-.21.21-.468.315-.775.315Zm-4.561 3.656c.182-.25.42-.393.712-.433.293-.04.564.033.813.215l1.672 1.254c.25.183.393.42.433.713.04.292-.033.563-.215.813-.183.253-.42.4-.713.439a1.105 1.105 0 0 1-.819-.215l-1.672-1.26a1.014 1.014 0 0 1-.43-.713 1.097 1.097 0 0 1 .218-.813ZM19.91 6.218l-1.672 1.254c-.25.182-.52.254-.813.215a1.025 1.025 0 0 1-.712-.433 1.108 1.108 0 0 1-.221-.813.998.998 0 0 1 .433-.713l1.677-1.26a1.1 1.1 0 0 1 .816-.215c.29.04.527.186.71.44.182.249.254.52.215.812-.04.293-.184.53-.433.713ZM4.635 15.203h-.82a2.181 2.181 0 0 1-1.547-.698 2.205 2.205 0 0 1-.633-1.577v-1.856c0-.626.223-1.162.669-1.607a2.19 2.19 0 0 1 1.606-.668H7.78l3.528-2.13c.382-.228.765-.23 1.15-.006.386.224.579.555.579.994v8.69c0 .439-.193.77-.579.994-.385.224-.768.222-1.15-.006l-3.528-2.13h-.833v2.845c0 .323-.112.596-.335.82a1.115 1.115 0 0 1-.82.335c-.323 0-.597-.111-.82-.335a1.116 1.116 0 0 1-.336-.82v-2.845Zm9.4.35V8.447c.51.416.919.931 1.226 1.546A4.43 4.43 0 0 1 15.72 12a4.43 4.43 0 0 1-.46 2.007 4.644 4.644 0 0 1-1.225 1.546Z"
    />
  </svg>
);

export default memo(PromotionsIcon);
