import { useCallback, useMemo } from 'react';

import { css } from 'styled-components/macro';

import type Tier from 'components/core/createModify/interfaces/tier';
import type { SecondaryListTabViewProps } from 'containers/nestedView/NestedViewSettings';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { ElementTestId } from 'enums/testing';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { ENTITY_PADDING } from 'styles/spacing';

import { CTAButton } from '../shared/Button';

export interface CondensedListNestedCreateProps {
  /*
   * A nested list view may have multiple lists, this prop specifies which create builder should be used. However, if
   * this is omitted, then the create builder for the first list in the configuration will be used.
   */
  activeCreateBuilder?: (data) => Partial<Tier>;
  /** Label to use for the CTA button. If none is provided, the title of the builder will be used. */
  createButtonLabel?: string;
}

const CondensedListNestedCreate = ({ activeCreateBuilder, createButtonLabel }: CondensedListNestedCreateProps) => {
  const {
    viewItems: [viewItem],
  } = useNestedView();
  const { toggleTier } = useCreateModify();

  const listConfig = viewItem.config as SecondaryListTabViewProps;

  const createBuilderSettings = useMemo(() => {
    if (listConfig) {
      if (activeCreateBuilder) {
        return activeCreateBuilder(viewItem.seededData) as Tier;
      } else if (listConfig.tabs?.length) {
        return listConfig.tabs[0]?.createBuilder?.(viewItem.seededData) as Tier;
      }
    }
  }, [activeCreateBuilder, listConfig, viewItem]);

  const onCreate = useCallback(() => {
    if (createBuilderSettings) {
      toggleTier(CreateModifyTiers.TIER_0, {
        ...createBuilderSettings,
        tierId: CreateModifyTiers.TIER_0,
      });
    }
  }, [createBuilderSettings, toggleTier]);

  if (!createBuilderSettings) {
    return null;
  }

  return (
    <div
      css={css`
        padding: 14px ${ENTITY_PADDING};
      `}
    >
      <CTAButton data-testid={ElementTestId.NESTED_VIEW_CREATE_BUTTON} onClick={onCreate}>
        {createButtonLabel || createBuilderSettings.title}
      </CTAButton>
    </div>
  );
};

export default CondensedListNestedCreate;
