import { memo, type SVGProps } from 'react';

const ClipboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.048 18.018h5.91c.295 0 .543-.1.742-.303.2-.201.3-.45.3-.745s-.1-.543-.3-.742c-.2-.2-.447-.3-.742-.3H9.042c-.295 0-.543.1-.742.3-.2.2-.3.447-.3.742s.1.544.303.745c.201.202.45.303.745.303Zm0-4h5.91c.295 0 .543-.1.742-.303.2-.201.3-.45.3-.745s-.1-.543-.3-.742c-.2-.2-.447-.3-.742-.3H9.042c-.295 0-.543.1-.742.3-.2.2-.3.447-.3.742s.1.544.303.745c.201.202.45.303.745.303Zm-2.976 8.185c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61V4.072c0-.63.222-1.167.665-1.61.444-.444.98-.665 1.61-.665h7.073a2.246 2.246 0 0 1 1.598.658l4.801 4.803a2.246 2.246 0 0 1 .659 1.598v11.072c0 .63-.221 1.166-.665 1.61-.444.444-.98.665-1.61.665H6.072Zm6.856-14.269c0 .32.11.589.33.808.22.22.489.33.808.33h3.862l-5-5v3.862Z"
    />
  </svg>
);
export default memo(ClipboardIcon);
