import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { leadActivityMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { leadActivityDetailsQuery } from 'containers/leadActivities/LeadActivitiesContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { LeadActivityMetaQuery, LeadActivityMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const LeadActivityQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<LeadActivityMetaQuery, LeadActivityMetaQueryVariables>({
      query: leadActivityMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: leadActivityDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'LeadActivityConnectionQuery',
    'LeadConnectionQuery',
    'LeadActivityCondensedListQuery',
    'LeadDetailsContainerQuery',
    'RetailItemCondensedListQuery',
    'UserDetailsContainerQuery',
    'RetailItemDetailsContainerQuery',
    'ConversationDetailsContainerQuery',
  ],
};

export default LeadActivityQueryMethods;
