import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { websiteDetailQuery } from 'containers/websites/WebsitesContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const WebsiteLeadRoutingQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const response = await client.query({
      query: websiteDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['WebsiteDetailsContainerQuery'],
};

export default WebsiteLeadRoutingQueryMethods;
