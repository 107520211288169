import type { SelectOption } from 'store/api/graph/responses/responseTypes';

import { joinStrings } from './stringUtils';

type InputOption = SelectOption | { id: string; name: { value: string } };
type InputValue = string | number | Record<string, any> | undefined;
/**
 *
 * Parses the value of an input field and formats the value depending on the value type.
 *
 * Examples:
 * - formatInputValue('foo') => 'string'
 * - formatInputValue('foo', [{ id: 'foo', name: 'bar' }]) => 'bar'
 * - formatInputValue(123) => 123
 * - formatInputValue([{ id: 'a', name: 'A' }, { id: 'b', name: 'B' }]) => 'A, B'
 * - formatInputValue({ id: 'foo', name: 'bar' }) => 'bar'
 *
 * @param {string|number|array|object} value - The value of the input
 * @param {array} options - An array of any associated selection options
 * @param {string} key - Property name of the input value returned; defaults to 'name'
 * @returns {string|*}
 */
export const formatInputValue = (
  value: InputValue | InputValue[],
  options: InputOption[] | null = null,
  key = 'name'
) => {
  const valueType = typeof value;

  if (valueType === 'string' || valueType === 'number') {
    if (options && options.length > 0) {
      const option = options.find(o => o.id === value);

      return option?.name && typeof option.name === 'object' && 'value' in option.name
        ? option?.name?.value
        : option?.name || value;
    }
    return value;
  } else if (Array.isArray(value)) {
    return joinStrings(value.map(o => o[key]?.value || o[key]));
  } else if (valueType === 'object' && !!value) {
    return value[key]?.value || value[key];
  }
  return value;
};
