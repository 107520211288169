import { gql } from '@apollo/client';

export const tradeInAppraisalInternal = gql`
  fragment TradeInAppraisalInternalFragment on TradeInAppraisalInternal {
    id
    comments
    user {
      id
    }
  }
`;
