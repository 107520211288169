import { AutoPriceAdjustmentBaseFields } from 'components/sections/createModify/rooftopPricingSegments/interfaces';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';

export enum RooftopCashSegmentBuilderSteps {
  DETAILS = 'DETAILS',
  FILTERS = 'FILTERS',
}

export enum RooftopCashSegmentDetailsBuilderFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  ORDER = 'order',
  TARGETS = 'targets',
  CASH_CONFIGURATION_ENABLED = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.enabled`,
  CASH_CONFIGURATION_INCLUDE_TAX = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.includeTax`,
  CASH_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.includeAfterTaxRebates`,
  CASH_CONFIGURATION_STARTING_PRICE_FIELD = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.startingPriceField`,
  CASH_CONFIGURATION_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.disclaimerTemplate`,
  CASH_CONFIGURATION_INCLUDE_FREIGHT = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.includeFreight`,
  CASH_CONFIGURATION_PRICING_SOURCE = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.pricingSource`,
  CASH_CONFIGURATION_MIN_DISPLAY_PRICE = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.minDisplayPrice`,
  CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED = AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_ENABLED,
  CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_TARGET_PRICE}`,
  CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_LABEL}`,
  CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE = `${RooftopPricingSegmentConfigurationPointer.CASH_CONFIGURATION}.${AutoPriceAdjustmentBaseFields.AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE}`,
}
