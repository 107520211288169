import { gql } from '@apollo/client';

export const employment = gql`
  fragment EmploymentFragment on Employment {
    type
    typeName
    status
    statusName
    employer
    occupation
    durationMonths
    monthlyIncome
    otherIncome
    otherIncomeSource
    phoneNumber
  }
`;
