import { gql } from '@apollo/client';

import { rooftopIntegrationDetails } from 'store/api/graph/fragments/rooftopIntegrationDetails';

export const rooftopIntegrationCreate = gql`
  mutation RooftopIntegrationCreate(
    $cppAttributes: CppRooftopIntegrationAttributesInput
    $leadDistributionAttributes: LeadDistributionRooftopIntegrationAttributesInput
    $rooftopId: ID
    $importPhotos: Boolean
    $integrationId: ID
    $primary: Boolean
    $privacyPolicyUrl: String
    $runOnce: Boolean
    $thirdPartyRooftopId: ID
    $active: Boolean
  ) {
    data: rooftopIntegrationCreate(
      cppAttributes: $cppAttributes
      leadDistributionAttributes: $leadDistributionAttributes
      rooftopId: $rooftopId
      importPhotos: $importPhotos
      integrationId: $integrationId
      primary: $primary
      privacyPolicyUrl: $privacyPolicyUrl
      runOnce: $runOnce
      thirdPartyRooftopId: $thirdPartyRooftopId
      active: $active
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;

export const rooftopIntegrationModify = gql`
  mutation RooftopIntegrationModify(
    $id: ID!
    $active: Boolean
    $cppAttributes: CppRooftopIntegrationAttributesInput
    $leadDistributionAttributes: LeadDistributionRooftopIntegrationAttributesInput
    $importPhotos: Boolean
    $primary: Boolean
    $privacyPolicyUrl: String
    $runOnce: Boolean
    $thirdPartyRooftopId: ID
    $_clear: [RooftopIntegrationModifyParameter!]
  ) {
    data: rooftopIntegrationModify(
      id: $id
      active: $active
      cppAttributes: $cppAttributes
      leadDistributionAttributes: $leadDistributionAttributes
      importPhotos: $importPhotos
      primary: $primary
      privacyPolicyUrl: $privacyPolicyUrl
      runOnce: $runOnce
      thirdPartyRooftopId: $thirdPartyRooftopId
      _clear: $_clear
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;
