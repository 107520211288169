import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const buildAndPrice = gql`
  fragment BuildAndPriceFragment on BuildAndPriceConfiguration {
    disclaimerTemplate {
      ...MultilingualStringFragment
    }
  }
  ${MultilingualString}
`;
