import { css } from 'styled-components/macro';

import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import LinkedFields from 'components/sections/createModify/shared/steps/LinkedFields';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import LeadListItem from 'components/ui/lists/LeadListItem';
import { BuilderType } from 'enums/builderType';
import { LeadActivity } from 'enums/columns/leadActivity';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { LeadActivityModifyParameter } from 'store/api/graph/interfaces/types';
import { PURCHASER_CATEGORY_PROPERTY_NAME, PurchaserCategory } from 'utils/purchaseActivityInterfaces';

import { PurchaseActivityBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [PurchaseActivityBuilderFields.PURCHASED_BY]: {
    label: 'purchased_by',
    groupType: StepFieldType.RENDER_OBJECT,
    renderElement: LeadListItem,
    subStep: [SubStepType.ASYNC],
    queryAlias: LeadActivity.LEAD_NAME,
    subStepAddConfig: SubStepAddConfigs[BuilderType.LEAD_CREATE],
    hasSeparator: true,
    subStepCategories: [
      {
        conditionProp: PURCHASER_CATEGORY_PROPERTY_NAME,
        conditionValue: PurchaserCategory.LINKED,
        label: 'linked_leads',
      },
      { conditionProp: PURCHASER_CATEGORY_PROPERTY_NAME, conditionValue: PurchaserCategory.ALL, label: 'all_leads' },
    ],
    clear: { field: LeadActivityModifyParameter._leadId },
  },
  [PurchaseActivityBuilderFields.SOURCE_ID]: {
    label: 'lead_source',
    queryAlias: LeadActivity.SOURCE_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    clear: { field: LeadActivityModifyParameter._sourceId },
  },
  [PurchaseActivityBuilderFields.DATE]: {
    label: 'date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
    hasSeparator: true,
  },
  [PurchaseActivityBuilderFields.RETAIL_ITEM_PURCHASED_ID]: {
    ...LinkedFields.inventoryItemId,
    label: 'retail_items_one',
    queryAlias: LeadActivity.RETAIL_ITEM_PURCHASED,
    hasSeparator: true,
    subStepCategories: [{ conditionProp: '__typename', conditionValue: 'RetailItem', label: 'retail' }],
    required: true,
  },
  [PurchaseActivityBuilderFields.NOTES]: {
    label: 'note_other',
    queryAlias: LeadActivity.NOTE,
    groupType: StepFieldType.TEXT_AREA,
    clear: { field: LeadActivityModifyParameter._note },
    containerInnerStyles: css`
      textarea {
        height: 321px;
      }
    `,
    hasSeparator: true,
  },
};

export default DetailFields;
