import styled from 'styled-components/macro';

import Checkbox from 'components/ui/forms/shared/Checkbox';

const ListSelectionCheckbox = styled(Checkbox)`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

export default ListSelectionCheckbox;
