import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { ContainerStyles } from 'components/core/createModify/stepFields/StepFieldLayout';
import { StepFieldType } from 'enums/stepFieldType';
import { RooftopModifyParameter } from 'store/api/graph/interfaces/types';

import { RooftopDescriptionBuilderFields } from './interfaces';

const DescriptionFields: StepFields = {
  [RooftopDescriptionBuilderFields.DESCRIPTION]: {
    label: 'description',
    placeholder: 'enter_description',
    groupType: StepFieldType.TEXT_AREA,
    active: true,
    containerStyles: ContainerStyles.FULL_HEIGHT,
    clear: { field: RooftopModifyParameter._description },
  },
};

export default DescriptionFields;
