import { memo, type SVGProps } from 'react';

const NoConnectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m18.966 21.93-8.568-8.607a8.913 8.913 0 0 0-1.401.445 7.196 7.196 0 0 0-1.25.666 2.369 2.369 0 0 1-1.244.374 1.466 1.466 0 0 1-1.131-.467 1.46 1.46 0 0 1-.446-1.153c.023-.45.213-.805.57-1.067.36-.263.738-.505 1.135-.724.398-.22.807-.423 1.23-.611L5.747 8.673c-.41.226-.806.459-1.188.699-.382.24-.753.499-1.113.775a1.91 1.91 0 0 1-1.206.418c-.447 0-.822-.16-1.125-.48a1.46 1.46 0 0 1-.452-1.138c.018-.438.204-.8.558-1.082.346-.284.7-.553 1.059-.809.36-.255.738-.49 1.135-.708L2.064 4.996a.98.98 0 0 1-.29-.73 1.002 1.002 0 0 1 1.029-1.023c.293 0 .538.098.733.293L20.45 20.45c.208.208.312.454.312.74 0 .285-.104.531-.312.739a1.084 1.084 0 0 1-.742.302.982.982 0 0 1-.743-.302ZM12 21c-.7 0-1.292-.246-1.775-.738A2.426 2.426 0 0 1 9.5 18.5c0-.7.242-1.292.725-1.775C10.708 16.242 11.3 16 12 16s1.292.242 1.775.725c.483.483.725 1.075.725 1.775 0 .683-.242 1.27-.725 1.762A2.393 2.393 0 0 1 12 21Zm6.86-6.91c-.258.258-.56.384-.904.378a1.275 1.275 0 0 1-.904-.398l-.256-.256-.256-.256-2.275-2.268c-.22-.22-.261-.45-.122-.687.14-.238.37-.315.691-.231.722.187 1.407.444 2.054.772.647.327 1.25.708 1.81 1.142.288.225.452.518.491.877.04.358-.07.667-.328.926Zm4.03-4a1.54 1.54 0 0 1-1.116.49c-.44.01-.84-.124-1.196-.402A13.566 13.566 0 0 0 12 7.186a10.987 10.987 0 0 0-1.713.12 1.535 1.535 0 0 1-1.188-.302 1.553 1.553 0 0 1-.615-1.062c-.062-.445.04-.842.308-1.191.268-.35.624-.553 1.069-.612.348-.055.7-.091 1.057-.11A20.33 20.33 0 0 1 12 4c2.071 0 4.022.342 5.852 1.026a17.077 17.077 0 0 1 4.944 2.844c.346.291.527.655.543 1.092.017.437-.133.813-.449 1.129Z"
    />
  </svg>
);

export default memo(NoConnectionIcon);
