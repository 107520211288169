import { useCallback, useEffect, useRef } from 'react';

import type { OperationVariables } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { cloneDeep, isNil, orderBy, uniqBy } from 'lodash-es';

import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import { setIsIncomingItem } from 'store/apollo/localState/isIncoming';

/**
 * Custom apollo subscription that handles out of sync data
 */
const useSubscription = (
  /** The query to subscribe to */
  subscriptionQuery: DocumentNode,
  /**
   * The variables required to make the query
   *  `undefined` means variables are in flux so no subscription will happen,
   *  `null` means no need for variables
   */
  subscriptionVariables: OperationVariables | undefined | null,
  /** The callback that decides what to do with the data on a proper update */
  onUpdate: (data: any) => void,
  /** The callback for when the subscription is out of sync (e.g. missed an item) */
  onRefetch: (data: any) => void,
  /** A flag that is used to determine whether or not this hook is allowed to run */
  allowed = true,
  /** An error callback used to handle issues from the subscription */
  onError?: (e: ApiError[]) => void
) => {
  // The id from the last subscription event that the user received
  const subscriptionId = useRef(null);
  // Dynamic callbacks used inside of `useEffect` so as to not constantly trigger the subscription unload
  const dynamicCallbacks = useRef({
    onRefetch,
    onUpdate,
    onError,
  });

  useEffect(() => {
    dynamicCallbacks.current = { onRefetch, onUpdate, onError };
  }, [onRefetch, onUpdate, onError]);

  // Subscription logic
  useEffect(() => {
    // Only subscribe if variables are available, `undefined` means variables are in flux
    if (subscriptionVariables !== undefined && allowed) {
      subscriptionId.current = null;

      const isExplicitSubscribeWithNoVariables = subscriptionVariables === null;

      const observer = client.subscribe({
        query: subscriptionQuery,
        variables: isExplicitSubscribeWithNoVariables ? undefined : subscriptionVariables,
      });

      const subscription = observer.subscribe(
        ({
          data: {
            item: { previousId, ...item },
          },
        }) => {
          // Silent refetch if out of sync
          if (subscriptionId.current !== previousId && window.navigator.onLine) {
            dynamicCallbacks.current.onRefetch(item);
            subscriptionId.current = item.id;
          }
          // Directly update cache
          else if (subscriptionId.current === previousId) {
            dynamicCallbacks.current.onUpdate(item);
            subscriptionId.current = item.id;
          }
        },
        e => dynamicCallbacks.current.onError?.(getApiErrors(e))
      );

      const unsubscribe = () => subscription.unsubscribe();
      window.addEventListener('beforeunload', unsubscribe);
      return () => {
        unsubscribe();
        window.removeEventListener('beforeunload', unsubscribe);
      };
    }
  }, [subscriptionVariables, subscriptionQuery, allowed]);
};

export interface CondensedListSubscriptionConfig {
  /** The query to subscribe to */
  subscriptionQuery: DocumentNode;
  /** Any filters to ignore when deciding whether or not to update */
  ignoredFilters?: string[];
  /** Any specific sorting */
  sortBy?: { target: string; dir: string };
}

interface CondensedListUpdateProps {
  /** The incoming item from the subscription that is being updated */
  updatedItem?: any;
  /** The current list of results from the response */
  items: any[];
  /** The query used to update cache */
  query: DocumentNode;
  /** The variables used to filter the query being updated */
  variables: any;
}

/**
 * Update callback specifically used for subscribed condensed lists
 */
export const onCondensedListUpdate = (props: CondensedListSubscriptionConfig & CondensedListUpdateProps) => {
  const { updatedItem, items, variables, ignoredFilters = [] } = props;
  const currentItemIndex = items.findIndex(item => item.id === updatedItem.id);

  const isFiltered =
    Object.keys(variables.filter || {}).some(
      filterKey =>
        // Ignore specific filters
        !ignoredFilters.includes(filterKey)
    ) ||
    ['after', 'keyword'].some(key => {
      const val = variables[key];
      return Array.isArray(val) || typeof val === 'string' ? val.length > 0 : !isNil(val);
    });

  // Updated item does not exist in current list
  if (
    currentItemIndex === -1 &&
    // Filters are applied, add new item to list as incoming
    isFiltered
  ) {
    setIsIncomingItem(updatedItem);
  }

  // Sort the updated data, No sorting if filtered
  updateCondensedListCache({ ...props, sortBy: isFiltered ? undefined : props.sortBy });
};

// Writing condensed list items to the Apollo cache directly
const updateCondensedListCache = (props: CondensedListSubscriptionConfig & CondensedListUpdateProps) => {
  const { items, query, variables, sortBy } = props;
  const data = client.readQuery({
    query,
    variables,
  });

  // If the cache is missing data for any of the query's fields, readQuery returns null
  if (!data) {
    return;
  }

  const dataToWrite = cloneDeep(data);

  // Sort the updated data
  dataToWrite.connection.edges = uniqBy(
    sortBy ? orderBy(items, sortBy.target, sortBy.dir as 'desc' | 'asc') : items,
    'id'
  ).map(node => ({
    node,
    __typename: dataToWrite.connection.edges[0]?.__typename,
  }));

  // If this query has type filters, update the facet count (TODO: Backend will look into providing updated filter data)
  if (dataToWrite.connection.filters) {
    for (const [index, element] of dataToWrite.connection.filters.entries()) {
      if (element.id === 'type') {
        dataToWrite.connection.filters[index].facets = dataToWrite.connection.filters[index].facets.map(facet => {
          facet.count = items.filter(item => item.type === facet.id).length;
          return facet;
        });
      }
    }
  }

  client.writeQuery({
    query,
    variables,
    data: dataToWrite,
  });
};

/**
 * A commonly used method of subscribing unique to full condensedlist views
 */

export const useCondensedListSubscription = (
  /** The configuration being used */
  config: CondensedListSubscriptionConfig = {} as CondensedListSubscriptionConfig,
  /** Variables that the subscription is dependant on */
  variables: any,
  /** The condensed listquery that updates once the list hears a subscription */
  condensedListQuery: DocumentNode,
  /** The raw item data for the condensed list */
  items: any[],
  /** Refetch method for out of sync data */
  refetch: (variables?: any, options?: any) => void,
  /** Whether or not the subscription is valid */
  allowed: boolean
) => {
  useSubscription(
    config.subscriptionQuery,
    null,
    useCallback(
      ({ updatedItem }) => {
        onCondensedListUpdate({
          ...config,
          updatedItem,
          items,
          variables,
          query: condensedListQuery,
        });
      },
      [items, variables, condensedListQuery, config]
    ),
    useCallback(() => {
      refetch();
    }, [refetch]),
    // Only start subscriptions if the response has loaded
    allowed && !!config.subscriptionQuery
  );
};
export default useSubscription;
