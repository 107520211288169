import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import IntegrationWebsiteListItem from 'components/ui/lists/IntegrationWebsiteListItem';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import {
  OemAccountExportRooftopIntegrationAttributesInputParameter,
  RooftopIntegrationModifyParameter,
} from 'store/api/graph/interfaces/types';

const DetailFields: StepFields = {
  [RooftopIntegrationDetailsFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopIntegration.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_TYPE]: {
    label: 'type',
    placeholder: 'type',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.INTEGRATION_ID]: {
    label: 'integration_one',
    placeholder: 'integration_one',
    queryAlias: RooftopIntegration.INTEGRATION_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE]: {
    label: 'website_one',
    placeholder: 'website_one',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    overrideSubStepRenderElement: IntegrationWebsiteListItem,
    queryAlias: [
      RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE,
      RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE,
    ],
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._websiteId,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND]: {
    label: 'website_brand',
    queryAlias: RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND,
    placeholder: 'website_brand',
    groupType: StepFieldType.DROPDOWN,
    options: 'mutation.rooftopIntegration.oemAccountExportAttributes.websiteBrand',
    subStep: [SubStepType.ASYNC],
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._websiteBrand,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.DEALER_CODE]: {
    label: 'dealer_code',
    queryAlias: RooftopIntegration.DEALER_CODE,
    placeholder: 'dealer_code',
    tooltipContents: 'dealer_code_tooltip',
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._dealerCode,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.BAC]: {
    label: 'bac',
    queryAlias: RooftopIntegration.BAC,
    placeholder: 'bac',
    tooltipContents: 'bac_tooltip',
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._bac,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.ROOFTOP_NAME_OVERRIDE]: {
    label: 'rooftop_name_override',
    queryAlias: RooftopIntegration.ROOFTOP_NAME_OVERRIDE,
    placeholder: 'rooftop_name_override',
    hasSeparator: true,
    clear: {
      field: OemAccountExportRooftopIntegrationAttributesInputParameter._rooftopNameOverride,
      target: RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT,
    },
  },
  [RooftopIntegrationDetailsFields.ACTIVE]: {
    label: 'active',
    queryAlias: RooftopIntegration.ACTIVE,
    groupType: StepFieldType.SWITCH,
    clear: { field: RooftopIntegrationModifyParameter._active },
  },
};

export default DetailFields;
