import { gql } from '@apollo/client';

import { mailboxDetail } from 'store/api/graph/fragments/mailboxDetail';
import { mailboxList } from 'store/api/graph/fragments/mailboxList';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';

export const mailboxDetailsQuery = gql`
  query MailboxDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: mailbox(id: $id) {
      ...MailboxDetailFragment
    }
  }
  ${mailboxDetail}
`;

export const mailboxCondensedListQuery = gql`
  query MailboxCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: MailboxConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: mailboxConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...MailboxListFragment
        }
      }
    }
  }
  ${mailboxList}
  ${pageInfo}
`;
