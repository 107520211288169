import { useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import { BODY_TEXT, BODY_TEXT_SECONDARY, BODY_TEXT_TERTIARY, BORDER_DEFAULT } from 'styles/color';
import { DISABLED_INPUT_OPACITY } from 'styles/forms';
import { BLUE_500, LINE_HEIGHT_2, NEUTRAL_0, NEUTRAL_050, SPACE_200 } from 'styles/tokens';
import { FONT_SIZE_11, FONT_SIZE_14, FONT_WEIGHT_BOLD, FONT_WEIGHT_SEMI_BOLD } from 'styles/typography';

import CloseIcon from '../icons/CloseIcon';

export const ButtonContainer = styled.button<{ selected?: boolean }>`
  color: ${props => (props.selected ? NEUTRAL_0 : BODY_TEXT_SECONDARY)};
  background: ${props => (props.selected ? BLUE_500 : NEUTRAL_050)};
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: center;

  :disabled {
    cursor: not-allowed;
    opacity: ${DISABLED_INPUT_OPACITY};
  }
`;

export const ButtonText = styled(Text)`
  color: inherit;
  font-size: ${FONT_SIZE_14};
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  line-height: ${LINE_HEIGHT_2};
`;

/**
 * Use this for a button-looking button
 */
const Button = ({ children, ...props }) => {
  if (typeof children === 'string') {
    return (
      <ButtonContainer {...props}>
        <ButtonText>{children}</ButtonText>
      </ButtonContainer>
    );
  }
  return <ButtonContainer {...props}>{children}</ButtonContainer>;
};

/**
 * Use this to render multiple buttons separated by some space
 */
export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;
  gap: ${SPACE_200};

  :empty {
    display: none;
  }
`;

export const ClickableButtonCSS = css<{ disabled?: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: block;
  background: none;
  color: inherit;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

/**
 * Use this when you need an un-styled button
 */
export const Clickable = styled.button<{ disabled?: boolean }>`
  ${ClickableButtonCSS}
`;

/**
 * Use this component for nested clickable areas
 * to avoid React warning about nested buttons
 */
export const FillWithClickable = styled(Clickable)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const RemovableButtonText = styled(Text)`
  color: inherit;
  margin-right: 14px;
`;

/**
 * Use this when you need a button-looking component
 * that can be removed when you click on X
 */
export const RemovableButton = ({ onRemove, children, ...props }) => {
  const [isRemoved, setIsRemoved] = useState(false);

  if (isRemoved) {
    return null;
  }

  return (
    <ButtonContainer
      selected
      title={children}
      onClick={event => {
        if (onRemove) {
          onRemove(event);
        }
        setIsRemoved(true);
      }}
      {...props}
    >
      <RemovableButtonText>{children}</RemovableButtonText>
      <CloseIcon width={16} height={16} />
    </ButtonContainer>
  );
};

export const LinkLikeButton = styled(Button)`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  background: none;
`;

export const ButtonCSS = css<{ disabled?: boolean }>`
  height: 34px;
  min-width: 34px;
  border: 1px solid ${BORDER_DEFAULT};
  border-radius: 5px;
  background-color: ${NEUTRAL_0};
  color: ${BODY_TEXT};
  font-size: ${FONT_SIZE_14};
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  line-height: ${LINE_HEIGHT_2};
  text-decoration: none;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `};
  cursor: pointer;
`;

export const WhiteButton = styled(Clickable)<{ disabled?: boolean }>`
  ${ClickableButtonCSS}
  ${ButtonCSS}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryBottomToolbarButton = styled(WhiteButton)`
  padding: 0 9.5px;
  text-align: center;
  justify-content: center;
  min-width: unset;
  width: initial;
`;

export const InputToggleButton = styled(ButtonContainer)<{ selected?: boolean }>`
  color: ${NEUTRAL_0};
  background: ${props => (props.selected ? BLUE_500 : BODY_TEXT_TERTIARY)};
  font-size: ${FONT_SIZE_11};
  font-weight: ${FONT_WEIGHT_BOLD};
  padding: 0 8px;
  min-width: 36px;
  height: 24px;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const CTAButton = styled(Button)`
  height: 40px;
  width: 100%;
  background: ${BLUE_500};

  & > div {
    margin: auto;
    color: ${NEUTRAL_0};
  }
`;

export const ToggleButton = styled(Clickable)<{ isSelected?: boolean; height?: string; width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height || '38px'};
  width: ${props => props.width || '40px'};
  border: 1px solid ${props => (props.isSelected ? BLUE_500 : BORDER_DEFAULT)};
  background-color: ${props => (props.isSelected ? BLUE_500 : NEUTRAL_0)};
  color: ${props => (props.isSelected ? NEUTRAL_0 : BORDER_DEFAULT)};

  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`;

export const ToggleButtonDivider = styled.div<{ hasSelection?: boolean; height?: string }>`
  height: ${props => props.height || '38px'};
  width: 1px;
  background-color: ${props => (props.hasSelection ? BLUE_500 : BORDER_DEFAULT)};
`;

export const FilterSectionFullWidthButton = styled(Button)`
  width: 100%;
  height: 40px;

  > * {
    justify-content: 'center';
    align-items: 'center';
    width: 100%;
  }
`;

/** Button used in all Builders (StepField Buttons, and Save/Next Buttons in the footer) */
export const BuilderButton = styled(Button)<{ colour?: string }>`
  height: 40px;
  width: 100%;
  display: inline-block;
  background: ${({ colour }) => colour || BLUE_500};

  & > div {
    text-align: center;
    color: ${NEUTRAL_0};
  }
`;

/** Interface for configuring button settings when using a StepFieldType.BUTTON input in a builder */
export interface ButtonSettings {
  // The label text inside the button
  buttonLabel: string;
  // The background colour of the button (BLUE_500 is used as a default if none is provided)
  colour?: string;
}

export default Button;
