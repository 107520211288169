import type { ElementType } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled from 'styled-components/macro';

import type { StepFieldInputConfig } from 'components/core/createModify/stepFields/interfaces';
import ItemTypeSelector from 'components/ui/shared/ItemTypeSelector';
import { builderItemTypeSelectorTestId } from 'enums/testing';

const TypesContainer = styled.div<{ customStyles?: FlattenSimpleInterpolation }>`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  > *:nth-child(odd):last-child {
    grid-column: span 2;
  }

  ${({ customStyles }) => customStyles}
`;

interface Props extends Pick<StepFieldInputConfig, 'onChange' | 'selectedValue'> {
  // Mandatory settings used to configure this input
  settings: {
    // Whether or not type selecting is enabled
    disabled?: boolean;
    // An array of types to render
    types: {
      // The ID of the type to be returned when selected
      id: string;
      // The translated label of the type if applicable
      label?: string;
      // The translated sub label of the type if applicable
      subLabel?: string;
      // The icon used for the type
      icon?: ElementType;
      // Whether or not this type option is disabled
      disabled?: boolean;
    }[];
    // Any custom styling that needs to be applied to render the types
    customStyles?: FlattenSimpleInterpolation;
  };
  /** Data test ID */
  testId?: string;
}

const TypeInput = ({ onChange, selectedValue, settings: { types, disabled, customStyles }, testId }: Props) => {
  const [currentType, setCurrentType] = useState(selectedValue || types[0]);

  const onTypeSelected = useCallback(
    value => {
      setCurrentType(value);
      onChange?.({ currentTarget: { value } });
    },
    [onChange, setCurrentType]
  );

  const renderedTypes = useMemo(
    () =>
      types.map(({ id, label = '', subLabel, icon, disabled: optionDisabled }) => (
        <ItemTypeSelector
          key={id}
          selected={currentType === id}
          onClick={() => onTypeSelected(id)}
          icon={icon}
          label={label}
          subLabel={subLabel}
          disabled={disabled || optionDisabled}
          data-testid={builderItemTypeSelectorTestId(id)}
        />
      )),
    [types, onTypeSelected, disabled, currentType]
  );

  return (
    <TypesContainer data-testid={testId} customStyles={customStyles}>
      {renderedTypes}
    </TypesContainer>
  );
};

export default TypeInput;
