import { useCallback, useState } from 'react';

async function copyTextToClipboard(text: string) {
  return 'clipboard' in navigator ? navigator.clipboard.writeText(text) : document.execCommand('copy', true, text);
}

/** A custom hook for copying provided text to the clipboard. */
const useCopyTextToClipboard = ({
  textToCopy,
  timeout = 0,
}: {
  /** The text to copy to the clipboard */
  textToCopy: string;
  /** A timeout duration that will reset isCopied to false upon expiry */
  timeout?: number;
}) => {
  const [isCopied, setCopied] = useState<boolean>(false);

  const copy = useCallback(() => {
    copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, timeout);
      })
      .catch(() => {});
  }, [textToCopy, timeout]);

  return { isCopied, copy };
};

export default useCopyTextToClipboard;
