import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type StepFieldHyperlink from 'components/core/createModify/interfaces/stepFieldHyperlink';
import Paragraph from 'components/core/typography/Paragraph';
import type RetailItemDetailsStep from 'components/sections/createModify/inventoryItems/retailItem/steps/DetailsStep';
import { CarfaxReportErrors } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import { carfaxReportQuery } from 'components/sections/createModify/inventoryItems/steps/carfaxReportQuery';
import type { VinDecode } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { WarningContainer } from 'components/sections/createModify/inventoryItems/steps/utils';
import { vinDecodeQuery } from 'components/sections/createModify/inventoryItems/steps/VinDecodeQuery';
import { TrimSelectionFields } from 'components/sections/createModify/inventoryItems/tradeInItem/steps/interfaces';
import WarningIcon from 'components/ui/icons/WarningIcon';
import { ApolloFetchPolicy } from 'enums/apollo';
import { getApiErrors, getApiErrorsMessage } from 'store/api/graph/interfaces/apiErrors';
import type { CarfaxReport, Rooftop } from 'store/api/graph/interfaces/types';
import type { VinDecodeResponseType } from 'store/api/graph/responses/responseTypes';
import { YELLOW_500 } from 'styles/tokens';
import {
  formatApiValuesForFields,
  getStepField,
  overwriteFieldSelectedValues,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

export class RetailItemDetailVinDecodeImplementer implements VinDecode {
  step: RetailItemDetailsStep;

  constructor(step: RetailItemDetailsStep) {
    this.step = step;
  }

  async performVinDecodeAndGetCarfaxReport({ rooftop }: { rooftop: Partial<Rooftop> }): Promise<boolean> {
    const vinDecode = await this.performVinDecode();
    if (vinDecode) {
      await this.performGetCarfaxReport({ rooftop });
      return true;
    } else {
      return false;
    }
  }

  async performGetCarfaxReport({ rooftop }: { rooftop: Partial<Rooftop> }): Promise<CarfaxReportErrors | null> {
    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.step.fields);
    const carfax = await this.getCarfaxReport(vinField.selectedValue, rooftop);

    if (carfax.errorType) {
      return carfax.errorType;
    }

    if (carfax.carfaxReport) {
      // Update the carfaxReport fields with the new report. If there is no report, then the fields will be cleared
      const carfaxShowWebField = getStepField(
        DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB,
        this.step.fields
      );
      const carfaxReportField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID, this.step.fields);
      const carfaxReportBadgesField = getStepField(
        DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES,
        this.step.fields
      );

      removeDisplayType(carfaxShowWebField, StepFieldDisplayType.DISABLED);

      carfaxShowWebField.selectedValue = !!carfax.carfaxReport?.id;
      carfaxReportBadgesField.selectedValue = carfax.carfaxReport?.badges;
      carfaxReportField.selectedValue = carfax.carfaxReport?.id;
      carfaxReportField.hyperlink = carfax.carfaxReport?.url
        ? ({
            url: carfax.carfaxReport?.url,
            title: t('view_report'),
          } as StepFieldHyperlink)
        : undefined;
    }

    return null;
  }

  async performVinDecode(options?: {
    /**
     * Vin decoding process can be triggered when saving a builder step, or it can be triggered when refreshing the
     * VIN field to reload vehicle data. This flag determines how the vin decoding process was triggered, so any
     * differences in the decoding flow can be handled.
     */
    isRefreshing?: boolean;
  }): Promise<boolean> {
    const {
      tier: { data },
    } = this.step.props;

    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.step.fields);
    const rooftop = getStepField(DetailsInventoryItemBuilderFields.ROOFTOP_ID, this.step.fields).selectedValue;

    const vinDecode = await this.decodeVin({ rooftop, isRefreshing: options?.isRefreshing });

    this.step.setTier({ data: { ...data, vin: vinField.selectedValue } });
    this.step.selectedTrim.selectedValue = null;

    if (vinDecode) {
      this.step.fields = overwriteFieldSelectedValues(this.step.fields, formatApiValuesForFields(vinDecode));

      // Ensure the MMST fields are not disabled
      for (const field of [
        getStepField(DetailsInventoryItemBuilderFields.MAKE_ID, this.step.fields),
        getStepField(DetailsInventoryItemBuilderFields.MODEL_ID, this.step.fields),
        getStepField(DetailsInventoryItemBuilderFields.SUB_MODEL_ID, this.step.fields),
        getStepField(DetailsInventoryItemBuilderFields.TRIM_ID, this.step.fields),
      ]) {
        setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: false }, field);
      }

      // Set MSRP value
      const msrpField = getStepField(DetailsInventoryItemBuilderFields.MSRP, this.step.fields);
      msrpField.selectedValue = vinDecode?.msrp;

      return true;
    } else {
      return false;
    }
  }

  async getCarfaxReport(
    vin: string,
    rooftop: Partial<Rooftop>
  ): Promise<{ carfaxReport?: CarfaxReport; errorType?: CarfaxReportErrors }> {
    // If no rooftop id is provided, or there is no carfaxId associated with this rooftop, then we cannot get a report
    if (!rooftop?.id || !rooftop?.carfaxId) {
      return { errorType: CarfaxReportErrors.NO_CARFAX_ACCOUNT_ON_ROOFTOP };
    }

    try {
      const { data } = await this.step.client.query({
        query: carfaxReportQuery,
        variables: {
          vin,
          rooftopId: rooftop.id,
        },
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });
      return { carfaxReport: data.carfaxReport };
    } catch (error) {
      const errors = getApiErrors(error);

      /**
       * If there was no carfax report found, the error status will be 400 - we don't need to show any error message
       * in this scenario. However any other errors (network errors, etc) we will need to show an error message.
       */
      if (errors.some(({ extensions }) => extensions?.status !== 400)) {
        this.step.setTier({
          errors: getApiErrors(error),
        });
      }
      return { errorType: CarfaxReportErrors.NO_CARFAX_FOUND };
    }
  }

  async decodeVin({ rooftop, isRefreshing }): Promise<Partial<VinDecodeResponseType> | null> {
    const {
      tier: { activeStep },
    } = this.step.props;
    const vinField = getStepField(DetailsInventoryItemBuilderFields.VIN, this.step.fields);
    const emptyVinResult = {};

    let vinDecode;

    try {
      const { data } = await this.step.client.query({
        query: vinDecodeQuery,
        variables: {
          vin: vinField.selectedValue.trim(),
          trimId: this.step.selectedTrim?.selectedValue?.id,
        },
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });

      vinDecode = data.vinDecode;
    } catch (error) {
      this.step.setTier({
        alert: {
          content: getApiErrorsMessage(error),
          onClick: () => (isRefreshing ? this.step.setTier({ alert: undefined }) : this.proceedWithInvalidVin()),
          buttonText: t('proceed_with_vin'),
        },
      });
      return null;
    } finally {
      /*
       * Clear the default close prompt, even though the API response may have failed, we can proceed
       * to the next step
       */
      if (activeStep?.onClosePrompt === this.step.defaultClosePrompt) {
        this.step.setOnClosePrompt(undefined);
      }
    }

    const matchedTrim =
      vinDecode.matchingTrims.length === 1
        ? vinDecode.matchingTrims[0]
        : vinDecode.matchingTrims.find(trim => trim.id === this.step.selectedTrim?.selectedValue?.id);

    // More than one matching trim, queue subStep to select a match and make a call again
    if (vinDecode.matchingTrims.length > 1) {
      // VinField and selectedTrim are deeply coupled, manually set vinField visuals to `active` override
      this.step.selectedTrim.options = vinDecode.matchingTrims.map(item => ({ ...item, name: item.name.value }));

      this.step.selectedTrim.queryAlias = isRefreshing ? [TrimSelectionFields.VIN_REFRESHING] : [];

      vinField.active = true;
      this.step.setState({
        childrenBeforeSubStep: this.renderTrimWarning(),
        currentStepField: this.step.selectedTrim,
        childrenAfter: undefined,
      });
      return null;
    } else if (matchedTrim) {
      return { ...vinDecode.vinMatch, trimName: matchedTrim };
    } else {
      return emptyVinResult;
    }
  }

  renderTrimWarning() {
    return (
      <WarningContainer>
        <WarningIcon color={YELLOW_500} />
        <Paragraph>{t('partial_vin_decode_message')}</Paragraph>
      </WarningContainer>
    );
  }

  clearTrimSubStep() {
    // Clearing substep where applicable
    this.step.selectedTrim.options = [];
    this.step.selectedTrim.selectedValue = undefined;
    this.step.setState({ childrenBeforeSubStep: undefined });
  }

  proceedWithInvalidVin() {
    const { onStepComplete } = this.step.props;

    // Since this VIN has changed, and is now invalid, we need to clear any carfax reports
    const carfaxReportField = getStepField(DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID, this.step.fields);
    const carfaxReportBadgesField = getStepField(
      DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES,
      this.step.fields
    );
    carfaxReportBadgesField.selectedValue = null;
    carfaxReportField.selectedValue = null;

    onStepComplete(false);
  }
}
