import { get, isNil, isObject } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import LoggingService from 'components/core/logging/LoggingService';
import { RetailBulkAdjustmentBuilderFields } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import { isBannerLinksToVdpFieldVisible } from 'components/sections/createModify/retailBulkAdjustment/utils';
import type { MultilingualToggleInputSettings } from 'components/ui/forms/shared/MultilingualToggleInput';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { RetailBulkAdjustment } from 'enums/columns/retailBulkAdjustment';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RetailAdjustmentAmountAdjustmentType, RetailAdjustmentAmountType } from 'enums/retailBulkAdjustment';
import type {
  DisclaimerRetailBulkAdjustmentAttributesInput,
  OverlayRetailBulkAdjustmentAttributesInput,
  PriceRetailBulkAdjustmentAttributesInput,
  PromotionRetailBulkAdjustmentAttributesInput,
} from 'store/api/graph/interfaces/types';
import {
  OverlayRetailBulkAdjustmentPosition,
  OverlayRetailBulkAdjustmentScalingDimension,
  PaymentOption,
  PromotionRetailBulkAdjustmentPosition,
  RetailBulkAdjustmentType,
  RetailPriceAdjustmentType,
} from 'store/api/graph/interfaces/types';
import { normalizeAndFilterDisclaimerTemplates } from 'utils/disclaimerTemplateUtils';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  getFormattedFixedPrice,
  getFormattedPercentagePrice,
  getPaymentOptions,
  getStepField,
  getUsersRooftop,
  hideAndOmitFields,
  objectToStepFieldArray,
  setDisplayTypes,
  setFixedAndPercentageValuesFromToggleFieldState,
} from 'utils/formatting/createModifyFormatUtils';
import { getImageDataURLFromFile } from 'utils/imageUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const priceAdjustmentFields = [
  RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE,
  RetailBulkAdjustmentBuilderFields.AMOUNT,
  RetailBulkAdjustmentBuilderFields.FIXED,
  RetailBulkAdjustmentBuilderFields.PERCENTAGE,
  RetailBulkAdjustmentBuilderFields.INCLUDE_CASH,
  RetailBulkAdjustmentBuilderFields.INCLUDE_FINANCE,
  RetailBulkAdjustmentBuilderFields.INCLUDE_LEASE,
  RetailBulkAdjustmentBuilderFields.LABEL,
  RetailBulkAdjustmentBuilderFields.DISCLAIMER,
  RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX,
  RetailBulkAdjustmentBuilderFields.TARGETS,
];

const overlayAdjustmentFields = [
  RetailBulkAdjustmentBuilderFields.PRIMARY_PHOTO_ONLY,
  RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE,
  RetailBulkAdjustmentBuilderFields.OVERLAY_POSITION,
  RetailBulkAdjustmentBuilderFields.SCALING_DIMENSION,
  RetailBulkAdjustmentBuilderFields.SCALE,
];

const descriptionAdjustmentFields = [
  RetailBulkAdjustmentBuilderFields.PLACEMENT,
  RetailBulkAdjustmentBuilderFields.TEXT,
];

const promotionAdjustmentFields = [
  RetailBulkAdjustmentBuilderFields.BANNER_IMAGE,
  RetailBulkAdjustmentBuilderFields.IMAGE_POSITION,
  RetailBulkAdjustmentBuilderFields.ALTERNATIVE_TEXT,
  RetailBulkAdjustmentBuilderFields.BANNER_URL,
  RetailBulkAdjustmentBuilderFields.OPEN_LINK_IN_NEW_TAB,
  RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP,
  RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP,
  RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP,
  RetailBulkAdjustmentBuilderFields.SEO_PAGE_TEXT,
];

const disclaimerAdjustmentFields = [RetailBulkAdjustmentBuilderFields.DISCLAIMER_TEMPLATE];

class RetailBulkAdjustmentStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data: currentData, formData, activeStep, seededData },
    } = props;

    const {
      subContexts: {
        userContext: { user },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const data = formData || currentData;
    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);

    const isCashPaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
      featureFlagOn: rooftopPackageEnabled,
    });

    const isFinancePaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
      featureFlagOn: rooftopPackageEnabled,
    });

    const isLeasePaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
      featureFlagOn: rooftopPackageEnabled,
    });

    /**
     * This Step can show either Price related fields, Overlay related fields, or Description related fields. The
     * activeFields will be the fields that need to be displayed and included in the save request.
     */

    switch (data.type) {
      case RetailBulkAdjustmentType.PRICE: {
        this.initializePriceAdjustmentFields({
          isCashPaymentOptionEnabled,
          isFinancePaymentOptionEnabled,
          isLeasePaymentOptionEnabled,
        });
        break;
      }

      case RetailBulkAdjustmentType.OVERLAY: {
        this.initializeOverlayAdjustmentFields();
        break;
      }

      case RetailBulkAdjustmentType.DESCRIPTION: {
        this.initializeDescriptionAdjustmentFields();
        break;
      }

      case RetailBulkAdjustmentType.PROMOTION: {
        this.initializePromotionAdjustmentFields();
        if (activeStep) {
          activeStep.tooltip = { title: t('image_sizing_tip'), content: t('image_sizing_tip_message') };
        }
        break;
      }

      case RetailBulkAdjustmentType.DISCLAIMER: {
        this.initializeDisclaimerAdjustmentFields();
        break;
      }
    }
  }

  /**
   * For Price type adjustments, we need to show the discount/fee toggle, and fixed/percentage input fields.
   * We then need to hide and omit all overlay related fields.
   */
  initializePriceAdjustmentFields(options: {
    isCashPaymentOptionEnabled: boolean;
    isFinancePaymentOptionEnabled: boolean;
    isLeasePaymentOptionEnabled: boolean;
  }) {
    const { isCashPaymentOptionEnabled, isFinancePaymentOptionEnabled, isLeasePaymentOptionEnabled } = options;
    const {
      tier: { data: currentData, formData, activeStep, isCreating },
    } = this.props;
    const data = formData || currentData;

    const isFee =
      Number(get(data, RetailBulkAdjustment.FIXED_AMOUNT, get(data, RetailBulkAdjustment.PERCENTAGE, 0))) >= 0;

    const amountType = isNil(get(data, RetailBulkAdjustment.FIXED))
      ? RetailAdjustmentAmountType.PERCENTAGE
      : RetailAdjustmentAmountType.FIXED;

    const amountValue = isNil(get(data, RetailBulkAdjustment.FIXED))
      ? getFormattedPercentagePrice(get(data, RetailBulkAdjustment.PERCENTAGE))
      : getFormattedFixedPrice(get(data, RetailBulkAdjustment.FIXED_AMOUNT));

    const paymentOptions = get(data, RetailBulkAdjustment.PAYMENT_OPTIONS);

    const isLuxuryTaxEnabled = get(data, RetailBulkAdjustment.PRICE_TYPE) === RetailPriceAdjustmentType.LUXURY_TAX;

    const omittedFields = [
      ...overlayAdjustmentFields,
      ...descriptionAdjustmentFields,
      ...promotionAdjustmentFields,
      ...disclaimerAdjustmentFields,
    ];

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.INCLUDE_CASH]: {
        selectedValue: paymentOptions?.includes(PaymentOption.CASH) ?? true,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isCashPaymentOptionEnabled }),
      },
      [RetailBulkAdjustmentBuilderFields.INCLUDE_FINANCE]: {
        selectedValue: paymentOptions?.includes(PaymentOption.FINANCE) ?? true,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isFinancePaymentOptionEnabled }),
      },
      [RetailBulkAdjustmentBuilderFields.INCLUDE_LEASE]: {
        selectedValue: paymentOptions?.includes(PaymentOption.LEASE) ?? true,
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isLeasePaymentOptionEnabled }),
      },
      [RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX]: {
        selectedValue: isLuxuryTaxEnabled,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: isCreating || !isFee,
        }),
      },
      [RetailBulkAdjustmentBuilderFields.AMOUNT]: {
        selectedValue: isCreating ? { id: RetailAdjustmentAmountType.FIXED } : { id: amountType, value: amountValue },
      },
      [RetailBulkAdjustmentBuilderFields.PERCENTAGE]: {
        selectedValue: !isCreating && amountType === RetailAdjustmentAmountType.PERCENTAGE ? amountValue : null,
      },
      [RetailBulkAdjustmentBuilderFields.FIXED]: {
        selectedValue: !isCreating && amountType === RetailAdjustmentAmountType.FIXED ? amountValue : null,
      },
      [RetailBulkAdjustmentBuilderFields.LABEL]: {
        selectedValue: !isCreating && get(currentData, RetailBulkAdjustment.LABEL),
      },
      [RetailBulkAdjustmentBuilderFields.DISCLAIMER]: {
        selectedValue: isCreating ? null : get(currentData, RetailBulkAdjustment.DISCLAIMER),
      },
      [RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE]: {
        selectedValue:
          !isCreating && isFee
            ? RetailAdjustmentAmountAdjustmentType.FEE
            : RetailAdjustmentAmountAdjustmentType.DISCOUNT,
      },
      [RetailBulkAdjustmentBuilderFields.TARGETS]: {
        selectedValue: isCreating ? null : get(currentData, RetailBulkAdjustmentBuilderFields.TARGETS),
      },
    });

    hideAndOmitFields(omittedFields, this.fields);
    this.hideOrShowLuxuryTaxFields(isLuxuryTaxEnabled);
  }

  /**
   * For Overlay type adjustments, we only need to show two fields -- one for the overlay image and the other
   * for marking the image as the primary photo. We then need to hide and omit all price related fields.
   */
  initializeOverlayAdjustmentFields() {
    const {
      tier: { data: currentData, formData, activeStep, metadata },
    } = this.props;
    const data = formData || currentData;

    const omittedFields = [
      ...priceAdjustmentFields,
      ...descriptionAdjustmentFields,
      ...promotionAdjustmentFields,
      ...disclaimerAdjustmentFields,
    ];

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.PRIMARY_PHOTO_ONLY]: {
        selectedValue: get(data, RetailBulkAdjustment.PRIMARY_PHOTO_ONLY, false),
      },
      [RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE]: {
        selectedValue: get(data, RetailBulkAdjustment.OVERLAY_IMAGE_URL),
      },
      [RetailBulkAdjustmentBuilderFields.OVERLAY_POSITION]: {
        selectedValue: get(metadata, 'mutation.retailBulkAdjustment.overlayAttributes.position', []).find(
          option =>
            option.id ===
            get(data, RetailBulkAdjustment.OVERLAY_POSITION, OverlayRetailBulkAdjustmentPosition.MIDDLE_CENTER)
        ),
      },
      [RetailBulkAdjustmentBuilderFields.SCALING_DIMENSION]: {
        selectedValue: get(
          data,
          RetailBulkAdjustment.OVERLAY_SCALING_DIMENSION,
          OverlayRetailBulkAdjustmentScalingDimension.WIDTH
        ),
      },
      [RetailBulkAdjustmentBuilderFields.SCALE]: {
        selectedValue: 100 * get(data, RetailBulkAdjustment.OVERLAY_SCALE, 0.5),
      },
    });

    hideAndOmitFields(omittedFields, this.fields);
  }

  initializeDescriptionAdjustmentFields() {
    const {
      tier: { data: currentData, formData, activeStep },
    } = this.props;
    const data = formData || currentData;

    const omittedFields = [
      ...priceAdjustmentFields,
      ...overlayAdjustmentFields,
      ...promotionAdjustmentFields,
      ...disclaimerAdjustmentFields,
    ];

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.PLACEMENT]: {
        selectedValue: data.descriptionAttributes?.placement,
      },
      [RetailBulkAdjustmentBuilderFields.TEXT]: {
        selectedValue: data.descriptionAttributes?.text,
      },
    });

    hideAndOmitFields(omittedFields, this.fields);
  }

  initializeDisclaimerAdjustmentFields() {
    const {
      tier: { data: currentData, formData, activeStep, seededData, metadata },
    } = this.props;
    const data = formData || currentData;

    const omittedFields = [
      ...priceAdjustmentFields,
      ...overlayAdjustmentFields,
      ...promotionAdjustmentFields,
      ...descriptionAdjustmentFields,
    ];

    const rooftop = data?.rooftopName || seededData?.rooftopName;
    const rooftopOemProgram = rooftop.bundle.features.oem || undefined;
    const disclaimerTemplates = normalizeAndFilterDisclaimerTemplates(
      metadata.mutation.retailBulkAdjustment.disclaimerAttributes.disclaimerTemplate,
      rooftopOemProgram
    );

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.DISCLAIMER_TEMPLATE]: {
        selectedValue: data.disclaimerAttributes?.disclaimerTemplate,
        settings: {
          insertOptions: disclaimerTemplates,
        } as MultilingualToggleInputSettings,
      },
    });

    hideAndOmitFields(omittedFields, this.fields);
  }

  initializePromotionAdjustmentFields() {
    const {
      tier: { data: currentData, formData, activeStep },
    } = this.props;
    const data = formData || currentData;

    const omittedFields = [
      ...priceAdjustmentFields,
      ...overlayAdjustmentFields,
      ...descriptionAdjustmentFields,
      ...disclaimerAdjustmentFields,
    ];

    const initialShowOnVdp = data.promotionAttributes?.showOnVdp || false;
    const initialShowOnVlp = isNil(data.promotionAttributes?.showOnVlp) ? true : data.promotionAttributes.showOnVlp;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.OPEN_LINK_IN_NEW_TAB]: {
        selectedValue: data.promotionAttributes?.openLinkInNewTab || true,
      },
      [RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP]: {
        selectedValue: initialShowOnVdp,
      },
      [RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP]: {
        selectedValue: initialShowOnVlp,
      },
      [RetailBulkAdjustmentBuilderFields.ALTERNATIVE_TEXT]: {
        selectedValue: data.promotionAttributes?.altText,
      },
      [RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP]: {
        selectedValue: isNil(data.promotionAttributes?.bannerUrl)
          ? data.promotionAttributes?.bannerLinksToVdp || false
          : null,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !isNil(data.promotionAttributes?.bannerUrl),
        }),
      },
      [RetailBulkAdjustmentBuilderFields.BANNER_URL]: {
        selectedValue: data.promotionAttributes?.bannerUrl,
      },
      [RetailBulkAdjustmentBuilderFields.BANNER_IMAGE]: {
        selectedValue: data.promotionAttributes?.bannerImageUrl,
      },
      [RetailBulkAdjustmentBuilderFields.IMAGE_POSITION]: {
        selectedValue: data.promotionAttributes?.position,
      },
    });

    hideAndOmitFields(omittedFields, this.fields);

    this.hideOrShowPromotionFields(data.promotionAttributes?.position, initialShowOnVlp);
    this.updatePromotionImagePositionOptions(initialShowOnVlp, initialShowOnVdp);
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getCurrentAdjustmentType() === RetailBulkAdjustmentType.OVERLAY) {
      void this.updateOverlayPreviewValues();
    }
  }

  getCurrentAdjustmentType(): RetailBulkAdjustmentType {
    const {
      tier: { data: currentData, formData },
    } = this.props;

    const data = formData || currentData;
    return data.type;
  }

  async updateOverlayPreviewValues() {
    const image = getStepField(RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE, this.fields).selectedValue;
    const position = getStepField(RetailBulkAdjustmentBuilderFields.OVERLAY_POSITION, this.fields).selectedValue?.id;
    const scalingDimension = getStepField(
      RetailBulkAdjustmentBuilderFields.SCALING_DIMENSION,
      this.fields
    ).selectedValue;
    const scale = getStepField(RetailBulkAdjustmentBuilderFields.SCALE, this.fields).selectedValue;

    // If the image is an uploaded File, get the image contents as a blob
    try {
      const imageUrl = isObject(image) ? await getImageDataURLFromFile(image as File) : image;

      this.setStepPreviewValue({
        imageUrl,
        position,
        scalingDimension,
        scale,
      });
    } catch (error) {
      if (error instanceof Error) {
        LoggingService.captureException(error, {
          info: 'Error getting data blob from selected image in bulk overlay adjustment',
        });
      }
    }
  }

  updateDisclaimerField(luxuryTaxEnabled: boolean) {
    const {
      tier: { data },
    } = this.props;

    const disclaimerField = getStepField(RetailBulkAdjustmentBuilderFields.DISCLAIMER, this.fields);
    const savedPriceType = get(data, RetailBulkAdjustment.PRICE_TYPE);

    if (luxuryTaxEnabled && savedPriceType === RetailPriceAdjustmentType.LUXURY_TAX) {
      disclaimerField.selectedValue = get(data, RetailBulkAdjustment.DISCLAIMER, null);
    } else if (!luxuryTaxEnabled && savedPriceType !== RetailPriceAdjustmentType.LUXURY_TAX) {
      disclaimerField.selectedValue = get(data, RetailBulkAdjustment.DISCLAIMER, null);
    } else {
      disclaimerField.selectedValue = null;
    }

    // Disclaimer label changes depending on whether the luxury tax is enabled
    disclaimerField.label = luxuryTaxEnabled ? t('luxury_tax_disclaimer') : t('disclaimer');
    disclaimerField.tooltipContents = luxuryTaxEnabled
      ? t('luxury_tax_disclaimer_tooltip')
      : t('retail_bulk_adjustment_disclaimer_tooltip');
  }

  updateAmountField(luxuryTaxEnabled: boolean) {
    const {
      tier: { data },
    } = this.props;

    const amountField = getStepField(RetailBulkAdjustmentBuilderFields.AMOUNT, this.fields);
    const savedPriceType = get(data, RetailBulkAdjustment.PRICE_TYPE);

    const amountType = isNil(get(data, RetailBulkAdjustment.FIXED))
      ? RetailAdjustmentAmountType.PERCENTAGE
      : RetailAdjustmentAmountType.FIXED;

    const amountValue = isNil(get(data, RetailBulkAdjustment.FIXED))
      ? getFormattedPercentagePrice(get(data, RetailBulkAdjustment.PERCENTAGE))
      : getFormattedFixedPrice(get(data, RetailBulkAdjustment.FIXED_AMOUNT));

    amountField.selectedValue =
      !luxuryTaxEnabled && savedPriceType === RetailPriceAdjustmentType.USER
        ? { id: amountType, value: amountValue }
        : { id: RetailAdjustmentAmountType.FIXED };
  }

  hideOrShowLuxuryTaxFields(luxuryTaxEnabled: boolean) {
    const amountField = getStepField(RetailBulkAdjustmentBuilderFields.AMOUNT, this.fields);

    // If the luxury tax is enabled, then hide the amount field
    setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: luxuryTaxEnabled }, amountField);

    if (luxuryTaxEnabled) {
      // Once the luxury tax is enabled, clear any defined values for the amount and percentage/fixed fields
      amountField.selectedValue = null;
      getStepField(RetailBulkAdjustmentBuilderFields.PERCENTAGE, this.fields).selectedValue = null;
      getStepField(RetailBulkAdjustmentBuilderFields.FIXED, this.fields).selectedValue = null;
    }
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === RetailBulkAdjustmentBuilderFields.IMAGE_POSITION) {
      const linkToVlp = getStepField(RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP, this.fields).selectedValue;
      this.hideOrShowPromotionFields(value.id, linkToVlp);
    }
    super.onFieldSelection(stepField, value);

    if (this.getCurrentAdjustmentType() === RetailBulkAdjustmentType.OVERLAY) {
      void this.updateOverlayPreviewValues();
    }
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    super.onFieldChange(stepField, e);

    if (this.getCurrentAdjustmentType() === RetailBulkAdjustmentType.OVERLAY) {
      void this.updateOverlayPreviewValues();
    }

    switch (stepField.queryVar) {
      case RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE: {
        const discountOrFeeField = getStepField(RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE, this.fields);
        const enableLuxuryTaxField = getStepField(RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX, this.fields);

        // If the user has selected a FEE adjustment, then show the 'enable luxury tax' field
        const isDiscount = discountOrFeeField.selectedValue === RetailAdjustmentAmountAdjustmentType.DISCOUNT;
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: isDiscount }, enableLuxuryTaxField);

        if (isDiscount) {
          // If a DISCOUNT was selected, then clear the current value of the luxury tax field
          enableLuxuryTaxField.selectedValue = false;
        }

        this.hideOrShowLuxuryTaxFields(false);
        this.updateDisclaimerField(false);
        this.updateAmountField(false);
        break;
      }

      case RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX: {
        this.hideOrShowLuxuryTaxFields(e.currentTarget.value);
        this.updateDisclaimerField(e.currentTarget.value);
        this.updateAmountField(e.currentTarget.value);
        break;
      }

      case RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP:
      case RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP: {
        const linkToVdp = getStepField(RetailBulkAdjustmentBuilderFields.SHOW_ON_VDP, this.fields).selectedValue;
        const linkToVlp = getStepField(RetailBulkAdjustmentBuilderFields.SHOW_ON_VLP, this.fields).selectedValue;

        this.updatePromotionImagePositionOptions(linkToVlp, linkToVdp);
        this.hideOrShowPromotionFields(
          getStepField(RetailBulkAdjustmentBuilderFields.IMAGE_POSITION, this.fields).selectedValue,
          linkToVlp
        );
        break;
      }

      case RetailBulkAdjustmentBuilderFields.BANNER_URL: {
        const bannerLinksToVdpField = getStepField(RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP, this.fields);
        const bannerUrlField = getStepField(RetailBulkAdjustmentBuilderFields.BANNER_URL, this.fields);

        setDisplayTypes(
          { type: StepFieldDisplayType.DISABLED, active: !!bannerUrlField.selectedValue },
          bannerLinksToVdpField
        );
        bannerLinksToVdpField.selectedValue = bannerUrlField.selectedValue ? null : false;

        this.forceUpdate();
        break;
      }
    }
  }

  updatePromotionImagePositionOptions(linkToVlp: boolean, linkToVdp: boolean) {
    const {
      tier: { metadata },
    } = this.props;

    const allImagePositionOptions = get(metadata, 'mutation.retailBulkAdjustment.promotionAttributes.position', []);
    const imagePositionField = getStepField(RetailBulkAdjustmentBuilderFields.IMAGE_POSITION, this.fields);

    setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !linkToVlp && !linkToVdp }, imagePositionField);

    if ((linkToVlp && !linkToVdp) || (linkToVlp && linkToVdp)) {
      imagePositionField.options = allImagePositionOptions.filter(
        option => option.id !== PromotionRetailBulkAdjustmentPosition.BOTTOM
      );
      if (imagePositionField.selectedValue?.id === PromotionRetailBulkAdjustmentPosition.BOTTOM) {
        imagePositionField.selectedValue = null;
      }
    } else if (linkToVdp && !linkToVlp) {
      imagePositionField.options = allImagePositionOptions;
    } else {
      imagePositionField.selectedValue = null;
    }
  }

  hideOrShowPromotionFields(imagePosition: PromotionRetailBulkAdjustmentPosition, linkToVlp: boolean) {
    const bannerLinkToVdpField = getStepField(RetailBulkAdjustmentBuilderFields.BANNER_LINKS_TO_VDP, this.fields);
    const bannerUrlField = getStepField(RetailBulkAdjustmentBuilderFields.BANNER_URL, this.fields);
    const isVisible = isBannerLinksToVdpFieldVisible(imagePosition, linkToVlp);

    setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !isVisible }, bannerLinkToVdpField);
    if (isVisible && !bannerUrlField.selectedValue && bannerLinkToVdpField.selectedValue === null) {
      bannerLinkToVdpField.selectedValue = false;
    }
  }

  async save() {
    const {
      tier: { data: currentData, formData },
    } = this.props;

    const type = currentData?.type || formData?.type;
    const overrideVariables: {
      priceAttributes?: PriceRetailBulkAdjustmentAttributesInput;
      overlayAttributes?: OverlayRetailBulkAdjustmentAttributesInput;
      promotionAttributes?: PromotionRetailBulkAdjustmentAttributesInput;
      disclaimerAttributes?: DisclaimerRetailBulkAdjustmentAttributesInput;
    } = {};

    switch (type) {
      case RetailBulkAdjustmentType.PRICE: {
        const luxuryTaxField = getStepField(RetailBulkAdjustmentBuilderFields.ENABLE_LUXURY_TAX, this.fields);

        const isNegativeAmount =
          getStepField(RetailBulkAdjustmentBuilderFields.DISCOUNT_OR_FEE, this.fields).selectedValue ===
          RetailAdjustmentAmountAdjustmentType.DISCOUNT;

        const paymentOptions = getPaymentOptions(
          getStepField(RetailBulkAdjustmentBuilderFields.INCLUDE_CASH, this.fields),
          getStepField(RetailBulkAdjustmentBuilderFields.INCLUDE_FINANCE, this.fields),
          getStepField(RetailBulkAdjustmentBuilderFields.INCLUDE_LEASE, this.fields)
        );

        if (!luxuryTaxField.selectedValue) {
          // This util will update the PERCENTAGE and FIXED fields with the correct values
          setFixedAndPercentageValuesFromToggleFieldState(
            getStepField(RetailBulkAdjustmentBuilderFields.FIXED, this.fields, true),
            getStepField(RetailBulkAdjustmentBuilderFields.PERCENTAGE, this.fields, true),
            getStepField(RetailBulkAdjustmentBuilderFields.AMOUNT, this.fields, true),
            isNegativeAmount
          );
        }

        overrideVariables.priceAttributes = {
          paymentOptions,
          // If the luxury tax has been enabled, then the type of adjustment is LUXURY_TAX
          type: luxuryTaxField.selectedValue ? RetailPriceAdjustmentType.LUXURY_TAX : RetailPriceAdjustmentType.USER,
        };
        break;
      }

      case RetailBulkAdjustmentType.PROMOTION: {
        const imageField = getStepField(RetailBulkAdjustmentBuilderFields.BANNER_IMAGE, this.fields);

        overrideVariables.promotionAttributes = {
          // If the user hasn't selected a new image, then send null
          bannerImage: isObject(imageField.selectedValue) ? (imageField.selectedValue as File) : null,
        };
        break;
      }

      case RetailBulkAdjustmentType.OVERLAY: {
        const imageField = getStepField(RetailBulkAdjustmentBuilderFields.OVERLAY_IMAGE, this.fields);
        const scaleField = getStepField(RetailBulkAdjustmentBuilderFields.SCALE, this.fields);

        overrideVariables.overlayAttributes = {
          // If the user has not uploaded a new image, then send null
          overlayImage: isObject(imageField.selectedValue) ? (imageField.selectedValue as File) : null,
          // Scale percentage should be between 0 and 1
          scale: scaleField.selectedValue / 100,
        };
        break;
      }

      case RetailBulkAdjustmentType.DISCLAIMER: {
        const disclaimerField = getStepField(RetailBulkAdjustmentBuilderFields.DISCLAIMER_TEMPLATE, this.fields);

        overrideVariables.disclaimerAttributes = {
          disclaimerTemplate: disclaimerField.selectedValue,
        };
        break;
      }
    }

    return super.save(undefined, overrideVariables);
  }
}

export default RetailBulkAdjustmentStep;
