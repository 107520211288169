import { useCallback, useEffect, useMemo, useState } from 'react';

import type { DateTime } from 'luxon';

import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import { translate } from 'utils/intlUtils';

import NumberList, { NumberLists } from './NumberList';

export interface DateListPickerSettings {
  /** The earliest date the user can select */
  minDate?: DateTime;
  /** The earliest year to show in the year list, defaults to 100 years before maxYear **/
  minYear?: number;
  /** The latest year to show in the year list, defaults to today's year **/
  maxYear?: number;
}

type YMDObject = {
  year?: number;
  month?: number;
  day?: number;
};

const getDaysInMonth = ({ year, month }: YMDObject) =>
  getDateTime()
    .set({ year: year || getDateTime().year })
    .set({ month }).daysInMonth;

const shortMonthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

interface Props {
  settings?: DateListPickerSettings;
  /** Local date in the format 'uuuu-MM-dd'T'HH:mm:ss.SSS' (ISO-8601) */
  selectedDate?: string;
  /** Callback when date is clicked, emits ISO-8601 date string */
  onChange?: (date: string | undefined) => void;
}

const DateListPicker = ({ onChange, selectedDate, settings }: Props) => {
  const { minDate, minYear, maxYear } = settings || {};
  const [selectedYMD, setSelectedYMD] = useState<YMDObject>(() => {
    const { year, month, day } = getDateTime(selectedDate) || {};
    return { year, month, day };
  });
  const daysInSelectedMonth = useMemo(() => (selectedYMD.month ? getDaysInMonth(selectedYMD) : 31), [selectedYMD]);
  const monthNumberLabels = useMemo(
    () =>
      shortMonthNames.reduce((labels, monthName, index) => {
        labels[index + 1] = translate.t(monthName);
        return labels;
      }, {}),
    []
  );
  const yearRange = useMemo(() => {
    const today = getDateTime();
    return {
      maxYear: maxYear || today.year,
      minYear: minDate?.year || minYear || today.minus({ years: 100 }).year,
    };
  }, [minYear, maxYear, minDate?.year]);
  const emitOnChange = useCallback(
    ({ year, month, day }: YMDObject) =>
      onChange?.(getFormattedDateTimeString(getDateTime().set({ year }).set({ month }).set({ day }))),
    [onChange]
  );
  const onSelectDateUnit = useCallback(
    (unitOption: Record<string, unknown>) => {
      const newSelectedYMD = { ...selectedYMD, ...unitOption };
      const { year, month, day } = newSelectedYMD;

      newSelectedYMD.day = month && day && getDaysInMonth(newSelectedYMD) < day ? 1 : day;
      if (year && month && newSelectedYMD.day) {
        emitOnChange(newSelectedYMD);
      }
      setSelectedYMD(newSelectedYMD);
    },
    [emitOnChange, selectedYMD]
  );
  const onSelectYear = useCallback(year => onSelectDateUnit({ year }), [onSelectDateUnit]);
  const onSelectMonth = useCallback(month => onSelectDateUnit({ month }), [onSelectDateUnit]);
  const onSelectDay = useCallback(day => onSelectDateUnit({ day }), [onSelectDateUnit]);
  const prevSelectedDate = usePrevious(selectedDate);

  useEffect(() => {
    if (prevSelectedDate !== selectedDate) {
      const selectedDateTime = getDateTime(selectedDate);
      const { year, month, day } = selectedDateTime || {};

      setSelectedYMD({ year, month, day });
    }
  }, [selectedDate, prevSelectedDate]);

  useMountEffect(() => emitOnChange(selectedYMD));

  return (
    <NumberLists>
      <NumberList
        fromNumber={yearRange.minYear}
        toNumber={yearRange.maxYear}
        onSelectNumber={onSelectYear}
        selectedNumber={selectedYMD.year}
        isReversed
      />
      <NumberList
        fromNumber={minDate?.month || 1}
        toNumber={12}
        onSelectNumber={onSelectMonth}
        numberLabels={monthNumberLabels}
        selectedNumber={selectedYMD.month}
      />
      <NumberList
        fromNumber={minDate?.day || 1}
        toNumber={daysInSelectedMonth}
        onSelectNumber={onSelectDay}
        selectedNumber={selectedYMD.day}
      />
    </NumberLists>
  );
};

export default DateListPicker;
