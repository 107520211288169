import { gql } from '@apollo/client';

import { incentive } from 'store/api/graph/fragments/incentive';
import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';
import { retailBulkAdjustment } from 'store/api/graph/fragments/retailBulkAdjustment';
import { retailPriceAdjustment } from 'store/api/graph/fragments/retailPriceAdjustment';

import { MultilingualString } from './multilingualString';

export const retailPricing = gql`
  fragment RetailPricingFragment on RetailPricing {
    purchasePrice {
      ...MonetaryAmountFragment
    }
    startingPrice {
      ...MonetaryAmountFragment
    }
    priceAdjustments {
      ...RetailPriceAdjustmentFragment
    }
    priceBulkAdjustments {
      ...RetailBulkAdjustmentFragment
    }
    incentives {
      ...IncentiveFragment
    }
    rooftopRetailPricing {
      id
      name
      rooftop {
        id
        name {
          ...MultilingualStringFragment
        }
      }
    }
    purchasePriceBelowMin
    ending
  }
  ${monetaryAmount}
  ${retailPriceAdjustment}
  ${retailBulkAdjustment}
  ${MultilingualString}
  ${incentive}
`;
