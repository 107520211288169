import { createContext } from 'react';

type GalleryData = {
  /** An array of photos to view in the lightbox gallery */
  photos?: { lightboxPhoto?: string; gridPhoto: string }[];
  /** The index of the current photo being viewed, based off of the  `photos` array */
  currentPhotoIndex: number;
};

interface PhotoPreviewContext {
  /** The gallery data being viewed */
  galleryData?: GalleryData;
  /** Method to update gallery data with completely new data */
  setPhotoGallery: (data: GalleryData) => void;
  /** Method to simply clear any existing gallery data */
  closePhotoGallery: () => void;
}

/**
 * The context class used to determine what is begin viewed in the `PhotoPreviewsContainer`
 */
export const PhotoPreviewContext = createContext<PhotoPreviewContext | undefined>(undefined);
