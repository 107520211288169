import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

class LeadNotesStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { data, activeStep },
    } = props;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // Defaults
      leadNote: {
        selectedValue: data?.leadNote,
      },
    });
  }
}

export default LeadNotesStep;
