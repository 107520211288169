import styled from 'styled-components/macro';

import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500, NEUTRAL_0 } from 'styles/tokens';

interface CounterInput {
  /** The length of the selectedValue of the input */
  count: number;
  /** An id for testing */
  testId?: string;
}

const CounterInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CounterElement = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 20%;
  color: ${NEUTRAL_0};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  background-color: ${BLUE_500};
`;

const CounterInput = ({ count, testId }: CounterInput) => (
  <CounterInputContainer data-testid={testId}>
    {count === 0 ? <ChevronRightIcon color={BODY_TEXT} /> : <CounterElement>{count}</CounterElement>}
  </CounterInputContainer>
);

export default CounterInput;
