import { useMemo } from 'react';

import type { ApolloQueryResult } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { get, merge, set } from 'lodash-es';

import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import LoggingService from 'components/core/logging/LoggingService';
import { getParametersForMetadata } from 'components/sections/createModify/websiteRoutes/websiteRouteFilterUtils';
import { ApolloFetchPolicy } from 'enums/apollo';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { Lead } from 'enums/columns/lead';
import { User } from 'enums/columns/user';
import type { ExtendedEntityType } from 'enums/extendedEntityType';
import { useQuery } from 'hooks/useQuery';
import type {
  ContactType,
  FeatureSetInput,
  IntegrationOemAccountExportWebsiteBrandQuery,
  IntegrationOemAccountExportWebsiteBrandQueryVariables,
  IntegrationType,
  IntegrationTypeQuery,
  IntegrationTypeQueryVariables,
  IntegrationWebsiteConnectionQuery,
  IntegrationWebsiteConnectionQueryVariables,
  Lender,
  RegionCode,
  ResourceType,
  RetailItemConnectionFilterInput,
  SelectStringOptionFragment,
  SortInput,
  TradeInItemConnectionFilterInput,
  UserConnectionFilterInput,
  WebsiteDetailFragment,
  WebsiteDetailsContainerQuery,
  WebsiteDetailsMetaQuery,
  WebsiteDetailsMetaQueryVariables,
  WebsitePageTypeQuery,
  WebsitePageTypeQueryVariables,
  WebsiteRetailFilterMetaquery,
  WebsiteSortOptionsQuery,
  WebsiteSortOptionsQueryVariables,
} from 'store/api/graph/interfaces/types';
import { RetailItemStatus, SortDirection } from 'store/api/graph/interfaces/types';
import type {
  ContactResponseType,
  LeadResponseType,
  UserResponseType,
  UserType,
} from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

import {
  contactListQuery,
  groupsListQuery,
  integrationOemAccountExportWebsiteBrand,
  integrationsQuery,
  integrationType,
  integrationWebsiteConnectionQuery,
  inventoryItemsListQuery,
  itemTagsQuery,
  leadListQuery,
  leadSourceQuery,
  lenderListQuery,
  makesQuery,
  modelsQuery,
  rolesListQuery,
  rooftopMetaQuery,
  subModelsQuery,
  trimsQuery,
  usersAssignableQuery,
  usersListQuery,
  vehicleManufacturerOptionsMetaQuery,
  vehicleStandardEquipmentOptionsMetaQuery,
  vehicleTechnicalSpecificationsOptionsMetaQuery,
  websiteDetailsMetaQuery,
  websitePageType,
  websiteSortOptionsQuery,
  whiteLabelsQuery,
  yearsQuery,
} from './ItemMetaQueries';

export enum YMMTTarget {
  YEAR = 'year',
  MAKE_ID = 'makeId',
  MODEL_ID = 'modelId',
  SUB_MODEL_ID = 'subModelId',
  TRIM_ID = 'trimId',
}

export const getYMMTOptions: (variables: any, target: YMMTTarget) => Promise<StepFieldOptions[]> = async (
  variables,
  target
) => {
  const {
    query,
    accessor = 'options',
    options = [],
  } = [
    {
      target: YMMTTarget.YEAR,
      query: yearsQuery,
      accessor: `metadata.mutation.inventoryItem.options`,
    },
    {
      target: YMMTTarget.MAKE_ID,
      query: makesQuery,
    },
    {
      target: YMMTTarget.MODEL_ID,
      query: modelsQuery,
    },
    {
      target: YMMTTarget.SUB_MODEL_ID,
      query: subModelsQuery,
    },
    {
      target: YMMTTarget.TRIM_ID,
      query: trimsQuery,
      options: [{ id: null, name: translate.t('other_not_sure') }],
    },
  ].find(item => item.target === target) || {};

  if (!query) {
    LoggingService.debug({ message: `Invalid YMMT target: ${target}` });
    return [];
  }

  // If there is no given year for the trim query, then just return the default options
  if (target === YMMTTarget.TRIM_ID && !variables.year) {
    return options;
  }

  const { data } = await client.query({ query, variables });

  return get(data, accessor).concat(options);
};

/**
 * Get the list of available rooftops for a whitelabel scoped user. The rooftops come from calling the rooftopMetaQuery,
 * which will query for available rooftops. Various filters can be passed, including group, keyword search, and feature
 * availability
 */
interface RooftopOptionsForWhitelabelScopedUserParams {
  /** Filter rooftops by keyword */
  keyword?: string;
  /** Filter rooftops by a particular group */
  groupId?: string;
  /** Filter rooftops by active status */
  active?: boolean;
  /** Filter rooftops by feature availability */
  features?: FeatureSetInput;
}

export const getRooftopOptionsForWhitelabelScopedUser: (
  args: RooftopOptionsForWhitelabelScopedUserParams
) => Promise<StepFieldOptions[]> = async ({ keyword, groupId, active, features }) => {
  const response = await client.query({
    query: rooftopMetaQuery,
    variables: {
      sort: [
        {
          id: 'name',
          sortDirection: SortDirection.ASCENDING,
        },
      ],
      active,
      keyword,
      groupId,
      features,
    },
  });
  return (
    response.data?.rooftopConnection?.edges?.map(edge => ({
      ...edge.node,
    })) || []
  );
};

/**
 * Get the list of available rooftops for a non-whitelabel scoped user. The rooftops come from the User object, an
 * additional function can be passed to filter out these rooftops based on feature availability.
 */
interface RooftopOptionsForNonWhitelabelScopedUserParams {
  /** The User we are getting available rooftops for */
  user: UserType;
  /** Function to filter available rooftops by feature availability */
  filterRooftopsByFeatureFunction: (rooftop) => boolean;
}

export const getRooftopOptionsForNonWhitelabelScopedUser: (
  args: RooftopOptionsForNonWhitelabelScopedUserParams
) => StepFieldOptions[] = ({ user, filterRooftopsByFeatureFunction }) =>
  (user?.rooftops?.filter(filterRooftopsByFeatureFunction) || []) as StepFieldOptions[];

interface RooftopOptionsParams {
  user: UserType;
  keyword?: string;
  isWhiteLabelScoped?: boolean;
  groupId?: string;
  active?: boolean;
  whiteLabelId?: string;
  sort?: SortInput[];
}

export const getRooftopOptions: (args: RooftopOptionsParams) => Promise<StepFieldOptions[]> = async ({
  user,
  keyword,
  isWhiteLabelScoped,
  groupId,
  whiteLabelId,
  active = true,
  sort = [
    {
      id: 'name',
      sortDirection: SortDirection.ASCENDING,
    },
  ],
}) => {
  if (isWhiteLabelScoped) {
    // Client request
    const response = await client.query({
      query: rooftopMetaQuery,
      variables: { active, keyword, sort, groupId, whiteLabelId },
    });
    return (
      response.data?.rooftopConnection?.edges?.map(edge => ({
        ...edge.node,
      })) || []
    );
  } else {
    // Use cached rooftops
    return user.group?.rooftops;
  }
};

export const getLeadOptions: (
  rooftopId: string,
  keyword?: string,
  sort?: SortInput[]
) => Promise<LeadResponseType[]> = async (
  rooftopId,
  keyword,
  sort = [
    { id: Lead.FIRST_NAME, sortDirection: SortDirection.ASCENDING },
    { id: Lead.LAST_NAME, sortDirection: SortDirection.ASCENDING },
  ]
) => {
  // Client request
  const response = await client.query({
    query: leadListQuery,
    variables: { keyword, rooftopId: [rooftopId], sort, archived: false, spam: false },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return (
    response.data?.leadConnection?.edges?.map(edge => ({
      ...edge.node,
      // For search functionality
      name: edge.node.displayName,
    })) || []
  );
};

export const getLenderOptions: (regionCode: RegionCode) => Promise<StepFieldOptions[]> = async regionCode => {
  // Client request
  const response = await client.query({
    query: lenderListQuery,
    variables: { regionCode },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return (
    response.data?.lenders?.map((lender: Lender) => ({
      id: lender.id,
      name: lender.name,
      data: lender,
    })) || []
  );
};

export const getTagOptions: ({
  entityType,
  rooftopId,
}: {
  entityType: ExtendedEntityType;
  rooftopId?: string[];
}) => Promise<StepFieldOptions[]> = async args => {
  const rooftops = args.rooftopId?.filter(Boolean);
  // Client request
  const response = await client.query({
    query: itemTagsQuery,
    variables: { rooftopId: rooftops?.length ? rooftops : undefined, type: args.entityType },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.tags;
};

/**
 * TODO [#579]: 1) Remove fetch policy
 */
export const getContactOptions: (
  type: ContactType,
  rooftopId: string,
  archived?: boolean,
  keyword?: string
) => Promise<ContactResponseType[]> = async (type, rooftopId, archived = false, keyword) => {
  const response = await client.query({
    query: contactListQuery,
    variables: { keyword, type: [type], rooftopId, archived },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return (
    response.data?.contactConnection?.edges?.map(edge => ({
      ...edge.node,
      // For search functionality
      name: edge.node.displayName,
    })) || []
  );
};

/**
 * TODO [#844]: 1) Remove fetch policy
 */
export const getGroupOptions: (keyword?: string) => Promise<StepFieldOptions[]> = async keyword => {
  // Client request
  const response = await client.query({
    query: groupsListQuery,
    variables: { keyword },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.groupConnection?.edges.map(edge => ({ ...edge.node })) || [];
};

/**
 * TODO [#844]: 1) Remove fetch policy
 */
export const getUserOptions: (
  keyword?: string,
  filter?: UserConnectionFilterInput,
  sort?: SortInput[],
  query?: DocumentNode
) => Promise<UserResponseType[]> = async (
  keyword = '',
  filter = {},
  sort = [
    { id: User.FIRST_NAME, sortDirection: SortDirection.ASCENDING },
    { id: User.LAST_NAME, sortDirection: SortDirection.ASCENDING },
  ],
  query = usersListQuery
) => {
  const response = await client.query({
    query,
    variables: { keyword, filter: { ...filter, active: true }, sort },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return (
    response.data?.userConnection?.edges?.map(edge => ({
      ...edge.node,
      // For search functionality
      name: edge.node.displayName,
    })) || []
  );
};

/**
 * TODO [#844]: 1) Remove fetch policy
 *
 * @param retailFilter Defaults to non-archived under the given rooftopId
 * @param tradeInFilter Defaults to non-archived under the given rooftopId
 */
export const getInventoryItemOptions: (
  rooftopId: string,
  keyword?: string,
  sort?: SortInput[],
  isRetailAvailable?: boolean,
  isTradeInAvailable?: boolean,
  retailFilter?: RetailItemConnectionFilterInput,
  tradeInFilter?: TradeInItemConnectionFilterInput
) => Promise<ContactResponseType[]> = async (
  rooftopId,
  keyword = '',
  sort = [{ id: InventoryItem.UPDATED, sortDirection: SortDirection.DESCENDING }],
  isRetailAvailable = true,
  isTradeInAvailable = true,
  retailFilter = {},
  tradeInFilter = {}
) => {
  const response = await client.query({
    query: inventoryItemsListQuery,
    variables: {
      keyword,
      retailItemFilter: {
        rooftopId,
        archived: false,
        status: RetailItemStatus.FOR_SALE,
        ...retailFilter,
      },
      tradeInItemFilter: {
        rooftopId,
        archived: false,
        ...tradeInFilter,
      },
      sort,
      d_retailOn: isRetailAvailable,
      d_tradeInOn: isTradeInAvailable,
    },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  const metadata = response.data.metadata.mutation.item;
  return (
    response.data?.retailItemConnection?.edges?.map(edge => ({
      ...edge.node,
      metadata,
    })) || []
  ).concat(
    response.data?.tradeInItemConnection?.edges?.map(edge => ({
      ...edge.node,
      metadata,
    })) || []
  );
};

export const getLeadSourceOptions: (rooftopId: string) => Promise<StepFieldOptions[]> = async rooftopId => {
  const response = await client.query({
    query: leadSourceQuery,
    variables: { rooftopId },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.leadSources || [];
};

export const getRoleOptions = async (groupId?: string): Promise<any> => {
  const response = await client.query({
    query: rolesListQuery,
    variables: { id: groupId },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });

  return (
    response.data?.roles.map(({ id, name: { value }, permissions }) => ({
      id,
      name: value,
      // Mapping permissions to builder readable sub values
      permissions: permissions.map(({ resource, level, levelName }) => ({
        id: level,
        name: levelName,
        resource,
      })),
    })) || []
  );
};

export const getIntegrationOptions: (type?: IntegrationType, premium?: boolean) => Promise<any> = async (
  type,
  premium
) => {
  const response = await client.query({
    query: integrationsQuery,
    variables: {
      type,
      premium,
    },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.integrations || [];
};

export const getWhiteLabels: () => Promise<StepFieldOptions[]> = async () => {
  const response = await client.query({
    query: whiteLabelsQuery,
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.whiteLabels || [];
};

export const getManufacturerOptions: (trimId: string) => Promise<any> = async trimId => {
  const response = await client.query({
    query: vehicleManufacturerOptionsMetaQuery,
    fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    variables: { trimId },
  });
  return response.data?.trimSpecification.attributes.manufacturerOptions;
};

export const getTechnicalSpecificationsOptions: (trimId: string) => Promise<any> = async trimId => {
  const response = await client.query({
    query: vehicleTechnicalSpecificationsOptionsMetaQuery,
    fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    variables: { trimId },
  });
  return response.data?.trimSpecification.technicalSpecifications;
};

export const getStandardEquipmentOptions: (trimId: string) => Promise<any> = async trimId => {
  const response = await client.query({
    query: vehicleStandardEquipmentOptionsMetaQuery,
    fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    variables: { trimId },
  });
  return response.data?.trimSpecification.standardEquipment;
};

export const getUsersAssignable: (rooftopId: string, resource: ResourceType) => Promise<UserResponseType[]> = async (
  rooftopId,
  resource
) => {
  const response = await client.query({
    query: usersAssignableQuery,
    variables: { rooftopId, resource },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  });
  return response.data?.usersAssignable.map(user => ({
    ...user,
    // For search functionality
    name: user.displayName,
  }));
};

/**
 * Filter pricingSource metadata options by rooftop.bundle.addOns.oem
 */
export const getPricingSourceOptions: (
  rooftopOem: string,
  metadataOptions: SelectStringOptionFragment[],
  featureFlagOn?: boolean
) => any[] = (rooftopOem, metadataOptions, featureFlagOn = false) => {
  const options = metadataOptions.filter(option => {
    const { data } = option;

    if (!data) {
      return true;
    }

    try {
      const { oemProgram } = JSON.parse(data);

      return oemProgram === null || (featureFlagOn && oemProgram === rooftopOem);
    } catch (error) {
      // Suppress error
      console.debug(option, error);
      return true;
    }
  });

  return options || [];
};

export const getWebsites = async (groupId: string, keyword: string) => {
  const response = await client.query<IntegrationWebsiteConnectionQuery, IntegrationWebsiteConnectionQueryVariables>({
    query: integrationWebsiteConnectionQuery,
    variables: { first: 50, filter: { groupId }, keyword },
    fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
  });

  return response.data.websiteConnection.edges.map(({ node }) => ({ ...node }));
};

export const getIntegrationType = async () => {
  const { data, loading, error } = await client.query<IntegrationTypeQuery, IntegrationTypeQueryVariables>({
    query: integrationType,
  });
  return loading || error ? [] : data.metadata.mutation.rooftopIntegration.integration.type;
};

export const getOemAccountExportWebsiteBrands = async () => {
  const { data, loading, error } = await client.query<
    IntegrationOemAccountExportWebsiteBrandQuery,
    IntegrationOemAccountExportWebsiteBrandQueryVariables
  >({
    query: integrationOemAccountExportWebsiteBrand,
  });
  return loading || error ? [] : data.metadata.mutation.rooftopIntegration.oemAccountExportAttributes.websiteBrand;
};

export const getWebsiteSortOptionsFromMetadata = (
  websiteSortOptions: WebsiteRetailFilterMetaquery['websiteSortOptions'] = []
) =>
  websiteSortOptions
    .filter(({ customOrderOptions }) => !(Array.isArray(customOrderOptions) && customOrderOptions.length === 0))
    .map(({ name, ...option }) => ({
      id: `${option.columnId}-${option.direction}`,
      name,
      data: { ...option },
    }));

export const getWebsiteSortOptions = async (groupRooftopIds?: string[]) => {
  const response = await client.query<WebsiteSortOptionsQuery, WebsiteSortOptionsQueryVariables>({
    query: websiteSortOptionsQuery,
    variables: { id: groupRooftopIds },
  });

  return getWebsiteSortOptionsFromMetadata(response.data?.websiteSortOptions);
};

/**
 *  Top level `metadata` from response not used, contents are
 *  instead spread for builder `metadata` structure consistency
 */
export const flattenMetadataResponse = <T extends ApolloQueryResult<Record<string, any>>>(
  res: T
): Record<string, any> & { metadata: undefined } => ({
  ...res.data.metadata,
  ...res.data,
  metadata: undefined,
});

export const getWebsitePageType = async () => {
  const { data } = await client.query<WebsitePageTypeQuery, WebsitePageTypeQueryVariables>({
    query: websitePageType,
  });
  return data.metadata.mutation.rooftopIntegration.cppAttributes.websitePageType;
};

export const useWebsiteDetailsMetaQueryFromWebsite = (
  website: WebsiteDetailFragment | undefined,
  websiteMetadata: WebsiteDetailsContainerQuery['metadata']
) => {
  const { groupId, groupRooftopIds, rooftopIds, makeIds, modelIds, subModelIds } = getParametersForMetadata(website);
  const { isLoaded, data, ...rest } = useQuery<WebsiteDetailsMetaQuery, WebsiteDetailsMetaQueryVariables>(
    websiteDetailsMetaQuery,
    {
      variables: {
        groupId,
        rooftopIds,
        groupRooftopIds,
        makeIds,
        modelIds,
        /**
         * Implicit checks, if the details contains data for the specific MMS,
         * then it can be assumed that the dependant Make/Models also exist for that call
         */
        d_makesOn: !!makeIds?.length,
        d_modelsOn: !!modelIds?.length,
        d_subModelsOn: !!subModelIds?.length,
        d_websiteSortOptionsOn: !!groupRooftopIds?.length,
      },
      options: {
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      },
    }
  );

  const metadata = useMemo<
    WebsiteDetailsContainerQuery['metadata'] & {
      mutation: {
        rooftopId?: WebsiteDetailsMetaQuery['group']['rooftops'];
      };
      websiteSortOptions?: WebsiteDetailsMetaQuery['websiteSortOptions'];
    }
  >(() => {
    if (isLoaded) {
      const combinedMetadata = merge({}, websiteMetadata);

      set(combinedMetadata, 'mutation.rooftopId', data?.group?.rooftops);
      set(combinedMetadata, 'mutation.tagId', data?.tags);
      set(combinedMetadata, 'mutation.makeId', data?.makes);
      set(combinedMetadata, 'mutation.makeIdExcluded', data?.makes);
      set(combinedMetadata, 'mutation.modelId', data?.models);
      set(combinedMetadata, 'mutation.subModelId', data?.subModels);
      set(combinedMetadata, 'mutation.status', data?.metadata?.mutation?.inventoryItem?.retailItem?.status);
      set(
        combinedMetadata,
        'mutation.vehicleAttributes.bodyType',
        data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.bodyType
      );
      set(
        combinedMetadata,
        'mutation.vehicleAttributes.fuelType',
        data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.fuelType
      );
      set(combinedMetadata, 'mutation.condition', data?.metadata?.mutation?.inventoryItem?.condition);
      set(combinedMetadata, 'websiteSortOptions', data?.websiteSortOptions);

      return combinedMetadata;
    }

    return websiteMetadata;
  }, [
    data?.group?.rooftops,
    data?.makes,
    data?.metadata?.mutation?.inventoryItem?.condition,
    data?.metadata?.mutation?.inventoryItem?.retailItem?.status,
    data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.bodyType,
    data?.metadata?.mutation?.inventoryItem?.vehicleAttributes?.fuelType,
    data?.models,
    data?.subModels,
    data?.tags,
    data?.websiteSortOptions,
    isLoaded,
    websiteMetadata,
  ]);

  return {
    ...rest,
    data: metadata,
    isLoaded,
  };
};
