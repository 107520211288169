import styled, { css } from 'styled-components/macro';

import type { Column } from 'store/api/graph/interfaces/types';
import { SortDirection } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { FONT_SIZE_9, FONT_WEIGHT_BOLD } from 'styles/typography';

import SortDirectionDownIcon from '../icons/SortDirectionDownIcon';
import SortDirectionsIcon from '../icons/SortDirectionsIcon';
import SortDirectionUpIcon from '../icons/SortDirectionUpIcon';

const SortContainer = styled.span<{ isSorted: boolean }>`
  display: flex;
  margin: 4px 8px;
  align-items: center;

  ${({ isSorted }) => css`
    color: ${isSorted ? BLUE_500 : BODY_TEXT};
    fill: ${isSorted ? BLUE_500 : BODY_TEXT};
  `}
  > span {
    font-size: ${FONT_SIZE_9};
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`;

type Props = Pick<Column, 'sortDirection' | 'sortPriority'>;

/**
 * A generic sort icon that renders based on a passed sortDirection state
 */
const SortableIcon = ({ sortDirection, sortPriority }: Props) => {
  const isSorted = !!sortDirection;

  const directionIcon =
    sortDirection === SortDirection.DESCENDING ? <SortDirectionDownIcon /> : <SortDirectionUpIcon />;

  return (
    <SortContainer isSorted={isSorted}>
      {isSorted ? directionIcon : <SortDirectionsIcon />}
      {isSorted && <span>{sortPriority}</span>}
    </SortContainer>
  );
};

export default SortableIcon;
