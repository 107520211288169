import { gql } from '@apollo/client';

import { carfaxReport } from 'store/api/graph/fragments/carfaxReport';
import { userList } from 'store/api/graph/fragments/userList';
import { inventoryItemVideo } from 'store/api/graph/fragments/video';

import { listPhoto } from './listPhoto';
import { motorcycleAttributes } from './motorcycleAttributes';
import { MultilingualString } from './multilingualString';
import { inventoryItemPhoto } from './photos';
import { rooftopList } from './rooftopList';
import { trimFragment } from './trimFragment';
import { vehicleAttributes } from './vehicleAttributes';

export const inventoryItemDetail = gql`
  fragment InventoryItemDetailFragment on InventoryItem {
    id
    year
    note
    createdByName: createdBy {
      ...UserListFragment
    }
    makeName: make {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    modelName: model {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModelName: subModel {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    rooftop {
      ...RooftopListFragment
      carfaxId
    }
    trimName: trim {
      ...TrimFragment
    }
    type
    typeName
    vin
    condition
    conditionName
    archived
    primaryPhoto {
      url(width: 896, height: 672, crop: LIMIT)
      ...ListPhotoFragment
    }
    carfaxReport {
      ...CarfaxReportFragment
    }

    ## MEDIA TAB

    photos {
      ...InventoryItemPhotoFragment
    }
    videos {
      ...InventoryItemVideoFragment
    }

    vehicleAttributes: attributes {
      ...VehicleAttributesFragment
    }
    motorcycleAttributes: attributes {
      ...MotorcycleAttributesFragment
    }
    created
    updated
  }
  ${MultilingualString}
  ${rooftopList}
  ${userList}
  ${inventoryItemPhoto}
  ${inventoryItemVideo}
  ${vehicleAttributes}
  ${motorcycleAttributes}
  ${carfaxReport}
  ${listPhoto}
  ${trimFragment}
`;
