import { StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import modifyPasswordFields from 'components/sections/createModify/settings/steps/ModifyPasswordFields';
import ModifyPasswordStep from 'components/sections/createModify/settings/steps/ModifyPasswordStep';
import { userModify } from 'components/sections/createModify/users/UserCreateModifyQuery';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const ModifyPasswordBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.USER_PASSWORD_MODIFY]: {
    steps: [
      {
        id: 'MODIFY_PASSWORD',
        step: ModifyPasswordStep,
        stepName: 'change_password',
        fields: modifyPasswordFields,
        tooltip: {
          title: 'note_one',
          content: 'change_password_tooltip_content',
        },
      },
    ],
    legend: [StepLegendTooltip],
    mutations: {
      modify: userModify,
    },
    requiredPermissions: [{ resource: ResourceType.USERS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.USERS,
  },
};

export default ModifyPasswordBuilders;
