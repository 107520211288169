import { gql } from '@apollo/client';

export const retailItemFilters = gql`
  fragment RetailItemFiltersFragment on RetailItemConnectionFilter {
    type
    condition
    status
    tagId
    vehicleAttributes {
      fuelType
      mileage {
        gte
        lte
      }
      bodyType
    }
    motorcycleAttributes {
      fuelType
      mileage {
        gte
        lte
      }
    }
    yearRange {
      gte
      lte
    }
    makeId
    makeIdExcluded
    modelId
    subModelId
    rooftopId
    purchasePrice {
      gte
      lte
    }
    mapped
    showWeb
    asIs
    certified
    demo
    featured
    onOrder
    inTransit
    complete
    hasPhotos
    hasMileage
    hasPrice
    daysInStock {
      gte
      lte
    }
  }
`;
