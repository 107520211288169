import styled from 'styled-components/macro';

import Paragraph from 'components/core/typography/Paragraph';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { DIVIDER } from 'styles/color';

export const WarningContainer = styled.div`
  width: 100%;
  height: 230px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${DIVIDER};

  ${Paragraph} {
    margin-top: 40px;
    text-align: center;
    padding: 0 35px;
  }
`;

export const YMMTFields = [
  DetailsInventoryItemBuilderFields.YEAR,
  DetailsInventoryItemBuilderFields.MAKE_ID,
  DetailsInventoryItemBuilderFields.MODEL_ID,
  DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
  DetailsInventoryItemBuilderFields.TRIM_ID,
];

/**
 * Given a MMST field, determine which variables are needed for that fields query. Ex, the MODEL_ID query needs the
 * MAKE_ID, the SUB_MODEL_ID query needs the MODEL_ID, etc.
 *
 * @param mmstField - The MMST field in question
 */
export const getDependentVariablesForMMST = (
  mmstField: DetailsInventoryItemBuilderFields
): [DetailsInventoryItemBuilderFields] =>
  ({
    [DetailsInventoryItemBuilderFields.MODEL_ID]: [
      DetailsInventoryItemBuilderFields.MAKE_ID,
      DetailsInventoryItemBuilderFields.ROOFTOP_ID,
    ],
    [DetailsInventoryItemBuilderFields.SUB_MODEL_ID]: [
      DetailsInventoryItemBuilderFields.MODEL_ID,
      DetailsInventoryItemBuilderFields.ROOFTOP_ID,
    ],
    [DetailsInventoryItemBuilderFields.TRIM_ID]: [
      DetailsInventoryItemBuilderFields.SUB_MODEL_ID,
      DetailsInventoryItemBuilderFields.YEAR,
      DetailsInventoryItemBuilderFields.ROOFTOP_ID,
    ],
  })[mmstField] || [];
