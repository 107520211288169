import type { Maybe } from 'graphql/jsutils/Maybe';
import { isNil, shuffle } from 'lodash-es';

import type {
  Incentive,
  MonetaryAmount,
  PriceRetailBulkAdjustmentAttributes,
  RetailBulkAdjustment,
  RetailPriceAdjustment,
} from 'store/api/graph/interfaces/types';

/**
 * Given a starting price and series of price, incentive and bulk adjustments, calculate the cumulative
 * purchase price value
 *
 * @param startingPrice - The starting price to use in the calculation. If none is defined null will be returned.
 * @param priceAdjustments - Array of RetailPriceAdjustment
 * @param bulkAdjustments - Array of RetailBulkAdjustment, only 'Price' type bulk adjustments are used
 * @param incentives - Array of Incentives, only fixed and percentage price incentive types are used
 */
export const calcPriceAdjustments = (
  startingPrice: Maybe<MonetaryAmount> | null,
  priceAdjustments: RetailPriceAdjustment[] = [],
  bulkAdjustments: RetailBulkAdjustment[] = [],
  incentives: Incentive[] = []
): number | null => {
  if (isNil(startingPrice)) {
    return null;
  }

  // Combine Bulk adjustments, price incentives and single Price adjustments
  const allAdjustments = [
    ...incentives.map(incentive => ({
      fixed:
        incentive.attributes.__typename === 'PurchasePriceFixedIncentiveAttributes'
          ? incentive.attributes?.fixed
          : null,
      percentage:
        incentive.attributes.__typename === 'PurchasePricePercentageIncentiveAttributes'
          ? incentive.attributes?.percentage
          : null,
    })),
    ...(bulkAdjustments
      .filter(bulkAdjustment => bulkAdjustment.typeName === 'Price')
      .map(bulkAdjustment => bulkAdjustment.attributes) as PriceRetailBulkAdjustmentAttributes[]),
    ...priceAdjustments,
  ];

  // If final price is < 0 then return 0 (cannot have a negative final price)
  return Math.max(
    allAdjustments.reduce(
      // Add fixed or percentage values to the adjusted total.
      (total, current) =>
        isNil(current.fixed)
          ? total + startingPrice.amount * (current.percentage as number)
          : total + current.fixed.amount,
      // Use the starting price as the base for the cumulative value
      startingPrice.amount
    ),
    0
  );
};

/**
 * Generate random 5 digit number, includes optional `originalNumber` to "re-roll", if somehow the value is equal.
 * This is to ensure that number generated between create and modify always differ.
 */
export const getRandom5DigitNumber = (originalNumber = -1) => {
  const randomNumber = Math.floor(Math.random() * 90000) + 10000;
  return randomNumber === originalNumber ? getRandom5DigitNumber(originalNumber) : randomNumber;
};

/**
 * Returns 2 random indexes to be used for selection
 */
export const getFirstTwoIndex = (indexLength: number) => {
  const range = shuffle(Array.from({ length: indexLength }, (_, index) => index));
  return range.slice(0, 2);
};

/**
 * Returns a random index within the bounds of the given length.
 *
 * @param {number} length - The length of the array.
 * @return {number} A random index between 0 and length - 1.
 */
export const getRandomIndex = (length: number) => {
  if (length <= 0) {
    throw new Error('Length must be a positive number');
  }
  return Math.floor(Math.random() * length);
};
