import { memo, type SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.452 16.137c-1.852 0-3.422-.643-4.708-1.929s-1.93-2.856-1.93-4.708c0-1.853.644-3.422 1.93-4.708s2.856-1.93 4.708-1.93c1.853 0 3.422.644 4.708 1.93 1.287 1.286 1.93 2.855 1.93 4.708a6.25 6.25 0 0 1-.338 2.078 5.93 5.93 0 0 1-.915 1.704l5.553 5.558c.21.215.316.482.316.799 0 .317-.107.581-.322.793a1.08 1.08 0 0 1-.796.316c-.32 0-.584-.105-.796-.317l-5.546-5.546a5.99 5.99 0 0 1-1.716.915 6.284 6.284 0 0 1-2.078.337Zm0-2.274c1.214 0 2.245-.424 3.092-1.271s1.27-1.878 1.27-3.092c0-1.214-.423-2.245-1.27-3.092s-1.878-1.27-3.092-1.27c-1.214 0-2.245.423-3.092 1.27S5.09 8.286 5.09 9.5c0 1.214.423 2.245 1.27 3.092s1.878 1.27 3.092 1.27Z"
    />
  </svg>
);

export default memo(SearchIcon);
