import styled from 'styled-components/macro';

import { NEUTRAL_0 } from 'styles/tokens';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${NEUTRAL_0};
`;
