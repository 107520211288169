import type { MutationOptions } from '@apollo/client';
import type { DocumentNode, OperationDefinitionNode } from 'graphql';
import { cloneDeep } from 'lodash-es';

import LoggingService from 'components/core/logging/LoggingService';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { convertedNestedString } from 'utils/stringUtils';

/**
 * Given a gql document and payload, filter out any unneeded fields in the payload that aren't used in the
 * gql document. This prevents extra fields from being sent in a gql query/mutation request.
 * @param query - the document created with the gql template literal
 * @param payload - the payload (ie variables) used as arguments to the gql document
 * @param warn - boolean, to console.warn when an unnecessary field is found (true by default on dev build)
 */

export const gqlFilterFields = (
  query: DocumentNode,
  payload: Record<string, any>,
  warn = process.env.NODE_ENV === 'development'
): Record<string, any> => {
  // Get list of variables used in the query.
  const definition = query.definitions?.[0] as OperationDefinitionNode;
  const variableNames = definition?.variableDefinitions?.map(({ variable }) => variable.name.value);

  // If there was a problem getting the variable names in the gql doc then just return the payload
  if (!variableNames) {
    return payload;
  }

  // Get a copy of the payload
  const payloadCopy = cloneDeep(payload);
  const unusedFields: string[] = [];

  // Delete any fields in payload that aren't included in the list of variables used in the query
  for (const field in payloadCopy) {
    if (payloadCopy.hasOwnProperty(field) && !variableNames.includes(field)) {
      delete payloadCopy[field];

      unusedFields.push(field);
    }
  }

  if (warn && unusedFields.length > 0) {
    LoggingService.warn({
      message: `Query "${definition.name?.value}" has unnecessary fields: "${unusedFields.join(', ')}"`,
    });
  }

  return payloadCopy;
};
/**
 * Given a query, table columns, and static columns, this util will return the variables for the query based on
 * whether or not the table column is enabled/disabled and whether or not the table column is static. Table columns
 * that are static are ignored, and if the table column id is not in the list of the query paramaters, it will
 * also be ignored.
 * @param query - The container query
 * @param tableColumns - The column configuration
 * @param staticColumns - Fields always requested which are the minimum mandatory fields needed by container queries
 * defined by `xItemList fragments e.g. retailItemList`.
 * Thus, staticColumns must always contain at minimum all fields in `xItemList`.
 */

export const gqlFormatTableColumnFields = (
  query: DocumentNode,
  tableColumns: TableCellData[],
  staticColumns: string[]
): Record<string, any> => {
  // Get list of variables used in the query.
  const definition = query.definitions?.[0] as OperationDefinitionNode;
  const variableNames = definition?.variableDefinitions?.map(({ variable }) => variable.name.value);
  const results = {};

  // If there was a problem getting the variable names in the gql doc, then just return an empty result set
  if (!variableNames) {
    return results;
  }

  for (const column of tableColumns) {
    const columnId = convertedNestedString(column.columnId);
    if (!staticColumns.includes(column.columnId) && variableNames.includes(`d_${columnId}On`)) {
      results[`d_${columnId}On`] = column.enabled;
    }
  }

  return results;
};

export const checkForMatchingRefetchQueries = (
  refetchQueries?: string[]
): Pick<MutationOptions, 'refetchQueries' | 'onQueryUpdated'> => ({
  refetchQueries,
  onQueryUpdated: (observableQuery, diff) => {
    /**
     * Look for matching refetch queries. If `true`, refetch from network
     */
    const isMatchingRefetchQueries = refetchQueries?.includes(observableQuery?.queryName || '');
    if (isMatchingRefetchQueries) {
      return observableQuery.refetch();
    }

    if (!diff.complete && !!diff.missing?.length) {
      /**
       * For development purposes only, this will flag to us if there is an issue when updating the cache
       */
      if (process.env.NODE_ENV === 'development') {
        for (const element of diff.missing) {
          console.warn(element);
        }
      }

      /**
       * Since we have issue updating the cache, force a refetch to grab the updated value
       */
      return observableQuery.refetch();
    } else {
      /**
       * Explicitly return the updated results
       */
      return diff.result;
    }
  },
});
