import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { InventoryItemTypeSettings } from 'components/sections/createModify/inventoryItems/steps/DetailsInventoryItemFields';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { StepFieldType } from 'enums/stepFieldType';

const CaptureVinFields: StepFields = {
  [DetailsInventoryItemBuilderFields.TYPE]: {
    groupType: StepFieldType.TYPES,
    settings: {
      ...InventoryItemTypeSettings,
    },
  },
  [DetailsInventoryItemBuilderFields.VIN]: { label: 'vin', selectedValue: '' },
};

export default CaptureVinFields;
