import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

// TODO: Potentially name this file Contact.ts if contacts get their own section
export enum RooftopContact {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ROOFTOP_NAME = 'rooftopName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ARCHIVED = 'archived',
  DEFAULT_APPRAISER = 'defaultAppraiser',
  TYPE_NAME = 'typeName',
  TYPE = 'type',
  COMPANY = 'company',
  CREATED = 'created',
  UPDATED = 'updated',
}

const RooftopContactConfig: TableSettingsType = {
  [RooftopContact.COMPANY]: { label: 'company', type: FieldDataType.DEFAULT },
  [RooftopContact.FIRST_NAME]: { label: 'first_name', type: FieldDataType.DEFAULT },
  [RooftopContact.LAST_NAME]: { label: 'last_name', type: FieldDataType.DEFAULT },
  [RooftopContact.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [RooftopContact.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
  [RooftopContact.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [RooftopContact.ARCHIVED]: { label: 'archived', type: FieldDataType.BOOLEAN },
  [RooftopContact.DEFAULT_APPRAISER]: {
    label: 'default_appraiser',
    type: FieldDataType.BOOLEAN,
    tooltip: 'default_appraiser_tooltip',
  },
  [RooftopContact.TYPE_NAME]: { label: 'type', type: FieldDataType.DEFAULT },
  [RooftopContact.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [RooftopContact.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const RooftopContactSettings = translateFields(RooftopContactConfig);
