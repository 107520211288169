import { memo, type SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 15.45a1.2 1.2 0 0 1-.429-.078 1.054 1.054 0 0 1-.373-.245l-3.69-3.69a1.055 1.055 0 0 1-.326-.796 1.157 1.157 0 0 1 1.135-1.147c.31-.006.58.105.808.332l1.738 1.738v-6.63c0-.319.11-.588.329-.808.22-.22.489-.33.808-.33.32 0 .589.11.808.33.22.22.33.49.33.808v6.63l1.737-1.738c.224-.227.491-.34.802-.335.311.005.58.12.808.349.216.228.326.494.333.798a1.06 1.06 0 0 1-.333.799l-3.683 3.69a1.053 1.053 0 0 1-.373.245 1.2 1.2 0 0 1-.429.078Zm-5.928 4.753c-.63 0-1.167-.221-1.61-.665a2.193 2.193 0 0 1-.665-1.61v-1.862c0-.32.11-.589.329-.808.22-.22.49-.33.808-.33.32 0 .589.11.808.33.22.22.33.489.33.808v1.862h11.856v-1.862c0-.32.11-.589.33-.808.22-.22.489-.33.808-.33.319 0 .588.11.808.33.22.22.33.489.33.808v1.862c0 .63-.223 1.166-.666 1.61-.444.444-.98.665-1.61.665H6.072Z"
    />
  </svg>
);

export default memo(DownloadIcon);
