import { get, isObject, merge } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getGroupOptions, getUserOptions } from 'components/sections/shared/ItemMetaHelpers';
import { rooftopBuilderMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import LocationSelector from 'components/sections/shared/LocationSelector';
import { PromptDialogIcon } from 'components/ui/dialogs/PromptDialog';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { Rooftop } from 'enums/columns/rooftop';
import { FeatureBundleSet } from 'enums/featureBundle';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import type { LocationInput, Rooftop as RooftopType } from 'store/api/graph/interfaces/types';
import { AccessLevel, PlaceAutocompleteType, ResourceType, UserScope } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { formatLocationData } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import { RooftopDetailsBuilderFields } from './interfaces';

const { t } = translate;

const getWarrantyOptions = (data: RooftopType): SelectOption[] | null => {
  if (!data?.availableWarrantyTypes || !data?.availableWarrantyTypes?.length) {
    return null;
  }

  return data.availableWarrantyTypes.map((type, i) => ({
    id: type,
    name: data.availableWarrantyTypesNames[i],
  }));
};

class DetailsStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data: savedData, formData, metadata, activeStep, isCreating, stepFieldData },
    } = props;

    const {
      subContexts: {
        userContext: { isWhiteLabelScoped, hasPermissions, user },
        featureFlags,
      },
    } = context;

    const data = merge({}, savedData, formData);

    const disableAccountReps = !data.groupName?.id && !stepFieldData?.[RooftopDetailsBuilderFields.GROUP_ID];

    const warrantyOptions = getWarrantyOptions(data as RooftopType);

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // Presets
      [RooftopDetailsBuilderFields.LOGO]: {
        selectedValue: isCreating ? stepFieldData?.[RooftopDetailsBuilderFields.LOGO] : get(data, Rooftop.LOGO_URL),
      },
      [RooftopDetailsBuilderFields.EMAIL_SUBDOMAIN]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
        ]),
      },
      [RooftopDetailsBuilderFields.WARRANTY_TYPES]: {
        selectedValue: data?.warrantyTypes || [],
        options: (warrantyOptions || []) as StepFieldOptions[],
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !warrantyOptions,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !!isCreating,
          },
        ]),
      },
      [RooftopDetailsBuilderFields.GROUP_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          { type: StepFieldDisplayType.HIDDEN, active: !isWhiteLabelScoped },
        ]),
        get selectedValue() {
          if (!isWhiteLabelScoped) {
            return user.group;
          }
          return isCreating
            ? stepFieldData?.[RooftopDetailsBuilderFields.GROUP_ID]
            : data?.groupName && {
                ...data.groupName,
                name: data.groupName.name.value,
                whiteLabel: data.whiteLabelName.whiteLabel,
              };
        },
      },
      [RooftopDetailsBuilderFields.ACCOUNT_REP_ID]: {
        displayType: setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: disableAccountReps }),
        get selectedValue() {
          if (disableAccountReps) {
            return undefined;
          }
          return isCreating ? stepFieldData?.[RooftopDetailsBuilderFields.ACCOUNT_REP_ID] : data?.accountRepName;
        },
      },
      [RooftopDetailsBuilderFields.LOCALE]: {
        selectedValue: isCreating
          ? stepFieldData?.[RooftopDetailsBuilderFields.LOCALE]
          : data?.locale && { id: data.locale.languageTag, name: data.locale.displayName },
      },
      [RooftopDetailsBuilderFields.CARFAX_ID]: {
        selectedValue: isCreating ? stepFieldData?.[RooftopDetailsBuilderFields.CARFAX_ID] : data.carfaxId,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isWhiteLabelScoped,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: !isWhiteLabelScoped,
          },
        ]),
        hasSeparator: !isFeatureEnabledForRooftop({
          rooftop: data,
          feature: FeatureBundleSet.LEAD,
          featureFlagOn: featureFlags.rooftopPackageEnabled,
        }),
      },
      [RooftopDetailsBuilderFields.WEBSITE_PROVIDER]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !hasPermissions([{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }]),
        }),
      },
      [RooftopDetailsBuilderFields.DEALERTRACK_ID]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isFeatureEnabledForRooftop({
            rooftop: data,
            feature: FeatureBundleSet.LEAD,
            featureFlagOn: featureFlags.rooftopPackageEnabled,
          }),
        }),
      },
      [RooftopDetailsBuilderFields.DEALERTRACK_CONFIRM_ID]: {
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.HIDDEN,
          active: !isFeatureEnabledForRooftop({
            rooftop: data,
            feature: FeatureBundleSet.LEAD,
            featureFlagOn: featureFlags.rooftopPackageEnabled,
          }),
        }),
      },
      [RooftopDetailsBuilderFields.LOCATION]: {
        selectedValue: isCreating
          ? stepFieldData?.[RooftopDetailsBuilderFields.LOCATION]
          : formatLocationData(data as LocationInput, true),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data, metadata);

    // Async subpanel configurations
    const groupIdField = getStepField(RooftopDetailsBuilderFields.GROUP_ID, this.fields);

    this.asyncConfigurations = {
      groupId: {
        request: keyword => getGroupOptions(keyword),
      },
      accountRepId: {
        request: keyword =>
          getUserOptions(keyword, {
            whiteLabelId: groupIdField?.selectedValue?.whiteLabel?.id,
            scope: [UserScope.WHITE_LABEL],
          }),
      },
    };
  }

  async toggleSubPanel(stepField?: StepField) {
    if (stepField?.queryVar === RooftopDetailsBuilderFields.LOCATION) {
      this.setState({
        childrenBeforeSubStep: (
          <LocationSelector
            type={PlaceAutocompleteType.ESTABLISHMENT}
            location={stepField.selectedValue}
            onDone={location => void this.onFieldSelection(stepField, location)}
          />
        ),
      });
    } else {
      this.setState({ childrenBeforeSubStep: null });
    }

    await super.toggleSubPanel(stepField);
  }

  async performSave() {
    const {
      tier: { steps },
    } = this.props;
    // If the user has not uploaded a different photo, need to send null
    const logoField = getStepField(RooftopDetailsBuilderFields.LOGO, this.fields);
    const location = getStepField(RooftopDetailsBuilderFields.LOCATION, this.fields).selectedValue;

    const success = await super.save(undefined, {
      location,
      // If the logo is not a File, then send null, as the logo has not been changed
      logo: isObject(logoField.selectedValue) ? logoField.selectedValue : null,
    });

    if (!success) {
      return false;
    }

    // Enabling other steps for edit/navigation
    for (const step of steps!) {
      step.isEnabled = true;
    }

    return true;
  }

  async save(): Promise<boolean> {
    const {
      tier: { data, isCreating },
      onStepComplete,
      toggleClosePrompt,
    } = this.props;
    const carfaxFieldValue = getStepField(RooftopDetailsBuilderFields.CARFAX_ID, this.fields).selectedValue;
    const prevCarfaxValue = get(data, Rooftop.CARFAX_ID);

    // If the carfax id has changed to a different id, then warn the user that the report links will need to be updated
    if (!isCreating && !!carfaxFieldValue && carfaxFieldValue !== prevCarfaxValue) {
      toggleClosePrompt({
        message: t('update_carfax_links_warning_message'),
        messageIcon: PromptDialogIcon.WARNING,
        confirmText: t('yes_continue'),
        cancelText: t('dont_update'),
        onConfirm: async () => {
          try {
            await this.performSave();
            return true;
          } catch (error) {
            this.setTier({ errors: getApiErrors(error) });
            return false;
          }
        },
        onClose: () => {
          toggleClosePrompt(undefined);
        },
        onComplete: success => {
          // On complete, close this prompt
          toggleClosePrompt(undefined);
          // If the user clicked Yes, clear the close prompt and mark this step complete
          if (success) {
            this.setOnClosePrompt(undefined);
            onStepComplete(true);
          }
          // Otherwise, reset the close prompt to default settings
          else {
            this.setOnClosePrompt(this.defaultClosePrompt);
          }
        },
        onCancel: () => {
          this.setOnClosePrompt(undefined);
        },
      });
      return false;
    } else {
      return this.performSave();
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    const {
      tier: { data, isCreating },
    } = this.props;

    if (!isCreating && data.whiteLabelName) {
      await this.showAccountRepsIfApplicable(data.whiteLabelName.whiteLabel.id, { forceUpdate: true });
    }
  }

  async showAccountRepsIfApplicable(whiteLabelId: string, options?: { resetValue?: boolean; forceUpdate?: boolean }) {
    const response = await this.client.query({
      query: rooftopBuilderMetaQuery,
      variables: {
        filter: { whiteLabelId, scope: [UserScope.WHITE_LABEL], active: true },
        [`d_accountRepsOn`]: true,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    // Enable/disable representatives
    const hasReps = !!response.data.accountReps.edges?.length;
    const accountRepIdField = getStepField(RooftopDetailsBuilderFields.ACCOUNT_REP_ID, this.fields);
    setDisplayTypes([{ type: StepFieldDisplayType.DISABLED, active: !hasReps }], accountRepIdField);

    if (options?.resetValue) {
      accountRepIdField.selectedValue = undefined;
    }

    if (options?.forceUpdate) {
      this.forceUpdate();
    }
  }

  async onFieldSelection(stepField: StepField, value: any, persistSeededValues = false, advance = true) {
    if (stepField.queryVar === RooftopDetailsBuilderFields.GROUP_ID) {
      await this.showAccountRepsIfApplicable(value.whiteLabel.id, { resetValue: true });
    }

    super.onFieldSelection(stepField, value, persistSeededValues, advance);
  }

  async onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>, shouldForceUpdate?: boolean) {
    super.onFieldChange(stepField, e, shouldForceUpdate);
    if (stepField.queryVar === RooftopDetailsBuilderFields.LOCATION && stepField.selectedValue === null) {
      void this.toggleSubPanel(stepField);
    }
  }
}

export default DetailsStep;
