import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopLeaseSegmentRatesBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import { BuilderType } from 'enums/builderType';
import { RooftopLeasePricingSegment, RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import { RooftopPricingSegmentConfigurationPointer } from 'enums/rooftopPricingSegmentConfigurationPointer';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { LeaseRetailPricingConfigurationInputParameter } from 'store/api/graph/interfaces/types';
import { getNestedConfigurationField } from 'utils/formatting/pricingUtils';

const RatesFields: StepFields = {
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_PRICING_SOURCE]: {
    label: 'incentives_and_rates_source',
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE_NAME,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
    label: 'include_after_tax_rebates',
    groupType: StepFieldType.SWITCH,
    queryAlias: [
      RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES,
      getNestedConfigurationField(RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES),
    ],
    clear: {
      field: LeaseRetailPricingConfigurationInputParameter._includeAfterTaxRebates,
      target: RooftopPricingSegmentConfigurationPointer.LEASE_CONFIGURATION,
    },
    hasSeparator: true,
  },
  // This is field is included in all API requests, but is hidden from the user
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_RESIDUAL_RATE_TYPE]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM_TYPE]: {
    label: 'default_term',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE_NAME,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM_TYPE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE]: {
    label: 'default_mileage_allowance',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE_NAME,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE_TYPE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_INTEREST_RATE_TYPE]: {
    label: 'interest_rate_type',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE_NAME,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_INTEREST_RATE_TYPE),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_TERMS]: {
    label: 'available_term_other',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_TERMS,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_TERMS),
    ],
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    required: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_TERM]: {
    label: 'default_term',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_TERM),
    ],
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
    hasSeparator: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES]: {
    label: 'available_mileage_allowance_other',
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_AVAILABLE_MILEAGE_ALLOWANCES),
    ],
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.DEFAULT],
    subStepAddConfig: SubStepAddConfigs[BuilderType.ROOFTOP_LEASE_SEGMENT_AVAILABLE_MILEAGE_ALLOWANCE_CREATE],
    required: true,
  },
  [RooftopLeaseSegmentRatesBuilderFields.LEASE_CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE]: {
    queryAlias: [
      RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE,
      getNestedConfigurationField(RooftopLeasePricingSegment.CONFIGURATION_DEFAULT_MILEAGE_ALLOWANCE),
    ],
    label: 'default_mileage_allowance',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    required: true,
    hasSeparator: true,
  },
};

export default RatesFields;
