import styled from 'styled-components/macro';

import { NEUTRAL_050 } from 'styles/tokens';

import Spinner from './Spinner';

export const ImageLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${NEUTRAL_050};
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Used for displaying a loading spinner for images.
 */
const ImageLoader = props => (
  <ImageLoaderContainer {...props}>
    <Spinner />
  </ImageLoaderContainer>
);

export default ImageLoader;
