import { PromotionRetailBulkAdjustmentPosition } from 'store/api/graph/interfaces/types';

/**
 * Util for determining if the 'banner links to VDP' field is visible. This field should be visible in the builder
 * and details view if the link to vlp toggle is enabled and the banner image position is set to WITHIN
 * @param imagePosition - The banner image position
 * @param linkToVlp - The status of the vlp toggle
 */
export const isBannerLinksToVdpFieldVisible = (
  imagePosition?: PromotionRetailBulkAdjustmentPosition,
  linkToVlp?: boolean
): boolean => !!linkToVlp && imagePosition === PromotionRetailBulkAdjustmentPosition.WITHIN;
