import { gql } from '@apollo/client';

import { selectStringOption } from 'store/api/graph/fragments/selectOption';

export const retailFilterMetadata = gql`
  fragment RetailFilterMetadataFragment on Metadata {
    mutation {
      inventoryItem {
        condition {
          ...SelectStringOptionFragment
        }
        type {
          ...SelectStringOptionFragment
        }
        vehicleAttributes {
          fuelType {
            ...SelectStringOptionFragment
          }
          bodyType {
            ...SelectStringOptionFragment
          }
        }
        retailItem {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;
