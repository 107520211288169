import type { FormEvent } from 'react';
import { useCallback, useState } from 'react';

import { css } from 'styled-components/macro';

import { isIncomingStringANumber } from 'components/ui/forms/shared/NumberInput';
import { formatCurrency, is32IntCompatible } from 'utils/formatUtils';
import { Locale } from 'utils/intlUtils';

import { Input, InputContainer, InputPrefix, InputSuffix } from './InputText';

/**
 * Input to capture currency amount. Currently, the `$` sign is hard coded.
 *
 * TODO: Add support for decimals
 */
const CurrencyInput = ({
  locale,
  onChange,
  defaultValue,
  ...props
}: {
  locale: Locale;
  onChange?: (e: Record<'currentTarget', { value: number | null }>) => void;
  defaultValue?: number;
  // All other arguments from ...props
  [x: string]: any;
}) => {
  // This is the raw currency value represented as a number
  const [value, setValue] = useState<number | null>(defaultValue || null);

  // This is the value currently being displayed in the input
  const [inputValue, setInputValue] = useState<string>(
    defaultValue ? formatCurrency({ amount: defaultValue, locale, omitDollarSign: true }) : ''
  );

  /*
   * On change we want to store the currency value. While the user is editing the input we don't want to apply any
   * formatting, so we set the rendered input value as the currency value.
   */
  const onChangeCallback = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const parsedNumber = e.currentTarget.value ? Number(e.currentTarget.value) : null;
      setValue(parsedNumber);
      setInputValue(e.currentTarget.value);
      onChange?.({ currentTarget: { value: parsedNumber } });
    },
    [onChange]
  );

  // On blur set the input to have the currency value in a formatted string
  const onBlurCallback = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setInputValue(value ? formatCurrency({ amount: value, locale, omitDollarSign: true }) : '');
    },
    [value, locale]
  );

  // On focus set the input to use the raw currency value with no formatting applied
  const onFocusCallback = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setInputValue(value ? String(value) : '');
    },
    [value]
  );

  return (
    <InputContainer>
      <Input
        {...props}
        css={
          locale === Locale.FR_CA
            ? css`
                padding-right: 30px;
              `
            : css`
                padding-left: 30px;
              `
        }
        onBlur={e => onBlurCallback(e)}
        onFocus={e => onFocusCallback(e)}
        onPaste={e => {
          const pastedText = e.clipboardData?.getData('Text');
          const currentText = e.currentTarget.value || '';
          const caretPosition = e.currentTarget.selectionStart || 0;
          const newText =
            currentText.slice(0, Math.max(0, caretPosition)) +
            pastedText +
            currentText.slice(Math.max(0, caretPosition));

          if (
            Array.from(newText || []).every((char, index) =>
              isIncomingStringANumber({
                insertIndex: index,
                incomingCharCode: char.codePointAt(0),
                currentString: newText.slice(0, Math.max(0, index)),
                isDecimalAllowed: false,
                isNegativeAllowed: false,
              })
            ) &&
            is32IntCompatible(Number(newText))
          ) {
            return true;
          } else {
            e.preventDefault();
            return false;
          }
        }}
        onKeyPress={e => {
          const selectionStart = e.currentTarget.selectionStart || 0;
          const charCode = e.which || e.keyCode;
          const newString = [
            e.currentTarget.value.slice(0, selectionStart) + e.key + e.currentTarget.value.slice(selectionStart),
            // eslint-disable-next-line unicorn/require-array-join-separator
          ].join();

          if (
            isIncomingStringANumber({
              insertIndex: selectionStart,
              incomingCharCode: charCode,
              currentString: e.currentTarget.value,
              isDecimalAllowed: false,
              isNegativeAllowed: false,
            }) &&
            is32IntCompatible(Number(newString))
          ) {
            return true;
          } else {
            e.preventDefault();
            return false;
          }
        }}
        onChange={onChangeCallback}
        value={inputValue}
      />
      {locale !== Locale.FR_CA && <InputPrefix>$</InputPrefix>}
      {locale === Locale.FR_CA && <InputSuffix>$</InputSuffix>}
    </InputContainer>
  );
};

export default CurrencyInput;
