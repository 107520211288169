import { get } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import LoggingService from 'components/core/logging/LoggingService';
import {
  RetailItemBuilderSteps,
  RetailItemDetailsBuilderFields,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import {
  handleConfirmToggleFieldLock,
  initLockingField,
  isBulkAdjustmentStepEnabled,
  updateLockedFieldsSelectedValue,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/utils';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import RetailItemPricingInfoStep from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/steps/RetailItemPricingInfoStep';
import { isWarrantyAvailable } from 'components/sections/inventoryItems/retailItems/warrantyUtils';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { InventoryItemAttributesPointer } from 'enums/inventoryItemAttributesPointer';
import { MileageUnit } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import { getStepField, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { getRemovedAttributes } from 'utils/inventoryItemUtils';

class PricingAndMileageStep extends RetailItemPricingInfoStep {
  static contextType = CreateModifyContext;

  constructor(props, context: CreateModifyContextInterface) {
    super(props, context);
    const {
      tier: {
        tierId,
        data,
        steps,
        metadata: {
          mutation: {
            item: {
              retailItem: {
                warranty: { quebecCategoryOverride },
              },
            },
          },
        },
        metadata,
      },
    } = props;

    const {
      subContexts: { featureFlags },
      setTier,
    } = context;

    const { rooftop } = data;

    if (
      !isBulkAdjustmentStepEnabled({ rooftop, featureFlagRooftopPackageEnabled: featureFlags.rooftopPackageEnabled })
    ) {
      // Hide the bulk adjustment step if none of the retail payment options are available
      setTier(tierId, { steps: steps?.filter(step => step.id !== RetailItemBuilderSteps.BULK_ADJUSTMENTS) });
    }

    const removedAttributes = getRemovedAttributes(data);
    const isWarrantyEnabled = isWarrantyAvailable(data);
    const isFieldLockingVisible = metadata?.isFieldLockingVisible;

    this.fields = this.fields
      .filter(stepField => !removedAttributes.includes(stepField.queryVar.split('.')[0]))
      .map(stepField => {
        switch (stepField.queryVar) {
          case RetailItemDetailsBuilderFields.LOCKED_FIELDS: {
            stepField.selectedValue = get(data, RetailItemDetailsBuilderFields.LOCKED_FIELDS);
            break;
          }

          case RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS: {
            stepField.selectedValue = get(data, RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS);
            break;
          }

          case `${InventoryItemAttributesPointer.VEHICLE}.mileage`: {
            stepField.selectedValue = get(data, `${InventoryItemAttributesPointer.VEHICLE}.mileage`) || {
              amount: null,
              unit: MileageUnit.KILOMETERS,
            };
            stepField.required = isWarrantyEnabled;
            break;
          }

          case `${InventoryItemAttributesPointer.MOTORCYCLE}.mileage`: {
            stepField.selectedValue = get(data, `${InventoryItemAttributesPointer.MOTORCYCLE}.mileage`) || {
              amount: null,
              unit: MileageUnit.KILOMETERS,
            };
            break;
          }

          case DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB: {
            stepField.displayType = setDisplayTypes([
              {
                type: StepFieldDisplayType.HIDDEN,
                active: !isWarrantyEnabled || !get(data, InventoryItem.VEHICLE_MILEAGE)?.amount,
              },
              {
                type: StepFieldDisplayType.OMITTED,
                active: !isWarrantyEnabled,
              },
            ]);
            stepField.selectedValue = get(data, InventoryItem.WARRANTY_SHOW_WEB) ?? true;
            break;
          }

          case DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE: {
            stepField.displayType = setDisplayTypes([
              {
                type: StepFieldDisplayType.HIDDEN,
                active: !isWarrantyEnabled || !get(data, InventoryItem.VEHICLE_MILEAGE)?.amount,
              },
              {
                type: StepFieldDisplayType.OMITTED,
                active: !isWarrantyEnabled,
              },
            ]);
            stepField.options = quebecCategoryOverride ? this.parseWarrantyCategoryOptions(quebecCategoryOverride) : [];
            const savedWarrantyCategory = get(data, InventoryItem.WARRANTY_QUEBEC_CATEGORY);
            stepField.selectedValue = stepField.options.find(option => option.id === savedWarrantyCategory);
            break;
          }
        }
        return stepField;
      })
      .map(stepField => initLockingField(stepField, data, isFieldLockingVisible));
  }

  private parseWarrantyCategoryOptions(options: SelectOption[]): SelectOption[] {
    return options.map(option => {
      let data;
      if (option.data) {
        try {
          data = JSON.parse(option.data.toString());
        } catch {
          LoggingService.debug({ message: 'Could not parse warranty description metadata!' });
        }
      }
      return {
        id: option.id,
        name: option.name,
        data,
      } as SelectOption;
    });
  }

  onConfirmToggleFieldLock(queryVar, optionQueryVar) {
    handleConfirmToggleFieldLock({ fields: this.fields, queryVar, optionQueryVar });

    this.forceUpdate();

    updateLockedFieldsSelectedValue(this.fields);
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    const {
      tier: { data },
    } = this.props;
    super.onFieldChange(stepField, e);

    if (stepField.queryVar === `${InventoryItemAttributesPointer.VEHICLE}.mileage`) {
      const isWarrantyEnabled = isWarrantyAvailable(data) && !!stepField.selectedValue?.amount;

      // If there is no mileage set, then hide the show web switch and warranty override field
      setDisplayTypes(
        [{ type: StepFieldDisplayType.HIDDEN, active: !isWarrantyEnabled }],
        getStepField(DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB, this.fields)
      );
      setDisplayTypes(
        [{ type: StepFieldDisplayType.HIDDEN, active: !isWarrantyEnabled }],
        getStepField(DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE, this.fields)
      );
      super.forceUpdate();
    }
  }

  async save(): Promise<boolean> {
    const showWarrantyField = getStepField(DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB, this.fields);
    const overrideWarrantyField = getStepField(
      DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE,
      this.fields
    );

    // If the warranty show web toggle is hidden, then it should be cleared
    if (showWarrantyField.displayType?.includes(StepFieldDisplayType.HIDDEN)) {
      showWarrantyField.selectedValue = null;
    }
    // If the warranty override field is hidden, then it should be cleared
    if (overrideWarrantyField.displayType?.includes(StepFieldDisplayType.HIDDEN)) {
      overrideWarrantyField.selectedValue = null;
    }

    return super.save();
  }
}

export default PricingAndMileageStep;
