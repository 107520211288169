import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import type { RetailItemModifyMutationVariables } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { getStepField, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';

import { RetailBulkAdjustmentBuilderFields } from './interfaces';

/**
 * Modify Builder only step.
 */
class RetailBulkAdjustmentStep extends StepComponentCore<
  RetailItemResponseType,
  { metadata: undefined },
  RetailItemModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<RetailItemResponseType, { metadata: undefined }, RetailItemModifyMutationVariables>,
    context: CreateModifyContextInterface
  ) {
    super(props);
    const {
      tier: {
        activeStep,
        data: {
          rooftop,
          priceBulkAdjustments,
          overlayBulkAdjustments,
          descriptionBulkAdjustments,
          promotionBulkAdjustments,
          disclaimerBulkAdjustments,
        },
      },
    } = props;

    const {
      subContexts: {
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const isCashPaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
      featureFlagOn: rooftopPackageEnabled,
    });

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [RetailBulkAdjustmentBuilderFields.PRICE_ADJUSTMENTS]: {
        selectedValue: priceBulkAdjustments.filter(adjustment => adjustment.enabled),
        options: priceBulkAdjustments as StepFieldOptions[],
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !isCashPaymentOptionEnabled },
          { type: StepFieldDisplayType.OMITTED, active: !isCashPaymentOptionEnabled },
        ]),
      },
      [RetailBulkAdjustmentBuilderFields.OVERLAY_ADJUSTMENTS]: {
        selectedValue: overlayBulkAdjustments.filter(adjustment => adjustment.enabled),
        options: overlayBulkAdjustments as StepFieldOptions[],
      },
      [RetailBulkAdjustmentBuilderFields.DESCRIPTION_ADJUSTMENTS]: {
        selectedValue: descriptionBulkAdjustments.filter(adjustment => adjustment.enabled),
        options: descriptionBulkAdjustments as StepFieldOptions[],
      },
      [RetailBulkAdjustmentBuilderFields.PROMOTION_ADJUSTMENTS]: {
        selectedValue: promotionBulkAdjustments.filter(adjustment => adjustment.enabled),
        options: promotionBulkAdjustments as StepFieldOptions[],
      },
      [RetailBulkAdjustmentBuilderFields.DISCLAIMER_ADJUSTMENTS]: {
        selectedValue: disclaimerBulkAdjustments.filter(adjustment => adjustment.enabled),
        options: disclaimerBulkAdjustments as StepFieldOptions[],
      },
    });
  }

  save() {
    const {
      tier: {
        data: {
          priceBulkAdjustments,
          overlayBulkAdjustments,
          descriptionBulkAdjustments,
          promotionBulkAdjustments,
          disclaimerBulkAdjustments,
        },
      },
    } = this.props;

    const adjustmentsByType = {
      [RetailBulkAdjustmentBuilderFields.PRICE_ADJUSTMENTS]: priceBulkAdjustments,
      [RetailBulkAdjustmentBuilderFields.OVERLAY_ADJUSTMENTS]: overlayBulkAdjustments,
      [RetailBulkAdjustmentBuilderFields.DESCRIPTION_ADJUSTMENTS]: descriptionBulkAdjustments,
      [RetailBulkAdjustmentBuilderFields.PROMOTION_ADJUSTMENTS]: promotionBulkAdjustments,
      [RetailBulkAdjustmentBuilderFields.DISCLAIMER_ADJUSTMENTS]: disclaimerBulkAdjustments,
    };

    const disabledBulkAdjustmentIds = Object.entries(adjustmentsByType).flatMap(([fieldType, adjustments]) => {
      const selectedAdjustments = getStepField(fieldType, this.fields).selectedValue;

      return adjustments
        .filter(adjustment => !selectedAdjustments.some(selectedAdjustment => selectedAdjustment.id === adjustment.id))
        .map(adjustment => adjustment.id);
    });

    return super.save(undefined, { disabledBulkAdjustmentIds });
  }
}

export default RetailBulkAdjustmentStep;
