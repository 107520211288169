import { useEffect, useRef, useState } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import VehicleIcon from 'components/ui/icons/VehicleIcon';
import ImageLoader from 'components/ui/loading/ImageLoader';
import useImageLoader from 'hooks/useImageLoader';
import {
  OverlayRetailBulkAdjustmentPosition,
  OverlayRetailBulkAdjustmentScalingDimension,
} from 'store/api/graph/interfaces/types';
import { NEUTRAL_075, NEUTRAL_200 } from 'styles/tokens';

import type { OverlayImagePreviewProps } from './OverlayImagePreview';

/**
 * OverlayImageTransforms are the transformation values to position the overlay image within the preview
 */
export interface OverlayImageTransforms {
  // The width of the overlay image (in px)
  width: number;
  // The height of the overlay image (in px)
  height: number;
  // The top offset of the overlay image (in px)
  top?: number;
  // The bottom offset of the overlay image (in px)
  bottom?: number;
  // The left offset of the overlay image (in px)
  left?: number;
  // The right offset of the overlay image (in px)
  right?: number;
}

const PreviewFrameBody = styled.div<{
  aspectRatio: number;
  transforms?: OverlayImageTransforms;
  boxWidth?: number;
}>`
  position: relative;
  width: ${({ boxWidth }) => (boxWidth ? boxWidth + 'px' : '100%')};
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};

  img {
    position: absolute;
    ${({ transforms }) =>
      transforms
        ? css`
            width: ${transforms.width}px;
            height: ${transforms.height}px;
            top: ${isNil(transforms.top) ? 'initial' : transforms.top + 'px'};
            bottom: ${isNil(transforms.bottom) ? 'initial' : transforms.bottom + 'px'};
            left: ${isNil(transforms.left) ? 'initial' : transforms.left + 'px'};
            right: ${isNil(transforms.right) ? 'initial' : transforms.right + 'px'};
          `
        : null}
  }
`;

/**
 * The preview placeholder image. The overlay image will be layered on top of this placeholder in the preview.
 */
export const PreviewPlaceholderImage = styled.div`
  width: 100%;
  height: 100%;
  background: ${NEUTRAL_075};
  display: flex;
  justify-content: center;
  color: ${NEUTRAL_200};
  position: absolute;
  align-items: center;

  svg {
    width: 30%;
  }
`;

/**
 * Get the overlay image offset positions. This is determined by the OverlayRetailBulkAdjustmentPosition, which
 * will configure where the overlay image is going to be positioned. The image width/height and preview box width/height
 * are needed to center the overlay image.
 *
 * @param position - The overlay image position
 * @param imageWidth - The width of the overlay image (in px)
 * @param imageHeight - The height of the overlay image (in px)
 * @param previewBoxWidth - The width of the preview box container (in px)
 * @param previewBoxHeight - The height of the preview box container (in px)
 */
const getOverlayOffsetPositions = (
  position: OverlayRetailBulkAdjustmentPosition,
  imageWidth: number,
  imageHeight: number,
  previewBoxWidth: number,
  previewBoxHeight: number
): Partial<OverlayImageTransforms> =>
  ({
    [OverlayRetailBulkAdjustmentPosition.TOP_LEFT]: {
      top: 0,
      left: 0,
    },
    [OverlayRetailBulkAdjustmentPosition.TOP_CENTER]: {
      top: 0,
      left: (previewBoxWidth - imageWidth) / 2,
    },
    [OverlayRetailBulkAdjustmentPosition.TOP_RIGHT]: {
      top: 0,
      right: 0,
    },
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_LEFT]: {
      bottom: 0,
      left: 0,
    },
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_CENTER]: {
      bottom: 0,
      left: (previewBoxWidth - imageWidth) / 2,
    },
    [OverlayRetailBulkAdjustmentPosition.BOTTOM_RIGHT]: {
      bottom: 0,
      right: 0,
    },
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_LEFT]: {
      left: 0,
      top: (previewBoxHeight - imageHeight) / 2,
    },
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_CENTER]: {
      left: (previewBoxWidth - imageWidth) / 2,
      top: (previewBoxHeight - imageHeight) / 2,
    },
    [OverlayRetailBulkAdjustmentPosition.MIDDLE_RIGHT]: {
      right: 0,
      top: (previewBoxHeight - imageHeight) / 2,
    },
  })[position];

/**
 * Calculate the overlay image transforms. Will take in the position, scale and scaling dimension, and from this
 * calculate the height/width of the overlay image, as well as any position offset values. Will return an
 * OverlayImageTransforms object which contains all the necessary values to transform the overlay image.
 *
 * @param previewBox - The preview container that the overlay image is contained within
 * @param image - The overlay image
 * @param position - The position of the overlay image
 * @param scale - The scaling factor of the overlay image (between 0 - 100)
 * @param scalingDimension - The dimension the scaling will be applied to (width or height)
 */
const calculateOverlayImageTransforms = (
  previewBox: HTMLDivElement,
  image: HTMLImageElement,
  position: OverlayRetailBulkAdjustmentPosition,
  scale: number,
  scalingDimension: OverlayRetailBulkAdjustmentScalingDimension
): OverlayImageTransforms => {
  const scaledWidth = (scale / 100) * previewBox.offsetWidth;
  const scaledHeight = (scale / 100) * previewBox.offsetHeight;

  /*
   * Calculate the new width and height of the overlay image. If one dimension is being scaled, then the other
   * dimension should preserve the original aspect ratio of the image. This ensures the image will not stretch.
   */
  const newWidth =
    scalingDimension === OverlayRetailBulkAdjustmentScalingDimension.WIDTH
      ? scaledWidth
      : scaledHeight * (image.naturalWidth / image.naturalHeight);
  const newHeight =
    scalingDimension === OverlayRetailBulkAdjustmentScalingDimension.HEIGHT
      ? scaledHeight
      : scaledWidth * (image.naturalHeight / image.naturalWidth);

  return {
    width: newWidth,
    height: newHeight,
    ...getOverlayOffsetPositions(position, newWidth, newHeight, previewBox.offsetWidth, previewBox.offsetHeight),
  };
};

/**
 * The configuration props for rendering a preview box. It extends all the configuration from OverlayImagePreviewProps
 * with additional properties for setting the aspect ratio and alt image text of the preview box.
 */
export interface OverlayImagePreviewBoxProps extends OverlayImagePreviewProps {
  // The aspect ratio of the preview box
  aspectRatio: number;
  // The alt text for the preview image
  altImageText: string;
  // The width of the preview box in px. If none is provided, then 100% will be used
  boxWidth?: number;
  // ID to use in any cypress tests
  testId?: string;
}

/**
 * The OverlayImagePreviewBox will render an overlay image inside a preview box. Preview box can be configured
 * with a certain aspect ratio.
 *
 * @param imageUrl - The imageUrl of the overlay image. Can be a http url or base64 data url
 * @param position - The position of the overlay image
 * @param scalingDimension - The scaling dimension of the overlay image
 * @param scale - The amount to scale the overlay image (between 0 and 100)
 * @param aspectRatio - The aspect ratio of the preview box
 * @param altImageText - The alt text for the preview image
 * @param boxWidth - The width of the preview box
 * @param testId - ID to use in cypress tests
 */
export const OverlayImagePreviewBox = ({
  imageUrl,
  position,
  scalingDimension,
  scale,
  aspectRatio,
  altImageText,
  boxWidth,
  testId,
}: OverlayImagePreviewBoxProps) => {
  const previewBoxRef = useRef<HTMLDivElement>(null);
  const previewImageRef = useRef<HTMLImageElement>(null);
  const [previewTransform, setPreviewTransform] = useState<OverlayImageTransforms>();

  const isLoading = useImageLoader(imageUrl, true);

  useEffect(() => {
    // Calculate and set the overlay image preview transforms
    if (previewBoxRef.current && previewImageRef.current && !isLoading) {
      const data = calculateOverlayImageTransforms(
        previewBoxRef.current,
        previewImageRef.current,
        position,
        scale,
        scalingDimension
      );
      setPreviewTransform(data);
    }
  }, [position, scalingDimension, scale, imageUrl, isLoading]);

  const previewPlaceholder = (
    <PreviewPlaceholderImage>
      <VehicleIcon width={36} height={36} />
    </PreviewPlaceholderImage>
  );

  const previewImage = (
    <img
      alt={altImageText}
      src={imageUrl}
      ref={previewImageRef}
      /*
       * Hide the image until the preview transformations have been determined, this prevents the image from flashing
       * between the original size to the transformed size when initially loading
       */
      css={
        previewTransform
          ? css`
              visibility: initial;
            `
          : css`
              visibility: hidden;
            `
      }
    />
  );

  return (
    <PreviewFrameBody
      data-testid={testId}
      ref={previewBoxRef}
      transforms={previewTransform}
      aspectRatio={aspectRatio}
      boxWidth={boxWidth}
    >
      {imageUrl ? !isLoading && previewPlaceholder : previewPlaceholder}
      {isLoading && imageUrl && <ImageLoader />}
      {!isLoading && imageUrl && previewImage}
    </PreviewFrameBody>
  );
};
