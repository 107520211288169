import { memo, useCallback } from 'react';

import { get } from 'lodash-es';

import { useWebsiteDetailsMetaQueryFromWebsite } from 'components/sections/shared/ItemMetaHelpers';
import CollapsibleSection from 'components/ui/details/collapsibleSections/CollapsibleSection';
import { Badges } from 'components/ui/shared/Badge';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { BuilderType } from 'enums/builderType';
import { Website, WebsiteSettings } from 'enums/columns/website';
import { collapsibleRouteSectionTestId, gridCellItemEditIconTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useUser } from 'hooks/contexts/useUser';
import useEditField from 'hooks/useEditField';
import type { WebsiteDetailFragment } from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { WebsiteResponseType } from 'store/api/graph/responses/responseTypes';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback, fullGroupOrAboveScopes } from 'utils/permissionUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { InitialItemSortingBuilderFields } from '../createModify/shared/steps/interfaces';
import ItemGridCellContentExternalLink from '../shared/ItemGridCellExternalLink';
import { ItemGridRowDisplayType } from '../shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from '../shared/ItemGridRowSection';
import { ItemGridRowSection } from '../shared/ItemGridRowSection';
import StatusBadge from '../shared/StatusBadge';

import InitialItemSortingSection from './InitialItemSortingSection';

const { t } = translate;
const {
  WEBSITE_ID,
  NAME,
  STATUS,
  URL,
  GROUP_NAME,
  GROUP_ID,
  PRIMARY_ROOFTOP_NAME,
  LOCATION,
  PHONE_NUMBER,
  CREATED,
  UPDATED,
  GOOGLE_ADS_ID,
  GA4_PROPERTY_ID,
  LOCALES,
  PRIMARY_LOCALE,
  DEFAULT_INITIAL_ITEM_SORTING,
} = Website;

const { BOLD } = ItemGridRowDisplayType;

const gridConfig = (website: WebsiteResponseType | undefined): ItemGridRowProps[] => [
  { fields: [WEBSITE_ID, GROUP_ID], displayType: [BOLD], canEdit: false, canCopy: true },
  {
    fields: [
      { id: GROUP_NAME, canCopy: true },
      { id: PRIMARY_ROOFTOP_NAME, canEdit: true },
    ],
    displayType: [BOLD],
    canEdit: false,
  },
  {
    fields: [
      {
        id: STATUS,
        gridCellRenderMethod: (settings: TableSettingsType, item: any) => (
          <Badges>
            <StatusBadge item={item} />
          </Badges>
        ),
      },
      NAME,
    ],
    displayType: [BOLD],
    canEdit: true,
  },
  { fields: [URL, { id: GA4_PROPERTY_ID, canCopy: true }], displayType: [BOLD], canEdit: true },
  {
    fields: [LOCALES, { id: PRIMARY_LOCALE, canEdit: website ? website.locales.length > 1 : false }],
    displayType: [BOLD],
    canEdit: true,
  },
  { fields: [GOOGLE_ADS_ID, PHONE_NUMBER], canEdit: true },
  { fields: [LOCATION], canEdit: true },
];

const metaGridConfig: ItemGridRowProps[] = [{ fields: [CREATED, UPDATED], displayType: [BOLD] }];

const WebsiteDetailsTab = memo<ItemTabProps<WebsiteDetailFragment>>(({ item: website, metadata: websiteMetadata }) => {
  const { builderConfig } = useBuilderConfig();
  const { data: metadata } = useWebsiteDetailsMetaQueryFromWebsite(website, websiteMetadata);
  const {
    user: { scope },
    hasPermissions,
  } = useUser();

  const onEditClicked = useEditField({
    tierData: {
      itemId: website?.id,
      seededData: {
        website,
      },
      title: translate.t('website_one'),
      type: BuilderType.WEBSITE_MODIFY,
      entityType: EntityType.WEBSITE,
    },
  });

  const authorizedOnEdit = authorizedCallback({
    cb: onEditClicked,
    isAuth:
      hasPermissions(builderConfig[BuilderType.WEBSITE_MODIFY].requiredPermissions) &&
      fullGroupOrAboveScopes.includes(scope),
  }) as ItemTabProps['onEdit'];

  const customGridCellRenderMethod = useCallback((_, item, fieldId, _metadata) => {
    if (fieldId === URL) {
      const url: string = get(item, fieldId);
      return <ItemGridCellContentExternalLink to={url}>{url}</ItemGridCellContentExternalLink>;
    }
  }, []);

  return (
    <>
      {gridConfig(website).map(config => (
        <ItemGridRowSection
          key={formatItemGridRowSectionKey(config.fields)}
          item={website}
          settings={WebsiteSettings}
          metadata={metadata}
          config={config}
          onEdit={authorizedOnEdit}
          gridCellRenderMethod={customGridCellRenderMethod}
        />
      ))}
      <CollapsibleSection
        key={`collapsible-section-${DEFAULT_INITIAL_ITEM_SORTING}`}
        label={t('default_sort_rule')}
        onEdit={authorizedOnEdit?.bind(null, InitialItemSortingBuilderFields.ADD_SORTING_RULE)}
        editIconTestId={gridCellItemEditIconTestId(DEFAULT_INITIAL_ITEM_SORTING)}
        testId={collapsibleRouteSectionTestId(DEFAULT_INITIAL_ITEM_SORTING)}
        addBottomBorder={true}
      >
        {website?.[DEFAULT_INITIAL_ITEM_SORTING]?.length && (
          <InitialItemSortingSection
            items={website[DEFAULT_INITIAL_ITEM_SORTING]}
            metadata={{ websiteSortOptions: metadata.websiteSortOptions }}
          />
        )}
      </CollapsibleSection>
      {metaGridConfig.map(config => (
        <ItemGridRowSection
          key={formatItemGridRowSectionKey(config.fields)}
          item={website}
          settings={WebsiteSettings}
          metadata={metadata}
          config={config}
          onEdit={authorizedOnEdit}
          gridCellRenderMethod={customGridCellRenderMethod}
        />
      ))}
    </>
  );
});

export default WebsiteDetailsTab;
