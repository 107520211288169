import type { HTMLAttributes } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { clamp, debounce } from 'lodash-es';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components/macro';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { BODY_TEXT } from 'styles/color';
import { getCellClassName } from 'utils/tableUtils';

import ArrowLeftToRightIcon from '../icons/ArrowLeftToRightIcon';

import type { TableCellProps } from './TableCell';

const ResizeHandle = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  cursor: col-resize;

  :hover {
    opacity: 1;
  }
`;

const TableCell = styled.div<{ cellStyles?: string | string[]; width: number | string }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 44px; /** 45px - 1px (consider 1px border-bottom) */
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  color: black;
  text-align: left;
  ${({ cellStyles }) => (cellStyles && Array.isArray(cellStyles) ? cellStyles.join(' ') : cellStyles || '')}

  &:hover ${ResizeHandle} {
    opacity: 1;
  }
`;

type DropResult = {
  dropTarget: TableCellData;
};

type Props = {
  onDropped?: (dragTarget: TableCellData, dropTarget: TableCellData) => void;
  onCellResized: (cellData: TableCellData) => void;
  onResizeStartAndEnd: (cellId: string) => void;
  cellStyles?: string | string[];
  disabled: boolean;
} & TableCellProps &
  HTMLAttributes<HTMLDivElement>;

const TableHeaderCell = (props: Props) => {
  const {
    cellData,
    cellStyles,
    onDropped,
    onCellResized = () => {},
    onResizeStartAndEnd = () => {},
    disabled,
    contentWidth,
  } = useMemo(() => props, [props]);
  const styles = useMemo<string[]>(
    () => [cellStyles, cellData.styles].flat() as string[],
    [cellData.styles, cellStyles]
  );
  const cellId = useMemo(() => getCellClassName({ columnId: cellData.columnId }), [cellData.columnId]);
  const mouseMove = useRef<EventListenerOrEventListenerObject>();
  const mousePosX = useRef<number>();
  const originalWidth = useRef<number>();

  const [, drag] = useDrag({
    type: cellData.rowId,
    item: { dragTarget: cellData },
    canDrag: cellData.canReorder && !disabled,
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        onDropped?.(cellData, monitor.getDropResult<DropResult>()!.dropTarget);
      }
    },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: cellData.rowId,
    drop: () => ({ dropTarget: cellData }),
  });

  const onMouseMove = useMemo(
    () =>
      debounce(e => {
        const offset: number = mousePosX.current! - e.clientX;
        const newWidth = originalWidth.current! - offset;
        cellData.width = clamp(newWidth, cellData.minWidth || newWidth, cellData.maxWidth || newWidth);
        onCellResized(cellData);
      }, 1),
    [cellData, onCellResized]
  );

  const width = useMemo(
    () =>
      typeof cellData.width === 'string'
        ? (Number.parseInt(cellData.width.replace('%', '')) / 100) * contentWidth
        : cellData.width,
    [contentWidth, cellData.width]
  );

  const resizeStartEnd = useCallback(
    e => {
      const method = e.type === 'mousedown' ? 'addEventListener' : 'removeEventListener';
      if (e.type === 'mousedown') {
        mousePosX.current = e.clientX;
        originalWidth.current = width;
        mouseMove.current = e => onMouseMove(e);
      }
      window[method]('mousemove', mouseMove.current!);
      window[method]('mouseup', resizeStartEnd);

      onResizeStartAndEnd(e.type === 'mousedown' ? cellData.columnId : '');
    },
    [cellData, onMouseMove, onResizeStartAndEnd, width]
  );

  return (
    <TableCell
      className={cellId}
      cellStyles={styles}
      ref={useCallback(node => drag(drop(node)), [drag, drop])}
      width={width}
    >
      {cellData.render ? cellData.render() : cellData.content}
      {!disabled && cellData.resizable && (
        <ResizeHandle onMouseDown={resizeStartEnd}>
          <ArrowLeftToRightIcon color={BODY_TEXT} />
        </ResizeHandle>
      )}
    </TableCell>
  );
};

export default TableHeaderCell;
