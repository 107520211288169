import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { cloneDeep, isEqual, uniqWith } from 'lodash-es';
import styled from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { usePrevious } from 'hooks/usePrevious';
import { NEUTRAL_0 } from 'styles/tokens';
import { move } from 'utils/arrayUtils';

import { SubStepType } from '../interfaces/stepField';
import { SELECT_ALL_TOGGLE_OPTION } from '../stepFields/subSteps/interfaces';
import ListSelection from '../stepFields/subSteps/ListSelection';

import type { SubStepControllerProps } from './interfaces';

export interface SubStepHandles {
  selectedOptions?: SubStepOption[];
  // Override the options that are currently selected in the list, useful for dynamic on the fly list updates
  overrideSelectedOptions: (options: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${NEUTRAL_0};
  height: 100%;
`;

/**
 * A hook that renders the sub panel and fills it with selectable options according to the `type`.
 * Sometimes the rendering may be using an existing list, other times it may use a self-contained list.
 * This is contained by the implementing `StepComponent` and flies out over top of the `StepLegend`
 */
export const SubStepController = forwardRef<SubStepHandles, SubStepControllerProps>((props, ref) => {
  const {
    onSelected,
    onMultiSelectItemChecked,
    groupSubTypes = [],
    queryVar,
    options,
    selectedValue,
    childrenBefore,
    childrenAfter,
    onSubStepAdd,
    onEdit,
    onDelete,
    label,
    overrideSubStepSearchLabel,
    isShowing,
    seededValues,
    subStep,
    settings,
    onConfirmToggleFieldLock,
  } = useMemo(() => ({ ...props, options: cloneDeep(props.options) }), [props]);

  const selectedValuePrev = usePrevious(selectedValue);
  const seededValuesPrev = usePrevious(seededValues);
  const queryVarPrev = usePrevious(queryVar);
  const [selectedOptions, setSelectedOptions] = useState<SubStepOption[]>();
  const subTypes = useMemo(
    () => ({
      isColor: groupSubTypes.includes(StepFieldSubType.COLOR),
      isDate: groupSubTypes.includes(StepFieldSubType.DATE),
      isTime: groupSubTypes.includes(StepFieldSubType.TIME),
      isMultiSelect: groupSubTypes.includes(StepFieldSubType.MULTI_SELECT),
      isFieldGroup: groupSubTypes.includes(StepFieldSubType.FIELD_GROUP),
      isToggle: groupSubTypes.includes(StepFieldSubType.TOGGLE),
      isSortable: groupSubTypes.includes(StepFieldSubType.SORTABLE),
      isCheckbox: groupSubTypes.includes(StepFieldSubType.CHECKBOX),
      isSelectAllDisabled: groupSubTypes.includes(StepFieldSubType.SELECT_ALL_DISABLED),
    }),
    [groupSubTypes]
  );
  const listRef = useRef<ListSelection>();

  const onSelect = useCallback(
    option => {
      if (subTypes.isFieldGroup) {
        setSelectedOptions(option);
        if (!settings?.enableDoneButton) {
          onSelected(props, option, !subStep?.includes(SubStepType.ASYNC));
        }
      } else if (!subTypes.isMultiSelect) {
        onSelected(props, option);
      } else if (selectedOptions) {
        if (option?.id === SELECT_ALL_TOGGLE_OPTION) {
          // The option.data argument should contain the next options
          const newSelectedOptions = option.data;

          setSelectedOptions(newSelectedOptions);
          onSelected(props, newSelectedOptions, !subStep?.includes(SubStepType.ASYNC));

          return;
        }

        const optionIndex = selectedOptions.findIndex(o => option.id === (o.id || o));
        const isAdding = optionIndex === -1;
        const formattedOptions = isAdding
          ? [...selectedOptions, option]
          : selectedOptions.filter(o => option.id !== (o.id || o));

        const newSelectedOptions = subTypes.isSortable
          ? formattedOptions
          : formattedOptions.sort(
              (a, b) => options.findIndex(o => o.id === (a.id || a)) - options.findIndex(o => o.id === (b.id || b))
            );
        setSelectedOptions(newSelectedOptions);
        if (subTypes.isMultiSelect) {
          onMultiSelectItemChecked(props, option, newSelectedOptions);
        }
        if (!settings?.enableDoneButton) {
          onSelected(props, newSelectedOptions, !subStep?.includes(SubStepType.ASYNC));
        }
      }
    },
    [
      subTypes.isFieldGroup,
      subTypes.isMultiSelect,
      subTypes.isSortable,
      selectedOptions,
      settings?.enableDoneButton,
      onSelected,
      props,
      subStep,
      options,
      onMultiSelectItemChecked,
    ]
  );

  const onDone = useCallback(
    () => onSelected(props, selectedOptions, !subStep?.includes(SubStepType.ASYNC)),
    [onSelected, subStep, props, selectedOptions]
  );

  const onListItemDrag = useCallback(
    (itemId: string, indexNext: number) => {
      if (selectedOptions) {
        const newOptions = [...selectedOptions];
        const itemIndex = newOptions.findIndex(option => option.id === itemId || (option as string) === itemId);
        if (itemIndex > -1) {
          const reorderedOptions = move(newOptions, itemIndex, indexNext).map(item => item);
          setSelectedOptions(reorderedOptions);
        }
      }
    },
    [selectedOptions]
  );

  useEffect(() => {
    const optionsDidUpdate = queryVarPrev !== queryVar || !isEqual(seededValues, seededValuesPrev);
    const selectedValueUpdated = !isEqual(selectedValue, selectedValuePrev);

    if (selectedValueUpdated || optionsDidUpdate) {
      const optionsNext = subTypes.isFieldGroup
        ? options
        : [selectedValueUpdated ? selectedValue : selectedOptions]
            .flat()
            // Passing through seeded pre-selected values first
            .concat(seededValues || [])
            /*
             * Filtering out any selections that don't exist if there is an options array available,
             * bypassing filter for ASYNC steps
             */
            .filter(
              selection =>
                !options ||
                // Checking against selections that have an id and selections that are simple string values
                !!options.some(option =>
                  selection?.id === undefined ? option.id === selection : option.id === selection?.id
                ) ||
                (subStep?.includes(SubStepType.ASYNC) && !!selection)
            );

      // Setting new scroll value for new data
      if (optionsDidUpdate) {
        listRef.current?.scrollTo(
          // If an applicable item is found, scroll it into view, else scroll to top
          (seededValues?.find?.(item => item.focus && item.id)?.id && seededValues?.[0]?.id) || 0
        );
      }

      // Ignoring uniqBy operation if the array is a string array and not an object array
      const newOptions = uniqWith(optionsNext, (a, b) => (a?.id || a) === (b?.id || b));
      setSelectedOptions(newOptions);
    }
  }, [
    selectedValuePrev,
    selectedValue,
    queryVarPrev,
    queryVar,
    options,
    seededValuesPrev,
    seededValues,
    subTypes,
    selectedOptions,
    subStep,
  ]);

  // Exposing callback methods to parent
  useImperativeHandle(
    ref,
    (): SubStepHandles => ({
      get selectedOptions() {
        return selectedOptions;
      },
      overrideSelectedOptions: options => {
        setSelectedOptions(options);
      },
    }),
    [selectedOptions]
  );

  return (
    <Container>
      {childrenBefore}
      {!subStep?.includes(SubStepType.CUSTOM) && (
        <ListSelection
          // Inherited & passed props
          {...props}
          {...subTypes}
          // Computed props
          ref={item => {
            if (item) {
              listRef.current = item;
            }
          }}
          label={overrideSubStepSearchLabel || label}
          selectedOptions={selectedOptions}
          onSelect={onSelect}
          onSelected={onSelected}
          onEdit={onEdit}
          onDelete={onDelete}
          onDone={settings?.enableDoneButton || subTypes.isSortable ? onDone : undefined}
          onListItemDrag={onListItemDrag}
          // Aliased props
          isDisabled={!isShowing}
          type={queryVar}
          onAdd={onSubStepAdd}
          settings={settings}
          onConfirmToggleFieldLock={onConfirmToggleFieldLock}
        />
      )}
      {childrenAfter}
    </Container>
  );
});
