import { gql } from '@apollo/client';

export const conversationItemAttachment = gql`
  fragment ConversationItemAttachmentFragment on ConversationItemAttachment {
    id
    extension
    name
    type
    typeName
    size
    url
    thumbnailUrl
  }
`;
