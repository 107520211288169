import { memo } from 'react';

import styled from 'styled-components/macro';

import ChevronDownIcon from './ChevronDownIcon';

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: rotate(180deg);
`;

export default memo(ChevronUpIcon);
