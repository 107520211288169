import DynamicImport from 'components/core/DynamicImport';
import { TabType } from 'enums/tabType';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';

export const retailItemsDetailsTabs = [
  {
    tabId: TabType.DETAILS,
    component: DynamicImport(() => import('components/sections/inventoryItems/retailItems/RetailItemDetailsTab')),
  },
  {
    tabId: TabType.RETAIL_PRICING,
    component: DynamicImport(
      () => import('components/sections/inventoryItems/retailItems/pricing/RetailItemPricingTab')
    ),
    label: 'pricing',
  },
  {
    tabId: TabType.MEDIA,
    component: DynamicImport(() => import('components/sections/inventoryItems/shared/InventoryItemMediaTab')),
  },
  {
    tabId: TabType.LINKED,
    component: DynamicImport(() => import('components/sections/inventoryItems/retailItems/RetailItemLinkedTab')),
  },
];

export const getRetailItemDetailsTitle = (retailItem: RetailItemResponseType) => formatYMMT(retailItem);
