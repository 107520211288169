import type { ElementType } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { Section } from 'components/ui/layouts/CardLayout';
import { InputToggleButton } from 'components/ui/shared/Button';
import { toggleSelectOptionTestId } from 'enums/testing';
import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import { BLUE_500, LINE_HEIGHT_2, NEUTRAL_0, NEUTRAL_050, NEUTRAL_500 } from 'styles/tokens';
import { FONT_SIZE_14, FONT_WEIGHT_SEMI_BOLD } from 'styles/typography';
import { translate } from 'utils/intlUtils';

export interface ToggleSelectOptionConfig {
  /** Id of the option */
  id: string;
  /** The display label used for this option */
  label: string;
  /** Optional colour for the display label when it is not selected */
  labelColour?: string;
  /** Optional colour for the display label when it is selected */
  labelSelectedColour?: string;
  /** Custom background colour when this option is selected */
  colour?: string;
  /** Whether to highlight option to user. Option has grey background with bold text. e.g. a default option */
  isHighlighted?: boolean;
  /** Optional icon that can be displayed */
  icon?: ElementType;
}

export interface ToggleSelectType {
  /** Default pre-selected value */
  defaultValue?: string;
  /** The onChange callback fired whenever a new option is selected */
  onChange?: (e: Record<'currentTarget', { value: string }>) => void;
  /** List of toggle options */
  options: ToggleSelectOptionConfig[];
  /** Whether or not this control should be disabled */
  isDisabled?: boolean;
  /** Ref for dynamically setting selected toggle option. TODO: move away from using any for refs */
  ref?: any;
}

export interface ToggleSelectRef {
  select: (id: string) => void;
}

/**
 * A `ToggleSelect` details section.
 */
export const ToggleSection = styled(Section)`
  ${Label} {
    margin: 0;
  }

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// TODO: Refactor to use variant prop
const ToggleButton = styled(InputToggleButton)<
  Pick<ToggleSelectOptionConfig, 'isHighlighted'> & {
    /** Whether or not this toggle is selected */
    selected?: boolean;
    /** The custom background colour for when this toggle is selected */
    selectedColour: string;
    /** When an adjacent toggle is selected, the border colour should match the selected colour */
    adjacentBorderColour?: string;
  }
>`
  background: ${props => {
    if (props.selected) {
      return props.selectedColour;
    } else if (props.isHighlighted) {
      return NEUTRAL_050;
    }
    return NEUTRAL_0;
  }};
  border-radius: 0;
  width: 100%;
  height: 24px;
  min-width: unset;
  padding: 0 8px;

  /* Need to unset margins from base component (InputToggleButton) */
  &:not(:last-child) {
    margin-right: 0;
  }

  /* Don't want double borders in-between buttons */
  &:not(:first-child) {
    border-left: 0;
  }

  ${props => css`
    border-right: 1px solid ${props.adjacentBorderColour || NEUTRAL_500};
  `}

  ${props =>
    props.selected
      ? css`
          border-top: 1px solid ${props.selectedColour};
          border-bottom: 1px solid ${props.selectedColour};
          border-right: 1px solid ${props.selectedColour};
          border-left: 0;
        `
      : css`
          border-top: 1px solid ${NEUTRAL_500};
          border-bottom: 1px solid ${NEUTRAL_500};
        `}

  /** First Button */
  :first-child {
    margin-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    ${props => css`
      border-left: 1px solid ${props.selected ? props.selectedColour : NEUTRAL_500};
    `};
  }

  /** Last Button */
  :last-child {
    margin-right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0;
    ${props => css`
      border-right: 1px solid ${props.selected ? props.selectedColour : NEUTRAL_500};
    `};
  }

  :disabled {
    opacity: 1;
  }
`;

const ToggleButtonLabel = styled(Label)<{
  /** Whether or not this toggle label is selected */
  selected?: boolean;
  /** Whether or not this toggle label is supposed to be highlighted */
  isHighlighted: boolean;
  /** The colour of the label when it is not selected */
  labelColour: string;
  /** The colour of the label when it is selected */
  labelSelectedColour: string;
  /** If there is an icon present, the label will need some additional margins */
  hasIcon?: boolean;
}>`
  color: ${props => {
    if (props.selected) {
      return props.labelSelectedColour;
    } else if (props.isHighlighted) {
      return BODY_TEXT;
    }
    return props.labelColour;
  }};
  ${props =>
    props.hasIcon &&
    css`
      margin-left: 5px;
    `}
  font-size: ${FONT_SIZE_14};
  font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  line-height: ${LINE_HEIGHT_2};
`;

const ToggleSelect = forwardRef<ToggleSelectRef | undefined, ToggleSelectType>(
  ({ defaultValue, onChange, options, isDisabled }, ref) => {
    const [selectedToggle, setSelectedToggle] = useState<string | undefined>(defaultValue);

    const onToggle = useCallback(
      id => {
        setSelectedToggle(id);
        onChange?.({ currentTarget: { value: id } });
      },
      [onChange, setSelectedToggle]
    );

    /**
     * Each Toggle button has a right border and no left border (except for the first and last toggle buttons which are
     * a special case). This ensures that no toggle button has a double border between them. However, when a toggle
     * button is selected, the colour of the border adjacent to that button must be changed to match the buttons
     * selected background colour.
     */
    const getAdjacentBorderColour = useCallback(
      index => {
        if (selectedToggle === options[index + 1]?.id) {
          return isDisabled ? BODY_TEXT_TERTIARY : options[index + 1]?.colour || BLUE_500;
        }
        return undefined;
      },
      [selectedToggle, options, isDisabled]
    );

    /** Imperative handler for programmatically selecting a toggle option */
    useImperativeHandle(ref, () => ({
      select: id => setSelectedToggle(id),
    }));

    /**
     * Whenever the list of available options change, a check needs to be done to see if the currently selected
     * option is still available. If its not, then select the next available option.
     */
    useEffect(() => {
      const nextAvailableOption = options?.[0]?.id;
      if (!options?.some(option => option.id === selectedToggle) && !!nextAvailableOption) {
        setSelectedToggle(nextAvailableOption);
        onChange?.({ currentTarget: { value: nextAvailableOption } });
      }
    }, [options, selectedToggle, onChange]);

    return (
      <div css="display: inline-flex">
        {options.map((toggle, index) => {
          const Icon = toggle.icon;
          const isSelected = selectedToggle === toggle.id;
          const labelColour = toggle.labelColour || BODY_TEXT_TERTIARY;
          const labelSelectedColour = toggle.labelSelectedColour || NEUTRAL_0;

          return (
            <ToggleButton
              data-testid={toggleSelectOptionTestId(toggle.id)}
              key={`toggle-${toggle.id}`}
              adjacentBorderColour={getAdjacentBorderColour(index)}
              selectedColour={isDisabled ? BODY_TEXT_TERTIARY : toggle.colour || BLUE_500}
              selected={isSelected}
              isHighlighted={toggle.isHighlighted}
              onClick={() => onToggle(toggle.id)}
              disabled={isDisabled}
            >
              {Icon && <Icon width={16} height={16} color={isSelected ? NEUTRAL_0 : labelColour} />}
              <ToggleButtonLabel
                selected={isSelected}
                isHighlighted={!!toggle.isHighlighted}
                labelColour={labelColour}
                labelSelectedColour={labelSelectedColour}
                hasIcon={!!Icon}
              >
                {translate.t(toggle.label)}
              </ToggleButtonLabel>
            </ToggleButton>
          );
        })}
      </div>
    );
  }
);

export default ToggleSelect;
