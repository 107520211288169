import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';
import { trimPhotoFragment } from './trimPhotoFragment';

export const trimFragment = gql`
  fragment TrimFragment on Trim {
    id
    name {
      ...MultilingualStringFragment
    }
    source

    interiorPhotos: photos(photoType: INTERIOR) {
      ...TrimPhotoFragment
    }
    exteriorPhotos: photos(photoType: EXTERIOR) {
      ...TrimPhotoFragment
    }

    specification {
      technicalSpecifications {
        id
        category
        name {
          ...MultilingualStringFragment
        }
        value {
          ...MultilingualStringFragment
        }
        enabled
      }
      standardEquipment {
        id
        category
        name {
          ...MultilingualStringFragment
        }
        enabled
      }

      attributes {
        ... on VehicleSpecificationAttributes {
          colors {
            exteriorColor {
              primary {
                genericColor
                genericColorName
                genericColorRgbHex
                manufacturerRgbHex
                manufacturerCode
                name {
                  value
                }
                rgbHex
              }
            }
          }
        }
      }
    }
  }
  ${MultilingualString}
  ${trimPhotoFragment}
`;
