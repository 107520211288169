import { BuilderConfigProvider } from 'providers/BuilderConfigProvider';
import { CreateModifyProvider } from 'providers/CreateModifyProvider';
import { GlobalDialogProvider } from 'providers/GlobalDialogProvider';
import { NestedViewProvider } from 'providers/NestedViewProvider';
import { PhotoPreviewContextProvider } from 'providers/PhotoPreviewContextProvider';
import { UserProvider } from 'providers/UserProvider';
import { combineProviders } from 'utils/contextUtils';

/* All EDealer specific providers that require a global context should live here */
const EDealerProvider = combineProviders([
  GlobalDialogProvider,
  UserProvider,
  BuilderConfigProvider,
  PhotoPreviewContextProvider,
  CreateModifyProvider,
  NestedViewProvider,
]);

export default EDealerProvider;
