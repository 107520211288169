import { gql } from '@apollo/client';

import { avatar } from './avatar';

export const identity = gql`
  fragment IdentityFragment on Identity {
    id
    avatar {
      ...AvatarFragment
    }
    firstName
    displayName
    lastName
    rgbHex
  }
  ${avatar}
`;
