import { memo, type SVGProps } from 'react';

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M10.675 13.325h-4.83c-.369 0-.681-.129-.94-.386A1.278 1.278 0 0 1 4.52 12c0-.368.128-.681.386-.939.258-.257.57-.386.939-.386h4.83v-4.83c0-.369.129-.681.386-.94.258-.257.57-.385.939-.385.368 0 .681.128.939.386.257.258.386.57.386.939v4.83h4.83c.368 0 .681.129.939.386.258.258.386.57.386.939 0 .368-.128.681-.386.939-.258.257-.57.386-.939.386h-4.83v4.83c0 .368-.129.681-.386.939-.258.258-.57.386-.939.386-.368 0-.681-.128-.939-.386a1.278 1.278 0 0 1-.386-.939v-4.83Z"
    />
  </svg>
);

export default memo(AddIcon);
