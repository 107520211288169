import { gql } from '@apollo/client';

import { retailItemFilters } from 'store/api/graph/fragments/retailItemFilters';
import { rooftop } from 'store/api/graph/fragments/rooftop';

export const rooftopRetailPricing = gql`
  fragment RooftopRetailPricingFragment on RooftopRetailPricing {
    id
    description
    name
    order
    paymentOption
    paymentOptionName
    rooftopName: rooftop {
      ...RooftopFragment
    }
    filter {
      ...RetailItemFiltersFragment
    }
    targets
    targetsNames
  }

  ${retailItemFilters}
  ${rooftop}
`;
