import { useMemo } from 'react';

import { gql } from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { User } from 'enums/columns/user';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { locale } from 'store/api/graph/fragments/locale';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { resource } from 'store/api/graph/fragments/resource';
import { userDetail } from 'store/api/graph/fragments/userDetail';
import { userList } from 'store/api/graph/fragments/userList';
import { whiteLabel } from 'store/api/graph/fragments/whiteLabel';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const userDetailsQuery = gql`
  query UserDetailsContainerQuery($id: ID!, $d_isUsersAdmin: Boolean!) {
    ## Important: 'item' alias is required for data reading
    item: user(id: $id) {
      ...UserDetailFragment
    }

    metadata: resources @include(if: $d_isUsersAdmin) {
      ...ResourceFragment
    }
  }
  ${userDetail}
  ${resource}
`;

export const userCondensedListQuery = gql`
  query UserCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $filter: UserConnectionFilterInput
  ) {
    connection: userConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...UserListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${userList}
`;

export const userContainerQuery = gql`
  query UserConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $searchFilter: FacetSearchInput
    $filter: UserConnectionFilterInput
    $d_emailOn: Boolean!
    $d_cellNumberOn: Boolean!
    $d_phoneNumberOn: Boolean!
    $d_whiteLabelNameOn: Boolean!
    $d_localeOn: Boolean!
    $d_activeOn: Boolean!
    $d_subscribedEmailOn: Boolean!
    $d_lastActiveOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
  ) {
    connection: userConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...UserListFragment
          ## Directives
          whiteLabelName: whiteLabel @include(if: $d_whiteLabelNameOn) {
            ...WhiteLabelFragment
          }
          locale @include(if: $d_localeOn) {
            ...LocaleFragment
          }
          email @include(if: $d_emailOn)
          cellNumber @include(if: $d_cellNumberOn)
          phoneNumber @include(if: $d_phoneNumberOn)
          active @include(if: $d_activeOn)
          subscribedEmail @include(if: $d_subscribedEmailOn)
          lastActive @include(if: $d_lastActiveOn)
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${locale}
  ${whiteLabel}
  ${userList}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  User.FIRST_NAME,
  User.LAST_NAME,
  User.ROOFTOP_NAME,
] as string[];

export const useUserConnectionQuery = (
  variables = {},
  options?: any,
  queryAdapter?: (data: any) => any
): CustomQueryResult => {
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(TableType.USERS);
  const formattedTableColumns = gqlFormatTableColumnFields(userContainerQuery, tableConfigurationNext, staticColumns);

  return useQuery(userContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
    queryAdapter,
  });
};

export const useUserDetailsQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const { hasPermissions } = useUser();
  const formattedConfig = useMemo(
    () => ({
      ...config,
      variables: {
        ...config.variables,
        d_isUsersAdmin: hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.FULL }]),
      },
    }),
    [config, hasPermissions]
  );
  return useQuery(query, formattedConfig);
};
