import { useEffect } from 'react';

import { usePrevious } from './usePrevious';

const useIsLoaded = (isLoaded: boolean, onLoaded: (isLoaded: boolean) => void) => {
  const isLoadedPrev = usePrevious(isLoaded);
  useEffect(() => {
    if (!isLoadedPrev && isLoaded) {
      onLoaded(true);
    }
  }, [isLoadedPrev, isLoaded, onLoaded]);
};

export default useIsLoaded;
