import { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { ToggleButton as BaseToggleButton } from 'components/ui/shared/Button';
import { PrimaryArrowPosition, SecondaryArrowPosition, TooltipStyle } from 'components/ui/shared/Tooltip';
import TooltipButton from 'components/ui/shared/TooltipButton';
import { ItemViewType } from 'enums/ItemViewType';
import { SubRoute } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import { useRouter } from 'hooks/useRouter';
import { withTooltip } from 'hooks/withTooltip';
import { BORDER_DEFAULT } from 'styles/color';
import { BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';
import { getInitialLayoutView, saveInitialLayoutView } from 'utils/persistanceUtils';

import DetailsViewIcon from '../icons/DetailsViewIcon';
import TableViewIcon from '../icons/TableViewIcon';

const ViewModeToggleButtonContainer = styled.div`
  display: flex;
`;

const FilterSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Label} {
    top: 0;
  }
`;

const borderStyles = `1px solid ${BORDER_DEFAULT}`;

const ToggleButton = styled(BaseToggleButton)<{ isSelected?: boolean }>`
  width: initial;
  height: initial;
  padding: 0;
  margin: 0 0 2px 0;
  border: 0;

  svg {
    color: ${({ isSelected }) => (isSelected ? NEUTRAL_0 : BORDER_DEFAULT)};
  }

  border-top: ${borderStyles};
  border-bottom: ${borderStyles};

  &:first-child {
    border-radius: 8px 0 0 8px;
    border-left: ${borderStyles};
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
    border-right: ${borderStyles};
  }

  ${({ isSelected }) => `
    &:first-child {
      border-color: ${isSelected ? BLUE_500 : BORDER_DEFAULT};
    }
    &:last-child {
      border-color: ${isSelected ? BLUE_500 : BORDER_DEFAULT};
    }
  `};
`;

const ToggleButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
`;

const DetailsViewTooltipIcon = withTooltip(() => (
  <ToggleButtonIconContainer>
    <DetailsViewIcon />
  </ToggleButtonIconContainer>
));

const TableListViewTooltipIcon = withTooltip(() => (
  <ToggleButtonIconContainer>
    <TableViewIcon />
  </ToggleButtonIconContainer>
));

const ViewToggleFilter = () => {
  const router = useRouter();

  const initialLayoutView = getInitialLayoutView({
    forPathname: router.pathname,
    usePersistedView: router.isDefaultSectionPath,
  });
  const [initialViewMode, setInitialViewMode] = useState<ItemViewType>(initialLayoutView);

  // Set view mode
  useEffect(() => {
    setInitialViewMode(initialLayoutView);
    saveInitialLayoutView({ forPathname: router.sectionPath, toViewType: initialLayoutView });
  }, [initialLayoutView, router.sectionPath]);

  return (
    <FilterSectionContainer>
      <Label>{translate.t('layout')}</Label>
      <ViewModeToggleButtonContainer data-testid={ElementTestId.FACET_FILTER_LAYOUT_VIEW_BUTTON}>
        <ToggleButton
          isSelected={initialViewMode === ItemViewType.SPLIT_VIEW}
          onClick={useCallback(() => {
            saveInitialLayoutView({ forPathname: router.sectionPath, toViewType: ItemViewType.SPLIT_VIEW });
            setInitialViewMode(ItemViewType.SPLIT_VIEW);
            router.push(router.sectionPath);
          }, [router])}
        >
          <TooltipButton
            icon={DetailsViewTooltipIcon}
            tooltip={{
              arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
              margin: { x: 0, y: 8 },
              styleVariant: TooltipStyle.NORMAL,
            }}
          >
            {translate.t('split_view')}
          </TooltipButton>
        </ToggleButton>
        <ToggleButton
          isSelected={initialViewMode === ItemViewType.TABLE_VIEW}
          onClick={useCallback(() => {
            saveInitialLayoutView({ forPathname: router.sectionPath, toViewType: ItemViewType.TABLE_VIEW });
            setInitialViewMode(ItemViewType.TABLE_VIEW);
            router.push(`${router.sectionPath}${SubRoute.TABLE}`);
          }, [router])}
        >
          <TooltipButton
            icon={TableListViewTooltipIcon}
            tooltip={{
              arrowPosition: { primary: PrimaryArrowPosition.TOP, secondary: SecondaryArrowPosition.CENTER },
              margin: { x: 0, y: 8 },
              styleVariant: TooltipStyle.NORMAL,
            }}
          >
            {translate.t('table_view')}
          </TooltipButton>
        </ToggleButton>
      </ViewModeToggleButtonContainer>
    </FilterSectionContainer>
  );
};

export default ViewToggleFilter;
