import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const rooftopIntegrationList = gql`
  fragment RooftopIntegrationListFragment on RooftopIntegration {
    id
    active
    integrationName: integration {
      id
      value: name
    }
    integrationType: integration {
      id: type
      value: typeName
    }
    primary
    privacyPolicyUrl
    rooftopName: rooftop {
      name {
        ...MultilingualStringFragment
      }
    }
    updated
  }

  ${MultilingualString}
`;
