import type { UIEvent } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash-es';
import styled from 'styled-components/macro';

import { ScrollableType } from 'enums/scrollableType';
import { LDFeatureFlags } from 'utils/featureFlagUtils';

import type { ScrollableRef } from './interfaces/Scrollable';
import type { TranslateScrollableProps } from './TranslateScrollable';
import TranslateScrollable from './TranslateScrollable';

const ScrollableContainer = styled.div`
  height: 100%;
  overflow: auto;
  -ms-overflow-style: auto;

  /* Prevent underlying content from overlapping native scrollbar in Safari */
  transform: translateZ(0);
`;
export interface DefaultScrollableProps {
  /**
   * The type "ReactElement" wasn't playing nice since children is sometimes a function in this instance,
   * so had to go with 'any' as a type for children here.
   */
  children?: any;
  /** Callback for any onScroll events */
  onScroll?: (e: UIEvent<HTMLElement>) => void;
  /** Reference to scrollbar container */
  ref?: any;
}

/**
 * A component that renders a scrollable section. Scrollbars are added directly via `overflow: auto`,
 * rather than being rendered independently and positioned overlapping the container, as with TranslateScrollable
 */
const DefaultScrollable = forwardRef<ScrollableRef | undefined, DefaultScrollableProps>(
  ({ children, onScroll = () => {} }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const updateScroll = (x: number | null, y: number | null) => {
      if (containerRef.current) {
        if (!isNil(x)) {
          containerRef.current.scrollLeft = -x;
        }
        if (!isNil(y)) {
          containerRef.current.scrollTop = -y;
        }
      }
    };

    useImperativeHandle(
      ref,
      (): ScrollableRef => ({
        updateScroll,
        container: () => containerRef?.current as HTMLDivElement,
      })
    );

    return <ScrollableContainer ref={containerRef}>{children}</ScrollableContainer>;
  }
);

type ScrollableProps = (DefaultScrollableProps & TranslateScrollableProps) & {
  scrollableType?: ScrollableType;
};

/**
 * A component that renders a scrollable section, using a chosen scrollable type
 */
const Scrollable = forwardRef<ScrollableRef | undefined, ScrollableProps>(
  ({ children, scrollableType = ScrollableType.DEFAULT, ...props }, ref) => {
    const flags = useFlags();

    return scrollableType === ScrollableType.TRANSLATE && flags[LDFeatureFlags.translateScrollbarEnabled] ? (
      <TranslateScrollable ref={ref} {...props}>
        {children}
      </TranslateScrollable>
    ) : (
      <DefaultScrollable ref={ref} {...props}>
        {children}
      </DefaultScrollable>
    );
  }
);

Scrollable.displayName = 'Scrollable';

export default Scrollable;
