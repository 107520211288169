import { memo } from 'react';

import styled from 'styled-components/macro';

import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import { WebsiteRouteFilterBadges } from 'components/sections/createModify/websiteRoutes/WebsiteRouteFilterBadges';
import { getRouteFilterGroup } from 'components/sections/createModify/websiteRoutes/websiteRouteFilterUtils';
import { ListItem } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { ElementTestId } from 'enums/testing';
import type { RetailItemConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';

import EditIcon from '../icons/EditIcon';
import TrashCanIcon from '../icons/TrashCanIcon';
import { Badges } from '../shared/Badge';

interface Props extends ListItemProps, RetailItemConnectionFilterFragment {}

export type RetailItemConnectionFilterStepOption = RetailItemConnectionFilterFragment &
  StepFieldOptions & {
    metadata?: any;
  };

export const ListItemBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  margin-right: 2px !important; /* Overriding margin-right that is being passed down from ListItemContainer */
  gap: 2px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const RetailFilterListItem = ({ suffixIcon, listItemType, metadata, onDelete, ...filter }: Props) => {
  const filterGroup = getRouteFilterGroup(filter, metadata);

  return (
    <ListItem suffixIcon={suffixIcon} listItemType={listItemType} isHighlighted={false}>
      <ListItemBadgeContainer>
        <Badges css={'width: 80%'}>
          <WebsiteRouteFilterBadges filters={filterGroup} />
        </Badges>
        <IconContainer>
          <EditIcon height={20} width={20} color={BODY_TEXT} />
          <TrashCanIcon
            color={BODY_TEXT}
            data-testid={`${ElementTestId.DELETE_ICON}-${(filter as RetailItemConnectionFilterStepOption).id}`}
            onClick={e => {
              e.stopPropagation();
              onDelete?.((filter as RetailItemConnectionFilterStepOption).id as string);
            }}
          />
        </IconContainer>
      </ListItemBadgeContainer>
    </ListItem>
  );
};

export default memo(RetailFilterListItem);
