import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { PhotoHeaders, SelectHeaders } from 'components/ui/tables/tableSettings/configs/staticTableConfig';
import { InventoryItem, InventoryItemSettings } from 'enums/columns/inventoryItem';
import { FieldDataType } from 'enums/fieldDataType';
import { ImageType } from 'enums/imageType';
import { ItemsColumn } from 'enums/itemsColumn';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

const defaultTradeInItemHeaders: Partial<TableCellData> = {
  width: 90,
};

export const tradeInItemsDefaultSortOrder = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  InventoryItem.VIN,
  InventoryItem.STATUS,
  InventoryItem.TYPE,
  InventoryItem.CONDITION,
  InventoryItem.YEAR,
  InventoryItem.MAKE_NAME,
  InventoryItem.MODEL_NAME,
  InventoryItem.SUB_MODEL_NAME,
  InventoryItem.TRIM_NAME,
  InventoryItem.TAG_NAME,
  InventoryItem.VEHICLE_MILEAGE,
  InventoryItem.MOTORCYCLE_MILEAGE,
  InventoryItem.ACCIDENT_AMOUNT,
  InventoryItem.APPOINTMENTS_COUNT,
  InventoryItem.CONDITION_SCORE,
  InventoryItem.LEAD,
  InventoryItem.LEAD_NOTE,
  InventoryItem.TASKS_COUNT,
  InventoryItem.ARCHIVED,
  InventoryItem.UPDATED,
  InventoryItem.CREATED,
  InventoryItem.CREATED_BY_NAME,
];

export const TRADE_IN_ITEMS_BASE = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),

  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: ImageType.INVENTORY_ITEM,
  }),

  // ...Rest
  getCellDataByItemColumnType(InventoryItem.VIN, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 120,
  }),

  getCellDataByItemColumnType(InventoryItem.STATUS, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.TYPE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CONDITION, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 150,
  }),
  getCellDataByItemColumnType(InventoryItem.YEAR, InventoryItemSettings, defaultTradeInItemHeaders),
  getCellDataByItemColumnType(InventoryItem.MAKE_NAME, InventoryItemSettings, defaultTradeInItemHeaders),
  getCellDataByItemColumnType(InventoryItem.MODEL_NAME, InventoryItemSettings, defaultTradeInItemHeaders),
  getCellDataByItemColumnType(InventoryItem.SUB_MODEL_NAME, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 150,
  }),
  getCellDataByItemColumnType(InventoryItem.TRIM_NAME, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 290,
  }),
  getCellDataByItemColumnType(InventoryItem.TAG_NAME, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.NOTE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.ACCIDENT_AMOUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 160,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.APPOINTMENTS_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CONDITION_SCORE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.LEAD, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.LEAD_NOTE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.TASKS_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.ARCHIVED, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.UPDATED, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CREATED, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 160,
    cellType: FieldDataType.CUSTOM,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.CREATED_BY_NAME, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 120,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.VIDEOS_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_DAMAGE_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_EXTERIOR_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
  getCellDataByItemColumnType(InventoryItem.PHOTOS_INTERIOR_COUNT, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    enabled: false,
  }),
]);

export const TRADE_IN_ITEMS_VEHICLES = Object.freeze<TableCellData[]>([
  // ## attributes of VEHICLE
  getCellDataByItemColumnType(InventoryItem.VEHICLE_MILEAGE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 150,
  }),
]);

export const TRADE_IN_ITEMS_MOTORCYCLES = Object.freeze<TableCellData[]>([
  // ## attributes of MOTORCYCLE
  getCellDataByItemColumnType(InventoryItem.MOTORCYCLE_MILEAGE, InventoryItemSettings, {
    ...defaultTradeInItemHeaders,
    width: 100,
  }),
]);
