import 'styled-components/macro';

import type { Props } from './InputText';
import { Input, InputContainer, InputPrefix } from './InputText';

type PrefixedInputProps = Props & {
  inputCss?: string;
  inputPrefixDelimiter: string;
};
/**
 * Input to capture prefixed input fields.
 */
const PrefixedInput = (props: PrefixedInputProps) => {
  const { inputPrefixDelimiter, inputCss = 'padding-left: 30px' } = props;

  return (
    <InputContainer>
      <Input {...props} css={inputCss} />
      <InputPrefix>{inputPrefixDelimiter}</InputPrefix>
    </InputContainer>
  );
};

export default PrefixedInput;
