import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { websiteDetailQuery } from 'containers/websites/WebsitesContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import type { WebsiteDetailFragment } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

import { fetchWebsiteRetailFilterMetaquery, getParametersForMetadata } from './websiteRouteFilterUtils';

const WebsiteRouteQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const params = getParametersForMetadata(seededData?.website as WebsiteDetailFragment);
    return fetchWebsiteRetailFilterMetaquery(params);
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: websiteDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['WebsiteDetailsContainerQuery'],
};

export default WebsiteRouteQueryMethods;
