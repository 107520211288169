import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type Tier from 'components/core/createModify/interfaces/tier';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CustomEntity } from 'enums/extendedEntityType';
import { StepFieldType } from 'enums/stepFieldType';
import { EntityType } from 'store/api/graph/interfaces/types';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';
import type { Locale } from 'utils/intlUtils';
import { getMultilingualValueFromLocale } from 'utils/intlUtils';

// Used to seed data from a main tier to a tag sub tier.
export const seedTagStepData = (preFill: any, { entityType }: Tier) => {
  // Bulk adjustments and pricing segments can only have tags applied to the associated retail items
  const type = [
    CustomEntity.ROOFTOP_PRICING_SEGMENTS,
    CustomEntity.ROOFTOP_BULK_ADJUSTMENT,
    CustomEntity.RETAIL_BULK_ADJUSTMENT,
  ].includes(entityType as CustomEntity)
    ? EntityType.RETAIL_ITEM
    : entityType;

  return {
    type,
    name: preFill || '',
  };
};

export const defaultFields: StepFields = {
  name: {
    label: 'tag_name',
    placeholder: 'enter_tag',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    active: true,
    required: true,
  },
};

class TagStep extends StepComponentCore {
  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { seededData, data },
    } = props;

    const {
      subContexts: {
        userContext: {
          user: { locale },
        },
      },
    } = context;
    const name = seededData?.name || data?.name;
    const multiLingualValue = getMultilingualValueFromLocale(locale.languageTag as Locale);

    this.fields = objectToStepFieldArray(props.tier.activeStep?.fields, {
      name: { selectedValue: { [multiLingualValue]: name } },
    });
  }

  async save(): Promise<boolean> {
    return super.save({
      type: this.props.tier.seededData?.type || this.props.tier.data?.type,
      rooftopId: this.props.tier.seededData?.rooftopId || this.props.tier.data?.rooftopId?.id,
    });
  }
}

export default TagStep;
