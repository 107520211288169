import { memo } from 'react';

import styled from 'styled-components/macro';

import ArrowToTopIcon from './ArrowToTopIcon';

const ArrowToBottomIcon = styled(ArrowToTopIcon)`
  transform: scaleY(-1);
`;

export default memo(ArrowToBottomIcon);
