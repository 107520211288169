import { gql } from '@apollo/client';

import { websiteLeadRoute } from 'store/api/graph/fragments/websiteLeadRoute';

export const leadRouteModify = gql`
  mutation LeadRouteModifyMutation(
    $id: ID!
    $rooftopId: ID
    $type: LeadRouteType
    $_clear: [LeadRouteModifyParameter!]
  ) {
    data: leadRouteModify(id: $id, rooftopId: $rooftopId, type: $type, _clear: $_clear) {
      ...WebsiteLeadRouteFragment
    }
  }
  ${websiteLeadRoute}
`;
