import type { RefObject } from 'react';

import { cloneDeep, get, pick } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import Label from 'components/core/typography/Label';
import { retailItemModify } from 'components/sections/createModify/inventoryItems/retailItem/RetailItemCreateModifyQuery';
import {
  RetailItemBuilderSteps,
  RetailItemDetailsBuilderFields,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import {
  handleConfirmToggleFieldLock,
  isBulkAdjustmentStepEnabled,
  updateLockedFieldsSelectedValue,
} from 'components/sections/createModify/inventoryItems/retailItem/steps/utils';
import {
  inventoryItemPhotoDelete,
  inventoryItemPhotoModify,
  inventoryItemVideoCreate,
  inventoryItemVideoDelete,
  retailItemAddTrimPhotos,
} from 'components/sections/createModify/inventoryItems/steps/photosStep/MediaStepQueries';
import { tradeInItemModify } from 'components/sections/createModify/inventoryItems/tradeInItem/TradeInItemCreateModifyQuery';
import type {
  TrimColourSelectOption,
  TrimFiltersStepSeededData,
} from 'components/sections/createModify/MMST/steps/TrimFiltersStep';
import { trimsQuery } from 'components/sections/shared/ItemMetaQueries';
import MovePhotosDrawer from 'components/ui/drawers/MovePhotosDrawer';
import {
  AddPhotoIcon,
  EmptyPhotoPlaceholder,
  PhotoInput,
  PhotoInputContainer,
  YouTubeVideoInput,
} from 'components/ui/forms/shared/MediaInput';
import FilterSlidersIcon from 'components/ui/icons/FilterSlidersIcon';
import { ImageLoadingState } from 'components/ui/images/helpers/Images';
import Image from 'components/ui/images/Images';
import TrimPhotoListItem from 'components/ui/lists/TrimPhotoListItem';
import Button from 'components/ui/shared/Button';
import CounterBadge from 'components/ui/shared/CounterBadge';
import LockButton from 'components/ui/shared/LockButton';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { ImageSize } from 'enums/imageType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { builderFieldTestId, ElementTestId } from 'enums/testing';
import ApiRequest from 'store/api/ApiRequest';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { getApiErrors, logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  InventoryItemPhotoInput,
  TrimPhotoFragment,
  TrimsQuery,
  TrimsQueryVariables,
} from 'store/api/graph/interfaces/types';
import {
  EntityType,
  InventoryItemPhotoDamageLocation,
  InventoryItemPhotoInputParameter,
  InventoryItemPhotoType,
  InventoryItemVideoSource,
  RetailItemLockableField,
  RetailItemStatus,
  TradeInItemStatus,
} from 'store/api/graph/interfaces/types';
import type {
  InventoryItemPhotoResponseType,
  InventoryItemVideoResponseType,
  RetailItemResponseType,
} from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { BLUE_500, NEUTRAL_800, RED_500, SPACE_200 } from 'styles/tokens';
import { getGraphURL } from 'utils/apiUtils';
import { move } from 'utils/arrayUtils';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';
import { filterImagesByMaxSize, isStockPhotosEnabled } from 'utils/imageUtils';
import { translate } from 'utils/intlUtils';
import { capitalizeFirstChar } from 'utils/stringUtils';

import { DetailsInventoryItemBuilderFields } from '../interfaces';

import SortableMedia from './SortableMedia';

const VIDEO_LOADING_PLACEHOLDER_ID = 'VIDEO_LOADING_PLACEHOLDER_ID';
const PHOTO_PLACEHOLDER_ID_PREFIX = 'id-';
const { PHOTOS_INTERIOR, PHOTOS_EXTERIOR, PHOTOS_DAMAGE, VIDEOS } = InventoryItem;
const GRID_PHOTO_SIZE = { width: 410, height: 308 }; // Unit is px
const IMAGE_LOADING_HEIGHT_PX = 138; // Unit is px

const { t } = translate;

export const defaultFields: StepFields = {
  [PHOTOS_EXTERIOR]: {
    label: 'exterior_photos',
    queryAlias: [InventoryItem.PHOTOS_EXTERIOR_COUNT],
    shouldExpandBuilderOnEdit: true,
  },
  [PHOTOS_INTERIOR]: {
    label: 'interior_photos',
    queryAlias: [InventoryItem.PHOTOS_INTERIOR_COUNT],
    shouldExpandBuilderOnEdit: true,
  },
  [PHOTOS_DAMAGE]: {
    label: 'damage_photos',
    queryAlias: [InventoryItem.PHOTOS_DAMAGE_COUNT],
    shouldExpandBuilderOnEdit: true,
  },
  [VIDEOS]: {
    label: 'video_other',
    queryAlias: [InventoryItem.VIDEOS_COUNT],
    shouldExpandBuilderOnEdit: true,
  },
};

const FieldsContainer = styled.div<{ ref: RefObject<HTMLDivElement> }>`
  overflow: auto;
  padding: 17px 15px 0;
`;

const PhotoGroup = styled.div`
  position: relative;
  padding-bottom: 25px;

  &:not(:first-child) {
    padding-top: 25px;
  }

  &:not(:last-child) {
    &::after {
      content: '';
      width: calc(100% + ${ENTITY_PADDING} * 2);
      height: 1px;
      background: ${DIVIDER};
      position: absolute;
      bottom: 0;
      left: -${ENTITY_PADDING};
    }
  }

  ${Label} {
    margin-bottom: 12px;
  }
`;

const MediaContainer = styled.div`
  display: grid;
  gap: ${SPACE_200};
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  > * {
    height: initial;
    min-height: 155px;
    width: 100%;
  }
`;

const PhotosLabel = styled(Label)`
  display: flex;
  align-items: center;
`;

interface MediaStepState extends StepComponentState {
  /** Flag for controlling loading state when a YouTube video is in the process of being saved */
  isSavingYouTubeVideo?: boolean;
  /** A map of selected photos keyed by id, that are pending re-classification to a new `type`. */
  selectedForReclassify: Map<string, any>;
}

// TODO: [#1110] Allow for photo section to be highlighted as 'active' when deep-linking?
class MediaStep extends StepComponentCore {
  static contextType = CreateModifyContext;

  state: MediaStepState = {
    ...defaultState,
    isSavingYouTubeVideo: false,
    selectedForReclassify: new Map(),
  };

  photos: InventoryItemPhotoResponseType[];
  /*
   * The videos array will contain InventoryItemVideoResponseType items, but when displaying a loading placeholder,
   * it will use a photo, as the uploading spinner is not a video. This is why the videos array supports both
   * video and photo types.
   */
  videos: (InventoryItemVideoResponseType | InventoryItemPhotoResponseType)[];
  deletePhotoQueue: InventoryItemPhotoResponseType[];
  deleteVideoQueue: InventoryItemVideoResponseType[];
  maxSizeMB: number;
  /** All trim photos related to vehicle subModel */
  stockPhotos?: TrimsQuery;
  /** Field sections made in Stock Photo Filter Builder */
  trimFilterFieldSelections: {
    [InventoryItemPhotoType.INTERIOR]: StepField[] | undefined;
    [InventoryItemPhotoType.EXTERIOR]: StepField[] | undefined;
  };
  /** Array of fields for this step */
  customFields: StepField[] = [];

  constructor(props, context: CreateModifyContextInterface) {
    super(props);

    const { data, steps, tierId } = props.tier;

    this.photos = get(data, InventoryItem.PHOTOS, []);
    this.videos = get(data, InventoryItem.VIDEOS, []);
    this.deletePhotoQueue = [];
    this.deleteVideoQueue = [];
    this.maxSizeMB = 20;
    this.trimFilterFieldSelections = {
      [InventoryItemPhotoType.INTERIOR]: undefined,
      [InventoryItemPhotoType.EXTERIOR]: undefined,
    };

    this.onMovePhoto = this.onMovePhoto.bind(this);
    this.onAddPhoto = this.onAddPhoto.bind(this);
    this.onRemovePhoto = this.onRemovePhoto.bind(this);
    this.onModifyDamageLocation = this.onModifyDamageLocation.bind(this);
    this.onMediaUploadError = this.onMediaUploadError.bind(this);
    this.save = this.save.bind(this);

    const {
      subContexts: { featureFlags },
      setTier,
    } = context;

    this.context = context;
    this.state = {
      ...this.state,
      childrenBefore: this.renderMediaStep(),
    };

    const { rooftop } = data;

    if (
      !isBulkAdjustmentStepEnabled({ rooftop, featureFlagRooftopPackageEnabled: featureFlags.rooftopPackageEnabled })
    ) {
      // Hide the bulk adjustment step if none of the retail payment options are available
      setTier(tierId, { steps: steps?.filter(step => step.id !== RetailItemBuilderSteps.BULK_ADJUSTMENTS) });
    }

    this.customFields = Object.entries({
      [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
        selectedValue: data?.lockedFields || [],
      },
      photos: {
        isLocked: data?.lockedFields?.includes(RetailItemLockableField._photos),
      },
    }).map(([key, value]) => ({ queryVar: key, ...value }));
  }

  async componentDidMount() {
    super.componentDidMount();

    this.stockPhotos = await this.initStockPhotos();

    if (this.stockPhotos) {
      this.forceRender();
    }
  }

  componentDidUpdate(prevProps: any, prevState): void {
    super.componentDidUpdate(prevProps, prevState);

    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.forceRender({ isExpanded: this.props.isExpanded });
    }
  }

  /**
   * Retrieve all stock photos related to the vehicle.
   *
   * Is not trim specific. User can select photos not related to current trim.
   */
  async initStockPhotos() {
    const { data: item } = this.props.tier;

    if (!item?.subModelName?.id || !item?.year) {
      return null;
    }

    return this.client
      .query({
        query: trimsQuery,
        variables: {
          rooftopId: item?.rooftop.id,
          year: item.year,
          subModelIds: [item?.subModelName.id],
        } as TrimsQueryVariables,
        fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      })
      .then(({ data }) => data)
      .catch((error: Error) => logApiError(error));
  }

  enableOnClosePrompt() {
    const { onTierHide } = this.props;
    if (!this.props.tier.activeStep!.onClosePrompt) {
      this.setOnClosePrompt({
        ...this.defaultClosePrompt,
        onConfirm: this.onCloseConfirmed.bind(this),
        onComplete: (success: boolean) => {
          if (success) {
            onTierHide();
          }
        },
      });
    }
  }

  pickPhotoInputKeys(photo: InventoryItemPhotoResponseType) {
    const photoInputKeys: Array<keyof InventoryItemPhotoInput> = ['id', 'type', 'shotCode', 'damageLocation', '_clear'];

    return pick(photo, photoInputKeys);
  }

  getPhotosByType(type: InventoryItemPhotoType): InventoryItemPhotoResponseType[] {
    return this.photos.filter(photo => photo.type === type);
  }

  processPhotosBeforeUpload(images: File[], type: InventoryItemPhotoType) {
    const { data } = this.props.tier;
    const formattedImages: Array<any> = [];

    for (const image of images) {
      const formData = new FormData();
      formData.append('inventory_item_id', data.id);
      formData.append('photo', image, image.name);
      formData.append('name', image.name);
      formData.append('type', type);
      formData.append('thumbnails', JSON.stringify([{ ...GRID_PHOTO_SIZE, cropType: 'FILL' }]));
      if (type === InventoryItemPhotoType.DAMAGE) {
        formData.append('damage_location', 'OTHER');
      }
      formattedImages.push(formData);
    }
    return formattedImages;
  }

  addPhotoLoadingPlaceholders(number: number, type: InventoryItemPhotoType) {
    this.photos = [
      ...this.photos,
      ...Array.from({ length: number }).map((_, index) => {
        const shotCode = this.getPhotosByType(type).length + index + 1;
        return {
          id: `${PHOTO_PLACEHOLDER_ID_PREFIX}${shotCode}`,
          lightboxPhoto: ImageLoadingState.LOADING,
          gridPhoto: ImageLoadingState.LOADING,
          type,
          typeName: '',
          damageLocation: null,
          damageLocationName: null,
          shotCode,
        };
      }),
    ];
    this.forceRender();
  }

  removePhotoLoadingPlaceholders(type: InventoryItemPhotoType) {
    this.photos = this.photos.filter(photo => photo.gridPhoto !== ImageLoadingState.LOADING);
    this.forceRender();
  }

  addVideoLoadingPlaceholders() {
    this.videos = [
      ...this.videos,
      {
        id: VIDEO_LOADING_PLACEHOLDER_ID,
        gridPhoto: ImageLoadingState.LOADING,
      } as InventoryItemPhotoResponseType,
    ];
    this.forceRender();
  }

  removeVideoLoadingPlaceholders() {
    this.videos = this.videos.filter(video => video.id !== VIDEO_LOADING_PLACEHOLDER_ID);
    this.forceRender();
  }

  onMovePhoto(type: InventoryItemPhotoType, photoId: string, indexNext: number) {
    const photosOfType = this.getPhotosByType(type);
    const photoIndex = photosOfType.findIndex(photo => photo.id === photoId);

    // Rearrange photos and reset the shotCodes to match updated ordering
    const photosReordered = move(photosOfType, photoIndex, indexNext).map((photo, index) => ({
      ...photo,
      shotCode: index + 1,
    }));

    // Update photos instance
    this.photos = [...this.photos.filter(photo => photo.type !== type), ...photosReordered];
    this.forceRender();
  }

  onAddPhoto(type: InventoryItemPhotoType, e: any) {
    const filesArray = Array.from(e.target.files as File[]);
    const { validFiles, overSizedFiles } = filterImagesByMaxSize(filesArray, this.maxSizeMB);

    if (overSizedFiles.length > 0) {
      let errorMessage = `${t('following_files_exceed_filesize', [this.maxSizeMB])}:\n`;
      for (const photo of overSizedFiles) {
        errorMessage += `\n• ${photo.name}`;
      }
      this.setTier({ errors: [{ name: `photo`, message: errorMessage }] });
      return;
    }

    if (validFiles.length > 0) {
      const uploadPhotoUrl = getGraphURL() + '/upload-inventory-photo';
      const processedPhotos = this.processPhotosBeforeUpload(filesArray, type);
      const getRemainingUploads = index => processedPhotos.length - index;

      this.enableOnClosePrompt();

      processedPhotos.reduce(
        (promise, photo, index) =>
          promise.then(
            () =>
              new Promise((resolve, reject) => {
                this.addPhotoLoadingPlaceholders(getRemainingUploads(index), type);
                ApiRequest({ url: uploadPhotoUrl, data: photo })
                  // TODO: May potentially want to type this response
                  .then((response: any) => {
                    // Remove unnecessary fields from response and update photo type
                    this.photos = (response.data?.photos || [])
                      .filter(photo => !this.deletePhotoQueue.some(queuedPhoto => photo.id === queuedPhoto.id))
                      .map(({ thumbnails, ...photo }) => {
                        photo.gridPhoto = thumbnails?.[0];
                        return photo;
                      });
                    this.forceRender();
                  })
                  .then(resolve)
                  .catch((error: Error) => {
                    this.removePhotoLoadingPlaceholders(type);
                    this.onMediaUploadError(get(error, 'response.data.errors', []));
                    reject(error);
                  });
              })
          ),
        Promise.resolve()
      );
    }
  }

  async onRemovePhoto(targetPhoto: InventoryItemPhotoResponseType) {
    this.deletePhotoQueue.push(targetPhoto);
    this.photos = this.photos?.filter(photo => photo.id !== targetPhoto.id) || [];
    this.enableOnClosePrompt();
    this.setState(
      {
        selectedForReclassify: new Map(
          Array.from(this.state.selectedForReclassify)?.filter(([photoId]) => photoId !== targetPhoto.id) || []
        ),
      },
      this.forceRender
    );
  }

  async onRemoveVideo(targetVideo: InventoryItemVideoResponseType) {
    this.deleteVideoQueue.push(targetVideo);
    this.videos = this.videos?.filter(video => video.id !== targetVideo.id) || [];
    this.enableOnClosePrompt();
    this.forceRender();
  }

  async removePhotos(photos: InventoryItemPhotoResponseType[]): Promise<void> {
    const {
      data: { id: inventoryItemId },
    } = this.props.tier;
    const photoIds: string[] = photos
      .map(photo => photo.id)
      .filter(photoId => !photoId.startsWith(PHOTO_PLACEHOLDER_ID_PREFIX));

    if (photos.length > 0) {
      await client
        .mutate({
          mutation: inventoryItemPhotoDelete,
          variables: { inventoryItemId, photoIds },
        })
        .catch((error: Error) => {
          this.onMediaUploadError(getApiErrors(error));
        });
    }
  }

  async removeVideos(videos: InventoryItemVideoResponseType[]): Promise<void> {
    const {
      data: { id: inventoryItemId },
    } = this.props.tier;

    if (videos.length > 0) {
      const videoIds: string[] = videos.map(video => video.id);

      await client
        .mutate({
          mutation: inventoryItemVideoDelete,
          variables: { inventoryItemId, videoIds },
        })
        .catch((error: Error) => {
          this.onMediaUploadError(getApiErrors(error));
        });
    }
  }

  onMediaUploadError(errors: ApiError[]) {
    logApiError(errors);
    this.setTier({
      errors: errors.map((err, index) => ({
        name: `media-error-${index}`,
        message: err.message,
      })),
    });
  }

  onModifyDamageLocation(photo: InventoryItemPhotoResponseType) {
    const damageSubStepField: StepField = {
      label: t('damage_location'),
      queryVar: photo.id.toString(),
      groupType: StepFieldType.DROPDOWN,
      options: this.props.tier.metadata.mutation?.inventoryItemPhoto?.damageLocation || [],
      selectedValue: photo.damageLocation,
      subStep: [SubStepType.DEFAULT],
    };

    this.setState({ currentStepField: damageSubStepField });
  }

  async onSaveYouTubeVideo(url: string) {
    const {
      data: { id: inventoryItemId, videos },
    } = this.props.tier;

    const currentVideos = cloneDeep(this.videos);

    // Determine the max shot code by sorting saved videos, and videos that have been added but not yet saved
    const nextShotCode =
      ([...(videos || []), ...currentVideos].sort((a, b) => b.shotCode - a.shotCode)[0]?.shotCode || 0) + 1;

    this.addVideoLoadingPlaceholders();

    try {
      const result = await client.mutate({
        mutation: inventoryItemVideoCreate,
        variables: { inventoryItemId, url, shotCode: nextShotCode, source: InventoryItemVideoSource.YOUTUBE },
      });
      this.videos = [...currentVideos, result.data.data];
      this.enableOnClosePrompt();
    } catch (error) {
      this.onMediaUploadError(getApiErrors(error));
    } finally {
      this.removeVideoLoadingPlaceholders();
    }
  }

  async onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === InventoryItem.TRIM_PHOTOS) {
      const retailItemId = this.props.tier.data?.id;
      const trimPhotoIds = (value || []).map(trim => trim.id);

      if (retailItemId && trimPhotoIds.length > 0) {
        this.setState({ isProcessing: true });

        await client
          .mutate({
            mutation: retailItemAddTrimPhotos,
            variables: {
              id: retailItemId,
              trimPhotoIds,
            },
          })
          .then(response => {
            this.photos = (response.data?.retailItemAddTrimPhotos?.photos || []).filter(
              photo => !this.deletePhotoQueue.some(queuedPhoto => photo.id === queuedPhoto.id)
            );
          })
          .catch((error: Error) => this.onMediaUploadError(getApiErrors(error)))
          .finally(() => {
            this.setState({ isProcessing: false });
          });
      }

      this.forceRender({ currentStepField: undefined });

      return;
    }

    // Update photo with selected damage location
    this.photos = this.photos.map(photo =>
      photo.id === stepField.queryVar ? { ...photo, damageLocation: value.id, damageLocationName: value.name } : photo
    );

    this.enableOnClosePrompt();

    // Format photo for mutation, run mutation, close subStep
    const { data } = this.props.tier;
    const photosFormatted = this.photos.map(photo => this.pickPhotoInputKeys(photo));
    const variables = { inventoryItemId: data.id, photos: photosFormatted };
    await client
      .mutate({ mutation: inventoryItemPhotoModify, variables })
      .catch((error: Error) => this.onMediaUploadError(get(error, 'networkError.result.errors', [])));
    this.forceRender({ currentStepField: undefined });
  }

  async save(): Promise<boolean> {
    const { data, activeStep, isCreating, entityType } = this.props.tier;
    // Only assigning valid `InventoryItemPhotoInput` properties
    const photosFormatted = this.photos.map(photo => this.pickPhotoInputKeys(photo));
    const photoVariables = { inventoryItemId: data.id, photos: photosFormatted };

    // Applying any deletes from existing media
    await this.removePhotos(this.deletePhotoQueue);
    await this.removeVideos(this.deleteVideoQueue);

    if (photosFormatted.length > 0) {
      try {
        const response = await client.mutate({ mutation: inventoryItemPhotoModify, variables: photoVariables });
        const formattedData = { ...data, ...(get(response, 'data.data') || {}) };

        this.setTier({ data: formattedData });
      } catch (error) {
        this.onMediaUploadError(get(error, 'networkError.result.errors', []));

        return false;
      }
    }

    const lockedFields = getStepField(RetailItemDetailsBuilderFields.LOCKED_FIELDS, this.customFields).selectedValue;
    const lockedVariables = {
      [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: lockedFields,
    };
    /*
     * Update the status to complete after the PhotosStep, which is our last step in the builder for creating
     * an inventory item. If we are modifying, then use the container query as a refetch query to update the
     * media details tab with the latest media.
     */
    let variables: Record<string, any>;
    let refetchQueries: string[] = [];

    if (isCreating) {
      variables = {
        ...(entityType === EntityType.RETAIL_ITEM ? lockedVariables : {}),
        id: data.id,
        status: entityType === EntityType.RETAIL_ITEM ? RetailItemStatus.FOR_SALE : TradeInItemStatus.PENDING,
      };
    } else {
      variables = { id: data.id };
      refetchQueries = EntityType.RETAIL_ITEM
        ? ['RetailItemDetailsContainerQuery']
        : ['TradeInItemDetailsContainerQuery'];
    }

    try {
      await this.client.mutate({
        mutation: entityType === EntityType.RETAIL_ITEM ? retailItemModify : tradeInItemModify,
        variables: entityType === EntityType.RETAIL_ITEM ? { ...variables, ...lockedVariables } : variables,
        refetchQueries,
      });

      activeStep!.onClosePrompt = undefined;
      this.setTier({ activeStep });
      return true;
    } catch (error) {
      this.onMediaUploadError(getApiErrors(error));
      return false;
    }
  }

  // Overriding close, do any necessary mutations etc here and then set the current Tier to undefined
  async onCloseConfirmed() {
    const { tier } = this.props;
    const { activeStep } = tier;

    // Discarding any media queued for deletion
    this.deletePhotoQueue = [];
    this.deleteVideoQueue = [];

    // Remove unwanted media uploads
    const discardedPhotos = this.photos.filter(photo => !tier.data.photos?.find(_photo => photo.id === _photo.id));
    if (discardedPhotos.length > 0) {
      await this.removePhotos(discardedPhotos);
    }

    const discardedVideos = this.videos.filter(
      video => !tier.data.videos?.find(_video => video.id === _video.id) && video.id !== VIDEO_LOADING_PLACEHOLDER_ID
    ) as InventoryItemVideoResponseType[];
    if (discardedVideos.length > 0) {
      await this.removeVideos(discardedVideos);
    }

    const discardedPhotoIds = new Set(discardedPhotos.map(photo => photo.id));
    this.photos = this.photos
      .filter(photo => !discardedPhotoIds.has(photo.id))
      .map(photo => ({ ...photo, __typename: 'InventoryItemPhoto' }));

    const discardedVideoIds = new Set(discardedVideos.map(video => video.id));
    this.videos = this.videos
      .filter(video => !discardedVideoIds.has(video.id) && video.id !== VIDEO_LOADING_PLACEHOLDER_ID)
      .map(video => ({ ...video, __typename: 'InventoryItemVideo' })) as InventoryItemVideoResponseType[];

    activeStep!.onClosePrompt = undefined;
  }

  onSelectForReclassification(photo) {
    const selected = new Map(this.state.selectedForReclassify);

    if (selected.has(photo.id)) {
      selected.delete(photo.id);
      this.setState({ selectedForReclassify: selected }, this.forceRender);
    } else {
      selected.set(photo.id, photo);
      this.setState({ selectedForReclassify: selected }, this.forceRender);
    }
  }

  onConfirmReclassify(targetCategory) {
    const photosReclassified = Array.from(this.state.selectedForReclassify, ([key, photo], index) => ({
      ...photo,
      type: targetCategory,
      typeName: capitalizeFirstChar(targetCategory),
      ...(targetCategory === InventoryItemPhotoType.DAMAGE
        ? // Photos being moved to DAMAGE need to default the damageLocation to OTHER, and remove _clear
          {
            damageLocation:
              // If moving from damage, to damage, retain existing damageLocation
              photo.type === InventoryItemPhotoType.DAMAGE
                ? photo.damageLocation
                : InventoryItemPhotoDamageLocation.OTHER,
            damageLocationName: photo.type === InventoryItemPhotoType.DAMAGE ? photo.damageLocationName : t('other'),
            _clear: null,
          }
        : // Photos being moved to INTERIOR/EXTERIOR need to clear damageLocation
          { damageLocation: null, damageLocationName: null, _clear: InventoryItemPhotoInputParameter._damageLocation }),
    }));
    const prevPhotos = this.photos.filter(({ id }) => !this.state.selectedForReclassify.has(id));
    const photosModified = [...prevPhotos, ...photosReclassified];
    const photosReordered = Object.values(InventoryItemPhotoType).flatMap(type => {
      const photosOfType = photosModified.filter(({ type: photoType }) => photoType === type);

      return photosOfType.map((photo, index) => ({
        ...photo,
        shotCode: index + 1,
      }));
    });

    this.photos = photosReordered;
    this.enableOnClosePrompt();
    this.setState({ selectedForReclassify: new Map() }, this.forceRender);
  }

  /**
   * Toggles a subpanel whereby user can see a list of stock photos.
   * This list can be filtered by the builder that `toggleStockPhotoBuilder` opens.
   */
  toggleStockPhotoSubPanel(type: InventoryItemPhotoType) {
    const photosSource = this.trimFilterFieldSelections[type];

    const filterBadgeCount = photosSource?.reduce((acc: number, curr: StepField) => {
      if (curr?.selectedValue) {
        acc += 1;
      }
      return acc;
    }, 0);

    const selectedTrimField = (photosSource as StepField[])?.find(
      field => field?.queryVar === DetailsInventoryItemBuilderFields.TRIM_ID
    );
    const selectedExteriorColourField = (photosSource as StepField[])?.find(
      field => field?.queryVar === DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR
    );
    const selectedExteriorColour = selectedExteriorColourField?.selectedValue as TrimColourSelectOption;
    const selectedTrim = selectedTrimField?.selectedValue;

    const defaultPhotosSource = (this.props.tier.data as RetailItemResponseType)?.trim;
    const seededPhotosSource =
      selectedTrim && !selectedTrim?.id ? { ...selectedTrim, exteriorPhotos: [], interiorPhotos: [] } : selectedTrim;
    const trimPhotoOptions = {
      [InventoryItemPhotoType.EXTERIOR]:
        seededPhotosSource?.exteriorPhotos || defaultPhotosSource?.exteriorPhotos || [],
      [InventoryItemPhotoType.INTERIOR]:
        seededPhotosSource?.interiorPhotos || defaultPhotosSource?.interiorPhotos || [],
    };
    const filteredtrimPhotoOptions = selectedExteriorColour
      ? (trimPhotoOptions[type] as TrimPhotoFragment[]).filter(trimPhoto =>
          selectedExteriorColour.manufacturerCodes.includes(trimPhoto.primaryColorManufacturerCode || 'N/A')
        )
      : trimPhotoOptions[type];

    void this.toggleSubPanel({
      queryVar: InventoryItem.TRIM_PHOTOS,
      required: true,
      groupType: StepFieldType.RENDER_OBJECT,
      groupSubTypes: [StepFieldSubType.MULTI_SELECT],
      renderElement: TrimPhotoListItem,
      subStep: [SubStepType.DEFAULT],
      hasSeparator: true,
      options: filteredtrimPhotoOptions,
      settings: {
        enableDoneButton: true,
        listSelectionEmptyPlaceholder: {
          title: t('no_photos_available_title'),
          subtitle: t('no_photos_available_for_x_subtitle', [
            this.props.tier.data?.trimName?.name?.value || t('other_trim'),
          ]),
          onClick: () => this.toggleStockPhotoBuilder(type),
          buttonLabel: t('select_x', [t('trim')]),
          leftActionBarButtonIcon: () => (
            <div css={'margin-right: 6px'}>
              <CounterBadge count={filterBadgeCount} color={RED_500}>
                <Button css="padding: 7px; margin-right: 4px" onClick={() => this.toggleStockPhotoBuilder(type)}>
                  <FilterSlidersIcon color={NEUTRAL_800} />
                </Button>
              </CounterBadge>
            </div>
          ),
        },
      } as ListSelectionSettings,
    });
  }

  /**
   * Toggles a FE only builder, whereby user can filter the list of photos
   * shown in `toggleStockPhotoSubPanel`.
   */
  toggleStockPhotoBuilder(type: InventoryItemPhotoType) {
    const { toggleTier, topTier } = this.getContext();
    const tiers = Object.values(CreateModifyTiers);
    const nextTier = tiers[tiers.indexOf(topTier!.tierId) + 1];

    const savedFilterSubModelSelection = (this.trimFilterFieldSelections[type] as StepField[])?.find(
      field => field?.queryVar === DetailsInventoryItemBuilderFields.SUB_MODEL_ID
    )?.selectedValue;
    const savedFilterTrimSelection = (this.trimFilterFieldSelections[type] as StepField[])?.find(
      field => field?.queryVar === DetailsInventoryItemBuilderFields.TRIM_ID
    )?.selectedValue;
    const savedFilterExteriorColourSelection = (this.trimFilterFieldSelections[type] as StepField[])?.find(
      field => field?.queryVar === DetailsInventoryItemBuilderFields.VEHICLE_EXTERIOR_COLOR
    )?.selectedValue;

    const seededSubmodel = savedFilterSubModelSelection || this.props.tier?.data?.[InventoryItem.SUB_MODEL_NAME];
    const seededTrim = savedFilterTrimSelection || this.props.tier?.data?.[InventoryItem.TRIM_NAME];

    toggleTier(nextTier, {
      tierId: nextTier,
      type: BuilderType.TRIM_PHOTOS_FILTER,
      title: t('filter_other'),
      isCreating: true,
      nextButtonLabel: t('apply_filter_one'),
      nextButtonCss: css`
        background: ${BLUE_500};
      `,
      seededData: {
        stockPhotoType: type,
        parentTier: this.props.tier,
        initialSubModelSelectedValue: seededSubmodel,
        initialTrimSelectedValue: seededTrim,
        initialExteriorColorSelectedValue: savedFilterExteriorColourSelection,
      } as TrimFiltersStepSeededData,
      onStepSave: async (_, data) => {
        this.trimFilterFieldSelections[type] = data;
        this.toggleStockPhotoSubPanel(type);
      },
    });
  }

  onConfirmToggleFieldLock(queryVar) {
    handleConfirmToggleFieldLock({ queryVar, fields: this.customFields });

    this.forceRender();

    updateLockedFieldsSelectedValue(this.customFields);
  }

  renderMediaStep() {
    const {
      tier: { metadata },
    } = this.props;
    const isFieldLockingVisible = metadata?.isFieldLockingVisible;
    const photosField = getStepField(InventoryItem.PHOTOS, this.customFields);

    return (
      <FieldsContainer ref={this.fieldsContainerRef}>
        {[InventoryItemPhotoType.EXTERIOR, InventoryItemPhotoType.INTERIOR, InventoryItemPhotoType.DAMAGE].map(type => (
          <PhotoGroup key={type} className={`step-field-input-photos_type_${type.toLowerCase()}`}>
            <PhotosLabel>
              {isFieldLockingVisible && this.props.tier.entityType === EntityType.RETAIL_ITEM && photosField && (
                <LockButton
                  testId={builderFieldTestId(`${InventoryItem.PHOTOS}-${type}`)}
                  isLocked={photosField?.isLocked}
                  onConfirm={this.onConfirmToggleFieldLock.bind(this, InventoryItem.PHOTOS)}
                />
              )}
              {t('x_photos', [t(type.toLowerCase())])}
            </PhotosLabel>
            <MediaContainer>
              <SortableMedia
                type={type}
                media={this.getPhotosByType(type)}
                onMoveMedia={this.onMovePhoto}
                onRemoveMedia={target => void this.onRemovePhoto(target as InventoryItemPhotoResponseType)}
                onModifyDamageLocation={this.onModifyDamageLocation}
                onSelectForReclassification={target => this.onSelectForReclassification(target)}
                selectedForReclassify={this.state.selectedForReclassify}
              />
              <PhotoInput
                onChange={this.onAddPhoto.bind(null, type)}
                clearOnChange
                settings={{ id: type, multiple: true }}
              />
              {isStockPhotosEnabled({ inventoryItem: this.props.tier.data, photoType: type }) && (
                <PhotoInputContainer
                  data-testid={`${ElementTestId.BUILDER_STOCK_PHOTO_FIELD_CONTAINER}-${type}`}
                  isAvatar={true}
                >
                  <EmptyPhotoPlaceholder onClick={() => this.toggleStockPhotoSubPanel(type)}>
                    <AddPhotoIcon />
                    <Label>{t('add_stock_photo_one')}</Label>
                  </EmptyPhotoPlaceholder>
                </PhotoInputContainer>
              )}
            </MediaContainer>
          </PhotoGroup>
        ))}
        <PhotoGroup className={`step-field-input-${InventoryItem.VIDEOS.toLowerCase()}`}>
          <Label>{t('video_other')}</Label>
          <MediaContainer data-testid={ElementTestId.BUILDER_VIDEO_FIELD_CONTAINER}>
            <SortableMedia
              type={InventoryItemVideoSource.YOUTUBE}
              media={[...this.videos].sort((a, b) => a.shotCode - b.shotCode)}
              onRemoveMedia={target => void this.onRemoveVideo(target as InventoryItemVideoResponseType)}
              onModifyDamageLocation={this.onModifyDamageLocation}
            />
            {this.state.isSavingYouTubeVideo && (
              <Image
                src={ImageLoadingState.LOADING}
                size={ImageSize.FILL}
                css={css`
                  height: ${IMAGE_LOADING_HEIGHT_PX}px;
                `}
              />
            )}
            <YouTubeVideoInput onUpload={url => void this.onSaveYouTubeVideo(url)} />
          </MediaContainer>
        </PhotoGroup>
        <MovePhotosDrawer
          options={[InventoryItemPhotoType.EXTERIOR, InventoryItemPhotoType.INTERIOR, InventoryItemPhotoType.DAMAGE]}
          count={this.state.selectedForReclassify.size}
          isOpen={this.state.selectedForReclassify.size > 0}
          onConfirm={this.onConfirmReclassify.bind(this)}
          onCancel={() => this.setState({ selectedForReclassify: new Map() }, this.forceRender)}
        />
      </FieldsContainer>
    );
  }

  /**
   * The core visual markup of this step is rendered outside
   * of our stepField input grouping, and instead is set in
   * state under `childrenBefore`. In order for rendering to
   * update, a `setState()` call is required with the latest
   * rendering of the PhotoStep.
   *
   * Furthermore, it's optional to pass additional state fields
   * to update when re-rendering.
   *
   * @param {object} nextState
   */
  forceRender(nextState = {}) {
    this.setState({ childrenBefore: this.renderMediaStep(), ...nextState });
  }
}

export default MediaStep;
