import { gql } from '@apollo/client';

import { leadList } from 'store/api/graph/fragments/leadList';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';
import { rooftopList } from 'store/api/graph/fragments/rooftopList';

import { conversationList } from './conversationList';
import { tag } from './tag';
import { tradeInItemList } from './tradeInItemList';
import { userList } from './userList';

export const leadActivityDetail = gql`
  fragment LeadActivityDetailFragment on LeadActivity {
    id
    rooftopName: rooftop {
      ...RooftopListFragment
    }
    archived
    note
    sourceName: source {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    tagName: tags {
      ...TagFragment
    }
    attributesName: attributes {
      ... on FormLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on InquiryLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on TradeInLeadActivityAttributes {
        content(type: HTML)
        referringUrl
        subject
        tradeInItem {
          ...TradeInItemListFragment
        }
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on PhoneCallLeadActivityAttributes {
        phoneCallContent: content(type: PLAIN_TEXT) # aliasing since other content fields have a different nullability shape
        recordingUrl
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on PurchaseLeadActivityAttributes {
        purchaseDate
        retailItemPurchased {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on SubscriptionChangeLeadActivityAttributes {
        subscribed
      }
      ... on TestDriveLeadActivityAttributes {
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
      ... on WalkInLeadActivityAttributes {
        retailItemOfInterest {
          ...RetailItemListFragment
          rooftop {
            id
          }
        }
      }
    }
    leadName: lead {
      ...LeadListFragment
      assignedToName: assignedTo {
        ...UserListFragment
      }
      spam
    }
    assignedToName: assignedTo {
      ...UserListFragment
    }
    conversation {
      ...ConversationListFragment
    }
    type
    typeName
    created
    createdByName: createdBy {
      ...UserListFragment
    }
    updated
    status
  }
  ${tag}
  ${leadList}
  ${retailItemList}
  ${tradeInItemList}
  ${rooftopList}
  ${userList}
  ${conversationList}
  ${MultilingualString}
`;
