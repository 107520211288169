/**
 * This file was automatically generated and should not be edited.
 */
export const NEUTRAL_0 = '#FFFFFF';
export const NEUTRAL_050 = '#F6F7F7';
export const NEUTRAL_075 = '#ECEEEF';
export const NEUTRAL_100 = '#E1E3E5';
export const NEUTRAL_200 = '#C5CACD';
export const NEUTRAL_300 = '#ABB2B7';
export const NEUTRAL_400 = '#9FA9B1';
export const NEUTRAL_500 = '#818B94';
export const NEUTRAL_600 = '#65727C';
export const NEUTRAL_700 = '#3A505F';
export const NEUTRAL_800 = '#213442';
export const NEUTRAL_900 = '#01040A';
export const BLUE_050 = '#E6F1FA';
export const BLUE_100 = '#B2D4F0';
export const BLUE_200 = '#8DC0E8';
export const BLUE_300 = '#5AA3DE';
export const BLUE_400 = '#3991D8';
export const BLUE_500 = '#0875CE';
export const BLUE_600 = '#076ABB';
export const BLUE_700 = '#065392';
export const BLUE_800 = '#044071';
export const BLUE_900 = '#033157';
export const YELLOW_050 = '#F9F5C8';
export const YELLOW_100 = '#F0DB8F';
export const YELLOW_200 = '#F7E273';
export const YELLOW_300 = '#F5D45B';
export const YELLOW_400 = '#E5C13D';
export const YELLOW_500 = '#DFB10C';
export const YELLOW_600 = '#CBA10B';
export const YELLOW_700 = '#9E7E09';
export const YELLOW_800 = '#674D0A';
export const YELLOW_900 = '#3A2E03';
export const GREEN_050 = '#E6F2ED';
export const GREEN_100 = '#B1D7C8';
export const GREEN_200 = '#8BC4AE';
export const GREEN_300 = '#55A988';
export const GREEN_400 = '#359871';
export const GREEN_500 = '#008763';
export const GREEN_600 = '#017154';
export const GREEN_700 = '#01644A';
export const GREEN_800 = '#015841';
export const GREEN_900 = '#014B38';
export const RED_050 = '#FAEDEC';
export const RED_100 = '#EFC7C3';
export const RED_200 = '#E7ACA7';
export const RED_300 = '#DB867E';
export const RED_400 = '#D56E65';
export const RED_500 = '#E32012';
export const RED_600 = '#CE1E11';
export const RED_700 = '#A1160A';
export const RED_800 = '#6F2923';
export const RED_900 = '#551F1A';
export const BLACK_ALPHA_001 = '#00000014';
export const BLACK_ALPHA_002 = '#0000001F';
export const BLACK_ALPHA_003 = '#0000003D';
export const BLACK_ALPHA_004 = '#00000052';
export const BLACK_ALPHA_005 = '#00000066';
export const BLACK_ALPHA_006 = '#000000A3';
export const BLACK_ALPHA_007 = '#000000B8';
export const BLACK_ALPHA_008 = '#000000CC';
