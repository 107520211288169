import type { LDMultiKindContext } from 'launchdarkly-react-client-sdk';

import type { UserType } from 'store/api/graph/responses/responseTypes';

export type LDFeatureFlagsKey = keyof typeof LDFeatureFlags;

export type FeatureFlagSet = {
  [key in LDFeatureFlagsKey]: boolean;
};

/**
 * An option to override the user's feature flags when showcasing Storybook entries
 *
 * @see setFeatureFlagsOverride
 */
let featureFlagsOverride: Partial<FeatureFlagSet>;

/**
 * We use a shared static key for anonymous users.
 * This is to prevent the SDK from automatically generating a new key for each anonymous user.
 *
 * Source: https://docs.launchdarkly.com/home/users/anonymous-users#tracking-anonymous-users-with-a-shared-key
 */
export const ANONYMOUS_USER_KEY = 'anonymous_user';

/**
 * Active flags that are on LaunchDarkly. When referencing flag names in the code, the flag names should be included
 * in this enum. Camel case is used when referencing flag names - ex 'Example Flag' would be 'exampleFlag'. See
 * LaunchDarkly for full list of flag names.
 */
export enum LDFeatureFlags {
  dashboardEnabled = 'dashboardEnabled',
  rooftopPackageEnabled = 'rooftopPackageEnabled',
  showroomConfigurationEnabled = 'showroomConfigurationEnabled',
  inventoryMetricsDashboardEnabled = 'inventoryMetricsDashboardEnabled',
  incomingWidgetsDashboardEnabled = 'incomingWidgetsDashboardEnabled',
  leadCountsDashboardEnabled = 'leadCountsDashboardEnabled',
  leadsAndSalesDistributionDashboardEnabled = 'leadsAndSalesDistributionDashboardEnabled',
  translateScrollbarEnabled = 'translateScrollbarEnabled',
  websiteSectionEnabled = 'websiteSectionEnabled',
  manualAddressInputEnabled = 'manualAddressInputEnabled',
  guaranteedOfferEnabled = 'guaranteedOfferEnabled',
  sessionReplayEnabled = 'sessionReplayEnabled',
  resourceCentreEnabled = 'resourceCentreEnabled',
  oemPricingSourcesEnabled = 'oemPricingSourcesEnabled',
  ssoEnabled = 'ssoEnabled',
  retailDescriptionTextGenerationEnabled = 'retailDescriptionTextGenerationEnabled',
  oemAccountExportIntegrationEnabled = 'oemAccountExportIntegrationEnabled',
}

/**
 * Active context kinds should match this - https://app.launchdarkly.com/edealer/dev/contexts/kinds
 */
export enum LDContextKinds {
  rooftop = 'rooftop',
  user = 'user',
}

/**
 * Formats a user object for identifying the user with LaunchDarkly's feature flags.
 * The data provided will dictate what sort of flags will be set for this user
 * See this for more info: https://docs.launchdarkly.com/sdk/features/user-config#javascript
 *
 * @param {UserType} user - The user to be identified. If no user id is provided, then an anonymous
 * configuration will be returned
 */
export const createLaunchDarklyContext = (user: UserType): LDMultiKindContext => ({
  kind: 'multi',
  user: {
    key: user?.group?.id || user?.id || ANONYMOUS_USER_KEY,
    name: user?.group?.name?.value || user?.displayName || ANONYMOUS_USER_KEY,
    admin: !user?.group?.id,
    platform: 'web',
    userId: user?.id,
    whiteLabelId: user?.whiteLabel?.id || user?.group?.whiteLabel?.id || '',
    anonymous: !user?.id,
  },
});

/**
 * A helper method to set feature flags for Storybook components that require them
 *
 * @see hasFeatureFlag
 * @example
 * // someComponent.stories.tsx
 * setFeatureFlagsOverride({ 'rooftopPackageEnabled': true });
 * const Template = () => (
 *   <StorybookTemplate>
 *     <Component />
 *   </StorybookTemplate>
 * );
 */
export const setFeatureFlagsOverride = (featureFlags: Partial<FeatureFlagSet>) => {
  featureFlagsOverride = featureFlags;
};

/**
 * Returns a set of feature flag overrides
 */
export const getFeatureFlagsOverride = () => featureFlagsOverride;
