import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { leadForwardCreate } from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingCreateModifyQuery';
import { getLeadSourceOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { StepFieldType } from 'enums/stepFieldType';
import { client } from 'store/apollo/ApolloClient';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

import { LeadForwardingBuilderFields } from './interfaces';

class DetailsStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, seededData, activeStep, isCreating },
    } = props;

    const {
      subContexts: {
        userContext: { user, canAccessMultipleRooftops },
      },
    } = context;

    const rooftopName = data?.rooftopName || seededData?.rooftopName || getUsersRooftop(user);
    const sourceNames = data?.sourceNames;

    // Converting to readable fields and setting presets
    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [LeadForwardingBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftopName,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: !!rooftopName,
          },
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !canAccessMultipleRooftops,
          },
        ]),
      },
      [LeadForwardingBuilderFields.SOURCE_ID]: {
        selectedValue: sourceNames,
      },
      [LeadForwardingBuilderFields.ADF]: {
        selectedValue: isCreating ? false : !!data?.adf,
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, data);

    this.asyncConfigurations = {
      [LeadForwardingBuilderFields.SOURCE_ID]: {
        request: () =>
          getLeadSourceOptions(getStepField(LeadForwardingBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.id),
        disableKeywordRefetch: true,
      },
    };
  }

  /** Creates a new Lead Forwarding rule off the current builder fields, with the specified source. */
  async createNewLeadForwardingRule(sourceId: string) {
    const variables = this.fields.reduce((result, field) => {
      if (field.displayType?.includes(StepFieldDisplayType.OMITTED)) {
        return result;
      }
      return {
        ...result,
        [field.queryVar]: field.groupType === StepFieldType.DROPDOWN ? field.selectedValue?.id : field.selectedValue,
      };
    }, []);
    await client.mutate({
      mutation: leadForwardCreate,
      variables: { ...variables, [LeadForwardingBuilderFields.SOURCE_ID]: sourceId },
    });
  }
}

export default DetailsStep;
