import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableSubType, TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { Task } from 'enums/columns/task';
import { ItemsColumn } from 'enums/itemsColumn';
import { useUser } from 'hooks/contexts/useUser';
import { useQuery } from 'hooks/useQuery';
import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { leadName } from 'store/api/graph/fragments/leadName';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { selectStringOption } from 'store/api/graph/fragments/selectOption';
import { taskDetail } from 'store/api/graph/fragments/taskDetail';
import { taskList } from 'store/api/graph/fragments/taskList';
import { userName } from 'store/api/graph/fragments/userName';
import { ymmt } from 'store/api/graph/fragments/YMMT';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const taskDetailsQuery = gql`
  query TaskDetailsContainerQuery($id: ID!) {
    ## Important: 'item' alias is required for data reading
    item: task(id: $id) {
      ...TaskDetailFragment
    }

    metadata {
      ...ColorMetadataFragment
    }
  }
  ${taskDetail}
  ${colorMetadata}
`;

export const taskCondensedListQuery = gql`
  query TaskCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $filter: TaskConnectionFilterInput
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: taskConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...TaskListFragment
        }
      }
    }
    metadata {
      mutation {
        item: task {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
  ${taskList}
  ${pageInfo}
`;

export const taskContainerQuery = gql`
  query TaskConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: TaskConnectionFilterInput
    $d_groupNameOn: Boolean!
    $d_assignedToNameOn: Boolean!
    $d_leadNameOn: Boolean!
    $d_inventoryItemNameOn: Boolean!
    $d_createdOn: Boolean!
  ) {
    ## Important: 'connection' alias is required for data reading
    connection: taskConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...TaskListFragment
          groupName: rooftop @include(if: $d_groupNameOn) {
            id
            group {
              id
              name {
                ...MultilingualStringFragment
              }
            }
          }
          assignedToName: assignedTo @include(if: $d_assignedToNameOn) {
            ...UserNameFragment
          }
          ## Lead is not of type User and can not use the UserNameFragment
          leadName: lead @include(if: $d_leadNameOn) {
            ...LeadNameFragment
          }
          inventoryItemName: inventoryItem @include(if: $d_inventoryItemNameOn) {
            id
            ...YMMTFragment
          }
          created @include(if: $d_createdOn)
        }
      }
    }
    metadata {
      mutation {
        item: task {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${columns}
  ${filters}
  ${pageInfo}
  ${MultilingualString}
  ${userName}
  ${ymmt}
  ${selectStringOption}
  ${leadName}
  ${taskList}
`;

const staticColumns = [
  ItemsColumn.SELECT,
  ItemsColumn.PHOTOS,
  Task.TITLE,
  Task.STATUS,
  Task.CREATED_BY_NAME,
  Task.DUE_DATE,
  Task.NOTE,
] as string[];

export const useTaskConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const { isWhiteLabelScoped, canAccessMultipleRooftops } = useUser();
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(
    TableType.TASKS,
    isWhiteLabelScoped || canAccessMultipleRooftops ? TableSubType.ALL : TableSubType.LIMITED
  );

  const formattedTableColumns = gqlFormatTableColumnFields(taskContainerQuery, tableConfigurationNext, staticColumns);

  return useQuery(taskContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};
