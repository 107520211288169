import { useCallback } from 'react';

import styled from 'styled-components/macro';

import SecondaryText from 'components/core/typography/SecondaryText';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { WhiteButton } from 'components/ui/shared/Button';
import { Pagination } from 'enums/pagination';
import { ElementTestId } from 'enums/testing';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { NEUTRAL_050 } from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import { calculatePreviousPaginationParams } from 'utils/apiUtils';
import { formatNumber } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import ChevronLeftIcon from '../icons/ChevronLeftIcon';

const Container = styled.div<{ hasPreviousPage?: boolean; hasNextPage?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: ${NEUTRAL_050};
  border-top: 1px solid ${DIVIDER};
  z-index: ${Z_INDEX_1};
`;

export const CondensedListPagination = ({
  pageInfo,
  onPageChange,
  requestPagination,
}: {
  pageInfo?: any;
  onPageChange: () => void;
  requestPagination: (pageInfo: any) => void; // TODO: Type pageInfo by common connection params
}) => {
  const { t } = translate;

  const { startCursor, endCursor, totalEdges, hasNextPage, hasPreviousPage } = pageInfo || {};
  const pageSize = Pagination.LIST_LENGTH;

  // Callbacks
  const onNextClicked = useCallback(() => {
    requestPagination({ first: pageSize, after: endCursor });
    onPageChange();
  }, [endCursor, onPageChange, pageSize, requestPagination]);

  const onPreviousClicked = useCallback(() => {
    requestPagination(calculatePreviousPaginationParams(startCursor, pageSize));
    onPageChange();
  }, [startCursor, onPageChange, pageSize, requestPagination]);

  if (!pageInfo) {
    return <Container />;
  }

  return (
    <Container hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage}>
      <WhiteButton
        disabled={!hasPreviousPage}
        onClick={onPreviousClicked}
        title={t('previous')}
        data-testid={ElementTestId.PAGINATION_PREVIOUS}
      >
        <ChevronLeftIcon color={BODY_TEXT} />
      </WhiteButton>
      <SecondaryText css="text-align: center;">
        {t('page_x_of_n', [Math.ceil(startCursor / pageSize), formatNumber(Math.ceil(totalEdges / pageSize))])}
      </SecondaryText>
      <WhiteButton
        disabled={!hasNextPage}
        onClick={onNextClicked}
        title={t('next')}
        data-testid={ElementTestId.PAGINATION_NEXT}
      >
        <ChevronRightIcon color={BODY_TEXT} />
      </WhiteButton>
    </Container>
  );
};
