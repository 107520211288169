import type { MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';

import EditorIconButton from 'components/ui/editor/components/shared/EditorIconButton';
import LanguageEN from 'components/ui/icons/LanguageENIcon';
import LanguageES from 'components/ui/icons/LanguageESIcon';
import LanguageFR from 'components/ui/icons/LanguageFRIcon';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { MultilingualStringValue, translate } from 'utils/intlUtils';

const { t } = translate;

/**
 * Map of language icons
 */
const languageIconMap = {
  [MultilingualStringValue.EN]: LanguageEN,
  [MultilingualStringValue.FR]: LanguageFR,
  [MultilingualStringValue.ES]: LanguageES,
} as const;

const LanguageToggle = () => {
  const { clientType, language, setLanguage, supportedLanguages, isGeneratingText } = useRichTextEditor();
  const icon = useMemo(() => languageIconMap[language], [language]);
  const isMobileClientType = clientType === RichTextClientType.MOBILE;

  /**
   * Handles cycling through the available languages
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const currentIndex = supportedLanguages.indexOf(language);
    const nextIndex = (currentIndex + 1) % supportedLanguages.length;
    const nextLanguage = supportedLanguages[nextIndex];

    setLanguage(nextLanguage);
  }, [language, setLanguage, supportedLanguages]);

  return (
    <EditorIconButton
      data-testid={ElementTestId.EDITOR_BUTTON_LANGUAGE_TOGGLE}
      disabled={isGeneratingText}
      icon={icon}
      onClick={handleOnClick}
      tooltip={isMobileClientType ? undefined : { children: t('language') }}
    />
  );
};

export default LanguageToggle;
