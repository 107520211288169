import { gql } from '@apollo/client';

import { leadList } from 'store/api/graph/fragments/leadList';

import { avatar } from './avatar';

export const conversationList = gql`
  fragment ConversationListFragment on Conversation {
    id
    archivedByMe

    type
    typeName
    leadName: lead {
      ...LeadListFragment
    }
    subject
    itemsCount
    readByMe
    replied
    lastItemCreated
    messageSnippet
    attachmentsCount
  }
  ${leadList}
  ${avatar}
`;
