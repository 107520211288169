import { gql } from '@apollo/client';

import { retailPricingFinanceConfiguration } from 'store/api/graph/fragments/retailPricingFinanceConfiguration';
import { rooftopRetailPricing } from 'store/api/graph/fragments/rooftopRetailPricing';

export const rooftopRetailFinancePricingCreate = gql`
  mutation RooftopRetailFinancePricingCreate(
    $rooftopId: ID
    $financeConfiguration: FinanceRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_submit: Boolean! = true
  ) {
    data: rooftopRetailPricingCreate(
      rooftopId: $rooftopId
      financeConfiguration: $financeConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _submit: $_submit
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingFinanceConfigurationFragment
      }
    }
  }

  ${retailPricingFinanceConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailFinancePricingModify = gql`
  mutation RooftopRetailFinancePricingModify(
    $id: ID!
    $financeConfiguration: FinanceRetailPricingConfigurationInput
    $description: String
    $filter: RetailItemConnectionFilterInput
    $name: String
    $order: Int
    $targets: [PricingTarget!]
    $_clear: [RooftopRetailPricingModifyParameter!]
  ) {
    data: rooftopRetailPricingModify(
      id: $id
      financeConfiguration: $financeConfiguration
      description: $description
      filter: $filter
      name: $name
      order: $order
      targets: $targets
      _clear: $_clear
    ) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingFinanceConfigurationFragment
      }
    }
  }

  ${retailPricingFinanceConfiguration}
  ${rooftopRetailPricing}
`;
