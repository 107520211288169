import { gql } from '@apollo/client';

import { ApolloFetchPolicy } from 'enums/apollo';
import { useQuery } from 'hooks/useQuery';
import {
  integrationInventoryExportWebsiteBrandFragment,
  integrationOemAccountExportWebsiteBrandFragment,
} from 'store/api/graph/fragments/integration';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { rooftopIntegrationDetails } from 'store/api/graph/fragments/rooftopIntegrationDetails';
import { rooftopIntegrationList } from 'store/api/graph/fragments/rooftopIntegrationList';
import { rooftopIntegrationContainerItemFragment } from 'store/api/graph/fragments/rooftopIntegrationsContainer';
import type {
  RooftopIntegrationsCondensedListQuery,
  RooftopIntegrationsCondensedListQueryVariables,
} from 'store/api/graph/interfaces/types';

export const rooftopIntegrationsDetailsQuery = gql`
  query RooftopIntegrationsDetailsQuery($id: ID!) {
    item: rooftopIntegration(id: $id) {
      ...RooftopIntegrationContainerItemFragment
      ...RooftopIntegrationFragment
    }

    metadata {
      mutation {
        rooftopIntegration {
          oemAccountExportAttributes {
            ...IntegrationOemAccountExportWebsiteBrandFragment
          }
          inventoryExportAttributes {
            ...IntegrationInventoryExportWebsiteBrandFragment
          }
        }
      }
    }
  }
  ${rooftopIntegrationDetails}
  ${integrationOemAccountExportWebsiteBrandFragment}
  ${integrationInventoryExportWebsiteBrandFragment}
  ${rooftopIntegrationContainerItemFragment}
`;

export const rooftopIntegrationsCondensedListQuery = gql`
  query RooftopIntegrationsCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $filter: RooftopIntegrationConnectionFilterInput
  ) {
    connection: rooftopIntegrationConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RooftopIntegrationContainerItemFragment
          ...RooftopIntegrationListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${rooftopIntegrationList}
  ${rooftopIntegrationContainerItemFragment}
`;

export const useRooftopIntegrationsCondensedListQuery = (
  variables: Omit<RooftopIntegrationsCondensedListQueryVariables, 'first'>
): RooftopIntegrationsCondensedListQuery['connection'] | undefined => {
  const { data } = useQuery<RooftopIntegrationsCondensedListQuery, RooftopIntegrationsCondensedListQueryVariables>(
    rooftopIntegrationsCondensedListQuery,
    {
      options: { fetchPolicy: ApolloFetchPolicy.CACHE_FIRST },
      variables: { first: 3, ...variables },
    }
  );

  return data.connection;
};
