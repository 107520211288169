import { forwardRef } from 'react';

import styled from 'styled-components/macro';

const Scrollbar = styled.div<{ scrollHeight?: number; scrollWidth?: number }>`
  position: absolute;
  right: ${({ scrollHeight }) => scrollHeight && `0px`};
  top: ${({ scrollHeight }) => scrollHeight && '45px'};
  bottom: ${({ scrollWidth }) => scrollWidth && '0px'};
  left: ${({ scrollWidth }) => scrollWidth && '0px'};
  max-height: ${({ scrollHeight }) => (scrollHeight ? 'calc(100% - 45px)' : '17px')};
  max-width: ${({ scrollWidth }) => (scrollWidth ? '100%' : '17px')};
  overflow: auto;

  &::after {
    display: block;
    content: '';
    min-width: 17px;
    min-height: 17px;
    height: ${({ scrollHeight }) => (scrollHeight || 0) + 'px'};
    width: ${({ scrollWidth }) => (scrollWidth || 0) + 'px'};
  }
`;

interface Props {
  scrollHeight?: number;
  scrollWidth?: number;
  ref?: any;
  onScroll?: any;
}

const TableScrollbar = forwardRef<HTMLDivElement, Props>((props, ref) => <Scrollbar ref={ref} {...props} />);

export default TableScrollbar;
