import { gql } from '@apollo/client';

import { leadActivityList } from 'store/api/graph/fragments/leadActivityList';
import { leadList } from 'store/api/graph/fragments/leadList';

// Special class for lead section details/table view, used in the condensedList there
export const leadAndLeadActivityList = gql`
  fragment LeadAndLeadActivityListFragment on Lead {
    ...LeadListFragment
    leadActivities {
      ...LeadActivityListFragment
    }
  }
  ${leadList}
  ${leadActivityList}
`;
