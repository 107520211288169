import { memo, useCallback, useMemo, useRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styled, { css } from 'styled-components/macro';
import useDeepCompareEffect from 'use-deep-compare-effect';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import type { SectionProps } from 'components/sections/shared/linkedTab/LinkedTab';
import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItem, ListItemDetails, ListItems } from 'components/ui/layouts/ListItem';
import { Clickable } from 'components/ui/shared/Button';
import { rooftopDetailsQuery } from 'containers/rooftops/RooftopsContainerQuery';
import { CustomEntity } from 'enums/extendedEntityType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { ListItemType } from 'enums/listItemType';
import { linkedSectionItemTestId } from 'enums/testing';
import { useNestedView } from 'hooks/contexts/useNestedView';
import type { LinkedListProps } from 'hooks/useLinkedList';
import useLinkedList from 'hooks/useLinkedList';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { BLUE_050, NEUTRAL_050 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import type { FilterTagParams } from 'utils/filterUtils';
import { filterTagItems } from 'utils/filterUtils';
import { translate } from 'utils/intlUtils';

const { t } = translate;

type LinkedConfigurationListItemProps = LinkedListProps & {
  /** A count of configuration items. */
  count: number;
  /**
   * Whether or not the configuration has an associated list of items.
   * TODO: Remove prop when all sections have been implemented.
   */
  hasList?: boolean;
};

const ListItemCount = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 34px;
  min-height: 34px;
  border-radius: 5px;
  background-color: ${NEUTRAL_050};
`;

const ConfigurationListItem = styled(ListItem)<{ isSelected: boolean; isClickable: boolean }>`
  align-items: center;
  padding-right: 22px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  margin: 0;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${BLUE_050};
    `}

  ${({ isClickable }) =>
    !isClickable &&
    css`
      cursor: default;
    `}
`;

interface Props extends LinkedConfigurationListItemProps {
  rooftop: RooftopResponseType;
}

const LinkedConfigurationListItem = (props: Props) => {
  const { listEntity, title, isNested, count, rooftop, hasList } = useMemo(() => props, [props]);
  const { showList } = useLinkedList(
    listEntity,
    title,
    isNested,
    /*
     * We need to pass the current rooftop as seededData to the nested list view. This is then passed on to the create
     * builders, which needs a rooftop pre-selected. Its best practise to alias this seededData so multiple data
     * sources can be passed in.
     */
    { rooftop }
  );
  const { viewItems } = useNestedView();
  const showListCallback = useCallback(() => showList(), [showList]);

  return (
    <Clickable onClick={hasList ? showListCallback : undefined} data-testid={linkedSectionItemTestId(listEntity)}>
      {/*
       * Unlike other linked sections, the selected states for this section are manually determined by comparing
       * the entity type of the current nested view item, with each entity type found in the linked sections here.
       * Normally <ListItemClickable> would be used, but the rooftop configuration linked section is a special case.
       */}
      <ConfigurationListItem
        isSelected={!!viewItems.some(viewItem => viewItem.entityType === listEntity)}
        suffixIcon={!!hasList}
        isClickable={!!hasList}
        listItemType={ListItemType.SECTION_LIST}
      >
        <ListItemCount>
          <PrimaryText>{count}</PrimaryText>
        </ListItemCount>
        <ListItemDetails>
          <TextRow>
            <PrimaryText>{title}</PrimaryText>
          </TextRow>
        </ListItemDetails>
      </ConfigurationListItem>
    </Clickable>
  );
};

const RooftopConfigurationsSection = memo<SectionProps & { rooftop: RooftopResponseType }>(
  ({ sectionTitle = t('configuration_other'), rooftop, isNested }) => {
    const flags = useFlags();
    const { updateCurrentView } = useNestedView();
    const refUpdateCurrentView = useRef(updateCurrentView);

    /**
     * Update the view seed data whenever the rooftop gets updated
     */
    useDeepCompareEffect(() => {
      refUpdateCurrentView.current({ rooftop });
    }, [rooftop]);

    /**
     * For performance, get existing data from `rooftopDetailsQuery` that contains the tags.
     * Prevents having to resort to make a new separate query.
     */
    const data = client.readQuery({
      query: rooftopDetailsQuery,
      variables: {
        id: rooftop.id,
      },
    });

    const tagsResponse = data?.tags;

    const isRetailEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL,
      featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
    });

    const isRetailCashPaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
      featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
    });

    const isRetailFinancePaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
      featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
    });

    const isRetailLeasePaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
      featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
    });

    const filterTags: FilterTagParams = {
      response: tagsResponse,
      rooftop,
      flags,
    };

    const filteredTags = filterTagItems(filterTags);

    const tagsCount = filteredTags.length;

    const configurations: (LinkedConfigurationListItemProps & { inactive?: boolean })[] = [
      {
        listEntity: CustomEntity.ROOFTOP_BULK_ADJUSTMENT,
        count: rooftop.retailBulkAdjustmentsCount,
        isNested,
        title: t('retail_item_adjustments'),
        hasList: true,
        inactive: !isRetailEnabled,
      },
      {
        listEntity: CustomEntity.ROOFTOP_TAGS,
        count: tagsCount,
        isNested,
        title: t('tags'),
        hasList: true,
      },
      {
        listEntity: CustomEntity.USER,
        count: rooftop.usersCount,
        isNested,
        title: t('users'),
        hasList: true,
      },
      {
        listEntity: EntityType.LEAD_FORWARD,
        count: rooftop.leadForwardsCount,
        isNested,
        title: t('lead_forwarding'),
        hasList: true,
        inactive: !isFeatureEnabledForRooftop({
          rooftop,
          feature: FeatureBundleSet.LEAD_FORWARDING,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }),
      },
      {
        listEntity: CustomEntity.ROOFTOP_DEPARTMENTS,
        count: rooftop.departmentsCount,
        isNested,
        title: t('departments'),
        hasList: true,
      },
      {
        listEntity: CustomEntity.ROOFTOP_PRICING_SEGMENTS,
        count: rooftop.retailPricingsCount,
        isNested,
        title: t('pricing_segment_other'),
        hasList: true,
        inactive:
          !isRetailEnabled ||
          (!isRetailCashPaymentOptionEnabled &&
            !isRetailFinancePaymentOptionEnabled &&
            !isRetailLeasePaymentOptionEnabled),
      },
      {
        listEntity: CustomEntity.ROOFTOP_INTEGRATIONS,
        count: rooftop.integrationsCount,
        isNested,
        title: t('integration_other'),
        hasList: true,
      },
      {
        listEntity: EntityType.MAILBOX,
        count: rooftop.mailboxesCount,
        isNested,
        title: t('mailboxes'),
        hasList: true,
        inactive: !isFeatureEnabledForRooftop({
          rooftop,
          feature: FeatureBundleSet.LEAD,
          featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
        }),
      },
      {
        listEntity: CustomEntity.ROOFTOP_CONTACTS,
        count: rooftop.contactsCount,
        isNested,
        title: t('contacts'),
        hasList: true,
      },
    ].filter(configurationSection => !configurationSection.inactive);

    return (
      <LinkedSection>
        <LinkedSectionHeader title={sectionTitle} hasItems={!!configurations?.length} />
        <ListItems>
          {configurations.map(config => (
            <LinkedConfigurationListItem key={config.listEntity} {...config} rooftop={rooftop} />
          ))}
        </ListItems>
      </LinkedSection>
    );
  }
);

export default RooftopConfigurationsSection;
