import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { shoppingToolsModify } from 'components/sections/createModify/shoppingTools/ShoppingToolsCreateModifyQueries';
import ShoppingToolsQueryMethods from 'components/sections/createModify/shoppingTools/ShoppingToolsQueryMethods';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import DetailsFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';

const ShoppingToolsBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.SHOPPING_TOOLS_MODIFY]: {
    getData: ShoppingToolsQueryMethods.getData,
    getMetaData: ShoppingToolsQueryMethods.getMetaData,
    refetchQueries: ShoppingToolsQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: 'SHOPPING_TOOLS_DETAILS',
        step: DetailsStep,
        stepName: 'configuration_one',
        isEnabled: true,
        fields: DetailsFields,
      },
    ],
    mutations: {
      modify: shoppingToolsModify,
    },
  },
};

export default ShoppingToolsBuilders;
