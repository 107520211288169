import type { ClassAttributes, DOMAttributes } from 'react';
import { createElement } from 'react';

/**
 * Stops the default action of an element from happening.
 *
 * e.g. prevent a submit button from submitting a form
 */
const preventDefault = (e: Event) => {
  e.preventDefault();
  return false;
};

/**
 * Default event listener options.
 * NOTE: `passive` was not recognized as an event listener option but was being used previously,
 * so the object is being casted
 */
const eventOptions: EventListenerOptions = { passive: false, capture: true } as EventListenerOptions;

/**
 * Prevents the default scroll behaviour on the given element.
 *
 * Useful for disabling native OS scroll behaviours.
 * e.g. In a Table, so that horizontal trackpad scrolling doesn't
 * invoke swipe back gesture on OSX.
 *
 * Source:
 * https://stackoverflow.com/questions/50616221/prevent-page-navigation-on-horizontal-scroll
 * https://stackoverflow.com/questions/8737709/any-way-to-prevent-horizontal-scrolling-triggering-swipe-back-gesture-on-os-x-li
 */
export const enableScrollEventCapturing = (element: Element | undefined) => {
  element?.addEventListener?.('wheel', preventDefault, eventOptions);
  element?.addEventListener?.('mousewheel', preventDefault, eventOptions);
};

export const disableScrollEventCapturing = (element?: Element) => {
  element?.removeEventListener?.('wheel', preventDefault, eventOptions);
  element?.removeEventListener?.('mousewheel', preventDefault, eventOptions);
};

interface RawHtmlProps<T = Element> extends ClassAttributes<T>, DOMAttributes<T> {
  key?: string;
}

const renderHTML = (rawHTML: string, tagName: string, { key, ...props }: RawHtmlProps) =>
  createElement(tagName, {
    ...props,
    key,
    dangerouslySetInnerHTML: { __html: rawHTML },
  });

/**
 * Decodes a raw html string to an inline element.
 *
 * e.g. Used to create interactive or rich text markup
 */
export const renderRawHtmlInline = (rawHTML: string, htmlProps: RawHtmlProps) => renderHTML(rawHTML, 'span', htmlProps);

/**
 * Returns the number of columns used in a css grid element.
 */
export const getColumnCountOfGrid = (grid: Element): number =>
  getComputedStyle(grid).getPropertyValue('grid-template-columns').split(' ').length;

/**
 * Simple helper method that determines whether or not the element is visible in the container's vertical space
 */
export const isOffscreen = (el: HTMLElement, container: HTMLElement): boolean =>
  el.offsetTop + el.clientHeight < container.scrollTop ||
  el.offsetTop + el.clientHeight - container.scrollTop > container.clientHeight;
