import { memo, type SVGProps } from 'react';

import { ElementTestId } from 'enums/testing';

const UnlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg data-testid={ElementTestId.UNLOCK_ICON} width={12} height={12} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.038 11.62c.373 0 .687-.127.94-.381.253-.254.38-.569.38-.943V5.198c0-.375-.127-.69-.38-.944a1.274 1.274 0 0 0-.94-.38H4.48v-1c0-.425.148-.79.443-1.094A1.444 1.444 0 0 1 6 1.324c.339 0 .635.098.889.294.254.196.435.444.543.743a.746.746 0 0 0 .294.375.81.81 0 0 0 .457.138.567.567 0 0 0 .463-.225c.12-.15.155-.32.105-.512a2.853 2.853 0 0 0-1-1.537C7.248.2 6.664 0 6 0c-.785 0-1.455.279-2.008.837-.554.558-.83 1.237-.83 2.037v1h-.2c-.373 0-.687.126-.94.38-.253.254-.38.569-.38.944v5.098c0 .374.127.689.38.943s.567.381.94.381h6.076Zm0-1.324H2.962V5.198h6.076v5.098Zm-3.04-1.35a1.12 1.12 0 0 1-.826-.345 1.138 1.138 0 0 1-.342-.831c0-.324.114-.6.344-.83.23-.228.506-.343.828-.343.322 0 .598.115.826.346.228.23.342.507.342.83 0 .324-.115.6-.344.83a1.13 1.13 0 0 1-.828.343Z"
    />
  </svg>
);
export default memo(UnlockIcon);
