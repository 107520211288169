import { gql } from '@apollo/client';

import { mailboxDetail } from 'store/api/graph/fragments/mailboxDetail';

export const mailboxCreate = gql`
  mutation MailboxCreateMutation(
    $rooftopId: ID
    $areaCode: Int
    $sourceId: ID
    $conversationType: ConversationType
    $description: String
    $emailLocalPart: String
    $name: String
  ) {
    data: mailboxCreate(
      rooftopId: $rooftopId
      areaCode: $areaCode
      sourceId: $sourceId
      conversationType: $conversationType
      description: $description
      emailLocalPart: $emailLocalPart
      name: $name
    ) {
      ...MailboxDetailFragment
    }
  }

  ${mailboxDetail}
`;

export const mailboxModify = gql`
  mutation MailboxModifyMutation($id: ID!, $name: String, $description: String, $_clear: [MailboxModifyParameter!]) {
    data: mailboxModify(id: $id, name: $name, description: $description, _clear: $_clear) {
      ...MailboxDetailFragment
    }
  }

  ${mailboxDetail}
`;
