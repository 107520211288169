// List item with no border
export const LIST_ITEM_HEIGHT = '54px';
// List item with a single top or bottom border
export const LIST_ITEM_HEIGHT_WITH_BORDER = '53px';
// Width for the base builder
export const BUILDER_BASE_WIDTH = '800px';
// Generic width for all cards
export const CARD_WIDTH = '315px';
// Min panel width in px for all generic panels
export const MIN_PANEL_WIDTH = 130;
// Height of images used to preview content e.g. overlay, hero section
export const PREVIEW_IMAGE_HEIGHT = '215px';
// Width of the global nav bar
export const GLOBAL_NAV_WIDTH = '60px';
// Width of the step legend of a builder
export const BUILDER_STEP_LEGEND_WIDTH = '40%';
// Width of the fields container for the base builder
export const BUILDER_FIELDS_CONTAINER_BASE_WIDTH = '60%';

export const BREAKPOINTS = {
  LARGE: '1024px',
  EXTRA_LARGE: '1500px',
};
