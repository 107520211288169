import { useState } from 'react';

import { MenuItem } from 'components/ui/menus/MenuButton';
import { ElementTestId } from 'enums/testing';
import { useUser } from 'hooks/contexts/useUser';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';

import UserSelector from './UserSelector';

const { t } = translate;

const ImpersonationToggle = () => {
  const {
    user: { id, displayName },
    hasPermissions,
    impersonateAsUser,
  } = useUser();

  const [isUserSelectorOpen, setUserSelectorOpen] = useState<boolean>(false);

  if (
    !impersonationManager.isCurrentlyImpersonating &&
    !hasPermissions([{ resource: ResourceType.AS_USER, level: AccessLevel.FULL }])
  ) {
    return null;
  }

  if (impersonationManager.isCurrentlyImpersonating) {
    return (
      <MenuItem
        label={t('logout_of_user', [displayName])}
        onClick={() => impersonateAsUser(undefined)}
        testId={ElementTestId.LOGOUT_OF_USER_MENU_ITEM}
      />
    );
  }

  return (
    <>
      <MenuItem
        label={t('login_as_user')}
        onClick={() => setUserSelectorOpen(true)}
        testId={ElementTestId.LOGIN_AS_USER_MENU_ITEM}
      />
      {isUserSelectorOpen && (
        <UserSelector
          onUserSelected={user => impersonateAsUser(user)}
          myId={id}
          onCancel={() => setUserSelectorOpen(false)}
        />
      )}
    </>
  );
};

export default ImpersonationToggle;
