import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { subscribeWithMetadata } from 'components/sections/createModify/shared/utils';
import { bulkAdjustmentMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { rooftopRetailBulkAdjustmentsDetailsQuery } from 'containers/rooftops/retailBulkAdjustments/RooftopRetailBulkAdjustmentsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import type { BulkAdjustmentMetaQuery, BulkAdjustmentMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const RetailBulkAdjustmentQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const rooftopId = seededData?.rooftopName.id || defaultData.rooftopName.id;

    const bulkAdjustmentObservable = client.watchQuery<BulkAdjustmentMetaQuery, BulkAdjustmentMetaQueryVariables>({
      query: bulkAdjustmentMetaQuery,
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      variables: getRetailFiltersMetaQueryVariables(rooftopId, defaultData.filter),
    });

    return subscribeWithMetadata<BulkAdjustmentMetaQuery, BulkAdjustmentMetaQueryVariables>(
      bulkAdjustmentObservable,
      onMetaUpdate
    );
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopRetailBulkAdjustmentsDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },
  refetchQueries: [
    'RooftopRetailBulkAdjustmentsCondensedListQuery',
    'RooftopRetailBulkAdjustmentsCountQuery',
    'RooftopConnectionQuery',
    'RooftopDetailsContainerQuery',
    'RetailItemConnectionQuery',
    'RetailItemDetailsContainerQuery',
    'RooftopRetailBulkAdjustmentsDetailsQuery',
  ],
};

export default RetailBulkAdjustmentQueryMethods;
