import type { ReactNode } from 'react';

import { get } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import MonetaryAmount from 'components/core/typography/MonetaryAmount';
import SecondaryText from 'components/core/typography/SecondaryText';
import FalseIcon from 'components/ui/icons/FalseIcon';
import TrueIcon from 'components/ui/icons/TrueIcon';
import Badge, { BadgeSizes } from 'components/ui/shared/Badge';
import Swatch from 'components/ui/shared/Swatch';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { FieldDataType } from 'enums/fieldDataType';
import { ItemsColumn } from 'enums/itemsColumn';
import { StatusColors } from 'enums/statusColors';
import type { Permission } from 'store/api/graph/interfaces/types';
import { InventoryItemType } from 'store/api/graph/interfaces/types';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { formatYMMT } from 'utils/formatting/inventoryItemFormatUtils';
import { formatColorFromData, formatFullName } from 'utils/formatUtils';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';
import { userIdStorage } from 'utils/storage/auth';
import { storageManager, StorageType } from 'utils/storageUtils';
import { getTableColumnParams } from 'utils/urlUtils';

import type { TableType } from './tableSettings/TableSettings';
import { TableSettings, TableSubType } from './tableSettings/TableSettings';

const { t } = translate;
const { DATE_YEAR_FORMAT, DATE_TIME_FORMAT, DAY_DATE_YEAR_FORMAT, TIME_STAMP_FORMAT } = DateTimeFormat;

// For internal use with `getCellTemplate`
const PillContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  ${Badge} {
    height: 25px;
    flex-shrink: 0;
    padding: 0 10px;

    &:not(:last-child) {
      margin-right: 7px;
    }
  }
`;

const ColorContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  span {
    text-transform: capitalize;
  }
`;

/**
 * Gets a table's column configuration
 * @param tableType The type of table being retrieved based on `TableType`
 * @param tableSubType The sub-type of table being retrieved based on `TableSubType`, optional depending on table
 */
export function getStoredTableConfiguration(
  tableType: TableType,
  tableSubType?: TableSubType,
  // TODO: This may potentially be required to be used from the query themselves
  hasPermissions: (permissions: Permission[]) => boolean = () => true
): TableCellData[] {
  const tableSettingsId = getTableSettingsId(tableType, tableSubType);
  const tableStorage = storageManager.createOrFetchStorage<TableCellData[]>(
    `${tableSettingsId}Configuration`,
    StorageType.LOCAL,
    impersonationManager.getImpersonatedUserId() || userIdStorage.get()
  );

  const defaultData: readonly TableCellData[] = TableSettings[tableSettingsId];
  const storedConfig: TableCellData[] = tableStorage.get() || [];
  const urlColumns = getTableColumnParams(window.location.search);

  if (urlColumns.length > 0) {
    // Only show table columns coming from URL params
    return defaultData.map(defaultCell => {
      const isColumnInUrlParams =
        urlColumns.includes(defaultCell.columnId) ||
        [ItemsColumn.SELECT, ItemsColumn.PHOTOS].includes(defaultCell.columnId as ItemsColumn);
      return {
        ...defaultCell,
        ...(isColumnInUrlParams ? { enabled: true } : { enabled: false }),
      };
    });
  }

  return defaultData
    .map(defaultCell => {
      const storedCell = storedConfig.find(cellData => cellData.columnId === defaultCell.columnId);
      return storedCell
        ? {
            ...defaultCell,
            ...storedCell,
          }
        : { ...defaultCell };
    })
    .filter(item => !item.requiredPermissions || hasPermissions(item.requiredPermissions))
    .sort((a, b) => {
      const index1 = storedConfig.findIndex(item => item.columnId === a.columnId);
      const index2 = storedConfig.findIndex(item => item.columnId === b.columnId);
      return index1 === -1 || index2 === -1 ? 0 : index1 - index2;
    });
}

/**
 * Sets a table's column configuration
 * @param tableType The type of table being saved based on `TableType`
 * @param tableSubType The sub-type of table being saved based on `TableSubType`, optional depending on table
 * @param headerData The new header array full of column data
 */
export function saveTableConfiguration(
  tableType: TableType,
  tableSubType: TableSubType | undefined,
  headerData: TableCellData[]
) {
  const tableStorage = storageManager.createOrFetchStorage(
    `${getTableSettingsId(tableType, tableSubType)}Configuration`,
    StorageType.LOCAL,
    impersonationManager.getImpersonatedUserId() || userIdStorage.get()
  );

  tableStorage.set(
    headerData.map(({ columnId, width, enabled, rowId }) => ({
      columnId,
      rowId,
      width,
      enabled,
    }))
  );
}

/**
 * Returns the formatted table id based on `tableType` and `tableSubType`
 * @param tableType The type of table based on `TableType`
 * @param tableSubType The sub-type of table based on `TableSubType`, optional depending on table
 */
export function getTableSettingsId(tableType: TableType, tableSubType?: TableSubType) {
  return `${tableType}${tableSubType ? `-${tableSubType}` : ''}`;
}

/**
 * Formats cellData from the API based on the headerData column the cell corresponds to and returns a
 * renderable ReactNode
 * @param headerData The header data that this cell references for columnId and type
 * @param cellData The raw cell data that came back from the API, usually comes back as a ResponseType
 * @param metadata Metadata info that came with the data connection (e.g. colors, status, translations, etc)
 */
export function getCellTemplate(headerData: TableCellData, cellData: any, metadata?: any): ReactNode {
  const content = get(cellData, `${headerData.columnId}.value`, get(cellData, headerData.columnId));

  // Formatting for header cells
  switch (headerData.cellType) {
    case FieldDataType.NAME: {
      return <SecondaryText>{content?.name?.value || content?.name || ''}</SecondaryText>;
    }

    case FieldDataType.DISPLAY_NAME: {
      return <SecondaryText>{formatFullName(content)}</SecondaryText>;
    }

    case FieldDataType.GROUP_NAME: {
      return <SecondaryText>{content?.group?.name?.value}</SecondaryText>;
    }

    case FieldDataType.MONETARY: {
      const amount = get(content, 'formattedAmountRounded');
      return (
        <MonetaryAmount
          css={css`
            font-variant-numeric: tabular-nums;
            width: 100%;
          `}
        >
          {amount === null || amount === undefined ? '' : amount}
        </MonetaryAmount>
      );
    }

    case FieldDataType.DATE: {
      return content && <SecondaryText>{getFormattedDateTimeString(content, DATE_YEAR_FORMAT)}</SecondaryText>;
    }

    case FieldDataType.DAY_TIME: {
      return content && <SecondaryText>{getFormattedDateTimeString(content, DATE_TIME_FORMAT)}</SecondaryText>;
    }

    case FieldDataType.DAY_MONTH_YEAR: {
      return content && <SecondaryText>{getFormattedDateTimeString(content, DAY_DATE_YEAR_FORMAT)}</SecondaryText>;
    }

    case FieldDataType.TIME: {
      return content && <SecondaryText>{getFormattedDateTimeString(content, TIME_STAMP_FORMAT)}</SecondaryText>;
    }

    case FieldDataType.ENUM: {
      return <SecondaryText>{get(cellData, `${headerData.columnId}Name`, content)}</SecondaryText>;
    }

    case FieldDataType.BOOLEAN: {
      return content ? (
        <TrueIcon width={14} height={14} color={GREEN_500} />
      ) : (
        <FalseIcon width={14} height={14} color={RED_500} />
      );
    }

    case FieldDataType.BADGE_LIST: {
      return (
        <PillContainer>
          {get(cellData, `${headerData.columnId}Names`, content || []).map(badge => {
            const badgeValue = get(badge, 'name.value') || badge;
            return (
              <Badge title={`${badgeValue}`} key={badge.id || badgeValue}>
                {badgeValue}
              </Badge>
            );
          })}
        </PillContainer>
      );
    }

    case FieldDataType.FORMATTED_AMOUNT: {
      return <SecondaryText>{get(content, 'formattedAmount')}</SecondaryText>;
    }

    case FieldDataType.PERCENTAGE: {
      return <SecondaryText>{Math.round((content || 0) * 100)}%</SecondaryText>;
    }

    case FieldDataType.COLOR_DESCRIBED:
    case FieldDataType.COLOR_PREVIEW: {
      // Grabbing applicable color metadata hex value
      const color = get(metadata, `mutation.item.${headerData.columnId}`)?.find(({ id }) => id === content)?.data;
      const colorName = get(cellData, `${headerData.columnId}Name`);
      return (
        <ColorContent>
          {headerData.cellType !== FieldDataType.COLOR_DESCRIBED && (
            <Swatch color={formatColorFromData(color, content)} css="margin-right: 12px" />
          )}
          <SecondaryText>{colorName && <span>{colorName}</span>}</SecondaryText>
        </ColorContent>
      );
    }

    case FieldDataType.STATUS: {
      const status = get(cellData, `${headerData.columnId}Name`, content);
      return (
        <Badge title={`${t('status')}: ${status}`} color={StatusColors[content]} size={BadgeSizes.LARGE}>
          {status}
        </Badge>
      );
    }

    case FieldDataType.USER_NAME: {
      /*
       * Attempt to parse from content if available, otherwise fall back to cell data where name could be
       * on the top level
       */
      return <SecondaryText>{formatFullName(content || cellData)}</SecondaryText>;
    }

    case FieldDataType.YMMT: {
      return <SecondaryText>{formatYMMT(content)}</SecondaryText>;
    }

    case FieldDataType.CUSTOM: {
      LoggingService.debug({ message: `Unsupported custom cell: ${headerData.columnId}` });
      return null;
    }

    default: {
      return <SecondaryText>{`${content === null || content === undefined ? '' : content}`}</SecondaryText>;
    }
  }
}

/**
 * Returns the corresponding TableSubType based on the `type` that gets passed, usually comes from the URL
 * @param type VEHICLE, MOTORCYCLE, or null/undefined
 */
export function getInventoryTableSubType(type: InventoryItemType): TableSubType {
  // TODO: [ED-8912]
  switch (type) {
    case InventoryItemType.VEHICLE: {
      return TableSubType.VEHICLES;
    }

    case InventoryItemType.MOTORCYCLE: {
      return TableSubType.MOTORCYCLES;
    }

    default: {
      return TableSubType.BASE;
    }
  }
}

/**
 * Returns a combined Retail items config with shared items filtered out with priority based on `type`
 * @param type VEHICLE, MOTORCYCLE, or null/undefined
 * @param tableType The type of table being accessed based on `TableType`
 */
export function getInventoryItemCombinedConfigurations(type: InventoryItemType, tableType: TableType): TableCellData[] {
  const tableConfigBase: TableCellData[] = getStoredTableConfiguration(tableType, TableSubType.BASE);
  const tableConfigMotorycle: TableCellData[] = getStoredTableConfiguration(tableType, TableSubType.MOTORCYCLES);
  const tableConfigVehicle: TableCellData[] = getStoredTableConfiguration(tableType, TableSubType.VEHICLES);
  // TODO: [ED-8912]
  switch (type) {
    case InventoryItemType.VEHICLE: {
      return tableConfigVehicle.concat(filterOutBaseConfig(tableConfigBase, tableConfigMotorycle));
    }

    case InventoryItemType.MOTORCYCLE: {
      return tableConfigMotorycle.concat(filterOutBaseConfig(tableConfigBase, tableConfigVehicle));
    }

    default: {
      return tableConfigBase
        .concat(filterOutBaseConfig(tableConfigBase, tableConfigMotorycle))
        .concat(filterOutBaseConfig(tableConfigBase, tableConfigVehicle));
    }
  }
}

/**
 * Helper method to remove similar items from the config.
 * Used mainly when there are multiple tables sharing the same base data
 * @param base Shared table cell data from TableSettings
 * @param target Target table cell data from TableSettings
 */
function filterOutBaseConfig(base: TableCellData[], target: TableCellData[]): TableCellData[] {
  return target.filter(targetItem => !base.some(baseItem => baseItem.columnId === targetItem.columnId));
}
