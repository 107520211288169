import type { GlobalAdminFilters } from 'components/ui/filters/interfaces/globalFilter';
import { ItemViewType } from 'enums/ItemViewType';
import { SubRoute } from 'enums/routePath';
import type { SavedConnectionFilterFragment } from 'store/api/graph/interfaces/types';
import { getSectionPath } from 'utils/urlUtils';

import { impersonationManager } from './impersonationUtils';
import { userIdStorage } from './storage/auth';
import { storageManager, StorageType } from './storageUtils';

/**
 * List of unique storage identifiers used in app
 *
 * TODO: Add legacy storage IDs
 */
enum StorageID {
  INITIAL_LAYOUT_VIEW = 'initialLayoutView',
  INITIAL_LINKED_SECTION_TOGGLED_STATE = 'initialLinkedSectionToggledState',
  INITIAL_FACET_FILTER_TOGGLED_STATE = 'initialFacetFilterToggledState',
  GLOBAL_ADMIN_FILTERS = 'globalAdminFilters',
  FACET_FILTER = 'facetFilter',
  SAVED_FILTER_VIEW = 'savedFilterView',
}

/**
 * COMMON
 */

const getSectionStorageName = (options: { forPathname?: string; withName: StorageID; forUserId?: string }): string => {
  const key = `${options.forPathname || 'global'}.${options.withName}`;
  return storageManager.getStorageName({
    withKey: key,
    forUserId: options?.forUserId || impersonationManager.getImpersonatedUserId() || userIdStorage.get(),
  });
};

/**
 * StorageID.INITIAL_LAYOUT_VIEW
 */

export const getInitialLayoutView = (options: { forPathname: string; usePersistedView: boolean }): ItemViewType => {
  const storageName = getSectionStorageName({
    forPathname: getSectionPath(options.forPathname),
    withName: StorageID.INITIAL_LAYOUT_VIEW,
  });
  const viewConfigurationStorage = storageManager.createOrFetchStorage<ItemViewType>(storageName, StorageType.LOCAL);
  const viewFromStorage = viewConfigurationStorage.get();
  const isValidView = viewFromStorage ? Object.values(ItemViewType).includes(viewFromStorage) : false;

  const defaultNonPersistedView = options.forPathname.includes(SubRoute.TABLE)
    ? ItemViewType.TABLE_VIEW
    : ItemViewType.SPLIT_VIEW;
  const storedView = isValidView && options.usePersistedView ? viewFromStorage : defaultNonPersistedView;
  const initialView = storedView ?? defaultNonPersistedView;

  if (!isValidView) {
    saveInitialLayoutView({ forPathname: options.forPathname, toViewType: initialView });
  }

  return initialView;
};

export const saveInitialLayoutView = (options: { forPathname: string; toViewType: ItemViewType }) => {
  const storageName = getSectionStorageName({
    forPathname: getSectionPath(options.forPathname),
    withName: StorageID.INITIAL_LAYOUT_VIEW,
  });
  const viewConfigurationStorage = storageManager.createOrFetchStorage(storageName, StorageType.LOCAL);

  viewConfigurationStorage.set(options.toViewType);
};

/**
 * StorageID.INITIAL_LINKED_SECTION_TOGGLED_STATE
 */

interface GetInitialLinkedSectionToggledStateProps {
  /** Pathname to be used as a unique identifier as part of the localStorage key */
  forPathname: string;
}

export const getInitialLinkedSectionToggledState = (options: GetInitialLinkedSectionToggledStateProps): boolean => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.INITIAL_LINKED_SECTION_TOGGLED_STATE,
  });
  const linkedSectionToggleStateStorage = storageManager.createOrFetchStorage<boolean>(storageName, StorageType.LOCAL);
  const linkedSectionToggleState = linkedSectionToggleStateStorage.get();

  /**
   * If there are no existing local storage key found, create a new key and return `false` by default.
   */
  if (linkedSectionToggleState === undefined) {
    saveInitialLinkedSectionToggledState({ forPathname: options.forPathname, shouldCollapse: false });
  }

  return linkedSectionToggleState || false;
};

interface SaveInitialLinkedSectionToggledStateProps {
  /** Pathname to be used as a unique identifier as part of the localStorage key */
  forPathname: string;
  /** The expanded/collapse state of the linked section */
  shouldCollapse: boolean;
}

export const saveInitialLinkedSectionToggledState = (options: SaveInitialLinkedSectionToggledStateProps) => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.INITIAL_LINKED_SECTION_TOGGLED_STATE,
  });
  const viewConfigurationStorage = storageManager.createOrFetchStorage(storageName, StorageType.LOCAL);

  viewConfigurationStorage.set(options.shouldCollapse);
};

/**
 * StorageID.INITIAL_FACET_FILTER_TOGGLED_STATE
 */

const DEFAULT_FACET_FILTER_TOGGLED_STATE = false;

export const getInitialFacetFilterToggledState = (options: { forPathname: string }): boolean => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.INITIAL_FACET_FILTER_TOGGLED_STATE,
  });
  const viewConfigurationStorage = storageManager.createOrFetchStorage<boolean>(storageName, StorageType.LOCAL);
  const isToggled = viewConfigurationStorage.get() ?? DEFAULT_FACET_FILTER_TOGGLED_STATE;

  return isToggled;
};

export const saveInitialFacetFilterToggledState = (options: { forPathname: string; isToggled: boolean }) => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.INITIAL_FACET_FILTER_TOGGLED_STATE,
  });
  const isToggledStorage = storageManager.createOrFetchStorage<boolean>(storageName, StorageType.LOCAL);

  isToggledStorage.set(options.isToggled ?? DEFAULT_FACET_FILTER_TOGGLED_STATE);
};

/**
 * StorageID.SAVED_FILTER_VIEW
 */

export const getInitialSavedFilterViewState = (options: {
  forPathname: string;
}): SavedConnectionFilterFragment | undefined => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.SAVED_FILTER_VIEW,
  });

  const state = storageManager.createOrFetchStorage<SavedConnectionFilterFragment | undefined>(
    storageName,
    StorageType.LOCAL
  );

  return state.get();
};

export const saveInitialSavedFilterViewState = (options: {
  forPathname: string;
  value: SavedConnectionFilterFragment | undefined;
}) => {
  const storageName = getSectionStorageName({
    forPathname: options.forPathname,
    withName: StorageID.SAVED_FILTER_VIEW,
  });

  const state = storageManager.createOrFetchStorage<SavedConnectionFilterFragment | undefined>(
    storageName,
    StorageType.LOCAL
  );
  state.set(options.value);
};

/**
 * StorageID.GLOBAL_ADMIN_FILTERS
 */

export const getInitialGlobalAdminFilters = () => {
  const storageName = getSectionStorageName({ withName: StorageID.GLOBAL_ADMIN_FILTERS });
  const globalAdminFiltersStorage = storageManager.createOrFetchStorage<GlobalAdminFilters>(
    storageName,
    StorageType.LOCAL
  );

  return globalAdminFiltersStorage.get() || {};
};

export const saveInitialGlobalAdminFilters = (options: { filters?: Partial<GlobalAdminFilters> }) => {
  const storageName = getSectionStorageName({ withName: StorageID.GLOBAL_ADMIN_FILTERS });
  const globalAdminFiltersStorage = storageManager.createOrFetchStorage<GlobalAdminFilters | undefined>(
    storageName,
    StorageType.LOCAL
  );

  globalAdminFiltersStorage.set(options.filters);
};

/**
 * StorageID.FACET_FILTER
 */

export const getInitialFacetFilters = (route: string) => {
  const storageName = getSectionStorageName({ forPathname: route, withName: StorageID.FACET_FILTER });
  const routeFiltersStorage = storageManager.createOrFetchStorage<Record<string, unknown>>(storageName);

  return routeFiltersStorage.get();
};

export const saveInitialFacetFilters = ({
  routeFilters,
  route,
}: {
  routeFilters: Record<string, any>;
  route: string;
}) => {
  const storageName = getSectionStorageName({ forPathname: route, withName: StorageID.FACET_FILTER });
  const routeFiltersStorage = storageManager.createOrFetchStorage<Record<string, unknown>>(storageName);

  routeFiltersStorage.set(routeFilters);
};
