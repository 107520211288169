import { memo, type SVGProps } from 'react';

const TagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m20.265 13.312-3.49 4.94a2.11 2.11 0 0 1-.804.702c-.33.166-.682.25-1.055.25H5.072a2.19 2.19 0 0 1-1.607-.669 2.19 2.19 0 0 1-.668-1.607V7.072c0-.626.222-1.162.668-1.607a2.19 2.19 0 0 1 1.607-.668h9.844c.373 0 .725.084 1.054.252.33.168.598.4.806.7l3.489 4.94c.282.397.422.835.422 1.311s-.14.914-.422 1.312Z"
    />
  </svg>
);
export default memo(TagIcon);
