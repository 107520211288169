import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { StepFieldType } from 'enums/stepFieldType';

import { GroupDetailsBuilderFields } from './interfaces';

const DetailsFields: StepFields = {
  [GroupDetailsBuilderFields.NAME]: {
    label: 'name',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
  },
  [GroupDetailsBuilderFields.WHITE_LABEL_ID]: {
    label: 'white_label',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
};

export default DetailsFields;
