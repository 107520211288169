import { gql } from '@apollo/client';

import { appointmentDetail } from 'store/api/graph/fragments/appointmentDetail';

export const appointmentModify = gql`
  mutation AppointmentModifyMutation(
    $id: ID!
    $assignedToId: ID
    $leadId: ID
    $dateFrom: DateTime
    $dateTo: DateTime
    $inventoryItemId: ID
    $location: String
    $note: String
    $reminder: DurationInput
    $tagIds: [ID!]
    $title: String
    $archived: Boolean
    $_clear: [AppointmentModifyParameter!]
  ) {
    data: appointmentModify(
      id: $id
      assignedToId: $assignedToId
      leadId: $leadId
      dateFrom: $dateFrom
      dateTo: $dateTo
      inventoryItemId: $inventoryItemId
      location: $location
      note: $note
      reminder: $reminder
      tagIds: $tagIds
      title: $title
      archived: $archived
      _clear: $_clear
    ) {
      ...AppointmentDetailFragment
    }
  }
  ${appointmentDetail}
`;

export const appointmentCreate = gql`
  mutation AppointmentCreateMutation(
    $rooftopId: ID
    $assignedToId: ID
    $leadId: ID
    $dateFrom: DateTime
    $dateTo: DateTime
    $inventoryItemId: ID
    $location: String
    $note: String
    $reminder: DurationInput
    $tagIds: [ID!]
    $title: String
  ) {
    data: appointmentCreate(
      rooftopId: $rooftopId
      assignedToId: $assignedToId
      leadId: $leadId
      dateFrom: $dateFrom
      dateTo: $dateTo
      inventoryItemId: $inventoryItemId
      location: $location
      note: $note
      reminder: $reminder
      tagIds: $tagIds
      title: $title
    ) {
      ...AppointmentDetailFragment
    }
  }
  ${appointmentDetail}
`;
