import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { integrationOemAccountExportWebsiteBrand } from 'components/sections/shared/ItemMetaQueries';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type {
  IntegrationOemAccountExportWebsiteBrandQuery,
  IntegrationOemAccountExportWebsiteBrandQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const OemAccountExportQueryMethods: QueryMethods = {
  getMetaData: async onMetaUpdate => {
    const observable = client.watchQuery<
      IntegrationOemAccountExportWebsiteBrandQuery,
      IntegrationOemAccountExportWebsiteBrandQueryVariables
    >({
      query: integrationOemAccountExportWebsiteBrand,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data.metadata;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },
};

export default OemAccountExportQueryMethods;
