import Swatch from 'components/ui/shared/Swatch';
import { formatColorFromData } from 'utils/formatUtils';

interface Props {
  color: string;
  metadata: any;
}

export const ItemColorPreview = ({ color, metadata }: Props) => {
  const colorMetadata = ((metadata?.mutation?.item || metadata)?.vehicleAttributes?.exteriorColor || []).find(
    ({ id }) => id === color
  );
  return <Swatch color={formatColorFromData(colorMetadata.data, 'transparent')} />;
};
