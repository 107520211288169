import { get, isEmpty } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RooftopYearModelOptions } from 'components/sections/createModify/inventoryItems/steps/OptionsFields';
import type { YearModelOption } from 'components/sections/createModify/shoppingTools/steps/interfaces';
import { ShoppingToolsDetailsBuilderFields } from 'components/sections/createModify/shoppingTools/steps/interfaces';
import {
  getModelOptions,
  getModelYearId,
  getShowroomYears,
} from 'components/sections/createModify/shoppingTools/utils';
import { getYMMTOptions, YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { RooftopShoppingTools } from 'enums/columns/rooftopShoppingTools';
import { FeatureBundleSet } from 'enums/featureBundle';
import type {
  Make,
  Model,
  ShoppingToolsConfigurationInput,
  ShoppingToolsDetailsQuery,
} from 'store/api/graph/interfaces/types';
import {
  InventoryItemType,
  RooftopModifyParameter,
  ShoppingToolsConfigurationInputParameter,
} from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  getStepField,
  objectToStepFieldArray,
  parseCategoryGroups,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

const SHOWROOM_FIELDS = [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE];

const BUILD_AND_PRICE_FIELDS = [
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE,
];

const COMMON_FIELDS = [
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENT,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_TRIMS,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS,
  ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS,
];

class DetailsStep extends StepComponentCore<ShoppingToolsDetailsQuery['item']> {
  constructor(props: StepComponentProps<ShoppingToolsDetailsQuery['item']>, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, activeStep, itemId: rooftopId, metadata, isCreating },
    } = props;

    const {
      subContexts: { featureFlags },
    } = context;

    const countryCode = data?.location?.countryCode;

    if (!countryCode) {
      throw new Error('Developer Error: Please provide countryCode');
    }

    const savedMakes = get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_MAKES);

    const isBuildAndPriceFeatureEnabled = isFeatureEnabledForRooftop({
      rooftop: data,
      feature: FeatureBundleSet.RETAIL_BUILD_AND_PRICE,
      featureFlagOn: featureFlags.showroomConfigurationEnabled,
    });

    const isShowroomFeatureEnabled = isFeatureEnabledForRooftop({
      rooftop: data,
      feature: FeatureBundleSet.RETAIL_SHOWROOM,
      featureFlagOn: featureFlags.showroomConfigurationEnabled,
    });

    const initialShowroomEnabledStatus = isCreating ? true : !!data?.shoppingToolsConfiguration?.showroomConfiguration;
    const initialBuildAndPriceEnabledStatus = isCreating
      ? true
      : !!data?.shoppingToolsConfiguration?.buildAndPriceConfiguration;

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // Defaults
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM]: {
        selectedValue: isShowroomFeatureEnabled ? initialShowroomEnabledStatus : null,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isShowroomFeatureEnabled,
          },
        ]),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE]: {
        selectedValue: isBuildAndPriceFeatureEnabled ? initialBuildAndPriceEnabledStatus : null,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !isBuildAndPriceFeatureEnabled,
          },
        ]),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES]: {
        selectedValue: savedMakes,
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS]: {
        renderElement: RooftopYearModelOptions,
        selectedValue: this.getSavedYearModels(),
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.DISABLED, active: !savedMakes },
          { type: StepFieldDisplayType.OMITTED, active: true },
        ]),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS]: {
        selectedValue: get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_LEASE_PAYMENTS, true),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS]: {
        selectedValue: get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOW_FINANCE_PAYMENTS, true),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES]: {
        selectedValue: get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_INCENTIVES, true),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENT]: {
        selectedValue: get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_BULK_ADJUSTMENTS, true),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_TRIMS]: {
        selectedValue: get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_INCLUDE_COMMERCIAL_VEHICLES, false),
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE]: {
        selectedValue:
          get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_SHOWROOM_DISCLAIMER_TEMPLATE) ||
          metadata?.showroomConfiguration?.disclaimerTemplate,
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE]: {
        selectedValue:
          get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_BUILD_AND_PRICE_DISCLAIMER_TEMPLATE) ||
          metadata?.buildAndPriceConfiguration?.disclaimerTemplate,
      },
    });

    this.hideOrShowFields();

    this.asyncConfigurations = {
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES]: {
        request: () => getYMMTOptions({ rooftopId: [rooftopId], type: InventoryItemType.VEHICLE }, YMMTTarget.MAKE_ID),
        disableKeywordRefetch: true,
      },
      [ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS]: {
        request: async () => {
          const yearModelField = getStepField(
            ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
            this.fields
          );

          const makesField = getStepField(ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES, this.fields);
          const savedMakes = get(data, RooftopShoppingTools.SHOPPING_TOOLS_CONFIG_MAKES);

          const availableMakes = makesField.selectedValue || savedMakes;

          // If there are no makes selected then don't bother getting any models
          if (isEmpty(availableMakes)) {
            return [];
          }

          const [currentYear, nextYear] = getShowroomYears();

          // Get the models for the current year
          const currentYearModels = await getYMMTOptions(
            {
              rooftopId: [rooftopId],
              makeIds: availableMakes.map(item => item.id) || [],
              year: currentYear,
              countryCode,
            },
            YMMTTarget.MODEL_ID
          );

          // Get the models for next year
          const nextYearModels = await getYMMTOptions(
            {
              rooftopId: [rooftopId],
              makeIds: availableMakes.map(item => item.id) || [],
              year: nextYear,
              countryCode,
            },
            YMMTTarget.MODEL_ID
          );

          const allModels = getModelOptions(
            [
              {
                year: currentYear,
                models: currentYearModels as Model[],
              },
              {
                year: nextYear,
                models: nextYearModels as Model[],
              },
            ],
            availableMakes
          );

          yearModelField.subStepGroups = parseCategoryGroups(allModels);
          return allModels;
        },
        disableKeywordRefetch: true,
      },
    };
  }

  getSavedYearModels() {
    const {
      tier: { data, metadata },
    } = this.props;

    // If there is no metadata for the makes we can't determine the saved models
    if (!metadata) {
      return null;
    }

    const savedMakes = data.shoppingToolsConfiguration?.makes;
    const savedModels = data.shoppingToolsConfiguration?.yearModels;

    // There must be saved makes in order for there to be saved models
    if (savedMakes?.length) {
      const modelOptions = savedModels ? getModelOptions(savedModels, metadata.makes) : [];
      const savedModelIds = savedModels?.flatMap(yearModel =>
        yearModel.models.map(model => getModelYearId(model, yearModel.year))
      );

      const savedModelOptions = modelOptions.filter(option => savedModelIds?.includes(option.id));
      return savedModelOptions?.length ? savedModelOptions : null;
    } else {
      return null;
    }
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES) {
      const selectedMakeIds = (value as Make[]).map(make => make.id);
      const modelsField = getStepField(
        ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
        this.fields
      );
      const currentSelectedModels = modelsField.selectedValue;

      if (modelsField.selectedValue?.length) {
        modelsField.selectedValue = currentSelectedModels.filter((item: YearModelOption) =>
          // Filter out any selected models that are no longer available given the selected makes
          selectedMakeIds.includes(item.makeId)
        );
      }

      // If there are no makes selected then disable the models field
      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: selectedMakeIds.length === 0 }, modelsField);
    }

    super.onFieldSelection(stepField, value);
  }

  onFieldChange(
    stepField: StepField<ShoppingToolsDetailsQuery['item'], any>,
    e: Record<'currentTarget', { value: any }>,
    shouldForceUpdate = false
  ) {
    super.onFieldChange(stepField, e, shouldForceUpdate);

    if (
      [
        ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM,
        ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE,
      ].includes(stepField.queryVar as ShoppingToolsDetailsBuilderFields)
    ) {
      this.hideOrShowFields();
    }
  }

  hideOrShowFields() {
    const buildAndPriceEnabled = !!getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE,
      this.fields
    ).selectedValue;
    const showroomEnabled = !!getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM,
      this.fields
    ).selectedValue;

    for (const field of BUILD_AND_PRICE_FIELDS) {
      setDisplayTypes(
        { type: StepFieldDisplayType.HIDDEN, active: !buildAndPriceEnabled },
        getStepField(field, this.fields)
      );
    }

    for (const field of SHOWROOM_FIELDS) {
      setDisplayTypes(
        { type: StepFieldDisplayType.HIDDEN, active: !showroomEnabled },
        getStepField(field, this.fields)
      );
    }

    for (const field of COMMON_FIELDS) {
      setDisplayTypes(
        { type: StepFieldDisplayType.HIDDEN, active: !showroomEnabled && !buildAndPriceEnabled },
        getStepField(field, this.fields)
      );
    }
  }

  async save(): Promise<boolean> {
    const {
      tier: { itemId },
    } = this.props;

    const shoppingToolsVariableOverrides: ShoppingToolsConfigurationInput = {
      _clear: [],
    };

    const isBuildAndPriceEnabled = !!getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_BUILD_AND_PRICE,
      this.fields
    ).selectedValue;
    const isShowroomEnabled = !!getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_ENABLED_SHOWROOM,
      this.fields
    ).selectedValue;

    if (!isBuildAndPriceEnabled) {
      shoppingToolsVariableOverrides.buildAndPriceConfiguration = null;
      shoppingToolsVariableOverrides._clear?.push(ShoppingToolsConfigurationInputParameter._buildAndPriceConfiguration);
    } else if (!isShowroomEnabled) {
      shoppingToolsVariableOverrides.showroomConfiguration = null;
      shoppingToolsVariableOverrides._clear?.push(ShoppingToolsConfigurationInputParameter._showroomConfiguration);
    }

    const models = getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_YEAR_MODELS,
      this.fields
    ).selectedValue;

    const makes = getStepField(
      ShoppingToolsDetailsBuilderFields.SHOPPING_TOOLS_CONFIG_MAKES,
      this.fields
    ).selectedValue;

    const yearModelsValue = getShowroomYears()
      .map(year => ({
        year,
        modelIds: (models || []).reduce(
          (acc: string[], current: YearModelOption) => (current.year === year ? [...acc, current.modelId] : acc),
          []
        ),
      }))
      .filter(year => year.modelIds.length > 0);

    const variableOverrides = {
      _clear:
        !isBuildAndPriceEnabled && !isShowroomEnabled
          ? [RooftopModifyParameter._shoppingToolsConfiguration]
          : undefined,
      id: itemId,
      shoppingToolsConfiguration:
        isBuildAndPriceEnabled || isShowroomEnabled
          ? {
              ...shoppingToolsVariableOverrides,
              makeIds: makes?.length ? makes?.map(make => make.id) : null,
              yearModels: yearModelsValue?.length ? yearModelsValue : null,
            }
          : null,
    };

    return super.save({}, variableOverrides);
  }
}

export default DetailsStep;
