import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { BadgeColors, Badges } from 'components/ui/shared/Badge';
import type { MailboxResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

interface Props extends MailboxResponseType, ListItemProps {}

const { t } = translate;

const MailboxListItem = ({ suffixIcon, listItemType, name, active, conversationTypeName }: Props) => (
  <ListItem suffixIcon={suffixIcon} listItemType={listItemType}>
    <ListItemDetails>
      <TextRow>
        <PrimaryText>{name}</PrimaryText>
      </TextRow>
      <TextRow>
        <Badges>
          <Badge title={`${t('type')}: ${conversationTypeName}`}>{conversationTypeName}</Badge>
          {active && (
            <Badge title={`${t('status')}: ${t('active')}`} color={BadgeColors.POSITIVE}>
              {t('active')}
            </Badge>
          )}
        </Badges>
      </TextRow>
    </ListItemDetails>
  </ListItem>
);

export default memo(MailboxListItem);
