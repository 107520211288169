import { get } from 'lodash-es';

import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type StepField from 'components/core/createModify/interfaces/stepField';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RooftopCashSegmentDetailsBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/cashPricing/steps/interfaces';
import {
  getVariablePresets,
  prepareForAutoPriceAdjustmentClearing,
  toggleAutoPriceAdjustmentField,
} from 'components/sections/createModify/rooftopPricingSegments/utils';
import { getPricingSourceOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { MultilingualToggleInputSettings } from 'components/ui/forms/shared/MultilingualToggleInput';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import type {
  RooftopCashPricingSegmentMetaQuery,
  RooftopRetailCashPricingCreateMutationVariables,
  RooftopRetailCashPricingDetailQuery,
  SelectStringOptionFragment,
} from 'store/api/graph/interfaces/types';
import { PaymentOption, PricingSource } from 'store/api/graph/interfaces/types';
import { getDefaultDisclaimerTemplate, normalizeAndFilterDisclaimerTemplates } from 'utils/disclaimerTemplateUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

type RooftopRetailCashPricingDetails = RooftopRetailCashPricingDetailQuery['item'];
class DetailsStep extends StepComponentCore<
  RooftopRetailCashPricingDetailQuery['item'],
  RooftopCashPricingSegmentMetaQuery['metadata'],
  RooftopRetailCashPricingCreateMutationVariables
> {
  constructor(
    props: StepComponentProps<
      RooftopRetailCashPricingDetails,
      RooftopCashPricingSegmentMetaQuery['metadata'],
      RooftopRetailCashPricingCreateMutationVariables
    >,
    context: CreateModifyContextInterface
  ) {
    super(props);

    const {
      tier: { activeStep, data: savedData, formData, metadata, seededData, isCreating },
    } = props;
    const {
      subContexts: { featureFlags },
    } = context;
    const data = Object.keys(savedData || {})?.length
      ? savedData
      : (formData as RooftopRetailCashPricingDetails as any); // Casting to satisfy `defineFieldValues`
    const configurationData = get(savedData, RooftopPricingSegment.CONFIGURATION) || formData?.cashConfiguration;

    const rooftop = data?.rooftopName || seededData?.rooftopName;
    const rooftopOemProgram = rooftop.bundle.features.oem || undefined;
    const disclaimerTemplates = normalizeAndFilterDisclaimerTemplates(
      metadata.mutation.rooftopRetailPricing.cashConfiguration.disclaimerTemplate,
      rooftopOemProgram
    );

    const defaultDisclaimer = rooftop?.regionCode
      ? getDefaultDisclaimerTemplate(disclaimerTemplates, rooftop.regionCode.value, rooftopOemProgram)
      : undefined;

    const pricingSourceMetadata: SelectStringOptionFragment[] =
      metadata.mutation.rooftopRetailPricing.cashConfiguration.pricingSource;

    const pricingSourceOptions = getPricingSourceOptions(
      rooftop?.bundle?.features?.oem,
      pricingSourceMetadata,
      featureFlags?.[LDFeatureFlags.oemPricingSourcesEnabled]
    );

    const hasAutoPriceAdjustment = !!data?.configuration?.autoPriceAdjustmentConfiguration;
    const hasPricingSource = data?.configuration?.pricingSource !== undefined;
    const isCustomSource = data?.configuration?.pricingSource === PricingSource.CUSTOM;

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_TAX]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX, false),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_ENABLED]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_ENABLED, true),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_DISCLAIMER_TEMPLATE]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_DISCLAIMER, defaultDisclaimer),
        settings: {
          insertOptions: disclaimerTemplates,
        } as MultilingualToggleInputSettings,
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_STARTING_PRICE_FIELD]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_PRICING_SOURCE]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_PRICING_SOURCE),
        options: pricingSourceOptions as StepFieldOptions[],
        hasSeparator: !hasPricingSource || isCustomSource,
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_INCLUDE_AFTER_TAX_REBATES, false),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !hasPricingSource || isCustomSource,
          },
        ]),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_FREIGHT]: {
        selectedValue: configurationData?.includeFreight ?? false,
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_MIN_DISPLAY_PRICE]: {
        selectedValue: data?.configuration?.minDisplayPrice?.amount,
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED]: {
        selectedValue: !!hasAutoPriceAdjustment,
        hasSeparator: isCreating || !hasAutoPriceAdjustment,
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.targetPriceField,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.label,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
      [RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.disclaimerTemplate,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
    });

    this.fields = defineFieldValues(this.fields, data);
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    if (
      stepField.queryVar === RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED
    ) {
      toggleAutoPriceAdjustmentField(PaymentOption.CASH, this.fields, Boolean(e.currentTarget.value));
    }

    super.onFieldChange(stepField, e);
  }

  onFieldSelection(
    stepField: StepField<RooftopRetailCashPricingDetailQuery['item'], RooftopCashPricingSegmentMetaQuery['metadata']>,
    value: SelectStringOptionFragment
  ): void {
    super.onFieldSelection(stepField, value);

    if (stepField.queryVar === RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_PRICING_SOURCE) {
      const pricingSourceField = getStepField<
        SelectStringOptionFragment,
        RooftopRetailCashPricingDetailQuery['item'],
        RooftopCashPricingSegmentMetaQuery['metadata']
      >(RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_PRICING_SOURCE, this.fields);

      const hasPricingSource = pricingSourceField?.selectedValue?.id !== undefined;
      const isCustomSource = pricingSourceField?.selectedValue?.id === PricingSource.CUSTOM;

      const includeAfterTaxRebateField = getStepField<
        boolean,
        RooftopRetailCashPricingDetailQuery['item'],
        RooftopCashPricingSegmentMetaQuery['metadata']
      >(RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_INCLUDE_AFTER_TAX_REBATES, this.fields);

      includeAfterTaxRebateField.displayType = setDisplayTypes([
        { type: StepFieldDisplayType.HIDDEN, active: !hasPricingSource || isCustomSource },
      ]);

      includeAfterTaxRebateField.selectedValue =
        !hasPricingSource || isCustomSource ? undefined : includeAfterTaxRebateField.selectedValue || false;

      pricingSourceField.hasSeparator = !hasPricingSource || isCustomSource;
    }
  }

  async save() {
    const {
      tier: { seededData, steps, isCreating },
    } = this.props;

    const variablePresets = isCreating ? getVariablePresets(seededData?.rooftopName?.id) : undefined;

    if (!isCreating) {
      prepareForAutoPriceAdjustmentClearing(
        getStepField<boolean | null>(
          RooftopCashSegmentDetailsBuilderFields.CASH_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED,
          this.fields
        )
      );
    }

    const result = await super.save(variablePresets);

    if (result) {
      // Enabling other steps for edit/navigation
      for (const step of steps!) {
        step.isEnabled = true;
      }
    }

    return result;
  }
}

export default DetailsStep;
