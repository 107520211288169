import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { get, isEmpty, isFunction } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import ArchiveBanner from 'components/ui/details/ArchiveBanner';
import ArchiveDialog, { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import ArrowLeftIcon from 'components/ui/icons/ArrowLeftIcon';
import { HeaderSection } from 'components/ui/layouts/CardLayout';
import Loader from 'components/ui/loading/Loader';
import MenuButton, { MenuButtonType, MenuPosition } from 'components/ui/menus/MenuButton';
import { Clickable } from 'components/ui/shared/Button';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import Scrollable from 'components/ui/shared/Scrollable';
import ArchiveConfigSettings from 'containers/ArchiveConfigSettings';
import { DetailsTabs, getDetailsTitle } from 'containers/ItemDetailsContainer';
import NestedViewDetailRenderer from 'containers/nestedView/NestedViewDetailRenderer';
import NestedViewListRenderer from 'containers/nestedView/NestedViewListRenderer';
import { Pagination } from 'enums/pagination';
import { TabType } from 'enums/tabType';
import { ElementTestId } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import useEntityView from 'hooks/useEntityView';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePrevious } from 'hooks/usePrevious';
import type { SortDirection } from 'store/api/graph/interfaces/types';
import type { CustomItemResponseType } from 'store/api/graph/responses/responseTypes';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { NEUTRAL_0 } from 'styles/tokens';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { LDFeatureFlags } from 'utils/featureFlagUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import { generateUrlPathFromListFilters, openNewTab } from 'utils/urlUtils';

import { SecondaryViewType } from './interfaces';
import type { SecondaryDetailViewProps, SecondaryListTabViewProps } from './NestedViewSettings';
import { NestedViewDetailsSettings } from './NestedViewSettings';

const { t } = translate;

const ClickableNestedViewHeaderName = styled(Clickable)`
  margin-right: auto;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${NEUTRAL_0};
  height: 100%;
  position: relative;
`;

const DetailsContainer = styled.div<{ width: string }>`
  height: 100%;

  > * {
    flex-shrink: 0;
  }
`;

const NestedHeader = styled(HeaderSection)`
  flex-direction: row;
  flex-shrink: 0;
  padding: 0 17px 0 15px;
  align-items: center;
  ${Clickable} {
    line-height: 0;
  }
`;

const NestedSearchInputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${DIVIDER};
  padding: 16px 10px;
`;

const NestedViewContainer = () => {
  const {
    viewItems: [viewItem],
    back,
    activeListTab,
    setActiveListTab,
    activeDetailsTab,
    setActiveDetailsTab,
    pagination,
    setPagination,
    keyword,
    setKeyword,
  } = useNestedView();
  const { builderConfig } = useBuilderConfig();
  const basePath = useMemo(() => NestedViewDetailsSettings[viewItem.entityType]?.(viewItem).base, [viewItem]);
  const { generatePath } = useEntityView(basePath);
  const { hasPermissions, user } = useUser();
  const flags = useFlags();

  const listConfig = viewItem.config as SecondaryListTabViewProps;
  const detailsConfig = viewItem.config as SecondaryDetailViewProps;

  // State
  const [isUpdating, setIsUpdating] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [data, setData] = useState<any>();

  useMountEffect(() => {
    // If there is no persisted pagination, default to the initial pagination (page 1)
    if (!pagination) {
      setPagination(
        listConfig?.tabs?.map(item => ({
          first: Pagination.LIST_LENGTH,
          after: undefined,
          tabId: item.tabId,
        }))
      );
    }

    // A nested view configuration might have a default keyword set, if one is available then set it
    if (viewItem?.queryVars?.keyword) {
      setKeyword(viewItem.queryVars.keyword as string);
    }
  });

  const activeListConfig = useMemo(
    () => (activeListTab ? listConfig?.tabs?.find(item => item.tabId === activeListTab) : undefined),
    [listConfig, activeListTab]
  );

  const availableListConfigTabsBasedOnFeatureBundles = useMemo(
    () =>
      listConfig?.tabs?.filter(tabItem => {
        /*
         * Filter out any tabs that do not have a specified feature enabled
         */
        if (tabItem.requiredFeatureBundle && viewItem?.seededData?.rooftop) {
          return isFeatureEnabledForRooftop({
            rooftop: viewItem.seededData.rooftop,
            feature: tabItem.requiredFeatureBundle,
            featureFlagOn: flags[LDFeatureFlags.rooftopPackageEnabled],
          });
        }
        return tabItem;
      }),
    [viewItem, listConfig, flags]
  );

  const previousAvailableListConfigTabsBasedOnFeatureBundles = usePrevious(
    availableListConfigTabsBasedOnFeatureBundles
  );

  useEffect(() => {
    /**
     * Return to the active tab if an active tab was already selected
     */
    if (activeListTab && !previousAvailableListConfigTabsBasedOnFeatureBundles) {
      return;
    }

    /*
     * Once the available list configurations have been filtered based on available feature bundles, set the active
     * list opened to the first one.
     */
    if (
      !activeListTab ||
      availableListConfigTabsBasedOnFeatureBundles?.length !==
        previousAvailableListConfigTabsBasedOnFeatureBundles?.length
    ) {
      setActiveListTab(availableListConfigTabsBasedOnFeatureBundles?.[0]?.tabId);
    }
  }, [
    activeListTab,
    availableListConfigTabsBasedOnFeatureBundles,
    previousAvailableListConfigTabsBasedOnFeatureBundles,
    setActiveListTab,
  ]);

  // Context derived data
  const MenuItems = detailsConfig?.renderDataSettings?.menuItems;
  const updateArchiveStatusConfig = ArchiveConfigSettings[viewItem.entityType];

  const viewTitle = useMemo(
    () =>
      !!data && viewItem?.type === SecondaryViewType.DETAILS
        ? NestedViewDetailsSettings[viewItem.entityType]?.(data).title
        : viewItem.title,
    [viewItem, data]
  );

  const detailsTitle = useMemo(() => {
    const entityLabel =
      !!data && viewItem?.type === SecondaryViewType.DETAILS
        ? NestedViewDetailsSettings[viewItem.entityType]?.(data).title
        : viewItem.title;
    return getDetailsTitle(data, entityLabel);
  }, [data, viewItem]);

  // Permissions
  const hasEditPermission = useMemo(() => {
    const builder = isFunction(detailsConfig.editBuilder) ? detailsConfig.editBuilder(data) : detailsConfig.editBuilder;

    return builder && builderConfig[builder] ? hasPermissions(builderConfig[builder].requiredPermissions) : false;
  }, [detailsConfig, data, builderConfig, hasPermissions]);

  const detailsRenderData = useMemo(() => {
    if (isFunction(detailsConfig?.renderData)) {
      return detailsConfig.renderData(data) as ItemTab[];
    } else if (detailsConfig?.renderData?.length) {
      return (detailsConfig.renderData as ItemTab[]).filter(
        ({ requiredPermissions }) => !requiredPermissions || hasPermissions(requiredPermissions)
      );
    }
  }, [detailsConfig, data, hasPermissions]);

  const onKeywordChange = useCallback(
    keyword => {
      setKeyword(keyword);

      // Reset the pagination for the current list
      setPagination(
        pagination?.map(item =>
          item.tabId === activeListTab
            ? { first: Pagination.LIST_LENGTH, after: undefined, tabId: activeListTab }
            : item
        )
      );
    },
    [activeListTab, pagination, setPagination, setKeyword]
  );

  const onPaginationChange = useCallback(
    pageInfo => {
      setPagination(
        pagination?.map(item => (item.tabId === activeListTab ? { ...pageInfo, tabId: activeListTab } : item))
      );
    },
    [activeListTab, pagination, setPagination]
  );

  const onTabChange = useCallback(
    tab => {
      setActiveDetailsTab(tab);
    },
    [setActiveDetailsTab]
  );

  const onListTabChange = useCallback(
    tab => {
      setActiveListTab(tab);
    },
    [setActiveListTab]
  );

  const updateArchiveStatus = useCallback(
    () => setIsArchiveDialogOpen(!isArchiveDialogOpen),
    [setIsArchiveDialogOpen, isArchiveDialogOpen]
  );
  const onUpdateArchiveStatusCallback = authorizedCallback({
    cb: updateArchiveStatus,
    isAuth: updateArchiveStatusConfig?.canUpdateArchiveStatus(hasEditPermission, user, data),
  });

  // Link to full item details or list view
  const itemUrl = useMemo(() => {
    if (!basePath) {
      return;
    }

    if (viewItem.type === SecondaryViewType.LIST && !isEmpty(data) && activeListTab) {
      return generateUrlPathFromListFilters(
        generatePath(),
        activeListConfig?.queryVars?.filter as Record<string, any>,
        activeListConfig?.queryVars?.sort as SortDirection,
        keyword
      );
    } else {
      const itemPathname = viewItem?.queryVars?.id;
      if (typeof itemPathname === 'string') {
        return generatePath(itemPathname);
      }

      return;
    }
  }, [
    basePath,
    viewItem.type,
    viewItem?.queryVars?.id,
    data,
    activeListTab,
    activeListConfig?.queryVars?.filter,
    activeListConfig?.queryVars?.sort,
    keyword,
    generatePath,
  ]);

  useLayoutEffect(() => {
    setActiveDetailsTab(
      detailsConfig?.renderDataSettings?.currentTab || detailsRenderData?.[0]?.tabId || TabType.DETAILS
    );
  }, [detailsConfig, detailsRenderData, setActiveDetailsTab]);

  const clickableNestedViewHeaderNameOnClick = useCallback(() => {
    if (!itemUrl) {
      return;
    }

    openNewTab(itemUrl);
  }, [itemUrl]);

  const hasItemAccess = (data as CustomItemResponseType)?.hasItemAccess;
  const customBanner = detailsConfig.renderDataSettings?.customBanner;
  const bannerToRender = customBanner ? customBanner(data) : undefined;

  return (
    <Container>
      <NestedHeader>
        <Clickable data-testid={ElementTestId.NESTED_VIEW_BACK_BUTTON} onClick={back} css="margin-right: 17px;">
          <ArrowLeftIcon
            css={css`
              color: ${BODY_TEXT};
            `}
          />
        </Clickable>
        <ClickableNestedViewHeaderName
          disabled={!itemUrl || hasItemAccess === false}
          onClick={clickableNestedViewHeaderNameOnClick}
          data-testid={ElementTestId.NESTED_VIEW_CLICKABLE_HEADER}
        >
          <PrimaryText>{detailsTitle}</PrimaryText>
        </ClickableNestedViewHeaderName>
        {!!MenuItems && hasItemAccess !== false && (
          /*
           * Because visibility is determined by the `MenuButton` ref's nextChildren,
           *  internal `menuItems` updates may not always update `MenuButton` state
           */
          <MenuButton
            key={`menuButton-${data?.id}-${isUpdating}`}
            type={MenuButtonType.MORE}
            position={MenuPosition.TOP_RIGHT}
            testId={ElementTestId.NESTED_VIEW_MEATBALL_MENU_BUTTON_CONTAINER}
          >
            <MenuItems
              item={data}
              setIsDetailsUpdating={setIsUpdating}
              onUpdateArchiveStatus={onUpdateArchiveStatusCallback}
            />
          </MenuButton>
        )}
      </NestedHeader>
      <>
        {isItemArchived(data) && hasItemAccess !== false ? (
          <ArchiveBanner onUpdateArchiveStatus={onUpdateArchiveStatusCallback} />
        ) : (
          bannerToRender
        )}
        {viewItem?.type === SecondaryViewType.LIST && !activeListConfig?.hideSearchInput && (
          <NestedSearchInputContainer data-testid={ElementTestId.NESTED_VIEW_SEARCH_INPUT}>
            <SearchInput placeholder={t('search_x', [viewTitle])} defaultValue={keyword} onChange={onKeywordChange} />
          </NestedSearchInputContainer>
        )}
        {!!detailsRenderData && detailsRenderData?.length > 1 && (
          <DetailsTabs
            entityType={viewItem.entityType}
            tabs={detailsRenderData}
            activeTab={activeDetailsTab}
            setActiveTab={onTabChange}
          />
        )}
        {!!activeListTab &&
          availableListConfigTabsBasedOnFeatureBundles &&
          availableListConfigTabsBasedOnFeatureBundles?.length > 1 &&
          !!data && (
            <DetailsTabs
              entityType={viewItem.entityType}
              tabs={availableListConfigTabsBasedOnFeatureBundles.map(item => ({
                tabId: item.tabId,
                label: item.labelTranslationKey,
                labelCount: get(data, `${item.tabId}Count`),
              }))}
              activeTab={activeListTab}
              setActiveTab={onListTabChange}
            />
          )}
        <Scrollable>
          {isUpdating ? (
            <Loader css="background: transparent;" />
          ) : (
            <DetailsContainer data-testid={ElementTestId.NESTED_VIEW_CONTENT} width={viewItem?.width}>
              {viewItem?.type === SecondaryViewType.LIST && activeListConfig && (
                <NestedViewListRenderer
                  configuration={activeListConfig}
                  onDataLoaded={setData}
                  onPaginationChange={onPaginationChange}
                  pagination={pagination?.find(item => item.tabId === activeListTab)}
                  keyword={keyword}
                />
              )}
              {viewItem?.type === SecondaryViewType.DETAILS && (
                <NestedViewDetailRenderer
                  configuration={detailsConfig}
                  renderElement={
                    (
                      (detailsRenderData as ItemTab[])?.find(({ tabId }) => tabId === activeDetailsTab) ||
                      detailsRenderData?.[0]
                    )?.component
                  }
                  onDataLoaded={setData}
                />
              )}
            </DetailsContainer>
          )}
        </Scrollable>
      </>
      {!!updateArchiveStatusConfig && data && (
        <ArchiveDialog
          entityTypeLabel={translate.t(updateArchiveStatusConfig.entityTypeLabel)}
          item={data}
          mutation={updateArchiveStatusConfig.mutation}
          isDialogOpen={isArchiveDialogOpen}
          setIsDialogOpen={setIsArchiveDialogOpen}
          refetchQueryNames={updateArchiveStatusConfig.refetchQueries}
          customMessage={updateArchiveStatusConfig.customMessage}
        />
      )}
    </Container>
  );
};

export default NestedViewContainer;
