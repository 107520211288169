import styled from 'styled-components/macro';

import { DIVIDER } from 'styles/color';
import { NEUTRAL_0 } from 'styles/tokens';

export const EntityContainer = styled.div<{ ref?: any }>`
  background: ${DIVIDER};
  display: flex;
  flex-direction: row;
  overflow: auto;
  position: relative;
  height: 100%;

  > * {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

export const Column = styled.div`
  background: ${NEUTRAL_0};
  border-radius: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  position: relative;

  :empty {
    display: none;
  }

  :first-child {
    border-top-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
  }
`;
