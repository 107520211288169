import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { Location, LocationInput } from 'store/api/graph/interfaces/types';
import { formatLocationData } from 'utils/formatUtils';

import Map from '../maps/Map';

import type { ListItemProps } from './interfaces/ListItemProps';

const MapIconContainer = styled(Map)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
`;

interface Props extends ListItemProps, Location {}

const LocationListItem = ({ suffixIcon, listItemType, ...props }: Props) => {
  const { address, city, regionCode, countryCode, latitude, longitude } =
    formatLocationData(props, true) || ({} as Location | LocationInput);

  return (
    <ListItem listItemType={listItemType} suffixIcon={suffixIcon}>
      {longitude && latitude && (
        <MapIconContainer
          markerScale={0.45}
          zoom={7}
          locations={[{ latitude, longitude }]}
          isClickEnabled={false}
          css="flex-shrink: 0;"
        />
      )}
      <ListItemDetails>
        <TextRow>
          <PrimaryText title={address || ''}>{address}</PrimaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{[city, regionCode, countryCode].filter(Boolean).join(', ')}</SecondaryText>
        </TextRow>
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(LocationListItem);
