import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum User {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  CELL_NUMBER = 'cellNumber',
  LEADS_COUNT = 'leadsCount',
  CONVERSATIONS_COUNT = 'conversationsCount',
  WALK_IN_COUNT = 'walkInCount',
  TEST_DRIVE_COUNT = 'testDriveCount',
  PHONE_CALL_COUNT = 'phoneCallCount',
  ROOFTOP_NAME = 'rooftopName',
  WHITE_LABEL_NAME = 'whiteLabelName',
  GROUP_NAME = 'groupName',
  SCOPE_NAME = 'scopeName',
  ROLE_NAME = 'roleName',
  LOCALE = 'locale',
  ACTIVE = 'active',
  SUBSCRIBED_EMAIL = 'subscribedEmail',
  LAST_ACTIVE = 'lastActive',
  CREATED = 'created',
  CREATED_BY_NAME = 'createdByName',
  TITLE = 'title',
  UPDATED = 'updated',
}

const UserConfig: TableSettingsType = {
  [User.FIRST_NAME]: { label: 'first_name', type: FieldDataType.DEFAULT },
  [User.LAST_NAME]: { label: 'last_name', type: FieldDataType.DEFAULT },
  [User.ROOFTOP_NAME]: { label: 'rooftops', type: FieldDataType.BADGE_LIST },
  [User.EMAIL]: { label: 'email_one', type: FieldDataType.DEFAULT },
  [User.CELL_NUMBER]: { label: 'cellphone_number', type: FieldDataType.DEFAULT },
  [User.PHONE_NUMBER]: { label: 'phone_number_one', type: FieldDataType.DEFAULT },
  [User.CONVERSATIONS_COUNT]: { label: 'conversations', type: FieldDataType.DEFAULT },
  [User.WALK_IN_COUNT]: { label: 'walk_ins', type: FieldDataType.DEFAULT },
  [User.TEST_DRIVE_COUNT]: { label: 'test_drives', type: FieldDataType.DEFAULT },
  [User.PHONE_CALL_COUNT]: { label: 'phone_calls', type: FieldDataType.DEFAULT },
  [User.LEADS_COUNT]: { label: 'lead_activity_other', type: FieldDataType.DEFAULT },
  [User.SCOPE_NAME]: { label: 'scope', type: FieldDataType.ENUM },
  [User.TITLE]: { label: 'title', type: FieldDataType.DEFAULT },

  // Directive derived columns
  [User.WHITE_LABEL_NAME]: { label: 'white_label', type: FieldDataType.NAME },
  [User.GROUP_NAME]: { label: 'group', type: FieldDataType.NAME },
  [User.ROLE_NAME]: { label: 'role', type: FieldDataType.NAME },
  [User.LOCALE]: { label: 'locale', type: FieldDataType.DISPLAY_NAME },
  [User.ACTIVE]: { label: 'active', type: FieldDataType.BOOLEAN },
  [User.SUBSCRIBED_EMAIL]: { label: 'receive_email_notifications', type: FieldDataType.BOOLEAN },
  [User.LAST_ACTIVE]: { label: 'last_active', type: FieldDataType.DAY_TIME },
  [User.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [User.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
};

export const UserSettings = translateFields(UserConfig);
