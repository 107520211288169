import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { conversationDetailsQuery } from 'containers/conversations/ConversationsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const ConversationQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const response = await client.query({
      query: conversationDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['UserDetailsContainerQuery'],
};

export default ConversationQueryMethods;
