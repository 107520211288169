import { gql } from '@apollo/client';

import { selectIntOption, selectStringOption } from 'store/api/graph/fragments/selectOption';

export const vehicleAttributesMetadata = gql`
  fragment VehicleAttributesMetadataFragment on VehicleAttributesMetadata {
    bodyType {
      ...SelectStringOptionFragment
    }
    cabType {
      ...SelectStringOptionFragment
    }
    exteriorColor {
      ...SelectStringOptionFragment
    }
    interiorColor {
      ...SelectStringOptionFragment
    }
    numberOfDoors {
      ...SelectIntOptionFragment
    }
    numberOfPassengers {
      ...SelectIntOptionFragment
    }
    transmission {
      ...SelectStringOptionFragment
    }
    driveTrain {
      ...SelectStringOptionFragment
    }
    cylinders {
      ...SelectIntOptionFragment
    }
    fuelType {
      ...SelectStringOptionFragment
    }
    options {
      ...SelectStringOptionFragment
    }
  }
  ${selectStringOption}
  ${selectIntOption}
`;
