import { css } from 'styled-components/macro';

import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { AppointmentResponseType } from 'store/api/graph/responses/responseTypes';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: AppointmentResponseType;
}

const AppointmentMenuItems = ({ item, onUpdateArchiveStatus }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { hasPermissions } = useUser();
  const { toggleTier } = useCreateModify();
  const isArchived = isItemArchived(item);
  const { id } = item || {};

  // onClick callbacks
  const onModifyClicked = toggleTier.bind(null, CreateModifyTiers.TIER_0, {
    tierId: CreateModifyTiers.TIER_0,
    type: BuilderType.APPOINTMENT_MODIFY,
    entityType: EntityType.APPOINTMENT,
    title: t('modify_appointment'),
    isCreating: false,
    itemId: id,
  });

  const menuItemsConfig: MenuItemConfig[] = [
    {
      label: t('modify'),
      onClick: onModifyClicked,
      disabled: !hasPermissions(builderConfig[BuilderType.APPOINTMENT_MODIFY].requiredPermissions),
    },
    {
      label: t(isArchived ? 'unarchive' : 'archive'),
      onClick: () => onUpdateArchiveStatus?.(),
      isArchivable: false,
      disabled: !onUpdateArchiveStatus,
      titleCss: css`
        color: ${RED_500};
      `,
    },
  ];

  return <MenuItems items={menuItemsConfig} isArchived={isArchived} />;
};

export default AppointmentMenuItems;
