import { User } from 'enums/columns/user';
import { RoutePath } from 'enums/routePath';
import {
  InventoryItemType,
  LeadActivityStatus,
  LeadStatus,
  RetailItemStatus,
  SortDirection,
  TaskStatus,
  TradeInItemStatus,
  WebsiteStatus,
} from 'store/api/graph/interfaces/types';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';

const DefaultListSettings = {
  [RoutePath.APPOINTMENTS]: {
    filter: {
      date: {
        get gte() {
          return getFormattedDateTimeString(getDateTime().startOf('day'));
        },
      },
      archived: false,
    },
  },
  [RoutePath.CONVERSATIONS]: {
    filter: { archivedByMe: false },
    sort: [{ id: 'lastItemCreated', sortDirection: SortDirection.DESCENDING }],
  },
  [RoutePath.LEADS]: {
    filter: {
      archived: false,
      status: [LeadStatus.ACTIVE, LeadStatus.DORMANT, LeadStatus.NEW],
      spam: false,
    },
  },
  [RoutePath.TRADE_IN_ITEMS]: {
    filter: {
      type: InventoryItemType.VEHICLE,
      archived: false,
      status: [TradeInItemStatus.DRAFT, TradeInItemStatus.PENDING],
    },
  },
  [RoutePath.RETAIL_ITEMS]: {
    filter: {
      type: InventoryItemType.VEHICLE,
      status: [RetailItemStatus.FOR_SALE, RetailItemStatus.DRAFT],
      archived: false,
    },
  },
  [RoutePath.LEAD_ACTIVITIES]: { filter: { archived: false, status: LeadActivityStatus.NEW, leadSpam: false } },
  [RoutePath.ROOFTOPS]: {
    filter: { active: true },
  },
  [RoutePath.TASKS]: {
    filter: {
      archived: false,
      status: [TaskStatus.NOT_STARTED, TaskStatus.IN_PROGRESS],
    },
  },
  [RoutePath.USERS]: {
    filter: { active: true },
    sort: [
      { id: User.FIRST_NAME, sortDirection: SortDirection.ASCENDING },
      { id: User.LAST_NAME, sortDirection: SortDirection.ASCENDING },
    ],
  },
  [RoutePath.WEBSITES]: {
    filter: { status: [WebsiteStatus.ACTIVE, WebsiteStatus.DRAFT] },
  },
};

export default DefaultListSettings;
