import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import TagStep, { defaultFields as TagStepFields } from './steps/TagStep';
import { tagCreate } from './TagCreateModifyQuery';

// This specific tag builder is gated at the substep level
const TagBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.TAGS_CREATE]: {
    steps: [{ id: 'TAGS_CREATE', step: TagStep, stepName: 'tags', isEnabled: true, fields: TagStepFields }],
    legend: null,
    mutations: {
      create: tagCreate,
    },
    requiredPermissions: [{ resource: ResourceType.TAGS, level: AccessLevel.FULL }],
    resourceType: ResourceType.TAGS,
  },
};

export default TagBuilders;
