import {
  rooftopRetailFinancePricingCreate,
  rooftopRetailFinancePricingModify,
} from 'components/sections/createModify/rooftopPricingSegments/financePricing/RooftopFinanceSegmentCreateModifyQuery';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';

class FiltersStep extends RetailFilterStep {
  async save(): Promise<boolean> {
    const {
      tier: { isCreating },
    } = this.props;

    return super.save(isCreating ? rooftopRetailFinancePricingCreate : rooftopRetailFinancePricingModify);
  }
}

export default FiltersStep;
