import { gql } from '@apollo/client';

import { tradeInAppraisalExternal } from 'store/api/graph/fragments/tradeInAppraisalExternal';
import { tradeInAppraisals } from 'store/api/graph/fragments/tradeInAppraisals';
import { tradeInGuaranteedTradeValue } from 'store/api/graph/fragments/tradeInGuaranteedTradeValue';

export const tradeInAppraisalExternalSendInvite = gql`
  mutation TradeInAppraisalExternalSendInviteMutation($id: ID!, $contactId: [ID!]!) {
    data: tradeInAppraisalExternalSendInvite(tradeInItemId: $id, contactId: $contactId) {
      id
      guaranteedValue {
        ...TradeInGuaranteedTradeValueFragment
      }
      appraisals {
        ...TradeInAppraisalsFragment
      }
      pendingAppraisals {
        ...TradeInAppraisalExternalFragment
      }
    }
  }
  ${tradeInGuaranteedTradeValue}
  ${tradeInAppraisals}
  ${tradeInAppraisalExternal}
`;
