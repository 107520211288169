import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Lead, LeadSettings } from 'enums/columns/lead';
import { ImageType } from 'enums/imageType';
import { ItemsColumn } from 'enums/itemsColumn';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { PhotoHeaders, SelectHeaders } from './staticTableConfig';

export const leadsDefaultSortOrder = [];

const countColumnSettings = {
  enabled: false,
  styles: 'justify-content: center;',
};

export const LEADS_BASE = Object.freeze<TableCellData[]>([
  // Defaults
  getCellDataByItemColumnType(ItemsColumn.SELECT, null, {
    ...SelectHeaders,
  }),

  getCellDataByItemColumnType(ItemsColumn.PHOTOS, null, {
    ...PhotoHeaders,
    content: ImageType.USER,
  }),

  getCellDataByItemColumnType(Lead.CONTACT, LeadSettings, { enabled: false, width: '20%' }),
  getCellDataByItemColumnType(Lead.FIRST_NAME, LeadSettings, { enabled: true, width: '15%' }),
  getCellDataByItemColumnType(Lead.LAST_NAME, LeadSettings, { enabled: true, width: '15%' }),
  getCellDataByItemColumnType(Lead.EMAIL, LeadSettings, { enabled: true, width: '20%' }),
  getCellDataByItemColumnType(Lead.GROUP_NAME, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.PHONE_NUMBER, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.PAYMENT_OPTIONS, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.TAG_NAME, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.RETAIL_ITEMS_OF_INTEREST_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.TRADE_IN_ITEMS_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.APPOINTMENTS_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.CONVERSATIONS_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.WALK_IN_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.TEST_DRIVE_COUNT, LeadSettings, countColumnSettings),
  getCellDataByItemColumnType(Lead.ARCHIVED, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.SUBSCRIBED_EMAIL, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.SUBSCRIBED_PROMOTIONS, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.CREATED_BY_NAME, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.CREATED, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.UPDATED, LeadSettings, { enabled: false }),
]);

export const LEADS = Object.freeze<TableCellData[]>([
  // Defaults
  ...LEADS_BASE,

  getCellDataByItemColumnType(Lead.STATUS, LeadSettings, { width: '10%' }),
  getCellDataByItemColumnType(Lead.SUMMARY, LeadSettings, { width: '20%' }),
  getCellDataByItemColumnType(Lead.LAST_ACTIVE, LeadSettings, { width: '10%' }),
  getCellDataByItemColumnType(Lead.ROOFTOP_NAME, LeadSettings, { width: '10%' }),
  getCellDataByItemColumnType(Lead.ASSIGNED_TO_NAME, LeadSettings, { width: '10%' }),
]);

export const LEADS_LIMITED = Object.freeze<TableCellData[]>([
  ...LEADS_BASE,

  getCellDataByItemColumnType(Lead.STATUS, LeadSettings, { width: '10%' }),
  getCellDataByItemColumnType(Lead.SUMMARY, LeadSettings, { width: '30%' }),
  getCellDataByItemColumnType(Lead.LAST_ACTIVE, LeadSettings, { width: '10%' }),
  getCellDataByItemColumnType(Lead.ASSIGNED_TO_NAME, LeadSettings, { enabled: false }),
  getCellDataByItemColumnType(Lead.ROOFTOP_NAME, LeadSettings, { enabled: false }),
]);
