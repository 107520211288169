import type { ComponentType } from 'react';

import useCopyTextToClipboard from 'hooks/useCopyText';
import { withTooltip } from 'hooks/withTooltip';
import { BODY_TEXT } from 'styles/color';
import { translate } from 'utils/intlUtils';
import type { MaybeRenderProp } from 'utils/renderingUtils';
import { runIfFun } from 'utils/renderingUtils';

import CopyIcon from '../icons/CopyIcon';

import { Clickable } from './Button';
import { SecondaryArrowPosition, TooltipStyle } from './Tooltip';

// The duration before the tooltip automatically disappears (milliseconds)
const COPY_BUTTON_TOOLTIP_TIMEOUT = 2000;

const CopyIconWithTooltip = withTooltip(CopyIcon);

interface CopyButtonRenderFunctionProps {
  /** A flag set to true for a preset timeout duration when click event is fired */
  isCopied: boolean;
}
interface CopyButtonProps {
  /** The text to copy to the clipboard */
  textToCopy: string;
  /** The children to render inside the copy button */
  children?: MaybeRenderProp<CopyButtonRenderFunctionProps>;
  /** The component to use as the copy button */
  renderElement?: ComponentType<any>;
  /** The id for testing */
  testId?: string;
}

export const CopyButton = ({ textToCopy, children, renderElement: RenderElement, testId }: CopyButtonProps) => {
  const { isCopied, copy } = useCopyTextToClipboard({ textToCopy, timeout: COPY_BUTTON_TOOLTIP_TIMEOUT });
  const ButtonComponent = RenderElement || Clickable;

  return (
    <ButtonComponent css="position: relative" onClick={copy} data-testid={testId}>
      {runIfFun<any, CopyButtonRenderFunctionProps>(children, { isCopied }) || (
        <CopyIconWithTooltip
          width={18}
          height={18}
          title={translate.t('copy')}
          style={{ color: BODY_TEXT, verticalAlign: 'middle' }}
          tooltip={{
            shouldShow: isCopied,
            styleVariant: TooltipStyle.CONDENSED,
            isAnimated: true,
            arrowPosition: { secondary: SecondaryArrowPosition.CENTER },
            margin: { x: 8, y: 0 },
            children: translate.t('copied'),
            width: 100,
            wrapComponent: true,
          }}
        />
      )}
    </ButtonComponent>
  );
};
