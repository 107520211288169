import styled from 'styled-components/macro';

import ErrorIcon from 'components/ui/icons/ErrorIcon';
import type { ArrowPosition } from 'components/ui/shared/Tooltip';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import TooltipButton from 'components/ui/shared/TooltipButton';
import { withTooltip } from 'hooks/withTooltip';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import type { RetailItemResponseType } from 'store/api/graph/responses/responseTypes';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';
import { getPaymentOptionWithPriceBelowMin } from 'utils/inventoryItemUtils';

import RetailItemPurchasePriceDisplay from './RetailItemPurchasePriceDisplay';

const ToggleButtonIconContainer = styled.div`
  color: ${RED_500};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
`;

const ErrorIconTooltip = withTooltip(() => (
  <ToggleButtonIconContainer>
    <ErrorIcon />
  </ToggleButtonIconContainer>
));

interface RetailItemPurchasePriceSectionProps {
  /** Retail Item to render section for */
  retailItem: RetailItemResponseType;
  /** Whether or not a warning tooltip should be displayed if the purchase price is below the minDisplayPrice */
  showWarningTooltip?: boolean;
  /** The position of the tooltip in relation to the icon */
  tooltipPosition?: ArrowPosition;
}

const RetailItemPurchasePriceSection = ({
  retailItem,
  showWarningTooltip: showTooltip = false,
  tooltipPosition = { primary: PrimaryArrowPosition.RIGHT, secondary: SecondaryArrowPosition.CENTER },
}: RetailItemPurchasePriceSectionProps) => {
  const purchasePrice = retailItem.cashRetailPricing?.purchasePrice;
  const paymentOptionWithPriceBelowMin = getPaymentOptionWithPriceBelowMin(retailItem);
  const formattedPrice = purchasePrice?.formattedAmountRounded || '';

  return (
    <>
      {showTooltip && paymentOptionWithPriceBelowMin === PaymentOption.CASH && (
        <TooltipButton
          icon={ErrorIconTooltip}
          tooltip={{
            arrowPosition: tooltipPosition,
            margin: { x: 0, y: 8 },
          }}
        >
          {translate.t('minimum_display_price_warning_tooltip')}
        </TooltipButton>
      )}
      <RetailItemPurchasePriceDisplay
        purchasePriceBelowMin={paymentOptionWithPriceBelowMin === PaymentOption.CASH}
        purchasePriceFormatted={formattedPrice}
      />
    </>
  );
};

export default RetailItemPurchasePriceSection;
