import { get, isNil } from 'lodash-es';

import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type Tier from 'components/core/createModify/interfaces/tier';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RetailItemPriceAdjustmentBuilderFields } from 'components/sections/createModify/retailItemPriceAdjustment/steps/interfaces';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { RetailAdjustmentAmountAdjustmentType, RetailAdjustmentAmountType } from 'enums/retailBulkAdjustment';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import {
  getFormattedFixedPrice,
  getFormattedPercentagePrice,
  getPaymentOptions,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
  setFixedAndPercentageValuesFromToggleFieldState,
} from 'utils/formatting/createModifyFormatUtils';
import type { Locale } from 'utils/intlUtils';
import { getMultilingualValueFromLocale } from 'utils/intlUtils';

// Data that can be seeded to a price adjustment builder (from the retail pricing builder)
export interface PriceAdjustmentSeedData {
  // The pre-seeded label value for this new adjustment
  seedLabelString: string;
  // The payment type this adjustment will be for (Cash, Finance, Lease)
  paymentOption: PaymentOption;
  // All available payment types for this vehicle (Cash, Finance, Lease)
  availablePaymentOptions: PaymentOption[];
}

export const seedPriceAdjustmentStep = (seedData: PriceAdjustmentSeedData, { data: { id } }: Tier) => ({
  ...seedData,
  retailItemId: id,
});

class RetailItemPriceAdjustmentStep extends StepComponentCore {
  constructor(props, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { data, activeStep, seededData, isCreating },
    } = props;

    const {
      subContexts: {
        userContext: {
          user: { locale },
        },
      },
    } = context;

    const multiLingualValue = getMultilingualValueFromLocale(locale.languageTag as Locale);
    const paymentOptions = new Set([...(data?.paymentOptions || []), seededData?.paymentOption]);
    const isFee = Number(get(data, `fixed.amount`, get(data, `percentage`, 0))) >= 0;

    const amountType = isNil(data.fixed) ? RetailAdjustmentAmountType.PERCENTAGE : RetailAdjustmentAmountType.FIXED;
    const amountValue = isNil(data.fixed)
      ? getFormattedPercentagePrice(data.percentage)
      : getFormattedFixedPrice(data.fixed?.amount);

    // If this is a new adjustment, pre-seed the multilingual label field with search string (if available)
    const newLabel = seededData?.seedLabelString
      ? { [multiLingualValue]: seededData?.seedLabelString, __typename: 'MultilingualString' }
      : null;

    this.fields = objectToStepFieldArray(activeStep?.fields, {
      [RetailItemPriceAdjustmentBuilderFields.INCLUDE_CASH]: {
        selectedValue: paymentOptions.has(PaymentOption.CASH),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !seededData?.availablePaymentOptions?.includes(PaymentOption.CASH),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPriceAdjustmentBuilderFields.INCLUDE_FINANCE]: {
        selectedValue: paymentOptions.has(PaymentOption.FINANCE),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !seededData?.availablePaymentOptions?.includes(PaymentOption.FINANCE),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPriceAdjustmentBuilderFields.INCLUDE_LEASE]: {
        selectedValue: paymentOptions.has(PaymentOption.LEASE),
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: !seededData?.availablePaymentOptions?.includes(PaymentOption.LEASE),
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: true,
          },
        ]),
      },
      [RetailItemPriceAdjustmentBuilderFields.DISCLAIMER]: {
        selectedValue: isCreating ? null : data?.disclaimer,
      },
      [RetailItemPriceAdjustmentBuilderFields.LABEL]: {
        selectedValue: isCreating ? newLabel : data?.label,
      },
      [RetailItemPriceAdjustmentBuilderFields.AMOUNT]: {
        selectedValue: isCreating ? { id: RetailAdjustmentAmountType.FIXED } : { id: amountType, value: amountValue },
      },
      [RetailItemPriceAdjustmentBuilderFields.AMOUNT_PERCENTAGE]: {
        selectedValue: !isCreating && amountType === RetailAdjustmentAmountType.PERCENTAGE ? amountValue : null,
      },
      [RetailItemPriceAdjustmentBuilderFields.AMOUNT_FIXED]: {
        selectedValue: !isCreating && amountType === RetailAdjustmentAmountType.FIXED ? amountValue : null,
      },
      [RetailItemPriceAdjustmentBuilderFields.AMOUNT_ADJUSTMENT_TYPE]: {
        selectedValue:
          !isCreating && isFee
            ? RetailAdjustmentAmountAdjustmentType.FEE
            : RetailAdjustmentAmountAdjustmentType.DISCOUNT,
      },
    });
  }

  async save() {
    const {
      tier: { seededData, isCreating },
    } = this.props;

    const paymentOptions = getPaymentOptions(
      getStepField(RetailItemPriceAdjustmentBuilderFields.INCLUDE_CASH, this.fields),
      getStepField(RetailItemPriceAdjustmentBuilderFields.INCLUDE_FINANCE, this.fields),
      getStepField(RetailItemPriceAdjustmentBuilderFields.INCLUDE_LEASE, this.fields)
    );

    const isNegativeAmount =
      getStepField(RetailItemPriceAdjustmentBuilderFields.AMOUNT_ADJUSTMENT_TYPE, this.fields).selectedValue ===
      RetailAdjustmentAmountAdjustmentType.DISCOUNT;

    // This util will update the PERCENTAGE and FIXED fields with the correct values
    setFixedAndPercentageValuesFromToggleFieldState(
      getStepField(RetailItemPriceAdjustmentBuilderFields.AMOUNT_FIXED, this.fields, true),
      getStepField(RetailItemPriceAdjustmentBuilderFields.AMOUNT_PERCENTAGE, this.fields, true),
      getStepField(RetailItemPriceAdjustmentBuilderFields.AMOUNT, this.fields, true),
      isNegativeAmount
    );

    const variableOverrides = { paymentOptions };
    if (isCreating) {
      variableOverrides['retailItemId'] = seededData?.retailItemId;
    }

    return super.save(undefined, variableOverrides);
  }
}

export default RetailItemPriceAdjustmentStep;
