import en from './en.json';
import fr from './fr.json';

/*
 * TODO:
 * - [#2893] Re-enable Spanish support when needed. 
 */
export default {
  // NOTE: i18next requires each locale to be wrapped in `translation`
  'en-CA': { translation: en },
  'fr-CA': { translation: fr },
};
