import { memo, type SVGProps } from 'react';

const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={22} viewBox="0 0 18 22" {...props}>
    <g transform="translate(-12 -8)">
      <path
        d="M24.408 12.915c.765 0 3.083.168 4.477 2.261l.327.491-.47.355c-.671.506-1.896 1.43-1.896 3.26 0 2.169 1.387 3.002 2.053 3.403.295.177.6.36.6.76 0 .26-2.081 5.856-5.102 5.856-.739 0-1.261-.222-1.722-.418-.466-.198-.869-.37-1.533-.37-.337 0-.763.16-1.214.329-.617.23-1.314.491-2.106.491h-.02c-3.176-.018-5.802-6.5-5.802-9.8 0-5.392 4.045-6.572 5.604-6.572.702 0 1.452.276 2.114.52.463.17.941.346 1.207.346.16 0 .535-.15.866-.281.707-.281 1.587-.631 2.61-.631h.007zM24.722 8c.078 2.836-1.95 4.804-3.976 4.68C20.412 10.418 22.772 8 24.722 8z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default memo(AppleIcon);
