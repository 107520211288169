import { memo, useCallback } from 'react';

import invariant from 'tiny-invariant';

import PrimaryText from 'components/core/typography/PrimaryText';
import RooftopIntegrationPhoneNumbers from 'components/sections/rooftops/integrations/RooftopIntegrationPhoneNumbers';
import { isIntegrationFieldEnabled } from 'components/sections/rooftops/integrations/utils';
import { ItemGridRowDisplayType } from 'components/sections/shared/ItemGridRowDisplayType';
import type { ItemGridRowProps } from 'components/sections/shared/ItemGridRowSection';
import { ItemGridRowSection } from 'components/sections/shared/ItemGridRowSection';
import type { ItemTabProps } from 'components/ui/shared/interfaces/ItemTab';
import { RooftopIntegration, RooftopIntegrationSettings } from 'enums/columns/rooftopIntegration';
import { useUser } from 'hooks/contexts/useUser';
import type { RooftopIntegrationFragment } from 'store/api/graph/interfaces/types';
import { WebsitePageType } from 'store/api/graph/interfaces/types';
import { PLACEHOLDER } from 'utils/formatUtils';
import { formatItemGridRowSectionKey } from 'utils/gridUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';

const {
  ACTIVE,
  IMPORT_PHOTOS,
  PRIMARY,
  RUN_ONCE,
  THIRD_PARTY_ROOFTOP_ID,
  INTEGRATION_NAME,
  INTEGRATION_TYPE,
  PRIVACY_POLICY_URL,
  CREATED,
  ROOFTOP_NAME,
  UPDATED,
  CPP_WEBSITE,
  WEBSITE_ROUTES,
  WEBSITE_PAGE_TYPE,
  OEM_ACCOUNT_EXPORT_WEBSITE,
  OEM_ACCOUNT_EXPORT_WEBSITE_BRAND,
  DEALER_CODE,
  BAC,
  ROOFTOP_NAME_OVERRIDE,
  LEAD_DISTRIBUTION_WEBSITE,
  LEAD_DISTRIBUTION_THIRD_PARTY_ID,
  INVENTORY_EXPORT_THIRD_PARTY_ID,
  INVENTORY_EXPORT_WEBSITE_BRAND,
  PHONE_CALL_TRACKING_PHONE_NUMBERS,
} = RooftopIntegration;

const { BOLD } = ItemGridRowDisplayType;

/**
 * Note: This grid is always used in a nested view, so will always see one field per row.
 */
const gridConfig = (isWhiteLabelScoped: boolean, webpageType: WebsitePageType | undefined): ItemGridRowProps[] => [
  { fields: [ROOFTOP_NAME, INTEGRATION_TYPE, INTEGRATION_NAME], displayType: [BOLD], canEdit: false },
  {
    fields: [
      THIRD_PARTY_ROOFTOP_ID,
      LEAD_DISTRIBUTION_THIRD_PARTY_ID,
      INVENTORY_EXPORT_THIRD_PARTY_ID,
      WEBSITE_PAGE_TYPE,
      /**
       * We only want to show `website` and `website route` fields if  it is a vehicle listing page
       */
      ...(webpageType === WebsitePageType.VLP ? [CPP_WEBSITE, WEBSITE_ROUTES] : []),
      OEM_ACCOUNT_EXPORT_WEBSITE,
      LEAD_DISTRIBUTION_WEBSITE,
      OEM_ACCOUNT_EXPORT_WEBSITE_BRAND,
      INVENTORY_EXPORT_WEBSITE_BRAND,
      DEALER_CODE,
      BAC,
      ROOFTOP_NAME_OVERRIDE,
    ],
    displayType: [BOLD],
    canEdit: isWhiteLabelScoped,
  },
  {
    fields: [PHONE_CALL_TRACKING_PHONE_NUMBERS],
    canEdit: isWhiteLabelScoped,
    gridCellRenderMethod: (_, item: RooftopIntegrationFragment) => {
      try {
        invariant(item.phoneCallTrackingAttributes?.__typename === 'PhoneCallTrackingRooftopIntegrationAttributes');
        return <RooftopIntegrationPhoneNumbers phoneNumbers={item?.phoneCallTrackingAttributes?.phoneNumbers} />;
      } catch {
        return <PrimaryText>{PLACEHOLDER}</PrimaryText>;
      }
    },
  },
  {
    fields: [PRIMARY, ACTIVE, RUN_ONCE, IMPORT_PHOTOS, PRIVACY_POLICY_URL],
    displayType: [BOLD],
    canEdit: isWhiteLabelScoped,
  },
  { fields: [CREATED, UPDATED], displayType: [BOLD], canEdit: false },
];

export const RooftopIntegrationDetailsTab = memo<ItemTabProps<RooftopIntegrationFragment>>(
  ({ item: rooftopIntegration, metadata, onEdit }) => {
    const { isWhiteLabelScoped } = useUser();
    const webpageType =
      rooftopIntegration?.cppAttributes?.__typename === 'CppRooftopIntegrationAttributes'
        ? rooftopIntegration.cppAttributes.websitePageType
        : undefined;

    const onEditCallback = useCallback(
      (field?: string) => onEdit(field, translate.t('integration_other'), rooftopIntegration),
      [onEdit, rooftopIntegration]
    );

    return rooftopIntegration ? (
      <>
        {gridConfig(isWhiteLabelScoped, webpageType).map(config => {
          const fields = config.fields.filter(field =>
            isIntegrationFieldEnabled(field as RooftopIntegration, rooftopIntegration.integrationType.id, true)
          );
          return (
            <ItemGridRowSection
              key={formatItemGridRowSectionKey(fields)}
              item={rooftopIntegration}
              settings={RooftopIntegrationSettings}
              onEdit={authorizedCallback({
                cb: onEditCallback,
                isAuth: !!onEdit,
              })}
              config={{ ...config, fields }}
              metadata={metadata?.mutation?.rooftopIntegration}
            />
          );
        })}
      </>
    ) : null;
  }
);
