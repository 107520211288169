import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import DealerTrackIcon from 'components/ui/icons/DealerTrackIcon';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { ListItemType } from 'enums/listItemType';
import type { CreditApplication } from 'store/api/graph/interfaces/types';
import { ENTITY_PADDING } from 'styles/spacing';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { translate } from 'utils/intlUtils';

import Badge, { BadgeColors, Badges } from '../shared/Badge';

import type { ListItemProps } from './interfaces/ListItemProps';

const { t } = translate;

interface CreditApplicationListItemProps extends CreditApplication, ListItemProps {}

const Wrapper = styled(ListItem)`
  margin-bottom: 0;
  padding: ${ENTITY_PADDING};
`;

/**
 * Displays info for a single Credit Application that a lead had applied for in order to finance an inventory item.
 * Info includes:
 *  - When the application was received
 *  - What the lender and product code is
 *  - Whether it was successful or not
 */
export const CreditApplicationListItem = memo(
  ({ suffixIcon, listItemType, ...application }: CreditApplicationListItemProps) => (
    <Wrapper key={application.id} listItemType={ListItemType.SECTION_LIST} suffixIcon={suffixIcon}>
      <div>
        <DealerTrackIcon />
      </div>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>
            {t('dealertrack')}
            {application.productCodeName && ' • '}
          </PrimaryText>
          {application.productCodeName && <SecondaryText>{application.productCodeName}</SecondaryText>}
        </TextRow>
        <TextRow>
          <SecondaryText>
            {getFormattedDateTimeString(application.received, DateTimeFormat.DATE_YEAR_FORMAT)} -{' '}
            {getFormattedDateTimeString(application.received, DateTimeFormat.TIME_FORMAT)}
          </SecondaryText>
        </TextRow>
        <TextRow>
          <Badges>
            <Badge title={application.lender.name}>{application.lender.name}</Badge>
            {application.success ? (
              <Badge title={t('successful')} color={BadgeColors.POSITIVE}>
                {t('successful')}
              </Badge>
            ) : (
              <Badge title={t('unsuccessful')} color={BadgeColors.NEGATIVE}>
                {t('unsuccessful')}
              </Badge>
            )}
          </Badges>
        </TextRow>
      </ListItemDetails>
    </Wrapper>
  )
);
