import {
  OverlayImagePreviewBox,
  type OverlayImagePreviewBoxProps,
} from 'components/sections/createModify/retailBulkAdjustment/OverlayImagePreviewBox';
import type { Props as BaseDialogProps } from 'components/ui/dialogs/Dialog';
import Dialog from 'components/ui/dialogs/Dialog';

type OverlayImagePreviewDialogProps = OverlayImagePreviewBoxProps & BaseDialogProps;

/**
 * OverlayImagePreviewDialog is a dialog component that will render an overlay image preview. See OverlayImagePreviewBox
 * for implementation details on how overlay image previews are done.
 */
const OverlayImagePreviewDialog = ({
  isOpen,
  isFixedToTop,
  onCancel,
  testId,
  ...props
}: OverlayImagePreviewDialogProps) => (
  <Dialog isOpen={isOpen} isFixedToTop={isFixedToTop} onCancel={onCancel} showBorderRadius={false}>
    <OverlayImagePreviewBox {...props} boxWidth={500} testId={testId} />
  </Dialog>
);

export default OverlayImagePreviewDialog;
