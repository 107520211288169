export enum Pagination {
  LIST_LENGTH = 50,
}
/**
 * BE pagination and types follow relay spec.
 *
 * @see https://relay.dev/graphql/connections.htm
 */
export interface PaginationProps {
  /** Number max of items to fetch after the `after` cursor. */
  first: number;
  /** Cursor where fetching begins. Can be hashes and not just numbers. */
  after?: string;
}
