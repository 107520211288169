import type { ElementType, ReactNode } from 'react';
import { useState } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled from 'styled-components/macro';

import InfoIcon from 'components/ui/icons/InfoIcon';
import type { ArrowPosition, TooltipMargin } from 'components/ui/shared/Tooltip';
import { PrimaryArrowPosition, SecondaryArrowPosition, TooltipStyle } from 'components/ui/shared/Tooltip';
import { withTooltip } from 'hooks/withTooltip';
import { BODY_TEXT, BODY_TEXT_SECONDARY } from 'styles/color';

const Container = styled.div<{ customStyles?: FlattenSimpleInterpolation }>`
  > :first-child {
    /* Center align SVG icon in anchor container */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ customStyles }) => customStyles}
`;

export const InfoIconContainer = styled(InfoIcon)<{ $hoverColor: string; color: string }>`
  transition-delay: 0.1s;
  color: ${({ color }) => color};

  :hover {
    color: ${({ $hoverColor }) => $hoverColor};
  }
`;

export const TitleWithIconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

interface Props {
  children: ReactNode;
  // Custom CSS styles to apply to the container (useful for margins and padding)
  styles?: FlattenSimpleInterpolation;
  // Tooltip props
  /** The icon component used as the tooltip button. Must be wrapped in withTooltip() HOC. */
  icon?: ElementType;
  /** Tooltip props */
  tooltip?: {
    /** The expected width of the tooltip, used as a cutoff point for repositioning it based on browser width */
    width?: number;
    /** Where the arrow should appear relative to the tooltip's body. Is `LEFT TOP` by default. */
    arrowPosition?: ArrowPosition;
    /** Any extra spacing between the anchor and the tooltip */
    margin?: TooltipMargin;
    /** Style variant for the tooltip */
    styleVariant?: TooltipStyle;
  };
}

// This is the default tooltip icon that is used if no argument for icon prop is provided.
const TooltipIcon = withTooltip(() => (
  <InfoIconContainer width={16} height={16} color={BODY_TEXT_SECONDARY} $hoverColor={BODY_TEXT} />
));

const TooltipButton = ({ icon = TooltipIcon, styles, tooltip, children }: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const TooltipIconContainer = icon;

  return (
    <Container
      customStyles={styles}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <TooltipIconContainer
        tooltip={{
          width: 100,
          wrapComponent: true,
          shouldShow: isTooltipVisible,
          styleVariant: TooltipStyle.NORMAL,
          arrowPosition: {
            primary: PrimaryArrowPosition.TOP,
            secondary: SecondaryArrowPosition.LEFT,
          },
          isAnimated: true,
          children,
          ...(tooltip || {}),
        }}
      />
    </Container>
  );
};

export default TooltipButton;
