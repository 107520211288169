/**
 * Generic type guard function to check if a string is a value of any enum
 */
export const isEnumValue = <T extends { [key: string]: string }>(enumObj: T, value: any): value is T[keyof T] =>
  Object.values(enumObj).includes(value);

/**
 * A utility type for extracting a type union from and array
 *
 * @example
 * type ArrayType = (A | B | C)[];
 * type UnionType = ExtractUnionFromArray<typeof ArrayType>; // A | B | C
 */
export type ExtractUnionFromArray<T> = T extends (infer U)[] ? U : never;
