export enum ApolloFetchPolicy {
  NETWORK_ONLY = 'network-only',
  CACHE_AND_NETWORK = 'cache-and-network',
  CACHE_FIRST = 'cache-first',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
}

export enum ApolloContextHeaders {
  NO_AUTH_REQUIRED = 'noAuthRequired',
}
