import { memo } from 'react';

import styled from 'styled-components/macro';

import { TertiaryLabel } from 'components/core/typography/Label';
import PrimaryText from 'components/core/typography/PrimaryText';
import type { RouteFilterGroup } from 'components/sections/createModify/websiteRoutes/WebsiteRouteFilterBadges';
import { WebsiteRouteFilterBadges } from 'components/sections/createModify/websiteRoutes/WebsiteRouteFilterBadges';
import { Section } from 'components/ui/layouts/CardLayout';
import { Badges } from 'components/ui/shared/Badge';
import { PLACEHOLDER } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import { CollapsibleSectionHeaderWrapper } from './collapsibleSections/CollapsibleSection';

const { t } = translate;

interface Props {
  /**  A list of RouteFilterGroups to be displayed for a website route  */
  filters: RouteFilterGroup[][];
  /**  Path of associated route to be used as a key */
  path: string;
}

const WebsiteRouteFilterCellSection = styled(Section)`
  :first-of-type {
    padding-top: 5px;
  }
`;

const WebsiteRouteFilterCell = memo<{
  /** List of selected filters to be displayed */
  filterOptions: RouteFilterGroup[];
  /** Indicates whether the 'OR' label will be displayed. */
  isFirstFilter: boolean;
  /** Placeholder value to be displayed if there are no filters */
  placeholder?: string;
}>(({ filterOptions, isFirstFilter, placeholder = PLACEHOLDER }) => (
  <WebsiteRouteFilterCellSection>
    {!isFirstFilter && <TertiaryLabel>{t('or')}</TertiaryLabel>}
    <Badges>
      {filterOptions.length > 0 ? (
        <WebsiteRouteFilterBadges filters={filterOptions} />
      ) : (
        <PrimaryText>{placeholder}</PrimaryText>
      )}
    </Badges>
  </WebsiteRouteFilterCellSection>
));

export const WebsiteRoutesTabFiltersSection = ({ filters, path }: Props) => (
  <>
    <CollapsibleSectionHeaderWrapper>
      <TertiaryLabel>{t('filter_other')}</TertiaryLabel>
    </CollapsibleSectionHeaderWrapper>
    {filters.map((filter, index) => (
      // TODO ED-8416 look into a better way to generate keys when this is no longer read only

      <WebsiteRouteFilterCell filterOptions={filter} isFirstFilter={filter === filters[0]} key={path + index} />
    ))}
  </>
);
