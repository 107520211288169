import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import WebsiteLeadRoutingDetailsFields from './steps/DetailsFields';
import WebsiteLeadRoutingDetailsStep from './steps/DetailsStep';
import { leadRouteModify } from './WebsiteLeadRouteCreateModifyQuery';
import WebsiteLeadRoutingQueryMethods from './WebsiteLeadRoutingQueryMethods';

const WebsiteLeadRoutingBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.WEBSITE_LEAD_ROUTING_MODIFY]: {
    getData: WebsiteLeadRoutingQueryMethods.getData,
    resourceType: ResourceType.WEBSITES,
    refetchQueries: WebsiteLeadRoutingQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.WEBSITES, level: AccessLevel.FULL }],
    steps: [
      {
        id: 'WEBSITE_LEAD_ROUTING_DETAILS',
        step: WebsiteLeadRoutingDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: WebsiteLeadRoutingDetailsFields,
      },
    ],
    mutations: {
      modify: leadRouteModify,
    },
  },
};

export default WebsiteLeadRoutingBuilders;
