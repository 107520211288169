import { gql } from '@apollo/client';

export const initialItemSorting = gql`
  fragment InitialItemSortingFragment on Sort {
    columnId
    customOrder
    direction
    directionName
    nullsLast
  }
`;
