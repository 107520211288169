import { merge } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getGroupOptions, getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import LocationSelector from 'components/sections/shared/LocationSelector';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import type {
  LocationInput,
  SelectStringOption,
  WebsiteDetailsContainerQuery,
  WebsiteMetaQuery,
  WebsiteModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { PlaceAutocompleteType } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { formatLocationData } from 'utils/formatUtils';
import { filterByKeyword } from 'utils/metadataUtils';

import { WebsiteDetailsBuilderFields } from './interfaces';

type WebsiteDetails = {
  /** Location input for address fields */
  location?: Partial<LocationInput | Location>;
} & WebsiteDetailsContainerQuery['item'];

class DetailsStep extends StepComponentCore<
  WebsiteDetails,
  WebsiteMetaQuery['metadata'],
  WebsiteModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<WebsiteDetails, WebsiteMetaQuery['metadata'], WebsiteModifyMutationVariables>,
    context: CreateModifyContextInterface<WebsiteDetails, WebsiteMetaQuery>
  ) {
    super(props);
    const {
      tier: { data: savedData, isCreating, activeStep, formData, stepFieldData, metadata },
    } = props;
    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped },
      },
    } = context;

    const data = merge({}, savedData, formData);

    const stepFieldDataGroup = stepFieldData?.[WebsiteDetailsBuilderFields.GROUP_ID];
    const stepFieldDataPrimaryRooftop = stepFieldData?.[WebsiteDetailsBuilderFields.PRIMARY_ROOFTOP_ID];
    const stepFieldDataLocation = stepFieldData?.[WebsiteDetailsBuilderFields.LOCATION];

    const selectedGroup = stepFieldDataGroup || data?.groupName;
    const group = isWhiteLabelScoped ? selectedGroup : user.group;

    const selectedPrimaryRooftop = stepFieldDataPrimaryRooftop || data?.primaryRooftop;

    const {
      website: { locale: availableLocaleOptions },
    } = metadata.mutation;

    const selectedLocales = availableLocaleOptions.filter(availableLocale => {
      const locales: WebsiteDetails['locales'] | string[] = data?.locales;
      return locales?.some(
        selectedLocale =>
          (typeof selectedLocale === 'string' ? selectedLocale : selectedLocale.id) === availableLocale.id
      );
    });

    const selectedPrimaryLocale = availableLocaleOptions.find(availableLocale => {
      const primaryLocale: WebsiteDetails['primaryLocale'] | string = data?.primaryLocale;
      return typeof primaryLocale === 'string' ? availableLocale.id === primaryLocale : data?.primaryLocale;
    });

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [WebsiteDetailsBuilderFields.GROUP_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
          { type: StepFieldDisplayType.DISABLED, active: !isCreating },
          { type: StepFieldDisplayType.HIDDEN, active: !isWhiteLabelScoped },
        ]),
        selectedValue: group,
      },
      [WebsiteDetailsBuilderFields.PRIMARY_ROOFTOP_ID]: {
        selectedValue: selectedPrimaryRooftop,
        displayType: setDisplayTypes([{ type: StepFieldDisplayType.DISABLED, active: !!isCreating && !group }]),
      },
      [WebsiteDetailsBuilderFields.LOCALES]: {
        selectedValue: selectedLocales,
      },
      [WebsiteDetailsBuilderFields.PRIMARY_LOCALE]: {
        selectedValue: selectedPrimaryLocale,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.DISABLED,
            active: (isCreating && selectedLocales.length === 0) || selectedLocales.length === 1,
          },
        ]),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, {
      ...data,
      location:
        !isCreating || stepFieldDataLocation
          ? formatLocationData(
              {
                zipCode: stepFieldDataLocation?.zipCode || data.zipCode?.value,
                region: stepFieldDataLocation?.region || data.region?.value,
                country: stepFieldDataLocation?.country || data.country?.value,
                countryCode: stepFieldDataLocation?.countryCode || data.countryCode?.value,
                placeId: stepFieldDataLocation?.placeId || data.placeId?.value,
                timeZone: stepFieldDataLocation?.timeZone || data.timeZone?.value,
                latitude: stepFieldDataLocation?.latitude || data.latitude?.value,
                longitude: stepFieldDataLocation?.longitude || data.longitude?.value,
                address: stepFieldDataLocation?.address || data.address?.value,
                city: stepFieldDataLocation?.city || data.city?.value,
                regionCode: stepFieldDataLocation?.regionCode || data.regionCode?.value,
              },
              true
            )
          : undefined,
    });

    // Async subpanel configurations
    this.asyncConfigurations = {
      [WebsiteDetailsBuilderFields.GROUP_ID]: {
        request: async keyword => getGroupOptions(keyword),
      },
      [WebsiteDetailsBuilderFields.PRIMARY_ROOFTOP_ID]: {
        request: async keyword =>
          getRooftopOptions({
            user,
            keyword,
            isWhiteLabelScoped,
            groupId: getStepField(WebsiteDetailsBuilderFields.GROUP_ID, this.fields).selectedValue?.id,
          }),
      },
      [WebsiteDetailsBuilderFields.PRIMARY_LOCALE]: {
        request: (keyword: string) => {
          const localesField = getStepField<SelectStringOption[]>(WebsiteDetailsBuilderFields.LOCALES, this.fields);
          const locales = localesField.selectedValue || [];
          return filterByKeyword(keyword, locales);
        },
      },
    };
  }

  async onFieldSelection(stepField: StepField, value: any) {
    super.onFieldSelection(stepField, value);

    const stepFieldQueryVar = stepField.queryVar;

    if (stepFieldQueryVar === WebsiteDetailsBuilderFields.GROUP_ID) {
      const rooftopIdField = getStepField(WebsiteDetailsBuilderFields.PRIMARY_ROOFTOP_ID, this.fields);
      const prevValue = stepField.selectedValue?.id;

      setDisplayTypes(
        { type: StepFieldDisplayType.DISABLED, active: !value?.id || prevValue === value?.id },
        rooftopIdField
      );
      rooftopIdField.selectedValue = null;
    } else if (stepFieldQueryVar === WebsiteDetailsBuilderFields.LOCALES) {
      const locales: SelectOption[] = value;
      const primaryLocaleField = getStepField(WebsiteDetailsBuilderFields.PRIMARY_LOCALE, this.fields);
      const newPrimaryLocaleValue = locales.find(({ id }) => id === primaryLocaleField.selectedValue?.id) || locales[0];
      primaryLocaleField.selectedValue = newPrimaryLocaleValue || null;
      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: locales.length < 2 }, primaryLocaleField);
    }
  }

  async toggleSubPanel(stepField?: StepField) {
    if (stepField?.queryVar === WebsiteDetailsBuilderFields.LOCATION) {
      this.setState({
        childrenBeforeSubStep: (
          <LocationSelector
            type={PlaceAutocompleteType.ESTABLISHMENT}
            location={stepField.selectedValue}
            onDone={location => void this.onFieldSelection(stepField, location)}
          />
        ),
      });
    } else {
      this.setState({ childrenBeforeSubStep: null });
    }

    await super.toggleSubPanel(stepField);
  }
}

export default DetailsStep;
