import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum RooftopTag {
  TYPE = 'type',
  NAME = 'name',
  ROOFTOP = 'rooftop',
}

const RooftopTagConfig: TableSettingsType = {
  [RooftopTag.TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [RooftopTag.NAME]: { label: 'name', type: FieldDataType.DEFAULT },
};

export const RooftopTagSettings = translateFields(RooftopTagConfig);
