import { memo } from 'react';

import { isNil } from 'lodash-es';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import Badge, { Badges } from 'components/ui/shared/Badge';
import type { PriceRetailBulkAdjustmentAttributes, RetailBulkAdjustment } from 'store/api/graph/interfaces/types';
import { RetailBulkAdjustmentType } from 'store/api/graph/interfaces/types';
import { formatPriceAdjustmentAmount } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

interface Props extends Omit<RetailBulkAdjustment, '__typename'>, ListItemProps {}

const RooftopRetailBulkAdjustmentListItem = ({
  suffixIcon,
  listItemType,
  name,
  typeName,
  type,
  attributes,
  starting,
  ending,
}: Props) => {
  const { t } = translate;
  const isScheduled = starting || ending;
  const priceBadge =
    type === RetailBulkAdjustmentType.PRICE
      ? formatPriceAdjustmentAmount(attributes as PriceRetailBulkAdjustmentAttributes)
      : null;

  return (
    <ListItem suffixIcon={suffixIcon} listItemType={listItemType}>
      <ListItemDetails>
        <TextRow>
          <PrimaryText>{name}</PrimaryText>
        </TextRow>
        <TextRow>
          <Badges>
            <Badge title={`${t('type')}: ${typeName}`}> {typeName}</Badge>
            {!isNil(priceBadge) && <Badge title={priceBadge}>{priceBadge}</Badge>}
            {!isNil(isScheduled) && <Badge title={`${t('status')}: ${t('scheduled')}`}>{t('scheduled')}</Badge>}
          </Badges>
        </TextRow>
      </ListItemDetails>
    </ListItem>
  );
};

export default memo(RooftopRetailBulkAdjustmentListItem);
