import { memo, type SVGProps } from 'react';

import { ElementTestId } from 'enums/testing';

const LinkedOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props} data-testid={ElementTestId.LINKED_OPEN_ICON}>
    <path
      fill="currentColor"
      d="M6.25 12.333c0 .195.083.327.25.396.167.07.32.035.458-.104L9 10.583A.8.8 0 0 0 9.25 10 .8.8 0 0 0 9 9.417L6.958 7.375c-.139-.139-.291-.174-.458-.104-.167.07-.25.201-.25.396v4.666ZM4.167 17.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V4.167c0-.459.163-.851.49-1.177.326-.327.718-.49 1.177-.49h11.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v11.666c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H4.167Zm9.166-1.667h2.5V4.167h-2.5v11.666Zm-1.666 0V4.167h-7.5v11.666h7.5Z"
    />
  </svg>
);

export default memo(LinkedOpen);
