import { useEffect, useRef } from 'react';

/**
 * Hook to handle unloading of document resources.
 * e.g. Useful for running browser refresh cleanup methods.
 * Source: https://stackoverflow.com/questions/39084924/componentwillunmount-not-being-called-when-refreshing-the-current-page
 *
 * @param handler Method to run `beforeunload`
 * @param callOnCleanup Whether to call the `handler`
 */
export const useWindowUnloadEffect = (handler: () => void, callOnCleanup: boolean) => {
  const cb = useRef(handler);

  cb.current = handler;

  useEffect(() => {
    const handler = () => cb?.current?.();

    window.addEventListener('beforeunload', handler);

    return () => {
      if (callOnCleanup) {
        handler();
      }

      window.removeEventListener('beforeunload', handler);
    };
  }, [callOnCleanup]);
};
