import { memo, type SVGProps } from 'react';

const EditColumnsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M3.025 17V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 5.025 5H19c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 19H5.025c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3.025 17ZM5 16.6c0 .22.18.4.4.4h2.525a.4.4 0 0 0 .4-.4V7.4a.4.4 0 0 0-.4-.4H5.4a.4.4 0 0 0-.4.4v9.2Zm5.325 0c0 .22.18.4.4.4h2.525a.4.4 0 0 0 .4-.4V7.4a.4.4 0 0 0-.4-.4h-2.525a.4.4 0 0 0-.4.4v9.2Zm5.325 0c0 .22.18.4.4.4h2.525a.4.4 0 0 0 .4-.4V7.4a.4.4 0 0 0-.4-.4H16.05a.4.4 0 0 0-.4.4v9.2Z"
    />
  </svg>
);
export default memo(EditColumnsIcon);
