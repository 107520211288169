import { useContext } from 'react';

import { BuilderConfigContext } from 'contexts/BuilderConfigContext';

export const useBuilderConfig = () => {
  const context = useContext(BuilderConfigContext);
  if (context === undefined) {
    throw new Error('useBuilderConfig must be used within a BuilderConfigProvider');
  }
  return context;
};
