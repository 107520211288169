import { merge } from 'lodash-es';

import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

import { RooftopDescriptionBuilderFields } from './interfaces';

class DescriptionStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { data: savedData, formData, activeStep },
    } = props;

    const data = merge({}, savedData, formData);

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      // Defaults
      [RooftopDescriptionBuilderFields.DESCRIPTION]: {
        selectedValue: data.description,
      },
    });
  }
}

export default DescriptionStep;
