import { StepLegendNav, StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import WebsiteRouteSortingFields from '../shared/steps/InitialItemSortingFields';

import WebsiteDetailsFields from './steps/DetailsFields';
import WebsiteDetailsStep from './steps/DetailsStep';
import { WebsiteBuilderStepId } from './steps/interfaces';
import WebsiteSortingStep from './steps/WebsiteSortingStep';
import { websiteCreate, websiteModify } from './WebsiteCreateModifyQuery';
import WebsiteQueryMethods from './WebsiteQueryMethods';

const WebsiteBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.WEBSITE_CREATE]: {
    getMetaData: WebsiteQueryMethods.getMetaData,
    resourceType: ResourceType.WEBSITES,
    refetchQueries: WebsiteQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.WEBSITES, level: AccessLevel.FULL }],
    steps: [
      {
        id: WebsiteBuilderStepId.WEBSITE_DETAILS,
        step: WebsiteDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: WebsiteDetailsFields,
        tooltip: {
          title: 'ga4_property_id_tooltip_title',
          content: 'ga4_property_id_tooltip_content',
        },
      },
      {
        id: WebsiteBuilderStepId.WEBSITE_SORTING,
        step: WebsiteSortingStep,
        stepName: 'default_sort_rule',
        isEnabled: false,
        fields: WebsiteRouteSortingFields,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      create: websiteCreate,
      validate: websiteCreate,
    },
  },
  [BuilderType.WEBSITE_MODIFY]: {
    getMetaData: WebsiteQueryMethods.getMetaData,
    getData: WebsiteQueryMethods.getData,
    resourceType: ResourceType.WEBSITES,
    refetchQueries: WebsiteQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.WEBSITES, level: AccessLevel.FULL }],
    steps: [
      {
        id: WebsiteBuilderStepId.WEBSITE_DETAILS,
        step: WebsiteDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: WebsiteDetailsFields,
        tooltip: {
          title: 'ga4_property_id_tooltip_title',
          content: 'ga4_property_id_tooltip_content',
        },
      },
      {
        id: WebsiteBuilderStepId.WEBSITE_SORTING,
        step: WebsiteSortingStep,
        stepName: 'default_sort_rule',
        isEnabled: true,
        fields: WebsiteRouteSortingFields,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      modify: websiteModify,
    },
  },
};

export default WebsiteBuilders;
