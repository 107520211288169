import styled from 'styled-components/macro';

const TextRow = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.5ch;
  grid-auto-flow: column;
  justify-content: flex-start;
`;

export default TextRow;
