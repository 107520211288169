import { gql } from '@apollo/client';

import { mailboxList } from 'store/api/graph/fragments/mailboxList';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { rooftop } from 'store/api/graph/fragments/rooftop';
import { tag } from 'store/api/graph/fragments/tag';

export const mailboxDetail = gql`
  fragment MailboxDetailFragment on Mailbox {
    ...MailboxListFragment

    rooftopName: rooftop {
      ...RooftopFragment
    }
    description
    email
    facebookId
    phoneNumber
    sourceName: source {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    tagName: tags {
      ...TagFragment
    }
  }

  ${mailboxList}
  ${rooftop}
  ${tag}
  ${MultilingualString}
`;
