import {
  StepLegendCompletionPercent,
  StepLegendNav,
  StepLegendTooltip,
} from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import CaptureVinFields from 'components/sections/createModify/inventoryItems/retailItem/steps/CaptureVinFields';
import CaptureVinStep from 'components/sections/createModify/inventoryItems/retailItem/steps/CaptureVinStep';
import { RetailItemBuilderSteps } from 'components/sections/createModify/inventoryItems/retailItem/steps/interfaces';
import RetailBulkAdjustmentFields from 'components/sections/createModify/inventoryItems/retailItem/steps/RetailBulkAdjustmentFields';
import RetailBulkAdjustmentStep from 'components/sections/createModify/inventoryItems/retailItem/steps/RetailBulkAdjustmentStep';
import MediaStep, {
  defaultFields as PhotosStepFields,
} from 'components/sections/createModify/inventoryItems/steps/photosStep/MediaStep';
import { mediaStepTooltipConfig } from 'components/sections/createModify/shared/steps/mediaStepTooltipConfig';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { retailItemCreate, retailItemModify } from './RetailItemCreateModifyQuery';
import RetailItemQueryMethods from './RetailItemQueryMethods';
import DescriptionFields from './steps/DescriptionFields';
import DescriptionStep from './steps/DescriptionStep';
import DetailsRetailFields from './steps/DetailsRetailFields';
import DetailsStep from './steps/DetailsStep';
import PricingAndMileageFields from './steps/PricingAndMileageFields';
import PricingAndMileageStep from './steps/PricingAndMileageStep';

const RetailItemBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_ITEMS_CREATE]: {
    getMetaData: RetailItemQueryMethods.getMetaData,
    refetchQueries: RetailItemQueryMethods.refetchQueries,
    steps: [
      {
        id: RetailItemBuilderSteps.VIN,
        step: CaptureVinStep,
        stepName: 'vin',
        isEnabled: true,
        fields: CaptureVinFields,
        tooltip: {
          title: 'get_started',
          content: 'vin_step_tooltip',
        },
      },
      {
        id: RetailItemBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: false,
        fields: DetailsRetailFields,
      },
      {
        id: RetailItemBuilderSteps.PRICING_AND_MILEAGE,
        step: PricingAndMileageStep,
        stepName: 'pricing_and_mileage',
        isEnabled: false,
        fields: PricingAndMileageFields,
      },
      {
        id: RetailItemBuilderSteps.DESCRIPTION,
        step: DescriptionStep,
        stepName: 'description_and_highlights',
        isEnabled: false,
        isExpandable: true,
        fields: DescriptionFields,
      },
      {
        id: RetailItemBuilderSteps.MEDIA,
        step: MediaStep,
        stepName: 'media',
        isEnabled: false,
        isExpandable: true,
        tooltip: mediaStepTooltipConfig,
      },
    ],
    legend: [StepLegendNav, StepLegendCompletionPercent, StepLegendTooltip],
    mutations: {
      create: retailItemCreate,
      modify: retailItemModify,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.RETAIL_ITEMS,
  },
  [BuilderType.RETAIL_ITEMS_MODIFY]: {
    getMetaData: RetailItemQueryMethods.getMetaData,
    getData: RetailItemQueryMethods.getData,
    refetchQueries: ['RetailItemDetailsContainerQuery'], // TODO: [ED-10208] Remove this line to reproduce the issue, should be able to remove this entirely once bug is fixed
    steps: [
      {
        id: RetailItemBuilderSteps.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsRetailFields,
      },
      {
        id: RetailItemBuilderSteps.PRICING_AND_MILEAGE,
        step: PricingAndMileageStep,
        stepName: 'pricing_and_mileage',
        isEnabled: true,
        fields: PricingAndMileageFields,
      },
      {
        id: RetailItemBuilderSteps.DESCRIPTION,
        step: DescriptionStep,
        stepName: 'description_and_highlights',
        isEnabled: true,
        isExpandable: true,
        fields: DescriptionFields,
      },
      {
        id: RetailItemBuilderSteps.MEDIA,
        step: MediaStep,
        stepName: 'media',
        isEnabled: true,
        isExpandable: true,
        fields: PhotosStepFields,
        tooltip: mediaStepTooltipConfig,
      },
      {
        id: RetailItemBuilderSteps.BULK_ADJUSTMENTS,
        step: RetailBulkAdjustmentStep,
        stepName: 'bulk_adjustment_other',
        isEnabled: true,
        fields: RetailBulkAdjustmentFields,
      },
    ],
    legend: [StepLegendNav, StepLegendCompletionPercent, StepLegendTooltip],
    mutations: {
      modify: retailItemModify,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.RETAIL_ITEMS,
  },
};

export default RetailItemBuilders;
