import { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { OptionButton, OptionButtonStyle, OptionsContainer } from 'components/ui/dialogs/PromptDialogOptions';
import InputText from 'components/ui/forms/shared/InputText';
import ErrorIcon from 'components/ui/icons/ErrorIcon';
import { RED_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const SaveFilterMessageText = styled(Text)`
  white-space: pre-line;
  text-align: center;
  line-height: 24px;
`;

const ButtonContainer = styled(OptionsContainer)`
  width: 100%;
  margin-top: 15px;
  padding: 0;
`;

interface SaveFilterViewPromptProps {
  /** Callback for when the user has clicked 'Save' button. Will pass the name of the saved filter view */
  onSubmit: (name: string) => void;
  /** Callback for when the user acknowledged an error and wants to close the prompt */
  onClose: () => void;
  /** Error to show in this prompt */
  error?: string;
}

const SaveFilterViewForm = ({ onSubmit, onClose, error }: SaveFilterViewPromptProps) => {
  const [filterViewName, setFilterViewName] = useState('');

  const onSubmitCallback = useCallback(() => onSubmit(filterViewName), [filterViewName, onSubmit]);

  const renderContents = useMemo(() => {
    if (error) {
      return (
        <>
          <ErrorIcon width={72} height={72} color={RED_500} />
          <Text css="margin: 12px 0 12px 0">{error}</Text>
        </>
      );
    }

    return (
      <>
        <SaveFilterMessageText>{t('save_filter_view_message')}</SaveFilterMessageText>
        <InputText
          placeholder={t('filter_name')}
          css="margin-bottom: 12px"
          onChange={e => setFilterViewName(e.currentTarget.value)}
        />
      </>
    );
  }, [error]);

  return (
    <>
      {renderContents}
      <ButtonContainer>
        <div>
          {error && (
            <OptionButton onClick={onClose} styleVariant={OptionButtonStyle.DESTRUCTIVE}>
              <Label>{t('okay')}</Label>
            </OptionButton>
          )}
          {!error && (
            <>
              <OptionButton onClick={onClose} styleVariant={OptionButtonStyle.CANCEL}>
                <Label>{t('cancel')}</Label>
              </OptionButton>
              <OptionButton
                onClick={onSubmitCallback}
                styleVariant={OptionButtonStyle.CONFIRMATION}
                disabled={!filterViewName?.length}
              >
                <Label>{t('save')}</Label>
              </OptionButton>
            </>
          )}
        </div>
      </ButtonContainer>
    </>
  );
};

export default SaveFilterViewForm;
