import { memo, type SVGProps } from 'react';

const ContractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M6.072 22.203c-.91 0-1.683-.318-2.32-.955-.637-.637-.955-1.41-.955-2.32v-1.012c0-.621.22-1.152.659-1.591.44-.44.97-.66 1.592-.66h.76V4.072c0-.63.223-1.167.666-1.61.444-.444.98-.665 1.61-.665h10.85c.63 0 1.166.222 1.61.665.444.443.665.98.665 1.61v14.856c0 .91-.318 1.683-.955 2.32-.637.637-1.41.955-2.32.955H6.072Zm11.862-2.275a.97.97 0 0 0 .713-.287.968.968 0 0 0 .287-.713V4.072H8.084v11.593h6.575c.63 0 1.166.222 1.61.666.444.443.665.98.665 1.61v.987c0 .284.096.521.288.713a.968.968 0 0 0 .712.287Zm-7.773-10.94c-.303 0-.558-.104-.766-.311a1.042 1.042 0 0 1-.311-.767c0-.303.104-.558.311-.766.208-.208.463-.312.767-.312h6.694c.304 0 .56.104.767.312.207.208.311.463.311.766 0 .304-.104.56-.311.767a1.042 1.042 0 0 1-.767.311h-6.695Zm0 3.155a1.04 1.04 0 0 1-.766-.311 1.041 1.041 0 0 1-.311-.766c0-.304.104-.559.311-.767.208-.207.463-.311.767-.311h6.694c.304 0 .56.104.767.312.207.207.311.462.311.766 0 .303-.104.558-.311.766a1.041 1.041 0 0 1-.767.311h-6.695Zm-4.09 7.785h8.588V17.94H5.072v.988a.97.97 0 0 0 .287.713c.192.191.43.287.713.287Zm0 0h-1 9.588-8.587Z"
    />
  </svg>
);

export default memo(ContractIcon);
