import { memo, useMemo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import RichText from 'components/core/typography/RichText';
import SecondaryText from 'components/core/typography/SecondaryText';
import SemiBoldText from 'components/core/typography/SemiBoldText';
import TextRow from 'components/core/typography/TextRow';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import {
  ListItem,
  ListItemDetails,
  ListItemSeparator,
  MAX_LIST_ITEM_TEXT_LENGTH,
} from 'components/ui/layouts/ListItem';
import { ArchiveBadge } from 'components/ui/shared/ArchiveBadge';
import { ConversationType } from 'store/api/graph/interfaces/types';
import type { ConversationResponseType } from 'store/api/graph/responses/responseTypes';
import { NEUTRAL_700 } from 'styles/tokens';
import { LINE_HEIGHT_DEFAULT } from 'styles/typography';
import { getRecentDateTime } from 'utils/dateUtils';
import { formatFullName, formatTextForPreview } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import AttachmentIcon from '../icons/AttachmentIcon';
import ChatIcon from '../icons/ChatIcon';
import EmailIcon from '../icons/EmailIcon';
import SMSIcon from '../icons/SMSIcon';
import Badge, { BadgeColors, Badges } from '../shared/Badge';

import type { ListItemProps } from './interfaces/ListItemProps';

const { t } = translate;

const MessageSnippet = styled(SecondaryText)`
  white-space: pre-wrap;
  line-height: ${LINE_HEIGHT_DEFAULT};
`;

interface Props extends ConversationResponseType, ListItemProps {
  selectedId?: string;
}

const ConversationListItem = ({ suffixIcon, listItemType, ...conversation }: Props) => {
  const {
    leadName,
    type,
    typeName,
    itemsCount,
    replied,
    readByMe,
    messageSnippet,
    subject,
    lastItemCreated,
    selectedId,
    id,
    attachmentsCount,
  } = conversation;

  const messagePreview = useMemo(
    () => formatTextForPreview(messageSnippet, MAX_LIST_ITEM_TEXT_LENGTH),
    [messageSnippet]
  );

  const isUnread = readByMe === false; // Unread specifically if false, `null` not a participant so it is not applicable

  return (
    <ListItem
      css="> :first-child { margin-right: 0px; }"
      suffixIcon={suffixIcon}
      listItemType={listItemType}
      isHighlighted={selectedId !== id && isUnread}
      isArchived={isItemArchived(conversation)}
    >
      <ListItemDetails>
        <TextRow css="justify-content: space-between;">
          <PrimaryText>{formatFullName(leadName)}</PrimaryText>
          <SecondaryText css="text-transform: capitalize;" title={getRecentDateTime(lastItemCreated) || ''}>
            {getRecentDateTime(lastItemCreated)}
          </SecondaryText>
        </TextRow>
        {subject && (
          <TextRow>
            <SemiBoldText title={subject}>{subject}</SemiBoldText>
          </TextRow>
        )}
        {messagePreview && (
          <TextRow>
            <MessageSnippet>
              <RichText>{messagePreview}</RichText>
            </MessageSnippet>
          </TextRow>
        )}
        <TextRow>
          <Badges>
            <ArchiveBadge isArchived={isItemArchived(conversation)} />
            {isUnread && (
              <Badge title={`${t('status')}: ${t('unread')}`} color={BadgeColors.NEUTRAL}>
                {t('unread')}
              </Badge>
            )}
            {!replied && type !== ConversationType.EMAIL && (
              <Badge title={`${t('status')}: ${t('unanswered')}`} color={BadgeColors.NEUTRAL}>
                {t('unanswered')}
              </Badge>
            )}
            <Badge title={`${typeName}: ${itemsCount}`}>
              {useMemo(
                () =>
                  ({
                    [ConversationType.EMAIL]: (
                      <EmailIcon height={10} width={10} css="margin-right: 7px;" color={NEUTRAL_700} />
                    ),
                    [ConversationType.SMS]: (
                      <SMSIcon height={10} width={10} color={NEUTRAL_700} css="margin-right: 7px;" />
                    ),
                  })[type] || <ChatIcon color={NEUTRAL_700} height={10} width={10} css="margin-right: 7px;" />,
                [type]
              )}
              {itemsCount}
            </Badge>
            {!!attachmentsCount && (
              <Badge title={`${t('attachments')}: ${attachmentsCount}`}>
                <AttachmentIcon height={10} width={10} color={NEUTRAL_700} css="margin-right: 7px;" />
                {attachmentsCount}
              </Badge>
            )}
          </Badges>
        </TextRow>
      </ListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(ConversationListItem);
