import type { MouseEventHandler } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { Clickable } from 'components/ui/shared/Button';
import { ListSelectionStyle } from 'enums/listSelection';
import { ElementTestId } from 'enums/testing';
import { DIVIDER } from 'styles/color';
import { SECTION_PADDING, SPACE_16 } from 'styles/spacing';
import { NEUTRAL_050, NEUTRAL_300 } from 'styles/tokens';
import { variants } from 'utils/styledUtils';

import type { ListSelectionOptionsProps } from './interfaces';
import ListSelectionCheckbox from './ListSelectionCheckbox';

export const GroupHeading = styled.div<{ styleVariant?: ListSelectionStyle; disabled?: boolean }>`
  width: 100%;
  height: 48px;
  background: ${NEUTRAL_050};
  border-bottom: 1px solid ${DIVIDER};
  display: flex;
  align-items: center;

  ${variants<ListSelectionStyle>('styleVariant', {
    [ListSelectionStyle.CONDENSED_STYLE]: css`
      height: 37px;
      padding: ${SPACE_16} 15px;
    `,
    [ListSelectionStyle.DEFAULT]: css`
      height: 48px;
      padding: ${SPACE_16} ${SECTION_PADDING};
    `,
  })}

  ${Label} {
    ${({ disabled }) =>
      disabled &&
      css`
        color: ${NEUTRAL_300};
      `}
  }
`;

const ListSelectionGroupHeaderClickable = styled(Clickable)`
  width: 100%;
`;

type ListSelectionGroupHeader = {
  /** Whether all the options for this group are checked */
  isChecked: boolean;
  /** Whether some but not all of the options for this group are checked */
  isIndeterminate: boolean;
  /** An optional id for testing */
  testId?: string;
  /** The callback to fire when the group header checkbox is toggled  */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Whether the header checkbox should appear disabled */
  disabled?: boolean;
} & Pick<ListSelectionOptionsProps, 'styleVariant' | 'isMultiSelect' | 'label'>;

const ListSelectionGroupHeader = ({
  label,
  onClick,
  styleVariant,
  isMultiSelect,
  isChecked,
  isIndeterminate,
  testId,
  disabled,
}: ListSelectionGroupHeader) =>
  !!label && (
    <ListSelectionGroupHeaderClickable disabled={!onClick || disabled} onClick={onClick} data-testid={testId}>
      <GroupHeading styleVariant={styleVariant} disabled={disabled}>
        {onClick && !disabled && (
          <ListSelectionCheckbox
            round={!isMultiSelect}
            checked={isChecked}
            indeterminate={isIndeterminate}
            data-testid={isIndeterminate ? ElementTestId.ICON_INDETERMINATE : undefined}
          />
        )}
        <Label>{label}</Label>
      </GroupHeading>
    </ListSelectionGroupHeaderClickable>
  );

export default ListSelectionGroupHeader;
