import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { LeadActivity } from 'enums/columns/leadActivity';
import { RooftopLeadForwarding } from 'enums/columns/rooftopLeadForwarding';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

import { LeadForwardingBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [LeadForwardingBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: LeadActivity.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [LeadForwardingBuilderFields.SOURCE_ID]: {
    label: 'lead_source',
    queryAlias: [RooftopLeadForwarding.SOURCE_NAMES],
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.ASYNC],
    required: true,
  },
  [LeadForwardingBuilderFields.EMAIL]: {
    label: 'email_one',
  },
  [LeadForwardingBuilderFields.ADF]: {
    label: 'adf',
    groupType: StepFieldType.SWITCH,
    tooltipContents: 'auto_lead_data_format_tooltip',
  },
};

export default DetailFields;
