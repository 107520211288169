export enum WebsiteDetailsBuilderFields {
  GROUP_ID = 'groupId',
  NAME = 'name',
  STATUS = 'status',
  URL = 'url',
  LOCATION = 'location',
  PHONE_NUMBER = 'phoneNumber',
  PRIMARY_ROOFTOP_ID = 'primaryRooftopId',
  GA4_PROPERTY_ID = 'ga4PropertyId',
  GOOGLE_ADS_ID = 'googleAdsId',
  LOCALES = 'locales',
  PRIMARY_LOCALE = 'primaryLocale',
}

export enum WebsiteBuilderStepId {
  WEBSITE_DETAILS = 'WEBSITE_DETAILS',
  WEBSITE_SORTING = 'WEBSITE_SORTING',
}
