import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import {
  getPhoneNumberByDepartmentAndDevice,
  getPhoneNumbersForInput,
  setQueryAliasForMutation,
} from 'components/sections/createModify/rooftopIntegration/builders/phoneCallTracking/utils';
import RooftopIntegration from 'components/sections/createModify/rooftopIntegration/steps/DetailsStep';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import {
  DeviceCategory,
  type PhoneCallTrackingRooftopIntegrationAttributes,
  RooftopDepartmentType,
  type TrackingPhoneNumber,
} from 'store/api/graph/interfaces/types';

class DetailsStep extends RooftopIntegration {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props, context);

    const {
      tier: { data },
    } = props;

    const phoneCallTrackingAttributes: PhoneCallTrackingRooftopIntegrationAttributes | undefined =
      data?.phoneCallTrackingAttributes;
    const phoneNumbers: TrackingPhoneNumber[] = phoneCallTrackingAttributes?.phoneNumbers || [];

    for (const field of this.fields) {
      switch (field.queryVar) {
        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.SALES,
            DeviceCategory.DESKTOP
          );
          break;
        }

        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.SERVICE,
            DeviceCategory.DESKTOP
          );
          break;
        }

        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.PARTS,
            DeviceCategory.DESKTOP
          );
          break;
        }

        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.SALES,
            DeviceCategory.MOBILE
          );
          break;
        }

        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.SERVICE,
            DeviceCategory.MOBILE
          );
          break;
        }

        case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER: {
          field.selectedValue = getPhoneNumberByDepartmentAndDevice(
            phoneNumbers,
            RooftopDepartmentType.PARTS,
            DeviceCategory.MOBILE
          );
          break;
        }

        default: {
          break;
        }
      }
    }
  }

  async save(): Promise<boolean> {
    const phoneNumbers = getPhoneNumbersForInput(this.fields);
    setQueryAliasForMutation(phoneNumbers, this.fields);

    const overrides = {
      phoneCallTrackingAttributes: {
        phoneNumbers,
      },
    };

    return super.save(overrides);
  }
}

export default DetailsStep;
