import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { ENTITY_PADDING } from 'styles/spacing';
import { NEUTRAL_0, NEUTRAL_050, NEUTRAL_100, SPACE_300, SPACE_400 } from 'styles/tokens';
import { Z_INDEX_1, Z_INDEX_2 } from 'styles/z-index';

import { GridSectionTitle } from './Grid';

export const CARD_LAYOUT_HEADER_HEIGHT = '61px';
export const TOOLBAR_HEIGHT = 65;

export const Section = styled.section<{ as?: any; to?: any }>`
  padding: ${SPACE_300} ${SPACE_400};
  border-bottom: 1px solid ${DIVIDER};
  display: flex;
  flex-direction: column;
  position: relative;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const HeaderSection = styled(Section)`
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  height: ${CARD_LAYOUT_HEADER_HEIGHT};
  background: ${NEUTRAL_0};
  z-index: ${Z_INDEX_2};
  justify-content: center;
  border-bottom: 1px solid ${NEUTRAL_100};

  > * {
    color: ${BODY_TEXT};

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  ${PrimaryText} {
    top: 0;
    flex: 1;
    margin: 0;
  }
`;

export const LinkedSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid ${DIVIDER};
`;

export const BottomToolbar = styled.div`
  display: flex;
  height: ${TOOLBAR_HEIGHT}px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  background: ${NEUTRAL_050};
  border-top: 1px solid ${DIVIDER};
  z-index: ${Z_INDEX_1};
`;

export const FullWidthSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
  > ${GridSectionTitle} {
    padding-left: ${ENTITY_PADDING};
    padding-right: ${ENTITY_PADDING};
  }
`;

export const DetailsContainer = styled.div<{ hasToolbar?: boolean }>`
  ${({ hasToolbar }) => css`
    height: ${hasToolbar ? `calc(100% - ${TOOLBAR_HEIGHT}px)` : '100%'};
  `}
  overflow-y: auto;
`;
