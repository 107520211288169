import { gql } from '@apollo/client';

/**
 * Since both Integration List and Integration Details displays the type name differently, we create
 * a separate fragment `title` to represent the actual title name that we want to display.
 */
export const rooftopIntegrationContainerItemFragment = gql`
  fragment RooftopIntegrationContainerItemFragment on RooftopIntegration {
    title: integration {
      value: name
    }
  }
`;
