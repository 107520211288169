import type { Location, NavigateFunction, NavigationType, PathMatch } from 'react-router-dom';

import { MatchPattern, useRouter } from './useRouter';

/**
 * Typing for the router hook `useRouter` used from a Class component.
 */
export type RouterHooksHOCProps = {
  navigationType: NavigationType;
  push: NavigateFunction;
  replace: (path: any) => void;
  pathname: string;
  query: string | string[] | null | undefined;
  location: Location;
  navigate: NavigateFunction;
};

/**
 * Router HOC
 *
 * Used to add router functionality to class components.
 */
export const withRouter = (Component: any) => (props: any) => {
  const router = useRouter();

  /**
   * Pre-compute `match` in FC before passing to class component as `match`
   * has required params and hooks cannot be called directly in a class component.
   */
  const match: Record<string, PathMatch<string>> = Object.entries(MatchPattern).reduce((acc, [_, pattern]) => {
    acc[pattern] = router.match(pattern);
    return acc;
  }, {});

  return <Component router={router} match={match} {...props} />;
};
