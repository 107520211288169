import { memo, type SVGProps } from 'react';

const DeletedUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m19.5 11.122-1.304 1.28c-.216.21-.478.32-.787.326a1.07 1.07 0 0 1-.805-.332 1.085 1.085 0 0 1-.322-.799c0-.317.107-.583.322-.799l1.28-1.298-1.28-1.28a1.058 1.058 0 0 1-.322-.786c0-.312.107-.583.322-.81.228-.229.498-.343.809-.343.31 0 .58.114.808.342L19.5 7.91l1.28-1.299c.21-.228.473-.343.785-.345.313-.002.584.111.812.34.228.227.341.497.341.807 0 .311-.113.58-.341.809L21.097 9.5l1.299 1.304c.224.228.333.493.329.796-.004.303-.12.568-.348.796a1.164 1.164 0 0 1-.799.332 1.062 1.062 0 0 1-.799-.332L19.5 11.122Zm-10.482.776c-1.16 0-2.15-.41-2.972-1.232-.82-.82-1.231-1.811-1.231-2.971 0-1.16.41-2.15 1.231-2.969.822-.819 1.812-1.228 2.972-1.228s2.15.41 2.971 1.228c.822.82 1.232 1.81 1.232 2.969 0 1.16-.41 2.15-1.232 2.971-.82.822-1.811 1.232-2.971 1.232ZM.815 18.03v-.74c0-.603.156-1.158.467-1.664a3.1 3.1 0 0 1 1.246-1.16c1.046-.52 2.11-.912 3.192-1.174a14.11 14.11 0 0 1 6.608-.003c1.082.26 2.142.65 3.18 1.171.519.266.934.652 1.246 1.156.311.506.467 1.063.467 1.674v.74c0 .63-.221 1.166-.665 1.61-.444.443-.98.665-1.61.665H3.09c-.63 0-1.167-.222-1.61-.665a2.193 2.193 0 0 1-.665-1.61Z"
    />
  </svg>
);
export default memo(DeletedUserIcon);
