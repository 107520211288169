import { useMemo } from 'react';

import type { DocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';

import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { getStoredTableConfiguration } from 'components/ui/tables/TableHelpers';
import { TableType } from 'components/ui/tables/tableSettings/TableSettings';
import { Rooftop } from 'enums/columns/rooftop';
import { ItemsColumn } from 'enums/itemsColumn';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import { columns } from 'store/api/graph/fragments/columns';
import { filters } from 'store/api/graph/fragments/filters';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { rooftopDetail } from 'store/api/graph/fragments/rooftopDetail';
import { rooftopList } from 'store/api/graph/fragments/rooftopList';
import { tag } from 'store/api/graph/fragments/tag';
import { userList } from 'store/api/graph/fragments/userList';
import { whiteLabel } from 'store/api/graph/fragments/whiteLabel';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { parseConnectionParams } from 'utils/apiUtils';
import { gqlFormatTableColumnFields } from 'utils/gqlUtils';

export const rooftopDetailsQuery = gql`
  query RooftopDetailsContainerQuery($id: ID!) {
    item: rooftop(id: $id) {
      ### Details tab
      ...RooftopDetailFragment

      ### Linked tab
      retailItemsCount
      tradeInItemsCount
      leadActivitiesCount
      leadsCount
      conversationsCount
      tasksCount
      departmentsCount
      usersCount
      rolesCount
      mailboxesCount
      leadForwardsCount
      departmentsCount
      retailBulkAdjustmentsCount
      retailPricingsCount
    }

    tags(rooftopId: [$id]) {
      ...TagFragment
    }
  }
  ${tag}
  ${rooftopDetail}
`;

export const rooftopCondensedListQuery = gql`
  query RooftopCondensedListQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sort: [SortInput!]
    $keyword: String
    $filter: RooftopConnectionFilterInput
  ) {
    connection: rooftopConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      keyword: $keyword
      filter: $filter
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RooftopListFragment
        }
      }
    }
  }
  ${pageInfo}
  ${rooftopList}
`;

export const rooftopContainerQuery = gql`
  query RooftopConnectionQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $sort: [SortInput!]
    $searchFilter: FacetSearchInput
    $filter: RooftopConnectionFilterInput
    $d_accountRepNameOn: Boolean!
    $d_whiteLabelNameOn: Boolean!
    $d_zipCodeOn: Boolean!
    $d_regionOn: Boolean!
    $d_countryOn: Boolean!
    $d_countryCodeOn: Boolean!
    $d_placeIdOn: Boolean!
    $d_timeZoneOn: Boolean!
    $d_localeOn: Boolean!
    $d_descriptionOn: Boolean!
    $d_emailBillingOn: Boolean!
    $d_emailGeneralOn: Boolean!
    $d_faxNumberOn: Boolean!
    $d_isOpenOn: Boolean!
    $d_websiteOn: Boolean!
    $d_createdOn: Boolean!
    $d_updatedOn: Boolean!
  ) {
    connection: rooftopConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      sort: $sort
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      filters {
        ...FiltersFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RooftopListFragment
          accountRepName: accountRep @include(if: $d_accountRepNameOn) {
            ...UserListFragment
          }
          description @include(if: $d_descriptionOn)
          emailBilling @include(if: $d_emailBillingOn)
          emailGeneral @include(if: $d_emailGeneralOn)
          faxNumber @include(if: $d_faxNumberOn)
          whiteLabelName: group @include(if: $d_whiteLabelNameOn) {
            id
            whiteLabel {
              ...WhiteLabelFragment
            }
          }
          isOpen @include(if: $d_isOpenOn)
          locale @include(if: $d_localeOn) {
            displayName
          }
          country: location @include(if: $d_countryOn) {
            value: country
          }
          countryCode: location @include(if: $d_countryCodeOn) {
            value: countryCode
          }
          placeId: location @include(if: $d_placeIdOn) {
            value: placeId
          }
          region: location @include(if: $d_regionOn) {
            value: region
          }
          timeZone: location @include(if: $d_timeZoneOn) {
            value: timeZone
          }
          zipCode: location @include(if: $d_zipCodeOn) {
            value: zipCode
          }
          website @include(if: $d_websiteOn)
          created @include(if: $d_createdOn)
          updated @include(if: $d_updatedOn)
          tagsCount
        }
      }
    }
  }
  ${MultilingualString}
  ${columns}
  ${filters}
  ${pageInfo}
  ${whiteLabel}
  ${rooftopList}
  ${userList}
`;

const staticColumns: string[] = [
  ItemsColumn.PHOTOS,
  ItemsColumn.SELECT,
  Rooftop.NAME,
  Rooftop.GROUP_NAME,
  Rooftop.ADDRESS,
  Rooftop.CITY,
  Rooftop.REGION_CODE,
  Rooftop.ACTIVE,
];

export const useRooftopConnectionQuery = (variables = {}, options?: any): CustomQueryResult => {
  const formattedConnectionParams = parseConnectionParams(variables);
  const tableConfigurationNext: TableCellData[] = getStoredTableConfiguration(TableType.ROOFTOPS);

  const formattedTableColumns = gqlFormatTableColumnFields(
    rooftopContainerQuery,
    tableConfigurationNext,
    staticColumns
  );

  return useQuery(rooftopContainerQuery, {
    variables: { ...formattedConnectionParams, ...formattedTableColumns },
    options,
  });
};

export const useRooftopDetailsContainerQuery = (query: DocumentNode, config: QueryConfig): CustomQueryResult => {
  const formattedConfig = useMemo(
    () => ({
      ...config,
      variables: {
        ...config.variables,
      },
      ignore: !config.variables.id,
    }),
    [config]
  );

  return useQuery(query, formattedConfig);
};
