import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import { FormControl, FormHelperLabel, FormSubmitButton } from 'components/ui/forms/shared/Form';
import { Input } from 'components/ui/forms/shared/InputText';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import AuthLayout, { SuccessLayout } from 'components/ui/layouts/AuthLayout';
import Spinner from 'components/ui/loading/Spinner';
import BaseClass from 'components/ui/shared/BaseClass';
import { userForgotPassword } from 'containers/auth/AuthQueries';
import { ApolloContextHeaders } from 'enums/apollo';
import { RoutePath } from 'enums/routePath';
import type { ApiError } from 'store/api/graph/interfaces/apiErrors';
import { client } from 'store/apollo/ApolloClient';
import { SPACE_100 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

type Props = object;
interface State {
  username: string;
  isCompleted: boolean;
  isSubmitting: boolean;
  error?: ApiError;
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACE_100};
`;

/**
 * Forgot Password page.
 * Used by users to recover their password.
 */
class ForgotPassword extends BaseClass<Props, State> {
  state = { username: '', isCompleted: false, isSubmitting: false, error: undefined };

  onUsernameChange = e => {
    this.setState({ username: e.target.value });
  };

  onFormSubmit = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true, error: undefined });

    await client
      .mutate({
        mutation: userForgotPassword,
        variables: { username: this.state.username },
        context: { headers: ApolloContextHeaders.NO_AUTH_REQUIRED },
      })
      .then(this.onFormSubmitSuccess)
      .catch(this.onFormSubmitError);
  };

  reset = () => {
    this.setState({ isCompleted: false });
  };

  onFormSubmitSuccess = () => {
    this.setState({ isCompleted: true, isSubmitting: false, error: undefined });
  };

  onFormSubmitError = (error: Error) => {
    this.setState({ isSubmitting: false, error });
  };

  render() {
    const { username, isCompleted, isSubmitting, error } = this.state;
    const { t } = translate;

    // Render completed state
    if (isCompleted) {
      return <SuccessLayout title={t('email_sent')} message={t('password_recovery_success_message')} />;
    }

    // Render base state
    return (
      <AuthLayout logo={<EDealerIcon />} title={t('forgot_password')} error={error}>
        <form onSubmit={event => void this.onFormSubmit(event)}>
          <FormControl>
            <LabelContainer>
              <Label>{t('email_one')}</Label>
              <FormHelperLabel>{t('enter_email_password_recovery')}</FormHelperLabel>
            </LabelContainer>
            <Input
              type="text"
              placeholder={t('email_one')}
              value={username}
              onChange={this.onUsernameChange}
              autoFocus
            />
          </FormControl>
          <FormSubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner /> : t('send')}
          </FormSubmitButton>
          <FormControl>
            <Link to={RoutePath.LOGIN}>{t('back_to_login')}</Link>
          </FormControl>
        </form>
      </AuthLayout>
    );
  }
}

export default ForgotPassword;
