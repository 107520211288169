import DomPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

import { renderRawHtmlInline } from 'utils/domUtils';
import { truncateBetween } from 'utils/stringUtils';

interface Props extends Record<string, unknown> {
  children?: string | null;
}

/**
 * Displays simple rich text formatting.
 *
 * e.g. Makes urls/emails clickable and properly displays tags such as <br>, <b> etc.
 */
const RichText = ({ children: text, ...props }: Props) => {
  if (!text) {
    return null;
  }

  const sanitizedText = DomPurify.sanitize(text, { USE_PROFILES: { html: true } });

  return renderRawHtmlInline(
    linkifyHtml(sanitizedText, {
      className: 'rich-text-link',
      format: {
        url: (displayedUrl: string) => truncateBetween(displayedUrl, 25, 15),
      },
      target: (_href, type) => (type === 'url' ? '_blank' : '_self'),
    }),
    props
  );
};

export default RichText;
