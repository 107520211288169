import type { ObservableQuery } from '@apollo/client';

import type { MetadataResponse } from 'components/core/createModify/interfaces/queryMethods';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';

/** Use this util in `getMetaData` when an entity fetches the required metadata */
export function subscribeWithMetadata<TQuery extends Record<string, any>, TVariables extends Record<string, any>>(
  /** Result of client.watchQuery for metadata query */
  observable: ObservableQuery<TQuery, TVariables>,
  /** OnMetaUpdate passed in from `getMetaData` */
  onMetaUpdate: (metadata: Record<string, any>) => void
): Promise<MetadataResponse<TQuery>> {
  return new Promise((resolve, reject) => {
    const subscription = observable.subscribe({
      next: res => {
        /**
         *  Top level `metadata` from response not used, contents are
         *  instead spread for builder `metadata` structure consistency
         */
        const metadata = {
          ...res.data.metadata,
          ...res.data,
          metadata: undefined,
        };

        resolve({ subscription, metadata });
        onMetaUpdate(metadata);
      },
      error: (error: Error) => {
        logApiError(error);
        reject(error);
      },
    });
  });
}
