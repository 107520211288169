import axios from 'axios';

import LoggingService from 'components/core/logging/LoggingService';

/**
 * Fetches `/manifest.json`, extracts the `buildTime` value and compares it to the
 * environment variable value set in the application at build-time. If the `buildTime`
 * value from the server is not equal to what's set within the application, we know
 * it's out of sync and should be reloaded.
 */

export const reloadAppIfOutdated = async () => {
  const manifestURL = `/manifest.json?${Date.now()}`;
  let manifest;

  try {
    const response = await axios(manifestURL);
    manifest = response?.data;
  } catch (error) {
    LoggingService.warn({
      message: 'Issue with retrieving manifest:',
      messageContext: error as Error,
    });
  }

  if (
    manifest?.buildTime &&
    process.env.REACT_APP_BUILD_TIME &&
    manifest?.buildTime !== process.env.REACT_APP_BUILD_TIME
  ) {
    window.location?.reload();
  }
};
