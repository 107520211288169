import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { ContainerStyles } from 'components/core/createModify/stepFields/StepFieldLayout';
import { StepFieldType } from 'enums/stepFieldType';
import { ConversationItemType } from 'store/api/graph/interfaces/types';
import { objectToStepFieldArray } from 'utils/formatting/createModifyFormatUtils';

import { LeadActivityReplyBuilderFields } from '../steps/interfaces';

export const LeadActivityReplyFields: StepFields = {
  [LeadActivityReplyBuilderFields.MESSAGE]: {
    label: 'message',
    groupType: StepFieldType.TEXT_AREA,
    active: true,
    containerStyles: ContainerStyles.FULL_HEIGHT,
  },
};

class LeadActivityReplyStep extends StepComponentCore {
  constructor(props) {
    super(props);
    const {
      tier: { activeStep },
    } = props;
    this.fields = objectToStepFieldArray(activeStep?.fields);
  }

  componentDidMount() {
    this.setOnClosePrompt(this.defaultClosePrompt);
  }

  async save() {
    const {
      tier: { seededData },
    } = this.props;

    const success = await super.save({
      leadActivityId: seededData?.leadActivityId,
      type: ConversationItemType.MESSAGE,
    });

    if (success) {
      this.setOnClosePrompt(undefined);
    }

    return success;
  }
}

export default LeadActivityReplyStep;
