import { gql } from '@apollo/client';

import { savedConnectionFilterFragment } from 'store/api/graph/fragments/savedConnectionFilter';

export const saveFilterViewCreate = gql`
  mutation saveFilterViewCreate(
    $name: String
    $type: EntityType
    $d_isAppointmentConnectionFilter: Boolean!
    $d_isTaskConnectionFilter: Boolean!
    $d_isLeadConnectionFilter: Boolean!
    $d_isConversationConnectionFilter: Boolean!
    $d_isTradeInConnectionFilter: Boolean!
    $d_isRetailItemConnectionFilter: Boolean!
    $appointmentConnectionFilterInput: AppointmentConnectionFilterInput
    $conversationConnectionFilterInput: ConversationConnectionFilterInput
    $leadConnectionFilterInput: LeadConnectionFilterInput
    $retailItemConnectionFilterInput: RetailItemConnectionFilterInput
    $taskConnectionFilterInput: TaskConnectionFilterInput
    $tradeInConnectionFilterInput: TradeInItemConnectionFilterInput
  ) {
    savedConnectionFilterCreate(
      name: $name
      type: $type
      appointmentConnectionFilterInput: $appointmentConnectionFilterInput
      conversationConnectionFilterInput: $conversationConnectionFilterInput
      leadConnectionFilterInput: $leadConnectionFilterInput
      retailItemConnectionFilterInput: $retailItemConnectionFilterInput
      taskConnectionFilterInput: $taskConnectionFilterInput
      tradeInConnectionFilterInput: $tradeInConnectionFilterInput
    ) {
      ...SavedConnectionFilterFragment
    }
  }

  ${savedConnectionFilterFragment}
`;

export const saveFilterViewList = gql`
  query SavedFilterViewListQuery(
    $type: EntityType
    $d_isAppointmentConnectionFilter: Boolean!
    $d_isTaskConnectionFilter: Boolean!
    $d_isLeadConnectionFilter: Boolean!
    $d_isConversationConnectionFilter: Boolean!
    $d_isTradeInConnectionFilter: Boolean!
    $d_isRetailItemConnectionFilter: Boolean!
  ) {
    savedConnectionFilters(type: $type) {
      ...SavedConnectionFilterFragment
    }
  }

  ${savedConnectionFilterFragment}
`;

export const saveFilterViewDelete = gql`
  mutation SaveFilterViewDelete($id: ID!) {
    savedConnectionFilterDelete(id: $id)
  }
`;
