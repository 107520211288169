import { gql } from '@apollo/client';

import { retailPricingConfiguration } from 'store/api/graph/fragments/retailPricingConfiguration';

import { monetaryAmount } from './monetaryAmount';
import { MultilingualString } from './multilingualString';

export const retailPricingFinanceConfiguration = gql`
  fragment RetailPricingFinanceConfigurationFragment on FinanceRetailPricingConfiguration {
    ...RetailPricingConfigurationFragment
    additionalFixedAmounts {
      amount {
        ...MonetaryAmountFragment
      }
      term
    }
    availablePaymentFrequencies
    availablePaymentFrequenciesNames
    defaultPaymentFrequency
    defaultPaymentFrequencyName
    availableTerms
    defaultTerm
    defaultTermType
    defaultTermTypeName
    interestRateType
    interestRateTypeName
    interestRates {
      interestRate
      term
    }
    autoPriceAdjustmentConfiguration {
      targetPriceFieldName
      targetPriceField
      label {
        ...MultilingualStringFragment
      }
      disclaimerTemplate {
        ...MultilingualStringFragment
      }
    }
  }
  ${retailPricingConfiguration}
  ${MultilingualString}
  ${monetaryAmount}
`;
