import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  retailItemPriceAdjustmentCreate,
  retailItemPriceAdjustmentModify,
} from 'components/sections/createModify/retailItemPriceAdjustment/RetailItemPriceAdjustmentCreateModifyQueries';
import RetailItemPriceAdjustmentQueryMethods from 'components/sections/createModify/retailItemPriceAdjustment/RetailItemPriceAdjustmentQueryMethods';
import RetailItemPriceAdjustmentFields from 'components/sections/createModify/retailItemPriceAdjustment/steps/RetailItemPriceAdjustmentFields';
import RetailItemPriceAdjustmentStep from 'components/sections/createModify/retailItemPriceAdjustment/steps/RetailItemPriceAdjustmentStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

export enum RetailItemPriceAdjustmentBuilderStep {
  ADJUSTMENT = 'RETAIL_ITEM_PRICE_ADJUSTMENT',
}

const RetailItemPriceAdjustmentBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_CREATE]: {
    resourceType: ResourceType.RETAIL_ITEMS,
    steps: [
      {
        id: RetailItemPriceAdjustmentBuilderStep.ADJUSTMENT,
        step: RetailItemPriceAdjustmentStep,
        stepName: 'add_adjustment',
        isEnabled: true,
        fields: RetailItemPriceAdjustmentFields,
      },
    ],
    refetchQueries: RetailItemPriceAdjustmentQueryMethods.refetchQueries,
    mutations: {
      create: retailItemPriceAdjustmentCreate,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
  },
  [BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_MODIFY]: {
    resourceType: ResourceType.RETAIL_ITEMS,
    steps: [
      {
        id: RetailItemPriceAdjustmentBuilderStep.ADJUSTMENT,
        step: RetailItemPriceAdjustmentStep,
        stepName: 'modify_adjustment',
        isEnabled: true,
        fields: RetailItemPriceAdjustmentFields,
      },
    ],
    mutations: {
      modify: retailItemPriceAdjustmentModify,
    },
    requiredPermissions: [{ resource: ResourceType.RETAIL_ITEMS, level: AccessLevel.FULL }],
  },
};

export default RetailItemPriceAdjustmentBuilders;
