import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import { WarrantyListItem } from 'components/ui/lists/WarrantyListItem';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { UserModifyParameter } from 'store/api/graph/interfaces/types';

import { UserDetailBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [UserDetailBuilderFields.SCOPE]: {
    label: 'scope',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    overrideSubStepRenderElement: WarrantyListItem,
    subStep: [SubStepType.DEFAULT],
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
    settings: {
      hideSearchHeader: true,
    } as ListSelectionSettings,
    forceRequired: true,
  },
  [UserDetailBuilderFields.WHITE_LABEL_ID]: {
    label: 'white_label',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'whiteLabels',
  },
  [UserDetailBuilderFields.GROUP_ID]: {
    label: 'group',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [UserDetailBuilderFields.ROOFTOP_IDS_LIMITED_TO]: {
    label: 'rooftops',
    queryAlias: 'rooftopName',
    groupType: StepFieldType.BADGES,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    hasSeparator: true,
  },
  [UserDetailBuilderFields.AVATAR_IMAGE]: {
    label: 'avatar_one',
    groupType: StepFieldType.PHOTO,
    settings: {
      isAvatar: true,
      canDelete: true,
    },
    hasSeparator: true,
    clear: { field: UserModifyParameter._avatarImage },
  },
  [UserDetailBuilderFields.EMAIL]: {
    label: 'email_one',
  },
  [UserDetailBuilderFields.FIRST_NAME]: {
    label: 'first_name',
  },
  [UserDetailBuilderFields.LAST_NAME]: {
    label: 'last_name',
  },
  [UserDetailBuilderFields.TITLE]: {
    label: 'title',
    clear: { field: UserModifyParameter._title },
  },
  [UserDetailBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: UserModifyParameter._phoneNumber },
  },
  [UserDetailBuilderFields.CELL_NUMBER]: {
    label: 'cell_number',
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: UserModifyParameter._cellNumber },
  },
  [UserDetailBuilderFields.SUBSCRIBED_EMAIL]: {
    label: 'receive_email_notifications',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [UserDetailBuilderFields.LOCALE]: {
    label: 'locale',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'metadata.mutation.user.locale',
    clear: { field: UserModifyParameter._locale },
  },
};

export default DetailFields;
