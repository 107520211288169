import RooftopIntegration from 'components/sections/createModify/rooftopIntegration/steps/DetailsStep';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { IntegrationType } from 'store/api/graph/interfaces/types';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';

class DetailsStep extends RooftopIntegration {
  async save(): Promise<boolean> {
    const {
      tier: { isCreating },
    } = this.props;

    const integrationTypeField = getStepField(RooftopIntegrationDetailsFields.INTEGRATION_TYPE, this.fields);

    const overrides = isCreating
      ? {
          ...(integrationTypeField.selectedValue?.id === IntegrationType.INVENTORY_EXPORT && {
            inventoryExportAttributes: {
              filter: {},
            },
          }),
        }
      : undefined;

    return super.save(overrides);
  }
}

export default DetailsStep;
