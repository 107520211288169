import { gql } from '@apollo/client';

import { leadName } from 'store/api/graph/fragments/leadName';

import { listPhoto } from './listPhoto';
import { MultilingualString } from './multilingualString';
import { rooftopList } from './rooftopList';
import { tag } from './tag';

export const tradeInItemList = gql`
  fragment TradeInItemListFragment on TradeInItem {
    id
    archived

    appraisals {
      averageAppraisalAmount {
        formattedAmountRounded
      }
      numOfAppraisalsWithAmount
    }
    primaryPhoto {
      ...ListPhotoFragment
    }
    rooftopName: rooftop {
      ...RooftopListFragment
    }
    year
    makeName: make {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    modelName: model {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModelName: subModel {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    tradeInItemStatus: status ## This is a special case for tradeIn because status is also used in retail and causes errors when the two fragments are used in conjunction
    statusName
    accidentAmount {
      amount
      formattedAmountRounded
    }
    appointmentsCount
    conditionScore
    lead {
      ...LeadNameFragment
    }
    leadNote
    guaranteedValue {
      amount {
        formattedAmountRounded
      }
    }
    tagName: tags {
      ...TagFragment
    }
    trimName: trim {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    vin
    vehicleAttributes: attributes {
      ... on VehicleAttributes {
        exteriorColor
        exteriorColorName
        transmission
        transmissionName
        mileage {
          formattedAmount
        }
        displacement {
          formattedAmount
        }
        cylinders
        fuelType
        fuelTypeName
        bodyType
        bodyTypeName
        interiorColor
        interiorColorName
        numberOfDoors
        numberOfPassengers
        driveTrain
        driveTrainName
        options
        optionsNames
      }
    }
    motorcycleAttributes: attributes {
      ... on MotorcycleAttributes {
        mileage {
          formattedAmount
        }
        displacement {
          formattedAmount
        }
        color
        colorName
        cylinders
        fuelType
        fuelTypeName
        category
        categoryName
      }
    }
  }
  ${MultilingualString}
  ${leadName}
  ${tag}
  ${listPhoto}
  ${rooftopList}
`;
