import { cloneDeep, isEqual, partition } from 'lodash-es';
import styled from 'styled-components/macro';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import LoggingService from 'components/core/logging/LoggingService';
import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import { getRoleOptions } from 'components/sections/shared/ItemMetaHelpers';
import Checkbox, { CheckboxSizes } from 'components/ui/forms/shared/Checkbox';
import { Clickable } from 'components/ui/shared/Button';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import type { AccessLevelDescription, Permission, ResourceDescription } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceTier, ResourceType } from 'store/api/graph/interfaces/types';
import { DIVIDER } from 'styles/color';
import { LINE_HEIGHT_DEFAULT } from 'styles/typography';
import type { RequiredPermissions } from 'types/Permissions';
import { defineFieldValues, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { hasWhiteLabelScopedAccess } from 'utils/permissionUtils';

import { UserPermissionBuilderFields } from './interfaces';

const { t } = translate;

const LevelContainer = styled(Clickable)`
  display: flex;
  flex-direction: row;
  padding: 15px 17px 21px;
  border-bottom: 1px solid ${DIVIDER};
  text-align: left;

  > * {
    /** Targeting the checkbox */
    &:first-child {
      top: 2px;
      margin-right: 15px;
    }
  }
  ${Text} {
    white-space: normal;
    line-height: ${LINE_HEIGHT_DEFAULT};
  }
`;

const NonRemovablePermissions: RequiredPermissions = [
  { resource: ResourceType.METADATA, level: AccessLevel.BASIC },
  { resource: ResourceType.MAKES_MODELS_SUB_MODELS_TRIMS, level: AccessLevel.BASIC },
  { resource: ResourceType.TAGS, level: AccessLevel.BASIC },
  { resource: ResourceType.USERS, level: AccessLevel.BASIC },
];

// The type of options that the role field uses
type RoleFieldOptions = StepFieldOptions & {
  permissions?: Permission[];
};

class PermissionsStep extends StepComponentCore {
  constructor(props: StepComponentProps) {
    super(props);
    const {
      tier: { data: currentData, activeStep, metadata, formData, stepFieldData, isCreating },
    } = props;
    const data = formData || currentData;

    // If a new user is being created, the scope information comes from the scope StepField in the previous step
    const scope = isCreating ? stepFieldData?.scope : data.scope;

    // Determining visible fields based on results of resources query
    const fields: StepFields = cloneDeep(activeStep?.fields || {});

    /**
     * Checks whether or not the given ResourceType exists in `fields`,
     * throwing a warning and returning true if it indeed does not exist.
     * Returns false otherwise.
     */
    const resourceDoesNotExist = (resource: ResourceType) => {
      if (!(resource in fields)) {
        LoggingService.debug({
          message: `ResourceType ${resource} is missing from the User Permissions step fields. Please regenerate types.`,
        });
        return true;
      }
      return false;
    };

    /*
     * Partition the fields by available permissions, by user's tier;
     * only `GLOBAL` and `WHITE_LABEL` may access `INTERNAL`.
     * The non scoped fields will be omitted and hidden.
     */
    const [scopedResources, nonScopedResources] = partition(
      metadata.resources as ResourceDescription[],
      ({ tier }) => tier !== ResourceTier.INTERNAL || hasWhiteLabelScopedAccess(scope)
    );

    for (const { resource, resourceName, levels } of scopedResources) {
      if (resourceDoesNotExist(resource)) {
        continue;
      }
      const nonRemovableResource = NonRemovablePermissions?.find(
        ({ resource: nonRemovableTarget }) => nonRemovableTarget === resource
      );
      fields[resource].label = resourceName;
      fields[resource].displayType = setDisplayTypes([
        { type: StepFieldDisplayType.HIDDEN, active: false },
        { type: StepFieldDisplayType.OMITTED, active: true },
      ]);
      fields[resource].options = levels.map(({ level, levelName, description }) => ({
        id: level,
        name: levelName,
        description,
      })) as StepFieldOptions[];

      const options = fields[resource].options;
      // Setting the default selected value if applicable
      fields[resource].selectedValue =
        nonRemovableResource && options && typeof options !== 'string'
          ? options.find(({ id }) => id === nonRemovableResource.level)
          : { name: t('no_access') };
    }

    for (const { resource } of nonScopedResources) {
      if (resourceDoesNotExist(resource)) {
        continue;
      }
      fields[resource].displayType = setDisplayTypes([
        { type: StepFieldDisplayType.HIDDEN, active: true },
        { type: StepFieldDisplayType.OMITTED, active: true },
      ]);
    }

    // Pre-populating data
    if (data.permissions as Permission[]) {
      for (const { resource, level, levelName } of data.permissions as Permission[]) {
        fields[resource].selectedValue = { id: level, name: levelName };
      }
    }

    // Assigning pre-defined values if available
    this.fields = defineFieldValues(
      objectToStepFieldArray(fields, {
        [UserPermissionBuilderFields.ROLE]: {
          selectedValue: data.role || { name: t('custom') },
          displayType: setDisplayTypes({ type: StepFieldDisplayType.OMITTED, active: true }),
        },
      }),
      data,
      metadata
    );

    // Match permission roles for any pre-populated permission data
    void this.matchPermissionsRole();

    this.asyncConfigurations = {
      [UserPermissionBuilderFields.ROLE]: {
        request: () => getRoleOptions(data.groupName?.id),
      },
    };
  }

  matchPermissionsRole = async () => {
    const {
      tier: { data: currentData, formData },
    } = this.props;
    const data = formData || currentData;
    const roleField = this.fields.find(({ queryVar }) => queryVar === UserPermissionBuilderFields.ROLE)!;

    /**
     * Get roles, using the preexisting options if they have a `permissions` property,
     * or retrieving that from the server otherwise
     */
    const availableRoles: {
      id: string;
      name: string;
      permissions: { id: string; name: string; resource: ResourceType }[];
    }[] =
      roleField.options && (roleField.options as RoleFieldOptions[])[0]?.permissions !== undefined
        ? roleField.options
        : await getRoleOptions(data.groupName?.id);

    // Get permissions
    const permissions = this.getFormattedPermissions();

    // Determine if permissions match up with any existing roles, else use custom
    const currentRole = availableRoles.find(role => {
      const rolePermissions = role.permissions.map(({ id, resource }) => ({ resource, level: id }));
      return (
        rolePermissions.length === permissions.length &&
        rolePermissions.every(rolePermission => !!permissions.some(permission => isEqual(rolePermission, permission)))
      );
    }) || { name: t('custom') };

    roleField.selectedValue = currentRole;
    this.forceUpdate();
  };

  renderPermissionLevels = (stepField: StepField) => {
    const {
      tier: {
        metadata: { resources },
      },
    } = this.props;
    const levels: Partial<AccessLevelDescription>[] = [
      ...(resources.find(({ resource }) => resource === stepField.queryVar)?.levels || []),
    ];

    if (!NonRemovablePermissions?.some(({ resource }) => resource === stepField.queryVar)) {
      levels.unshift({ levelName: t('no_access') });
    }

    return levels.map(({ level, levelName, description }) => (
      <LevelContainer
        onClick={() => {
          this.onFieldSelection(stepField, { id: level, name: levelName });
          // If a stepfield has been changed from its previously set value, the role will always become 'custom'
          void this.matchPermissionsRole();

          void this.toggleSubPanel();
        }}
        key={level || ''}
      >
        <Checkbox round size={CheckboxSizes.LARGE} checked={level === stepField.selectedValue?.id} />
        <div>
          <PrimaryText>{levelName}</PrimaryText>
          <Text>{description}</Text>
        </div>
      </LevelContainer>
    ));
  };

  // Overriding field selection callback
  onFieldSelection(stepField: StepField, value: any) {
    // On role, preset all permissions
    if (stepField.queryVar === UserPermissionBuilderFields.ROLE) {
      // Use pre-defined permissions that come from the selected role
      for (const field of this.fields.filter(({ queryVar }) => queryVar !== stepField.queryVar)) {
        // Mapping permissions if the exist, otherwise unset `selectedValue`
        field.selectedValue = value.permissions.find(({ resource }) => resource === field.queryVar) || {
          name: t('no_access'),
        };
      }
    }

    // Clear any errors
    this.clearErrors();

    super.onFieldSelection(stepField, value);
  }

  clearErrors = () =>
    this.setTier({
      errors: [],
    });

  async toggleSubPanel(stepField?: StepField) {
    // Render permission options
    this.setState({
      childrenBeforeSubStep:
        !!stepField &&
        stepField.queryVar !== UserPermissionBuilderFields.ROLE &&
        this.renderPermissionLevels(stepField),
    });

    await super.toggleSubPanel(stepField);
  }

  async save() {
    // Combine and format permissions
    const permissions = this.getFormattedPermissions();

    try {
      await super.save({
        permissions,
      });
    } catch (error) {
      const errors = getApiErrors(error).map(error => ({
        extensions: { fields: this.fields.map(({ queryVar }) => queryVar) },
        message: error.extensions?.debug || error.message,
      }));
      this.setTier({
        errors,
      });
      return false;
    }

    return true;
  }

  getFormattedPermissions(): Pick<Permission, 'resource' | 'level'>[] {
    return this.fields
      .filter(({ queryVar, selectedValue }) => queryVar !== UserPermissionBuilderFields.ROLE && !!selectedValue?.id)
      .map(({ queryVar, selectedValue }) => ({ resource: queryVar as ResourceType, level: selectedValue.id }))
      .reduce((acc, val) => acc.concat(val), [] as Pick<Permission, 'resource' | 'level'>[]);
  }
}

export default PermissionsStep;
