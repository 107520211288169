import DynamicImport from 'components/core/DynamicImport';
import { TabType } from 'enums/tabType';
import type { LeadActivityResponseType } from 'store/api/graph/responses/responseTypes';
import { translate } from 'utils/intlUtils';

export const leadActivityDetailsTabs = [
  {
    tabId: TabType.DETAILS,
    component: DynamicImport(() => import('components/sections/leadActivities/LeadActivityDetailsTab')),
  },
];

export const getLeadActivityDetailsTitle = (item: LeadActivityResponseType) =>
  `${item?.typeName} ${translate.t('from')} ${item?.sourceName?.name?.value}`;
