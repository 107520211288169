import { type HTMLAttributes, memo, useMemo } from 'react';

import { isNil } from 'lodash-es';
import { useMeasure } from 'react-use';
import styled, { type FlattenSimpleInterpolation } from 'styled-components/macro';
import type { Spread } from 'type-fest';

import PrimaryText from 'components/core/typography/PrimaryText';
import ActionableTitle from 'components/ui/details/ActionableTitle';
import { Section } from 'components/ui/layouts/CardLayout';
import type { LinkedPanelProps } from 'components/ui/panels/LinkedPanel';
import LinkedPanel from 'components/ui/panels/LinkedPanel';
import type { PanelProps } from 'components/ui/panels/Panel';
import Panel from 'components/ui/panels/Panel';
import { ElementTestId } from 'enums/testing';
import { SPACE_400 } from 'styles/tokens';
import { PLACEHOLDER } from 'utils/formatUtils';

interface Props {
  /**
   * The label for the section.
   */
  label?: string;
  /**
   * An array of PanelProps or LinkedPanelProps objects.
   */
  panels: (PanelProps | LinkedPanelProps)[];
  /**
   * Callback for when the edit icon is clicked.
   */
  onEdit?: () => void;
  /**
   * Placeholder text to display when there are no panels.
   */
  placeholder?: string;
  /**
   * Whether the panels are nested.
   */
  isNested?: boolean;
  /**
   * Whether the panels are linked.
   */
  isLinkedList?: boolean;
  /**
   * A custom function to apply styles to the PanelContainer component. Mainly used for highly dynamic styles that
   * changes frequently.
   */
  customAttributeStyles?: (width: number) => HTMLAttributes<HTMLDivElement>['style'] | undefined;
  /**
   * A custom function to apply styles to the PanelContainer component.
   */
  customStyles?: (width: number) => FlattenSimpleInterpolation | undefined;
}

interface PanelContainerAtttributeProps extends Pick<Props, 'customAttributeStyles'> {
  /** Width of the PanelContainer component */
  $width: number;
}

type PanelContainerProps = Spread<PanelContainerAtttributeProps, Pick<Props, 'customStyles'>>;

const PanelContainer = styled.div.attrs<PanelContainerAtttributeProps>(({ customAttributeStyles, $width }) => ({
  style: customAttributeStyles?.($width),
}))<PanelContainerProps>`
  display: grid;
  gap: ${SPACE_400};
  grid-template-columns: repeat(2, 1fr);

  ${({ customStyles, $width }) => customStyles?.($width)}
`;

const PanelsSection = memo<Props>(
  ({
    label,
    panels,
    onEdit,
    placeholder = PLACEHOLDER,
    isLinkedList,
    isNested,
    customAttributeStyles,
    customStyles,
  }) => {
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    const validPanels = useMemo(
      () =>
        panels
          .filter(({ label, value }) => label && !isNil(value))
          .map(panel => {
            const { label, value, theme, isContentFlipped } = panel || {};
            const panelId = `${label}-${value}`;

            // Panels with nested linked list
            if (isLinkedList) {
              const { linkedListConfig } = panel as LinkedPanelProps;
              const testId = ElementTestId.LINKED_SECTION_ITEM_PANEL_PREFIX + `${linkedListConfig?.listEntity}`;
              return (
                <LinkedPanel
                  key={panelId}
                  theme={theme}
                  testId={testId}
                  isContentFlipped={isContentFlipped}
                  label={label}
                  value={value}
                  linkedListConfig={linkedListConfig}
                  isNested={isNested}
                />
              );
            }

            // Default Panels
            const { to } = panel as PanelProps;
            return (
              <Panel
                key={panelId}
                theme={theme}
                isContentFlipped={isContentFlipped}
                to={to}
                label={label}
                value={value}
              />
            );
          }),
      [isLinkedList, isNested, panels]
    );

    return (
      <Section>
        {!!label && <ActionableTitle onEdit={onEdit} label={label} />}
        <PanelContainer
          ref={ref}
          customAttributeStyles={customAttributeStyles}
          customStyles={customStyles}
          $width={width}
        >
          {validPanels.length > 0 ? <>{validPanels}</> : <PrimaryText>{placeholder}</PrimaryText>}
        </PanelContainer>
      </Section>
    );
  }
);

export default PanelsSection;
