import { SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { RooftopContact } from 'enums/columns/rooftopContact';
import { StepFieldType } from 'enums/stepFieldType';
import { ContactModifyParameter } from 'store/api/graph/interfaces/types';

import { ContactsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [ContactsBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: RooftopContact.ROOFTOP_NAME,
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [ContactsBuilderFields.FIRST_NAME]: {
    label: 'first_name',
    placeholder: 'first_name',
    queryAlias: RooftopContact.FIRST_NAME,
    clear: { field: ContactModifyParameter._firstName },
  },
  [ContactsBuilderFields.LAST_NAME]: {
    label: 'last_name',
    placeholder: 'last_name',
    queryAlias: RooftopContact.LAST_NAME,
    clear: { field: ContactModifyParameter._lastName },
  },
  [ContactsBuilderFields.COMPANY]: {
    label: 'company',
    queryAlias: RooftopContact.COMPANY,
    placeholder: 'company',
    clear: { field: ContactModifyParameter._company },
  },
  [ContactsBuilderFields.PHONE_NUMBER]: {
    label: 'phone_number_one',
    placeholder: 'phone_number_one',
    queryAlias: RooftopContact.PHONE_NUMBER,
    groupType: StepFieldType.PHONE_NUMBER,
    clear: { field: ContactModifyParameter._phoneNumber },
  },
  [ContactsBuilderFields.EMAIL]: {
    label: 'email_one',
    placeholder: 'email_one',
    queryAlias: RooftopContact.EMAIL,
    clear: { field: ContactModifyParameter._email },
  },
  [ContactsBuilderFields.DEFAULT_APPRAISER]: {
    label: 'default_appraiser',
    groupType: StepFieldType.SWITCH,
    queryAlias: RooftopContact.DEFAULT_APPRAISER,
    tooltipContents: 'default_appraiser_tooltip',
    clear: { field: ContactModifyParameter._defaultAppraiser },
  },
};

export default DetailFields;
