/**
 * List of all types of tabs, both generic and section specific.
 */
export enum TabType {
  /** Generic */
  DETAILS = 'details',
  MEDIA = 'media',
  LINKED = 'linked',

  /** Default identifier, used when there is only one tab */
  DEFAULT = 'default',

  /** Trade-In */
  TRADE_IN_VALUE = 'trade_in_value',

  /** User */
  USER_PERMISSIONS = 'user_permissions',

  /** Conversation */
  CONVERSATION_INQUIRY = 'conversation_inquiry',

  /** Retail */
  RETAIL_PRICING = 'retail_pricing',
  RETAIL_FILTERS = 'retail_filters',

  /** Pricing types */
  PRICING_CASH = 'cash',
  PRICING_FINANCE = 'finance',
  PRICING_LEASE = 'lease',

  /** Pricing rates configuration */
  PRICING_CONFIGURATION = 'pricing_configuration',

  /** Bulk adjustments */
  BULK_ADJUSTMENT_DESCRIPTION = 'bulk_adjustment_description',
  BULK_ADJUSTMENT_PRICING = 'bulk_adjustment_pricing',
  BULK_ADJUSTMENT_OVERLAY = 'bulk_adjustment_overlay',
  BULK_ADJUSTMENT_PROMOTION = 'bulk_adjustment_promotion',
  BULK_ADJUSTMENT_DISCLAIMER = 'bulk_adjustment_disclaimer',

  /** Leads */
  FINANCE = 'finance',

  /** Website */
  ROUTES = 'route_other',
  LEAD_ROUTING = 'lead_routing',

  /** RooftopIntegration */
  URLS = 'url_other',
}
