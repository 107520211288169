import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { getRooftopOptionsForWhitelabelScopedUser } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { getApiErrors } from 'store/api/graph/interfaces/apiErrors';
import type {
  LeadRouteModifyMutationVariables,
  RooftopNameFragment,
  WebsiteDetailsContainerQuery,
} from 'store/api/graph/interfaces/types';
import { LeadRouteModifyParameter, LeadRouteType } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import {
  defineFieldValues,
  formatStepError,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import { leadRouteModify } from '../WebsiteLeadRouteCreateModifyQuery';

import { LeadRoutingBuilderFields, LeadRoutingOption } from './interfaces';

const { t } = translate;

type LeadRoutingDetails = WebsiteDetailsContainerQuery['item'];

class DetailsStep extends StepComponentCore<LeadRoutingDetails, LeadRouteModifyMutationVariables> {
  constructor(
    props: StepComponentProps<LeadRoutingDetails, LeadRouteModifyMutationVariables>,
    context: CreateModifyContextInterface<LeadRoutingDetails>
  ) {
    super(props);
    const {
      tier: { data, seededData, activeStep },
    } = props;

    const vlpvdpLeadRoute =
      seededData?.vlpVdpLeadRoute || data.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.VLP_VDP);
    const otherLeadRoute =
      seededData?.otherLeadRoute || data.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.OTHER);
    // Find rooftops in the metadata so we can display their name on load
    const vlpvdpRooftopSelection: RooftopNameFragment | undefined = seededData?.rooftops.find(
      rooftop => rooftop.id === vlpvdpLeadRoute?.rooftopId
    );
    const otherRooftopSelection: RooftopNameFragment | undefined = seededData?.rooftops.find(
      rooftop => rooftop.id === otherLeadRoute?.rooftopId
    );

    // Define lead routing options here instead of DetailsFields so translation works
    const specificRooftopStepFieldOption: StepFieldOptions = {
      id: LeadRoutingOption.SPECIFIC_ROOFTOP,
      name: t('route_to_specific_rooftop'),
    };
    const followVehicleStepFieldOption: StepFieldOptions = {
      id: LeadRoutingOption.FOLLOW_VEHICLE_OWNER,
      name: t('route_to_vehicle_owner'),
    };

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [LeadRoutingBuilderFields.VLP_VDP_LEADS]: {
        options: [specificRooftopStepFieldOption, followVehicleStepFieldOption],
        selectedValue: vlpvdpLeadRoute?.rooftopId ? specificRooftopStepFieldOption : followVehicleStepFieldOption,
        hasSeparator: !vlpvdpLeadRoute?.rooftopId,
      },
      [LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: !vlpvdpLeadRoute?.rooftopId },
          { type: StepFieldDisplayType.DISABLED, active: !vlpvdpLeadRoute?.rooftopId },
          { type: StepFieldDisplayType.HIDDEN, active: !vlpvdpLeadRoute?.rooftopId },
        ]),
        selectedValue: vlpvdpRooftopSelection,
        hasSeparator: vlpvdpLeadRoute?.rooftopId,
      },
      // OTHER leads must be routed to a rooftop so selection is disabled but should be displayed to user
      [LeadRoutingBuilderFields.OTHER_LEADS]: {
        options: [specificRooftopStepFieldOption],
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: true },
          { type: StepFieldDisplayType.DISABLED, active: true },
        ]),
        selectedValue: specificRooftopStepFieldOption,
      },
      [LeadRoutingBuilderFields.OTHER_ROOFTOP_ID]: {
        selectedValue: otherRooftopSelection,
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, {
      ...data,
    });

    this.asyncConfigurations = {
      [LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID]: {
        request: async keyword =>
          getRooftopOptionsForWhitelabelScopedUser({
            keyword,
            groupId: data?.groupName.id,
            features: { lead: { enabled: true } },
          }),
        disableKeywordRefetch: false,
      },
      [LeadRoutingBuilderFields.OTHER_ROOFTOP_ID]: {
        request: async keyword =>
          getRooftopOptionsForWhitelabelScopedUser({
            keyword,
            groupId: data?.groupName.id,
            features: { lead: { enabled: true } },
          }),
        disableKeywordRefetch: false,
      },
    };
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === LeadRoutingBuilderFields.VLP_VDP_LEADS) {
      for (const field of this.fields.filter(field => field.queryVar === LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID)) {
        setDisplayTypes(
          [
            { type: StepFieldDisplayType.OMITTED, active: value?.id === LeadRoutingOption.FOLLOW_VEHICLE_OWNER },
            { type: StepFieldDisplayType.DISABLED, active: value?.id === LeadRoutingOption.FOLLOW_VEHICLE_OWNER },
            { type: StepFieldDisplayType.HIDDEN, active: value?.id === LeadRoutingOption.FOLLOW_VEHICLE_OWNER },
          ],
          field
        );
        // Clear rooftop selection if FOLLOW_VEHICLE_OWNER is selected
        if (value?.id === LeadRoutingOption.FOLLOW_VEHICLE_OWNER) {
          field.selectedValue = null;
        }

        // Display separator correctly between VDP/VLP and OTHER lead route
        if (stepField?.selectedValue?.id !== value?.id) {
          const vlpVdpLeadsField = getStepField(LeadRoutingBuilderFields.VLP_VDP_LEADS, this.fields);
          vlpVdpLeadsField.hasSeparator = !vlpVdpLeadsField.hasSeparator;
          const vlpVdpRooftopIdField = getStepField(LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID, this.fields);
          vlpVdpRooftopIdField.hasSeparator = !vlpVdpRooftopIdField.hasSeparator;
        }
      }
    }
    // Override ability to deselect options for all fields in step
    if (stepField?.selectedValue?.id !== value?.id) {
      super.onFieldSelection(stepField, value);
    }
  }

  async save(): Promise<boolean> {
    const {
      tier: { data },
    } = this.props;

    const vdpVlpLeadRouteOption = getStepField(LeadRoutingBuilderFields.VLP_VDP_LEADS, this.fields).selectedValue?.id;
    const vdpVlpRooftopId = getStepField(LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID, this.fields).selectedValue?.id;

    // If SPECIFIC_ROOFTOP is selected but no rooftop, show error and don't call mutations
    if (vdpVlpLeadRouteOption === LeadRoutingOption.SPECIFIC_ROOFTOP && !vdpVlpRooftopId) {
      this.setTier({
        errors: [formatStepError(getStepField(LeadRoutingBuilderFields.VLP_VDP_ROOFTOP_ID, this.fields))],
      });
      return false;
    }

    const otherRooftopId = getStepField(LeadRoutingBuilderFields.OTHER_ROOFTOP_ID, this.fields).selectedValue?.id;

    const leadRoutesToModify: Partial<LeadRouteModifyMutationVariables>[] = [
      {
        id: data.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.VLP_VDP)?.id,
        rooftopId: vdpVlpRooftopId,
        _clear: vdpVlpRooftopId ? undefined : [LeadRouteModifyParameter._rooftopId],
      },
      {
        id: data.leadRoutes.find(leadRoute => leadRoute.type === LeadRouteType.OTHER)?.id,
        rooftopId: otherRooftopId,
      },
    ];

    /**
     * Save the lead routes one by one, saving only the last one normally in order to
     * prevent the 'unsaved changes' dialog and to properly refetch queries.
     */
    const lastLeadRouteToModify = leadRoutesToModify.pop();
    try {
      await Promise.all(
        leadRoutesToModify.map(async leadRoute => {
          if (leadRoute.id) {
            return client.mutate({
              mutation: leadRouteModify,
              variables: leadRoute,
            });
          } else {
            return false;
          }
        })
      );
      return await super.save(undefined, lastLeadRouteToModify);
    } catch (error) {
      this.setTier({ errors: getApiErrors(error) });
      return false;
    }
  }
}

export default DetailsStep;
