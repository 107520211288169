import { memo } from 'react';

import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import Text from 'components/core/typography/Text';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import type { Incentive } from 'store/api/graph/interfaces/types';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { getFormattedDateTimeString } from 'utils/dateUtils';
import { getIncentiveAdjustmentAmount } from 'utils/formatting/pricingUtils';
import { translate } from 'utils/intlUtils';

const Container = styled.div`
  text-align: left;
`;

const IncentiveLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;

  /* This targets the incentive text, its max width is 65% of the row before truncation happens */
  > span {
    width: 65%;
  }
`;

const IncentiveDateRange = styled(SecondaryText)`
  white-space: pre-wrap;
  margin-top: 5px;
`;

/**
 * Incentive list item used to display the name of the incentive, starting/ending dates, and adjustment amount
 */
const IncentiveListItem = ({ label, starting, ending, attributes, term, fee }: Incentive & { term?: number }) => {
  const incentiveAdjustmentAmount = getIncentiveAdjustmentAmount(attributes, { term });

  return (
    <Container>
      <IncentiveLabelContainer>
        <span>
          <Text title={label.value}>{label.value}</Text>
        </span>
        {!!incentiveAdjustmentAmount && (
          <PrimaryText
            css={css`
              color: ${fee ? GREEN_500 : RED_500};
            `}
          >
            {incentiveAdjustmentAmount}
          </PrimaryText>
        )}
      </IncentiveLabelContainer>
      <IncentiveDateRange>
        {getFormattedDateTimeString(starting, DateTimeFormat.DATE_YEAR_FORMAT)}
        {` ${translate.t('to').toLowerCase()} `}
        {getFormattedDateTimeString(ending, DateTimeFormat.DATE_YEAR_FORMAT)}
      </IncentiveDateRange>
    </Container>
  );
};

export default memo(IncentiveListItem);
