import { omit } from 'lodash-es';

import { useSearch } from 'hooks/contexts/useSearch';

export const useActiveFiltersCount = () => {
  const { searchParams } = useSearch();
  const { vehicleAttributes, motorcycleAttributes, ...otherParams } = searchParams;
  const filteredParams = omit(otherParams, ['after', 'before', 'last', 'first', 'keyword']);
  const getParamsLength = obj =>
    obj ? Object.values(obj).filter(value => value !== undefined && value !== '').length : 0;

  return getParamsLength(filteredParams) + getParamsLength(vehicleAttributes) + getParamsLength(motorcycleAttributes);
};
