import { memo, type SVGProps } from 'react';

const SparkleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} {...props}>
    <path
      fill="currentColor"
      d="M16 2.946a.491.491 0 0 0-.313-.458l-.976-.367a1.346 1.346 0 0 1-.791-.806L13.56.32a.487.487 0 0 0-.175-.232.472.472 0 0 0-.723.232l-.36.994a1.347 1.347 0 0 1-.79.805l-.976.367a.48.48 0 0 0-.228.179.496.496 0 0 0 .228.738l.976.366c.366.138.655.433.79.807l.36.995a.488.488 0 0 0 .175.232.472.472 0 0 0 .723-.232l.36-.996c.134-.374.423-.67.79-.807l.975-.366a.476.476 0 0 0 .228-.177.491.491 0 0 0 .087-.279ZM12.627 9.565a1.073 1.073 0 0 0-.187-.612 1.04 1.04 0 0 0-.498-.39l-1.47-.551a4.489 4.489 0 0 1-2.635-2.688l-.54-1.498a1.066 1.066 0 0 0-.384-.507 1.032 1.032 0 0 0-1.198 0c-.175.125-.31.302-.383.507l-.543 1.498a4.489 4.489 0 0 1-2.633 2.683l-1.47.552a1.05 1.05 0 0 0-.498.391 1.083 1.083 0 0 0 0 1.222c.123.18.296.316.497.392l1.47.551a4.49 4.49 0 0 1 2.635 2.688l.54 1.498c.074.205.208.382.384.507a1.032 1.032 0 0 0 1.198 0c.175-.125.309-.302.383-.507l.54-1.5a4.488 4.488 0 0 1 2.636-2.689l1.467-.551a1.04 1.04 0 0 0 .499-.386c.123-.179.19-.391.19-.61Z"
    />
  </svg>
);

export default memo(SparkleIcon);
