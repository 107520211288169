import { gql } from '@apollo/client';

import { rooftopIntegrationDetails } from 'store/api/graph/fragments/rooftopIntegrationDetails';

export const rooftopIntegrationInventoryExportCreate = gql`
  mutation RooftopIntegrationInventoryExportCreate(
    $rooftopId: ID
    $integrationId: ID
    $primary: Boolean
    $runOnce: Boolean
    $privacyPolicyUrl: String
    $inventoryExportAttributes: InventoryExportRooftopIntegrationAttributesInput
    $active: Boolean
  ) {
    data: rooftopIntegrationCreate(
      rooftopId: $rooftopId
      integrationId: $integrationId
      primary: $primary
      privacyPolicyUrl: $privacyPolicyUrl
      runOnce: $runOnce
      inventoryExportAttributes: $inventoryExportAttributes
      active: $active
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;

export const rooftopIntegrationInventoryExportModify = gql`
  mutation RooftopIntegrationInventoryExportModify(
    $id: ID!
    $active: Boolean
    $primary: Boolean
    $runOnce: Boolean
    $privacyPolicyUrl: String
    $inventoryExportAttributes: InventoryExportRooftopIntegrationAttributesInput
    $_clear: [RooftopIntegrationModifyParameter!]
  ) {
    data: rooftopIntegrationModify(
      id: $id
      active: $active
      primary: $primary
      runOnce: $runOnce
      privacyPolicyUrl: $privacyPolicyUrl
      inventoryExportAttributes: $inventoryExportAttributes
      _clear: $_clear
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;
