import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TertiaryText from 'components/core/typography/TertiaryText';
import TextRow from 'components/core/typography/TextRow';
import { isItemArchived } from 'components/ui/dialogs/ArchiveDialog';
import Image from 'components/ui/images/Images';
import { ListItem, ListItemDetails, ListItemSeparator } from 'components/ui/layouts/ListItem';
import type { ListItemProps } from 'components/ui/lists/interfaces/ListItemProps';
import { LeadListItemTags } from 'components/ui/lists/LeadListItem';
import { ImageSize, ImageType } from 'enums/imageType';
import type { LeadActivityResponseType } from 'store/api/graph/responses/responseTypes';
import { DIVIDER } from 'styles/color';
import { FONT_SIZE_8 } from 'styles/typography';
import { formatFullName } from 'utils/formatUtils';
import { LeadActivityIcon, leadActivityItemDisplayData } from 'utils/leadActivityUtils';
import { getRelativeTime } from 'utils/timeUtils';

const LeadListItemDetails = styled(ListItemDetails)`
  > :not(:first-child) {
    margin-top: 10px;
  }
`;

const LeadIcon = styled(Image)`
  width: 22px;
  margin: auto;
  height: 22px;
  border-radius: 5px;
  margin-top: 11px;

  ${PrimaryText} {
    font-size: ${FONT_SIZE_8};
  }

  ::before {
    content: '';
    width: 2px;
    height: 7px;
    position: absolute;
    left: 50%;
    top: 42px; /* The separator is at the bottom of the lead list icon (which has 40px of height) */
    background: ${DIVIDER};
  }
`;

const LeadActivityIcons = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface Props extends LeadActivityResponseType, ListItemProps {}

const LeadActivityListItem = ({ suffixIcon, listItemType, ...leadActivity }: Props) => {
  const { leadName, created, assignedToName } = leadActivity;
  const { firstName, lastName, displayName, rgbHex } = leadName;
  const createdRelativeTime = getRelativeTime(created);

  return (
    <ListItem
      isHighlighted={!assignedToName}
      listItemType={listItemType}
      suffixIcon={suffixIcon}
      isArchived={isItemArchived(leadActivity)}
    >
      <LeadActivityIcons>
        <LeadActivityIcon
          type={ImageType.ICON}
          size={ImageSize.LIST_ITEM}
          fallbackSrc={leadActivityItemDisplayData.getIconImage(leadActivity)}
          {...leadActivityItemDisplayData.getIconStyledProps(leadActivity)}
        />
        <LeadIcon
          type={ImageType.USER}
          src={leadName?.avatar?.url}
          size={ImageSize.LIST_ITEM}
          fallbackSrc={{ firstName, lastName, rgbHex }}
        />
      </LeadActivityIcons>
      <LeadListItemDetails>
        <TextRow>{leadActivityItemDisplayData.getPrimaryText(leadActivity)}</TextRow>
        <TextRow>
          <TertiaryText title={createdRelativeTime || undefined}>{createdRelativeTime}</TertiaryText>
        </TextRow>
        <TextRow>
          <SecondaryText>{formatFullName({ displayName, firstName, lastName })}</SecondaryText>
        </TextRow>
        <LeadListItemTags {...leadName} />
      </LeadListItemDetails>
      <ListItemSeparator />
    </ListItem>
  );
};

export default memo(LeadActivityListItem);
