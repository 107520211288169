import { memo, type SVGProps } from 'react';

const TimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M13.09 11.552V8.12c0-.31-.105-.568-.313-.777A1.055 1.055 0 0 0 12 7.03c-.309 0-.568.104-.777.313a1.054 1.054 0 0 0-.313.777v3.837c0 .153.028.3.084.439.056.14.14.264.252.376l3.204 3.204c.204.203.457.305.76.305.304 0 .559-.102.766-.305.207-.203.311-.458.311-.766 0-.307-.104-.564-.311-.772l-2.886-2.886ZM12 22.203a9.946 9.946 0 0 1-3.983-.803 10.3 10.3 0 0 1-3.238-2.179 10.3 10.3 0 0 1-2.18-3.238A9.945 9.945 0 0 1 1.798 12c0-1.415.267-2.743.803-3.983a10.3 10.3 0 0 1 2.179-3.238 10.3 10.3 0 0 1 3.238-2.18A9.945 9.945 0 0 1 12 1.798c1.415 0 2.743.267 3.983.803a10.3 10.3 0 0 1 3.238 2.179 10.3 10.3 0 0 1 2.18 3.238A9.946 9.946 0 0 1 22.202 12a9.946 9.946 0 0 1-.803 3.983 10.3 10.3 0 0 1-2.179 3.238 10.3 10.3 0 0 1-3.238 2.18 9.946 9.946 0 0 1-3.983.802Z"
    />
  </svg>
);

export default memo(TimeIcon);
