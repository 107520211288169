import { useCallback, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash-es';

import {
  retailBulkAdjustmentDelete,
  retailBulkAdjustmentModify,
} from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentCreateModifyQuery';
import RetailBulkAdjustmentQueryMethods from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentQueryMethods';
import PromptDialog from 'components/ui/dialogs/PromptDialog';
import type { MenuItemConfig, MenuItemProps } from 'components/ui/menus/MenuButton';
import { MenuItems } from 'components/ui/menus/MenuButton';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useCreateModify } from 'hooks/contexts/useCreateModify';
import { useNestedView } from 'hooks/contexts/useNestedView';
import { useUser } from 'hooks/contexts/useUser';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { RooftopResponseType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props extends MenuItemProps {
  item: RooftopResponseType;
}

const getActiveStatusLabel = active => (active ? t('deactivate_adjustment') : t('activate_adjustment'));

const RooftopRetailBulkAdjustmentsMenuItems = ({ item }: Props) => {
  const { builderConfig } = useBuilderConfig();
  const { id, active } = item || {};
  const [isHardDeleteDialogOpen, setIsHardDeleteDialogOpen] = useState<boolean>(false);

  const { toggleTier } = useCreateModify();
  const { back } = useNestedView();
  const { hasPermissions } = useUser();
  const [isTogglingActivate, setIsTogglingActivate] = useState(false);
  const [prevActive, setPrevActive] = useState<boolean | undefined>(undefined);
  const activeStatusLabel = useMemo(() => getActiveStatusLabel(prevActive ?? active), [prevActive, active]);

  const onModifyClicked = useCallback(
    () =>
      toggleTier(CreateModifyTiers.TIER_0, {
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY,
        entityType: EntityType.RETAIL_ITEM,
        title: t('modify_x', [t('adjustment_one')]),
        isCreating: false,
        itemId: item?.id,
        data: cloneDeep(item),
      }),
    [item, toggleTier]
  );

  const onHardDeleteClicked = useCallback(
    async () =>
      client.mutate({
        mutation: retailBulkAdjustmentDelete,
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: ['RooftopRetailBulkAdjustmentsCondensedListQuery', 'RooftopDetailsContainerQuery'],
      }),
    [id]
  );

  const disabled = !hasPermissions(builderConfig[BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY].requiredPermissions);

  // In the case of Adjustments, we want to be able to modify/delete it even when it is not active
  const menuItemsConfig: MenuItemConfig[] = [
    { label: t('modify'), onClick: onModifyClicked, disabled, isArchivable: false },
    { label: t('delete'), onClick: () => setIsHardDeleteDialogOpen(true), disabled, isArchivable: false },
    {
      label: activeStatusLabel,
      onClick: () => setIsTogglingActivate(true),
      disabled,
      isArchivable: false,
    },
  ];

  return (
    <>
      <MenuItems items={menuItemsConfig} isArchived={!active} />
      {/* Delete Dialog */}
      <PromptDialog
        isConfirmDestructive
        isOpen={isHardDeleteDialogOpen}
        onConfirm={onHardDeleteClicked}
        onCancel={() => setIsHardDeleteDialogOpen(false)}
        onComplete={(success: boolean) => {
          if (success) {
            // Hide the success dialog after 0.7 seconds, if the user hasn't already dismissed it
            setTimeout(() => {
              setIsHardDeleteDialogOpen(false);
              back();
            }, 700);
          }
        }}
        confirmText={t('delete')}
        cancelText={t('cancel')}
        title={t('hard_delete_title')}
        message={t('hard_delete_message')}
      />
      {/* Activate/Deactivate Dialog */}
      <PromptDialog
        isFixedToTop
        isOpen={isTogglingActivate}
        onCancel={useCallback(() => {
          setIsTogglingActivate(false);
          setPrevActive(undefined);
        }, [])}
        onComplete={(success: boolean) => {
          if (success) {
            // Hide the success dialog after 0.7 seconds, if the user hasn't already dismissed it
            setTimeout(() => {
              setIsTogglingActivate(false);
              back();
            }, 700);
          }
        }}
        onConfirm={useCallback(async () => {
          setPrevActive(active);
          await client.mutate({
            mutation: retailBulkAdjustmentModify,
            variables: { id, active: !active },
            refetchQueries: RetailBulkAdjustmentQueryMethods.refetchQueries,
          });
        }, [id, active])}
        onClose={() => setPrevActive(undefined)}
        title={activeStatusLabel}
      />
    </>
  );
};

export default RooftopRetailBulkAdjustmentsMenuItems;
