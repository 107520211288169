// Re-export colors using semantic names to simplify component updates
export { NEUTRAL_700 as BODY_TEXT } from 'styles/tokens';
export { NEUTRAL_500 as BODY_TEXT_SECONDARY } from 'styles/tokens';
export { NEUTRAL_200 as BODY_TEXT_TERTIARY } from 'styles/tokens';
export { NEUTRAL_050 as DIVIDER } from 'styles/tokens';
export { NEUTRAL_200 as BORDER_DEFAULT } from 'styles/tokens';

export enum ChartColors {
  BLUE_DARK = '#374C80',
  PURPLE_DARK = '#7A5195',
  PINK_DARK = '#BC5090',
  PINK = '#EF5675',
  ORANGE = '#FF764A',
  ORANGE_RED = '#FF6361',
  NO_DATA = '#C8E2F4',
  NEGATIVE = '#F12B2C',
  POSITIVE = '#1C9C73',
}

/*
 * Colour options for the rich text editor. Array corresponds to how the colours are shown in the colour picker,
 * which is a 6x5 grid of coloured squares. Each block here represents one row in this grid.
 */
export const richTextInputColourOptions = [
  '#000000',
  '#11354e',
  '#186b5f',
  '#124e2c',
  '#d35400',
  '#800000',

  '#333333',
  '#1a4d71',
  '#229988',
  '#1c7a44',
  '#e67e22',
  '#993333',

  '#4e5f70',
  '#2980b9',
  '#1abc9c',
  '#27ae60',
  '#f39c12',
  '#c0392b',

  '#718290',
  '#5499c7',
  '#7ac2b8',
  '#2ecc71',
  '#ffff00',
  '#e74c3c',

  '#666666',
  '#3300ff',
  '#00ffd1',
  '#00cc33',
  '#ccff33',
  '#ff0000',
];
