import { memo, type SVGProps } from 'react';

const BoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 20 20" height={20} width={20} {...props}>
    <path
      fill="currentColor"
      d="M7.313 15.998c-.524 0-.972-.185-1.341-.555a1.827 1.827 0 0 1-.555-1.341v-8.21c0-.524.185-.971.555-1.34.37-.37.817-.555 1.341-.555h3.117a4.17 4.17 0 0 1 2.577.876c.773.584 1.159 1.375 1.159 2.375 0 .668-.152 1.206-.455 1.613-.302.406-.618.701-.945.885v.015c.41.176.816.489 1.216.939.4.45.6 1.071.6 1.865 0 1.203-.446 2.075-1.339 2.618-.893.543-1.778.815-2.656.815H7.313Zm.795-2.423h2.29c.601 0 .99-.16 1.17-.478.179-.32.268-.582.268-.787 0-.206-.09-.47-.268-.79-.18-.32-.585-.48-1.217-.48H8.108v2.535Zm0-4.88h2.072c.431 0 .757-.117.977-.352.22-.234.33-.502.33-.804 0-.333-.12-.606-.361-.82-.242-.213-.546-.32-.915-.32H8.108v2.296Z"
    />
  </svg>
);

export default memo(BoldIcon);
