import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { subscribeWithMetadata } from 'components/sections/createModify/shared/utils';
import { rooftopFinancePricingSegmentMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { rooftopRetailPricingFinanceDetailQuery } from 'containers/rooftops/pricingSegments/RooftopRetailPricingContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import type {
  RooftopFinancePricingSegmentMetaQuery,
  RooftopFinancePricingSegmentMetaQueryVariables,
} from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';
import { getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

const RooftopFinanceSegmentQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, _, seededData) => {
    const rooftopId = seededData?.rooftopName.id || defaultData.rooftopName.id;

    const rooftopFinancePricingSegmentObservable = client.watchQuery<
      RooftopFinancePricingSegmentMetaQuery,
      RooftopFinancePricingSegmentMetaQueryVariables
    >({
      query: rooftopFinancePricingSegmentMetaQuery,
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
      variables: getRetailFiltersMetaQueryVariables(rooftopId, defaultData.filter),
    });

    return subscribeWithMetadata<RooftopFinancePricingSegmentMetaQuery, RooftopFinancePricingSegmentMetaQueryVariables>(
      rooftopFinancePricingSegmentObservable,
      onMetaUpdate
    );
  },
  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopRetailPricingFinanceDetailQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: [
    'RooftopRetailFinancePricingDetailQuery',
    'RooftopRetailPricingContainerQuery',
    'RooftopDetailsContainerQuery',
    'RooftopRetailPricingCountQuery',
  ],
};

export default RooftopFinanceSegmentQueryMethods;
