import { createContext } from 'react';

import type Step from 'components/core/createModify/interfaces/step';
import type StepField from 'components/core/createModify/interfaces/stepField';
import type Tier from 'components/core/createModify/interfaces/tier';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import type { BuilderConfigContextInterface } from 'contexts/BuilderConfigContext';
import type { GlobalDialogContextInterface } from 'contexts/GlobalDialogContext';
import type { UserContextInterface } from 'contexts/UserContext';
import type { CreateModifyTiers } from 'enums/createModifyTiers';
import type { ContextKindsArgs, FeatureFlagSet } from 'hooks/useFeatureFlags';

type SubContextsType = {
  builderConfigContext: BuilderConfigContextInterface;
  userContext: UserContextInterface;
  globalDialogContext: GlobalDialogContextInterface;
  featureFlags: FeatureFlagSet;
  updateFeatureFlagContext: (contextKinds: ContextKindsArgs[]) => Promise<FeatureFlagSet | undefined>;
};

export interface CreateModifyContextInterface<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
> {
  tiers: { [key: string]: Tier<TData, TMetaData> | undefined };
  setTier: (tierId: CreateModifyTiers, tierUpdates: Partial<Tier<TData, TMetaData>>) => void;
  getTier: (tierId: CreateModifyTiers) => Tier<TData, TMetaData> | undefined;
  toggleTier: (
    tierId: CreateModifyTiers,
    tierData?: Partial<Tier<TData, TMetaData>> & Pick<Tier<TData, TMetaData>, 'type' | 'tierId'>
  ) => void;
  findActiveStep: (activeField: string, builder: BuilderEntry) => Step | undefined;
  getField: (step: Step<TData, TMetaData>, field: string) => Partial<StepField<TData, TMetaData>> | undefined;
  topTier?: Tier<TData, TMetaData>;
  subContexts: SubContextsType;
}

/**
 * The context class used and referenced by everything that exists in and including the `CreatModifyController`.
 * Data is contained in ‘tiers’ with a reference to each tier passed through to the rendering container.
 */
export const CreateModifyContext = createContext<CreateModifyContextInterface | undefined>(undefined);
