import type { PriceAdjustmentSeedData } from 'components/sections/createModify/retailItemPriceAdjustment/steps/RetailItemPriceAdjustmentStep';
import RetailItemPricingTypeStep from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeStep';
import { InventoryItem } from 'enums/columns/inventoryItem';
import type { RetailItem } from 'store/api/graph/interfaces/types';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import { translate } from 'utils/intlUtils';

import { getAvailablePaymentMethods } from '../helpers/RetailItemPricingBuilders';

class RetailItemPricingTypeCashStep extends RetailItemPricingTypeStep {
  componentDidMount() {
    const {
      tier: { data },
    } = this.props;

    super.componentDidMount();

    this.setSelectedAdjustments(data as RetailItem);

    this.setTitleStepName(translate.t('cash'));
  }

  onItemAdd(seedLabelString: string) {
    const {
      tier: { data },
    } = this.props;

    const {
      subContexts: {
        userContext: {
          featureFlags: { rooftopPackageEnabled },
        },
      },
    } = this.getContext();

    super.onItemAdd({
      seedLabelString,
      paymentOption: PaymentOption.CASH,
      availablePaymentOptions: getAvailablePaymentMethods(data as RetailItem, rooftopPackageEnabled),
    } as PriceAdjustmentSeedData);
  }

  async save() {
    return super.savePaymentStep(InventoryItem.CASH_RETAIL_PRICING, PaymentOption.CASH);
  }
}

export default RetailItemPricingTypeCashStep;
