import type { ReactNode } from 'react';

import styled, { css } from 'styled-components/macro';

import { BLUE_500, NEUTRAL_0 } from 'styles/tokens';

interface CounterBadgeProps {
  /** The count number to display */
  count?: number;
  /** The color of the badge counter */
  color?: string;
  /** The children elements that the badge counter will appear above */
  children: ReactNode;
}

const CounterBadgeElement = styled.div<{ color?: string }>`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  color: ${NEUTRAL_0};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  ${({ color }) => css`
    background-color: ${color ?? BLUE_500};
  `}
`;

const CounterBadgeContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CounterBadgeLabel = styled.div`
  position: relative;
`;

const CounterBadge = ({ count, color, children }: CounterBadgeProps) => (
  <CounterBadgeContainer>
    {count ? (
      <CounterBadgeElement color={color}>
        <CounterBadgeLabel>{count}</CounterBadgeLabel>
      </CounterBadgeElement>
    ) : null}
    {children}
  </CounterBadgeContainer>
);

export default CounterBadge;
