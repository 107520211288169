import { gql } from '@apollo/client';

import { tradeInAppraisal } from './tradeInAppraisal';
import { tradeInAppraisalInternal } from './tradeInAppraisalInternal';

export const tradeInAppraisals = gql`
  fragment TradeInAppraisalsFragment on TradeInAppraisals {
    highAppraisal {
      ...TradeInAppraisalFragment
    }
    lowAppraisal {
      ...TradeInAppraisalFragment
    }
    list {
      ...TradeInAppraisalFragment
      ... on TradeInAppraisalInternal {
        ...TradeInAppraisalInternalFragment
      }
    }
  }
  ${tradeInAppraisal}
  ${tradeInAppraisalInternal}
`;
