import { css } from 'styled-components/macro';

import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { type MultilingualToggleInputSettings, TextAreaSize } from 'components/ui/forms/shared/MultilingualToggleInput';
import type { NumberInputSettings } from 'components/ui/forms/shared/NumberInput';
import NumberInput from 'components/ui/forms/shared/NumberInput';
import type { ToggleType } from 'components/ui/forms/shared/ToggleInput';
import DiscountIcon from 'components/ui/icons/DiscountIcon';
import DollarSignCircledIcon from 'components/ui/icons/DollarSignCircledIcon';
import DollarSignIcon from 'components/ui/icons/DollarSignIcon';
import PercentIcon from 'components/ui/icons/PercentIcon';
import { RetailAdjustmentAmountAdjustmentType, RetailAdjustmentAmountType } from 'enums/retailBulkAdjustment';
import { StepFieldType } from 'enums/stepFieldType';
import { RetailPriceAdjustmentModifyParameter } from 'store/api/graph/interfaces/types';

import { RetailItemPriceAdjustmentBuilderFields } from './interfaces';

const RetailItemPriceAdjustmentFields: StepFields = {
  [RetailItemPriceAdjustmentBuilderFields.AMOUNT_ADJUSTMENT_TYPE]: {
    groupType: StepFieldType.TYPES,
    displayType: [StepFieldDisplayType.OMITTED],
    settings: {
      types: [
        {
          id: RetailAdjustmentAmountAdjustmentType.DISCOUNT,
          label: RetailAdjustmentAmountAdjustmentType.DISCOUNT,
          icon: DiscountIcon,
        },
        {
          id: RetailAdjustmentAmountAdjustmentType.FEE,
          label: RetailAdjustmentAmountAdjustmentType.FEE,
          icon: DollarSignCircledIcon,
        },
      ],
      customStyles: css`
        svg {
          width: 35px;
          height: 32px;
        }
      `,
    },
  },
  [RetailItemPriceAdjustmentBuilderFields.INCLUDE_CASH]: {
    label: 'apply_cash',
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPriceAdjustmentBuilderFields.INCLUDE_FINANCE]: {
    label: 'apply_finance',
    groupType: StepFieldType.SWITCH,
  },
  [RetailItemPriceAdjustmentBuilderFields.INCLUDE_LEASE]: {
    label: 'apply_lease',
    groupType: StepFieldType.SWITCH,
    hasSeparator: true,
  },
  [RetailItemPriceAdjustmentBuilderFields.LABEL]: {
    label: 'label',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: RetailPriceAdjustmentModifyParameter._label },
  },
  [RetailItemPriceAdjustmentBuilderFields.DISCLAIMER]: {
    label: 'disclaimer',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    tooltipContents: 'retail_item_price_adjustment_disclaimer_tooltip',
    settings: {
      textAreaSize: TextAreaSize.MEDIUM,
    } as MultilingualToggleInputSettings,
    clear: { field: RetailPriceAdjustmentModifyParameter._disclaimer },
  },
  [RetailItemPriceAdjustmentBuilderFields.AMOUNT]: {
    label: 'amount',
    groupType: StepFieldType.TOGGLE,
    displayType: [StepFieldDisplayType.OMITTED],
    queryAlias: [
      RetailItemPriceAdjustmentBuilderFields.AMOUNT_PERCENTAGE,
      RetailItemPriceAdjustmentBuilderFields.AMOUNT_FIXED,
    ],
    settings: {
      input: NumberInput,
      types: [
        {
          icon: <PercentIcon />,
          id: RetailAdjustmentAmountType.PERCENTAGE,
          settings: { allowDecimal: true, shouldSanitizeInput: true },
        },
        {
          icon: <DollarSignIcon />,
          id: RetailAdjustmentAmountType.FIXED,
          settings: { allowDecimal: false, shouldSanitizeInput: true },
        },
      ] as ToggleType[],
    } as NumberInputSettings,
  },
  /*
   * These two fields will be hidden from the user, but they hold the respective fixed/percentage amounts that will be
   * included in the save request
   */
  [RetailItemPriceAdjustmentBuilderFields.AMOUNT_FIXED]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: RetailPriceAdjustmentModifyParameter._fixed },
  },
  [RetailItemPriceAdjustmentBuilderFields.AMOUNT_PERCENTAGE]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: RetailPriceAdjustmentModifyParameter._percentage },
  },
};

export default RetailItemPriceAdjustmentFields;
