import { Extension } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';

import { MultilingualStringValue } from 'utils/intlUtils';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    language: {
      /**
       * Set a language on the editor
       * @example editor.commands.setLanguage(language: MultilingualStringValue)
       */
      setLanguage: (language: MultilingualStringValue) => ReturnType;
    };
  }
}

export const key = new PluginKey('languagePlugin');

interface LanguageStatePluginOptions {
  defaultLanguage: MultilingualStringValue;
}

const Language = Extension.create<LanguageStatePluginOptions>({
  name: 'language',

  addOptions() {
    return {
      defaultLanguage: MultilingualStringValue.EN,
    };
  },

  addStorage() {
    return {
      value: this.options.defaultLanguage,
    };
  },

  onBeforeCreate() {
    this.storage.value = this.options.defaultLanguage;
  },

  addCommands() {
    return {
      setLanguage: (language: MultilingualStringValue) => () => {
        this.storage.value = language;
        return true;
      },
    };
  },
});

export default Language;
