import { memo } from 'react';

import PrimaryText from 'components/core/typography/PrimaryText';
import TextRow from 'components/core/typography/TextRow';
import type { StandardEquipment } from 'store/api/graph/interfaces/types';

const StandardEquipmentListItem = ({ name }: StandardEquipment) => (
  <TextRow>
    <PrimaryText>{name.value}</PrimaryText>
  </TextRow>
);

export default memo(StandardEquipmentListItem);
