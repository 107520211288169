import { type ComponentProps, memo } from 'react';

import styled, { css } from 'styled-components/macro';
import type { SetOptional } from 'type-fest';

import { WrapLink } from 'components/core/navigation/shared/WrapLink';
import EDealerIcon from 'components/ui/icons/EDealerIcon';
import { BLUE_500, NEUTRAL_0, SPACE_500 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const TopNavLinkItemContainer = styled(WrapLink)<{ disabled?: boolean }>`
  align-items: center;
  background: ${BLUE_500};
  color: ${NEUTRAL_0};
  display: flex;
  justify-content: center;
  padding: ${SPACE_500};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

type Props = SetOptional<Pick<ComponentProps<typeof TopNavLinkItemContainer>, 'disabled' | 'to'>, 'disabled'>;

const TopNavLinkItem = ({ disabled = false, to }: Props) => (
  <TopNavLinkItemContainer disabled={disabled} to={to} title={t('home')}>
    <EDealerIcon width={20} height={20} />
  </TopNavLinkItemContainer>
);

export default memo(TopNavLinkItem);
