import { gql } from '@apollo/client';

import { rooftopIntegrationDetails } from 'store/api/graph/fragments/rooftopIntegrationDetails';

export const rooftopIntegrationPhoneCallTrackingCreate = gql`
  mutation RooftopIntegrationPhoneCallTrackingCreate(
    $rooftopId: ID
    $integrationId: ID
    $phoneCallTrackingAttributes: PhoneCallTrackingRooftopIntegrationAttributesInput
  ) {
    data: rooftopIntegrationCreate(
      rooftopId: $rooftopId
      integrationId: $integrationId
      phoneCallTrackingAttributes: $phoneCallTrackingAttributes
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;

export const rooftopIntegrationPhoneCallTrackingModify = gql`
  mutation RooftopIntegrationPhoneCallTrackingModify(
    $id: ID!
    $active: Boolean
    $phoneCallTrackingAttributes: PhoneCallTrackingRooftopIntegrationAttributesInput
    $_clear: [RooftopIntegrationModifyParameter!]
  ) {
    data: rooftopIntegrationModify(
      id: $id
      active: $active
      phoneCallTrackingAttributes: $phoneCallTrackingAttributes
      _clear: $_clear
    ) {
      ...RooftopIntegrationFragment
    }
  }

  ${rooftopIntegrationDetails}
`;
