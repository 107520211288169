import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styled from 'styled-components/macro';

import SecondaryText from 'components/core/typography/SecondaryText';
import InputText, { Input } from 'components/ui/forms/shared/InputText';
import CloseIcon from 'components/ui/icons/CloseIcon';
import SearchIcon from 'components/ui/icons/SearchIcon';
import { ElementTestId } from 'enums/testing';
import useDebounceKeywordSearch from 'hooks/useKeywordSearch';
import { usePrevious } from 'hooks/usePrevious';
import { BODY_TEXT, BORDER_DEFAULT } from 'styles/color';
import {
  BORDER_RADIUS_200,
  HEIGHT_400,
  LINE_HEIGHT_2,
  NEUTRAL_0,
  SPACE_200,
  SPACE_300,
  WIDTH_400,
} from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import { translate } from 'utils/intlUtils';
import { DEFAULT_SEARCH_DEBOUNCE } from 'utils/timeUtils';

const SearchContainer = styled(SecondaryText)<{ width?: number }>`
  align-items: center;
  background: ${NEUTRAL_0};
  border-radius: ${BORDER_RADIUS_200};
  box-shadow: 0 0 0 1px ${BORDER_DEFAULT} inset;
  color: ${BODY_TEXT};
  display: flex;
  column-gap: ${SPACE_200};
  height: 100%;
  padding: ${SPACE_200} ${SPACE_300};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;

const SearchInputText = styled(InputText)`
  ${Input} {
    line-height: ${LINE_HEIGHT_2};
    padding: 0;
  }
`;

const ExtendedSearchIcon = styled(SearchIcon)`
  height: ${HEIGHT_400};
  pointer-events: none;
  width: ${WIDTH_400};
  z-index: ${Z_INDEX_1};
`;

interface SearchProps {
  autoFocus?: boolean;
  debounceTime?: number | null;
  defaultValue?: string;
  className?: string;
  onChange?: (event) => void;
  onClose?: () => void;
  placeholder?: string;
  width?: number;
  ref?: any;
  testId?: string;
}

export interface SearchInputRef {
  clear: () => void;
  focus: () => void;
  input: () => HTMLInputElement;
}

const SearchInput = forwardRef<SearchInputRef | undefined, SearchProps>(
  (
    {
      width,
      onChange = () => {},
      placeholder,
      autoFocus,
      defaultValue,
      className,
      onClose,
      debounceTime = DEFAULT_SEARCH_DEBOUNCE,
      testId,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const inputRef = useRef<HTMLInputElement>();
    const defaultValuePrev = usePrevious(defaultValue);

    const onCloseCallback = useCallback(() => setInputValue(''), [setInputValue]);
    const onChangeCallback = useCallback(e => setInputValue(e?.currentTarget?.value || ''), [setInputValue]);
    const onKeyUpCallback = useCallback(
      e => {
        if (e?.keyCode === 27) {
          onClose?.();
          setInputValue('');
          e.stopPropagation();
        }
      },
      [onClose]
    );

    useDebounceKeywordSearch(inputValue, onChange, debounceTime || 0);

    // Clear search input if value is reset
    useEffect(() => {
      if (defaultValuePrev && !defaultValue) {
        setInputValue('');
      }
    }, [defaultValue, defaultValuePrev]);

    useImperativeHandle(
      ref,
      (): SearchInputRef => ({
        clear: () => {
          setInputValue('');
        },
        focus: () => {
          inputRef?.current?.focus();
        },
        input: () => inputRef?.current as HTMLInputElement,
      })
    );

    return (
      <SearchContainer width={width}>
        <ExtendedSearchIcon />
        <SearchInputText
          autoFocus={autoFocus}
          theme="none"
          onKeyUp={onKeyUpCallback}
          onChange={onChangeCallback}
          value={inputValue}
          placeholder={placeholder || translate.t('search')}
          ref={inputRef}
          data-testid={testId || ElementTestId.SEARCH_INPUT}
          {...props}
        />
        {!!inputValue && <CloseIcon onClick={onCloseCallback} />}
      </SearchContainer>
    );
  }
);

export default SearchInput;
