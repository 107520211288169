import styled from 'styled-components/macro';

import ReloadIcon from 'components/ui/icons/ReloadIcon';
import { Clickable } from 'components/ui/shared/Button';
import type { ElementTestId } from 'enums/testing';
import { BLUE_500, NEUTRAL_0, NEUTRAL_800, SPACE_200 } from 'styles/tokens';

const RefreshButton = styled(Clickable)`
  height: 40px;
  width: 40px;
  color: ${NEUTRAL_0};
  background: ${NEUTRAL_800};
  border-radius: 8px;
  margin-left: 8px;
  transition: 200ms;
  margin-top: ${SPACE_200};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    transition: 200ms;
  }

  :hover {
    background: ${BLUE_500};

    > svg {
      transform: rotate(45deg);
    }
  }
`;

const RefreshActionButton = ({ onClick, testingId }: { onClick: () => void; testingId?: ElementTestId }) => (
  <RefreshButton onClick={onClick} data-testid={testingId}>
    <ReloadIcon />
  </RefreshButton>
);

export default RefreshActionButton;
