import { gql } from '@apollo/client';

export const appointmentConnectionFilterFragment = gql`
  fragment AppointmentConnectionFilterFragment on AppointmentConnectionFilter {
    whiteLabelId
    groupId
    rooftopId
    archived
    assigned
    assignedToId
    assignedToMe
    createdById
    date {
      gte
      lte
    }
    inventoryItemId
    leadId
    tagId
  }
`;
