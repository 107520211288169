import { memo } from 'react';

import styled from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import type { TechnicalSpecification } from 'store/api/graph/interfaces/types';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 4px;
`;

const TechnicalSpecificationsListItem = ({ name, value }: TechnicalSpecification) => (
  <Container>
    <PrimaryText title={name.value}>{name.value}</PrimaryText>
    <Text title={name.value}>{value.value}</Text>
  </Container>
);

export default memo(TechnicalSpecificationsListItem);
