import { gql } from '@apollo/client';

import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';

import { MultilingualString } from './multilingualString';
import { Permission } from './permission';

export const user = gql`
  fragment UserFragment on User {
    id
    displayName
    whiteLabel {
      id
      name
    }
    group {
      id
      name {
        ...MultilingualStringFragment
      }
      whiteLabel {
        id
        name
      }
      rooftops {
        id
        name {
          ...MultilingualStringFragment
        }
        bundle {
          ...RooftopFeatureBundle
        }
      }
    }
    scope
    rooftops {
      id
      name {
        ...MultilingualStringFragment
      }
      group {
        id
      }
      carfaxId
      bundle {
        ...RooftopFeatureBundle
      }
      locale {
        languageTag
      }
    }
    active
    cellNumber
    email
    subscribedEmail
    firstName
    lastActive
    lastName
    hasIncomingLeadActivities
    hasUnreadConversations
    locale {
      country
      displayName
      language
      languageTag
    }
    permissions {
      ...PermissionFragment
    }
    phoneNumber
    rgbHex
    role {
      id
      archived
      name {
        ...MultilingualStringFragment
      }
      permissions {
        ...PermissionFragment
      }
    }
    created
    updated
  }
  ${MultilingualString}
  ${Permission}
  ${rooftopFeatureBundle}
`;
