import type { ContextType, MouseEvent, ReactNode, RefObject } from 'react';
import { createRef, memo, PureComponent } from 'react';

import { get, isEqual, isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import Label from 'components/core/typography/Label';
import Checkbox from 'components/ui/forms/shared/Checkbox';
import type { ImageProps } from 'components/ui/images/Images';
import Image from 'components/ui/images/Images';
import NoResultsPlaceholder from 'components/ui/placeholders/NoResultsPlaceholder';
import { Clickable } from 'components/ui/shared/Button';
import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import type TableData from 'components/ui/tables/interfaces/tableData';
import Table from 'components/ui/tables/Table';
import TableFilters from 'components/ui/tables/TableFilters';
import type { CommonItemsConfigProps } from 'containers/ItemsContainer';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { FieldDataType } from 'enums/fieldDataType';
import { ImageSize, ImageType } from 'enums/imageType';
import { ItemsColumn } from 'enums/itemsColumn';
import type { RouterHooksHOCProps } from 'hooks/withRouter';
import { withRouter } from 'hooks/withRouter';
import type { Column, PageInfo } from 'store/api/graph/interfaces/types';
import { BODY_TEXT } from 'styles/color';
import { BLUE_500 } from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import type { RequiredPermissions } from 'types/Permissions';
import { getBuilderTitleForTables } from 'utils/formatting/createModifyFormatUtils';
import { isPrimitive } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';
import { authorizedCallback } from 'utils/permissionUtils';
import { deferredRenderCall } from 'utils/renderingUtils';

import { isItemArchived } from '../dialogs/ArchiveDialog';
import SortableIcon from '../shared/SortableIcon';

import ItemsOverlay from './ItemsOverlay';
import TableFooterPagination from './TableFooterPagination';
import { getCellTemplate, getStoredTableConfiguration, saveTableConfiguration } from './TableHelpers';
import type { TableSubType, TableType } from './tableSettings/TableSettings';

const SortableCell = styled(Clickable)<{ isSorted?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${Label} {
    text-align: left;
    align-items: center;
    ${({ isSorted }) => css`
      color: ${isSorted ? BLUE_500 : BODY_TEXT};
    `}
  }
`;

export type ItemsTableConfigProps = {
  /** The type of table that is being rendered, used for `Table` component */
  tableType: TableType;
  /** Any relevant table sub types, used to get/set configurations */
  tableSubType?: TableSubType;
  /** Any custom cell rendering types required (e.g. STOCK_NUMBER) */
  getCustomCellTemplate?: (headerData: TableCellData, itemData: any) => ReactNode;
  /** Whether or not we show the filters icon & functionality */
  disableColumnFilters?: boolean;
};

export type ItemsTableProps = CommonItemsConfigProps &
  ItemsTableConfigProps & {
    /** The raw connection data for rows and their respective columns being rendered in this table */
    data: any[];
    /** The string query used by the api to request data for exporting */
    exportQueryString: string;
    /** The variables used by the exportQueryString to achieve the view required */
    exportQueryVars: any;
    /** Whether or not this table is in edit mode */
    isEditing: boolean;
    /** Whether the user has permissions to edit entities within the table */
    isEditAllowed: boolean;
    /** Whether or not there is API specific processing happening */
    isLoading: boolean;
    /** Metadata that comes from the connection, used to parse things in `TableHelpers` */
    metadata: any;
    /** Callback used to inform parent that the filters have changed */
    onFiltersChange: () => void;
    /** For pagination, the info of the page currently being rendered (number of items, cursor, etc) */
    pageInfo: PageInfo;
    /** Used to determine whether or not the filters have changed, scrolls table to top when update evaluates to true */
    searchParams: any;
    /** Comes from the API, the list of columns and their sort orders if applicable */
    sortColumns: any;
    /** Update callback for when a new sorting priority is requested through the headers */
    updateSortOrder: (columnId: string, columns: Column[]) => void;
    /** User context callback that checks for permissions */
    hasPermissions: (permissions: RequiredPermissions) => boolean;
    /** Router hook, see `useRouter` */
    router: RouterHooksHOCProps;
    /** Callback used to inform parent that the selected items have changed */
    onSelectionChange: (ids: string[]) => void;
    /** Callback used to inform parent that the column headers have changed */
    onColumnChange: (ids: TableCellData[]) => void;
    /** Callback used to inform parent that the selectAll button has been clicked */
    onSelectedAll: (selectAll: boolean) => void;
    /** Comes from the ItemsContainer, used to refresh selections after a bulk action has occured */
    selectedIds: string[];
  };

class ItemsTable extends PureComponent<
  ItemsTableProps,
  {
    tableDisabled: boolean;
    selectedRows: string[];
    photoOverlay?: ReactNode;
    columnHeaders: TableCellData[];
    parsedData: TableData;
    isProcessing: boolean;
    didFiltersChange: boolean;
  }
> {
  static contextType = CreateModifyContext;
  context: ContextType<typeof CreateModifyContext>;

  tableRef: RefObject<Table>;

  constructor(props) {
    super(props);
    const { tableType, tableSubType, hasPermissions, onColumnChange } = props;
    const columnHeaders = getStoredTableConfiguration(tableType, tableSubType, hasPermissions);
    onColumnChange(columnHeaders);

    this.tableRef = createRef();

    this.state = {
      tableDisabled: false,
      isProcessing: false,
      photoOverlay: null,
      selectedRows: [],
      columnHeaders,
      parsedData: this.parseData(columnHeaders),
      didFiltersChange: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { tableSubType: tableSubTypePrev, data: dataPrev, searchParams: searchParamsPrev } = prevProps;
    const { tableType, tableSubType, data, searchParams, selectedIds, onSelectionChange } = this.props;
    const { didFiltersChange, selectedRows } = this.state;

    if (tableSubTypePrev !== tableSubType) {
      const columnHeaders = getStoredTableConfiguration(tableType, tableSubType);
      this.setState({
        columnHeaders,
        parsedData: this.parseData(columnHeaders),
      });
    }

    if (!isEqual(dataPrev, data)) {
      if (didFiltersChange) {
        const newSelectedIds = selectedIds.filter(id => data.some(row => row.id === id));
        this.setState({
          selectedRows: [...newSelectedIds],
          didFiltersChange: false,
        });
        onSelectionChange([...newSelectedIds]);
      }

      this.setState({
        parsedData: this.parseData(this.state.columnHeaders),
      });
    }

    if (!isEqual(searchParamsPrev, searchParams)) {
      this.tableRef.current?.scrollTableContentToTop();

      if (searchParams.after === searchParamsPrev.after) {
        this.setState({
          didFiltersChange: true,
        });
      }
    }

    if (!isEqual(selectedRows, selectedIds)) {
      this.setState({
        selectedRows: [...selectedIds],
        parsedData: this.parseData(this.state.columnHeaders),
      });
    }
  }

  onFiltersToggle = (tableDisabled: boolean) => {
    this.setState({ tableDisabled });
  };

  updateColumnHeaders = (rowData: TableCellData[], _, disableReParsing = false) => {
    const { tableType, tableSubType, onColumnChange } = this.props;
    if (!disableReParsing) {
      this.setState({ parsedData: this.parseData(rowData) });
    }
    this.setState({ columnHeaders: rowData });
    onColumnChange(rowData);
    // LocalStorage
    saveTableConfiguration(tableType, tableSubType, rowData);
  };

  filtersChanged = (rowData: TableCellData[], cell: TableCellData, isEnabling: boolean) => {
    const { data, onFiltersChange } = this.props;
    deferredRenderCall(() =>
      this.updateColumnHeaders(rowData, cell, isEnabling && get(data[0], cell.columnId) === undefined)
    );
    if (isEnabling) {
      deferredRenderCall(() => {
        onFiltersChange();
      });
    }
  };

  toggleOverlay = (e?: MouseEvent, url = '') => {
    if (e) {
      const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
      // Setting position of the overlay to target's right-center position + 5px padding
      this.setState({
        photoOverlay: <ItemsOverlay left={left + width + 5} top={top + height / 2} url={url} />,
      });
    } else {
      this.setState({ photoOverlay: null });
    }
  };

  onScroll = () => {
    // Simple state change but stored here as a ref to avoid render leaks
    this.setState({ photoOverlay: null });
  };

  onRowResize = (rowData: TableCellData[], cellData: TableCellData) => {
    this.updateColumnHeaders(rowData, cellData, true);
  };

  onFieldEdit = (_, cellData: TableCellData) => {
    const { isEditing, entityType, editBuilder } = this.props;
    if (!isEditing || !cellData.canEdit || !this.context) {
      return;
    }

    const {
      findActiveStep,
      toggleTier,
      subContexts: {
        builderConfigContext: { builderConfig },
      },
    } = this.context;

    const itemData = this.props.data.find(item => item.id === cellData.rowId);

    const activeStep = findActiveStep(cellData.columnId, builderConfig[editBuilder]);

    const tierData = {
      tierId: CreateModifyTiers.TIER_0,
      type: editBuilder,
      entityType,
      title: translate.t('modify_x', [translate.t(getBuilderTitleForTables({ entityType, itemData }) || '')]),
      isCreating: false,
      itemId: itemData?.id,
      activeField: cellData.columnId,
      activeStep,
    };

    toggleTier(CreateModifyTiers.TIER_0, tierData);
  };

  parseData(columnHeaders: TableCellData[]): TableData {
    const {
      data,
      getCustomCellTemplate,
      metadata,
      sortColumns,
      router: { location },
    } = this.props;

    // Create header data
    const filteredHeaders = columnHeaders.map(header => {
      const isStaticColumn = [ItemsColumn.SELECT, ItemsColumn.PHOTOS].includes(header.columnId as ItemsColumn);

      return {
        ...header,
        render: () => (isStaticColumn ? this.getStaticHeaderTemplates : this.getHeaderCellTemplates)(header),
      };
    });

    // Create table data
    const tableData: TableCellData[][] = [];
    for (const itemData of data) {
      const rowData: TableCellData[] = [];

      // Permissions
      const isEditAllowed =
        this.props.hasPermissions(
          this.context?.subContexts.builderConfigContext[this.props.editBuilder]?.requiredPermissions
        ) && !isItemArchived(itemData);

      for (const header of filteredHeaders) {
        const isStaticColumn = [ItemsColumn.SELECT, ItemsColumn.PHOTOS].includes(header.columnId as ItemsColumn);
        const renderMethod = isStaticColumn
          ? this.getStaticCellTemplate
          : (header.cellType === FieldDataType.CUSTOM && getCustomCellTemplate) || getCellTemplate;
        const itemRowData = {
          ...header,
          canReorder: false,
          resizable: false,
          itemData,
          rowId: itemData.id,
          render: () => renderMethod(header, itemData, metadata),
          link: `${location.pathname}/${itemData.id}${location.search}`,
          onClick: authorizedCallback({ cb: this.onFieldEdit, isAuth: isEditAllowed }),
        };

        // If user can edit the entity and the table column
        const canEditCell =
          isEditAllowed &&
          (isNil(itemRowData.canEdit)
            ? sortColumns.find(({ id }) => [header.columnIdOverride, header.columnId].includes(id))?.editable
            : itemRowData.canEdit);

        rowData.push({ ...itemRowData, canEdit: canEditCell });
      }

      tableData.push(rowData);
    }

    return {
      headerData: filteredHeaders,
      data: tableData,
    };
  }

  getStaticHeaderTemplates = (headerCellData: TableCellData) => {
    const { tableType, tableSubType, disableColumnFilters } = this.props;
    const {
      columnHeaders,
      selectedRows,
      parsedData: { data },
    } = this.state;

    switch (headerCellData.columnId) {
      case ItemsColumn.SELECT: {
        const isAllChecked = data.every(row => selectedRows.includes(row[0].rowId));
        const isSomeChecked = data.some(row => selectedRows.includes(row[0].rowId));
        return (
          <Checkbox
            checked={isAllChecked}
            indeterminate={isSomeChecked}
            onChange={() => {
              const checked = !isSomeChecked;
              this.onCheck(
                checked,
                data.map(row => row[0].rowId)
              );
            }}
          />
        );
      }

      case ItemsColumn.PHOTOS: {
        return (
          !disableColumnFilters && (
            <TableFilters
              onOpenClose={this.onFiltersToggle}
              data={columnHeaders}
              tableType={tableType}
              tableSubType={tableSubType}
              onFiltersChanged={this.filtersChanged}
            />
          )
        );
      }
    }
  };

  getHeaderCellTemplates = (headerCellData: TableCellData) => {
    const { sortColumns, updateSortOrder } = this.props;
    const sortOpt = sortColumns?.find(opt =>
      [headerCellData.columnIdOverride, headerCellData.columnId].includes(opt.id)
    );
    const title = typeof headerCellData.content === 'string' ? headerCellData.content : undefined;

    if (sortOpt && sortOpt.sortable) {
      return (
        <SortableCell isSorted={!!sortOpt.sortDirection} onClick={() => updateSortOrder(sortOpt.id, sortColumns)}>
          <Label title={title}>{headerCellData.content}</Label>
          <SortableIcon sortDirection={sortOpt.sortDirection} sortPriority={sortOpt.sortPriority} />
        </SortableCell>
      );
    } else {
      return <Label title={title}>{headerCellData.content}</Label>;
    }
  };

  getStaticCellTemplate = (headerData: TableCellData, itemData: any) => {
    const { isEditing, basePath, getCustomCellTemplate } = this.props;
    const {
      selectedRows,
      parsedData: { data },
    } = this.state;

    switch (headerData.columnId) {
      case ItemsColumn.SELECT: {
        const targetCellData = data
          .find(row => row[0]?.rowId === itemData.id)
          ?.find(cell => cell.columnId === ItemsColumn.SELECT);

        // Overriding any wrappers and triggering select
        if (targetCellData) {
          targetCellData.onClick = e => {
            e.preventDefault();
            this.onCheck(!selectedRows.includes(itemData.id), [itemData.id]);
          };

          /**
           * Programmatically `select` rows during pagination
           * as `selections` are lost because they are initially
           * selected based on `onClick` events.
           */
          if (selectedRows.includes(targetCellData.rowId)) {
            targetCellData.content = true;
          }

          this.forceUpdate();
        }

        return (
          <Checkbox
            css={css`
              z-index: ${Z_INDEX_1};
            `}
            checked={!!selectedRows.includes(itemData.id)}
          />
        );
      }

      case ItemsColumn.PHOTOS: {
        const hasImage = [ImageType.INVENTORY_ITEM, ImageType.PHOTO, ImageType.AVATAR].includes(
          headerData.content as ImageType
        );

        let content;
        if (headerData.content === FieldDataType.CUSTOM) {
          content = getCustomCellTemplate?.(headerData, itemData) || null;
        } else {
          const imageType = headerData.content as ImageType;
          const defaultImageProps: Omit<ImageProps, 'type'> = { size: ImageSize.THUMBNAIL };

          const getImageComponent = <Type extends ImageType>(type: Type, props) => (
            <Image {...defaultImageProps} type={type} {...props} />
          );

          switch (imageType) {
            case ImageType.INVENTORY_ITEM:
            case ImageType.PHOTO: {
              content = getImageComponent(imageType, { src: itemData.primaryPhoto?.thumb });
              break;
            }

            case ImageType.AVATAR: {
              content = getImageComponent(imageType, { src: itemData.avatar?.url });
              break;
            }

            case ImageType.USER: {
              content = getImageComponent(imageType, { src: itemData.avatar?.url, fallbackSrc: itemData });
              break;
            }

            case ImageType.ICON: {
              // TODO: Support icons when we implement it
              break;
            }

            default: {
              if (headerData.content) {
                LoggingService.debug({
                  message: `Unhandled image type for ItemsColumn.PHOTOS: ${isPrimitive(headerData.content) ? headerData.content : JSON.stringify(headerData.content)}`,
                });
              }
              break;
            }
          }
        }

        return (
          headerData.content && (
            <Clickable
              onMouseOver={(e: any) => hasImage && this.toggleOverlay(e, itemData?.primaryPhoto?.large)}
              onMouseOut={() => hasImage && this.toggleOverlay()}
              onClick={() => isEditing && this.props.router.push(`${basePath}/${itemData.id}`)}
              css={css`
                z-index: ${Z_INDEX_1};
              `}
            >
              {content}
            </Clickable>
          )
        );
      }
    }
  };

  onCheck = (checked: boolean, rowIds: string[]) => {
    const {
      selectedRows,
      parsedData: { data },
    } = this.state;

    const { onSelectionChange } = this.props;
    /*
     * Iterating through all affected `SELECT` rows and assigning checked state,
     * Casting to TableCellData[] to indicate that undefined content has been filtered out
     */
    for (const targetCell of rowIds
      .map(id => data.find(row => row[0].rowId === id)?.find(cell => cell.columnId === ItemsColumn.SELECT))
      .filter(Boolean)) {
      const targetCellInSelectedRows = selectedRows.indexOf(targetCell.rowId);

      if (checked) {
        // If the rows was checked, then it needs to be added to the list of selected rows
        selectedRows.push(targetCell.rowId);
      } else if (targetCellInSelectedRows > -1) {
        // If the row is already selected, then clicking the checkbox again should de-select the row
        selectedRows.splice(targetCellInSelectedRows, 1);
      }
      targetCell.content = checked;
    }
    onSelectionChange(selectedRows);
    this.forceUpdate();
  };

  onProcessing = isProcessing => {
    this.setState({ isProcessing });
  };

  renderFooter = () => {
    const { pageInfo, sortColumns, onSelectedAll } = this.props;
    const { selectedRows, columnHeaders } = this.state;

    return (
      <TableFooterPagination
        pageInfo={pageInfo}
        onPageChange={() => {
          this.tableRef.current?.scrollTableContentToTop();
        }}
        onSelectedAll={onSelectedAll}
        columnsInfo={sortColumns}
        ids={selectedRows}
        columnIds={columnHeaders.filter(header => header.enabled).map(header => header.columnId)}
      />
    );
  };

  render() {
    const { data, isLoading, isEditing, isEditAllowed } = this.props;
    const { photoOverlay, tableDisabled, parsedData, isProcessing } = this.state;

    if (!isLoading && data.length === 0) {
      return <NoResultsPlaceholder />;
    }

    return (
      <>
        <Table
          ref={this.tableRef}
          disabled={tableDisabled}
          onScroll={this.onScroll}
          isLoading={isLoading || isProcessing}
          tableData={parsedData}
          onRowResize={this.onRowResize}
          onRowReorder={this.updateColumnHeaders}
          isEditing={isEditing && isEditAllowed}
          contentCellStyles="padding: 10px;"
          headerCellStyles="padding: 10px;"
          footer={this.renderFooter()}
        />
        {photoOverlay}
      </>
    );
  }
}

export default withRouter(memo(ItemsTable));
