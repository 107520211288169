import type { ReactElement } from 'react';
import { isValidElement } from 'react';

import type { StyledComponentBase } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import DeletedUserIcon from 'components/ui/icons/DeletedUserIcon';
import UserIcon from 'components/ui/icons/UserIcon';
import VehicleIcon from 'components/ui/icons/VehicleIcon';
import ImageLoader from 'components/ui/loading/ImageLoader';
import { ImageSize, ImageType } from 'enums/imageType';
import useImageLoader from 'hooks/useImageLoader';
import type { Contact, Lead, User } from 'store/api/graph/interfaces/types';
import { BODY_TEXT, BODY_TEXT_TERTIARY } from 'styles/color';
import {
  BLUE_500,
  BORDER_RADIUS_200,
  GREEN_500,
  NEUTRAL_0,
  NEUTRAL_050,
  NEUTRAL_075,
  NEUTRAL_200,
  YELLOW_500,
} from 'styles/tokens';
import { FONT_SIZE_44, FONT_WEIGHT_BOLDER } from 'styles/typography';
import { formatInitials } from 'utils/formatUtils';

const HeroText = styled(PrimaryText)`
  font-weight: ${FONT_WEIGHT_BOLDER};
  font-size: ${FONT_SIZE_44};
`;

export const ListItemImageSize = '40px';

export const HeroImageElement = styled.div`
  min-width: 0;
  width: 100%;
`;

export const HeroImage = styled.div<{ img?: string }>`
  ${({ img }) =>
    img &&
    css`
      background-image: url('${img}');
    `}
  background-position: center;
  background-size: cover;
  height: 100%;
`;

export const ThumbnailImage = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  overflow: hidden;
`;

export const ListItemImage = styled.div`
  width: ${ListItemImageSize};
  height: ${ListItemImageSize};
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
`;

export const DefaultImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
`;

const FilledImage = styled(DefaultImage)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  > img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
`;

export const AvatarImage = styled(DefaultImage)`
  height: 34px;
  width: 34px;
  border-radius: ${BORDER_RADIUS_200};

  svg {
    color: ${BODY_TEXT};
  }
`;

export const ImageElement = styled.img`
  width: 100%;
  height: 100%;
`;

export const FallbackIcon = styled.div`
  width: 100%;
  height: 100%;
  background: ${NEUTRAL_050};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${BODY_TEXT};
  border-radius: 8px;

  /* Ensuring proper hierarchy overriding in nested areas  */
  > ${Text} {
    &${PrimaryText} {
      color: ${NEUTRAL_0};
    }
  }
`;

export const FallbackImage = styled.div`
  width: 100%;
  height: 100%;
  background: ${NEUTRAL_075};
  display: flex;
  justify-content: center;
  color: ${NEUTRAL_200};
  align-items: center;

  svg {
    width: 50%;
  }
`;

const getUserColor = user => {
  if (user?.rgbHex) {
    return user.rgbHex;
  }

  switch (Number.parseInt(user.id, 16) % 4) {
    case 1: {
      return GREEN_500;
    }

    case 2: {
      return YELLOW_500;
    }

    case 3: {
      return BLUE_500;
    }

    default: {
      return YELLOW_500;
    }
  }
};

interface BaseImageProps {
  size?: ImageSize;
  src?: string;
}

interface ImagePropsWithoutFallbackSource extends BaseImageProps {
  type?: ImageType.INVENTORY_ITEM | ImageType.AVATAR;
}

interface ImagePropsWithElementFallback extends BaseImageProps {
  type?: ImageType.ICON | ImageType.PHOTO;
  fallbackSrc?: ReactElement;
}

interface ImagePropsWithUserInfoFallback extends BaseImageProps {
  type?: ImageType.USER;
  fallbackSrc:
    | (Optional<Pick<Lead | Contact, 'firstName' | 'lastName' | 'rgbHex'>, 'lastName'> & { deleted?: undefined })
    | Optional<Pick<User, 'firstName' | 'lastName' | 'rgbHex' | 'deleted'>, 'lastName' | 'deleted'>;
}

export interface ImagePropsForHeroType extends BaseImageProps {
  type?: ImageType.HERO;
  fallbackSrc?: ImagePropsWithElementFallback['fallbackSrc'] | ImagePropsWithUserInfoFallback['fallbackSrc'];
}

export type ImageProps =
  | ImagePropsWithoutFallbackSource
  | ImagePropsWithElementFallback
  | ImagePropsWithUserInfoFallback
  | ImagePropsForHeroType;

const Image = ({ size, src, ...props }: ImageProps) => {
  let ImageClass;
  let ImageContent: ReactElement | StyledComponentBase<'img', any, Record<string, unknown>, never> | null = null;

  const isLoading = useImageLoader(src);

  switch (size) {
    case ImageSize.THUMBNAIL: {
      ImageClass = ThumbnailImage;
      break;
    }

    case ImageSize.LIST_ITEM: {
      ImageClass = ListItemImage;
      break;
    }

    case ImageSize.AVATAR: {
      ImageClass = AvatarImage;
      break;
    }

    case ImageSize.FILL: {
      ImageClass = FilledImage;
      break;
    }

    case ImageSize.HERO: {
      ImageClass = HeroImageElement;
      break;
    }

    default: {
      ImageClass = DefaultImage;
      break;
    }
  }

  if (src) {
    ImageContent = props.type === ImageType.HERO ? <HeroImage img={src} /> : <ImageElement src={src} />;
  } else {
    switch (props.type) {
      case ImageType.USER: {
        const isDeleted = props.fallbackSrc.deleted;
        ImageContent = (
          <FallbackIcon
            style={{
              background: isDeleted ? BODY_TEXT_TERTIARY : getUserColor(props.fallbackSrc),
            }}
          >
            {isDeleted ? (
              <DeletedUserIcon color={NEUTRAL_0} />
            ) : (
              <PrimaryText>{formatInitials(props.fallbackSrc)}</PrimaryText>
            )}
          </FallbackIcon>
        );
        break;
      }

      case ImageType.ICON: {
        ImageContent = <FallbackIcon>{props.fallbackSrc}</FallbackIcon>;
        break;
      }

      case ImageType.AVATAR: {
        ImageContent = (
          <FallbackIcon
            css={css`
              background: ${BODY_TEXT_TERTIARY};
            `}
          >
            <UserIcon height={20} width={20} />
          </FallbackIcon>
        );
        break;
      }

      case ImageType.INVENTORY_ITEM: {
        ImageContent = <FallbackImage>{<VehicleIcon />}</FallbackImage>;
        break;
      }

      case ImageType.HERO: {
        if (isValidElement(props.fallbackSrc)) {
          ImageContent = <FallbackImage>{props.fallbackSrc}</FallbackImage>;
        } else if (props.fallbackSrc) {
          ImageContent = (
            <FallbackIcon
              style={{
                background: getUserColor(props.fallbackSrc),
              }}
            >
              <HeroText>{formatInitials(props.fallbackSrc as User)}</HeroText>
            </FallbackIcon>
          );
        }
        break;
      }

      case ImageType.PHOTO: {
        ImageContent = props.fallbackSrc ? <FallbackImage>{props.fallbackSrc}</FallbackImage> : null;
        break;
      }

      default: {
        ImageContent = null;
        LoggingService.debug({ message: `Unhandled fallback Image: ${props.type}` });
        break;
      }
    }
  }

  return <ImageClass {...props}>{isLoading ? <ImageLoader /> : ImageContent}</ImageClass>;
};

export default Image;
