import { useMemo } from 'react';

import { useDragDropManager, useDragLayer } from 'react-dnd';
import type { DragLayerMonitor, XYCoord } from 'react-dnd';
import styled from 'styled-components/macro';

import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import { SecondaryViewWidth } from 'containers/nestedView/interfaces';
import { listSelectionItemTestId } from 'enums/testing';
import { BLUE_050 } from 'styles/tokens';

import ListSelectionCheckbox from './ListSelectionCheckbox';
import ListSelectionDraggableHandle from './ListSelectionDraggableHandle';
import { OptionText } from './ListSelectionOptions';
import { ListItemContainer } from './ListSelectionSortableListItemContainer';

const ListSelectionSortableDragPreviewContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: ${SecondaryViewWidth.DEFAULT};
  height: 100%;
`;

type SortableListItemContainerPreviewProps = {
  /**
   * The projected { x, y } client offset of the drag source component's root DOM node,
   * based on its position at the time when the current drag operation has started, and the movement difference.
   * Returns null if no item is being dragged.
   */
  currentOffset: XYCoord | null;
  /** The width for the preview item, based on the width of the drag source list item */
  $width: number | undefined;
  /** Whether the preview should be visible */
  isVisible: boolean;
};

const SortableListItemContainerPreview = styled.div.attrs<SortableListItemContainerPreviewProps>(
  ({ $width, currentOffset }) => ({
    style: {
      width: $width ? `${$width}px` : 'auto',
      top: `${currentOffset ? currentOffset.y - 60 : 0}px`,
    },
  })
)<SortableListItemContainerPreviewProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: relative;
  background: ${BLUE_050};
`;

type ListSelectionSortableDragPreviewProps = {
  /** The items rendered in the sortable list to be used as a reference */
  items: SubStepOption[];
};

/**
 * Renders a custom drag preview for sortable list items
 */
const ListSelectionSortableDragPreview = ({ items }: ListSelectionSortableDragPreviewProps) => {
  const {
    isDragging,
    initialOffset,
    currentOffset,
    item: currentItem,
  } = useDragLayer((monitor: DragLayerMonitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const firstListItemWidth = useDragDropManager().getBackend().sourceNodes.values().next().value?.parentElement
    ?.parentElement?.clientWidth;

  const item = items?.find(({ id }) => id === currentItem?.id);
  const value = useMemo(() => (typeof item?.name === 'string' ? item.name : item?.name?.value), [item]);
  const isVisible = useMemo(() => !!(initialOffset && currentOffset), [currentOffset, initialOffset]);

  if (!isDragging || !item || !value || !item.id) {
    return null;
  }

  return (
    <ListSelectionSortableDragPreviewContainer>
      <SortableListItemContainerPreview currentOffset={currentOffset} $width={firstListItemWidth} isVisible={isVisible}>
        <>
          <ListItemContainer isCheckbox={true} isActive={true} data-testid={listSelectionItemTestId(item.id || '')}>
            <ListSelectionCheckbox disabled={item.disabled} checked={true} />
            <OptionText>{value}</OptionText>
          </ListItemContainer>
          <ListSelectionDraggableHandle />
        </>
      </SortableListItemContainerPreview>
    </ListSelectionSortableDragPreviewContainer>
  );
};

export default ListSelectionSortableDragPreview;
