import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { WebsiteRouteModifyParameter } from 'store/api/graph/interfaces/types';

import { WebsiteRouteDetailsBuilderFields } from './interfaces';

const DetailFields: StepFields = {
  [WebsiteRouteDetailsBuilderFields.WEBSITE_ID]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [WebsiteRouteDetailsBuilderFields.PATH]: {
    label: 'path',
    groupType: StepFieldType.MULTILINGUAL_TOGGLE_FIELD,
    clear: { field: WebsiteRouteModifyParameter._path },
    hasSeparator: true,
  },
  [WebsiteRouteDetailsBuilderFields.FILTERS]: {
    label: 'filter_other',
    queryAlias: 'filters',
    groupType: StepFieldType.COUNTER,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    hasSeparator: true,
  },
};

export default DetailFields;
