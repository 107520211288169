import type { ReactNode } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

import type { RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';

interface Props {
  /** Children to render  */
  children: ReactNode;
}

/**
 * The core editor component. This component is responsible for rendering the editor
 * and providing access to the editor instance.
 *
 * This component is a wrapper around the Tiptap editor instance.
 *
 * @param children The children to render inside the editor.
 * @param ref The ref to the editor instance.
 */
const Editor = forwardRef<RichTextEditorContextInterface, Props>(({ children }, ref) => {
  const { editor, ...rest } = useRichTextEditor();

  useImperativeHandle(ref, () => ({ ...rest, editor }));

  if (!editor) {
    return null;
  }

  return children;
});

export default Editor;
