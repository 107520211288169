import { gql } from '@apollo/client';

import { MultilingualString } from './multilingualString';

export const tag = gql`
  fragment TagFragment on Tag {
    id
    rooftop {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    name {
      ...MultilingualStringFragment
    }
    type
    typeName
  }
  ${MultilingualString}
`;
