import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import LinkedStep from '../shared/steps/LinkedStep';

import { appointmentCreate, appointmentModify } from './AppointmentCreateModifyQuery';
import AppointmentQueryMethods from './AppointmentQueryMethods';
import AppointmentLinkedFields from './steps/AppointmentLinkedFields';
import AppointmentDetailsFields from './steps/DetailsFields';
import AppointmentDetailsStep from './steps/DetailsStep';

const AppointmentBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.APPOINTMENT_CREATE]: {
    getMetaData: AppointmentQueryMethods.getMetaData,
    refetchQueries: AppointmentQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.APPOINTMENTS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.APPOINTMENTS,
    steps: [
      {
        id: 'APPOINTMENT_DETAILS',
        step: AppointmentDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: AppointmentDetailsFields,
      },
      {
        id: 'APPOINTMENT_LINKED',
        step: LinkedStep,
        stepName: 'linked',
        isEnabled: false,
        fields: AppointmentLinkedFields,
      },
    ],
    mutations: {
      create: appointmentCreate,
      modify: appointmentModify,
    },
  },
  [BuilderType.APPOINTMENT_MODIFY]: {
    getMetaData: AppointmentQueryMethods.getMetaData,
    getData: AppointmentQueryMethods.getData,
    refetchQueries: AppointmentQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.APPOINTMENTS, level: AccessLevel.ADVANCED }],
    resourceType: ResourceType.APPOINTMENTS,
    steps: [
      {
        id: 'APPOINTMENT_DETAILS',
        step: AppointmentDetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: AppointmentDetailsFields,
      },
      {
        id: 'APPOINTMENT_LINKED',
        step: LinkedStep,
        stepName: 'linked',
        isEnabled: true,
        fields: AppointmentLinkedFields,
      },
    ],
    mutations: {
      modify: appointmentModify,
    },
  },
};

export default AppointmentBuilders;
