import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

import { avatar } from './avatar';

export const userList = gql`
  fragment UserListFragment on User {
    ## Minimum required fields for table/listItem
    avatar {
      ...AvatarFragment
    }
    id
    firstName
    lastName
    displayName
    rgbHex
    deleted
    active
    rooftops {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    rooftopName: rooftops {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    groupName: group {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    roleName: role {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
  ${avatar}
`;
