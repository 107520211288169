import { useMemo } from 'react';

import ImpersonationError from 'components/ui/shared/ImpersonationError';
import { BannerStyle } from 'enums/bannerStyle';
import { ElementTestId } from 'enums/testing';
import { useUser } from 'hooks/contexts/useUser';
import { impersonationManager } from 'utils/impersonationUtils';
import { translate } from 'utils/intlUtils';

import Banner from './Banner';

const { t } = translate;

const LoggedInAsBanner = () => {
  const {
    user: { displayName },
    impersonateAsUser,
  } = useUser();

  const banner = useMemo(() => {
    if (!impersonationManager.isCurrentlyImpersonating) {
      return null;
    }

    // Display this banner in the language of the actual user and not the impersonated one
    const translationOptions = { lng: impersonationManager.getActualUser()?.locale?.languageTag };
    return (
      <Banner
        testId={ElementTestId.LOGGED_IN_AS_BANNER}
        title={t('logged_in_as_message', [displayName], translationOptions)}
        ctaLabel={t('logout', undefined, translationOptions)}
        onClick={() => impersonateAsUser(undefined)}
        ctaTestId={ElementTestId.LOGGED_IN_AS_BANNER_LOGOUT_LINK}
        styleVariant={BannerStyle.IMPERSONATION}
      />
    );
  }, [displayName, impersonateAsUser]);

  return (
    <>
      <ImpersonationError />
      {banner}
    </>
  );
};

export default LoggedInAsBanner;
