import { useMatches } from 'react-router-dom';

type MatchHandle = {
  pathPattern?: string;
};

/**
 * This hook will get all matching paths and build out a path pattern to be use
 * for analytics. This improves the naming of your pages and results in simplified analysis.
 *
 * @example returns `/retail/:id`
 */
export const usePathPattern = () => {
  const matches = useMatches();

  return matches.reduce((pattern, match) => {
    const matchHandle = match.handle as MatchHandle | undefined;
    if (!matchHandle?.pathPattern) {
      return pattern;
    }

    pattern += matchHandle.pathPattern;
    return pattern;
  }, '');
};
