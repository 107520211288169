import { css } from 'styled-components/macro';

export const DEFAULT_STEP_FIELD_HEIGHT = '115px';

export const INDENT_WIDTH = 35;

export const ContainerStyles = {
  // Custom styling to take over the entire column
  FULL_HEIGHT: css`
    height: 100%;
  `,
  /**
   * Causes the field to be indented at the specified level
   */
  INDENTED: (level = 1) => css`
    padding-left: ${INDENT_WIDTH * level}px;
  `,
};

export const ContainerInnerStyles = {
  /** Indent inner input within the `StepField` and round the corners */
  INDENTED_ROUNDED_CORNERS: css`
    padding-left: 10px !important;
    border-radius: 8px !important;
  `,
};
