import styled from 'styled-components/macro';

import { LINE_HEIGHT_DEFAULT } from 'styles/typography';

import Text from './Text';

const Paragraph = styled(Text)`
  line-height: ${LINE_HEIGHT_DEFAULT};
  white-space: normal;
  overflow: auto;
  top: initial;
  vertical-align: top;
`;

export default Paragraph;
