import { gql } from '@apollo/client';

import { creditApplication } from 'store/api/graph/fragments/creditApplication';

export const CreditApplicationCreate = gql`
  mutation CreditApplicationCreateMutation($leadId: ID, $lenderId: ID, $productCode: ProductCode) {
    data: creditApplicationCreate(leadId: $leadId, lenderId: $lenderId, productCode: $productCode) {
      ...CreditApplicationFragment
    }
  }
  ${creditApplication}
`;
