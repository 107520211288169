import { cloneDeep } from 'lodash-es';

import type Step from 'components/core/createModify/interfaces/step';
import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import { RetailFilterBuilderFields } from 'components/sections/createModify/shared/steps/interfaces';
import RetailFilterStep from 'components/sections/createModify/shared/steps/RetailFilterStep';
import {
  getRooftopOptionsForNonWhitelabelScopedUser,
  getRooftopOptionsForWhitelabelScopedUser,
  getTagOptions,
} from 'components/sections/shared/ItemMetaHelpers';
import type { RetailItemConnectionFilterStepOption } from 'components/ui/lists/RetailFilterListItem';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { FeatureBundleSet } from 'enums/featureBundle';
import { getApiErrors, logApiError } from 'store/api/graph/interfaces/apiErrors';
import { EntityType, type RetailItemConnectionFilter } from 'store/api/graph/interfaces/types';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { getStepField, setDisplayTypes, stripTypeNames } from 'utils/formatting/createModifyFormatUtils';
import { getRetailItemConnectionFilterFromFields } from 'utils/retailFilterUtils';

import { websiteRouteCreate } from '../WebsiteRouteCreateModifyQuery';
import { generateRandomPath, getFilterName } from '../websiteRouteFilterUtils';

class FilterStep extends RetailFilterStep {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    const {
      tier: { data: currentData, seededData, isCreating },
    } = props;

    const data = { ...seededData, ...currentData };
    const websiteData = stripTypeNames(cloneDeep(data));

    if (!isCreating) {
      /*
       * Since this step always uses seeded data we need to set the `tier.data` value
       * with the seeded website data to work with the shared RetailFilterStep.
       */
      props.tier.data = websiteData;
    }

    super(props, context);

    let rooftopId = websiteData.rooftopId || websiteData.rooftopName?.id || [];
    if (websiteData.filter) {
      rooftopId = websiteData.groupName.rooftops.filter(item => websiteData?.filter?.rooftopId.includes(item.id));
    }
    const groupId = websiteData.groupName.id;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped },
        featureFlags,
      },
    } = context;

    this.fields = this.fields.map(stepField => {
      if (
        stepField.queryVar === RetailFilterBuilderFields.FILTER_MODEL_ID ||
        stepField.queryVar === RetailFilterBuilderFields.FILTER_SUBMODEL_ID
      ) {
        return stepField;
      }

      switch (stepField.queryVar) {
        case RetailFilterBuilderFields.ROOFTOP_ID: {
          stepField.displayType = setDisplayTypes([{ type: StepFieldDisplayType.HIDDEN, active: false }]);
          stepField.selectedValue = rooftopId;
          break;
        }

        case RetailFilterBuilderFields.FILTER_TYPE: {
          stepField.displayType = setDisplayTypes({
            type: StepFieldDisplayType.HIDDEN,
            active: true,
          });
          break;
        }

        case RetailFilterBuilderFields.FILTER_MAKE_ID: {
          stepField.displayType = setDisplayTypes([
            {
              type: StepFieldDisplayType.DISABLED,
              active: isCreating || websiteData.filter?.makeIdExcluded?.length,
            },
          ]);
          break;
        }

        case RetailFilterBuilderFields.FILTER_MAKE_ID_EXCLUDED: {
          stepField.displayType = setDisplayTypes([
            { type: StepFieldDisplayType.DISABLED, active: isCreating || websiteData.filter?.makeId?.length },
          ]);
          break;
        }

        default: {
          if (stepField.queryVar === RetailFilterBuilderFields.FLAGS) {
            stepField.options = (stepField?.options as StepField[])?.filter(
              option => option.queryVar !== RetailFilterBuilderFields.FILTER_SHOW_WEB
            );
          }
          stepField.displayType = setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !!isCreating });
        }
      }
      return stepField;
    });

    this.asyncConfigurations = {
      ...this.asyncConfigurations,
      [RetailFilterBuilderFields.ROOFTOP_ID]: {
        request: async keyword =>
          isWhiteLabelScoped
            ? getRooftopOptionsForWhitelabelScopedUser({
                keyword,
                groupId,
                features: { retail: { enabled: true } },
              })
            : getRooftopOptionsForNonWhitelabelScopedUser({
                user,
                filterRooftopsByFeatureFunction: rooftop =>
                  isFeatureEnabledForRooftop({
                    rooftop,
                    feature: FeatureBundleSet.RETAIL,
                    featureFlagOn: featureFlags.rooftopPackageEnabled,
                  }),
              }),
        disableKeywordRefetch: !isWhiteLabelScoped,
      },
      [RetailFilterBuilderFields.FILTER_TAG_ID]: {
        request: () => {
          const rooftopIds = getStepField(RetailFilterBuilderFields.ROOFTOP_ID, this.fields).selectedValue?.map(
            ({ id }) => id
          );
          return getTagOptions({ rooftopId: rooftopIds, entityType: EntityType.RETAIL_ITEM });
        },
        disableKeywordRefetch: true,
      },
    };
  }

  /**
   * Callback override for MMS handling
   */
  async onFieldSelection(stepField: StepField, value: any, persistSeededValues: boolean, disableAdvance: boolean) {
    const stepFieldQueryVar = stepField.queryVar;
    const prevValue = stepField.selectedValue;
    const fieldsToIgnore = new Set([
      RetailFilterBuilderFields.FILTER_MODEL_ID,
      RetailFilterBuilderFields.FILTER_SUBMODEL_ID,
      RetailFilterBuilderFields.FILTER_TYPE,
    ]);
    if (stepFieldQueryVar === RetailFilterBuilderFields.ROOFTOP_ID && prevValue.length === 0) {
      for (const field of this.fields) {
        if (!fieldsToIgnore.has(field.queryVar as RetailFilterBuilderFields)) {
          field.displayType = setDisplayTypes({
            type: StepFieldDisplayType.DISABLED,
            active: !value,
          });
        }
      }
    }
    void super.onFieldSelection(stepField, value, persistSeededValues, disableAdvance);
  }

  /** Leverages backend validation for a given filter  */
  async validateFilter(websiteId: string, filter: RetailItemConnectionFilter): Promise<boolean> {
    try {
      await this.client.mutate({
        mutation: websiteRouteCreate,
        variables: {
          websiteId,
          filters: [filter],
          path: generateRandomPath(),
          _submit: false,
        },
      });
      return true;
    } catch (error) {
      const errors = getApiErrors(error);
      const transformedErrors = errors.map(error => {
        if (error.extensions) {
          error.extensions.fields = error.extensions?.fields.map(field =>
            field.includes(RetailFilterBuilderFields.ROOFTOP_ID)
              ? /*
                 * The validation returns `filter#0.rooftopId` but the builder field is `rooftopId` so we override the
                 * field to get the error to show up properly. In the future we should update the field to be
                 * `filter.rooftopId` for consistency but it would require some reworking of all other instances of
                 * RetailFilterStep.
                 */
                RetailFilterBuilderFields.ROOFTOP_ID
              : /*
                 * For all other fields this mutation returns the filter index in the errors field. Since we're always
                 * sending 1 filter for the validation we need to remove the `#0` for it to match the builder fields and
                 * display the error properly
                 */

                field.replace('filters#0', 'filter')
          );
        }
        return error;
      });
      logApiError(transformedErrors);
      this.setTier({ errors: transformedErrors });
      return false;
    }
  }

  async save(): Promise<boolean> {
    const {
      tier: { activeStep, data: currentData, seededData },
    } = this.props;

    const filterData = { ...seededData, ...currentData };
    const data = stripTypeNames(cloneDeep(filterData));

    const filter = getRetailItemConnectionFilterFromFields(this.fields);
    const isValid = await this.validateFilter(data.id, filter);
    if (!isValid) {
      return false;
    }

    this.setOnClosePrompt(undefined);
    const id = data?.filter ? data.filter.id : data?.currentFilterCount;
    void this.props.tier?.onStepSave?.(
      activeStep as Step<any, any>,
      {
        id,
        name: getFilterName(id),
        ...filter,
      } as RetailItemConnectionFilterStepOption
    );
    return true;
  }
}

export default FilterStep;
