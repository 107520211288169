import axios from 'axios';

import { APIType, getGraphURL } from 'utils/apiUtils';
import { getDeviceInfoHeaders } from 'utils/deviceInfoUtils';
import { authStorage } from 'utils/storage/auth';
import { impersonationStorage } from 'utils/storage/impersonation';
import { localeStorage, timeZoneStorage } from 'utils/storage/intl';

import { logApiError } from './graph/interfaces/apiErrors';

/**
 * Custom headers used for all graphql network requests
 */
export const getDefaultHeaders = () => {
  const impersonatedUserId = impersonationStorage.get()?.impersonatedUserId;

  return {
    'Channel-Type': 'WEB',
    'Accept-Language': localeStorage.get(),
    'Time-Zone': timeZoneStorage.get(),
    Authorization: authStorage.get(),
    ...(impersonatedUserId && {
      'As-User': impersonatedUserId,
    }),
    ...getDeviceInfoHeaders(),
  };
};

const ApiRequest = options => {
  const defaultOptions = {
    uri: getGraphURL(APIType.GRAPHQL),
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
  };

  return axios({ ...defaultOptions, ...options }).catch((error: Error) => {
    logApiError(error);
    return error;
  });
};

export default ApiRequest;
