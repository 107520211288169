import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import {
  CommonInventoryItemFields,
  commonInventoryItemNoteField,
} from 'components/sections/createModify/inventoryItems/steps/DetailsInventoryItemFields';
import { DetailsInventoryItemBuilderFields } from 'components/sections/createModify/inventoryItems/steps/interfaces';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import type { ActionInputSettings } from 'components/ui/forms/shared/ActionInput';
import InputText from 'components/ui/forms/shared/InputText';
import RefreshActionButton from 'components/ui/forms/shared/RefreshActionButton';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { ElementTestId } from 'enums/testing';
import { RetailItemModifyParameter, WarrantyInputParameter } from 'store/api/graph/interfaces/types';

import { RetailItemDetailsBuilderFields } from './interfaces';

const VinRefreshButton = ({ onClick }) => (
  <RefreshActionButton onClick={onClick} testingId={ElementTestId.VIN_ACTION_BUTTON_REFRESH} />
);

const CarfaxRefreshButton = ({ onClick }) => (
  <RefreshActionButton onClick={onClick} testingId={ElementTestId.CARFAX_ACTION_BUTTON_REFRESH} />
);

/**
 * Master list of inventoryItem fields.
 * Unnecessary fields are filtered in `DetailsStep`.
 */
export const DetailsFields: StepFields = {
  [RetailItemDetailsBuilderFields.LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  [RetailItemDetailsBuilderFields.VEHICLE_LOCKED_FIELDS]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
  // Variation of vin step fields carried over
  [DetailsInventoryItemBuilderFields.STATUS]: {
    queryAlias: InventoryItem.RETAIL_ITEM_STATUS,
    label: 'status',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.item.retailItem.status',
    clear: { field: RetailItemModifyParameter._status },
  },
  [DetailsInventoryItemBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    queryAlias: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
  },
  [DetailsInventoryItemBuilderFields.VIN]: {
    label: 'vin',
    groupType: StepFieldType.ACTION_INPUT,
    settings: {
      input: InputText,
      actionButton: VinRefreshButton,
    } as ActionInputSettings,
    clear: { field: RetailItemModifyParameter._vin },
  },
  [DetailsInventoryItemBuilderFields.STOCK_NUMBER]: {
    label: 'stock_number_symbol',
    clear: { field: RetailItemModifyParameter._stockNumber },
  },
  [RetailItemDetailsBuilderFields.CONDITION]: {
    label: 'condition',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.DEFAULT],
    options: 'mutation.item.condition',
    hasSeparator: true,
    clear: { field: RetailItemModifyParameter._condition },
  },
  ...CommonInventoryItemFields(),
  [RetailItemDetailsBuilderFields.MANUFACTURER_VEHICLE_OPTION_CODES]: {
    label: 'manufacturer_options',
    queryAlias: InventoryItem.VEHICLE_MANUFACTURER_OPTIONS,
    groupType: StepFieldType.CUSTOM,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT, SubStepType.ASYNC],
    hasSeparator: true,
  },
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_ID]: {
    label: 'carfax_report_id',
    queryAlias: InventoryItem.CARFAX_REPORT,
    groupType: StepFieldType.ACTION_INPUT,
    hasSeparator: true,
    settings: {
      input: InputText,
      actionButton: CarfaxRefreshButton,
    } as ActionInputSettings,
    clear: { field: RetailItemModifyParameter._carfaxReportId },
  },
  // This field won't be visible to users, but is required to add a new carfax report
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_BADGES]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: RetailItemModifyParameter._carfaxReportBadges, allowEmptyArray: true },
  },
  [DetailsInventoryItemBuilderFields.CARFAX_REPORT_SHOW_WEB]: {
    label: 'show_badge',
    groupType: StepFieldType.SWITCH,
    queryAlias: InventoryItem.CARFAX_SHOW_WEB,
    clear: { field: RetailItemModifyParameter._carfaxReportShowWeb },
  },
  [RetailItemDetailsBuilderFields.STANDARD_EQUIPMENT]: {
    label: 'standard_equipment',
    queryAlias: InventoryItem.STANDARD_EQUIPMENT,
    groupType: StepFieldType.CUSTOM,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT, SubStepType.ASYNC],
    hasSeparator: true,
  },
  [RetailItemDetailsBuilderFields.TECHNICAL_SPECIFICATIONS]: {
    label: 'technical_specification_other',
    queryAlias: InventoryItem.TECHNICAL_SPECIFICATIONS,
    groupType: StepFieldType.CUSTOM,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT, SubStepType.ASYNC],
    hasSeparator: true,
  },
  [RetailItemDetailsBuilderFields.FLAGS]: {
    label: 'flags',
    groupType: StepFieldType.FLAGS,
    groupSubTypes: [StepFieldSubType.TOGGLE, StepFieldSubType.FIELD_GROUP],
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
    queryAlias: [
      InventoryItem.FEATURED,
      InventoryItem.AS_IS,
      InventoryItem.DEMO,
      InventoryItem.ON_ORDER,
      InventoryItem.IN_TRANSIT,
      InventoryItem.CERTIFIED,
      InventoryItem.SHOW_WEB,
      InventoryItem.LOCKED,
    ],
  },
  [RetailItemDetailsBuilderFields.TAG_IDS]: {
    label: 'tags',
    queryAlias: 'tagName',
    groupType: StepFieldType.TAGS,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT],
    subStep: [SubStepType.ASYNC],
    subStepAddConfig: SubStepAddConfigs[BuilderType.TAGS_CREATE],
    hasSeparator: true,
  },
  ...commonInventoryItemNoteField,
  // This is a hidden field. When submitted, it will clear the show warranty toggle in the Pricing & Mileage step.
  [DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_SHOW_WEB]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: WarrantyInputParameter._showWeb, target: InventoryItem.WARRANTY },
  },
  [DetailsInventoryItemBuilderFields.WARRANTY_CATEGORY_OVERRIDE]: {
    displayType: [StepFieldDisplayType.HIDDEN],
    clear: { field: WarrantyInputParameter._quebecCategoryOverride, target: InventoryItem.WARRANTY },
  },
  // This is a hidden field. When decoding a VIN, the MSRP value should also be saved
  [DetailsInventoryItemBuilderFields.MSRP]: {
    displayType: [StepFieldDisplayType.HIDDEN],
  },
};

export default DetailsFields;
