import { getLeadOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { LeadResponseType } from 'store/api/graph/responses/responseTypes';

import { PURCHASER_CATEGORY_PROPERTY_NAME, PurchaserCategory } from './purchaseActivityInterfaces';

/**
 * Returns a function to be added to `asyncConfigurations` that adds the list of All leads to
 * a given list of Linked leads, and adds a `purchaserCategory` property to both of them
 * for use with `subStepCategories` in builder field definitions.
 */
export function getPurchaserOptions(linkedLeads: LeadResponseType[], rooftopId: string) {
  return async (keyword: string | undefined) => {
    const allLeads = await getLeadOptions(rooftopId, keyword);
    const categorizedLinkedLeads = linkedLeads.map(lead => ({
      ...lead,
      [PURCHASER_CATEGORY_PROPERTY_NAME]: PurchaserCategory.LINKED,
    }));
    return [
      ...(keyword ? categorizedLinkedLeads.filter(lead => lead.displayName.includes(keyword)) : categorizedLinkedLeads),
      ...allLeads.map(lead => ({ ...lead, [PURCHASER_CATEGORY_PROPERTY_NAME]: PurchaserCategory.ALL })),
    ];
  };
}
