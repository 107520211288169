import { gql } from '@apollo/client';

export const address = gql`
  fragment AddressFragment on Location {
    address
    city
    country
    countryCode
    placeId
    region
    regionCode
    timeZone
    longitude
    latitude
    zipCode
  }
`;
