import NoConnectionIcon from 'components/ui/icons/NoConnectionIcon';
import Placeholder from 'components/ui/placeholders/Placeholder';
import { BODY_TEXT } from 'styles/color';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const NoConnection = () => (
  <Placeholder
    icon={<NoConnectionIcon width={64} height={64} color={BODY_TEXT} />}
    title={t('no_connection')}
    subtitle={t('connection_error_placeholder_message')}
    buttonText={t('refresh')}
    onClick={() => window.location.reload()}
  />
);

export default NoConnection;
