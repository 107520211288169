export enum RetailItemPricingInfoBuilderFields {
  PRICE_1 = 'price1',
  PRICE_2 = 'price2',
  MSRP = 'msrp',
  COST = 'cost',
  CASH_CONFIGURATION_ENABLED = 'cashEnabled',
  FINANCE_CONFIGURATION_ENABLED = 'financeEnabled',
  LEASE_CONFIGURATION_ENABLED = 'leaseEnabled',
  REVERT_CONFIGURATION = 'revertConfiguration',
}

/**
 * In the retail builder there is a field on the Details step for MSRP as well as the Pricing & Mileage step. If you
 * need to open the builder and have the MSRP field from the Pricing & Mileage step highlighted, then this field alias
 * can be used.
 */
export const MSRP_PRICING_AND_MILEAGE_FIELD = 'msrp_pricing_and_mileage';
