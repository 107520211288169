import { memo } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import Text from 'components/core/typography/Text';
import type { RetailBulkAdjustment, RetailPriceAdjustment } from 'store/api/graph/interfaces/types';
import { GREEN_500, RED_500 } from 'styles/tokens';
import { decimalPercentToString } from 'utils/stringUtils';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = Partial<RetailPriceAdjustment | RetailBulkAdjustment> & {
  startingPrice?: number;
};

const PriceListItem = (props: Props) => {
  const isBulkAdjustment = props?.__typename === 'RetailBulkAdjustment';
  const priceAdjustmentData = isBulkAdjustment
    ? {
        ...(props as RetailBulkAdjustment)?.attributes,
        ...(props as RetailBulkAdjustment),
      }
    : (props as RetailPriceAdjustment);

  const { label, fixed, percentage, percentageAsAmount } = priceAdjustmentData as RetailPriceAdjustment;
  const priceLabel = label?.value || label;
  const isPercentage = isNil(fixed);
  const priceValue = isPercentage ? percentage : fixed?.amount;
  const isNegative = (priceValue || 0) < 0;
  const priceValueLabel = isPercentage ? decimalPercentToString(percentage as number) : fixed?.formattedAmountRounded;
  const amountLabel = isNil(percentageAsAmount)
    ? // Percentage relative amount label
      priceValueLabel
    : // Fixed label
      `(${priceValueLabel}) ${percentageAsAmount.formattedAmountRounded}`;

  if (!priceLabel) {
    return null;
  }

  return (
    <Container>
      <Text>
        <>{priceLabel}</>
      </Text>
      <PrimaryText
        css={css`
          color: ${isNegative ? RED_500 : GREEN_500};
        `}
      >
        {!!priceValue && amountLabel}
      </PrimaryText>
    </Container>
  );
};

export default memo(PriceListItem);
