import styled from 'styled-components/macro';

import SpinnerIcon from 'components/ui/icons/SpinnerIcon';
import { BODY_TEXT } from 'styles/color';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /** 
  * The container must be 1px larger than the SpinnerIcon for the transformation origin to be centered in IE.
  * A width, height, & viewBox attribute must be present on the svg element to avoid scaling issues.
  */
  width: 15px;
  height: 15px;
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Generic spinner.
 */
const Spinner = ({ fill = BODY_TEXT, ...props }) => (
  <SpinnerContainer {...props}>
    <SpinnerIcon fill={fill} width="14px" height="14px" />
  </SpinnerContainer>
);

export default Spinner;
