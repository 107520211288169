import { useEffect, useMemo, useState } from 'react';

import type { DocumentNode } from 'graphql';

import { retailFiltersMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import type { QueryConfig } from 'hooks/useQuery';
import { useQuery } from 'hooks/useQuery';
import type {
  RetailFiltersMetaQuery,
  RetailFiltersMetaQueryVariables,
  RooftopIntegrationListFragment,
} from 'store/api/graph/interfaces/types';
import { IntegrationType } from 'store/api/graph/interfaces/types';
import type CustomQueryResult from 'store/apollo/interfaces/customQueryResult';
import { combineDetailAndMetadataResponses, getRetailFiltersMetaQueryVariables } from 'utils/retailFilterUtils';

export const useRooftopIntegrationRetailFiltersDetailsQuery = (
  query: DocumentNode,
  config: QueryConfig,
  itemData: RooftopIntegrationListFragment
): CustomQueryResult => {
  const [isInventoryExport, setIsInventoryExport] = useState<boolean>(false);

  useEffect(() => {
    setIsInventoryExport(itemData?.integrationType?.id === IntegrationType.INVENTORY_EXPORT);
  }, [itemData]);

  const detailsResponse = useQuery(query, config);

  const metadataResponse = useQuery<RetailFiltersMetaQuery, RetailFiltersMetaQueryVariables>(retailFiltersMetaQuery, {
    variables: getRetailFiltersMetaQueryVariables(
      detailsResponse?.data?.item?.rooftopName?.id,
      detailsResponse?.data?.item?.inventoryExportAttributes?.filter
    ),
    ignore: !isInventoryExport || detailsResponse.isLoading,
  });

  return useMemo(() => {
    if (!isInventoryExport) {
      return detailsResponse;
    }

    return combineDetailAndMetadataResponses(detailsResponse, metadataResponse, 'data.item.inventoryExportAttributes');
  }, [detailsResponse, isInventoryExport, metadataResponse]);
};
