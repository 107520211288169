import { useEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import { PrimaryLabel } from 'components/core/typography/Label';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import ChevronRightIcon from 'components/ui/icons/ChevronRightIcon';
import { Clickable, WhiteButton } from 'components/ui/shared/Button';
import { Pagination } from 'enums/pagination';
import { useSearch } from 'hooks/contexts/useSearch';
import { DIVIDER } from 'styles/color';
import { SPACE_10 } from 'styles/spacing';
import { NEUTRAL_050 } from 'styles/tokens';
import { Z_INDEX_1 } from 'styles/z-index';
import { calculatePreviousPaginationParams } from 'utils/apiUtils';
import { formatNumber } from 'utils/formatUtils';
import { translate } from 'utils/intlUtils';

import ChevronLeftIcon from '../icons/ChevronLeftIcon';

const Container = styled.div<{ hasPreviousPage?: boolean; hasNextPage?: boolean }>`
  display: flex;
  background: ${NEUTRAL_050};
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  border-top: 1px solid ${DIVIDER};
  height: 100%;
  z-index: ${Z_INDEX_1};
`;

const PaginationActionsContainer = styled.div`
  display: flex;
  text-align: right;
  align-items: center;

  > * {
    margin-right: ${SPACE_10};

    &:last-child {
      margin-right: 0;
    }
  }

  ${SecondaryText} {
    padding: 0 ${SPACE_10};
  }
`;

interface Props {
  /** Pagination information that shows how many items are available/shown */
  pageInfo: any;
  /** Callback function to notify the parent when the page is changed */
  onPageChange: () => void;
  /** Callback function to notify the parent when the select all button has been clicked */
  onSelectedAll: (selectedAll: boolean) => void;
  /** Column information from the connection that determines whether or not a column exists and can be exported */
  columnsInfo: {
    exportable: boolean;
    id: string;
  }[];
  ids: string[];
  columnIds: string[];
}

const TableFooterPagination = (props: Props) => {
  const {
    pageInfo: { startCursor, endCursor, totalEdges, hasNextPage, hasPreviousPage },
    onPageChange,
    onSelectedAll,
    maxPageCount,
    formattedTotalCount,
    ids,
  } = useMemo(
    () => ({
      ...props,
      maxPageCount: Number(props.pageInfo.endCursor) - Number(props.pageInfo.startCursor),
      formattedTotalCount: formatNumber(props.pageInfo.totalEdges),
      // Filtering out invalid columns
      columnIds: props.columnIds.filter(id => !!props.columnsInfo.some(col => col.id === id && col.exportable)),
    }),
    [props]
  );

  const { updatePaginationParams } = useSearch();
  const { t } = translate;
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const pageSize = Pagination.LIST_LENGTH;

  // Conditionally rendering the select all button to maintain grid spacing
  const selectAll = useMemo(
    () =>
      ids.length > maxPageCount && totalEdges > maxPageCount && !isAllSelected ? (
        <Clickable
          onClick={() => {
            setIsAllSelected(true);
            onSelectedAll(true);
          }}
        >
          <PrimaryLabel>{translate.t('select_all_x_items', [formattedTotalCount])}</PrimaryLabel>
        </Clickable>
      ) : null,
    [formattedTotalCount, ids.length, isAllSelected, maxPageCount, totalEdges, onSelectedAll]
  );

  // Reset to selected amount should user selection change
  useEffect(() => {
    if (ids.length < maxPageCount) {
      setIsAllSelected(false);
      onSelectedAll(false);
    }
  }, [ids.length, maxPageCount, onSelectedAll, isAllSelected]);

  // Conditionally set the label based on if the user has selected any or all items
  const selectionIndicatorLabel = ids.length > 0 ? 'selected_x_of_x' : 'showing_x_results';
  let selectionIndicatorValue =
    ids.length > 0 ? ids.length : endCursor === null && startCursor === null ? 0 : endCursor - startCursor + 1;
  if (isAllSelected) {
    selectionIndicatorValue = totalEdges;
  }

  return (
    <Container hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage}>
      <TextRow>
        <SecondaryText>
          {t(selectionIndicatorLabel, [formatNumber(selectionIndicatorValue), formatNumber(totalEdges)])}
        </SecondaryText>
        {!!selectAll && (
          <SecondaryText
            css={css`
              margin-left: ${SPACE_10};
            `}
          >
            {selectAll}
          </SecondaryText>
        )}
      </TextRow>
      <PaginationActionsContainer>
        {(hasPreviousPage || hasNextPage) && (
          <SecondaryText>
            {t('page_x_of_n', [Math.ceil(startCursor / pageSize), formatNumber(Math.ceil(totalEdges / pageSize))])}
          </SecondaryText>
        )}
        {hasPreviousPage && (
          <WhiteButton
            onClick={() => {
              updatePaginationParams(calculatePreviousPaginationParams(startCursor, pageSize));
              onPageChange();
            }}
            title={t('previous')}
          >
            <ChevronLeftIcon />
          </WhiteButton>
        )}
        {hasNextPage && (
          <WhiteButton
            onClick={() => {
              updatePaginationParams({ first: pageSize, after: endCursor });
              onPageChange();
            }}
            title={t('next')}
          >
            <ChevronRightIcon />
          </WhiteButton>
        )}
      </PaginationActionsContainer>
    </Container>
  );
};
export default TableFooterPagination;
