import { gql } from '@apollo/client';

import { conversationItemAttachment } from './conversationItemAttachment';
import { identity } from './identity';

export const conversationItem = gql`
  fragment ConversationItemFragment on ConversationItem {
    id
    attachments {
      ...ConversationItemAttachmentFragment
    }
    message
    type
    typeName
    created
    to {
      ...IdentityFragment
    }
    from {
      ...IdentityFragment
    }
  }
  ${conversationItemAttachment}
  ${identity}
`;
