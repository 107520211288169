import { gql } from '@apollo/client';

import { selectIntOption, selectStringOption } from 'store/api/graph/fragments/selectOption';

export const motorcycleAttributesMetadata = gql`
  fragment MotorcycleAttributesMetadataFragment on MotorcycleAttributesMetadata {
    category {
      ...SelectStringOptionFragment
    }
    color {
      ...SelectStringOptionFragment
    }
    cylinders {
      ...SelectIntOptionFragment
    }
    fuelType {
      ...SelectStringOptionFragment
    }
  }
  ${selectStringOption}
  ${selectIntOption}
`;
