import { gql } from '@apollo/client';

import { tag } from './tag';
import { userList } from './userList';

export const taskList = gql`
  fragment TaskListFragment on Task {
    id
    archived

    rooftopName: rooftop {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    status
    statusName
    dateDue
    dateCompleted
    title
    note
    tagName: tags {
      ...TagFragment
    }
    createdByName: createdBy {
      ...UserListFragment
    }
    updated
  }
  ${userList}
  ${tag}
`;
