import styled from 'styled-components/macro';

import { DIVIDER } from 'styles/color';

export const VerticalDivider = styled.hr`
  background-color: ${DIVIDER};
  border: 0;
  height: 24px;
  margin: 0 4px;
  width: 1px;
`;
