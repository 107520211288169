import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { mailboxCreate, mailboxModify } from 'components/sections/createModify/mailbox/MailboxCreateModifyQuery';
import MailboxQueryMethods from 'components/sections/createModify/mailbox/MailboxQueryMethods';
import DetailsFields from 'components/sections/createModify/mailbox/steps/DetailsFields';
import DetailsStep from 'components/sections/createModify/mailbox/steps/DetailsStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

export const detailStepTooltipConfig = {
  title: 'get_started',
  content: 'mailbox_detail_step_tooltip_content',
};

const MailboxBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.MAILBOX_CREATE]: {
    refetchQueries: MailboxQueryMethods.refetchQueries,
    steps: [
      {
        id: 'MAILBOX_DETAILS',
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
    ],
    mutations: {
      create: mailboxCreate,
    },
    requiredPermissions: [{ resource: ResourceType.MAILBOXES, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAILBOXES,
  },
  [BuilderType.MAILBOX_MODIFY]: {
    refetchQueries: MailboxQueryMethods.refetchQueries,
    getData: MailboxQueryMethods.getData,
    steps: [
      {
        id: 'MAILBOX_DETAILS',
        step: DetailsStep,
        stepName: 'details',
        isEnabled: true,
        fields: DetailsFields,
        tooltip: detailStepTooltipConfig,
      },
    ],
    mutations: {
      modify: mailboxModify,
    },
    requiredPermissions: [{ resource: ResourceType.MAILBOXES, level: AccessLevel.FULL }],
    resourceType: ResourceType.MAILBOXES,
  },
};

export default MailboxBuilders;
