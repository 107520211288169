import { memo, type SVGProps } from 'react';

const LinkUnsetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 20 20" width={20} height={20} {...props}>
    <path
      fill="currentcolor"
      d="m13.11 10.74-1.604-1.613h.949c.266 0 .49.091.673.274a.916.916 0 0 1 .275.674.859.859 0 0 1-.293.666Zm3.893 7.48a.829.829 0 0 1-.61.244.829.829 0 0 1-.611-.244L1.72 4.163a.829.829 0 0 1-.244-.61c0-.245.081-.449.244-.611a.829.829 0 0 1 .61-.244c.245 0 .448.08.611.244l14.062 14.062a.826.826 0 0 1 .244.608.826.826 0 0 1-.244.608ZM5.828 14.336c-1.2 0-2.222-.423-3.068-1.268-.845-.846-1.268-1.869-1.268-3.068 0-.965.286-1.83.857-2.595A4.22 4.22 0 0 1 4.582 5.85l1.711 1.71h-.465c-.678 0-1.254.238-1.729.713A2.353 2.353 0 0 0 3.388 10c0 .678.237 1.254.711 1.729a2.356 2.356 0 0 0 1.729.711h2.31c.267 0 .491.092.674.275a.916.916 0 0 1 .275.673c0 .266-.092.49-.275.674a.916.916 0 0 1-.673.274H5.828Zm1.642-3.463a.844.844 0 0 1-.621-.252.844.844 0 0 1-.253-.62c0-.247.085-.454.253-.622a.844.844 0 0 1 .62-.252h.462l1.725 1.746H7.47Zm7.931 2.359a.952.952 0 0 1-.168-.7.851.851 0 0 1 .377-.6c.306-.23.55-.511.73-.843A2.24 2.24 0 0 0 16.611 10c0-.678-.236-1.254-.709-1.728a2.345 2.345 0 0 0-1.726-.712h-2.365a.915.915 0 0 1-.674-.275.916.916 0 0 1-.274-.673c0-.266.091-.49.274-.674a.915.915 0 0 1 .674-.274h2.36c1.2 0 2.222.423 3.068 1.269.845.845 1.268 1.868 1.268 3.067 0 .69-.162 1.337-.486 1.939a4.382 4.382 0 0 1-1.32 1.5.951.951 0 0 1-.7.169.851.851 0 0 1-.6-.377Z"
    />
  </svg>
);

export default memo(LinkUnsetIcon);
