import styled from 'styled-components/macro';

import MultiLineText from 'components/core/typography/MultiLineText';
import { StructuredDialog } from 'components/ui/dialogs/StructuredDialog';
import { EntityType } from 'store/api/graph/interfaces/types';
import { FONT_WEIGHT_BOLD } from 'styles/typography';
import { translate } from 'utils/intlUtils';

const { t } = translate;

/** A list of the reasons why the builder might not be accessible for the given lead or their rooftop. */
export enum CreditApplicationBuilderErrorReason {
  LEAD_NAME,
  ROOFTOP_DEALERTRACK_INFO,
}

/** Lists the associated entity and fields that are missing for a given error reason. */
const REASON_DATA: {
  [reason in CreditApplicationBuilderErrorReason]: {
    entity: EntityType;
    missingFieldTranslationKeys: [string, string];
  };
} = {
  [CreditApplicationBuilderErrorReason.LEAD_NAME]: {
    entity: EntityType.LEAD,
    missingFieldTranslationKeys: ['first_name', 'last_name'],
  },
  [CreditApplicationBuilderErrorReason.ROOFTOP_DEALERTRACK_INFO]: {
    entity: EntityType.ROOFTOP,
    missingFieldTranslationKeys: ['dealertrack_id', 'dealertrack_confirm_id'],
  },
};

interface CreditApplicationsErrorModalProps {
  /** Whether or not the modal is open */
  isOpen: boolean;
  /** What to call when the modal is to be closed */
  onClose: () => void;
  /** The reason this modal is shown */
  reason: CreditApplicationBuilderErrorReason;
}

const ErrorText = styled(MultiLineText)`
  b {
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`;

/**
 * Renders an error modal used exclusively for `CreditApplicationsSection`.
 * Its message is determined by the given `reason`.
 */
const CreditApplicationsErrorModal = ({ isOpen, onClose, reason }: CreditApplicationsErrorModalProps) => {
  const reasonData = REASON_DATA[reason];
  return (
    <StructuredDialog isOpen={isOpen} onClose={onClose} header={t('access_denied')}>
      <ErrorText>
        <span>
          {t('feature_access_x_error_message', [reasonData.entity])}
          <br />
          <b>{t(reasonData.missingFieldTranslationKeys[0])}</b> {t('and')}{' '}
          <b>{t(reasonData.missingFieldTranslationKeys[1])}</b>
        </span>
      </ErrorText>
    </StructuredDialog>
  );
};

export default CreditApplicationsErrorModal;
