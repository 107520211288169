import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  rooftopIntegrationCreate,
  rooftopIntegrationModify,
} from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationCreateModifyQueries';
import RooftopIntegrationQueryMethods from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationQueryMethods';
import rooftopIntegrationFields from 'components/sections/createModify/rooftopIntegration/steps/DetailsFields';
import RooftopIntegrationStep from 'components/sections/createModify/rooftopIntegration/steps/DetailsStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import { RooftopIntegrationBuilderStep } from './steps/interfaces';

const RooftopIntegrationBuilder: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_INTEGRATION_CREATE]: {
    refetchQueries: RooftopIntegrationQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: RooftopIntegrationStep,
        stepName: 'details',
        fields: rooftopIntegrationFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      create: rooftopIntegrationCreate,
    },
  },
  [BuilderType.ROOFTOP_INTEGRATION_MODIFY]: {
    refetchQueries: RooftopIntegrationQueryMethods.refetchQueries,
    getMetaData: RooftopIntegrationQueryMethods.getMetaData,
    getData: RooftopIntegrationQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: RooftopIntegrationStep,
        stepName: 'details',
        fields: rooftopIntegrationFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      modify: rooftopIntegrationModify,
    },
  },
};

export default RooftopIntegrationBuilder;
