import type { ElementType, UIEvent } from 'react';

import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import Button from 'components/ui/shared/Button';
import { BODY_TEXT_SECONDARY } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_500, SPACE_100, SPACE_150, SPACE_200 } from 'styles/tokens';
import { FONT_SIZE_10, LETTER_SPACING_EXPANDED_2 } from 'styles/typography';
import { formatNumber } from 'utils/formatUtils';

const ItemTypeIcon = styled.div`
  display: flex;
`;

const ItemTypeContainer = styled(Button)`
  color: ${props => (props.selected ? NEUTRAL_0 : BODY_TEXT_SECONDARY)};
  ${({ selected, isToggleable }) =>
    selected &&
    !isToggleable &&
    css`
      pointer-events: none;
    `}

  ${ItemTypeIcon} {
    color: ${props => (props.selected ? NEUTRAL_0 : NEUTRAL_500)};
  }
`;

const ItemTypeLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98px;
  width: 100%;
  gap: ${SPACE_200};
`;

const ItemTypeLabel = styled(Text)`
  color: inherit;
`;

const ItemTypeSubLabel = styled(Text)`
  color: inherit;
  font-size: ${FONT_SIZE_10};
  margin-top: ${SPACE_150};
  letter-spacing: ${LETTER_SPACING_EXPANDED_2};
`;

const ItemTypeCount = styled(Text)`
  color: inherit;
  font-size: ${FONT_SIZE_10};
  margin-top: ${SPACE_100};
  letter-spacing: ${LETTER_SPACING_EXPANDED_2};
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  selected: boolean;
  // TODO: [#2350] Refactor label prop to a translation key, and toUpperCase() the results
  label: string;
  subLabel?: string;
  isToggleable?: boolean;
  count?: number;
  onClick: (e: UIEvent<HTMLElement>) => void;
  icon?: ElementType;
  disabled?: boolean;
}

const ItemTypeSelector = ({ selected, isToggleable, icon, label, subLabel, count, onClick, ...props }: Props) => {
  const ItemIcon = icon;
  return (
    <ItemTypeContainer selected={selected} isToggleable={isToggleable} onClick={onClick} {...props}>
      <ItemTypeLayout>
        {!!ItemIcon && (
          <ItemTypeIcon>
            <ItemIcon height={28} width={28} />
          </ItemTypeIcon>
        )}
        <LabelContainer>
          <ItemTypeLabel>{label}</ItemTypeLabel>
          {!!subLabel && <ItemTypeSubLabel>{subLabel?.toUpperCase()}</ItemTypeSubLabel>}
          {!isNil(count) && <ItemTypeCount>({formatNumber(count)})</ItemTypeCount>}
        </LabelContainer>
      </ItemTypeLayout>
    </ItemTypeContainer>
  );
};

export default ItemTypeSelector;
