import { get, isEmpty, isNil, pick } from 'lodash-es';
import type { Primitive } from 'type-fest';

import type {
  Location,
  LocationInput,
  MonetaryAmount,
  PriceRetailBulkAdjustmentAttributes,
} from 'store/api/graph/interfaces/types';
import { MileageUnit } from 'store/api/graph/interfaces/types';
import { capitalize, capitalizeFirstChar, decimalPercentToString } from 'utils/stringUtils';

import { isFrCA, Locale, translate } from './intlUtils';

// TODO: Add this to our translations once Lokalise is setup
export const PLACEHOLDER = '-';

export const formatCurrency = ({
  amount,
  currency = 'CAD',
  locale = Locale.EN_CA,
  omitDollarSign = false,
}: {
  /** Currency value to be formatted */
  amount: number;
  /** Currency to use in formatting (default is CAD) */
  currency?: 'CAD' | 'USD';
  /** Locale to use in formatting (default is EN_CA */
  locale?: Locale;
  /** Whether to omit the dollar sign */
  omitDollarSign?: boolean;
}) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
  const formattedValue = formatter.format(amount);
  return omitDollarSign ? formattedValue.replace('$', '') : formattedValue;
};

const numberFormatter = new Intl.NumberFormat();
export const formatNumber = (number: number) => numberFormatter.format(number);

type User = { firstName?: string | null; lastName?: string | null; displayName?: string | null };
export const formatFullName = (user?: User, fallback?: string) =>
  user?.displayName || `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || fallback || '';

export const formatInitials = (user: User, separator = '') =>
  user.firstName || user.lastName
    ? formatFullName(user)
        .split(' ')
        .map(word => word?.[0] || '')
        .reduce((acc: string[], val) => {
          if (acc.length > 0) {
            acc[1] = val;
          } else {
            acc[0] = val;
          }
          return acc;
        }, [])
        .join(separator)
        .toUpperCase()
    : '?';

export const formatAmountRounded = (amount: Partial<MonetaryAmount> | null | undefined) =>
  get(amount, 'formattedAmountRounded', '');

export const formatAmount = (amount: Partial<MonetaryAmount> | null | undefined) => get(amount, 'formattedAmount', '');

/**
 * Formats a boolean into a representative string.
 * @param yesNoOverride Overrides the default strings with the given translation keys.
 */
export const formatBoolean = (
  bool: boolean,
  yesNoOverride?: { yesTranslationKey: string; noTranslationKey: string }
): string =>
  bool ? translate.t(yesNoOverride?.yesTranslationKey || 'yes') : translate.t(yesNoOverride?.noTranslationKey || 'no');

// Used to format location data from the API
export const formatLocationData = (data?: LocationInput | Location, inputsOnly = false) => {
  if (isEmpty(data)) {
    return undefined;
  }
  const locationInputKeys: Array<keyof LocationInput> = [
    'address',
    'city',
    'countryCode',
    'latitude',
    'longitude',
    'placeId',
    'regionCode',
    'zipCode',
  ];

  const filteredObject = inputsOnly ? pick(data, locationInputKeys) : { ...data };

  for (const key of Object.keys(filteredObject)) {
    filteredObject[key] = filteredObject[key]?.hasOwnProperty('value')
      ? filteredObject[key]?.value
      : filteredObject[key];
  }

  return filteredObject;
};

// For formatting colour that comes back from a SelectOption, where `data` is a json string
export const formatColorFromData = (data?: any, fallback?: string): string =>
  (data && JSON.parse(data).rgbHex) || fallback;

// Returns a human readable file size using the JEDEC Spec
export const formatFileSize = (bytes: number): string => {
  const KB = 1024;
  const MB = KB * KB;
  const GB = MB * KB;

  if (bytes >= GB) {
    return `${(bytes / GB).toFixed(2)} GB`;
  } else if (bytes >= MB) {
    return `${(bytes / MB).toFixed(2)} MB`;
  } else if (bytes >= KB) {
    return `${(bytes / KB).toFixed(2)} KB`;
  } else {
    return `${bytes} B`;
  }
};

// Helper that returns true/false depending on  whether the value is withing the java 32int supported value
export const is32IntCompatible = (value: number | string): boolean => Number(value) <= 2147483647;

/**
 * Formats a long block of text to an ellipsed version, if needed.
 * Used for preview purposes. e.g. in ListItems.
 * @param {string} fullText The raw text content
 * @param {number} maxTextLength The max characters shown before it is ellipsed.
 */
export const formatTextForPreview = (fullText: string | undefined | null, maxTextLength: number) => {
  if (!fullText) {
    return fullText;
  }
  const sanitizedInput = fullText.replaceAll(/(\r\n|\n|\r)/gm, ' ');
  return sanitizedInput?.length > maxTextLength
    ? `${sanitizedInput?.slice(0, Math.max(0, maxTextLength))}...`
    : sanitizedInput;
};

/**
 * Formats an array of strings into a string with bullet characters separating each item.
 */
export const formatTextWithBullets = (text: Array<string | undefined | null>) => {
  const filtered = text.filter(Boolean);
  return filtered.length > 0 ? filtered.join('  •  ') : null;
};

/**
 * Helper to determine if a price adjustment is a discount or fee. Returns true if the adjustment is a discount, false
 * if it is a fee.
 * @param adjustment - the price adjustment
 */
export const isPriceAdjustmentDiscount = (adjustment: PriceRetailBulkAdjustmentAttributes): boolean =>
  isNil(adjustment.fixed) ? (adjustment.percentage as number) < 0 : adjustment.fixed.amount < 0;

/**
 * Format a price adjustment amount. Includes a pre-pended label before the adjustment amount indicating whether or
 * not the adjustment is a discount or fee.
 * @param adjustment - the price adjustment to format
 */
export const formatPriceAdjustmentAmount = (adjustment?: PriceRetailBulkAdjustmentAttributes): string | null => {
  if (isNil(adjustment)) {
    return null;
  }

  const label = isPriceAdjustmentDiscount(adjustment) ? `discount` : `fee`;

  // If neither fixed nor percentage amount is defined, this is a special tax adjustment, just show the adjustment label
  if (isNil(adjustment.fixed) && isNil(adjustment.percentage)) {
    return label;
  }

  const value = isNil(adjustment.fixed)
    ? decimalPercentToString(adjustment.percentage as number)
    : adjustment.fixed.formattedAmountRounded;

  return `${label}: ${value}`;
};

/**
 * Format range data, rules are as follows:
 * If only gte value is defined, string will be '≥ gte'
 * If only lte value is defined, string will be '≤ lte'
 * If both gte and lte are defined, string will be 'gte - lte'
 * @param range - A range data type that contains a lte and gte property
 * @param units - Optional units to display
 */
export const formatRange = (range: { gte?: any; lte?: any }, units?: string): string => {
  const formatUnits = units ? ` ${units}` : '';

  if (range?.gte && range?.lte) {
    return `${range?.gte}${formatUnits} - ${range?.lte}${formatUnits}`;
  } else if (range?.gte) {
    return `≥ ${range?.gte}${formatUnits}`;
  } else if (range?.lte) {
    return `≤ ${range?.lte}${formatUnits}`;
  } else {
    return PLACEHOLDER;
  }
};

/**
 * Format a mileage value. Usually this comes from the API, but there are some cases where we need to display
 * a mileage value from a mileage type input
 * @param amount - the mileage amount (string or number accepted)
 * @param unit - the unit for this mileage
 */
export const formatMileage = (amount: number | string, unit: MileageUnit): string => {
  const mileageAmount = Number(amount);
  return `${Number.isNaN(mileageAmount) ? 0 : mileageAmount.toLocaleString()} ${
    unit === MileageUnit.KILOMETERS ? 'km' : 'mi'
  }`;
};

/**
 * Format a title string. Titles are used in builders, menu options, prompt dialogs, etc. Depending on the locale
 * selected, there will be different rules for formatting.
 * @param str
 */
export const formatTitleString = (str: string): string =>
  isFrCA() ? capitalizeFirstChar(str.toLowerCase()) : capitalize(str);

/**
 * Checks if a value is a primitive type.
 *
 * Primitive types include: undefined, null, boolean, number, string, bigint, and symbol.
 *
 * @param value - The value to check.
 * @returns True if the value is a primitive type, false otherwise.
 *
 * @example
 * ```
 * isPrimitive(42); // true
 * isPrimitive('hello'); // true
 * isPrimitive(false); // true
 * isPrimitive(null); // true
 * isPrimitive(undefined); // true
 * isPrimitive(Symbol('symbol')); // true
 * isPrimitive({}); // false
 * isPrimitive([]); // false
 * isPrimitive(() => {}); // false
 * ```
 */
export const isPrimitive = (value: unknown): value is Primitive => {
  const typeOfValue = typeof value;

  return (
    value === null ||
    typeOfValue === 'undefined' ||
    typeOfValue === 'boolean' ||
    typeOfValue === 'number' ||
    typeOfValue === 'string' ||
    typeOfValue === 'bigint' ||
    typeOfValue === 'symbol'
  );
};
