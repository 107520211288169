import { get, isNil } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import type Tier from 'components/core/createModify/interfaces/tier';
import LoggingService from 'components/core/logging/LoggingService';
import { BuilderType } from 'enums/builderType';
import { InventoryItem } from 'enums/columns/inventoryItem';
import { CustomEntity } from 'enums/extendedEntityType';
import type { CashRetailPricingInput, IncentiveAttributes, RetailItem } from 'store/api/graph/interfaces/types';
import {
  CashRetailPricingConfigurationInputParameter,
  FinanceRetailPricingConfigurationInputParameter,
  LeaseRetailPricingConfigurationInputParameter,
  PaymentOption,
} from 'store/api/graph/interfaces/types';
import { decimalPercentToString } from 'utils/stringUtils';

export type InventoryPaymentOption =
  | InventoryItem.FINANCE_RETAIL_PRICING
  | InventoryItem.CASH_RETAIL_PRICING
  | InventoryItem.LEASE_RETAIL_PRICING;

/**
 * Map a pricing payment method to its ConfigurationInputParameter type
 *
 * @param paymentMethod - the payment method
 */
export const getPricingConfigurationModifyParameter = (
  paymentMethod: InventoryPaymentOption
):
  | typeof CashRetailPricingConfigurationInputParameter
  | typeof FinanceRetailPricingConfigurationInputParameter
  | typeof LeaseRetailPricingConfigurationInputParameter =>
  ({
    [InventoryItem.CASH_RETAIL_PRICING]: CashRetailPricingConfigurationInputParameter,
    [InventoryItem.FINANCE_RETAIL_PRICING]: FinanceRetailPricingConfigurationInputParameter,
    [InventoryItem.LEASE_RETAIL_PRICING]: LeaseRetailPricingConfigurationInputParameter,
  })[paymentMethod];

/**
 * Map a pricing payment method to its configuration enabled path (configuration.enabled) in the RetailItem data,
 * given the InventoryPaymentOption value
 *
 * @param paymentMethod - the payment method
 */
export const getPricingConfigurationEnabledPath = (paymentMethod: InventoryPaymentOption) => {
  const pricingConfiguration = {
    [InventoryItem.CASH_RETAIL_PRICING]: InventoryItem.CASH_CONFIGURATION_ENABLED,
    [InventoryItem.FINANCE_RETAIL_PRICING]: InventoryItem.FINANCE_CONFIGURATION_ENABLED,
    [InventoryItem.LEASE_RETAIL_PRICING]: InventoryItem.LEASE_CONFIGURATION_ENABLED,
  } as const;

  return pricingConfiguration[paymentMethod];
};

/**
 * Map a pricing payment option to its pricing data property path in the RetailItem data,
 * given the PaymentOption value
 *
 * @param payment - the PaymentOption
 */
export const getRetailItemsPricingPropertyPath = (payment: PaymentOption) => {
  const retailPricing = {
    [PaymentOption.CASH]: InventoryItem.CASH_RETAIL_PRICING,
    [PaymentOption.FINANCE]: InventoryItem.FINANCE_RETAIL_PRICING,
    [PaymentOption.LEASE]: InventoryItem.LEASE_RETAIL_PRICING,
  } as const;

  return retailPricing[payment];
};

/**
 * This util will iterate through the provided cash, finance, or lease enabled toggles, and will return
 * the configuration override payload needed to update the enabled status of this payment option. If the toggles
 * value is different from the configuration enabled status, then the configuration will be overridden with
 * this new value.
 *
 * @param retailItem - The retail item data
 * @param paymentOptionFields - The payment option toggles
 */
export const getPaymentOptionConfigurationOverride = (
  retailItem: RetailItem,
  paymentOptionFields: StepField[]
): Record<string, CashRetailPricingInput> => {
  const payload = {};

  for (const field of paymentOptionFields) {
    const paymentMethodMutationField = field.queryAlias?.[0];

    if (!paymentMethodMutationField) {
      LoggingService.debug({ message: `Missing queryAlias for ${field.queryVar}, cannot save configuration override` });
      continue;
    }

    // Map this payment method to the configuration enabled status
    const currentConfigurationEnabledStatus = get(
      retailItem,
      getPricingConfigurationEnabledPath(paymentMethodMutationField as InventoryPaymentOption)
    );

    /*
     * If the payment method toggle field is null, then the payment method has been disabled on rooftop/segment level,
     * also if the toggle value does not differ from the current configuration enabled status, then no override needs
     * to be set
     */
    payload[paymentMethodMutationField] =
      !isNil(field.selectedValue) && field.selectedValue !== currentConfigurationEnabledStatus
        ? { configurationOverride: { enabled: field.selectedValue } }
        : undefined;
  }

  return payload;
};

type PricingSegmentBuilderOptions = {
  isModify?: boolean;
};

/**
 * Given a PaymentOption, return the builder type and entityType for the associated segment builder. If the
 * isModify option param is passed, then the modify builder type will be returned. This is useful for getting a segment
 * builder based on a PaymentOption.
 *
 * @param paymentOption - given PaymentOption
 * @param options - options available: isModify
 */
export function getPricingSegmentBuilderFromPaymentOption(
  paymentOption: undefined,
  options?: PricingSegmentBuilderOptions
): undefined;
export function getPricingSegmentBuilderFromPaymentOption(
  paymentOption: PaymentOption,
  options?: PricingSegmentBuilderOptions
): Pick<Tier, 'entityType' | 'type'>;
export function getPricingSegmentBuilderFromPaymentOption(
  paymentOption: PaymentOption | undefined,
  options?: PricingSegmentBuilderOptions
): Pick<Tier, 'entityType' | 'type'> | undefined;
export function getPricingSegmentBuilderFromPaymentOption(
  paymentOption: PaymentOption | undefined,
  options?: PricingSegmentBuilderOptions
): Pick<Tier, 'entityType' | 'type'> | undefined {
  if (!paymentOption) {
    return undefined;
  }

  const type = {
    [PaymentOption.CASH]: options?.isModify
      ? BuilderType.ROOFTOP_CASH_SEGMENT_MODIFY
      : BuilderType.ROOFTOP_CASH_SEGMENT_CREATE,
    [PaymentOption.FINANCE]: options?.isModify
      ? BuilderType.ROOFTOP_FINANCE_SEGMENT_MODIFY
      : BuilderType.ROOFTOP_FINANCE_SEGMENT_CREATE,
    [PaymentOption.LEASE]: options?.isModify
      ? BuilderType.ROOFTOP_LEASE_SEGMENT_MODIFY
      : BuilderType.ROOFTOP_LEASE_SEGMENT_CREATE,
  }[paymentOption];

  return {
    entityType: CustomEntity.ROOFTOP_PRICING_SEGMENTS,
    type,
  };
}

/**
 * Generate a unique queryVar value for a given interest rate. This is used when dynamically generating interest
 * rate fields in the Rates step for both finance and lease segment builders
 *
 * @param interestRate - the interest rate
 */
export const getInterestRateQueryVar = (interestRate: string | number): string => `${interestRate}-interestRate`;

/**
 * Get the query alias for a given interest rate field in finance/lease pricing segment builders. This format
 * comes from the api, which will return errors mapped to this field identifier
 * @param index - the nth instance of the interest rate field in the builder
 */
export const getInterestRateQueryAlias = (index: number): string => `interestRates#${index}.interestRate`;

/**
 * Generate a unique queryVar value for a given mileage rate field. This is used when dynamically generating mileage
 * allowance rate fields in the Rates step for lease segment builders
 *
 * @param interestRate - the interest rate
 * @param mileageAllowance - the milage allowance
 */
export const getMileageRateQueryVar = (interestRate: string | number, mileageAllowance: string): string =>
  `${interestRate}-${mileageAllowance.replaceAll(/[\s,]/g, '')}`;

/**
 * Get the query alias for a given residual rate field in lease pricing segment builders. This format
 * comes from the api, which will return errors mapped to this field identifier
 * @param index - the nth instance of the residual rate field in the builder
 */
export const getResidualRateQueryAlias = (index: number): string => `residualRates#${index}.residualRate`;

/**
 * Get the nested field from a lease/finance/cash configuration object. Ex, "configuration.availableTerms", we would
 * get the nested field, which would be "availableTerms".
 * @param field - the configuration field
 */
export const getNestedConfigurationField = (field: string): string => {
  if (!field) {
    return '';
  }

  const parsed = field.split?.('configuration.');

  return parsed?.length > 1 ? parsed[1] : '';
};

/**
 * Get the adjustment amount of an incentive. At the moment this can be a fixed or percentage price amount. As more
 * incentive types are supported this will include interest rate discounts, and residual rate discounts.
 * @param attributes - IncentiveAttributes
 * @param options - List of options: hidePercentageAmount - don't show the percentage amount (even if it exists)
 *
 */
export const getIncentiveAdjustmentAmount = (
  attributes: IncentiveAttributes,
  options?: { hidePercentageAmount?: boolean; term?: number }
): string | undefined => {
  if (attributes.__typename === 'PurchasePriceFixedIncentiveAttributes') {
    return attributes.fixed.formattedAmountRounded;
  } else if (attributes.__typename === 'PurchasePricePercentageIncentiveAttributes') {
    // If there is a defined starting price, then show how much the percentage will affect this price amount
    return isNil(attributes.percentageAsAmount) || options?.hidePercentageAmount
      ? decimalPercentToString(attributes.percentage)
      : `(${decimalPercentToString(attributes.percentage)}) ${attributes.percentageAsAmount.formattedAmountRounded}`;
  } else if (attributes.__typename === 'PurchasePriceFixedByTermIncentiveAttributes' && options?.term) {
    return attributes.fixedAmounts.find(fixedAmount => fixedAmount.term === options.term)?.amount
      ?.formattedAmountRounded;
  }
};
