import { memo, type SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  title: string;
}

const CopyIcon = ({ title, ...props }: Props) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.21 18.066c-.63 0-1.167-.222-1.61-.665a2.193 2.193 0 0 1-.666-1.61V3.934c0-.63.222-1.166.665-1.61.444-.443.98-.665 1.61-.665h8.857c.63 0 1.166.222 1.61.665.443.444.665.98.665 1.61v11.857c0 .63-.222 1.166-.666 1.61-.443.443-.98.665-1.61.665H9.21Zm0-2.275h8.856V3.934H9.209v11.857Zm-4.276 6.55c-.63 0-1.166-.222-1.61-.666a2.193 2.193 0 0 1-.665-1.61V7.073c0-.32.11-.589.33-.809.22-.219.489-.329.808-.329.319 0 .588.11.808.33.22.22.33.489.33.808v12.994h9.993c.32 0 .589.11.808.329.22.22.33.49.33.808 0 .32-.11.589-.33.809a1.1 1.1 0 0 1-.808.329H4.934Z"
    />
    {title && <title>{title}</title>}
  </svg>
);
export default memo(CopyIcon);
