// Builders
import AppointmentBuilders from 'components/sections/createModify/appointments/AppointmentBuilders';
import ContactBuilders from 'components/sections/createModify/contacts/ContactBuilders';
import ConversationBuilders from 'components/sections/createModify/conversations/ConversationBuilders';
import CreditApplicationBuilders from 'components/sections/createModify/creditApplications/CreditApplicationBuilders';
import GroupsBuilders from 'components/sections/createModify/groups/GroupsBuilders';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import RetailItemBuilders from 'components/sections/createModify/inventoryItems/retailItem/RetailItemBuilders';
import TradeInItemBuilders from 'components/sections/createModify/inventoryItems/tradeInItem/TradeInItemBuilders';
import LeadActivityBuilders from 'components/sections/createModify/leadActivities/LeadActivityBuilders';
import LeadBuilders from 'components/sections/createModify/leads/LeadBuilders';
import MailboxBuilders from 'components/sections/createModify/mailbox/MailboxBuilders';
import MMSTBuilders from 'components/sections/createModify/MMST/MMSTBuilders';
import PurchaseActivityBuilders from 'components/sections/createModify/purchaseActivities/PurchaseActivityBuilders';
import RetailBulkAdjustmentBuilders from 'components/sections/createModify/retailBulkAdjustment/RetailBulkAdjustmentBuilders';
import RetailItemPriceAdjustmentBuilders from 'components/sections/createModify/retailItemPriceAdjustment/RetailItemPriceAdjustmentBuilders';
import RetailItemPricingBuilders from 'components/sections/createModify/retailItemPricing/RetailItemPricingBuilders';
import RetailItemPricingInfoBuilders from 'components/sections/createModify/retailItemPricing/retailItemPricingInfo/RetailItemPricingInfoBuilders';
import RooftopDepartmentsBuilder from 'components/sections/createModify/rooftopDepartments/RooftopDepartmentsBuilders';
import InventoryExportBuilders from 'components/sections/createModify/rooftopIntegration/builders/inventoryExport/InventoryExportBuilders';
import OemAccountExportBuilders from 'components/sections/createModify/rooftopIntegration/builders/oemAccountExport/OemAccountExportBuilders';
import PhoneCallTrackingBuilders from 'components/sections/createModify/rooftopIntegration/builders/phoneCallTracking/PhoneCallTrackingBuillders';
import RooftopIntegrationBuilder from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationBuilders';
import RooftopLeadForwardingBuilders from 'components/sections/createModify/rooftopLeadForwarding/RooftopLeadForwardingBuilders';
import RooftopCashSegmentBuilders from 'components/sections/createModify/rooftopPricingSegments/cashPricing/RooftopCashSegmentBuilders';
import RooftopFinanceSegmentBuilder from 'components/sections/createModify/rooftopPricingSegments/financePricing/RooftopFinanceSegmentBuilder';
import RooftopLeaseSegmentBuilders, {
  RooftopLeaseSegmentAvailableMileageAllowanceBuilder,
} from 'components/sections/createModify/rooftopPricingSegments/leasePricing/RooftopLeaseSegmentBuilders';
import RooftopBuilders from 'components/sections/createModify/rooftops/RooftopBuilders';
import RooftopTagsBuilder from 'components/sections/createModify/rooftopTags/RooftopTagsBuilders';
import ModifyPasswordBuilders from 'components/sections/createModify/settings/ModifyPasswordBuilders';
import ShoppingToolsBuilders from 'components/sections/createModify/shoppingTools/ShoppingToolsBuilders';
import TagBuilders from 'components/sections/createModify/tags/TagBuilders';
import TaskBuilders from 'components/sections/createModify/tasks/TaskBuilders';
import UserBuilders from 'components/sections/createModify/users/UserBuilders';
import WebsiteLeadRoutingBuilders from 'components/sections/createModify/websiteLeadRouting/WebsiteLeadRoutingBuilders';
import WebsiteRouteBuilders from 'components/sections/createModify/websiteRoutes/WebsiteRouteBuilders';
import WebsiteRouteFilterBuilders from 'components/sections/createModify/websiteRoutes/WebsiteRouteFilterBuilders';
import WebsiteBuilders from 'components/sections/createModify/websites/WebsiteBuilders';
import type { BuilderConfig } from 'enums/builderType';
import { defaultTranslateFields, translateFields } from 'utils/intlUtils';

export { type BuilderConfig } from 'enums/builderType';

const BuilderTypes: { [key: string]: BuilderEntry } = {
  ...RetailItemBuilders,
  ...TradeInItemBuilders,
  ...RooftopBuilders,
  ...TagBuilders,
  ...TaskBuilders,
  ...AppointmentBuilders,
  ...LeadBuilders,
  ...LeadActivityBuilders,
  ...PurchaseActivityBuilders,
  ...CreditApplicationBuilders,
  ...RooftopLeadForwardingBuilders,
  ...ConversationBuilders,
  ...MMSTBuilders,
  ...UserBuilders,
  ...RooftopIntegrationBuilder,
  ...InventoryExportBuilders,
  ...OemAccountExportBuilders,
  ...PhoneCallTrackingBuilders,
  ...ContactBuilders,
  ...RooftopTagsBuilder,
  ...GroupsBuilders,
  ...RetailBulkAdjustmentBuilders,
  ...RetailItemPricingInfoBuilders,
  ...RetailItemPriceAdjustmentBuilders,
  ...RetailItemPricingBuilders,
  ...MailboxBuilders,
  ...ModifyPasswordBuilders,
  ...RooftopFinanceSegmentBuilder,
  ...RooftopCashSegmentBuilders,
  ...RooftopLeaseSegmentBuilders,
  ...RooftopLeaseSegmentAvailableMileageAllowanceBuilder,
  ...RooftopDepartmentsBuilder,
  ...ShoppingToolsBuilders,
  ...WebsiteBuilders,
  ...WebsiteLeadRoutingBuilders,
  ...WebsiteRouteBuilders,
  ...WebsiteRouteFilterBuilders,
};

export const Builders: BuilderConfig = Object.freeze(
  translateFields(BuilderTypes, [
    ...defaultTranslateFields,
    'stepName',
    'title',
    'content',
    'name',
    'overrideSubStepSearchLabel',
    'tooltipContents',
  ])
);
