import { type ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import type Step from 'components/core/createModify/interfaces/step';
import type Tier from 'components/core/createModify/interfaces/tier';
import LoggingService from 'components/core/logging/LoggingService';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import { CreateModifyContext } from 'contexts/CreateModifyContext';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { TestingCommands } from 'enums/testing';
import { useBuilderConfig } from 'hooks/contexts/useBuilderConfig';
import { useGlobalDialog } from 'hooks/contexts/useGlobalDialog';
import { useUser } from 'hooks/contexts/useUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export interface Props {
  children: ReactNode;
}

export const CreateModifyProvider = ({ children }: Props) => {
  const [tiers, setTiers] = useState<{ [key: string]: Tier | undefined }>({});
  const [topTier, updateTopTier] = useState<Tier>();

  const userContext = useUser();
  const globalDialogContext = useGlobalDialog();
  const builderConfigContext = useBuilderConfig();
  const { flags: featureFlags, updateFeatureFlagContext } = useFeatureFlags();

  // This is required for reference because `setTiers` does not update `tiers` fast enough, so multiple calls overwrite
  const currentTiers = useRef<{ [key: string]: Tier | undefined }>(tiers);

  // Internal method that will filter out any Steps that have featureFlagsRequired conditions configured
  const featureFlagSteps = useCallback(
    (tierData: Tier): Tier => {
      tierData.steps = tierData?.steps?.filter(
        step => !step.featureFlagsRequired?.length || step.featureFlagsRequired?.every(flag => !!featureFlags[flag])
      );
      return tierData;
    },
    [featureFlags]
  );

  // This will set the top-most tier. Will apply feature flagging conditions and call updateTopTier to update the state.
  const setTopTier = useCallback(
    (tier: Tier | undefined) => updateTopTier(tier && featureFlagSteps(tier)),
    [updateTopTier, featureFlagSteps]
  );

  // Internal callback that sets the top most tier and updates variables to ref
  const updateTiers = useCallback(() => {
    const topTierId = Object.values(CreateModifyTiers).findLast(tierId => !!currentTiers.current[tierId])!;
    setTopTier(currentTiers.current[topTierId]);

    setTiers({ ...currentTiers.current });
  }, [setTopTier]);

  // This handles updates of existing tiers
  const setTier = useCallback(
    (tierId: CreateModifyTiers, tierUpdates: Partial<Tier>) => {
      if (!currentTiers.current[tierId]) {
        LoggingService.debug({ message: `tier: ${tierId} does not exist, no updates called.` });
        return;
      }

      const currentTier = currentTiers.current[tierId];

      if (currentTier) {
        currentTiers.current[tierId] = {
          ...currentTier,
          ...tierUpdates,
          // Merge objects while disallowing required keys to be overwritten if explicitly 'undefined' in tierUpdates
          tierId: tierUpdates.tierId || currentTier.tierId,
          type: tierUpdates.type || currentTier.type,
          metadata: tierUpdates.metadata || currentTier.metadata,
          data: tierUpdates.data || currentTier.data,
        };
      }

      updateTiers();
    },
    [updateTiers]
  );

  // Simple getter method that uses `currentTiers` for the latest set of tier data at all times
  const getTier = useCallback((tierId: CreateModifyTiers): Tier | undefined => currentTiers.current[tierId], []);

  // This handles both instatiation/removal of new/existing tiers
  const toggleTier = useCallback(
    (tierId: CreateModifyTiers, tierData?: Partial<Tier> & Pick<Tier, 'type' | 'tierId'>) => {
      if (tierData) {
        currentTiers.current[tierId] = { metadata: {}, data: {}, ...tierData, tierId };
      } else {
        delete currentTiers.current[tierId];
      }
      updateTiers();
    },
    [updateTiers]
  );
  // Expose to window obj so Cypress tests have access
  window[TestingCommands.BUILDER_OPEN] = toggleTier;

  // Helper method that returns the Step in which the `activeField` may reside in
  const findActiveStep = useCallback((activeField: string, builder: BuilderEntry) => {
    if (!builder?.steps) {
      throw new Error(
        `No steps found in the builder configuration for ${activeField}. Check "builderType.ts" for more info.`
      );
    }

    return builder.steps.find(
      step =>
        !!step.fields &&
        Object.keys(step.fields).find(
          key =>
            key === activeField ||
            step.fields![key].queryAlias === activeField ||
            step.fields![key].queryAlias?.includes(activeField)
        )
    );
  }, []);

  // Helper method that returns the field data in a specific step
  const getField = useCallback((step: Step, field: string) => {
    if (!step?.fields) {
      throw new Error(`Unable to find ${field}, no fields contained in step.`);
    }
    const fields = step.fields;
    const fieldKey = Object.keys(fields).find(
      key => key === field || fields?.[key]?.queryAlias?.includes(field) || fields?.[key]?.queryAlias === field
    );

    return fieldKey ? fields[fieldKey] : undefined;
  }, []);

  return (
    <CreateModifyContext.Provider
      value={{
        topTier,
        tiers,
        setTier,
        getTier,
        toggleTier,
        findActiveStep,
        getField,
        subContexts: useMemo(
          () => ({ builderConfigContext, userContext, globalDialogContext, featureFlags, updateFeatureFlagContext }),
          [builderConfigContext, userContext, globalDialogContext, featureFlags, updateFeatureFlagContext]
        ),
      }}
    >
      {children}
    </CreateModifyContext.Provider>
  );
};
