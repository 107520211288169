import type { ReactNode } from 'react';
import { Component } from 'react';

import styled from 'styled-components/macro';

import Paragraph from 'components/core/typography/Paragraph';
import PrimaryText from 'components/core/typography/PrimaryText';
import ErrorBoundaryIcon from 'components/ui/icons/ErrorBoundaryIcon';
import { CTAButton } from 'components/ui/shared/Button';
import { DatadogCustomAction } from 'enums/datadogCustomAction';
import { NEUTRAL_050 } from 'styles/tokens';
import {
  FONT_SIZE_14,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLDER,
  FONT_WEIGHT_SEMI_BOLD,
  LETTER_SPACING_DENSE,
} from 'styles/typography';

import LoggingService from '../logging/LoggingService';

const ErrorBoundaryLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  width: 100%;
  height: 100vh;
  background-color: ${NEUTRAL_050};
`;

const SectionBlock = styled.div`
  text-align: center;
`;

const Heading = styled(PrimaryText)`
  font-size: ${FONT_SIZE_24};
  font-weight: ${FONT_WEIGHT_BOLDER};
  text-transform: uppercase;
  margin: 20px 0;
`;

const Button = styled(CTAButton)`
  margin: 20px auto;
  width: 140px;

  & > div {
    font-size: ${FONT_SIZE_14};
    letter-spacing: ${LETTER_SPACING_DENSE};
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  }
`;

interface Props {
  children: ReactNode;
  scope?: string;
}

interface State {
  error?: Error;
  prevScope: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: undefined, prevScope: props.scope };
  }

  static defaultProps = {
    scope: 'error-boundary',
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.prevScope !== props.scope) {
      return {
        error: undefined,
        prevScope: props.scope,
      };
    }

    return state;
  }

  /**
   * Log the event.
   *
   * Don't need exception handling here as:
   *  - Actual exceptions are automatically caught by Datadog
   *  - Capturing here or throwing an exception would make it difficult for debugging, as
   *    stack trace would indicate error is from this file, when it's not.
   *  - For expected exceptions use `LoggingService.captureException` where the exception actually occurs.
   */
  componentDidCatch(error, info) {
    LoggingService.trackAction(DatadogCustomAction.ERROR_BOUNDARY, {
      error,
      info,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorBoundaryLayout>
          <ErrorBoundaryIcon />
          <SectionBlock>
            <Heading>Something went wrong!</Heading>
            <Paragraph>Looks like you&apos;ve found a bug.</Paragraph>
            <Paragraph>
              We track these errors automatically, but if the problem persists feel free to contact us.
            </Paragraph>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </Button>
          </SectionBlock>
        </ErrorBoundaryLayout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
