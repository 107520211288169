import { gql } from '@apollo/client';

import { retailItemFilters } from './retailItemFilters';

export const inventoryExportRooftopIntegrationAttributes = gql`
  fragment InventoryExportRooftopIntegrationAttributesFragment on InventoryExportRooftopIntegrationAttributes {
    filter {
      ...RetailItemFiltersFragment
    }
    thirdPartyId
    websiteBrand
  }

  ${retailItemFilters}
`;
