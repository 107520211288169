import { gql } from '@apollo/client';

import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';
import { shoppingToolsConfiguration } from 'store/api/graph/fragments/shoppingToolsConfiguration';

export const shoppingToolsDetailsQuery = gql`
  query ShoppingToolsDetailsQuery($id: ID!) {
    item: rooftop(id: $id) {
      id
      location {
        countryCode
      }
      bundle {
        ...RooftopFeatureBundle
      }
      shoppingToolsConfiguration {
        ...ShoppingToolsConfigurationFragment
      }
      website
    }
  }
  ${rooftopFeatureBundle}
  ${shoppingToolsConfiguration}
`;
