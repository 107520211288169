import type TableCellData from 'components/ui/tables/interfaces/tableCellData';
import { Conversation, ConversationSettings } from 'enums/columns/conversation';
import { getCellDataByItemColumnType } from 'utils/tableUtils';

import { STATIC_CONFIGS } from './staticTableConfig';

// TODO: [#1123] determine default sort order
export const conversationsDefaultSortOrder = [];

export const CONVERSATIONS = Object.freeze<TableCellData[]>([
  // Defaults
  ...STATIC_CONFIGS,

  // ...Rest
  getCellDataByItemColumnType(Conversation.GROUP_NAME, ConversationSettings, {
    width: 220,
    enabled: false,
  }),
  getCellDataByItemColumnType(Conversation.ROOFTOP_NAME, ConversationSettings, { width: 220, enabled: false }),
  getCellDataByItemColumnType(Conversation.TYPE, ConversationSettings),
  getCellDataByItemColumnType(Conversation.LEAD_NAME, ConversationSettings),
  getCellDataByItemColumnType(Conversation.ASSIGNED_TO_NAME, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.SUBJECT, ConversationSettings),
  getCellDataByItemColumnType(Conversation.ITEMS_COUNT, ConversationSettings),
  getCellDataByItemColumnType(Conversation.MESSAGE_SNIPPET, ConversationSettings),
  getCellDataByItemColumnType(Conversation.PARTICIPANTS, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.TAGS, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.HAS_ATTACHMENTS, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.READ_BY_ME, ConversationSettings),
  getCellDataByItemColumnType(Conversation.REPLIED, ConversationSettings),
  getCellDataByItemColumnType(Conversation.ARCHIVED_BY_ME, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.CREATED, ConversationSettings, { enabled: false }),
  getCellDataByItemColumnType(Conversation.UPDATED, ConversationSettings),
]);
