import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { leadDetailsQuery } from 'containers/leads/LeadsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { client } from 'store/apollo/ApolloClient';

const CreditApplicationQueryMethods: QueryMethods = {
  getData: async (id: string) => {
    const response = await client.query({
      query: leadDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },
  refetchQueries: ['LeadDetailsContainerQuery'],
};

export default CreditApplicationQueryMethods;
