import { useContext } from 'react';

import { PhotoPreviewContext } from 'contexts/PhotoPreviewContext';

export function usePhotoPreviewContext() {
  const context = useContext(PhotoPreviewContext);
  if (context === undefined) {
    throw new Error('usePhotoPreviewContext must be used within a PhotoPreviewContextProvider');
  }
  return context;
}
