import { cloneDeep } from 'lodash-es';
import styled, { css } from 'styled-components/macro';
import type { Subscription } from 'zen-observable-ts';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps, StepComponentState } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import Label from 'components/core/typography/Label';
import type { RetailItemConnectionFilterStepOption } from 'components/ui/lists/RetailFilterListItem';
import RetailFilterListItem from 'components/ui/lists/RetailFilterListItem';
import Button from 'components/ui/shared/Button';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { StepFieldType } from 'enums/stepFieldType';
import { ElementTestId } from 'enums/testing';
import type {
  MultilingualString,
  RetailItemConnectionFilter,
  RetailItemConnectionFilterFragment,
  RetailItemConnectionFilterInput,
  WebsiteDetailsContainerQuery,
  WebsiteMetaQuery,
  WebsiteRouteCreateMutationVariables,
  WebsiteRouteModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { BLUE_500, NEUTRAL_0, NEUTRAL_800 } from 'styles/tokens';
import { defineFieldValues, objectToStepFieldArray, setDisplayTypes } from 'utils/formatting/createModifyFormatUtils';
import type { MultilingualStringValue } from 'utils/intlUtils';
import { getLocaleFromMultilingualValue, translate } from 'utils/intlUtils';

import type { FetchWebsiteRetailFilterMetaqueryMetadata } from '../websiteRouteFilterUtils';
import { getFilterName, getMetadataForFilter } from '../websiteRouteFilterUtils';

import { WebsiteRouteDetailsBuilderFields } from './interfaces';
import { parseSeededData } from './stepUtils';

const { t } = translate;

type WebsiteDetails = WebsiteDetailsContainerQuery['item'];

const AddFilterButton = styled(Button)`
  padding: 8px;
  margin-right: 4px;
  background: ${NEUTRAL_800};
  color: ${NEUTRAL_0};

  div {
    margin: auto;
    color: ${NEUTRAL_0};
  }
`;

interface WebsiteRouteDetailsStepState extends StepComponentState {
  filters?: RetailItemConnectionFilterStepOption[];
}

class DetailsStep extends StepComponentCore<
  WebsiteDetails,
  FetchWebsiteRetailFilterMetaqueryMetadata,
  WebsiteRouteModifyMutationVariables
> {
  state: WebsiteRouteDetailsStepState = {
    ...defaultState,
    filters: undefined,
  };

  metadataSubscriptions: Subscription[] = [];
  constructor(
    props: StepComponentProps<
      WebsiteDetails,
      FetchWebsiteRetailFilterMetaqueryMetadata,
      WebsiteRouteCreateMutationVariables
    >,
    _context: CreateModifyContextInterface<WebsiteDetails, WebsiteMetaQuery>
  ) {
    super(props);
    const {
      tier: { seededData, activeStep, metadata, isCreating, stepFieldData },
    } = props;

    const { website, route } = parseSeededData(seededData);

    if (!website) {
      return;
    }

    const filters =
      stepFieldData?.filters || (route?.inventoryItemSegment.filters as RetailItemConnectionFilterFragment[]);
    const filterOptions: RetailItemConnectionFilterStepOption[] =
      filters?.map(
        (filter, index) =>
          ({
            id: index,
            name: getFilterName(index),
            metadata,
            ...filter,
          }) as RetailItemConnectionFilterStepOption
      ) || [];

    this.state = {
      filters: filterOptions,
      ...defaultState,
    };

    this.fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [WebsiteRouteDetailsBuilderFields.WEBSITE_ID]: {
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: true },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
        selectedValue: website.id,
      },
      [WebsiteRouteDetailsBuilderFields.PATH]: {
        subLabel: `${website.url}/${t('inventory').toLowerCase()}${route?.path.value || ''}`,
        selectedValue: stepFieldData?.path || route?.path,
      },
      [WebsiteRouteDetailsBuilderFields.FILTERS]: {
        selectedValue: filters,
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(this.fields, {
      ...website,
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    // Unsubscribe all watched queries
    for (const subscription of this.metadataSubscriptions) {
      subscription.unsubscribe();
    }
  }

  async toggleSubPanel(stepField?: StepField) {
    if (stepField?.queryVar === WebsiteRouteDetailsBuilderFields.FILTERS) {
      this.toggleFiltersSubPanel();
    } else {
      void super.toggleSubPanel(stepField);
    }
  }

  onFieldLocaleChanged(stepField: StepField, value: MultilingualStringValue) {
    const {
      tier: { seededData },
    } = this.props;

    if (stepField.queryVar === WebsiteRouteDetailsBuilderFields.PATH) {
      const path = (stepField?.selectedValue as MultilingualString)?.value || '';
      const locale = getLocaleFromMultilingualValue(value);
      stepField.subLabel = `${seededData?.website.url}/${t('inventory', undefined, {
        lng: locale,
      }).toLowerCase()}${path}`;
    }
  }

  async onFieldSelection(stepField: StepField, value: any) {
    super.onFieldSelection(stepField, value);
    if (stepField.queryVar === WebsiteRouteDetailsBuilderFields.FILTERS_LIST) {
      const filter = value as RetailItemConnectionFilterFragment;
      this.toggleFilterBuilder(filter);
    }
  }

  /**
   * Toggles a subpanel whereby user can see a list of filters associated with a route.
   */
  toggleFiltersSubPanel() {
    const { filters } = this.state;
    void super.toggleSubPanel({
      queryVar: WebsiteRouteDetailsBuilderFields.FILTERS_LIST,
      required: true,
      groupType: StepFieldType.RENDER_OBJECT,
      renderElement: RetailFilterListItem,
      subStep: [SubStepType.DEFAULT],
      hasSeparator: true,
      options: filters,
      settings: {
        hideSearchInput: true,
        listSelectionEmptyPlaceholder: {
          title: t('no_filter_other'),
          subtitle: t('no_route_filter_message'),
          onClick: () => this.toggleFilterBuilder(),
          buttonLabel: t('add_x', [t('filter_one')]),
          leftActionBarButtonIcon: () => (
            <AddFilterButton
              onClick={() => this.toggleFilterBuilder()}
              data-testid={ElementTestId.WEBSITE_ROUTES_ADD_FILTER}
            >
              <Label>{t('add_x', [t('filter_one')])}</Label>
            </AddFilterButton>
          ),
        },
      } as ListSelectionSettings,
    });
  }

  /**
   * Toggles a FE only builder, where users can define a filter to be included in their route.
   * @param filter - Optional filter to seed into the builder that is opened.
   */
  toggleFilterBuilder(filter?: RetailItemConnectionFilterFragment) {
    const { toggleTier } = this.getContext();
    const {
      tier: { seededData },
    } = this.props;

    const website = seededData?.website;

    const { filters } = this.state;
    toggleTier(CreateModifyTiers.TIER_1, {
      tierId: CreateModifyTiers.TIER_1,
      type: BuilderType.RETAIL_FILTER,
      title: t('filter_other'),
      isCreating: !filter,
      nextButtonLabel: t('finish'),
      nextButtonCss: css`
        background: ${BLUE_500};
      `,
      seededData: {
        ...website,
        filter,
        currentFilterCount: filters && filters.length > 0 ? filters.length : 0,
      },
      onStepSave: async (_, data) => {
        const index = data.id;
        const { filters } = this.state;
        await getMetadataForFilter(data as RetailItemConnectionFilter, website.groupName.id).then(
          ({ metadata, subscription }) => {
            const savedFilter = {
              ...data,
              metadata,
            } as RetailItemConnectionFilterStepOption;
            if (filters) {
              if (filters[index]) {
                filters[index] = savedFilter;
              } else {
                filters.push(savedFilter);
              }
            } else {
              this.setState({ filters: [savedFilter] });
            }

            this.updateFilterStepField(filters);
            this.toggleFiltersSubPanel();
            if (metadata) {
              this.setTier({ metadata });
            }

            if (Array.isArray(subscription)) {
              this.metadataSubscriptions.push(...subscription);
            } else {
              this.metadataSubscriptions.push(subscription);
            }
          }
        );
      },
    });
  }

  /**
   * Deletes a filter from the route, reindexes the filters in state and refreshes the subpanel
   */
  onSubStepDelete(id: string) {
    const { filters } = this.state;
    if (filters) {
      filters.splice(Number(id), 1);
      filters.map((value, index) => {
        value.id = index;
        value.name = getFilterName(index);
        return value;
      });
    }
    this.updateFilterStepField(filters);
    this.toggleFiltersSubPanel();
  }

  /**
   * Updates the filter step fields counter and sets the selectedValue
   */
  private updateFilterStepField(filters?: RetailItemConnectionFilterStepOption[]) {
    this.fields.map(stepField => {
      if (stepField.queryVar === WebsiteRouteDetailsBuilderFields.FILTERS) {
        super.onFieldSelection(
          stepField,
          cloneDeep(filters)?.map((item: Partial<RetailItemConnectionFilterStepOption>) => {
            // Id, name and metadata were added to conform to the list selection and need to be removed for the mutation
            delete item.id;
            delete item.name;
            delete item.metadata;
            return item;
          }) as RetailItemConnectionFilterInput[]
        );
      }
      return stepField;
    });
  }

  async save(): Promise<boolean> {
    const {
      tier: { seededData, isCreating },
    } = this.props;

    let variableOverrides;

    if (!isCreating && seededData?.routeId) {
      // When modifying the routes id must be passed instead of the websiteID
      variableOverrides = {
        id: seededData.routeId,
      };
    }

    return super.save({}, variableOverrides);
  }
}

export default DetailsStep;
