import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import { PhotoPreviewContext } from 'contexts/PhotoPreviewContext';

interface Props {
  children: ReactNode;
}

export const PhotoPreviewContextProvider = ({ children }: Props) => {
  const [galleryData, setGalleryData] = useState();

  const setPhotoGallery = useCallback(data => setGalleryData(data), [setGalleryData]);
  const closePhotoGallery = useCallback(() => setGalleryData(undefined), [setGalleryData]);

  return (
    <PhotoPreviewContext.Provider
      value={{
        galleryData,
        setPhotoGallery,
        closePhotoGallery,
      }}
    >
      {children}
    </PhotoPreviewContext.Provider>
  );
};
