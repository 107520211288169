/*
 * TODO: Provide better, more specific names, and remove number-based naming.
 * E.g.: `export const ENTITY_PADDING = 15px`;
 */

export const SPACE_10 = '10px';

/*
 * ==============================================
 * COMMON ---------------------------------------
 */

export const ENTITY_PADDING = '15px'; // Spacing around main-entity layouts
// Spacing around linked list sections

export {
  SPACE_400 as SPACE_16,
  SPACE_300 as SPACE_12,
  SPACE_500 as SPACE_20,
  SPACE_400 as SECTION_PADDING,
  SPACE_700 as SPACE_32,
} from 'styles/tokens';
