import { Component, createRef } from 'react';
import 'styled-components/macro';

import type { SearchInputRef } from 'components/ui/forms/shared/SearchInput';
import SearchInput from 'components/ui/forms/shared/SearchInput';
import type { CommonListItem } from 'components/ui/lists/CommonList';
import { ListItems, ListSearchSection } from 'components/ui/lists/CommonList';
import { ElementTestId } from 'enums/testing';

import { DialogBodyContentContainer, DialogContentContainer, DialogHeader } from '../layouts/DialogLayout';

import type { Props as BaseDialogProps } from './Dialog';
import Dialog from './Dialog';

/** If using server-side search, onSearchTermChanged must be specified. */
type ServerSideSearchProps = {
  isUsingServerSideSearch: true;
  onSearchTermChanged: (query: string) => void;
};

/** If using client-side search, onSearchTermChanged will never be called. */
type ClientSideSearchProps = {
  isUsingServerSideSearch?: false;
};

type Props = BaseDialogProps & {
  listItems: CommonListItem[];
  searchPlaceholderText: string;
  headerTitle: string;
  searchEnabled?: boolean;
  width?: number;
} & (ClientSideSearchProps | ServerSideSearchProps);

/**
 * Generic dialog that displays a filterable list
 * e.g. <AddEntityDialog />
 */
class FilterableListDialog extends Component<Props> {
  private searchInputRef;
  state = { searchQuery: '' };

  constructor(props) {
    super(props);
    this.searchInputRef = createRef<SearchInputRef>();
  }

  static defaultProps = {
    searchEnabled: true,
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { isOpen: isOpenPrev } = prevProps;
    const { isOpen, searchEnabled } = this.props;

    if (searchEnabled) {
      // Focus the input the first time this is opened
      if (!isOpenPrev && isOpen) {
        this.searchInputRef?.focus();
      }
      // Clear the input on close
      else if (isOpenPrev && !isOpen) {
        this.onSearchInputChange('');
      }
    }
  }

  onSearchInputChange = query => {
    if (this.props.isUsingServerSideSearch) {
      this.props.onSearchTermChanged(query);
    } else {
      this.setState({ searchQuery: query });
    }
  };

  render() {
    const { onCancel, searchPlaceholderText, headerTitle, listItems, searchEnabled } = this.props;
    const { searchQuery } = this.state;
    const { width, ...miscProps } = this.props;
    return (
      <Dialog {...miscProps}>
        <DialogContentContainer css={{ minWidth: 306, width }}>
          <DialogHeader title={headerTitle} onClose={onCancel} showDivider />
          {searchEnabled && (
            <ListSearchSection>
              <SearchInput
                ref={input => {
                  this.searchInputRef = input;
                }}
                placeholder={searchPlaceholderText}
                defaultValue={searchQuery}
                onChange={this.onSearchInputChange}
              />
            </ListSearchSection>
          )}
          <DialogBodyContentContainer data-testid={ElementTestId.FILTER_DIALOG_RESULTS_CONTAINER}>
            <ListItems items={listItems} searchQuery={searchQuery} />
          </DialogBodyContentContainer>
        </DialogContentContainer>
      </Dialog>
    );
  }
}

export default FilterableListDialog;
