import { useCallback } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components/macro';

import Text from 'components/core/typography/Text';
import CheckIcon from 'components/ui/icons/CheckIcon';
import ChevronDownIcon from 'components/ui/icons/ChevronDownIcon';
import TrashCanIcon from 'components/ui/icons/TrashCanIcon';
import Image, { DefaultImage } from 'components/ui/images/Images';
import { Clickable } from 'components/ui/shared/Button';
import { ImageSize } from 'enums/imageType';
import type { InventoryItemVideoSource } from 'store/api/graph/interfaces/types';
import { InventoryItemPhotoType } from 'store/api/graph/interfaces/types';
import type {
  InventoryItemPhotoResponseType,
  InventoryItemVideoResponseType,
} from 'store/api/graph/responses/responseTypes';
import { BORDER_DEFAULT } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_900 } from 'styles/tokens';
import { FONT_SIZE_11 } from 'styles/typography';
import { hexToRGBA } from 'utils/styledUtils';

const ICON_CONTAINER_SIZE = '20px';

const Container = styled.div<{ isSelected: boolean }>`
  position: relative;

  ${DefaultImage}:after {
    ${({ isSelected }) =>
      isSelected &&
      css`
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${hexToRGBA(NEUTRAL_900, '0.4')};
        border-radius: 8px;
      `}
  }
`;

const RemoveMediaButton = styled(Clickable)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px 0 0;
  width: ${ICON_CONTAINER_SIZE};
  height: ${ICON_CONTAINER_SIZE};
  background: ${hexToRGBA(NEUTRAL_900, '0.8')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    filter: drop-shadow(0 0 3px rgba(${NEUTRAL_900}, 0.3));
    height: 12px;
  }
`;

const AddDamageTypeButton = styled(Clickable)`
  background: ${hexToRGBA(NEUTRAL_900, '0.31')};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Text} {
    color: ${NEUTRAL_0};
    font-size: ${FONT_SIZE_11};
    padding-right: 10px;
  }
`;

const SelectPhotoButton = styled(Clickable)<{ isSelected: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  width: ${ICON_CONTAINER_SIZE};
  height: ${ICON_CONTAINER_SIZE};
  background: ${NEUTRAL_0};
  border: 2px solid ${BORDER_DEFAULT};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${BLUE_500};
      border: none;
    `}
`;

type DragItem = {
  id: string;
  originalIndex: number;
};

type SortableMedia = {
  type: InventoryItemPhotoType | InventoryItemVideoSource;
  onRemoveMedia: (targetMedia: InventoryItemPhotoResponseType | InventoryItemVideoResponseType) => void;
  onMoveMedia?: (type: any, photoId: string, indexNext: number) => void;
  onModifyDamageLocation?: (photo: InventoryItemPhotoResponseType) => void;
  onSelectForReclassification?: (photo: InventoryItemPhotoResponseType) => void;
};
interface SortableMediaItemProps extends SortableMedia {
  /** Index of the media item */
  index: number;
  /** Media to be rendered in the component */
  media: InventoryItemPhotoResponseType | InventoryItemVideoResponseType;
  /** Whether or not the media item is selected */
  isSelected: boolean;
}

const SortableMediaItem = ({
  index,
  type,
  media,
  onMoveMedia,
  onRemoveMedia,
  onModifyDamageLocation,
  onSelectForReclassification,
  isSelected,
}: SortableMediaItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type,
    item: { id: media.id, originalIndex: index },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: type,
    hover(item: DragItem) {
      if (item['id'] !== media.id) {
        onMoveMedia?.(type, item['id'], index);
      }
    },
  });

  return (
    <Container
      ref={useCallback(node => !!onMoveMedia && drag(drop(node)), [drag, drop, onMoveMedia])}
      isSelected={isSelected}
    >
      {/** TODO: Use ImageActions with built-in edit/delete callbacks **/}
      <Image
        src={
          (media as InventoryItemPhotoResponseType).gridPhoto || (media as InventoryItemVideoResponseType).thumbnailUrl
        }
        size={ImageSize.FILL}
        css={css`
          opacity: ${isDragging ? 0.25 : 1};
        `}
      />
      {[InventoryItemPhotoType.EXTERIOR, InventoryItemPhotoType.INTERIOR, InventoryItemPhotoType.DAMAGE].includes(
        type as InventoryItemPhotoType
      ) && (
        <SelectPhotoButton
          onClick={onSelectForReclassification?.bind(null, media as InventoryItemPhotoResponseType)}
          isSelected={isSelected}
        >
          {isSelected && <CheckIcon color={NEUTRAL_0} />}
        </SelectPhotoButton>
      )}
      {type === InventoryItemPhotoType.DAMAGE &&
        !!onModifyDamageLocation && ( // OnModifyDamageLocation
          <AddDamageTypeButton onClick={onModifyDamageLocation.bind(null, media as InventoryItemPhotoResponseType)}>
            <Text title={(media as InventoryItemPhotoResponseType).damageLocationName || ''}>
              {(media as InventoryItemPhotoResponseType).damageLocationName}
            </Text>
            <ChevronDownIcon width={16} height={16} color={NEUTRAL_0} />
          </AddDamageTypeButton>
        )}
      <RemoveMediaButton onClick={onRemoveMedia.bind(null, media)}>
        <TrashCanIcon width={16} height={16} color={NEUTRAL_0} />
      </RemoveMediaButton>
    </Container>
  );
};

interface SortableMediaProps extends SortableMedia {
  media?: Array<InventoryItemPhotoResponseType | InventoryItemVideoResponseType>;
  selectedForReclassify?: Map<string, InventoryItemPhotoResponseType>;
}

const SortableMedia = ({ media = [], selectedForReclassify, ...props }: SortableMediaProps) => (
  <>
    {media.map((photo, index) => (
      <SortableMediaItem
        key={photo.id}
        index={index}
        media={photo}
        isSelected={!!selectedForReclassify?.get(photo.id)}
        {...props}
      />
    ))}
  </>
);

export default SortableMedia;
