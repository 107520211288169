export enum DetailsLeadActivityBuilderFields {
  TYPE = 'type',
  ROOFTOP_ID = 'rooftopId',
  LEAD_ID = 'leadId',
  SOURCE_ID = 'sourceId',
  RETAIL_ITEM_OF_INTEREST_ID = 'retailItemOfInterestId',
  NOTE = 'note',
}

export enum DetailsLeadActivityBuilderFieldsQueryAlias {
  ATTRIBUTES_NAME_RETAIL_ITEM_OF_INTEREST_ID = 'attributesName.retailItemOfInterest',
}

export enum LeadActivityBuilderStep {
  DETAILS = 'LEAD_ACTIVITY_DETAILS',
  REPLY = 'LEAD_ACTIVITY_REPLY',
  NOTE = 'LEAD_ACTIVITY_NOTE',
}

export enum LeadActivityReplyBuilderFields {
  MESSAGE = 'message',
}
