import { type MouseEventHandler, useCallback, useMemo } from 'react';

import { css } from 'styled-components/macro';

import IconDropdown from 'components/ui/editor/components/shared/IconDropdown';
import ColorSquareIcon from 'components/ui/icons/ColorSquareIcon';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { richTextInputColourOptions } from 'styles/color';
import { translate } from 'utils/intlUtils';
import { hexToRGBA } from 'utils/styledUtils';

const { t } = translate;

interface Props {
  /** An array of colors to display in the dropdown. Defaults to `richTextInputColourOptions` if undefined */
  colors?: string[];
}

const iconButtonCss = css`
  padding: 6px;
`;

const listContainerCss = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 0;

  & > button {
    padding: 2px;
  }
`;

const ColorPalette = ({ colors = richTextInputColourOptions }: Props) => {
  const { clientType, editor } = useRichTextEditor();
  const editorColor = editor?.getAttributes('textStyle')?.color || richTextInputColourOptions[0];
  const isMobileClientType = clientType === RichTextClientType.MOBILE;

  /**
   * Handle the click event for each color in the dropdown.
   */
  const handleColorClick = useCallback(
    (color: string): MouseEventHandler<HTMLButtonElement> =>
      () => {
        editor?.chain().focus().setColor(hexToRGBA(color)).run();
      },
    [editor]
  );

  /**
   * Create an array of color objects for the dropdown items.
   */
  const colorList = useMemo(
    () =>
      colors.map(color => ({
        labelRender: () => <ColorSquareIcon key={color} color={color} radius={2} width={24} height={24} />,
        onClick: handleColorClick(color),
      })),
    [colors, handleColorClick]
  );

  return (
    <IconDropdown
      data-testid={ElementTestId.EDITOR_BUTTON_COLOR_PALETTE}
      showChevron={false}
      icon={ColorSquareIcon}
      iconProps={{
        color: editorColor,
        width: 16,
        height: 16,
      }}
      iconButtonCss={iconButtonCss}
      items={colorList}
      listContainerCss={listContainerCss}
      tooltip={isMobileClientType ? undefined : { children: t('text_colour') }}
    />
  );
};

export default ColorPalette;
