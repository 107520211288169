import { RoutePath } from 'enums/routePath';
import type {
  MutationsavedConnectionFilterCreateArgs,
  SavedFilterViewListQuery,
} from 'store/api/graph/interfaces/types';
import { EntityType } from 'store/api/graph/interfaces/types';

/** The routes that the save filter view feature is available */
export const ROUTES_ENABLED_FOR_SAVED_FILTER_VIEWS = [
  RoutePath.APPOINTMENTS,
  RoutePath.CONVERSATIONS,
  RoutePath.LEADS,
  RoutePath.RETAIL_ITEMS,
  RoutePath.TASKS,
  RoutePath.TRADE_IN_ITEMS,
];

export interface SavedFilterViewSettingsType {
  /** The entity type for this filter view */
  entityType: EntityType;
  /**
   * The connection filter input path for this filter view. This corresponds to the input property in the
   * query/mutations for the saved filter views.
   */
  connectionFilterInputPath: keyof Omit<MutationsavedConnectionFilterCreateArgs, 'name' | 'type'>;
  /** The query alias path for this filter view */
  connectionFilterQueryAliasPath: keyof Omit<
    SavedFilterViewListQuery['savedConnectionFilters'][number],
    '__typename' | 'id' | 'name' | 'type' | 'created'
  >;
  /** The name of the fragment associated with this filter view */
  connectionFilterFragmentTypeName: string;
}

export const SavedFilterViewSettings: { [key: string]: SavedFilterViewSettingsType } = {
  [RoutePath.APPOINTMENTS]: {
    entityType: EntityType.APPOINTMENT,
    connectionFilterInputPath: 'appointmentConnectionFilterInput',
    connectionFilterQueryAliasPath: 'appointmentConnectionFilter',
    connectionFilterFragmentTypeName: 'AppointmentConnectionFilterFragment',
  },
  [RoutePath.CONVERSATIONS]: {
    entityType: EntityType.CONVERSATION,
    connectionFilterInputPath: 'conversationConnectionFilterInput',
    connectionFilterQueryAliasPath: 'conversationConnectionFilter',
    connectionFilterFragmentTypeName: 'ConversationConnectionFilterFragment',
  },
  [RoutePath.LEADS]: {
    entityType: EntityType.LEAD,
    connectionFilterInputPath: 'leadConnectionFilterInput',
    connectionFilterQueryAliasPath: 'leadConnectionFilter',
    connectionFilterFragmentTypeName: 'LeadConnectionFilterFragment',
  },
  [RoutePath.RETAIL_ITEMS]: {
    entityType: EntityType.RETAIL_ITEM,
    connectionFilterInputPath: 'retailItemConnectionFilterInput',
    connectionFilterQueryAliasPath: 'retailItemConnectionFilter',
    connectionFilterFragmentTypeName: 'RetailItemConnectionFilterFragment',
  },
  [RoutePath.TASKS]: {
    entityType: EntityType.TASK,
    connectionFilterInputPath: 'taskConnectionFilterInput',
    connectionFilterQueryAliasPath: 'taskConnectionFilter',
    connectionFilterFragmentTypeName: 'TaskConnectionFilterFragment',
  },
  [RoutePath.TRADE_IN_ITEMS]: {
    entityType: EntityType.TRADE_IN_ITEM,
    connectionFilterInputPath: 'tradeInConnectionFilterInput',
    connectionFilterQueryAliasPath: 'tradeInItemConnectionFilter',
    connectionFilterFragmentTypeName: 'TradeInConnectionFilterFragment',
  },
};
