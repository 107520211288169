import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import { SubStepAddConfigs } from 'components/sections/createModify/shared/steps/subStepAddConfigs';
import IncentiveListItem from 'components/ui/lists/IncentiveListItem';
import IncentiveOptionListItem from 'components/ui/lists/IncentiveOptionListItem';
import PriceAdjustmentListItem from 'components/ui/lists/PriceAdjustmentListItem';
import PriceListItem from 'components/ui/lists/PriceListItem';
import { BuilderType } from 'enums/builderType';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';

import { RetailItemPricingTypeBuilderFields } from './interfaces';

const RetailItemPricingTypeFields: StepFields = {
  [RetailItemPricingTypeBuilderFields.ADJUSTMENTS]: {
    label: 'adjustment_other',
    displayType: [StepFieldDisplayType.OMITTED],
    groupType: StepFieldType.RENDER_OBJECT,
    groupSubTypes: [StepFieldSubType.CHECKBOX, StepFieldSubType.MULTI_SELECT],
    renderElement: PriceListItem,
    overrideSubStepRenderElement: PriceAdjustmentListItem,
    subStep: [SubStepType.DEFAULT],
    subStepAddConfig: SubStepAddConfigs[BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_CREATE],
    hasSeparator: true,
  },
  [RetailItemPricingTypeBuilderFields.BULK_ADJUSTMENTS]: {
    label: 'bulk_adjustment_other',
    displayType: [StepFieldDisplayType.OMITTED, StepFieldDisplayType.DISABLED],
    groupType: StepFieldType.RENDER_OBJECT,
    groupSubTypes: [StepFieldSubType.CHECKBOX, StepFieldSubType.MULTI_SELECT],
    renderElement: PriceListItem,
    overrideSubStepRenderElement: PriceAdjustmentListItem,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
  [RetailItemPricingTypeBuilderFields.INCENTIVES]: {
    label: 'incentive_other',
    displayType: [StepFieldDisplayType.OMITTED],
    groupType: StepFieldType.RENDER_OBJECT,
    groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.SELECT_ALL_DISABLED, StepFieldSubType.CHECKBOX],
    renderElement: IncentiveListItem,
    overrideSubStepRenderElement: IncentiveOptionListItem,
    subStep: [SubStepType.DEFAULT],
    hasSeparator: true,
  },
};

export default RetailItemPricingTypeFields;
