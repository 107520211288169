import { useCallback, useEffect, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import Label from 'components/core/typography/Label';
import Text from 'components/core/typography/Text';
import { usePrevious } from 'hooks/usePrevious';
import type { InventoryItemPhotoType } from 'store/api/graph/interfaces/types';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { BLUE_500, NEUTRAL_0, NEUTRAL_800, RED_500 } from 'styles/tokens';
import { FONT_SIZE_14 } from 'styles/typography';
import { translate } from 'utils/intlUtils';

import ChevronDownIcon from '../icons/ChevronDownIcon';
import MenuButton, { MenuButtonType, MenuItems, MenuItemsContainer, MenuPosition } from '../menus/MenuButton';
import Badge, { BadgeColors } from '../shared/Badge';
import Button, { Clickable } from '../shared/Button';
import type { DrawerProps } from '../shared/Drawer';
import Drawer from '../shared/Drawer';

const ContentContainer = styled.div`
  flex: 1 1 auto;
`;
const ContentContainerInner = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
const SelectContainer = styled.div`
  flex: 0;
  padding-left: 8px;
  padding-right: 24px;
  border-right: 1px solid ${DIVIDER};
  display: flex;
  align-items: center;

  ${MenuItemsContainer} {
    left: 0;
    margin-top: auto;
    bottom: 100%;
    right: 0;
    top: auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled(Label)`
  color: ${NEUTRAL_800};
  font-size: ${FONT_SIZE_14};
  position: relative;
  top: 2px;
`;

const Instructions = styled(Text)`
  line-height: 24px;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  height: 40px;
  min-width: 140px;
  width: 100%;
  color: ${NEUTRAL_0};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 0;
  gap: 8px;
  padding-left: 24px;
  align-items: center;

  ${ActionButton} {
    &:first-child {
      background: ${RED_500};
    }

    &:last-child {
      background: ${BLUE_500};
    }
  }
`;

const FakeSelect = styled(Clickable)<{ isOpen: boolean }>`
  background: #f8f9fa;
  width: 245px;
  border-radius: 8px;
  border: 1px solid #adc1ce;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;

  svg {
    transition: 0.2s transform;
    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
        transition: 0.2s transform;
      `}
  }
`;

const BadgeCount = styled(Badge)`
  align-self: flex-end;
  letter-spacing: 0;
  min-width: auto;
`;

const { t } = translate;

export const MenuTrigger = ({ isOpen, value = t('Select Category') }) => (
  <FakeSelect isOpen={isOpen}>
    <Text>{value}</Text>
    <ChevronDownIcon color={BODY_TEXT} />
  </FakeSelect>
);

const TargetPhotoTypeSelect = ({ value, options, onOptionClick }) => (
  <MenuButton
    customIcon={isOpen => <MenuTrigger isOpen={isOpen} value={value} />}
    type={MenuButtonType.CUSTOM}
    position={MenuPosition.TOP_LEFT}
  >
    <MenuItems items={options.map(item => ({ label: item, onClick: () => onOptionClick(item) }))}></MenuItems>
  </MenuButton>
);

type MovePhotosDrawerProps = {
  /** The category options displayed in the select option list */
  options: (keyof typeof InventoryItemPhotoType)[];
  /** The number of photos selected */
  count: number;
  /** The callback to run when clicking the cancel button */
  onCancel: () => void;
  /** The callback to run when clicking the confirm button. Returns the category currently selected. */
  onConfirm: (targetCategory: string) => void;
} & DrawerProps;

const MovePhotosDrawer = ({ options: photoTypes, count, onCancel, onConfirm, isOpen }: MovePhotosDrawerProps) => {
  const [targetPhotoType, setTargetPhotoType] = useState();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    // Reset the targetPhotoType each time drawer is reopened, after being closed
    if (isOpen && !prevIsOpen) {
      setTargetPhotoType(undefined);
    }
  }, [isOpen, prevIsOpen]);

  const onConfirmCallback = useCallback(
    targetPhotoType => {
      if (targetPhotoType) {
        onConfirm?.(targetPhotoType);
        setTargetPhotoType(undefined);
      }
    },
    [onConfirm]
  );

  return (
    <Drawer isOpen={isOpen}>
      <ContentContainer>
        <ContentContainerInner>
          <TitleContainer>
            <BadgeCount color={BadgeColors.PRIMARY} title={`${count}`}>
              {count}
            </BadgeCount>
            <Title>{t('move_selected_photo_other')}</Title>
          </TitleContainer>
          <Instructions>{t('move_selected_photo_message')}</Instructions>
        </ContentContainerInner>
      </ContentContainer>
      <SelectContainer>
        <TargetPhotoTypeSelect value={targetPhotoType} options={photoTypes} onOptionClick={setTargetPhotoType} />
      </SelectContainer>
      <ButtonsContainer>
        <ActionButton onClick={onCancel}>{t('cancel')}</ActionButton>
        <ActionButton onClick={() => onConfirmCallback(targetPhotoType)} disabled={count < 1 || !targetPhotoType}>
          {t('confirm')}
        </ActionButton>
      </ButtonsContainer>
    </Drawer>
  );
};

export default MovePhotosDrawer;
