import type { ElementType } from 'react';

import type { DocumentNode } from 'graphql';

import type Tier from 'components/core/createModify/interfaces/tier';
import DynamicImport from 'components/core/DynamicImport';
import AppointmentMenuItems from 'components/sections/appointments/AppointmentMenuItems';
import ConversationMenuItems from 'components/sections/conversations/ConversationMenuItems';
import RetailItemMenuItems from 'components/sections/inventoryItems/retailItems/RetailItemMenuItems';
import TradeInItemMenuItems from 'components/sections/inventoryItems/tradeInItems/TradeInItemMenuItems';
import LeadActivityMenuItems from 'components/sections/leadActivities/LeadActivityMenuItems';
import LeadMenuItems from 'components/sections/leads/LeadMenuItems';
import { RooftopContactDetailsTab } from 'components/sections/rooftops/contacts/RooftopContactsDetailsTab';
import RooftopContactsMenuItems from 'components/sections/rooftops/contacts/RooftopContactsMenuItems';
import { RooftopDepartmentDetailsTab } from 'components/sections/rooftops/departments/DepartmentDetailsTab';
import DepartmentMenuItems from 'components/sections/rooftops/departments/DepartmentMenuItems';
import { RooftopIntegrationDetailsTab } from 'components/sections/rooftops/integrations/RooftopIntegrationDetailsTab';
import RooftopIntegrationsMenuItems from 'components/sections/rooftops/integrations/RooftopIntegrationsMenuItems';
import { RooftopIntegrationURLsTab } from 'components/sections/rooftops/integrations/RooftopIntegrationURLsTab';
import { useRooftopIntegrationRetailFiltersDetailsQuery } from 'components/sections/rooftops/integrations/useRooftopIntegrationRetailFiltersDetailsQuery';
import { getEditBuilderForIntegrationType } from 'components/sections/rooftops/integrations/utils';
import { RooftopLeadForwardingDetailsTab } from 'components/sections/rooftops/leadForwarding/RooftopLeadForwardingDetailsTab';
import RooftopLeadForwardingMenuItems from 'components/sections/rooftops/leadForwarding/RooftopLeadForwardingMenuItems';
import { RooftopMailboxDetailsTab } from 'components/sections/rooftops/mailboxes/RooftopMailboxDetailsTab';
import RooftopMailboxMenuItems from 'components/sections/rooftops/mailboxes/RooftopMailboxMenuItems';
import { CashSegmentDetailsTab } from 'components/sections/rooftops/pricingSegments/CashSegmentDetailsTab';
import { FinanceSegmentConfigurationTab } from 'components/sections/rooftops/pricingSegments/FinanceSegmentConfigurationTab';
import { FinanceSegmentDetailsTab } from 'components/sections/rooftops/pricingSegments/FinanceSegmentDetailsTab';
import { LeaseSegmentConfigurationTab } from 'components/sections/rooftops/pricingSegments/LeaseSegmentConfigurationTab';
import { LeaseSegmentDetailsTab } from 'components/sections/rooftops/pricingSegments/LeaseSegmentDetailsTab';
import PricingSegmentMenuItems from 'components/sections/rooftops/pricingSegments/PricingSegmentMenuItems';
import { RooftopRetailBulkAdjustmentsDetailsTab } from 'components/sections/rooftops/retailBulkAdjustments/RooftopRetailBulkAdjustmentsDetailsTab';
import RooftopRetailBulkAdjustmentsMenuItems from 'components/sections/rooftops/retailBulkAdjustments/RooftopRetailBulkAdjustmentsMenuItems';
import RooftopDetailsTab from 'components/sections/rooftops/RooftopDetailsTab';
import RooftopMenuItems from 'components/sections/rooftops/RooftopMenuItems';
import { ShoppingToolsDetailsTab } from 'components/sections/rooftops/shoppingTools/ShoppingToolsDetailsTab';
import { getShoppingToolsTitle } from 'components/sections/rooftops/shoppingTools/utils';
import RooftopTagsMenuItems from 'components/sections/rooftops/tags/RooftopTagsMenuItems';
import { useCreditApplicationQuery } from 'components/sections/shared/linkedTab/creditApplications/useCreditApplicationQuery';
import { RetailFiltersTab } from 'components/sections/shared/retailFilters/RetailFiltersTab';
import { useRetailFiltersDetailsQuery } from 'components/sections/shared/retailFilters/useRetailFiltersDetailsQuery';
import TagDetailsTab from 'components/sections/tags/TagDetailsTab';
import TaskMenuItems from 'components/sections/tasks/TaskMenuItems';
import UserMenuItems from 'components/sections/users/UserMenuItems';
import MinimumDisplayPriceBanner from 'components/ui/details/MinimumDisplayPriceBanner';
import AppointmentListItem from 'components/ui/lists/AppointmentListItem';
import ContactListItem from 'components/ui/lists/ContactListItem';
import ConversationListItem from 'components/ui/lists/ConversationListItem';
import { CreditApplicationListItem } from 'components/ui/lists/CreditApplicationListItem';
import DepartmentListItem from 'components/ui/lists/DepartmentListItem';
import LeadActivityListItem from 'components/ui/lists/LeadActivityListItem';
import LeadListItem from 'components/ui/lists/LeadListItem';
import MailboxListItem from 'components/ui/lists/MailboxListItem';
import { RetailListItem } from 'components/ui/lists/RetailListItem';
import RooftopIntegrationListItem from 'components/ui/lists/RooftopIntegrationListItem';
import RooftopLeadForwardListItem from 'components/ui/lists/RooftopLeadForwardListItem';
import RooftopListItem from 'components/ui/lists/RooftopListItem';
import RooftopRetailBulkAdjustmentListItem from 'components/ui/lists/RooftopRetailBulkAdjustmentListItem';
import RooftopRetailPricingListItem from 'components/ui/lists/RooftopRetailPricingListItem';
import TagListItem from 'components/ui/lists/TagListItem';
import TaskListItem from 'components/ui/lists/TaskListItem';
import { TradeInListItem } from 'components/ui/lists/TradeInListItem';
import UserListItem from 'components/ui/lists/UserListItem';
import type ItemTab from 'components/ui/shared/interfaces/ItemTab';
import {
  appointmentCondensedListQuery,
  appointmentDetailsQuery,
} from 'containers/appointments/AppointmentsContainerQuery';
import {
  appointmentDetailsTabs,
  getAppointmentDetailsTitle,
} from 'containers/appointments/helpers/AppointmentContainer';
import {
  conversationCondensedListQuery,
  conversationDetailsQuery,
  useConversationDetailsQuery,
} from 'containers/conversations/ConversationsContainerQuery';
import {
  conversationDetailsTabs,
  getConversationDetailsTitle,
} from 'containers/conversations/helpers/ConversationsContainer';
import {
  getRetailItemDetailsTitle,
  retailItemsDetailsTabs,
} from 'containers/inventoryItems/helpers/RetailItemsContainer';
import {
  getTradeInItemDetailsTitle,
  tradeInItemsDetailsTabs,
} from 'containers/inventoryItems/helpers/TradeInItemsContainer';
import {
  retailItemsCondensedListQuery,
  retailItemsDetailsQuery,
  useRetailItemDetailsQuery,
  useRetailItemForSaleListQuery,
} from 'containers/inventoryItems/RetailItemsContainerQuery';
import {
  tradeInItemsCondensedListQuery,
  tradeInItemsDetailsQuery,
} from 'containers/inventoryItems/TradeInItemsContainerQuery';
import {
  getLeadActivityDetailsTitle,
  leadActivityDetailsTabs,
} from 'containers/leadActivities/helpers/LeadActivitiesContainer';
import {
  leadActivityCondensedListQuery,
  leadActivityDetailsQuery,
} from 'containers/leadActivities/LeadActivitiesContainerQuery';
import { getLeadDetailsTitle, leadDetailsTabs } from 'containers/leads/helpers/LeadsContainer';
import {
  leadCondensedListQuery,
  leadCreditApplicationsQuery,
  leadDetailsQuery,
} from 'containers/leads/LeadsContainerQuery';
import { mailboxCondensedListQuery, mailboxDetailsQuery } from 'containers/mailbox/MailboxContainerQuery';
import {
  rooftopContactsCondensedListQuery,
  rooftopContactsDetailsQuery,
} from 'containers/rooftops/contacts/RooftopContactsContainerQuery';
import { getRooftopDetailsTitle } from 'containers/rooftops/helpers/RooftopsContainer';
import {
  rooftopIntegrationsCondensedListQuery,
  rooftopIntegrationsDetailsQuery,
} from 'containers/rooftops/integrations/RooftopIntegrationsContainerQuery';
import {
  rooftopLeadForwardingCondensedListQuery,
  rooftopLeadForwardingDetailsQuery,
  useRooftopLeadForwardListQuery,
} from 'containers/rooftops/leadForward/RooftopLeadForwardContainerQuery';
import {
  rooftopRetailPricingCashDetailQuery,
  rooftopRetailPricingContainerQuery,
} from 'containers/rooftops/pricingSegments/RooftopRetailPricingContainerQuery';
import { useRetailPricingListQuery } from 'containers/rooftops/pricingSegments/useRetailPricingQuery';
import {
  rooftopRetailBulkAdjustmentsCondensedListQuery,
  rooftopRetailBulkAdjustmentsDetailsQuery,
} from 'containers/rooftops/retailBulkAdjustments/RooftopRetailBulkAdjustmentsContainerQuery';
import { useBulkAdjustmentQuery } from 'containers/rooftops/retailBulkAdjustments/useBulkAdjustmentQuery';
import { rooftopCondensedListQuery, rooftopDetailsQuery } from 'containers/rooftops/RooftopsContainerQuery';
import {
  rooftopDepartmentDetailsQuery,
  rooftopDepartmentsQuery,
  useRooftopDepartmentDetailsQuery,
  useRooftopDepartmentsQuery,
} from 'containers/rooftops/RooftopsDepartmentsQuery';
import { shoppingToolsDetailsQuery } from 'containers/rooftops/shoppingTools/ShoppingToolsContainerQuery';
import {
  rooftopTagsCondensedListQuery,
  tagDetailQuery,
  useRooftopTagsCondensedListQuery,
} from 'containers/rooftops/tags/RooftopTagsContainerQuery';
import { getTaskDetailsTitle, taskDetailsTabs } from 'containers/tasks/helpers/TasksContainer';
import { taskCondensedListQuery, taskDetailsQuery } from 'containers/tasks/TasksContainerQuery';
import { getUserDetailsTitle, userDetailsTabs } from 'containers/users/helpers/UsersContainer';
import { userCondensedListQuery, userDetailsQuery, useUserDetailsQuery } from 'containers/users/UsersContainerQuery';
import { BuilderType } from 'enums/builderType';
import { CreateModifyTiers } from 'enums/createModifyTiers';
import { CustomEntity } from 'enums/extendedEntityType';
import { FeatureBundleSet } from 'enums/featureBundle';
import { RoutePath } from 'enums/routePath';
import { TabType } from 'enums/tabType';
import type {
  RooftopIntegrationContainerItemFragment,
  RooftopRetailPricingFragment,
  ShoppingToolsDetailsQuery,
} from 'store/api/graph/interfaces/types';
import {
  EntityType,
  IntegrationType,
  InventoryItemType,
  LeadActivityType,
  PaymentOption,
  RetailBulkAdjustmentType,
  RetailItemStatus,
  SortDirection,
  TaskStatus,
  TradeInItemStatus,
  UserScope,
} from 'store/api/graph/interfaces/types';
import type {
  ContactResponseType,
  MailboxResponseType,
  RooftopIntegrationResponseType,
  RooftopResponseType,
  RooftopRetailBulkAdjustmentResponseType,
  TagResponseType,
  UserResponseType,
} from 'store/api/graph/responses/responseTypes';
import { getDateTime, getFormattedDateTimeString } from 'utils/dateUtils';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import { translate } from 'utils/intlUtils';

import { NestedViewConfigType, SecondaryViewType, SecondaryViewWidth } from './interfaces';

const { t } = translate;

interface SecondaryViewRenderDataSettings {
  /** List of <MenuItem /> actions related to the nested view item */
  menuItems?: ElementType;
  /** Optional data to render if there is no server-side data (query == null) */
  item?: any;
  /** The current tab type being viewed if applicable */
  currentTab?: TabType;
  /** Custom banner to render above tabs */
  customBanner?: (item?: any) => JSX.Element | undefined;
}

type SecondaryViewCommonProps = {
  /** The query required for this view to get its data */
  query: DocumentNode;
  /**
   * The optional data connection for prop `query` to be used when retrieving data on a specific item.
   * Defaults to `useQuery`
   */
  useQueryOverride?: any;
  /**
   * The rendering class that houses the data in this view. For LIST views this component is used as the List Item,
   * for DETAILS view this is a tabbed configuration. Optionally, a function can be provided if dynamic tabs are
   * needed based on the item being loaded.
   */
  renderData: ElementType | ItemTab[] | ((itemData: any) => ItemTab[]);
  /** The overriding container class */
  containerOverride?: ElementType;
  /** The width of this Secondary View */
  width?: SecondaryViewWidth;
};

export type SecondaryDetailViewProps = SecondaryViewCommonProps & {
  /** Optional data to be rendered in the nested view */
  renderDataSettings?: SecondaryViewRenderDataSettings;

  /**
   * The editing builder used for this nested view. Optionally a function can be provided if a dynamic builder is needed
   * based on the item being edited.
   */
  editBuilder?: BuilderType | ((itemData: any) => BuilderType);
};

export type SecondaryListViewProps = SecondaryViewCommonProps & {
  /** If there are more than one lists, this is the label of the tab for this particular list */
  labelTranslationKey?: string;
  /** The builder config used for creating an item in this nested view */
  createBuilder?: (itemData: any) => Partial<Tier>;
  /** Override the text used in the create button. Default is the title of the create builder, this overrides that. */
  overrideCreateButtonTranslationKey?: string;
  /**
   * Whether to always show a CTA button at the bottom of the list to create a new item. If this is false, then
   * the CTA button will only show up when there are no items in the list
   */
  alwaysShowCreateButton?: boolean;
  /** Disable pagination in the list view */
  disablePagination?: boolean;
  /** Override to show when there are no results in a list view */
  noResultsPlaceholderOverride?: ElementType | null;
  /** Custom labels to use in the no results placeholder */
  noResultsPlaceholderLabels?: {
    labelTranslationKey: string;
    subLabelTranslationKey?: string;
  };
  /** Custom query vars to use in the query */
  queryVars?: Record<string, unknown>;
  /**
   * The rendering class that houses the data in this view. For LIST views this component is used as the List Item,
   */
  renderData: ElementType;
  /** False by default. Will make the item unclickable if set to true. */
  isUnclickable?: boolean | ((itemData: any) => boolean);
  /** Hides the search input. */
  hideSearchInput?: boolean;
  /** If given, the Create button will be disabled if the user does not have at least this scope. */
  minimumRequiredCreateScope?: UserScope;
  /** If given, the list view will need the following feature bundle enabled */
  requiredFeatureBundle?: FeatureBundleSet;
};

export type SecondaryListTabViewProps = {
  tabs?: (SecondaryListViewProps & { tabId: TabType })[];
};

type SecondaryViewConfigType = {
  [SecondaryViewType.DETAILS]: SecondaryDetailViewProps;
  [SecondaryViewType.LIST]: Partial<SecondaryListViewProps> & SecondaryListTabViewProps;
};

type NestedViewDetailsConfigType = {
  [key: string]: (itemData: any) => { queryVars: any; title: string; base?: RoutePath };
};

type NestedViewListConfigType = {
  [key: string]: (...args) => Record<string, unknown>;
};

// TODO: Move to separate file
const LeadActivityNestedViewSettings: {
  [NestedViewConfigType.SecondaryView]: SecondaryViewConfigType;
  [NestedViewConfigType.DetailsView]: NestedViewDetailsConfigType;
  [NestedViewConfigType.ListView]: NestedViewListConfigType;
} = {
  [NestedViewConfigType.SecondaryView]: {
    [SecondaryViewType.DETAILS]: {
      query: leadActivityDetailsQuery,
      renderData: leadActivityDetailsTabs,
      renderDataSettings: {
        menuItems: LeadActivityMenuItems,
      },
      editBuilder: BuilderType.LEAD_ACTIVITY_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: leadActivityCondensedListQuery,
      renderData: LeadActivityListItem,
    },
  },
  [NestedViewConfigType.DetailsView]: {
    defaultConfig: itemData => ({
      title: getLeadActivityDetailsTitle(itemData),
      queryVars: {
        // TODO: TBD
      },
    }),
  },
  [NestedViewConfigType.ListView]: {
    defaultConfig: (params, type?: LeadActivityType) => ({
      filter: {
        ...params,
        type,
      },
    }),
  },
};

// TODO: Combine these configs with their respective settings below somehow
export const SecondaryViewsConfig: {
  [key: string]: SecondaryViewConfigType;
} = {
  [EntityType.LEAD_ACTIVITY]: LeadActivityNestedViewSettings[NestedViewConfigType.SecondaryView],
  [LeadActivityType.PHONE_CALL]: LeadActivityNestedViewSettings[NestedViewConfigType.SecondaryView],
  [LeadActivityType.TEST_DRIVE]: LeadActivityNestedViewSettings[NestedViewConfigType.SecondaryView],
  [LeadActivityType.WALK_IN]: LeadActivityNestedViewSettings[NestedViewConfigType.SecondaryView],
  [EntityType.APPOINTMENT]: {
    [SecondaryViewType.DETAILS]: {
      query: appointmentDetailsQuery,
      renderData: appointmentDetailsTabs,
      renderDataSettings: {
        menuItems: AppointmentMenuItems,
      },
      editBuilder: BuilderType.APPOINTMENT_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: appointmentCondensedListQuery,
      renderData: AppointmentListItem,
      containerOverride: DynamicImport(() => import('components/ui/lists/AppointmentList')),
    },
  },
  [EntityType.LEAD]: {
    [SecondaryViewType.DETAILS]: {
      query: leadDetailsQuery,
      renderData: leadDetailsTabs,
      renderDataSettings: {
        menuItems: LeadMenuItems,
      },
      editBuilder: BuilderType.LEAD_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: leadCondensedListQuery,
      renderData: LeadListItem,
    },
  },
  [EntityType.FINANCE_APPLICATION]: {
    // This entity type does not have an associated details view
    [SecondaryViewType.DETAILS]: {} as SecondaryDetailViewProps,
    [SecondaryViewType.LIST]: {
      query: leadCreditApplicationsQuery,
      renderData: CreditApplicationListItem,
      useQueryOverride: useCreditApplicationQuery,
      isUnclickable: true,
      hideSearchInput: true,
    },
  },
  [EntityType.RETAIL_ITEM]: {
    [SecondaryViewType.DETAILS]: {
      query: retailItemsDetailsQuery,
      useQueryOverride: useRetailItemDetailsQuery,
      editBuilder: BuilderType.RETAIL_ITEMS_MODIFY,
      /*
       * TODO: This is a temporary fix for a strange import issue that evaluates this specific export to 'undefined'
       * if assigned right away
       */
      get renderData() {
        return retailItemsDetailsTabs;
      },
      renderDataSettings: {
        menuItems: RetailItemMenuItems,
        customBanner: MinimumDisplayPriceBanner,
      },
    },
    [SecondaryViewType.LIST]: {
      query: retailItemsCondensedListQuery,
      useQueryOverride: useRetailItemForSaleListQuery,
      renderData: RetailListItem,
    },
  },
  [EntityType.TRADE_IN_ITEM]: {
    [SecondaryViewType.DETAILS]: {
      query: tradeInItemsDetailsQuery,
      renderData: tradeInItemsDetailsTabs,
      renderDataSettings: {
        menuItems: TradeInItemMenuItems,
      },
      editBuilder: BuilderType.TRADE_IN_ITEMS_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: tradeInItemsCondensedListQuery,
      renderData: TradeInListItem,
    },
  },
  [EntityType.TASK]: {
    [SecondaryViewType.DETAILS]: {
      query: taskDetailsQuery,
      renderData: taskDetailsTabs,
      renderDataSettings: {
        menuItems: TaskMenuItems,
      },
      editBuilder: BuilderType.TASK_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: taskCondensedListQuery,
      renderData: TaskListItem,
    },
  },
  [EntityType.CONVERSATION]: {
    [SecondaryViewType.DETAILS]: {
      query: conversationDetailsQuery,
      useQueryOverride: useConversationDetailsQuery,
      renderData: conversationDetailsTabs,
      renderDataSettings: {
        menuItems: ConversationMenuItems,
      },
      editBuilder: BuilderType.CONVERSATION_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: conversationCondensedListQuery,
      renderData: ConversationListItem,
    },
  },
  [CustomEntity.USER]: {
    [SecondaryViewType.DETAILS]: {
      query: userDetailsQuery,
      useQueryOverride: useUserDetailsQuery,
      renderData: userDetailsTabs,
      renderDataSettings: {
        menuItems: UserMenuItems,
      },
      editBuilder: BuilderType.USER_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: userCondensedListQuery,
      renderData: UserListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_user_other',
        subLabelTranslationKey: 'no_user_message_other',
      },
      alwaysShowCreateButton: true,
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.USER_CREATE,
        entityType: CustomEntity.USER,
        title: t('create_user'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              groupId: {
                ...seededData.rooftop.groupName,
              },
            }
          : undefined,
      }),
      isUnclickable: (item: UserResponseType) => !!item.deleted,
    },
  },
  [CustomEntity.ROOFTOP]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopDetailsQuery,
      // This creates errors of imported from `RooftopInfoContainer` so has to be recreated here
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopDetailsTab,
        },
      ],
      renderDataSettings: {
        menuItems: RooftopMenuItems,
      },
      editBuilder: BuilderType.ROOFTOPS_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: rooftopCondensedListQuery,
      renderData: RooftopListItem,
    },
  },
  [CustomEntity.ROOFTOP_INTEGRATIONS]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopIntegrationsDetailsQuery,
      useQueryOverride: useRooftopIntegrationRetailFiltersDetailsQuery,
      // This creates errors of imported from `RooftopInfoContainer` so has to be recreated here
      renderData: itemData => {
        switch (itemData?.integrationType?.id) {
          case IntegrationType.OEM_ACCOUNT_EXPORT: {
            return [
              {
                tabId: TabType.DETAILS,
                component: RooftopIntegrationDetailsTab,
              },
              {
                tabId: TabType.URLS,
                component: RooftopIntegrationURLsTab,
              },
            ];
          }

          case IntegrationType.INVENTORY_EXPORT: {
            return [
              {
                tabId: TabType.DETAILS,
                component: RooftopIntegrationDetailsTab,
              },
              {
                tabId: TabType.RETAIL_FILTERS,
                label: 'filter_other',
                component: RetailFiltersTab,
              },
            ];
          }

          default: {
            return [
              {
                tabId: TabType.DETAILS,
                component: RooftopIntegrationDetailsTab,
              },
            ];
          }
        }
      },
      renderDataSettings: {
        menuItems: RooftopIntegrationsMenuItems,
      },
      editBuilder: itemData => getEditBuilderForIntegrationType(itemData?.integrationType?.id),
    },
    [SecondaryViewType.LIST]: {
      query: rooftopIntegrationsCondensedListQuery,
      alwaysShowCreateButton: true,
      renderData: RooftopIntegrationListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_integration_other',
        subLabelTranslationKey: 'no_integration_message_other',
      },
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_INTEGRATION_CREATE,
        entityType: EntityType.ROOFTOP_INTEGRATION,
        title: t('create_integration'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              rooftopName: {
                id: seededData.rooftop.id,
                name: seededData.rooftop.name,
                group: seededData.rooftop.groupName,
              },
            }
          : undefined,
      }),
      minimumRequiredCreateScope: UserScope.WHITE_LABEL,
    },
  },
  [CustomEntity.ROOFTOP_SHOPPING_TOOLS]: {
    [SecondaryViewType.DETAILS]: {
      query: shoppingToolsDetailsQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: ShoppingToolsDetailsTab,
        },
      ],
      editBuilder: BuilderType.SHOPPING_TOOLS_MODIFY,
    },
    [SecondaryViewType.LIST]: {},
  },
  [CustomEntity.ROOFTOP_CONTACTS]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopContactsDetailsQuery,
      // This creates errors of imported from `RooftopInfoContainer` so has to be recreated here
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopContactDetailsTab,
        },
      ],
      renderDataSettings: {
        menuItems: RooftopContactsMenuItems,
      },
      editBuilder: BuilderType.CONTACT_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: rooftopContactsCondensedListQuery,
      alwaysShowCreateButton: true,
      renderData: ContactListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_contact_other',
        subLabelTranslationKey: 'no_contact_message_other',
      },
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.CONTACT_CREATE,
        entityType: CustomEntity.ROOFTOP_CONTACTS,
        title: t('create_contact'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              rooftop: seededData.rooftop,
            }
          : undefined,
      }),
    },
  },
  [CustomEntity.ROOFTOP_DEPARTMENTS]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopDepartmentDetailsQuery,
      useQueryOverride: useRooftopDepartmentDetailsQuery,
      renderData: [{ tabId: TabType.DETAILS, component: RooftopDepartmentDetailsTab }],
      editBuilder: BuilderType.ROOFTOP_DEPARTMENTS_MODIFY,
      renderDataSettings: {
        menuItems: DepartmentMenuItems,
      },
    },
    [SecondaryViewType.LIST]: {
      query: rooftopDepartmentsQuery,
      useQueryOverride: useRooftopDepartmentsQuery,
      renderData: DepartmentListItem,
      alwaysShowCreateButton: true,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_department_other',
        subLabelTranslationKey: 'no_department_message_other',
      },
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_DEPARTMENTS_CREATE,
        entityType: CustomEntity.ROOFTOP_DEPARTMENTS,
        title: t('create_department'),
        isCreating: true,
        seededData: seededData?.rooftop ? { rooftop: seededData.rooftop } : undefined,
      }),
    },
  },
  [CustomEntity.ROOFTOP_PRICING_SEGMENTS]: {
    [SecondaryViewType.DETAILS]: {
      useQueryOverride: useRetailFiltersDetailsQuery,
      query: rooftopRetailPricingCashDetailQuery,
      width: SecondaryViewWidth.EXPANDED,
      editBuilder: itemData =>
        ({
          [PaymentOption.CASH]: BuilderType.ROOFTOP_CASH_SEGMENT_MODIFY,
          [PaymentOption.FINANCE]: BuilderType.ROOFTOP_FINANCE_SEGMENT_MODIFY,
          [PaymentOption.LEASE]: BuilderType.ROOFTOP_LEASE_SEGMENT_MODIFY,
        })[itemData?.paymentOption],
      renderData: itemData =>
        ({
          [PaymentOption.CASH]: [
            {
              tabId: TabType.DETAILS,
              component: CashSegmentDetailsTab,
            },
            {
              tabId: TabType.RETAIL_FILTERS,
              label: 'filter_other',
              component: RetailFiltersTab,
            },
          ],
          [PaymentOption.FINANCE]: [
            {
              tabId: TabType.DETAILS,
              component: FinanceSegmentDetailsTab,
            },
            {
              tabId: TabType.PRICING_CONFIGURATION,
              label: 'configuration_one',
              component: FinanceSegmentConfigurationTab,
            },
            {
              tabId: TabType.RETAIL_FILTERS,
              label: 'filter_other',
              component: RetailFiltersTab,
            },
          ],
          [PaymentOption.LEASE]: [
            {
              tabId: TabType.DETAILS,
              component: LeaseSegmentDetailsTab,
            },
            {
              tabId: TabType.PRICING_CONFIGURATION,
              label: 'configuration_one',
              component: LeaseSegmentConfigurationTab,
            },
            {
              tabId: TabType.RETAIL_FILTERS,
              label: 'filter_other',
              component: RetailFiltersTab,
            },
          ],
        })[itemData?.paymentOption],
      renderDataSettings: {
        menuItems: PricingSegmentMenuItems,
      },
    },
    [SecondaryViewType.LIST]: {
      tabs: [
        {
          labelTranslationKey: 'cash',
          tabId: TabType.PRICING_CASH,
          requiredFeatureBundle: FeatureBundleSet.RETAIL_CASH_PAYMENT_OPTION,
          query: rooftopRetailPricingContainerQuery,
          useQueryOverride: useRetailPricingListQuery,
          queryVars: { filter: { paymentOption: PaymentOption.CASH } },
          renderData: RooftopRetailPricingListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_cash_pricing_segment_other',
            subLabelTranslationKey: 'no_cash_pricing_segment_message_other',
          },
          overrideCreateButtonTranslationKey: 'create_cash_segment',
          alwaysShowCreateButton: true,
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            type: BuilderType.ROOFTOP_CASH_SEGMENT_CREATE,
            tierId: CreateModifyTiers.TIER_0,
            entityType: CustomEntity.ROOFTOP_PRICING_SEGMENTS,
            title: `${t('configure_pricing_segment')} (${t('cash')})`,
            isCreating: true,
            seededData: seededData?.rooftop
              ? {
                  rooftopName: seededData.rooftop,
                }
              : undefined,
          }),
        },
        {
          labelTranslationKey: 'finance',
          tabId: TabType.PRICING_FINANCE,
          requiredFeatureBundle: FeatureBundleSet.RETAIL_FINANCE_PAYMENT_OPTION,
          query: rooftopRetailPricingContainerQuery,
          useQueryOverride: useRetailPricingListQuery,
          queryVars: { filter: { paymentOption: PaymentOption.FINANCE } },
          renderData: RooftopRetailPricingListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_finance_pricing_segment_other',
            subLabelTranslationKey: 'no_finance_pricing_segment_message_other',
          },
          overrideCreateButtonTranslationKey: 'create_finance_segment',
          alwaysShowCreateButton: true,
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            type: BuilderType.ROOFTOP_FINANCE_SEGMENT_CREATE,
            tierId: CreateModifyTiers.TIER_0,
            entityType: CustomEntity.ROOFTOP_PRICING_SEGMENTS,
            title: `${t('configure_pricing_segment')} (${t('finance')})`,
            isCreating: true,
            seededData: seededData?.rooftop
              ? {
                  rooftopName: seededData.rooftop,
                }
              : undefined,
          }),
        },
        {
          labelTranslationKey: 'lease',
          tabId: TabType.PRICING_LEASE,
          requiredFeatureBundle: FeatureBundleSet.RETAIL_LEASE_PAYMENT_OPTION,
          query: rooftopRetailPricingContainerQuery,
          useQueryOverride: useRetailPricingListQuery,
          queryVars: { filter: { paymentOption: PaymentOption.LEASE } },
          renderData: RooftopRetailPricingListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_lease_pricing_segment_other',
            subLabelTranslationKey: 'no_lease_pricing_segment_message_other',
          },
          overrideCreateButtonTranslationKey: 'create_lease_segment',
          alwaysShowCreateButton: true,
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            type: BuilderType.ROOFTOP_LEASE_SEGMENT_CREATE,
            tierId: CreateModifyTiers.TIER_0,
            entityType: CustomEntity.ROOFTOP_PRICING_SEGMENTS,
            title: `${t('configure_pricing_segment')} (${t('lease')})`,
            isCreating: true,
            seededData: seededData?.rooftop
              ? {
                  rooftopName: seededData.rooftop,
                }
              : undefined,
          }),
        },
      ],
    },
  },

  [CustomEntity.ROOFTOP_TAGS]: {
    [SecondaryViewType.DETAILS]: {
      query: tagDetailQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: TagDetailsTab,
        },
      ],
      renderDataSettings: {
        menuItems: RooftopTagsMenuItems,
      },
      editBuilder: BuilderType.ROOFTOP_TAGS_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: rooftopTagsCondensedListQuery,
      alwaysShowCreateButton: true,
      renderData: TagListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_tag_other',
        subLabelTranslationKey: 'no_tag_message_other',
      },
      useQueryOverride: useRooftopTagsCondensedListQuery,
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_TAGS_CREATE,
        entityType: CustomEntity.ROOFTOP_TAGS,
        title: t('create_tag'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              rooftop: { ...seededData.rooftop },
            }
          : undefined,
      }),
    },
  },

  [CustomEntity.ROOFTOP_BULK_ADJUSTMENT]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopRetailBulkAdjustmentsDetailsQuery,
      useQueryOverride: useRetailFiltersDetailsQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopRetailBulkAdjustmentsDetailsTab,
        },
        {
          tabId: TabType.RETAIL_FILTERS,
          component: RetailFiltersTab,
          label: 'filter_other',
        },
      ],
      renderDataSettings: {
        menuItems: RooftopRetailBulkAdjustmentsMenuItems,
      },
      editBuilder: BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      tabs: [
        {
          tabId: TabType.BULK_ADJUSTMENT_PRICING,
          labelTranslationKey: 'price',
          requiredFeatureBundle: FeatureBundleSet.RETAIL_PAYMENT_OPTION,
          query: rooftopRetailBulkAdjustmentsCondensedListQuery,
          useQueryOverride: useBulkAdjustmentQuery,
          queryVars: { filter: { type: RetailBulkAdjustmentType.PRICE } },
          alwaysShowCreateButton: true,
          overrideCreateButtonTranslationKey: 'create_price_adjustment',
          renderData: RooftopRetailBulkAdjustmentListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_price_bulk_adjustment_other',
            subLabelTranslationKey: 'no_price_bulk_adjustment_message_other',
          },
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            entityType: EntityType.RETAIL_ITEM,
            tierId: CreateModifyTiers.TIER_0,
            type: BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE,
            title: t('create_adjustment'),
            isCreating: true,
            seededData: {
              rooftopName: seededData?.rooftop ?? undefined,
              type: RetailBulkAdjustmentType.PRICE,
            },
          }),
        },
        {
          tabId: TabType.BULK_ADJUSTMENT_OVERLAY,
          labelTranslationKey: 'overlay',
          query: rooftopRetailBulkAdjustmentsCondensedListQuery,
          useQueryOverride: useBulkAdjustmentQuery,
          queryVars: { filter: { type: RetailBulkAdjustmentType.OVERLAY } },
          alwaysShowCreateButton: true,
          overrideCreateButtonTranslationKey: 'create_overlay_adjustment',
          renderData: RooftopRetailBulkAdjustmentListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_overlay_bulk_adjustment_other',
            subLabelTranslationKey: 'no_overlay_bulk_adjustment_message_other',
          },
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            entityType: EntityType.RETAIL_ITEM,
            tierId: CreateModifyTiers.TIER_0,
            type: BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE,
            title: t('create_adjustment'),
            isCreating: true,
            seededData: {
              rooftopName: seededData?.rooftop ?? undefined,
              type: RetailBulkAdjustmentType.OVERLAY,
            },
          }),
        },
        {
          tabId: TabType.BULK_ADJUSTMENT_DESCRIPTION,
          labelTranslationKey: 'description',
          query: rooftopRetailBulkAdjustmentsCondensedListQuery,
          useQueryOverride: useBulkAdjustmentQuery,
          queryVars: { filter: { type: RetailBulkAdjustmentType.DESCRIPTION } },
          alwaysShowCreateButton: true,
          overrideCreateButtonTranslationKey: 'create_description_adjustment',
          renderData: RooftopRetailBulkAdjustmentListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_description_bulk_adjustment_other',
            subLabelTranslationKey: 'no_description_bulk_adjustment_message_other',
          },
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            entityType: EntityType.RETAIL_ITEM,
            tierId: CreateModifyTiers.TIER_0,
            type: BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE,
            title: t('create_adjustment'),
            isCreating: true,
            seededData: {
              rooftopName: seededData?.rooftop ?? undefined,
              type: RetailBulkAdjustmentType.DESCRIPTION,
            },
          }),
        },
        {
          tabId: TabType.BULK_ADJUSTMENT_PROMOTION,
          labelTranslationKey: 'promotion',
          query: rooftopRetailBulkAdjustmentsCondensedListQuery,
          useQueryOverride: useBulkAdjustmentQuery,
          queryVars: { filter: { type: RetailBulkAdjustmentType.PROMOTION } },
          alwaysShowCreateButton: true,
          overrideCreateButtonTranslationKey: 'create_promotion_adjustment',
          renderData: RooftopRetailBulkAdjustmentListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_promotion_bulk_adjustment_other',
            subLabelTranslationKey: 'no_promotion_bulk_adjustment_message_other',
          },
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            entityType: EntityType.RETAIL_ITEM,
            tierId: CreateModifyTiers.TIER_0,
            type: BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE,
            title: t('create_adjustment'),
            isCreating: true,
            seededData: {
              rooftopName: seededData?.rooftop ?? undefined,
              type: RetailBulkAdjustmentType.PROMOTION,
            },
          }),
        },
        {
          tabId: TabType.BULK_ADJUSTMENT_DISCLAIMER,
          labelTranslationKey: 'disclaimer',
          query: rooftopRetailBulkAdjustmentsCondensedListQuery,
          useQueryOverride: useBulkAdjustmentQuery,
          queryVars: { filter: { type: RetailBulkAdjustmentType.DISCLAIMER } },
          alwaysShowCreateButton: true,
          overrideCreateButtonTranslationKey: 'create_disclaimer_adjustment',
          renderData: RooftopRetailBulkAdjustmentListItem,
          noResultsPlaceholderLabels: {
            labelTranslationKey: 'no_disclaimer_bulk_adjustment_other',
            subLabelTranslationKey: 'no_disclaimer_bulk_adjustment_message_other',
          },
          createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
            entityType: EntityType.RETAIL_ITEM,
            tierId: CreateModifyTiers.TIER_0,
            type: BuilderType.RETAIL_BULK_ADJUSTMENT_CREATE,
            title: t('create_adjustment'),
            isCreating: true,
            seededData: {
              rooftopName: seededData?.rooftop ?? undefined,
              type: RetailBulkAdjustmentType.DISCLAIMER,
            },
          }),
        },
      ],
    },
  },

  [CustomEntity.RETAIL_BULK_ADJUSTMENT]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopRetailBulkAdjustmentsDetailsQuery,
      useQueryOverride: useRetailFiltersDetailsQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopRetailBulkAdjustmentsDetailsTab,
        },
        {
          tabId: TabType.RETAIL_FILTERS,
          component: RetailFiltersTab,
          label: 'filter_other',
        },
      ],
      renderDataSettings: {
        menuItems: RooftopRetailBulkAdjustmentsMenuItems,
      },
      editBuilder: BuilderType.RETAIL_BULK_ADJUSTMENT_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: rooftopRetailBulkAdjustmentsCondensedListQuery,
      renderData: RooftopRetailBulkAdjustmentListItem,
    },
  },

  [EntityType.MAILBOX]: {
    [SecondaryViewType.DETAILS]: {
      query: mailboxDetailsQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopMailboxDetailsTab,
        },
      ],
      renderDataSettings: {
        menuItems: RooftopMailboxMenuItems,
      },
      editBuilder: BuilderType.MAILBOX_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: mailboxCondensedListQuery,
      alwaysShowCreateButton: true,
      renderData: MailboxListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_mailbox_other',
        subLabelTranslationKey: 'no_mailbox_message_other',
      },
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.MAILBOX_CREATE,
        entityType: EntityType.MAILBOX,
        title: t('create_mailbox'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              rooftopName: { id: seededData.rooftop.id, name: seededData.rooftop.name },
            }
          : undefined,
      }),
    },
  },
  [EntityType.LEAD_FORWARD]: {
    [SecondaryViewType.DETAILS]: {
      query: rooftopLeadForwardingDetailsQuery,
      renderData: [
        {
          tabId: TabType.DETAILS,
          component: RooftopLeadForwardingDetailsTab,
        },
      ],
      renderDataSettings: {
        menuItems: RooftopLeadForwardingMenuItems,
      },
      editBuilder: BuilderType.ROOFTOP_LEAD_FORWARD_MODIFY,
    },
    [SecondaryViewType.LIST]: {
      query: rooftopLeadForwardingCondensedListQuery,
      useQueryOverride: useRooftopLeadForwardListQuery,
      alwaysShowCreateButton: true,
      renderData: RooftopLeadForwardListItem,
      noResultsPlaceholderLabels: {
        labelTranslationKey: 'no_lead_forwarding_other',
        subLabelTranslationKey: 'no_lead_forwarding_message_other',
      },
      createBuilder: (seededData: { rooftop: RooftopResponseType }) => ({
        tierId: CreateModifyTiers.TIER_0,
        type: BuilderType.ROOFTOP_LEAD_FORWARD_CREATE,
        title: t('create_lead_forwarding'),
        isCreating: true,
        seededData: seededData?.rooftop
          ? {
              rooftopName: { id: seededData.rooftop.id, name: seededData.rooftop.name },
            }
          : undefined,
      }),
    },
  },
};

// Settings to render nested details
export const NestedViewDetailsSettings: NestedViewDetailsConfigType = {
  [EntityType.LEAD]: itemData => ({
    title: getLeadDetailsTitle(itemData),
    base: RoutePath.LEADS,
    queryVars: {
      sort: {
        id: 'updated',
        sortDirection: SortDirection.DESCENDING,
      },
    },
  }),
  [EntityType.APPOINTMENT]: itemData => ({
    title: getAppointmentDetailsTitle(itemData),
    base: RoutePath.APPOINTMENTS,
    queryVars: {
      filter: {
        date: { gte: getFormattedDateTimeString(getDateTime()) },
      },
      sort: [
        {
          id: 'dateFrom',
          sortDirection: SortDirection.ASCENDING,
        },
      ],
    },
  }),
  [EntityType.TASK]: itemData => ({
    title: getTaskDetailsTitle(itemData),
    base: RoutePath.TASKS,
    queryVars: {
      filter: {
        status: [TaskStatus.NOT_STARTED, TaskStatus.IN_PROGRESS],
      },
      sort: [
        {
          id: 'updated',
          sortDirection: SortDirection.DESCENDING,
        },
      ],
    },
  }),
  [EntityType.RETAIL_ITEM]: itemData => ({
    title: getRetailItemDetailsTitle(itemData),
    base: RoutePath.RETAIL_ITEMS,
    queryVars: {
      filter: {
        type: InventoryItemType.VEHICLE,
        status: [RetailItemStatus.FOR_SALE],
      },
      sort: [
        {
          id: 'updated',
          sortDirection: SortDirection.DESCENDING,
        },
      ],
    },
  }),
  [EntityType.TRADE_IN_ITEM]: itemData => ({
    title: getTradeInItemDetailsTitle(itemData),
    base: RoutePath.TRADE_IN_ITEMS,
    queryVars: {
      filter: {
        type: InventoryItemType.VEHICLE,
      },
      sort: [
        {
          id: 'updated',
          sortDirection: SortDirection.DESCENDING,
        },
      ],
    },
  }),
  [CustomEntity.USER]: itemData => ({
    title: getUserDetailsTitle(itemData),
    base: RoutePath.USERS,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP]: itemData => ({
    title: getRooftopDetailsTitle(itemData),
    base: RoutePath.ROOFTOPS,
    queryVars: {
      // TODO: TBD
    },
  }),
  [EntityType.LEAD_ACTIVITY]: LeadActivityNestedViewSettings[NestedViewConfigType.DetailsView].defaultConfig,
  [LeadActivityType.PHONE_CALL]: LeadActivityNestedViewSettings[NestedViewConfigType.DetailsView].defaultConfig,
  [LeadActivityType.WALK_IN]: LeadActivityNestedViewSettings[NestedViewConfigType.DetailsView].defaultConfig,
  [LeadActivityType.TEST_DRIVE]: LeadActivityNestedViewSettings[NestedViewConfigType.DetailsView].defaultConfig,
  [EntityType.CONVERSATION]: itemData => ({
    title: getConversationDetailsTitle(itemData),
    base: RoutePath.CONVERSATIONS,
    queryVars: {
      // TODO: TBD
    },
  }),
  [EntityType.LEAD_FORWARD]: itemData => ({
    title: itemData?.email,
    queryVars: {},
  }),
  [EntityType.MAILBOX]: (itemData: MailboxResponseType) => ({
    title: itemData?.name,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP_INTEGRATIONS]: (
    itemData: RooftopIntegrationResponseType & RooftopIntegrationContainerItemFragment
  ) => ({
    title: itemData.title?.value,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP_BULK_ADJUSTMENT]: (itemData: RooftopRetailBulkAdjustmentResponseType) => ({
    title: itemData.name,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.RETAIL_BULK_ADJUSTMENT]: (itemData: RooftopRetailBulkAdjustmentResponseType) => ({
    title: itemData.name,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP_CONTACTS]: (itemData: ContactResponseType) => ({
    title: itemData.displayName,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP_TAGS]: (itemData: TagResponseType) => ({
    title: itemData.name?.value,
    queryVars: {
      // TODO: TBD
    },
  }),
  [CustomEntity.ROOFTOP_PRICING_SEGMENTS]: (itemData: RooftopRetailPricingFragment) => ({
    title: `${itemData.name} (${itemData.paymentOptionName})`,
    queryVars: {},
  }),
  [CustomEntity.ROOFTOP_SHOPPING_TOOLS]: (itemData: ShoppingToolsDetailsQuery['item']) => ({
    title: getShoppingToolsTitle({
      isBuildAndPriceFeatureEnabled: isFeatureEnabledForRooftop({
        rooftop: itemData,
        feature: FeatureBundleSet.RETAIL_BUILD_AND_PRICE,
        // We can assume feature flag is turned on here, otherwise user would not be able to view the details page
        featureFlagOn: true,
      }),
      isShowroomFeatureEnabled: isFeatureEnabledForRooftop({
        rooftop: itemData,
        feature: FeatureBundleSet.RETAIL_SHOWROOM,
        // We can assume feature flag is turned on here, otherwise user would not be able to view the details page
        featureFlagOn: true,
      }),
    }),
    queryVars: {},
  }),
  [CustomEntity.ROOFTOP_DEPARTMENTS]: itemData => ({
    title: itemData.typeName,
    queryVars: {},
  }),
};

// Query vars to render list views
export const NestedViewListSettings: {
  [key: string]: NestedViewListConfigType;
} = {
  [EntityType.LEAD]: {
    [EntityType.TRADE_IN_ITEM]: id => ({
      filter: {
        leadId: id,
        status: Object.values(TradeInItemStatus),
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.RETAIL_ITEM]: id => ({
      filter: {
        leadId: id,
        status: Object.values(RetailItemStatus),
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.TASK]: id => ({
      filter: {
        leadId: id,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.APPOINTMENT]: id => ({
      filter: {
        leadId: id,
        date: { gte: getFormattedDateTimeString(getDateTime()) },
      },
      sort: [{ id: 'dateFrom', sortDirection: SortDirection.ASCENDING }],
    }),
    [EntityType.LEAD_ACTIVITY]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig({ leadId: id }),
    [LeadActivityType.PHONE_CALL]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { leadId: id },
        LeadActivityType.PHONE_CALL
      ),
    [LeadActivityType.WALK_IN]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { leadId: id },
        LeadActivityType.WALK_IN
      ),
    [LeadActivityType.TEST_DRIVE]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { leadId: id },
        LeadActivityType.TEST_DRIVE
      ),
    [EntityType.CONVERSATION]: id => ({
      filter: {
        leadId: id,
      },
    }),
  },
  [EntityType.TRADE_IN_ITEM]: {
    [EntityType.APPOINTMENT]: id => ({
      filter: {
        inventoryItemId: id,
        date: { gte: getFormattedDateTimeString(getDateTime()) },
      },
      sort: [{ id: 'dateFrom', sortDirection: SortDirection.ASCENDING }],
    }),
    [EntityType.TASK]: id => ({
      filter: {
        inventoryItemId: id,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
  },
  [EntityType.RETAIL_ITEM]: {
    // TODO: Leads / incoming leadActivities / conversations
    [EntityType.APPOINTMENT]: id => ({
      filter: {
        inventoryItemId: id,
        date: { gte: getFormattedDateTimeString(getDateTime()) },
      },
      sort: [{ id: 'dateFrom', sortDirection: SortDirection.ASCENDING }],
    }),
    [EntityType.TASK]: id => ({
      filter: {
        inventoryItemId: id,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.LEAD]: id => ({
      filter: {
        retailItemOfInterestId: id,
      },
    }),
    [EntityType.LEAD_ACTIVITY]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig({ inventoryItemId: id }),
    [LeadActivityType.PHONE_CALL]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { inventoryItemId: id },
        LeadActivityType.PHONE_CALL
      ),
    [LeadActivityType.WALK_IN]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { inventoryItemId: id },
        LeadActivityType.WALK_IN
      ),
    [LeadActivityType.TEST_DRIVE]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { inventoryItemId: id },
        LeadActivityType.TEST_DRIVE
      ),
    [EntityType.CONVERSATION]: id => ({
      filter: {
        inventoryItemId: id,
      },
    }),
  },
  [CustomEntity.USER]: {
    [EntityType.APPOINTMENT]: id => ({
      filter: {
        assignedToId: id,
        date: { gte: getFormattedDateTimeString(getDateTime()) },
      },
      sort: [{ id: 'dateFrom', sortDirection: SortDirection.ASCENDING }],
    }),
    [EntityType.TASK]: id => ({
      filter: {
        assignedToId: id,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.LEAD]: id => ({
      filter: {
        assignedToId: id,
      },
    }),
    [EntityType.LEAD_ACTIVITY]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig({ assignedToId: id }),
    [LeadActivityType.PHONE_CALL]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { assignedToId: id },
        LeadActivityType.PHONE_CALL
      ),
    [LeadActivityType.WALK_IN]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { assignedToId: id },
        LeadActivityType.WALK_IN
      ),
    [LeadActivityType.TEST_DRIVE]: id =>
      LeadActivityNestedViewSettings[NestedViewConfigType.ListView].defaultConfig(
        { assignedToId: id },
        LeadActivityType.TEST_DRIVE
      ),
    [EntityType.CONVERSATION]: id => ({
      filter: {
        participantId: [id],
      },
    }),
  },
  [EntityType.ROOFTOP]: {
    [EntityType.RETAIL_ITEM]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
        status: RetailItemStatus.FOR_SALE,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.TRADE_IN_ITEM]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.LEAD]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.LEAD_ACTIVITY]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
      },
    }),
    [EntityType.CONVERSATION]: id => ({
      filter: {
        rooftopId: [id],
      },
    }),
    [EntityType.TASK]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
      },
      sort: [{ id: 'updated', sortDirection: SortDirection.DESCENDING }],
    }),
    [EntityType.MAILBOX]: id => ({
      filter: {
        rooftopId: [id],
      },
    }),
    [EntityType.LEAD_FORWARD]: id => ({
      id,
    }),
    [CustomEntity.ROOFTOP_INTEGRATIONS]: id => ({
      filter: {
        rooftopId: [id],
      },
    }),
    [CustomEntity.ROOFTOP_BULK_ADJUSTMENT]: id => ({
      filter: {
        rooftopId: [id],
      },
    }),
    [CustomEntity.ROOFTOP_CONTACTS]: id => ({
      filter: {
        rooftopId: [id],
        archived: false,
      },
    }),
    [CustomEntity.ROOFTOP_PRICING_SEGMENTS]: id => ({
      filter: { rooftopId: id },
    }),
    [CustomEntity.ROOFTOP_TAGS]: id => ({
      rooftopId: id,
    }),
    [CustomEntity.ROOFTOP_DEPARTMENTS]: id => ({
      id,
    }),
    [CustomEntity.USER]: id => ({
      filter: {
        rooftopId: [id],
      },
      sort: [
        { id: 'active', sortDirection: SortDirection.DESCENDING },
        { id: 'updated', sortDirection: SortDirection.DESCENDING },
      ],
    }),
  },
};
