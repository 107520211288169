import { StepLegendNav, StepLegendTooltip } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import RooftopIntegrationQueryMethods from 'components/sections/createModify/rooftopIntegration/RooftopIntegrationQueryMethods';
import { RooftopIntegrationBuilderStep } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

import {
  rooftopIntegrationOemAccountExportCreate,
  rooftopIntegrationOemAccountExportModify,
} from './OemAccountExportCreateModifyQueries';
import OemAccountExportQueryMethods from './OemAccountExportQueryMethods';
import DetailFields from './steps/DetailsFields';
import DetailsStep from './steps/DetailsStep';
import URLFields from './steps/URLFields';
import URLStep from './steps/URLStep';

const OemAccountExportBuilders: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_CREATE]: {
    refetchQueries: RooftopIntegrationQueryMethods.refetchQueries,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        fields: DetailFields,
        isEnabled: true,
      },
      {
        id: RooftopIntegrationBuilderStep.URLS,
        step: URLStep,
        stepName: 'url_other',
        fields: URLFields,
        isEnabled: false,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      create: rooftopIntegrationOemAccountExportCreate,
      modify: rooftopIntegrationOemAccountExportModify,
    },
  },
  [BuilderType.ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_MODIFY]: {
    refetchQueries: RooftopIntegrationQueryMethods.refetchQueries,
    getMetaData: OemAccountExportQueryMethods.getMetaData,
    getData: RooftopIntegrationQueryMethods.getData,
    requiredPermissions: [{ resource: ResourceType.ROOFTOPS, level: AccessLevel.FULL }],
    resourceType: ResourceType.ROOFTOPS,
    steps: [
      {
        id: RooftopIntegrationBuilderStep.DETAILS,
        step: DetailsStep,
        stepName: 'details',
        fields: DetailFields,
        isEnabled: true,
      },
      {
        id: RooftopIntegrationBuilderStep.URLS,
        step: URLStep,
        stepName: 'url_other',
        fields: URLFields,
        isEnabled: true,
      },
    ],
    legend: [StepLegendNav, StepLegendTooltip],
    mutations: {
      modify: rooftopIntegrationOemAccountExportModify,
    },
  },
};

export default OemAccountExportBuilders;
