import { get, isNil } from 'lodash-es';

import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import { RetailItemPricingBuilderStep } from 'components/sections/createModify/retailItemPricing/enums/RetailItemPricingBuilder';
import type { Incentive, RetailItem, RetailPriceAdjustment } from 'store/api/graph/interfaces/types';
import { PaymentOption, RetailPriceAdjustmentType } from 'store/api/graph/interfaces/types';
import { getRetailItemsPricingPropertyPath } from 'utils/formatting/pricingUtils';

// Ways in which Incentives can be grouped in the selection list
export enum IncentiveListGroups {
  // Targeted incentives have an eligibility requirement (ex 'Student Discount' requires person to be a student)
  TARGETED = 'TARGETED',
  // Available incentives have no eligibility requirements
  AVAILABLE = 'AVAILABLE',
}

// Given a PaymentOption and retail item data, get the associated pricing data for that PaymentOption
export const getPricingData = (retailItem: RetailItem, paymentOption: PaymentOption) =>
  get(retailItem, getRetailItemsPricingPropertyPath(paymentOption));

// Given the current builder step, get the associated PaymentOption
export const getBuilderStepPaymentOption = (builderStep: RetailItemPricingBuilderStep) => {
  const builderStepPayment = {
    [RetailItemPricingBuilderStep.CASH]: PaymentOption.CASH,
    [RetailItemPricingBuilderStep.FINANCE]: PaymentOption.FINANCE,
    [RetailItemPricingBuilderStep.LEASE]: PaymentOption.LEASE,
  } as const;

  return builderStepPayment[builderStep];
};

// Given a list of price adjustments, turn this list into a compatible StepFieldOptions list to be used in the builder
export const getPriceAdjustmentOptions = (priceAdjustments: RetailPriceAdjustment[]): StepFieldOptions[] =>
  priceAdjustments.map(adjustment => ({
    ...adjustment,
    name: adjustment.label,
    disabled: adjustment.type === RetailPriceAdjustmentType.AUTO,
  })) as StepFieldOptions[];

/**
 * Given a retail item, get the list of all available incentive options for the given builder step.
 * @param retailItem - The retail item data
 * @param builderStep - The current builder step
 */
export const getIncentiveAdjustmentOptions = (
  retailItem: RetailItem,
  builderStep: RetailItemPricingBuilderStep
): StepFieldOptions[] => {
  const pricingInfo = getPricingData(retailItem, getBuilderStepPaymentOption(builderStep));
  if (!pricingInfo) {
    return [];
  }

  return pricingInfo.incentives.map(({ __typename, ...incentive }) => ({
    ...incentive,
    name: incentive.label,
    category: isNil(incentive.requirements) ? IncentiveListGroups.AVAILABLE : IncentiveListGroups.TARGETED,
    // Targeted incentives are read-only, meaning they can't explicitly be selected
    isReadOnly: !isNil(incentive.requirements),
  }));
};

/**
 * Given an Incentive item, and a list of currently selected incentives, determine which incentives in that list are
 * incompatible with the provided incentive item.
 * @param incentive - The incentive item
 * @param selectedIncentives - The list of all currently selected incentives
 */
export const getIncompatibleIncentives = (incentive: Incentive, selectedIncentives: Incentive[]): Incentive[] =>
  selectedIncentives.filter(
    selectedIncentive =>
      selectedIncentive.id !== incentive.id &&
      selectedIncentive.requirements === null &&
      !selectedIncentive.stackableIncentiveIds.includes(incentive.id)
  );
