import { get } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RooftopFinanceSegmentDetailsBuilderFields } from 'components/sections/createModify/rooftopPricingSegments/financePricing/steps/interfaces';
import {
  getVariablePresets,
  prepareForAutoPriceAdjustmentClearing,
  toggleAutoPriceAdjustmentField,
} from 'components/sections/createModify/rooftopPricingSegments/utils';
import type { MultilingualToggleInputSettings } from 'components/ui/forms/shared/MultilingualToggleInput';
import { RooftopPricingSegment } from 'enums/columns/rooftopPricingSegments';
import {
  type FinanceRetailPricingConfiguration,
  type PaymentFrequency,
  PaymentOption,
  type RooftopDetailsContainerQuery,
  type RooftopFinancePricingSegmentMetaQuery,
  type RooftopRetailFinancePricingDetailQuery,
  type RooftopRetailFinancePricingModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { getDefaultDisclaimerTemplate, normalizeAndFilterDisclaimerTemplates } from 'utils/disclaimerTemplateUtils';
import {
  defineFieldValues,
  getStepField,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';

class DetailsStep extends StepComponentCore<
  RooftopRetailFinancePricingDetailQuery['item'],
  RooftopFinancePricingSegmentMetaQuery['metadata'],
  RooftopRetailFinancePricingModifyMutationVariables
> {
  constructor(
    props: StepComponentProps<
      RooftopRetailFinancePricingDetailQuery['item'],
      RooftopFinancePricingSegmentMetaQuery['metadata'],
      RooftopRetailFinancePricingModifyMutationVariables
    >
  ) {
    super(props);
    const {
      tier: { isCreating, data: savedData, formData, activeStep, metadata, seededData },
    } = props;

    // The list of available term options and payment frequencies come from metadata
    const {
      rooftopRetailPricing: {
        financeConfiguration: { availablePaymentFrequencies: availablePaymentFrequencyOptions },
      },
    } = metadata.mutation;

    const data = Object.keys(savedData || {})?.length ? savedData : formData;
    const configurationData = (get(savedData, RooftopPricingSegment.CONFIGURATION) ||
      formData?.financeConfiguration) as FinanceRetailPricingConfiguration;

    const rooftopName: RooftopDetailsContainerQuery['item'] = isCreating ? seededData?.rooftopName : data?.rooftopName;

    const rooftopOemProgram = rooftopName.bundle.features.oem || undefined;
    const disclaimerTemplates = normalizeAndFilterDisclaimerTemplates(
      metadata.mutation.rooftopRetailPricing.financeConfiguration.disclaimerTemplate,
      rooftopOemProgram
    );

    const defaultDisclaimer = rooftopName?.regionCode
      ? getDefaultDisclaimerTemplate(disclaimerTemplates, rooftopName.regionCode.value, rooftopOemProgram)
      : undefined;

    // The default payment frequency options come from the current list of available payment frequencies.
    const defaultPaymentFrequencyOptions = availablePaymentFrequencyOptions.filter(option =>
      configurationData?.availablePaymentFrequencies?.includes(option.id as PaymentFrequency)
    );

    const selectedPaymentFrequency = availablePaymentFrequencyOptions.filter(option =>
      configurationData?.availablePaymentFrequencies?.includes(option.id as PaymentFrequency)
    );

    const hasAutoPriceAdjustment = !!data?.configuration?.autoPriceAdjustmentConfiguration;

    // Converting to readable fields and setting presets
    const fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_ENABLED]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_ENABLED, true),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_INCLUDE_TAX]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_INCLUDE_TAX, false),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES]: {
        selectedValue: selectedPaymentFrequency.length > 0 ? selectedPaymentFrequency : null,
        options: availablePaymentFrequencyOptions,
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY]: {
        selectedValue: availablePaymentFrequencyOptions.find(
          option => option.id === configurationData?.defaultPaymentFrequency
        ),
        options: defaultPaymentFrequencyOptions,
        displayType: setDisplayTypes({
          type: StepFieldDisplayType.DISABLED,
          active: !defaultPaymentFrequencyOptions?.length,
        }),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_STARTING_PRICE_FIELD]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_STARTING_PRICE_FIELD),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_DISCLAIMER_TEMPLATE]: {
        selectedValue: get(data, RooftopPricingSegment.CONFIGURATION_DISCLAIMER) || defaultDisclaimer,
        settings: {
          insertOptions: disclaimerTemplates,
        } as MultilingualToggleInputSettings,
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_INCLUDE_FREIGHT]: {
        selectedValue: configurationData?.includeFreight ?? false,
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_MIN_DISPLAY_PRICE]: {
        selectedValue: data?.configuration?.minDisplayPrice?.amount,
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED]: {
        selectedValue: !!hasAutoPriceAdjustment,
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_TARGET_PRICE]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.targetPriceField,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_LABEL]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.label,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
      [RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_DISCLAIMER_TEMPLATE]: {
        selectedValue: data?.configuration?.autoPriceAdjustmentConfiguration?.disclaimerTemplate,
        displayType: setDisplayTypes([
          {
            type: StepFieldDisplayType.HIDDEN,
            active: isCreating || !hasAutoPriceAdjustment,
          },
          {
            type: StepFieldDisplayType.OMITTED,
            active: isCreating || !hasAutoPriceAdjustment,
          },
        ]),
      },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(fields, data);
  }

  onFieldSelection(stepField: StepField, value: any) {
    /**
     * Whenever the list of available payment frequencies change, we must update the list of options that the user
     * can select for the default payment frequency. Same goes for available terms.
     */
    if (
      stepField.queryVar ===
      RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AVAILABLE_PAYMENT_FREQUENCIES
    ) {
      const defaultPaymentFrequencyField = getStepField(
        RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_DEFAULT_PAYMENT_FREQUENCY,
        this.fields
      );
      defaultPaymentFrequencyField.options = value;

      setDisplayTypes({ type: StepFieldDisplayType.DISABLED, active: !value?.length }, defaultPaymentFrequencyField);

      // If the default payment frequency is no longer in the available payment frequencies, then clear the field
      if (!value.some(item => item.id === defaultPaymentFrequencyField.selectedValue?.id)) {
        defaultPaymentFrequencyField.selectedValue = null;
      }
    }

    super.onFieldSelection(stepField, value);
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    if (
      stepField.queryVar ===
      RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED
    ) {
      toggleAutoPriceAdjustmentField(PaymentOption.FINANCE, this.fields, Boolean(e.currentTarget.value));
    }

    super.onFieldChange(stepField, e);
  }

  async save() {
    const {
      tier: { seededData, steps, isCreating },
    } = this.props;

    const variablePresets = isCreating ? getVariablePresets(seededData?.rooftopName?.id) : undefined;

    if (!isCreating) {
      prepareForAutoPriceAdjustmentClearing(
        getStepField<boolean | null>(
          RooftopFinanceSegmentDetailsBuilderFields.FINANCE_CONFIGURATION_AUTO_PRICE_ADJUSTMENT_ENABLED,
          this.fields
        )
      );
    }

    const result = await super.save(variablePresets);

    if (result) {
      // Enabling other steps for edit/navigation
      for (const step of steps!) {
        step.isEnabled = true;
      }
    }

    return result;
  }
}

export default DetailsStep;
