import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import DatadogLogger from 'components/core/logging/DatadogLogger';
import { UserContext } from 'contexts/UserContext';
import type { ImpersonatingUser, Notifications } from 'contexts/UserContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { CustomAccessLevel } from 'enums/extendedAccessLevel';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useRouter } from 'hooks/useRouter';
import { useWindowUnloadEffect } from 'hooks/useWindowUnloadEffect';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import { AccessLevel } from 'store/api/graph/interfaces/types';
import type { UserType } from 'store/api/graph/responses/responseTypes';
import { client } from 'store/apollo/ApolloClient';
import type { RequiredPermissions } from 'types/Permissions';
import { createLaunchDarklyContext } from 'utils/featureFlagUtils';
import { impersonationManager } from 'utils/impersonationUtils';
import type { Locale } from 'utils/intlUtils';
import { setLocale } from 'utils/intlUtils';
import { hasWhiteLabelScopedAccess } from 'utils/permissionUtils';

const userNotificationsQuery = gql`
  query UserNotificationsQuery {
    user {
      id
      hasIncomingLeadActivities
      hasUnreadConversations
    }
  }
`;

/**
 * The higher the integer the higher the access level.
 * A user that has a "higher-level" access to a resource automatically inherits all the "lower-level" access levels.
 */
export const LevelPriority = {
  [AccessLevel.BASIC]: 0,
  [AccessLevel.ADVANCED]: 1,
  [AccessLevel.FULL]: 2,
  [CustomAccessLevel.NO_ACCESS]: 9,
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const ldClient = useLDClient();
  const { flags: featureFlags } = useFeatureFlags();
  const [user, setUser] = useState<UserType>({} as UserType);
  const [userNotifications, setUserNotifications] = useState<Notifications>({
    hasIncomingLeadActivities: !!user?.hasIncomingLeadActivities,
    hasUnreadConversations: !!user?.hasUnreadConversations,
  });
  const [lastLogin, setLastLogin] = useState<number | undefined>();

  if (user.locale?.languageTag) {
    setLocale(user.locale.languageTag as Locale);
  }
  const [impersonationError, setImpersonationError] = useState<string>();

  const { redirectBackToSectionPath } = useRouter();

  const impersonateAsUser = useCallback(
    (userToImpersonate: ImpersonatingUser | undefined) => {
      if (userToImpersonate) {
        void impersonationManager.tryLoginAs(userToImpersonate.id, user).then(result => {
          // On success, refresh the page to have it reflect the current user's permissions
          if (result.success) {
            redirectBackToSectionPath();
          } else {
            setImpersonationError(result.errorMessage);
          }
        });
      } else {
        /**
         * If the user to impersonate is undefined, end impersonation and
         * refresh the page to have it reflect the current user's permissions
         */
        impersonationManager.endImpersonation();
        redirectBackToSectionPath();
      }
    },
    [redirectBackToSectionPath, user]
  );

  /**
   * Whether or not a user has all of the requested permissions based off of id.
   *
   * More info:
   * https://edealer-wiki.netlify.com/documentation/permissions.html#read-write-permissions
   */
  const hasPermissions = useCallback(
    (permissions: RequiredPermissions): boolean =>
      !permissions ||
      permissions.every(
        ({ resource: requiredResource, level: requiredLevel }) =>
          !!user?.permissions?.find(
            ({ resource: userResource, level: userLevel }) =>
              userResource === requiredResource && LevelPriority[userLevel] >= LevelPriority[requiredLevel]
          )
      ),
    [user]
  );

  /**
   * Async callback that requests the user notifications status from the API
   */
  const updateUserNotifications = useCallback(async () => {
    try {
      const {
        data: {
          user: { hasIncomingLeadActivities, hasUnreadConversations },
        },
      } = await client.query({
        query: userNotificationsQuery,
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      });

      setUserNotifications({ hasIncomingLeadActivities, hasUnreadConversations });
      return true;
    } catch (error) {
      logApiError(error, 'Unable to update user notifications status');
      return false;
    }
  }, [setUserNotifications]);

  // Users with white label scope or higher. e.g. Such users can grant all permissions.
  const isWhiteLabelScoped = hasWhiteLabelScopedAccess(user.scope);

  // Admin and whitelabel scope users will have null for rooftops, which means they can access all rooftops
  const canAccessMultipleRooftops = user?.rooftops === null || (user?.rooftops?.length as number) > 1;

  useWindowUnloadEffect(() => {
    // Manually stop session e.g. logout, window close, user change etc.
    DatadogLogger.stopSessionReplayRecording();

    // Shuts down client, release resources and deliver pending anaytics
    void ldClient?.close();
  }, true);

  useEffect(() => {
    /**
     * To save costs we enable session replay on an as needed basis, based on `userId`.
     * Should always be false unless we need to debug an issue further.
     */
    const isNotAnonymousUser = !!user?.id;
    if (featureFlags.sessionReplayEnabled === true && isNotAnonymousUser) {
      DatadogLogger.startSessionReplayRecording();
    }
  }, [featureFlags, user]);

  return (
    <UserContext.Provider
      value={{
        canAccessMultipleRooftops,
        clearImpersonationError: useCallback(() => setImpersonationError(undefined), []),
        featureFlags,
        hasPermissions,
        impersonateAsUser,
        impersonationError,
        isWhiteLabelScoped,
        lastLogin,
        setLastLogin,
        setUser: useCallback(
          (user: UserType) => {
            void ldClient?.identify(createLaunchDarklyContext(user));
            setUser(user);
            setUserNotifications({
              hasIncomingLeadActivities: !!user.hasIncomingLeadActivities,
              hasUnreadConversations: !!user.hasUnreadConversations,
            });
          },
          [setUser, setUserNotifications, ldClient]
        ),
        setUserNotifications,
        updateUserNotifications,
        user,
        userNotifications,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
