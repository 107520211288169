import { memo, type SVGProps } from 'react';

const BellRingingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M4.934 19.203a1.1 1.1 0 0 1-.808-.329 1.1 1.1 0 0 1-.33-.808c0-.32.11-.589.33-.808.22-.22.49-.33.808-.33h.791v-6.796c0-1.436.428-2.716 1.283-3.84.855-1.125 1.973-1.86 3.355-2.205v-.653c0-.456.158-.843.476-1.161A1.58 1.58 0 0 1 12 1.797c.457 0 .844.159 1.161.476.318.318.476.705.476 1.161v.652c1.386.346 2.505 1.08 3.358 2.202.853 1.123 1.28 2.404 1.28 3.844v6.796h.79c.32 0 .59.11.809.33.22.22.33.489.33.808 0 .319-.11.588-.33.808a1.1 1.1 0 0 1-.808.33H4.934Zm7.072 3.066a2 2 0 0 1-1.463-.605 1.985 1.985 0 0 1-.609-1.46h4.138a1.99 1.99 0 0 1-.606 1.46 1.99 1.99 0 0 1-1.46.605ZM2.689 10.138c-.315 0-.578-.119-.787-.355a1.007 1.007 0 0 1-.259-.822A10.072 10.072 0 0 1 2.73 5.384a10.59 10.59 0 0 1 2.217-2.897c.233-.207.503-.299.812-.276a.95.95 0 0 1 .738.42c.182.257.254.535.214.833-.039.299-.175.558-.407.778a8.02 8.02 0 0 0-1.594 2.156 8.447 8.447 0 0 0-.831 2.65 1.268 1.268 0 0 1-.395.775c-.222.21-.487.314-.794.314Zm18.622 0c-.307 0-.572-.105-.794-.315a1.268 1.268 0 0 1-.395-.775 8.445 8.445 0 0 0-.83-2.65 8.018 8.018 0 0 0-1.595-2.156 1.274 1.274 0 0 1-.407-.778c-.04-.298.032-.576.214-.833a.951.951 0 0 1 .737-.42c.31-.023.58.07.813.276.9.828 1.638 1.794 2.217 2.897.578 1.103.94 2.295 1.085 3.577.038.311-.048.585-.258.822-.21.236-.472.354-.787.354Z"
    />
  </svg>
);
export default memo(BellRingingIcon);
