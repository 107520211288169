import type { SubStepAddConfig } from 'components/core/createModify/interfaces/stepField';
import { seedContactStep } from 'components/sections/createModify/contacts/steps/DetailsStep';
import { seedLeadStepData } from 'components/sections/createModify/leads/steps/DetailsStep';
import { seedMMSTStep } from 'components/sections/createModify/MMST/steps/MMSTStep';
import { seedPriceAdjustmentStep } from 'components/sections/createModify/retailItemPriceAdjustment/steps/RetailItemPriceAdjustmentStep';
import { seedAvailableMileageStep } from 'components/sections/createModify/rooftopPricingSegments/leasePricing/steps/AvailableMileageAllowanceStep';
import { seedTagStepData } from 'components/sections/createModify/tags/steps/TagStep';
import { YMMTTarget } from 'components/sections/shared/ItemMetaHelpers';
import { BuilderType } from 'enums/builderType';
import { EntityType } from 'store/api/graph/interfaces/types';
import { whiteLabelScopes } from 'utils/permissionUtils';

export const SubStepAddConfigs: { [key: string]: SubStepAddConfig } = {
  [BuilderType.TAGS_CREATE]: {
    builderTitle: 'create_tag',
    builderType: BuilderType.TAGS_CREATE,
    dataFields: [{ sources: ['rooftopId'], target: 'rooftopId', sourceProp: 'id' }],
    seedPrefillMethod: seedTagStepData,
  },
  [BuilderType.LEAD_CREATE]: {
    builderTitle: 'create_lead',
    builderType: BuilderType.LEAD_CREATE,
    dataFields: [{ sources: ['rooftop', 'rooftopId', 'rooftopName'], target: 'rooftopId' }],
    seedPrefillMethod: seedLeadStepData,
    entityType: EntityType.LEAD,
  },
  [BuilderType.MAKE_CREATE]: {
    builderTitle: 'create_make',
    builderType: BuilderType.MAKE_CREATE,
    seedPrefillMethod: seedMMSTStep,
    dataFields: [{ sources: ['rooftop', 'rooftopId', 'rooftopName'], sourceProp: 'id', target: 'rooftopId' }],
    defaultSeededData: { field: YMMTTarget.MAKE_ID },
  },
  [BuilderType.MODEL_CREATE]: {
    builderTitle: 'create_model',
    builderType: BuilderType.MODEL_CREATE,
    seedPrefillMethod: seedMMSTStep,
    dataFields: [
      { sources: [YMMTTarget.MAKE_ID], sourceProp: 'id', target: YMMTTarget.MAKE_ID },
      { sources: ['rooftop', 'rooftopId', 'rooftopName'], sourceProp: 'id', target: 'rooftopId' },
    ],
    defaultSeededData: { field: YMMTTarget.MODEL_ID },
  },
  [BuilderType.SUBMODEL_CREATE]: {
    builderTitle: 'create_submodel',
    builderType: BuilderType.SUBMODEL_CREATE,
    seedPrefillMethod: seedMMSTStep,
    dataFields: [
      { sources: [YMMTTarget.MODEL_ID], sourceProp: 'id', target: YMMTTarget.MODEL_ID },
      { sources: ['rooftop', 'rooftopId', 'rooftopName'], sourceProp: 'id', target: 'rooftopId' },
    ],
    defaultSeededData: { field: YMMTTarget.SUB_MODEL_ID },
  },
  [BuilderType.TRIM_CREATE]: {
    builderTitle: 'create_trim',
    builderType: BuilderType.TRIM_CREATE,
    seedPrefillMethod: seedMMSTStep,
    dataFields: [
      { sources: [YMMTTarget.SUB_MODEL_ID], sourceProp: 'id', target: YMMTTarget.SUB_MODEL_ID },
      { sources: ['rooftop', 'rooftopId', 'rooftopName'], sourceProp: 'id', target: 'rooftopId' },
      { sources: ['year'], target: 'year' },
    ],
    defaultSeededData: { field: YMMTTarget.TRIM_ID },
  },
  [BuilderType.GROUP_CREATE]: {
    builderTitle: 'create_group',
    builderType: BuilderType.GROUP_CREATE,
    allowedScopes: whiteLabelScopes,
  },
  [BuilderType.CONTACT_CREATE]: {
    builderTitle: 'create_contact',
    builderType: BuilderType.CONTACT_CREATE,
    seedPrefillMethod: seedContactStep,
  },
  [BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_CREATE]: {
    builderTitle: 'create_adjustment',
    builderType: BuilderType.RETAIL_ITEM_PRICE_ADJUSTMENT_CREATE,
    seedPrefillMethod: seedPriceAdjustmentStep,
  },
  [BuilderType.ROOFTOP_LEASE_SEGMENT_AVAILABLE_MILEAGE_ALLOWANCE_CREATE]: {
    builderTitle: 'add_mileage_allowance',
    builderType: BuilderType.ROOFTOP_LEASE_SEGMENT_AVAILABLE_MILEAGE_ALLOWANCE_CREATE,
    seedPrefillMethod: seedAvailableMileageStep,
  },
};
