import { cloneDeep, set } from 'lodash-es';

import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import StepComponentCore, { defaultState } from 'components/core/createModify/stepFields/StepComponentCore';
import type { getWebsiteSortOptionsFromMetadata } from 'components/sections/shared/ItemMetaHelpers';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import type { Sort, SortInput } from 'store/api/graph/interfaces/types';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';
import {
  addDisplayType,
  getStepField,
  removeDisplayType,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import type { SortOption } from './interfaces';
import { COLUMN_ID_QUERY_VAR, CUSTOM_ORDER_QUERY_VAR, InitialItemSortingBuilderFields } from './interfaces';

const { t } = translate;

class InitialItemSortingStep<
  TData extends Record<string, any> = any,
  TMetaData extends Record<string, any> = any,
  TSavingMutationVariables extends Record<string, any> = any,
> extends StepComponentCore<TData, TMetaData, TSavingMutationVariables> {
  state = {
    ...defaultState,
  };
  metadataSortOptions: ReturnType<typeof getWebsiteSortOptionsFromMetadata> = [];

  initSortFields(initialSortRules?: Sort[]) {
    const canDelete = initialSortRules && initialSortRules?.length > 1;

    return (initialSortRules || [{ columnId: null, customOrder: null }]).reduce<Record<string, StepField>>(
      (acc, sortRule, index) => {
        const queryVarBase = InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING;
        const queryVarWithIndex = `${queryVarBase}[${index}]`;

        acc[`${queryVarWithIndex}.${COLUMN_ID_QUERY_VAR}`] = {
          queryVar: `${queryVarWithIndex}.${COLUMN_ID_QUERY_VAR}`,
          queryAlias: `${queryVarBase}#${index}.${COLUMN_ID_QUERY_VAR}`,
          selectedValue: sortRule.columnId
            ? this.metadataSortOptions.find(
                ({ data: { columnId, direction } }) =>
                  columnId === sortRule.columnId && direction === sortRule.direction
              )
            : null,
          label: t(index > 0 ? 'then_by' : 'sort_by'),
          groupType: StepFieldType.DROPDOWN,
          groupSubTypes: [StepFieldSubType.CHECKBOX],
          subStep: [SubStepType.ASYNC],
          options: this.metadataSortOptions,
          displayType: [StepFieldDisplayType.DISABLED, StepFieldDisplayType.OMITTED],
          hasSeparator: !sortRule.customOrder || sortRule.customOrder.length === 0,
          canDelete,
        };

        if (Array.isArray(sortRule.customOrder)) {
          const unsortedSelectedValue =
            sortRule.customOrder && sortRule.customOrder.length > 0
              ? this.metadataSortOptions
                  .find(({ data: { columnId } }) => columnId === sortRule.columnId)
                  ?.data?.customOrderOptions?.filter(option => sortRule.customOrder?.includes(option.id)) || null
              : null;

          const sortedSelectedValue = unsortedSelectedValue?.sort((a, b) => {
            if (!sortRule || !sortRule.customOrder) {
              return 0;
            }

            return sortRule.customOrder.indexOf(a.id) - sortRule.customOrder.indexOf(b.id);
          });

          acc[`${queryVarWithIndex}.${CUSTOM_ORDER_QUERY_VAR}`] = {
            queryVar: `${queryVarWithIndex}.${CUSTOM_ORDER_QUERY_VAR}`,
            queryAlias: `${queryVarBase}#${index}.${CUSTOM_ORDER_QUERY_VAR}`,
            label: this.metadataSortOptions.find(({ data: { columnId } }) => columnId === sortRule.columnId)?.name,
            groupType: StepFieldType.TAGS,
            groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.CHECKBOX, StepFieldSubType.SORTABLE],
            subStep: [SubStepType.DEFAULT],
            hasSeparator: true,
            options:
              sortRule.customOrder && sortRule.customOrder.length > 0
                ? this.metadataSortOptions.find(({ data: { columnId } }) => columnId === sortRule.columnId)?.data
                    ?.customOrderOptions || undefined
                : undefined,
            displayType: [StepFieldDisplayType.DISABLED, StepFieldDisplayType.OMITTED],
            selectedValue: sortedSelectedValue,
          };
        }

        this.attachColumnIdAsyncConfig(queryVarWithIndex);

        return acc;
      },
      {}
    );
  }

  /** A utility for updating the queryVar/queryAlias of a sortable field with a provided index number */
  reindexSortableField = (field: StepField, index: number) => {
    field.queryVar = field.queryVar.replace(/\d+/, `${index}`);
    field.queryAlias =
      field.queryAlias && typeof field.queryAlias === 'string'
        ? field.queryAlias.replace(/\d+/, `${index}`)
        : field.queryAlias;
  };

  onConfirmDeleteStepField(stepField: StepField<TData, TMetaData, any>): void {
    const fieldsModified = cloneDeep(this.fields);
    const fieldIndex = this.fields.findIndex(f => f.queryVar === stepField.queryVar);

    // If the field that follows is a customOrder, remove it
    const hasAdjacentCustomOrderField = this.fields[fieldIndex + 1].queryVar.includes(CUSTOM_ORDER_QUERY_VAR);
    const deleteCount = hasAdjacentCustomOrderField ? 2 : 1;
    // Remove selected field
    fieldsModified.splice(fieldIndex, deleteCount);

    let sortFieldIndex = 0;

    for (const [index, field] of fieldsModified.entries()) {
      if (new RegExp(COLUMN_ID_QUERY_VAR, 'i').test(field.queryVar)) {
        this.reindexSortableField(field, sortFieldIndex);
        field.label = t(sortFieldIndex > 0 ? 'then_by' : 'sort_by');

        const nextField = fieldsModified[index + 1];

        if (nextField?.queryVar.match(CUSTOM_ORDER_QUERY_VAR)) {
          this.reindexSortableField(nextField, sortFieldIndex);
        }

        sortFieldIndex++;
      }
    }

    this.updateDeleteAvailability(fieldsModified);

    this.fields = fieldsModified;

    // Close open substep upon deletion of sort rule
    void this.toggleSubPanel(undefined);

    this.forceUpdate();
  }

  /**
   * Add the delete icon to the first sort rule, any time there is more than 1 sort rule.
   * Remove the delete icon from the first sort rule when it is the only rule.
   */
  updateDeleteAvailability(fields: StepField[]) {
    const sortFields = fields.filter(({ queryVar }) => queryVar.match(COLUMN_ID_QUERY_VAR));
    const firstField = sortFields?.[0];

    if (firstField) {
      firstField.canDelete = sortFields.length > 1;
    }

    this.updateAddSortLevelDisplayType(fields);
  }

  attachColumnIdAsyncConfig(queryVarWithIndex) {
    if (this.asyncConfigurations?.[InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING]) {
      this.asyncConfigurations[`${queryVarWithIndex}.${COLUMN_ID_QUERY_VAR}`] =
        this.asyncConfigurations?.[InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING];
    }
  }

  toggleSortFields(active: boolean) {
    for (const field of this.fields.filter(
      ({ queryVar }) =>
        queryVar.includes(InitialItemSortingBuilderFields.ADD_SORTING_RULE) ||
        queryVar.includes(InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING) ||
        queryVar.includes(CUSTOM_ORDER_QUERY_VAR)
    )) {
      field.displayType = setDisplayTypes([
        { type: StepFieldDisplayType.DISABLED, active },
        { type: StepFieldDisplayType.OMITTED, active: true },
      ]);
    }
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    super.onFieldChange(stepField, e);

    if (stepField.queryVar === InitialItemSortingBuilderFields.USE_CUSTOM_SORT_RULE) {
      this.toggleSortFields(!stepField.selectedValue);
    }
  }

  /**
   * Update disabled state for the "add sort rule" button based on whether the last sort rule is empty
   */
  updateAddSortLevelDisplayType(fields = this.fields) {
    const sortFields = fields.filter(
      ({ queryVar }) => queryVar.match(COLUMN_ID_QUERY_VAR) || queryVar.match(CUSTOM_ORDER_QUERY_VAR)
    );
    const addButton = getStepField(InitialItemSortingBuilderFields.ADD_SORTING_RULE, fields);

    const lastField = sortFields.at(-1);
    const updateMethod =
      lastField?.selectedValue &&
      (typeof lastField.selectedValue?.id === 'string' ||
        (Array.isArray(lastField.selectedValue) && lastField.selectedValue.length > 0))
        ? removeDisplayType
        : addDisplayType;

    addButton.displayType = updateMethod(addButton, StepFieldDisplayType.DISABLED);
  }

  async onButtonClick(stepField?: StepField<TData, TMetaData>) {
    if (stepField?.queryVar === InitialItemSortingBuilderFields.ADD_SORTING_RULE) {
      const fieldsModified = cloneDeep(this.fields);
      const sortFields = this.fields.filter(({ queryVar }) => queryVar.match(COLUMN_ID_QUERY_VAR));
      const queryVarBase = InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING;
      const queryVarWithIndex = `${queryVarBase}[${sortFields.length}]`;
      const queryVar = `${queryVarWithIndex}.${COLUMN_ID_QUERY_VAR}`;
      const queryAlias = `${queryVarBase}#${sortFields.length}.${COLUMN_ID_QUERY_VAR}`;

      fieldsModified.splice(fieldsModified.length - 1, 0, {
        queryVar,
        queryAlias,
        label: t('then_by'),
        groupType: StepFieldType.DROPDOWN,
        groupSubTypes: [StepFieldSubType.CHECKBOX],
        subStep: [SubStepType.ASYNC],
        options: this.metadataSortOptions,
        displayType: [StepFieldDisplayType.OMITTED],
        hasSeparator: true,
        canDelete: fieldsModified.length > 0,
      });

      this.updateDeleteAvailability(fieldsModified);

      this.fields = fieldsModified;

      this.attachColumnIdAsyncConfig(queryVarWithIndex);

      this.setTier({ activeField: queryVar });

      this.forceUpdate();
    }
  }

  async onFieldSelection(stepField: StepField, value: StepFieldOptions | SortOption) {
    const isColumnIdField = stepField.queryVar.includes(COLUMN_ID_QUERY_VAR);
    const hasCustomOrderOptions =
      !!value.data && 'customOrderOptions' in value.data && Array.isArray(value.data.customOrderOptions);

    // Prevent deselection of current value
    if (isColumnIdField && stepField.selectedValue && stepField.selectedValue?.id === value.id) {
      return;
    }

    super.onFieldSelection(
      // Only auto-advance if this columnId supports customOrderOptions
      { ...stepField, forceAdvance: (isColumnIdField && hasCustomOrderOptions) || stepField?.forceAdvance },
      value
    );

    if (isColumnIdField) {
      const fieldsModified = cloneDeep(this.fields);
      const sortFields = this.fields.filter(({ queryVar }) => queryVar.match(COLUMN_ID_QUERY_VAR));
      const fieldIndex = this.fields.findIndex(({ queryVar }) => queryVar === stepField.queryVar);
      const sortFieldsIndex = sortFields.findIndex(({ queryVar }) => queryVar === stepField.queryVar);

      fieldsModified[fieldIndex].hasSeparator = true;
      // If the field that follows is a customOrder, remove it
      const hasAdjacentCustomOrderField = this.fields[fieldIndex + 1].queryVar.includes(CUSTOM_ORDER_QUERY_VAR);

      if (hasAdjacentCustomOrderField) {
        fieldsModified.splice(fieldIndex + 1, 1);
        this.fields = fieldsModified;
      }

      // If unsetting the current selected value, skip considering whether to add a customOrder field
      if (stepField.selectedValue?.id === value.id) {
        return;
      }

      // Generate a customOrder field dynamically using customOrderOptions from the WebsiteSortOption
      if (hasCustomOrderOptions) {
        const customOrderQueryVar = `${InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING}[${sortFieldsIndex}].${CUSTOM_ORDER_QUERY_VAR}`;

        fieldsModified[fieldIndex].hasSeparator = false;

        fieldsModified.splice(fieldIndex + 1, 0, {
          queryVar: customOrderQueryVar,
          queryAlias: `${InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING}#${sortFieldsIndex}.${CUSTOM_ORDER_QUERY_VAR}`,
          label: typeof value.name === 'string' ? value.name : value.name.value,
          groupType: StepFieldType.TAGS,
          groupSubTypes: [StepFieldSubType.MULTI_SELECT, StepFieldSubType.SORTABLE],
          subStep: [SubStepType.DEFAULT],
          hasSeparator: true,
          options: value.data.customOrderOptions,
          displayType: [StepFieldDisplayType.OMITTED],
        });

        this.fields = fieldsModified;
      }
    }

    this.updateAddSortLevelDisplayType();
  }

  formatInitialItemSortingVariable() {
    const sortFields = this.fields.filter(({ queryVar }) =>
      queryVar.includes(InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING)
    );

    const initSortArg: {
      columnId: SortOption;
      customOrder: SelectOption[];
    }[] = [];

    for (const { queryVar, selectedValue } of sortFields) {
      set(initSortArg, queryVar.replace(InitialItemSortingBuilderFields.INITIAL_ITEM_SORTING, ''), selectedValue);
    }

    const initialItemSorting: SortInput[] = initSortArg
      .filter(({ columnId }) => !!columnId)
      .map(
        ({
          columnId: {
            data: { columnId: id, direction: sortDirection },
          },
          customOrder = [],
        }) => ({
          id,
          customOrder: customOrder.length > 0 ? customOrder.map(({ id }) => String(id)) : null,
          sortDirection,
          nullsLast: true,
        })
      );

    return initialItemSorting;
  }
}

export default InitialItemSortingStep;
