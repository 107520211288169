export enum LeadRoutingOption {
  FOLLOW_VEHICLE_OWNER = 'followVehicleOwner',
  SPECIFIC_ROOFTOP = 'specificRooftop',
}

export enum LeadRoutingBuilderFields {
  VLP_VDP_LEADS = 'vlpVdpLeads',
  VLP_VDP_ROOFTOP_ID = 'vlpVdpRooftopId',
  OTHER_LEADS = 'otherLeads',
  OTHER_ROOFTOP_ID = 'otherRooftopId',
}
