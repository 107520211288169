import { gql } from '@apollo/client';

import { tag } from 'store/api/graph/fragments/tag';

export const rooftopTagCreate = gql`
  mutation RooftopTagCreate($rooftopId: ID, $name: MultilingualStringInput, $type: EntityType) {
    data: tagCreate(rooftopId: $rooftopId, name: $name, type: $type) {
      ...TagFragment
    }
  }
  ${tag}
`;

export const rooftopTagModify = gql`
  mutation RooftopTagModify($id: ID!, $name: MultilingualStringInput, $_clear: [TagModifyParameter!]) {
    data: tagModify(id: $id, name: $name, _clear: $_clear) {
      ...TagFragment
    }
  }
  ${tag}
`;
