import { LinkedSectionHeader } from 'components/ui/details/LinkedSectionHeader';
import { LinkedSection } from 'components/ui/layouts/CardLayout';
import { ListItems } from 'components/ui/layouts/ListItem';
import ConversationListItem from 'components/ui/lists/ConversationListItem';
import ListItemClickable from 'components/ui/lists/ListItemClickable';
import { ListItemType } from 'enums/listItemType';
import { EntityType } from 'store/api/graph/interfaces/types';
import type { ConversationResponseType } from 'store/api/graph/responses/responseTypes';

import type { SectionProps } from './LinkedTab';

interface Props extends SectionProps {
  conversation: ConversationResponseType;
}

const ConversationSection = ({ conversation, isNested, sectionTitle = '' }: Props) => (
  <LinkedSection>
    <LinkedSectionHeader title={sectionTitle} hasItems={!!conversation} />
    <ListItems>
      <ListItemClickable
        item={conversation}
        entity={EntityType.CONVERSATION}
        isNested={isNested}
        renderElement={ConversationListItem}
        renderSettings={{ listItemType: ListItemType.SECTION_LIST }}
      />
    </ListItems>
  </LinkedSection>
);

export default ConversationSection;
