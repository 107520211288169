import { gql } from '@apollo/client';

import { MultilingualString } from 'store/api/graph/fragments/multilingualString';

export const promotionRetailBulkAdjustmentAttributes = gql`
  fragment PromotionRetailBulkAdjustmentAttributesFragment on PromotionRetailBulkAdjustmentAttributes {
    altText {
      ...MultilingualStringFragment
    }
    bannerImageUrl
    bannerUrl
    bannerLinksToVdp
    openLinkInNewTab
    position
    positionName
    showOnVdp
    showOnVlp
  }

  ${MultilingualString}
`;
