import { useContext } from 'react';

import { CreateModifyContext } from 'contexts/CreateModifyContext';

export function useCreateModify() {
  const context = useContext(CreateModifyContext);
  if (context === undefined) {
    throw new Error('useCreateModify must be used within a CreateModifyProvider');
  }
  return context;
}
