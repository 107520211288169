import { useCallback, useRef, useState } from 'react';

import { isNil } from 'lodash-es';
import { useMount } from 'react-use';
import styled from 'styled-components/macro';

import RichTextInput, { type RichTextInputSettings } from 'components/ui/forms/shared/RichTextInput';
import { RichTextClientType, type RichTextEditorContextInterface } from 'contexts/RichTextEditorContext';
import { ApolloFetchPolicy } from 'enums/apollo';
import { ElementTestId } from 'enums/testing';
import type {
  MultilingualString,
  RetailItemGenerateDescriptionQuery,
  RetailItemGenerateDescriptionQueryVariables,
} from 'store/api/graph/interfaces/types';
import { RETAIL_ITEM_GENERATE_DESCRIPTION } from 'store/api/graph/queries/generate';
import { client } from 'store/apollo/ApolloClient';
import type { MultilingualStringValue } from 'utils/intlUtils';
import { getUrlSearchParams } from 'utils/urlUtils';

interface InitEditorCallback {
  (
    /**
     * The selected value of the item.
     */
    selectedValue: MultilingualString | null,
    /**
     * The id of the item.
     */
    id?: string | null,
    /**
     * The auth token of the user.
     */
    authToken?: string
  ): void;
}

const MobileEditorTheme = styled.div`
  > :first-child {
    margin-top: 0;
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    right: 0;
  }
`;

const urlParams = getUrlSearchParams(window.location);

export const MobileRichTextEditorContainer = () => {
  const editorContextRef = useRef<RichTextEditorContextInterface>(null);
  const [authToken, setAuthToken] = useState<string>();
  const [id, setId] = useState<string | null>(null);

  window['initEditor'] = useCallback<InitEditorCallback>((selectedValue, id, authToken) => {
    // Set selected value on the editor
    editorContextRef.current?.setContent(selectedValue);

    setAuthToken(authToken);
    setId(id || null);
  }, []);

  /**
   * TODO: [ED-11554] Remove this method once mobile points to the new callback
   * @deprecated Use `window.initEditor` instead
   */
  window['setRichTextEditorValue'] = useCallback((currentMultiLingualString: MultilingualString | null) => {
    editorContextRef.current?.setContent(currentMultiLingualString);
  }, []);

  window['getRichTextEditorValue'] = useCallback(() => editorContextRef.current?.getContent(), []);

  useMount(() => {
    editorContextRef.current?.setClientType(RichTextClientType.MOBILE);
  });

  /**
   * Handles the click event for the text generation button.
   */
  const handleOnGenerateText = useCallback(async () => {
    if (!authToken || !id) {
      return '';
    }

    const { data } = await client.query<
      RetailItemGenerateDescriptionQuery,
      RetailItemGenerateDescriptionQueryVariables
    >({
      query: RETAIL_ITEM_GENERATE_DESCRIPTION,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.NO_CACHE,
      context: { authToken },
    });

    return data.retailItemGenerateDescription;
  }, [id, authToken]);

  /**
   * Handles the change event for the editor.
   *
   * This event is triggered when the user changes the content of the editor.
   * The event is dispatched to the native app via a custom event.
   */
  const handleOnchange = useCallback<NonNullable<RichTextInputSettings['onChange']>>(
    ({ currentTarget: { value: contents } }) => {
      if (window['Android']) {
        window['Android'].onRichTextEditorChange?.(isNil(contents) ? null : JSON.stringify(contents));
      } else {
        window.dispatchEvent(new CustomEvent('onRichTextEditorChange', { detail: contents }));
      }
    },
    []
  );

  return (
    <MobileEditorTheme>
      <RichTextInput
        ref={editorContextRef}
        defaultLanguage={urlParams?.locale as MultilingualStringValue}
        onChange={handleOnchange}
        onGenerateText={authToken && id ? handleOnGenerateText : undefined}
        selectedValue={null}
        styleVariant={RichTextClientType.MOBILE}
        testId={ElementTestId.MOBILE_RICH_TEXT_EDITOR}
      />
    </MobileEditorTheme>
  );
};
