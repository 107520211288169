import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import type { Group } from 'store/api/graph/interfaces/types';

/** The property names used in the global admin filters */
export enum GlobalAdminFilterProperties {
  GROUPS = 'groups',
  ROOFTOPS = 'rooftops',
}

/** The data shape of the GlobalAdminFilters */
export interface GlobalAdminFilters {
  /** Group filters are a part of the GlobalAdminFilters */
  [GlobalAdminFilterProperties.GROUPS]?: GlobalFilterOption;
  /** Rooftop filters are a part of the GlobalAdminFilters */
  [GlobalAdminFilterProperties.ROOFTOPS]?: GlobalFilterOption[];
}

/** The list option used in the global filter selector */
export type GlobalFilterOption = SubStepOption & {
  /**
   * The groupName of this SubStepOption, this allows for ListConditions to be determined and the list to organize
   * rooftops by the group
   */
  groupName: Omit<Group, 'whiteLabel' | 'rooftops'>;
  /** Since both rooftops and groups can be selected in the list, we need to know which type this list option is */
  type: GlobalAdminFilterProperties;
};

/** Data format of the transformed global admin filters */
export interface TransformedGlobalAdminFilters {
  /** The rooftopId is an array of rooftop ids */
  rooftopId?: string[];
  /** The groupId is the id of the group */
  groupId?: string;
}
