import { useCallback, useMemo } from 'react';

import { useLocation, useMatch, useNavigate, useNavigationType } from 'react-router-dom';

import { getSectionPath } from 'utils/urlUtils';

export enum MatchPattern {
  ROOT_SECTION_VIEW = '/:section',
  SPLIT_VIEW = '/:section/:itemId',
  TABLE_VIEW = '/:section/table',
  TABLE_DETAILS_VIEW = '/:section/table/:itemId',
  TOKEN = '/:section/:token',
}

/**
 * UseRouter
 *
 * @see https://usehooks.com/useRouter/
 * @returns {object} Router object
 */
export function useRouter() {
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch;
  const navigationType = useNavigationType();

  const sectionPath = useMemo(() => getSectionPath(location.pathname), [location.pathname]);

  const checkIsDefaultSectionPath = useCallback((pathname: string) => pathname.split('/').length === 2, []);

  const redirectBackToSectionPath = useCallback(() => {
    window.location.assign(`${window.location.origin}${sectionPath}`);
  }, [sectionPath]);

  return useMemo(
    () => ({
      navigationType,
      push: navigate,
      replace: path => navigate(path, { replace: true }),
      pathname: location.pathname,
      match,
      location,
      navigate,
      /**
       * Checks if the given pathname is a root path.
       * A root path is defined as '/' or a path with a single segment (e.g., '/task', '/retail/', etc.).
       *
       * @param {string} pathname - The pathname to check.
       * @returns {boolean} - True if the pathname is a root path, false otherwise.
       *
       * @example
       * checkIsDefaultSectionPath('/'); // true
       * @example
       * checkIsDefaultSectionPath('/task'); // true
       * @example
       * checkIsDefaultSectionPath('/task/table/someID'); // false
       * @example
       * checkIsDefaultSectionPath('/task/someID'); // false
       */
      checkIsDefaultSectionPath,
      /**
       * A default section path, is the path to a mainSection that does not
       * indicate if it is a table or details view.
       *
       * e.g.
       * Default path: "/tasks"
       * Details path: "/tasks/someID"
       * Table path: "/tasks/table"
       */
      isDefaultSectionPath: checkIsDefaultSectionPath(location.pathname),
      /**
       * Parses the current url and returns then mainSection path.
       *
       * e.g. "/tasks/table?archived=false" => "/tasks"
       */
      sectionPath,
      /**
       * Redirects the user to a specific section path based on the current location pathname
       */
      redirectBackToSectionPath,
    }),
    [navigationType, navigate, location, match, checkIsDefaultSectionPath, sectionPath, redirectBackToSectionPath]
  );
}
