import type { HTMLAttributes } from 'react';
import { memo } from 'react';

import styled from 'styled-components/macro';

import LinkedCloseIcon from 'components/ui/icons/LinkedCloseIcon';
import LinkedOpenIcon from 'components/ui/icons/LinkedOpenIcon';
import IconButton from 'components/ui/shared/IconButton';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import { ElementTestId } from 'enums/testing';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const TooltipContent = styled.div`
  white-space: nowrap;
`;

interface CollapseLinkedSectionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /** When true, display open Icon and tooltip, otherwise display close icon */
  isCollapsed: boolean;
}

const CollapseLinkedSectionButton = ({ isCollapsed, ...props }: CollapseLinkedSectionButtonProps) => (
  <IconButton
    {...props}
    data-testid={ElementTestId.COLLAPSE_LINKED_SECTION_BUTTON}
    tooltip={{
      arrowPosition: {
        primary: PrimaryArrowPosition.BOTTOM,
        secondary: SecondaryArrowPosition.RIGHT,
      },
      children: <TooltipContent>{t(isCollapsed ? 'open_linked' : 'close_linked')}</TooltipContent>,
    }}
  >
    {isCollapsed ? <LinkedCloseIcon /> : <LinkedOpenIcon />}
  </IconButton>
);

export default memo(CollapseLinkedSectionButton);
