import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

/*
 * Based off of fields from `LeadConnection`
 * TODO: [#1117] Derive from columns in connection
 */
export enum LeadActivity {
  GROUP_NAME = 'groupName',
  ROOFTOP_NAME = 'rooftopName',
  TYPE = 'type',
  SOURCE_NAME = 'sourceName',
  SOURCE_ID = 'sourceId',
  SUBJECT = 'attributesName.subject',
  CONVERSATION = 'conversation',
  CONTENT = 'attributesName.content',
  PHONE_CALL_CONTENT = 'attributesName.phoneCallContent',
  LEAD_NAME = 'leadName',
  ATTRIBUTES_NAME = 'attributesName',
  REFERRING_URL = 'attributesName.referringUrl',
  ASSIGNED_TO_NAME = 'assignedToName',
  TAG_NAME = 'tagName',
  NOTE = 'note',
  ARCHIVED = 'archived',
  CREATED = 'created',
  CREATED_BY_NAME = 'createdByName',
  UPDATED = 'updated',
  PURCHASE_DATE = 'attributesName.purchaseDate',
  RETAIL_ITEM_PURCHASED = 'attributesName.retailItemPurchased',
  RETAIL_ITEM_OF_INTEREST = 'attributesName.retailItemOfInterest',
  TRADE_IN_ITEM = 'attributesName.tradeInItem',
  RECORDING_URL = 'attributesName.recordingUrl',
}

/*
 * Settings with type derived from API docs
 * TODO: [#1117] Refactor when moving over to `columns` in connection for specifying labels
 */
const LeadActivityConfig: TableSettingsType = {
  [LeadActivity.GROUP_NAME]: { label: 'group', type: FieldDataType.GROUP_NAME },
  [LeadActivity.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [LeadActivity.TYPE]: { label: 'type', type: FieldDataType.STATUS },
  [LeadActivity.SOURCE_NAME]: { label: 'source', type: FieldDataType.NAME },
  [LeadActivity.SUBJECT]: { label: 'subject', type: FieldDataType.DEFAULT },
  [LeadActivity.REFERRING_URL]: { label: 'referring_url', type: FieldDataType.DEFAULT },
  [LeadActivity.RECORDING_URL]: {
    label: 'recording_url',
    type: FieldDataType.DEFAULT,
    tooltip: 'lead_activity_recording_url_tooltip',
  },
  [LeadActivity.NOTE]: { label: 'note_other', type: FieldDataType.DEFAULT },
  [LeadActivity.CONTENT]: { label: 'content', type: FieldDataType.DEFAULT },
  [LeadActivity.PHONE_CALL_CONTENT]: { label: 'content', type: FieldDataType.DEFAULT },
  [LeadActivity.LEAD_NAME]: { label: 'lead_one', type: FieldDataType.USER_NAME },
  [LeadActivity.ASSIGNED_TO_NAME]: { label: 'assigned_to', type: FieldDataType.USER_NAME },
  [LeadActivity.TAG_NAME]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [LeadActivity.ARCHIVED]: { label: 'archived', type: FieldDataType.BOOLEAN },
  [LeadActivity.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [LeadActivity.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  [LeadActivity.PURCHASE_DATE]: { label: 'date', type: FieldDataType.DATE },
  [LeadActivity.RETAIL_ITEM_PURCHASED]: { label: 'vehicles_purchased_one', type: FieldDataType.YMMT },
  [LeadActivity.RETAIL_ITEM_OF_INTEREST]: { label: 'vehicles_of_interest_one', type: FieldDataType.YMMT },
  [LeadActivity.TRADE_IN_ITEM]: { label: 'trade_in', type: FieldDataType.YMMT },
};

export const LeadActivitySettings = translateFields(LeadActivityConfig);
