export enum RetailAdjustmentAmountType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum RetailAdjustmentAmountAdjustmentType {
  FEE = 'fee',
  DISCOUNT = 'discount',
}

export enum RetailBulkAdjustmentBuilderSteps {
  RETAIL_ITEM_ADJUSTMENTS_TYPE = 'RETAIL_ITEM_ADJUSTMENTS_TYPE',
  RETAIL_ITEM_FILTERS = 'RETAIL_ITEM_FILTERS',
  RETAIL_ITEM_ADJUSTMENTS = 'RETAIL_ITEM_ADJUSTMENTS',
}

export enum RetailBulkAdjustmentBuilderFields {
  TYPE = 'type',
  ROOFTOP_ID = 'rooftopId',
  NAME = 'name',
  ACTIVE = 'active',
  ORDER = 'order',
  SCHEDULE_ADJUSTMENT = 'scheduleAdjustment',
  STARTING = 'starting',
  ENDING = 'ending',

  // Price adjustment fields
  DISCOUNT_OR_FEE = 'discountOrFee',
  ENABLE_LUXURY_TAX = 'enableLuxuryTax',
  AMOUNT = 'amount',
  INCLUDE_CASH = 'includeCash',
  INCLUDE_FINANCE = 'includeFinance',
  INCLUDE_LEASE = 'includeLease',
  PERCENTAGE = 'priceAttributes.percentage',
  FIXED = 'priceAttributes.fixed',
  LABEL = 'priceAttributes.label',
  TARGETS = 'priceAttributes.targets',
  DISCLAIMER = 'priceAttributes.disclaimer',

  // Overlay adjustment fields
  OVERLAY_IMAGE = 'overlayAttributes.overlayImage',
  PRIMARY_PHOTO_ONLY = 'overlayAttributes.primaryPhotoOnly',
  SCALE = 'overlayAttributes.scale',
  SCALING_DIMENSION = 'overlayAttributes.scalingDimension',
  OVERLAY_POSITION = 'overlayAttributes.position',

  // Description adjustment fields
  PLACEMENT = 'descriptionAttributes.placement',
  TEXT = 'descriptionAttributes.text',

  // Promotion adjustment fields
  BANNER_IMAGE = 'promotionAttributes.bannerImage',
  IMAGE_POSITION = 'promotionAttributes.position',
  ALTERNATIVE_TEXT = 'promotionAttributes.altText',
  BANNER_URL = 'promotionAttributes.bannerUrl',
  BANNER_LINKS_TO_VDP = 'promotionAttributes.bannerLinksToVdp',
  OPEN_LINK_IN_NEW_TAB = 'promotionAttributes.openLinkInNewTab',
  SHOW_ON_VDP = 'promotionAttributes.showOnVdp',
  SHOW_ON_VLP = 'promotionAttributes.showOnVlp',
  SEO_PAGE_TEXT = 'promotionAttributes.seoPageText',

  // Disclaimer adjustment fields
  DISCLAIMER_TEMPLATE = 'disclaimerAttributes.disclaimerTemplate',
}
