import { type MouseEventHandler, useCallback } from 'react';

import type { Level } from '@tiptap/extension-heading';
import { css } from 'styled-components/macro';

import IconDropdown from 'components/ui/editor/components/shared/IconDropdown';
import FormatSizeIcon from 'components/ui/icons/FormatSizeIcon';
import { Clickable } from 'components/ui/shared/Button';
import { RichTextClientType } from 'contexts/RichTextEditorContext';
import { ElementTestId } from 'enums/testing';
import { useRichTextEditor } from 'hooks/contexts/useRichTextEditor';
import { translate } from 'utils/intlUtils';

const { t } = translate;

const listContainerCss = css`
  grid-auto-rows: unset;

  ${Clickable} {
    padding: 4px 8px;

    > h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
      line-height: 24px;
    }

    h1 {
      line-height: 32px;
    }
  }
`;

const FormatSize = () => {
  const { clientType, editor } = useRichTextEditor();
  const isMobileClientType = clientType === RichTextClientType.MOBILE;
  /**
   * Handle the click event for the normal heading.
   */
  const handleNormalHeadingClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => editor?.chain().focus().setParagraph().run(),
    [editor]
  );

  /**
   * Handle the click event for each heading level in the dropdown.
   */
  const handleHeadingLevelClick = useCallback(
    (level: Level): MouseEventHandler<HTMLButtonElement> =>
      () =>
        editor?.chain().focus().toggleHeading({ level }).run(),
    [editor]
  );

  return (
    <IconDropdown
      data-testid={ElementTestId.EDITOR_BUTTON_FORMAT_SIZE}
      icon={FormatSizeIcon}
      items={[
        {
          label: 'Normal',
          labelRender: label => <p>{label}</p>,
          onClick: handleNormalHeadingClick,
        },
        {
          label: 'Heading 1',
          labelRender: label => <h1>{label}</h1>,
          onClick: handleHeadingLevelClick(1),
        },
        {
          label: 'Heading 2',
          labelRender: label => <h2>{label}</h2>,
          onClick: handleHeadingLevelClick(2),
        },
        {
          label: 'Heading 3',
          labelRender: label => <h3>{label}</h3>,
          onClick: handleHeadingLevelClick(3),
        },
        {
          label: 'Heading 4',
          labelRender: label => <h4>{label}</h4>,
          onClick: handleHeadingLevelClick(4),
        },
      ]}
      listContainerCss={listContainerCss}
      tooltip={isMobileClientType ? undefined : { children: t('text_styles') }}
    />
  );
};

export default FormatSize;
