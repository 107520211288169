import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';

import { SELECT_ALL_TOGGLE_OPTION } from './interfaces';

export const getEnabledItems = (items: Record<string, any> & { disabled?: boolean }) =>
  items.filter(({ disabled }) => !disabled);

export const getItemsByGroupId = (groupId: string | undefined, items: Array<SubStepOption & { category?: string }>) =>
  groupId ? items.filter(({ category: itemGroupId }) => groupId === itemGroupId) : items;

export const isGroupChecked = (
  groupId: string | undefined,
  selectedOptions: SubStepOption[],
  groupItems: SubStepOption[]
) => {
  const selectedGroupItems = getItemsByGroupId(groupId, selectedOptions); // Some of these may be disabled
  const enabledGroupItems = getEnabledItems(groupItems);
  const allEnabledItemsSelected = enabledGroupItems.every(enabledItem =>
    selectedGroupItems.find(selectedItem => enabledItem.id === selectedItem?.id || enabledItem.id === selectedItem)
  );
  return selectedGroupItems.length > 0 && allEnabledItemsSelected;
};

export const isGroupIndeterminate = (
  groupId: string | undefined,
  selectedOptions: SubStepOption[],
  groupItems: SubStepOption[]
) => {
  const selectedGroupItems = getItemsByGroupId(groupId, selectedOptions); // Some of these may be disabled
  const enabledGroupItems = getEnabledItems(groupItems);
  const allElligibleItemsSelected = enabledGroupItems.every(enabledItem =>
    selectedGroupItems.find(selectedItem => enabledItem.id === selectedItem?.id || enabledItem.id === selectedItem)
  );
  const someEligibleItemsSelected = enabledGroupItems.some(enabledItem =>
    selectedGroupItems.find(selectedItem => enabledItem.id === selectedItem?.id || enabledItem.id === selectedItem)
  );
  return someEligibleItemsSelected && !allElligibleItemsSelected;
};

export const isGroupFullyDisabled = (groupItems: SubStepOption[]) => groupItems.every(({ disabled }) => disabled);

/**
 * A function for selecting or deselecting the options of a particular group
 *
 * @param groupId The id of the group
 * @param selectedOptions The currently selected options across all groups
 * @param groupItems The subset of options belonging to the group
 * @param onSelect The callback to run the next set of options
 */
export const onSelectAll = (
  groupId: string | undefined,
  selectedOptions: SubStepOption[],
  groupItems: SubStepOption[],
  onSelect: (args: any) => any
) => {
  const checked = isGroupChecked(groupId, selectedOptions, groupItems);
  const indeterminate = isGroupIndeterminate(groupId, selectedOptions, groupItems);
  const groupSelectedItems = getItemsByGroupId(groupId, selectedOptions);
  const nextSelectedItems =
    indeterminate || checked
      ? // Deselect all items eligible for deselection
        selectedOptions.filter(
          option =>
            // If the item doesn't belong to the group, keep it selected
            !groupSelectedItems.some(groupItem => groupItem.id === option.id) ||
            // If the item belongs to the group, but is disabled, keep it selected
            groupItems.find(groupItem => groupItem.id === option.id || groupItem.id === option)?.disabled
        )
      : // Select all items eligible for selection
        [...selectedOptions, ...groupItems.filter(groupItem => !groupItem.disabled)];

  onSelect({ id: SELECT_ALL_TOGGLE_OPTION, data: nextSelectedItems });
};
