import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { DocumentNode } from 'graphql';

import { usePrevious } from 'hooks/usePrevious';
import { client } from 'store/apollo/ApolloClient';
import { checkForMatchingRefetchQueries } from 'utils/gqlUtils';
import { translate } from 'utils/intlUtils';

import PromptDialog from './PromptDialog';

const { t } = translate;

/**
 * Determines whether an item is archived or not.
 *
 * Considers all possible archived fields:
 * - archive : Most entities
 * - archivedByMe : Conversations
 */
export const isItemArchived = (item?: Record<string, any>): boolean => !!(item?.archived || item?.archivedByMe);

interface ArchiveDialogProps {
  /** Label used in title and message of dialog. */
  entityTypeLabel: string;
  /** API data of item to archive. */
  item: Record<string, unknown>;
  /** Mutation to run to archive item. */
  mutation: DocumentNode;
  /** Whether dialog is opened or not. */
  isDialogOpen: boolean;
  /** Method to set dialog open state. */
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  /** List of queries to run after mutation. */
  refetchQueryNames?: string[];
  /** Name of the `archive` api property. */
  archiveParameter?: string;
  /** Custom message to use in the prompt */
  customMessage?: string;
}

const getIsArchivedStatusLabel = isArchived => (isArchived ? 'unarchive_x' : 'archive_x');

/**
 * ArchiveDialog is a controlled component used
 * for archiving/unarchiving of items.
 */
const ArchiveDialog = ({
  entityTypeLabel,
  item,
  mutation,
  isDialogOpen,
  setIsDialogOpen,
  refetchQueryNames,
  customMessage,
  archiveParameter = 'archived',
}: ArchiveDialogProps) => {
  const prevIsDialogOpen = usePrevious(isDialogOpen);
  const isArchived = item?.[archiveParameter];
  const [prevIsArchived, setPrevIsArchived] = useState<boolean | undefined>(undefined);
  const isArchivedStatusLabel = useMemo(
    () => getIsArchivedStatusLabel(prevIsArchived ?? isArchived),
    [prevIsArchived, isArchived]
  );

  const onArchiveClicked = useCallback(async () => {
    const { id } = item || {};

    setPrevIsArchived(isArchived as boolean);
    return client.mutate({
      mutation,
      variables: { id, [archiveParameter]: !isArchived },
      ...checkForMatchingRefetchQueries(refetchQueryNames),
    });
  }, [item, isArchived, mutation, archiveParameter, refetchQueryNames]);
  const onArchiveComplete = useCallback(
    (success: boolean) => {
      if (success) {
        // Hide the success dialog after 0.7 seconds, if the user hasn't already dismissed it
        setTimeout(() => {
          setIsDialogOpen(false);
        }, 700);
      }
    },
    [setIsDialogOpen]
  );
  const onArchiveCancel = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);

  useEffect(() => {
    if (!isDialogOpen && prevIsDialogOpen) {
      setPrevIsArchived(undefined);
    }
  }, [isDialogOpen, prevIsDialogOpen]);

  return (
    <PromptDialog
      isOpen={isDialogOpen}
      onConfirm={onArchiveClicked}
      onCancel={onArchiveCancel}
      onComplete={onArchiveComplete}
      onClose={() => setPrevIsArchived(undefined)}
      title={t(isArchivedStatusLabel, [entityTypeLabel])}
      message={t(isArchived ? 'unarchive_x_message' : customMessage || 'archive_x_message', [
        entityTypeLabel.toLowerCase(),
      ])}
    />
  );
};

export default ArchiveDialog;
