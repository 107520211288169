import { gql } from '@apollo/client';

export const websiteLeadRoute = gql`
  fragment WebsiteLeadRouteFragment on LeadRoute {
    created
    id
    rooftopId
    type
    typeName
    updated
  }
`;
