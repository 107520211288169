import { gql } from '@apollo/client';

import { motorcycleAttributesMetadata } from './motorcycleAttributesMetadata';
import { selectStringOption } from './selectOption';
import { vehicleAttributesMetadata } from './vehicleAttributesMetadata';

export const retailItemMetadata = gql`
  fragment RetailItemMetadataFragment on InventoryItemMetadata {
    vehicleAttributes {
      ...VehicleAttributesMetadataFragment
    }
    motorcycleAttributes {
      ...MotorcycleAttributesMetadataFragment
    }
    retailItem {
      status {
        ...SelectStringOptionFragment
      }
      warranty {
        quebecCategoryOverride {
          ...SelectStringOptionFragment
        }
      }
    }
    condition {
      ...SelectStringOptionFragment
    }
  }
  ${selectStringOption}
  ${motorcycleAttributesMetadata}
  ${vehicleAttributesMetadata}
`;
