import Badge, { BadgeColors } from 'components/ui/shared/Badge';
import { translate } from 'utils/intlUtils';

const { t } = translate;

interface Props {
  isArchived: boolean;
}

export const ArchiveBadge = ({ isArchived }: Props) =>
  isArchived ? (
    <Badge color={BadgeColors.NEGATIVE} title={`${t('archived')}: ${t('yes')}`}>
      {t('archived')}
    </Badge>
  ) : null;
