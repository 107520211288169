import { gql } from '@apollo/client';

import { columns } from 'store/api/graph/fragments/columns';
import { pageInfo } from 'store/api/graph/fragments/pageInfo';
import { retailPricingConfiguration } from 'store/api/graph/fragments/retailPricingConfiguration';
import { retailPricingFinanceConfiguration } from 'store/api/graph/fragments/retailPricingFinanceConfiguration';
import { retailPricingLeaseConfiguration } from 'store/api/graph/fragments/retailPricingLeaseConfiguration';
import { rooftopRetailPricing } from 'store/api/graph/fragments/rooftopRetailPricing';

export const rooftopRetailPricingCashDetailQuery = gql`
  query RooftopRetailCashPricingDetailQuery($id: ID!) {
    item: rooftopRetailPricing(id: $id) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingConfigurationFragment
      }
    }
  }
  ${retailPricingConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailPricingFinanceDetailQuery = gql`
  query RooftopRetailFinancePricingDetailQuery($id: ID!) {
    item: rooftopRetailPricing(id: $id) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingFinanceConfigurationFragment
      }
    }
  }
  ${retailPricingFinanceConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailPricingLeaseDetailQuery = gql`
  query RooftopRetailLeasePricingDetailQuery($id: ID!) {
    item: rooftopRetailPricing(id: $id) {
      ...RooftopRetailPricingFragment
      configuration {
        ...RetailPricingLeaseConfigurationFragment
      }
    }
  }
  ${retailPricingLeaseConfiguration}
  ${rooftopRetailPricing}
`;

export const rooftopRetailPricingContainerQuery = gql`
  query RooftopRetailPricingContainerQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $keyword: String
    $filter: RooftopRetailPricingConnectionFilterInput
  ) {
    connection: rooftopRetailPricingConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      keyword: $keyword
      filter: $filter
    ) {
      columns {
        ...ColumnsFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...RooftopRetailPricingFragment
          configuration {
            ...RetailPricingConfigurationFragment
          }
        }
      }
    }
  }
  ${retailPricingConfiguration}
  ${rooftopRetailPricing}
  ${pageInfo}
  ${columns}
`;

export const rooftopRetailPricingCountQuery = gql`
  query RooftopRetailPricingCountQuery($keyword: String, $rooftopId: [ID!]) {
    cash: rooftopRetailPricingConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, paymentOption: CASH }
    ) {
      pageInfo {
        totalEdges
      }
    }
    finance: rooftopRetailPricingConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, paymentOption: FINANCE }
    ) {
      pageInfo {
        totalEdges
      }
    }
    lease: rooftopRetailPricingConnection(
      first: 1
      keyword: $keyword
      filter: { rooftopId: $rooftopId, paymentOption: LEASE }
    ) {
      pageInfo {
        totalEdges
      }
    }
  }
`;
