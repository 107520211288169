import type QueryMethods from 'components/core/createModify/interfaces/queryMethods';
import { rooftopBuilderMetaQuery } from 'components/sections/shared/ItemMetaQueries';
import { rooftopDetailsQuery } from 'containers/rooftops/RooftopsContainerQuery';
import { ApolloFetchPolicy } from 'enums/apollo';
import { logApiError } from 'store/api/graph/interfaces/apiErrors';
import type { RooftopBuilderMetaQuery, RooftopBuilderMetaQueryVariables } from 'store/api/graph/interfaces/types';
import { AccessLevel, ResourceType, UserScope } from 'store/api/graph/interfaces/types';
import { client } from 'store/apollo/ApolloClient';

const RooftopQueryMethods: QueryMethods = {
  getMetaData: async (onMetaUpdate, defaultData, userContext) => {
    const observable = client.watchQuery<RooftopBuilderMetaQuery, RooftopBuilderMetaQueryVariables>({
      query: rooftopBuilderMetaQuery,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        filter: {
          whiteLabelId: defaultData?.whiteLabelName?.whiteLabel?.id,
          scope: [UserScope.WHITE_LABEL],
          active: true,
        },
        [`d_accountRepsOn`]:
          !!userContext?.isWhiteLabelScoped &&
          !!userContext?.hasPermissions([{ resource: ResourceType.USERS, level: AccessLevel.BASIC }]),
      },
    });

    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: res => {
          const metadata = res.data;
          resolve({ subscription, metadata });
          onMetaUpdate(metadata);
        },
        error: (error: Error) => {
          logApiError(error);
          reject(error);
        },
      });
    });
  },

  getData: async (id: string) => {
    const response = await client.query({
      query: rooftopDetailsQuery,
      variables: { id },
      fetchPolicy: ApolloFetchPolicy.CACHE_FIRST,
    });
    return response.data.item;
  },

  refetchQueries: ['RooftopConnectionQuery', 'RooftopCondensedListQuery'],
};

export default RooftopQueryMethods;
