import type { RefObject } from 'react';
import { useLayoutEffect, useState } from 'react';

import { deferredRenderCall } from 'utils/renderingUtils';

interface FlexGridInfo {
  /** Total columns represented as a floating point number */
  totalColumns: number;
  /** Integer representation of columns we actually need in the grid */
  columnsToFill: number;
  /** Total space the grid row will be able to expand */
  availableSpaceToExpand: number;
  /** Width that the grid column is extended by */
  columnExtraWidth: number;
  /** Actual width of the grid column */
  columnTotalWidth: number;
  /** Integer representation of columns we need in a non css grid custom layout */
  customColumnsCount: number;
}

/**
 * Custom hook used to get information about a flebox simulated css grid.
 * Usually used in conjunction with the `FlexGrid` styled component.
 */
export const useFlexGrid = (
  flexContainerRef: RefObject<HTMLDivElement>,
  flexItemMinWidth: number,
  flexItemGap: number,
  flexCustomColumnsToFill?: (totalColumns: number) => number
): FlexGridInfo => {
  const [flexGridInfo, setFlexGridInfo] = useState<FlexGridInfo>({
    totalColumns: 0,
    columnsToFill: 0,
    availableSpaceToExpand: 0,
    columnExtraWidth: 0,
    columnTotalWidth: 0,
    customColumnsCount: 4,
  });

  useLayoutEffect(() => {
    /**
     * Simulate the css grid sizing spec programmatically.
     * Spec source: https://www.w3.org/TR/css-grid-1/#auto-repeat
     */
    function getFlexGridInfo(
      containerWidth: number,
      minItemWidth: number,
      itemGap: number,
      getColummnsToFill?: (totalColumns: number) => number
    ): FlexGridInfo {
      // '- 1' to ensure sub-pixel inconsistencies are accounted for
      const totalColumns = (containerWidth - 1 + itemGap) / (minItemWidth + itemGap);
      const columnsToFill = getColummnsToFill?.(totalColumns) || Math.floor(totalColumns);
      const availableSpaceToExpand = (totalColumns - columnsToFill) * (minItemWidth + itemGap);
      const columnExtraWidth = availableSpaceToExpand / columnsToFill;
      // Truncate to 2 decimal points, to prevent cross-browser sub-pixel rounding inconsistency
      const columnTotalWidth = Math.floor((minItemWidth + columnExtraWidth) * 100) / 100;

      // Determine custom grid behaviour e.g. 4/2/1 grid layout
      let customColumnsCount;
      if (columnsToFill >= 4) {
        customColumnsCount = 4;
      } else if (columnsToFill >= 2) {
        customColumnsCount = 2;
      } else {
        customColumnsCount = 1;
      }

      return {
        customColumnsCount,
        totalColumns,
        columnsToFill,
        availableSpaceToExpand,
        columnExtraWidth,
        columnTotalWidth,
      };
    }

    function onGridResize() {
      if (!flexContainerRef.current) {
        return;
      }
      setFlexGridInfo(
        getFlexGridInfo(flexContainerRef.current.offsetWidth, flexItemMinWidth, flexItemGap, flexCustomColumnsToFill)
      );
    }

    requestAnimationFrame(onGridResize);

    /** TODO: [#2535] Update so onGridResize is triggered via ResizeObserver monitoring container dimensions */
    /** Wait for children to mount, then update grid info */
    deferredRenderCall(() => requestAnimationFrame(onGridResize));

    window.addEventListener('resize', onGridResize);
    return () => window.removeEventListener('resize', onGridResize);
  }, [flexContainerRef, flexItemGap, flexItemMinWidth, flexCustomColumnsToFill]);

  return flexGridInfo;
};
