import { useEffect } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import type { FlattenSimpleInterpolation } from 'styled-components/macro';
import styled, { css } from 'styled-components/macro';

import { DIVIDER } from 'styles/color';
import { BLUE_050 } from 'styles/tokens';

import type { SortableListItemContainerProps } from './interfaces';
import ListSelectionDraggableHandle from './ListSelectionDraggableHandle';

type Identifier = symbol | string;

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const SortableListItem = styled.li<{ hoverCss?: FlattenSimpleInterpolation; isDragging: boolean }>`
  display: flex;
  border-bottom: 1px solid ${DIVIDER};
  ${({ isDragging }) => css`
    background: ${isDragging ? BLUE_050 : 'none'};
    opacity: ${isDragging ? 0 : 1};
  `}

  ${({ hoverCss }) => hoverCss}
`;

const dragAndDropType = 'SortableListItemContainer';

const SortableListItemContainer = ({
  index,
  onListItemDrag,
  item,
  children,
  isSortable = false,
  onDone,
  hoverCss,
}: SortableListItemContainerProps) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: dragAndDropType,
    item: () => ({ id: item.id, index }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    end(draggedItem, monitor) {
      if (monitor.didDrop()) {
        onDone?.();
      }
    },
  });

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: dragAndDropType,
    collect: monitor => ({ handlerId: monitor.getHandlerId() }),
    hover: (dragItem: DragItem) => {
      if (dragItem?.id !== item.id) {
        onListItemDrag(dragItem.id, index);
      }
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return (
    <SortableListItem
      data-testid={`${dragAndDropType}--${item.id}`}
      isDragging={isDragging}
      ref={drop}
      data-handler-id={handlerId}
      hoverCss={hoverCss}
    >
      {children}
      {isSortable && <ListSelectionDraggableHandle ref={drag} />}
    </SortableListItem>
  );
};

export default SortableListItemContainer;
