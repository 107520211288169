import type { PriceAdjustmentSeedData } from 'components/sections/createModify/retailItemPriceAdjustment/steps/RetailItemPriceAdjustmentStep';
import { RetailItemPricingTypeBuilderFields } from 'components/sections/createModify/retailItemPricing/steps/interfaces';
import RetailItemPricingTypeStep from 'components/sections/createModify/retailItemPricing/steps/RetailItemPricingTypeStep';
import { InventoryItem } from 'enums/columns/inventoryItem';
import type { RetailItem } from 'store/api/graph/interfaces/types';
import { PaymentOption } from 'store/api/graph/interfaces/types';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';

import { getAvailablePaymentMethods } from '../helpers/RetailItemPricingBuilders';

class RetailItemPricingTypeFinanceStep extends RetailItemPricingTypeStep {
  componentDidMount() {
    const {
      tier: { data },
    } = this.props;

    const { financeRetailPricing } = data as RetailItem;

    super.componentDidMount();

    getStepField(RetailItemPricingTypeBuilderFields.BULK_ADJUSTMENTS, this.fields).selectedValue =
      financeRetailPricing?.priceBulkAdjustments;

    this.setSelectedAdjustments(data as RetailItem);

    this.setTitleStepName(translate.t('finance'));
  }

  onItemAdd(seedLabelString: string) {
    const {
      tier: { data },
    } = this.props;

    const {
      subContexts: {
        userContext: {
          featureFlags: { rooftopPackageEnabled },
        },
      },
    } = this.getContext();

    super.onItemAdd({
      seedLabelString,
      paymentOption: PaymentOption.FINANCE,
      availablePaymentOptions: getAvailablePaymentMethods(data as RetailItem, rooftopPackageEnabled),
    } as PriceAdjustmentSeedData);
  }

  async save() {
    return super.savePaymentStep(InventoryItem.FINANCE_RETAIL_PRICING, PaymentOption.FINANCE);
  }
}

export default RetailItemPricingTypeFinanceStep;
