import { memo, type SVGProps } from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12 16c1.25 0 2.313-.438 3.188-1.313.874-.874 1.312-1.937 1.312-3.187 0-1.25-.438-2.313-1.313-3.188C14.313 7.437 13.25 7 12 7c-1.25 0-2.313.437-3.188 1.312C7.939 9.187 7.5 10.25 7.5 11.5c0 1.25.438 2.313 1.313 3.188C9.687 15.562 10.75 16 12 16Zm.001-1.925c-.715 0-1.323-.25-1.824-.751a2.48 2.48 0 0 1-.752-1.823c0-.715.25-1.323.751-1.824a2.48 2.48 0 0 1 1.823-.752c.715 0 1.323.25 1.824.751.5.5.752 1.108.752 1.823 0 .715-.25 1.323-.751 1.824a2.48 2.48 0 0 1-1.823.752ZM12 19.184c-2.257 0-4.319-.604-6.184-1.812-1.866-1.208-3.336-2.807-4.41-4.798a2.146 2.146 0 0 1-.28-1.075 2.147 2.147 0 0 1 .28-1.075c1.074-1.991 2.544-3.59 4.41-4.798C7.68 4.419 9.743 3.815 12 3.815s4.319.604 6.184 1.812c1.866 1.208 3.336 2.807 4.41 4.798a2.15 2.15 0 0 1 .28 1.075 2.148 2.148 0 0 1-.28 1.075c-1.074 1.991-2.544 3.59-4.41 4.798-1.865 1.208-3.927 1.812-6.184 1.812Z"
    />
  </svg>
);
export default memo(EyeIcon);
