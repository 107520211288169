import { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components/macro';

import LoggingService from 'components/core/logging/LoggingService';
import Image from 'components/ui/images/Images';
import MenuButton, { MenuButtonType, MenuItem, MenuPosition } from 'components/ui/menus/MenuButton';
import { ImageSize, ImageType } from 'enums/imageType';
import { RoutePath } from 'enums/routePath';
import { ElementTestId } from 'enums/testing';
import { useUser } from 'hooks/contexts/useUser';
import { useRouter } from 'hooks/useRouter';
import { HEIGHT_750, WIDTH_750 } from 'styles/tokens';
import { authManager, isLoggedIn } from 'utils/authUtils';
import { translate } from 'utils/intlUtils';

import { NavActionItem } from '../shared/CommonNavItems';

import ImpersonationToggle from './ImpersonationToggle';
import LanguageToggle from './LanguageToggle';

const { t } = translate;

const UserNavActionItem = styled(NavActionItem)`
  padding: 0;
`;

const UserImage = styled(Image)`
  height: ${HEIGHT_750};
  width: ${WIDTH_750};
`;

const UserMenuNavItem = () => {
  const {
    user: { avatar },
  } = useUser();

  const isAuthenticated = isLoggedIn();
  const router = useRouter();
  const logout = useCallback(async () => {
    await authManager.logout();
    LoggingService.clearUser();
  }, []);

  const customIcon = useMemo(
    () => (
      <UserNavActionItem data-testid={ElementTestId.USER_MENU_GLOBAL_NAV_BUTTON}>
        <UserImage type={ImageType.AVATAR} size={ImageSize.AVATAR} src={avatar?.url} />
      </UserNavActionItem>
    ),
    [avatar?.url]
  );

  const [isLanguageToggleOpen, setIsLanguageToggleOpen] = useState<boolean>(false);

  const toggleLanguage = useCallback(isOpen => () => setIsLanguageToggleOpen(isOpen), [setIsLanguageToggleOpen]);

  return (
    <MenuButton type={MenuButtonType.CUSTOM} position={MenuPosition.BOTTOM_LEFT} customIcon={customIcon}>
      {isAuthenticated && (
        <>
          <MenuItem label={t('language')} onClick={toggleLanguage(true)} />
          <MenuItem
            label={t('terms_and_privacy_policy')}
            onClick={() => window.open(process.env.REACT_APP_PRIVACY_POLICY_URL, '_blank')}
          />
          <MenuItem label={t('my_account')} onClick={() => router.push(RoutePath.MY_ACCOUNT)} />
          <ImpersonationToggle />
          <MenuItem label={t('logout')} onClick={() => void logout()} />

          <LanguageToggle isOpen={isLanguageToggleOpen} onCancel={toggleLanguage(false)} />
        </>
      )}
    </MenuButton>
  );
};

export default UserMenuNavItem;
