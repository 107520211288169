import { RetailItemLockableField, VehicleAttributesLockableField } from 'store/api/graph/interfaces/types';
import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

// InventoryItem commands a master list of all fields that would come back from the API
export enum InventoryItem {
  /** INVENTORY ITEM BASE & SHARED **/
  APPOINTMENTS_COUNT = 'appointmentsCount',
  ARCHIVED = 'archived',
  CARFAX_REPORT = 'carfaxReport',
  CARFAX_SHOW_WEB = 'carfaxReport.showWeb',
  CONDITION = 'condition',
  CONVERSATION = 'leadPartial.conversation',
  COST = 'cost',
  CREATED = 'created',
  CREATED_BY_NAME = 'createdByName',
  DAYS_IN_STOCK = 'daysInStock',
  DEMO = 'demo',
  INTEGRATIONS = 'integrations',
  MAKE_NAME = 'makeName',
  MAPPED = 'mapped',
  MODEL_NAME = 'modelName',
  NOTE = 'note',
  PHOTOS = 'photos',
  PHOTOS_EXTERIOR = 'photos.type.exterior',
  PHOTOS_EXTERIOR_COUNT = 'photosCountExterior',
  PHOTOS_INTERIOR = 'photos.type.interior',
  PHOTOS_INTERIOR_COUNT = 'photosCountInterior',
  PHOTOS_DAMAGE = 'photos.type.damage',
  PHOTOS_DAMAGE_COUNT = 'photosCountDamage',
  SUB_MODEL_NAME = 'subModelName',
  STATUS = 'status',
  TAG_NAME = 'tagName',
  TASKS_COUNT = 'tasksCount',
  TRIM_NAME = 'trimName',
  TRIM_PHOTOS = 'trim',
  TRIM_PHOTOS_INTERIOR = 'trim.interiorPhotos',
  TRIM_PHOTOS_EXTERIOR = 'trim.exteriorPhotos',
  TYPE = 'type',
  UPDATED = 'updated',
  VIN = 'vin',
  YEAR = 'year',
  TECHNICAL_SPECIFICATIONS = 'trimName.specification.technicalSpecifications',
  STANDARD_EQUIPMENT = 'trimName.specification.standardEquipment',
  VIDEOS = 'videos',
  VIDEOS_COUNT = 'videosCount',

  /** RETAIL ITEMS **/
  STOCK_NUMBER = 'stockNumber',
  RETAIL_ITEM_STATUS = 'retailItemStatus',
  AS_IS = 'asIs',
  CERTIFIED = 'certified',
  COMPLETE = 'complete',
  COMPLETE_PERCENT = 'completePercent',
  CONVERSATIONS_COUNT = 'conversationsCount',
  WALK_INS_COUNT = 'walkInsCount',
  LEADS_COUNT = 'leadsCount',
  DESCRIPTION = 'description',
  PURCHASE_PRICE = 'cashRetailPricing.purchasePrice',
  LEAD_ACTIVITIES_COUNT = 'leadActivitiesCount',
  PRICE_1 = 'price1',
  LOCKED = 'locked',
  MSRP = 'msrp',
  PHONE_CALLS_COUNT = 'phoneCallsCount',
  ROOFTOP_NAME = 'rooftopName',
  SHOW_WEB = 'showWeb',
  SOLD = 'sold',
  TEST_DRIVES_COUNT = 'testDrivesCount',
  PRICE_2 = 'price2',
  FINANCE_RETAIL_PRICING = 'financeRetailPricing',
  LEASE_RETAIL_PRICING = 'leaseRetailPricing',
  CASH_RETAIL_PRICING = 'cashRetailPricing',
  CASH_CONFIGURATION_ENABLED = 'cashRetailPricing.configuration.enabled',
  CASH_CONFIGURATION_OVERRIDE_ENABLED = 'cashRetailPricing.configurationOverride.enabled',
  CASH_ROOFTOP_RETAIL_PRICING_NAME = 'cashRetailPricing.rooftopRetailPricing.name',
  CASH_ROOFTOP_RETAIL_PRICING = 'cashRetailPricing.rooftopRetailPricing',
  CASH_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE = 'cashRetailPricing.rooftopRetailPricing.minDisplayPrice',
  FINANCE_CONFIGURATION_ENABLED = 'financeRetailPricing.configuration.enabled',
  FINANCE_CONFIGURATION_OVERRIDE_ENABLED = 'financeRetailPricing.configurationOverride.enabled',
  FINANCE_ROOFTOP_RETAIL_PRICING_NAME = 'financeRetailPricing.rooftopRetailPricing.name',
  FINANCE_ROOFTOP_RETAIL_PRICING = 'financeRetailPricing.rooftopRetailPricing',
  FINANCE_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE = 'financeRetailPricing.rooftopRetailPricing.minDisplayPrice',
  LEASE_CONFIGURATION_ENABLED = 'leaseRetailPricing.configuration.enabled',
  LEASE_CONFIGURATION_OVERRIDE_ENABLED = 'leaseRetailPricing.configurationOverride.enabled',
  LEASE_ROOFTOP_RETAIL_PRICING_NAME = 'leaseRetailPricing.rooftopRetailPricing.name',
  LEASE_ROOFTOP_RETAIL_PRICING = 'leaseRetailPricing.rooftopRetailPricing',
  LEASE_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE = 'leaseRetailPricing.rooftopRetailPricing.minDisplayPrice',
  BULK_ADJUSTMENTS = 'bulkAdjustments',
  FEATURED = 'featured',
  ON_ORDER = 'onOrder',
  IN_TRANSIT = 'inTransit',
  HIGHLIGHTS = 'highlights',
  WARRANTY = 'warranty',
  WARRANTY_QUEBEC_CATEGORY = 'warranty.quebecCategory',
  WARRANTY_QUEBEC_CATEGORY_NAME = 'warranty.quebecCategoryName',
  WARRANTY_SHOW_WEB = 'warranty.showWeb',
  WARRANTY_DESCRIPTION = 'warranty.description',
  DESCRIPTION_ADJUSTED = 'descriptionAdjusted',
  TRIM_INTERIOR_PHOTOS = 'trim.interiorPhotos',
  TRIM_EXTERIOR_PHOTOS = 'trim.exteriorPhotos',

  /** TRADE IN ITEMS */
  TRADE_IN_ITEM_STATUS = 'tradeInItemStatus',
  ACCIDENT_AMOUNT = 'accidentAmount',
  CONDITION_SCORE = 'conditionScore',
  LEAD = 'lead',
  LEAD_NOTE = 'leadNote',
  NUMBER_OF_KEYS = 'numKeys',
  CONDITION_DECLARATIONS = 'conditionDeclarations',
  CONDITION_DECLARATIONS_NAMES = 'conditionDeclarationsNames',

  // ## Shared attributes
  MOTORCYCLE_CYLINDERS = 'motorcycleAttributes.cylinders',
  MOTORCYCLE_DISPLACEMENT = 'motorcycleAttributes.displacement',
  MOTORCYCLE_FUEL_TYPE = 'motorcycleAttributes.fuelType',
  MOTORCYCLE_MILEAGE = 'motorcycleAttributes.mileage',
  VEHICLE_CYLINDERS = 'vehicleAttributes.cylinders',
  VEHICLE_DISPLACEMENT = 'vehicleAttributes.displacement',
  VEHICLE_FUEL_TYPE = 'vehicleAttributes.fuelType',
  VEHICLE_MILEAGE = 'vehicleAttributes.mileage',

  // ## attributes if MOTORCYCLE
  MOTORCYCLE_CATEGORY = 'motorcycleAttributes.category',
  MOTORCYCLE_COLOR = 'motorcycleAttributes.color',
  /*
   * MOTORCYCLE_OPTIONS = 'motorcycleAttributes.options',
   * TODO: Put this back in when motorcycle options are supported by the API
   */

  // ## attributes if VEHICLE
  VEHICLE_BODY_TYPE = 'vehicleAttributes.bodyType',
  VEHICLE_CAB_TYPE = 'vehicleAttributes.cabType',
  VEHICLE_CARGO_BED_LENGTH = 'vehicleAttributes.cargoBedLength',
  VEHICLE_DRIVE_TRAIN = 'vehicleAttributes.driveTrain',
  VEHICLE_EXTERIOR_COLOR = 'vehicleAttributes.exteriorColor',
  VEHICLE_INTERIOR_COLOR = 'vehicleAttributes.interiorColor',
  VEHICLE_NUMBER_OF_DOORS = 'vehicleAttributes.numberOfDoors',
  VEHICLE_NUMBER_OF_PASSENGERS = 'vehicleAttributes.numberOfPassengers',
  VEHICLE_OPTIONS = 'vehicleAttributes.options',
  VEHICLE_MANUFACTURER_OPTIONS = 'vehicleAttributes.manufacturerVehicleOptions',
  VEHICLE_TRANSMISSION = 'vehicleAttributes.transmission',
  VEHICLE_MANUFACTURER_OPTION_CODES = 'vehicleAttributes.manufacturerVehicleOptionCodes',

  // Misc
  FLAGS = 'flags', // Not included in the actual API, is actually a subset of fields
}

export const InventoryItemConfig: TableSettingsType = {
  /** INVENTORY ITEM BASE & SHARED **/
  [InventoryItem.APPOINTMENTS_COUNT]: { label: 'appointments_other', type: FieldDataType.DEFAULT },
  [InventoryItem.ARCHIVED]: { label: 'archived', type: FieldDataType.BOOLEAN },
  [InventoryItem.CARFAX_REPORT]: {
    label: 'carfax',
    type: FieldDataType.IMAGE,
    lockedFieldId: RetailItemLockableField._carfaxReportId,
  },
  [InventoryItem.CARFAX_SHOW_WEB]: { label: 'show_carfax_badge', type: FieldDataType.BOOLEAN },
  [InventoryItem.CONDITION]: { label: 'condition', type: FieldDataType.ENUM },
  [InventoryItem.COST]: { label: 'cost', type: FieldDataType.MONETARY, lockedFieldId: RetailItemLockableField._cost },
  [InventoryItem.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [InventoryItem.CREATED_BY_NAME]: { label: 'created_by', type: FieldDataType.USER_NAME },
  [InventoryItem.DAYS_IN_STOCK]: { label: 'days_in_stock', type: FieldDataType.DEFAULT },
  [InventoryItem.DEMO]: { label: 'demo', type: FieldDataType.BOOLEAN, lockedFieldId: RetailItemLockableField._demo },
  [InventoryItem.MAKE_NAME]: {
    label: 'make_one',
    type: FieldDataType.NAME,
    lockedFieldId: RetailItemLockableField._year,
  },
  [InventoryItem.MAPPED]: { label: 'mapped', type: FieldDataType.BOOLEAN },
  [InventoryItem.MODEL_NAME]: {
    label: 'model_one',
    type: FieldDataType.NAME,
    lockedFieldId: RetailItemLockableField._model,
  },
  [InventoryItem.NOTE]: { label: 'note_one', type: FieldDataType.DEFAULT, tooltip: 'for_internal_dealer_use_only' },
  [InventoryItem.PHOTOS_EXTERIOR]: { label: 'exterior_photos', type: FieldDataType.CUSTOM },
  [InventoryItem.PHOTOS_INTERIOR]: { label: 'interior_photos', type: FieldDataType.CUSTOM },
  [InventoryItem.PHOTOS_DAMAGE]: { label: 'damage_photos', type: FieldDataType.CUSTOM },
  [InventoryItem.VIDEOS]: { label: 'video_other', type: FieldDataType.CUSTOM },
  [InventoryItem.STOCK_NUMBER]: { label: 'stock_number', type: FieldDataType.DEFAULT },
  [InventoryItem.SUB_MODEL_NAME]: { label: 'submodel_one', type: FieldDataType.NAME },
  [InventoryItem.TAG_NAME]: { label: 'tags', type: FieldDataType.BADGE_LIST },
  [InventoryItem.TASKS_COUNT]: { label: 'tasks_other', type: FieldDataType.DEFAULT },
  [InventoryItem.TRIM_NAME]: { label: 'trim', type: FieldDataType.NAME },
  [InventoryItem.TYPE]: { label: 'type', type: FieldDataType.ENUM },
  [InventoryItem.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  [InventoryItem.VIN]: { label: 'vin', type: FieldDataType.DEFAULT },
  [InventoryItem.YEAR]: {
    label: 'year_one',
    type: FieldDataType.DEFAULT,
    lockedFieldId: RetailItemLockableField._year,
  },
  [InventoryItem.STATUS]: { label: 'status', type: FieldDataType.ENUM },
  [InventoryItem.TECHNICAL_SPECIFICATIONS]: { label: 'technical_specification_other', type: FieldDataType.DEFAULT },
  [InventoryItem.STANDARD_EQUIPMENT]: { label: 'standard_equipment', type: FieldDataType.DEFAULT },
  [InventoryItem.VIDEOS_COUNT]: { label: 'video_other', type: FieldDataType.DEFAULT },
  [InventoryItem.PHOTOS_DAMAGE_COUNT]: { label: 'damage_photos', type: FieldDataType.DEFAULT },
  [InventoryItem.PHOTOS_EXTERIOR_COUNT]: { label: 'exterior_photos', type: FieldDataType.DEFAULT },
  [InventoryItem.PHOTOS_INTERIOR_COUNT]: { label: 'interior_photos', type: FieldDataType.DEFAULT },

  /** RETAIL ITEMS **/
  [InventoryItem.PRICE_2]: {
    label: 'price_2',
    type: FieldDataType.MONETARY,
    tooltip: 'price_1_and_2_tooltip',
    lockedFieldId: RetailItemLockableField._price2,
  },
  [InventoryItem.RETAIL_ITEM_STATUS]: {
    label: 'status',
    type: FieldDataType.ENUM,
    lockedFieldId: RetailItemLockableField._status,
  },
  [InventoryItem.AS_IS]: {
    label: 'as_is',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._asIs,
  },
  [InventoryItem.CERTIFIED]: {
    label: 'certified',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._certified,
  },
  [InventoryItem.COMPLETE]: { label: 'complete', type: FieldDataType.BOOLEAN },
  [InventoryItem.COMPLETE_PERCENT]: { label: 'complete_percent', type: FieldDataType.PERCENTAGE },
  [InventoryItem.CONVERSATIONS_COUNT]: { label: 'conversations', type: FieldDataType.DEFAULT },
  [InventoryItem.WALK_INS_COUNT]: { label: 'walk_ins', type: FieldDataType.DEFAULT },
  [InventoryItem.LEADS_COUNT]: { label: 'lead_other', type: FieldDataType.DEFAULT },
  [InventoryItem.DESCRIPTION]: {
    label: 'description',
    type: FieldDataType.DEFAULT,
    lockedFieldId: RetailItemLockableField._description,
  },
  [InventoryItem.PURCHASE_PRICE]: { label: 'purchase_price', type: FieldDataType.MONETARY }, // TODO: locked
  [InventoryItem.LEAD_ACTIVITIES_COUNT]: { label: 'lead_activity_other', type: FieldDataType.DEFAULT },
  [InventoryItem.PRICE_1]: {
    label: 'price_1',
    type: FieldDataType.MONETARY,
    tooltip: 'price_1_and_2_tooltip',
    lockedFieldId: RetailItemLockableField._price1,
  },
  [InventoryItem.LOCKED]: { label: 'locked', type: FieldDataType.BOOLEAN },
  [InventoryItem.MSRP]: { label: 'msrp', type: FieldDataType.MONETARY, lockedFieldId: RetailItemLockableField._msrp },
  [InventoryItem.PHONE_CALLS_COUNT]: { label: 'phone_calls', type: FieldDataType.DEFAULT },
  [InventoryItem.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.NAME },
  [InventoryItem.SHOW_WEB]: {
    label: 'show_web',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._showWeb,
  },
  [InventoryItem.SOLD]: { label: 'sold', type: FieldDataType.DAY_TIME },
  [InventoryItem.TEST_DRIVES_COUNT]: { label: 'test_drives', type: FieldDataType.DEFAULT },
  [InventoryItem.HIGHLIGHTS]: {
    label: 'highlights',
    type: FieldDataType.MULTILINGUAL_STRING,
    tooltip: 'highlights_tooltip',
    lockedFieldId: RetailItemLockableField._highlights,
  },
  [InventoryItem.FEATURED]: {
    label: 'featured',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._featured,
  },
  [InventoryItem.ON_ORDER]: {
    label: 'on_order',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._onOrder,
  },
  [InventoryItem.IN_TRANSIT]: {
    label: 'in_transit',
    type: FieldDataType.BOOLEAN,
    lockedFieldId: RetailItemLockableField._inTransit,
  },
  [InventoryItem.WARRANTY_QUEBEC_CATEGORY_NAME]: { label: 'warranty', type: FieldDataType.ENUM },
  [InventoryItem.WARRANTY_SHOW_WEB]: { label: 'show_warranty', type: FieldDataType.BOOLEAN },
  [InventoryItem.WARRANTY_DESCRIPTION]: { label: 'warranty_description', type: FieldDataType.DEFAULT },
  [InventoryItem.DESCRIPTION_ADJUSTED]: { label: 'adjusted_description', type: FieldDataType.MULTILINGUAL_STRING },

  /** RETAIL ITEMS Pricing Tab */
  [InventoryItem.CASH_ROOFTOP_RETAIL_PRICING_NAME]: {
    label: 'configuration_source',
    type: FieldDataType.CUSTOM,
  },
  [InventoryItem.CASH_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    type: FieldDataType.CUSTOM,
    tooltip: 'minimum_display_price_tooltip',
  },
  [InventoryItem.FINANCE_ROOFTOP_RETAIL_PRICING_NAME]: {
    label: 'configuration_source',
    type: FieldDataType.CUSTOM,
  },
  [InventoryItem.FINANCE_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    type: FieldDataType.CUSTOM,
    tooltip: 'minimum_display_price_tooltip',
  },
  [InventoryItem.LEASE_ROOFTOP_RETAIL_PRICING_NAME]: {
    label: 'configuration_source',
    type: FieldDataType.CUSTOM,
  },
  [InventoryItem.LEASE_ROOFTOP_RETAIL_PRICING_MIN_DISPLAY_PRICE]: {
    label: 'minimum_display_price',
    type: FieldDataType.CUSTOM,
    tooltip: 'minimum_display_price_tooltip',
  },
  /** TRADE IN ITEMS */
  [InventoryItem.TRADE_IN_ITEM_STATUS]: { label: 'status', type: FieldDataType.ENUM },
  [InventoryItem.ACCIDENT_AMOUNT]: { label: 'accident_amount', type: FieldDataType.MONETARY },
  [InventoryItem.CONDITION_SCORE]: { label: 'condition', type: FieldDataType.DEFAULT },
  [InventoryItem.LEAD]: { label: 'lead_one', type: FieldDataType.USER_NAME },
  [InventoryItem.LEAD_NOTE]: { label: 'lead_notes', type: FieldDataType.DEFAULT },
  [InventoryItem.NUMBER_OF_KEYS]: { label: 'number_of_keys', type: FieldDataType.DEFAULT },
  [InventoryItem.CONDITION_DECLARATIONS]: { label: 'declaration_other', type: FieldDataType.BADGE_LIST },

  // ## Shared attributes
  [InventoryItem.MOTORCYCLE_CYLINDERS]: { label: 'cylinders', type: FieldDataType.DEFAULT },
  [InventoryItem.MOTORCYCLE_DISPLACEMENT]: { label: 'engine', type: FieldDataType.FORMATTED_AMOUNT },
  [InventoryItem.MOTORCYCLE_FUEL_TYPE]: { label: 'fuel_type', type: FieldDataType.ENUM },
  [InventoryItem.MOTORCYCLE_MILEAGE]: { label: 'mileage', type: FieldDataType.FORMATTED_AMOUNT },
  [InventoryItem.VEHICLE_CYLINDERS]: {
    label: 'cylinders',
    type: FieldDataType.DEFAULT,
    lockedFieldId: VehicleAttributesLockableField._cylinders,
  },
  [InventoryItem.VEHICLE_DISPLACEMENT]: {
    label: 'engine',
    type: FieldDataType.FORMATTED_AMOUNT,
    lockedFieldId: VehicleAttributesLockableField._displacement,
  },
  [InventoryItem.VEHICLE_FUEL_TYPE]: {
    label: 'fuel_type',
    type: FieldDataType.ENUM,
    lockedFieldId: VehicleAttributesLockableField._fuelType,
  },
  [InventoryItem.VEHICLE_MILEAGE]: {
    label: 'mileage',
    type: FieldDataType.FORMATTED_AMOUNT,
    lockedFieldId: VehicleAttributesLockableField._mileage,
  },

  // ## attributes if MOTORCYCLE
  [InventoryItem.MOTORCYCLE_CATEGORY]: { label: 'category', type: FieldDataType.ENUM },
  [InventoryItem.MOTORCYCLE_COLOR]: { label: 'color', type: FieldDataType.COLOR_PREVIEW },
  /*
   * [InventoryItem.MOTORCYCLE_OPTIONS] : 'motorcycleAttributes.options',
   * TODO: Put this back in when motorcycle options are supported by the API
   */

  // ## attributes if VEHICLE
  [InventoryItem.VEHICLE_BODY_TYPE]: {
    label: 'body_type',
    type: FieldDataType.ENUM,
    lockedFieldId: VehicleAttributesLockableField._bodyType,
  },
  [InventoryItem.VEHICLE_CAB_TYPE]: { label: 'cab_type', type: FieldDataType.ENUM },
  [InventoryItem.VEHICLE_CARGO_BED_LENGTH]: { label: 'cargo_bed_length_in_feet', type: FieldDataType.DEFAULT },
  [InventoryItem.VEHICLE_DRIVE_TRAIN]: {
    label: 'drive_train',
    type: FieldDataType.ENUM,
    lockedFieldId: VehicleAttributesLockableField._driveTrain,
  },
  [InventoryItem.VEHICLE_EXTERIOR_COLOR]: {
    label: 'exterior_color',
    type: FieldDataType.COLOR_PREVIEW,
    lockedFieldId: VehicleAttributesLockableField._exteriorColor,
  },
  [InventoryItem.VEHICLE_INTERIOR_COLOR]: {
    label: 'interior_color',
    type: FieldDataType.COLOR_PREVIEW,
    lockedFieldId: VehicleAttributesLockableField._interiorColor,
  },
  [InventoryItem.VEHICLE_NUMBER_OF_DOORS]: {
    label: 'doors',
    type: FieldDataType.DEFAULT,
    lockedFieldId: VehicleAttributesLockableField._numberOfDoors,
  },
  [InventoryItem.VEHICLE_NUMBER_OF_PASSENGERS]: {
    label: 'passengers',
    type: FieldDataType.DEFAULT,
    lockedFieldId: VehicleAttributesLockableField._numberOfPassengers,
  },
  [InventoryItem.VEHICLE_OPTIONS]: {
    label: 'option_other',
    type: FieldDataType.BADGE_LIST,
    lockedFieldId: VehicleAttributesLockableField._options,
  },
  [InventoryItem.VEHICLE_TRANSMISSION]: {
    label: 'transmission',
    type: FieldDataType.ENUM,
    lockedFieldId: VehicleAttributesLockableField._transmission,
  },
  [InventoryItem.VEHICLE_MANUFACTURER_OPTIONS]: {
    label: 'manufacturer_options',
    type: FieldDataType.CUSTOM,
    lockedFieldId: VehicleAttributesLockableField._manufacturerVehicleOptionCodes,
  },
};

export const InventoryItemSettings = translateFields(InventoryItemConfig);
