import { gql } from '@apollo/client';

export const trimPhotoFragment = gql`
  fragment TrimPhotoFragment on TrimPhoto {
    id
    name: typeName ### Descriptive property used for seaching etc.
    primaryColorManufacturerCode
    gridPhoto: url(width: 275, height: 275, crop: LIMIT)
    type
    typeName
  }
`;
