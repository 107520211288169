import 'styled-components/macro';

import PrimaryText from 'components/core/typography/PrimaryText';
import SecondaryText from 'components/core/typography/SecondaryText';
import TextRow from 'components/core/typography/TextRow';
import { ListItem, ListItemDetails } from 'components/ui/layouts/ListItem';
import type { SelectOption } from 'store/api/graph/responses/responseTypes';

/**
 * WarrantyListItem shows a warranty option that a user could choose. This includes the title of the warranty category
 * and a brief description outlining its conditions and requirements.
 */
export const WarrantyListItem = ({ name, data }: Omit<SelectOption, 'data'> & { data: { description: string } }) => (
  <ListItem>
    <ListItemDetails>
      <TextRow>
        <PrimaryText>{name}</PrimaryText>
      </TextRow>
      <TextRow>
        <SecondaryText css={'white-space: normal'}>{data.description}</SecondaryText>
      </TextRow>
    </ListItemDetails>
  </ListItem>
);
