import type StepField from 'components/core/createModify/interfaces/stepField';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import {
  DeviceCategory,
  RooftopDepartmentType,
  type TrackingPhoneNumber,
  type TrackingPhoneNumberInput,
} from 'store/api/graph/interfaces/types';
import { getStepField } from 'utils/formatting/createModifyFormatUtils';

/**
 * Extracts phone numbers from given step fields into `TrackingPhoneNumberInput[]`
 * for use in PhoneCallTrackingRooftopIntegrationAttributesInput.
 */
export const getPhoneNumbersForInput = (fields: StepField<any, any, any>[]): TrackingPhoneNumberInput[] => {
  const desktopSalesNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER,
    fields
  );
  const desktopServiceNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER,
    fields
  );
  const desktopPartsNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER,
    fields
  );
  const mobileSalesNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER,
    fields
  );
  const mobileServiceNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER,
    fields
  );
  const mobilePartsNumberField = getStepField(
    RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER,
    fields
  );

  return [
    {
      department: RooftopDepartmentType.SALES,
      deviceCategory: DeviceCategory.DESKTOP,
      phoneNumber: desktopSalesNumberField.selectedValue,
    },
    {
      department: RooftopDepartmentType.SERVICE,
      deviceCategory: DeviceCategory.DESKTOP,
      phoneNumber: desktopServiceNumberField.selectedValue,
    },
    {
      department: RooftopDepartmentType.PARTS,
      deviceCategory: DeviceCategory.DESKTOP,
      phoneNumber: desktopPartsNumberField.selectedValue,
    },
    {
      department: RooftopDepartmentType.SALES,
      deviceCategory: DeviceCategory.MOBILE,
      phoneNumber: mobileSalesNumberField.selectedValue,
    },
    {
      department: RooftopDepartmentType.SERVICE,
      deviceCategory: DeviceCategory.MOBILE,
      phoneNumber: mobileServiceNumberField.selectedValue,
    },
    {
      department: RooftopDepartmentType.PARTS,
      deviceCategory: DeviceCategory.MOBILE,
      phoneNumber: mobilePartsNumberField.selectedValue,
    },
  ].filter(item => !!item.phoneNumber);
};

/**
 * Returns the appropriately indexed `queryAlias` for a given department and device category
 */
const getQueryAliasForPhoneNumber = (
  phoneNumbers: TrackingPhoneNumberInput[],
  department: RooftopDepartmentType,
  deviceCategory: DeviceCategory
): string =>
  `phoneNumbers#${phoneNumbers.findIndex(
    item => item.department === department && item.deviceCategory === deviceCategory
  )}.phoneNumber`;

/**
 * Dynamically updates the appropriate fields query alias so it will match for any errors that are returned.
 * If a phone number is set the queryAlias is set to `phoneNumbers#{INDEX}.phoneNumber`.
 * If no phone number is set for a given field, the queryAlias is cleared.
 */
export const setQueryAliasForMutation = (
  phoneNumbers: TrackingPhoneNumberInput[],
  fields: StepField<any, any, any>[]
) => {
  const fieldsConfig = [
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER, fields),
      department: RooftopDepartmentType.SALES,
      deviceCategory: DeviceCategory.DESKTOP,
    },
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER, fields),
      department: RooftopDepartmentType.SERVICE,
      deviceCategory: DeviceCategory.DESKTOP,
    },
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER, fields),
      department: RooftopDepartmentType.PARTS,
      deviceCategory: DeviceCategory.DESKTOP,
    },
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER, fields),
      department: RooftopDepartmentType.SALES,
      deviceCategory: DeviceCategory.MOBILE,
    },
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER, fields),
      department: RooftopDepartmentType.SERVICE,
      deviceCategory: DeviceCategory.MOBILE,
    },
    {
      stepField: getStepField(RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER, fields),
      department: RooftopDepartmentType.PARTS,
      deviceCategory: DeviceCategory.MOBILE,
    },
  ];

  for (const { stepField, department, deviceCategory } of fieldsConfig) {
    stepField.queryAlias = stepField.selectedValue
      ? getQueryAliasForPhoneNumber(phoneNumbers, department, deviceCategory)
      : undefined;
  }
};

/**
 * Returns the appropriately `phoneNumber` for a given department and device category
 */
export const getPhoneNumberByDepartmentAndDevice = (
  phoneNumbers: TrackingPhoneNumber[],
  department: RooftopDepartmentType,
  deviceCategory: DeviceCategory
): string | undefined =>
  phoneNumbers.find(item => item.department === department && item.deviceCategory === deviceCategory)?.phoneNumber;
