import type { MouseEvent, ReactNode } from 'react';

import type { FlattenSimpleInterpolation } from 'styled-components/macro';

import type { SubStepControllerProps } from 'components/core/createModify/components/interfaces';
import type StepField from 'components/core/createModify/interfaces/stepField';
import type { SubStepOption } from 'components/core/createModify/interfaces/subStepOption';
import type { ListSelectionStyle } from 'enums/listSelection';

import type { ListCondition } from './listCondition';

export type ListSelectionSortableProps = ListSelectionOptionsProps &
  Pick<ListSelectionProps, 'onDone' | 'options'> &
  Required<Pick<ListSelectionProps, 'onListItemDrag'>> & {
    /** The array of selected options */
    selectedOptions?: Array<SubStepOption | string>;
    /** Whether a search keyword is present */
    hasSearchKeyword?: boolean;
  };

export interface ListSelectionEmptyPlaceholderProps {
  /** Title of the empty sub panel message */
  title?: string;
  /** Subtitle of the empty sub panel message */
  subtitle?: string;
  /** Button label */
  buttonLabel?: string;
  /** Button onClick event */
  onClick?: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Icon to render above the title */
  icon?: ReactNode;
  /** Component to render in the left position of the action bar */
  leftActionBarButtonIcon?: React.ElementType;
}

export interface ListSelectionSettings {
  /** Hides search header and everything in it */
  hideSearchHeader?: boolean;
  /** Hides the search input only */
  hideSearchInput?: boolean;
  /**
   * Enables the done button for multi-selection lists. By default there is no done button
   *  and selections update automatically
   */
  enableDoneButton?: boolean;
  /** Sub settings for an empty panel when a list is by default empty */
  listSelectionEmptyPlaceholder: ListSelectionEmptyPlaceholderProps;
}

export type ListSelectionProps = Omit<SubStepControllerProps, 'options'> & {
  /**  Field type (e.g.: 'makes'); used for search placeholders */
  type?: string;
  /** The category of items being searched for */
  label?: string;
  /** List of options in this list */
  options?: SubStepOption[];
  /** List of options that are currently selected */
  selectedOptions?: SubStepOption[];
  /** Callback for when the user wants to add new item to this list */
  onAdd?: (preFill?: any) => void;
  /** Callback for when a user clicks on a list option */
  onSelect?: (item: SubStepOption | StepField[] | string) => void;
  /** Callback for when a user clicks on a group in the list */
  onSelectGroup?: (item: SubStepOption) => void;
  /** Callback for when the user clicks the done button at the bottom of the list */
  onDone?: (event?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Callback for when the user clicks the edit button on a list option */
  onEdit?: (id: string) => void;
  /** Callback for when the user clicks the delete button on a list option */
  onDelete?: (id: string) => void;
  /** Callback for when the user wants to clear the list selection */
  onClearSelection?: () => void;
  /** Callback for when user drags a sortable list option */
  onListItemDrag?: (itemId: string, indexNext: number) => void;
  /** Is this list a series of color options */
  isColor?: boolean;
  /** Is this list a series of date options */
  isDate?: boolean;
  /** Is this list a series of time options */
  isTime?: boolean;
  /** Can the user select multiple options at once */
  isMultiSelect?: boolean;
  /** Is this list selection disabled */
  isDisabled?: boolean;
  /** Whether this list is a StepFieldSubType.isFieldGroup type */
  isFieldGroup?: boolean;
  /** Whether this list uses toggles for the selection */
  isToggle?: boolean;
  /** Whether this list uses a sortable list for the selected items */
  isSortable?: boolean;
  /** Whether this list uses checkboxes for items */
  isCheckbox?: boolean;
  /** Whether select all header should be disabled */
  isSelectAllDisabled?: boolean;
  /** Settings for the list selection */
  settings?: ListSelectionSettings;
  /** Style variations for this list selection */
  styleVariant?: ListSelectionStyle;
};

export type ListSelectionSortableGroup = {
  /** The items in the group */
  items: SubStepOption[];
  /** The label for the group */
  label: string;
  /** Whether items in the group should be sortable */
  isSortable?: boolean;
  /** The component to use as a placeholder when no items are in the list */
  placeholder?: ListSelectionEmptyPlaceholderProps;
  /** The number of items to display in a group before a max-height scrollable container is used */
  scrollLimit?: number;
  /** CSS to apply when the SortableListItem is hovered */
  hoverCss: FlattenSimpleInterpolation;
};

export type ListSelectionOptionsProps = {
  /** The filtered list of available options */
  filteredOptions?: SubStepOption[];
  /** The selected options */
  selectedOptions?: SubStepOption[];
  /** The groups that the options can be sorted into */
  subStepGroups?: ListCondition[];
  /** Callback for when an option is selected */
  onSelect?: (item: SubStepOption | StepField[]) => void;
  /** Callback for when a group option is selected */
  onSelectGroup?: (item: SubStepOption) => void;
  /** Whether this list of options is a colour selection */
  isColor?: boolean;
  /** Can multiple items in this list be selected */
  isMultiSelect?: boolean;
  /** The base class name */
  baseClassName: string;
  /** The style variant of this list */
  styleVariant?: ListSelectionStyle;
} & Pick<ListSelectionProps, 'settings' | 'label'>;

export interface ListSelectionSortableGroupListProps
  extends ListSelectionOptionsProps,
    Pick<ListSelectionSortableProps, 'onListItemDrag' | 'onDone'> {
  group: ListSelectionSortableGroup[];
  itemIsActive: (itemId: string) => boolean;
}

export interface SortableListItemContainerProps extends Pick<ListSelectionSortableProps, 'onDone' | 'onListItemDrag'> {
  /** The index position of the item within the list where it is being rendered */
  index: number;
  /** The item object to be used for this option */
  item: SubStepOption;
  /** Children to render in the SortableListItem */
  children: ReactNode;
  /** Whether this item is sortable */
  isSortable?: boolean;
  /** CSS to apply when the SortableListItem is hovered */
  hoverCss: FlattenSimpleInterpolation;
}

export enum ListSelectionSortableGroupLabel {
  SELECTED = 'selected',
  UNSELECTED = 'unselected',
}

export const SELECT_ALL_TOGGLE_OPTION = 'SELECT_ALL_TOGGLE_OPTION';
