import { StepLegendNav } from 'components/core/createModify/components/StepLegend';
import type { BuilderEntry } from 'components/sections/createModify/interfaces';
import {
  rooftopTagCreate,
  rooftopTagModify,
} from 'components/sections/createModify/rooftopTags/RooftopTagsCreateModifyQueries';
import RooftopTagsQueryMethods from 'components/sections/createModify/rooftopTags/RooftopTagsQueryMethods';
import RooftopTagsFields from 'components/sections/createModify/rooftopTags/steps/DetailsFields';
import RooftopTagsStep from 'components/sections/createModify/rooftopTags/steps/DetailsStep';
import { BuilderType } from 'enums/builderType';
import { AccessLevel, ResourceType } from 'store/api/graph/interfaces/types';

const RooftopTagsBuilder: { [key: string]: BuilderEntry } = {
  [BuilderType.ROOFTOP_TAGS_CREATE]: {
    refetchQueries: RooftopTagsQueryMethods.refetchQueries,
    getMetaData: RooftopTagsQueryMethods.getMetaData,
    resourceType: ResourceType.TAGS,
    steps: [
      {
        id: 'ROOFTOP_TAGS_CREATE',
        step: RooftopTagsStep,
        stepName: 'create_tag',
        fields: RooftopTagsFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      create: rooftopTagCreate,
    },
    requiredPermissions: [{ resource: ResourceType.TAGS, level: AccessLevel.FULL }],
  },
  [BuilderType.ROOFTOP_TAGS_MODIFY]: {
    refetchQueries: RooftopTagsQueryMethods.refetchQueries,
    getMetaData: RooftopTagsQueryMethods.getMetaData,
    getData: RooftopTagsQueryMethods.getData,
    resourceType: ResourceType.TAGS,
    steps: [
      {
        id: 'ROOFTOP_TAGS_MODIFY',
        step: RooftopTagsStep,
        stepName: 'modify_tag',
        fields: RooftopTagsFields,
      },
    ],
    legend: [StepLegendNav],
    mutations: {
      modify: rooftopTagModify,
    },
    requiredPermissions: [{ resource: ResourceType.TAGS, level: AccessLevel.FULL }],
  },
};

export default RooftopTagsBuilder;
