import { gql } from '@apollo/client';

import { colorMetadata } from 'store/api/graph/fragments/colorMetadata';
import { contactList } from 'store/api/graph/fragments/contactList';
import { featureSet } from 'store/api/graph/fragments/featureSet';
import { group } from 'store/api/graph/fragments/group';
import {
  integration,
  integrationInventoryExportWebsiteBrandFragment,
  integrationOemAccountExportWebsiteBrandFragment,
  integrationTypeFragment,
} from 'store/api/graph/fragments/integration';
import { integrationWebsite } from 'store/api/graph/fragments/integrationWebsite';
import { leadList } from 'store/api/graph/fragments/leadList';
import { lender } from 'store/api/graph/fragments/lender';
import { manufacturerOption } from 'store/api/graph/fragments/manufacturerOption';
import { motorcycleAttributesMetadata } from 'store/api/graph/fragments/motorcycleAttributesMetadata';
import { MultilingualString } from 'store/api/graph/fragments/multilingualString';
import { resource } from 'store/api/graph/fragments/resource';
import { retailFilterMetadata } from 'store/api/graph/fragments/retailFilterMetadata';
import { retailItemList } from 'store/api/graph/fragments/retailItemList';
import { retailItemMetadata } from 'store/api/graph/fragments/retailItemMetadata';
import { role } from 'store/api/graph/fragments/role';
import { rooftopFeatureBundle } from 'store/api/graph/fragments/rooftopFeatureBundle';
import { rooftopName } from 'store/api/graph/fragments/rooftopName';
import {
  selectIntOption,
  selectMultilingualStringOption,
  selectStringOption,
} from 'store/api/graph/fragments/selectOption';
import { tradeInItemList } from 'store/api/graph/fragments/tradeInItemList';
import { trimFragment } from 'store/api/graph/fragments/trimFragment';
import { userList } from 'store/api/graph/fragments/userList';
import { vehicleAttributesMetadata } from 'store/api/graph/fragments/vehicleAttributesMetadata';
import { websiteSortOption } from 'store/api/graph/fragments/websiteSortOption';

export const tagsMetaQuery = gql`
  query TagsMetaQuery {
    metadata {
      mutation {
        item: tag {
          type {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;

// TODO conditionally request vehicleAttributes or motorcycleAttributes based on type?
export const tradeInItemMetaQuery = gql`
  query TradeInItemMetaQuery {
    metadata {
      mutation {
        item: inventoryItem {
          conditionDeclarations {
            ...SelectStringOptionFragment
          }
          tradeInItem {
            status {
              ...SelectStringOptionFragment
            }
          }
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
          motorcycleAttributes {
            ...MotorcycleAttributesMetadataFragment
          }
        }
        inventoryItemPhoto {
          damageLocation {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${vehicleAttributesMetadata}
  ${motorcycleAttributesMetadata}
`;

export const rooftopImportIntegrationsCountQuery = gql`
  query RooftopIntegrationQuery($rooftopId: [ID!]) {
    importIntegrations: rooftopIntegrationConnection(
      first: 10
      filter: { rooftopId: $rooftopId, active: true, integrationType: [INVENTORY_IMPORT, PHOTO_IMPORT, PRICE_IMPORT] }
    ) {
      pageInfo {
        totalEdges
      }
    }
  }
`;

// TODO conditionally request vehicleAttributes or motorcycleAttributes based on type?
export const retailItemMetaQuery = gql`
  query RetailItemMetaQuery {
    metadata {
      mutation {
        item: inventoryItem {
          ...RetailItemMetadataFragment
        }
        inventoryItemPhoto {
          damageLocation {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${retailItemMetadata}
`;

export const retailFiltersMetaQuery = gql`
  query RetailFiltersMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
    }

    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
  ${selectStringOption}
  ${retailFilterMetadata}
`;

export const userMetaQueries = gql`
  query UserMetaQueries($d_canAccessWhiteLabels: Boolean!, $d_isUsersAdmin: Boolean!) {
    metadata {
      mutation {
        user {
          locale {
            ...SelectStringOptionFragment
          }
        }
      }
    }

    whiteLabels @include(if: $d_canAccessWhiteLabels) {
      id
      name
    }

    resources @include(if: $d_isUsersAdmin) {
      ...ResourceFragment
    }
  }
  ${selectStringOption}
  ${resource}
`;

export const itemTagsQuery = gql`
  query ItemTagsQuery($rooftopId: [ID!], $type: EntityType) {
    tags(type: $type, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const rooftopMetaQuery = gql`
  query RooftopMetaQuery(
    $keyword: String
    $active: Boolean
    $groupId: ID
    $sort: [SortInput!]
    $features: FeatureSetInput
    $whiteLabelId: ID
  ) {
    rooftopConnection(
      first: 100
      keyword: $keyword
      filter: { active: $active, groupId: $groupId, features: $features, whiteLabelId: $whiteLabelId }
      sort: $sort
    ) {
      edges {
        node {
          id
          name {
            ...MultilingualStringFragment
          }
          group {
            id
          }
          carfaxId
          warrantyAvailable
          bundle {
            ...RooftopFeatureBundle
          }
          locale {
            languageTag
          }
        }
      }
    }
  }
  ${MultilingualString}
  ${rooftopFeatureBundle}
`;

export const rooftopDepartmentsMetaQuery = gql`
  query RooftopDepartmentsMetaQuery {
    metadata {
      mutation {
        rooftopDepartment {
          day {
            ...SelectStringOptionFragment
          }

          type {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;

export const yearsQuery = gql`
  query YearsQuery {
    metadata {
      mutation {
        inventoryItem {
          options: year {
            ...SelectIntOptionFragment
          }
        }
      }
    }
  }
  ${selectIntOption}
`;

export const makesQuery = gql`
  query MakesQuery($type: InventoryItemType!, $rooftopId: [ID!]) {
    options: makes(rooftopId: $rooftopId, type: $type) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const modelsQuery = gql`
  query ModelsQuery($rooftopId: [ID!], $makeIds: [ID!]!, $year: Int, $countryCode: CountryCode) {
    options: models(rooftopId: $rooftopId, makeIds: $makeIds, year: $year, countryCode: $countryCode) {
      id
      name {
        ...MultilingualStringFragment
      }
      makeId
    }
  }
  ${MultilingualString}
`;

export const subModelsQuery = gql`
  query SubModelsQuery($rooftopId: [ID!], $modelIds: [ID!]!) {
    options: subModels(rooftopId: $rooftopId, modelIds: $modelIds) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const trimsQuery = gql`
  query TrimsQuery($rooftopId: [ID!], $year: Int!, $subModelIds: [ID!]!) {
    options: trims(rooftopId: $rooftopId, year: $year, subModelIds: $subModelIds) {
      ...TrimFragment
    }
  }
  ${trimFragment}
`;

export const vehicleManufacturerOptionsMetaQuery = gql`
  query VehicleManufacturerOptionsQuery($trimId: ID) {
    trimSpecification(trimId: $trimId) {
      attributes {
        ... on VehicleSpecificationAttributes {
          manufacturerOptions {
            ...ManufacturerOptionFragment
          }
        }
      }
      standardEquipment {
        id
        category
        enabled
        name {
          ...MultilingualStringFragment
        }
      }
      technicalSpecifications {
        id
        category
        enabled
        name {
          ...MultilingualStringFragment
        }
        value {
          ...MultilingualStringFragment
        }
      }
    }
  }
  ${MultilingualString}
  ${manufacturerOption}
`;

export const vehicleTechnicalSpecificationsOptionsMetaQuery = gql`
  query VehicleTechnicalSpecificationsOptionsQuery($trimId: ID) {
    trimSpecification(trimId: $trimId) {
      technicalSpecifications {
        id
        category
        enabled
        name {
          ...MultilingualStringFragment
        }
        value {
          ...MultilingualStringFragment
        }
      }
    }
  }

  ${MultilingualString}
`;

export const vehicleStandardEquipmentOptionsMetaQuery = gql`
  query VehicleStandardEquipmentOptionsQuery($trimId: ID) {
    trimSpecification(trimId: $trimId) {
      standardEquipment {
        id
        category
        enabled
        name {
          ...MultilingualStringFragment
        }
      }
    }
  }
  ${MultilingualString}
`;

export const leadMetaQuery = gql`
  query LeadMetaQuery {
    metadata {
      mutation {
        ### TODO [ED-8264]: Replace with lead.locale when available
        rooftop {
          locale {
            ...SelectStringOptionFragment
          }
        }
        lead {
          paymentOptions {
            ...SelectStringOptionFragment
          }
          contactTime {
            ...SelectStringOptionFragment
          }
          contactMethod {
            ...SelectStringOptionFragment
          }
          status {
            ...SelectStringOptionFragment
          }
          employment {
            status {
              ...SelectStringOptionFragment
            }
            type {
              ...SelectStringOptionFragment
            }
          }
          gender {
            ...SelectStringOptionFragment
          }
          homeStatus {
            ...SelectStringOptionFragment
          }
          salutation {
            ...SelectStringOptionFragment
          }
          maritalStatus {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
`;

export const lenderListQuery = gql`
  query LenderListQuery($regionCode: RegionCode) {
    lenders(regionCode: $regionCode) {
      ...LenderFragment
    }
  }
  ${lender}
`;

export const taskMetaQuery = gql`
  query TaskMetaQuery {
    metadata {
      ...ColorMetadataFragment
      mutation {
        task {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }
  }
  ${selectStringOption}
  ${colorMetadata}
`;

export const leadListQuery = gql`
  query LeadListConnectionQuery(
    $keyword: String
    $rooftopId: [ID!]
    $sort: [SortInput!]
    $archived: Boolean
    $spam: Boolean
  ) {
    leadConnection(
      first: 100
      keyword: $keyword
      filter: { rooftopId: $rooftopId, archived: $archived, spam: $spam }
      sort: $sort
    ) {
      edges {
        node {
          ...LeadListFragment
        }
      }
    }
  }
  ${leadList}
`;

// TODO: [#579] Add paging at a later date
export const contactListQuery = gql`
  query ContactConnectionListQuery($keyword: String, $type: [ContactType!], $rooftopId: [ID!], $archived: Boolean) {
    contactConnection(
      first: 100
      keyword: $keyword
      filter: { archived: $archived, type: $type, rooftopId: $rooftopId }
    ) {
      edges {
        node {
          ...ContactListFragment
        }
      }
    }
  }
  ${contactList}
`;

export const rooftopBuilderMetaQuery = gql`
  query RooftopBuilderMetaQuery($filter: UserConnectionFilterInput, $d_accountRepsOn: Boolean!) {
    metadata {
      mutation {
        rooftop {
          locale {
            ...SelectStringOptionFragment
          }
          websiteProvider {
            ...SelectStringOptionFragment
          }
          bundle {
            oem {
              ...SelectStringOptionFragment
            }
            addOns {
              retail {
                paymentOptions {
                  ...SelectStringOptionFragment
                }
              }
              tradeIn {
                guaranteedOfferPackage {
                  ...SelectStringOptionFragment
                }
              }
            }
          }
        }
      }
    }

    ### Used to dynamically toggle the accountReps field in rooftop builder
    accountReps: userConnection(first: 1, filter: $filter) @include(if: $d_accountRepsOn) {
      edges {
        node {
          id
        }
      }
    }

    featurePackages {
      id
      features {
        ...FeatureSetFragment
      }
      name
    }

    integrations(type: INVENTORY_EXPORT, premium: true) {
      ...IntegrationFragment
    }
  }
  ${integration}
  ${selectStringOption}
  ${featureSet}
`;

export const shoppingToolsBuilderMetaQuery = gql`
  query ShoppingToolsBuilderMetaQuery($type: InventoryItemType!, $rooftopId: ID!) {
    metadata {
      mutation {
        rooftop {
          shoppingToolsConfiguration {
            showroomConfiguration {
              disclaimerTemplate {
                ...MultilingualStringFragment
              }
            }
            buildAndPriceConfiguration {
              disclaimerTemplate {
                ...MultilingualStringFragment
              }
            }
          }
        }
      }
    }

    makeOptions: makes(rooftopId: [$rooftopId], type: $type) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const groupsListQuery = gql`
  query GroupConnectionListQuery($keyword: String) {
    groupConnection(first: 100, keyword: $keyword) {
      edges {
        node {
          ...GroupFragment
        }
      }
    }
  }
  ${group}
`;

export const usersListQuery = gql`
  query UserConnectionListQuery($keyword: String, $filter: UserConnectionFilterInput) {
    userConnection(first: 100, keyword: $keyword, filter: $filter) {
      edges {
        node {
          ...UserListFragment
        }
      }
    }
  }
  ${userList}
`;

export const participantsListQuery = gql`
  query ParticipantsListQuery($keyword: String, $sort: [SortInput!], $filter: UserConnectionFilterInput) {
    userConnection(first: 100, keyword: $keyword, sort: $sort, filter: $filter) {
      edges {
        node {
          ...UserListFragment
          permissions {
            resource
            level
          }
        }
      }
    }
  }
  ${userList}
`;

export const inventoryItemsListQuery = gql`
  query InventoryItemsListQuery(
    $keyword: String
    $retailItemFilter: RetailItemConnectionFilterInput
    $tradeInItemFilter: TradeInItemConnectionFilterInput
    $sort: [SortInput!]
    $d_retailOn: Boolean!
    $d_tradeInOn: Boolean!
  ) {
    metadata {
      mutation {
        item: inventoryItem {
          vehicleAttributes {
            ...VehicleAttributesMetadataFragment
          }
        }
      }
    }
    retailItemConnection(first: 100, keyword: $keyword, sort: $sort, filter: $retailItemFilter)
      @include(if: $d_retailOn) {
      edges {
        node {
          ...RetailItemListFragment
        }
      }
    }

    tradeInItemConnection(first: 100, keyword: $keyword, sort: $sort, filter: $tradeInItemFilter)
      @include(if: $d_tradeInOn) {
      edges {
        node {
          ...TradeInItemListFragment
        }
      }
    }
  }
  ${retailItemList}
  ${tradeInItemList}
  ${vehicleAttributesMetadata}
`;

export const colorMetaQuery = gql`
  query ColorMetaQuery {
    metadata {
      ...ColorMetadataFragment
    }
  }
  ${colorMetadata}
`;

export const bulkAdjustmentMetaQuery = gql`
  query BulkAdjustmentMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
      mutation {
        retailBulkAdjustment {
          descriptionAttributes {
            placement {
              ...SelectStringOptionFragment
            }
          }
          promotionAttributes {
            position {
              ...SelectStringOptionFragment
            }
          }
          overlayAttributes {
            position {
              ...SelectStringOptionFragment
            }
            scalingDimension {
              ...SelectStringOptionFragment
            }
          }
          priceAttributes {
            targets {
              ...SelectStringOptionFragment
            }
          }
          disclaimerAttributes {
            disclaimerTemplate {
              ...SelectMultilingualStringOptionFragment
            }
          }
        }
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${selectStringOption}
  ${MultilingualString}
  ${selectMultilingualStringOption}
  ${retailFilterMetadata}
`;

export const leadActivityMetaQuery = gql`
  query LeadActivityMetaQuery {
    metadata {
      mutation {
        leadActivity {
          type {
            ...SelectStringOptionFragment
          }
        }
        item: inventoryItem {
          vehicleAttributes {
            exteriorColor {
              ...SelectStringOptionFragment
            }
          }
        }
      }
    }
  }
  ${selectStringOption}
`;

export const leadSourceQuery = gql`
  query LeadSourceQuery($rooftopId: ID) {
    leadSources(rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
`;

export const rolesListQuery = gql`
  query RolesMetaQuery($id: ID) {
    roles(groupId: $id) {
      ...RoleFragment
    }
  }
  ${role}
`;

export const integrationType = gql`
  query IntegrationType {
    metadata {
      mutation {
        rooftopIntegration {
          integration {
            ...IntegrationTypeFragment
          }
        }
      }
    }
  }
  ${integrationTypeFragment}
`;

export const integrationOemAccountExportWebsiteBrand = gql`
  query IntegrationOemAccountExportWebsiteBrandQuery {
    metadata {
      mutation {
        rooftopIntegration {
          oemAccountExportAttributes {
            ...IntegrationOemAccountExportWebsiteBrandFragment
          }
        }
      }
    }
  }
  ${integrationOemAccountExportWebsiteBrandFragment}
`;

export const integrationInventoryExportMetaQuery = gql`
  query IntegrationInventoryExportMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
      mutation {
        rooftopIntegration {
          inventoryExportAttributes {
            ...IntegrationInventoryExportWebsiteBrandFragment
          }
        }
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${retailFilterMetadata}
  ${MultilingualString}
  ${integrationInventoryExportWebsiteBrandFragment}
`;

export const integrationsQuery = gql`
  query IntegrationsQuery($type: IntegrationType, $premium: Boolean) {
    integrations(type: $type, premium: $premium) {
      id
      name: provider
    }
  }
`;

export const whiteLabelsQuery = gql`
  query WhiteLabels {
    whiteLabels {
      id
      name
    }
  }
`;

export const usersAssignableQuery = gql`
  query UsersAssignableQuery($rooftopId: ID!, $resource: ResourceType!) {
    usersAssignable(rooftopId: $rooftopId, resource: $resource) {
      ...UserListFragment
    }
  }
  ${userList}
`;

export const websiteMetaQuery = gql`
  query WebsiteMetaQuery($groupRooftopIds: [ID!], $d_websiteSortOptionsOn: Boolean!) {
    metadata {
      mutation {
        website {
          status {
            ...SelectStringOptionFragment
          }
          locale {
            ...SelectStringOptionFragment
          }
        }
      }
    }

    websiteSortOptions(rooftopId: $groupRooftopIds) @include(if: $d_websiteSortOptionsOn) {
      ...WebsiteSortOptionFragment
    }
  }
  ${selectStringOption}
  ${websiteSortOption}
`;

export const websiteDetailsMetaQuery = gql`
  query WebsiteDetailsMetaQuery(
    $groupId: ID!
    $rooftopIds: [ID!]!
    $groupRooftopIds: [ID!]!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
    $d_websiteSortOptionsOn: Boolean!
  ) {
    metadata {
      mutation {
        inventoryItem {
          condition {
            ...SelectStringOptionFragment
          }
          type {
            ...SelectStringOptionFragment
          }
          ...RetailItemMetadataFragment
        }
        website {
          status {
            ...SelectStringOptionFragment
          }
        }
      }
    }

    group(id: $groupId) {
      rooftops {
        ...RooftopNameFragment
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopIds) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(type: VEHICLE, rooftopId: $rooftopIds) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(makeIds: $makeIds, rooftopId: $rooftopIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(modelIds: $modelIds, rooftopId: $rooftopIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    websiteSortOptions(rooftopId: $groupRooftopIds) @include(if: $d_websiteSortOptionsOn) {
      ...WebsiteSortOptionFragment
    }
  }
  ${MultilingualString}
  ${selectStringOption}
  ${retailItemMetadata}
  ${rooftopName}
  ${websiteSortOption}
`;

export const regionAndCountryCodeQuery = gql`
  query RegionAndCountryCodeQuery {
    metadata {
      mutation {
        rooftop {
          location {
            regionCode {
              ...SelectStringOptionFragment
            }
            countryCode {
              ...SelectStringOptionFragment
            }
          }
        }
      }
    }
  }
  ${selectStringOption}
`;

export const websiteRetailFilterMetaquery = gql`
  query WebsiteRetailFilterMetaquery(
    $groupId: ID!
    $rooftopIds: [ID!]
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      mutation {
        inventoryItem {
          condition {
            ...SelectStringOptionFragment
          }
          type {
            ...SelectStringOptionFragment
          }
          vehicleAttributes {
            fuelType {
              ...SelectStringOptionFragment
            }
            bodyType {
              ...SelectStringOptionFragment
            }
          }
          retailItem {
            status {
              ...SelectStringOptionFragment
            }
          }
        }
      }
    }

    rooftopId: group(id: $groupId) {
      rooftops {
        ...RooftopNameFragment
      }
    }
    tagId: tags(type: RETAIL_ITEM, rooftopId: $rooftopIds) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makeId: makes(rooftopId: $rooftopIds, type: VEHICLE) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    modelId: models(rooftopId: $rooftopIds, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModelId: subModels(rooftopId: $rooftopIds, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }

    websiteSortOptions(rooftopId: $rooftopIds) {
      ...WebsiteSortOptionFragment
    }
  }

  ${MultilingualString}
  ${selectStringOption}
  ${websiteSortOption}
  ${rooftopName}
`;

export const integrationWebsiteConnectionQuery = gql`
  query IntegrationWebsiteConnectionQuery($first: Int, $filter: WebsiteConnectionFilterInput, $keyword: String) {
    websiteConnection(first: $first, filter: $filter, keyword: $keyword) {
      edges {
        node {
          ...IntegrationWebsiteFragment
        }
      }
    }
  }
  ${MultilingualString}
  ${integrationWebsite}
`;

export const websiteSortOptionsQuery = gql`
  query WebsiteSortOptions($id: [ID!]) {
    websiteSortOptions(rooftopId: $id) {
      ...WebsiteSortOptionFragment
    }
  }
  ${websiteSortOption}
`;

export const websitePageType = gql`
  query WebsitePageType {
    metadata {
      mutation {
        rooftopIntegration {
          cppAttributes {
            websitePageType {
              id: value
              name
            }
          }
        }
      }
    }
  }
`;

export const rooftopCashPricingSegmentMetaQuery = gql`
  query RooftopCashPricingSegmentMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
      mutation {
        rooftopRetailPricing {
          targets {
            ...SelectStringOptionFragment
          }
          cashConfiguration {
            startingPriceField {
              ...SelectStringOptionFragment
            }
            pricingSource {
              ...SelectStringOptionFragment
            }
            disclaimerTemplate {
              ...SelectMultilingualStringOptionFragment
            }
            autoPriceAdjustmentConfiguration {
              targetPriceField {
                ...SelectStringOptionFragment
              }
            }
          }
        }
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${MultilingualString}
  ${selectStringOption}
  ${selectMultilingualStringOption}
  ${retailFilterMetadata}
`;

export const rooftopFinancePricingSegmentMetaQuery = gql`
  query RooftopFinancePricingSegmentMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
      mutation {
        rooftopRetailPricing {
          targets {
            ...SelectStringOptionFragment
          }
          financeConfiguration {
            availableTerms {
              ...SelectIntOptionFragment
            }
            availablePaymentFrequencies {
              ...SelectStringOptionFragment
            }
            startingPriceField {
              ...SelectStringOptionFragment
            }
            pricingSource {
              ...SelectStringOptionFragment
            }
            defaultTermType {
              ...SelectStringOptionFragment
            }
            interestRateType {
              ...SelectStringOptionFragment
            }
            disclaimerTemplate {
              ...SelectMultilingualStringOptionFragment
            }
            autoPriceAdjustmentConfiguration {
              targetPriceField {
                ...SelectStringOptionFragment
              }
            }
          }
        }
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${selectStringOption}
  ${selectIntOption}
  ${selectMultilingualStringOption}
  ${retailFilterMetadata}
  ${MultilingualString}
`;

export const rooftopLeasePricingSegmentMetaQuery = gql`
  query RooftopLeasePricingSegmentMetaQuery(
    $rooftopId: [ID!]
    $inventoryItemType: InventoryItemType!
    $makeIds: [ID!]!
    $modelIds: [ID!]!
    $d_makesOn: Boolean!
    $d_modelsOn: Boolean!
    $d_subModelsOn: Boolean!
  ) {
    metadata {
      ...RetailFilterMetadataFragment
      mutation {
        rooftopRetailPricing {
          targets {
            ...SelectStringOptionFragment
          }
          leaseConfiguration {
            availableTerms {
              ...SelectIntOptionFragment
            }
            availablePaymentFrequencies {
              ...SelectStringOptionFragment
            }
            startingPriceField {
              ...SelectStringOptionFragment
            }
            pricingSource {
              ...SelectStringOptionFragment
            }
            defaultTermType {
              ...SelectStringOptionFragment
            }
            interestRateType {
              ...SelectStringOptionFragment
            }
            residualRateType {
              ...SelectStringOptionFragment
            }
            defaultMileageAllowanceType {
              ...SelectStringOptionFragment
            }
            defaultMileageAllowances {
              ...SelectStringOptionFragment
            }
            disclaimerTemplate {
              ...SelectMultilingualStringOptionFragment
            }
            autoPriceAdjustmentConfiguration {
              targetPriceField {
                ...SelectStringOptionFragment
              }
            }
          }
        }
      }
    }
    tags(type: RETAIL_ITEM, rooftopId: $rooftopId) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    makes(rooftopId: $rooftopId, type: $inventoryItemType) @include(if: $d_makesOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    models(rooftopId: $rooftopId, makeIds: $makeIds) @include(if: $d_modelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
    subModels(rooftopId: $rooftopId, modelIds: $modelIds) @include(if: $d_subModelsOn) {
      id
      name {
        ...MultilingualStringFragment
      }
    }
  }
  ${retailFilterMetadata}
  ${selectStringOption}
  ${selectIntOption}
  ${selectMultilingualStringOption}
  ${MultilingualString}
`;
