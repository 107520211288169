import { gql } from '@apollo/client';

export const phoneCallTrackingRooftopIntegrationAttributes = gql`
  fragment PhoneCallTrackingRooftopIntegrationAttributesFragment on PhoneCallTrackingRooftopIntegrationAttributes {
    phoneNumbers {
      department
      departmentName
      deviceCategory
      deviceCategoryName
      phoneNumber
    }
  }
`;
