import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components/macro';

import { usePrevious } from 'hooks/usePrevious';
import { BODY_TEXT, DIVIDER } from 'styles/color';
import { BLUE_050, BLUE_500, NEUTRAL_0 } from 'styles/tokens';
import { FONT_SIZE_14 } from 'styles/typography';

const ListItem = styled.div<{ isSelected?: boolean }>`
  background: ${({ isSelected }) => (isSelected ? BLUE_050 : 'none')};
  border-radius: 4px;
  color: ${({ isSelected }) => (isSelected ? BLUE_500 : BODY_TEXT)};
  cursor: pointer;
  font-size: ${FONT_SIZE_14};
  height: 35px;
  line-height: 35px;
  text-align: center;
  user-select: none;
`;

export const NumberLists = styled.div`
  background: ${NEUTRAL_0};
  display: flex;
  height: 100%;

  > div {
    flex: 1;
    padding: 10px;
  }

  > :not(:first-child) {
    border-left: 1px solid ${DIVIDER};
  }
`;

interface Props {
  fromNumber: number;
  toNumber: number;
  /** A step interval used to filter the options that will be generated using fromNumber/toNumber */
  stepNumber?: number;
  numberLabels?: Record<string, ReactNode>;
  onSelectNumber?: (number: number) => void;
  isReversed?: boolean;
  selectedNumber?: number;
  testid?: string;
}

const NumberList = ({
  fromNumber,
  toNumber,
  stepNumber,
  numberLabels,
  onSelectNumber,
  isReversed,
  selectedNumber,
  testid,
}: Props) => {
  const [currentSelectedNumber, setCurrentSelectedNumber] = useState(selectedNumber);
  const prevSelectedNumber = usePrevious(selectedNumber);
  const onClickNumber = useCallback(
    number => {
      if (onSelectNumber) {
        onSelectNumber(number);
      }
      setCurrentSelectedNumber(number);
    },
    [onSelectNumber, setCurrentSelectedNumber]
  );
  const numberListItems = useMemo(() => {
    const baseNumbers = Array.from({ length: toNumber - fromNumber + 1 }, (_, i) => fromNumber + i);
    const filteredNumbers = baseNumbers.filter(n => stepNumber === undefined || n % stepNumber === 0);
    const numbers =
      /*
       * Skip filtering the baseNumbers if selectedValue falls outside the filteredNumbers range,
       * (ensures component can display a selectedValue that is no longer within range due to stepNumber update)
       */
      selectedNumber !== undefined && filteredNumbers.includes(selectedNumber) ? filteredNumbers : baseNumbers;

    return (isReversed ? numbers.reverse() : numbers).map(numberItem => (
      <ListItem
        isSelected={numberItem === currentSelectedNumber}
        key={numberItem}
        onClick={() => onClickNumber(numberItem)}
      >
        {numberLabels?.[numberItem] || numberItem}
      </ListItem>
    ));
  }, [
    toNumber,
    fromNumber,
    selectedNumber,
    isReversed,
    stepNumber,
    currentSelectedNumber,
    numberLabels,
    onClickNumber,
  ]);

  useEffect(() => {
    if (prevSelectedNumber !== selectedNumber) {
      setCurrentSelectedNumber(selectedNumber);
    }
  }, [prevSelectedNumber, selectedNumber]);

  return (
    <div css="overflow-y: auto;" data-testid={testid}>
      {numberListItems}
    </div>
  );
};

export default NumberList;
